const CheckCircle02 = ({ color = '#BD8BC1', style = {}, className = '' }) => (
  <svg
    width={20}
    height={20}
    className={className}
    style={style}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_240_4417)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.99998 2.49992C5.85784 2.49992 2.49998 5.85778 2.49998 9.99992C2.49998 14.1421 5.85784 17.4999 9.99998 17.4999C14.1421 17.4999 17.5 14.1421 17.5 9.99992C17.5 5.85778 14.1421 2.49992 9.99998 2.49992ZM0.833313 9.99992C0.833313 4.93731 4.93737 0.833252 9.99998 0.833252C15.0626 0.833252 19.1666 4.93731 19.1666 9.99992C19.1666 15.0625 15.0626 19.1666 9.99998 19.1666C4.93737 19.1666 0.833313 15.0625 0.833313 9.99992ZM14.3392 6.91066C14.6647 7.2361 14.6647 7.76374 14.3392 8.08917L9.33923 13.0892C9.0138 13.4146 8.48616 13.4146 8.16072 13.0892L5.66072 10.5892C5.33529 10.2637 5.33529 9.7361 5.66072 9.41066C5.98616 9.08523 6.5138 9.08523 6.83923 9.41066L8.74998 11.3214L13.1607 6.91066C13.4862 6.58523 14.0138 6.58523 14.3392 6.91066Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_240_4417'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default CheckCircle02;
