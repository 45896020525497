import { cx } from '@emotion/css';
import { Box, InputBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@mysteryco/design';

export interface TextInputProps {
  value?: string;
  error?: boolean;
  placeholder?: string;
  id?: string;
  onChange?: (event: any) => void;
  disabled?: boolean;
  readOnly?: boolean;
  fullWidth?: boolean;
  params?: any;
  [key: string]: any;
}

const TextInput = ({
  value,
  helperText,
  error,
  placeholder,
  id,
  onChange = () => {},
  disabled = false,
  readOnly = false,
  fullWidth = false,
  params,
  ...props
}: TextInputProps) => {
  const classes = useStyles();

  return readOnly ? (
    <Box className={classes.inputReadOnly}>{value || placeholder}</Box>
  ) : (
    <InputBase
      id={id}
      aria-describedby={`${!!error && `${id}-error`} ${
        !!helperText && `${id}-helper-text`
      }`}
      placeholder={placeholder}
      disabled={disabled}
      error={!!error}
      fullWidth={fullWidth}
      {...props}
      value={value}
      onChange={onChange}
      className={cx(classes.input, props?.className)}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  input: {
    '&.MuiInputBase-root': {
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
      background: colors.White,
      border: `1px solid ${colors.LightGray}`,
      borderRadius: theme.spacing(1),
      gap: theme.spacing(3),
      fontWeight: 500,
      fontSize: '.875rem',
      lineHeight: 1,
      color: colors.Black,
      transition: 'all 300ms, ease-in-out',
      caretColor: colors.Main,
      '&:hover': {
        borderColor: colors.Purple200,
      },
      '&:focus-within': {
        borderColor: colors.Main,
      },
      '&::placeholder': {
        opacity: 1,
        color: colors.LightGray,
      },
      '&.Mui-disabled': {
        background: colors.OffWhite,
        color: colors.MediumGray,
        '&:focus-within, &:hover': {
          borderColor: colors.LightGray,
        },
      },
      '&.Mui-error': {
        background: colors.Orange100,
        borderColor: colors.Orange600,
        '&:focus-within, &:hover': {
          borderColor: colors.Orange600,
        },
      },
      '&.MuiInputBase-multiline': {
        lineHeight: 1.71,
      },
    },
  },
  inputReadOnly: {
    padding: `${theme.spacing(2)} 0`,
    margin: '1px 0',
    fontWeight: 500,
    fontSize: '.875rem',
    lineHeight: 1,
    color: colors.Black,
  },
}));

export default TextInput;
