// @ts-strict-ignore
import { cx } from '@emotion/css';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@mysteryco/design';
import XClose2 from '@mysteryco/design/icons/XClose2';
import theme from 'theme';

const TagChip = ({
  text,
  variant = 'active',
  disabled = false,
  onDelete,
  ...props
}: {
  text: string;
  variant?: 'active' | 'base';
  disabled?: boolean;
  onDelete?: (event: any) => void;
  [key: string]: any;
}) => {
  const classes = useStyles();

  const handleClick = (event: any) => {
    if (disabled) return;
    onDelete(event);
  };

  return (
    <Box
      className={cx(classes.chip, classes[variant], disabled && classes.disabled)}
      {...props}
    >
      <Box className={classes.label}>{text}</Box>
      {onDelete && (
        <Box className={classes.icon} onClickCapture={handleClick}>
          <XClose2 />
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  chip: {
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
    gap: theme.spacing(2),
    background: '#F2F2F2',
    borderRadius: theme.spacing(4),
    height: 'inherit',
  },
  base: {
    background: '#F2F2F2',
    '& $label': {
      color: '#4F4F4F',
    },
  },
  active: {
    background: colors.Purple100,
    '& $label': {
      color: colors.Dusk,
    },
  },
  label: {
    fontWeight: 500,
    fontSize: '.875rem',
    lineHeight: 1.43,
    padding: 0,
  },
  icon: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      height: 12,
      width: 12,
      color: colors.Dusk,
    },
  },
  disabled: {
    background: colors.OffWhite,
    '& $label': {
      color: colors.MediumGray,
    },
    '& $icon': {
      cursor: 'default',
      '& svg': {
        color: colors.MediumGray,
      },
    },
  },
});

export default TagChip;
