import { css } from '@emotion/react';
import { BoxProps, Box } from '@material-ui/core';
import { colors } from '@mysteryco/design';
import theme from 'theme';
import UpgradeButton from '../UpgradeButton';

export interface PageTitleProps extends BoxProps {
  hideUpgradeButton?: boolean;
  /**
   * For Mixpanel analytics
   */
  pageName: string;
}

export function PageTitle({
  children,
  pageName,
  hideUpgradeButton,
  ...rest
}: PageTitleProps) {
  return (
    <Box css={styles.pageHeader} {...rest}>
      <h1 css={styles.pageTitle}>{children}</h1>
      {!hideUpgradeButton && <UpgradeButton page={pageName} />}
    </Box>
  );
}

export default PageTitle;

const styles = {
  pageHeader: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(5),
  }),
  pageTitle: css({
    fontWeight: 500,
    fontSize: '2.5rem',
    lineHeight: 1,
    color: colors.Glue_Ink10,
  }),
};
