// @ts-strict-ignore
import { Box } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import NewPrimaryButton from 'components/core/NewPrimaryButton';
import HostPasswordViewer from 'components/HostPasswordViewer';
import Star01 from 'components/icons/Star01';
import {
  canJoinVirtualEvent,
  canShowJoinButton,
  getJoinButtonUrl,
  hasZoomMeeting,
  isEventCompleted,
} from 'lib/helpers/events';
import mixpanel from 'mixpanel-browser';
import {
  EventDetailsQuery,
  InvitedGuestRsvpStatus,
  VirtualEventFragmentFragment,
} from 'types';
import { useViewer } from 'utils/state';

const JoinAndFeedbackSection = ({
  teamEvent,
  virtualEvent,
  isHost,
  addAttendee,
  hasCompletedFeedback,
  rsvpStatus,
}: {
  teamEvent: EventDetailsQuery['teamEvent'];
  virtualEvent: VirtualEventFragmentFragment;
  isHost: boolean;
  addAttendee: (...args: any) => Promise<any>;
  hasCompletedFeedback: boolean;
  rsvpStatus: InvitedGuestRsvpStatus;
}) => {
  const classes = useStyles();
  const viewer = useViewer();
  const canJoin = canJoinVirtualEvent({ teamEvent, isHost });
  const isCompleted = isEventCompleted(teamEvent);
  const buttonUrl = getJoinButtonUrl({ teamEvent, isHost });

  const rsvpDeclined = rsvpStatus === InvitedGuestRsvpStatus.Declined;
  const host = virtualEvent?.zoomMeeting?.host;
  const showHostPassword =
    !!host && canJoin && isHost && hasZoomMeeting({ virtualEvent });
  const showJoinButton = canShowJoinButton({ teamEvent, isHost });
  const showFeedbackButton =
    !rsvpDeclined && isCompleted && !showJoinButton && !hasCompletedFeedback;
  const showFeedbackComplete =
    !rsvpDeclined && isCompleted && !showJoinButton && hasCompletedFeedback;
  const showJoinSection =
    showJoinButton || showHostPassword || showFeedbackButton || showFeedbackComplete;

  if (!showJoinSection) {
    return null;
  }

  return (
    <Box className={classes.container}>
      {showHostPassword && <HostPasswordViewer hostEmail={host.email} hostId={host.id} />}
      {showJoinButton && (
        <NewPrimaryButton
          label={isHost ? 'Start now' : 'Join now'}
          onClick={() => {
            mixpanel.track('event joined', {
              source: 'details',
              customerType: viewer?.customerType,
              firstTimeBooker: viewer?.requestedTeamEvents.length === 0,
            });
            addAttendee();
            window.open(buttonUrl, '_blank');
          }}
        />
      )}
      {showFeedbackButton && (
        <NewPrimaryButton
          label={'Give feedback'}
          onClick={() => {
            mixpanel.track('feedback opened', {
              source: 'details',
              customerType: viewer?.customerType,
              firstTimeBooker: viewer?.requestedTeamEvents.length === 0,
            });
            addAttendee();
            window.open(buttonUrl, '_blank');
          }}
        />
      )}
      {showFeedbackComplete && (
        <Box className={classes.feedbackCompleteText}>
          <Star01 style={{ margin: '0 8px' }} /> Thank you for your feedback on this
          event!
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '40px',
    '& >button': {
      width: '536px',
    },
  },
  feedbackCompleteText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    background: green[50],
    fontWeight: 700,
    color: theme.palette.primary.main,
    fontSize: '16px',
    margin: '12px 32px',
    height: '48px',
    padding: '12px',
    borderRadius: '4px',
    width: '536px',
    marginBottom: '40px',
  },
}));

export default JoinAndFeedbackSection;
