import React from 'react';
import theme from 'theme';

const Stars = () => {
  return (
    <svg
      width='231'
      height='165'
      viewBox='0 0 231 165'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='231' height='165' fill='white' />
      <path
        d='M41.644 43.3696L51.736 1.08594L80.9502 33.2773L124.284 29.809L102.695 67.5413L119.385 107.683L76.8262 98.8106L43.8091 127.086L39.096 83.8712L2 61.2065L41.644 43.3696Z'
        stroke={theme.palette.primary.main}
      />
      <path
        d='M191.64 41.8757L210.825 28.6339L210.932 51.9444L229.454 66.0982L207.317 73.4034L199.58 95.3934L185.791 76.5966L162.488 76.0331L176.103 57.1118L169.437 34.774L191.64 41.8757Z'
        stroke={theme.palette.primary.main}
      />
      <path
        d='M149.982 127.177L159.923 111.993L166.85 128.767L184.362 133.529L170.549 145.301L171.432 163.428L155.967 153.928L139.001 160.369L143.257 142.727L131.888 128.58L149.982 127.177Z'
        stroke={theme.palette.primary.main}
      />
    </svg>
  );
};

export default Stars;
