import { css } from '@emotion/react';
import { Coal_40 } from '@mysteryco/design/src/tokens/colors';
import theme from 'theme';

const EditSection = ({ children }) => <div css={styles.container}>{children}</div>;

export default EditSection;

const styles = {
  container: css({
    fontFamily: 'Manrope',
    border: `solid 1px ${Coal_40}`,
    borderRadius: theme.spacing(4),
    padding: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
  }),
};
