import { Sizes, Type } from 'constants/index';
import _ from 'lodash';
import { ResponsiveContext, responsiveSwitch } from 'providers/BreakpointsProvider';
import React, { useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';

type SizeVariant = 'normal' | 'big' | 'small' | 'xsmall';

interface TitleProps {
  children: any;
  className?: string;
  size?: SizeVariant;
  explicitSize?: number;
  marginBottom?: keyof typeof Sizes;
  weight?: keyof typeof Type['FontWeights'];
}

const useStyles = makeStyles({
  root: (props: any) => ({
    ...responsiveSwitch(
      {
        small: Type.fontSize(Type.Sizes.XLARGE * props.factor),
        medium: Type.fontSize(Type.Sizes.XXLARGE * props.factor),
        large: Type.fontSize(Type.Sizes.XXXLARGE * props.factor),
        xlarge: Type.fontSize(Type.Sizes.XXXLARGE * props.factor),
      },
      props.context,
    ),
    ...(props.explicitSize ? { fontSize: `${props.explicitSize}rem` } : {}),
    marginBottom: Sizes[props.marginBottom],
    fontWeight: Type.FontWeights[props.weight || 'NORMAL'],
  }),
});

export function Title({
  size,
  explicitSize,
  weight,
  className,
  children,
  marginBottom = 'SPACING_XSMALL',
}: TitleProps) {
  const context = useContext(ResponsiveContext);

  const factor = (() => {
    switch (size) {
      case 'xsmall':
        return 0.58;
      case 'small':
        return 0.8;
      case 'big':
        return 1.2;
      default:
      case 'normal':
        return 1;
    }
  })();

  const classes = useStyles({
    context,
    explicitSize,
    marginBottom,
    weight,
    factor,
  });

  return <h1 className={_.compact([classes.root, className]).join(' ')}>{children}</h1>;
}
