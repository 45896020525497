// @ts-strict-ignore
import { useParams } from 'react-router-dom';
import { getInvoiceQuery } from 'gql/queries';
import { useMutation, useQuery } from '@apollo/client';
import { Footer, Loading, NotFoundError } from 'components/core';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import LogoWithName from 'components/LogoWithName';
import theme from 'theme';
import {
  ViewerFragmentFragment,
  LineItemCategory,
  PriceStatus,
  TeamEventInvoiceFragmentFragment,
} from 'types';

import ContractSummary from 'components/SSFSteps/Payment/ContractSummary';
import _ from 'lodash';
import {
  centsToDollarString,
  getContractCostForInvoice,
  getContractFromTeamEventInvoiceFragment,
} from 'lib/helpers/money';
import PaymentMethod from 'components/SSFSteps/Payment/PaymentMethod';
import { useEffect, useState } from 'react';
import SummarySection from 'components/SSFSteps/Payment/SummarySection';
import { chargeForFinalInvoice } from 'gql/mutations';
import NextButton from 'components/Checkout/steps/NextButton';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    padding: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
  },
  content: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(6),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '70vh',
  },
});

const PaymentButton = ({ teamEvent, viewer, disabled, setCharged }) => {
  const [loading, setLoading] = useState(false);
  const [chargeCustomer] = useMutation(chargeForFinalInvoice, {
    variables: {
      teamEventId: teamEvent.id,
      userId: viewer.id,
    },
  });

  return (
    <Box mt={6}>
      <NextButton
        loading={loading}
        disabled={disabled}
        onClick={async () => {
          setLoading(true);
          await chargeCustomer();
          setCharged(true);
          setLoading(false);
        }}
        label='Pay Now'
        width='100%'
      />
    </Box>
  );
};

const ChargeComplete = () => {
  return (
    <Box textAlign='center' my={6}>
      <Typography variant='h4'>
        Payment Complete. Thanks for using Glue! We'll see you again soon.
      </Typography>
    </Box>
  );
};

const CompletePayment = ({
  viewer,
  teamEvent,
  setCharged,
}: {
  viewer: ViewerFragmentFragment;
  teamEvent: TeamEventInvoiceFragmentFragment;
  setCharged(charged: boolean): void;
}) => {
  const [stripeToken, setStripeToken] = useState<string>();
  const [errorState, setErrorState] = useState();

  const { experience, finalizedPrice, title } = teamEvent;

  const taxLineItem = _.find(
    _.get(finalizedPrice, 'lineItems'),
    (li) => li.category === LineItemCategory.Tax,
  );

  const contract = getContractFromTeamEventInvoiceFragment(teamEvent);
  const contractCost = getContractCostForInvoice(teamEvent);

  return (
    <Box display='flex' flexDirection='row' justifyContent='space-between' width='100%'>
      <Box flex={3} marginRight={14}>
        <PaymentMethod
          userId={viewer.id}
          cardOnFile={viewer.cardOnFile}
          setErrorState={setErrorState}
          setStripeToken={setStripeToken}
        />
        <SummarySection
          experience={{
            ...experience,
            startTimeISO: teamEvent.requestedFor,
            title: experience.name,
            photoUrl: _.first(experience.templates).photoUrl,
          }}
          surpriseToAll={false}
          surpriseToAttendees={false}
          headCount={teamEvent.expectedHeadCount}
          title={title}
        />
      </Box>
      <Box flex={1} minWidth='280px'>
        <ContractSummary
          contract={contract}
          contractCost={contractCost}
          subtotal={centsToDollarString(finalizedPrice.totalCents - taxLineItem.cents)}
          tax={centsToDollarString(taxLineItem.cents)}
          total={centsToDollarString(finalizedPrice.totalCents)}
          finalBilling
        />
        <PaymentButton
          teamEvent={teamEvent}
          viewer={viewer}
          disabled={(!viewer.cardOnFile && !stripeToken) || errorState}
          setCharged={setCharged}
        />
      </Box>
    </Box>
  );
};

/**
 * This scene is used for collecting payment from contract customers when an event
 * incurs an overage charge. We send them an email that routes to /invoice/:id,
 * which renders this component.
 */

const Invoice = ({ viewer }: { viewer: ViewerFragmentFragment | undefined }) => {
  const { id: teamEventId } = useParams<{ id: string }>();

  const { data } = useQuery<{
    teamEvent: TeamEventInvoiceFragmentFragment;
  }>(getInvoiceQuery, {
    variables: {
      teamEventId,
    },
  });

  const [charged, setCharged] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (!data) return;

    setCharged(_.get(data, 'teamEvent.finalizedPrice.status') === PriceStatus.Paid);
  }, [data]);

  if (!data) return <Loading />;

  const { teamEvent } = data;

  return teamEvent ? (
    <Box className={classes.container}>
      <LogoWithName />
      <Box mt={6}>
        <Typography variant='h3'>Invoice</Typography>
        <Typography variant='subtitle1'>
          Final billing for your event: {teamEvent.title}
        </Typography>
      </Box>
      <Box className={classes.content}>
        {charged ? (
          <ChargeComplete />
        ) : (
          <CompletePayment
            viewer={viewer}
            teamEvent={teamEvent}
            setCharged={setCharged}
          />
        )}
        <Footer />
      </Box>
    </Box>
  ) : (
    <NotFoundError
      title='Invoice not found'
      subtitle='It appears that Team Event does not exist...'
    />
  );
};

export default Invoice;
