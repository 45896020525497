import { LabeledField } from './core';
import { useQuery, gql } from '@apollo/client';
import { makeStyles } from '@material-ui/styles';
import { SPACING_MEDIUM, SPACING_SMALL } from 'constants/Sizes';
import { isProd } from 'config';

const useStyles = makeStyles({
  email: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: SPACING_SMALL,
  },
  password: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const HostPasswordViewer = ({ hostEmail, hostId }) => {
  const classes = useStyles();
  const { data } = useQuery(HostPasswordViewer.getPassword, {
    variables: { zoomHostId: hostId },
  });

  return data ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        marginBottom: SPACING_MEDIUM,
      }}
    >
      <LabeledField className={classes.email} label='Zoom Host Email Address'>
        <p>{hostEmail}</p>
      </LabeledField>
      <LabeledField className={classes.password} label='Zoom Host Password'>
        <p>{isProd ? (data ? data.zoomHostPassword : '') : 'devPlaceholder'}</p>
      </LabeledField>
    </div>
  ) : null;
};

HostPasswordViewer.getPassword = gql`
  query HostPassword($zoomHostId: ID!) {
    zoomHostPassword(zoomHostId: $zoomHostId)
  }
`;

export default HostPasswordViewer;
