import { Box, Typography } from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { Dusk, Main, Purple100, Purple50 } from '@mysteryco/design/src/tokens/colors';

const PlaceholderCard = ({
  title,
  subtitle,
  children,
}: {
  title: string;
  subtitle: string;
  children: React.ReactNode;
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.subtitle}>{subtitle}</Typography>
      <Box className={classes.content}>{children}</Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px solid ${Purple100}`,
    borderRadius: 4,
    background: Purple50,
    transition: 'all 300ms ease-in-out',
    boxShadow: `0 ${theme.spacing(1)} ${theme.spacing(2)} -${theme.spacing(1)} ${alpha(
      Main,
      0.2,
    )}`,
    textAlign: 'center',
    padding: theme.spacing(4),
  },
  title: {
    fontSize: '1.125rem',
    fontWeight: theme.typography.fontWeightBold,
    color: Dusk,
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    fontSize: '.85rem',
    color: theme.palette.common.black,
    opacity: '.75',
    fontWeight: 500,
  },
  content: {
    padding: `${theme.spacing(4)} ${theme.spacing(4)} 0 ${theme.spacing(4)}`,
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default PlaceholderCard;
