import { gql } from '@apollo/client';
import TimeframeFilter from 'glue/scenes/Explore/Pulse/TimeframeFilter';
import { useQueryParam } from 'lib/helpers/router';
import {
  MoralePulseSurveyReportingIntervalType,
  TimeframeFilterIntervalsQuery,
  TimeframeFilterTimeframeFragment,
  useTimeframeFilterIntervalsQuery,
} from 'types';
import { useCallback, useMemo } from 'react';

export function usePulseReportTimeframeFilterKey() {
  return useQueryParam('timeframe');
}

export type PulseTimeframes = {
  quarters: TimeframeFilterTimeframeFragment[];
  months: TimeframeFilterTimeframeFragment[];
  allTimeframes: TimeframeFilterTimeframeFragment[];
  loading: boolean;
};

function useTimeframes(data: TimeframeFilterIntervalsQuery | undefined) {
  return useMemo(() => {
    const quarters =
      data?.moralePulseReportingTimeframes.find(
        (category) =>
          category.interval === MoralePulseSurveyReportingIntervalType.Quarter,
      )?.timeframeData || [];
    const months =
      data?.moralePulseReportingTimeframes.find(
        (category) => category.interval === MoralePulseSurveyReportingIntervalType.Month,
      )?.timeframeData || [];
    const allTimeframes = Object.values(quarters).concat(months);
    return {
      quarters,
      months,
      allTimeframes,
    };
  }, [data]);
}

export const TimeframeFilterQuery = gql`
  query TimeframeFilterIntervals {
    moralePulseReportingTimeframes {
      interval
      timeframeData {
        ...TimeframeFilterTimeframe
      }
    }
  }
  ${TimeframeFilter.fragments}
`;

export function usePulseTimeframes(): PulseTimeframes {
  const { data: intervalData, loading } = useTimeframeFilterIntervalsQuery({});
  const { quarters, months, allTimeframes } = useTimeframes(intervalData);

  return {
    quarters,
    months,
    allTimeframes,
    loading,
  };
}

export function findTimeframeByKey(
  allTimeframes: TimeframeFilterTimeframeFragment[],
  key: string | undefined,
) {
  if (!key) return undefined;
  return allTimeframes.find((timeframe) => timeframe.key === key);
}

export function useSelectedTimeframeFilter() {
  const [filterKey, setFilterKey] = usePulseReportTimeframeFilterKey();
  const { allTimeframes } = usePulseTimeframes();
  const value = findTimeframeByKey(allTimeframes, filterKey);
  const setValue = useCallback(
    (timeframe: TimeframeFilterTimeframeFragment | undefined) => {
      setFilterKey(timeframe?.key);
    },
    [setFilterKey],
  );
  return [value, setValue] as const;
}
