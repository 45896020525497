// @ts-strict-ignore
import { Box, Button, ButtonGroup, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import React from 'react';
import theme from 'theme';
import { PersonRaterAnswer, PersonRaterOption, RelationshipOptions } from 'types';
import { Colors } from 'constants/index';
import { AnswerProps } from '.';

const useStyles = makeStyles({
  buttonGroup: {
    height: '48px',
    '& .MuiButton-contained': {
      background: '#F2E8F3',
      border: '1.5px solid #7B1783',
    },
    borderRadius: '100px',
    alignSelf: 'center',
  },
  answerContainer: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: '100%',
  },
  button: {
    textTransform: 'none',
    width: '100%',
  },
  activeButton: {
    textTransform: 'none',
    width: '100%',
    color: Colors.Brand.Purple,
    background: Colors.Brand.PurpleLight,
  },
  name: {
    width: '536px',
    height: '24px',
    fontFamily: 'inherit',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24px',
  },
});

const RELATIONSHIP_OPTIONS = ['Not Sure', 'Just Met', 'Neutral', 'Work Friends', 'BFFs'];

const RelationshipMappings: { [key: string]: RelationshipOptions } = {
  'Not Sure': RelationshipOptions.NotSure,
  'Just Met': RelationshipOptions.JustMet,
  Neutral: RelationshipOptions.Neutral,
  'Work Friends': RelationshipOptions.WorkFriends,
  BFFs: RelationshipOptions.Bffs,
};

const RelationshipChoice = ({ question, answer, setAnswer }: AnswerProps) => {
  const classes = useStyles();
  const [selectedOptions, setSelectedOptions] = React.useState(
    JSON.parse(answer ?? '{}') as {
      [key: string]: PersonRaterAnswer;
    },
  );

  const setSelected = ({ userId, relationship }) => {
    const copySelectedOptions = selectedOptions;
    copySelectedOptions[userId] = {
      userId,
      value: RelationshipMappings[relationship],
    };
    setSelectedOptions(copySelectedOptions);
    setAnswer(JSON.stringify(Object.values(copySelectedOptions)));
  };

  return (
    <FormControl
      fullWidth
      component='fieldset'
      style={{ height: `${question.options.length * 110}px` }}
    >
      {question.options.map((option) => {
        const parsedOption = JSON.parse(option) as PersonRaterOption;
        return (
          <Box
            style={{
              marginTop: '20px',
            }}
          >
            <h2 className={classes.name}>{parsedOption.label}</h2>
            <ButtonGroup fullWidth className={classes.buttonGroup}>
              {_.map(RELATIONSHIP_OPTIONS, (relationshipOption) => (
                <Button
                  value={relationshipOption}
                  key={relationshipOption}
                  variant={
                    selectedOptions[parsedOption.userId]?.value ===
                    RelationshipMappings[relationshipOption]
                      ? 'contained'
                      : 'outlined'
                  }
                  onClick={() => {
                    setSelected({
                      userId: parsedOption.userId,
                      relationship: relationshipOption,
                    });
                  }}
                >
                  {relationshipOption}
                </Button>
              ))}
            </ButtonGroup>
          </Box>
        );
      })}
    </FormControl>
  );
};

export default RelationshipChoice;
