import theme from 'theme';

const LineChartUp = ({
  size = 20,
  color = theme.palette.primary.main,
  style = {},
  className = '',
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 19 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      style={style}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.49992 2.49967C5.35778 2.49967 1.99992 5.85754 1.99992 9.99967C1.99992 14.1418 5.35778 17.4997 9.49992 17.4997C13.6421 17.4997 16.9999 14.1418 16.9999 9.99967C16.9999 5.85754 13.6421 2.49967 9.49992 2.49967ZM0.333252 9.99967C0.333252 4.93706 4.43731 0.833008 9.49992 0.833008C14.5625 0.833008 18.6666 4.93706 18.6666 9.99967C18.6666 15.0623 14.5625 19.1663 9.49992 19.1663C4.43731 19.1663 0.333252 15.0623 0.333252 9.99967ZM15.0892 7.74375C15.4146 8.06919 15.4146 8.59683 15.0892 8.92226L12.2272 11.7842C12.2228 11.7887 12.2182 11.7932 12.2136 11.7978C12.1434 11.8681 12.0597 11.9519 11.9798 12.0197C11.8872 12.0983 11.7467 12.2029 11.5529 12.2659C11.3018 12.3475 11.0314 12.3475 10.7803 12.2659C10.5865 12.2029 10.446 12.0983 10.3534 12.0197C10.2735 11.9519 10.1897 11.8681 10.1196 11.7978C10.115 11.7932 10.1104 11.7887 10.1059 11.7842L7.83325 9.51152L5.08917 12.2556C4.76374 12.581 4.2361 12.581 3.91066 12.2556C3.58523 11.9302 3.58523 11.4025 3.91066 11.0771L6.77259 8.21516C6.77707 8.21067 6.78162 8.20612 6.78623 8.20151C6.85639 8.1313 6.94018 8.04744 7.02006 7.97963C7.11262 7.90105 7.25312 7.79643 7.44698 7.73344C7.69803 7.65187 7.96847 7.65187 8.21952 7.73344C8.41338 7.79643 8.55388 7.90105 8.64644 7.97963C8.72632 8.04744 8.81011 8.1313 8.88027 8.20151C8.88488 8.20612 8.88943 8.21067 8.89391 8.21516L11.1666 10.4878L13.9107 7.74375C14.2361 7.41831 14.7637 7.41831 15.0892 7.74375Z'
        fill={color}
      />
    </svg>
  );
};

export default LineChartUp;
