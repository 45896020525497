// @ts-strict-ignore
import _ from 'lodash';
import { DateTime } from 'luxon';

import Star from 'assets/star.png';
import UserCheckStar from 'assets/user-check-star.png';
import UserCheck from 'assets/user-check.png';
import { isNewHire } from 'utils/dateUtil';

import { MemberFragment, Node, NodeType } from './interface';
import { getDepartmentName } from './utility';
import { GraphContext } from './GraphContext';
import { defaultTeamName, isUsingDefaultTeamName } from 'utils/teamUtil';

export const computeNodes = (
  context: GraphContext,
  teamId: string,
  rootLevel: boolean,
  showUpdateTeams: boolean = false,
) => {
  let nodes: Node[] = [];
  if (context.config.flattenMembers) {
    const transitiveTeamMembers = context.transitiveTeamMembers(teamId);
    for (const member of transitiveTeamMembers) {
      nodes.push(createMemberNode(context, member, context.isManager(member.id)));
    }
    return nodes;
  }

  if (context.config.flattenTeams) {
    for (const team of context.teams) {
      nodes.push(
        createTeamNode(
          context,
          Math.max(...context.teams.map((team) => team.members.length)),
          team.id,
          true,
        ),
      );
    }
    const team = context.team(teamId);
    nodes.push(createMemberNode(context, team.manager, true));
    return nodes;
  }

  const team = context.team(teamId);
  nodes.push(createMemberNode(context, team.manager, true));

  const maxMembers = team.childTeams
    .map(({ id }) => context.transitiveTeamMembers(id).length + 1)
    .sort((a, b) => b - a)[0];

  for (const childTeam of team.childTeams) {
    nodes.push(
      createTeamNode(context, maxMembers, childTeam.id, rootLevel, showUpdateTeams),
    );
  }
  const nonManagerMembers = team.members.filter(
    (member) => !context.isManager(member.id),
  );
  for (const member of nonManagerMembers) {
    nodes.push(createMemberNode(context, member));
  }
  return nodes;
};

const createMemberNode = (
  { config }: GraphContext,
  member: MemberFragment,
  isManager = false,
) => {
  const node = {
    type: NodeType.Member,
    id: member.id,
    name: member.name,
    size: isManager ? config.managerNodeSize : config.minNodeSize,
    label: `${member.firstName} ${_.first(member.lastName)}`,
    role: member.companyRole,
    chosen: false,
    color: {
      background: '#FFFFFF',
      border: '#7B1783',
    },
    font: {
      color: '#7B1783',
      strokeColor: '#F5EEF6',
      background: '#F5EEF6',
    },
  } as Node;
  const newHire =
    member.startDate && isNewHire(DateTime.fromFormat(member.startDate, 'yyyy-MM-dd'));
  if (isManager || newHire) {
    node.shape = 'circularImage';
    if (isManager && newHire) {
      node.image = UserCheckStar;
    } else if (isManager) {
      node.image = UserCheck;
    } else if (newHire) {
      node.image = Star;
    }
  }

  return node;
};

const createTeamNode = (
  context: GraphContext,
  maxMembers: number,
  teamId: string,
  rootLevel: boolean,
  showTeamName: boolean = false,
) => {
  const team = context.team(teamId);
  const departmentName = getDepartmentName(team.manager);
  const teamMembers = [team.manager, ...context.transitiveTeamMembers(teamId)];

  const { nodeSizeCurve, minNodeSize, maxNodeSize } = context.config;
  // We scale node sizes based on the largest team that is visible (and the
  // smallest "team" being an individual person).
  const easedRelativeSize = nodeSizeCurve(teamMembers.length / maxMembers);
  const size = minNodeSize + (maxNodeSize - minNodeSize) * easedRelativeSize;

  let label = '';
  let role = '';
  if (showTeamName) {
    label = team.name;
    role = isUsingDefaultTeamName(team) ? '' : team.name;
  } else {
    label = rootLevel && departmentName ? departmentName : defaultTeamName(team);
    role = departmentName?.toUpperCase();
  }

  return {
    type: NodeType.Team,
    id: team.id,
    name: `${team.manager.name}'s Team`,
    size,
    label: label,
    role: role,
    members: teamMembers,
    chosen: false,
  } as Node;
};

export const getNodeStrength = (context: GraphContext, node: Node): number => {
  const scores = context.nodeConnectionScores(node.id);
  if (scores) {
    return scores.WithinTeam;
  } else {
    const memberIds = node.members.map(({ id }) => id);
    return context.meanConnectionScore(memberIds, memberIds);
  }
};
