// @ts-strict-ignore
import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import { colors } from '@mysteryco/design';
import { IconProps } from '@mysteryco/design/icons';
import theme from 'theme';
import Link, { LinkProps } from 'glue/components/buttons/Link';

interface Props extends Omit<LinkProps, 'to'> {
  Icon?: React.ComponentType<IconProps>;
  size?: 'small' | 'large';
  disabled?: boolean;
  to?: LinkProps['to'];
  /**
   * Pass this to render a link that looks like a link but doesn't actually
   * navigate. Useful to avoid invalid DOM nesting if this is rendered
   * within another <a> tag somewhere.
   */
  visualOnly?: boolean;
}

const TextLink = ({
  children,
  Icon,
  size = 'small',
  onClick,
  to,
  disabled = false,
  visualOnly,
  ...props
}: Props) => {
  // treat links without a `to` prop as buttons
  const role = to ? 'link' : 'button'; // for accessibility
  const iconSize = size === 'small' ? 16 : 20;
  const fontSize = size === 'small' ? 14 : 16;

  const Implementation = visualOnly ? DummyLink : Link;

  const invalidConfiguration = !!to && visualOnly;
  useEffect(() => {
    if (invalidConfiguration) {
      console.warn(
        'TextLink: `to` and `visualOnly` props cannot be used together. Ignoring `to` prop.',
      );
    }
  }, [invalidConfiguration]);

  return (
    <Implementation
      css={[styles.root, disabled && styles.disabled]}
      onClick={!disabled && onClick}
      to={!disabled && (to || '#')}
      role={role}
      {...props}
    >
      {!!Icon && <Icon css={styles.icon} size={iconSize} />}
      <div css={styles.textContainer}>
        <span css={[styles.text, { fontSize }, disabled && styles.disabled]}>
          {children}
        </span>
      </div>
    </Implementation>
  );
};

const DummyLink = ({ onClick, className, children }: LinkProps) => (
  <span onClick={onClick} className={className}>
    {children}
  </span>
);

const styles = {
  root: css({
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(2),
    cursor: 'pointer',
    lineHeight: 1.4,
    position: 'relative',
  }),
  textContainer: css({
    color: colors.Glue_Ink00,
    position: 'relative',
    '&::before': {
      position: 'absolute',
      zIndex: 0,
      content: '""',
      transformOrigin: 'bottom',
      backgroundColor: colors.Glue_Mint00,
      bottom: -8,
      left: 0,
      right: 0,
      height: 2,
      transition: 'all 200ms ease-out',
      borderRadius: 2,
    },

    '*:hover > &::before, *:focus > &:before': {
      left: -8,
      right: -8,
      height: 'calc(100% + 16px)',
    },
  }),
  text: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    fontStyle: 'normal',
    fontWeight: 800,
    transition: 'all 200ms ease-out',
    color: 'inherit',
    position: 'relative',
  }),
  disabled: css({
    cursor: 'default',
    '& svg': {
      color: colors.Glue_Ink20,
    },
    '& > div': {
      color: colors.Glue_Ink20,

      '&:before': {
        backgroundColor: 'transparent',
      },
    },
  }),
  icon: css({
    color: colors.Glue_Ink00,
  }),
};

export default TextLink;
