// @ts-strict-ignore
import { Box, Button, Typography } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { useFinchConnect } from 'react-finch-connect';
import { useHistory } from 'react-router-dom';
import EmptyButton from 'components/EmptyButton';
import { HrisIntegration, Organization, User, ViewerFragmentFragment } from 'types';
import { getFinchConnectOptions } from 'lib/finch';
import { gql } from '@apollo/client';
import { client } from 'gql/client';
import { DateTime } from 'luxon';
import humanizeDuration from 'humanize-duration';
import _ from 'lodash';
import { useState } from 'react';
import { Paths } from 'Routes';
import theme from 'theme';
import DataIcon from 'components/icons/DataIcon';
import RefreshCycle from 'components/icons/RefreshCycle';

const EMPTY_HRIS =
  'Have your HR admin connect your internal system to keep your team members updated and organized.';
const HAS_HRIS =
  'Glue syncs with your HR system to keep your team member information updated and organized';

interface HrisProps {
  org: Organization;
  viewer: ViewerFragmentFragment;
  classes: ClassNameMap;
  pending: User[];
}

export const Hris = ({ org, viewer, classes, pending }: HrisProps) => {
  return (
    <Box className={classes.mCard}>
      <Box mb={2} className={classes.mCardIcon}>
        <DataIcon color={theme.palette.secondary[600]} height={24} width={24} />
      </Box>
      <Box mb={2}>
        <Typography className={classes.mCardLabel}>Org mapping</Typography>
      </Box>
      <Box>
        <Typography variant='h4' className={classes.mCardTitle}>
          HR system
        </Typography>
      </Box>
      {org.isHrisConnected && (
        <Box className={classes.mCardHelperText}>
          <HasHris
            classes={classes}
            integrations={org.hrisIntegrations}
            org={org}
            viewer={viewer}
            pending={pending}
          />
        </Box>
      )}
      {!org.isHrisConnected && <EmptyHris org={org} viewer={viewer} classes={classes} />}
    </Box>
  );
};

interface HasHrisProps {
  integrations: HrisIntegration[];
  classes: ClassNameMap;
  org: Organization;
  viewer: ViewerFragmentFragment;
  pending: User[];
}

const HasHris = ({ integrations, classes, org, viewer, pending }: HasHrisProps) => {
  const history = useHistory();
  const { open } = useFinchConnect(getFinchConnectOptions(org.id, viewer.id));
  return (
    <Box>
      <Typography style={{ marginBottom: theme.spacing(4) }} className={classes.subtitle}>
        {HAS_HRIS}
      </Typography>
      {!_.isEmpty(pending) && (
        <Box className={classes.hrisIssuesBlock}>
          <Typography className={classes.hrisIssuesBlockHeadline}>
            Help us get a clear picture of your organization
          </Typography>
          <Typography className={classes.hrisIssuesBlockCopy}>
            There are issues with some of your members' details
          </Typography>
          <Button
            className={classes.hrisIssuesBlockButton}
            onClick={() => {
              history.push(Paths.AWAITING);
            }}
          >
            <Typography>Correct issues</Typography>
          </Button>
        </Box>
      )}
      <Box style={{ marginBottom: theme.spacing(8) }}>
        {integrations.map((integration) => {
          return <HrisConnection classes={classes} hrisIntegration={integration} />;
        })}
      </Box>
      <EmptyButton onClick={open}>Connect a different account</EmptyButton>
    </Box>
  );
};

const EmptyHris = ({
  classes,
  org,
  viewer,
}: {
  org: Organization;
  viewer: ViewerFragmentFragment;
  classes: ClassNameMap;
}) => {
  const { open } = useFinchConnect(getFinchConnectOptions(org.id, viewer.id));
  return (
    <Box className={classes.integration}>
      <Typography style={{ marginBottom: '16px' }} className={classes.subtitle}>
        {EMPTY_HRIS}
      </Typography>
      <EmptyButton onClick={open}>Connect your HR system</EmptyButton>
    </Box>
  );
};

const HrisConnection = ({
  hrisIntegration,
  classes,
}: {
  hrisIntegration: HrisIntegration;
  classes: ClassNameMap;
}) => {
  const [lastUpdated, setLastUpdated] = useState(new Date(hrisIntegration.lastUpdated));
  return (
    <Box>
      <Box className={classes.statusRow}>
        <Typography className={classes.statusRowCopy}>Status</Typography>
        <Typography className={classes.statusRowPill}>Active</Typography>
      </Box>
      <Box className={classes.detailsBlock}>
        <Box className={classes.detailsBlockCell}>
          <Typography className={classes.mCardLabel}>Connected to</Typography>
          <Typography className={classes.detailsBlockText}>
            <span>{hrisIntegration.providerName}</span>
          </Typography>
        </Box>
        {hrisIntegration.lastUpdated && (
          <Box className={classes.detailsBlockCell}>
            <Typography className={classes.mCardLabel}>Last updated</Typography>
            <Typography className={classes.detailsBlockText}>
              {getLastUpdated(lastUpdated)}
            </Typography>
          </Box>
        )}
      </Box>
      <Button
        onClick={async () => {
          await client.mutate({
            mutation: refreshHrisIntegrationQuery,
            variables: {
              integrationId: hrisIntegration.id,
            },
            refetchQueries: ['searchOrgUsers'],
            awaitRefetchQueries: true,
          });
          setLastUpdated(new Date());
        }}
        className={classes.refresh}
      >
        <RefreshCycle color={theme.palette.primary.main} height={20} width={20} />
        <span>Refresh HR connection</span>
      </Button>
    </Box>
  );
};

const getLastUpdated = (date: Date): string => {
  const dateTime = DateTime.fromJSDate(date);
  const now = DateTime.now();
  return (
    humanizeDuration(now.diff(dateTime).toMillis(), {
      round: true,
    }) + ' ago'
  );
};

const refreshHrisIntegrationQuery = gql`
  mutation refreshSingleHrisIntegration($integrationId: ID!) {
    refreshSingleHris(integrationId: $integrationId) {
      id
    }
  }
`;
