import React, { createContext, useState } from 'react';
import { SelfServeState } from 'types/selfServeFlow';

export const SelfServeContext = createContext<{
  state: SelfServeState;
  setState: React.Dispatch<React.SetStateAction<SelfServeState>>;
} | null>(null);

export const SelfServeProvider = ({ children }) => {
  const [state, setState] = useState<SelfServeState>({});
  return (
    <SelfServeContext.Provider value={{ state, setState }}>
      {children}
    </SelfServeContext.Provider>
  );
};
