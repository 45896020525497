import { Typography } from '@material-ui/core';
import RegistrationStepWrapper from 'components/Checkout/styling/RegistrationStepWrapper';
import mixpanel from 'mixpanel-browser';
import { useHistory } from 'react-router-dom';
import { Paths } from 'Routes';
const WrongEvent = ({ context, teamEvent }) => {
  const history = useHistory();

  return (
    <RegistrationStepWrapper
      {...context}
      label={`Wrong event link?`}
      nextButtonProps={{
        label: 'View upcoming events',
        onClick: () => {
          mixpanel.track('upcoming events clicked', { source: 'registration' });
          history.push(Paths.EVENTS);
        },
      }}
      teamEvent={teamEvent}
    >
      <div>
        <div style={{ marginBottom: '1rem' }}>
          <Typography>Looks like this event has already taken place.</Typography>
        </div>
        <div style={{ marginBottom: '1rem' }}>
          <Typography>
            If you've already registered for an event, you can find it in your upcoming
            events list on your Glue homepage by clicking the button below. Otherwise, try
            searching your inbox for our short URL: "mstry.co”.
          </Typography>
        </div>
      </div>
    </RegistrationStepWrapper>
  );
};

export default WrongEvent;
