import { Box, Typography } from '@material-ui/core';
import { goalToString } from 'lib/helpers/goals';

import theme from 'theme';
import { EventGoal } from 'types';
import GoalIcon from './GoalIcon';

interface GoalInfo {
  Icon: () => JSX.Element;
  label: string;
}

const getGoalInfo = ({
  disabled,
  goal,
  size,
}: {
  disabled: boolean;
  goal: EventGoal;
  size: number;
}): GoalInfo => {
  return {
    Icon: () => <GoalIcon disabled={disabled} goal={goal} size={size} />,
    label: goalToString(goal),
  };
};

const GoalSection = ({
  eventGoals,
  fontSize = '1rem',
  iconSize = 20,
  labeled = false,
  disabled = false,
  hovered = false,
}: {
  eventGoals: { goal: EventGoal }[];
  fontSize?: string;
  iconSize?: number;
  labeled?: boolean;
  disabled?: boolean;
  hovered?: boolean;
}) => {
  if (!(eventGoals?.length > 0)) {
    return null;
  }
  const goalInfo = eventGoals.map((goal) =>
    getGoalInfo({ ...goal, size: iconSize, disabled }),
  );
  const { Icon } = goalInfo[0];

  return (
    <Box display='flex' flexDirection='row' alignItems='center'>
      <Icon />
      {labeled && (
        <Typography
          css={{
            fontWeight: 'bold',
            marginLeft: theme.spacing(2),
          }}
        >
          Connection focus:
        </Typography>
      )}
      <Typography
        css={{
          marginLeft: theme.spacing(2),
          fontSize,
          fontWeight: hovered ? 'bold' : 'normal',
        }}
      >
        {goalInfo.map(({ label }) => label).join(', ')}
      </Typography>
    </Box>
  );
};

export default GoalSection;
