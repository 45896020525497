import { makeStyles } from '@material-ui/core/styles';
import ChevronRight from 'components/icons/ChevronRight';
import SSFMessageWidgetWrapper from 'components/SSFMessageWidgetWrapper';
import React, { CSSProperties } from 'react';
import theme from 'theme';

export interface UpSellProps {
  icon: React.ReactNode;
  illustration: React.ReactNode;
  description: React.ReactNode;
  illustrationStyle?: CSSProperties;
  linkTo: string;
}

const useStyles = makeStyles({
  upSellWrapper: {
    borderTopRightRadius: '1rem',
    borderTopLeftRadius: '1rem',
    background: theme.palette.secondary[100],
  },
  upSellTinyHeadline: {
    display: 'flex',
    padding: '32px 32px 0px 32px',
    width: 300,
    height: 32,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24,
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 12,
    letterSpacing: '.5px',
    textTransform: 'uppercase',
    position: 'absolute',
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  upSellContent: {
    fontSize: 24,
    display: 'flex',
    padding: '60px 32px 80px',
    flexDirection: 'column',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      padding: '24px 32px',
      textAlign: 'center',
    },
  },
  upSellIcon: {
    width: 32,
    height: 32,
    marginBottom: 16,
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      top: '-24px',
      left: 'calc(50% - 24px)',
      zIndex: 1,
      width: 48,
      height: 48,
      borderRadius: '48px 48px 0 0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: theme.palette.secondary[100],
    },
  },
  upSellIllustation: {
    position: 'absolute',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  upSellFooter: {
    display: 'flex',
    flexDirection: 'column',
  },
  upSellFooterTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  upSellFooterLink: {
    fontSize: 14,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  upSellFooterText: {
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: 'bold',
    display: 'none',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
});

const UpSellFooter = ({ linkTo }) => {
  const classes = useStyles();
  return (
    <a className={classes.upSellFooter} href={linkTo} target='_blank' rel='noreferrer'>
      <span className={classes.upSellFooterTitle}>Let's chat</span>
      <span className={classes.upSellFooterLink}>Get a demo of Glue Subscription</span>
      <span className={classes.upSellFooterText}>
        Glue Subscription can help, let's chat
      </span>
    </a>
  );
};

const UpSell = ({
  icon,
  illustration,
  description,
  illustrationStyle,
  linkTo,
}: UpSellProps) => {
  const classes = useStyles();
  return (
    <SSFMessageWidgetWrapper footer={<UpSellFooter linkTo={linkTo} />}>
      <div className={classes.upSellWrapper}>
        <div className={classes.upSellTinyHeadline}>
          <span>GLUE SUBSCRIPTION</span>
          <ChevronRight size={16} />
        </div>
        <div className={classes.upSellContent}>
          <div className={classes.upSellIcon}>{icon}</div>
          {description}
        </div>
        <div className={classes.upSellIllustation} style={illustrationStyle}>
          {illustration}
        </div>
      </div>
    </SSFMessageWidgetWrapper>
  );
};

export default UpSell;
