import React, { useCallback } from 'react';
import { Mask } from 'string-mask-jedi';

const isHTMLInput = (x: any): x is HTMLInputElement | HTMLTextAreaElement => {
  return typeof x.value === 'string';
};

const useMask = <T = HTMLInputElement>(
  mask: Mask,
  changeHandler: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void,
  currentValue?: string,
): {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  ref: React.RefObject<T>;
} => {
  const ref = React.useRef<T>(null);

  const syntheticChangeHandler = useCallback(
    (value: string) => ({ target: { value, validity: { valid: true } } }),
    [],
  );

  const initialMaskValue = React.useMemo(
    () => mask.run(currentValue ? currentValue.replace(/^\+1/, '') : ''),
    [currentValue],
  );

  const [cursor, setCursor] = React.useState(initialMaskValue.cursor);

  const onChange = React.useCallback(
    (event) => {
      const { currentTarget } = event;
      const { value, selectionStart } = currentTarget;

      if (typeof selectionStart === 'number') {
        const { cursor, value: newValue } = mask.run(value, selectionStart);
        setCursor(cursor);
        changeHandler(syntheticChangeHandler(newValue) as any);
      }
    },
    [changeHandler, mask],
  );

  React.useEffect(() => {
    const { current } = ref;

    if (current && isHTMLInput(current)) {
      current.selectionStart = cursor;
      current.selectionEnd = cursor;
    }
  }, [cursor]);

  return { onChange, ref, value: initialMaskValue.value };
};

export default useMask;
