// @ts-strict-ignore
import { useQuery } from '@apollo/client';
import { Box, ButtonBase, Drawer, Snackbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { colors } from '@mysteryco/design/src';
import { Loading } from 'components/core';
import FlatButton from 'components/core/FlatButton';
import AutocompleteInput from 'components/Forms/AutocompleteInput';
import LabeledInput from 'components/Forms/LabeledInput';
import TextInput from 'components/Forms/TextInput';
import CloseX from 'components/icons/CloseX';
import TagSelect from 'components/SelectDropdown/TagSelect';
import TeamSelect from 'components/SelectDropdown/TeamSelect';
import { Formik } from 'formik';
import { getOrganizationRoles } from 'gql/queries';
import { DateTime, Info as DateInfo } from 'luxon';
import { ClassNameMap } from 'notistack';
import { useEffect, useState } from 'react';
import theme from 'theme';
import {
  MemberFragmentFragment,
  OrganizationRole,
  Team,
  User,
  UserTag,
  UserTagType,
  useUpdateUserMutation,
  useUpdateUserTagsMutation,
} from 'types';
import { isContract } from 'utils/customerTypeUtils';
import { displayTeamName } from 'utils/teamUtil';
import * as yup from 'yup';
import { TeamPageOrganization } from '.';
import RolePicker from './RolePicker';

interface Props {
  isShowing: boolean;
  isArchivedPage: boolean;
  setIsShowing(isShowing: boolean): void;
  member: MemberFragmentFragment;
  organization: TeamPageOrganization;
  setSelectedMember: (member: MemberFragmentFragment) => void;
  setSelectedMembers: (members: Set<MemberFragmentFragment>) => void;
}

interface EditTeamMemberFormProps {
  member: MemberFragmentFragment;
  organization: TeamPageOrganization;
  roles: OrganizationRole[];
  userTagIds: string[];
  setUserTagIds: (userTagIds: string[]) => void;
  teamId: string[];
  setTeamId: (teamId: string[]) => void;
  isArchivedPage?: boolean;
  setIsShowing: (value: boolean) => void;
  setSelectedMembers: (members: Set<MemberFragmentFragment>) => void;
  snackbarAlert: (message: string) => void;
  onClickSave: (values: any) => Promise<void>;
  classes?: ClassNameMap;
  disabled?: boolean;
  [key: string]: any;
}

const validationSchema = yup.object().shape(
  {
    firstName: yup
      .string()
      .required('First name is required')
      .typeError('First name is requried'),
    lastName: yup
      .string()
      .required('Last name is required')
      .typeError('Last name is requried'),
    companyRole: yup
      .string()
      .required('Job title is required')
      .typeError('Job title is required'),
    startMonth: yup.number().when('startYear', {
      is: (value) => value || value === 0,
      then: (schema) =>
        schema.required('Start month is required').min(1, 'Start month is required'),
    }),
    startYear: yup
      .number()
      .when('startMonth', {
        is: (value) => value > 0,
        then: (schema) => schema.required('Start year is required'),
      })
      .typeError('Start year must be a valid year'),
  },
  [['startMonth', 'startYear']],
);

const EditDetails = ({
  classes,
  member,
  organization,
  teamId,
  setTeamId,
  isHrisConnected,
  isContract,
  disabled = false,
  errors,
  values,
  setFieldValue,
  handleChange,
}: {
  classes: ClassNameMap;
  member: MemberFragmentFragment;
  organization: TeamPageOrganization;
  teamId: string[];
  setTeamId: (teamId: string[]) => void;
  isHrisConnected: boolean;
  isContract: boolean;
  disabled?: boolean;
  errors: any;
  values: any;
  setFieldValue: any;
  handleChange: any;
}) => {
  const [monthInput, setMonthInput] = useState('');
  const months = DateInfo.months();
  const showDateFields = !(isHrisConnected && !values.startYear && values.startMonth < 0);

  const teamName = displayTeamName(member?.teams?.[0] as Team);

  return (
    <Box>
      <Typography className={classes.header}>Details</Typography>
      <Box className={classes.formDetails}>
        <Box className={classes.formRow}>
          <LabeledInput
            id='firstName'
            label='First name'
            error={errors.firstName}
            fullWidth
          >
            <TextInput
              id='firstName'
              value={values.firstName}
              error={!!errors.firstName}
              onChange={handleChange}
              disabled={disabled}
              fullWidth
            />
          </LabeledInput>
          <LabeledInput id='lastName' label='Last name' error={errors.lastName} fullWidth>
            <TextInput
              id='lastName'
              value={values.lastName}
              error={!!errors.lastName}
              onChange={handleChange}
              disabled={disabled}
              fullWidth
            />
          </LabeledInput>
        </Box>
        <LabeledInput id='email' label='Email'>
          <TextInput
            id='email'
            value={values.email}
            hidden={!values.email}
            readOnly
            fullWidth
          />
        </LabeledInput>
        <LabeledInput id='companyRole' label='Title' error={errors.companyRole}>
          <TextInput
            id='companyRole'
            value={values.companyRole}
            error={!!errors.companyRole}
            helperText={errors.companyRole}
            disabled={disabled}
            readOnly={isHrisConnected}
            hidden={isHrisConnected && !values.companyRole}
            onChange={handleChange}
            fullWidth
          />
        </LabeledInput>
        {showDateFields &&
          (!isHrisConnected ? (
            <Box className={classes.formRow}>
              <LabeledInput
                label='Start date'
                error={errors.startMonth || errors.startYear}
                fullWidth
              >
                <AutocompleteInput
                  id='startMonth'
                  aria-label='Start month'
                  options={months}
                  value={values.startMonth > 0 ? months[values.startMonth - 1] : null}
                  onChange={(_, newValue) => {
                    setFieldValue('startMonth', months.indexOf(newValue) + 1);
                  }}
                  inputValue={monthInput}
                  onInputChange={(_, newValue) => {
                    setMonthInput(newValue);
                  }}
                  disabled={disabled}
                  fullWidth
                />
              </LabeledInput>
              <LabeledInput id='startYear' hiddenLabel fullWidth>
                <TextInput
                  id='startYear'
                  aria-label='Start year'
                  value={values.startYear}
                  onChange={handleChange}
                  error={!!errors.startYear}
                  disabled={disabled}
                  fullWidth
                />
              </LabeledInput>
            </Box>
          ) : (
            <LabeledInput id='startDate' label='Start date'>
              <TextInput
                id='startDate'
                value={`${months[values.startMonth - 1]} ${values.startYear}`}
                readOnly
              />
            </LabeledInput>
          ))}
        {isContract && (
          <Box>
            <LabeledInput label='Team' fullWidth>
              {!isHrisConnected && (
                <TeamSelect
                  organization={organization}
                  value={teamId}
                  onChange={(_, newValue) => setTeamId(newValue)}
                  userIds={[member?.id]}
                  disabled={disabled}
                />
              )}
              {isHrisConnected && !!teamId && <TextInput value={teamName} readOnly />}
            </LabeledInput>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const EditTags = ({
  classes,
  disabled,
  isContract,
  organization,
  userTagIds,
  setUserTagIds,
}: {
  classes: ClassNameMap;
  organization: TeamPageOrganization;
  userTagIds: string[];
  setUserTagIds: (userTagIds: string[]) => void;
  disabled: boolean;
  isContract: boolean;
}) => {
  return (
    isContract && (
      <>
        <Typography className={classes.header}>Tags</Typography>
        <Typography className={classes.subtitle}>Individual's tags</Typography>
        <Box>
          <TagSelect
            organization={organization}
            value={userTagIds}
            onChange={(_, newValues) => setUserTagIds(newValues)}
            multiple
            disabled={disabled}
          />
        </Box>
      </>
    )
  );
};

const EditTeamMemberHeader = ({
  classes,
  onClose = () => null,
}: {
  classes: ClassNameMap;
  onClose: (event) => void;
}) => {
  return (
    <Box className={classes.headerContainer}>
      <Typography className={classes.title}>Edit Member</Typography>
      <ButtonBase onClick={onClose}>
        <CloseX className={classes.close} />
      </ButtonBase>
    </Box>
  );
};

const EditTeamMemberForm = ({ classes, ...props }: EditTeamMemberFormProps) => {
  const isHrisConnected = props.organization?.isHrisConnected;
  const isContractCustomer = isContract(props.organization?.customerType);
  const disabled = props.isArchivedPage || props.disabled;

  return (
    props.member && (
      <>
        <EditTeamMemberHeader
          classes={classes}
          onClose={() => props.setIsShowing(false)}
        />
        <div>
          <Formik
            initialValues={{
              organizationRoleId: props.member.organizationRole?.id,
              firstName: props.member.firstName ?? '',
              lastName: props.member.lastName ?? '',
              email: props.member.email ?? '',
              companyRole: props.member.companyRole ?? '',
              startMonth: props.member.startDate
                ? DateTime.fromISO(props.member.startDate).month
                : '',
              startYear: props.member.startDate
                ? DateTime.fromISO(props.member.startDate).year
                : '',
              team: props.member.teams?.[0]?.id,
            }}
            validateOnMount
            validationSchema={validationSchema}
            onSubmit={props.onClickSave}
          >
            {({ values, errors, isValid, handleChange, handleSubmit, setFieldValue }) => {
              return (
                <form onSubmit={handleSubmit} className={classes.formContainer}>
                  <RolePicker
                    onChange={(e) => {
                      setFieldValue('organizationRoleId', e);
                    }}
                    value={values.organizationRoleId}
                    {...props}
                    disabled={disabled}
                  />
                  <Box>
                    <EditDetails
                      classes={classes}
                      errors={errors}
                      values={values}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      isHrisConnected={isHrisConnected}
                      isContract={isContractCustomer}
                      {...props}
                      disabled={disabled}
                    />
                  </Box>
                  <Box>
                    <EditTags
                      classes={classes}
                      isContract={isContractCustomer}
                      {...props}
                      disabled={disabled}
                    />
                  </Box>
                  <Box>
                    <FlatButton type='submit' fullWidth disabled={disabled || !isValid}>
                      Save changes
                    </FlatButton>
                  </Box>
                </form>
              );
            }}
          </Formik>
        </div>
      </>
    )
  );
};

const loadTagIds = (tags: UserTag[]): string[] => {
  return tags?.filter((tag) => tag.type === UserTagType.Manual).map((tag) => tag.id);
};

const loadTeam = (teams: Team[]): string[] => {
  const team = teams?.[0]?.id;
  return team ? [team] : [];
};

const EditTeamMember = ({
  member,
  organization,
  isShowing,
  isArchivedPage,
  setIsShowing,
  setSelectedMember,
  setSelectedMembers,
}: Props) => {
  const classes = useStyles();
  const [roles, setRoles] = useState([]);
  const [status, setStatus] = useState(member ? member.accountStatus : '');
  const [userTagIds, setUserTagIds] = useState(loadTagIds(member?.tags) || []);
  const [teamId, setTeamId] = useState(loadTeam(member?.teams as Team[]));
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>(null);
  const [submitting, setSubmitting] = useState(false);

  const [updateTags] = useUpdateUserTagsMutation();
  const [updateUser] = useUpdateUserMutation();

  const { data, loading } = useQuery(getOrganizationRoles);

  useEffect(() => {
    if (!data) return;
    setRoles(data.roles);
  }, [data]);

  useEffect(() => {
    if (!member) return;
    setUserTagIds(loadTagIds(member.tags));
    setTeamId(loadTeam(member.teams as Team[]));
    setStatus(member.accountStatus);
  }, [member]);

  const snackbarAlert = (message: string) => {
    setAlertMessage(message);
    setShowSnackbar(true);
  };

  const saveMember = async (memberDetails: Partial<User>) => {
    setSubmitting(true);
    const tagIds = userTagIds;
    await updateTags({ variables: { userId: member.id, tagIds } });
    await updateUser({
      variables: { id: member.id, ...memberDetails },
      refetchQueries: ['searchOrgUsers'],
    });
    setSubmitting(false);
  };

  const processMemberDetails = ({
    startMonth,
    startYear,
    ...updatedMember
  }): Partial<User> => {
    let values = {};
    if (!organization.isHrisConnected) {
      values = { ...updatedMember };
      const startDate =
        startMonth >= 0 && startYear
          ? DateTime.utc(parseInt(startYear), startMonth).toUTC()
          : null;
      if (startDate) values['startDate'] = startDate;
      const team = teamId?.[0];
      if (team) values['team'] = team;
    } else {
      values = {
        firstName: updatedMember.firstName,
        lastName: updatedMember.lastName,
        organizationRoleId: updatedMember.organizationRoleId,
      };
    }
    return values;
  };

  const handleSave = async (values) => {
    const memberDetails = processMemberDetails(values);
    await saveMember(memberDetails);
    snackbarAlert('Member updated!');
    setIsShowing(false);
    setSelectedMembers(new Set());
  };

  const orgTags = organization.userTags;
  const orgTeams = organization.teams;

  return (
    <>
      <Drawer
        className={classes.drawer}
        open={isShowing && !loading}
        anchor='right'
        onClose={() => {
          setIsShowing(false);
          setSelectedMember(null);
        }}
      >
        <div className={classes.container}>
          {!member && <Loading />}
          {member && (
            <EditTeamMemberForm
              member={member}
              organization={organization}
              roles={roles}
              status={status}
              orgTags={orgTags}
              orgTeams={orgTeams}
              userTagIds={userTagIds}
              setUserTagIds={setUserTagIds}
              teamId={teamId}
              setTeamId={setTeamId}
              setIsShowing={setIsShowing}
              setSelectedMembers={setSelectedMembers}
              onClickSave={handleSave}
              snackbarAlert={snackbarAlert}
              isArchivedPage={isArchivedPage}
              classes={classes}
              disabled={submitting}
            />
          )}
        </div>
      </Drawer>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity='success'>{alertMessage}</Alert>
      </Snackbar>
    </>
  );
};

const useStyles = makeStyles({
  drawer: {
    overflowY: 'scroll',
    '& .MuiDrawer-paperAnchorRight': {
      width: '30rem',
    },
  },
  chip: {
    width: '100%',
    display: 'flex',
    justfiyContent: 'flex-end',
    '& .MuiChip-label': {
      flex: 1,
    },
  },
  container: {
    padding: '1.5rem',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8),
  },
  row: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    width: '100%',
  },
  formDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    '& .Mui-disabled > .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  formRow: {
    display: 'flex',
    gap: theme.spacing(4),
  },
  title: {
    fontWeight: 500,
    fontSize: 30,
    lineHeight: 1.07,
    color: colors.Midnight,
    paddingBottom: theme.spacing(8),
  },
  header: {
    fontWeight: 500,
    fontSize: '1.125rem',
    lineHeight: 1.56,
    color: colors.Midnight,
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '.75rem',
    lineHeight: 1.33,
    color: colors.DarkGray,
  },
  close: {
    color: colors.MediumGray,
    transition: 'color 300ms ease-in-out',
    '&:hover': {
      color: colors.Main,
    },
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
});

export default EditTeamMember;
