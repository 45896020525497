import { IconProps } from '@mysteryco/design/icons';
import Coins01 from '@mysteryco/design/icons/Coins01';
import Ticket01 from '@mysteryco/design/icons/Ticket01';
import { ContractUnitType } from 'types';

export const CONTRACT_UNIT_PHRASE_LOOKUP = {
  [ContractUnitType.Cents]: 'dollar',
  [ContractUnitType.Credits]: 'credit',
  [ContractUnitType.Event]: 'event credit',
  [ContractUnitType.Recurring]: 'seat',
};

const CONTRACT_UNIT_FORMATS = {
  [ContractUnitType.Cents]: new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }),
  [ContractUnitType.Credits]: new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2, //Only show two decimal places at maximum
  }),
  [ContractUnitType.Event]: new Intl.NumberFormat('en-US'), //Basic number
  [ContractUnitType.Recurring]: new Intl.NumberFormat('en-US'), //Basic number
};

export const CONTRACT_UNIT_FORMAT_LOOKUP = {
  [ContractUnitType.Cents]: (cents: number) =>
    CONTRACT_UNIT_FORMATS[ContractUnitType.Cents].format(cents / 100),
  [ContractUnitType.Credits]: (credits: number) =>
    CONTRACT_UNIT_FORMATS[ContractUnitType.Credits].format(credits / 100),
  [ContractUnitType.Event]: (event: number) =>
    CONTRACT_UNIT_FORMATS[ContractUnitType.Event].format(event),
  [ContractUnitType.Recurring]: (seats: number) =>
    CONTRACT_UNIT_FORMATS[ContractUnitType.Recurring].format(seats),
};

const CONTRACT_UNIT_ICON = {
  [ContractUnitType.Cents]: Coins01,
  [ContractUnitType.Credits]: Coins01,
  [ContractUnitType.Event]: Coins01,
  [ContractUnitType.Recurring]: Ticket01,
};

const CONTRACT_HAS_PREMIUM_UNIT = {
  [ContractUnitType.Cents]: false,
  [ContractUnitType.Credits]: false,
  [ContractUnitType.Event]: true,
  [ContractUnitType.Recurring]: true,
};

export const getContractUnit = (type: ContractUnitType): string => {
  return `${CONTRACT_UNIT_PHRASE_LOOKUP[type]}s`;
};

export const formatContractUnit = (type: ContractUnitType, value: number): string => {
  return CONTRACT_UNIT_FORMAT_LOOKUP[type](value);
};

export const getContractIcon = (
  type: ContractUnitType,
): React.ComponentType<IconProps> => {
  return CONTRACT_UNIT_ICON[type];
};

export const contractHasPremiumUnits = (type: ContractUnitType): boolean => {
  return CONTRACT_HAS_PREMIUM_UNIT[type];
};
