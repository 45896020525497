import NotSetUp from 'glue/scenes/Explore/Pulse/NotSetUp';
import mixpanel from 'mixpanel-browser';

const PULSE_BETA_URL = 'https://go.glue.co/pulse-beta';

const PulseUpsell = () => {
  return (
    <NotSetUp
      ctaLink={PULSE_BETA_URL}
      ctaText='Join the BETA'
      onClickCta={(buttonCopy) => {
        mixpanel.track('pls-upsell-cta', {
          eventType: 'click',
          buttonCopy,
        });
      }}
    />
  );
};

export default PulseUpsell;
