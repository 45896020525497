import { makeStyles } from '@material-ui/styles';
import { colors } from '@mysteryco/design';
import { HTMLAttributes } from 'react';
import theme from 'theme';

export interface PremiumExperienceBannerProps {}

const useStyles = makeStyles({
  root: {
    background: '#f7f1ff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 12px',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  label: {
    color: colors.Dusk,
    fontSize: 12,
    fontWeight: 700,
    textTransform: 'uppercase',
    letterSpacing: '1px',
  },
  price: {
    color: colors.Black,
    flex: '1 1 0',
    textAlign: 'right',
  },
});

export function PremiumExperienceBanner(props: HTMLAttributes<HTMLDivElement>) {
  const classes = useStyles();

  return (
    <div className={classes.root} {...props}>
      <span className={classes.label}>Plus Upgrade Fee</span>
    </div>
  );
}
