// @ts-strict-ignore
import { Avatar, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SSFMessageWidgetWrapper from 'components/SSFMessageWidgetWrapper';
import React from 'react';
import theme from 'theme';

export interface QuoteBoxProps {
  quote: React.ReactNode;
  author: string;
  title: string;
  company: string;
  avatarImage: string;
  color: 'primary' | 'secondary';
}

const useStyles = makeStyles<Theme, Partial<QuoteBoxProps>>({
  upSellContent: ({ color }) => ({
    background: theme.palette[color][100],
    fontSize: 16,
    display: 'flex',
    padding: 32,
    flexDirection: 'column',
    fontWeight: 'bold',
    position: 'relative',
    borderTopLeftRadius: '1rem',
    borderTopRightRadius: '1rem',
    [theme.breakpoints.down('md')]: {
      padding: '24px 32px',
      textAlign: 'center',
    },
  }),
});

const useFooterStyles = makeStyles({
  quoteBoxName: {
    fontSize: 14,
  },
  quoteBoxTitle: {
    fontSize: 11,
  },
  quoteBoxCompany: {
    fontSize: 12,
    fontWeight: 'bold',
    width: '100%',
    textTransform: 'uppercase',
  },
});

const QuoteBoxFooter = ({ author, title, company, avatarImage }) => {
  const classes = useFooterStyles();
  return (
    <Box display='flex' justifyContent='center' alignItems='center'>
      <Avatar alt={author} src={avatarImage} />
      <Box display='flex' flexDirection='column' ml={4}>
        <span className={classes.quoteBoxName}>{author}</span>
        <span className={classes.quoteBoxTitle}>{title}</span>
        <span className={classes.quoteBoxCompany}>{company}</span>
      </Box>
    </Box>
  );
};

const QuoteBox = ({
  quote,
  author,
  title,
  company,
  color,
  avatarImage,
}: QuoteBoxProps) => {
  const classes = useStyles({ color });
  return (
    <SSFMessageWidgetWrapper
      color={color}
      footer={
        <QuoteBoxFooter
          avatarImage={avatarImage}
          author={author}
          title={title}
          company={company}
        />
      }
    >
      <div className={classes.upSellContent}>{quote}</div>
    </SSFMessageWidgetWrapper>
  );
};

export default QuoteBox;
