import { colors } from '@mysteryco/design/src';
import { FunctionComponent, ComponentClass } from 'react';
import { ContractUnitType } from 'types';
import BankNote01 from './BankNote01';
import Credit from './Credit';
import Ticket01 from './Ticket01';

type InternalIconProps = Omit<PricingUnitIconProps, 'unit'>;
type IconComponent =
  | FunctionComponent<InternalIconProps>
  | ComponentClass<InternalIconProps>;

const unitIcons: Record<ContractUnitType, IconComponent> = {
  [ContractUnitType.Recurring]: Ticket01,
  [ContractUnitType.Cents]: BankNote01,
  [ContractUnitType.Credits]: Credit,
  [ContractUnitType.Event]: Ticket01,
  [ContractUnitType.Unlimited]: Ticket01,
};

export interface PricingUnitIconProps {
  unit: ContractUnitType;
  className?: string;
  color?: string;
}

export function PricingUnitIcon({
  unit,
  color = colors.Purple700,
  ...rest
}: PricingUnitIconProps) {
  const Icon = unitIcons[unit];
  return <Icon color={color} {...rest} />;
}
