import { HTMLAttributes } from 'react';
import { css } from '@emotion/react';
import { colors } from '@mysteryco/design';
import theme from 'theme';

interface Props extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  description?: string;
  children?: React.ReactNode;
}

const PreferencesSection = ({ title, description, children, ...extraProps }: Props) => {
  return (
    <div {...extraProps} css={styles.root}>
      <div css={styles.left}>
        <div css={styles.title}>{title}</div>
        <div css={styles.description}>{description}</div>
      </div>
      <div css={styles.right}>{children}</div>
    </div>
  );
};

export const sectionStyles = {
  content: css({
    display: 'flex',
    flexDirection: 'column',
    padding: `0 ${theme.spacing(5)}`,
    gap: theme.spacing(7),
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  }),
  header: css({
    fontWeight: 900,
    fontSize: '12px',
    lineHeight: '120%',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
  }),
  label: css({
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '140%',
    color: colors.Coal_10,
  }),
};

const styles = {
  root: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: `${theme.spacing(12)} ${theme.spacing(5)}`,
    gap: theme.spacing(4),
    borderBottom: `1.5px solid ${colors.Coal_40}`,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: `${theme.spacing(8)} 0`,
      gap: theme.spacing(4),
    },
  }),
  left: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    gap: theme.spacing(2),
    flex: '0 0 40%',
  }),
  title: css({
    fontWeight: 800,
    fontSize: '18px',
    lineHeight: '140%',
    color: colors.Coal_10,
  }),
  description: css({
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '140%',
    color: colors.Coal_20,
  }),
  right: css({
    flex: '0 0 60%',
  }),
};

export default PreferencesSection;
