// @ts-strict-ignore
import { makeStyles } from '@material-ui/styles';
import { colors } from '@mysteryco/design';
import EditTeam, {
  isValidTeam,
  RenderTeam,
  teamFilterOptions,
} from 'components/Forms/EditTeam';
import { getOrganizationQuery } from 'gql/queries';
import { useEffect, useState } from 'react';
import { TeamPageOrganization } from 'scenes/Team';
import theme from 'theme';
import {
  Team,
  useCreateTeamMutation,
  useEditTeamNameMutation,
  useGetUserTeamsLazyQuery,
} from 'types';
import { defaultTeamName, displayTeamName, isTeamDisabled } from 'utils/teamUtil';
import OptionSelect from './OptionSelect';

const getValueAsTeam = (inputValue: string): Team => {
  const name = !inputValue || inputValue?.endsWith(`'s team`) ? '' : inputValue;
  return { name } as Team;
};

const renderTeamInput = (team: Team) => {
  return displayTeamName(team);
};

interface TeamSelectProps {
  organization: TeamPageOrganization;
  value?: string[];
  onChange?: (event?: any, newValue?: string[]) => void;
  userIds?: string[];
  disabled?: boolean;
}

const TeamSelect = ({
  organization,
  value = [],
  onChange = () => {},
  userIds,
  disabled = false,
  ...props
}: TeamSelectProps) => {
  const classes = useStyles();

  const orgId = organization.id;
  const orgTeams = (organization.teams || []) as Team[];

  const [selected, setSelected] = useState(value);
  const [renameTeam] = useEditTeamNameMutation();
  const [createTeam] = useCreateTeamMutation();
  const [getMembers, { data: userData, loading: usersLoading }] =
    useGetUserTeamsLazyQuery({
      variables: { orgId },
    });

  useEffect(() => {
    if (!value?.length) return;
    setSelected(value);
  }, [value]);

  useEffect(() => {
    if (!orgId) return;
    getMembers();
  }, [organization]);

  let members = [];
  if (userData && userData.orgUsersConnection.edges) {
    members = userData.orgUsersConnection.edges
      .map((edge) => edge.node)
      .filter((user) => !user?.managerOf?.length);
  }

  const handleChange = (event: any, newTeam: string[]) => {
    setSelected(newTeam);
    onChange(event, newTeam);
  };

  const handleSave = async (team: Team) => {
    if (team?.id) {
      const name = team?.name || defaultTeamName(team);
      await renameTeam({
        variables: { id: team?.id, name },
        refetchQueries: [{ query: getOrganizationQuery }],
      });
    } else if (team?.manager?.id && orgId) {
      const result = await createTeam({
        variables: { managerId: team.manager.id, orgId, teamName: team.name },
        refetchQueries: [{ query: getOrganizationQuery }],
        awaitRefetchQueries: true,
      });
      if (!userIds || userIds.includes(team.manager.id)) return;
      setSelected([result.data.createTeam.id]);
    }
  };

  const isDisabled = (team: Team) => {
    return userIds?.includes(team.manager.id) || isTeamDisabled(team);
  };

  const RenderTeamOption = (team: Team) => {
    return <RenderTeam team={team} classes={classes} />;
  };

  const RenderActiveTeam = (
    team: Team,
    thisOnChange: (event: any, newValue: Team) => void,
  ) => {
    return (
      <EditTeam
        team={team}
        teamOptions={orgTeams}
        onChange={thisOnChange}
        members={members}
        membersLoading={usersLoading}
      />
    );
  };

  return (
    <>
      <OptionSelect
        options={orgTeams}
        values={selected}
        onChange={handleChange}
        onSave={async (_, team) => handleSave(team as Team)}
        filterOptions={teamFilterOptions}
        getValueAsOption={getValueAsTeam}
        getOptionIsDisabled={isDisabled}
        renderOption={RenderTeamOption}
        renderActive={RenderActiveTeam}
        renderInput={renderTeamInput}
        isValid={(newTeam: Team) => isValidTeam(newTeam, orgTeams)}
        helperText='Select a team or create a new one'
        createPrompt={<div>Create a new team</div>}
        editOnCreate
        {...props}
        multiple={false}
        disabled={disabled}
      />
    </>
  );
};

const useStyles = makeStyles({
  teamOption: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(2)} 0`,
  },
  teamOptionName: {
    color: colors.Black,
    fontSize: '.875rem',
  },
  teamOptionManager: {
    color: colors.MediumGray,
    fontSize: '.75rem',
  },
});

export default TeamSelect;
