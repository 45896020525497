import { Box, Chip, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Black,
  Blue200,
  Blue500,
  Blue600,
  Green1000,
  Green200,
  Green600,
  Orange800,
  Orange600,
  Orange100,
  Main,
  White,
} from '@mysteryco/design/src/tokens/colors';
import classNames from 'classnames';
import InfoCircleLight from 'components/icons/InfoCircleLight';

export enum Score {
  High,
  Low,
  Average,
}

const ScorePill = ({ score, tooltip }: { score: Score; tooltip?: string }) => {
  const classes = useStyles();

  let labelText, scoreClass: string;
  switch (score) {
    case Score.High:
      labelText = 'Good';
      scoreClass = classes.highScore;
      break;
    case Score.Low:
      labelText = 'Poor';
      scoreClass = classes.lowScore;
      break;
    case Score.Average:
    default:
      labelText = 'Average';
      scoreClass = classes.averageScore;
  }

  const Label = () => (
    <Box className={classes.label}>
      {labelText}
      {tooltip && <InfoCircleLight />}
    </Box>
  );
  const StatusDot = () => <span className={classes.dot}></span>;

  return (
    <>
      <Tooltip title={tooltip || ''} arrow>
        <Chip
          icon={<StatusDot />}
          label={<Label />}
          size='small'
          className={classNames(
            classes.pill,
            scoreClass,
            tooltip ? classes.tooltipHover : '',
          )}
        />
      </Tooltip>
    </>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    dot: {
      display: 'inline-block',
      width: 8,
      height: 8,
      backgroundColor: Black,
      borderRadius: 4,
      marginLeft: 8,
    },
    pill: {
      fontSize: '.75rem',
    },
    highScore: {
      color: Green1000,
      backgroundColor: Green200,
      '& $dot': {
        backgroundColor: Green600,
      },
      '& path': {
        fill: Green1000,
      },
    },
    lowScore: {
      color: Orange800,
      backgroundColor: Orange100,
      '& $dot': {
        backgroundColor: Orange600,
      },
      '& path': {
        fill: Orange800,
      },
    },
    averageScore: {
      color: Blue600,
      backgroundColor: Blue200,
      '& $dot': {
        backgroundColor: Blue500,
      },
      '& path': {
        fill: Blue600,
      },
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
    tooltipHover: {
      '&:hover': {
        backgroundColor: Main,
        color: White,
        '& path': {
          fill: White,
        },
      },
    },
  };
});

export default ScorePill;
