import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import { TeamEventFragmentFragment, ViewerFragmentFragment } from 'types';
import { getEventDetails } from 'utils/state';
import EventCalendarImage from './EventCalendarImage';
import EventImage from './EventImage';

const TeamEventDetail = ({
  teamEvent,
  viewer,
}: {
  teamEvent: TeamEventFragmentFragment;
  viewer: ViewerFragmentFragment;
}) => {
  const classes = useStyles();

  const requestedFor = DateTime.fromISO(teamEvent?.requestedFor);
  const eventDate = requestedFor.toFormat('EEE MMMM d');
  const eventTime = requestedFor.toLocaleString({
    ...DateTime.TIME_WITH_SHORT_OFFSET,
    second: undefined,
  });
  const durationMins = teamEvent.virtualEvent?.durationMins || 60;
  const startTime = requestedFor.toLocaleString(DateTime.TIME_SIMPLE);
  const endTime = requestedFor.plus({ minutes: durationMins }).toLocaleString({
    ...DateTime.TIME_WITH_SHORT_OFFSET,
    second: undefined,
  });
  const { title, photoUrl } = getEventDetails({
    teamEvent,
    viewer,
    defaultTitle: teamEvent.title || '',
  });

  return (
    <Box className={classes.teamEventDetail}>
      <Box className={classes.teamEventImageContainer}>
        <EventImage photoUrl={photoUrl} title={title} />
      </Box>
      <Box className={classes.teamEventContent}>
        <Box className={classes.teamEventTitle}>
          <Typography className={classes.headerBodyText}>What</Typography>
          <Typography className={classes.titleText}>{title}</Typography>
        </Box>
        <Box className={classes.teamEventTime}>
          <Typography className={classes.headerBodyText}>When</Typography>
          <Box className={classes.teamEventCalendarDetails}>
            <EventCalendarImage dateTime={requestedFor} />
            <Box className={classes.teamEventCalendarContent}>
              <Typography className={classes.contentText}>
                {eventDate}, {eventTime}
              </Typography>
              <Typography className={classes.teamEventCalendarTime}>
                {startTime} to {endTime}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  headerBodyText: {
    fontSize: '12px',
    lineHeight: '20px',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: 'Reboto Mono, monospace',
  },
  teamEventDetail: {
    display: 'flex',
    flexDirection: 'row',
    border: `1px solid ${theme.palette.primary[300]}`,
    borderLeft: 'none',
    borderRight: 'none',
    backgroud: theme.palette.primary[50],
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  teamEventImageContainer: {
    flex: 1,
    padding: '16px 24px',
    [theme.breakpoints.up('md')]: {
      borderRight: `1px solid ${theme.palette.primary[300]}`,
    },
    [theme.breakpoints.down('sm')]: {
      borderBottom: `1px solid ${theme.palette.primary[300]}`,
    },
  },
  teamEventContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    fontSize: '12px',
    fontFamily: 'Reboto Mono, monospace',
    lineHeight: '20px',
    [theme.breakpoints.up('md')]: {
      width: `calc(50% - 32px)`,
    },
  },
  titleText: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    overflowWrap: 'break-word',
  },
  contentText: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 500,
  },
  teamEventTitle: {
    padding: '16px 24px',
    fontSize: '14px',
    lineHeight: '20px',
    borderBottom: `1px solid ${theme.palette.primary[300]}`,
  },
  teamEventTime: {
    padding: '16px 24px ',
  },
  teamEventCalendarDetails: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
  },
  teamEventCalendarImage: {
    marginRight: '16px',
  },
  teamEventCalendarContent: {
    flex: 1,
    fontSize: '14px',
    lineHeight: '20px',
  },
  teamEventCalendarTime: {
    display: 'block',
    margin: '0 0 4px',
    fontSize: '12px',
    color: theme.palette.text.secondary,
  },
  teamEventAddToCalendar: {
    fontSize: '12px',
    textDecoration: 'underline',
    color: theme.palette.text.secondary,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'none',
    },
  },
}));

export default TeamEventDetail;
