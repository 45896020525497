import {
  COLORS,
  TrendDownArrowPath,
  TrendNeutralArrowPath,
  TrendUpArrowPath,
} from 'glue/components/accessibility/SvgColorblindPatterns';
import { MoralePulseResponseSentiment } from 'types';
import { colors } from '@mysteryco/design';

export const sentimentGraphics: Record<
  MoralePulseResponseSentiment,
  {
    background: string;
    color: string;
    textBackground: string;
    textColor: string;
    // using this as a generic typing for all of the arrows since they're the same.
    Arrow: typeof TrendDownArrowPath;
    trend: 'down' | 'up' | 'neutral';
  }
> = {
  [MoralePulseResponseSentiment.Negative]: {
    background: COLORS.trendDownBg,
    color: COLORS.trendDownFg,
    textBackground: colors.Ruby_50,
    textColor: colors.Ruby_10,
    Arrow: TrendDownArrowPath,
    trend: 'down',
  },
  [MoralePulseResponseSentiment.VeryNegative]: {
    background: COLORS.trendDownStrongBg,
    color: COLORS.trendDownStrongFg,
    textBackground: COLORS.trendDownStrongBg,
    textColor: colors.Ruby_50,
    Arrow: TrendDownArrowPath,
    trend: 'down',
  },
  [MoralePulseResponseSentiment.Positive]: {
    background: COLORS.trendUpBg,
    color: COLORS.trendUpFg,
    textBackground: colors.Mint_50,
    textColor: colors.Mint_00,
    Arrow: TrendUpArrowPath,
    trend: 'up',
  },
  [MoralePulseResponseSentiment.VeryPositive]: {
    background: COLORS.trendUpStrongBg,
    color: COLORS.trendUpStrongFg,
    textBackground: COLORS.trendUpStrongBg,
    textColor: colors.White,
    Arrow: TrendUpArrowPath,
    trend: 'up',
  },
  [MoralePulseResponseSentiment.Neutral]: {
    background: COLORS.trendNeutralBg,
    color: COLORS.trendNeutralFg,
    textBackground: colors.Plum_60,
    textColor: colors.Plum_10,
    Arrow: TrendNeutralArrowPath,
    trend: 'neutral',
  },
};
