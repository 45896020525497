import { gql } from '@apollo/client';
import { Box, CircularProgress, Popper, TextField, Typography } from '@material-ui/core';
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  createFilterOptions,
} from '@material-ui/lab';
import { ClassNameMap, makeStyles } from '@material-ui/styles';
import { colors } from '@mysteryco/design';
import { ChangeEvent, useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { pluralize } from 'humanize-plus';

import theme from 'theme';
import { TeamSearchFragment, useTeamSearchQuery } from 'types';

import MagnGlass from './icons/MagnGlass';
import RefreshCycle from './icons/RefreshCycle';
import { defaultTeamName, isUsingDefaultTeamName } from 'utils/teamUtil';
import { cx } from '@emotion/css';

type Option = TeamSearchFragment;

export type TeamSearchProps = Pick<
  AutocompleteProps<Option, false, true, false>,
  'getOptionLabel'
> & {
  value: string; // teamId
  onChange: (newTeamId: string | undefined) => unknown;
  onOpen?: (event: ChangeEvent<{}> | PointerEvent) => void;
  onClose?: (event: ChangeEvent<{}> | PointerEvent | MouseEvent) => void;
};

const useStyles = makeStyles({
  input: {
    '& .MuiInputBase-root': {
      borderRadius: 100,
      padding: `8px !important`,
    },
  },
  adornment: {
    position: 'relative',
    display: 'flex',
    height: 32,
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    backgroundColor: colors.Main,
    '&[data-loading=true]': {
      backgroundColor: colors.DarkGray,
    },
    '&[data-error=true][data-loading=false]': {
      color: colors.White,
      backgroundColor: colors.Red600,
      cursor: 'pointer',
    },
  },
  adornmentIcon: {
    display: 'inline-flex',
    height: 32,
    width: 32,
    justifyContent: 'center',
    alignItems: 'center',
  },
  adornmentSpinner: {
    position: 'absolute',
    left: -4,
    top: -4,
  },
  adornmentErrorText: {
    marginRight: theme.spacing(2),
  },
  autocompleteDropdown: {
    '& .MuiAutocomplete-option[aria-selected=true]': {
      backgroundColor: colors.OffWhite,
      '& $teamOptionTitle': {
        color: colors.Dusk,
      },
    },
    '& .MuiAutocomplete-option[data-focus=true]': {
      backgroundColor: colors.Green100,
    },
  },
  teamOptionTitle: {
    fontWeight: 500,
    fontSize: '.875rem',
    lineHeight: 1.43,
    color: colors.Black,
  },
  teamOptionDetails: {
    fontWeight: 500,
    fontSize: '.75rem',
    lineHeight: 1.67,
    color: colors.MediumGray,
  },
});

export const TeamSearch = ({
  value,
  onChange,
  onOpen = () => null,
  onClose = () => null,
  ...props
}: TeamSearchProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const { loading: apolloLoading, data, error, refetch } = useTeamSearchQuery();
  // Wat: Apollo is not re-rendering when we call `refetch`.
  //
  // Unclear why, and I've beaten my head against this for too long. So, as
  // a workaround, this forces the component to re-render (and Apollo does have
  // the correct state handy at that point).
  const [reloading, setReloading] = useState(false);
  const reload = useCallback(
    async function reload() {
      setReloading(true);
      try {
        await refetch();
      } finally {
        setReloading(false);
      }
    },
    [refetch, setReloading],
  );
  const loading = apolloLoading || reloading;

  const options = useMemo(() => {
    if (!data?.allTeamsInMyOrg) return [];
    return [...data.allTeamsInMyOrg]
      .sort((a, b) => b.members?.length - a.members?.length)
      .filter((t): t is TeamSearchFragment => !!t);
  }, [data]);

  const team: TeamSearchFragment = useMemo(() => {
    const found = options?.find((t) => t.id === value);
    return found || { id: value, name: 'Unknown Team', members: [] };
  }, [value, options]);

  const filterOptions = createFilterOptions({
    stringify: (option: TeamSearchFragment) => option.name + option.manager?.name,
  });

  const handleOpen = (event: ChangeEvent<{}> | PointerEvent) => {
    setOpen(true);
    onOpen(event);
  };

  const handleClose = (event: ChangeEvent<{}> | PointerEvent | MouseEvent) => {
    setOpen(false);
    onClose(event);
  };

  const globalClickHandler = useCallback(function globalClickHandler(event: MouseEvent) {
    if (
      !inputRef?.current?.contains(event.target as HTMLElement) &&
      !dropdownRef?.current?.contains(event.target as HTMLElement)
    ) {
      handleClose(event);
    }
  }, []);

  useEffect(() => {
    document.body.addEventListener('click', globalClickHandler);
    return () => {
      document.body.removeEventListener('click', globalClickHandler);
    };
  });

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(t) => t.name}
      renderOption={(t) => {
        const teamSize = t.members?.length || 0;
        return (
          <Box>
            <Typography className={classes.teamOptionTitle}>{t.name}</Typography>
            {!isUsingDefaultTeamName(t) && (
              <Typography className={classes.teamOptionDetails}>
                {defaultTeamName(t)}
              </Typography>
            )}
            <Typography className={classes.teamOptionDetails}>
              {teamSize} {pluralize(teamSize, 'direct report')}
            </Typography>
          </Box>
        );
      }}
      getOptionSelected={(option, value) => option.id === value.id}
      loading={loading}
      ref={inputRef}
      renderInput={(params) => (
        <TeamSearchInput
          params={params}
          queryState={{ loading, error, reload, classes }}
          classes={classes}
        />
      )}
      disableClearable
      value={team}
      onChange={(_event, newValue) =>
        onChange(typeof newValue === 'object' ? newValue.id : undefined)
      }
      PopperComponent={(props) => (
        <Popper
          {...props}
          ref={dropdownRef}
          className={cx(classes.autocompleteDropdown, props?.className)}
        />
      )}
      filterOptions={filterOptions}
      {...props}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    />
  );
};

const TeamSearchInput = ({
  params,
  queryState,
  classes,
}: {
  params: AutocompleteRenderInputParams;
  queryState: TeamSearchInputAdornmentProps;
  classes: ClassNameMap;
}) => {
  return (
    <TextField
      {...params}
      InputProps={{
        ...params.InputProps,
        startAdornment: <TeamSearchInputAdornment {...queryState} />,
      }}
      className={classes.input}
      variant='outlined'
      size='small'
      placeholder={
        queryState.loading ? 'Loading teams…' : queryState.error ? '' : 'Search for teams'
      }
    />
  );
};

interface TeamSearchInputAdornmentProps {
  loading: boolean;
  error?: any;
  reload: () => unknown;
  classes: ClassNameMap;
}

const TeamSearchInputAdornment = ({
  loading,
  error,
  reload,
  classes,
}: TeamSearchInputAdornmentProps) => {
  const showError = !!error && !loading;

  return (
    <div
      className={classes.adornment}
      data-loading={loading}
      data-error={!!error}
      onClick={reload}
    >
      <div className={classes.adornmentIcon}>
        {showError ? (
          <RefreshCycle color={colors.White} height={16} width={16} />
        ) : (
          <MagnGlass color={colors.White} height={16} width={16} />
        )}
      </div>
      {showError && (
        <span className={classes.adornmentErrorText}>Unable to load teams. Retry?</span>
      )}
      {loading && (
        <CircularProgress classes={{ root: classes.adornmentSpinner }} size={40} />
      )}
    </div>
  );
};

TeamSearch.fragment = gql`
  fragment TeamSearch on Team {
    id
    name
    manager {
      id
      name
    }
    members {
      id
    }
  }
`;

TeamSearch.query = gql`
  query TeamSearch {
    allTeamsInMyOrg {
      ...TeamSearch
    }
  }
  ${TeamSearch.fragment}
`;
