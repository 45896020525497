import { Box } from '@material-ui/core';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import { CreditCardForm } from 'components/core/CreditCardForm';
import SectionSubHeader from 'components/SectionSubHeader';

const PaymentMethod = ({
  userId,
  cardOnFile,
  setErrorState,
  setStripeToken,
  bookSuccessful,
}: {
  userId: string;
  cardOnFile: any;
  setErrorState(err: any): void;
  setStripeToken(token: string): void;
  bookSuccessful?: boolean;
}) => {

  return (
    <Box my={4}>
      <form>
        <SectionSubHeader
          title='Payment details'
          subTitle={!!cardOnFile ? '' : `Add a credit card to pay and book your event`}
        />
        <Box>
          <ElementsConsumer>
            {({ elements, stripe }) => (
              <CreditCardForm
                elements={elements}
                stripe={stripe}
                userId={userId}
                cardOnFile={cardOnFile}
                onSuccess={(token) => {
                  setStripeToken(token);
                  setErrorState(false);
                }}
                onError={() => setErrorState(true)}
                hideChangeButton={bookSuccessful}
              />
            )}
          </ElementsConsumer>
        </Box>
      </form>
    </Box>
  );
};

export default PaymentMethod;
