import { gql } from '@apollo/client';
import { Link } from '@material-ui/core';
import ArrowRight from '@mysteryco/design/icons/ArrowRight';
import { colors } from '@mysteryco/design/src';
import RegistrationStepWrapper from 'components/Checkout/styling/RegistrationStepWrapper';
import { sharedStyles } from 'components/Checkout/styling/sharedStyles';
import Key01 from 'components/icons/Key01';
import FormikCheckboxField from 'glue/components/forms/FormikCheckboxField';
import FormikForm from 'glue/components/forms/FormikForm';
import FormikSubmitButton from 'glue/components/forms/FormikSubmitButton';
import FormikTextField from 'glue/components/forms/FormikTextField';
import InlineBanner from 'glue/components/structure/InlineBanner';
import mixpanel from 'mixpanel-browser';
import { Paths } from 'Routes';
import theme from 'theme';
import { useUpdateUserAccountMutation } from 'types';
import { PRIVACY_POLICY_URL, TOS_URL } from 'utils/stringUtil';
import * as yup from 'yup';

const validationSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  privacyPolicyAndTosAccepted: yup
    .bool()
    .isTrue('Privacy policy and terms of use must be accepted before proceeding'),
});

const AccountStep = ({ viewer, ...otherProps }) => {
  const [updateUser, { loading, error }] = useUpdateUserAccountMutation();

  const handleSubmit = async (values) => {
    mixpanel.track('Continue clicked', {
      source: 'registration',
      step: otherProps.step,
      customerType: viewer?.customerType,
      firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
    });
    mixpanel.track('user name added', {
      source: 'registration',
      customerType: viewer?.customerType,
      firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
    });
    await updateUser({
      variables: {
        id: viewer.id,
        firstName: values.firstName,
        lastName: values.lastName,
        marketingConsent: values.marketingConsent,
      },
    });
    if (!error) otherProps.next();
  };

  return (
    <RegistrationStepWrapper
      {...otherProps}
      label='First, let’s get to know you'
      hideButtons
    >
      <FormikForm
        initialValues={{
          firstName: viewer?.firstName,
          lastName: viewer?.lastName,
          marketingConsent: viewer?.marketingConsent || false,
          privacyPolicyAndTosAccepted: false,
        }}
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <FormikTextField
          name='firstName'
          type='text'
          label='First name'
          css={sharedStyles.textInput}
        />
        <FormikTextField
          name='lastName'
          type='text'
          label='Last name'
          css={sharedStyles.textInput}
        />

        <FormikCheckboxField
          name='privacyPolicyAndTosAccepted'
          label={
            <div css={sharedStyles.checkboxLabel}>
              I've read and agree to Glue's{' '}
              <Link
                href={PRIVACY_POLICY_URL}
                target='_blank'
                rel='noopener'
                underline='always'
              >
                Privacy Policy
              </Link>{' '}
              and{' '}
              <Link href={TOS_URL} target='_blank' rel='noopener' underline='always'>
                Terms of Use
              </Link>
            </div>
          }
        />
        <FormikCheckboxField
          name='marketingConsent'
          label={
            <div css={sharedStyles.checkboxLabel}>
              <div css={sharedStyles.checkboxHeading}>Subscribe to our newsletter</div>
              <div>Receive tips and product updates from Glue</div>
            </div>
          }
        />
        <FormikSubmitButton
          onlyValid
          css={sharedStyles.button}
          endIcon={<ArrowRight size={16} />}
          loading={loading && !error}
        >
          Continue
        </FormikSubmitButton>
        <InlineBanner
          css={styles.banner}
          icon={<Key01 color={colors.Glue_Ink00} />}
          type='lavender'
          headline='Not new?'
          description={
            <Link href={Paths.LOG_OUT} underline='always'>
              Sign in with a different account
            </Link>
          }
        />
      </FormikForm>
    </RegistrationStepWrapper>
  );
};

const styles = {
  banner: {
    marginTop: theme.spacing(4),
  },
};

AccountStep.mutation = gql`
  mutation updateUserAccount(
    $id: ID!
    $firstName: String
    $lastName: String
    $companyRole: String
    $marketingConsent: Boolean
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      companyRole: $companyRole
      marketingConsent: $marketingConsent
      wasPromptedForMarketingConsent: true
    ) {
      id
      name
      firstName
      lastName
      email
      phone
      dob
      companyRole
      role
      createdAt
      zipCode
    }
    acceptLatestLegalAgreements {
      id
      hasAgreedToLatestTOS
      promptedForMarketingConsent
    }
  }
`;

export default AccountStep;
