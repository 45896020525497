import { Button } from '@material-ui/core';
import config from 'config';
import { redirectAndSaveSSFState } from 'lib/helpers/login';
import mixpanel from 'mixpanel-browser';
import { primaryGradient } from 'theme';
import { SelfServeState } from 'types/selfServeFlow';

const LoginButton = ({ globalState }: { globalState: SelfServeState }) => {
  return (
    <div
      style={{
        width: '100px',
        height: '44px',
        position: 'relative',
        padding: '2px',
        borderRadius: '44px',
        background: primaryGradient,
        zIndex: 1,
      }}
    >
      <Button
        variant='outlined'
        style={{
          width: '96px',
          height: '40px',
          border: '0px solid',
          background: 'white',
          borderRadius: '40px',
          textTransform: 'none',
          zIndex: 2,
        }}
        onClick={() => {
          mixpanel.track('login button clicked', { source: 'self serve flow' });
          redirectAndSaveSSFState({
            globalState,
            fromLoginStep: false,
            redirectToUrl: config.auth.url,
          });
        }}
      >
        <span
          style={{
            background: primaryGradient,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Sign In
        </span>
      </Button>
    </div>
  );
};

export default LoginButton;
