import EmptyButton from 'components/EmptyButton';
import FilledButton from 'components/FilledButton';
import Check from 'components/icons/Check';
import Clock from 'components/icons/Clock';
import { EncircleIcon } from 'components/icons/EncircleIcon';
import Star01 from 'components/icons/Star01';
import Star07 from 'components/icons/Star07';
import mixpanel from 'mixpanel-browser';
import theme from 'theme';
import { useViewer } from 'utils/state';

import { Box, Link, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { getUpsellLink } from 'utils/stringUtil';
import React from 'react';

const useStyles = makeStyles({
  card: {
    border: '2px solid',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(8),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(8),
    justifyContent: 'space-between',
    borderRadius: theme.spacing(2),
    position: 'relative',
  },
  selectedCard: {
    borderColor: theme.palette.primary.main,
  },
  unselectedCard: {
    borderColor: theme.palette.primary[100],
  },
  selectedText: {
    paddingLeft: theme.spacing(10),
  },
  unselectedText: {
    paddingLeft: theme.spacing(10),
  },
  title: {
    color: theme.palette.text.secondary,
    fontSize: '18px',
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
  },
  purpleCheck: {
    height: '32px',
    width: '32px',
    position: 'absolute',
    top: 0,
    left: 0,
    background: theme.palette.primary.main,
    borderRight: '4px solid white',
    borderBottom: '4px solid white',
    borderRadius: '4px 0px 8px 0px',
    overflow: 'hidden',
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
  },
  blurbButton: {
    color: theme.palette.common.white,
    minWidth: '108px',
    padding: '8px 16px',
    background: theme.palette.primary.main,
    '&:hover': {
      borderColor: theme.palette.text.secondary,
      backgroundColor: theme.palette.text.secondary,
    },
  },
  buttonText: {
    fontSize: '12px',
    lineHeight: '12px',
    whiteSpace: 'nowrap',
    fontWeight: theme.typography.fontWeightBold,
  },
  pointer: {
    cursor: 'pointer',
  },
  image: {
    width: '228px',
    maxHeight: '156px',
    borderRadius: '4px',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  infoPadding: {
    paddingLeft: '22px',
    paddingTop: '16px',
    paddingBottom: '20px',
  },
});

export const InfoCard = ({
  title,
  durationMins,
  photoUrl,
  rating,
  showEventSelectionScreen,
  showUpsell,
}: {
  title: string;
  durationMins: number;
  photoUrl: string;
  rating: number;
  showEventSelectionScreen: () => void;
  showUpsell: boolean;
}) => {
  const classes = useStyles({ photoUrl });
  const viewer = useViewer();
  return (
    <Box className={[classes.card, classes.selectedCard, classes.infoPadding].join(' ')}>
      <Box className={classes.purpleCheck}>
        <Check color={theme.palette.common.white} css={{ margin: '2px' }} />
      </Box>
      <img className={classes.image} src={photoUrl} alt='experience' />
      <Box display='flex' flexDirection='column' justifyContent='space-between' flex={1}>
        <Box flex={1}>
          <Typography className={classes.title}>{title}</Typography>
          {showUpsell && (
            <Box mb={2}>
              <Link
                onClick={() => {
                  mixpanel.track('clicked upsell from event selection', {
                    customerType: viewer?.customerType,
                    firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
                  });
                  window.open(getUpsellLink('booking-10'), '_blank');
                }}
                css={{ fontSize: '12px' }}
              >
                Save 10% with a Glue Subscription
              </Link>
            </Box>
          )}
          <Box display='flex' flexDirection='row' justifyContent='space-between' mb={4}>
            <Box display='flex' flexDirection='row' alignItems='center' flex={1}>
              <Clock color={theme.palette.primary[500]} size={16} />
              <Typography
                css={{
                  color: theme.palette.common.black,
                  opacity: '.8',
                  fontSize: '14px',
                  marginLeft: theme.spacing(1),
                  paddingTop: '1px',
                }}
              >{`${durationMins} mins`}</Typography>
            </Box>
            {rating && rating > 0 && (
              <Box display='flex' flexDirection='row' alignItems='center' flex={1}>
                <Star01
                  color={theme.palette.primary.main}
                  width={16}
                  height={16}
                  filled={true}
                />

                <Typography
                  css={{
                    color: theme.palette.text.secondary,
                    fontSize: '12px',
                    marginLeft: theme.spacing(1),
                    paddingTop: '1px',
                  }}
                >
                  <b>{`${rating.toFixed(1)}/5`}</b> Rating
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <EmptyButton className={classes.blurbButton} onClick={showEventSelectionScreen}>
          <Typography className={classes.buttonText}>Change selection</Typography>
        </EmptyButton>
      </Box>
    </Box>
  );
};

export const SelectionCard = ({
  buttonText,
  content,
  onClick,
  selected,
  title,
  showIcon = false,
}: {
  buttonText?: string;
  content: React.ReactNode;
  onClick: () => void;
  selected: boolean;
  title: string;
  showIcon?: boolean;
}) => {
  const classes = useStyles({});
  const Button = selected && showIcon ? FilledButton : EmptyButton;

  return (
    <Box
      className={[
        classes.card,
        classes.pointer,
        selected ? classes.selectedCard : classes.unselectedCard,
      ].join(' ')}
      onClick={onClick}
    >
      {selected && (
        <Box className={classes.purpleCheck}>
          <Check color={theme.palette.common.white} css={{ margin: '2px' }} />
        </Box>
      )}
      <Box
        className={[
          classes.col,
          selected ? classes.selectedText : classes.unselectedText,
        ].join(' ')}
      >
        {showIcon && (
          <Box mb={theme.spacing(2)}>
            <EncircleIcon
              backgroundColor={theme.palette.primary[200]}
              css={{ border: `1px solid ${theme.palette.primary[400]}` }}
            >
              <Star07 />
            </EncircleIcon>
          </Box>
        )}
        <Typography className={classes.title}>{title}</Typography>
        {content}
      </Box>
      {buttonText && (
        <Box className={classes.buttonBox}>
          <Button className={classes.blurbButton}>
            <Typography className={classes.buttonText}>{buttonText}</Typography>
          </Button>
        </Box>
      )}
    </Box>
  );
};
