// @ts-strict-ignore
import { gql } from '@apollo/client';
import DEFAULT_EVENT_IMAGE from 'assets/event_example.jpeg';
import { IS_GLUE } from 'constants/Strings';
import { isEventCompleted, isRequester } from 'lib/helpers/events';
import { Props } from '../components/SSFSteps/types';
import {
  AccountSetupViewerFragment,
  GetEventDetailsTeamEventFragment,
  GetPhotoUrlTeamEventFragment,
  InvitedGuestRsvpStatus,
  TeamEvent,
  useGetViewerQuery,
  User,
} from '../types';

type TeamEventRsvpStatus = {
  invitedGuests?: {
    user?: {
      id?: string;
    };
    rsvpStatus?: InvitedGuestRsvpStatus;
    email?: string;
  }[];
};

export type nil = null | undefined;

export const userNeedsSetup = (viewer: AccountSetupViewerFragment | nil): boolean => {
  if (!viewer) return false;
  const userNeedsLegal =
    !viewer.hasAgreedToLatestTOS || !viewer.promptedForMarketingConsent;
  const userNeedsDetails =
    !!viewer.unansweredPreferenceQuestions.length ||
    !viewer.dietaryRestrictionsLastAsked ||
    !viewer.firstName;
  return userNeedsDetails || userNeedsLegal;
};

export const AccountSetupFragment = gql`
  fragment AccountSetupViewer on User {
    hasAgreedToLatestTOS
    promptedForMarketingConsent
    dietaryRestrictionsLastAsked
    firstName
    unansweredPreferenceQuestions {
      id
    }
  }
`;

export const headCountFromProps = (props: Props<any>): number =>
  props?.globalState?.uploadGuests?.totalRecipientsEstimate ?? 0;

const shouldShowAsSurprise = ({
  teamEvent,
  viewer,
}: {
  teamEvent: {
    requestedBy?: { id?: string };
  } & Pick<TeamEvent, 'status' | 'surpriseToAll' | 'surpriseToAttendees'>;
  viewer: { id: string };
}): boolean => {
  if (isEventCompleted(teamEvent)) return false;

  if (isRequester({ teamEvent, viewer })) {
    return teamEvent.surpriseToAll ?? false;
  }

  return teamEvent.surpriseToAttendees ?? false;
};

export const getPhotoUrl = ({
  teamEvent,
  viewer,
}: {
  teamEvent: GetPhotoUrlTeamEventFragment;
  viewer: { id: string };
}): string => {
  if (shouldShowAsSurprise({ teamEvent, viewer })) {
    return IS_GLUE.imageUrl;
  } else if (teamEvent.experience?.photoUrl) {
    return teamEvent.experience.photoUrl;
  } else if (teamEvent.experience?.templates?.[0]?.photoUrl) {
    return teamEvent.experience.templates[0].photoUrl;
  } else {
    return DEFAULT_EVENT_IMAGE;
  }
};
getPhotoUrl.fragments = gql`
  fragment GetPhotoUrlTeamEvent on TeamEvent {
    id
    experience {
      id
      photoUrl
      templates {
        id
        photoUrl
      }
    }
    status
    surpriseToAll
    surpriseToAttendees
  }
`;

type EventDetails = {
  title: string;
  description: string;
  experienceName: string;
  photoUrl: string;
};

export const getEventDetails = ({
  teamEvent,
  viewer,
  defaultTitle,
}: {
  teamEvent: GetEventDetailsTeamEventFragment;
  viewer: { id: string };
  defaultTitle?: string;
}): EventDetails => {
  const isMysteryForViewer = shouldShowAsSurprise({ teamEvent, viewer });
  return {
    title: teamEvent.title || (defaultTitle ?? ''),
    description: isMysteryForViewer
      ? IS_GLUE.description
      : teamEvent.experience?.description || (teamEvent.description ?? ''),
    experienceName: isMysteryForViewer ? IS_GLUE.title : teamEvent.experience?.name ?? '',
    photoUrl: getPhotoUrl({ teamEvent, viewer }),
  };
};
getEventDetails.fragments = gql`
  fragment GetEventDetailsTeamEvent on TeamEvent {
    id
    title
    description
    ...GetPhotoUrlTeamEvent
    experience {
      id
      name
      description
    }
  }
  ${getPhotoUrl.fragments}
`;

export const getGuests = (
  teamEvent: TeamEventRsvpStatus,
): {
  email: string | undefined;
  rsvpStatus: InvitedGuestRsvpStatus;
}[] => {
  if (!teamEvent.invitedGuests?.length) {
    return [];
  }

  return teamEvent.invitedGuests?.map(({ email, rsvpStatus }) => ({
    email,
    rsvpStatus: rsvpStatus ?? InvitedGuestRsvpStatus.Invited,
  }));
};

export const getUserRsvpStatus = (
  user: Pick<User, 'email'>,
  teamEvent: TeamEventRsvpStatus,
): InvitedGuestRsvpStatus | null => {
  const foundInvitedGuest = teamEvent.invitedGuests?.find(
    (invitedGuest) => invitedGuest.email === user.email,
  );

  return foundInvitedGuest?.rsvpStatus ?? null;
};

export const useViewer = () => {
  return useGetViewerQuery().data?.viewer ?? undefined;
};
