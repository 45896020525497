import { css } from '@emotion/react';
import theme from 'theme';

const CenterSection = ({ loading = false, children }) => (
  <div css={styles.centerSection(loading)}>{children}</div>
);

const styles = {
  centerSection: (loading: boolean) =>
    css({
      opacity: loading ? 0.4 : 1,
      margin: `0 auto`,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(12),
      fontFamily: 'Manrope',
    }),
};

export default CenterSection;
