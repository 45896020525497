// @ts-strict-ignore
import _ from 'lodash';
import { DateTime } from 'luxon';
import { Link, useHistory } from 'react-router-dom';
import {
  EventPageTeamEventFragmentFragment,
  TeamEventStatus,
  ViewerFragmentFragment,
} from 'types';
import { getEventDetails } from 'utils/state';

import { TableCell, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

import {
  Green200,
  Green800,
  Main,
  Orange200,
  Orange600,
  Purple100,
  Purple200,
  Purple50,
  Purple800,
  White,
} from '@mysteryco/design/src/tokens/colors';
import GoalSection from 'components/GoalSection';
import { eventStatusToString } from 'lib/helpers/teamEventStatus';
import mixpanel from 'mixpanel-browser';
import { getActivitiesEventsPath, useEventStyles } from './';
import { getBookedEventPath } from './BookedEvent';

const getStatusObject = (teamEvent) => {
  if (teamEvent.isRequestToBook && teamEvent.status === TeamEventStatus.Requested) {
    return {
      bgcolor: theme.palette.warning[200],
      color: theme.palette.warning[600],
      text: 'Requested',
    };
  }

  const result = {
    text: eventStatusToString(teamEvent.status),
  };

  switch (teamEvent.status) {
    case TeamEventStatus.Canceled:
    case TeamEventStatus.Expired:
      return {
        ...result,
        bgcolor: Orange200,
        color: Orange600,
      };
    case TeamEventStatus.Requested:
    case TeamEventStatus.Scheduled:
      return {
        ...result,
        bgcolor: Green200,
        color: Green800,
      };
    case TeamEventStatus.InProgress:
      return {
        bgcolor: Main,
        color: White,
        text: 'About To Begin!',
      };
    case TeamEventStatus.Complete:
    default:
      return {
        ...result,
        bgcolor: Purple200,
        color: Purple800,
      };
  }
};

const TeamEventRow = ({
  teamEvent,
  viewer,
}: {
  teamEvent: EventPageTeamEventFragmentFragment;
  viewer: ViewerFragmentFragment;
}) => {
  const classes = { ...useStyles(), ...useEventStyles() };
  const history = useHistory();

  const requestedFor = DateTime.fromISO(_.get(teamEvent, 'requestedFor'));
  const monthAndYear = requestedFor.toLocaleString(DateTime.DATE_MED);
  const hour = requestedFor.toLocaleString({
    ...DateTime.TIME_WITH_SHORT_OFFSET,
    second: undefined,
  });

  const { title, experienceName, photoUrl } = getEventDetails({
    teamEvent,
    viewer,
    defaultTitle: 'Event Title',
  });

  const status = getStatusObject(teamEvent);

  return (
    <TableRow
      key={teamEvent.id}
      className={classes.tableRow}
      onClick={() => {
        mixpanel.track('event details opened', {
          source: 'events',
          customerType: viewer?.customerType,
          firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
        });
        history.push(getBookedEventPath(teamEvent.id));
      }}
      style={{ cursor: 'pointer' }}
    >
      {/* Thumbnail */}
      <TableCell className={classes.imageCell}>
        <img alt={experienceName} className={classes.thumbnail} src={photoUrl} />
      </TableCell>
      {/* Title */}
      <TableCell className={classes.titleCell} component='th'>
        <Typography className={classes.maxTwoLinesText}>
          <b>{title}</b>
        </Typography>
      </TableCell>
      {/* Goal */}
      <TableCell>
        <GoalSection eventGoals={teamEvent?.goals} iconSize={16} fontSize='.875rem' />
      </TableCell>
      {/* Time */}
      <TableCell className={classes.dataCell}>
        <Typography noWrap>{monthAndYear}</Typography>
        <Typography noWrap>{`@ ${hour}`}</Typography>
      </TableCell>
      {/* Status */}
      <TableCell>
        <Typography
          style={{ background: status.bgcolor, color: status.color }}
          className={classes.statusText}
        >
          {status.text}
        </Typography>
      </TableCell>
      {/* RSVPs */}
      <TableCell className={classes.dataCell} align='center'>
        <Typography>
          {teamEvent.invitationsSent > 0
            ? `${teamEvent.invitationsAccepted || 0} of ${teamEvent.invitationsSent}`
            : '-'}
        </Typography>
      </TableCell>
      {/* Action */}
      <TableCell className={classes.dataCell} align='right'>
        <Link
          to={{
            pathname: getActivitiesEventsPath(),
            search: `?teamEventId=${teamEvent.id}`,
            state: {
              teamEventId: teamEvent.id,
            },
          }}
        >
          <Typography className={classes.detailsLink}>Details</Typography>
        </Link>
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles({
  maxTwoLinesText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
  },
  statusText: {
    width: 'fit-content',
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    borderRadius: theme.spacing(8),
    fontWeight: 500,
    fontSize: '.9rem',
    color: White,
  },
  detailsLink: {
    color: Main,
    fontWeight: 500,
    textTransform: 'none',
    textDecoration: 'underline',
    '&:focus': {
      color: White,
      backgroundColor: theme.palette.primary[600],
    },
  },
  tableRow: {
    padding: `${theme.spacing(1)}`,
    background: White,
    '& .MuiTableCell-root': {
      borderBottom: `1px solid ${Purple100}`,
    },
    '&:hover': {
      backgroundColor: Purple50,
    },
  },
  imageCell: {
    width: 112,
    '& img': {
      width: '80px',
      minWidth: '80px',
      height: '50px',
      display: 'block',
      overflow: 'hidden',
    },
  },
  dataCell: {
    '& *': {
      fontSize: '.875rem',
    },
  },
  titleCell: {
    minWidth: 200,
    '& *': {
      fontSize: '.875rem',
    },
  },
});

export default TeamEventRow;
