import { css } from '@emotion/react';
import { colors } from '@mysteryco/design/src';
import ProgressSemiCircle from 'glue/assets/ProgressSemiCircle';
import { pluralize } from 'humanize-plus';
import theme from 'theme';

export const INSIGHTS_THRESHOLD = 40;
export const ACCURACY_THRESHOLD = 90;

const CalendarConnectionProgress = ({
  current,
  total,
}: {
  current: number;
  total: number;
}) => {
  const progress = Math.round((current / total) * 100);
  const insightsDiff = Math.round((INSIGHTS_THRESHOLD / 100) * total - current);
  const accuracyDiff = Math.round((ACCURACY_THRESHOLD / 100) * total - current);
  return (
    <div css={progressStyles.root}>
      <div css={progressStyles.donut}>
        <ProgressSemiCircle progress={progress / 100} css={progressStyles.donutGraph} />
        <div css={progressStyles.donutText}>{progress}%</div>
      </div>
      <div css={progressStyles.legend}>
        {insightsDiff > 0 && (
          <div css={progressStyles.cell}>
            <h5 css={progressStyles.header}>{INSIGHTS_THRESHOLD}% to unlock insights</h5>
            <p css={progressStyles.detail}>
              You're {insightsDiff} {pluralize(insightsDiff, 'connection')} away
            </p>
          </div>
        )}
        {accuracyDiff > 0 && (
          <div css={progressStyles.cell}>
            <h5 css={progressStyles.header}>{ACCURACY_THRESHOLD}% for high accuracy</h5>
            <p css={progressStyles.detail}>
              You're {accuracyDiff} {pluralize(accuracyDiff, 'connection')} away
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

const progressStyles = {
  root: css({
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
    padding: theme.spacing(5),
    gap: theme.spacing(4),
    background: colors.Glue_LavenderLight,
    alignSelf: 'stretch',
  }),
  donut: css({
    display: 'grid',
  }),
  donutGraph: css({
    gridColumn: 1,
    gridRow: 1,
  }),
  donutText: css({
    gridColumn: 1,
    gridRow: 1,
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center',
    fontWeight: 800,
    fontSize: 20,
    lineHeight: 1,
    color: colors.Glue_Ink00,
  }),
  legend: css({
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 0,
    gap: theme.spacing(5),
  }),
  cell: css({
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: 0,
    gap: theme.spacing(2),
  }),
  header: css({
    fontWeight: 800,
    fontSize: '.875rem',
    lineHeight: 1,
    color: colors.Glue_Ink00,
    margin: 0,
  }),
  detail: css({
    fontWeight: 800,
    fontSize: '.875rem',
    lineHeight: 1,
    color: colors.Glue_Ink10,
  }),
};

export default CalendarConnectionProgress;
