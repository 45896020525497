import _ from 'lodash';
import React, { PureComponent } from 'react';

enum Breakpoint {
  Small,
  Medium,
  Large,
  XLarge,
}

export interface Breakpoints {
  small?: boolean;
  medium?: boolean;
  smallOrMedium?: boolean;
  large?: boolean;
  xlarge?: boolean;
}

export const ResponsiveContext = React.createContext<Breakpoints>({});

export function responsiveSwitch(
  obj: { [K in keyof Breakpoints]: any } | any[],
  context: Breakpoints,
) {
  if (_.isArray(obj)) {
    if (context.small) return obj[0];
    if (context.medium) return obj[1];
    if (context.large) return obj[2];
    if (context.xlarge) return obj[3];
  } else {
    // @ts-ignore

    for (const key in context) if (context[key]) return obj[key];
  }
}

export default class BreakpointsProvider extends PureComponent {
  static displayName = 'BreakpointsProvider';

  static defaultProps = {
    breakpoints: {
      small: 600,
      medium: 960,
      large: 1200,
      xlarge: Infinity,
    },
  };

  state = {
    currentBreakpoint: Breakpoint.Large,
  };

  componentDidMount() {
    const debouncedResize = _.debounce(this.handleResize, 50);
    window.addEventListener('resize', debouncedResize, { passive: true });
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    const { breakpoints } = this.props as any;
    if (
      window.matchMedia(
        `(max-device-width: ${breakpoints.small}px), (max-width: ${breakpoints.small}px)`,
      ).matches
    ) {
      this.setState({ currentBreakpoint: Breakpoint.Small });
    } else if (
      window.matchMedia(
        `(max-device-width: ${breakpoints.medium}px), (max-width: ${breakpoints.medium}px)`,
      ).matches
    ) {
      this.setState({ currentBreakpoint: Breakpoint.Medium });
    } else if (
      window.matchMedia(
        `(max-device-width: ${breakpoints.large}px), (max-width: ${breakpoints.large}px)`,
      ).matches
    ) {
      this.setState({ currentBreakpoint: Breakpoint.Large });
    } else {
      this.setState({ currentBreakpoint: Breakpoint.XLarge });
    }
  };

  render() {
    const bp = this.state.currentBreakpoint;
    return (
      <ResponsiveContext.Provider
        value={{
          small: bp === Breakpoint.Small,
          medium: bp === Breakpoint.Medium,
          smallOrMedium: _.includes([Breakpoint.Small, Breakpoint.Medium], bp),
          large: bp === Breakpoint.Large,
          xlarge: bp === Breakpoint.XLarge,
        }}
      >
        {this.props.children}
      </ResponsiveContext.Provider>
    );
  }
}
