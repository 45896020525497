import { useHistory } from 'react-router-dom';
import SidePanel from './SidePanel';
import EditSection from './EditSection';
import Button from 'glue/components/buttons/Button';
import { css } from '@emotion/react';
import { RadioGroup } from '@material-ui/core';
import RadioButtonCard from 'glue/components/inputs/RadioButtonCard';
import {
  Coal_10,
  Glue_Ink10,
  Glue_LavenderLight,
  Mint_30,
  Mint_40,
  Plum_30,
  Plum_60,
} from '@mysteryco/design/src/tokens/colors';
import { CopyProps } from './MeetupsLoader';
import { Paths } from 'Routes';
import theme from 'theme';

const SetupSection = ({ copy }: { copy: CopyProps }) => {
  const history = useHistory();
  const { memberCount } = copy;

  // A lot of these components are directly copied from SelectCohort.
  // Unfortunately the use cases are too different that we don't have a good way to extract them.
  // Make sure all style changes are applied to both.
  return (
    <SidePanel>
      <EditSection>
        <div css={styles.header}>Selected cohort</div>
        <div css={styles.description}>Select who should be scheduled for Meetups</div>
        <RadioGroup
          css={styles.flexCol}
          value={'noone'}
          onChange={(event) => {
            const { value } = event.target;
            if (['everyone', 'specific'].includes(value)) {
              history.push(`${Paths.MEETUPS}/settings?cohort=${value}`);
            }
          }}
        >
          <RadioButtonCard
            css={styles.card}
            label='Everyone in my org'
            subText={`${memberCount} members`}
            value='everyone'
          />
          <RadioButtonCard css={styles.card} label='A specific cohort' value='specific' />
          <RadioButtonCard
            css={styles.card}
            label='Nobody'
            subText='Meetups is disabled'
            value='noone'
            disabled
          />
        </RadioGroup>
        <Button shape='square' css={styles.setupButton} disabled>
          Set up Meetups
        </Button>
      </EditSection>
    </SidePanel>
  );
};

const styles = {
  header: css({
    color: Coal_10,
    fontWeight: 800,
    fontSize: '15px',
    lineHeight: '15px',
  }),
  description: css({
    color: Coal_10,
    opacity: '0.7',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '160%',
  }),
  flexCol: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  }),
  card: {
    '&:has(> .Mui-checked)': {
      background: Plum_60,
      border: `solid 1px ${Plum_30}`,
    },
  },
  setupButton: css({
    background: Mint_40,
    border: `solid 1px ${Mint_30}`,
    '--bg-disabled': Glue_LavenderLight,
    '--fg-disabled': Glue_Ink10,
  }),
};

export default SetupSection;
