import * as Tabs from '@radix-ui/react-tabs';
import TabsRow from 'glue/components/tabs/TabsRow';
import { tabStyles } from 'glue/components/tabs/styles';
import { forwardRef } from 'react';
import { useLocation } from 'react-router-dom';
import Link, { LinkProps } from 'glue/components/buttons/Link';

export const NavigationTabs = forwardRef<any, Tabs.TabsProps>(function NavigationTabs(
  props,
  ref,
) {
  const path = useLocation().pathname;
  return <Tabs.Tabs {...props} value={path} ref={ref} />;
});

export const NavigationTabsList = TabsRow;

export function NavigationTab({
  children,
  to,
  className,
  ...rest
}: Omit<LinkProps, 'to'> & { to: string; className?: string }) {
  return (
    <Tabs.TabsTrigger asChild css={tabStyles} value={to} className={className}>
      <Link to={to} {...rest}>
        {children}
      </Link>
    </Tabs.TabsTrigger>
  );
}
