import { Dispatch, SetStateAction, Fragment } from 'react';
import { css } from '@emotion/react';
import ChevronRight from '@mysteryco/design/icons/ChevronRight';
import { colors } from '@mysteryco/design';
import { StepStatus } from 'constants/Type';
import StepperLink, { StepperLinkProps } from './StepperLink';
import theme from 'theme';

export interface StepperBlockProps {
  steps: StepperLinkProps[];
  activeStep?: number;
  setActiveStep?: Dispatch<SetStateAction<number>>;
  [key: string]: any;
}

const StepperBlock = ({
  steps,
  activeStep = 0,
  setActiveStep,
  ...props
}: StepperBlockProps) => {
  return (
    <div css={styles.root} {...props}>
      {steps.map((step, i) => (
        <Fragment key={`step-${i}`}>
          <StepperLink
            text={step.text}
            status={
              step.status
                ? step.status
                : activeStep < i
                ? StepStatus.Initial
                : activeStep > i
                ? StepStatus.Completed
                : StepStatus.Current
            }
            Icon={step.Icon}
            iconText={`${i + 1}`}
            css={{ cursor: setActiveStep ? 'pointer' : 'default' }}
            onClick={() => setActiveStep?.(i)}
          />
          {i < steps.length - 1 && (
            <ChevronRight key={`chevron-${i}`} color={colors.Glue_Ink10} size={14} />
          )}
        </Fragment>
      ))}
    </div>
  );
};

const styles = {
  root: css({
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(4),
    },
  }),
};

export default StepperBlock;
