import React from 'react';
import theme from 'theme';

import { SerializedStyles, css } from '@emotion/react';

import { Coal_10, Coal_60, Plum_50 } from '@mysteryco/design/src/tokens/colors';
import LogoTypeNoWs from 'glue/components/branding/LogoTypeNoWs';

const Footer = () => (
  <div css={styles.footer}>Glue is your space for bonding and building happier teams</div>
);

const Illustration = ({
  src,
  animationStyle,
}: {
  src: string;
  animationStyle?: SerializedStyles | false;
}) => (
  <div css={[styles.imageContainer, animationStyle]}>
    <img
      src={src}
      alt='Smiling coworkers'
      style={{ width: '100%', height: '100%', overflow: 'hidden', objectFit: 'contain' }}
    />
  </div>
);

const AuthContainer = ({
  children,
  title,
  subTitle,
  leftIllustrationSrc,
  rightIllustrationSrc,
  logoImage,
  icon,
  loading,
}: {
  children: React.ReactNode;
  title: string;
  subTitle: string | React.ReactNode;
  showCodeMessage?: boolean;
  leftIllustrationSrc?: string;
  rightIllustrationSrc?: string;
  logoImage?: string | null;
  icon?: React.ReactNode;
  loading?: boolean;
}) => {
  const showIllustrations = !!(leftIllustrationSrc && rightIllustrationSrc);

  return (
    <div css={styles.page}>
      <div css={styles.container(showIllustrations)}>
        {showIllustrations && (
          <Illustration
            src={leftIllustrationSrc}
            animationStyle={loading && styles.slideOffscreenLeft}
          />
        )}
        <div css={styles.contentContainer}>
          <LogoTypeNoWs />
          {icon && <div css={styles.iconContainer}>{icon}</div>}
          <div>
            <div css={styles.title}>{title}</div>
            {!!logoImage && (
              <div>
                <img css={{ maxWidth: 350 }} src={logoImage} alt='Company logo' />
              </div>
            )}
            <div css={styles.subtitle}>{subTitle}</div>
          </div>
          {children}
        </div>
        {showIllustrations && (
          <Illustration
            src={rightIllustrationSrc}
            animationStyle={loading && styles.slideOffscreenRight}
          />
        )}
      </div>
      <Footer />
    </div>
  );
};

const styles = {
  page: css({
    fontFamily: 'Figtree',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  }),
  container: (showIllustrations: boolean) =>
    css({
      display: 'flex',
      flex: 1,
      justifyContent: showIllustrations ? 'space-between' : 'center',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        justifyContent: 'center',
      },
    }),
  imageContainer: css({
    flex: 1,
    maxWidth: '360px',
    minWidth: '120px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  }),
  image: css({
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    objectFit: 'contain',
  }),
  slideOffscreenLeft: css({
    transition: 'transform 800ms ease-in-out',
    transform: 'translateX(-150%)',
  }),
  slideOffscreenRight: css({
    transition: 'transform 800ms ease-in-out',
    transform: 'translateX(150%)',
  }),
  contentContainer: css({
    minWidth: 400,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: `${theme.spacing(4)} ${theme.spacing(20)}`,
  }),
  footer: css({
    flex: 0,
    background: Coal_60,
    color: Coal_10,
    fontSize: `calc(${theme.spacing(4)} - 1px)`,
    lineHeight: theme.spacing(5),
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(4),
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
  }),
  iconContainer: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(11),
    height: theme.spacing(11),
    borderRadius: theme.spacing(3.5),
    background: Plum_50,
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(7),
  }),
  title: css({
    fontSize: theme.spacing(7),
    fontWeight: 700,
    color: Coal_10,
  }),
  subtitle: css({
    fontSize: `calc(${theme.spacing(4)} - 1px)`,
    fontWeight: 500,
    color: Coal_10,
  }),
};

export default AuthContainer;
