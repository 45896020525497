import { Checkbox, FormControlLabel, FormGroup, Slider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@mysteryco/design';
import DropdownFilterButton from 'glue/components/inputs/DropdownFilterButton';
import { Popover, PopoverContent, PopoverTrigger } from 'glue/components/popover/Popover';
import { pluralize } from 'humanize-plus';
import _ from 'lodash';
import { Dispatch, SetStateAction, useState } from 'react';

export enum FilterType {
  RANGE,
  OPTIONS,
}

const selectedRangeLabel = (selectedRange: number[], unit: string = 'unit') => {
  return `${
    selectedRange[0] === selectedRange[1]
      ? selectedRange[0]
      : `${selectedRange[0]} - ${selectedRange[1]}`
  } ${pluralize(selectedRange[1], unit)}`;
};

const defaultSelectedOptionsLabel = (selectedOptions: boolean[], name: string) => {
  const numSelected = selectedOptions.filter((option) => option).length;
  return `${
    numSelected > 0 ? `${numSelected} ${pluralize(numSelected, name)}` : 'none'
  } selected`;
};

const RangeDropdown = ({
  caption,
  min = 0,
  max = 0,
  step,
  unit = 'unit',
  selectedRange,
  setSelectedRange,
}: {
  caption: string;
  min?: number;
  max?: number;
  step: number;
  unit?: string;
  selectedRange: number[];
  setSelectedRange?: Dispatch<SetStateAction<number[]>>;
}) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.caption}>{caption}</div>
      <div className={classes.selectedRangeBox}>
        <span>{selectedRange[0] === selectedRange[1] ? 'Exactly' : 'Between'}</span>
        <span className={classes.selectedRangeLabel}>
          {selectedRangeLabel(selectedRange, unit)}
        </span>
      </div>
      <Slider
        className={classes.rangeSlider}
        min={min}
        max={max}
        step={step}
        valueLabelDisplay='auto'
        value={selectedRange}
        onChange={(_event, newValue) => setSelectedRange?.(newValue as number[])}
      />
    </div>
  );
};

const OptionsDropdown = ({
  name,
  caption,
  options,
  selectedOptions,
  setSelectedOptions,
}: {
  name: string;
  caption: string;
  options: string[];
  selectedOptions: boolean[];
  setSelectedOptions?: Dispatch<SetStateAction<boolean[]>>;
}) => {
  const classes = useStyles();
  const allSelected = selectedOptions.every((option) => option);
  return (
    <div>
      <div className={classes.caption}>{caption}</div>
      {allSelected ? (
        <div className={classes.allSelected}>{'All selected'}</div>
      ) : (
        <div
          className={classes.selectAll}
          onClick={() => setSelectedOptions?.(selectedOptions.map(() => true))}
        >
          {`Select all ${options.length} ${pluralize(options.length, name)}`}
        </div>
      )}
      <FormGroup>
        {options.map((option, i) => {
          return (
            <FormControlLabel
              key={i}
              control={
                <Checkbox
                  color={'primary'}
                  checked={selectedOptions[i]}
                  name={`${i}`}
                  onChange={(event) => {
                    const changedIndex = parseInt(event.target.name);
                    setSelectedOptions?.(
                      selectedOptions.map((option, i) =>
                        i === changedIndex ? !option : option,
                      ),
                    );
                  }}
                />
              }
              label={<span className={classes.optionLabel}>{option}</span>}
            />
          );
        })}
      </FormGroup>
    </div>
  );
};

const ExperienceFilter = ({
  name,
  caption,
  type,
  min = 0,
  max = 0,
  step = 1,
  unit,
  options = [],
  selectedRange = [min ?? 0, max ?? 0],
  setSelectedRange,
  selectedOptions = [],
  setSelectedOptions,
  selectedOptionsLabel = defaultSelectedOptionsLabel,
  className = '',
  onClick,
}: {
  name: string;
  caption: string;
  type: FilterType;
  min?: number;
  max?: number;
  step?: number;
  unit?: string;
  options?: string[];
  selectedRange?: number[];
  setSelectedRange?: Dispatch<SetStateAction<number[]>>;
  selectedOptions?: boolean[];
  setSelectedOptions?: Dispatch<SetStateAction<boolean[]>>;
  selectedOptionsLabel?: (selectedOptions: boolean[], name: string) => string;
  className?: string;
  onClick?: () => void;
}) => {
  const classes = useStyles();
  const [expandDropdown, setExpandDropdown] = useState(false);
  if (FilterType.RANGE === type && selectedRange.length < 1) {
    selectedRange = [min, max];
  }
  if (FilterType.OPTIONS === type && selectedOptions.length < 1) {
    selectedOptions = options?.map(() => false) ?? [];
  }

  return (
    <Popover open={expandDropdown} onOpenChange={setExpandDropdown}>
      <PopoverTrigger className={className} onClick={onClick} asChild>
        <DropdownFilterButton
          label={_.startCase(name)}
          value={
            type === FilterType.OPTIONS
              ? selectedOptionsLabel(selectedOptions, name)
              : selectedRangeLabel(selectedRange, unit)
          }
        />
      </PopoverTrigger>
      <PopoverContent className={classes.dropdown}>
        {FilterType.OPTIONS === type ? (
          <OptionsDropdown
            name={name}
            caption={caption}
            options={options}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
          />
        ) : FilterType.RANGE === type ? (
          <RangeDropdown
            caption={caption}
            min={min}
            max={max}
            step={step}
            unit={unit}
            selectedRange={selectedRange}
            setSelectedRange={setSelectedRange}
          />
        ) : null}
      </PopoverContent>
    </Popover>
  );
};

const useStyles = makeStyles({
  dropdown: {
    display: 'flex',
    marginTop: '8px',
    width: '320px',
    maxHeight: '340px',
    fontSize: '14px',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  caption: {
    color: colors.Dusk,
    fontWeight: 500,
    lineHeight: '14px',
    marginBottom: '12px',
  },
  selectedRangeBox: {
    background: colors.Purple50,
    border: `1px solid ${colors.Purple100}`,
    borderRadius: '4px',
    color: colors.Dusk,
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '14px',
    padding: '12px',
    marginBottom: '10px',
  },
  selectedRangeLabel: {
    fontWeight: 700,
  },
  rangeSlider: {
    width: '97%',
    display: 'block',
    margin: 'auto',
  },
  allSelected: {
    width: 'fit-content',
    border: 'none',
    borderRadius: '14px',
    color: colors.Purple700,
    fontWeight: 700,
    padding: 0,
    fontSize: '12px',
    marginBottom: '10px',
  },
  selectAll: {
    cursor: 'pointer',
    background: colors.Purple100,
    width: 'fit-content',
    border: '0px',
    borderRadius: '14px',
    color: colors.Main,
    fontWeight: 700,
    padding: '4px 16px',
    fontSize: '12px',
    marginBottom: '10px',
    transition: 'all .3s ease-in-out',
    '&:hover': {
      background: colors.Purple200,
    },
  },
  optionLabel: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
  },
});

export default ExperienceFilter;
