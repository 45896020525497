import { css, keyframes } from '@emotion/react';
import { colors } from '@mysteryco/design';
import {
  Root,
  Content as ContentBase,
  Trigger,
  TooltipContentProps,
  Portal,
  Arrow,
  TooltipProps as RootProps,
} from '@radix-ui/react-tooltip';
import { forwardRef, ReactNode } from 'react';

export { TooltipProvider } from '@radix-ui/react-tooltip';

export type TooltipProps = TooltipContentProps &
  Pick<RootProps, 'delayDuration' | 'open' | 'onOpenChange' | 'defaultOpen'> & {
    content: ReactNode;
    /**
     * Must be a single element with forwarded ref that passes ...rest props to its
     * root element. If wrapping a button, the tooltip will not work when the button is disabled.
     */
    children: ReactNode;
    size?: TooltipSize;
  };

export type TooltipSize = 'small' | 'regular';

const Content = forwardRef<HTMLDivElement, TooltipContentProps & { size?: TooltipSize }>(
  ({ children, size, ...rest }, ref) => (
    <Portal>
      <ContentBase ref={ref} {...rest} css={styles.content} data-size={size}>
        {children}
        {size === 'small' && <Arrow css={styles.arrow} />}
      </ContentBase>
    </Portal>
  ),
);
Content.displayName = 'TooltipContent';

/**
 * Renders a tooltip on hover or focus.
 * Please don't use this for button-triggered menus! See <Popover />!
 */
export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
  (
    {
      children,
      content,
      size = 'regular',
      delayDuration = 0,
      open,
      onOpenChange,
      defaultOpen,
      ...props
    },
    ref,
  ) => (
    <Root
      delayDuration={delayDuration}
      open={open}
      onOpenChange={onOpenChange}
      defaultOpen={defaultOpen}
    >
      <Trigger asChild>{children}</Trigger>
      <Content sideOffset={5} align='center' side='top' size={size} ref={ref} {...props}>
        {content}
      </Content>
    </Root>
  ),
);
Tooltip.displayName = 'Tooltip';

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const styles = {
  content: css({
    position: 'relative',
    borderRadius: 4,
    padding: '20px 20px 24px 20px',
    border: `1px solid ${colors.Glue_BorderLight}`,
    boxShadow: `0px 10px 10px -5px #9BA0A60A, 0px 20px 25px -5px #9BA0A626`,
    backgroundColor: colors.Glue_Paper,
    color: colors.Glue_Ink00,
    userSelect: 'none',
    zIndex: 10000,
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',

    '@media (prefers-reduced-motion: no-preference)': {
      animationDuration: '400ms',
      animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
      willChange: 'transform, opacity',
      '&[data-state="delayed-open"][data-side="top"]': {
        animationName: slideDownAndFade,
      },
      '&[data-state="delayed-open"][data-side="right"]': {
        animationName: slideLeftAndFade,
      },
      '&[data-state="delayed-open"][data-side="bottom"]': {
        animationName: slideUpAndFade,
      },
      '&[data-state="delayed-open"][data-side="left"]': {
        animationName: slideRightAndFade,
      },
    },

    '&[data-size="small"]': {
      padding: '8px',
      backgroundColor: colors.Glue_Ink00,
      color: colors.Glue_Paper,
      alignItems: 'center',
      fontSize: '12px',
      lineHeight: '16px',
    },
  }),
  arrow: css({
    fill: 'inherit',
  }),
};
