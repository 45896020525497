// @ts-strict-ignore
import {
  Box,
  ButtonBase,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputBase,
  Radio,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@mysteryco/design';
import ArrowCircleLeft from '@mysteryco/design/icons/ArrowCircleLeft';
import Edit04 from '@mysteryco/design/icons/Edit04';
import Plus from '@mysteryco/design/icons/Plus';
import { forwardRef, ReactNode, useEffect, useState } from 'react';
import theme from 'theme';
import IconButton from 'components/IconButton';
import { cx } from '@emotion/css';
import SearchMd from '@mysteryco/design/icons/SearchMd';
import FlatButton from 'components/core/FlatButton';

export interface Option {
  id: string;
  [key: string]: any;
}

interface ContainerProps {
  className?: string;
  [key: string]: any;
}
export interface OptionMenuProps {
  options: Option[];
  values?: string[];
  onChange?: (event?: any, newValue?: string[]) => void;
  inputValue?: string;
  onInputChange?: (event?: any, filteredOptions?: Option[]) => void;
  activeValue?: Option;
  onActiveValueChange?: (event?: any, activeOption?: Option) => void;
  filterOptions?: (options: Option[], inputValue?: string) => Option[];
  onCreate?: (event?: any, newValue?: any) => Promise<string>;
  onSave?: (event?: any, updatedOption?: Option) => Promise<void>;
  renderOption?: (option: Option) => ReactNode;
  renderActive?: (
    option: Option,
    onChange: (event: any, newOption: Option) => void,
  ) => ReactNode;
  getValueAsOption?: (value: string) => Option;
  isValid?: (newOption: Option) => boolean;
  getOptionIsDisabled?: (option: Option) => boolean;
  multiple?: boolean;
  disableSelectAll?: boolean;
  disableCreate?: boolean;
  disableUpdate?: boolean;
  disableSearch?: boolean;
  disableFilter?: boolean;
  editOnCreate?: boolean;
  createPrompt?: ReactNode;
  helperText?: ReactNode;
  selectAllText?: ReactNode;
  placeholderText?: string;
  optionsContainerProps?: ContainerProps;
  editContainerProps?: ContainerProps;
  [key: string]: any;
}

export const filterOptionsDefault = (
  options: Option[],
  optionsQuery: string,
): Option[] => {
  if (!optionsQuery?.trim()) return options;
  if (!options?.length) return [];
  const query = optionsQuery.toLowerCase();
  return options.filter((option) => {
    const values = Object.values(option).join(' ').toLowerCase();
    return values.includes(query);
  });
};

export const getValueAsOptionDefault = (value: string): Option => {
  return { id: value };
};

export const isDuplicateDefault = (newOption: Option, options: Option[]) => {
  return newOption ? options.some((option) => option.id === newOption.id) : false;
};

const RenderOptionDefault = (option: Option) => {
  return option ? <Box>{option.id}</Box> : <></>;
};

const RenderActiveDefault = (
  value: Option,
  onChange: (event: any, newValue: Option) => void,
) => {
  const handleChange = (e) => {
    onChange(e, { ...value, id: e.target.value });
  };
  return <TextField value={value.id} onChange={handleChange} />;
};

const getValuesFromState = (state: {}): string[] => {
  return Object.entries(state).reduce(
    (selectedIds, [id, isSelected]) =>
      isSelected ? selectedIds.concat([id]) : selectedIds,
    [],
  );
};

const getStateFromValues = (values: string[]): {} => {
  if (!values) return {};
  return Object.fromEntries(values.map((value) => [value, true]));
};

const OptionMenu = forwardRef((props: any, ref: any) => {
  return (
    <div ref={ref}>
      <OptionMenuInternals {...props} />
    </div>
  );
});

const OptionMenuInternals = ({
  options = [],
  values = [],
  onChange = () => {},
  inputValue = '',
  onInputChange = () => {},
  onActiveValueChange = () => {},
  placeholderText = '',
  filterOptions = filterOptionsDefault,
  onCreate = async () => '',
  onSave = async () => {},
  renderOption = RenderOptionDefault,
  renderActive = RenderActiveDefault,
  getValueAsOption = getValueAsOptionDefault,
  getOptionIsDisabled = () => false,
  isValid = (newOption) => {
    return !isDuplicateDefault(newOption, options);
  },
  multiple = false,
  disableSearch = false,
  disableSelectAll = false,
  disableCreate = false,
  disableUpdate = false,
  disableFilter = false,
  editOnCreate = false,
  createPrompt = '',
  helperText = 'Select an option or create a new one',
  selectAllText = 'Select all options',
  optionsContainerProps = {},
  editContainerProps = {},
  ...props
}: OptionMenuProps) => {
  const classes = useStyles();

  const [selectedState, setSelectedState] = useState(getStateFromValues(values));
  const [activeState, setActiveState] = useState(null);
  const [inputState, setInputState] = useState(null);

  useEffect(() => {
    if (!values) return;
    setSelectedState(getStateFromValues(values));
  }, [values]);

  useEffect(() => {
    if (inputValue === null) return;
    setInputState(inputValue);
  }, [inputValue]);

  const filteredOptions = disableFilter ? options : filterOptions(options, inputState);

  const showActive = !!activeState;
  const showHelperText = !!helperText;
  const showSelectAll = !disableSelectAll && multiple;
  const showCreate =
    !disableCreate && (editOnCreate || isValid(getValueAsOption(inputState)));
  const allSelected =
    multiple && options.length === getValuesFromState(selectedState)?.length;

  const selected = (id: string): boolean => {
    return id in selectedState && selectedState[id];
  };

  const setSelected = (id: string, newValue = true) => {
    const newState = multiple
      ? {
          ...selectedState,
          [id]: newValue,
        }
      : { [id]: newValue };
    setSelectedState(newState);
    return newState;
  };

  const selectAll = (event: any) => {
    const newValue =
      options.length && !allSelected
        ? Object.fromEntries(options.map((option) => [option.id, true]))
        : {};
    setSelectedState(newValue);
    onChange(event, getValuesFromState(newValue));
  };

  const handleSelect = (event: any) => {
    const newValue = setSelected(event.target.name, event.target.checked);
    onChange(event, getValuesFromState(newValue));
  };

  const handleInputChange = (event: any) => {
    setInputState(event.target.value);
  };

  const handleActiveChange = (event: any, option: Option) => {
    setActiveState(option);
    onActiveValueChange(event, option);
  };

  const handleCreate = async (event: any, newValue: string) => {
    if (editOnCreate) {
      handleActiveChange(event, getValueAsOption(newValue));
      return;
    } else if (!isValid(getValueAsOption(newValue))) {
      return;
    }
    const result = await onCreate(event, newValue);
    const newState = setSelected(result);
    onChange(event, getValuesFromState(newState));
  };

  const handleSave = async (event: any, option: Option) => {
    await onSave(event, option);
    setActiveState(null);
  };

  return (
    <Box {...props} className={classes.container}>
      {!showActive ? (
        // List view
        <Box
          className={cx(
            classes.menuContainer,
            !disableSearch && classes.searchMenuContainer,
          )}
        >
          {/* Search bar */}
          {!disableSearch && (
            <FormControl className={classes.searchBar}>
              <SearchMd size={16} />
              <InputBase
                value={inputState}
                onChange={handleInputChange}
                placeholder={placeholderText}
                autoFocus
              />
            </FormControl>
          )}
          {/* Helper text */}
          {showHelperText && <Box className={classes.helperText}>{helperText}</Box>}
          {/* Create */}
          {showCreate && (
            <FormControl className={classes.createOption}>
              <ButtonBase onClick={async (e) => await handleCreate(e, inputState)}>
                {createPrompt || (
                  <>
                    <span className={classes.createLabel}>Create:</span>
                    <span className={classes.createText}>{inputState}</span>
                  </>
                )}
                <Plus size={20} color={colors.White} />
              </ButtonBase>
            </FormControl>
          )}
          {/* Select all */}
          {showSelectAll && (
            <FormControl
              className={cx(classes.selectAllOption, allSelected && classes.allSelected)}
              onClick={selectAll}
            >
              <ButtonBase>{allSelected ? 'All selected' : selectAllText}</ButtonBase>
            </FormControl>
          )}
          {/* Options */}
          <Box className={cx(classes.optionsContainer, optionsContainerProps?.className)}>
            {filteredOptions.length > 0 && (
              <>
                {/* Option item */}
                {filteredOptions.map((option) => {
                  return (
                    <FormControl
                      className={classes.optionItem}
                      key={option.id}
                      disabled={getOptionIsDisabled(option)}
                    >
                      <FormControlLabel
                        control={
                          multiple ? <Checkbox disableRipple /> : <Radio disableRipple />
                        }
                        label={
                          <Box className={classes.optionLabel}>
                            {renderOption(option)}
                          </Box>
                        }
                        name={option.id}
                        checked={selected(option.id)}
                        onChange={handleSelect}
                      />
                      {!disableUpdate && (
                        <IconButton onClick={(e) => handleActiveChange(e, option)}>
                          <Edit04 size={16} />
                        </IconButton>
                      )}
                    </FormControl>
                  );
                })}
              </>
            )}
          </Box>
        </Box>
      ) : (
        // Edit view
        <Box className={classes.editContainer}>
          {/* Back bar */}
          <ButtonBase
            className={classes.backBar}
            onClick={(e) => handleActiveChange(e, null)}
          >
            <ArrowCircleLeft size={16} color={colors.Main} />
            Back
          </ButtonBase>
          {/* Edit content */}
          <Box
            className={cx(classes.editContentContainer, editContainerProps?.className)}
          >
            {renderActive(activeState, (e, newOption) =>
              handleActiveChange(e, newOption),
            )}
          </Box>
          {/* Action Bar */}
          <Box className={classes.actionBar}>
            <FlatButton
              variant='secondary'
              onClick={(e) => handleActiveChange(e, null)}
              fullWidth
            >
              Cancel
            </FlatButton>
            <FlatButton
              variant='primary'
              onClick={(e) => handleSave(e, activeState)}
              disabled={!isValid(activeState)}
              fullWidth
            >
              Save
            </FlatButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    fontSize: '.875rem',
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(2)} 0 ${theme.spacing(3)}`,
    gap: theme.spacing(3),
  },
  searchMenuContainer: {
    paddingTop: 0,
  },
  searchBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: `0 ${theme.spacing(4)}`,
    gap: theme.spacing(2),
    background: colors.Purple50,
    borderBottom: `1px solid ${colors.LightGray}`,
    fontWeight: 500,
    width: '100%',
    borderRadius: `${theme.spacing(1)} ${theme.spacing(1)} 0 0`,
    '& .MuiInputBase-root': {
      flexGrow: 1,
      '& .MuiInputBase-input': {
        fontSize: '.875rem',
        lineHeight: 1,
        color: colors.Black,
        padding: `${theme.spacing(4)} 0`,
        '&::placeholder': {
          opacity: 1,
          color: colors.Purple700,
        },
      },
    },
  },
  helperText: {
    padding: `${theme.spacing(3)} ${theme.spacing(4)} 0`,
    fontWeight: 500,
    lineHeight: 1,
    color: colors.Dusk,
  },
  selectAllOption: {
    display: 'flex',
    padding: `${theme.spacing(1)} ${theme.spacing(4)}`,
    alignItems: 'flex-start',
    margin: '1px 0',
    '& .MuiButtonBase-root': {
      textAlign: 'left',
      fontWeight: 700,
      fontSize: '.75rem',
      lineHeight: 1.67,
      textDecorationLine: 'underline',
      color: colors.Main,
    },
  },
  allSelected: {
    padding: `0 ${theme.spacing(4)}`,
    margin: 0,
    '& .MuiButtonBase-root': {
      padding: `${theme.spacing(1)} ${theme.spacing(4)}`,
      background: colors.Green100,
      border: `1px solid ${colors.Green300}`,
      borderRadius: theme.spacing(3.5),
      '&.MuiButtonBase-root': {
        textDecorationLine: 'none',
        color: colors.Green900,
      },
    },
  },
  createOption: {
    '& .MuiButtonBase-root': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
      gap: theme.spacing(3),
      background: colors.Main,
      borderWidth: '1px 0',
      borderStyle: 'solid',
      borderColor: colors.Purple100,
      alignSelf: 'stretch',
      color: colors.White,
      transition: 'background 300ms ease-in-out',
      '&:focus': {
        background: colors.Dusk,
      },
    },
  },
  createLabel: {
    flex: 'none',
    fontWeight: 700,
  },
  createText: {
    flexGrow: 1,
    textAlign: 'left',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '100%',
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '18rem',
    overflowY: 'auto',
  },
  optionItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: `0 ${theme.spacing(4)}`,
    gap: theme.spacing(3),
    '& .MuiFormControlLabel-root': {
      width: '100%',
    },
    '&:hover, &:focus-within': {
      backgroundColor: colors.Green100,
    },
  },
  optionLabel: {},
  editContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  backBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    background: colors.Purple50,
    borderBottom: `1px solid ${colors.LightGray}`,
    color: colors.Main,
    lineHeight: 1,
  },
  editContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    maxHeight: '18rem',
  },
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'stretch',
    padding: `${theme.spacing(3)} ${theme.spacing(4)} ${theme.spacing(4)}`,
    gap: theme.spacing(5),
    background: colors.Purple50,
    borderTop: `1px solid ${colors.LightGray}`,
  },
  cancelButton: {
    padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  },
  saveButton: {
    padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
    background: colors.Main,
    border: `1px solid ${colors.Main}`,
    borderRadius: theme.spacing(2),
    color: colors.White,
    flexGrow: 1,
    fontWeight: 700,
  },
});

export default OptionMenu;
