import { css } from '@emotion/react';
import { Dialog } from '@material-ui/core';
import { Coal_10, Coal_40 } from '@mysteryco/design/src/tokens/colors';
import TextLink from 'glue/components/buttons/TextLink';
import { useState } from 'react';
import PreviewEmail from './PreviewEmail';
import theme from 'theme';

type MeetupSummaryProps = {
  firstName: string;
  memberCount: number;
  orgName: string;
};

const Copy = ({
  title,
  subtitle,
  children,
}: {
  title: string;
  subtitle: string;
  children?: React.ReactNode;
}) => {
  return (
    <div css={styles.flexCol}>
      <p css={styles.title}>{title}</p>
      <p css={[styles.title, styles.subtitle]}>{subtitle}</p>
      {children}
    </div>
  );
};

const CircledNumber = ({ children }: { children: React.ReactNode }) => {
  return <div css={styles.circledNumber}>{children}</div>;
};

const Row = ({ number, children }: { number: number; children: React.ReactNode }) => {
  return (
    <div css={styles.row}>
      <CircledNumber>{number}</CircledNumber>
      {children}
    </div>
  );
};

const ActiveMeetupSummary = (props: MeetupSummaryProps) => {
  const [showPreviewEmail, setShowPreviewEmail] = useState(false);
  const compiledStrings = strings(props);

  return (
    <>
      <Row number={1}>
        <Copy {...compiledStrings.notified}>
          <TextLink onClick={() => setShowPreviewEmail(true)} visualOnly>
            Preview Email
          </TextLink>
        </Copy>
      </Row>
      <Row number={2}>
        <Copy {...compiledStrings.pairings} />
      </Row>
      <Row number={3}>
        <Copy {...compiledStrings.engagement} />
      </Row>
      <Dialog
        PaperProps={{ style: { borderRadius: '16px' } }}
        open={showPreviewEmail}
        onClose={() => setShowPreviewEmail(false)}
      >
        <PreviewEmail firstName={props.firstName} orgName={props.orgName} />
      </Dialog>
    </>
  );
};

const strings = (props: MeetupSummaryProps) => ({
  notified: {
    title: `${props.memberCount} participants will be notified by email`,
    subtitle: 'They will receive a link to customize their preferences.',
  },
  pairings: {
    title: 'In 48h, Glue will schedule your first round of Meetups',
    subtitle: 'This gives participants time to set preferences or opt-out.',
  },
  engagement: {
    title: 'Track Meetups engagement',
    subtitle: 'Reporting will be available once Meetups are scheduled.',
  },
});

const styles = {
  flexCol: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  circledNumber: css({
    borderRadius: theme.spacing(8),
    width: theme.spacing(8),
    height: theme.spacing(8),
    border: `solid 1px ${Coal_40}`,
    fontSize: theme.spacing(3.5),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 600,
    color: Coal_10,
  }),
  row: css({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(5),
  }),
  title: css({
    fontWeight: 500,
    color: Coal_10,
  }),
  subtitle: css({
    opacity: 0.7,
  }),
  metricsCard: css({
    flex: 1,
  }),
  settingsRow: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.spacing(4),
  }),
};

export default ActiveMeetupSummary;
