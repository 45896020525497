// @ts-strict-ignore
import FlatButton from 'components/core/FlatButton';
import { pluralize } from 'humanize-plus';
import { useState } from 'react';
import {
  GetUserTeamsQuery,
  MemberFragmentFragment,
  useArchiveMembersMutation,
  useGetUserTeamsLazyQuery,
  useRestoreMembersMutation,
} from 'types';
import { TeamPageOrganization } from '.';
import ArchiveConfirmation from './Modals/ArchiveConfirmation';

interface ArchiveButtonProps {
  afterUpdate?: (alertMessage: string) => void;
  members: Set<MemberFragmentFragment>;
  organization: TeamPageOrganization;
  restoreMode?: boolean;
}

const getIds = (members: Set<MemberFragmentFragment>): string[] => {
  return [...members].map((member) => member.id);
};

const getMembersExcluding = (
  userData: GetUserTeamsQuery,
  excludedMembers: MemberFragmentFragment[],
): MemberFragmentFragment[] => {
  let otherMembers = [];
  if (userData && userData.orgUsersConnection.edges) {
    otherMembers = userData.orgUsersConnection.edges
      .map((edge) => edge.node)
      .filter((user) => !excludedMembers.some((member) => member.id === user.id));
  }
  return otherMembers;
};

const ArchiveButton = ({
  afterUpdate = () => {},
  members,
  organization,
  restoreMode = false,
}: ArchiveButtonProps) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [restoreOrgMembers, { loading: restoreLoading }] = useRestoreMembersMutation({
    variables: { id: organization.id, usersToRestore: getIds(members) },
    refetchQueries: ['searchOrgUsers'],
    awaitRefetchQueries: true,
  });
  const [archiveOrgMembers, { loading: archiveLoading }] = useArchiveMembersMutation({
    variables: { id: organization.id, usersToArchive: getIds(members) },
    refetchQueries: ['searchOrgUsers'],
    awaitRefetchQueries: true,
  });
  const [getMembers, { data: userData }] = useGetUserTeamsLazyQuery({
    variables: { orgId: organization.id },
  });

  const otherMembers = getMembersExcluding(userData, [...members]);

  const loading = restoreLoading || archiveLoading;
  const message = `${members.size} ${pluralize(
    members.size,
    'member has',
    'members have',
  )} been ${restoreMode ? 'unarchived' : 'archived'}`;

  const handleClick = async () => {
    if (restoreMode) {
      await restoreOrgMembers();
      afterUpdate(message);
    } else {
      getMembers();
      setConfirmOpen(true);
    }
  };

  const handleArchive = async () => {
    await archiveOrgMembers();
    setConfirmOpen(false);
    afterUpdate(message);
  };

  return (
    <>
      <FlatButton
        variant='secondary'
        corners='rounded'
        disabled={loading}
        onClick={handleClick}
      >
        {restoreMode ? 'Unarchive' : 'Archive'}
      </FlatButton>
      <ArchiveConfirmation
        members={members}
        availableManagers={otherMembers}
        isShowing={confirmOpen}
        isHrisConnected={organization.isHrisConnected}
        loading={loading}
        onArchive={handleArchive}
        onCancel={() => setConfirmOpen(false)}
      />
    </>
  );
};

export default ArchiveButton;
