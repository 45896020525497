import { gql } from '@apollo/client';
import bezier, { EasingFunction } from 'bezier-easing';
import * as visNetwork from 'vis-network';

import {
  InsightsGraphEngagementEdgeFragment,
  InsightsGraphOrganizationGlueFragment,
  InsightsGraphOrganizationTeamGlueFragment,
  InsightsSidePanelTeamFragment,
  ScoreType,
} from 'types';

export enum NodeType {
  Member = 'Member',
  Team = 'Team',
}

export interface Node extends visNetwork.Node {
  id: string;
  name: string;
  role: string;
  type: NodeType;
  members?: { id: string }[];
  font: visNetwork.Font;
  connection?: number;
  connectionStrengthRating?: string;
}

export interface Edge extends visNetwork.Edge {
  id: string;
  from: string;
  to: string;
  connection: number;
  connectionStrengthRating?: string;
}

export interface Graph {
  nodes: Node[];
  edges: Edge[];
}

export interface Configuration {
  flattenMembers: boolean;
  flattenTeams: boolean;
  showEdge: number;
  strongModerate: number;
  moderateSubpar: number;
  subparWeak: number;
  minNodeSize: number;
  maxNodeSize: number;
  managerNodeSize: number;
  nodeSizeCurve: EasingFunction & { points: [number, number, number, number] };
}

export interface GraphData {
  graph: Graph;
  nodeMap: { [id: string]: Node };
  edgeMap: { [id: string]: Edge };
}

export type NodeSizeCurve = EasingFunction & { points: [number, number, number, number] };
export type NodeEngagementScores = Record<ScoreType, number>;

export function createNodeSizeCurve(
  ...points: [number, number, number, number]
): NodeSizeCurve {
  const curve = bezier(...points) as NodeSizeCurve;
  curve.points = points;

  return curve;
}

// GraphQL

export const dataFragments = gql`
  fragment InsightsGraphOrganizationTeamGlue on Team {
    id
    name
    manager {
      id
      name
      firstName
      lastName
      companyRole
      tags {
        id
        name
        type
      }
    }
    members {
      id
      name
      firstName
      lastName
      companyRole
      startDate
    }
    childTeams {
      id
    }
  }

  fragment InsightsGraphOrganizationGlue on Organization {
    id
    rootTeam {
      id
    }
    teams {
      id
      ...InsightsGraphOrganizationTeamGlue
    }
  }

  fragment InsightsGraphEngagementEdge on ScalarEngagementEdge {
    fromUserId
    toUserId
    connection
  }
`;

export type OrganizationFragment = InsightsGraphOrganizationGlueFragment;
export type TeamFragment = InsightsSidePanelTeamFragment &
  InsightsGraphOrganizationTeamGlueFragment;
export type MemberFragment = TeamFragment['members'][number];

export type EngagementEdgeFragment = InsightsGraphEngagementEdgeFragment;
