import { makeStyles } from '@material-ui/styles';
import { colors } from '@mysteryco/design';
import MessageAlertCircle from '@mysteryco/design/icons/MessageAlertCircle';
import imageBackup from 'assets/catalog/image-backup.png';
import Clock from 'components/icons/Clock';
import Credit from 'components/icons/Credit';
import CurrencyDollar from 'components/icons/CurrencyDollar';
import { EncircleIcon } from 'components/icons/EncircleIcon';
import ShoppingBag02 from 'components/icons/ShoppingBag02';
import Ticket01 from 'components/icons/Ticket01';
import { pluralize } from 'humanize-plus';
import { centsToDollarString, getTemplatePrice } from 'lib/helpers/money';
import _ from 'lodash';
import { useState } from 'react';
import theme from 'theme';
import { ContractUnitType, MysteryTemplate, Xperience } from 'types';
import { TWO_DOLLAR_THRESHOLD, THREE_DOLLAR_THRESHOLD } from 'constants/Costs';

const displayPriceLevel = (price) => {
  let priceLevel = 1;
  if (price > TWO_DOLLAR_THRESHOLD) priceLevel++;
  if (price > THREE_DOLLAR_THRESHOLD) priceLevel++;
  return _.range(priceLevel).map((i) => (
    <CurrencyDollar key={i} color={colors.Purple700} size={8} />
  ));
};

const ExperienceCard = ({
  template,
  experiences,
  contractType,
  totalGuests,
  ...props
}: {
  template: MysteryTemplate;
  experiences: Xperience[];
  contractType: ContractUnitType;
  totalGuests: number;
  [key: string]: any;
}) => {
  const classes = useStyles();
  const [hover, setHover] = useState(false);
  const price = getTemplatePrice({ template, contractType });
  const hasOneExperienceWithSupplies = experiences.find(
    (experience) => experience.hasPhysicalGoods,
  );
  const lowestMinimumGuests = Math.min(
    ...experiences.map((experience) => experience.numMinGuests ?? 0),
  );
  const highestMaximumGuests = Math.max(
    ...experiences.map((experience) => experience.numMaxGuests ?? Infinity),
  );
  const guestCountTooBig = totalGuests > highestMaximumGuests;
  const guestCountTooSmall = totalGuests < lowestMinimumGuests;

  return (
    <div
      className={classes.cardContainer}
      onPointerEnter={() => setHover(true)}
      onPointerLeave={() => setHover(false)}
      {...props}
    >
      <div className={classes.flexCol}>
        <div>
          {/* IMAGE AREA */}
          <div css={{ position: 'relative' }}>
            {hover && (
              <div className={classes.photoOverlay}>
                {(guestCountTooBig || guestCountTooSmall) && (
                  <>
                    <MessageAlertCircle color={colors.White} />
                    <p>{`Your guest count is too ${
                      guestCountTooBig ? 'big' : 'small'
                    } for this experience`}</p>
                  </>
                )}
              </div>
            )}
            <img
              className={classes.eventPhoto}
              src={template.photoUrl || imageBackup}
              alt={'experience'}
            />
            {hasOneExperienceWithSupplies && (
              <EncircleIcon
                className={classes.shoppingIcon}
                backgroundColor={colors.White}
              >
                <ShoppingBag02 />
              </EncircleIcon>
            )}
            {hover && hasOneExperienceWithSupplies && (
              <div className={classes.supplyTag}>Includes supplies</div>
            )}
          </div>
          {/* DETAILS */}
          <div className={classes.eventDetails}>
            <p className={classes.eventName}>{template.title}</p>
          </div>
          <div className={classes.eventDescription}>
            <p>{template.description}</p>
          </div>
          <div className={classes.eventPrice}>
            {contractType === ContractUnitType.Credits ? (
              <div className={classes.creditPrice}>
                <Credit />
                {`${price} ${pluralize(price, 'credit')} /guest`}
              </div>
            ) : contractType === ContractUnitType.Recurring ? (
              <div className={classes.creditPrice}>
                <Ticket01 />1 seat /guest
              </div>
            ) : (
              `From ${centsToDollarString(price * 100)}/guest`
            )}
            {![ContractUnitType.Credits, ContractUnitType.Recurring].includes(
              contractType,
            ) && <div className={classes.priceSymbol}>{displayPriceLevel(price)}</div>}
          </div>
        </div>
        {/* TAGS */}
        <div className={classes.eventOverview}>
          <div className={classes.overviewTag}>
            <Clock className={classes.overviewTagIcon} color='#828282' />
            {` ${template.estimatedDurationMins} min`}
          </div>
          {!!template.categories?.length && (
            <div className={classes.categoryTag}>
              {template.categories[0]?.name ?? ''}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  cardContainer: {
    cursor: 'pointer',
    width: '100%',
    maxWidth: '460px',
    overflow: 'hidden',
    borderRadius: '12px',
    padding: '16px',
    background: colors.White,
    border: `2px solid ${colors.Purple100}`,
    minHeight: '280px',
    transition: 'all 0.3s ease-out',
    alignSelf: 'start',
    '@media (min-width: 700px)': {
      width: '260px',
      maxWidth: '260px',
      minWidth: '260px',
      height: '300px',
    },
    '&:hover': {
      height: 'min-content',
      borderColor: colors.Purple500,
      boxShadow: '0px 6px 12px rgba(123, 23, 131, 0.1)',
      '@media (min-width: 700px)': {
        transform: 'translateY(-10%)',
      },
      '& $eventDetails': {
        height: 'auto',
        marginBottom: '8px',
      },
      '& $eventDescription': {
        opacity: 1,
        height: 'auto',
      },
    },
  },
  eventPhoto: {
    width: '100%',
    height: '120px',
    minHeight: '120px',
    maxHeight: '120px',
    borderRadius: '4px',
    objectFit: 'cover',
    objectPosition: 'center',
    background: colors.Purple500,
    '@media (max-width: 700px)': {
      height: '220px',
      minHeight: '220px',
      maxHeight: '220px',
    },
  },
  photoOverlay: {
    position: 'absolute',
    background: '#C34BCC',
    mixBlendMode: 'normal',
    borderRadius: '4px',
    opacity: 0.7,
    backdropFilter: 'blur(4px)',
    zIndex: 5,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: colors.White,
  },
  supplyTag: {
    padding: '8px',
    background: colors.Midnight,
    borderRadius: '4px',
    color: colors.White,
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    width: 'fit-content',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 10,
  },
  shoppingIcon: {
    position: 'absolute',
    right: '6px',
    bottom: '6px',
    zIndex: 10,
  },
  eventDetails: {
    height: '48px',
  },
  eventName: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 700,
    color: colors.Midnight,
    margin: 0,
    paddingTop: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
    '@media (max-width: 700px)': {
      fontSize: '18px',
      lineHeight: '24px',
    },
  },
  eventDescription: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    color: theme.palette.grey[900],
    marginBottom: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
    opacity: 0,
    height: 0,
    transition: 'all 0.5s ease-out',
  },
  eventPrice: {
    height: '32px',
    fontWeight: 500,
    lineHeight: '20px',
    color: colors.Main,
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  creditPrice: {
    display: 'flex',
    alignItems: 'center',
    '& > :first-of-type': {
      marginRight: theme.spacing(1),
    },
  },
  priceSymbol: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > :not(:first-of-type)': {
      marginLeft: theme.spacing(1),
    },
  },
  categoryTag: {
    color: colors.Main,
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 500,
    background: colors.Purple50,
    border: `1px solid ${colors.Purple200}`,
    borderRadius: '6px',
    padding: '3px 8px',
    marginRight: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
  eventOverview: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    color: colors.Purple700,
    fontWeight: 500,
    fontSize: '12px',
  },
  overviewTagIcon: {
    marginRight: theme.spacing(1),
  },
  overviewTag: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.grey[700],
    minWidth: '4rem',
    paddingBottom: '0.15rem',
  },
  flexCol: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
});

export default ExperienceCard;
