import React from 'react';
import ReactFullHeight from 'react-full-height-container';

import { Colors, Sizes } from 'constants/index';
import { HEADER_BOX_SHADOW } from 'constants/Colors';
import useResponsive from 'hooks/useResponsive';

import { makeStyles } from '@material-ui/styles';
import { Link } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: (props: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${props.verticalHeaderPadding}px ${props.horizontalHeaderPadding}px`,
    backgroundColor: Colors.Brand.White,
    boxShadow: HEADER_BOX_SHADOW,
    zIndex: 1,
  }),
  grid27: {
    width: Sizes.GRID_UNIT * 27,
  },
  logo: {
    width: '100%',
    height: 'auto',
    cursor: 'pointer',
    alignItems: 'center',
    boxSizing: 'content-box',
    paddingLeft: Sizes.GRID_UNIT * 2,
  },
  children: (props) => ({
    flex: 1,
    backgroundColor: Colors.Palette.Grey100,
    paddingTop: props.verticalHeaderPadding,
  }),
});

const PageWrapper = ({ children }) => {
  const responsive = useResponsive();

  const horizontalHeaderPadding = responsive({
    small: Sizes.TWENTY,
    medium: Sizes.TWENTY,
    large: Sizes.SPACING_MEDIUM,
    xlarge: Sizes.SPACING_MEDIUM,
  });

  const verticalHeaderPadding = responsive({
    small: Sizes.SPACING_XSMALL,
    medium: Sizes.SPACING_XSMALL,
    large: Sizes.SPACING_SMALL,
    xlarge: Sizes.SPACING_SMALL,
  });

  const classes = useStyles({ horizontalHeaderPadding, verticalHeaderPadding });

  return (
    <ReactFullHeight>
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <Link href='#'>
            <div>
              <div className={classes.grid27}>
                <img
                  src='//mystery-public-assets.s3.amazonaws.com/header_logo_web.png'
                  className={classes.logo}
                  alt='Mystery Logo'
                />
              </div>
            </div>
          </Link>
        </div>

        <div className={classes.children}>{children}</div>
      </div>
    </ReactFullHeight>
  );
};

export default PageWrapper;
