import { css } from '@emotion/react';
import CheckVerified01 from '@mysteryco/design/icons/CheckVerified01';
import { colors } from '@mysteryco/design/src';
import theme from 'theme';

interface Props {
  className?: string;
}

const MoralePulseSurveyFooter = ({ className }: Props) => {
  return (
    <div css={styles.footer} className={className}>
      <div css={styles.leftContainer}>
        <CheckVerified01 size={18} color='#3B3B3B' style={{ fill: 'transparent' }} />
        <div css={styles.leftText}>
          This is a safe space. Your answers are 100% anonymous.
        </div>
      </div>
      <div css={styles.rightText}>Powered by Glue</div>
    </div>
  );
};

const styles = {
  footer: css({
    position: 'sticky',
    bottom: 0,
    width: '100%',
    justifySelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    height: '78px',
    marginTop: theme.spacing(4),
    '@media (min-width: 960px)': {
      flexDirection: 'row',
      padding: `0 ${theme.spacing(20)}`,
      justifyContent: 'space-between',
      height: '56px',
    },
    backgroundColor: colors.Glue_LavenderLight,
    color: colors.Glue_Ink00,
  }),
  leftContainer: css({
    display: 'flex',
  }),
  leftText: css({
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '20px',
    '@media (min-width: 960px)': {
      fontSize: '14px',
      lineHeight: '20px',
    },
    marginLeft: theme.spacing(2),
  }),
  rightText: css({
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: 1,
    '@media (min-width: 960px)': {
      fontSize: '12px',
      lineHeight: '16px',
    },
  }),
};

export default MoralePulseSurveyFooter;
