// @ts-strict-ignore
import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core/styles';
import NewPrimaryButton from 'components/core/NewPrimaryButton';
import mixpanel from 'mixpanel-browser';
import { default as React, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Paths } from 'Routes';
import {
  getCurrentStepIndex,
  navigateToNextStep,
  NavigateToNextStepOptions,
} from '../../lib/helpers/router';
import theme from '../../theme';
import NextButton from '../Checkout/steps/NextButton';
import { Props } from './types';

type StepWrapperProps = {
  title: string;
  subTitle?: string;
  fullWidth?: boolean;
  children: React.ReactNode;
  steps: [];
  activeStep: string;
  stepIsValid?: boolean;
  hideBackButton?: boolean; // Hides the back button without sacrificing the button layout
  validOverride?: boolean;
  onNext?(navigateForward: (options?: NavigateToNextStepOptions) => void): void;
  onBack?(navigateBack: Function): void;
  bookSuccessful?: boolean;
  customNextText?: string;
  nextButtonLoading?: boolean;
  footerLink?: React.ReactNode;
  hideNext?: boolean;
  messageWidget?: React.ReactNode;
} & Partial<Props<{}>>;

export const STEP_WRAPPER_SUBTITLE_STYLE = {
  fontSize: '1rem',
  fontWeight: theme.typography.fontWeightLight,
};

const useStyles = makeStyles<Theme, Partial<StepWrapperProps>>((theme) => ({
  flowContentBox: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    maxWidth: 1200,
    margin: 'auto',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      maxWidth: '90%',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap-reverse',
      paddingBottom: theme.spacing(12),
    },
  },
  container: ({ fullWidth }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(10),
    marginBottom: '6rem',
    flex: fullWidth ? '1' : '0 0 540px',
    overflow: 'auto',
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.down('md')]: {
      flex: '0 0 90%',
      minWidth: '90%',
      marginRight: 0,
      marginBottom: theme.spacing(4),
    },
  }),
  header: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    '> *': {
      marginRight: theme.spacing(2),
    },
  },
  subTitle: {
    ...STEP_WRAPPER_SUBTITLE_STYLE,
  },
  footer: {
    display: 'flex',
    width: '100%',
    padding: `${theme.spacing(10)} 0px`,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
  },
  footerButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  footerLink: {
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '100%',
    marginRight: theme.spacing(5),
    marginTop: theme.spacing(3),
  },
  button: {
    marginRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    textDecoration: 'none',
  },
  linkStyle: {
    textDecoration: 'none',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  contentWrapper: {
    textAlign: 'inherit',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      textAlign: 'left',
    },
  },
  childrenWrapper: {
    background: 'red',
  },
}));

const StepWrapper = ({
  title,
  subTitle,
  fullWidth,
  activeStep,
  steps,
  children,
  stepIsValid,
  validOverride,
  onNext,
  onBack,
  hideBackButton,
  bookSuccessful,
  customNextText,
  nextButtonLoading = false,
  footerLink,
  hideNext,
  messageWidget,
}: StepWrapperProps) => {
  const classes = useStyles({ fullWidth });
  const history = useHistory();

  const goBack = async () => {
    const navigateBack = () => history.goBack();
    if (onBack) return await onBack(navigateBack);
    navigateBack();
  };

  // Make sure that steps can be only triggered once.
  //
  // Also note that we use a ref rather than state here, because if someone
  // is fast, they can trigger multiple events within the same frame.
  const isNavigating = useRef(false);
  const onProceed = async () => {
    const navigateForward = (options?: NavigateToNextStepOptions) =>
      navigateToNextStep({ history, activeStep, steps }, options);

    if (stepIsValid && !isNavigating.current) {
      isNavigating.current = true;
      mixpanel.track('next step navigated to', { source: activeStep });
      if (onNext) {
        try {
          return onNext(navigateForward);
        } catch (error) {
          console.error('Error when navigating to the next step:', error);
          isNavigating.current = false;
        }
      }
      navigateForward();
    }
  };

  const shouldShowBackButton = () => {
    if (bookSuccessful) return false;
    if (hideBackButton) return false;
    return true;
  };

  return (
    <Box className={classes.flowContentBox}>
      <Box className={classes.container}>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Box className={classes.contentWrapper}>
            <Box mb={2}>
              <Typography variant='h3'>{title}</Typography>
            </Box>
            {subTitle && <Typography className={classes.subTitle}>{subTitle}</Typography>}
            <Box width='100%' mt={8}>
              {children}
            </Box>
          </Box>
        </Box>
        <Box>
          <div className={classes.footer}>
            {bookSuccessful ? (
              <NewPrimaryButton
                label='View booked events'
                onClick={() => history.push(Paths.EVENTS)}
              />
            ) : (
              <Box
                className={classes.footerButtons}
                css={hideBackButton && { justifyContent: 'flex-end' }}
              >
                {shouldShowBackButton() && (
                  <Button
                    onClick={() => {
                      mixpanel.track('previous step navigated to', {
                        source: activeStep,
                      });
                      goBack();
                    }}
                    disabled={getCurrentStepIndex({ activeStep, steps }) === 0}
                    className={classes.button}
                  >
                    Back
                  </Button>
                )}
                {!hideNext && (
                  <NextButton
                    loading={nextButtonLoading}
                    onClick={onProceed}
                    disabled={!stepIsValid || validOverride}
                    label={customNextText}
                  />
                )}
              </Box>
            )}

            {footerLink && (
              <Box className={classes.footerLink}>
                <Box>{footerLink}</Box>
              </Box>
            )}
          </div>
        </Box>
      </Box>
      {!fullWidth && !!messageWidget && messageWidget}
    </Box>
  );
};

export default StepWrapper;
