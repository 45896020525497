import { cx } from '@emotion/css';
import { Switch, SwitchProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ChangeEvent, forwardRef } from 'react';
import theme from 'theme';

export interface ToggleProps extends SwitchProps {
  className?: string;
  disabled?: boolean;
  checked?: boolean;
  /**
   * Simplified access to checked state on change.
   *
   * Avoiding collision with default onChange is important to continue
   * integrating with MUI systems like FormControlLabel
   */
  onCheckedChange?: (value: boolean) => void;
}

const Toggle = forwardRef<HTMLButtonElement, ToggleProps>(
  (
    { className, disabled = false, checked = false, onCheckedChange, onChange, ...rest },
    ref,
  ) => {
    const classes = useStyles();
    const handleOnChange = (e: ChangeEvent<HTMLInputElement>, newChecked: boolean) => {
      onChange?.(e, newChecked);
      onCheckedChange?.(newChecked);
    };

    return (
      <Switch
        ref={ref}
        checked={checked}
        onChange={handleOnChange}
        disabled={disabled}
        className={cx(classes.toggle, className)}
        disableRipple
        {...rest}
      />
    );
  },
);
Toggle.displayName = 'Toggle';

const useStyles = makeStyles({
  toggle: {
    width: 36,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#A8ECBC',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#ECEEF5',
        },
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: '#FFFFFF',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#ECEEF5',
      },
    },
    '& .MuiSwitch-thumb': {
      color: '#FFFFFF',
      boxSizing: 'border-box',
      width: 16,
      height: 16,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#DEE1F0',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
    '&:hover': {
      '& .MuiSwitch-track': {
        backgroundColor: '#CACEE6',
      },
      '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
          '& + .MuiSwitch-track': {
            backgroundColor: '#96D8A9',
          },
        },
      },
    },
  },
});

export default Toggle;
