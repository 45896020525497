import { cx } from '@emotion/css';
import { Popper, Tooltip as MuiTooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@mysteryco/design';
import theme from 'theme';

type Props = {
  title: string;
  placement?:
    | 'bottom-start'
    | 'bottom-end'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  children: any;
  offset?: string;
  disableFlip?: boolean;
  [key: string]: any;
};

const TooltipSmall = ({
  title,
  placement = 'bottom-start',
  children,
  offset,
  disableFlip = false,
  ...props
}: Props) => {
  const classes = useStyles();
  return (
    <MuiTooltip
      title={title}
      PopperProps={{
        modifiers: {
          flip: { enabled: !disableFlip },
          offset: { enabled: !!offset, offset },
        },
      }}
      PopperComponent={(props) => (
        <Popper {...props} className={cx(classes.tooltip, props?.className)} />
      )}
      placement={placement}
      arrow
      {...props}
    >
      <div>{children}</div>
    </MuiTooltip>
  );
};

const useStyles = makeStyles({
  tooltip: {
    zindex: theme.zIndex.tooltip,
    '& .MuiTooltip-tooltip': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      padding: theme.spacing(2),
      gap: theme.spacing(2.5),
      background: colors.Glue_Ink00,
      borderRadius: theme.spacing(1),
      fontWeight: 500,
      fontSize: '.75rem',
      lineHeight: 1.33,
      color: colors.Glue_Paper,
    },
    '& .MuiTooltip-arrow': {
      '&::before': {
        background: colors.Glue_Ink00,
        borderRadius: 1,
      },
    },
  },
});

export default TooltipSmall;
