import theme from 'theme';

const Check = ({
  size = 24,
  color = theme.palette.primary.main,
  style = {},
  className = '',
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      style={style}
      viewBox={`0 0 16 16`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.0404 4.70711C14.431 4.31658 14.431 3.68342 14.0404 3.29289C13.6499 2.90237 13.0167 2.90237 12.6262 3.29289L14.0404 4.70711ZM5.99999 11.3333L5.29288 12.0404C5.68341 12.431 6.31657 12.431 6.7071 12.0404L5.99999 11.3333ZM3.37376 7.29289C2.98324 6.90237 2.35007 6.90237 1.95955 7.29289C1.56903 7.68342 1.56903 8.31658 1.95955 8.70711L3.37376 7.29289ZM12.6262 3.29289L5.29288 10.6262L6.7071 12.0404L14.0404 4.70711L12.6262 3.29289ZM6.7071 10.6262L3.37376 7.29289L1.95955 8.70711L5.29288 12.0404L6.7071 10.6262Z'
        fill={color}
      />
    </svg>
  );
};

export default Check;
