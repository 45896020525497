import { Loading } from 'components/core';
import {
  GetOrgMembersQuery,
  WatercoolerInitiativeByOrgQuery,
  useGetOrgMembersQuery,
  useWatercoolerInitiativeByOrgQuery,
} from 'types';

export type CopyProps = {
  firstName: string;
  memberCount: number;
  orgName: string;
};

export type MeetupsLoaderPassedProps = {
  existingInitiative?: WatercoolerInitiativeByOrgQuery['watercoolerInitiativeByOrg'];
  orgId: string;
  copy: CopyProps;
  orgMembers: NonNullable<NonNullable<GetOrgMembersQuery['organization']>['members']>;
};

const MeetupsLoader = ({
  firstName,
  orgId,
  orgName,
  Component,
}: {
  firstName: string;
  orgId: string;
  orgName: string;
  Component: (props: MeetupsLoaderPassedProps) => React.ReactElement;
}) => {
  const { data: orgData } = useGetOrgMembersQuery({
    variables: {
      orgId,
    },
  });
  const { data: existingInitiativeData } = useWatercoolerInitiativeByOrgQuery({
    variables: { orgId },
  });

  if (!orgData || !existingInitiativeData) {
    return <Loading />;
  }

  const copyProps = {
    firstName,
    memberCount: orgData?.organization?.members?.length ?? 0,
    orgName,
  };

  const initiative = existingInitiativeData?.watercoolerInitiativeByOrg;

  return (
    <Component
      existingInitiative={initiative}
      orgId={orgId}
      copy={copyProps}
      orgMembers={orgData?.organization?.members ?? []}
    />
  );
};

export default MeetupsLoader;
