import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { SettingsTabProps } from '.';
import {} from './Integrations';
import { Box } from '@material-ui/core';
import theme from 'theme';
import { Hris } from './Integrations/Hris';
import _ from 'lodash';
import { Calendar } from './Integrations/Calendar';
import { getPendingOrgMembers, memberFragment } from 'gql/queries';

const Integrations = ({ viewer }: SettingsTabProps) => {
  const { data, loading } = useQuery(getIntegrationsInfoQuery);
  const { data: pendingData, loading: pendingLoading } = useQuery(getPendingOrgMembers, {
    variables: {
      id: _.first(viewer.orgs)?.id,
    },
  });

  const integrationClasses = useStyles();
  return (
    !loading &&
    !pendingLoading && (
      <Box className={integrationClasses.body}>
        <Hris
          classes={integrationClasses}
          org={data.viewer.orgs[0]}
          viewer={viewer}
          pending={pendingData.getPendingOrgMembers}
        />
        <Calendar
          classes={integrationClasses}
          org={data.viewer.orgs[0]}
          viewer={viewer}
        />
      </Box>
    )
  );
};

const useStyles = makeStyles({
  body: {
    marginTop: '32px',
  },
  integration: {
    width: '50%',
  },
  subtitle: {
    marginBottom: '1rem',
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  h2: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '36px',
    fontWeight: 500,
    lineHeight: '44px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  mCard: {
    width: '100%',
    maxWidth: theme.spacing(140),
    margin: `${theme.spacing(6)} auto`,
    padding: theme.spacing(6),
    borderRadius: theme.spacing(2),
    border: `1px solid ${theme.palette.primary[100]}`,
    background: theme.palette.common.white,
    boxShadow: `0px 6px 8px -3px ${theme.palette.primary[100]}`,
    '&:hover': {
      border: `1px solid ${theme.palette.primary[200]}`,
      boxShadow: `0px 8px 20px -2px ${theme.palette.primary[100]}`,
    },
  },
  mCardIcon: {
    display: 'inline-flex',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    background: theme.palette.secondary[200],
  },
  mCardLabel: {
    color: theme.palette.primary.main,
    fontSize: '0.8rem',
    fontFamily: `'Roboto Monospace', monospace`,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  mCardTitle: {
    fontSize: theme.spacing(8),
    color: theme.palette.text.primary,
  },
  mCardHelperText: {
    fontSize: '1rem',
    color: theme.palette.grey[900],
  },
  statusRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: `${theme.spacing(4)} 0`,
  },
  statusRowCopy: {
    color: theme.palette.text.primary,
    fontSize: '1.25rem',
    fontWeight: 500,
    marginRight: theme.spacing(2),
  },
  statusRowPill: {
    display: 'inline-flex',
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    borderRadius: theme.spacing(4),
    height: theme.spacing(6),
    fontSize: '0.8rem',
    fontWeight: 600,
    lineHeight: theme.spacing(4),
    color: theme.palette.secondary[600],
    backgroundColor: theme.palette.secondary[200],
  },
  statusRowPillNegative: {
    color: theme.palette.error[600],
    backgroundColor: theme.palette.error[200],
  },
  detailsBlock: {
    display: 'flex',
    flexDirection: 'row',
    margin: `${theme.spacing(4)} 0`,
    '@media (max-width: 800px)': {
      flexDirection: 'column',
    },
  },
  detailsBlockCell: {
    flex: 1,
    '&:first-of-type': {
      borderRight: `1px solid ${theme.palette.primary[100]}`,
    },
    '&:last-of-type': {
      paddingLeft: theme.spacing(4),
    },
    '@media (max-width: 800px)': {
      '&:first-of-type': {
        borderRight: 0,
        paddingBottom: theme.spacing(4),
        borderBottom: `1px solid ${theme.palette.primary[100]}`,
      },
      '&:last-of-type': {
        paddingTop: theme.spacing(4),
        paddingLeft: 0,
      },
    },
  },
  refresh: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    fontSize: '0.8rem',
    fontWeight: 600,
    margin: `${theme.spacing(6)} 0 0`,
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.primary[100],
    '&:hover': {
      backgroundColor: theme.palette.primary[200],
    },
    '& span': {
      textDecoration: 'underline',
      textTransform: 'none',
    },
  },
  detailsBlockText: {
    marginTop: theme.spacing(2),
    fontWeight: 400,
    fontSize: '1.25rem',
    lineHeight: '1.25rem',
    color: theme.palette.text.primary,
    '& span': {
      textTransform: 'capitalize',
    },
  },
  hrisIssuesBlock: {
    padding: theme.spacing(6),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.error[100],
  },
  hrisIssuesBlockHeadline: {
    fontSize: '1.15rem',
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  hrisIssuesBlockCopy: {
    fontSize: '.9rem',
    color: theme.palette.text.primary,
  },
  hrisIssuesBlockButton: {
    marginTop: theme.spacing(4),
    color: theme.palette.common.white,
    textTransform: 'none',
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.text.secondary,
    },
  },
});

const getIntegrationsInfoQuery = gql`
  query getIntegrationsInfo {
    viewer {
      id
      orgs {
        id
        name
        isHrisConnected
        hrisIntegrations {
          id
          providerName
          lastUpdated
          state
        }
        nylasServiceAccounts {
          id
          provider
          createdAt
        }
        members {
          ...memberFragment
        }
      }
    }
  }
  ${memberFragment}
`;

export default Integrations;
