// @ts-strict-ignore
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TabNav from 'components/TabNav';
import { recursivelyRemoveTypenames } from 'lib/helpers/apollo';
import { getTotalGuests } from 'lib/helpers/booking';
import _ from 'lodash';
import { DateTime, Duration } from 'luxon';
import React, { useContext } from 'react';
import { useLocation } from 'react-use';
import theme from 'theme';
import { ViewerFragmentFragment } from 'types';
import {
  EventArrayByDateString,
  EventForSelection,
  EventInfo,
  EventSelectionArgs,
  TemplateWithExperienceInfo,
} from 'types/selfServeFlow';
import { canRequestToBook, inRequestToBookThreshold } from 'utils/dateUtil';
import { SelfServeContext } from '../../../providers/SelfServeProvider';
import { addOpenExperiencesToEvents } from '../EventSelection/helpers';
import EmailsNotOnProviderTooltip from './EmailsNotOnProviderTooltip';
import {
  getHasPhysicalGoodsOrUpgradeFromTemplate,
  makeGroupedEventsForMysteryToAll,
} from './helpers';

import InfoCircleFilled from 'components/icons/InfoCircleFilled';
import SectionSubHeader from 'components/SectionSubHeader';
import { Skeleton } from 'components/Skeleton';
import PricingInfo from './PricingInfo';
import SelectAndShowDates, { PureEventTile } from './SelectAndShowDates';
import SelectionSection from './SelectionSection';
import AlertBar from 'components/AlertBar';
import { gql } from '@apollo/client';
import { contractFragment, viewerFragment } from 'gql/queries';

const BEST_DATES_CAP = 5;

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  image2: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: theme.spacing(5),
      marginRight: 0,
    },
  },
  image: {
    width: '100%',
    height: 288,
    objectFit: 'cover',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: theme.spacing(5),
    },
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },
  windowsWrapper: {
    [theme.breakpoints.down('md')]: {
      maxHeight: '20rem',
      overflow: 'scroll',
      border: `2px solid ${theme.palette.primary.light}`,
      borderRadius: '5px',
      padding: theme.spacing(3),
      marginBottom: theme.spacing(5),
    },
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  sectionHeader: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(3),
  },
  dusk: {
    color: theme.palette.text.secondary,
  },
  subtitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '12px',
    letterSpacing: '0.04rem',
    textTransform: 'uppercase',
  },
});

interface Props {
  clearSelection: () => void;
  selectedTemplate: TemplateWithExperienceInfo;
  onChoose(t: EventSelectionArgs): void;
  currentEventSelection: EventSelectionArgs;
  viewer: ViewerFragmentFragment;
  setValue(value: any, alternateKey: string): void;
  surpriseToAllView: boolean;
  setSurpriseToAllView(v: boolean): void;
  wantsPhysicalGoods: boolean;
  availableEventTimes: EventInfo[];
}

enum TabName {
  Suggested = 'Suggested times',
  ByTime = 'Select by date',
}

const SuggestedTimes = ({
  bestDateTimes,
  emailsNotOnProvider,
  totalGuests,
  currentEventSelection,
  surpriseToAllView,
  hasPhysicalGoods,
  onClickEventTime,
  inRequestToBookThreshold,
  hasWorkingHours,
  selectedTemplate,
  timesLoading,
}) => {
  return timesLoading ? (
    <Skeleton />
  ) : (
    <Box mt={6} mb={5}>
      <Typography>
        <b>We think these are the best dates for you</b>{' '}
        {!_.isEmpty(emailsNotOnProvider) && (
          <EmailsNotOnProviderTooltip
            emailsNotOnProvider={emailsNotOnProvider}
            guestListSize={totalGuests}
          />
        )}
      </Typography>
      <Box mt={4}>
        <Box display='flex' flexDirection='column'>
          {_.map(bestDateTimes, (event, i) => {
            const eventId = event.id;
            const isSelected = currentEventSelection?.event?.id === eventId;
            const isRequestToBook = surpriseToAllView
              ? false
              : inRequestToBookThreshold(
                  new Date(),
                  hasPhysicalGoods,
                  selectedTemplate?.experiences,
                );
            return (
              <PureEventTile
                key={`option-${i}`}
                event={event}
                isSelected={isSelected}
                onClickEventTime={() => onClickEventTime(event, isRequestToBook)}
                isRequestToBook={isRequestToBook}
                fullDate
              />
            );
          })}
        </Box>
        {hasWorkingHours && (
          <Box mt={4}>
            <Typography variant='body2'>
              Availability is determined by calendar events and 9:00 AM to 5:00 PM working
              hours based on each guest's local timezone
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const ByTime = (props: {
  groupedEvents: EventArrayByDateString;
  currentEventSelection: any;
  onClickEventTime(event: EventForSelection, isRequestToBook: boolean): void;
  surpriseToAllView: boolean;
  selectedTemplate: TemplateWithExperienceInfo;
}) => {
  const classes = useStyles();
  return (
    <Box mt={6} className={classes.windowsWrapper}>
      <SelectAndShowDates {...props} />
    </Box>
  );
};

const TabLookup = {
  [TabName.Suggested]: (props) => <SuggestedTimes {...props} />,
  [TabName.ByTime]: (props) => <ByTime {...props} />,
};

const EventSelectionDetails = ({
  clearSelection,
  selectedTemplate,
  onChoose,
  currentEventSelection,
  setValue,
  viewer,
  surpriseToAllView,
  setSurpriseToAllView,
  wantsPhysicalGoods,
  availableEventTimes,
}: Props) => {
  const { state } = useContext(SelfServeContext);
  const location = useLocation();
  const classes = useStyles();
  const checkingCalendarAvailability = state?.uploadGuests.checkingCalendarAvailability;
  const checkedCalendarAvailability = state?.uploadGuests.checkedCalendarAvailability;
  const emailsNotOnProvider = state?.eventDate?.emailsNotOnProvider;
  const hasWorkingHours = state?.eventDate?.hasWorkingHours;
  let contracts = viewer.contracts;
  contracts = contracts.filter((contract) => contract.status !== "Expired");
  const [selectedContract, setSelectedContract] = React.useState(state?.contract?.id || contracts?.[0]?.id);
  const totalGuests =
    getTotalGuests({ guests: state.uploadGuests.guests }) ||
    state.uploadGuests.totalRecipientsEstimate;
  const [selectedTab, setSelectedTab] = React.useState(
    location.hash ? decodeURI(location.hash.substring(1)) : TabName.ByTime.toString(),
  );
  const [validTabs, setValidTabs] = React.useState(Object.values(TabName));
  const { hasPhysicalGoods, requiresUpgrade } =
    getHasPhysicalGoodsOrUpgradeFromTemplate(selectedTemplate);
  const onClickEventTime = React.useCallback(
    (event: EventForSelection, isRequestToBook: boolean) => {
      const surpriseToAllPayload = {
        hasPhysicalGoods: wantsPhysicalGoods,
        requiresUpgrade: false,
        event,
      };
      const experiences = event?.openExperiences?.length
        ? _.map(event?.openExperiences, (exp) => _.pick(exp, 'id'))
        : event.rtbExperiences;
      const chosenEventPayload = {
        hasPhysicalGoods,
        requiresUpgrade,
        event: _.pick(event, 'id', 'start', 'end'),
        template: recursivelyRemoveTypenames(
          _.pick(selectedTemplate, 'id', 'cost', 'photoUrl', 'title', 'experiences'),
        ),
        experiences,
      };
      onChoose(surpriseToAllView ? surpriseToAllPayload : chosenEventPayload);
      setValue(isRequestToBook, 'isRequestToBook');
      setValue(
        { surpriseToAttendees: true, surpriseToAll: surpriseToAllView },
        'surpriseSelection',
      );
    },
    [onChoose, setValue],
  );

  const handleSelectContract = (contractId) => {
    setSelectedContract(contractId);
    state.contract = contracts.find((contract) => contract.id === contractId);
  }

  const groupedEvents = surpriseToAllView
    ? makeGroupedEventsForMysteryToAll({ availableEventTimes })
    : getGroupedEventsForTemplate(availableEventTimes);

  const bestDateTimes = React.useMemo(() => {
    if (checkedCalendarAvailability) {
      const ordered = _.orderBy(
        _.flattenDeep(Object.values(groupedEvents)),
        (e: EventForSelection) => {
          return _.size(e.emails);
        },
        ['desc'],
      ) as EventForSelection[];

      let events: EventForSelection[];
      if (hasPhysicalGoods) {
        const afterShippedGoodsLeadTime = ordered.filter(
          (event) =>
            DateTime.fromISO(event.start) >
            DateTime.now().plus(Duration.fromObject({ weeks: 2 })),
        );

        events = afterShippedGoodsLeadTime.slice(0, BEST_DATES_CAP);
      } else {
        events = ordered.slice(0, BEST_DATES_CAP);
      }
      // If the user is choosing their own template, add the experiences that are open to the returned events.
      // This is only necessary when the user is choosing their own template.
      if (selectedTemplate) {
        const withExperiences = addOpenExperiencesToEvents({ events, selectedTemplate });
        const onlyWithOpenExperiences = withExperiences?.filter(
          (template) => !_.isEmpty(template.openExperiences),
        );
        return _.size(onlyWithOpenExperiences) ? onlyWithOpenExperiences : null;
      }
      return events;
    }
  }, [JSON.stringify(groupedEvents)]);

  const timesLoading = checkingCalendarAvailability || !availableEventTimes;

  React.useEffect(() => {
    if (bestDateTimes) {
      setSelectedTab(TabName.Suggested.toString());
      setValidTabs(Object.values(TabName));
    } else {
      setValidTabs(Object.values(TabName).filter((v) => v !== TabName.Suggested));
    }
  }, [bestDateTimes]);

  const { sectionSubtitle } = getContent({
    template: selectedTemplate,
    isSurpriseToAll: surpriseToAllView,
  });

  const groupedEventsFilteredByRtbThreshold = Object.keys(groupedEvents).reduce(
    (acc, dateKey) => {
      if (
        surpriseToAllView ||
        canRequestToBook(
          new Date(dateKey),
          hasPhysicalGoods,
          selectedTemplate?.experiences,
        )
      ) {
        acc[dateKey] = groupedEvents[dateKey];
      }
      return acc;
    },
    {} as EventArrayByDateString,
  );

  return (
    <Box className={classes.container}>
      <Typography className={[classes.dusk, classes.subtitle].join(' ')}>
        Event Selection
      </Typography>
      <Box pt={4}>
        <SelectionSection
          clearSelection={() => {
            clearSelection();
            onChoose(null);
          }}
          description={selectedTemplate?.title}
          durationMins={selectedTemplate?.experiences[0]?.estimatedDurationMins || null}
          rating={selectedTemplate?.experiences[0]?.rating || null}
          photoUrl={selectedTemplate?.photoUrl}
          setSurpriseToAllView={setSurpriseToAllView}
          showUpsell={!contracts.length}
          surpriseToAllView={surpriseToAllView}
        />
      </Box>
      <Box mt={10}>
        <SectionSubHeader
          title={`Select a time for ${
            surpriseToAllView ? 'this surprise' : 'your event'
          }`}
          subTitle={sectionSubtitle}
        />
      </Box>
      {selectedTemplate?.experiences[0].hasPhysicalGoods && (
        <Box mb={3}>
          <AlertBar
            text='FYI: Events with supplies must be booked at least 3-5 weeks from your event'
            icon={<InfoCircleFilled />}
            severity='error'
          />
        </Box>
      )}
      <TabNav
        variant='fullWidth'
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        tabs={Object.values(validTabs)}
      />
      {TabLookup[selectedTab]({
        bestDateTimes,
        emailsNotOnProvider,
        totalGuests,
        currentEventSelection,
        surpriseToAllView,
        onClickEventTime,
        inRequestToBookThreshold,
        groupedEvents: groupedEventsFilteredByRtbThreshold,
        selectedTemplate,
        hasWorkingHours,
        timesLoading,
      })}
      <PricingInfo
        template={selectedTemplate}
        contracts={contracts}
        guestCount={totalGuests}
        isSurpriseToAll={surpriseToAllView}
        selectedContract={selectedContract}
        setSelectedContract={handleSelectContract}
      />
    </Box>
  );
};

const getGroupedEventsForTemplate = (availableEventTimes: EventForSelection[]) => {
  const groupedEvents = availableEventTimes.reduce((acc, event) => {
    const key = `${DateTime.fromJSDate(new Date(event.start)).toLocaleString()}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(event);
    return acc;
  }, {});

  return groupedEvents;
};

const getContent = ({
  template,
  isSurpriseToAll,
}): { description: string; subDescription?: string; sectionSubtitle: string } => {
  if (isSurpriseToAll) {
    return {
      description: `Experience the Science of Surprise, Glue's secret formula for building
  connection`,
      subDescription: `From thousands of events we’ve learned that people love the unexpected. We'll gather input from guests and plan a surprise that everyone will love, guaranteed!`,
      sectionSubtitle: `Choose one of the options below so we can start planning this event.`,
    };
  }
  return {
    description: _.get(template, 'description'),
    sectionSubtitle: `Choose one of the options below so we can start planning this event.`,
  };
};

EventSelectionDetails.fragments = {
  viewer: gql`
    fragment EventSelectionViewer on User {
      ...viewerFragment
      contracts {
        ...contractFragment
      }
    }
    ${viewerFragment}
    ${contractFragment}
  `
}

export default EventSelectionDetails;
