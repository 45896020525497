import { css } from '@emotion/react';
import Button from 'glue/components/buttons/Button';
import { useHistory } from 'react-router-dom';
import EditSection from './EditSection';
import { MeetupsLoaderPassedProps } from './MeetupsLoader';
import SidePanel from './SidePanel';

import MessageQuestionCircle from '@mysteryco/design/icons/MessageQuestionCircle';
import {
  Coal_10,
  Coal_20,
  Glue_Ink00,
  Glue_Ink10,
  Glue_LavenderLight,
  Mint_30,
  Mint_40,
  Plum_60,
} from '@mysteryco/design/src/tokens/colors';
import { getNameArr, getUniqUserCount } from 'glue/components/inputs/audience';
import { Tooltip } from 'glue/components/tooltip/Tooltip';
import { MEETUP_CADENCE_OPTIONS, getNextMeetupsRunDateString } from 'lib/helpers/meetups';
import theme from 'theme';
import { audienceReducer } from '.';

const SettingWithTooltip = ({
  title,
  subtitle,
  subSubtitle,
  tooltipText,
}: {
  title: string;
  subtitle: string;
  subSubtitle?: string;
  tooltipText?: string;
}) => (
  <div css={styles.settingsCard}>
    <div css={styles.settingText}>
      <span css={styles.settingTitle}>{title}</span>
      <span css={styles.settingSubtitle}>{subtitle}</span>
      {subSubtitle ? <span css={styles.settingSubtitle}>{subSubtitle}</span> : null}
    </div>
    {tooltipText && (
      <Tooltip content={<div css={{ width: '320px' }}>{tooltipText}</div>} size={'small'}>
        <span>
          <MessageQuestionCircle size={14} color={Coal_20} />
        </span>
      </Tooltip>
    )}
  </div>
);

const AudiencePill = ({ name }: { name: string }) => (
  <span css={styles.audiencePill}>{name}</span>
);

const SummaryAndEdit = ({ existingInitiative }: MeetupsLoaderPassedProps) => {
  const history = useHistory();
  const audience = audienceReducer(existingInitiative?.audience);
  const audienceSize = getUniqUserCount(audience);
  const audienceNames = getNameArr(audience);

  return (
    <SidePanel>
      <EditSection>
        <div css={styles.headerText}>
          <span css={styles.title}>Meetups Participants</span>
          <span>{audienceSize} people are currently participating in Meetups</span>
        </div>
        <div css={styles.audience}>
          {audienceNames.map((name, index) =>
            name ? <AudiencePill key={`pill-${index}`} name={name} /> : null,
          )}
        </div>
        <div css={styles.focusAreas}>
          <SettingWithTooltip
            title='Connection Focus'
            subtitle='Automatic'
            tooltipText='Glue automatically identifies whether participants need a boost in cross-team, within-team or leadership connections and schedules a Meetup to address that focus area.'
          />
          <SettingWithTooltip title='Length' subtitle='30 minutes' />
          <SettingWithTooltip
            title='Frequency'
            subtitle={
              MEETUP_CADENCE_OPTIONS.find(
                (opt) => existingInitiative?.cadenceWeeks === Number(opt.value),
              )?.label ?? '2 per month'
            }
            subSubtitle={`Next round: ${getNextMeetupsRunDateString({
              lastRun: existingInitiative?.lastRun,
              runAfter: existingInitiative?.runAfter,
              cadenceWeeks: existingInitiative?.cadenceWeeks!,
            })}`}
          />
        </div>
        <Button
          shape='square'
          css={styles.editButton}
          onClick={(e) => {
            e.preventDefault();
            history.push('/meetups/settings');
          }}
        >
          Edit Meetups
        </Button>
      </EditSection>
    </SidePanel>
  );
};

const styles = {
  title: css({
    fontWeight: 800,
  }),
  headerText: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    color: Coal_10,
    lineHeight: theme.spacing(6),
    fontSize: `calc(${theme.spacing(4)} - 1px)`,
  }),
  focusAreas: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.5),
  }),
  editButton: css({
    background: Mint_40,
    border: `solid 1px ${Mint_30}`,
    '--bg-disabled': Glue_LavenderLight,
    '--fg-disabled': Glue_Ink10,
  }),
  settingsCard: css({
    background: Glue_LavenderLight,
    borderRadius: theme.spacing(4),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'end',
  }),
  settingText: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
  }),
  settingTitle: css({
    fontWeight: 600,
    fontSize: `calc(${theme.spacing(4)} - 1px)`,
  }),
  settingSubtitle: css({
    fontSize: `calc(${theme.spacing(3)} + 1px)`,
    color: Coal_20,
    fontWeight: 500,
  }),

  tooltip: css({
    width: '200px',
    height: '200px',
    background: 'red',
  }),
  audience: css({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
  }),
  audiencePill: css({
    borderRadius: theme.spacing(4),
    background: Plum_60,
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    fontWeight: 500,
    fontSize: theme.spacing(3.5),
    lineHeight: theme.spacing(5),
    color: Glue_Ink00,
  }),
};

export default SummaryAndEdit;
