import { css } from '@emotion/react';
import { Coal_10, Coal_30 } from '@mysteryco/design/src/tokens/colors';
import IdentifyImage from 'glue/assets/artwork/meetups-identify.png';
import ScheduleImage from 'glue/assets/artwork/meetups-schedule.png';
import ProtectImage from 'glue/assets/artwork/meetups-protect.png';
import CalendarDate from '@mysteryco/design/icons/CalendarDate';
import Identify from '@mysteryco/design/icons/Identify';
import Protect from '@mysteryco/design/icons/Protect';
import theme from 'theme';

const ImageCard = ({ imgSrc, imgAlt }: { imgSrc: string; imgAlt: string }) => (
  <div css={styles.card}>
    <img css={styles.image} src={imgSrc} alt={imgAlt} />
  </div>
);

const CopyCard = ({
  title,
  subTitle,
  icon,
}: {
  icon?: React.ReactNode;
  title: string;
  subTitle: string;
}) => (
  <div css={styles.card}>
    <div css={styles.copyContainer}>
      {icon}
      <span css={styles.title}>{title}</span>
      <span css={styles.subTitle}>{subTitle}</span>
    </div>
  </div>
);

const Row = ({ children }: { children: React.ReactNode }) => {
  return <div css={styles.row}>{children}</div>;
};

const InitialSetup = () => {
  return (
    <div css={styles.container}>
      <Row>
        <CopyCard {...strings.identify} icon={<Identify size={36} color={Coal_30} />} />
        <ImageCard imgSrc={IdentifyImage} imgAlt='Connected avatars' />
      </Row>
      <Row>
        <ImageCard imgSrc={ScheduleImage} imgAlt='Avatars and icons of activities' />
        <CopyCard
          icon={<CalendarDate size={36} color={Coal_30} />}
          {...strings.schedule}
        />
      </Row>
      <Row>
        <CopyCard {...strings.protect} icon={<Protect size={36} color={Coal_30} />} />
        <ImageCard imgSrc={ProtectImage} imgAlt='Calendar' />
      </Row>
    </div>
  );
};

const strings = {
  identify: {
    title: 'Identify who needs a connection boost',
    subTitle:
      'Glue measures connection within teams, across teams, and with leaders to find who needs a boost.',
  },
  schedule: {
    title: 'Find and schedule the perfect matches',
    subTitle:
      'For every selected employee, Glue finds a perfect match based on interest and connection needs.',
  },
  protect: {
    title: 'Protect people’s time, especially leaders',
    subTitle:
      'If Glue can’t schedule the right match, the person is skipped. Senior Managers and above are only scheduled for leadership Meetups with 4 people or more.',
  },
};

const styles = {
  row: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.spacing(4),
  }),
  card: css({
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  container: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(7.5),
  }),
  copyContainer: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    width: '250px',
  }),
  image: css({
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '16px',
    maxWidth: '100%',
  }),
  title: css({
    color: Coal_10,
    fontWeight: 700,
    fontSize: theme.spacing(5),
    lineHeight: theme.spacing(6),
  }),
  subTitle: css({
    color: Coal_10,
    fontSize: theme.spacing(5),
    lineHeigh: theme.spacing(5.5),
  }),
};

export default InitialSetup;
