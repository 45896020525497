const ESGoalCrossTeam = ({ size, color, style = {}, className = '' }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      style={style}
      viewBox={'0 0 16 16'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.0661 0.5C8.6812 -0.166666 7.71895 -0.166667 7.33405 0.5L4.21636 5.9C3.83146 6.56667 4.31258 7.4 5.08238 7.4H11.3178C12.0876 7.4 12.5687 6.56667 12.1838 5.9L9.0661 0.5ZM5.42879 6.2L8.20008 1.4L10.9714 6.2H5.42879ZM3.8 9.6C2.36406 9.6 1.2 10.7641 1.2 12.2C1.2 13.6359 2.36406 14.8 3.8 14.8C5.23594 14.8 6.4 13.6359 6.4 12.2C6.4 10.7641 5.23594 9.6 3.8 9.6ZM0 12.2C0 10.1013 1.70132 8.4 3.8 8.4C5.89868 8.4 7.6 10.1013 7.6 12.2C7.6 14.2987 5.89868 16 3.8 16C1.70132 16 0 14.2987 0 12.2ZM8.40007 9.4C8.40007 8.84771 8.84778 8.4 9.40007 8.4H15.0001C15.5524 8.4 16.0001 8.84771 16.0001 9.4V15C16.0001 15.5523 15.5524 16 15.0001 16H9.40007C8.84778 16 8.40007 15.5523 8.40007 15V9.4ZM9.60007 14.8H14.8001V9.6H9.60007V14.8Z'
        fill={color}
      />
    </svg>
  );
};

export default ESGoalCrossTeam;
