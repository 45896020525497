import React from 'react';
import { SPACING_MEDIUM } from 'constants/Sizes';
import { makeStyles } from '@material-ui/styles';

const opacity = 0.1;

const useStyles = makeStyles({
  vertical: {
    opacity,
    background: 'black',
    width: 1,
    minHeight: '100%',
    marginLeft: (props: any) => props.marginTop,
    marginRight: (props: any) => props.marginBottom,
  },
  horizontal: {
    width: '100%',
    opacity,
    marginLeft: (props: any) => props.marginTop,
    marginRight: (props: any) => props.marginBottom,
  },
});

const ScottHr = ({
  marginTop = SPACING_MEDIUM,
  marginBottom = SPACING_MEDIUM,
  isVertical,
  ...otherProps
}: {
  marginTop?: number;
  marginBottom?: number;
  isVertical?: boolean;
}) => {
  const classes = useStyles({ marginTop, marginBottom });
  return isVertical ? (
    <div className={classes.vertical} {...otherProps} />
  ) : (
    <hr className={classes.horizontal} {...otherProps} />
  );
};

export default ScottHr;
