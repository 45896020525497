const StarDouble = ({ style = {}, className = '' }) => (
  <svg
    className={className}
    width={16}
    height={16}
    style={style}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.6222 1.0941C10.5232 0.836634 10.2759 0.666748 10 0.666748C9.72416 0.666748 9.4768 0.836634 9.37777 1.0941L8.59203 3.13704C8.39176 3.65774 8.32883 3.80778 8.24274 3.92885C8.15637 4.05032 8.05024 4.15645 7.92877 4.24282C7.80771 4.3289 7.65766 4.39183 7.13696 4.5921L5.09402 5.37785C4.83656 5.47687 4.66667 5.72423 4.66667 6.00008C4.66667 6.27593 4.83656 6.52329 5.09402 6.62231L7.13696 7.40806C7.65766 7.60833 7.80771 7.67126 7.92877 7.75734C8.05024 7.84371 8.15637 7.94984 8.24274 8.07132C8.32883 8.19238 8.39176 8.34243 8.59203 8.86312L9.37777 10.9061C9.4768 11.1635 9.72416 11.3334 10 11.3334C10.2759 11.3334 10.5232 11.1635 10.6222 10.9061L11.408 8.86312C11.6083 8.34243 11.6712 8.19238 11.7573 8.07132C11.8436 7.94984 11.9498 7.84371 12.0712 7.75734C12.1923 7.67126 12.3423 7.60833 12.863 7.40806L14.906 6.62231C15.1635 6.52329 15.3333 6.27593 15.3333 6.00008C15.3333 5.72423 15.1635 5.47687 14.906 5.37785L12.863 4.5921C12.3423 4.39183 12.1923 4.3289 12.0712 4.24282C11.9498 4.15645 11.8436 4.05032 11.7573 3.92885C11.6712 3.80778 11.6083 3.65774 11.408 3.13704L10.6222 1.0941Z'
      fill='url(#paint0_linear_240_4775)'
    />
    <path
      d='M4.92962 8.3686C4.81669 8.14275 4.58585 8.00008 4.33334 8.00008C4.08082 8.00008 3.84998 8.14275 3.73705 8.36861L3.21408 9.41455C3.02576 9.79119 2.96837 9.89958 2.89864 9.99004C2.8287 10.0808 2.74736 10.1621 2.65663 10.2321C2.56617 10.3018 2.45779 10.3592 2.08114 10.5475L1.0352 11.0705C0.80934 11.1834 0.666672 11.4142 0.666672 11.6667C0.666672 11.9193 0.80934 12.1501 1.0352 12.263L2.08114 12.786C2.45779 12.9743 2.56617 13.0317 2.65663 13.1014C2.74736 13.1714 2.8287 13.2527 2.89864 13.3435C2.96837 13.4339 3.02576 13.5423 3.21408 13.9189L3.73705 14.9649C3.84998 15.1907 4.08082 15.3334 4.33334 15.3334C4.58585 15.3334 4.8167 15.1907 4.92962 14.9649L5.4526 13.9189C5.64092 13.5423 5.6983 13.4339 5.76803 13.3435C5.83797 13.2527 5.91931 13.1714 6.01004 13.1014C6.1005 13.0317 6.20889 12.9743 6.58554 12.786L7.63148 12.263C7.85734 12.1501 8 11.9193 8 11.6667C8 11.4142 7.85734 11.1834 7.63148 11.0705L6.58554 10.5475C6.20889 10.3592 6.1005 10.3018 6.01004 10.2321C5.91931 10.1621 5.83797 10.0808 5.76803 9.99004C5.6983 9.89958 5.64092 9.7912 5.4526 9.41455L4.92962 8.3686Z'
      fill='url(#paint1_linear_240_4775)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_240_4775'
        x1='8'
        y1='0.666748'
        x2='8'
        y2='15.3334'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C24ACC' />
        <stop offset='1' stopColor='#ED9275' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_240_4775'
        x1='8'
        y1='0.666748'
        x2='8'
        y2='15.3334'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C24ACC' />
        <stop offset='1' stopColor='#ED9275' />
      </linearGradient>
    </defs>
  </svg>
);

export default StarDouble;
