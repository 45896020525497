import React from 'react';
import { usePrevious, useSessionStorage } from 'react-use';
import { v4 } from 'uuid';
import { gql, useMutation } from '@apollo/client';
import { CreateBookingSessionInput, MysteryProduct } from '../types';
import _ from 'lodash';

const useBookingSession = (
  data: Omit<CreateBookingSessionInput, 'sessionId' | 'productType'>,
) => {
  const [addBookingSessionRecord] = useMutation(Mutation);
  const [sessionStorageValue, setSessionStorageValue] = useSessionStorage<string>('bsid');

  const previousData = usePrevious(data);
  if (!sessionStorageValue) setSessionStorageValue(v4());

  React.useEffect(() => {
    if (
      !_.isEqual(data, previousData) &&
      sessionStorageValue &&
      Object.keys(data).length > 0
    )
      addBookingSessionRecord({
        variables: {
          input: {
            ...data,
            productType: MysteryProduct.TeamEvent,
            sessionId: sessionStorageValue,
          },
        },
      });
  }, [data, previousData, sessionStorageValue, addBookingSessionRecord]);

  return sessionStorageValue;
};

export default useBookingSession;

const Mutation = gql`
  mutation AddBookingSessionRecord($input: CreateBookingSessionInput!) {
    createBookingSession(bookingSession: $input) {
      id
    }
  }
`;
