import { User } from 'types';
import { Audience } from './types';
import { uniqBy } from 'lodash';

export const getUniqUserArr = (audience: Audience) => {
  const teamUsers =
    audience.teams?.flatMap((team) => {
      return team.members?.map((member) => member);
    }) || [];
  const tagUsers =
    audience.tags?.flatMap((tag) => {
      return tag.users?.map((user) => user);
    }) || [];

  const orgUsers = audience?.organization?.members || [];

  return uniqBy(
    [...(audience.users || []), ...teamUsers, ...tagUsers, ...orgUsers],
    'id',
  );
};

export const getUniqUserCount = (audience: Audience) => getUniqUserArr(audience).length;

export const getUniqUsers = (audience: Audience): User[] =>
  getUniqUserArr(audience).filter((i) => i) as User[];

export const getNameArr = (audience: Audience) => {
  if (!audience) return [];

  if (audience.organization) return [`Everyone at ${audience.organization.name}`];

  return [
    ...(audience.teams?.map((t) => t.name) || []),
    ...(audience.tags?.map((t) => t.name) || []),
    ...(audience.users?.map((u) => u.name || u.email) || []),
  ];
};
