import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

export interface LinkProps extends RouterLinkProps {
  newTab?: boolean;
  /**
   * Render
   */
  visualOnly?: boolean;
  /**
   * Keep search params when navigating. Only works if 'to'
   * is a string.
   */
  preserveSearchParams?: boolean;
  /**
   * Prevents navigation
   */
  disabled?: boolean;
}

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      newTab,
      to: providedTo,
      children,
      visualOnly,
      disabled,
      preserveSearchParams,
      ...rest
    },
    ref,
  ) => {
    const newTabProps = newTab ? { target: '_blank', rel: 'noopener noreferrer' } : {};

    const to =
      preserveSearchParams && typeof providedTo === 'string'
        ? providedTo + window.location.search
        : providedTo;

    if (visualOnly || disabled) {
      // render as a span to remove link functionality
      return (
        <span
          css={styles.unstyled}
          data-disabled={disabled}
          ref={ref as any}
          className={rest.className}
        >
          {children}
        </span>
      );
    }

    if (typeof to === 'string' && isExternal(to)) {
      return (
        <a
          css={styles.unstyled}
          href={to}
          ref={ref}
          className={rest.className}
          {...newTabProps}
        >
          {children}
        </a>
      );
    }

    return (
      <RouterLink to={to} css={styles.unstyled} ref={ref} {...rest} {...newTabProps}>
        {children}
      </RouterLink>
    );
  },
);
Link.displayName = 'Link';

export default Link;

const styles = {
  unstyled: css({
    textDecoration: 'none',
    color: 'inherit',

    '&:hover': {
      // overwrite some global style somewhere
      // TODO: get rid of that global
      color: 'inherit',
    },
  }),
};

function isExternal(url: string | undefined) {
  if (!url) return false;
  return url.startsWith('http') || url.startsWith('mailto');
}
