import PageWrapper from 'glue/components/structure/PageWrapper';
import {
  NavigationTabs,
  NavigationTab,
  NavigationTabsList,
} from 'glue/components/tabs/NavigationTabs';
import { TabLabel } from 'glue/components/tabs/TabLabel';
import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch } from 'react-router';
import { PageMetaData, Paths } from 'Routes';
import ConnectionsWrapper from './Connections';
import Pulse from './Pulse';

type TabDefinition = {
  path: string;
  name: string;
  Component: React.FC;
  disabled: boolean;
  label?: string;
};

export const EXPLORE: TabDefinition[] = [
  {
    path: 'connections',
    name: 'Connections',
    Component: () => <ConnectionsWrapper />,
    disabled: false,
  },
  {
    path: 'pulse',
    name: 'Pulse',
    Component: () => <Pulse />,
    disabled: false,
    label: 'Beta',
  },
];

export const getExploreTypePath = (type: string) => {
  return `${Paths.EXPLORE}/${type}`;
};

export const getExploreType = (pathname: string) => {
  return pathname.split(`${Paths.EXPLORE}/`)[1]?.split('/')[0] ?? EXPLORE[0].path;
};

const Explore = () => {
  return (
    <PageWrapper title={'Explore'}>
      <Helmet>
        <title>{PageMetaData.EXPLORE.title}</title>
      </Helmet>
      <NavigationTabs>
        <NavigationTabsList>
          {EXPLORE.map((tab) => (
            <NavigationTab
              to={`${Paths.EXPLORE}/${tab.path}`}
              key={tab.path}
              disabled={tab.disabled}
            >
              {tab.name} {tab.label && <TabLabel value={tab.label} />}
            </NavigationTab>
          ))}
        </NavigationTabsList>
      </NavigationTabs>
      <Switch>
        <Route
          exact
          path={Paths.EXPLORE}
          render={() => <Redirect to={getExploreTypePath(EXPLORE[0].path)} />}
        />
        {EXPLORE.map((tab) => {
          const { Component } = tab;
          return (
            <Route
              path={getExploreTypePath(tab.path)}
              key={`${tab.path}-link`}
              component={Component}
            />
          );
        })}
      </Switch>
    </PageWrapper>
  );
};

export default Explore;
