import { css } from '@emotion/react';
import { colors } from '@mysteryco/design/src';
import ProfileAvatar from './ProfileAvatar';
import TextLink from '../buttons/TextLink';
import theme from 'theme';
import { Paths } from 'Routes';

interface Props {
  imageUrl?: string;
  firstName: string;
  lastName: string;
  title: string;
}

const ProfileCard = ({ imageUrl, firstName, lastName, title }: Props) => {
  const initials = `${firstName[0]}${lastName[0]}`;
  return (
    <div css={styles.root}>
      {imageUrl && <img src={imageUrl} alt='Profile' />}
      <ProfileAvatar
        initials={initials}
        background={colors.Glue_Darkberry20}
        size='large'
      />
      <div css={styles.name}>{`${firstName} ${lastName}`}</div>
      <div css={styles.title}>{title}</div>
      <TextLink
        css={{
          paddingTop: theme.spacing(5),
        }}
        to={Paths.PROFILE}
      >
        Update profile
      </TextLink>
    </div>
  );
};

const styles = {
  root: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 24,
    background: colors.Glue_LavenderLight,
    borderRadius: 8,
    minWidth: 280,
  }),
  name: css({
    fontFamily: 'inherit',
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: 16,
    lineHeight: 1,
    color: colors.Glue_Ink00,
    paddingTop: theme.spacing(3),
  }),
  title: css({
    fontFamily: 'inherit',
    fontStyle: 'normal',
    fontWeight: 500,
    opacity: 0.7,
    fontSize: 14,
    lineHeight: 1.4,
    color: colors.Glue_Ink00,
    paddingTop: theme.spacing(2),
  }),
};

export default ProfileCard;
