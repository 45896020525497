// @ts-strict-ignore
import { cx } from '@emotion/css';
import {
  Box,
  Chip,
  ClickAwayListener,
  FormControl,
  InputBase,
  Popper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@mysteryco/design';
import { ReactNode, useEffect, useRef, useState } from 'react';
import theme from 'theme';
import OptionMenu, {
  filterOptionsDefault,
  getValueAsOptionDefault,
  isDuplicateDefault,
  Option,
  OptionMenuProps,
} from './OptionMenu';

interface OptionSelectProps extends OptionMenuProps {
  placeholderEmpty?: string;
  placeholderFocus?: string;
  disabled?: boolean;
  renderTag?: (
    option?: Option,
    onRemove?: (event?: any, removedValue?: string) => void,
  ) => ReactNode;
  renderInput?: (option?: Option) => string;
}

const renderInputDefault = (option: Option) => {
  return option.id;
};

const RenderTagDefault = (option: Option) => {
  return <Chip label={option?.id} />;
};

const OptionSelect = ({
  options = [],
  placeholderEmpty = '',
  placeholderFocus = '',
  values = [],
  onChange = () => null,
  onCreate = () => null,
  filterOptions = filterOptionsDefault,
  getValueAsOption = getValueAsOptionDefault,
  isValid = (newOption) => {
    return !isDuplicateDefault(newOption, options);
  },
  multiple = false,
  disabled = false,
  disableCreate = false,
  editOnCreate = false,
  renderTag = RenderTagDefault,
  renderInput = renderInputDefault,
  ...props
}: OptionSelectProps) => {
  const classes = useStyles();

  const [selected, setSelected] = useState(values);
  const [inputState, setInputState] = useState('');
  const [disableFilter, setDisableFilter] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const containerRef = useRef(null);
  const inputRef = useRef(null);
  const popperRef = useRef(null);

  useEffect(() => {
    if (!values) return;
    setAll(values);
  }, [values]);

  const handleEscape = (event) => {
    if (event.key === 'Escape') {
      event.stopPropagation();
      event.preventDefault();
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscape, true);

    return () => {
      document.removeEventListener('keydown', handleEscape, true);
    };
  }, [handleEscape]);

  const getOptionById = (id: string) => {
    return options.find((option) => option.id === id);
  };

  const resetInput = (newValues?: string[]) => {
    if (multiple) {
      setInputState('');
    } else if (newValues?.length) {
      setInputState(renderInput(getOptionById(newValues[0])));
    }
  };

  const setAll = (newValues: string[], shouldResetInput: boolean = false) => {
    setSelected(newValues);
    if (!multiple || shouldResetInput) resetInput(newValues);
  };

  const setOne = (newValue: string, shouldResetInput: boolean = false) => {
    let newSelected;
    if (multiple) {
      newSelected = selected.includes(newValue)
        ? selected.filter((values) => values !== newValue)
        : [...selected, newValue];
      setSelected(newSelected);
    } else {
      newSelected = [newValue];
      setSelected(newSelected);
    }
    if (!multiple || shouldResetInput) resetInput(newSelected);
  };

  const handleClick = (event: any) => {
    if (!anchorEl && !disabled) {
      inputRef?.current?.select();
      setAnchorEl(event?.currentTarget);
    }
  };

  const handleClose = () => {
    setAll(selected, true);
    setAnchorEl(null);
    setDisableFilter(true);
  };

  const handleClickAway = (event: any) => {
    if (containerRef?.current?.contains(event?.target)) return;
    handleClose();
  };

  const handleBlur = (event: any) => {
    if (containerRef?.current?.contains(event?.relatedTarget)) return;
    handleClose();
  };

  const handleChange = (event: any, newValues: string[]) => {
    setAll(newValues);
    onChange(event, newValues);
  };

  const handleInputChange = (event: any) => {
    if (!anchorEl) setAnchorEl(event.currentTarget);
    setInputState(event.target.value);
    setDisableFilter(false);
  };

  const handleActiveChange = async () => {
    popperRef?.current?.update();
  };

  const handleCreate = async (event: any, name: string): Promise<string> => {
    const result = await onCreate(event, name);
    setInputState('');
    return result;
  };

  const handleSubmit = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    if (!disableCreate && !editOnCreate && isValid(getValueAsOption(inputState))) {
      const result = await handleCreate(event, inputState);
      setOne(result, true);
      return;
    }
    const filteredOptions = filterOptions(options, inputState);
    if (filteredOptions?.length === 1) {
      setOne(filteredOptions[0].id, true);
      return;
    }
  };

  const dropdownOpen = Boolean(anchorEl);
  const placeholderText = Boolean(anchorEl)
    ? placeholderFocus
    : selected?.length === 0
    ? placeholderEmpty
    : '';

  const hideInput = multiple && !Boolean(anchorEl) && selected?.length > 0;

  return (
    <form className={classes.container} ref={containerRef} onSubmitCapture={handleSubmit}>
      <FormControl
        className={classes.inputContainer}
        onClick={handleClick}
        onChange={handleInputChange}
        disabled={disabled}
        fullWidth
      >
        <InputBase
          className={cx(hideInput && classes.hideClass, multiple && classes.multiple)}
          placeholder={placeholderText}
          value={inputState}
          inputRef={inputRef}
          startAdornment={
            multiple && selected?.length ? (
              <Box className={classes.tagContainer}>
                {selected.map((id) => renderTag(getOptionById(id)))}
              </Box>
            ) : (
              <></>
            )
          }
          fullWidth
        />
      </FormControl>
      <Popper
        className={classes.dropdown}
        open={dropdownOpen}
        popperRef={popperRef}
        anchorEl={anchorEl}
        disablePortal
        placement={'bottom-start'}
        style={{ width: anchorEl?.clientWidth }}
      >
        <ClickAwayListener onClickAway={handleClickAway} disableReactTree>
          <OptionMenu
            options={options}
            inputValue={inputState}
            values={selected}
            onChange={handleChange}
            onActiveValueChange={handleActiveChange}
            onCreate={handleCreate}
            onBlur={handleBlur}
            filterOptions={filterOptions}
            getValueAsOption={getValueAsOption}
            isValid={isValid}
            disableFilter={disableFilter}
            multiple={multiple}
            disableCreate={disableCreate}
            editOnCreate={editOnCreate}
            optionsContainerProps={{ className: classes.container }}
            editContainerProps={{ className: classes.container }}
            {...props}
            disableSearch
            tabIndex={-1}
          />
        </ClickAwayListener>
      </Popper>
    </form>
  );
};

const useStyles = makeStyles({
  container: {
    fontSize: '.875rem',
    display: 'flex',
    width: '100%',
  },
  inputContainer: {
    '& .MuiInputBase-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: theme.spacing(1),
      gap: theme.spacing(2),
      background: colors.White,
      border: `1px solid ${colors.LightGray}`,
      borderRadius: theme.spacing(1),
      fontWeight: 500,
      fontSize: '.875rem',
      lineHeight: 1,
      color: colors.Black,
      transition: 'all 300ms, ease-in-out',
      caretColor: colors.Main,
      '&:hover': {
        borderColor: colors.Purple200,
      },
      '&.Mui-focused': {
        borderColor: colors.Main,
      },
      '& input': {
        padding: theme.spacing(2),
        '&::placeholder': {
          opacity: 1,
          color: '#828282',
        },
      },
      '&.Mui-disabled': {
        color: '#828282',
        borderColor: colors.LightGray,
        background: colors.OffWhite,
        '&$multiple': {
          background: colors.White,
        },
      },
    },
  },
  multiple: {},
  hideClass: {
    '& input:not(:focus)': {
      height: 0,
      overflow: 'clip',
      margin: theme.spacing(-3),
    },
  },
  tagContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flexStart',
    padding: theme.spacing(1),
    gap: theme.spacing(1),
  },
  internalContainer: {
    height: '18rem',
  },
  dropdown: {
    zIndex: theme.zIndex.modal,
    boxShadow:
      '0px 4px 6px -1px rgba(155, 160, 166, 0.1), 0px 2px 4px -1px rgba(155, 160, 166, 0.06)',
    borderRadius: theme.spacing(1),
    border: `1px solid ${colors.LightGray}`,
    background: colors.White,
  },
});

export default OptionSelect;
