import styled from '@emotion/styled';
import { colors } from '@mysteryco/design';
import ChevronDown from '@mysteryco/design/icons/ChevronDown';
import { ReactNode, forwardRef, ComponentPropsWithoutRef } from 'react';
import theme from 'theme';

type DropdownFilterButtonProps = ComponentPropsWithoutRef<
  typeof DropdownFilterButtonRoot
> & {
  label: ReactNode;
  value: ReactNode;
  /** **Not required if used within Popover.** Optionally control the visual expanded state. */
  expanded?: boolean;
};

const DropdownFilterButton = forwardRef<HTMLButtonElement, DropdownFilterButtonProps>(
  ({ label, value, expanded, disabled = false, ...rest }, ref) => {
    return (
      <DropdownFilterButtonRoot
        ref={ref}
        disabled={disabled}
        // convert expanded prop into data-state, but spread rest props
        // afterward to override it.
        data-state={expanded ? 'open' : undefined}
        {...rest}
      >
        <DropdownFilterButtonLabel>{label}</DropdownFilterButtonLabel>
        <DropdownFilterButtonValue>{value}</DropdownFilterButtonValue>
        <DropdownFilterButtonIcon>
          <ChevronDown size={16} color='currentColor' />
        </DropdownFilterButtonIcon>
      </DropdownFilterButtonRoot>
    );
  },
);

export const DropdownFilterButtonRoot = styled('button')({
  boxSizing: 'border-box',
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-around',
  borderRadius: '40px',
  width: 'fit-content',
  color: colors.Glue_Ink00,
  fontSize: '14px',
  lineHeight: '20px',
  cursor: 'pointer',
  background: colors.White,
  border: `1px solid ${colors.Glue_BorderLight}`,
  transition: 'all 0.3s ease-out',
  padding: `9px 16px`,
  gap: theme.spacing(3),

  '&:hover': {
    background: colors.Glue_Paper,
    borderColor: colors.Glue_Ink00,
  },
  '&[data-state="open"]': {
    background: colors.Glue_Paper,
    borderColor: colors.Glue_Ink00,
    boxShadow: '0px 3px 3px #e3e4f3',
  },
  '&:disabled': {
    color: colors.Glue_Ink10,
  },
});

export const DropdownFilterButtonLabel = styled('div')({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  fontWeight: 500,
  color: 'inherit',

  '&::after': {
    content: '""',
    display: 'block',
    height: '1.25rem',
    borderLeft: `1px solid ${colors.Glue_Ink30}`,
    marginLeft: theme.spacing(3),
  },
});

export const DropdownFilterButtonValue = styled('div')({
  fontWeight: 700,
  color: 'inherit',
});

export const DropdownFilterButtonIcon = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: colors.Glue_Ink00,
  transition: 'transform 300ms ease-out',
  'button[disabled] &': {
    color: colors.Glue_Ink10,
  },
  'button[data-state="open"] &, button[data-expanded="true"] &': {
    transform: 'rotate(-180deg)',
  },
});

export default DropdownFilterButton;
