const MagnGlass = ({
    height,
    width,
    color,
    style = {},
    className = '',
  }) => {
    return (
      <svg
        className={className}
        width={width}
        height={height}
        style={style}
        viewBox={`0 0 ${width} ${height}`}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M13.2929 14.7071C13.6834 15.0976 14.3166 15.0976 14.7071 14.7071C15.0976 14.3166 15.0976 13.6834 14.7071 13.2929L13.2929 14.7071ZM11.8071 10.3929C11.4166 10.0024 10.7834 10.0024 10.3929 10.3929C10.0024 10.7834 10.0024 11.4166 10.3929 11.8071L11.8071 10.3929ZM14.7071 13.2929L11.8071 10.3929L10.3929 11.8071L13.2929 14.7071L14.7071 13.2929ZM11.6667 7.33333C11.6667 9.72657 9.72657 11.6667 7.33333 11.6667V13.6667C10.8311 13.6667 13.6667 10.8311 13.6667 7.33333H11.6667ZM7.33333 11.6667C4.9401 11.6667 3 9.72657 3 7.33333H1C1 10.8311 3.83553 13.6667 7.33333 13.6667V11.6667ZM3 7.33333C3 4.9401 4.9401 3 7.33333 3V1C3.83553 1 1 3.83553 1 7.33333H3ZM7.33333 3C9.72657 3 11.6667 4.9401 11.6667 7.33333H13.6667C13.6667 3.83553 10.8311 1 7.33333 1V3Z'
          fill={color}
        />
      </svg>
    );
  };
  
  export default MagnGlass;
