import { HTMLAttributes } from 'react';
import { css } from '@emotion/react';
import { colors } from '@mysteryco/design';
import { IconProps } from '@mysteryco/design/icons';
import theme from 'theme';

export interface ButtonProps extends HTMLAttributes<HTMLDivElement> {
  label?: string;
  Icon?: React.ComponentType<IconProps>;
  iconLocation?: 'leading' | 'trailing';
  disabled?: boolean;
  selected?: boolean;
}

export interface ButtonGroupProps extends HTMLAttributes<HTMLDivElement> {
  buttonsProps: ButtonProps[];
}

const ButtonGroup = ({ buttonsProps, ...extraProps }: ButtonGroupProps) => {
  return (
    <div {...extraProps} css={styles.root}>
      {buttonsProps.map(
        (
          {
            label,
            Icon,
            iconLocation = 'leading',
            disabled,
            selected,
            ...extraButtonProps
          }: ButtonProps,
          i: number,
        ) => {
          return (
            <div
              {...extraButtonProps}
              css={[
                styles.cell,
                disabled && styles.disabled,
                selected && styles.selected,
                i === 0 && styles.first,
                i === buttonsProps.length - 1 && styles.last,
                buttonsProps.length === 1 && styles.single,
              ]}
              key={`button-${i}`}
            >
              {Icon && iconLocation === 'leading' && (
                <div css={styles.icon}>
                  <Icon size={14} />
                </div>
              )}
              {label && <div css={styles.label}>{label}</div>}
              {Icon && iconLocation === 'trailing' && (
                <div css={styles.icon}>
                  <Icon size={14} />
                </div>
              )}
            </div>
          );
        },
      )}
    </div>
  );
};

const styles = {
  root: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
  }),
  cell: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: `${theme.spacing(2.5)} ${theme.spacing(4)}`,
    border: `1.5px solid ${colors.Coal_40}`,
    borderRight: 'none',
    color: colors.Coal_20,
    background: colors.Glue_Paper,
    '&:hover': {
      cursor: 'pointer',
      color: colors.Coal_10,
      background: colors.Coal_60,
    },
  }),
  disabled: css({
    color: colors.Coal_30,
    background: colors.Coal_60,
    borderColor: colors.Coal_50,
    '&:hover': {
      color: colors.Coal_30,
    },
  }),
  selected: css({
    background: colors.Mint_40,
    color: colors.Coal_10,
    '&:hover': {
      cursor: 'pointer',
      background: colors.Mint_40,
    },
  }),
  icon: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '20px',
  }),
  label: css({
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
  }),
  first: css({
    borderRadius: '8px 0px 0px 8px',
  }),
  last: css({
    borderRadius: '0px 8px 8px 0px',
    borderRight: `1.5px solid ${colors.Coal_40}`,
  }),
  single: css({
    borderRadius: '8px',
    border: `1.5px solid ${colors.Coal_40}`,
  }),
};

export default ButtonGroup;
