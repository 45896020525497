import { css } from '@emotion/react';
import theme from 'theme';

export const sharedStyles = {
  textInput: css({
    '& label': {
      marginBottom: theme.spacing(1),
      fontSize: '.875rem',
      fontWeight: 400,
      lineHeight: 1.43,
    },
  }),
  checkboxLabel: css({
    fontSize: '.875rem',
    fontWeight: 400,
    lineHeight: 1.43,
  }),
  checkboxHeading: css({
    fontWeight: 'bolder',
  }),
  button: {
    marginTop: theme.spacing(4),
    minWidth: '50%',
    alignSelf: 'center',
  },
};
