import React, { useContext } from 'react';

import { Type } from 'constants/index';
import { ResponsiveContext, responsiveSwitch } from 'providers/BreakpointsProvider';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';

type SizeVariant = 'normal' | 'big' | 'small';

interface SubTitleProps {
  children: any;
  className?: string;
  size?: SizeVariant;
  weight?: keyof typeof Type['FontWeights'];
  color?: string;
}

const useStyles = makeStyles({
  root: (props: any) => ({
    ...responsiveSwitch(
      {
        small: Type.fontSize(Type.Sizes.MEDIUM * props.factor),
        medium: Type.fontSize(Type.Sizes.LARGE * props.factor),
        large: Type.fontSize(Type.Sizes.LARGE * props.factor),
        xlarge: Type.fontSize(Type.Sizes.LARGE * props.factor),
      },
      props.context,
    ),
    fontWeight: Type.FontWeights[props.weight || 'NORMAL'],
    color: props.color,
  }),
});

export function SubTitle(props: SubTitleProps) {
  const context = useContext(ResponsiveContext);
  const factor = (() => {
    switch (props.size) {
      case 'small':
        return 0.8;
      case 'big':
        return 1.2;
      default:
      case 'normal':
        return 1;
    }
  })();

  const classes = useStyles({ context, factor, ...props });

  return (
    <h2 className={_.compact([classes.root, props.className]).join(' ')}>
      {props.children}
    </h2>
  );
}
