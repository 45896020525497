import { css } from '@emotion/react';
import Clapper from '@mysteryco/design/icons/Clapper';
import { colors } from '@mysteryco/design/src';
import { Paths } from 'Routes';
import { LinkButton } from 'glue/components/buttons/Button';
import { StyledLeadIcon } from 'glue/components/cards/CollapsibleCard';
import ListedStepper from 'glue/components/lists/ListedStepper';
import theme from 'theme';
import { Maybe } from 'types';
import MoralePulseStepWrapper from './MoralePulseStepWrapper';
import { getSuccessSteps } from './helpers';

const strings = {
  beenEnabled: 'Pulse has been enabled for your teams',
  changesSaved: 'Your changes have been saved and will be applied to upcoming surveys',
  whatNow: 'WHAT HAPPENS NOW',
  applied: 'APPLIED CHANGES',
  close: 'Close settings',
};

interface MoralePulseSuccessProps {
  stepperSteps: any[];
  isEnabling: boolean;
  firstName?: Maybe<string>;
}

const getHighFiveStr = (name?: Maybe<string>) =>
  name ? `High five, ${name}!` : `High five!`;

const MoralePulseSuccess = ({
  stepperSteps,
  isEnabling,
  firstName,
}: MoralePulseSuccessProps) => {
  const successSteps = getSuccessSteps({ steps: stepperSteps, isEnabling });

  return (
    <MoralePulseStepWrapper>
      <div css={styles.root}>
        <div css={styles.content}>
          <StyledLeadIcon backgroundColor={colors.Glue_Mint00}>
            <Clapper size={28} />
          </StyledLeadIcon>
          <div css={styles.title}>{getHighFiveStr(firstName)}</div>
          <div css={styles.subTitle}>
            {isEnabling ? strings.beenEnabled : strings.changesSaved}
          </div>
          <div css={styles.summaryDetails}>
            <div css={styles.subSection}>
              {isEnabling ? strings.whatNow : strings.applied}
            </div>
            <ListedStepper steps={successSteps} showConnector />
          </div>
          <LinkButton
            css={styles.closeButton}
            color='black'
            shape='round'
            to={`${Paths.EXPLORE}/pulse`}
          >
            {strings.close}
          </LinkButton>
        </div>
      </div>
    </MoralePulseStepWrapper>
  );
};

const styles = {
  root: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    padding: '2rem',
    width: 860,
  }),
  closeButton: css({ width: 154, justifyContent: 'center' }),
  content: css({
    color: colors.Glue_Ink00,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8),
    flex: 1,
  }),
  summaryDetails: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  }),
  title: css({
    fontSize: 32,
    fontWeight: 700,
    lineHeight: '120%',
  }),
  subTitle: css({
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '28px',
  }),
  subSection: css({
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: 12,
    lineHeight: 1.2,
    letterSpacing: '0.1em',
    color: colors.Glue_Ink10,
  }),
};

export default MoralePulseSuccess;
