// @ts-strict-ignore
/**
 * The NylasAvailabilityProvider is one big side effect...
 *
 * The purpose of this component is to call our Nylas availability API endpoint
 * and manipulate the SSF state based on the results of that call, for consumption
 * by the Event Selection portion of the flow.
 */
import { gql, useLazyQuery } from '@apollo/client';

import { getEmailsOfGuests } from 'lib/helpers/booking';
import _ from 'lodash';
import React, { ReactElement } from 'react';
import 'react-calendar/dist/Calendar.css';
import { GuestInputType, SelfServeState } from 'types/selfServeFlow';
import { NylasConnectionState, TimeWindow, ViewerFragmentFragment } from '../../types';
import {
  automatedSlotsToEvent,
  makeDummyEventsForSelection,
} from './EventSelectionDetails/helpers';

export const title = 'Event Date';
export const key = 'eventDate';
export const getSubtitle = ({ label }) => label;

export const sanitizeWindows = (windows: TimeWindow[]): TimeWindow[] =>
  _.filter(windows, (w) => !!w.startTime && !!w.endTime);

export const NylasAvailabilityProvider = (props: {
  globalState: SelfServeState;
  viewer?: ViewerFragmentFragment;
  setValue: (...args: any) => void;
  children: ReactElement;
}) => {
  const { setValue, children, globalState, viewer } = props;
  const guestEmails = getEmailsOfGuests({
    guests: globalState.uploadGuests?.guests,
  });

  const hasCalendarSyncGuestList =
    globalState.guestInputType === GuestInputType.imready &&
    !_.isEmpty(guestEmails) &&
    viewer.nylasConnectionState === NylasConnectionState.Active;

  const [getAvailability, { data: availabilityData }] = useLazyQuery(
    gql`
      query getAvailability($emails: [String]) {
        checkGroupCalendarAvailability(emails: $emails) {
          timeSlots {
            status
            startTime
            endTime
            emails
          }
          emailsNotOnProvider
          hasWorkingHours
        }
      }
    `,
    {
      variables: {
        emails: guestEmails,
      },
    },
  );

  React.useEffect(() => {
    if (hasCalendarSyncGuestList) {
      getAvailability();
      setValue(
        {
          ...globalState.uploadGuests,
          checkedCalendarAvailability: true,
          checkingCalendarAvailability: true,
        },
        'uploadGuests',
      );
    }
  }, [hasCalendarSyncGuestList]);

  React.useEffect(() => {
    const calendarAvailability = _.get(
      availabilityData,
      'checkGroupCalendarAvailability',
    );
    let setAvailableTimes = false;

    if (calendarAvailability) {
      const { timeSlots, emailsNotOnProvider, hasWorkingHours } = calendarAvailability;

      if (_.size(timeSlots)) {
        const filteredAndEventizedSlots = automatedSlotsToEvent({ slots: timeSlots });
        if (filteredAndEventizedSlots.length) {
          setValue(
            {
              availableEventTimes: filteredAndEventizedSlots,
              emailsNotOnProvider,
              hasWorkingHours,
            },
            'eventDate',
          );
          setAvailableTimes = true;
        }
      }
      setValue(
        { ...globalState.uploadGuests, checkingCalendarAvailability: false },
        'uploadGuests',
      );
    }

    if (!setAvailableTimes) {
      // Either no availablity data OR no times w/o blackout dates
      setValue(
        {
          availableEventTimes: makeDummyEventsForSelection(),
        },
        'eventDate',
      );
    }
  }, [availabilityData]);

  return children;
};
