import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CalendarCheck from 'components/illustrations/CalendarCheck';
import CalendarSyncButtons from 'components/CalendarSyncButtons';
import Check from 'components/icons/Check';
import CalendarCheck01 from 'components/icons/CalendarCheck01';
import FlipThroughCalendar from 'components/icons/FlipThroughCalendar';
import 'react-calendar/dist/Calendar.css';
import theme from 'theme';
import * as yup from 'yup';
import StepWrapper from './StepWrapper';
import { Props } from './types';
import UpSell from './UpSell';
import { CustomerType } from 'types';
import { getUpsellLink } from 'utils/stringUtil';

export const title = 'Calendar Sync';
export const key = 'calendarSync';

export const Content = ({
  globalState,
  value,
  setValue,
  viewer,
  ...props
}: Props<any>) => {
  const classes = useStyles();
  const title = 'Connect your calendar';
  const subTitle =
    'Boost event attendance and automatically find available times for your event by connecting your calendar';
  return (
    <StepWrapper
      title={''}
      hideNext
      messageWidget={
        viewer?.customerType === CustomerType.Transactional ? (
          <UpSell
            icon={<CalendarCheck01 />}
            description={
              <span>
                Glue can <strong>find the right date and time</strong> for everyone
              </span>
            }
            illustration={<FlipThroughCalendar />}
            illustrationStyle={{ top: 120, right: -110 }}
            linkTo={getUpsellLink('booking-calendar')}
          />
        ) : null
      }
      {...props}
    >
      <Box className={classes.container}>
        <CalendarCheck />
        <Box className={classes.contentWrapper}>
          <Box mb={6}>
            <Typography className={classes.title} variant='h4'>
              {title}
            </Typography>
            <Typography className={classes.subTitle}>{subTitle}</Typography>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'row' }}>
            <Check size={16} className={classes.checkIcon} />
            <Typography>
              <span className={classes.empasizedText}>Automatically find times</span> when
              all your guests are available for your event
            </Typography>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'row' }}>
            <Check size={16} className={classes.checkIcon} />
            <Typography>
              <span className={classes.empasizedText}>
                Send calendar holds and reminders
              </span>{' '}
              to guests so they don’t miss your event
            </Typography>
          </Box>
          <Box mt={6}>
            <Typography className={classes.calendarLabel}>
              Select your calendar platform:
            </Typography>
            <CalendarSyncButtons
              onConnection={() => {
                props.goToNextStep({ replace: true });
              }}
            />
          </Box>
          <Box mt={6}>
            <Typography className={classes.skip} onClick={() => props.goToNextStep()}>
              Skip for now
            </Typography>
          </Box>
        </Box>
      </Box>
    </StepWrapper>
  );
};

const useStyles = makeStyles({
  container: {
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  contentWrapper: {
    width: '720px',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },
  title: {
    marginBottom: theme.spacing(3),
    fontWeight: theme.typography.fontWeightBold,
  },
  subTitle: {
    marginBottom: theme.spacing(3),
    color: theme.palette.grey[700],
  },
  checkIcon: {
    marginRight: theme.spacing(5),
  },
  empasizedText: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
  calendarLabel: {
    marginBottom: theme.spacing(2),
  },
  skip: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
});

export const schema = yup
  .object({
    eventDate: yup.object(),
  })
  .required();
