// @ts-strict-ignore
import { css } from '@emotion/react';
import { InvitedGuestRsvpStatus, YourTasksViewerFragment } from 'types';
import Link from 'glue/components/buttons/Link';
import { Typography } from '@material-ui/core';
import { colors } from '@mysteryco/design';
import ProfileIcon from '@mysteryco/design/icons/MessageSmileCircle';
import StarIcon from '@mysteryco/design/icons/Star05';
import TicketIcon from '@mysteryco/design/icons/Ticket01';
import AsideHeader from 'glue/components/structure/AsideHeader';
import { gql } from '@apollo/client';
import { getUserRsvpStatus } from 'utils/state';
import { format } from 'date-fns';
import { isEventUpcoming, isEventValid } from 'lib/helpers/events';
import { useSessionStorage } from 'hooks';
import { Paths } from 'Routes';

interface YourTasksProps {
  viewer: YourTasksViewerFragment;
  className?: string;
}

const YourTasks = ({ viewer, ...rest }: YourTasksProps) => {
  const showUpdateProfile =
    viewer && (!viewer.firstName || !viewer.lastName || !viewer.companyRole);
  const askFeedbackEvent = viewer?.askFeedbackEvent;
  const allEvents = (viewer?.teamEvents?.edges || []).map((edge) => edge.node);
  const firstNotRsvpEvent = allEvents
    .filter((event) => isEventValid(event) && isEventUpcoming(event))
    .sort(
      (a, b) => new Date(a.requestedFor).getTime() - new Date(b.requestedFor).getTime(),
    )
    .find(
      (event) =>
        getUserRsvpStatus(viewer, event) === InvitedGuestRsvpStatus.NeedsMoreInfo ||
        getUserRsvpStatus(viewer, event) === InvitedGuestRsvpStatus.Invited,
    );

  // only show the feedback item once per session, even if there are multiple
  // feedback backlog items waiting
  const [hasSubmittedFeedback, setHasSubmittedFeedback] = useSessionStorage<boolean>(
    'hasSubmittedEventFeedbackFromHome',
  );

  const showFeedback = askFeedbackEvent && !hasSubmittedFeedback;

  const areAllHidden = !showUpdateProfile && !showFeedback && !firstNotRsvpEvent;

  if (areAllHidden) {
    return null;
  }

  return (
    <div css={styles.root} {...rest}>
      <AsideHeader>Your tasks</AsideHeader>
      {showUpdateProfile && (
        <Link to={Paths.PROFILE} css={styles.link}>
          <ProfileIcon css={styles.linkIcon} />
          <div css={styles.linkDetails}>
            <Typography variant='button'>Update profile</Typography>
            <Typography>Your work and personal details</Typography>
          </div>
        </Link>
      )}
      {showFeedback && (
        <Link
          to={`/feedback/${askFeedbackEvent.id}?product=TeamEvent`}
          css={styles.link}
          onClick={() => setHasSubmittedFeedback(true)}
        >
          <StarIcon css={styles.linkIcon} />
          <div css={styles.linkDetails}>
            <Typography variant='button'>Submit event feedback</Typography>
            <Typography>How was {askFeedbackEvent.title}?</Typography>
          </div>
        </Link>
      )}
      {firstNotRsvpEvent && (
        <Link to={`/event-details/${firstNotRsvpEvent.id}`} css={styles.link}>
          <TicketIcon css={styles.linkIcon} />
          <div css={styles.linkDetails}>
            <Typography variant='button'>RSVP to {firstNotRsvpEvent.title}</Typography>
            <Typography>
              RSVP before {format(new Date(firstNotRsvpEvent.requestedFor), 'MMM d')}
            </Typography>
          </div>
        </Link>
      )}
    </div>
  );
};

YourTasks.fragments = gql`
  fragment YourTasksViewer on User {
    id
    firstName
    lastName
    companyRole
    email

    askFeedbackEvent {
      id
      requestedFor
      title
    }

    teamEvents {
      edges {
        node {
          id
          requestedFor
          title
          status
          invitedGuests {
            user {
              id
            }
            rsvpStatus
            email
          }
        }
      }
    }
  }
`;

export default YourTasks;

const styles = {
  root: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: 0,
    gap: 8,
  }),
  link: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 12,
    padding: `16px 12px`,
    color: colors.Black,
    transition: 'background-color 0.2s ease',

    '&:hover': {
      backgroundColor: colors.Glue_LavenderLight,
    },
    '&:focus-visible': {
      backgroundColor: colors.Glue_LavenderLight,
      boxShadow: `inset 0 0 0 1.5px ${colors.Glue_Ink00}`,
    },
  }),
  linkIcon: css({
    width: 24,
    height: 24,
    stroke: colors.Glue_Ink10,
    fill: colors.Glue_Ink10,
    strokeWidth: 0,
    color: colors.Glue_Ink10,
  }),
  linkDetails: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 4,
  }),
};
