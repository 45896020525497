import Question from 'components/Checkout/steps/PreferenceQuestions/RadioOrCheckboxAnswer';
import RegistrationStepWrapper from 'components/Checkout/styling/RegistrationStepWrapper';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import { useState } from 'react';
import { WizardContext } from 'react-albus';
import { DietaryRestriction, ViewerFragmentFragment } from 'types';

export interface DietaryRestrictionsProps {
  context: WizardContext;
  viewer: ViewerFragmentFragment;
  dietaryRestrictionList: DietaryRestriction[];
  onNext: (answers: string[]) => Promise<void>;
}

export function DietaryRestrictions({
  context,
  viewer,
  dietaryRestrictionList,
  onNext,
}: DietaryRestrictionsProps) {
  const [value, setValue] = useState<string[]>([]);

  const onAnswer = ({ answerId, checked }) => {
    if (!checked) {
      setValue(value.filter((a) => a !== answerId));
    } else {
      setValue([...value, answerId]);
    }
  };

  const handleClickNext = async () => {
    await onNext(value);
    mixpanel.track('dietary restrictions added', {
      source: 'registration',
      questionsType: 'dietary',
      customerType: viewer?.customerType,
      firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
    });
  };

  return (
    <RegistrationStepWrapper
      {...context}
      label={'Drink or a snack'}
      subTitle={`Say we made you a drink and a snack, do you have any special requests?`}
      onClickNext={handleClickNext}
    >
      <Question
        currentAnswers={value}
        multiSelect
        onAnswer={onAnswer}
        answers={_.orderBy(dietaryRestrictionList, 'orderIndex').map((d) => ({
          ...d,
          label: d.name,
        }))}
      />
    </RegistrationStepWrapper>
  );
}

export default DietaryRestrictions;
