import { parsePhoneNumber, isValidNumber, parseNumber } from 'libphonenumber-js';

export function formatPhone(phone?: string | null) {
  if (!phone) return '';
  try {
    const parsedPhone = parsePhoneNumber(phone);
    return parsedPhone.formatNational();
  } catch (e) {
    return phone;
  }
}

export const parsePhone = (phone: string, validateNumber = true) => {
  if (validateNumber ? validate(phone) : true) {
    try {
      return parsePhoneNumber(phone).number as string;
    } catch (e) {
      console.log(e);
      //for legacy phone inputs
      return parsePhoneNumber(phone, 'US').number as string;
    }
  }
  return phone;
};

export const validate = (phone: string) => {
  if (!phone) return false;
  // if the phone number doesn't start with a +, we're assuming its U.S.
  // and adding a +1 before parsing for validations

  if (!phone.startsWith('+')) {
    phone = '+1' + phone;
  }

  try {
    const parsedNumber = parsePhone(phone, false);
    return isValidNumber(parseNumber(parsedNumber) as any);
  } catch (e) {
    console.error(e);
    return false;
  }
};
