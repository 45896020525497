import { css } from '@emotion/react';
import { colors } from '@mysteryco/design';
import { useField } from 'formik';
import PreferencesSection, { sectionStyles } from './PreferencesSection';
import MeetupsBold from '@mysteryco/design/icons/MeetupsBold';
import Stars03 from '@mysteryco/design/icons/Stars03';
import { Tooltip } from 'glue/components/tooltip/Tooltip';
import Toggle from 'glue/components/Toggle';
import theme from 'theme';

const Participation = ({
  isManager,
}: {
  isManager: boolean;
}) => {
  const [, meta, helpers] = useField({name: 'optOutOfWatercoolerAt'});
  const { value: optOutOfWatercoolerAt } = meta;
  const { setValue } = helpers;
  return (
    <PreferencesSection
      title={'Participation'}
      description={
        'Choose the connection activities you want Glue to include so you can easily connect with others.'
      }
    >
      <div css={sectionStyles.content}>
        <div css={styles.header}>Activity</div>
        <div css={styles.meetupCard} data-opted-out={!!optOutOfWatercoolerAt}>
          <div css={styles.icon} data-opted-out={!!optOutOfWatercoolerAt}>
            <MeetupsBold
              size={28}
              color={!!optOutOfWatercoolerAt ? colors.Glue_Ink00 : colors.Plum_10}
            />
          </div>
          <div>
            <div css={styles.title}>Meetups</div>
            <div css={styles.description}>
              We use your connections, interests, and availability to schedule tailored
              1:1 and small group meetings twice a month, boosting your network!
            </div>
            {isManager && (
              <Tooltip
                content={
                  <div css={{ width: '280px' }}>
                    As a company leader, we’ll connect you with individuals seeking to
                    boost their connections to leadership, and your Meetups will always
                    include 3+ people to maximize your time and impact.
                  </div>
                }
                size={'small'}
              >
                <div css={styles.managerTag}>
                  <Stars03 size={14} color={colors.Black} />
                  <div css={styles.managerText}>You play an important role</div>
                </div>
              </Tooltip>
            )}
          </div>
          <div css={styles.switch}>
            Opted {!optOutOfWatercoolerAt ? 'in' : 'out'}
            <Toggle
              checked={!optOutOfWatercoolerAt}
              onChange={(event) => setValue(!event.target.checked ? new Date() : null)}
            />
          </div>
        </div>
      </div>
    </PreferencesSection>
  );
};

const styles = {
  content: css({
    display: 'flex',
    flexDirection: 'column',
    padding: `0 ${theme.spacing(5)}`,
    gap: theme.spacing(7),
  }),
  header: css({
    fontWeight: 900,
    fontSize: '12px',
    lineHeight: '120%',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
    color: colors.Coal_20,
  }),
  meetupCard: css({
    padding: theme.spacing(5),
    border: `1.5px solid ${colors.Coal_40}`,
    background: colors.Glue_Paper,
    borderRadius: '13px',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(5),
    '&:hover': {
      borderColor: colors.Coal_30,
      boxShadow: '0px 2px 8px rgba(155, 160, 166, 0.06)',
    },
    '&[data-opted-out=true]': {
      background: colors.Glue_LavenderLight,
    },
  }),
  icon: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: colors.Plum_60,
    padding: theme.spacing(2),
    borderRadius: '15px',
    height: 'fit-content',
    '&[data-opted-out=true]': {
      background: colors.Coal_50,
    },
  }),
  title: css({
    fontWeight: 800,
    fontSize: '16px',
    lineHeight: '140%',
  }),
  description: css({
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '140%',
  }),
  managerTag: css({
    marginTop: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: 'fit-content',
    cursor: 'pointer',
  }),
  managerText: css({
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '140%',
    color: colors.Coal_10,
    borderBottom: `1.5px solid ${colors.Mint_30}`,
  }),
  switch: css({
    display: 'flex',
    fontWeight: 900,
    fontSize: '14px',
    lineHeight: '140%',
    textAlign: 'right',
    color: colors.Coal_20,
    flex: 'none',
    gap: theme.spacing(3),
  }),
};

export default Participation;
