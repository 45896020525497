import { CustomerType } from 'types';

export const isUpsellable = (customerType?: CustomerType) =>
  customerType !== CustomerType.EventsAndInsights;

export const isTransactional = (customerType?: CustomerType) =>
  customerType === CustomerType.Transactional;

export const isContract = (customerType?: CustomerType) =>
  customerType === CustomerType.EventsAndInsights ||
  customerType === CustomerType.EventsOnly;
