import { SnackbarUtils } from 'components/core';
import { OrgSearch, OrgSearchProps } from 'components/OrgSearch';
import { getEmailsOfGuests } from 'lib/helpers/booking';
import _ from 'lodash';
import { GuestsType } from 'types/selfServeFlow';
import { getParsedEmails } from 'utils/stringUtil';
import humanize from 'humanize-plus';
import { gql } from '@apollo/client';
import { OrgSearchAndSelectOrganizationFragment } from 'types';

export type OrgSearchAndSelectInterface = {
  organization: OrgSearchAndSelectOrganizationFragment | undefined | null;
  setGuests(guests: GuestsType): void;
  currentGuests: GuestsType;
} & Pick<
  OrgSearchProps,
  'searchTags' | 'setSearchTags' | 'setSearchString' | 'searchString'
>;

const makeInfoMessage = ({ name, tagNameList }) => {
  SnackbarUtils.info(
    `${name} is already added via the ${humanize.oxford(
      tagNameList,
    )} ${humanize.pluralize(_.size(tagNameList), 'group')}`,
  );
};

const OrgSearchAndSelect = ({
  organization,
  searchTags,
  setSearchTags,
  setSearchString,
  searchString,
  setGuests,
  currentGuests,
}) => {
  return (
    <OrgSearch
      orgId={organization?.id}
      orgTags={organization?.userTags}
      searchTags={searchTags}
      setSearchTags={setSearchTags}
      searchString={searchString}
      setSearchString={setSearchString}
      showUsersInOptions
      useAsSelectionComponent
      onChange={(_event, selectedVals) => {
        const groupsSelected = [..._.get(currentGuests, 'groups', [])];
        const otherGuestsSelected = [..._.get(currentGuests, 'otherGuests', [])];
        const groupEmailSet = new Set<string>(
          getEmailsOfGuests({
            guests: { groups: groupsSelected, otherGuests: otherGuestsSelected },
            groupsOnly: true,
          }),
        );
        selectedVals = selectedVals.filter((val) => val.type !== 'dummy');
        selectedVals.forEach((val) => {
          if (val.__typename === 'UserTag') groupsSelected.push(val);
          else if (val.__typename === 'User') {
            if (!groupEmailSet.has(val.email)) {
              otherGuestsSelected.push(val);
            } else {
              const tags = val.tags;
              const matchingTags = tags.filter((t) =>
                new Set(_.map(groupsSelected, 'id')).has(t.id),
              );
              makeInfoMessage({
                name: _.get(val, 'name', val.email),
                tagNameList: _.map(matchingTags, 'name'),
              });
            }
          } else {
            const emailArray = _.map(getParsedEmails(val), (email) => ({
              email,
              name: email,
            }));
            otherGuestsSelected.push(...emailArray);
          }
        });

        setGuests({
          // Remove null emails (can occur with automated tagging and HRIS integration)
          groups: _.map(_.uniqBy(groupsSelected, 'id'), (group) => ({
            ...group,
            users: _.filter(group.users, (u) => !!u.email),
          })),
          otherGuests: _.uniqBy(otherGuestsSelected, 'email'),
        });
        setSearchString('');
      }}
    />
  );
};
OrgSearchAndSelect.fragments = gql`
  fragment OrgSearchAndSelectOrganization on Organization {
    id
    userTags {
      id
      name
      type
      users {
        id
      }
    }
  }
`;

export default OrgSearchAndSelect;
