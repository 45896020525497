import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@mysteryco/design';
import { Link } from 'react-router-dom';

import theme from 'theme';

export const useStyles = makeStyles({
  root: {
    border: `1px solid ${colors.Purple100}`,
    backgroundColor: colors.White,
    borderRadius: 4,
  },
  tabs: {
    display: 'flex',
    textTransform: 'uppercase',
    gap: 8,
    margin: `-1px -1px 0 -1px`,
    backgroundColor: colors.White,
    '& > *': {
      color: colors.Purple700,
      boxSizing: 'border-box',
      display: 'flex',
      flex: 1,
      cursor: 'pointer',
      backgroundColor: colors.Purple50,
      border: `1px solid ${colors.Purple100}`,
      borderRadius: '4px 4px 0 0',
      padding: `0 ${theme.spacing(4)}`,
      fontSize: '12px',
      fontWeight: 600,
      letterSpacing: '1px',
      alignItems: 'center',
      height: theme.spacing(11),
      transition: `background-color 300ms`,
      '&:hover': {
        backgroundColor: colors.Purple100,
      },
    },
  },
  activeTab: {
    backgroundColor: `${colors.White} !important`, // y'know, for hovers
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomColor: colors.White,
    cursor: 'default',
    fontWeight: 700,
    color: colors.Purple800,
  },
  content: {
    padding: `${theme.spacing(6)} ${theme.spacing(8)}`,
  },
});

interface Props<TTabs extends string> {
  tabs: readonly TTabs[];
  value: TTabs;
  urlForValue: (tab: TTabs) => string;
  children: React.ReactNode;
  footer?: React.ReactNode;
}

const ScoreDetailsInsightsFauxTabs = <TTabs extends string>({
  tabs,
  value,
  urlForValue,
  children,
  footer,
}: Props<TTabs>) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.tabs}>
        {tabs.map((tab) => (
          <Link
            key={tab}
            to={urlForValue(tab)}
            className={tab === value ? classes.activeTab : ''}
          >
            {tab}
          </Link>
        ))}
      </div>
      <div className={classes.content}>{children}</div>
      {footer}
    </div>
  );
};

export default ScoreDetailsInsightsFauxTabs;
