import { gql } from '@apollo/client';
import { css } from '@emotion/react';
import { colors } from '@mysteryco/design';
import ConnectionZeroState from 'glue/assets/artwork/connection-zero-state.png';
import FeatureStatusPills from 'glue/components/structure/FeatureStatusPills';
import theme from 'theme';
import { Paths } from 'Routes';
import { LinkButton } from 'glue/components/buttons/Button';
import ArrowRight from '@mysteryco/design/icons/ArrowRight';

const EmptyState = () => {
  return (
    <div css={styles.root}>
      <div css={styles.status}>
        <FeatureStatusPills text={'Locked'} />
      </div>
      <div css={styles.summary}>
        <h2 css={styles.headline}>Understand your org’s connectivity</h2>
        <p css={styles.details}>
          Complete your Getting Started steps to unlock your team insights and help you
          see how connected your organization is
        </p>
      </div>
      <div css={styles.image}>
        <img src={ConnectionZeroState} alt='' />
      </div>
      <div css={styles.cta}>
        <LinkButton
          color='green'
          shape='round'
          to={Paths.HOME}
          endIcon={<ArrowRight size={24} color='currentColor' />}
        >
          Finish onboarding to unlock
        </LinkButton>
      </div>
    </div>
  );
};

EmptyState.fragments = gql`
  fragment InsightsEmptyStateOrganization on Organization {
    name
    isHrisConnected
    members {
      ...CalendarActionItemFooter
    }
  }
  fragment InsightsEmptyStateViewer on User {
    nylasConnectionState
  }
  fragment CalendarActionItemFooter on User {
    nylasAssociation {
      id
    }
  }
`;

const styles = {
  root: css({
    marginTop: theme.spacing(10),
  }),
  status: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  }),
  summary: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: theme.spacing(10),
    gap: theme.spacing(5),
  }),
  headline: css({
    fontFamily: 'inherit',
    fontWeight: 800,
    fontSize: '2.5rem',
    lineHeight: 1.2,
    textAlign: 'center',
    color: colors.Glue_Ink00,
  }),
  details: css({
    fontFamily: 'inherit',
    fontWeight: 500,
    fontSize: '1.375rem',
    lineHeight: 1.4,
    textAlign: 'center',
    color: colors.Glue_Ink00,
    maxWidth: 800,
  }),
  image: css({
    margin: 'auto',
    '& img': {
      maxWidth: '100%',
    },
  }),
  cta: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }),
};

export default EmptyState;
