import { css } from '@emotion/react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { AccessibilityIcon } from 'components/icons/Accessibility';
import Toggle from 'glue/components/Toggle';
import { useColorblindMode } from 'glue/components/accessibility/hooks';
import theme from 'theme';

export interface ColorblindToggleProps {
  className?: string;
}

export function ColorblindToggle({ className }: ColorblindToggleProps) {
  const [isEnabled, setIsEnabled] = useColorblindMode();

  return (
    <FormControlLabel
      value='colorblindMode'
      control={<Toggle />}
      label={
        <span css={styles.label}>
          <AccessibilityIcon /> Show patterns
        </span>
      }
      labelPlacement='start'
      checked={isEnabled}
      onChange={() => setIsEnabled(!isEnabled)}
      className={className}
      css={styles.root}
    />
  );
}

export default ColorblindToggle;

const styles = {
  root: css({
    gap: theme.spacing(3),
    // MUI is so weird... why is there a negative margin?
    marginRight: 0,
  }),
  label: css({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3),
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '24px',
  }),
};
