// @ts-strict-ignore
import { Box, Divider, Drawer, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Dusk, Main, Purple100, White } from '@mysteryco/design/src/tokens/colors';
import NewPrimaryButton from 'components/core/NewPrimaryButton';
import LineChartUp from 'components/icons/LineChartUp';
import Calendar from 'components/illustrations/Calendar';
import Rocket from 'components/illustrations/Rocket';
import SentimentRow from 'components/SentimentRow';
import StarRating from 'components/StarRating';
import { SCORE_TYPE_TO_PATH_PARAM } from 'constants/Strings';
import UpsellSubscription from 'glue/components/UpsellSubscription';
import { DateTime } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import theme from 'theme';
import {
  CustomerType,
  ScoreType,
  TeamEventFragmentFragment,
  TeamEventRating,
  ViewerFragmentFragment,
} from 'types';
import EventMetricData from './EventMetricData';
import EventMetricsCard from './EventMetricsCard';
import PlaceholderCard from './PlaceholderCard';
import { Score } from './ScorePill';

const REVIEW_PERIOD_HOURS = 24;

const PostEventPlaceholder = () => {
  // &#8288; => stops lines from breaking between 'feedback' and emdash.
  return (
    <PlaceholderCard
      title='Feedback is coming in'
      subtitle='We are waiting for guests to provide their feedback&#8288;&mdash;stay tuned'
    >
      <Rocket />
    </PlaceholderCard>
  );
};

const PreEventPlaceholder = () => {
  return (
    <PlaceholderCard
      title='Understand your event’s impact'
      subtitle='Once your event takes place, you will be able to see guest feedback on the event including how it impacted their sense of connection. '
    >
      <Calendar />
    </PlaceholderCard>
  );
};

const ExperienceRatingCard = ({
  rating,
  showUpsell = false,
}: {
  rating?: TeamEventRating;
  showUpsell?: boolean;
}) => {
  const overallRating = rating?.overallRating;
  const score =
    overallRating >= 4 ? Score.High : overallRating >= 3 ? Score.Average : Score.Low;
  return (
    <EventMetricsCard
      title='Event rating'
      subtitle='What guests thought of this event'
      score={score}
      showUpsell={showUpsell}
      upsellEmpty
    >
      <EventMetricData
        value={overallRating.toFixed(1)}
        text='of 5 stars'
        showUpsell={showUpsell}
      />
      <StarRating value={overallRating} />
    </EventMetricsCard>
  );
};

const ConnectednessCard = ({
  connectedness,
  connectionsStrengthened,
  responses = 0,
  showUpsell = false,
}) => {
  const classes = useStyles();
  const getSentimentPercentage = (connectedness: number): number => {
    if (!connectedness || responses === 0) return 0;
    return Math.round((connectedness / responses) * 100);
  };

  return (
    <EventMetricsCard
      title='Connection impact'
      subtitle='How guests felt this impacted their connections'
      showUpsell={showUpsell}
      upsellLock
    >
      <SentimentRow
        low={getSentimentPercentage(connectedness?.low)}
        neutral={getSentimentPercentage(connectedness?.neutral)}
        strong={getSentimentPercentage(connectedness?.strong)}
        showUpsell={showUpsell}
      />
      <Divider className={classes.metricDivider} />
      <EventMetricData
        value={connectionsStrengthened}
        text='connections strengthened'
        showUpsell={showUpsell}
      />
    </EventMetricsCard>
  );
};

const RSVPCard = ({ rsvpSummary, upsell: showUpsell = false }) => {
  const getResponseRate = (): number => {
    const invited = rsvpSummary?.total || 0;
    const accepted = rsvpSummary?.accepted || 0;
    if (invited === 0) return 0;
    return Math.floor((accepted / invited) * 100);
  };

  const responseRate = getResponseRate();
  const score =
    responseRate >= 75 ? Score.High : responseRate >= 50 ? Score.Average : Score.Low;

  return (
    <EventMetricsCard
      title='RSVP rate'
      subtitle='Response rate from invited guests'
      score={score}
      showUpsell={showUpsell}
      upsellEmpty
    >
      <Box
        css={{ display: 'flex', gap: theme.spacing(3) }}
        sx={{ flexDirection: 'column' }}
      >
        <EventMetricData
          value={rsvpSummary?.accepted}
          text={`of ${rsvpSummary?.total} guests`}
          showUpsell={showUpsell}
        />
        <EventMetricData
          value={`${responseRate.toFixed(0)}%`}
          text={`acceptance rate`}
          type='sm'
          showUpsell={showUpsell}
        />
      </Box>
    </EventMetricsCard>
  );
};

const InsightsButton = ({ organization }) => {
  const classes = useStyles();
  const url = `/insights/${organization.id}/${organization.rootTeam.id}/${
    SCORE_TYPE_TO_PATH_PARAM[ScoreType.SenseOfBelonging]
  }`;
  return (
    <NewPrimaryButton
      className={classes.insightsButton}
      label={'View more connection insights'}
      prependedIcon={<LineChartUp color={White} />}
      href={url}
    />
  );
};

const EventMetrics = ({
  teamEvent,
  viewer,
  showUpsell = false,
  canUpsell = false,
}: {
  teamEvent: TeamEventFragmentFragment;
  viewer: ViewerFragmentFragment;
  showUpsell?: boolean;
  canUpsell?: boolean;
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const timerRef = useRef(null);

  const { teamEventMetricSummary, rsvpSummary, requestedFor, organization } = teamEvent;

  const date = DateTime.fromISO(requestedFor);
  const durationMins = teamEvent.virtualEvent.durationMins || 60;
  const endTime = date.plus({ minutes: durationMins });

  const showPreEventPlaceholder = DateTime.now() < endTime && !showUpsell;

  const showPostEventPlaceholder =
    !showPreEventPlaceholder &&
    (DateTime.now() < endTime.plus({ hours: REVIEW_PERIOD_HOURS }) ||
      !teamEventMetricSummary?.responses) &&
    !showUpsell;

  const showTeamEventMetrics = !showPreEventPlaceholder && !showPostEventPlaceholder;

  const showRSVPSummary = !!rsvpSummary?.total || showUpsell;

  const organizationRootTeamId = organization?.rootTeam?.id;
  const isEventsAndInsights = viewer?.customerType === CustomerType.EventsAndInsights;
  const showInsights =
    showTeamEventMetrics && organizationRootTeamId && isEventsAndInsights && !showUpsell;

  const onMouseEnter = () => {
    if (canUpsell) {
      timerRef.current = setTimeout(() => {
        setOpen(true);
      }, 800);
    }
  };

  const onMouseLeave = () => {
    clearTimeout(timerRef.current);
  };

  const onClose = () => {
    setOpen(false);
  };

  // Clear the interval when the component unmounts
  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Typography className={classes.headerText}>Event Metrics</Typography>
      </Box>
      <Box
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={classes.container}
      >
        {showPreEventPlaceholder && <PreEventPlaceholder />}
        {showPostEventPlaceholder && <PostEventPlaceholder />}
        {showTeamEventMetrics && (
          <>
            <ConnectednessCard
              responses={teamEventMetricSummary?.responses}
              connectedness={teamEventMetricSummary?.connectedness}
              connectionsStrengthened={teamEvent?.connectionsStrengthened}
              showUpsell={showUpsell}
            />
            <ExperienceRatingCard
              rating={teamEventMetricSummary?.rating}
              showUpsell={showUpsell}
            />
          </>
        )}
        {showRSVPSummary && <RSVPCard rsvpSummary={rsvpSummary} upsell={showUpsell} />}
        {showInsights && <InsightsButton organization={organization} />}
      </Box>
      <Drawer anchor='right' open={open} onClose={onClose}>
        <UpsellSubscription onClose={onClose} />
      </Drawer>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(4),
    flexDirection: 'column',
    position: 'relative',
  },
  header: { minHeight: 48, display: 'flex', alignItems: 'center' },
  subheaderText: {
    fontSize: '.875rem',
    fontWeight: theme.typography.fontWeightBold,
    color: Dusk,
  },
  insightsButton: {
    marginTop: theme.spacing(4),
    background: Main,
    transition: 'none',
    '&:hover': {
      color: White,
    },
    fontSize: '.875rem',
    fontWeight: theme.typography.fontWeightBold,
  },
  metricDivider: {
    background: Purple100,
    margin: `${theme.spacing(4)} 0 ${theme.spacing(3)}`,
  },
  headerText: {
    fontSize: '.875rem',
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
    color: Dusk,
    letterSpacing: 1,
  },
}));

export default EventMetrics;
