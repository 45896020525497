import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { StepObject } from 'react-albus';
import theme, { primaryGradient } from 'theme';

const useStyles = makeStyles<Theme, { height: number }>({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 24px',
  },
  stepTextContainer: {
    whiteSpace: 'nowrap',
    marginRight: theme.spacing(2),
  },
  barContainer: ({ height }) => ({
    position: 'relative',
    height,
    borderRadius: height / 2,
    width: '100%',
    border: `1px solid ${theme.palette.grey[500]}`,
    boxSizing: 'content-box',
  }),
  fill: ({ height }) => ({
    height,
    borderRadius: height / 2,
    background: primaryGradient,
  }),
  lineSeparator: ({ height }) => ({
    position: 'absolute',
    left: '10%',
    top: 1,
    width: 1,
    height: height - 2,
    backgroundColor: theme.palette.common.white,
  }),
});

interface Props {
  steps: StepObject[];
  step: StepObject;
  height?: number;
}

const Progress = ({ steps, step, height = 10 }: Props) => {
  const stepsWithoutConfirmation = steps.slice(0, steps.length - 1);
  const stepIndex = stepsWithoutConfirmation.indexOf(step) + 1;
  const percentWithoutConfirmation = (stepIndex / stepsWithoutConfirmation.length) * 100;
  const classes = useStyles({ height });
  const confirmationStep = step === steps[steps.length - 1];
  if (confirmationStep) return <></>;
  return (
    <Box className={classes.container}>
      <Box className={classes.stepTextContainer}>
        {stepIndex} of {stepsWithoutConfirmation.length}
      </Box>
      <Box className={classes.barContainer}>
        {stepsWithoutConfirmation.map((s, i) => {
          if (i !== steps.length - 1) {
            const idx = steps.indexOf(s) + 1;
            const percent = (idx / stepsWithoutConfirmation.length) * 100;
            return (
              <Box
                key={`progress-${i}`}
                className={classes.lineSeparator}
                style={{ left: `${percent}%` }}
              />
            );
          }
          return undefined;
        })}
        <Box
          className={classes.fill}
          style={{ width: `${percentWithoutConfirmation}%` }}
        />
      </Box>
    </Box>
  );
};

export default Progress;
