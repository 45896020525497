// @ts-strict-ignore
import SelectFilter from './SelectFilter';
import {
  useDeleteUserTagsMutation,
  useRenameUserTagMutation,
  UserTag,
  UserTagType,
} from 'types';
import { pluralize } from 'humanize-plus';
import EditUserTag, { isTagChanged, isTagDuplicate } from 'components/Forms/EditUserTag';
import { getOrganizationQuery } from 'gql/queries';
import TagChip from 'components/Chips/TagChip';

interface SelectTagFilterProps {
  userTags: UserTag[];
  value?: string[];
  onChange?: (event: any, newValue: string[]) => void;
  disabled?: boolean;
  [key: string]: any;
}

const SelectTagFilter = ({
  userTags = [],
  value = [],
  onChange = () => null,
  disabled = false,
  ...props
}: SelectTagFilterProps) => {
  const [renameTag] = useRenameUserTagMutation();
  const [deleteTag] = useDeleteUserTagsMutation();

  // only allow manual user tag selection
  const userTagOptions = userTags.filter((tag) => tag.type === UserTagType.Manual);

  const handleSave = async (userTag: UserTag) => {
    await renameTag({
      variables: { tagId: userTag.id, name: userTag.name },
      refetchQueries: [{ query: getOrganizationQuery }],
    });
  };

  const isValid = (newTag: UserTag): boolean => {
    return (
      newTag?.name &&
      !isTagDuplicate(newTag, userTagOptions) &&
      isTagChanged(newTag, userTagOptions)
    );
  };

  const RenderUserTagOption = (userTag: UserTag) => {
    if (!userTag) return <></>;
    return <TagChip text={userTag.name} />;
  };

  const handleDelete = async (
    event: any,
    tagIds: string[],
    thisOnChange: (event: any, value: UserTag) => void,
  ) => {
    await deleteTag({
      variables: { tagIds },
      refetchQueries: [{ query: getOrganizationQuery }],
    });
    thisOnChange(event, null);
    onChange(
      event,
      value?.filter((id) => tagIds[0] !== id),
    );
  };

  const RenderActiveUserTag = (
    userTag: UserTag,
    thisOnChange: (event: any, newValue: UserTag) => void,
  ) => {
    return (
      <EditUserTag
        userTag={userTag}
        userTagOptions={userTagOptions}
        onChange={thisOnChange}
        onDelete={handleDelete}
      />
    );
  };

  return (
    <SelectFilter
      label='Tags'
      options={userTagOptions}
      value={value}
      onChange={onChange}
      onSave={async (_, userTag) => handleSave(userTag)}
      disabled={disabled}
      renderOption={RenderUserTagOption}
      renderActive={RenderActiveUserTag}
      selectAllText={`Select all ${userTagOptions?.length} ${pluralize(
        userTagOptions?.length,
        'tag',
      )}`}
      isValid={isValid}
      placeholderText='Search tags'
      disableCreate
      {...props}
    />
  );
};

export default SelectTagFilter;
