import theme from 'theme';

const LongArrowRight = ({
  color = theme.palette.primary[400],
  className = '',
  style = {},
}) => {
  return (
    <svg
      className={className}
      width='29'
      height='9'
      style={style}
      viewBox='0 0 29 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1 5.5C0.447716 5.5 0 5.05228 0 4.5C0 3.94772 0.447716 3.5 1 3.5V5.5ZM28 4.5L28.7071 3.79289C29.0976 4.18342 29.0976 4.81658 28.7071 5.20711L28 4.5ZM25.2071 8.70711C24.8166 9.09763 24.1834 9.09763 23.7929 8.70711C23.4024 8.31658 23.4024 7.68342 23.7929 7.29289L25.2071 8.70711ZM23.7929 1.70711C23.4024 1.31658 23.4024 0.683417 23.7929 0.292893C24.1834 -0.0976311 24.8166 -0.0976311 25.2071 0.292893L23.7929 1.70711ZM1 3.5H28V5.5H1V3.5ZM23.7929 7.29289L27.2929 3.79289L28.7071 5.20711L25.2071 8.70711L23.7929 7.29289ZM27.2929 5.20711L23.7929 1.70711L25.2071 0.292893L28.7071 3.79289L27.2929 5.20711Z'
        fill={color}
      />
    </svg>
  );
};

export default LongArrowRight;
