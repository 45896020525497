import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Purple100, Purple50 } from '@mysteryco/design/src/tokens/colors';

const UpsellWrapper = ({
  showUpsell,
  children,
  height,
  width,
  margin,
}: {
  showUpsell: Boolean;
  children?: JSX.Element;
  height?: number | string;
  width?: number | string;
  margin?: number | string;
}) => {
  const classes = useStyles();
  const fixedSize = height != null && width != null;
  return showUpsell ? (
    <Box className={classes.upsellWrapper} height={height} width={width} margin={margin}>
      {fixedSize}
    </Box>
  ) : (
    <>{children}</>
  );
};

const useStyles = makeStyles({
  upsellWrapper: {
    display: 'inline-block',
    background: `linear-gradient(90deg, ${Purple100} 0%, ${Purple50} 100%)`,
    '& > *': {
      visibility: 'hidden',
    },
  },
});

export default UpsellWrapper;
