import { gql } from '@apollo/client';
import { Link, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@mysteryco/design';
import TooltipWrapper from 'components/core/TooltipWrapper';
import PieProgress from 'components/PieProgress';
import { Skeleton } from 'components/Skeleton';
import { TeamSearch } from 'components/TeamSearch';
import { SCORE_TYPE_NAME_MAP } from 'constants/Strings';
import { getIntegrationsPath } from 'glue/scenes/Settings/Integrations';
import { pluralize } from 'humanize-plus';
import { useHistory } from 'react-router-dom';
import theme from 'theme';
import { AllTeamSearchFragment, ScoreDetailsTeamFragment } from 'types';

interface Props {
  loading: boolean;
  teamId: string;
  setTeamId: (newTeamId: string | undefined) => unknown;
  team?: ScoreDetailsTeamFragment;
  scoreType: string;
  allTeamsInMyOrg?: AllTeamSearchFragment[];
}

const LightTooltip = withStyles(() => ({
  tooltip: {
    color: colors.Midnight,
    backgroundColor: colors.White,
    borderColor: colors.Purple100,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 4px 20px rgba(123, 23, 131, 0.1)',
    fontSize: '14px',
    lineHeight: '24px',
  },
}))(Tooltip);

const ScoreDetailsTeam = ({
  loading,
  teamId,
  setTeamId,
  team,
  scoreType,
  allTeamsInMyOrg,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const totalTeamMembers = allTeamsInMyOrg?.flatMap((team) => team.members).length ?? 0;
  const teamMembersSharingCalendar =
    allTeamsInMyOrg
      ?.flatMap((team) => team.members)
      .filter((member) => !!member.nylasAssociation?.id).length ?? 0;
  const calendarSharingRate =
    totalTeamMembers > 0 ? teamMembersSharingCalendar / totalTeamMembers : 0;
  const lowShareRate = calendarSharingRate < 0.4;
  return (
    <div>
      {loading ? (
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Skeleton
            variant={'circle'}
            width={28}
            height={28}
            css={{ margin: theme.spacing(4) }}
          />
          <Skeleton width={'100%'} height={48} />
        </div>
      ) : (
        calendarSharingRate > 0 &&
        calendarSharingRate < 0.9 && (
          <div
            className={classes.accuracyContainer}
            css={
              lowShareRate
                ? {
                    borderColor: colors.Orange300,
                    backgroundColor: colors.Orange100,
                  }
                : {
                    borderColor: colors.Purple300,
                    backgroundColor: colors.Purple50,
                  }
            }
          >
            <PieProgress percentFilled={calendarSharingRate * 100} size={24} />
            <LightTooltip
              placement={'bottom'}
              title={
                <TooltipWrapper>
                  <div className={classes.tooltipContainer}>
                    <div className={classes.tooltipHeader}>{`${
                      lowShareRate ? 'Low' : 'Medium'
                    } accuracy`}</div>
                    <div>
                      {`${
                        lowShareRate
                          ? 'There are not enough members who have connected their calendars. '
                          : 'Enough members have connected their calendars. '
                      }
                      We recommend at least 40% to begin seeing actionable data.`}
                    </div>
                  </div>
                </TooltipWrapper>
              }
            >
              <span
                className={classes.accuracyChip}
                css={
                  lowShareRate
                    ? {
                        color: '#BC5636', // Orange800
                        backgroundColor: colors.Orange200,
                      }
                    : {
                        color: colors.Dusk,
                        backgroundColor: colors.Purple100,
                      }
                }
              >{`${lowShareRate ? 'Low' : 'Medium'} accuracy`}</span>
            </LightTooltip>
            <span className={classes.accuracyText}>
              {(calendarSharingRate * 100).toFixed()}% of members are sharing their
              calendars with Glue
            </span>
            <Link
              underline={'always'}
              className={classes.increaseAccuracyLink}
              onClick={() => history.push(getIntegrationsPath())}
            >
              Increase accuracy
            </Link>
          </div>
        )
      )}
      <div className={classes.headerLabel}>{SCORE_TYPE_NAME_MAP[scoreType]}</div>
      <div>
        <TeamSearch value={teamId} onChange={(id) => setTeamId(id)} />
        <div className={classes.headerTeamDetails}>
          <div className={classes.headerTeamManager}>
            {team ? (
              <Typography className={classes.headerTeamManagerCopy}>
                {`Manager: ${team.manager?.name ?? ''}, ${
                  team.members.length
                } ${pluralize(team.members.length, 'direct report')}`}
              </Typography>
            ) : (
              <Skeleton width={200} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ScoreDetailsTeam.fragment = gql`
  fragment ScoreDetailsTeam on Team {
    id
    name
    earliestKnownScoreTime
    members {
      id
    }
    manager {
      id
      name
    }
  }
`;

const useStyles = makeStyles({
  tooltipContainer: {
    padding: theme.spacing(2),
    width: '240px',
  },
  tooltipHeader: {
    color: colors.Main,
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  accuracyContainer: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '4px',
    padding: theme.spacing(4),
    marginBottom: theme.spacing(8),
    display: 'flex',
    alignItems: 'center',
    boxShadow:
      '0px 4px 6px -1px rgba(155, 160, 166, 0.1), 0px 2px 4px -1px rgba(155, 160, 166, 0.06)',
  },
  accuracyText: {
    color: colors.Midnight,
  },
  accuracyChip: {
    padding: `0 ${theme.spacing(3)}`,
    borderRadius: '14px',
    fontWeight: 500,
    margin: `0 ${theme.spacing(4)}`,
  },
  increaseAccuracyLink: {
    marginLeft: 'auto',
    color: colors.Main,
  },
  headerLabel: {
    fontSize: '30px',
    lineHeight: '36px',
    fontWeight: 600,
    marginBottom: 32,
    color: theme.palette.text.primary,
  },
  headerTeamDetails: {
    display: 'flex',
    alignItems: 'baseline',
    paddingBottom: 12,
  },
  headerTeamManager: {
    flex: 1,
    display: 'flex',
    paddingTop: 12,
    justifyContent: 'start',
  },
  headerTeamManagerCopy: {
    fontSize: '12px',
    lineHeight: '18px',
    color: theme.palette.primary[700],
    fontWeight: 500,
  },
});

export default ScoreDetailsTeam;
