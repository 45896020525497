import { useQuery } from '@apollo/client';
import Progress from 'components/Checkout/Progress';
import { renderQuestionnairesStep } from 'components/Checkout/steps/Questionnaire';
import { Loading } from 'components/core';
import LogoWithContainer from 'components/LogoWithContainer';
import WizardConfirmation from 'components/WizardConfirmation';
import TextLink from 'glue/components/buttons/TextLink';
import { getActivitiesEventsPath } from 'glue/scenes/Activities/Events';
import { questionFragment } from 'gql/queries';
import gql from 'graphql-tag';
import { useQueryParams } from 'lib/helpers/router';
import _ from 'lodash';
import React, { useState } from 'react';
import { Step, Steps, Wizard } from 'react-albus';
import { useHistory, useParams } from 'react-router-dom';
import { MysteryProduct, User, useToggleUserDidNotAttendMutation } from 'types';
import { css } from '@emotion/react';

export const Feedback = ({ viewer }: { viewer: User | undefined }) => {
  const query = useQueryParams();
  const { productId } = useParams<{ productId: string }>();
  const history = useHistory();
  const product = query.get('product');
  const rsvpLink = query.get('rsvpLink');
  const events = getActivitiesEventsPath();

  const { data, loading } = useQuery(mysteryFeedbackDataQuery, {
    variables: { product, productId },
  });
  const [toggleUserDidNotAttendMutation] = useToggleUserDidNotAttendMutation();

  const [answers, setAnswers] = useState(null as any);

  const reviewQuestionnaires = _.get(data, 'reviewQuestionnaires', []);

  const isMeetup = product === MysteryProduct.Watercooler;

  const nextButtonLabel = rsvpLink
    ? 'Continue your RSVP'
    : isMeetup
    ? 'Go to Glue'
    : 'View my events';
  const subtitle = isMeetup
    ? 'Thanks for sharing your feedback. Your input helps us improve your Meetups experience to make sure you are meeting and connecting with the right people.'
    : 'Thanks for sharing your feedback. We use your input to create the best experience for your team.';

  const questionnaireSteps = React.useMemo(() => {
    if (reviewQuestionnaires && viewer) {
      return renderQuestionnairesStep({
        teamEvent: _.get(data, 'teamEvent'),
        viewer,
        questionnaires: reviewQuestionnaires,
        answers,
        setAnswers,
        refetchQueries: ['getViewer'],
      });
    }
  }, [reviewQuestionnaires, answers]);

  const clickSkipFeedback = async () => {
    try {
      await toggleUserDidNotAttendMutation({
        variables: {
          teamEventId: productId,
        },
      });
      history.push(`/skipfeedback/${productId}?product=${product}`);
    } catch (error) {
      console.log('Error skipping feedback:', error);
    }
  };

  if (loading) return <Loading />;

  return (
    <LogoWithContainer>
      <Wizard
        history={history}
        basename={`/feedback/${productId}`}
        render={({ step, steps }) => {
          return (
            <>
              <Progress steps={steps} step={step} />
              <Steps key={step.id} step={step}>
                {questionnaireSteps}
                <Step
                  id='confirmation'
                  render={() => (
                    <WizardConfirmation
                      label='Thanks!'
                      subTitle={subtitle}
                      nextButtonProps={{
                        label: nextButtonLabel,
                        onClick: () =>
                          rsvpLink ? history.replace(rsvpLink) : history.replace(events),
                      }}
                    />
                  )}
                />
              </Steps>
            </>
          );
        }}
      ></Wizard>
      <div css={styles.skipContainer}>
        <text>Missed this event? No problem. &nbsp;</text>
        <TextLink onClick={clickSkipFeedback}>click to skip survey</TextLink>
      </div>
    </LogoWithContainer>
  );
};

const mysteryFeedbackDataQuery = gql`
  query mysteryFeedbackData($product: MysteryProduct, $productId: ID!) {
    reviewQuestionnaires(product: $product, productId: $productId) {
      id
      questions {
        ...questionFragment
      }
    }
  }
  ${questionFragment}
`;

const styles = {
  skipContainer: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  }),
};
