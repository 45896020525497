import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TICKET_MASK from 'assets/ticket-mask.svg';
import {
  Purple300,
} from '@mysteryco/design/src/tokens/colors';

const EventImage = ({ photoUrl, title }) => {
  const classes = useStyles();
  return (
    <Box className={classes.teamEventDetailImageWrapper}>
      <Box className={classes.teamEventImageMask}>
        <img className={classes.teamEventImage} src={photoUrl} alt={title} />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  teamEventDetailImageWrapper: {
    display: 'block',
    overflow: 'hidden',
    position: 'relative',
    width: 260,
    maxHeight: 190,
    boxShadow: `0 ${theme.spacing(6)} ${theme.spacing(5)} ${theme.spacing(
      -5,
    )} ${Purple300}`,
    marginBottom: theme.spacing(2),
  },
  teamEventImageMask: {
    backgroundColor: theme.palette.primary[100],
    maskImage: `url(${TICKET_MASK})`,
    maskSize: '100%',
    boxShadow: '0px 0px 10px  #000000',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '152px',
  },
  teamEventImage: {
    width: '100%',
  },
}));

export default EventImage;
