import { Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';

const BoldText = ({
  children,
  variant,
  noWrap = true,
}: {
  children: string | React.ReactNode;
  variant?: Variant;
  noWrap?: boolean;
}) => (
  <Typography variant={variant || 'body1'} noWrap={noWrap} style={{ fontWeight: 'bold' }}>
    {children}
  </Typography>
);

export default BoldText;
