import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const RsvpHeader = ({
  icon,
  iconText,
  title,
  subtitle,
  children,
}: {
  icon: React.ReactNode;
  iconText: string;
  title: string | React.ReactNode;
  subtitle?: string;
  children?: React.ReactNode;
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.header}>
      {icon}
      <Typography className={classes.iconText}>{iconText}</Typography>
      <Typography className={classes.title}>{title}</Typography>
      {subtitle && <Typography className={classes.subtitle}>{subtitle}</Typography>}
      {children}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: '48px',
    lineHeight: '52px',
    fontWeight: 'normal',
    margin: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  subtitle: {
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '28px',
    margin: '12px 0',
  },
  iconText: {
    fontSize: '12px',
    lineHeight: '20px',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: 'Reboto Mono, monospace',
  },
}));

export default RsvpHeader;
