import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@mysteryco/design';
import imageBackup from 'assets/catalog/image-backup.png';
import Clock from 'components/icons/Clock';
import { EncircleIcon } from 'components/icons/EncircleIcon';
import Gift02 from 'components/icons/Gift02';
import MessageChatCircle from 'components/icons/MessageChatCircle';
import Target from 'components/icons/Target';
import Users02 from 'components/icons/Users02';
import PrimaryButton from 'components/PrimaryButton';
import {
  ExperienceFromQuery,
  TemplateFromQuery,
} from 'components/SSFSteps/ExperienceCatalog';
import * as _ from 'lodash';
import { SelfServeContext } from 'providers/SelfServeProvider';
import { useContext } from 'react';
import theme from 'theme';
import { ContractFragmentFragment, ContractUnitType } from 'types';
import { DrawerPricingSummary } from './DrawerPricingSummary';

const ExperienceDrawer = ({
  template,
  experiences,
  contract,
  className = '',
  onSelectExperience,
  ...props
}: {
  template: TemplateFromQuery;
  experiences: ExperienceFromQuery[];
  contract: ContractFragmentFragment;
  className?: string;
  onSelectExperience: () => void;
  [key: string]: any;
}) => {
  const classes = useStyles();
  const selfServeContext = useContext(SelfServeContext);
  const prospectiveGuests =
    selfServeContext?.state.uploadGuests?.totalRecipientsEstimate ?? 0;
  const contractType = contract?.type ?? ContractUnitType.Cents;
  const hasOneExperienceWithSupplies = experiences.find(
    (experience) => experience.hasPhysicalGoods,
  );
  const hasOneExperienceThatRequiresUpgrade = experiences.find(
    (experience) => experience.requiresUpgrade,
  );
  const lowestMinimumGuests = Math.min(
    ...experiences.map((experience) => experience.numMinGuests),
  );
  const highestMaximumGuests = Math.max(
    ...experiences.map((experience) => experience.numMaxGuests),
  );
  const { rangeIdealGuests } = template;
  const hasIdealRange =
    rangeIdealGuests?.length > 1 &&
    rangeIdealGuests.some((guests) => guests !== rangeIdealGuests[0]);
  return (
    <Drawer className={className} anchor={'right'} {...props}>
      <div className={classes.drawerContainer}>
        <div className={classes.topDetails}>
          {/* CATEGORIES */}
          <div className={classes.categoryWrapper}>
            {template.categories.map((category, i) => (
              <span className={classes.categoryTag} key={i}>
                {category.name}
              </span>
            ))}
          </div>
          {/* NAME */}
          <div className={classes.eventName}>{template.title}</div>

          <img
            className={classes.eventPhoto}
            src={template.photoUrl || imageBackup}
            alt={'template'}
          />
          {/* OVERVIEW DETAILS */}
          <div className={classes.eventOverview}>
            {hasOneExperienceWithSupplies && (
              <span className={classes.overviewTag}>
                <Gift02
                  className={classes.overviewTagIcon}
                  size={16}
                  color={colors.Purple700}
                />
                Includes supplies
              </span>
            )}
            <span className={classes.overviewTag}>
              <Clock
                className={classes.overviewTagIcon}
                size={16}
                color={colors.Purple700}
              />
              {template.estimatedDurationMins} minutes
            </span>
          </div>
          {/* PRICE */}
          <DrawerPricingSummary
            contract={contract}
            prospectiveGuests={prospectiveGuests}
            template={template}
          />
          {/* CTA */}
          <div>
            <PrimaryButton label={'Select this event'} onClick={onSelectExperience} />
          </div>
        </div>
        {/* BOTTOM DETAILS */}
        <div className={classes.bottomDetails}>
          {/* DETAILS */}
          <div>
            <div className={classes.sectionHeader}>DETAILS</div>
            <div className={classes.detailList}>
              <div className={classes.detailListIcon}>
                <EncircleIcon backgroundColor={colors.Green300}>
                  <Users02 size={16} color={colors.Main} />
                </EncircleIcon>
              </div>
              <div css={{ width: '100%' }}>
                <div className={classes.detailListHeader}>Ideal group size</div>
                <div className={classes.detailListText}>
                  <div className={classes.guestDetails}>
                    {hasIdealRange && (
                      <div>{`${rangeIdealGuests?.[0]} - ${rangeIdealGuests?.[1]} guests`}</div>
                    )}
                    {!hasIdealRange && rangeIdealGuests && (
                      <div>{`${rangeIdealGuests?.[0]} guests`}</div>
                    )}
                    <div className={classes.minMaxGuests}>
                      {`Min guests required: ${lowestMinimumGuests} - Max guests capacity: ${highestMaximumGuests}`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {hasOneExperienceWithSupplies && (
              <div className={classes.detailList}>
                <div className={classes.detailListIcon}>
                  <EncircleIcon backgroundColor={colors.Green300}>
                    <Gift02 size={16} color={colors.Main} />
                  </EncircleIcon>
                </div>
                <div>
                  <div className={classes.detailListHeader}>Includes event supplies</div>
                  <div className={classes.detailListText}>
                    Yes – and we take care of shipping!
                  </div>
                </div>
              </div>
            )}
            {template.includesBreakoutRooms && (
              <div className={classes.detailList}>
                <div className={classes.detailListIcon}>
                  <EncircleIcon backgroundColor={colors.Green300}>
                    <MessageChatCircle size={16} color={colors.Main} />
                  </EncircleIcon>
                </div>
                <div>
                  <div className={classes.detailListHeader}>Includes small groups</div>
                  <div className={classes.detailListText}>
                    Event uses breakout rooms to enhance connections
                  </div>
                </div>
              </div>
            )}
            {template.connectionFocusAreas?.length > 0 && (
              <div className={classes.detailList}>
                <div className={classes.detailListIcon}>
                  <EncircleIcon backgroundColor={colors.Green300}>
                    <Target size={16} color={colors.Main} />
                  </EncircleIcon>
                </div>
                <div>
                  <div className={classes.detailListHeader}>Connection focus</div>
                  <div className={classes.detailListText}>
                    {template.connectionFocusAreas
                      .map((area) => {
                        if (area === 'IntraTeam') {
                          return 'Within-team';
                        } else if (area === 'CrossTeam') {
                          return 'Cross-team';
                        }
                        return _.startCase(area);
                      })
                      .join(', ')}
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* SUMMARY */}
          {contractType === ContractUnitType.Recurring &&
            (hasOneExperienceWithSupplies || hasOneExperienceThatRequiresUpgrade) && (
              <div>
                <div className={classes.sectionHeader}>PREMIUM EXPERIENCE UPGRADE</div>
                <div className={classes.summary}>
                  Premium experiences require a upgrade. An estimate of the upgrade cost
                  will be presented to you before booking your event in a confirmation
                  screen.
                </div>
              </div>
            )}
          <div>
            <div className={classes.sectionHeader}>SUMMARY</div>
            <div className={classes.summary}>{template.description}</div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

const useStyles = makeStyles({
  drawerContainer: {
    padding: '40px',
    width: '480px',
    overflowY: 'auto',
    overflowX: 'hidden',
    '& > :not(:first-of-type)': {
      marginTop: theme.spacing(4),
    },
  },
  topDetails: {
    position: 'sticky',
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    paddingBottom: '12px',
    background: colors.White,
  },
  bottomDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  categoryWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    gap: '8px',
    marginBottom: '12px',
    maxHeight: '24px',
    overflow: 'hidden',
  },
  categoryTag: {
    color: colors.Main,
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 500,
    background: colors.Purple50,
    border: `1px solid ${colors.Purple200}`,
    borderRadius: '6px',
    padding: '3px 8px',
  },
  eventName: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 700,
    color: colors.Midnight,
  },
  eventPhoto: {
    width: '100%',
    maxHeight: '225px',
    border: `solid 4px ${colors.Purple300}`,
    borderRadius: '12px',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  eventOverview: {
    margin: '12px 0',
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '12px',
    color: colors.Dusk,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '16px',
  },
  overviewTag: {
    display: 'flex',
    alignItems: 'center',
  },
  overviewTagIcon: {
    marginRight: theme.spacing(2),
  },
  sectionHeader: {
    color: colors.Dusk,
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 700,
    letterSpacing: '1px',
  },
  detailList: {
    display: 'flex',
    alignItems: 'start',
    marginTop: theme.spacing(2),
  },
  detailListIcon: {
    marginRight: theme.spacing(4),
  },
  detailListHeader: {
    color: colors.Dusk,
    lineHeight: '20px',
    fontWeight: 700,
  },
  detailListText: {
    color: colors.Main,
    fontSize: '14px',
    lineHeight: '24px',
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-between',
  },
  summary: {
    marginTop: theme.spacing(2),
    color: theme.palette.grey[700],
    lineHeight: '22px',
    fontWeight: 400,
  },
  guestDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  minMaxGuests: {
    fontSize: '12px',
    fontWeight: 500,
    marginTop: '-4px',
    color: theme.palette.grey[700],
  },
});

export default ExperienceDrawer;
