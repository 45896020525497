import { gql } from '@apollo/client';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@mysteryco/design/src';
import { PricingUnitIcon } from 'components/icons/PricingUnitIcon';
import { formatBandPrice } from 'lib/helpers/money';
import { ReactNode } from 'react';
import { ContractUnitType, DrawerPricingDetailPriceBandFragment } from 'types';

export function DrawerPricingDetail({
  contractType,
  priceBand,
  subLine,
  label,
  headCount,
}: {
  contractType: ContractUnitType;
  priceBand: DrawerPricingDetailPriceBandFragment;
  subLine?: ReactNode;
  label: string;
  headCount?: number;
}) {
  const classes = useStyles();

  return (
    <div className={classes.section}>
      <span className={classes.costLabel}>{label}</span>
      <div className={classes.costValue}>
        <PricingUnitIcon unit={contractType} />
        {formatBandPrice({ band: priceBand, contractType, headCount })}
      </div>
      {subLine && <div className={classes.costSubLine}>{subLine}</div>}
    </div>
  );
}
DrawerPricingDetail.fragments = {
  priceBand: gql`
    fragment DrawerPricingDetailPriceBand on PricingBand {
      minUsers
      maxUsers
      cents
      credits
      unit
    }
  `,
};

const useStyles = makeStyles({
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    flex: '1 0 0',

    '& + &': {
      borderLeft: `1px solid ${colors.Purple200}`,
      paddingLeft: '20px',
    },
  },
  costValue: {
    marginBottom: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '4px',
  },
  costLabel: {
    color: colors.Dusk,
    fontSize: '16px',
    fontWeight: 500,
  },
  costSubLine: {
    fontSize: '14px',
    fontWeight: 500,
    color: colors.DarkGray,
  },
});
