import { gql } from '@apollo/client';
import Billing from 'glue/scenes/Settings/Billing';

export const questionFragment = gql`
  fragment questionFragment on Question {
    id
    answers {
      id
      answer
    }
    orderIndex
    question
    questionType
    orderIndex
    options
    scaleMin
    scaleMax
    subtitle
    subIndex
    requesterOnly
    required
    contractOnly
    transactionalOnly
  }
`;

export const memberFragment = gql`
  fragment memberFragment on User {
    id
    firstName
    lastName
    name
    email
    companyRole
    startDate
    finchAssociation
    nylasAssociation {
      id
    }
    accountStatus
    organizationRole {
      id
      name
    }
    tags {
      id
      name
      type
    }
    managerOf {
      id
    }
    teams {
      id
      name
      manager {
        id
        name
      }
      members {
        id
      }
    }
  }
`;

export const priceFragment = gql`
  fragment priceFragment on Price {
    id
    stripeChargeId
    paidAt
    status
    createdAt
    totalCents
    lineItems {
      id
      category
      cents
      contractUnits
      contractPremiumUnits
      contract {
        id
        type
        unitCostCents
        unitCount
        premiumUnitCostCents
        premiumUnitCount
      }
    }
  }
`;

export const preferenceQuestionsFragment = gql`
  fragment preferenceQuestionsFragment on PreferenceQuestion {
    id
    type
    question
    subTitle
    multiSelect
    emoji
    category
    orderIndex
    preferenceAnswers {
      id
      label
      orderIndex
      value
      question {
        id
        category
        question
      }
    }
  }
`;

export const virtualEventFragment = gql`
  fragment virtualEventFragment on VirtualEvent {
    id
    durationMins
    attendees
    type
    instructorRating
    requireInstructor
    instructor {
      id
    }
    joinEventButtonEnabledLeadTimeMins
    zoomMeeting {
      id
      joinUrl
      startUrl
      host {
        id
        email
      }
    }
  }
`;

export const eventPageTeamEventFragment = gql`
  fragment eventPageTeamEventFragment on TeamEvent {
    id
    eventRating
    ratingCount
    experience {
      id
      name
      description
      photoUrl
      templates {
        id
        photoUrl
      }
    }
    invitationsAccepted
    invitationsSent
    requestedFor
    status
    surpriseToAll
    surpriseToAttendees
    title
    connectionsStrengthened
    teamEventMetricSummary {
      responses
      rating {
        overallRating
      }
      connectedness {
        low
        neutral
        strong
      }
    }
    rsvpSummary {
      total
      accepted
    }
    goals {
      id
      goal
    }
  }
`;

export const teamEventFragment = gql`
  fragment teamEventFragment on TeamEvent {
    id
    title
    status
    requestedFor
    requestedBy {
      id
    }
    joinLink
    surpriseToAttendees
    surpriseToAll
    isRequestToBook
    eventRating
    virtualEvent {
      ...virtualEventFragment
      reviewQuestionnaire {
        id
      }
    }
    experience {
      id
      estimatedDurationMins
      description
      name
      photoUrl
      templates {
        id
        photoUrl
      }
      hasPhysicalGoods
      requiresUpgrade
      tags {
        name
      }
      cost {
        ctcCents
        ctcUnit
        ctcPricingBands {
          minUsers
          maxUsers
          cents
          credits
          unit
        }
      }
    }
    invitedGuests {
      email
      rsvpStatus
      user {
        id
      }
    }
    expectedHeadCount
    finalHeadCount
    signUpExpirationDate
    owner {
      firstName
      lastName
      email
      phone
    }
    includeDelivery
    freeEvent
    organization {
      id
      activeContract {
        id
        premiumUnitCostCents
        type
        unitCostCents
      }
      rootTeam {
        id
      }
    }
    goals {
      id
      goal
    }
    connectionsStrengthened
    teamEventMetricSummary {
      responses
      rating {
        overallRating
      }
      connectedness {
        low
        neutral
        strong
      }
    }
    connectionsStrengthened
    rsvpSummary {
      total
      accepted
    }
    testEventType
    contract {
      id
      name
      status
      premiumUnitCostCents
      type
      unitCostCents
    }
  }
  ${virtualEventFragment}
`;

export const contractFragment = gql`
  fragment contractFragment on Contract {
    id
    unitCount
    unitCostCents
    unitInitialCount
    premiumUnitCount
    premiumUnitCostCents
    premiumUnitInitialCount
    productType
    renewDate
    startDate
    status
    type
    createdAt
    updatedAt
  }
`;

export const teamEventInvoiceFragment = gql`
  fragment teamEventInvoiceFragment on TeamEvent {
    id
    title
    requestedFor
    expectedHeadCount
    finalHeadCount
    experience {
      id
      hasPhysicalGoods
      templates {
        id
        photoUrl
      }
      requiresUpgrade
      name
    }
    finalizedPrice {
      id
      totalCents
      status
      lineItems {
        id
        category
        contractUnits
        contractPremiumUnits
        cents
        contract {
          ...contractFragment
        }
      }
    }
  }
  ${contractFragment}
`;

export const viewerFragment = gql`
  fragment viewerFragment on User {
    id
    role
    phone
    firstName
    lastName
    name
    email
    marketingConsent
    companyRole
    companyNameForSales
    isNylasConnected
    nylasConnectionState
    hasAgreedToLatestTOS
    promptedForMarketingConsent
    customerType
    isServiceAccount
    preferenceAnswers {
      id
      label
      orderIndex
      value
      selectAll
      deselectAll
      question {
        type
        id
        category
        question
        multiSelect
      }
    }
    restrictions {
      type
      value
    }
    foodPreferences {
      type
      value
    }
    dietaryRestrictions {
      id
      name
    }
    dietaryRestrictionsLastAsked
    unansweredPreferenceQuestions {
      ...preferenceQuestionsFragment
    }
    requestedTeamEvents {
      id
    }
    organizationRole {
      id
      name
    }
    askFeedbackEvent {
      id
      requestedFor
      title
    }
    orgs {
      id
      name
      customerType
      logoUrl
      activeContract {
        ...contractFragment
      }
      isPulseBetaEnabled
      isMeetupsBetaEnabled
    }
    cardOnFile {
      id
      brand
      last4
      expMonth
      expYear
    }
    customerType
    optOutOfWatercoolerAt
    preferredWatercoolerDays
    preferredWatercoolerTime
    timezone
    optOutOfDigestAt
    contracts {
      ...contractFragment
      ...BillingContractInfo
    }
  }
  ${contractFragment}
  ${preferenceQuestionsFragment}
  ${questionFragment}
  ${Billing.fragments.contract}
`;

export const teamFragment = gql`
  fragment teamFragment on Team {
    id
    name
    manager {
      ...memberFragment
    }
    childTeams {
      id
    }
    members {
      ...memberFragment
    }
    scores {
      ... on TransitiveTeamEngagementScore {
        scoreType
        value
        trend
      }
    }
  }
`;

export const getViewer = gql`
  query getViewer {
    viewer {
      ...viewerFragment
    }
  }
  ${viewerFragment}
`;

export const getOptions = gql`
  query getOptions {
    bookingSessionOptions {
      eventSize {
        label
        value
      }
      budget {
        labelLong
        minCents
        maxCents
        priceLevel
      }
    }
  }
`;

export const getPreferenceQuestions = gql`
  query PreferenceQuestions {
    preferenceQuestions {
      ...preferenceQuestionsFragment
    }
  }
  ${preferenceQuestionsFragment}
`;

export const getOrganizationRoles = gql`
  query Roles {
    roles {
      id
      name
    }
  }
`;

export const getPreEventInfo = gql`
  query CheckExistingInputs($eventId: ID!, $userId: ID!) {
    teamEvent(id: $eventId) {
      id
      requestedBy {
        id
      }
      virtualEvent {
        id
        requireInstructor
        instructor {
          id
        }
      }
      preEventQuestionnaires {
        id
        questions {
          ...questionFragment
          answerForUser(id: $userId) {
            id
            answer
          }
        }
      }
    }
  }
  ${questionFragment}
`;

export const getListOfDietaryRestrictions = gql`
  query DietaryRestrictionsList {
    dietaryRestrictions {
      id
      name
      orderIndex
    }
  }
`;

export const getHomePageEventsQuery = gql`
  query getHomePageEvents {
    viewer {
      id
      teamEvents {
        edges {
          node {
            id
            title
            requestedBy {
              id
            }
            requestedFor
            experience {
              name
              description
              templates {
                photoUrl
              }
              photoUrl
            }
            surpriseToAttendees
            surpriseToAll
            isRequestToBook
            status
            goals {
              id
              goal
            }
            userCompletedFeedback
            virtualEvent {
              ...virtualEventFragment
            }
          }
        }
      }
    }
  }
  ${virtualEventFragment}
`;

export const getTeamEventsQuery = gql`
  query getTeamEvents {
    viewer {
      id
      teamEvents {
        edges {
          node {
            ...teamEventFragment
          }
        }
      }
    }
  }
  ${teamEventFragment}
`;

export const getOrganizationTeamEventsQuery = gql`
  query getOrganizationTeamEvents(
    $orgId: ID
    $dateRange: [DateTime]
    $search: String
    $teamEventStatus: [TeamEventStatus]
    $goals: [EventGoal]
    $participantId: ID
  ) {
    viewer {
      orgs {
        id
        hasTeamEvents
      }
    }

    teamEventsConnection(
      organizationId: $orgId
      dateRange: $dateRange
      search: $search
      teamEventStatus: $teamEventStatus
      goals: $goals
      participantId: $participantId
    ) {
      edges {
        node {
          ...eventPageTeamEventFragment
        }
      }
    }
  }
  ${eventPageTeamEventFragment}
`;

export const getRequestedEventsQuery = gql`
  query getRequestedTeamEvents {
    viewer {
      id
      requestedTeamEvents {
        ...eventPageTeamEventFragment
      }
    }
  }
  ${eventPageTeamEventFragment}
`;

export const getTeamEventQuery = gql`
  query getTeamEvent($id: ID!, $includeBilling: Boolean! = false) {
    teamEvent(id: $id) {
      ...teamEventFragment
      initialPrice @include(if: $includeBilling) {
        ...priceFragment
      }
      finalizedPrice @include(if: $includeBilling) {
        ...priceFragment
      }
    }
  }
  ${teamEventFragment}
  ${priceFragment}
`;

export const getUserIdQuery = gql`
  query getUser {
    viewer {
      id
    }
  }
`;

export const getInvoiceQuery = gql`
  query getInvoice($teamEventId: ID!) {
    teamEvent(id: $teamEventId) {
      ...teamEventInvoiceFragment
    }
  }
  ${teamEventInvoiceFragment}
`;

export const getOrgMembers = gql`
  query searchOrgUsers(
    $orgId: ID!
    $query: String
    $userTags: [ID]
    $teams: [ID]
    $orgRoles: [ID]
    $accountStatuses: [AccountStatus]
    $sort: [UsersOrderArgs]
    $showArchived: Boolean!
    $includeManager: Boolean
  ) {
    orgUsersConnection(
      search: $query
      organizationArgs: { organizationId: $orgId, showArchived: $showArchived }
      userTags: $userTags
      teamArgs: { teams: $teams, includeManager: $includeManager }
      accountStatuses: $accountStatuses
      orgRoles: $orgRoles
      order: $sort
    ) {
      edges {
        node {
          ...memberFragment
        }
      }
    }
  }
  ${memberFragment}
`;

export const getUserTeams = gql`
  query getUserTeams($orgId: ID!) {
    orgUsersConnection(
      organizationArgs: { organizationId: $orgId, showArchived: false }
    ) {
      edges {
        node {
          id
          name
          managerOf {
            id
          }
        }
      }
    }
  }
`;

export const getOrgUserInfo = gql`
  query getOrgUserInfo($orgId: ID!) {
    orgUsersConnection(
      organizationArgs: { organizationId: $orgId, showArchived: false }
    ) {
      edges {
        node {
          id
          email
          organizationRole {
            id
          }
        }
      }
    }
  }
`;

export const getPendingOrgMembers = gql`
  query getPendingOrgMemberIds($id: ID!) {
    getPendingOrgMembers(id: $id) {
      id
    }
  }
`;

export const getOrganizationQuery = gql`
  query getUserWithOrgs($getMembers: Boolean! = false) {
    viewer {
      id
      orgs {
        customerType
        manualIntegrationLastUpdated
        isHrisConnected
        userSignUpPath
        userTags {
          id
          name
          type
          users {
            id
            name
            email
            tags {
              id
              name
              type
            }
          }
        }
        teams {
          id
          name
          members {
            id
          }
          manager {
            id
            name
            isArchived
          }
        }
        id
        name
        members @include(if: $getMembers) {
          ...memberFragment
        }
        archivedMembers @include(if: $getMembers) {
          ...memberFragment
        }
      }
    }
  }
  ${memberFragment}
`;

export const getEngagementEdgesQuery = gql`
  query getLatestEngagementEdges($organizationId: ID!, $version: String) {
    latestEngagementEdges(organizationId: $organizationId, version: $version) {
      fromUserId
      toUserId
      connection
      relationship
    }
  }
`;

export const getNylasInformation = gql`
  query getNylasInformation {
    viewer {
      id
      nylasConnectionState
      nylasAssociation {
        id
        email
        provider
      }
    }
  }
`;

export const getEventGoals = gql`
  query EventGoals {
    teamEventGoals {
      id
      goal
    }
  }
`;

export const templateCatalogQuery = gql`
  query TemplateCatalog {
    mysteryTemplatesConnection(filterInput: {})
      @connection(key: "mysteryTemplatesConnection", filter: ["filterInput"]) {
      edges {
        node {
          id
          title
          description
          photoUrl
          estimatedDurationMins
          rangeIdealGuests
          connectionFocusAreas
          includesBreakoutRooms
          categories {
            id
            name
          }
          cost {
            id
            ctcUnit
            ctcCents
            ctcPricingBands {
              cents
              unit
              credits
              minUsers
              maxUsers
            }
          }
          experiences {
            id
            hasPhysicalGoods
            requiresUpgrade
            isActive
            isVirtual
            name
            numMaxGuests
            numMinGuests
            status
            priceLevel
            usaShipping {
              estimatedLeadTimeDays
            }
            operatingHours {
              open {
                day
                time
                hours
                minutes
              }
              close {
                day
                time
                hours
                minutes
              }
            }
            location {
              timezone
            }
            connectednessScore
            partner {
              id
              name
            }
          }
          theme {
            id
            title
          }
        }
      }
      count
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const experienceCatalogQuery = gql`
  query ExperienceCatalog {
    xperiencesConnection(filterInput: { isActive: true })
      @connection(key: "xperiencesConnection", filter: ["filterInput"]) {
      edges {
        node {
          hasPhysicalGoods
          requiresUpgrade
          id
          isActive
          isVirtual
          name
          numMaxGuests
          numMinGuests
          status
          priceLevel
          usaShipping {
            estimatedLeadTimeDays
          }
          templates {
            id
            title
            description
            photoUrl
            estimatedDurationMins
            rangeIdealGuests
            connectionFocusAreas
            includesBreakoutRooms
            categories {
              id
              name
            }
            cost {
              ctcPricingBands {
                cents
                unit
                credits
                minUsers
                maxUsers
              }
            }
            experiences {
              id
              hasPhysicalGoods
              requiresUpgrade
            }
          }
          operatingHours {
            open {
              day
              time
              hours
              minutes
            }
            close {
              day
              time
              hours
              minutes
            }
          }
          location {
            timezone
          }
          connectednessScore
          partner {
            id
            name
          }
        }
      }
      count
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const searchTeamsQuery = gql`
  query searchTeams($orgId: ID!, $name: String) {
    searchTeams(orgId: $orgId, name: $name) {
      id
      name
      manager {
        id
        name
        firstName
        lastName
        email
        companyRole
      }
      members {
        id
        name
        firstName
        lastName
        email
        companyRole
      }
    }
  }
`;

export const searchUserTagsQuery = gql`
  query searchUserTags($orgId: ID!, $name: String) {
    searchUserTags(orgId: $orgId, name: $name) {
      id
      name
      users {
        id
        name
        firstName
        lastName
        email
        companyRole
      }
      type
    }
  }
`;

export const meetupAudienceOrganizationFragment = gql`
  fragment MeetupAudienceOrganization on Organization {
    id
    name
    members {
      id
      name
      optOutOfWatercoolerAt
    }
  }
`;

export const meetupAudienceFragment = gql`
  fragment MeetupAudienceFragment on AudienceMember {
    id
    user {
      id
      name
      email
      optOutOfWatercoolerAt
    }
    team {
      id
      name
      members {
        id
        name
        optOutOfWatercoolerAt
      }
    }
    userTag {
      id
      name
      users {
        id
        name
        optOutOfWatercoolerAt
      }
    }
    organization {
      ...MeetupAudienceOrganization
    }
  }
  ${meetupAudienceOrganizationFragment}
`;

export const watercoolerInitiativeByOrgQuery = gql`
  query WatercoolerInitiativeByOrg($orgId: ID!) {
    watercoolerInitiativeByOrg(orgId: $orgId) {
      id
      audience {
        ...MeetupAudienceFragment
      }
      disabledAt
      lastRun
      runAfter
      cadenceWeeks
    }
  }
  ${meetupAudienceFragment}
`;
