import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ComponentPropsWithoutRef, forwardRef } from 'react';

/**
 * Note: this is a lower-level component; see CenteredContent
 * for a pre-fabricated layout.
 *
 * A container for content that should be centered horizontally
 * within a CenteredContentRoot.
 */
const CenteredContentSectionBase = styled('div')({
  display: 'flex',
  flexDirection: 'column',

  width: '100%',
});

const sectionWidthStyles = {
  full: css({
    maxWidth: '100%',
  }),
  large: css({
    maxWidth: 1200,
  }),
  medium: css({
    maxWidth: 800,
  }),
  small: css({
    maxWidth: 600,
  }),
};

export type CenteredContentSectionWidth = keyof typeof sectionWidthStyles;

export type CenteredContentSectionProps = ComponentPropsWithoutRef<
  typeof CenteredContentSectionBase
> & {
  width?: CenteredContentSectionWidth;
};

export const CenteredContentSection = forwardRef<
  HTMLDivElement,
  CenteredContentSectionProps
>(function CenteredContentSection({ width = 'medium', ...props }, ref) {
  return (
    <CenteredContentSectionBase {...props} css={sectionWidthStyles[width]} ref={ref} />
  );
});

/**
 * Note: this is a lower-level component; see CenteredContent
 * for a pre-fabricated layout.
 *
 * The root container for centered content sections.
 */
export const CenteredContentRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export type CenteredContentProps = ComponentPropsWithoutRef<typeof CenteredContentRoot>;

/**
 * A prefabricated layout for pages with a centered content section.
 * If you need to customize the parameters of the inner container,
 * or add multiple sections (including full-width spans), you should
 * consider using CenteredContentRoot and CenteredContentSection
 * directly.
 */
export const CenteredContent = forwardRef<HTMLDivElement, CenteredContentProps>(
  function CenteredContent({ children, ...rest }, ref) {
    return (
      <CenteredContentRoot ref={ref} {...rest}>
        <CenteredContentSection>{children}</CenteredContentSection>
      </CenteredContentRoot>
    );
  },
);

export default CenteredContent;
