import { makeStyles } from '@material-ui/styles';
import { Checkbox, Space } from 'antd';
import _ from 'lodash';
import { AnswerProps } from '.';

const useStyles = makeStyles({
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const CheckboxesAnswer = ({ question, answer, setAnswer }: AnswerProps) => {
  const classes = useStyles();
  let isArray = false;

  try {
    const parsed = JSON.parse(answer);
    if (_.isArray(parsed)) {
      isArray = true;
    }
  } catch (err) {}

  return (
    <div className={classes.flexCol}>
      <Checkbox.Group
        onChange={(value) => {
          setAnswer(JSON.stringify(value));
        }}
        value={isArray ? JSON.parse(answer) : answer ? [answer] : null}
      >
        <Space direction='vertical'>
          {_.map(question.options, (option, i) => (
            <Checkbox key={i} value={option}>
              {option}
            </Checkbox>
          ))}
        </Space>
      </Checkbox.Group>
    </div>
  );
};

export default CheckboxesAnswer;
