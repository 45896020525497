import { OptionsObject, useSnackbar, WithSnackbarProps } from 'notistack';
import React from 'react';

interface Props {
  setUseSnackbarRef: (showSnackbar: WithSnackbarProps) => void;
}

const InnerSnackbarUtilsConfigurator = (props: Props) => {
  props.setUseSnackbarRef(useSnackbar());
  return null;
};

let useSnackbarRef: WithSnackbarProps;
const setUseSnackbarRef = (useSnackbarRefProp: WithSnackbarProps) => {
  useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator = () => {
  return <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />;
};

const defaultOpts: OptionsObject = { persist: false };
const SnackbarUtils = {
  success(msg: string, opts?: OptionsObject) {
    this.toast(msg, { variant: 'success', ...opts });
  },
  warning(msg: string, opts?: OptionsObject) {
    this.toast(msg, { variant: 'warning', ...opts });
  },
  info(msg: string, opts?: OptionsObject) {
    this.toast(msg, { variant: 'info', ...opts });
  },
  error(msg: string, opts?: OptionsObject) {
    this.toast(msg, { variant: 'error', ...opts });
  },
  toast(msg: string, opts: OptionsObject) {
    useSnackbarRef.enqueueSnackbar(msg, { ...defaultOpts, ...opts });
  },
};
export default SnackbarUtils;
