import { Box, Typography } from '@material-ui/core';
import { CSSProperties } from 'react';
import theme from 'theme';

type Props = {
  monthName: string;
  dateNumber: number;
  containerStyle?: CSSProperties;
  monthStyle?: CSSProperties;
  dateNumberStyle?: CSSProperties;
};

const ICSDate = ({
  monthName,
  dateNumber,
  containerStyle = {},
  monthStyle = {},
  dateNumberStyle = {},
}: Props) => {
  return (
    <Box
      display='flex'
      alignItems={'center'}
      flexDirection='column'
      css={{
        width: 136,
        height: '100%',
        background: theme.palette.common.white,
        borderTop: `4px solid ${theme.palette.primary.main}`,
        borderRadius: theme.spacing(1),
        ...containerStyle,
      }}
    >
      <Box>
        <Typography
          variant='h6'
          css={{
            textTransform: 'uppercase',
            color: theme.palette.primary.main,
            fontSize: '1.25rem',
            ...monthStyle,
          }}
        >
          {monthName}
        </Typography>
      </Box>
      <Box>
        <Typography style={{ fontSize: '3rem', ...dateNumberStyle }}>
          <b>{dateNumber}</b>
        </Typography>
      </Box>
    </Box>
  );
};

export default ICSDate;
