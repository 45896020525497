// @ts-strict-ignore
import { Box, Mark, Slider, Typography } from '@material-ui/core';
import SadFace from 'components/icons/SadFace';
import SmileFace from 'components/icons/SmileFace';
import { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import theme from 'theme';
import { AnswerProps } from '.';

export const EventReview = ({ question, answer, setAnswer }: AnswerProps) => {
  const urlAnswerIndex = parseInt(
    new URLSearchParams(window.location.search).get('answerIndex'),
  );
  const [currentAnswer, setCurrentAnswer] = useState(
    urlAnswerIndex && urlAnswerIndex >= 1 && urlAnswerIndex <= 5 ? urlAnswerIndex : 3,
  );
  const marks = question.options.map((option, i) => {
    return {
      label: option,
      value: i + 1,
    } as Mark;
  });
  const classes = useStyles();
  return (
    <Box>
      <Typography>Rate your experience on this event:</Typography>
      <Box
        css={{
          padding: '30px',
          marginTop: '50px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <SadFace
          color={theme.palette.primary[900]}
          height={20}
          width={20}
          css={{ width: '32px', height: '32px' }}
        />
        <Slider
          className={classes.reviewSlider}
          marks={marks}
          defaultValue={currentAnswer}
          value={currentAnswer}
          valueLabelDisplay='off'
          step={null}
          aria-labelledby='discrete-slider-custom'
          onChangeCommitted={(_, value) => {
            setCurrentAnswer(value as number);
            setAnswer(currentAnswer.toString());
          }}
          max={5}
          min={1}
        />
        <SmileFace
          color={theme.palette.primary[900]}
          height={20}
          width={20}
          css={{ width: '32px', height: '32px' }}
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  reviewSlider: {
    width: '80%',
    '& .MuiSlider-rail': {
      height: '8px',
      borderRadius: '4px',
    },
    '& .MuiSlider-track': {
      height: '8px',
      borderRadius: '4px',
    },
    '& .MuiSlider-mark': {
      height: '0px',
    },
    '& .MuiSlider-markLabel': {
      marginTop: '20px',
    },
    '& .MuiSlider-thumb': {
      boxShadow: 'none',
      height: '16px',
      width: '16px',
    },
  },
});
