import { cx } from '@emotion/css';
import { ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@mysteryco/design';

const IconButton = ({
  onClick,
  children,
  ...props
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: any;
  [key: string]: any;
}) => {
  const classes = useStyles();
  return (
    <ButtonBase
      className={cx(classes.iconButton, props?.className)}
      onClick={onClick}
      {...props}
    >
      {children}
    </ButtonBase>
  );
};

const useStyles = makeStyles({
  iconButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    width: 32,
    minWidth: 32,
    height: 32,
    background: colors.White,
    border: '1px solid #EBF0F3',
    boxShadow:
      '0px 1px 3px rgba(155, 160, 166, 0.1), 0px 1px 2px rgba(155, 160, 166, 0.06)',
    borderRadius: 32,
    flex: '1 1',
    transition: 'all 300ms ease-in-out',
    '& svg': {
      color: colors.Dusk,
      fill: colors.Dusk,
    },
    '&:hover': {
      background: colors.Purple50,
      border: `1px solid ${colors.Purple500}`,
      boxShadow:
        '0px 4px 6px -1px rgba(155, 160, 166, 0.1), 0px 2px 4px -1px rgba(155, 160, 166, 0.06)',
    },
    '&:focus': {
      color: colors.White,
      background: colors.Main,
      border: `1px solid ${colors.Purple400}`,
      '& svg': {
        color: colors.White,
        fill: colors.White,
      },
    },
    '&:disabled': {
      background: colors.OffWhite,
      boxShadow: 'none',
      '& svg': {
        color: colors.MediumGray,
        fill: colors.MediumGray,
      },
    },
  },
});

export default IconButton;
