import { Page } from 'components/core';
import { Loading } from 'components/core/Loading';
import React from 'react';

import { gql, useQuery } from '@apollo/client';

export const CancelPolicy = () => {
  const { data, loading } = useQuery(latestCancelPolicyWithContentQuery);

  if (loading) return <Loading />;

  return (
    <div style={{ marginTop: '20px' }}>
      <Page title={strings.CANCEL_POLICY}>
        <div dangerouslySetInnerHTML={{ __html: data!.legalDocument!.content }} />
      </Page>
    </div>
  );
};

const strings = {
  CANCEL_POLICY: `Event Cancelation Policy`,
};

const latestCancelPolicyWithContentQuery = gql`
  query getLatestCancelPolicyWithContent {
    legalDocument(type: CancelPolicy) {
      id
      content
    }
  }
`;
