import { useLocalStorage } from 'hooks';

/**
 * A hook to get and set the colorblind mode setting. Colorblind mode
 * should be used to show patterns and other visualizations wherever
 * colors are used as meaningful indicators.
 *
 * The setting is stored indefinitely in localStorage.
 *
 * Use ColorblindToggle to expose a UI for changing this setting.
 *
 * The value affects the PatternedBox component intrinsically.
 *
 * Colorblind mode defaults ON, because the patterns look cool,
 * and why not be accessible by default? If someone doesn't
 * like the patterns, they can turn them off, and it'll save
 * in localStorage.
 */
export function useColorblindMode() {
  return useLocalStorage('isColorblindAccessibilityMode', true);
}
