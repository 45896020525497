import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@mysteryco/design';
import { useState } from 'react';

import ChevronRight from 'components/icons/ChevronRight';
import KnowledgeBox from 'components/icons/KnowledgeBox';
import theme from 'theme';
import { ScoreType } from 'types';

import crossTeamIllustration from 'assets/illustrations/cross-team@2x.png';
import leadershipIllustration from 'assets/illustrations/leadershipV2@2x.png';
import senseOfBelongingIllustration from 'assets/illustrations/sense-of-belonging@2x.png';
import withinTeamIllustration from 'assets/illustrations/within-team@2x.png';

interface Props {
  scoreType: ScoreType;
}

const ANIMATION_MS = 300;
const ILLUSTRATION_SIZE = 200;

const DESCRIPTIONS: Record<
  ScoreType,
  { illustration: string; title: string; body: string; summary: string }
> = {
  [ScoreType.CrossTeam]: {
    illustration: crossTeamIllustration,
    title: `Cross-team`,
    body: `A high level of information sharing infuses teams with new ideas and context as they work against their goals. People on distributed teams often struggle to get visibility into work and efforts outside of their immediate scope, reducing creativity and productivity.`,
    summary: `reflects the strength of connection between people on different teams.`,
  },
  [ScoreType.Leadership]: {
    illustration: leadershipIllustration,
    title: `Leadership`,
    body: `People on distributed teams often struggle to meet and connect with leaders which impacts their trust in leadership, buy-in to company goals and sense of career mobility.`,
    summary: `reflects how strong people's connections are with their skip level leaders and above.`,
  },
  [ScoreType.SenseOfBelonging]: {
    illustration: senseOfBelongingIllustration,
    title: `Sense of Belonging`,
    body: `A person's sense of belonging is the feeling of security and support that individuals feel when they are seen and accepted by their colleagues. When people have strong connections to leaders, to people on their own team and to people on other teams this creates a resilient sense of belonging.`,
    summary: `is an average of your leadership, within-team and cross-team connection scores.`,
  },
  [ScoreType.WithinTeam]: {
    illustration: withinTeamIllustration,
    title: `Within-team`,
    body: `The majority of work happens within nuclear teams, having a strong base of empathy and connection between team-mates is critical to a productive working relationship.`,
    summary: `reflects the strength of connections between people on the same team.`,
  },
};

export const useStyles = makeStyles({
  root: {
    backgroundColor: colors.Purple100,
    border: `1px solid ${colors.Purple100}`,
    borderRadius: 4,
    transition: `background-color ${ANIMATION_MS}ms`,
    margin: `${theme.spacing(3)} 0`,
    '&[data-expanded=true]': {
      backgroundColor: colors.Purple50,
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    color: colors.Dusk,
    fontWeight: 700,
    padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
    cursor: 'pointer',
    '& span': {
      marginLeft: '0.25rem',
      flex: 1,
    },
  },
  chevron: {
    transition: `transform ${ANIMATION_MS}ms`,
    '[data-expanded=true] &': {
      transform: 'rotate(90deg)',
    },
  },
  contentContainer: {
    overflow: 'hidden',
    height: 0,
    transition: `height ${ANIMATION_MS}ms`,
    '[data-expanded=true] &': {
      backgroundColor: colors.Purple50,
      height: ILLUSTRATION_SIZE + 20 * 2,
    },
  },
  content: {
    display: 'flex',
    boxSizing: 'border-box',
    borderTop: `1px solid ${colors.Purple100}`,
    padding: 20,
  },
  illustration: {
    height: ILLUSTRATION_SIZE,
    width: ILLUSTRATION_SIZE,
    marginRight: 20,
  },
  summary: {
    marginTop: theme.spacing(6),
    fontWeight: 700,
    '& span': {
      color: colors.Main,
    },
  },
});

const ScoreDetailsInsightsScoreDescription = ({ scoreType }: Props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const { illustration, title, body, summary } = DESCRIPTIONS[scoreType];

  return (
    <div className={classes.root} data-expanded={expanded}>
      <div className={classes.title} onClick={() => setExpanded(!expanded)}>
        <KnowledgeBox />
        <span>Why is this important?</span>
        <ChevronRight size={16} className={classes.chevron} />
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <img src={illustration} alt={title} className={classes.illustration} />
          <div>
            <div>{body}</div>
            <div className={classes.summary}>
              This score <span>{summary}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScoreDetailsInsightsScoreDescription;
