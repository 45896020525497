import { InternalRefetchQueriesInclude, useQuery } from '@apollo/client';
import _ from 'lodash';
import { EventGoal, GetUserQuery, TeamEventStatus } from 'types';
import { client } from './client';
import * as mutations from './mutations';
import * as queries from './queries';

export async function updateUser(
  variables,
  refetchQueries: InternalRefetchQueriesInclude = [],
) {
  return await client.mutate({
    mutation: mutations.updateUser,
    variables,
    refetchQueries,
    awaitRefetchQueries: true,
  });
}

export const createNewTags = async (
  variables,
  refetchQueries: InternalRefetchQueriesInclude = [],
) => {
  const resp = await client.mutate({
    mutation: mutations.createUserTags,
    variables,
    refetchQueries,
    awaitRefetchQueries: true,
  });
  return resp.data.createUserTags;
};

export const deleteTags = async (
  variables,
  refetchQueries: InternalRefetchQueriesInclude = [],
) => {
  const resp = await client.mutate({
    mutation: mutations.deleteUserTags,
    variables,
    refetchQueries,
    awaitRefetchQueries: true,
  });
  return _.get(resp, 'data.renameUserTag');
};

export async function archiveOrgMembers(
  organizationId: string,
  memberIds: string[],
  refetchQueries: InternalRefetchQueriesInclude = [],
) {
  return await client.mutate({
    mutation: mutations.archiveOrganizationMembers,
    variables: {
      id: organizationId,
      usersToArchive: memberIds,
    },
    refetchQueries,
    awaitRefetchQueries: true,
  });
}

export async function restoreOrgMembers(
  organizationId: string,
  memberIds: string[],
  refetchQueries: InternalRefetchQueriesInclude = [],
) {
  return await client.mutate({
    mutation: mutations.restoreOrganizationMembers,
    variables: {
      id: organizationId,
      usersToRestore: memberIds,
    },
    refetchQueries,
    awaitRefetchQueries: true,
  });
}

export const GetOrgTeamEvents = (args: {
  orgId: string;
  startDate?: Date;
  endDate?: Date;
  search?: string;
  teamEventStatus?: TeamEventStatus[];
  goals?: EventGoal[];
}): { teamEvents: any; loading: boolean } => {
  const { data, loading } = useQuery(queries.getOrganizationTeamEventsQuery, {
    variables: args,
  });
  return {
    teamEvents: data?.teamEventsConnection?.edges?.map((edge) => edge.node) || [],
    loading: loading,
  };
};

export const GetTeamEvents = (): { teamEvents: any; loading: boolean } => {
  const { data, loading } = useQuery(queries.getTeamEventsQuery);

  const edges = _.get(data, 'viewer.teamEvents.edges') || [];
  return {
    teamEvents: edges.map((edge) => edge.node),
    loading: loading,
  };
};

export const GetHomePageEvents = (): { teamEvents: any; loading: boolean } => {
  const { data, loading } = useQuery(queries.getHomePageEventsQuery);

  const edges = loading ? [] : data?.viewer?.teamEvents?.edges;
  return {
    teamEvents: edges?.map((edge) => edge.node),
    loading: loading,
  };
};

export const GetRequestedByEvents = (): { teamEvents: any; loading: boolean } => {
  const { data, loading } = useQuery(queries.getRequestedEventsQuery);
  return { teamEvents: _.get(data, 'viewer.requestedTeamEvents', []), loading: loading };
};

export const GetViewerId = (): string => {
  const { data } = useQuery<GetUserQuery>(queries.getUserIdQuery);

  return _.get(data, 'viewer.id');
};
