import { Checkbox as MuiCheckbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Check from '@mysteryco/design/icons/Check';
import { colors } from '@mysteryco/design';
import theme from 'theme';

const Checkbox = ({
  checked,
  onClick,
  disabled = false,
}: {
  checked?: boolean;
  onClick?: (e: any) => void;
  disabled?: boolean;
}) => {
  const classes = useStyles();

  const Icon = ({ checked = false }: { checked?: boolean }) => {
    return <div className={classes.checkbox}>{checked && <Check size={16} />}</div>;
  };

  return (
    <MuiCheckbox
      icon={<Icon />}
      checkedIcon={<Icon checked />}
      checked={checked}
      onClick={onClick}
      disabled={disabled}
    />
  );
};

const useStyles = makeStyles({
  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
    gap: theme.spacing(2.5),
    width: 24,
    height: 24,
    backgroundColor: colors.White,
    border: '1px solid #BDBDBD',
    borderRadius: theme.spacing(1),
    transition: 'all 300ms ease-in-out',
    '.MuiIconButton-label:hover &': {
      borderColor: colors.Main,
    },
    '.MuiIconButton-label:focus-within &': {
      borderColor: colors.Main,
      backgroundColor: colors.Purple100,
    },
    '.Mui-checked &': {
      background: colors.White,
      border: `2px solid ${colors.Main}`,
    },
    '.Mui-disabled &': {
      borderColor: colors.Purple200,
    },
  },
});

export default Checkbox;
