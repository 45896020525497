import { Result } from 'antd';

const NotFoundError = ({ title, subtitle }: { title?: string; subtitle?: string }) => (
  <Result
    status='404'
    title={title || 'Oops...'}
    subTitle={subtitle || "That page doesn't exist."}
  />
);

export default NotFoundError;
