import { useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import CalendarMinus01 from '@mysteryco/design/icons/CalendarMinus01';
import LockKeyholeCircle from '@mysteryco/design/icons/LockKeyholeCircle';
import Toggle03Left from '@mysteryco/design/icons/Toggle03Left';
import { colors } from '@mysteryco/design/src';
import * as Separator from '@radix-ui/react-separator';
import { Paths } from 'Routes';
import config from 'config';
import { StepStatus } from 'constants/Type';
import Button from 'glue/components/buttons/Button';
import ListedStepper from 'glue/components/lists/ListedStepper';
import { cancelPulseQuery } from 'gql/mutations';
import mixpanel from 'mixpanel-browser';
import theme from 'theme';

const strings = {
  turningOff: 'Turning off Pulse will do the following:',
  youSure: 'Are you sure you want to continue?',
  turnOff: 'Yes, turn off Pulse',
  cancel: 'Cancel',
  whatWill: 'WHAT WILL HAPPEN',
  yourCurrent: 'Your current Pulse survey will be closed',
  yourEmployees: 'Your employees will be notified.',
  weWont: 'We won’t send any future Pulse surveys',
  willNotSchedule: 'Glue will not schedule any future surveys.',
  youCan: 'You can turn Pulse back on at any time',
  youCanAlways: 'You can always reactivate Pulse and we’ll help you handle the rest.',
};

interface Props {
  pulseId: string;
  setShowCancel: (value: boolean) => void;
}

const PulseCancelSection = ({ setShowCancel, pulseId }: Props) => {
  const [cancelPulse] = useMutation(cancelPulseQuery);
  return (
    <div>
      <Separator.Root css={styles.seperator} />
      <div css={styles.cancelContainer}>
        <div css={styles.title}>{strings.turningOff}</div>
        <ListedStepper
          steps={[
            {
              text: strings.yourCurrent,
              subText: strings.yourEmployees,
              status: StepStatus.Cancelled,
              Icon: () => <LockKeyholeCircle size={18} color='#AB5F5F' />,
            },
            {
              text: strings.weWont,
              subText: strings.willNotSchedule,
              status: StepStatus.Cancelled,
              Icon: () => <CalendarMinus01 size={18} color='#AB5F5F' />,
            },
            {
              text: strings.youCan,
              subText: strings.youCanAlways,
              Icon: () => <Toggle03Left size={18} color={colors.Glue_Ink00} />,
            },
          ]}
          showConnector
        />
        <br />
        <div css={styles.title}>{strings.youSure}</div>
        <div css={styles.buttonContainer}>
          <Button
            color='black'
            shape='round'
            onClick={() => {
              mixpanel.track('pls-disable');
              cancelPulse({
                variables: {
                  pulseId,
                },
                refetchQueries: ['PulseSettings'],
              });
              // Force complete refresh of stepper
              window.location.href = `${config.teams.url}${Paths.PULSE_SETTINGS}`;
            }}
          >
            {strings.turnOff}
          </Button>
          <Button
            color='white'
            shape='round'
            onClick={() => {
              setShowCancel(false);
            }}
          >
            {strings.cancel}
          </Button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  cancelContainer: css({
    backgroundColor: colors.Glue_LavenderLight,
    margin: theme.spacing(5),
    padding: theme.spacing(5),
  }),
  seperator: css({
    margin: `0 ${theme.spacing(5)} ${theme.spacing(5)}`,
    height: '1px',
    backgroundColor: colors.Glue_Ink30,
  }),
  title: css({
    fontWeight: 800,
    fontSize: '16px',
    lineHeight: '28px',
    marginBottom: theme.spacing(5),
  }),
  buttonContainer: css({
    display: 'flex',
    margin: `${theme.spacing(5)} 0`,
    gap: theme.spacing(4),
  }),
};

export default PulseCancelSection;
