import { gql } from '@apollo/client';
import { css } from '@emotion/react';
import { colors } from '@mysteryco/design/src';
import { Paths } from 'Routes';
import {
  SettingsTopperActions,
  SettingsTopperCloseButton,
  SettingsTopperContent,
  SettingsTopperRoot,
  SettingsTopperTitle,
} from 'glue/components/structure/SettingsTopper';
import {
  NavigationTab,
  NavigationTabs,
  NavigationTabsList,
} from 'glue/components/tabs/NavigationTabs';
import QuestionFeedback from 'glue/scenes/Explore/Pulse/questions/QuestionFeedback';
import QuestionResponseSummary from 'glue/scenes/Explore/Pulse/questions/QuestionResponseSummary';
import PulseHelpBanner from 'glue/scenes/PulseSettings/components/PulseHelpBanner';
import { Route, RouteComponentProps, Switch, useHistory } from 'react-router-dom';
import theme from 'theme';
import { useQuestionDeepDiveQuery } from 'types';

export type QuestionDeepDiveProps = RouteComponentProps<{ questionId: string }>;

export function QuestionDeepDive({ match }: QuestionDeepDiveProps) {
  const { questionId } = match.params;
  const { data, loading } = useQuestionDeepDiveQuery({
    variables: { questionId },
  });

  const history = useHistory();

  if (loading) {
    return <div>Loading...</div>;
  }

  const questionSummary = data?.moralePulseQuestionSummary;

  if (!questionSummary) {
    return <div>Not found</div>;
  }

  return (
    <div css={styles.root}>
      <SettingsTopperRoot css={styles.topperRoot}>
        <SettingsTopperContent css={styles.topperContent}>
          <SettingsTopperTitle>Question Deep Dive</SettingsTopperTitle>
          <SettingsTopperActions>
            <SettingsTopperCloseButton
              onClick={() =>
                history.push({
                  pathname: Paths.EXPLORE_PULSE,
                  search: history.location.search,
                })
              }
            />
          </SettingsTopperActions>
        </SettingsTopperContent>
      </SettingsTopperRoot>
      <div css={styles.content}>
        <h1 css={styles.category}>{questionSummary.question.category}</h1>
        <h2 css={styles.question}>{questionSummary.question.copy}</h2>
        <NavigationTabs css={styles.tabs}>
          <NavigationTabsList>
            <NavigationTab
              to={`${Paths.EXPLORE_PULSE}/question/${questionId}`}
              preserveSearchParams
            >
              Summary
            </NavigationTab>
            <NavigationTab
              to={`${Paths.EXPLORE_PULSE}/question/${questionId}/feedback`}
              preserveSearchParams
            >
              Feedback
            </NavigationTab>
          </NavigationTabsList>
        </NavigationTabs>
        <Switch>
          <Route
            path={Paths.EXPLORE_PULSE_QUESTION}
            exact
            component={QuestionResponseSummary}
          />
          <Route
            path={Paths.EXPLORE_PULSE_QUESTION_FEEDBACK}
            component={QuestionFeedback}
          />
        </Switch>
        <PulseHelpBanner />
      </div>
    </div>
  );
}
QuestionDeepDive.query = gql`
  query QuestionDeepDive($questionId: ID!) {
    moralePulseQuestionSummary(questionId: $questionId) {
      id
      question {
        id
        copy
        category
        maxValue
        minValue
      }
    }
  }
`;

export default QuestionDeepDive;

const styles = {
  root: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(10),
  }),
  content: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8),
    maxWidth: '60rem',
    width: '100%',
    margin: `0 auto ${theme.spacing(15)}`,
  }),
  category: css({
    fontSize: 16,
    lineHeight: '175%',
    fontWeight: 700,
    color: colors.Glue_Ink10,
  }),
  question: css({
    fontSize: 32,
    lineHeight: '110%',
    fontWeight: 700,
    color: colors.Glue_Ink00,
  }),
  topperRoot: css({
    alignItems: 'center',
  }),
  topperContent: css({
    maxWidth: '60rem',
    width: '100%',
  }),
  tabs: css({
    marginBottom: theme.spacing(3),
  }),
};
