import { gql } from '@apollo/client';
import { css } from '@emotion/react';
import { Box, Grid } from '@material-ui/core';
import ArrowRight from '@mysteryco/design/icons/ArrowRight';
import {
  Glue_Ink00,
  Glue_Ink10,
  Glue_LavenderLight,
} from '@mysteryco/design/src/tokens/colors';
import { DEFAULT_SSF_ROUTE } from 'Routes';
import ComputerIllustration from 'assets/illustrations/computer.png';
import { LinkButton } from 'glue/components/buttons/Button';
import ActivityScheduleCard from 'glue/components/cards/ActivitySchedule';
import SectionHeader from 'glue/components/structure/SectionHeader';
import { teamEventFragment, virtualEventFragment } from 'gql/queries';
import {
  canShowJoinButton,
  getFeedbackUrl,
  isEventCompleted,
  isEventUpcoming,
  isEventValid,
  isHost,
} from 'lib/helpers/events';
import { DateTime } from 'luxon';
import mixpanel from 'mixpanel-browser';
import theme from 'theme';
import { ActivitiesCalendarViewerFragment, ActivityRowTeamEventFragment } from 'types';
import { isAdmin } from 'utils/security';
import { getEventDetails, getPhotoUrl } from 'utils/state';

type Props = {
  viewer: ActivitiesCalendarViewerFragment;
  className?: string;
};

type RowProps = {
  teamEvent: ActivityRowTeamEventFragment;
  viewer: ActivitiesCalendarViewerFragment;
  className?: string;
};

const ActivityRow = ({ teamEvent, viewer, className, ...props }: RowProps) => {
  const requestedFor = DateTime.fromISO(teamEvent.requestedFor);
  const image = getPhotoUrl({
    teamEvent,
    viewer,
  });
  const name = teamEvent?.title || '';

  const { description } = getEventDetails({ teamEvent, viewer });
  const hasCompleted = isEventCompleted(teamEvent);
  const isLive = canShowJoinButton({
    teamEvent: teamEvent,
    isHost: isHost({ teamEvent: teamEvent, viewer }),
  });
  const hasCompletedFeedback = teamEvent?.userCompletedFeedback;

  const detailsLink = `/event-details/${teamEvent?.id}`;

  const showFeedback = hasCompleted && !hasCompletedFeedback;

  const trackDetailsEvent = () => {
    mixpanel.track('event details opened', {
      source: 'home',
      customerType: viewer?.customerType,
      firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
    });
  };

  const linkUrl = showFeedback ? getFeedbackUrl(teamEvent?.id) : detailsLink;

  return (
    <Grid item xs={12}>
      <div css={styles.row} {...props}>
        <div css={styles.rowLeft}>
          <div css={styles.month}>{requestedFor.monthShort.toUpperCase()}</div>
          <div css={styles.day}>{requestedFor.day}</div>
        </div>
        <ActivityScheduleCard
          linkUrl={linkUrl}
          name={name}
          description={description}
          imageUrl={image}
          cta={showFeedback ? 'Give feedback' : isLive ? 'Join now' : 'View details'}
          date={teamEvent?.requestedFor}
          onClick={showFeedback || isLive ? undefined : trackDetailsEvent}
        />
      </div>
    </Grid>
  );
};
ActivityRow.fragments = gql`
  fragment ActivityRowTeamEvent on TeamEvent {
    id
    status
    surpriseToAll
    surpriseToAttendees
    experience {
      id
      name
      description
      photoUrl
      templates {
        id
        photoUrl
      }
    }
    title
    requestedFor
    userCompletedFeedback
    eventRating
    ...teamEventFragment
    ...GetPhotoUrlTeamEvent
  }
  ${teamEventFragment}
  ${getPhotoUrl.fragments}
`;

const ActivitiesCalendar = ({ viewer, className, ...props }: Props) => {
  const eventEdges = viewer?.teamEvents?.edges || [];
  const validTeamEvents = eventEdges
    .map((edge) => edge?.node)
    .filter((event) => event && isEventValid(event) && isEventUpcoming(event))
    // FIXME: GraphQL should assert these are non-null to fix these types
    .filter((event): event is ActivityRowTeamEventFragment => !!event)
    .sort(
      (a, b) => new Date(a.requestedFor).getTime() - new Date(b.requestedFor).getTime(),
    );

  return (
    <Box css={[styles.root, className]} {...props}>
      <SectionHeader
        title='Upcoming events'
        subtitle='Your schedule for the next 30 days'
      />
      <Grid
        container
        css={{
          gap: theme.spacing(5),
        }}
      >
        {validTeamEvents.length > 0 ? (
          validTeamEvents.map((event) => {
            return <ActivityRow key={event.id} viewer={viewer} teamEvent={event} />;
          })
        ) : (
          <EmptyState viewer={viewer} />
        )}
      </Grid>
    </Box>
  );
};

ActivitiesCalendar.fragments = gql`
  fragment ActivitiesCalendarViewer on User {
    id
    requestedTeamEvents {
      id
    }
    customerType
    role
    defaultOrganization {
      id
    }
    organizationRole {
      id
      name
    }
    teamEvents {
      edges {
        node {
          id
          title
          status
          requestedFor
          requestedBy {
            id
          }
          experience {
            name
            description
            templates {
              photoUrl
            }
            photoUrl
          }
          surpriseToAttendees
          surpriseToAll
          userCompletedFeedback
          virtualEvent {
            ...virtualEventFragment
          }
          ...ActivityRowTeamEvent
        }
      }
    }
  }
  ${virtualEventFragment}
  ${ActivityRow.fragments}
`;

const styles = {
  root: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    gap: theme.spacing(8),
    alignSelf: 'stretch',
    maxWidth: 800,
  }),
  row: css({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(8),
    flex: 1,
    color: 'inherit',
    textDecoration: 'none',
  }),
  rowLeft: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  month: css({
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 12,
    lightHeight: 1.6,
    lineSpacing: '0.08em',
    color: Glue_Ink10,
  }),
  day: css({
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 24,
    lightHeight: 1,
    color: Glue_Ink00,
  }),
};

const EmptyState = ({ viewer }: { viewer: ActivitiesCalendarViewerFragment }) => {
  const handleBookEvent = () => {
    mixpanel.track('book event opened', {
      source: 'home',
      customerType: viewer.customerType,
      firstTimeBooker: viewer.requestedTeamEvents?.length === 0,
    });
  };

  const viewerIsAdmin = isAdmin(viewer);

  return (
    <div css={emptyStateStyles.root}>
      <div css={emptyStateStyles.circle}>
        <img css={emptyStateStyles.image} src={ComputerIllustration} alt='' />
      </div>
      <div css={emptyStateStyles.text}>Nothing to see here</div>
      <div css={emptyStateStyles.description}>
        {viewerIsAdmin
          ? 'Start by booking a team event'
          : 'Keep an eye open for future event invites'}
      </div>
      {viewerIsAdmin && (
        <LinkButton
          shape='round'
          color='green'
          css={emptyStateStyles.button}
          onClick={handleBookEvent}
          endIcon={<ArrowRight size={24} color='currentColor' />}
          newTab
          to={DEFAULT_SSF_ROUTE}
        >
          Book event
        </LinkButton>
      )}
    </div>
  );
};

const emptyStateStyles = {
  root: css({
    margin: `${theme.spacing(8)} 0 ${theme.spacing(30)} 0`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  }),
  circle: css({
    background: Glue_LavenderLight,
    borderRadius: '50%',
    width: 64,
    height: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  image: css({
    width: 32,
  }),
  text: css({
    fontFamily: 'Avenir, sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1,
    color: Glue_Ink10,
    marginTop: theme.spacing(5),
  }),
  description: css({
    fontFamily: 'Avenir, sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 1.4,
    color: Glue_Ink00,
    marginTop: theme.spacing(2),
  }),
  button: css({
    margin: theme.spacing(2),
  }),
};

export default ActivitiesCalendar;
