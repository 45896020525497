import React from 'react';

const FaceNegative = ({ color }: { color?: string }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='12' cy='12' r='11.5' stroke={color || '#7B1783'} />
      <ellipse cx='7.5' cy='11' rx='1.5' ry='2' fill={color || '#7B1783'} />
      <ellipse cx='15.5' cy='11' rx='1.5' ry='2' fill={color || '#7B1783'} />
      <path
        d='M7.5 18.0006C11 16.0007 15.5 15.5006 19.5 16.0006'
        stroke={color || '#7B1783'}
        strokeLinecap='round'
      />
    </svg>
  );
};

export default FaceNegative;
