import { EventGoal, ScoreType } from 'types';

export const goalToString = (goal: EventGoal): string => {
  switch (goal) {
    case EventGoal.BetweenTeam: {
      return 'Cross-team';
    }
    case EventGoal.FullOrg: {
      return 'All-org';
    }
    case EventGoal.Leadership: {
      return 'Leadership';
    }
    case EventGoal.NewHire: {
      return 'New-hire integration';
    }
    case EventGoal.WithinTeam: {
      return 'Within-team';
    }
    default:
      // Just so this doesn't blow up if we add new goals
      return 'Sense-of-belonging';
  }
};

export const goalToScoreType = (goal: EventGoal): ScoreType => {
  const map = {
    [EventGoal.BetweenTeam]: ScoreType.CrossTeam,
    [EventGoal.Leadership]: ScoreType.Leadership,
    [EventGoal.WithinTeam]: ScoreType.WithinTeam,
  };
  return map[goal];
};

export const scoreTypeToGoal = (scoreType: ScoreType): EventGoal => {
  const map = {
    [ScoreType.CrossTeam]: EventGoal.BetweenTeam,
    [ScoreType.Leadership]: EventGoal.Leadership,
    [ScoreType.WithinTeam]: EventGoal.WithinTeam,
  };

  return map[scoreType];
};
