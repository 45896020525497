import { Helmet } from 'react-helmet';
import { Redirect, Route, Switch } from 'react-router';
import { ViewerFragmentFragment } from 'types';
import { PageMetaData, Paths } from 'Routes';
import Settings from './Settings';
import Preferences from './Preferences';
import { isAdmin } from 'utils/security';

export const MEETUPS = [
  {
    path: 'settings',
    component: (props) => <Settings {...props} />,
  },
  {
    path: 'preferences',
    component: (props) => <Preferences {...props} />,
  },
];

const Meetups = ({ viewer }: { viewer: ViewerFragmentFragment }) => {
  return (
    <>
      <Helmet>
        <title>{PageMetaData.MEETUPS.title}</title>
      </Helmet>
      <Switch>
        <Route exact path={Paths.MEETUPS}>
          <Redirect
            to={`${Paths.MEETUPS}/${isAdmin(viewer) ? MEETUPS[0].path : MEETUPS[1].path}`}
          />
        </Route>
        {MEETUPS.map((page) => (
          <Route key={page.path} path={`${Paths.MEETUPS}/${page.path}`}>
            {page.component({ viewer })}
          </Route>
        ))}
      </Switch>
    </>
  );
};

export default Meetups;
