import React from 'react';
import theme from 'theme';

const Map = ({ 
    height, 
    width,
    color = theme.palette.primary.main,
    style = {},
    className = '',
  }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      style={style}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d="M1.3335 3.99998L0.837357 3.13174C0.525782 3.30978 0.333496 3.64112 0.333496 3.99998H1.3335ZM1.3335 14.6666H0.333496C0.333496 15.0231 0.523255 15.3526 0.831569 15.5316C1.13988 15.7105 1.52013 15.7117 1.82964 15.5349L1.3335 14.6666ZM10.6668 14.6666L10.1707 15.5349C10.4996 15.7228 10.9064 15.7088 11.2215 15.4987L10.6668 14.6666ZM14.6668 12L15.2215 12.832C15.4997 12.6466 15.6668 12.3343 15.6668 12H14.6668ZM14.6668 1.33331H15.6668C15.6668 0.964518 15.4638 0.625657 15.1387 0.451638C14.8135 0.277619 14.419 0.296692 14.1121 0.501263L14.6668 1.33331ZM6.00016 1.33331L6.4963 0.46507C6.18887 0.289394 5.81146 0.289394 5.50402 0.46507L6.00016 1.33331ZM0.333496 3.99998V14.6666H2.3335V3.99998H0.333496ZM1.82964 15.5349L6.4963 12.8682L5.50402 11.1317L0.837357 13.7984L1.82964 15.5349ZM5.50402 12.8682L10.1707 15.5349L11.163 13.7984L6.4963 11.1317L5.50402 12.8682ZM11.2215 15.4987L15.2215 12.832L14.1121 11.1679L10.1121 13.8346L11.2215 15.4987ZM15.6668 12V1.33331H13.6668V12H15.6668ZM14.1121 0.501263L10.1121 3.16793L11.2215 4.83203L15.2215 2.16536L14.1121 0.501263ZM11.163 3.13174L6.4963 0.46507L5.50402 2.20156L10.1707 4.86822L11.163 3.13174ZM5.50402 0.46507L0.837357 3.13174L1.82964 4.86822L6.4963 2.20156L5.50402 0.46507ZM11.6668 14.6666V3.99998H9.66683V14.6666H11.6668ZM5.00016 1.33331V12H7.00016V1.33331H5.00016Z"
        fill={color}
      />
    </svg>
  );
};

export default Map;
