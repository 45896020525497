import { gql } from '@apollo/client';
import { colors } from '@mysteryco/design';
import ScoringCard from 'components/Cards/ScoringCard';
import { SCORE_TYPE_NAME_MAP } from 'constants/Strings';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import theme from 'theme';
import {
  InsightsSidePanelOrganizationFragment,
  InsightsSidePanelTeamFragment,
  ScoreType,
} from 'types';

import { Button, Checkbox, Grid, Input, Slider, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Cube01 from 'components/icons/cube-01';

import { makeStyles } from '@material-ui/styles';
import {
  Configuration,
  createNodeSizeCurve,
} from 'glue/components/insights/data/interface';
import { ScoreTypeIndex } from 'glue/components/insights/data/utility';
import { pluralize } from 'humanize-plus';
import { getScoreDetailsPath } from './ScoreDetails';

const STEP_SIZE = 0.01;

interface Props {
  devMode: boolean;
  loading: boolean;
  setConfig(newConfig: Configuration): void;
  config: Configuration;
  showUpdateTeams?: boolean;
  // FIXME: switch to strict mode and refactor the component so these can be non-nullable or at least
  // we can say with confidence they should be nullable
  team?: InsightsSidePanelTeamFragment;
  organization?: InsightsSidePanelOrganizationFragment | null;
  departmentName?: string | null;
}

const ConfigSliders = ({
  loading,
  config,
  setConfig,
}: Pick<Props, 'loading' | 'config' | 'setConfig'>) => {
  const classes = useStyles();
  const [hidden, setHidden] = useState<boolean>(false);
  const [nodeSizeCurvePoints, setNodeSizeCurvePoints] = useState<string>(
    config.nodeSizeCurve.points.map((v) => v.toFixed(2)).join(', '),
  );

  if (hidden) return null;

  const nodeSize = parseNodeSize(nodeSizeCurvePoints);

  const handleChange = (key: keyof Configuration) => (_event, value) => {
    setConfig({ ...config, [key]: value });
  };

  const textClass = loading ? classes.loadingText : classes.loadedText;
  return (
    <Grid className={classes.devTooling} item css={{ '& > p': { marginBottom: 0 } }}>
      <Typography css={{ paddingTop: theme.spacing(6) }}>Flatten Graph</Typography>

      <div css={{ display: 'flex', alignItems: 'center' }}>
        <Typography className={textClass} css={{ marginBottom: '0' }}>
          Members
        </Typography>
        <Checkbox
          checked={config.flattenMembers}
          onChange={(event) =>
            setConfig({
              ...config,
              flattenMembers: event.target.checked,
              flattenTeams: false,
            })
          }
        />
        <Typography className={textClass} css={{ marginBottom: '0' }}>
          Teams
        </Typography>
        <Checkbox
          checked={config.flattenTeams}
          onChange={(event) =>
            setConfig({
              ...config,
              flattenTeams: event.target.checked,
              flattenMembers: false,
            })
          }
        />
      </div>

      <Typography css={{ paddingTop: theme.spacing(2) }}>Sizing</Typography>

      <Typography className={textClass}>Node Size</Typography>
      <Slider
        min={1}
        max={100}
        defaultValue={[config.minNodeSize, config.maxNodeSize]}
        onChangeCommitted={(_event, change) => {
          if (typeof change === 'number') return; // ignore non-range sliders
          const [minNodeSize, maxNodeSize] = change;
          setConfig({ ...config, minNodeSize, maxNodeSize });
        }}
        valueLabelDisplay='auto'
      />

      <Typography className={textClass}>Manager Node Size</Typography>
      <Slider
        min={1}
        max={100}
        defaultValue={config.managerNodeSize}
        onChangeCommitted={handleChange('managerNodeSize')}
        valueLabelDisplay='auto'
      />

      <Typography className={textClass}>Node Size Curve</Typography>
      <Input
        defaultValue={nodeSizeCurvePoints}
        onChange={(e) => setNodeSizeCurvePoints(e.target.value)}
        error={!nodeSize}
        css={{ width: '100%' }}
      />

      <Typography css={{ paddingTop: theme.spacing(6) }}>Score Thresholds</Typography>

      <Typography className={textClass}>Hide Edges</Typography>
      <Slider
        min={0}
        max={1}
        step={STEP_SIZE}
        defaultValue={config.showEdge}
        onChangeCommitted={handleChange('showEdge')}
        valueLabelDisplay='auto'
      />

      <Typography className={textClass}>Strong/Moderate</Typography>
      <Slider
        min={config.moderateSubpar}
        max={1}
        step={STEP_SIZE}
        defaultValue={config.strongModerate}
        onChangeCommitted={handleChange('strongModerate')}
        valueLabelDisplay='auto'
      />

      <Typography className={textClass}>Moderate/Subpar</Typography>
      <Slider
        min={config.subparWeak}
        max={config.strongModerate}
        step={STEP_SIZE}
        defaultValue={config.subparWeak}
        onChangeCommitted={handleChange('moderateSubpar')}
        valueLabelDisplay='auto'
      />

      <Typography className={textClass}>Subpar/Weak</Typography>
      <Slider
        min={0}
        max={config.moderateSubpar}
        step={STEP_SIZE}
        defaultValue={config.subparWeak}
        onChangeCommitted={handleChange('subparWeak')}
        valueLabelDisplay='auto'
      />

      <Button
        variant='outlined'
        onClick={() => {
          setHidden(true);
        }}
      >
        Hide Sliders
      </Button>
    </Grid>
  );
};

function parseNodeSize(nodeSize: string) {
  const points = nodeSize.split(/\s*,\s*/g).map((v) => parseFloat(v));
  if (points.length !== 4) return undefined;
  if (!points.every((p) => Number.isFinite(p))) return undefined;

  return createNodeSizeCurve(...(points as [number, number, number, number]));
}

const SidePanel = ({
  devMode,
  organization,
  loading,
  team,
  departmentName,
  setConfig,
  config,
  showUpdateTeams = false,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid
      container
      direction={'column'}
      css={{
        position: 'sticky',
        top: 0,
        zIndex: 1,
      }}
    >
      {!loading && (!organization || !team) ? null : (
        <>
          <div className={classes.orgName}>
            {loading ? (
              <Skeleton width={280} height={36} />
            ) : (
              <span>{organization?.name}</span>
            )}
          </div>
          {loading ? (
            <Skeleton width={280} height={22} />
          ) : showUpdateTeams ? (
            <div className={classes.teamLabel}>
              <div>{team?.name}</div>
              <div className={classes.teamLabelDetails}>
                Manager: {team?.manager?.name}
              </div>
              <div className={classes.teamLabelDetails}>
                {team?.members?.length}{' '}
                {pluralize(team?.members?.length || 0, 'direct report')}
              </div>
            </div>
          ) : (
            <div className={classes.tagLabel}>
              <span css={{ display: 'flex', alignItems: 'center' }}>
                <Cube01
                  color={colors.Green600}
                  width={16}
                  height={16}
                  style={{ marginRight: theme.spacing(1) }}
                />
                {departmentName || team?.name}
              </span>
            </div>
          )}
          <div className={classes.scoreHeader}>Org scores</div>
          {ScoreTypeIndex.map((scoreType) => {
            const score = team?.scores?.find((t) => t.scoreType === scoreType);
            return (
              <ScoringCard
                key={scoreType}
                loading={loading}
                title={SCORE_TYPE_NAME_MAP[scoreType]}
                value={(score as any)?.value}
                trend={(score as any)?.trend}
                showMeasureBar={scoreType === ScoreType.SenseOfBelonging}
                onClick={() => {
                  if (!team?.id || !organization?.id) {
                    console.warn(`No team id or org id available to link to for details`);
                    return;
                  }
                  history.push(
                    `${getScoreDetailsPath(organization.id, team.id, scoreType)}/${
                      history.location.search
                    }`,
                  );
                }}
              />
            );
          })}
          {devMode && (
            <ConfigSliders loading={loading} config={config} setConfig={setConfig} />
          )}
        </>
      )}
    </Grid>
  );
};

SidePanel.fragments = gql`
  fragment InsightsSidePanelOrganization on Organization {
    id
    name
  }

  fragment InsightsSidePanelTeam on Team {
    id
    name
    manager {
      id
      name
    }
    members {
      id
    }
    scores(version: $version) {
      scoreType
      ... on EngagementScore {
        value
        trend
      }
    }
  }
`;

const useStyles = makeStyles({
  loadingText: {
    textTransform: 'uppercase',
    fontFamily: `'Roboto Mono', monospaced`,
    color: theme.palette.grey[200],
    fontSize: '.75rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  loadedText: {
    textTransform: 'uppercase',
    fontFamily: `'Roboto Mono', monospaced`,
    color: theme.palette.primary.main,
    fontSize: '.75rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  orgName: {
    color: theme.palette.text.secondary,
    fontSize: '24px',
  },
  tagLabel: {
    paddingLeft: '10px',
    borderRadius: theme.spacing(6),
    color: colors.Green800,
    background: colors.Green200,
    height: '28px',
    fontWeight: 500,
    marginTop: theme.spacing(1),
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: `'Roboto Mono', monospaced`,
    textTransform: 'uppercase',
    letterSpacing: '-.5px',
    width: 'fit-content',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    marginBottom: theme.spacing(8),
  },
  teamLabel: {
    marginTop: theme.spacing(3),
    fontSize: '.875rem',
    lineHeight: 1.5,
    color: colors.Dusk,
    fontWeight: 500,
  },
  teamLabelDetails: {
    fontSize: '.75rem',
    lineHeight: 1.5,
    color: colors.Purple700,
    fontWeight: 500,
  },
  scoreHeader: {
    padding: theme.spacing(4),
    fontSize: '18px',
  },
  devTooling: {
    display: 'block',
    padding: `0 ${theme.spacing(8)} ${theme.spacing(8)}`,
    background: 'rgba(255, 255, 255, 0.8)',
    position: 'absolute',
    left: '68vw',
    top: 20,
    border: `1px solid ${theme.palette.primary[100]}`,
    backdropFilter: 'blur(10px)',
    boxShadow: `0 ${theme.spacing(6)} ${theme.spacing(5)} ${theme.spacing(-5)} ${
      theme.palette.primary[100]
    }`,
  },
});

export default SidePanel;
