import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormatPercentage from 'components/FormatPercentage';
import { pluralize } from 'humanize-plus';
import theme from 'theme';
import { colors } from '@mysteryco/design';
import PieProgress from '../PieProgress';
import EmptyPie from 'components/icons/EmptyPie';

interface Props<T> {
  title: string;
  data: T[];
  filter: (a: T) => boolean;
  subtitleSubject: string;
  subtitlePredicate: string;
  pillSubject: string;
}

const useStyles = makeStyles({
  percentageText: {
    fontFamily: 'Roboto Mono',
    fontSize: '40px',
    lineHeight: '48px',
    fontWeight: 'bold',
    color: colors.Dusk,
  },
  subjectPredicateText: {
    color: theme.palette.primary[800],
    fontSize: '14px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
  pillText: {
    color: colors.Dusk,
    background: theme.palette.primary[100],
    borderRadius: '20px',
    fontSize: '14px',
    padding: `${theme.spacing(1)} ${theme.spacing(4)}`,
    whiteSpace: 'nowrap',
    width: 'fit-content',
  },
});

const LOW_CUTOFF = 50;
const HIGH_CUTOFF = 75;

/**
 * A card-like metric that displays the percentage of data for which the filter
 * callback is truthy in a pie chart, with a subtitle
 * "_ out of _ subtitleSubject subtitlePredicate"
 */
const NumericalMetric = <T extends unknown>({
  title,
  data,
  filter,
  subtitleSubject,
  subtitlePredicate,
  pillSubject,
}: Props<T>) => {
  const truthy = data?.filter(filter)?.length || 0;
  const total = data?.length ?? 0;
  const asPercentage = Math.ceil((100 * truthy) / total);

  const classes = useStyles();
  const highPercentage = asPercentage >= HIGH_CUTOFF;
  const lowPercentage = asPercentage <= LOW_CUTOFF;

  return (
    <Box
      display={'grid'}
      gridTemplateRows={'repeat(auto, 4)'}
      gridTemplateColumns={'40px  auto'}
      gridRowGap={theme.spacing(2)}
      gridColumnGap={theme.spacing(2)}
    >
      <Box gridRow={1} gridColumn={'1 / 3'}>
        <Typography>{title}</Typography>
      </Box>
      <Box gridRow={2} gridColumn={1} paddingTop={theme.spacing(1)}>
        {asPercentage > 0 ? (
          <PieProgress percentFilled={asPercentage} />
        ) : (
          <EmptyPie size={40} color={colors.Purple300} />
        )}
      </Box>
      <Box gridRow={2} gridColumn={2}>
        <Typography className={classes.percentageText}>
          <FormatPercentage percentage={asPercentage} />
        </Typography>
      </Box>
      <Box gridRow={3} gridColumn={2}>
        <Typography className={classes.subjectPredicateText}>
          {`${truthy} out of ${total ?? 0} ${pluralize(
            total,
            subtitleSubject,
          )} ${subtitlePredicate}`}
        </Typography>
      </Box>
      <Box gridRow={4} gridColumn={2}>
        <Typography className={classes.pillText}>{`${
          highPercentage ? '🎉 High' : lowPercentage ? 'Low' : 'Good'
        } ${pillSubject}`}</Typography>
      </Box>
    </Box>
  );
};

export default NumericalMetric;
