import { gql } from '@apollo/client';
import { css } from '@emotion/react';
import { Loading } from 'components/core';
import { CONNECTION_STRENGTH_MAP, ConnectionStrength } from 'constants/Type';
import GalaxyView from 'glue/components/insights/GalaxyView';
import { GraphContext } from 'glue/components/insights/data/GraphContext';
import { computeGraph } from 'glue/components/insights/data/graph';
import {
  Configuration,
  TeamFragment,
  createNodeSizeCurve,
  dataFragments,
} from 'glue/components/insights/data/interface';
import { getDepartmentName } from 'glue/components/insights/data/utility';
import NotFoundPage from 'glue/components/navigation/NotFoundPage';
import { useGuaranteedMemo } from 'hooks';
import { useQueryParams } from 'lib/helpers/router';
import RequireRole from 'lib/hocs/RequireRole';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Route, Switch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Statsig } from 'statsig-react';
import theme from 'theme';
import {
  ConnectionsWrapperQuery,
  CustomerType,
  NylasConnectionState,
  OrganizationRoleName,
  useConnectionsWrapperQuery,
  useInsightsGalaxyViewGlueQuery,
} from 'types';
import { isAdmin, isWizard } from 'utils/security';
import { validate } from 'uuid';
import { getExploreTypePath } from '..';
import EmptyState from './EmptyState';
import PendingApproval from './EmptyState/PendingApproval';
import ScoreDetails from './ScoreDetails';
import SidePanel from './SidePanel';
import InsightsUpsell from './UpsellView';

interface Props {
  organizationId: string;
  scoreVersion?: string | null;
  devMode: boolean;
}

export const getConnectionsPath = (teamId?: string) => {
  const teamIdPath = teamId ? `?teamId=${teamId}` : '';
  return getExploreTypePath('connections').concat(teamIdPath);
};

const getOrganizationId = (
  viewer: ConnectionsWrapperQuery['viewer'],
  orgIdFromQueryParams: string | null,
): string | undefined => {
  if (!viewer) return undefined;

  // Allow admin users to use any organization ID by adding it to the page's route.
  // Non-wizards will see no effect, and will view their organization's engagement graph.
  const viewersOrganizationId = viewer?.orgs?.[0]?.id;

  return isWizard(viewer) && orgIdFromQueryParams && validate(orgIdFromQueryParams)
    ? orgIdFromQueryParams || viewersOrganizationId
    : viewersOrganizationId;
};

// Allow anyone to tweak UI parameters if they know the secret code.
const getDevMode = (devModeFromQueryParams: string | null): boolean => {
  return !_.isEmpty(devModeFromQueryParams);
};

const ConnectionsWrapper = () => {
  const { data, loading } = useConnectionsWrapperQuery();

  const params = useQueryParams();
  const teamIdFromParams = params.get('teamId');
  const devMode = getDevMode(params.get('devMode'));
  const scoreVersion = params.get('scoreVersion');

  if (loading) {
    return <Loading />;
  }

  const viewer = data?.viewer;
  const organizationId = getOrganizationId(viewer, params.get('orgId'));
  const organization = viewer?.orgs?.find((org) => org?.id === organizationId);
  const teamId = teamIdFromParams || organization?.rootTeam?.id;

  if (!viewer) {
    // this shouldn't happen except in an error state.
    return <NotFoundPage />;
  }

  const admin = isAdmin(viewer);
  const insightsEnabled = viewer?.customerType === CustomerType.EventsAndInsights;

  // User's org has insights enabled, send them in, allow require role to 405 non-admins
  if (insightsEnabled && teamId && organizationId) {
    return (
      <RequireRole role={OrganizationRoleName.Admin}>
        <Switch>
          <Route exact path={getConnectionsPath()}>
            <Connections
              organizationId={organizationId}
              teamId={teamId}
              devMode={devMode}
              scoreVersion={scoreVersion}
            />
          </Route>
          <Route path={`${getConnectionsPath()}/:orgId/:teamId/:scoreType`}>
            <ScoreDetails />
          </Route>
        </Switch>
      </RequireRole>
    );
  }

  // If insights not enabled, in an org, but not an admin, show not found (we don't think we can sell to these folks)
  if (organizationId && !admin) {
    return <NotFoundPage />;
  }

  // If transactional, or an admin of their org, show insights upsell
  return <InsightsUpsell />;
};

ConnectionsWrapper.query = gql`
  query ConnectionsWrapper {
    viewer {
      id
      customerType
      role
      organizationRole {
        id
        name
      }

      orgs {
        id
        rootTeam {
          id
        }
      }
    }
  }
`;

const Connections = ({
  organizationId,
  teamId,
  devMode,
  scoreVersion,
}: Props & { organizationId: string; teamId: string }) => {
  const history = useHistory();
  const params = useQueryParams();

  const [config, setConfig] = useState<Configuration>(() => ({
    flattenMembers: false,
    flattenTeams: false,
    showEdge: 0.15,
    strongModerate: CONNECTION_STRENGTH_MAP[ConnectionStrength.Strong].threshold,
    moderateSubpar: CONNECTION_STRENGTH_MAP[ConnectionStrength.Moderate].threshold,
    subparWeak: CONNECTION_STRENGTH_MAP[ConnectionStrength.Poor].threshold,
    minNodeSize: 10,
    maxNodeSize: 30,
    managerNodeSize: 10,
    nodeSizeCurve: createNodeSizeCurve(0.97, 0.25, 0.39, 0.83),
  }));

  const { data, loading } = useInsightsGalaxyViewGlueQuery({
    variables: { organizationId, version: scoreVersion },
    // Note that Apollo Client performs VERY poorly with large graphs when
    // writing them to the cache.
    //
    // On my laptop, Apollo spends ~4 SECONDS of pure JS time to handle a
    // response that represents an organization with 500 people.
    //
    // So, as a workaround, we don't cache this at all. The tradeoff is that
    // every time someone navigates to this page, they must fetch it again.
    //
    // for details, see: https://www.youtube.com/watch?v=Q4yltOuXufE)
    fetchPolicy: 'no-cache',
  });
  const { organization, latestEngagementEdges, viewer } = data || {};

  const graphContext = useGuaranteedMemo(() => {
    if (!organization?.teams || !latestEngagementEdges) return null;

    return new GraphContext(
      organization.teams.filter((team): team is TeamFragment => !!team),
      latestEngagementEdges,
      config,
    );
  }, [config, organization?.teams, latestEngagementEdges]);

  const showUpdateTeams = Statsig.checkGate('show_update_teams');
  const graphData = useGuaranteedMemo(() => {
    return computeGraph(
      graphContext,
      config,
      organization || undefined,
      latestEngagementEdges || undefined,
      teamId,
      showUpdateTeams,
    );
  }, [graphContext, config, organization, latestEngagementEdges, teamId]);

  const team = graphContext?.team(teamId);
  const departmentName = team?.manager ? getDepartmentName(team.manager) : undefined;

  const orgIdFromParams = params.get('orgId');
  const inspectMode =
    viewer && isWizard(viewer) && orgIdFromParams && validate(orgIdFromParams);
  const orgHasHrisData =
    organization?.isHrisConnected || !!organization?.manualIntegrationLastUpdated;

  const noCalendarConnection =
    viewer?.nylasConnectionState === NylasConnectionState.Inactive;
  const noOrgData = !organization?.rootTeam || !orgHasHrisData;
  const noData = noCalendarConnection && noOrgData;

  const shouldShowEmptyState = !loading && noData && !inspectMode;

  const manuallyResizeToRemainingHeightRef = useManuallyResizeToRemainingHeight();

  if (shouldShowEmptyState) {
    return <EmptyState />;
  }
  const pendingApproval =
    !loading &&
    !shouldShowEmptyState &&
    !organization?.engagementGraphApproved &&
    !inspectMode;

  return (
    <div css={styles.mainWrapper}>
      {/* START: Sidebar */}
      <div css={styles.sidebarWrapper}>
        <SidePanel
          devMode={devMode}
          loading={loading || pendingApproval}
          organization={organization}
          team={team}
          departmentName={departmentName}
          setConfig={setConfig}
          config={config}
          showUpdateTeams={showUpdateTeams}
        />
      </div>
      {/* END: Sidebar */}
      {/* START: Chart area */}
      {pendingApproval ? (
        <PendingApproval />
      ) : (
        <div css={styles.galaxyWrapper} ref={manuallyResizeToRemainingHeightRef}>
          <GalaxyView
            devMode={devMode}
            loading={loading}
            data={graphData}
            organization={organization}
            teamId={teamId}
            setTeamId={(teamId) => {
              if (!!teamId) {
                params.set('teamId', `${teamId}`);
                history.push(`${getConnectionsPath()}?${params.toString()}`);
              } else {
                params.delete('teamId');
                history.push(`${getConnectionsPath()}?${params.toString()}`);
              }
            }}
            scoreVersion={scoreVersion}
          />
        </div>
      )}
      {/* END: Chart area */}
    </div>
  );
};

/**
 * Do I feel good about this? No.
 *
 * But the chart wrapper just keeps getting bigger because there's no natural
 * constraint on the height.
 *
 * Ideally you'd fix this with a defined page layout that maxes out at the
 * window size, but our page layout structure is defined at a much higher scope.
 */
function useManuallyResizeToRemainingHeight(margin = 40) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resize = () => {
      if (!ref.current) return;
      const height = window.innerHeight - ref.current.getBoundingClientRect().top;
      ref.current.style.height = `${height - margin}px`;
    };
    resize();
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, []);

  return ref;
}

Connections.query = gql`
  query insightsGalaxyViewGlue($organizationId: ID!, $version: String) {
    viewer {
      id
      nylasConnectionState
      role
    }
    organization(id: $organizationId) {
      id
      isHrisConnected
      manualIntegrationLastUpdated
      engagementGraphApproved
      rootTeam {
        id
      }
      teams {
        id
        childTeams {
          id
        }
        ...InsightsSidePanelTeam
      }
      ...InsightsGraphOrganizationGlue
      ...InsightsEmptyStateOrganization
      ...InsightsSidePanelOrganization
      ...InsightsGalaxyViewOrganization
    }
    latestEngagementEdges(organizationId: $organizationId, version: $version) {
      ...InsightsGraphEngagementEdge
    }
  }

  ${dataFragments}
  ${EmptyState.fragments}
  ${SidePanel.fragments}
  ${GalaxyView.fragments}
`;

const styles = {
  mainWrapper: css({
    flex: 1,
    paddingTop: theme.spacing(6),
    overflow: 'hidden',
    display: 'flex',
    width: '100%',
    height: '100%',
    margin: '0 auto',
    maxWidth: '1600px',
    alignItems: 'flex-start',
    flexDirection: 'row',
    '@media (max-width: 1200px)': {
      flexDirection: 'column',
    },
  }),
  sidebarWrapper: css({
    flex: 1,
    '@media (min-width: 1200px)': {
      maxWidth: '240px',
      marginRight: '40px',
    },
    '@media (max-width: 1200px)': {
      marginBottom: '40px',
    },
  }),
  galaxyWrapper: css({
    flex: 2,
    '@media (max-width: 1200px)': {
      flex: 1,
      paddingBottom: '40px',
    },
  }),
};

ConnectionsWrapper.ScoreDetails = ScoreDetails;

export default ConnectionsWrapper;
