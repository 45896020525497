// Used to serialize / unserialize the Self Serve Flow's form state
// using JSONCrush to compress the state as JSON

import JSONCrush from 'jsoncrush';
import { SelfServeState } from 'types/selfServeFlow';

export const formStateFromString = (crushedJSON: string): SelfServeState => {
  const parsed = JSON.parse(JSONCrush.uncrush(crushedJSON)) as SelfServeState;

  return parsed;
};

export const stringFromFormState = (formState: SelfServeState): string => {
  return JSONCrush.crush(JSON.stringify(formState));
};
