// @ts-strict-ignore
import { useDebounceFn, useResponsive } from 'ahooks';
import { Drawer, Input, message } from 'antd';
import { Loading } from 'components/core';
import { Formik } from 'formik';
import { getOrganizationRoles } from 'gql/queries';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import theme, { primaryGradient } from 'theme';
import { getParsedEmails } from 'utils/stringUtil';

import { useMutation, useQuery } from '@apollo/client';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { Colors } from 'constants/index';
import RolePicker from './RolePicker';
import { updateOrganizationMembers } from 'gql/mutations';
import { MemberFragmentFragment } from 'types';

const ValidEmailSection = ({ classes, emails }) => {
  const count = _.size(emails);
  return (
    <Box display='flex' flexDirection='column' mt={4}>
      <Typography style={{ fontWeight: 'bold' }}>
        {`We'll send a sign up link to${count > 1 ? ` these ${count} people` : ''}`}
      </Typography>
      <div className={classes.validEmails}>
        {emails.map((email, i) => (
          <Box mr={'2px'} mb={'2px'} key={`email-${i}`}>
            <Typography>{`${email}${i < emails.length - 1 ? ',' : ''}`}</Typography>
          </Box>
        ))}
      </div>
    </Box>
  );
};

type Props = {
  isShowing: boolean;
  setIsShowing(isShowing: boolean): void;
  orgId: string;
  existingMembers: Pick<MemberFragmentFragment, 'id' | 'email' | 'organizationRole'>[];
};

const validationSchema = Yup.object({
  parsedEmails: Yup.array()
    .of(Yup.string().email(({ value }) => `${value} is not a valid email`))
    .min(1, 'You must enter at least one valid email'),
  roleId: Yup.string().required('You must select a Role to invite members'),
});

const AddTeamMembers = ({ isShowing, setIsShowing, orgId, existingMembers }: Props) => {
  const classes = useStyles();
  const [roles, setRoles] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const { data, loading } = useQuery(getOrganizationRoles);

  const [inviteUsersToOrganization] = useMutation(updateOrganizationMembers, {
    refetchQueries: ['searchOrgUsers'],
  });

  const processEmailString = (emailString: string, setFieldValue) => {
    const emailArray = getParsedEmails(emailString);
    setFieldValue('parsedEmails', _.uniq(emailArray));
  };

  const { run: processEmailsDebounced } = useDebounceFn(processEmailString, {
    wait: 500,
  });

  useEffect(() => {
    if (!data) return;
    setRoles(data.roles);
  }, [data]);

  const { md, lg } = useResponsive();

  return (
    <Drawer
      visible={isShowing && !loading && !!roles}
      width={lg ? '35%' : md ? '50%' : '70%'}
      destroyOnClose={true}
      style={{ overflow: 'hidden' }}
      onClose={() => setIsShowing(false)}
    >
      {submitting ? (
        <Loading />
      ) : (
        <div className={classes.container}>
          <Typography variant='h3'>Invite team members</Typography>
          <Formik
            initialValues={{
              emails: '',
              roleId: '',
              parsedEmails: [],
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
              setSubmitting(true);
              try {
                await inviteUsersToOrganization({
                  variables: {
                    id: orgId,
                    members: [
                      ...values.parsedEmails?.map((email) => ({
                        roleId: values.roleId,
                        email,
                      })),
                      ...existingMembers?.map((member) => ({
                        userId: member.id,
                        email: member.email,
                        roleId: member.organizationRole.id,
                      })),
                    ],
                    preserveServiceAccounts: true,
                  },
                });
                message.success('Invitations sent!');
                resetForm();
              } catch (err) {
                message.error(err);
              }
              setSubmitting(false);
              setIsShowing(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              handleReset,
            }) => {
              return (
                <form style={{ height: '90%' }} onSubmit={handleSubmit}>
                  <div className={classes.flexCol}>
                    <div>
                      <div className={classes.subtitleContainer}>
                        <Typography style={{ fontWeight: 'bold' }}>
                          Type or paste in email addresses for you team members
                        </Typography>
                      </div>
                      <div style={{ width: '100%', marginBottom: '10px' }}>
                        <Input.TextArea
                          value={values.emails}
                          name='emails'
                          id='emails'
                          rows={10}
                          onChange={(e) => {
                            processEmailsDebounced(e.target.value, setFieldValue);
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        />
                      </div>
                      {values.parsedEmails.length > 0 && (
                        <ValidEmailSection
                          classes={classes}
                          emails={values.parsedEmails}
                        />
                      )}
                      <RolePicker
                        onChange={(e) => {
                          setFieldValue('roleId', e);
                        }}
                        value={values.roleId}
                        roles={roles}
                      />
                      {!_.isEmpty(errors) &&
                        Object.values(errors).map((errorMessage, i) => {
                          if (touched.emails || values.parsedEmails.length) {
                            return (
                              <Box key={`error-${i}`} mt={2}>
                                <Typography
                                  style={{
                                    fontWeight: 'lighter',
                                    color: Colors.Palette.Error,
                                  }}
                                >
                                  {errorMessage}
                                </Typography>
                              </Box>
                            );
                          } else {
                            return null;
                          }
                        })}
                    </div>
                    <div className={classes.footer}>
                      <Button
                        className={classes.buttonSecondary}
                        onClick={() => {
                          setIsShowing(false);
                          handleReset();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className={classes.buttonPrimary}
                        type='submit'
                        disabled={!_.isEmpty(errors)}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      )}
    </Drawer>
  );
};

const useStyles = makeStyles({
  buttonPrimary: {
    marginRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    textDecoration: 'none',
    backgroundImage: primaryGradient,
    color: 'white',
    width: theme.spacing(50),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    borderRadius: theme.spacing(40),
    marginTop: '1rem',
  },
  buttonSecondary: {
    marginRight: theme.spacing(4),
    fontWeight: theme.typography.fontWeightMedium,
    textDecoration: 'none',
    color: theme.palette.primary.main,
    width: theme.spacing(50),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    border: '1px solid',
    borderRadius: theme.spacing(40),
    borderColor: theme.palette.primary.main,
    marginTop: '1rem',
  },
  container: {
    padding: '1.5rem',
    height: '100%',
  },
  subtitleContainer: { marginBottom: '1rem' },
  row: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    width: '100%',
  },
  footer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  validEmails: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  flexCol: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
});

export default AddTeamMembers;
