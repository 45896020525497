// @ts-strict-ignore
import { Route, Switch } from 'react-router';
import { ViewerFragmentFragment } from 'types';
import { Box } from '@material-ui/core';
import { Pending } from './Pending';
import { getSettingsTypePath } from '..';
import IntegrationSettings from './IntegrationSettings';

export const getIntegrationsPath = () => {
  return getSettingsTypePath('integrations');
};

export const getPendingPath = () => {
  return `${getIntegrationsPath()}/awaiting`;
};

const Integrations = ({ viewer }: { viewer: ViewerFragmentFragment }) => {
  return (
    <Box>
      <Switch>
        <Route exact path={getIntegrationsPath()}>
          <IntegrationSettings viewer={viewer} />
        </Route>
        <Route path={getPendingPath()}>
          <Pending viewer={viewer} />
        </Route>
      </Switch>
    </Box>
  );
};

export default Integrations;
