// @ts-strict-ignore
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EventIcon from '@material-ui/icons/Event';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import { getTotalGuests, getEmailsOfGuests } from 'lib/helpers/booking';
import _ from 'lodash';
import { DateTime } from 'luxon';
import mixpanel from 'mixpanel-browser';
import React, { useContext } from 'react';
import theme, { selectedOptionBackground } from 'theme';
import { EventForSelection } from 'types/selfServeFlow';
import { useViewer } from 'utils/state';
import { SelfServeContext } from '../../../providers/SelfServeProvider';
import GuestDrawer from '../UploadGuests/GuestDrawer';
import { audienceToGuests } from '../UploadGuests';

const useStyles = makeStyles({
  selectableOptionTime: {
    fontSize: '14px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
  },
  optionDate: {
    minWidth: '210px',
  },
  selectableOptionSubtitle: {
    display: 'flex',
    color: '#616161',
    fontSize: '0.6rem',
    alignItems: 'center',
  },
});

export interface EventTimeTileProps {
  event: EventForSelection;
  isSelected: boolean;
  onClickEventTime(): void;
  isRequestToBook: boolean;
  fullDate?: boolean;
}

const EventTimeTile = ({
  event,
  onClickEventTime: onClick,
  isSelected,
  isRequestToBook,
  fullDate,
}: EventTimeTileProps) => {
  const startTime = event.start;
  const [guestDrawerOpen, setGuestDrawerOpen] = React.useState(false);
  const viewer = useViewer();
  const { state, setState } = useContext(SelfServeContext);
  const invitedSet = new Set(getEmailsOfGuests({ guests: state.uploadGuests.guests }));
  const emails = !_.isEmpty(event.emails)
    ? event.emails.filter((email) => invitedSet.has(email))
    : undefined;
  const emailsNotOnProvider = state.eventDate?.emailsNotOnProvider;
  const totalGuests = getTotalGuests({ guests: state.uploadGuests.guests });
  const totalGuestsMinusEmailsNotOnProvider = totalGuests - _.size(emailsNotOnProvider);
  const fullAttendance = totalGuestsMinusEmailsNotOnProvider === _.size(emails);
  const setAudience = (audience) => {
    setState({
      ...state,
      uploadGuests: {
        ...state.uploadGuests,
        guests: audienceToGuests(audience),
      },
    });
  };
  const classes = useStyles();
  return (
    <Box
      onClick={() => {
        mixpanel.track('time selected', {
          selectedTime: DateTime.fromISO(startTime).toLocaleString(DateTime.TIME_SIMPLE),
          source: 'event selection',
          customerType: viewer?.customerType,
          firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
        });
        onClick();
      }}
      style={{
        border: `1px solid ${theme.palette.grey[500]}`,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
        borderRadius: 5,
        marginBottom: theme.spacing(2),
        backgroundColor: isSelected && selectedOptionBackground,
        cursor: 'pointer',
      }}
    >
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 24,
            width: 24,
            borderRadius: '50%',
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          <div
            style={{
              height: 18,
              width: 18,
              borderRadius: '50%',
              backgroundColor: isSelected && theme.palette.primary.main,
            }}
          ></div>
        </div>

        <div
          className={classes.selectableOptionTime}
          style={{ marginLeft: theme.spacing(4) }}
        >
          <div className={classes.optionDate}>
            {DateTime.fromISO(startTime).toLocaleString({
              ...(fullDate ? { weekday: 'short', month: 'short', day: '2-digit' } : {}),
              minute: '2-digit',
              hour: '2-digit',
              timeZoneName: 'short',
            })}
          </div>
          <div className={classes.selectableOptionSubtitle}>
            <Typography
              variant='caption'
              style={{
                marginRight: theme.spacing(0),
                whiteSpace: 'nowrap',
                fontSize: '14px',
                fontWeight: 500,
                color: theme.palette.primary.main,
              }}
            >
              {isRequestToBook ? 'Request To Book' : 'Instant Book'}
            </Typography>
            {isRequestToBook ? (
              <EventIcon color='primary' style={{ fontSize: 20 }} />
            ) : (
              <FlashOnIcon color='primary' style={{ fontSize: 20 }} />
            )}
          </div>
        </div>
      </Box>
      {guestDrawerOpen && (
        <GuestDrawer
          emailsNotOnProvider={emailsNotOnProvider}
          attendeeEmails={emails}
          organization={_.first(viewer.orgs)}
          setGuests={(guests) =>
            setState((prevState) => ({
              ...prevState,
              uploadGuests: { ...prevState.uploadGuests, guests },
            }))
          }
          setAudience={setAudience}
          currentGuests={state.uploadGuests?.guests}
          closeDrawer={() => setGuestDrawerOpen(false)}
          drawerOpen={guestDrawerOpen}
        />
      )}
      {!!emails && (
        <Box style={{ display: 'flex', marginLeft: '0.7rem' }}>
          {fullAttendance ? (
            <Box onClick={() => setGuestDrawerOpen(true)}>
              <Typography
                variant='body2'
                style={{
                  whiteSpace: 'nowrap',
                  fontSize: '14px',
                  fontWeight: 500,
                  color: theme.palette.common.black,
                  opacity: '.6',
                }}
              >
                All available 🎉
              </Typography>
            </Box>
          ) : (
            <Box
              onClick={() => setGuestDrawerOpen(true)}
              style={{
                color: theme.palette.grey[800],
                textDecoration: 'underline',
              }}
            >
              <Typography
                style={{
                  fontSize: '14px',
                  fontWeight: 500,
                  color: theme.palette.common.black,
                  opacity: '.6',
                }}
              >{`${_.size(
                emails,
              )}/${totalGuestsMinusEmailsNotOnProvider} guests available`}</Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default EventTimeTile;
