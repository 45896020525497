import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { makeStyles } from '@material-ui/styles';
import { Upload } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';
import { AnswerProps } from '.';
import config from '../../../config';

const useStyles = makeStyles({
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  imageWrapper: { alignSelf: 'center' },
  image: {
    width: '100%',
  },
});

const ImageAnswer = ({ answer, setAnswer }: AnswerProps) => {
  const [loading, setLoading] = useState(false);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
    }

    if (info.file.status === 'done') {
      const url = _.get(info, 'file.response.data.url');
      setAnswer(url);
      setLoading(false);
    }
  };

  const classes = useStyles();

  return (
    <div className={classes.flexCol}>
      <div className={classes.imageWrapper}>
        <Upload
          name='image'
          listType='picture-card'
          showUploadList={false}
          action={`${config.api.url}/uploadImage`}
          onChange={handleChange}
        >
          {answer ? (
            <img className={classes.image} src={answer} alt='User Uploaded' />
          ) : (
            uploadButton
          )}
        </Upload>
      </div>
    </div>
  );
};

export default ImageAnswer;
