/*

 /\_/\    /\_/\    /\_/\    /\_/\    /\_/\    /\_/\    /\_/\    /\_/\
( o.o )  ( o.o )  ( o.o )  ( o.o )  ( o.o )  ( o.o )  ( o.o )  ( o.o )
 > ^ <    > ^ <    > ^ <    > ^ <    > ^ <    > ^ <    > ^ <    > ^ <


HEY YOU


ARE YOU GOING TO DELETE THIS?


THAT'S GREAT!


REMOVE ALL USAGES OF GLUE_BANNER_HEIGHT and height: -webkit-fill-available
styling from /src/glue/components/navigation/DrawerWrapper.tsx


THERE ARE TODO COMMENTS ON THEM.


K BYE


 */

import { css } from '@emotion/react';
import { Typography } from '@material-ui/core';
import { colors } from '@mysteryco/design';
import ArrowRight from '@mysteryco/design/icons/ArrowRight';

export const GLUE_BANNER_HEIGHT = 36;

// TODO: Actual annoucement URL needed
const GLUE_ANNOUNCEMENT_URL = 'https://glue.co/blog/hello-glue/';

const GlueBanner = () => {
  const { banner, flexAlignCenter, padLeft, pointer, typography } = classes;
  return (
    <div css={[banner, flexAlignCenter]}>
      <Typography style={typography}>Mystery is now Glue.</Typography>
      <span
        css={[flexAlignCenter, pointer, padLeft]}
        onClick={() => window.open(GLUE_ANNOUNCEMENT_URL, '_blank')}
      >
        <Typography style={typography}>
          <b>Read our announcement</b>
        </Typography>
        <ArrowRight css={padLeft} size={14} color={colors.Glue_Darkberry00} />
      </span>
    </div>
  );
};

const classes = {
  banner: css({
    width: '100%',
    background: colors.Glue_Mint00,
    position: 'sticky',
    top: '0px',
    zIndex: 1190,
    justifyContent: 'center',
    padding: '8px',
    color: colors.Glue_Darkberry00,
    height: 36,
    display: 'flex',
  }),
  flexAlignCenter: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }),
  padLeft: css({
    paddingLeft: '4px',
  }),
  pointer: css({ cursor: 'pointer' }),
  typography: { fontSize: '14px', color: colors.Glue_Ink00 },
};

export default GlueBanner;
