import theme from 'theme';

const Clock = ({
  size = 16,
  color = theme.palette.primary.main,
  style = {},
  className = '',
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      style={style}
      viewBox={`0 0 16 16`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.00033 2.00008C4.68662 2.00008 2.00033 4.68637 2.00033 8.00008C2.00033 11.3138 4.68662 14.0001 8.00033 14.0001C11.314 14.0001 14.0003 11.3138 14.0003 8.00008C14.0003 4.68637 11.314 2.00008 8.00033 2.00008ZM0.666992 8.00008C0.666992 3.94999 3.95024 0.666748 8.00033 0.666748C12.0504 0.666748 15.3337 3.94999 15.3337 8.00008C15.3337 12.0502 12.0504 15.3334 8.00033 15.3334C3.95024 15.3334 0.666992 12.0502 0.666992 8.00008ZM8.00033 3.33341C8.36852 3.33341 8.66699 3.63189 8.66699 4.00008V7.58806L10.9651 8.73713C11.2945 8.90179 11.4279 9.30224 11.2633 9.63156C11.0986 9.96088 10.6982 10.0944 10.3688 9.9297L7.70218 8.59637C7.47633 8.48344 7.33366 8.2526 7.33366 8.00008V4.00008C7.33366 3.63189 7.63213 3.33341 8.00033 3.33341Z'
        fill={color}
      />
    </svg>
  );
};

export default Clock;
