// @ts-strict-ignore
import { gql } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BoldText from 'components/core/BoldText';
import { pluralize } from 'humanize-plus';
import {
  CONTRACT_UNIT_PHRASE_LOOKUP,
  CONTRACT_UNIT_FORMAT_LOOKUP,
} from 'lib/helpers/contract';
import { centsToDollarString, getEventCost } from 'lib/helpers/money';
import {
  ContractUnitType,
  GetTeamEventQuery,
  LineItemCategory,
  TeamEventStatus,
  useGetEventCostEstimateQuery,
} from 'types';
import DetailSection from './DetailSection';
import { getContractName } from 'utils/contract';

interface EventCostInfo {
  unitsUsed?: number;
  premiumUnitsUsed?: number;
  overageCents?: number;
  totalCents?: number;
  taxLineItemCents?: number;
  type?: ContractUnitType;
  showInvoiceReminder: boolean;
}

type EventPrice = GetTeamEventQuery['teamEvent']['finalizedPrice'];

const getCostFromFinalPrice = (price: EventPrice): EventCostInfo => {
  const contractLineItem = price?.lineItems?.find(
    (li) => li.category === LineItemCategory.Contract,
  );
  const taxLineItem = price?.lineItems?.find(
    (li) => li.category === LineItemCategory.Tax,
  );
  const contract = contractLineItem?.contract;

  return {
    unitsUsed: contractLineItem?.contractUnits ?? 0,
    premiumUnitsUsed: contractLineItem?.contractPremiumUnits ?? 0,
    overageCents: contractLineItem?.cents ?? 0,
    totalCents: price.totalCents,
    type: contract?.type,
    taxLineItemCents: taxLineItem?.cents ?? 0,
    showInvoiceReminder: false,
  };
};

const LineItem = ({ label, value }) => {
  const classes = useStyles();
  return (
    <Box className={classes.lineItem}>
      <BoldText>{label}</BoldText>
      <Typography>{value}</Typography>
    </Box>
  );
};

const NoPriceSummary = ({
  experience,
  teamEvent,
  useUpgrade,
}: {
  experience: GetTeamEventQuery['teamEvent']['experience'];
  teamEvent: GetTeamEventQuery['teamEvent'];
  useUpgrade: boolean;
}) => {
  const classes = useStyles();
  const contract = teamEvent.contract;

  return (
    <DetailSection label='Event Cost'>
      <Box className={classes.container}>
        <BoldText noWrap={false}>
          {getEventCost({
            contract,
            cost: experience?.cost,
            headCount: teamEvent.expectedHeadCount,
            useUpgrade,
          })}
        </BoldText>
      </Box>
    </DetailSection>
  );
};

const FreeEvent = () => {
  const classes = useStyles();
  return (
    <DetailSection label='Event Cost'>
      <Typography className={classes.container}>
        <b>Free! 🎉</b>
      </Typography>
    </DetailSection>
  );
};

const FinalPriceSummary = ({
  experience,
  minimumSize,
  price,
  teamEvent,
  useUpgrade,
}: {
  experience: GetTeamEventQuery['teamEvent']['experience'];
  minimumSize: number;
  price: EventPrice;
  teamEvent: GetTeamEventQuery['teamEvent'];
  useUpgrade: boolean;
}) => {
  const costBreakdown = getCostFromFinalPrice(price);
  if (!costBreakdown) {
    return (
      <NoPriceSummary
        teamEvent={teamEvent}
        experience={experience}
        useUpgrade={useUpgrade}
      />
    );
  }

  return (
    <SummaryFromEventCostInfo
      costInfo={costBreakdown}
      minimumSize={minimumSize}
      contract={teamEvent.contract}
    />
  );
};

const SummaryFromEventCostInfo = ({
  costInfo,
  minimumSize,
  contract,
}: {
  costInfo: EventCostInfo;
  minimumSize: number;
  contract?: GetTeamEventQuery['teamEvent']['contract'];
}) => {
  const {
    unitsUsed,
    premiumUnitsUsed,
    overageCents,
    totalCents,
    taxLineItemCents = 0,
    type,
    showInvoiceReminder,
  } = costInfo;
  const classes = useStyles();

  return (
    <DetailSection label='Event Cost'>
      <Box className={classes.container}>
        {!!contract && <LineItem label='Contract' value={getContractName(contract)} />}
        {unitsUsed > 0 && (
          <LineItem
            label={`Used contract ${pluralize(
              unitsUsed,
              CONTRACT_UNIT_PHRASE_LOOKUP[type],
            )}`}
            value={CONTRACT_UNIT_FORMAT_LOOKUP[type]?.(unitsUsed)}
          />
        )}
        {premiumUnitsUsed > 0 && (
          <LineItem label='Contract supply & upgrade credits' value={premiumUnitsUsed} />
        )}
        {overageCents > 0 && (
          <LineItem
            label='Costs not covered by contract'
            value={centsToDollarString(overageCents)}
          />
        )}
        {totalCents > 0 && (
          <>
            <LineItem
              label='Subtotal'
              value={centsToDollarString(totalCents - taxLineItemCents)}
            />
            <LineItem label='Tax' value={centsToDollarString(taxLineItemCents)} />
            <LineItem label='Total' value={centsToDollarString(totalCents)} />
          </>
        )}
        {showInvoiceReminder && (
          <Typography>
            {`Final invoice will be based on the number of guests that RSVP. This event has a minimum requirement of ${minimumSize} guests.`}
          </Typography>
        )}
      </Box>
    </DetailSection>
  );
};

const EventCost = ({
  teamEvent,
  experience,
  minimumEventSize,
}: {
  teamEvent: GetTeamEventQuery['teamEvent'];
  experience: GetTeamEventQuery['teamEvent']['experience'];
  minimumEventSize: number;
}) => {
  const { data, loading } = useGetEventCostEstimateQuery({
    variables: {
      teamEventId: teamEvent.id,
    },
  });

  if (teamEvent.freeEvent) {
    return <FreeEvent />;
  }

  const useUpgrade = experience?.hasPhysicalGoods || experience?.requiresUpgrade;
  const { initialPrice, finalizedPrice, status } = teamEvent;

  if (!(finalizedPrice || initialPrice)) {
    return (
      <NoPriceSummary
        teamEvent={teamEvent}
        experience={experience}
        useUpgrade={useUpgrade}
      />
    );
  }

  const isFinalPrice =
    !!finalizedPrice ||
    [TeamEventStatus.Complete, TeamEventStatus.InvoiceSent].includes(status);

  if (isFinalPrice) {
    const price = finalizedPrice || initialPrice;
    return (
      <FinalPriceSummary
        experience={experience}
        minimumSize={minimumEventSize}
        price={price}
        teamEvent={teamEvent}
        useUpgrade={useUpgrade}
      />
    );
  }

  if (!data || loading) return null;

  // This query can return null if the user is not an org Admin or Coordinator,
  // if that happens, show the "No Price Summary"
  if (data.estimatePriceForTeamEvent === null) {
    return (
      <NoPriceSummary
        teamEvent={teamEvent}
        experience={experience}
        useUpgrade={useUpgrade}
      />
    );
  }

  const type = data.estimatePriceForTeamEvent?.contract?.type;
  const eventBased = type === ContractUnitType.Event;
  const costInfoFromEstimate = {
    unitsUsed: data.estimatePriceForTeamEvent?.contractUnitsUsed,
    premiumUnitsUsed: data.estimatePriceForTeamEvent?.contractPremiumUnitsUsed,
    overageCents: data.estimatePriceForTeamEvent?.contractOverageCents,
    totalCents: data.estimatePriceForTeamEvent?.estimatedTotalCents,
    type,
    showInvoiceReminder: !eventBased,
  } as EventCostInfo;

  return (
    <SummaryFromEventCostInfo
      costInfo={costInfoFromEstimate}
      minimumSize={minimumEventSize}
      contract={teamEvent.contract}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& *': {
      fontSize: '.875rem',
    },
  },
  lineItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '.875rem',
  },
}));

export default EventCost;

EventCost.estimate = gql`
  query GetEventCostEstimate($teamEventId: ID!) {
    estimatePriceForTeamEvent(teamEventId: $teamEventId) {
      contract {
        id
        type
      }
      contractUnitsUsed
      contractPremiumUnitsUsed
      contractOverageCents
      estimatedTotalCents
    }
  }
`;
