import { gql } from '@apollo/client';
import { css } from '@emotion/react';
import { MetricCard, colors } from '@mysteryco/design/src';
import * as Progress from '@radix-ui/react-progress';
import * as Separator from '@radix-ui/react-separator';
import { Loading } from 'components/core';
import ColorblindToggle from 'glue/components/accessibility/ColorblindToggle';
import PatternedBox from 'glue/components/accessibility/PatternedBox';
import {
  Audience,
  AudienceBuilder,
  AudienceList,
  AudienceTypeEnum,
  getUniqUserCount,
  getUniqUsers,
} from 'glue/components/inputs/audience';
import PrivacyShield from 'glue/scenes/Explore/Pulse/PrivacyShield';
import FriendshipDistributionGraph from 'glue/scenes/Explore/Pulse/questions/FriendshipDistributionGraph';
import { patternsBySentiment } from 'glue/scenes/Explore/Pulse/questions/patternsBySentiment';
import {
  usePulseTimeframes,
  useSelectedTimeframeFilter,
} from 'glue/scenes/Explore/Pulse/timeframes';
import { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import theme from 'theme';
import {
  MoralePulseQuestionType,
  MoralePulseSurveyReportingTimeframe,
  ResultItemBucketFragment,
  useQuestionResponseSummaryQuery,
} from 'types';
import { useViewer } from 'utils/state';
import TimeframeFilter from '../TimeframeFilter';
import FiltersContainer from './FiltersContainer';
import DeepDiveLineChart from './components/DeepDiveLineChart';
import { EdgeCaseScreen } from 'glue/scenes/MoralePulseSurvey/EdgeScreens';

const strings = {
  trends: 'TRENDS',
  results: 'RESULTS',
  boxScore: 'Question score',
  boxAvg: 'Avg response rate',
  boxSurveys: 'Surveys within timeframe',
  showDist: 'Show distribution',
  comparedTo: 'Compared to',
};
export function QuestionResponseSummary({
  match,
}: RouteComponentProps<{ questionId: string }>) {
  const viewer = useViewer();
  const [timeframe, setTimeframe] = useSelectedTimeframeFilter();
  const timeframes = usePulseTimeframes();
  const [audience, setAudience] = useState<Audience>({});

  const orgId = viewer?.orgs[0]?.id || '';
  const handleTimeframeChange = (timeframe: MoralePulseSurveyReportingTimeframe) => {
    setTimeframe({ ...timeframe });
  };
  const audienceSize = getUniqUserCount(audience);
  const audienceTooSmall = 0 < audienceSize && audienceSize < 5;

  const { questionId } = match.params;

  return (
    <div css={styles.root}>
      <FiltersContainer>
        <TimeframeFilter
          onChange={handleTimeframeChange}
          value={timeframe}
          options={timeframes}
        />
        <AudienceBuilder
          audience={audience}
          setAudience={setAudience}
          organizationId={orgId}
          hideList
          allowedSearchTerms={[AudienceTypeEnum.TAG, AudienceTypeEnum.TEAM]}
          css={styles.audienceBuilder}
          variant='compact'
          placeholderActionText='Filter'
        />
      </FiltersContainer>
      <div>
        <AudienceList audience={audience} onAudienceChange={setAudience} />
      </div>
      {audienceTooSmall ? (
        <PrivacyShield />
      ) : timeframe ? (
        <QuestionResponseDisplay
          timeframe={timeframe}
          audience={audience}
          questionId={questionId}
        />
      ) : (
        <EdgeCaseScreen primary='Select a timeframe to view results' />
      )}
    </div>
  );
}

const ResultItem = ({
  bucket: { label, percentValue, sentiment },
}: {
  bucket: ResultItemBucketFragment;
}) => {
  const percentage = Math.round(percentValue * 100);

  return (
    <div css={styles.resultItem}>
      <div css={styles.resultItemInfo}>
        <div css={styles.resultItemLeft}>
          <span css={styles.resultItemOption}>{label}</span>
        </div>
        <div css={styles.resultItemPercentage}>{`${percentage}%`}</div>
      </div>
      <Progress.Root css={styles.progressRoot} value={percentage}>
        <Progress.Indicator
          asChild
          css={styles.progressIndicator}
          style={{
            transform: `translateX(-${100 - percentage}%)`,
          }}
        >
          <PatternedBox pattern={patternsBySentiment[sentiment]} />
        </Progress.Indicator>
      </Progress.Root>
    </div>
  );
};
ResultItem.fragment = gql`
  fragment ResultItemBucket on MoralePulseAnswerSummaryBucket {
    label
    sentiment
    percentValue
  }
`;

const QuestionResponseDisplay = ({
  timeframe,
  audience,
  questionId,
}: {
  timeframe: MoralePulseSurveyReportingTimeframe;
  audience: Audience;
  questionId: string;
}) => {
  const { data, loading } = useQuestionResponseSummaryQuery({
    variables: {
      audienceIds: getUniqUsers(audience).map((user) => user.id),
      timeframe: timeframe
        ? {
            startTime: timeframe.startDate,
            endTime: timeframe.endDate,
          }
        : undefined,
      questionId,
    },
  });

  if (loading) return <Loading />;

  const questionData = data?.moralePulseQuestionSummary?.answerSummary;
  const {
    averageResponseValue = 0,
    question,
    highestPossibleValue = 0,
  } = questionData ?? {};
  const summaryData = data?.moralePulseSurveyParticipationSummary;
  const { responseRate = 0, surveyIds = [] } = summaryData ?? {};
  let value: string | undefined = undefined;
  if (question?.type === MoralePulseQuestionType.Nps) {
    value = `${averageResponseValue.toFixed(0)}`;
  } else if (question?.type === MoralePulseQuestionType.Friendship) {
    value = `${Math.ceil(averageResponseValue)}`;
  } else {
    value = `${averageResponseValue.toFixed(1)}`;
  }

  return (
    <>
      <div css={styles.topLine}>
        <div css={styles.subtitle}>{strings.trends}</div>
      </div>
      <div css={styles.summary}>
        <MetricCard
          headline={strings.boxScore}
          value={value}
          // I wasn't sure this was going to work but I'm very pleased that it does
          subValue={
            (question?.type === MoralePulseQuestionType.Nps && ` `) ||
            (question?.type === MoralePulseQuestionType.Friendship &&
              `friends on average`) ||
            `out of ${highestPossibleValue}`
          }
        />
        <MetricCard
          headline={strings.boxAvg}
          value={`${Math.round(responseRate * 100)}%`}
        />
        <MetricCard headline={strings.boxSurveys} value={`${surveyIds.length}`} />
      </div>
      <DeepDiveLineChart
        audience={audience}
        timeframe={timeframe}
        questionId={questionId}
        highestPossibleValue={highestPossibleValue}
      />
      <div css={styles.subtitleRow}>
        <div css={styles.subtitle}>{strings.results}</div>
        <ColorblindToggle />
      </div>
      <Separator.Root css={styles.seperator} />
      {questionData &&
      questionData.question.type === MoralePulseQuestionType.Friendship ? (
        <FriendshipDistributionGraph buckets={questionData.buckets ?? []} />
      ) : (
        questionData?.buckets
          ?.map((bucket, i) => <ResultItem bucket={bucket} key={bucket.label} />)
          .reverse()
      )}
    </>
  );
};

QuestionResponseSummary.query = gql`
  query QuestionResponseSummary(
    $audienceIds: [ID!]!
    $timeframe: DateWindowInput
    $questionId: ID!
  ) {
    moralePulseQuestionSummary(questionId: $questionId) {
      id
      answerSummary(timeframe: $timeframe, audienceIds: $audienceIds) {
        id
        question {
          id
          type
        }
        lowestPossibleValue
        highestPossibleValue
        averageResponseValue
        prevAverageResponseValue
        questionTrend
        buckets {
          ...ResultItemBucket
          ...FriendshipDistributionGraphBucket
        }
      }
    }
    moralePulseSurveyParticipationSummary(
      timeframe: $timeframe
      audienceIds: $audienceIds
    ) {
      surveyIds
      responseRate
    }
  }

  ${ResultItem.fragment}
  ${FriendshipDistributionGraph.fragment}
`;

const styles = {
  root: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
    maxWidth: '60rem',
  }),
  toggleSection: css({
    display: 'flex',
    gap: theme.spacing(3),
  }),
  topLine: css({
    display: 'flex',
    justifyContent: 'space-between',
  }),
  resultItem: css({
    display: 'flex',
    gap: theme.spacing(5),
  }),
  resultItemLeft: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  resultItemInfo: css({
    minWidth: '25%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    gap: theme.spacing(8),
  }),
  resultItemOption: css({
    color: colors.Glue_Ink00,
    fontWeight: 800,
    fontSize: 16,
    lineHeight: '150%',
    letterSpacing: 0.25,
    maxWidth: '150px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  resultItemPercentage: css({
    color: colors.Coal_20,
    fontFamily: 'Manrope',
    fontWeight: 500,
    fontSize: 20,
    lineHeight: 1,
  }),
  resultItemRespondents: css({
    color: colors.Coal_20,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '150%',
  }),
  subtitleRow: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  subtitle: css({
    color: colors.Coal_20,
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '140%',
    letterSpacing: '0.1em',
  }),
  summary: css({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(3),

    '& > div': {
      flex: '1 0 0',
    },
  }),
  seperator: css({
    height: '1px',
    backgroundColor: colors.Glue_Ink30,
  }),
  progressRoot: css({
    overflow: 'hidden',
    flex: 1,
    background: colors.Glue_LavenderLight,
  }),
  progressIndicator: css({
    width: '100%',
    height: '100%',
    transition: 'transform 660ms cubic-bezier(0.65, 0, 0.35, 1)',
  }),
  audienceBuilder: css({ flex: 1 }),
};

export default QuestionResponseSummary;
