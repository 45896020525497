import { Props } from './types';
import { User } from '../../types';
import * as yup from 'yup';
import { redirectAndSaveSSFState } from 'lib/helpers/login';
import config from 'config';

export const title = 'Log In';
export const key = 'login';

export const Content = ({ globalState }: Props<Partial<User>>) => {
  redirectAndSaveSSFState({
    globalState,
    fromLoginStep: true,
    redirectToUrl: config.auth.url,
  });

  return <h2>One moment while we get you logged in...</h2>;
};

export const schema = yup.object({});
