import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import RegistrationStepWrapper, {
  RegistrationStepWrapperProps,
} from 'components/Checkout/styling/RegistrationStepWrapper';
import Stars from 'components/icons/Stars';

const useStyles = makeStyles({
  starsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '15rem',
  },
});

interface Props extends RegistrationStepWrapperProps {}

const WizardConfirmation = ({
  label,
  subTitle,
  nextButtonProps,
  ...otherProps
}: Props) => {
  const classes = useStyles();
  return (
    <RegistrationStepWrapper
      label={label}
      subTitle={subTitle}
      nextButtonProps={nextButtonProps}
      {...otherProps}
    >
      <Box className={classes.starsContainer}>
        <Stars />
      </Box>
    </RegistrationStepWrapper>
  );
};

export default WizardConfirmation;
