import styled from '@emotion/styled';
import theme from 'theme';

const FiltersContainer = styled('div')({
  display: 'flex',
  gap: theme.spacing(3),
  padding: `${theme.spacing(4)}px 0`,
});

export default FiltersContainer;
