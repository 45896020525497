// @ts-strict-ignore
import { Box } from '@material-ui/core';
import RegistrationStepWrapper from 'components/Checkout/styling/RegistrationStepWrapper';
import CheckboxesAnswer from 'components/Questionnaire/Answer/Checkboxes';
import DropDownAnswer from 'components/Questionnaire/Answer/DropDown';
import { EventReview } from 'components/Questionnaire/Answer/EventReview';
import ImageAnswer from 'components/Questionnaire/Answer/Image';
import LimitedCharTextAnswer from 'components/Questionnaire/Answer/LimitedCharText';
import MultipleChoiceAnswer from 'components/Questionnaire/Answer/MultipleChoice';
import PeopleChoice from 'components/Questionnaire/Answer/PeopleChoice';
import RelationshipChoice from 'components/Questionnaire/Answer/RelationshipChoice';
import ScaleAnswer from 'components/Questionnaire/Answer/Scale';
import TextAnswer from 'components/Questionnaire/Answer/Text';
import { client } from 'gql/client';
import { completeQuestionnaire } from 'gql/mutations';
import { recursivelyRemoveTypenames } from 'lib/helpers/apollo';
import { isExemptFromQuestions, isInstructor, isRequester } from 'lib/helpers/events';
import _ from 'lodash';
import { Step } from 'react-albus';

import { InternalRefetchQueriesInclude } from '@apollo/client';
import { AnswerProps } from 'components/Questionnaire/Answer';
import { PreEventQuestionnaire } from 'scenes/Checkout/NormalFlow';
import {
  Answer,
  AnswerInput,
  CompleteQuestionnaireMutation,
  CompleteQuestionnaireMutationVariables,
  QuestionType,
  TeamEventFragmentFragment,
  ViewerFragmentFragment,
} from 'types';

const AnswerComponents = {
  [QuestionType.DropDown]: (props: AnswerProps) => <DropDownAnswer {...props} />,
  [QuestionType.Image]: (props: AnswerProps) => <ImageAnswer {...props} />,
  [QuestionType.LimitedCharText]: (props: AnswerProps) => (
    <LimitedCharTextAnswer {...props} />
  ),
  [QuestionType.MultipleChoice]: (props: AnswerProps) => (
    <MultipleChoiceAnswer {...props} />
  ),
  [QuestionType.Text]: (props: AnswerProps) => <TextAnswer {...props} />,
  [QuestionType.Scale]: (props: AnswerProps) => <ScaleAnswer {...props} />,
  [QuestionType.Checkboxes]: (props: AnswerProps) => <CheckboxesAnswer {...props} />,
  [QuestionType.PeoplePicker]: (props: AnswerProps) => <PeopleChoice {...props} />,
  [QuestionType.PeopleRater]: (props: AnswerProps) => <RelationshipChoice {...props} />,
  [QuestionType.EventReview]: (props: AnswerProps) => <EventReview {...props} />,
};

export type QuestionnaireAnswerType = {
  answer: Omit<Answer, 'id' | 'createdAt' | 'updatedAt'>;
  questionnaire: { id: string };
  question: { id: string };
};
interface Props {
  teamEvent: TeamEventFragmentFragment;
  questionnaires: PreEventQuestionnaire[];
  viewer: ViewerFragmentFragment;
  answers: QuestionnaireAnswerType[];
  setAnswers(a: QuestionnaireAnswerType[]): void;
  refetchQueries?: InternalRefetchQueriesInclude;
}

const questionnaireAnswerTypeToAnswerInput = ({
  answer,
  question,
  questionnaire,
}: QuestionnaireAnswerType): AnswerInput => {
  return {
    answer: answer?.answer,
    question,
    questionnaire,
  };
};

const submitQuestionnaire = async ({
  answers,
  viewer,
  refetchQueries = [],
}: {
  answers: AnswerInput[];
  viewer: ViewerFragmentFragment;
  refetchQueries: InternalRefetchQueriesInclude;
}) => {
  // Convert checkbox values to answers
  await client.mutate<
    CompleteQuestionnaireMutation,
    CompleteQuestionnaireMutationVariables
  >({
    mutation: completeQuestionnaire,
    variables: {
      answers: recursivelyRemoveTypenames(answers),
      userId: viewer.id,
    },
    refetchQueries,
  });
};

export const renderQuestionnairesStep = ({
  teamEvent,
  questionnaires,
  viewer,
  answers,
  setAnswers,
  refetchQueries,
}: Props) => {
  if (isInstructor({ teamEvent, viewer }) || isExemptFromQuestions(teamEvent)) return [];

  return questionnaires?.map((questionnaire) => {
    const withoutRequesterOnly = isRequester({ teamEvent, viewer })
      ? questionnaire.questions
      : questionnaire.questions.filter((q) => !q.requesterOnly);
    const viewableQuestions = withoutRequesterOnly.filter((q) => {
      if (_.isNil(teamEvent?.organization?.activeContract)) {
        return !q.contractOnly;
      } else {
        return !q.transactionalOnly;
      }
    });
    const answersForUser = _.map(viewableQuestions, (q) => ({
      answer: q.answerForUser,
      questionnaire: _.pick(questionnaire, 'id'),
      question: _.pick(q, 'id'),
    }));

    if (!answers) {
      setAnswers(answersForUser);
    }

    const answersByQuestionId = answers?.reduce((acc, answer) => {
      acc[answer?.question?.id] = answer.answer;
      return acc;
    }, {} as { [key: string]: QuestionnaireAnswerType['answer'] });

    return _.orderBy(viewableQuestions, 'orderIndex').map((question, i: number) => {
      const isLast = i === viewableQuestions.length - 1;
      const AnswerComponent = AnswerComponents[question.questionType] as (
        props: AnswerProps,
      ) => JSX.Element;
      const foundAnswer = answersByQuestionId?.[question.id];

      return (
        <Step
          key={question.id}
          id={`questionnaire/${questionnaire.id}/question/${question.id}`}
          render={(context) => (
            <RegistrationStepWrapper
              {...context}
              label={question.question}
              subTitle={question.subtitle}
              onClickNext={async () => {
                if (isLast) {
                  await submitQuestionnaire({
                    answers: answers
                      ?.map(questionnaireAnswerTypeToAnswerInput)
                      ?.filter((answerInput) => !!answerInput.answer),
                    viewer,
                    refetchQueries,
                  });
                }
              }}
              nextButtonProps={{
                disabled: question.required && !foundAnswer,
              }}
            >
              <Box key={`answer-${question.id}`}>
                <AnswerComponent
                  question={question}
                  questionnaire={questionnaire}
                  answer={foundAnswer?.answer}
                  setAnswer={(newAnswer: string) => {
                    const answersWithoutNewAnswer = _.filter(answers, (a) => {
                      return a.question.id !== question.id;
                    });

                    answersWithoutNewAnswer.push({
                      answer: { answer: newAnswer },
                      questionnaire: _.pick(questionnaire, 'id'),
                      question: _.pick(question, 'id'),
                    });
                    setAnswers(answersWithoutNewAnswer);
                  }}
                />
              </Box>
            </RegistrationStepWrapper>
          )}
        />
      );
    });
  });
};
