// @ts-strict-ignore
import { Box, Typography } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import cx from 'classnames';
import NewPrimaryButton from 'components/core/NewPrimaryButton';
import CalendarDate from 'components/icons/CalendarDate';
import DataIcon from 'components/icons/DataIcon';
import PieProgress from 'components/PieProgress';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';
import { Paths } from 'Routes';
import theme from 'theme';
import {
  NylasConnectionState,
  NylasServiceAccount,
  Organization,
  ViewerFragmentFragment,
} from 'types';

interface CalendarIntegrationProps {
  org: Organization;
  viewer: ViewerFragmentFragment;
  classes: ClassNameMap;
}

export const Calendar = ({ org, viewer, classes }: CalendarIntegrationProps) => {
  const serviceAccounts = org.nylasServiceAccounts;
  const hasServiceAccounts = serviceAccounts?.length > 0;
  return (
    <Box className={classes.mCard}>
      <Box mb={2} className={classes.mCardIcon}>
        <DataIcon color={theme.palette.secondary[600]} height={24} width={24} />
      </Box>
      <Box mb={2}>
        <Typography className={classes.mCardLabel}>Calendar</Typography>
      </Box>
      <Box>
        <Typography variant='h4' className={classes.mCardTitle}>
          Organization Calendar
        </Typography>
      </Box>
      {hasServiceAccounts ? (
        <Box className={classes.mCardHelperText}>
          <ServiceAccount classes={classes} serviceAccounts={serviceAccounts} />
        </Box>
      ) : (
        <NoServiceAccount org={org} viewer={viewer} classes={classes} />
      )}
    </Box>
  );
};

interface ServiceAccountProps {
  serviceAccounts: NylasServiceAccount[];
  classes: ClassNameMap;
}

const ServiceAccount = ({ serviceAccounts, classes }: ServiceAccountProps) => {
  return (
    <Box>
      <Typography className={classes.subtitle}>
        Your organization's calendar has been connected and shared with Glue for automated
        event scheduling and access to engagement insights.
      </Typography>
      <Box mb={8} mt={4}>
        {serviceAccounts.map((serviceAccount) => {
          return (
            <ServiceAccountConnection classes={classes} serviceAccount={serviceAccount} />
          );
        })}
      </Box>
    </Box>
  );
};

const NoServiceAccount = ({
  classes,
  org,
  viewer,
}: {
  classes: ClassNameMap;
  org: Organization;
  viewer: ViewerFragmentFragment;
}) => {
  const history = useHistory();
  const membersWithConnectedCalendars = _.filter(
    org.members,
    (m) => !!m.nylasAssociation,
  );

  const percentageOfCalendarsConnected = Math.floor(
    100 * (membersWithConnectedCalendars.length / org.members.length),
  );

  return (
    <Box>
      <Box mt={6}>
        <Box display='flex' mb={4}>
          <PieProgress percentFilled={percentageOfCalendarsConnected} size={48} />
          <Box ml={4}>
            <Box>
              <Typography display='inline' color='primary' css={{ fontWeight: 700 }}>
                {`${percentageOfCalendarsConnected}% `}
              </Typography>
              <Typography display='inline'>{`of your org's calendars are connected`}</Typography>
            </Box>
            <Typography
              className={cx(classes.statusRowPill, {
                [classes.statusRowPillNegative]: percentageOfCalendarsConnected < 50,
              })}
            >
              {getRecommendationString(percentageOfCalendarsConnected)}
            </Typography>
          </Box>
        </Box>
        {viewer.nylasConnectionState === NylasConnectionState.Inactive && (
          <NewPrimaryButton
            prependedIcon={
              <CalendarDate width={24} height={24} color={theme.palette.common.white} />
            }
            label={'Start by connecting your calendar'}
            onClick={() => history.push(`${Paths.PROFILE}/integrations`)}
            css={{ width: '70%' }}
          />
        )}
      </Box>
      <Box mt={6}>
        <Typography variant='body2' css={{ fontWeight: 700 }} color='textPrimary'>
          Or connect your entire org's calendar at once
        </Typography>
        <Typography variant='body2' css={{ marginTop: theme.spacing(2) }}>
          Contact your Glue Customer Success Manager to enable this integration. Available
          for Google Calendar only.
        </Typography>
      </Box>
    </Box>
  );
};

const ServiceAccountConnection = ({
  serviceAccount,
  classes,
}: {
  serviceAccount: NylasServiceAccount;
  classes: ClassNameMap;
}) => {
  const dateString = DateTime.fromISO(serviceAccount.createdAt).toLocaleString(
    DateTime.DATE_FULL,
  );
  return (
    <Box>
      <Box className={classes.statusRow}>
        <Typography className={classes.statusRowCopy}>Status</Typography>
        <Typography className={classes.statusRowPill}>Active</Typography>
      </Box>
      <Box className={classes.detailsBlock}>
        <Box className={classes.detailsBlockCell}>
          <Typography className={classes.mCardLabel}>Connected to</Typography>
          <Typography className={classes.detailsBlockText}>
            <span>{serviceAccount.provider} Calendar</span>
          </Typography>
        </Box>
        <Box className={classes.detailsBlockCell}>
          <Typography className={classes.mCardLabel}>Connected</Typography>
          <Typography className={classes.detailsBlockText}>{dateString}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

const getRecommendationString = (percentageConnected) => {
  if (percentageConnected === 100) return 'Go Team! 🎉';
  else if (percentageConnected < 50) return `Integration recommended`;
  return `Integration optional`;
};
