import { css } from '@emotion/react';
import { FormControlLabel, FormControlLabelProps, Radio } from '@material-ui/core';
import { colors } from '@mysteryco/design';
import { forwardRef } from 'react';
import theme from 'theme';

export interface RadioButtonProps extends Omit<FormControlLabelProps, 'control'> {}

export const RadioButton = forwardRef<HTMLLabelElement, RadioButtonProps>(
  ({ label, ...props }, ref) => {
    return (
      <FormControlLabel
        ref={ref}
        control={<Radio css={styles.radio} disableRipple />}
        label={<span css={styles.label}>{label}</span>}
        css={styles.root}
        {...props}
      />
    );
  },
);
RadioButton.displayName = 'RadioButton';

export default RadioButton;

const styles = {
  root: css({
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: theme.spacing(3),
    marginLeft: 0,
    marginRight: 0,
  }),
  label: css({
    color: colors.Glue_Ink00,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '24px',
  }),
  radio: css({
    color: colors.Glue_Ink10,
    padding: 0,
    flexShrink: 0,
    '&.Mui-checked': {
      color: colors.Glue_Ink00,
    },
  }),
};
