// @ts-strict-ignore
import { gql } from '@apollo/client';
import * as sentry from '@sentry/browser';
import { uuid4 } from '@sentry/utils';
import { Statsig } from 'statsig-react';

import { client } from 'gql/client';
import { ConfusedSessionObserverQueryResult } from 'types';

const POLL_INTERVAL_MS = 60 * 1000;

const observeViewer = gql`
  query confusedSessionObserver {
    viewer {
      id
      email
    }
  }
`;

interface Deets {
  id?: string;
  email?: string;
}

/**
 * We have a very concerning problem where user sessions get confused: The user
 * retrieved (as viewer) _can change_ between requests!
 *
 * It's not clear what the root cause of this is yet. While we determine that,
 * we want to know whether users are being affected by this problem, and how
 * frequently.
 */
export class ConfusedSessionObserver {
  private static _singleton: ConfusedSessionObserver;
  static init() {
    this._singleton = new this();
  }

  constructor() {
    this._subscribe();
    this._poll();
  }

  /**
   * Listen to cache updates, always - so that we can observe any viewer change
   * from any query.
   */
  private _subscribe() {
    client
      .watchQuery({
        query: observeViewer,
        fetchPolicy: 'cache-only',
      })
      .subscribe(this._onUpdate.bind(this));
  }

  /**
   * We _try_ to poll continuously, but only actually trigger requests to the
   * server if our flag is enabled.
   */
  private async _poll() {
    warn('poll');
    // Only perform queries if our flag is enabled.
    if (Statsig.initializeCalled() && Statsig.checkGate('confused_session_observer')) {
      try {
        await client.query({
          query: observeViewer,
          fetchPolicy: 'network-only',
        });
      } catch (error) {
        warn('query error:', error);
      }
    }

    // But regardless of the flag's status, continue to poll.
    setTimeout(this._poll.bind(this), POLL_INTERVAL_MS);
  }

  private _previous?: Deets;
  private _onUpdate({ data }: ConfusedSessionObserverQueryResult) {
    const viewer: Deets = data?.viewer || {};
    // No change?
    if (this._previous?.id === viewer?.id) return;

    // Are we transitioning from no known user to a known one?
    // (e.g. logged in, or first launch)
    if (!this._previous && viewer?.id) {
      this._previous = { ...viewer };
      return;
    }

    // All the rest of the cases are _possible_ session confusion.
    warn(`viewer transitioned: from ${deets(this._previous)} to ${deets(viewer)}`);

    // However, it could also legitimately be a logout, so lets assume it is
    // such. At least we're logging it out, in case we catch something via
    // FullStory.
    if (this._previous && !viewer?.id) {
      delete this._previous;
      return;
    }

    // Whoop whoop possible session confusion!
    if (this._previous?.id !== viewer?.id) {
      warn('Possible session confusion!');
      sentry.captureEvent({
        // Note that it's important to vary the message so that sentry doesn't
        // drop duplicate looking events.
        message: `Possible confused session`,
        level: sentry.Severity.Error,
        extra: {
          fromUser: this._previous,
          toUser: viewer,
        },
        fingerprint: ['possible-confused-session'],
        // Force each event to be unique
        stacktrace: {
          frames: [
            { filename: 'ConfusedSessionObserver.ts' },
            { filename: `deduping-via:${uuid4()}` },
          ],
        },
      });
      sentry.flush();
    }
  }
}

function deets({ id, email }: Deets = {}) {
  if (!id) return 'no viewer';
  return `${id} (${email})`;
}

function warn(message: string, ...args: unknown[]) {
  console.warn(`[ConfusedSessionObserver] ${message}`, ...args);
}
