// @ts-strict-ignore
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import {
  TeamEventFragmentFragment,
  ViewerFragmentFragment,
  TeamEventStatus,
} from 'types';
import { getEventDetails } from 'utils/state';
import {
  Dusk,
  Main,
  Midnight,
  Purple300,
  Purple50,
} from '@mysteryco/design/src/tokens/colors';
import EventImage from 'components/EventImage';
import EventCalendarImage from 'components/EventCalendarImage';
import GoalIcon from 'components/GoalIcon';
import { goalToString } from 'lib/helpers/goals';
import imageBackup from 'assets/catalog/image-backup.png';
import { eventStatusToString } from 'lib/helpers/teamEventStatus';

const EventSummary = ({
  teamEvent,
  viewer,
}: {
  teamEvent: TeamEventFragmentFragment;
  viewer: ViewerFragmentFragment;
}) => {
  const classes = useStyles();

  const { title, photoUrl, description, experienceName } = getEventDetails({
    teamEvent,
    viewer,
    defaultTitle: 'Event Title',
  });

  const goal = teamEvent?.goals?.length === 1 ? teamEvent.goals[0].goal : null;

  const requestedFor = DateTime.fromISO(teamEvent?.requestedFor);
  const eventDate = requestedFor.toFormat('EEE MMMM d');
  const eventTime = requestedFor.toLocaleString({
    ...DateTime.TIME_WITH_SHORT_OFFSET,
    second: undefined,
  });
  const durationMins = teamEvent.virtualEvent?.durationMins || 60;
  const startTime = requestedFor.toLocaleString(DateTime.TIME_SIMPLE);
  const endTime = requestedFor.plus({ minutes: durationMins }).toLocaleString({
    ...DateTime.TIME_WITH_SHORT_OFFSET,
    second: undefined,
  });

  const status =
    teamEvent.status === TeamEventStatus.InProgress
      ? 'About To Begin!'
      : eventStatusToString(teamEvent.status);

  return (
    <>
      <Box className={classes.header}>
        <Typography className={classes.headerText}>{experienceName}</Typography>
      </Box>
      <Box className={classes.container}>
        {goal && (
          <Box className={classes.goalsContainer}>
            <Box className={classes.goalsSection}>
              <Typography className={classes.eventStatus}>{status}</Typography>
              <Typography className={classes.goalHeading}>Event Goal</Typography>
              <Box className={classes.goalEventContent}>
                <Box>
                  <GoalIcon size={16} goal={goal} />
                </Box>
                <Typography className={classes.goalText}>{goalToString(goal)}</Typography>
              </Box>
            </Box>
          </Box>
        )}
        <Box className={classes.mainContainer}>
          <Box className={classes.imageSection}>
            <EventImage photoUrl={photoUrl || imageBackup} title={title} />
          </Box>
          <Box className={classes.detailContainer}>
            <Box className={classes.detailSection}>
              <Typography className={classes.detailHeading}>What</Typography>
              <Typography className={classes.detailDescription}>{description}</Typography>
            </Box>
            <Box className={classes.detailSection}>
              <Typography className={classes.detailHeading}>When</Typography>
              <Box className={classes.calendarInfo}>
                <EventCalendarImage dateTime={requestedFor} />
                <Box>
                  <Typography className={classes.dateInfo}>
                    {eventDate}, {eventTime}
                  </Typography>
                  <Typography className={classes.timeInfo}>
                    {startTime} to {endTime}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    minHeight: 48,
    marginBottom: theme.spacing(4),
  },
  headerText: {
    fontSize: '1.875rem',
    fontWeight: theme.typography.fontWeightBold,
    color: Dusk,
  },
  container: {
    backgroundColor: Purple50,
  },
  goalsContainer: {
    borderBottom: `1px solid ${Purple300}`,
    '&:first-of-type::before': {
      content: '""',
      display: 'block',
      width: '100%',
      height: 3,
      background: `linear-gradient(90deg, #EB5757 0%, #BE207A 27.6%, #9747FF 68.75%, #7A1783 97.92%)`,
    },
  },
  goalsSection: {
    padding: `${theme.spacing(3)} ${theme.spacing(5)}`,
    position: 'relative',
  },
  goalHeading: {
    textTransform: 'uppercase',
    color: Dusk,
    fontSize: '.875rem',
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 1,
    marginBottom: theme.spacing(1),
  },
  goalEventContent: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  goalText: {
    fontSize: '1.125rem',
    fontWeight: theme.typography.fontWeightBold,
    color: Main,
  },
  eventStatus: {
    display: 'inline-block',
    color: theme.palette.primary.main,
    fontSize: '.7rem',
    fontWeight: 600,
    lineHeight: '1.5rem',
    letterSpacing: '.15rem',
    textTransform: 'uppercase',
    padding: '.2rem 1rem',
    background: theme.palette.primary[100],
    borderRadius: '1rem',
    margin: '1rem 0',
    position: 'absolute',
    top: theme.spacing(6),
    right: theme.spacing(4),
  },
  mainContainer: {
    display: 'flex',
    borderBottom: `1px solid ${Purple300}`,
    '&:first-of-type': {
      borderTop: `1px solid ${Purple300}`,
    },
  },
  imageSection: {
    padding: theme.spacing(5),
    borderRight: `1px solid ${Purple300}`,
    display: 'flex',
    alignItems: 'start',
  },
  detailContainer: {
    width: '100%',
  },
  detailSection: {
    padding: theme.spacing(5),
    '&:not(:last-child)': {
      borderBottom: `1px solid ${Purple300}`,
    },
  },
  detailHeading: {
    color: Main,
    fontFamily: theme.typography.actionLabel.fontFamily,
    textTransform: 'uppercase',
    fontSize: '.75rem',
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 1,
    marginBottom: theme.spacing(1),
  },
  detailDescription: {
    color: Midnight,
    fontSize: '.875rem',
  },
  calendarInfo: {
    display: 'flex',
    marginTop: theme.spacing(3),
    gap: theme.spacing(5),
    alignItems: 'center',
  },
  dateInfo: {
    fontSize: '1.25rem',
    marginBottom: theme.spacing(1),
    color: Midnight,
  },
  timeInfo: {
    fontSize: '.875rem',
    color: Dusk,
  },
}));

export default EventSummary;
