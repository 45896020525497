import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

const RsvpDeadlineBanner = ({ rsvpDeadline }: { rsvpDeadline: string }) => {
  const classes = useStyles();
  return (
    <Box>
      <Typography className={classes.bannerText}>
        rsvp deadline is {rsvpDeadline}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  bannerText: {
    color: theme.palette.common.white,
    padding: '12px',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Reboto Mono, monospace',
    background: theme.palette.primary.main,
  },
}));

export default RsvpDeadlineBanner;
