// @ts-strict-ignore
import { Dispatch, SetStateAction } from 'react';
import { css } from '@emotion/react';
import { IconProps } from '@mysteryco/design/icons';
import Check from '@mysteryco/design/icons/Check';
import { colors } from '@mysteryco/design';
import { StepStatus } from 'constants/Type';
import theme from 'theme';

export interface StepperProps {
  text: string;
  subText?: string;
  Icon?: React.ComponentType<IconProps>;
  iconText?: string;
  status?: StepStatus;
  // TODO: add support for button
}

export interface ListedStepperProps {
  steps: StepperProps[];
  activeStep?: number;
  setActiveStep?: Dispatch<SetStateAction<number>>;
  showConnector?: boolean;
  className?: string;
}

const ListedStepper = ({
  steps,
  activeStep,
  setActiveStep,
  showConnector,
  ...props
}: ListedStepperProps) => {
  if (!activeStep) {
    activeStep = 0;
  }
  return (
    <div
      className={props?.className}
      css={styles.root}
      style={showConnector ? { gap: 0 } : undefined}
      {...props}
    >
      {steps.map((step, i) => {
        let { text, subText, Icon, iconText, status } = step;
        if (!status) {
          status =
            activeStep < i
              ? StepStatus.Initial
              : activeStep > i
              ? StepStatus.Completed
              : StepStatus.Current;
        }
        if (!iconText) {
          iconText = `${i + 1}`;
        }

        return (
          <div
            key={`step-${i}`}
            style={{
              position: 'relative',
              minHeight: showConnector ? '72px' : '52px',
            }}
          >
            <div
              css={styles.stepperLink}
              onClick={() => setActiveStep && setActiveStep(i)}
              style={setActiveStep && { cursor: 'pointer' }}
            >
              <div
                css={[
                  styles.circle,
                  status === StepStatus.Initial && styles.initial,
                  status === StepStatus.Cancelled && styles.cancelled,
                  status === StepStatus.Completed && styles.completed,
                ]}
              >
                {status === StepStatus.Completed ? (
                  <Check size={14} color={colors.Glue_Ink00} />
                ) : Icon ? (
                  <Icon
                    size={14}
                    color={
                      status === StepStatus.Cancelled ? '#AB5F5F' : colors.Glue_Ink00
                    }
                  />
                ) : (
                  <p
                    style={
                      status === StepStatus.Cancelled
                        ? { color: '#AB5F5F' }
                        : { color: colors.Glue_Ink00 }
                    }
                  >
                    {iconText}
                  </p>
                )}
              </div>
              <div>
                <p css={styles.text}>{text}</p>
                {subText && <p css={styles.subText}>{subText}</p>}
              </div>
            </div>
            {showConnector && i < steps.length - 1 && <div css={styles.connector} />}
          </div>
        );
      })}
    </div>
  );
};

const styles = {
  root: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  }),
  stepperLink: css({
    display: 'flex',
    color: colors.Glue_Ink00,
    lineHeight: '24px',
  }),
  circle: css({
    display: 'flex',
    width: '32px',
    height: '32px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
    background: colors.Glue_Mint00,
    border: `1px solid ${colors.Glue_DarkMint10}`,
    marginRight: theme.spacing(4),
    flexShrink: 0,
  }),
  initial: css({
    background: colors.Glue_Paper,
    border: `1px solid ${colors.Glue_Ink00}`,
  }),
  cancelled: css({
    background: colors.Glue_Red10,
    border: '1px solid #E7AFAF',
  }),
  completed: css({
    background: colors.Glue_Mint00,
    border: `1px solid ${colors.Glue_DarkMint10}`,
  }),
  text: css({
    fontWeight: 800,
    fontSize: '16px',
    marginBottom: 0,
  }),
  subText: css({
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '1.5rem',
  }),
  connector: css({
    marginLeft: '15.5px',
    width: '1px',
    height: '32px',
    border: `1px solid ${colors.Glue_Ink20}`,
    position: 'absolute',
    top: '36px',
  }),
};

export default ListedStepper;
