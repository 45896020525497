// @ts-strict-ignore
import { gql, useMutation } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement
} from '@stripe/react-stripe-js';
import _ from 'lodash';
import { useState } from 'react';
import NewPrimaryButton from './NewPrimaryButton';
import SnackbarUtils from './SnackbarUtils';

const useStyles = makeStyles((theme) => ({
  stripeInput: {
    width: '100%',
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(2),
    padding: `8px 12px 8px 12px`,
    '&>.Mui-focused': {
      background: '#DBD0E4',
    },
    '&:focus': {
      background: '#DBD0E4',
    },
  },
  inputRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  button: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    borderRadius: theme.spacing(40),
  },
  boldRow: {
    fontWeight: 'bold',
    fontSize: '1.4rem',
    display: 'flex',
    flexDirection: 'column',
  },
  error: {
    color: 'red',
    marginTop: '1rem',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  success: {
    color: 'green',
    marginTop: '1rem',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  saveButtonSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: '2rem',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  cvcCode: {
    [theme.breakpoints.down('md')]: {
      marginTop: '2rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
    },
  },
  cardOnFileSection: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
  expirationDate: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
    },
  },
}));

const CardOnFileSection = ({ cardOnFile, classes, setIsEditing, hideChangeButton }) => {
  const yearString = String(
    _.get(cardOnFile, 'expYear', _.get(cardOnFile, 'exp_year', '')),
  );
  const expiresString = yearString
    ? `${_.get(cardOnFile, 'expMonth', cardOnFile.exp_month)}/${yearString.substring(2)}`
    : null;
  return (
    yearString && (
      <Box className={classes.cardOnFileSection}>
        <Box>
          <Typography color='primary'>
            <b>{`${cardOnFile.brand} ending in: ${cardOnFile.last4} - Expires: ${expiresString}`}</b>
          </Typography>
        </Box>
        {!hideChangeButton && (
          <NewPrimaryButton
            variant='contained'
            onClick={() => setIsEditing(true)}
            style={{ marginTop: '1rem' }}
            label='Change'
          />
        )}
      </Box>
    )
  );
};

export const CreditCardForm = ({
  elements,
  stripe,
  userId,
  cardOnFile,
  onSuccess,
  onError,
  hideChangeButton,
}) => {
  const classes = useStyles();
  const [addOrUpdateSourceForUser] = useMutation(addOrUpdateSourceForUserMutation);
  const [paymentInfoUpdated, setPaymentInfoUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [card, setCard] = useState(cardOnFile);
  const handleClick = () => {
    handleSubmit();
  };
  const [isEditing, setIsEditing] = useState(!!!cardOnFile);

  const handleSubmit = async () => {
    setLoading(true);
    if (elements == null) {
      return;
    }

    try {
      const token = await stripe.createToken(elements.getElement(CardNumberElement));
      setPaymentInfoUpdated(false);

      if (token.error) {
        setError(token.error.message);
        onError();
      } else {
        const card = await addOrUpdateSourceForUser({
          variables: {
            userId: userId,
            source: token.token.id,
          },
        });

        setCard(token.token.card);
        setError(null);
        onSuccess(card.data.addOrUpdateSourceForUser.stripeId);
        setPaymentInfoUpdated(true);
        setIsEditing(false);
        setLoading(false);
      }
    } catch (error) {
      SnackbarUtils.error(error.message);
      setLoading(false);
    }
  };

  return (
    <>
      <Box width='100%'>
        {!!card && !isEditing && (
          <CardOnFileSection
            cardOnFile={card}
            classes={classes}
            setIsEditing={setIsEditing}
            hideChangeButton={hideChangeButton}
          />
        )}
        <Box display={isEditing ? 'inline' : 'none'}>
          <Box mt={4} display='flex' justifyContent={'space-between'}>
            <Box flexBasis={'50%'}>
              <Typography variant='body2' color='primary'>
                Card number
              </Typography>
              <CardNumberElement
                className={classes.stripeInput}
                options={{
                  style: {
                    base: {
                      fontSize: '16px',
                      color: '#424770',
                      '::placeholder': {
                        color: '#aab7c4',
                      },
                    },
                    invalid: {
                      color: '#9e2146',
                    },
                  },
                }}
              />
            </Box>
            <Box flexBasis={'23%'}>
              <Typography variant='body2' color='primary'>
                Exp date
              </Typography>
              <CardExpiryElement className={classes.stripeInput} />
            </Box>
            <Box flexBasis={'23%'}>
              <Typography variant='body2' color='primary'>
                CVC code
              </Typography>
              <CardCvcElement className={classes.stripeInput} />
            </Box>
          </Box>
        </Box>
        {error && (
          <Box className={classes.error}>
            <Typography>{error}</Typography>
          </Box>
        )}
        {paymentInfoUpdated && (
          <Box className={classes.success}>
            <Typography>Payment Info Updated</Typography>
          </Box>
        )}

        <Box className={classes.saveButtonSection}>
          <NewPrimaryButton
            onClick={handleClick}
            style={{ display: isEditing ? 'inline' : 'none' }}
            label={'Save card'}
            loading={loading}
          />
        </Box>
      </Box>
    </>
  );
};

export const addOrUpdateSourceForUserMutation = gql`
  mutation AddOrUpdateUserSource($userId: ID!, $source: String!) {
    addOrUpdateSourceForUser(userId: $userId, source: $source) {
      id
      stripeId
    }
  }
`;
