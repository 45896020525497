import Target from 'glue/assets/onboarding-target-min.png';
import { SETTINGS } from 'glue/scenes/Settings';
import { Paths } from 'Routes';

import { css } from '@emotion/react';
import { Box, Typography } from '@material-ui/core';
import { colors } from '@mysteryco/design';
import { LinkButton } from 'glue/components/buttons/Button';

const TeamSetup = ({ teamsAdded }: { teamsAdded: boolean }) => {
  const teamSettingsTab = SETTINGS.find(({ name }) => name === 'Teams');
  const teamSettingsPath = Paths.SETTINGS + '/' + teamSettingsTab?.path;

  const NeedToAddTeams = () => (
    <Box>
      <Typography css={styles.contentHeader}>
        Add your team with one of the following options:
      </Typography>
      <ul css={styles.ul}>
        <li>
          <Typography css={styles.li}>
            Integrate your HRIS to automatically add members
          </Typography>
        </li>
        <li>
          <Typography css={styles.li}>
            Use a magic link to invite members directly to your account
          </Typography>
        </li>
        <li>
          <Typography css={styles.li}>Invite members via email</Typography>
        </li>
        <li>
          <Typography css={styles.li}>
            Send us a CSV of your members and we will take care of the rest
          </Typography>
        </li>
      </ul>
      <LinkButton shape='round' color='black' to={teamSettingsPath}>
        Add Teams
      </LinkButton>
      <Typography css={styles.psstText}>
        Psst... if you’ve used a CSV it may take a bit before seeing that data in Glue
      </Typography>
    </Box>
  );

  const TeamsAlreadyAdded = () => (
    <Box>
      <Typography css={styles.contentHeader}>You've added your team</Typography>
      <Box css={{ paddingTop: '12px' }}>
        <Typography css={styles.li}>
          Well done! Now you’re even closer to being the best team ever
        </Typography>
      </Box>
      <img
        src={Target}
        alt='Hand holding a dart next to a dartboard'
        css={styles.image}
      />
    </Box>
  );

  return teamsAdded ? <TeamsAlreadyAdded /> : <NeedToAddTeams />;
};

const styles = {
  contentHeader: css({
    fontSize: '18px',
    fontWeight: 700,
    color: colors.Glue_Ink00,
  }),
  ul: css({
    paddingInlineStart: '20px',
    paddingBlockEnd: '10px',
  }),
  li: css({
    fontSize: '16px',
    lineHeight: '200%',
    color: colors.Glue_Ink00,
    fontWeight: 500,
  }),
  psstText: css({
    paddingTop: '24px',
    fontSize: '14px',
    color: colors.Glue_Ink00,
    fontWeight: 500,
  }),
  image: css({
    paddingTop: '12px',
    borderRadius: '4px',
    width: '100%',
  }),
};

export default TeamSetup;
