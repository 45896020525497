// @ts-strict-ignore
import { useDebounceFn } from 'ahooks';
import { Loading } from 'components/core';
import { Formik } from 'formik';
import { useState } from 'react';
import theme from 'theme';
import { CLIENT_SUCCESS_EMAIL, getParsedEmails } from 'utils/stringUtil';
import { useMutation } from '@apollo/client';
import { Box, Button, ButtonGroup, Link, Snackbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { updateOrganizationMembers } from 'gql/mutations';
import { MemberFragmentFragment } from 'types';
import HrisBanner from 'components/illustrations/HrisBanner';
import CsvBanner from 'components/illustrations/CsvBanner';
import { pluralize } from 'humanize-plus';
import Data from '@mysteryco/design/icons/Data';
import Fingerprint04 from '@mysteryco/design/icons/Fingerprint04';
import AtSign from '@mysteryco/design/icons/AtSign';
import FileAttachment05 from '@mysteryco/design/icons/FileAttachment05';
import { TeamPageOrganization } from '../..';
import config from 'config';
import { Alert, Color } from '@material-ui/lab';
import { colors } from '@mysteryco/design';
import LinkExternal01 from '@mysteryco/design/icons/LinkExternal01';
import Modal from 'components/core/Modal';
import FlatButton from 'components/core/FlatButton';
import ArrowRight from '@mysteryco/design/icons/ArrowRight';
import TextInput from 'components/Forms/TextInput';
import LabeledInput from 'components/Forms/LabeledInput';

const CSV_TEMPLATE =
  'https://docs.google.com/spreadsheets/d/1I53-tYVksKyoIzmj_Y2aJfslZ6iHwoZ9h-BRJ2SuZqM/edit?usp=sharing';
const SIGN_UP_LINK = `${config.teams.url}/sign-up`;

type Props = {
  isShowing: boolean;
  setIsShowing(isShowing: boolean): void;
  organization: TeamPageOrganization;
  existingMembers: MemberFragmentFragment[];
  openHrisConnect: () => void;
};

const validationSchema = Yup.object({
  parsedEmails: Yup.array()
    .of(Yup.string().email(({ value }) => `${value} is not a valid email`))
    .min(1, 'You must enter at least one valid email'),
});

const AddTeamMembersModal = ({
  isShowing,
  setIsShowing,
  organization,
  existingMembers,
  openHrisConnect,
}: Props) => {
  const classes = useStyles();
  const [submitting, setSubmitting] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>(null);
  const [alertSeverity, setAlertSeverity] = useState('info');
  const [tab, setTab] = useState('hrisTab');

  const [inviteUsersToOrganization] = useMutation(updateOrganizationMembers, {
    refetchQueries: ['searchOrgUsers'],
  });

  const processEmailString = (emailString: string, setFieldValue) => {
    const emailArray = getParsedEmails(emailString);
    setFieldValue('parsedEmails', [...new Set(emailArray)]);
  };

  const { run: processEmailsDebounced } = useDebounceFn(processEmailString, {
    wait: 500,
  });

  const snackbarAlert = (message: string, severity = 'info') => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setSnackbarOpen(true);
  };

  const hasJoinLink = !!organization.userSignUpPath;
  const joinLink = `${SIGN_UP_LINK}${organization.userSignUpPath}`;

  const HrisTab = () => {
    const open = () => {
      openHrisConnect();
      setIsShowing(false);
    };
    return (
      <>
        <HrisBanner />
        <Box className={classes.tabContent}>
          <Typography className={classes.tabHeader}>Connect your org chart</Typography>
          <Typography className={classes.content}>
            Never worry about updating your team. Connect your HR system and Glue will
            automatically add new people, archive members who have left and update member
            details as things change.
          </Typography>
        </Box>
        <FlatButton onClick={open} fullWidth className={classes.button}>
          Connect org chart <ArrowRight size={16} />
        </FlatButton>
      </>
    );
  };

  const ShareTab = () => {
    return (
      <>
        <Typography className={classes.tabHeader}>Your share link</Typography>
        <TextInput value={joinLink} fullWidth />
        <FlatButton
          onClick={() => {
            navigator.clipboard.writeText(joinLink);
            snackbarAlert('Link copied to clipboard!', 'success');
            setIsShowing(false);
          }}
          fullWidth
          className={classes.button}
        >
          Copy link <ArrowRight size={16} />
        </FlatButton>
      </>
    );
  };

  const CsvTab = () => {
    return (
      <>
        <CsvBanner />
        <Box className={classes.tabContent}>
          <Typography className={classes.tabHeader}>Upload members to Glue</Typography>
          <Typography className={classes.content}>
            Easily add new members with a CSV upload. Fill out the{' '}
            <Link href={CSV_TEMPLATE} target='_blank' rel='noopener noreferrer'>
              CSV template guide <LinkExternal01 size={12} />
            </Link>{' '}
            and email your file to{' '}
            <Link href={`mailto:${CLIENT_SUCCESS_EMAIL}`}> {CLIENT_SUCCESS_EMAIL}</Link>;
            we will take care of the rest.
          </Typography>
        </Box>
      </>
    );
  };

  const EmailTab = () => {
    return (
      <Formik
        initialValues={{
          emails: '',
          parsedEmails: [],
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          setSubmitting(true);
          try {
            await inviteUsersToOrganization({
              variables: {
                id: organization.id,
                members: [
                  ...values.parsedEmails?.map((email) => ({
                    email,
                  })),
                  ...existingMembers?.map((member) => ({
                    userId: member.id,
                    email: member.email,
                    roleId: member.organizationRole?.id,
                  })),
                ],
                preserveServiceAccounts: true,
              },
            });
            snackbarAlert('Invitations sent!', 'success');
            resetForm();
          } catch (err) {
            snackbarAlert(err, 'error');
          }
          setSubmitting(false);
          setIsShowing(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => {
          const disabled = !!Object.values(errors).length || !values.parsedEmails?.length;
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <LabeledInput
                error={Object.values(errors).map((errorMessage, i) => {
                  return (
                    <div key={`error-${i}`}>{touched.emails ? errorMessage : ''}</div>
                  );
                })}
                fullWidth
              >
                <TextInput
                  value={values.emails}
                  id='emails'
                  error={!!Object.values(errors).length && touched.emails}
                  onChange={(e) => {
                    processEmailsDebounced(e.target.value, setFieldValue);
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  multiline
                  minRows={6}
                  maxRows={6}
                  placeholder='Paste or enter emails here'
                  variant='outlined'
                  fullWidth
                />
              </LabeledInput>
              <Typography className={classes.formHelper}>
                {values.parsedEmails?.length}{' '}
                {pluralize(values.parsedEmails?.length, 'member')}
              </Typography>
              <FlatButton
                type='submit'
                disabled={disabled}
                className={classes.button}
                fullWidth
              >
                Send email invites {!disabled && <ArrowRight size={16} />}
              </FlatButton>
            </form>
          );
        }}
      </Formik>
    );
  };

  return (
    <>
      <Modal open={isShowing} onClose={() => setIsShowing(false)} title='Org Chart'>
        {submitting ? (
          <Loading />
        ) : (
          <Box className={classes.dialogContent}>
            <Typography className={classes.content}>
              Select a method to add members to Glue
            </Typography>
            <ButtonGroup
              className={classes.tabs}
              variant='outlined'
              fullWidth
              disableElevation
            >
              <div>
                <Button
                  onClick={() => setTab('hrisTab')}
                  disabled={tab === 'hrisTab'}
                  startIcon={<Data size={16} />}
                  disableElevation
                  fullWidth
                >
                  HRIS
                </Button>
              </div>
              {hasJoinLink && (
                <div>
                  <Button
                    onClick={() => setTab('shareTab')}
                    disabled={tab === 'shareTab'}
                    startIcon={<Fingerprint04 size={16} />}
                    disableElevation
                    fullWidth
                  >
                    Share link
                  </Button>
                </div>
              )}
              <div>
                <Button
                  onClick={() => setTab('emailTab')}
                  disabled={tab === 'emailTab'}
                  startIcon={<AtSign size={16} />}
                  disableElevation
                  fullWidth
                >
                  Email
                </Button>
              </div>
              <div>
                <Button
                  onClick={() => setTab('csvTab')}
                  disabled={tab === 'csvTab'}
                  startIcon={<FileAttachment05 size={16} />}
                  disableElevation
                  fullWidth
                >
                  CSV
                </Button>
              </div>
            </ButtonGroup>
            {tab === 'hrisTab' && <HrisTab />}
            {tab === 'shareTab' && <ShareTab />}
            {tab === 'emailTab' && <EmailTab />}
            {tab === 'csvTab' && <CsvTab />}
          </Box>
        )}
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity={alertSeverity as Color}>{alertMessage}</Alert>
      </Snackbar>
    </>
  );
};

const useStyles = makeStyles({
  content: { fontWeight: 400, lineHeight: 1.5, color: colors.Black },
  button: {
    '&.MuiButtonBase-root': {
      fontSize: '1rem',
      padding: theme.spacing(4),
    },
  },
  tabContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: `${theme.spacing(2)} 0 0`,
    gap: theme.spacing(2),
    width: '100%',
  },
  tabHeader: {
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '.875rem',
    lineHeight: 1.43,
    letterSpacing: '0.1em',
    color: colors.Dusk,
  },
  dialogContent: {
    paddingBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(4),
    minWidth: '33.5rem',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(4),
    width: '100%',
  },
  formHelper: {
    fontWeight: 500,
    fontSize: '.875rem',
    lineHeight: 1.43,
    color: colors.MediumGray,
  },
  tabs: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: theme.spacing(1),
    gap: theme.spacing(2),
    background: '#f9f9f9',
    border: `1px solid ${colors.LightGray}`,
    boxShadow: `0px 1px 2px rgba(155, 160, 166, 0.06)`,
    borderRadius: theme.spacing(2),
    fontSize: '.875rem',
    '& div': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      '&:not(:last-child)::after': {
        content: '""',
        borderLeft: `1px solid ${colors.LightGray}`,
        marginLeft: theme.spacing(2),
        height: theme.spacing(7),
      },
    },
    '& .MuiButton-root': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: 'none',
      borderRadius: theme.spacing(1),
      padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
      textTransform: 'none',
      gap: theme.spacing(2),
      fontWeight: 500,
      color: colors.MediumGray,
      '& .MuiButton-startIcon svg': {
        color: colors.MediumGray,
      },
      '&.Mui-disabled': {
        background: colors.White,
        color: colors.Dusk,
        fontWeight: 700,
        '& .MuiButton-startIcon svg': {
          color: colors.Dusk,
        },
      },
      '&:hover': {
        background: colors.Purple100,
        color: colors.Dusk,
        '& .MuiButton-startIcon svg': {
          color: colors.Dusk,
        },
      },
    },
  },
});

export default AddTeamMembersModal;
