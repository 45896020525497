// @ts-strict-ignore
import {
  Box,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Typography,
  Radio,
  Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import React from 'react';
import theme, { selectedOptionBackground } from 'theme';
import { makeBorderStyle } from 'utils/borders';
import { AnswerProps } from '.';

const MAX_OPTIONS_IN_WINDOW = 5;

const useStyles = makeStyles({
  container: {},
  answerContainer: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    width: '100%',
    cursor: 'pointer',
  },
  answer: {
    fontSize: 30,
  },
  radio: {
    '&$checked': {
      color: '#4B8DF8',
    },
  },
});

const RadioOrChecked = ({ question, answer, setAnswer, multiSelect }: AnswerProps) => {
  const [options, setOptions] = React.useState(question.options);
  const [selectedOption, setSelectedOption] = React.useState(answer);
  const classes = useStyles();
  const controlComponent = multiSelect ? <Checkbox /> : <Radio />;

  React.useEffect(() => {
    if (_.isNil(options)) {
      return;
    }
    // Only show 5 options in a window from a 17, 😬 , option screen.
    if (options.length > MAX_OPTIONS_IN_WINDOW) {
      // Set answer to whats in URl if passed
      const urlAnswerIndex = new URLSearchParams(window.location.search).get(
        'answerIndex',
      );
      if (urlAnswerIndex) {
        setSelectedOption(options[urlAnswerIndex]);
      }
      setOptions(options.slice(6, 11));
      return;
    }
    // Slide window up and down when the user selects a top, or bottom, option in the window.
    const currentAnswerIndex = options.findIndex((o) => o === selectedOption);
    if (currentAnswerIndex === 0) {
      const originalAnswerIndex = question.options.findIndex((o) => o === options[0]);
      if (originalAnswerIndex === 0) {
        // Do nothing we're at the top of the original list already.
      } else {
        // Move the window up one spot.
        setOptions([question.options[originalAnswerIndex - 1], ...options.slice(0, -1)]);
      }
    } else if (currentAnswerIndex === options.length - 1) {
      const originalAnswerIndex = question.options.findIndex(
        (o) => o === options[options.length - 1],
      );
      if (originalAnswerIndex === question.options.length - 1) {
        // Do nothing we're at the bottom of the original list already.
      } else {
        // Move the window down one spot.
        setOptions([...options.slice(1), question.options[originalAnswerIndex + 1]]);
      }
    }
    setAnswer(selectedOption);
  }, [selectedOption]);

  return (
    <Box className={classes.container}>
      <FormControl fullWidth component='fieldset'>
        <RadioGroup>
          {_.map(options, (option, i) => {
            const isSelected = selectedOption === option;
            return (
              <Box
                key={i}
                className={classes.answerContainer}
                style={{
                  ...makeBorderStyle({
                    index: i,
                    options,
                    maxOptions: MAX_OPTIONS_IN_WINDOW,
                  }),
                  backgroundColor: isSelected ? selectedOptionBackground : 'white',
                }}
                onClick={(e) => {
                  e.preventDefault(); // Prevent event bubbling
                  setSelectedOption(option);
                }}
              >
                <FormControlLabel
                  checked={isSelected}
                  value={option}
                  control={controlComponent}
                  onChange={(e) => {
                    const t = e.target as any; // Target here doesn't inherit from Element for some reason?
                    setSelectedOption(t.value);
                  }}
                  label={<Typography variant='body1'>{option}</Typography>}
                />
              </Box>
            );
          })}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default RadioOrChecked;
