import { Box, ButtonBase, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@mysteryco/design/src';
import ChevronDown from '@mysteryco/design/icons/ChevronDown';
import theme from 'theme';
import { cx } from '@emotion/css';

interface FilterPillProps {
  label: string;
  value: string;
  expanded?: boolean;
  disabled?: boolean;
  onClick?: (event: any) => void;
}

const FilterPill = ({
  label,
  value,
  expanded = false,
  disabled = false,
  onClick = () => {},
}: FilterPillProps) => {
  const classes = useStyles();
  return (
    <ButtonBase
      className={cx(classes.root, expanded && classes.expanded)}
      onClick={onClick}
      disabled={disabled}
    >
      <Box className={classes.labelContainer}>
        <Typography className={classes.label}>{label}</Typography>
      </Box>
      <Box className={classes.valueContainer}>
        <Typography className={classes.value}>{value}</Typography>
        <Box className={classes.icon}>
          <ChevronDown size={16} />
        </Box>
      </Box>
    </ButtonBase>
  );
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    background: colors.White,
    border: '1px solid #D5DCDE',
    borderRadius: theme.spacing(10),
    transition: 'all 300ms ease-in-out',
    '&:hover': {
      background: colors.Purple50,
      borderColor: colors.Purple300,
      boxShadow: '0px 3px 3px rgba(123, 23, 131, 0.09)',
      '& $labelContainer::after': {
        borderColor: colors.Purple300,
      },
    },
    '&:focus, &$expanded': {
      background: colors.Purple50,
      borderColor: colors.Purple500,
      boxShadow: '0px 3px 3px rgba(123, 23, 131, 0.09)',
      '& $labelContainer::after': {
        borderColor: colors.Purple300,
      },
    },
    '&.Mui-disabled': {
      backgroundColor: colors.OffWhite,
    },
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: `${theme.spacing(2.5)} 0 ${theme.spacing(2.5)} ${theme.spacing(4)}`,
    gap: theme.spacing(3),
    '&::after': {
      content: '""',
      display: 'block',
      height: '1.25rem',
      borderLeft: '1px solid #D5DCDE',
    },
  },
  label: {
    fontWeight: 500,
    fontSize: '.875rem',
    lineHeight: 1.43,
    color: colors.Dusk,
    '.Mui-disabled &': {
      color: colors.MediumGray,
    },
  },
  valueContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: `${theme.spacing(2.5)} ${theme.spacing(3)}`,
    gap: theme.spacing(2),
  },
  value: {
    fontWeight: 700,
    fontSize: '.875rem',
    lineHeight: 1.43,
    color: colors.Dusk,
    '.Mui-disabled &': {
      color: colors.MediumGray,
    },
  },
  icon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& svg': {
      color: colors.Main,
      transition: 'transform 300ms ease-in-out',
      '.Mui-disabled &': {
        color: colors.MediumGray,
      },
    },
  },
  expanded: {
    '& $icon svg': {
      transform: 'rotate(-180deg)',
    },
  },
});

export default FilterPill;
