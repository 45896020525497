import { css } from '@emotion/react';
import { Box, FormControlLabel, Radio, Typography } from '@material-ui/core';
import { colors } from '@mysteryco/design';
import theme from 'theme';

interface Props {
  className?: string;
  value: any;
  label: string;
  subText?: string;
  recommended?: boolean;
  disabled?: boolean;
  /** @deprecated - wrap in a RadioGroup and pass your value to that RadioGroup to control selected values */
  selected?: boolean;
  borders?: boolean;
}

interface TextContentProps {
  label: string;
  recommended?: boolean;
  subText?: string;
}

const RadioButtonCard = ({
  className,
  value,
  label,
  subText,
  recommended = false,
  disabled = false,
  selected,
  borders = true,
  ...extraProps
}: Props) => {
  return (
    <FormControlLabel
      css={[
        styles.radioOption,
        selected && styles.selected,
        selected && borders && styles.selectedBorder,
        borders && styles.borderShow,
      ]}
      className={className}
      value={value}
      control={<Radio css={styles.radio} disableRipple checked={selected} />}
      label={<TextContent label={label} recommended={recommended} subText={subText} />}
      disabled={disabled}
      {...extraProps}
    />
  );
};

const TextContent = ({ label, recommended, subText }: TextContentProps) => {
  return (
    <Box css={styles.textContent}>
      <Typography css={styles.label}>{label}</Typography>
      {recommended && <Recommended />}
      {subText && <Typography css={styles.subText}>{subText}</Typography>}
    </Box>
  );
};

const Recommended = () => {
  return (
    <Box css={styles.recommended}>
      <Typography css={styles.recommendedText}>Recommended</Typography>
    </Box>
  );
};

const styles = {
  label: css({
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '140%',
  }),
  radioOption: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: `${theme.spacing(4)}`,
    minWidth: '185px',
    gap: `${theme.spacing(3)}`,
    '&.Mui-disabled': {
      color: colors.MediumGray,
      background: colors.OffWhite,
    },
    '&.MuiFormControlLabel-root': {
      margin: 0,
    },
  }),
  borderShow: css({
    borderRadius: '8px',
    border: '1px solid #E0E0E0',
    '&:hover': {
      border: '1px solid #828282',
    },
  }),
  selected: css({
    boxShadow:
      '0px 4px 6px -1px rgba(155, 160, 166, 0.1), 0px 2px 4px -1px rgba(155, 160, 166, 0.06)',
  }),
  selectedBorder: css({
    border: `1px solid ${colors.Glue_Ink00}`,
  }),
  radio: css({
    color: '#BDBDBD',
    padding: 0,
    '&.Mui-checked': {
      color: colors.Black,
    },
  }),
  recommended: css({
    backgroundColor: '#BFFFD2',
    borderRadius: '16px',
    padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
  }),
  recommendedText: css({
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '18px',
  }),
  subText: css({
    color: colors.Coal_20,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
  }),
  textContent: css({
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(1)}`,
  }),
};

export default RadioButtonCard;
