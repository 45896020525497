export enum SubjectType {
  Team,
  Individual,
}

export interface Dot {
  value: number;
  name: string;
  subjects: SubScore[];
  size: number;
  x?: number;
  y?: number;
  radius?: number;
  strength?: string;
  color?: string;
}

export interface SubScore {
  value?: number;
  teamName?: string;
  team?: {
    name: string;
    size?: number;
  };
}
