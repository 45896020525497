import { css } from '@emotion/react';
import PageTitle from 'glue/components/structure/PageTitle';
import theme from 'theme';

type Props = {
  title: React.ReactNode;
  children: React.ReactNode;
  page?: string;
  className?: string;
};

const PageWrapper = ({ title, children, page, className, ...props }: Props) => {
  const mixpanelPage = page ? page : typeof title === 'string' ? title.toLowerCase() : '';

  return (
    <div {...props} css={styles.root} className={className}>
      <PageTitle css={styles.pageTitle} pageName={mixpanelPage}>
        {title}
      </PageTitle>
      {children}
    </div>
  );
};

const styles = {
  root: css({
    width: '100%',
    padding: `${theme.spacing(10)} ${theme.spacing(20)}`,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  }),
  pageTitle: css({
    alignSelf: 'stretch',
    marginBottom: theme.spacing(10),
  }),
};

export default PageWrapper;
