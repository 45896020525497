import theme from 'theme';

const SwitchHorizontal = ({
  size = 24,
  color = theme.palette.primary.main,
  style = {},
  className = '',
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      style={style}
      viewBox={`0 0 ${size} ${size}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_731_2231)'>
        <path
          d='M2.08105 8.26184C1.52877 8.26184 1.08105 8.70956 1.08105 9.26184C1.08105 9.81413 1.52877 10.2618 2.08105 10.2618L2.08105 8.26184ZM10.0811 9.26184L10.7882 9.96895C11.1787 9.57842 11.1787 8.94526 10.7882 8.55473L10.0811 9.26184ZM8.78816 6.55473C8.39764 6.16421 7.76447 6.16421 7.37395 6.55473C6.98342 6.94526 6.98342 7.57842 7.37395 7.96895L8.78816 6.55473ZM7.37395 10.5547C6.98342 10.9453 6.98342 11.5784 7.37395 11.9689C7.76447 12.3595 8.39764 12.3595 8.78816 11.9689L7.37395 10.5547ZM10.0811 5.26184C10.6333 5.26184 11.0811 4.81413 11.0811 4.26184C11.0811 3.70956 10.6333 3.26184 10.0811 3.26184V5.26184ZM2.08105 4.26184L1.37395 3.55473C0.983424 3.94526 0.983424 4.57842 1.37395 4.96895L2.08105 4.26184ZM4.78816 2.96895C5.17869 2.57842 5.17869 1.94526 4.78816 1.55473C4.39764 1.16421 3.76447 1.16421 3.37395 1.55473L4.78816 2.96895ZM3.37395 6.96895C3.76447 7.35947 4.39764 7.35947 4.78816 6.96895C5.17869 6.57842 5.17869 5.94526 4.78816 5.55473L3.37395 6.96895ZM2.08105 10.2618H10.0811V8.26184H2.08105L2.08105 10.2618ZM7.37395 7.96895L9.37395 9.96895L10.7882 8.55473L8.78816 6.55473L7.37395 7.96895ZM9.37395 8.55473L7.37395 10.5547L8.78816 11.9689L10.7882 9.96895L9.37395 8.55473ZM10.0811 3.26184L2.08105 3.26184L2.08105 5.26184L10.0811 5.26184V3.26184ZM3.37395 1.55473L1.37395 3.55473L2.78816 4.96895L4.78816 2.96895L3.37395 1.55473ZM1.37395 4.96895L3.37395 6.96895L4.78816 5.55473L2.78816 3.55473L1.37395 4.96895Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_731_2231'>
          <rect
            width='12'
            height='12'
            fill='white'
            transform='translate(0.0810547 0.761841)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SwitchHorizontal;
