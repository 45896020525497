// @ts-strict-ignore
import {
  Box,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@mysteryco/design/src';
import {
  Dusk,
  LightGray,
  Main,
  Purple100,
  Purple300,
  Purple50,
  Purple600,
  Purple700,
} from '@mysteryco/design/src/tokens/colors';
import { useDebounceFn } from 'ahooks';
import MagnGlass from 'components/icons/MagnGlass';
import NoEventsState from 'components/NoEventsState';
import { Colors } from 'constants/index';
import DropdownFilterSimple, {
  toOptionArray,
} from 'glue/components/inputs/DropdownFilterSimple';
import { goalToString } from 'lib/helpers/goals';
import { stringToEventStatuses, teamEventStatusMap } from 'lib/helpers/teamEventStatus';
import { DateTime } from 'luxon';
import mixpanel from 'mixpanel-browser';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import ReactLoading from 'react-loading';
import { Route, Switch } from 'react-router';
import { PageMetaData } from 'Routes';
import theme from 'theme';
import {
  CustomerType,
  EventGoal,
  TeamEventStatus,
  useGetOrganizationTeamEventsQuery,
  ViewerFragmentFragment,
} from 'types';
import { isUpsellable } from 'utils/customerTypeUtils';
import { isAdmin } from 'utils/security';
import { getActivitiesTypePath } from '..';
import AggregateStats from './AggregateStats';
import BookedEvent from './BookedEvent';
import TeamEventRow from './Row';
import { LinkButton } from 'glue/components/buttons/Button';
import { DEFAULT_SSF_ROUTE } from 'Routes';
import ArrowRight from '@mysteryco/design/icons/ArrowRight';

const DEFAULT_DATE_SELECT = '3';

export interface EventsProps {
  viewer: ViewerFragmentFragment;
}

export const getActivitiesEventsPath = (): string => {
  return getActivitiesTypePath('events');
};

const getDateOptions = () => {
  const format = (date: DateTime): string => {
    return date.toFormat('MMM yyyy');
  };

  const currentMonth = DateTime.now();
  const nextMonth = currentMonth.plus({ month: 1 });
  const lastMonth = currentMonth.minus({ month: 1 });
  const thisQuarterStart = currentMonth.startOf('quarter');
  const thisQuarterEnd = thisQuarterStart.endOf('quarter');
  const lastQuarterStart = currentMonth.minus({ quarter: 1 }).startOf('quarter');
  const lastQuarterEnd = lastQuarterStart.endOf('quarter');

  const options = [
    {
      name: 'Next month',
      description: format(nextMonth),
      startDate: nextMonth.startOf('month').toJSDate(),
      endDate: nextMonth.endOf('month').toJSDate(),
    },
    {
      name: 'This month',
      description: format(currentMonth),
      startDate: currentMonth.startOf('month').toJSDate(),
      endDate: currentMonth.endOf('month').toJSDate(),
    },
    {
      name: 'Last month',
      description: format(lastMonth),
      startDate: lastMonth.startOf('month').toJSDate(),
      endDate: lastMonth.endOf('month').toJSDate(),
    },
    {
      name: 'This quarter',
      description: `${format(thisQuarterStart)} - ${format(thisQuarterEnd)}`,
      startDate: thisQuarterStart.toJSDate(),
      endDate: thisQuarterEnd.toJSDate(),
    },
    {
      name: 'Last quarter',
      description: `${format(lastQuarterStart)} - ${format(lastQuarterEnd)}`,
      startDate: lastQuarterStart.toJSDate(),
      endDate: lastQuarterEnd.toJSDate(),
    },
    {
      name: 'All time',
      description: '',
    },
  ];
  return options.map((options, i) => {
    return { ...options, id: `${i}` };
  });
};

const getGoalOptions = () => {
  return Object.keys(EventGoal).map((goal) => {
    return {
      id: goal,
      name: goalToString(goal as EventGoal),
    };
  });
};

const getStatusOptions = () => {
  return toOptionArray([...new Set(Object.values(teamEventStatusMap))]);
};

const getStatusFilter = (teamEventStatus: string[]): TeamEventStatus[] => {
  return teamEventStatus.flatMap((name) => stringToEventStatuses(name));
};

const getDateRangeFilter = (dateOption: string[], dateOptions): Date[] => {
  const id = parseInt(dateOption?.length ? dateOption[0] : DEFAULT_DATE_SELECT);
  const selected = dateOptions[id];
  return selected.startDate && selected.endDate
    ? [selected.startDate, selected.endDate]
    : [];
};

const EventsSummary = ({ viewer }: EventsProps) => {
  const classes = useEventStyles();

  const [teamEventStatus, setTeamEventStatus] = useState([]);
  const [goals, setGoals] = useState([]);
  const [search, setSearch] = useState('');
  const [dateOption, setDateOption] = useState([DEFAULT_DATE_SELECT]);

  const goalOptions = useMemo(getGoalOptions, []);
  const statusOptions = useMemo(getStatusOptions, []);
  const dateOptions = useMemo(getDateOptions, []);

  const orgId = viewer?.orgs?.[0]?.id;
  const { data, loading } = useGetOrganizationTeamEventsQuery({
    variables: {
      orgId,
      search,
      dateRange: getDateRangeFilter(dateOption, dateOptions),
      teamEventStatus: getStatusFilter(teamEventStatus),
      goals: goals,
      participantId: isAdmin(viewer) ? undefined : viewer.id,
    },
  });
  useEffect(() => {
    mixpanel.track('events loaded', {
      customerType: viewer?.customerType,
      firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
    });
  }, []);

  const { run: debounceSetSearch } = useDebounceFn(setSearch, { wait: 200 });

  const teamEvents = data?.teamEventsConnection?.edges?.map((edge) => edge.node) || [];
  const hasTeamEvents = teamEvents.length > 0;
  const matchingOrg = data?.viewer?.orgs?.find((org) => org.id === orgId);
  // we can't determine this for transactional users, but it works for orgs. don't mess with === false, we don't want to match for undefined here.
  const hasNeverBookedAnEvent = matchingOrg?.hasTeamEvents === false;

  const sortedEvents = [...teamEvents].sort(
    (a, b) => new Date(b.requestedFor).getTime() - new Date(a.requestedFor).getTime(),
  );

  const metricsEnabledCustomers = [
    CustomerType.EventsAndInsights,
    CustomerType.EventsOnly,
  ];
  const showUpsellMetrics = !metricsEnabledCustomers.includes(viewer?.customerType);

  const canUpsell = isUpsellable(viewer?.customerType);

  const RenderDateOption = (option) => {
    return (
      <Box className={classes.option}>
        <Typography className={classes.optionLabel}>{option.name}</Typography>
        <Typography className={classes.optionDescription}>
          {option.description}
        </Typography>
      </Box>
    );
  };

  const handleBookEvent = () => {
    mixpanel.track('book event opened', {
      source: 'home',
      customerType: viewer?.customerType,
      firstTimeBooker: viewer?.requestedTeamEvents.length === 0,
    });
  };

  return (
    <Box>
      <Helmet>
        <title>{PageMetaData.EVENTS.title}</title>
      </Helmet>
      <Box className={classes.mainWrapper}>
        {!hasNeverBookedAnEvent && (
          <>
            <div className={classes.searchAndBookEventContainer}>
              <TextField
                onChange={(e) => {
                  debounceSetSearch(e.target.value);
                }}
                variant='outlined'
                className={classes.searchBox}
                placeholder={'Search events'}
                defaultValue={search}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <MagnGlass
                        color={theme.palette.primary.main}
                        height={16}
                        width={16}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <LinkButton 
                color='green'
                shape='round'
                to={DEFAULT_SSF_ROUTE}
                onClick={handleBookEvent}
                endIcon={<ArrowRight color='inherit' size={22} />}
              >
                Book event
              </LinkButton>
            </div>

            <Box className={classes.filterRow}>
              <DropdownFilterSimple
                label='Goal types'
                options={goalOptions}
                value={goals}
                onChange={(_, newValue) => setGoals(newValue)}
                selectAllText={`Select all ${goalOptions?.length} goals`}
                multiple
                disabled={loading}
              />
              <DropdownFilterSimple
                label='Event statuses'
                options={statusOptions}
                value={teamEventStatus}
                onChange={(_, newValue) => setTeamEventStatus(newValue)}
                selectAllText={`Select all ${statusOptions?.length} event statuses`}
                multiple
                disabled={loading}
              />
              <DropdownFilterSimple
                label='Dates'
                options={dateOptions}
                value={dateOption}
                renderOption={RenderDateOption}
                onChange={(_, newValue) => setDateOption(newValue)}
                disabled={loading}
              />
            </Box>
          </>
        )}
        <Box className={classes.eventsWrapper}>
          {loading ? (
            <LoadingState />
          ) : hasNeverBookedAnEvent ? (
            <NoEventsState
              firstLine="Looks like you haven't booked any events yet"
              secondLine='Start bringing people together and book your first event'
            />
          ) : !hasTeamEvents ? (
            <NoEventsState
              firstLine='No events match this search'
              secondLine="New here? Let's book your first event!"
            />
          ) : (
            <>
              <AggregateStats
                teamEvents={teamEvents}
                showUpsell={showUpsellMetrics}
                canUpsell={canUpsell}
              />
              <Typography className={classes.heading}>Events</Typography>

              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader className={classes.eventsTable}>
                  <TableHead className={classes.headerRow}>
                    <TableRow>
                      <TableCell colSpan={2}>Title</TableCell>
                      <TableCell width='200'>Goal</TableCell>
                      <TableCell width='140'>Time</TableCell>
                      <TableCell width='130'>Status</TableCell>
                      <TableCell width='100' align='center'>
                        RSVPs
                      </TableCell>
                      <TableCell width='95'></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedEvents.map((teamEvent) => (
                      <TeamEventRow
                        key={teamEvent.id}
                        teamEvent={teamEvent}
                        viewer={viewer}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const Events = ({ viewer }: EventsProps) => {
  return (
    <Switch>
      <Route exact path={getActivitiesEventsPath()}>
        <EventsSummary viewer={viewer} />
      </Route>
      <Route path={`${getActivitiesEventsPath()}/:id`}>
        <BookedEvent viewer={viewer} />
      </Route>
    </Switch>
  );
};

// Matches components/core/Loading - but without the fixed position.
const LoadingState = () => {
  const classes = useEventStyles();

  return (
    <div className={classes.loadingState}>
      <ReactLoading
        type='spin'
        height='120px'
        width='120px'
        color={Colors.Brand.Primary}
      />
    </div>
  );
};

export const useEventStyles = makeStyles({
  loadingState: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 200,
  },
  pageHeader: {
    margin: `0 auto ${theme.spacing(5)}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'calc(100% - 48px)',
    maxWidth: '1600px',
    '& h3': {
      fontWeight: 300,
      color: theme.palette.grey[900],
      letterSpacing: '-1px',
    },
  },
  mainWrapper: {
    paddingTop: theme.spacing(5),
    height: 'calc(100% - 32px)',
  },
  eventsWrapper: {
    width: '100%',
    margin: '0 auto',
    maxWidth: '1600px',
  },
  thumbnail: {
    minWidth: '7.7rem',
    width: '7.7rem',
    height: '6rem',
    objectFit: 'cover',
    borderRadius: 6,
  },
  purpleText: { color: Colors.Brand.PurpleDark },
  whiteText: { color: Colors.Brand.White },
  opaque: {
    opacity: 0,
  },
  eventsTable: {
    border: `solid ${Purple100}`,
    borderWidth: '0 1px',
    borderRadius: 4,
  },
  tableContainer: {
    border: `solid ${Purple100}`,
    borderWidth: '1px 0',
    marginTop: theme.spacing(2),
    maxHeight: '71vh',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      paddingTop: '24px',
      width: '12px',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': `inset 0 0 12px ${Purple100}`,
      'box-shadow': `inset 0 0 12px ${Purple100}`,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: Purple600,
      '&:hover': {
        backgroundColor: Purple700,
      },
    },
  },
  headerRow: {
    '& .MuiTableCell-stickyHeader': {
      background: Purple50,
      borderBottom: `1px solid ${Purple100}`,
    },
    marginBottom: 0,
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    top: 0,
    zIndex: 1,
    '& .MuiTableCell-root': {
      color: Main,
      letterSpacing: 1,
      fontSize: '.75rem',
      fontWeight: theme.typography.fontWeightBold,
      fontFamily: theme.typography.actionLabel.fontFamily,
      textTransform: 'uppercase',
    },
  },
  heading: {
    fontSize: '.875rem',
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
    color: Dusk,
    letterSpacing: 1,
    marginTop: theme.spacing(8),
  },
  searchAndBookEventContainer:{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(4),
    width: '100%',
  },
  searchBox: {
    '& .MuiInputBase-root': {
      width: '100%',
      borderRadius: 20,
      height: 40,
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${LightGray}`,
        transition: 'all 0.1s ease-out',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: Purple300,
        boxShadow: '0px 3px 3px rgba(123, 23, 131, 0.09)',
      },
      '&:focus .MuiOutlinedInput-notchedOutline': {
        borderColor: Purple300,
        boxShadow: '0px 3px 3px rgba(123, 23, 131, 0.09)',
      },
      '&:active .MuiOutlinedInput-notchedOutline': {
        borderColor: Purple300,
        boxShadow: '0px 3px 3px rgba(123, 23, 131, 0.09)',
      },
    },
    '& .MuiInputBase-input': {
      boxSizing: 'none',
      fontSize: '.875rem',
    },
    width: '90%',
  },
  filterRow: {
    display: 'flex',
    marginBottom: theme.spacing(8),
    gap: theme.spacing(4),
  },
  option: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: `${theme.spacing(1)} 0`,
  },
  optionLabel: {
    fontWeight: 500,
    fontSize: '.875rem',
    lineHeight: 1.71,
    color: colors.Glue_Ink00,
  },
  optionDescription: {
    fontWeight: 500,
    fontSize: '.75rem',
    lineHeight: 1.33,
    color: colors.Glue_Ink10,
  },
});

export default Events;
