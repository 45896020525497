// @ts-strict-ignore
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@mysteryco/design';
import FlatButton from 'components/core/FlatButton';
import Modal from 'components/core/Modal';
import EmptyLoadingSpinner from 'components/EmptyLoadingSpinner';
import AutocompleteInput from 'components/Forms/AutocompleteInput';
import TextInput from 'components/Forms/TextInput';
import { pluralize } from 'humanize-plus';
import { useState } from 'react';
import theme from 'theme';
import {
  ChangeManagerInput,
  MemberFragmentFragment,
  useChangeManagersMutation,
} from 'types';

const getTeamManagers = (members: MemberFragmentFragment[]): MemberFragmentFragment[] => {
  return members.filter((member) => member.managerOf?.length);
};

const getManagedTeamId = (member: MemberFragmentFragment): string => {
  return member?.managerOf?.[0].id;
};

const ArchiveConfirmation = ({
  members,
  availableManagers,
  isShowing = false,
  isHrisConnected,
  loading = false,
  onArchive = () => null,
  onCancel = () => null,
}: {
  members: Set<MemberFragmentFragment>;
  availableManagers: MemberFragmentFragment[];
  isShowing: boolean;
  isHrisConnected: boolean;
  loading?: boolean;
  onArchive: (event: any) => void;
  onCancel: (event: any) => void;
}) => {
  const classes = useStyles();
  const [teamManagers, setTeamManagers] = useState<{
    [key: string]: MemberFragmentFragment;
  }>({});
  const [saveManagerChanges] = useChangeManagersMutation();

  const managers = getTeamManagers([...members]);

  const handleSelect = (id: string, newManager: MemberFragmentFragment) => {
    const newTeamManagers = { ...teamManagers, [id]: newManager };
    setTeamManagers(newTeamManagers);
  };

  const handleArchive = async (event: any) => {
    if (!!managers?.length) {
      const inputs: ChangeManagerInput[] = Object.entries(teamManagers).map(
        ([id, newManager]) => {
          return { teamId: id, managerId: newManager.id };
        },
      );
      await saveManagerChanges({ variables: { inputs } });
    }
    onArchive(event);
  };

  const membersArray = Array.from(members.values());

  return (
    <>
      {!!members.size && (
        <Modal
          open={isShowing}
          onClose={onCancel}
          title={`Archive ${pluralize(members.size, 'member')}`}
        >
          <Box className={classes.content}>
            <div>
              <p>
                {members.size === 1 ? (
                  <MemberToArchive member={membersArray[0]} />
                ) : (
                  'The following members'
                )}{' '}
                will lose access to their events calendar and the ability to join any of
                their upcoming events.
              </p>
              {members.size > 1 && (
                <ul>
                  {membersArray.map((member) => (
                    <li>
                      <MemberToArchive key={member.id} member={member} />
                    </li>
                  ))}
                </ul>
              )}
              <p>Do you want to continue?</p>
            </div>
            {!!managers?.length && !isHrisConnected && (
              <Box className={classes.managerContainer}>
                <Box>
                  <Typography className={classes.managerContainerTitle}>
                    Important
                  </Typography>
                  Select a replacement for their team:
                </Box>
                <Box className={classes.managerHeaderRow}>
                  <Box>Current manager</Box>
                  <Box>Set new manager</Box>
                </Box>
                {managers.map((oldManager, i) => {
                  return (
                    <Box className={classes.managerRow} key={`manager-${i}`}>
                      <Box>
                        <TextInput value={oldManager.name} fullWidth />
                      </Box>
                      <Box>
                        <AutocompleteInput
                          options={availableManagers}
                          getOptionLabel={(option) =>
                            'name' in option ? option.name : ''
                          }
                          selectOnFocus
                          placeholder='Select manager'
                          fullWidth
                          value={teamManagers?.[getManagedTeamId(oldManager)]}
                          getOptionSelected={(option, value) => option.id === value.id}
                          onChange={(_, value) =>
                            handleSelect(getManagedTeamId(oldManager), value)
                          }
                        />
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            )}
            <Box className={classes.actions}>
              <FlatButton
                variant='secondary'
                fullWidth
                onClick={onCancel}
                disabled={loading}
              >
                Cancel
              </FlatButton>
              <FlatButton
                type='submit'
                fullWidth
                disabled={loading}
                onClick={handleArchive}
              >
                {!loading ? (
                  `Yes, archive ${pluralize(members.size, 'member')}`
                ) : (
                  <>
                    <EmptyLoadingSpinner size={16} color='#828282' />
                    Archiving {pluralize(members.size, 'member')}...
                  </>
                )}
              </FlatButton>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

const MemberToArchive = ({ member }: { member: MemberFragmentFragment }) => {
  const classes = useStyles();

  return <span className={classes.member}>{member.name}</span>;
};

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(4),
    color: colors.DarkGray,
    lineHeight: 1.5,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginTop: theme.spacing(4),
    width: '100%',
  },
  managerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    gap: theme.spacing(2),
    width: '100%',
  },
  managerContainerTitle: {
    fontWeight: 800,
    fontSize: '.75rem',
    lineHeight: 2.33,
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
    color: colors.Dusk,
  },
  managerHeaderRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'stretch',
    padding: 0,
    gap: theme.spacing(2.5),
    fontFamily: theme.typography.actionLabel.fontFamily,
    fontWeight: 500,
    fontSize: '.75rem',
    lineHeight: 1.67,
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
    color: colors.DarkGray,
    '& > *': {
      width: '100%',
    },
  },
  rowContainer: {},
  managerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'stretch',
    padding: 0,
    gap: theme.spacing(2.5),
    '& > *': {
      width: '100%',
    },
    color: colors.DarkGray,
  },
  member: {
    fontWeight: 700,
  },
});

export default ArchiveConfirmation;
