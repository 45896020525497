import { Box, Card, CardContent, CardMedia, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { EventSelectionArgs, UploadGuests } from 'types/selfServeFlow';
import { getTotalGuests } from 'lib/helpers/booking';
import { DateTime } from 'luxon';
import { ContractCost } from 'lib/helpers/money';
import Estimate from 'components/Estimate';
import glueMysteryImage from 'assets/catalog/glue-mystery-event.png';
import ICSDate from 'components/ICSDate';
import theme from 'theme';

const strings = {
  experience: 'Experience',
  eventDate: 'Event Date',
  groupSize: 'Group Size',
  eventEstimate: 'Event Estimate',
  supplies: 'Supplies',
  included: 'Included',
};

interface Props {
  eventSelection: EventSelectionArgs;
  isSurpriseToAll: boolean;
  uploadGuests: UploadGuests;
  estimate: string;
  contractCost: ContractCost;
}

const EventSummaryCard = ({
  eventSelection,
  isSurpriseToAll,
  uploadGuests,
  estimate,
  contractCost,
}: Props) => {
  const classes = useStyles();
  const date = eventSelection?.event?.start;
  const templateName = eventSelection.template?.title;
  const photoUrl = eventSelection.template?.photoUrl ?? glueMysteryImage;

  const JSdate = new Date(date);
  const dt = DateTime.fromJSDate(JSdate);
  const monthName = dt.toLocaleString({
    month: 'short',
  });
  const dateNumber = JSdate.getDate();
  const dateString = DateTime.fromISO(date).toLocaleString({
    ...DateTime.DATETIME_FULL,
    timeZoneName: 'short',
  });

  const totalGuests =
    getTotalGuests({ guests: uploadGuests.guests }) ||
    uploadGuests.totalRecipientsEstimate;

  return (
    <Card className={classes.card}>
      <CardMedia className={classes.cardMedia} image={photoUrl} title='event image' />
      <CardContent className={classes.contentContainer}>
        <Box className={classes.contentLeft}>
          <ICSDate
            monthName={monthName}
            dateNumber={dateNumber}
            containerStyle={{
              width: 65,
              height: 68,
              boxShadow: `-3px 15px 58px -25px rgba(0,0,0,0.75)`,
              paddingTop: theme.spacing(1),
            }}
            dateNumberStyle={{ fontSize: 24 }}
            monthStyle={{ fontSize: 12 }}
          />
        </Box>
        <Box className={classes.contentRight}>
          <Typography className={classes.subTitle}>{strings.experience}</Typography>
          <Typography className={classes.contentText}>
            {isSurpriseToAll ? `Glue's choice` : templateName}
          </Typography>
          <Typography className={classes.subTitle}>{strings.eventDate}</Typography>
          <Typography className={classes.contentText}>{dateString}</Typography>
          <Typography className={classes.subTitle}>{strings.groupSize}</Typography>
          <Typography
            className={classes.contentText}
          >{`${totalGuests} guests`}</Typography>
          {!isSurpriseToAll && contractCost?.suppliesIncluded && (
            <>
              <Typography className={classes.subTitle}>{strings.supplies}</Typography>
              <Typography className={classes.contentText}>{strings.included}</Typography>
            </>
          )}
          <Typography className={classes.subTitle}>{strings.eventEstimate}</Typography>
          <Typography className={classes.contentText}>
            <Estimate estimate={estimate} contractCost={contractCost} />
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 540,
    backgroundColor: theme.palette.common.white,
    boxShadow: `0px 0px 16px -5px #CBD1D5`,
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(10),
  },
  cardMedia: {
    height: 180,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  contentLeft: {
    width: 70,
    marginRight: theme.spacing(6),
  },
  contentRight: {
    width: 400,
  },
  subTitle: {
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: 800,
    letterSpacing: '0.1em',
  },
  contentText: {
    color: theme.palette.common.black,
    marginBottom: theme.spacing(3),
    fontSize: 14,
    fontWeight: 500,
  },
}));

export default EventSummaryCard;
