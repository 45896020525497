import { gql } from '@apollo/client';
import { css } from '@emotion/react';
import { Box } from '@material-ui/core';
import { colors } from '@mysteryco/design';
import ClipboardPlusIcon from '@mysteryco/design/icons/ClipboardPlus';
import ZapFast from '@mysteryco/design/icons/ZapFast';
import Toggle from 'glue/components/Toggle';
import TextLink from 'glue/components/buttons/TextLink';
import StepperBlock from 'glue/components/structure/StepperBlock';
import { PULSE_SURVEY_LINK } from 'glue/scenes/PulseSettings/constants';
import { pluralize } from 'humanize-plus';
import { useState } from 'react';
import theme from 'theme';
import { Maybe, MoralePulseQuestionCategory, SurveyQuestionInfoFragment } from 'types';
import PulseHelpBanner from '../components/PulseHelpBanner';
import MoralePulseStepWrapper from './MoralePulseStepWrapper';
import { getStepperProps } from './helpers';
import { usePulseSettingsState } from './pulseSettingsState';

const strings = {
  title: 'Survey questions',
  select: 'Select the questions you’d like to include in your survey',
  helpBannerHeadline: 'Don’t see a question you’d like to add?',
  helpBannerDescription: 'Give us your suggestions',
  recommended: 'Recommended',
};

const categoryLabels = {
  [MoralePulseQuestionCategory.CompanyDirection]: 'Company Direction',
  [MoralePulseQuestionCategory.DiversityAndInclusion]: 'Diversity and Inclusion',
  [MoralePulseQuestionCategory.EmployeeGrowth]: 'Employee Growth',
  [MoralePulseQuestionCategory.JobSatisfaction]: 'Job Satisfaction',
  [MoralePulseQuestionCategory.WellBeing]: 'Well Being',
};

const getCategoryLabel = (category: MoralePulseQuestionCategory) => {
  return categoryLabels[category] || category;
};

interface Props {
  next: () => void;
  previous: () => void;
  activeMoralePulseQuestions: SurveyQuestionInfoFragment[];
}

interface QuestionBoxProps {
  questionObj: {
    id: string;
    category: MoralePulseQuestionCategory;
    copy: string;
  };
  checked?: boolean;
  recommended?: Maybe<boolean>;
  questionIds: Set<string>;
  setQuestionIds: (newSet: Set<string>) => void;
}

const QuestionBox = ({
  questionObj,
  checked,
  recommended,
  questionIds,
  setQuestionIds,
}: QuestionBoxProps) => {
  const { id, category, copy } = questionObj;

  const handleChange = (isToggled: boolean) => {
    const newQuestionIds = new Set(questionIds.values());
    if (isToggled) {
      newQuestionIds.add(id);
      setQuestionIds(newQuestionIds);
    } else {
      newQuestionIds.delete(id);
      setQuestionIds(newQuestionIds);
    }
  };
  return (
    <Box css={[styles.questionBox, checked === false && styles.disabledQuestionBox]}>
      <Box css={styles.toggleBox}>
        <Toggle checked={checked} onCheckedChange={handleChange} />
      </Box>
      <Box>
        <p css={styles.questionText}>{copy}</p>
        <p css={styles.questionTheme}>{getCategoryLabel(category)}</p>
        {recommended && (
          <div css={styles.recommended}>
            <div css={styles.zapIcon}>
              <ZapFast size={18} color={colors.Coal_20} />
            </div>
            <div>{strings.recommended}</div>
          </div>
        )}
      </Box>
    </Box>
  );
};

const SurveyQuestionsSettings = ({
  next,
  previous,
  activeMoralePulseQuestions,
}: Props) => {
  const state = usePulseSettingsState();

  const sortedPulseQuestions = activeMoralePulseQuestions
    .slice(0)
    .sort((a, b) => (a.recommended ? -1 : 0));

  // initialize selected questions from page state
  const [questionIds, setQuestionIds] = useState(() => new Set(state.questionIds));
  const onNext = () => {
    state.setQuestions(Array.from(questionIds));
    next();
  };

  const stepperProps = getStepperProps({ step: 1, enabled: !!state.pulseId });

  return (
    <MoralePulseStepWrapper next={onNext} previous={previous}>
      <Box css={styles.container}>
        <StepperBlock {...stepperProps} />
        <h2 css={styles.title}>{strings.title}</h2>
        <Box css={styles.subContainer}>
          <div css={styles.subTitleLine}>
            <p css={styles.subTitle}>{strings.select}</p>
            <p css={styles.selections}>{`${questionIds.size} ${pluralize(
              questionIds.size,
              'selection',
            )}`}</p>
          </div>
          <Box css={styles.questionContainer}>
            {sortedPulseQuestions.map((q) => (
              <QuestionBox
                questionObj={{
                  category: q.category!,
                  copy: q.copy,
                  id: q.id,
                }}
                questionIds={questionIds}
                setQuestionIds={setQuestionIds}
                recommended={q.recommended}
                checked={questionIds.has(q.id)}
                key={`question-box-${q.id}`}
              />
            ))}
          </Box>
        </Box>
        <PulseHelpBanner
          headline={strings.helpBannerHeadline}
          description={strings.helpBannerDescription}
          action={
            <TextLink newTab Icon={ClipboardPlusIcon} to={PULSE_SURVEY_LINK}>
              Give a suggestion
            </TextLink>
          }
        />
      </Box>
    </MoralePulseStepWrapper>
  );
};

SurveyQuestionsSettings.fragments = gql`
  fragment SurveyQuestionInfo on MoralePulseQuestion {
    id
    copy
    options
    category
    recommended: required
    minValue
    maxValue
  }
`;

const styles = {
  container: css({
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8),
  }),
  subContainer: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  }),
  title: css({
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: '120%',
    margin: 0,
  }),
  subSection: css({
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '120%',
    letterSpacing: '0.1em',
    color: colors.Glue_Ink10,
    margin: 0,
  }),
  subTitle: css({
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '28px',
    margin: 0,
  }),
  subTitleLine: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  questionBox: css({
    border: '1px solid #E0E0E0',
    borderRadius: '8px',
    padding: `${theme.spacing(4)} ${theme.spacing(5)}`,
    display: 'flex',
    flexDirection: 'row',
    transition: `all 0.2s ease-in-out`,
    maxWidth: 800,
  }),
  disabledQuestionBox: css({
    background: colors.Glue_LavenderLight,
    color: '#787a7b', // Lavender Dark 00
  }),
  questionContainer: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  }),
  questionText: css({
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '140%',
    margin: 0,
  }),
  questionTheme: css({
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '140%',
    color: colors.Glue_Ink10,
    margin: 0,
    marginTop: theme.spacing(1),
  }),
  toggleBox: css({
    marginRight: theme.spacing(4),
  }),
  recommended: css({
    display: 'flex',
    fontSize: '14px',
    textDecoration: 'underline',
    marginTop: theme.spacing(3),
  }),
  selections: css({
    fontWeight: 700,
    fontSize: '16px',
    color: colors.Coal_20,
  }),
  zapIcon: css({
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(1.5),
  }),
};

export default SurveyQuestionsSettings;
