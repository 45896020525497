import theme from 'theme';

const Users02 = ({
  size = 24,
  color = theme.palette.primary.main,
  style = {},
  className = '',
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.4'
        d='M16.445 2.57226C15.9505 2.32647 15.3503 2.52816 15.1045 3.02274C14.8587 3.51732 15.0604 4.1175 15.555 4.36329L16.445 2.57226ZM15.555 10.6367C15.0604 10.8825 14.8587 11.4827 15.1045 11.9773C15.3503 12.4719 15.9505 12.6736 16.445 12.4278L15.555 10.6367ZM18.4123 15.8553C17.9091 15.6276 17.3166 15.851 17.0889 16.3541C16.8612 16.8573 17.0846 17.4498 17.5877 17.6775L18.4123 15.8553ZM21.2137 20.6178C21.5549 21.0521 22.1835 21.1275 22.6178 20.7863C23.0521 20.4451 23.1275 19.8165 22.7863 19.3822L21.2137 20.6178ZM15.555 4.36329C16.7098 4.93721 17.5 6.12725 17.5 7.50001H19.5C19.5 5.33905 18.2536 3.47104 16.445 2.57226L15.555 4.36329ZM17.5 7.50001C17.5 8.87277 16.7098 10.0628 15.555 10.6367L16.445 12.4278C18.2536 11.529 19.5 9.66097 19.5 7.50001H17.5ZM17.5877 17.6775C18.9327 18.2861 20.171 19.2907 21.2137 20.6178L22.7863 19.3822C21.5741 17.8393 20.0902 16.6146 18.4123 15.8553L17.5877 17.6775Z'
        fill={color}
      />
      <path
        d='M1.21367 19.3822C0.872466 19.8165 0.947913 20.4451 1.38219 20.7863C1.81647 21.1275 2.44512 21.0521 2.78633 20.6178L1.21367 19.3822ZM16.2137 20.6178C16.5549 21.0521 17.1835 21.1275 17.6178 20.7863C18.0521 20.4451 18.1275 19.8165 17.7863 19.3822L16.2137 20.6178ZM13 7.5C13 9.433 11.433 11 9.5 11V13C12.5376 13 15 10.5376 15 7.5H13ZM9.5 11C7.567 11 6 9.433 6 7.5H4C4 10.5376 6.46243 13 9.5 13V11ZM6 7.5C6 5.567 7.567 4 9.5 4V2C6.46243 2 4 4.46243 4 7.5H6ZM9.5 4C11.433 4 13 5.567 13 7.5H15C15 4.46243 12.5376 2 9.5 2V4ZM2.78633 20.6178C4.58752 18.3253 6.96123 17 9.5 17V15C6.21713 15 3.30546 16.7198 1.21367 19.3822L2.78633 20.6178ZM9.5 17C12.0388 17 14.4125 18.3253 16.2137 20.6178L17.7863 19.3822C15.6945 16.7198 12.7829 15 9.5 15V17Z'
        fill={color}
      />
    </svg>
  );
};

export default Users02;
