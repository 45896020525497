import { gql } from '@apollo/client';
import { css } from '@emotion/react';
import Box from '@material-ui/core/Box';
import { colors } from '@mysteryco/design/src';
import theme from 'theme';
import { CustomerType, HeaderViewerFragment } from 'types';
import { isAdmin } from 'utils/security';

const Header = ({
  viewer,
  isOnboarding,
}: {
  viewer?: HeaderViewerFragment | null;
  isOnboarding: boolean;
}) => {
  const name = viewer?.firstName;
  const organization = viewer?.orgs[0]?.name;
  const isEventsOnly = viewer?.customerType === CustomerType.EventsOnly;
  const showSubtitle = organization && !isOnboarding && !isEventsOnly && isAdmin(viewer);

  return (
    <Box css={styles.root}>
      <span css={styles.greeting}>Hello{!!name ? `, ${name}` : ''}! 👋</span>
      {showSubtitle && (
        <span css={styles.text}>
          Here is what's going on with your team at {organization}
        </span>
      )}
    </Box>
  );
};
Header.fragments = gql`
  fragment HeaderViewer on User {
    id
    firstName
    customerType
    orgs {
      id
      name
    }
  }
`;

export default Header;

const styles = {
  root: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: `${theme.spacing(10)} 0`,
    gap: theme.spacing(2),
  }),
  greeting: css({
    fontWeight: 800,
    fontSize: '2.5rem',
    lineHeight: 1,
    color: colors.Glue_Ink00,
  }),
  text: css({
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: 1.5,
  }),
};
