// @ts-strict-ignore
import { Typography } from '@material-ui/core';
import RegistrationStepWrapper from 'components/Checkout/styling/RegistrationStepWrapper';

import { getZoomMeetingUrl, isHost } from 'lib/helpers/events';
import mixpanel from 'mixpanel-browser';
import {
  TeamEventFragmentFragment,
  useAddUserToTeamEventAndVirtualEventMutation,
  ViewerFragmentFragment,
} from 'types';

const FastTrack = ({
  context,
  teamEvent,
  viewer,
}: {
  context: any;
  teamEvent: TeamEventFragmentFragment;
  viewer: ViewerFragmentFragment;
}) => {
  const zoomMeetingUrl = getZoomMeetingUrl({
    teamEvent,
    isHost: isHost({ teamEvent, viewer }),
  });

  const [addUserToEvent] = useAddUserToTeamEventAndVirtualEventMutation({
    variables: {
      userId: viewer.id,
      teamEventId: teamEvent.id,
      virtualEventId: teamEvent.virtualEvent?.id,
      skipRegistrationConfirmationEmail: true,
    },
  });

  return (
    <RegistrationStepWrapper
      {...context}
      label={`Whew, you're not too late`}
      nextButtonProps={{
        label: 'Join event',
        onClick: async () => {
          mixpanel.track('fast track joined', {
            source: 'registration',
            customerType: viewer?.customerType,
            firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
          });
          await addUserToEvent();
          window.open(zoomMeetingUrl, '_blank');
        },
      }}
      teamEvent={teamEvent}
    >
      <div>
        <div style={{ marginBottom: '1rem' }}>
          <Typography>
            Looks like your event is starting soon, so let's jump right in
          </Typography>
        </div>
        <div style={{ marginBottom: '1rem' }}>
          <Typography>
            For future reference, event hosts use registration questions to get to know
            your team and make the experience special. So next time register early for
            that extra magic.
          </Typography>
        </div>
      </div>
    </RegistrationStepWrapper>
  );
};

export default FastTrack;
