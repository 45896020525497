import { css } from '@emotion/react';
import { colors } from '@mysteryco/design/src';
import theme from 'theme';

interface Props {
  icon?: React.ReactNode;
  headline?: React.ReactNode;
  description?: React.ReactNode;
  action?: React.ReactNode;
  type?: 'tip' | 'error' | 'help' | 'lavender';
  className?: string;
}

const InlineBanner = ({
  headline,
  icon,
  description,
  action,
  type = 'tip',
  ...props
}: Props) => {
  return (
    <div css={[styles.root, typeStyles[type]]} {...props}>
      <div css={styles.left}>
        {icon && <div css={styles.icon}>{icon}</div>}
        {headline && <div css={styles.headline}>{headline}</div>}
      </div>
      {description && <div css={styles.description}>{description}</div>}
      <div css={styles.right}>{action}</div>
    </div>
  );
};

const styles = {
  root: css({
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: theme.spacing(2),
    gap: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  }),
  left: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }),
  right: css({
    marginLeft: 'auto',

    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  }),
  icon: css({
    '& svg': {
      color: colors.Glue_Ink00,
    },
    marginRight: theme.spacing(1),
  }),
  headline: css({
    fontWeight: 800,
    fontSize: '.875',
    lineHeight: 1.4,
    color: colors.Glue_Ink00,
  }),
  description: css({
    fontWeight: 400,
    fontSize: '.875',
    lineHeight: 1.4,
    color: colors.Glue_Ink00,
  }),
};

const typeStyles = {
  tip: css({
    backgroundColor: colors.Glue_Mint20,
    borderColor: colors.Glue_Mint00,
  }),
  error: css({
    backgroundColor: colors.Glue_Yellow10,
    borderColor: colors.Glue_Yellow10,
  }),
  help: css({
    backgroundColor: colors.Glue_Paper,
    borderColor: colors.Glue_Ink00,
  }),
  lavender: css({
    backgroundColor: colors.Glue_LavenderLight,
    borderColor: colors.Glue_LavenderDark,
  }),
};

export default InlineBanner;
