import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Cube01 from 'components/icons/cube-01';
import cx from 'classnames';
import theme from 'theme';

export interface ChipProps {
  label: string;
  className?: string;
  automated?: boolean;
}

const TeamChip = ({ className, label, automated, ...props }: ChipProps) => {
  const classes = useStyles();
  return (
    <Chip
      label={label}
      className={cx([classes.chip, className], { [classes.automated]: automated })}
      icon={
        automated ? <Cube01 color={theme.palette.primary.main} width={16} height={16} /> : undefined
      }
      {...props}
    />
  );
};

const useStyles = makeStyles({
  chip: {
    '& .MuiChip-label': {
      flex: 1,
      paddingLeft: '5px',
      paddingRight: '5px',
    },
    '& .MuiChip-deleteIcon': {
      color: theme.palette.primary.main,
      width: 'auto',
      paddingLeft: '7px',
    },
    display: 'flex',
    justfiyContent: 'flex-end',
    paddingLeft: 5,
    paddingRight: 5,
    background: theme.palette.info.main,
    borderRadius: `${theme.spacing(2)} 0 ${theme.spacing(2)} 0`,
    color: theme.palette.common.white,
    marginRight: '4px',
    height: '22px',
    ...theme.typography.actionLabel,
  },
  automated: {
    '& .MuiChip-label': {
      paddingLeft: 6,
      paddingRight: 0,
    },
    paddingLeft: '10px',
    color: theme.palette.text.primary,
    background: theme.palette.primary[100],
  },
});

export default TeamChip;
