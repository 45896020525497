import { css } from '@emotion/react';
import { OutlinedTextFieldProps, TextField } from '@material-ui/core';
import { FieldValidator, useField } from 'formik';
import { forwardRef } from 'react';
import theme from 'theme';

// 'select' prop omitted - use FormikSelectField instead
export type FormikTextFieldProps = Omit<
  OutlinedTextFieldProps,
  'name' | 'variant' | 'select'
> & {
  /** Field name is required. This is used to hook the field up to the parent Formik. */
  name: string;
  validate?: FieldValidator;
  required?: boolean;
};

export const FormikTextField = forwardRef<HTMLDivElement, FormikTextFieldProps>(
  ({ name, type, validate, required, label, className, ...props }, ref) => {
    const [fieldProps, utils] = useField({
      name,
      type,
      validate,
      required,
    });

    return (
      <div ref={ref} css={styles.root} className={className}>
        {label && (
          <label htmlFor={name} css={styles.label}>
            {label}
          </label>
        )}
        <TextField
          size='small'
          {...props}
          {...fieldProps}
          error={utils.touched && !!utils.error}
          helperText={(utils.touched && utils.error) || props.helperText}
          name={name}
          type={type}
          required={required}
          variant='outlined'
        />
      </div>
    );
  },
);
FormikTextField.displayName = 'FormikTextField';

export default FormikTextField;

const styles = {
  root: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  label: css({
    marginBottom: theme.spacing(4),
    fontWeight: 400,
    lineHeight: '24px',
    fontSize: '18px',
    color: theme.palette.text.primary,
  }),
};

// exported for reuse elsewhere...
export const textFieldStyles = styles;
