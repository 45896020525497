import React from 'react';
import ReactLoading from 'react-loading';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from 'constants/index';

const useStyles = makeStyles({
  root: {
    width: '120px',
    height: '120px',
    margin: 'auto',
    padding: 0,
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  loader: {
    display: 'table-cell',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
});

export const Loading = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root} {...props}>
      <ReactLoading
        height='120px'
        width='120px'
        type='spin'
        color={Colors.Brand.Primary}
      />
    </div>
  );
};
