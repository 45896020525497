import theme from 'theme';

const FaceNeutral = ({
  size = 20,
  color = theme.palette.primary.main,
  style = {},
  className = '',
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      style={style}
    >
      <path
        d='M10.3332 2.49967C6.19103 2.49967 2.83317 5.85754 2.83317 9.99967C2.83317 14.1418 6.19103 17.4997 10.3332 17.4997C14.4753 17.4997 17.8332 14.1418 17.8332 9.99967C17.8332 5.85754 14.4753 2.49967 10.3332 2.49967ZM1.1665 9.99967C1.1665 4.93706 5.27056 0.833008 10.3332 0.833008C15.3958 0.833008 19.4998 4.93706 19.4998 9.99967C19.4998 15.0623 15.3958 19.1663 10.3332 19.1663C5.27056 19.1663 1.1665 15.0623 1.1665 9.99967ZM6.58317 7.49967C6.58317 6.80932 7.14281 6.24967 7.83317 6.24967C8.52353 6.24967 9.08317 6.80932 9.08317 7.49967C9.08317 8.19003 8.52353 8.74967 7.83317 8.74967C7.14281 8.74967 6.58317 8.19003 6.58317 7.49967ZM11.5832 7.49967C11.5832 6.80932 12.1428 6.24967 12.8332 6.24967C13.5235 6.24967 14.0832 6.80932 14.0832 7.49967C14.0832 8.19003 13.5235 8.74967 12.8332 8.74967C12.1428 8.74967 11.5832 8.19003 11.5832 7.49967ZM5.74984 11.6663C5.74984 11.2061 6.12293 10.833 6.58317 10.833H14.0832C14.5434 10.833 14.9165 11.2061 14.9165 11.6663C14.9165 12.6867 14.3202 13.6318 13.5182 14.2898C12.6997 14.9614 11.5774 15.4163 10.3332 15.4163C9.0889 15.4163 7.96661 14.9614 7.14816 14.2898C6.34614 13.6318 5.74984 12.6867 5.74984 11.6663ZM7.72905 12.4997C7.84961 12.6684 8.00717 12.8388 8.20537 13.0014C8.74098 13.4409 9.49369 13.7497 10.3332 13.7497C11.1727 13.7497 11.9254 13.4409 12.461 13.0014C12.6592 12.8388 12.8167 12.6684 12.9373 12.4997H7.72905Z'
        fill={color}
      />
    </svg>
  );
};

export default FaceNeutral;