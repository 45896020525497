import { css } from '@emotion/react';
import { colors } from '@mysteryco/design';
import X2 from '@mysteryco/design/icons/X2';
import theme from 'theme';

interface Props {
  children: React.ReactNode;
  onClick?: (event?: any) => void;
  disabled?: boolean;
  endIcon?: React.ReactElement;
  [key: string]: any;
}

const CloseablePill = ({
  children,
  onClick,
  disabled = false,
  endIcon = <X2 size={14} color={colors.Glue_Ink00} />,
  ...extraProps
}: Props) => (
  <div {...extraProps} css={[styles.root, disabled && styles.disabled, extraProps?.css]}>
    {children}
    <button type='button' onClick={onClick} disabled={disabled} css={styles.iconButton}>
      {endIcon}
    </button>
  </div>
);

const styles = {
  root: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
    border: `1px solid ${colors.Glue_Ink30}`,
    background: colors.Glue_Paper,
    borderRadius: '16px',
    padding: `${theme.spacing(1.5)} ${theme.spacing(4)}`,
    gap: theme.spacing(3),
    '&:hover': {
      borderColor: colors.Glue_Ink10,
    },
    '&:focus': {
      borderColor: colors.Glue_Ink00,
    },
  }),
  disabled: css({
    color: colors.Glue_Ink10,
    background: colors.Glue_LavenderLight,
    borderColor: colors.Glue_LavenderLight,
  }),
  iconButton: css({
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
      '&:disabled': {
        cursor: 'default',
      },
    },
  }),
};

export default CloseablePill;
