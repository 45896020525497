import theme from 'theme';

export const makeBorderStyle = ({ index, options, maxOptions = 0 }) => {
  const normalBorder = `1px solid ${theme.palette.grey[500]}`;
  if (options.length === 1) {
    return {
      border: normalBorder,
      borderBottom: normalBorder,
      borderRadius: theme.spacing(2),
    };
  }
  if (index === 0) {
    return {
      border: normalBorder,
      borderBottom: 'none',
      borderTopLeftRadius: theme.spacing(2),
      borderTopRightRadius: theme.spacing(2),
    };
  }
  if (index === options.length - 1 || (maxOptions && index === maxOptions - 1)) {
    return {
      border: normalBorder,
      borderBottom: normalBorder,
      borderBottomLeftRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
    };
  }
  return {
    border: normalBorder,
    borderBottom: 'none',
  };
};
