import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import LogoWithName from 'components/LogoWithName';
import theme from 'theme';

const useStyles = makeStyles({
  contentContainer: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      border: `1px solid ${theme.palette.grey['500']}`,
      borderRadius: theme.spacing(2),
      maxWidth: '40rem',
      padding: theme.spacing(6),
      margin: '0 auto',
      marginBottom: theme.spacing(10),
    },
  },
  logoContainer: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: `${theme.spacing(10)} 0 `,
    },
  },
});

const LogoWithContainer = ({ children }) => {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.logoContainer}>
        <LogoWithName logoSize={80} />
      </Box>
      <Box className={classes.contentContainer}>{children}</Box>
    </Box>
  );
};

export default LogoWithContainer;
