import { css } from '@emotion/react';
import ArrowDown from '@mysteryco/design/icons/ArrowDown';
import ArrowUp from '@mysteryco/design/icons/ArrowUp';
import Eye from '@mysteryco/design/icons/Eye';
import HappyFace from '@mysteryco/design/icons/FaceHappy';
import Minus from '@mysteryco/design/icons/Minus';
import Target04 from '@mysteryco/design/icons/Target04';
import { colors } from '@mysteryco/design/src';
import theme from 'theme';
import { card } from 'glue/components/cards/sharedStyles';
import { gql } from '@apollo/client';
import { ResultsSummaryTableAnswerFragment } from 'types';
import { questionCategoryDisplayMap } from 'glue/scenes/Explore/Pulse/categories';

export type QuestionOverviewRecommendation = 'focus here' | 'keep watching' | 'celebrate';
export type TrendDirection = 'positive' | 'negative' | 'neutral';

type Props = {
  // using table's fragment for simplicity as we transition
  data: ResultsSummaryTableAnswerFragment[];
};

const ResultsSummaryCards = ({ data }: Props) => {
  const onlyZeroToFourScaleAnswers = data?.filter(
    (answer) => answer?.lowestPossibleValue === 0 && answer?.highestPossibleValue === 4,
  );

  return (
    <div css={styles.root}>
      {onlyZeroToFourScaleAnswers?.map((answers) => {
        const category = answers?.question?.category;
        const categoryDisplay = category
          ? questionCategoryDisplayMap[category]
          : 'General';
        return (
          <QuestionOverviewCard
            answerSummary={answers}
            questionTheme={categoryDisplay}
            key={answers?.question.id}
          />
        );
      })}
    </div>
  );
};
export default ResultsSummaryCards;

const styles = {
  root: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTempalteRows: 'auto',
    gap: 20,
  }),
};

type CardProps = {
  questionTheme: string;
  // TODO(Justin): We eventually want this, but aren't sure how its calculated just yet
  trendPercentage?: number;
  showTrend?: boolean;
  answerSummary: ResultsSummaryTableAnswerFragment;
};

const trendIcons: Record<TrendDirection, any> = {
  positive: ArrowUp,
  neutral: Minus,
  negative: ArrowDown,
};

function QuestionOverviewCard({
  answerSummary,
  questionTheme,
  trendPercentage = 0,
  showTrend = false,
}: CardProps) {
  const {
    question,
    averageResponseValue: averageValue,
    highestPossibleValue: highestValue,
    lowestPossibleValue: lowestValue,
  } = answerSummary;
  const questionText = question.copy;
  // Handle the specific case of 0-4 scale displaying as 1-5
  const valueOffset = lowestValue === 0 && highestValue === 4 ? 1 : 0;
  const percentageOfTotal = averageValue / highestValue;
  const recommendation: QuestionOverviewRecommendation =
    percentageOfTotal >= 0.66
      ? 'celebrate'
      : percentageOfTotal >= 0.33
      ? 'keep watching'
      : 'focus here';
  const variant = variants[recommendation];
  const trendDirection: TrendDirection =
    trendPercentage > 0 ? 'positive' : trendPercentage < 0 ? 'negative' : 'neutral';
  const TrendIcon = trendIcons[trendDirection];
  const RecommendationIcon = variant.icon;
  const averageValueDisplay = averageValue + valueOffset;
  const highestValueDisplay = highestValue + valueOffset;

  return (
    <div css={[cardStyles.root, variant.css]}>
      <div css={cardStyles.questionTheme}>{questionTheme}</div>
      <div css={cardStyles.questionText}>{questionText}</div>
      <div css={cardStyles.resultsRow}>
        <div className='indicator' css={cardStyles.indicator}></div>
        <div css={cardStyles.resultValues}>
          <span css={cardStyles.actualResult}>{averageValueDisplay.toFixed(1)}</span> /{' '}
          <span>{highestValueDisplay}</span>
        </div>
        {showTrend && (
          <div css={cardStyles.trends}>
            <TrendIcon color={colors.Glue_Ink00} height={10.67} width={10.67} />{' '}
            {trendDirection === 'positive' ? '+' : ' '}
            {trendPercentage.toFixed(2)}%
          </div>
        )}
        <div css={cardStyles.recommendation}>
          <RecommendationIcon height={14.67} width={14.67} color={colors.Glue_Ink00} />
          {recommendation}
        </div>
      </div>
    </div>
  );
}
QuestionOverviewCard.fragments = gql`
  fragment QuestionOverviewCardData on MoralePulseAnswerSummary {
    question {
      id
      copy
      category
    }
    lowestPossibleValue
    highestPossibleValue
    averageResponseValue
    questionTrend
  }
`;

const cardStyles = {
  root: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(7),
    gap: theme.spacing(3),
    ...card.root,
    borderTopWidth: 8,
    borderTopStyle: 'solid',
    '&[role=link], &[role=button]': {
      cursor: 'pointer',
      color: colors.Glue_Ink00,
      '&:hover': {
        ...card.hover,
      },
    },
  }),
  resultsRow: css({
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    width: '100%',
  }),
  resultValues: css({
    fontSize: 20,
    lineHeight: '28px',
    color: colors.Glue_Ink10,
  }),
  actualResult: css({
    color: colors.Glue_Ink00,
    fontWeight: 800,
  }),
  keepWatching: css({
    backgroundColor: '#FFFFFF',
    borderTopColor: colors.Glue_LavenderDark,
    '& .indicator': {
      backgroundColor: colors.Glue_LavenderDark,
    },
  }),
  focusHere: css({
    backgroundColor: '#FFF3F3',
    borderTopColor: '#D06D6D',
    '& .indicator': {
      backgroundColor: colors.Glue_Red10,
    },
  }),
  celebrate: css({
    backgroundColor: '#F0FFF4',
    borderTopColor: colors.Glue_DarkMint10,
    '& .indicator': {
      backgroundColor: colors.Glue_DarkMint10,
    },
  }),
  questionTheme: css({
    textTransform: 'capitalize',
    fontWeight: 700,
    fontSize: 12,
    color: colors.Glue_Ink10,
  }),
  questionText: css({
    fontWeight: 800,
    fontStyle: 'normal',
    fontSize: 18,
    lineHeight: '140%',
    color: colors.Glue_Ink00,
    flexGrow: 1.5,
    marginBottom: theme.spacing(2),
  }),
  indicator: css({
    height: 16,
    width: 16,
    borderRadius: '50%',
  }),
  trends: css({
    alignSelf: 'flex-end',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '24px',
  }),
  recommendation: css({
    justifySelf: 'flex-end',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    color: colors.Glue_Ink00,
    flexGrow: 2,
    justifyContent: 'flex-end',
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  }),
};

const variants: Record<QuestionOverviewRecommendation, any> = {
  'focus here': {
    css: cardStyles.focusHere,
    icon: Target04,
  },
  'keep watching': {
    css: cardStyles.keepWatching,
    icon: Eye,
  },
  celebrate: {
    css: cardStyles.celebrate,
    icon: HappyFace,
  },
};
