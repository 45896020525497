import { ConnectionStrength } from 'constants/Type';

import {
  Edge,
  EngagementEdgeFragment,
  GraphData,
  Node,
  NodeType,
  OrganizationFragment,
  Configuration,
} from './interface';
import { computeNodes, getNodeStrength } from './nodes';
import { computeEdges, getConnectionStrength } from './edges';
import { GraphContext } from './GraphContext';

export const computeGraph = (
  context: GraphContext | null,
  thresholds: Configuration,
  organization?: OrganizationFragment,
  engagementEdges?: EngagementEdgeFragment[],
  teamId?: string,
  showUpdateTeams: boolean = false,
): GraphData => {
  if (
    !organization ||
    !organization.rootTeam ||
    !engagementEdges ||
    !teamId ||
    !context
  ) {
    return {
      graph: { nodes: [], edges: [] },
      nodeMap: {},
      edgeMap: {},
    };
  }

  const nodes = computeNodes(
    context,
    teamId,
    teamId === organization.rootTeam.id,
    showUpdateTeams,
  );
  const edges = computeEdges(context, thresholds, nodes);

  const nodeMap: { [key: string]: Node } = {};
  nodes.forEach((node) => {
    nodeMap[node.id] = node;
    if (NodeType.Member === node.type) return;
    const connectionVal = getNodeStrength(context, node);
    const connectionStrength = getConnectionStrength({
      connection: connectionVal,
      thresholds,
    });
    node.connection = connectionVal;
    node.connectionStrengthRating = connectionStrength.rating;
    node.color = {
      background: connectionStrength.color,
      border: connectionStrength.color,
    };
    node.font = {
      color: connectionStrength.textColor,
      strokeColor: connectionStrength.backgroundColor,
      background: connectionStrength.backgroundColor,
    };
  });

  const edgeMap: { [key: string]: Edge } = {};
  edges.forEach((edge) => {
    edgeMap[edge.id] = edge;
    const connectionStrength = getConnectionStrength({
      connection: edge.connection,
      thresholds,
    });
    edge.connectionStrengthRating = connectionStrength.rating;
    edge.width = connectionStrength.edgeWidth;
    if (ConnectionStrength.Weak === connectionStrength.rating) {
      edge.dashes = true;
    }
    edge.chosen = {
      edge: ((values: any) => {
        values.color = '#95459C';
      }) as any,
    };
  });

  return {
    graph: { nodes, edges },
    nodeMap,
    edgeMap,
  };
};
