import { Box, Typography } from '@material-ui/core';
import _ from 'lodash';
import { TeamEventFragmentFragment } from 'types';
import RegistrationStepWrapper from '../styling/RegistrationStepWrapper';

export interface CheckoutErrorType {
  message: string;
  contact: { firstName: string; email: string };
}

export const DEFAULT_ERROR_MESSAGE = `Oh no! An unknown error occured!`;

const CheckoutError = ({
  error,
  teamEvent,
}: {
  error: CheckoutErrorType;
  teamEvent: TeamEventFragmentFragment;
}) => {
  return (
    <RegistrationStepWrapper
      hideButtons
      label={error.message || DEFAULT_ERROR_MESSAGE}
      teamEvent={teamEvent}
    >
      <Box>
        <Typography>
          Please reach out to {_.get(error, 'contact.firstName')} at{' '}
          <a
            href={`mailto:${_.get(
              error,
              'contact.email',
            )}?subject=Team Event Link Expiration Inquiry [ID: ${teamEvent.id}]`}
          >
            {_.get(error, 'contact.email')}
          </a>
        </Typography>
      </Box>
    </RegistrationStepWrapper>
  );
};

export default CheckoutError;
