import { gql } from '@apollo/client';
import { css } from '@emotion/react';
import { colors } from '@mysteryco/design/src';
import { Loading, SnackbarUtils } from 'components/core';
import ArrowRight from 'components/icons/ArrowRight';
import FormikForm from 'glue/components/forms/FormikForm';
import FormikSubmitButton from 'glue/components/forms/FormikSubmitButton';
import FormikTextField from 'glue/components/forms/FormikTextField';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import theme from 'theme';
import {
  MagicLinkPulseQuestion,
  useGetMagicLinkPulseSurveyLazyQuery,
  useGetOrgForMagicLinkSurveyQuery,
  useRespondToMagicLinkPulseSurveyMutation,
} from 'types';
import { EdgeCaseScreen } from './EdgeScreens';
import MoralePulseSurveyFooter from './MoralePulseSurveyFooter';
import { PulseSurvey, PulseSurveyAnswer } from './PulseSurvey';

export const MagicLinkSurveyVerification = () => {
  const [email, setEmail] = useState<string>('');
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetOrgForMagicLinkSurveyQuery({
    variables: {
      magicLinkId: id,
    },
  });
  const [surveyQuery, { data: surveyData }] = useGetMagicLinkPulseSurveyLazyQuery({
    onCompleted: (data) => {
      if (!data?.magicLinkPulseSurvey?.success) {
        SnackbarUtils.error('Invalid email provided');
      }
    },
  });

  const onVerifyEmail = async ({ email }: { email: string }) => {
    await surveyQuery({
      variables: {
        magicLinkId: id,
        email,
      },
    });
    setEmail(email);
  };

  if (loading) return <Loading />;
  if (
    surveyData?.magicLinkPulseSurvey?.success &&
    surveyData.magicLinkPulseSurvey?.survey?.questions
  ) {
    return (
      <MagicLinkSurvey
        orgName={data?.magicLinkPulseOrganization?.name ?? ''}
        logoUrl={data?.magicLinkPulseOrganization?.logoUrl}
        magicLinkId={id}
        email={email}
        questions={surveyData.magicLinkPulseSurvey?.survey?.questions}
      />
    );
  } else if (data?.magicLinkPulseOrganization?.name) {
    return (
      <MagicLinkVerification
        logoUrl={data?.magicLinkPulseOrganization?.logoUrl}
        orgName={data?.magicLinkPulseOrganization?.name}
        onSubmit={onVerifyEmail}
      />
    );
  } else {
    return (
      <EdgeCaseScreen
        primary={`Looks like you have no surveys available at this time.`}
      />
    );
  }
};

interface MagicLinkVerificationProps {
  logoUrl?: string | null;
  orgName: string;
  onSubmit: (params: { email: string }) => void;
}
const MagicLinkVerification = ({
  logoUrl,
  orgName,
  onSubmit,
}: MagicLinkVerificationProps) => {
  return (
    <>
      <div css={styles.container}>
        <div css={styles.content}>
          {logoUrl && (
            <div>
              <img css={{ maxWidth: 350 }} src={logoUrl} alt={'Company logo'} />
            </div>
          )}
          <div css={styles.needsOpinion}>{`${orgName} needs your opinion`}</div>
          <div css={styles.helpUs}>
            {`Hi there 👋 Help us create a supportive and engaging workplace. Your input in
      this survey is very important to us.`}
          </div>
          <div css={styles.estimatedCompletion}>Estimated completion: 5 minutes</div>
          <FormikForm
            initialValues={{ email: '' }}
            onSubmit={onSubmit}
            css={styles.emailForm}
          >
            <FormikTextField
              label='Verify your work email'
              name='email'
              placeholder='your@email.here'
              required
              type='email'
            />
            <FormikSubmitButton
              shape='round'
              css={styles.getStartedButton}
              color='green'
              endIcon={<ArrowRight size={22} color='currentColor' />}
            >
              <div>Let's get started</div>
            </FormikSubmitButton>
          </FormikForm>
        </div>
      </div>
      <MoralePulseSurveyFooter />
    </>
  );
};

interface MagicLinkSurveyProps {
  magicLinkId: string;
  email: string;
  orgName: string;
  logoUrl?: string | null;
  questions: MagicLinkPulseQuestion[];
}

export const MagicLinkSurvey = ({
  magicLinkId,
  email,
  orgName,
  logoUrl,
  questions,
}: MagicLinkSurveyProps) => {
  const [respondToSurvey] = useRespondToMagicLinkPulseSurveyMutation();
  const onSubmit = (answers: PulseSurveyAnswer[]) => {
    respondToSurvey({
      variables: {
        answers,
        magicLinkId: magicLinkId,
        email: email,
      },
    });
  };
  return (
    <PulseSurvey
      logoUrl={logoUrl}
      name={''}
      orgName={orgName}
      questions={questions}
      onSubmit={onSubmit}
      showIntro={false}
    />
  );
};

MagicLinkSurveyVerification.orgQuery = gql`
  query getOrgForMagicLinkSurvey($magicLinkId: ID!) {
    magicLinkPulseOrganization(magicLinkId: $magicLinkId) {
      logoUrl
      name
    }
  }
`;

MagicLinkSurvey.query = gql`
  query getMagicLinkPulseSurvey($magicLinkId: ID!, $email: String!) {
    magicLinkPulseSurvey(magicLinkId: $magicLinkId, email: $email) {
      id
      success
      email
      survey {
        questions {
          id
          copy
          options
          type
        }
      }
    }
  }
`;

MagicLinkSurvey.mutation = gql`
  mutation respondToMagicLinkPulseSurvey(
    $magicLinkId: ID!
    $email: String!
    $answers: [MagicLinkAnswer!]!
  ) {
    respondToMagicLinkPulseSurvey(
      magicLinkId: $magicLinkId
      email: $email
      answers: $answers
    )
  }
`;

const styles = {
  brandImage: css({
    height: '25px',
  }),
  getStartedButton: css({
    width: '100%',
    marginTop: theme.spacing(10),
    justifyContent: 'center',
  }),
  estimatedCompletion: css({
    marginTop: theme.spacing(5),
    fontWeight: 900,
    fontSize: 14,
    lineHeight: '20px',
    '@media (min-width: 960px)': {
      fontSize: 16,
      lineHeight: '28px',
    },
    alignSelf: 'flex-start',
  }),
  container: css({
    padding: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: colors.Glue_Ink00,
    flex: 1,
  }),
  content: css({
    width: '320px',
    '@media (min-width: 600px)': {
      width: '440px',
    },
    '@media (min-width: 960px)': {
      width: '600px',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(8),
  }),
  helpUs: css({
    fontWeight: 500,
    fontSize: 24,
    '@media (min-width: 960px)': {
      fontSize: 32,
    },
    lineHeight: '120%',
    textAlign: 'left',
    marginTop: theme.spacing(10),
  }),
  needsOpinion: css({
    fontWeight: 900,
    fontSize: 14,
    lineHeight: '20px',
    '@media (min-width: 960px)': {
      fontSize: 22,
      lineHeight: '120%',
    },
    textAlign: 'center',
    color: 'rgba(59, 59, 59, 0.7)',
    marginTop: theme.spacing(5),
    alignSelf: 'flex-start',
  }),
  emailForm: css({
    width: '100%',
    margin: `${theme.spacing(5)} 0`,
  }),
};
