// @ts-strict-ignore
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NewPrimaryButton from 'components/core/NewPrimaryButton';
import GoalSection from 'components/GoalSection';
import ArrowRight from 'components/icons/ArrowRight';
import ArrowSquareRight from 'components/icons/ArrowSquareRight';
import { EN_DASH } from 'constants/Strings';
import { getActivitiesEventsPath } from 'glue/scenes/Activities/Events';
import { pluralize } from 'humanize-plus';
import { scoreTypeToGoal } from 'lib/helpers/goals';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import theme from 'theme';
import { EventGoal, ScoreType } from 'types';
import { EventStrategyTeamEvent } from '.';

export type EventsByTeam = {
  teamEvents: EventStrategyTeamEvent[];
  teams: {
    id: string;
    name: string;
    members: { id: string }[];
  }[];
};

const useStyles = makeStyles({
  padding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  header: {
    background: theme.palette.text.secondary,
    color: theme.palette.common.white,
    display: 'flex',
    gridRow: '1',
    gridColumn: '1 / -1',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  table: ({ showUpcomingEvents }: any) => ({
    display: 'grid',
    width: '100%',
    border: `1px solid ${theme.palette.primary[100]}`,
    borderRadius: theme.spacing(1),
    gridTemplateColumns: `repeat(${showUpcomingEvents ? 4 : 3}, 1fr)`,
    gridTemplateRows: 'repeat(7, auto)',
    overflow: 'hidden',
    marginTop: 40,
  }),
  bold: {
    fontWeight: 600,
  },
  dusk: {
    color: theme.palette.text.secondary,
  },
  lightText: {
    color: theme.palette.primary[600],
  },
  lightBackground: {
    background: theme.palette.primary[50],
  },
  greenBackground: {
    background: theme.palette.secondary[200],
  },
  tableCell: {
    border: `1px solid  ${theme.palette.primary[100]}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  lastColumn: {
    justifyContent: 'flex-end !important',
  },
});

const getConnectionImpact = (events: EventsByTeam['teamEvents']): number => {
  const eventsWithConnectionScores = events?.filter((event) => !!event.connectionRating);
  const connectionSum = eventsWithConnectionScores?.reduce(
    (acc, event) => acc + event.connectionRating,
    0,
  );
  const averageConnection = connectionSum / eventsWithConnectionScores?.length;
  return averageConnection;
};

const EventStrategyRow = ({
  classes,
  goal,
  isCurrentMonth,
  row,
  teamEvents,
  totalEventCount,
}: {
  classes: ReturnType<typeof useStyles>;
  goal: EventGoal;
  isCurrentMonth: boolean;
  row: number;
  scoreType: ScoreType;
  teamEvents: EventsByTeam['teamEvents'];
  totalEventCount: number;
}) => {
  const history = useHistory();
  const [hovered, setHovered] = useState(false);
  const defaultClasses = [classes.tableCell, classes.padding];

  const count = teamEvents?.length ?? 0;
  const disabled = count === 0;

  const asPercent = Math.ceil((100 * count) / totalEventCount);
  const disabledClasses = disabled ? [classes.lightBackground, classes.lightText] : [];
  const hoverClasses = hovered
    ? [classes.greenBackground, classes.bold, classes.dusk]
    : [];
  const rowClasses = [...defaultClasses, ...disabledClasses, ...hoverClasses];
  const upcomingEventCount =
    teamEvents?.filter((event) => event.requestedFor > DateTime.now())?.length ?? 0;

  const hoverProps = {
    onMouseOver: () => !disabled && setHovered(true),
    onMouseLeave: () => !disabled && setHovered(false),
  };

  const connectionImpact = getConnectionImpact(teamEvents);

  return (
    <>
      <td
        css={{ gridRow: `${row}`, gridColumn: '1' }}
        className={rowClasses.join(' ')}
        {...hoverProps}
      >
        <Box display='flex' justifyContent='space-between' width='100%'>
          <GoalSection
            eventGoals={[{ goal }]}
            iconSize={16}
            fontSize='14px'
            disabled={count === 0}
            hovered={hovered}
          />
          {/* Hidden if viewing data for months in the past */}
          {isCurrentMonth && (
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              css={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
              onClick={() => history.push(`${getActivitiesEventsPath()}#${goal}`)}
            >
              <ArrowSquareRight
                color={hovered ? theme.palette.primary.main : theme.palette.primary[600]}
              />
            </Box>
          )}
        </Box>
      </td>
      <td
        css={{ gridRow: `${row}`, gridColumn: '2' }}
        className={rowClasses.join(' ')}
        {...hoverProps}
      >
        <Typography
          variant='body2'
          className={hoverClasses.join(' ')}
        >{`${count} ${pluralize(count, 'event')}`}</Typography>
        <Typography
          variant='body2'
          css={{ color: theme.palette.primary[700] }}
          className={hovered && classes.bold}
        >
          {isNaN(asPercent) ? EN_DASH : `${asPercent}%`}
        </Typography>
      </td>
      {/* Hidden if viewing data for months in the past */}
      {isCurrentMonth && (
        <td
          css={{ gridRow: `${row}`, gridColumn: '3' }}
          className={rowClasses.join(' ')}
          {...hoverProps}
        >
          {`${upcomingEventCount} ${pluralize(upcomingEventCount, 'event')}`}
        </td>
      )}
      <td
        css={{ gridRow: `${row}`, gridColumn: isCurrentMonth ? '4' : '3' }}
        className={[...rowClasses, classes.lastColumn].join(' ')}
      >
        {isNaN(connectionImpact) ? EN_DASH : connectionImpact.toFixed(1)}
      </td>
    </>
  );
};

const EventStrategyTable = ({
  isCurrentMonth,
  teamEvents,
  scoreType,
}: {
  isCurrentMonth: boolean;
  teamEvents: EventsByTeam['teamEvents'];
  scoreType: ScoreType;
}) => {
  const classes = useStyles({ showUpcomingEvents: isCurrentMonth });
  const history = useHistory();

  const goalMap = Object.values(EventGoal).reduce((acc, goal) => {
    acc[goal] = [];
    return acc;
  }, {});

  const eventsByGoal = teamEvents.reduce((acc, event) => {
    for (const goal of event.goals) {
      acc[goal.goal].push(event);
    }
    return acc;
  }, goalMap);

  const headerClasses = [
    classes.padding,
    classes.lightBackground,
    classes.tableCell,
    classes.dusk,
  ];

  const goalRows =
    scoreType === ScoreType.SenseOfBelonging
      ? [
          EventGoal.FullOrg,
          EventGoal.Leadership,
          EventGoal.BetweenTeam,
          EventGoal.WithinTeam,
          EventGoal.NewHire,
        ]
      : scoreTypeToGoal(scoreType)
      ? [scoreTypeToGoal(scoreType)]
      : [];

  const eventsMatchingCurrentGoals = goalRows.flatMap((goal) => eventsByGoal[goal]);
  const averageConnection = getConnectionImpact(eventsMatchingCurrentGoals);

  return (
    <>
      <table className={classes.table}>
        <th className={[classes.header, classes.padding].join(' ')}>
          <Typography
            variant='body2'
            className={classes.bold}
          >{`Total team events: ${eventsMatchingCurrentGoals.length}`}</Typography>
          <Typography
            variant='body2'
            className={classes.bold}
          >{`Avg. connection impact: ${
            isNaN(averageConnection) ? EN_DASH : averageConnection.toFixed(1)
          }`}</Typography>
        </th>
        {/* Start column headers */}
        <td
          css={{
            gridRow: '2',
            gridColumn: '1',
            backgroundColor: theme.palette.primary.light,
          }}
          className={headerClasses.join(' ')}
        >
          <Typography className={classes.bold} variant='body2'>
            Connection focus
          </Typography>
        </td>
        <td
          css={{
            gridRow: '2',
            gridColumn: '2',
            backgroundColor: theme.palette.primary.light,
          }}
          className={headerClasses.join(' ')}
        >
          <Typography className={classes.bold} variant='body2'>
            Total events
          </Typography>
        </td>
        {isCurrentMonth && (
          <td
            css={{
              gridRow: '2',
              gridColumn: '3',
              backgroundColor: theme.palette.primary.light,
            }}
            className={headerClasses.join(' ')}
          >
            <Typography className={classes.bold} variant='body2'>
              Upcoming events
            </Typography>
          </td>
        )}
        <td
          css={{
            gridRow: '2',
            gridColumn: isCurrentMonth ? '4' : '3',
            backgroundColor: theme.palette.primary.light,
          }}
          className={[...headerClasses, classes.lastColumn].join(' ')}
        >
          <Typography className={classes.bold} variant='body2'>
            Connection impact
          </Typography>
        </td>

        {/* End column headers */}
        {/* Start goal rows */}
        {goalRows.map((goal, i) => (
          <EventStrategyRow
            key={`goal-row-${i}`}
            classes={classes}
            goal={goal}
            isCurrentMonth={isCurrentMonth}
            row={i + 3}
            scoreType={scoreType}
            teamEvents={eventsByGoal[goal]}
            totalEventCount={teamEvents?.length}
          />
        ))}
        {/* End goal rows */}
      </table>
      <Box display={'flex'} flexDirection='row' justifyContent={'flex-end'}>
        <Box>
          <NewPrimaryButton
            onClick={() => history.push(getActivitiesEventsPath())}
            label='View all events'
            appendedIcon={<ArrowRight color={theme.palette.common.white} />}
          />
        </Box>
      </Box>
    </>
  );
};

export default EventStrategyTable;
