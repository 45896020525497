import { Box, Button, CircularProgress } from '@material-ui/core';
import React from 'react';
import theme from 'theme';
import { makeStyles } from '@material-ui/styles';
import cx from 'classnames';

const useStyles = makeStyles({
  button: {
    width: '100%',
    height: theme.spacing(12),
    borderRadius: theme.spacing(12),
    textTransform: 'none',
    background:
      'linear-gradient(90deg, #7A1783 0%, #BE207A 27.6%, #9747FF 68.75%, #EB5757 97.92%)',
    color: theme.palette.common.white,
    fontWeight: 500,
    fontSize: 16,
    boxShadow: 'none',
    transition: 'all 1s linear 3ms',
    '&:disabled': {
      background: theme.palette.primary[100],
      color: theme.palette.primary[500],
    },
    '&:hover': {
      boxShadow: 'none',
      background:
        'linear-gradient(90deg, #EB5757 0%, #BE207A 27.6%, #9747FF 68.75%, #7A1783 97.92%)',
    },
  },
});

const NewPrimaryButton = ({
  onClick,
  label,
  loading,
  className,
  appendedIcon,
  prependedIcon,
  ...otherProps
}: {
  className?: string;
  onClick?: () => void | Promise<void>;
  label: string;
  loading?: boolean;
  appendedIcon?: React.ReactNode;
  prependedIcon?: React.ReactNode;
  [key: string]: any;
}) => {
  const classes = useStyles();
  return (
    <Button
      variant='contained'
      color='primary'
      onClick={onClick}
      className={cx(classes.button, className)}
      {...otherProps}
    >
      <Box display='flex' justifyContent={'center'} alignItems='center'>
        {prependedIcon && (
          <Box pr={theme.spacing(2)} display='flex' alignItems='center'>
            {prependedIcon}
          </Box>
        )}
        {label}
        {loading && (
          <CircularProgress
            style={{ marginLeft: theme.spacing(2), color: theme.palette.common.white }}
            size='1rem'
          />
        )}
        {!loading && appendedIcon && (
          <Box pl={theme.spacing(2)} display='flex' alignItems='center'>
            {appendedIcon}
          </Box>
        )}
      </Box>
    </Button>
  );
};

export default NewPrimaryButton;
