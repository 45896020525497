import config from '../config';
import { InMemoryCache, ApolloClient, ApolloLink, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import SnackbarUtils from '../components/core/SnackbarUtils';
import fragments from '../fragments.json';

const uri = `${config.api.url}/graphql`;

const httpLink = new HttpLink({ uri, credentials: 'include' });

const BLACKLISTED_OPERATIONS = new Set(['confusedSessionObserver']);

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError, operation, ...args }) => {
  if (BLACKLISTED_OPERATIONS.has(operation?.operationName)) return;

  if (graphQLErrors) {
    // GraphQL operations can pass a context which contains a filter
    // to ignore particular expected errors, or errors which are handled
    // by the UI itself.
    const context = operation.getContext();
    const filter = context?.ignoreErrorFilter || (() => false);
    graphQLErrors
      .filter((err) => !filter(err))
      .forEach(({ message, locations, path }) => {
        SnackbarUtils.error(message);
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations,
          )}, Path: ${path}`,
        );
      });
  }
  if (networkError) {
    SnackbarUtils.error(`[Network error]: ${networkError}`);
    console.log(`[Network error]: ${networkError}`);
  }
});

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, httpLink]),
  cache: new InMemoryCache({
    possibleTypes: fragments.possibleTypes,
    typePolicies: {
      // We have to disable caching for this type cause it depends on fields that are not present in its parent's arguments
      MoralePulseAnswerSummary: {
        keyFields: false,
      },
    },
  }),
});
