import { css } from '@emotion/react';
import Power02 from '@mysteryco/design/icons/Power02';
import Signal01 from '@mysteryco/design/icons/Signal01';
import { colors } from '@mysteryco/design/src';
import { StyledLeadIcon } from 'glue/components/cards/CollapsibleCard';
import Toggle from 'glue/components/Toggle';
import { useState } from 'react';
import theme from 'theme';
import PulseCancelSection from './components/PulseCancelSection';
import PulseInfoSection from './components/PulseInfoSection';
import {
  SettingsTopperActions,
  SettingsTopperCloseButton,
  SettingsTopperRoot,
  SettingsTopperTitle,
} from 'glue/components/structure/SettingsTopper';
import { LinkButton } from 'glue/components/buttons/Button';
import { Paths } from 'Routes';
import { GLUE_BANNER_HEIGHT } from 'glue/components/branding/GlueBanner';
import { gql } from '@apollo/client';
import { useMoralePulseIntroQuery } from 'types';
import ArrowRight from '@mysteryco/design/icons/ArrowRight';

const strings = {
  title: 'Gather signal on employee engagement and morale',
  enablePulse: 'Enable Pulse for your teams',
  currentlyEnabled: 'Pulse surveys are currently enabled',
  setUpPulse: 'Set up Pulse',
  changeSettings: 'Change settings',
};

const MoralePulseIntro = () => {
  const { data } = useMoralePulseIntroQuery({});
  const pulseId = data?.viewer?.defaultOrganization?.moralePulses?.[0]?.id;
  const hasPulse = !!pulseId;
  const [showCancel, setShowCancel] = useState(false);

  return (
    <div css={styles.root}>
      <SettingsTopperRoot css={styles.header}>
        <SettingsTopperTitle>Pulse Settings</SettingsTopperTitle>
        <SettingsTopperActions>
          <LinkButton
            size='compact'
            endIcon={<ArrowRight size={24} />}
            to={`${Paths.PULSE_SETTINGS}/edit`}
            color='purple'
            shape='round'
          >
            {hasPulse ? strings.changeSettings : strings.setUpPulse}
          </LinkButton>
          <SettingsTopperCloseButton />
        </SettingsTopperActions>
      </SettingsTopperRoot>
      <div css={styles.main}>
        <div css={styles.content}>
          <StyledLeadIcon backgroundColor={colors.Glue_DarkMint00}>
            <Signal01 color={colors.Glue_Ink00} size={22} />
          </StyledLeadIcon>
          <div css={styles.title}>{strings.title}</div>
          <div css={styles.subTitle}>{strings.enablePulse}</div>
          {hasPulse && (
            <div css={styles.enabledContainer}>
              <div css={styles.toggleBar}>
                <div
                  css={[
                    styles.enableToggleLabel,
                    showCancel && styles.enableToggleLabelCanceling,
                  ]}
                >
                  <Power02 color={colors.Glue_Ink10} size={18} />
                  <div css={styles.currentlyEnabled}>{strings.currentlyEnabled}</div>
                </div>
                <Toggle
                  checked={!showCancel}
                  onCheckedChange={(value) => {
                    setShowCancel(!value);
                  }}
                />
              </div>
              {showCancel && (
                <PulseCancelSection pulseId={pulseId} setShowCancel={setShowCancel} />
              )}
            </div>
          )}
          {!showCancel && <PulseInfoSection hasActivePulse={!!pulseId} />}
        </div>
      </div>
    </div>
  );
};

MoralePulseIntro.query = gql`
  query MoralePulseIntro {
    viewer {
      id
      defaultOrganization {
        id
        moralePulses {
          id
          enabled
        }
      }
    }
  }
`;

const styles = {
  root: css({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  header: css({
    position: 'sticky',
    top: GLUE_BANNER_HEIGHT,
    zIndex: theme.zIndex.appBar,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  main: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    padding: '2rem',
    width: 860,
  }),
  content: css({
    color: colors.Glue_Ink00,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
    flex: 1,
  }),
  enabledContainer: css({
    background: colors.Glue_Paper,
    border: '1px solid #E6E5E6',
    boxShadow:
      '0px 4px 6px -1px rgba(155, 160, 166, 0.1), 0px 2px 4px -1px rgba(155, 160, 166, 0.06)',
    borderRadius: theme.spacing(2),
    width: '100%',
  }),
  toggleBar: css({
    height: '52px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `0 ${theme.spacing(5)}`,
  }),
  currentlyEnabled: css({
    fontWeight: 700,
    fontSize: '16px',
    lineLeight: 1,
  }),
  enableToggleLabel: css({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3),
    color: colors.Glue_Ink00,
  }),
  enableToggleLabelCanceling: css({
    color: colors.Glue_Ink10,
  }),
  title: css({
    fontSize: 32,
    fontWeight: 700,
    lineHeight: '120%',
  }),
  subTitle: css({
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '28px',
  }),
};

export default MoralePulseIntro;
