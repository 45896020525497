import SmileFace from 'components/icons/SmileFace';
import mixpanel from 'mixpanel-browser';
import Loading from 'react-loading';
import theme from 'theme';
import { useUpdateProfileDetailsPageMutation, useProfileDetailsPageQuery } from 'types';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { SnackbarUtils } from 'components/core';
import FormikForm from 'glue/components/forms/FormikForm';
import FormikResetButton from 'glue/components/forms/FormikResetButton';
import FormikSelectField, {
  FormikSelectFieldOption,
} from 'glue/components/forms/FormikSelectField';
import FormikTextField from 'glue/components/forms/FormikTextField';
import FormikSubmitButton from 'glue/components/forms/FormikSubmitButton';
import { existsFilter } from 'lib/helpers/maybe';
import { gql } from '@apollo/client';

const DetailsHeader = ({ profileClasses }: { profileClasses: ClassNameMap }) => {
  return (
    <Box>
      <Box mb={2} className={profileClasses.mCardIcon}>
        <SmileFace color={theme.palette.secondary[600]} height={24} width={24} />
      </Box>
      <Box mb={2}>
        <Typography className={profileClasses.mCardLabel}>Your details</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant='h4' className={profileClasses.mCardTitle}>
          You're one in a million
        </Typography>
      </Box>
      <Box mb={4}>
        <Typography className={profileClasses.mCardHelperText}>
          Help us get to know you
        </Typography>
      </Box>
    </Box>
  );
};

export const Details = () => {
  const classes = useStyles();
  const profileClasses = useProfileStyles();

  const { data, loading } = useProfileDetailsPageQuery();
  const [updateUser] = useUpdateProfileDetailsPageMutation();

  const answers: { id: string; label: string; orderIndex: number }[] = data
    ?.dietaryRestrictions?.length
    ? [...data.dietaryRestrictions]
        .filter(existsFilter)
        .sort((a, b) => (a.orderIndex < b.orderIndex ? -1 : 1))
        .map((d) => ({ ...d, label: d.name }))
    : [];
  const viewer = data?.viewer;

  if (loading)
    return (
      <Box className={profileClasses.mCard}>
        <DetailsHeader profileClasses={profileClasses} />
        <Loading />
      </Box>
    );

  if (!viewer) {
    // this must be an error state
    return (
      <Box className={profileClasses.mCard}>
        <DetailsHeader profileClasses={profileClasses} />
        {/* TODO: error? */}
      </Box>
    );
  }

  return (
    <Box className={profileClasses.mCard}>
      {/* Details */}
      <DetailsHeader profileClasses={profileClasses} />
      <FormikForm
        initialValues={{
          firstName: viewer?.firstName || '',
          lastName: viewer?.lastName || '',
          email: viewer?.email || '',
          companyRole: viewer?.companyRole || '',
          companyName: viewer?.orgs?.length ? viewer.orgs[0]!.name : '',
          dietaryRestrictions: (viewer?.dietaryRestrictions ?? [])
            .map((d) => d?.id)
            .filter(existsFilter),
        }}
        enableReinitialize
        onSubmit={async ({ firstName, lastName, companyRole, dietaryRestrictions }) => {
          await updateUser({
            variables: {
              id: viewer.id,
              firstName,
              lastName,
              companyRole,
              dietaryRestrictions: dietaryRestrictions.map((id) => ({ id })),
            },
          });
          SnackbarUtils.success('Details updated!');
        }}
      >
        <Box className={classes.flexRow}>
          <FormikTextField
            name='firstName'
            required
            label={Strings.FIRST_NAME}
            className={classes.flexOne}
          />
          <FormikTextField
            name='lastName'
            required
            label={Strings.LAST_NAME}
            className={classes.flexOne}
          />
        </Box>
        <FormikTextField name='email' disabled label={Strings.EMAIL} />
        <FormikTextField name='companyName' disabled label={Strings.COMPANY_NAME} />
        <FormikTextField name='companyRole' label={Strings.COMPANY_TITLE} />
        <FormikSelectField
          name='dietaryRestrictions'
          label={Strings.FOOD_RESTRICTIONS}
          multiple
        >
          {answers.map((answer) => (
            <FormikSelectFieldOption key={answer.id} value={answer.id}>
              {answer.label}
            </FormikSelectFieldOption>
          ))}
        </FormikSelectField>
        <Box className={classes.flexRow} mb={theme.spacing(12)}>
          <FormikResetButton color='white'>{Strings.DISCARD_CHANGES}</FormikResetButton>
          <FormikSubmitButton
            onlyDirty
            onlyValid
            color='purple'
            onClick={() => {
              mixpanel.track('details in profile saved', {
                source: 'profile',
                customerType: viewer?.customerType,
                firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
              });
            }}
          >
            {Strings.SAVE_DETAILS}
          </FormikSubmitButton>
        </Box>
      </FormikForm>
    </Box>
  );
};
Details.fragments = gql`
  fragment ProfileDetailsPageViewer on User {
    id
    firstName
    lastName
    email
    companyRole
    orgs {
      id
      name
    }
    dietaryRestrictions {
      id
      name
    }
    customerType
    requestedTeamEvents {
      id
    }
  }
`;

Details.mutations = {
  updateUser: gql`
    mutation UpdateProfileDetailsPage(
      $id: ID!
      $firstName: String
      $lastName: String
      $companyRole: String
      $dietaryRestrictions: [GenericReferenceInput!]
    ) {
      updateUser(
        id: $id
        firstName: $firstName
        lastName: $lastName
        companyRole: $companyRole
        dietaryRestrictions: $dietaryRestrictions
      ) {
        id
        ...ProfileDetailsPageViewer
      }
    }
    ${Details.fragments}
  `,
};

Details.queries = {
  profileDetails: gql`
    query ProfileDetailsPage {
      viewer {
        id
        ...ProfileDetailsPageViewer
      }
      dietaryRestrictions {
        id
        name
        orderIndex
      }
    }
  `,
};

const useStyles = makeStyles({
  input: {
    height: '40px',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: theme.spacing(4),
  },
  flexOne: {
    flex: 1,
  },
  select: {
    width: '100%',
  },
});

const useProfileStyles = makeStyles({
  pageHeader: {
    margin: `0 auto ${theme.spacing(5)}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'calc(100% - 48px)',
    maxWidth: '1600px',
    '& h3': {
      fontWeight: 300,
      color: theme.palette.grey[900],
      letterSpacing: '-1px',
    },
  },
  pageTabs: {
    margin: `0 auto ${theme.spacing(4)}`,
    alignItems: 'center',
    width: 'calc(100% - 48px)',
    height: '48px',
    maxWidth: '1600px',
    overflow: 'hidden',
  },
  mainWrapper: {
    height: '100%',
    padding: theme.spacing(6),
    borderTop: `1px solid ${theme.palette.primary[200]}`,
    backgroundColor: theme.palette.primary[50],
  },
  root: {
    padding: '2rem 1rem',
  },
  mCard: {
    width: '100%',
    maxWidth: theme.spacing(140),
    margin: `${theme.spacing(6)} auto`,
    padding: theme.spacing(6),
    borderRadius: theme.spacing(2),
    border: `1px solid ${theme.palette.primary[100]}`,
    background: theme.palette.common.white,
    boxShadow: `0px 6px 8px -3px ${theme.palette.primary[100]}`,
    '&:hover': {
      border: `1px solid ${theme.palette.primary[200]}`,
      boxShadow: `0px 8px 20px -2px ${theme.palette.primary[100]}`,
    },
  },
  mCardIcon: {
    display: 'inline-flex',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    background: theme.palette.secondary[200],
  },
  mCardLabel: {
    color: theme.palette.primary.main,
    fontSize: '0.8rem',
    fontFamily: `'Roboto Monospace', monospace`,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  mCardTitle: {
    fontSize: theme.spacing(8),
    color: theme.palette.text.primary,
  },
  mCardHelperText: {
    fontSize: '1rem',
    color: theme.palette.grey[900],
  },
});

const Strings = {
  COMPANY_NAME: 'Company name',
  COMPANY_TITLE: 'Title',
  DISCARD_CHANGES: 'Discard changes',
  EMAIL: 'Email address',
  FIRST_NAME: 'First name',
  FOOD_RESTRICTIONS:
    'Tell us of any food restrictions or considerations we should know about',
  LAST_NAME: 'Last name',
  SAVE_DETAILS: 'Save changes',
};
