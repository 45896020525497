import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import _ from 'lodash';
import React from 'react';
import NewSelectableOption, { OPTION_HEIGHT } from './NewSelectableOptions';
import SectionSubHeader from './SectionSubHeader';

const useStyles = makeStyles<Theme, Partial<Props>>((theme) => ({
  optionWrapper: ({ optionSize, options }) => ({
    display: 'flex',
    flexDirection: optionSize === 'fullWidth' ? 'column' : 'row',
    // Allow for spacing between options in the case of fullWidth options
    minHeight:
      optionSize === 'fullWidth' ? _.size(options) * (OPTION_HEIGHT + 6) : undefined,
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  }),
  optionText: {
    fontSize: '16px',
    textTransform: 'none',
  },
}));

type Props = {
  description?: string;
  subtitle?: string;
  options: { key: string; text: string; icon?: React.ReactElement }[];
  optionSize?: 'small' | 'large' | 'fullWidth';
  selectedKey?: string;
  onSelectKey(key: string): void;
  conjunctionTextClass?: string;
  descriptionClass?: string;
};

const SelectOneOption = (props: Props) => {
  const classes = useStyles({ optionSize: props.optionSize, options: props.options });
  return (
    <Box width='100%'>
      {props.description && (
        <SectionSubHeader title={props.description} subTitle={props.subtitle} />
      )}
      <Box className={classes.optionWrapper}>
        {props.options.map((option, i) => {
          return (
            <NewSelectableOption
              key={i}
              size={props.optionSize}
              isSelected={props.selectedKey === option.key}
              label={option.text}
              onClick={() => props.onSelectKey(option.key)}
              icon={option.icon}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default SelectOneOption;
