// @ts-strict-ignore
import BarLineChart from '@mysteryco/design/icons/BarLineChart';
import Check from '@mysteryco/design/icons/Check';
import ClipboardCheck from '@mysteryco/design/icons/ClipboardCheck';
import ClockRefresh from '@mysteryco/design/icons/ClockRefresh';
import Send01 from '@mysteryco/design/icons/Send01';
import { StepStatus } from 'constants/Type';
import {
  Audience,
  getUniqUserCount,
  getUniqUserArr,
} from 'glue/components/inputs/audience';
import { StepperBlockProps } from 'glue/components/structure/StepperBlock';
import _ from 'lodash';
import { MoralePulseCadence } from 'types';

const cadenceMap = {
  [MoralePulseCadence.Weekly]: 'once a week',
  [MoralePulseCadence.Semimonthly]: 'every two weeks',
  [MoralePulseCadence.Monthly]: 'every four weeks',
  [MoralePulseCadence.Quarterly]: 'every 3 months',
};
const changesCadenceMap = {
  [MoralePulseCadence.Weekly]: 'once a week',
  [MoralePulseCadence.Semimonthly]: 'once every two weeks',
  [MoralePulseCadence.Monthly]: 'once every 4 weeks',
  [MoralePulseCadence.Quarterly]: 'once every quarter',
};
const weekdayMap = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};
const strings = {
  seeChanges: 'See Pulse changes overtime',
  youWillHave:
    'You will have access to intuitive reporting on participation and employee sentiment',
  setYour: 'Set your Pulse schedule and participants',
  youDetermine: 'You determine which teams will participate and how often.',
  getHelp: 'Get help building your survey',
  youllGet:
    'You’ll get a set of core questions based on key topics for you to choose from.',
  wellAlert: 'We’ll alert participants',
  participantsWill:
    'Participants will receive a notification at your set frequency to answer your survey.',
  willHaveAccess:
    'You will have access to intuitive reporting on participation and employee sentiment.',
};

const getSurveysSendStr = (
  date: Date,
  isToday: boolean,
  frequency: MoralePulseCadence,
) => {
  if (isToday) {
    return `Your survey will be sent immediately today`;
  }
  return `Surveys will send ${cadenceMap[frequency]} on ${weekdayMap[date.getDay()]}s`;
};
const getWellStartStr = (date: Date) =>
  `We’ll start sending your Pulse surveys on ${date.toLocaleDateString('en-us', {
    month: 'long',
    year: 'numeric',
    day: 'numeric',
  })}`;
const getTheyWillStr = (frequency: MoralePulseCadence) =>
  `They will receive a survey notification ${cadenceMap[frequency]} via email`;
const getNumMembersStr = (numMembers: number) =>
  `Your surveys will be sent to ${numMembers} members`;
const getWillIncludeStr = (numQuestions: number) =>
  `Surveys will include ${numQuestions} questions`;

const changesGetPrevParticipantsStr = (numMembers: number) =>
  `Survey participants used to be ${numMembers} members in your org`;
const changesGetSurveysStartStr = (date: Date) =>
  `Surveys will start on ${date.toLocaleDateString('en-us', {
    month: 'long',
    day: 'numeric',
  })}`;
const changesGetUsedToStartStr = (date: Date) =>
  `Surveys used to be set to start on ${date.toLocaleDateString('en-us', {
    month: 'long',
    day: 'numeric',
  })}`;
const changesWillSendStr = (cadence: MoralePulseCadence) =>
  `Surveys will send ${changesCadenceMap[cadence]}`;
const changesGetUsedToCadenceStr = (cadence: MoralePulseCadence) =>
  `Surveys used to be set to ${changesCadenceMap[cadence]}`;
const changesGetUsedToQuestionsStr = (numQuestions: number) =>
  `Surveys used to be set to include ${numQuestions} questions`;

const getAreAudiencesEqual = (
  previousAudience: Audience,
  updatedAudience: Audience,
): boolean => {
  const previousUserIds = getUniqUserArr(previousAudience).map((user) => user.id);
  const updatedUserIds = getUniqUserArr(updatedAudience).map((user) => user.id);
  const previousUserSet = new Set(...previousUserIds);
  const updatedUserSet = new Set(...updatedUserIds);

  return (
    previousUserIds.length === updatedUserIds.length &&
    _.isEqual(previousUserSet, updatedUserSet)
  );
};
const getAreQuestionsEqual = (
  previousQuestionIds: string[],
  updatedQuestionIds: string[],
): boolean => {
  const updatedQuestionSet = new Set(...updatedQuestionIds);
  return (
    previousQuestionIds.length === updatedQuestionIds.length &&
    previousQuestionIds.every((id) => updatedQuestionSet.has(id))
  );
};

export const getPreviewStepArr = ({
  hasActivePulse,
  date,
  isDatePickedToday,
  cadence,
  audience,
  questionIds,
  previousQuestionIds,
  previousAudience,
  previousStartDate,
  previousCadence,
}: {
  hasActivePulse: boolean;
  date: Date;
  isDatePickedToday: boolean;
  cadence: MoralePulseCadence;
  audience: Audience;
  questionIds: string[];
  previousQuestionIds?: string[];
  previousAudience?: Audience;
  previousStartDate?: Date;
  previousCadence?: MoralePulseCadence;
}) => {
  const totalGuests = getUniqUserCount(audience);

  if (hasActivePulse) {
    const steps = [];

    if (previousStartDate && date !== previousStartDate) {
      steps.push({
        text: changesGetSurveysStartStr(date),
        subText: changesGetUsedToStartStr(previousStartDate),
        Icon: ClockRefresh,
        status: StepStatus.Initial,
      });
    }
    if (previousCadence && cadence !== previousCadence) {
      steps.push({
        text: changesWillSendStr(cadence),
        subText: changesGetUsedToCadenceStr(previousCadence),
        Icon: ClockRefresh,
        status: StepStatus.Initial,
      });
    }
    if (previousAudience && !getAreAudiencesEqual(previousAudience, audience)) {
      steps.push({
        text: getNumMembersStr(getUniqUserCount(audience)),
        subText: changesGetPrevParticipantsStr(getUniqUserCount(previousAudience)),
        Icon: Send01,
        status: StepStatus.Initial,
      });
    }
    if (
      previousQuestionIds.length &&
      !getAreQuestionsEqual(previousQuestionIds, questionIds)
    ) {
      steps.push({
        text: getWillIncludeStr(questionIds.length),
        subText: changesGetUsedToQuestionsStr(previousQuestionIds.length),
        Icon: ClipboardCheck,
        status: StepStatus.Initial,
      });
    }
    return steps;
  }

  return [
    {
      text: getSurveysSendStr(date, isDatePickedToday, cadence),
      subText: getWellStartStr(date),
      Icon: ClockRefresh,
      status: StepStatus.Initial,
    },
    {
      text: getNumMembersStr(totalGuests),
      subText: getTheyWillStr(cadence),
      Icon: Send01,
      status: StepStatus.Initial,
    },
    {
      text: strings.seeChanges,
      subText: strings.youWillHave,
      Icon: BarLineChart,
      status: StepStatus.Initial,
    },
  ];
};

export const getStepperProps = ({
  step,
  enabled,
}: {
  step: 0 | 1 | 2;
  enabled: boolean;
}): StepperBlockProps => ({
  steps: [
    {
      text: 'Survey setup',
      status: step === 0 ? StepStatus.Current : StepStatus.Completed,
      Icon: step > 0 ? Check : null,
    },
    {
      text: 'Survey questions',
      status: step === 1 ? StepStatus.Current : step > 1 ? StepStatus.Completed : null,
      Icon: step > 1 ? Check : null,
    },
    {
      text: enabled ? 'Preview changes' : 'Preview & enable',
      status: step === 2 ? StepStatus.Current : null,
    },
  ],
  activeStep: step,
});

export const getSuccessSteps = ({ steps, isEnabling }) => {
  if (isEnabling) {
    return [
      {
        text: strings.setYour,
        subText: strings.youDetermine,
        Icon: Check,
        status: StepStatus.Completed,
      },
      {
        text: strings.getHelp,
        subText: strings.youllGet,
        Icon: Check,
        status: StepStatus.Completed,
      },
      {
        text: strings.wellAlert,
        subText: strings.participantsWill,
      },
      {
        text: strings.seeChanges,
        subText: strings.willHaveAccess,
      },
    ];
  }
  return steps.map((step) => ({ ...step, Icon: Check, status: StepStatus.Completed }));
};
