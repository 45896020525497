import { gql } from '@apollo/client';
import { css } from '@emotion/react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import ArrowRight from '@mysteryco/design/icons/ArrowRight';
import { colors } from '@mysteryco/design/src';
import { Paths } from 'Routes';
import { TrendArrow } from 'glue/components/accessibility/SvgColorblindPatterns';
import { TableSortIcon } from 'glue/components/tables/TableSortIcon';
import { questionCategoryDisplayMap } from 'glue/scenes/Explore/Pulse/categories';
import { sentimentGraphics } from 'glue/scenes/Explore/Pulse/sentimentGraphics';
import { useQueryParam } from 'lib/helpers/router';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import theme from 'theme';
import {
  MoralePulseQuestionType,
  MoralePulseResponseSentiment,
  ResultsSummaryTableAnswerFragment,
} from 'types';

export interface ResultsSummaryTableProps {
  data: ResultsSummaryTableAnswerFragment[];
}

type SortableValue = 'latest' | 'trend';

export function ResultsSummaryTable({ data }: ResultsSummaryTableProps) {
  const hasTrends = useMemo(
    () =>
      data.some(
        (row) => row.questionTrend !== null && row.prevAverageResponseValue !== null,
      ),
    [data],
  );
  const [sortBy = 'trend', setSortBy] = useQueryParam<SortableValue>(
    'sort',
    hasTrends ? 'trend' : 'latest',
  );
  const [sortOrder = 1, setSortOrder] = useQueryParam('order', -1, {
    toString: (val) => (val === 1 ? 'asc' : 'desc'),
    fromString: (val) => (val === 'asc' ? 1 : -1),
  });

  const sortedData = useMemo(
    () =>
      data.slice().sort((a, b) => {
        if (sortBy === 'latest') {
          return sortOrder * (a.averageResponseValue - b.averageResponseValue);
        } else {
          if (a.questionTrend === null && b.questionTrend === null)
            return sortOrder * (a.averageResponseValue - b.averageResponseValue);
          if (a.questionTrend === null || a.questionTrend === undefined) return 1;
          if (b.questionTrend === null || b.questionTrend === undefined) return -1;
          return sortOrder * (a.questionTrend - b.questionTrend);
        }
      }),
    [data, sortBy, sortOrder],
  );

  const handleSort = useCallback(
    (sortKey: SortableValue) => {
      const order = sortBy === sortKey ? -sortOrder : 1;
      setSortBy(sortKey);
      setSortOrder(order);
    },
    [sortBy, sortOrder, setSortBy, setSortOrder],
  );

  const history = useHistory();

  return (
    <Table css={styles.table}>
      <TableHead css={styles.head}>
        <TableRow css={styles.headRow}>
          <TableCell css={styles.headCell}>Question</TableCell>
          <SortableTableHeadCell
            sortKey='latest'
            activeSortKey={sortBy}
            sortOrder={sortOrder}
            handleSort={handleSort}
          >
            Latest score
          </SortableTableHeadCell>
          {hasTrends && (
            <SortableTableHeadCell
              sortKey='trend'
              activeSortKey={sortBy}
              sortOrder={sortOrder}
              handleSort={handleSort}
            >
              Trend
            </SortableTableHeadCell>
          )}
          <TableCell css={styles.headCell} />
        </TableRow>
      </TableHead>
      <TableBody css={styles.body}>
        {sortedData.map((row) => (
          <TableRow
            key={row.question.id}
            css={styles.row}
            role='link'
            tabIndex={0}
            onClick={() =>
              history.push(
                `${Paths.EXPLORE_PULSE}/question/${row.question.id}${history.location.search}`,
              )
            }
          >
            <TableCell css={styles.cell}>
              <div css={styles.questionContent}>
                <span css={styles.questionText}>{row.question.copy}</span>
                <span css={styles.questionCategory}>
                  {row.question.category &&
                    questionCategoryDisplayMap[row.question.category]}
                </span>
              </div>
            </TableCell>
            <TableCell css={styles.cell}>
              <ScoreDisplay
                score={row.averageResponseValue}
                questionType={row.question.type ?? undefined}
                maxScore={row.highestPossibleValue}
              />
            </TableCell>
            {hasTrends && (
              <TableCell css={styles.cell}>
                <div css={styles.trendContainer}>
                  {typeof row.prevAverageResponseValue === 'number' && (
                    <div css={styles.trend}>
                      <TrendMarker trend={row.questionTrend ?? 0} />
                      <span css={styles.prevScore}>
                        Previous: {row.prevAverageResponseValue.toFixed(1)}
                      </span>
                    </div>
                  )}
                </div>
              </TableCell>
            )}
            <TableCell css={styles.cell}>
              <div css={styles.arrowContainer}>
                <ArrowRight />
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

ResultsSummaryTable.fragments = gql`
  fragment ResultsSummaryTableAnswer on MoralePulseAnswerSummary {
    question {
      id
      copy
      category
      type
    }
    lowestPossibleValue
    highestPossibleValue
    averageResponseValue
    prevAverageResponseValue
    questionTrend
  }
`;

export default ResultsSummaryTable;

const SortableTableHeadCell = ({
  sortKey,
  activeSortKey,
  sortOrder,
  children,
  handleSort,
}: {
  sortKey: SortableValue;
  activeSortKey: SortableValue;
  sortOrder: number;
  children: React.ReactNode;
  handleSort: (sortKey: SortableValue) => void;
}) => {
  const onClick = useCallback(() => handleSort(sortKey), [handleSort, sortKey]);
  const active = sortKey === activeSortKey;
  const order = active ? (sortOrder === 1 ? 'desc' : 'asc') : undefined;
  // we show the "ascending" visual state as a "positive" state, but the
  // values actually descend... so this is to show the opposite arrow indicator.
  const oppositeOrder = active ? (sortOrder === 1 ? 'asc' : 'desc') : undefined;
  const [focused, setFocused] = useState(false);
  const onFocus = useCallback(() => {
    setFocused(true);
  }, []);
  const onBlur = useCallback(() => setFocused(false), []);

  return (
    <TableCell
      css={styles.headCell}
      data-order={order}
      data-active={active}
      data-focus={focused}
    >
      <TableSortLabel
        active={active}
        direction={oppositeOrder}
        onClick={onClick}
        css={styles.sortLabel}
        onFocusVisible={onFocus}
        onBlur={onBlur}
        IconComponent={TableSortIcon}
      >
        {children}
      </TableSortLabel>
    </TableCell>
  );
};

// how much of the center of the scale is the neutral zone
const TREND_MINIMUM_CHANGE = 0.01;
function getTrendSentiment(trend: number) {
  if (trend > 0.15) {
    return MoralePulseResponseSentiment.VeryPositive;
  } else if (trend > TREND_MINIMUM_CHANGE / 2) {
    return MoralePulseResponseSentiment.Positive;
  } else if (trend < -0.15) {
    return MoralePulseResponseSentiment.VeryNegative;
  } else if (trend < -TREND_MINIMUM_CHANGE / 2) {
    return MoralePulseResponseSentiment.Negative;
  }
  return MoralePulseResponseSentiment.Neutral;
}
const TrendMarker = ({ trend }: { trend: number }) => {
  const trendSentiment = getTrendSentiment(trend);
  const {
    textBackground: background,
    textColor: ink,
    trend: trendName,
  } = sentimentGraphics[trendSentiment];
  const trendValueAsPercentage = `${trend > 0 ? '+' : ''}${Math.round(trend * 100)}%`;

  return (
    <div
      css={[styles.trendMarker, { backgroundColor: background, stroke: ink, color: ink }]}
    >
      <TrendArrow trend={trendName} width={10} height={10} />
      <span>
        {trendSentiment === MoralePulseResponseSentiment.Neutral
          ? 'No change'
          : trendValueAsPercentage}
      </span>
    </div>
  );
};

const ScoreDisplay = ({
  score,
  questionType,
  maxScore = 0,
}: {
  score: number;
  questionType?: MoralePulseQuestionType;
  maxScore: number;
}) => {
  if (questionType === MoralePulseQuestionType.Nps) {
    return (
      <span>
        <span css={styles.scorePrimary}>{`${score.toFixed(0)}`}</span>
      </span>
    );
  }

  if (questionType === MoralePulseQuestionType.Friendship) {
    return (
      <span>
        <span css={styles.scorePrimary}>{`${Math.ceil(score)}`}</span>
        <span css={styles.scoreSecondary}>{` friends on average`}</span>
      </span>
    );
  }

  return (
    <span>
      <span css={styles.scorePrimary}>{score.toFixed(1)}</span>
      <span css={styles.scoreSecondary}>{` out of ${maxScore}`}</span>
    </span>
  );
};

const styles = {
  table: css({
    width: '100%',
    borderCollapse: 'separate',
    border: `1px solid ${colors.Coal_40}`,
    borderRadius: 8,
    overflow: 'hidden',
  }),
  head: css({}),
  headRow: css({}),
  headCell: css({
    borderWidth: 0,
    position: 'relative',
    transition: 'background-color 0.2s ease-in-out',
    fontWeight: 700,
    color: colors.Coal_20,
    padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
    borderBottom: `1px solid ${colors.Coal_40}`,
    overflow: 'hidden',

    '&[data-focus="true"]': {
      outline: `1px solid ${colors.Coal_00}`,
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      backgroundColor: colors.Mint_30,
      opacity: 0,
      transition: 'all 0.2s ease-in-out',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: colors.Ruby_30,
      opacity: 0,
      transition: 'all 0.2s ease-in-out',
    },

    '&[data-active="true"]': {
      color: colors.Coal_10,
    },
    '&[data-order="asc"]': {
      backgroundColor: colors.Mint_60,
    },
    '&[data-order="desc"]': {
      backgroundColor: colors.Ruby_60,
    },
    '&[data-order="asc"]::before': {
      height: 2,
      opacity: 1,
    },
    '&[data-order="desc"]::after': {
      height: 2,
      opacity: 1,
    },
  }),
  sortLabel: css({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'inherit',

    '& > svg': {
      color: 'inherit !important',
    },
  }),
  body: css({
    width: '100%',
  }),
  row: css({
    border: 'none',
    outline: 'none',
    position: 'relative',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: colors.Coal_60,
    },
    '&:focus-visible': {
      backgroundColor: colors.Coal_60,
    },

    '&::after': {
      content: '""',
      position: 'absolute',
      top: -1,
      left: -1,
      right: -1,
      bottom: -1,
      border: `1px solid ${colors.Coal_40}`,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      borderTop: 0,
      zIndex: -1,
    },
  }),
  cell: css({
    border: 'none',
    padding: theme.spacing(5),
  }),
  questionContent: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    maxWidth: 400,
  }),
  questionText: css({
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    color: colors.Coal_10,
  }),
  questionCategory: css({
    color: colors.Coal_20,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
  }),
  trendContainer: css({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    justifyContent: 'flex-end',
  }),
  trend: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  }),
  trendMarker: css({
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    width: 'min-content',
    whiteSpace: 'nowrap',
    fontSize: theme.typography.pxToRem(12),
    gap: theme.spacing(1),
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderRadius: 16,
    // just a default, overridden by trend colors
    backgroundColor: colors.Coal_60,
  }),
  scorePrimary: css({
    fontWeight: 700,
    whiteSpace: 'nowrap',
  }),
  scoreSecondary: css({
    color: colors.Coal_20,
    whiteSpace: 'nowrap',
  }),
  arrowContainer: css({
    borderRadius: 8,
    width: 40,
    height: 40,
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 0.2s ease-in-out',
    'tr:focus-visible &': {
      backgroundColor: colors.Mint_50,
    },
    '&:hover': {
      backgroundColor: colors.Mint_50,
    },
  }),
  prevScore: css({
    fontWeight: 500,
    fontSize: '13px',
    color: colors.Coal_20,
  }),
};
