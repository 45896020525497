import _ from 'lodash';

export const recursivelyRemoveTypenames = (_data: any) => {
  const data = _.cloneDeep(_data);

  const removeTypenames = (obj: any) => {
    if (typeof obj === 'object') {
      if (_.isArray(obj)) {
        // We're dealing with an array
        _.forEach(obj, removeTypenames);
      } else {
        // We're dealing with a non-array object
        _.forIn(obj, (val, key) => {
          if (key === '__typename') {
            delete obj[key];
          } else {
            removeTypenames(val);
          }
        });
      }
    } else {
      return;
    }
  };

  removeTypenames(data);

  return data;
};
