// @ts-strict-ignore
import { cx } from '@emotion/css';
import {
  Box,
  ButtonBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@mysteryco/design';
import SearchMd from '@mysteryco/design/icons/SearchMd';
import TagChip from 'components/Chips/TagChip';
import Tooltip from 'components/core/Tooltip';
import EmptyLoadingSpinner from 'components/EmptyLoadingSpinner';
import Checkbox from 'components/Forms/Checkbox';
import TablePagination from 'components/TablePagination';
import { pluralize } from 'humanize-plus';
import { DateTime } from 'luxon';
import { MutableRefObject, ReactNode, useRef, useState } from 'react';
import theme from 'theme';
import {
  MemberFragmentFragment,
  OrganizationRoleName,
  Team,
  UserTag,
  UserTagType,
} from 'types';
import { defaultTeamName, isUsingDefaultTeamName } from 'utils/teamUtil';

const PAGE_SIZE = 10;

export const hasUnselectedMembers = (
  members: MemberFragmentFragment[],
  selectedMembers: Set<MemberFragmentFragment>,
) => {
  return members?.some((member) => !isSelected(member, selectedMembers));
};

export const isSelected = (
  member: MemberFragmentFragment,
  selectedMembers: Set<MemberFragmentFragment>,
) => {
  return [...selectedMembers].some((selected) => member.id === selected.id);
};

const showOrgRole = (member: MemberFragmentFragment): boolean => {
  return (
    member.organizationRole &&
    member.organizationRole?.name !== OrganizationRoleName.Member
  );
};

const getTeam = (member: MemberFragmentFragment): Team => {
  return member.teams?.[0] as Team;
};

const getMemberTags = (member: MemberFragmentFragment): UserTag[] => {
  return member.tags?.filter((tag) => tag.type === UserTagType.Manual);
};

const scrollIntoViewIfNeeded = (ref: MutableRefObject<any>) => {
  const current = ref?.current;
  if (!current) {
    return;
  } else if (current.getBoundingClientRect().top < 0) {
    current.scrollIntoView();
  }
};

export const TeamTable = ({
  usersLoading = true,
  team,
  selectedMembers,
  onSelectMember = () => null,
  onSelectAllMembers = () => null,
  onClickEdit = () => null,
  onClickEditTeam = () => null,
  toolbar,
}: {
  usersLoading?: boolean;
  team: MemberFragmentFragment[];
  selectedMembers: Set<MemberFragmentFragment>;
  onSelectMember?: (member: MemberFragmentFragment) => void;
  onSelectAllMembers?: () => void;
  onClickEdit?: (member: MemberFragmentFragment) => void;
  onClickEditTeam?: (team: Team) => void;
  toolbar?: ReactNode;
}) => {
  const classes = useStyles();
  const [indices, setIndices] = useState([0, PAGE_SIZE]);
  const tableHeader = useRef(null);

  const handleEditTeam = (event: any, member: MemberFragmentFragment) => {
    event.stopPropagation();
    onClickEditTeam(getTeam(member));
  };

  const handleSelectMember = (event: any, member: MemberFragmentFragment) => {
    onSelectMember(member);
    event.stopPropagation();
  };

  const handlePageChange = (pages: [number, number]) => {
    setIndices(pages);
    scrollIntoViewIfNeeded(tableHeader);
  };

  const teamPage = team.slice(indices[0], indices[1]);

  const allSelected = team.length > 0 ? selectedMembers.size === team.length : false;

  const Placeholder = ({
    icon,
    miniHeadline,
    mainHeadline,
  }: {
    icon: ReactNode;
    miniHeadline: string;
    mainHeadline: string;
  }): JSX.Element => {
    return (
      <Box className={classes.placeholderContainer}>
        <Box className={classes.placeholderIcon}>{icon}</Box>
        <Box>
          <Typography className={classes.placeholderMiniHeadline}>
            {miniHeadline}
          </Typography>
          <Typography className={classes.placeholderMainHeadline}>
            {mainHeadline}
          </Typography>
        </Box>
      </Box>
    );
  };

  const EditTeamNameButton = ({
    member,
  }: {
    member: MemberFragmentFragment;
  }): JSX.Element => {
    const team = getTeam(member);
    const hasTeamName = !isUsingDefaultTeamName(team);
    return (
      <Tooltip
        title={`${hasTeamName ? 'Edit' : 'Add'} team name`}
        placement='bottom-start'
      >
        <ButtonBase
          className={classes.teamButton}
          onClick={(e) => handleEditTeam(e, member)}
        >
          {hasTeamName ? team?.name : 'No team name'}
        </ButtonBase>
      </Tooltip>
    );
  };

  return (
    <>
      {usersLoading ? (
        <Placeholder
          icon={<EmptyLoadingSpinner />}
          miniHeadline='Hold your horses'
          mainHeadline='We’re bringing in your entire squad'
        />
      ) : (
        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableHead ref={tableHeader}>
              <TableRow className={classes.headerRow}>
                <TableCell className={classes.checkboxCellPadding}>
                  <Box className={classes.checkboxCell}>
                    <Checkbox checked={allSelected} onClick={onSelectAllMembers} />
                    Member
                  </Box>
                </TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Tenure</TableCell>
                <TableCell>Team</TableCell>
                <TableCell>Tags</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teamPage.map((member, i) => (
                <TableRow
                  key={`team-member-${i}`}
                  className={cx(
                    classes.row,
                    isSelected(member, selectedMembers) && classes.selectedRow,
                  )}
                  onClick={() => {
                    onClickEdit(member);
                  }}
                >
                  {/* Member */}
                  <TableCell className={classes.checkboxCellPadding}>
                    <Box className={classes.checkboxCell}>
                      <Checkbox
                        checked={isSelected(member, selectedMembers)}
                        onClick={(e) => handleSelectMember(e, member)}
                      />
                      <Box>
                        <Typography className={classes.memberName}>
                          {member.name}
                        </Typography>
                        <Box>
                          {member.companyRole && (
                            <Typography className={classes.companyRole}>
                              {member.companyRole}
                            </Typography>
                          )}
                          {showOrgRole(member) && (
                            <Typography className={classes.organizationRole}>
                              {member.organizationRole.name}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  {/* Email */}
                  <TableCell>{member.email}</TableCell>
                  {/* Tenure */}
                  <TableCell>{member.startDate && getTenure(member.startDate)}</TableCell>
                  {/* Team */}
                  <TableCell>
                    <Box className={classes.teamCell}>
                      {defaultTeamName(getTeam(member))}
                      <EditTeamNameButton member={member} />
                    </Box>
                  </TableCell>
                  {/* Tags */}
                  <TableCell>
                    <Box className={classes.tagCell}>
                      {getMemberTags(member).length > 0 ? (
                        getMemberTags(member).map((tag, j) => (
                          <TagChip text={tag.name} key={`tag-${i}-${j}`} />
                        ))
                      ) : (
                        <Typography className={classes.tagPlaceholder}>
                          No tags
                        </Typography>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {!team?.length && (
            <Placeholder
              icon={<SearchMd />}
              miniHeadline='Nothing to see here'
              mainHeadline='Try changing your search'
            />
          )}
          <TablePagination
            totalCount={team.length}
            pageSize={PAGE_SIZE}
            onChange={(indices) => handlePageChange(indices)}
            pluralizeLabel={(count) => pluralize(count, 'member')}
          />
        </TableContainer>
      )}

      {selectedMembers && (
        <Box
          className={cx([
            classes.bulkEditBarContainer,
            !selectedMembers.size ? classes.hide : classes.show,
          ])}
        >
          {toolbar}
        </Box>
      )}
    </>
  );
};

const getTenure = (startDate: Date): ReactNode => {
  const startDateTime = DateTime.fromJSDate(new Date(startDate));
  const tenure = startDateTime.diffNow(['years', 'months', 'days', 'hours']).toObject();
  const years = Math.abs(tenure.years);
  const yearText = years ? pluralize(years, `${years} year`) : '';
  const months = Math.abs(tenure.months);
  const monthText = months ? pluralize(months, `${months} month`) : '';
  const days = Math.abs(tenure.days);
  const dayText = days ? pluralize(days, `${days} day`) : '';
  if (years) {
    return (
      <>
        {yearText}
        <br />
        {monthText}
      </>
    );
  } else if (months) {
    return <>{monthText}</>;
  } else if (days) {
    return <>{dayText}</>;
  } else {
    return <>&lt; 1 day</>;
  }
};

const useStyles = makeStyles({
  bulkEditBarContainer: {
    position: 'sticky',
    zIndex: theme.zIndex.drawer,
    bottom: 0,
  },
  hide: {
    transform: 'translateY(100%)',
    opacity: 0,
    transition: 'transform 300ms ease-in-out, opacity 300ms 100ms ease-in-out',
  },
  show: {
    transform: 'translateY(0%)',
    opacity: 1,
    transition: 'opacity 100ms ease-in-out, transform 300ms 100ms ease-in-out',
  },
  placeholderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(10)} 0`,
    gap: theme.spacing(3),
    textAlign: 'center',
  },
  placeholderIcon: {
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(3),
    background: colors.Purple100,
    borderRadius: theme.spacing(12),
    width: 44,
    height: 44,
  },
  placeholderMiniHeadline: {
    fontWeight: 500,
    fontSize: '.875rem',
    lineHeight: 1,
    color: colors.Purple700,
  },
  placeholderMainHeadline: {
    fontWeight: 500,
    fontSize: '1.125rem',
    lineHeight: 1.56,
    color: colors.Black,
  },
  tableContainer: {
    position: 'relative',
    border: `1px solid ${colors.Purple100}`,
    borderRadius: theme.spacing(2),
    background: colors.White,
    '& .MuiTableCell-root': {
      borderBottom: `1px solid ${colors.Purple100}`,
    },
    '& .MuiTableRow-root:last-child .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  headerRow: {
    background: colors.White,
    borderBottom: `1px solid ${colors.Purple100}`,
    '& .MuiTableCell-root': {
      padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
      fontFamily: `'Roboto Mono', monospaced`,
      fontWeight: 700,
      fontSize: '.75rem',
      lineHeight: 1.67,
      letterSpacing: '0.08em',
      textTransform: 'uppercase',
      color: colors.Dusk,
    },
    '& $checkboxCell': {
      alignItems: 'center',
      '& .MuiIconButton-label': {
        top: 0,
      },
    },
  },
  row: {
    cursor: 'pointer',
    fontWeight: 400,
    fontSize: '.875rem',
    lineHeight: 1.43,
    color: colors.Dusk,
    verticalAlign: 'top',
    background: colors.White,
    '&:hover': {
      background: colors.Purple50,
    },
  },
  selectedRow: {
    background: colors.Green100,
    '&:hover': {
      background: colors.Green100,
    },
  },
  checkboxCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    gap: theme.spacing(3),
    '& .MuiCheckbox-root': {
      padding: 0,
    },
  },
  teamCell: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  tagCell: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'start',
    gap: theme.spacing(2),
    '& .MuiChip-root': {
      marginTop: theme.spacing(-0.5),
    },
  },
  tagPlaceholder: {
    fontWeight: 400,
    fontSize: '.875rem',
    lineHeight: 1.43,
    color: '#828282',
  },
  memberName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontWeight: 800,
    fontSize: '.875rem',
    lineHeight: 1.43,
    color: colors.Midnight,
  },
  companyRole: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontWeight: 400,
    fontSize: '.75rem',
    lineHeight: 1.67,
    color: '#4F4F4F',
  },
  organizationRole: {
    fontFamily: `'Roboto Mono', monospaced`,
    fontWeight: 400,
    fontSize: '.75rem',
    lineHeight: 1.67,
    color: colors.Main,
  },
  teamButton: {
    textAlign: 'left',
    fontWeight: 700,
    fontSize: '.75rem',
    lineHeight: 1.67,
    textDecorationLine: 'underline',
    color: '#828282',
    transition: 'color 300ms ease-in-out',
    '&:hover': {
      color: colors.Main,
    },
  },
  automatedTableTag: {},
  tableTag: {},
  emptyTable: {},
  checkboxCellPadding: {},
});
