// @ts-strict-ignore
import { css } from '@emotion/react';
import { ClickAwayListener, Popper } from '@material-ui/core';
import ChevronDown from '@mysteryco/design/icons/ChevronDown';
import { colors } from '@mysteryco/design/src';
import OptionMenu from 'glue/components/inputs/dropdown/OptionMenu';
import { useState } from 'react';
import theme from 'theme';

type Props = {
  options: any[];
  value: any;
  onChange?: (newValue?: any) => void;
  label?: React.ReactNode;
  pill?: React.ReactNode;
  [key: string]: any;
};

const SelectHeader = ({ options, value, onChange, label, pill, ...props }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: any) => {
    if (!anchorEl) {
      setAnchorEl(event?.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (newValue: string[]) => {
    if (!newValue?.length) return;
    onChange(newValue[0]);
    handleClose();
  };

  const open = !!anchorEl;

  return (
    <div css={styles.root}>
      <div css={styles.pill}>{pill}</div>
      <div css={styles.header} onClick={handleClick}>
        <h2 css={styles.label}>{label}</h2>
        <div css={[styles.icon, open && styles.expanded]}>
          <ChevronDown size={24} />
        </div>
      </div>
      <Popper
        open={open}
        anchorEl={anchorEl}
        disablePortal
        placement={'bottom-start'}
        modifiers={{ flip: { enabled: false } }}
        css={styles.popper}
        style={{ width: anchorEl?.clientWidth }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <OptionMenu
            options={options}
            values={[value]}
            onChange={(_, newValue) => handleChange(newValue)}
            multiple={false}
            disableUpdate
            disableCreate
            disableDelete
            disableSearch
            helperText='Select a view'
            {...props}
          />
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

const styles = {
  root: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    gap: theme.spacing(2),
  }),
  header: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'botton',
    alignSelf: 'stretch',
    padding: 0,
    gap: theme.spacing(2),
    cursor: 'pointer',
  }),
  pill: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    background: colors.Glue_Mint00,
    borderRadius: 50,
    fontWeight: 400,
    fontSize: '1.5rem',
    lineHeight: 1.17,
    letterSpacing: '-0.04em',
    color: colors.Glue_Ink00,
  }),
  label: css({
    fontWeight: 500,
    fontSize: '1.75rem',
    lineHeight: 1.4,
    color: colors.Glue_Ink00,
    margin: 0,
  }),
  icon: css({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'transform 300ms ease-in-out',
    '& svg': {
      color: colors.Glue_Ink00,
    },
  }),
  expanded: css({
    transform: 'rotate(-180deg)',
  }),
  popper: css({
    zIndex: theme.zIndex.modal,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)} 0`,
    gap: theme.spacing(5),
    background: colors.Glue_Paper,
    border: `1px solid ${colors.Glue_Ink30}`,
    boxShadow: '0px 1px 10px rgba(222, 227, 243, 0.5)',
    borderRadius: theme.spacing(2),
  }),
};

export default SelectHeader;
