import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import puzzleConnectionIllustration from 'assets/illustrations/puzzle-connection@2x.png';
import BadgeChip from 'components/BadgeChip';
import CalendarSyncButtons from 'components/CalendarSyncButtons';
import RegistrationStepWrapper from 'components/Checkout/styling/RegistrationStepWrapper';
import AlertHexagon from 'components/icons/AlertHexagon';
import { useEffect } from 'react';
import { CustomerType, NylasConnectionState } from 'types';

const ILLUSTRATION_SIZE = 188;

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(8),
    alignItems: 'center',
  },
  bodyTypography: {
    display: 'flex',
    flexDirection: 'column',
  },
  subTitle: {
    marginBottom: theme.spacing(3),
    lineHeight: 'normal',
  },
  bodyIllustrationContainer: {
    marginLeft: theme.spacing(3),
  },
  bodyIllustration: {
    width: ILLUSTRATION_SIZE,
  },
  buttonLabel: {
    marginBottom: theme.spacing(3),
    fontWeight: 700,
  },
}));

const subTitle = 'Make it easy for your team to organize events when everyone can join';
const description = 'Connect your calendar and we will take it from there';
const buttonLabel = 'Select your calendar platform - this will take just a few clicks!';
const registrationStepLabel = 'Events are more fun when everyone is there!';

const CalendarSync = ({ viewer, ...otherProps }) => {
  const classes = useStyles();
  const disabled = () => {
    return (
      viewer?.orgs?.[0]?.customerType === CustomerType.EventsAndInsights &&
      viewer?.nylasConnectionState === NylasConnectionState.Inactive
    );
  };

  useEffect(() => {
    if (viewer.nylasConnectionState !== NylasConnectionState.Inactive) {
      otherProps.next();
    }
  }, [viewer.nylasConnectionState]);

  return (
    <RegistrationStepWrapper
      style={{ padding: '0 24px' }}
      {...otherProps}
      label={registrationStepLabel}
      nextButtonProps={{ disabled: disabled() }}
      hideContinueButton
      showSkipLink
      badgeElement={<BadgeChip text='Recommended' iconElement={<AlertHexagon />} />}
    >
      <Box className={classes.bodyContainer}>
        <Box className={classes.bodyTypography}>
          <Typography variant='h6' className={classes.subTitle}>
            {subTitle}
          </Typography>
          <Typography variant='body2'>{description}</Typography>
        </Box>
        <Box className={classes.bodyIllustrationContainer}>
          <img
            className={classes.bodyIllustration}
            src={puzzleConnectionIllustration}
            alt='connect calendar'
          />
        </Box>
      </Box>
      <Typography variant='body2' className={classes.buttonLabel}>
        {buttonLabel}
      </Typography>
      <CalendarSyncButtons />
    </RegistrationStepWrapper>
  );
};

export default CalendarSync;
