// @ts-strict-ignore
import { Collapse, Typography } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@mysteryco/design';
import glueMysteryImage from 'assets/catalog/glue-mystery-event.png';
import AlertBar from 'components/AlertBar';
import ExperienceCard from 'components/Catalog/Card';
import ExperienceDrawer from 'components/Catalog/Drawer';
import ExperienceFilter, { FilterType } from 'components/Catalog/Filter';
import { EncircleIcon } from 'components/icons/EncircleIcon';
import InfoCircleFilled from 'components/icons/InfoCircleFilled';
import Settings04 from 'components/icons/Settings04';
import { DEFAULT_SURPRISE_TO_ALL_BANDS, getTemplatePrice } from 'lib/helpers/money';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import { useContext, useMemo, useState } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import theme from 'theme';
import {
  ContractUnitType,
  MysteryTemplate,
  TemplateCatalogQuery,
  ViewerFragmentFragment,
  Xperience,
  XperienceCategory,
  XperienceStatus,
  useTemplateCatalogQuery,
} from 'types';
import { TemplateWithExperienceInfo } from 'types/selfServeFlow';

export type ExperienceFromQuery =
  TemplateCatalogQuery['mysteryTemplatesConnection']['edges'][0]['node']['experiences'][0];

export type TemplateFromQuery =
  TemplateCatalogQuery['mysteryTemplatesConnection']['edges'][0]['node'];

const RECOMMENDED_TEMPLATE_IDS = [
  '10b88761-f0c4-4376-aa1d-e5c63f2550eb', // Virtual Escape Room (Intermediate)
  '3901e569-57b2-4ed3-865c-322f0eaf0406', // Hot Takes
  '3284b153-7732-4cd1-a75d-1aabc3f818fd', // Black History Month: Where It Started Virtual Tour
];

const INTERNAL_PARTNER_NAME = 'Internal Glue Experiences';

const STA_TEMPLATE = {
  id: 'sta-template-id',
  title: 'Surprise to all',
  description: `Experience the Science of Surprise, Glue's own secret formula for building strong team connections. From thousands of events we've learned that people love the unexpected. We'll gather input from guests and plan a surprise that everyone will love, guaranteed!`,
  photoUrl: glueMysteryImage,
  estimatedDurationMins: 60,
  rangeIdealGuests: [6, 1000],
  connectionFocusAreas: [],
  includesBreakoutRooms: false,
  categories: [
    {
      id: 'surprise-category',
      name: 'Surprise',
    },
  ],
  cost: {
    ctcPricingBands: DEFAULT_SURPRISE_TO_ALL_BANDS,
  },
} as MysteryTemplate;

const STA_EXPERIENCE = {
  id: 'sta-experience-id',
  hasPhysicalGoods: false,
  priceLevel: 1,
  numMinGuests: 6,
  numMaxGuests: 1000,
  breakoutRoomSize: 1,
  templates: [STA_TEMPLATE],
  isVirtual: true,
  isActive: true,
  name: 'STA Experience',
  requiresUpgrade: false,
  status: XperienceStatus.CompletedReviewed,
} as unknown as ExperienceFromQuery;

STA_TEMPLATE.experiences = [STA_EXPERIENCE] as unknown as Xperience[];

const existsAndIsActive = (experience: ExperienceFromQuery) =>
  experience && experience.isActive;

const isInternal = (template: TemplateFromQuery) =>
  template.experiences.some(
    (experience) => experience.partner.name === INTERNAL_PARTNER_NAME,
  );

const NavArrow = ({ scrollAction, icon }) => {
  const context = useContext(VisibilityContext);
  const classes = useStyles();
  return (
    <span onClick={() => context[scrollAction]()} className={classes.categoryNavArrow}>
      <EncircleIcon backgroundColor={colors.White} padding={'6px'}>
        {icon}
      </EncircleIcon>
    </span>
  );
};

export const ExperienceCatalog = ({
  setSelectedTemplate,
  setSurpriseToAllView,
  viewer,
  totalGuests,
}: {
  setSelectedTemplate: (selectedTemplate: TemplateWithExperienceInfo) => void;
  setSurpriseToAllView: (staView: boolean) => void;
  viewer: ViewerFragmentFragment;
  totalGuests: number;
}) => {
  const classes = useStyles();
  const [activeTemplate, setActiveTemplate] = useState<TemplateFromQuery>(null);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedThemes, setSelectedThemes] = useState<string[]>([]);
  const [priceRange, setPriceRange] = useState<number[]>([]);
  const [durationRange, setDurationRange] = useState<number[]>([]);
  const [groupSizeRange, setGroupSizeRange] = useState<number[]>([]);
  const [mismatchedGuestRange, setMismatchedGuestRange] = useState<number[]>([]);
  const { data, loading } = useTemplateCatalogQuery();
  const contract = viewer.orgs[0]?.activeContract;
  const contractType = contract?.type || ContractUnitType.Cents;
  const {
    experiences,
    minPrice,
    maxPrice,
    minDuration,
    maxDuration,
    minGroupSize,
    maxGroupSize,
    categories,
    themes,
    templates,
    templateExperiencesMap,
  } = useMemo(() => {
    if (loading || !data?.mysteryTemplatesConnection?.edges) {
      return {};
    }
    const experiences = data?.mysteryTemplatesConnection.edges.flatMap((edge) => {
      const template = edge.node;
      return template?.experiences?.filter(existsAndIsActive);
    });

    const templates = data?.mysteryTemplatesConnection?.edges
      ?.map((edge) => edge.node)
      .filter((template) => template.experiences.filter(existsAndIsActive).length > 0)
      .sort((templateA, templateB) => {
        const templateAConnectednessScore = Math.max(
          ...templateA.experiences.map((experience) => experience.connectednessScore),
        );
        const templateBConnectednessScore = Math.max(
          ...templateB.experiences.map((experience) => experience.connectednessScore),
        );
        const scoreDiff = templateBConnectednessScore - templateAConnectednessScore;
        if (scoreDiff !== 0) {
          return scoreDiff;
        }
        return isInternal(templateA) ? -1 : isInternal(templateB) ? 1 : 0;
      });

    const templateExperiencesMap: Record<string, typeof experiences> = templates.reduce(
      (map, template) => {
        return {
          ...map,
          [template.id]: template.experiences.filter(existsAndIsActive),
        };
      },
      {},
    );

    const prices = _.compact(
      templates.map((template) => getTemplatePrice({ template, contractType })),
    );
    const durations = templates.map((template) => template.estimatedDurationMins);
    return {
      experiences,
      templates,
      templateExperiencesMap,
      minPrice: Math.min(...prices),
      maxPrice: Math.max(...prices),
      minDuration: Math.min(...durations),
      maxDuration: Math.max(...durations),
      minGroupSize: Math.min(...experiences.map((experience) => experience.numMinGuests)),
      maxGroupSize: Math.max(...experiences.map((experience) => experience.numMaxGuests)),
      categories: [
        ...new Set(
          templates.flatMap((template) =>
            template.categories.map((category: XperienceCategory) => category.name),
          ) as string[],
        ),
      ],
      themes: [
        ...new Set(
          _.compact(templates.map((template) => template.theme?.title) as string[]),
        ),
      ],
    };
  }, [data]);
  const filteredTemplates = useMemo(() => {
    if (!templates) {
      return [];
    }
    return templates.filter((template) => {
      const price = getTemplatePrice({ template, contractType });

      return !(
        priceRange[0] > price ||
        priceRange[1] < price ||
        durationRange[0] > template.estimatedDurationMins ||
        durationRange[1] < template.estimatedDurationMins ||
        (selectedThemes.length && !selectedThemes.includes(template.theme?.title)) ||
        groupSizeRange[0] >
          Math.min(
            ...template.experiences.map((experience) => experience.numMinGuests),
          ) ||
        groupSizeRange[1] <
          Math.max(
            ...template.experiences.map((experience) => experience.numMaxGuests),
          ) ||
        (selectedCategories.length &&
          !_.intersection(
            template.categories.map((c) => c.name),
            selectedCategories,
          ).length)
      );
    });
  }, [
    experiences,
    priceRange,
    durationRange,
    selectedThemes,
    groupSizeRange,
    selectedCategories,
  ]);

  const handleCategoryClick = (category: string) => {
    if (selectedCategories.includes(category)) {
      const categoriesToKeep = selectedCategories.filter(
        (selectedCategory) => selectedCategory !== category,
      );
      setSelectedCategories(categoriesToKeep);
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  return (
    <div>
      {!loading && (
        <div className={classes.catalogWrapper}>
          <div className={classes.header}>
            <p>Choose your event</p>
          </div>
          {/* TOP EVENTS */}
          <div className={classes.topEvents}>
            <div className={classes.title}>Most popular events</div>
            <div className={classes.subtitle}>
              You can't go wrong with these highly rated events!
            </div>
            <div className={classes.cardsRow}>
              <div
                className={classes.eventCell}
                key={STA_EXPERIENCE.id}
                onClick={() => setActiveTemplate(STA_TEMPLATE as TemplateFromQuery)}
              >
                <ExperienceCard
                  template={STA_TEMPLATE}
                  experiences={[STA_EXPERIENCE] as Xperience[]}
                  contractType={contractType}
                  totalGuests={totalGuests}
                />
              </div>
              {RECOMMENDED_TEMPLATE_IDS.map((id: string) => {
                if (!templates) {
                  return null;
                }
                const template = templates.find((template) => template.id === id);
                if (!template) {
                  return null;
                }
                return (
                  <div
                    className={classes.eventCell}
                    key={template.id}
                    onClick={() => setActiveTemplate(template)}
                  >
                    <ExperienceCard
                      template={template as MysteryTemplate}
                      experiences={templateExperiencesMap[template.id] as Xperience[]}
                      contractType={contractType}
                      totalGuests={totalGuests}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          {/* OTHER EVENTS */}
          <div className={classes.catalogList}>
            <div className={classes.title}>Our catalog</div>
            {/* MAIN FILTERS */}
            <div className={classes.mainFilters}>
              <div className={classes.eventFilters}>
                {contractType !== ContractUnitType.Recurring && (
                  <ExperienceFilter
                    name={'cost'}
                    caption={'Set your price range per person'}
                    type={FilterType.RANGE}
                    min={minPrice}
                    max={maxPrice}
                    step={contractType === ContractUnitType.Credits ? 1 : 10}
                    unit={contractType === ContractUnitType.Credits ? 'credit' : 'dollar'}
                    selectedRange={priceRange}
                    onClick={() => {
                      mixpanel.track('cost filter changed', {
                        source: 'catalog',
                        customerType: viewer?.customerType,
                        firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
                      });
                    }}
                    setSelectedRange={setPriceRange}
                  />
                )}
                <ExperienceFilter
                  name={'duration'}
                  caption={'Desired event duration'}
                  type={FilterType.RANGE}
                  min={minDuration}
                  max={maxDuration}
                  step={5}
                  unit={'minute'}
                  selectedRange={durationRange}
                  onClick={() => {
                    mixpanel.track('time filter changed', {
                      source: 'catalog',
                      customerType: viewer?.customerType,
                      firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
                    });
                  }}
                  setSelectedRange={setDurationRange}
                />
                <ExperienceFilter
                  name={'groupSize'}
                  caption={'Recommended group size'}
                  type={FilterType.RANGE}
                  min={minGroupSize}
                  max={maxGroupSize}
                  step={1}
                  unit={'guest'}
                  selectedRange={groupSizeRange}
                  setSelectedRange={(range: number[]) => setGroupSizeRange(range)}
                />
                {themes.length > 0 && (
                  <ExperienceFilter
                    name={'collection'}
                    caption={'Select one or many'}
                    type={FilterType.OPTIONS}
                    options={themes}
                    selectedOptions={themes.map((theme) =>
                      selectedThemes.includes(theme),
                    )}
                    setSelectedOptions={(options: boolean[]) => {
                      setSelectedThemes(themes.filter((_theme, i) => options[i]));
                    }}
                  />
                )}
              </div>
              {/* CATEGORY FILTERS */}
              <div className={classes.categoryNav}>
                <ScrollMenu
                  LeftArrow={
                    <NavArrow
                      scrollAction={'scrollPrev'}
                      icon={<KeyboardArrowLeft className={classes.arrowIcon} />}
                    />
                  }
                  RightArrow={
                    <NavArrow
                      scrollAction={'scrollNext'}
                      icon={<KeyboardArrowRight className={classes.arrowIcon} />}
                    />
                  }
                >
                  {categories?.map((category, i) => (
                    <div
                      key={i}
                      itemID={`${i}`}
                      className={
                        selectedCategories.includes(category)
                          ? classes.activeCategoryPill
                          : classes.categoryPill
                      }
                      onClick={() => handleCategoryClick(category)}
                    >
                      {category}
                    </div>
                  ))}
                </ScrollMenu>
              </div>
            </div>
            {/* SECONDARY FILTERS */}
            <div className={classes.secondaryFilters}>
              <span>{`MATCHES: ${filteredTemplates.length} EVENTS`}</span>
            </div>

            <div>
              <Collapse in={mismatchedGuestRange.length > 0}>
                <AlertBar
                  text={`FYI: Your selected event cannot accommodate ${
                    mismatchedGuestRange[0] > totalGuests
                      ? `less than ${mismatchedGuestRange[0]}`
                      : `more than ${mismatchedGuestRange[1]}`
                  } guests. Select a different event.`}
                  icon={<InfoCircleFilled />}
                  severity='error'
                />
              </Collapse>
            </div>
            {/* EVENTS */}
            <div className={classes.cardsRow}>
              {filteredTemplates.length > 0 ? (
                filteredTemplates.map((template) => (
                  <div
                    className={classes.eventCell}
                    key={template.id}
                    onClick={() => {
                      mixpanel.track('clicked experience card', {
                        source: 'catalog',
                        name: template.title,
                        customerType: viewer?.customerType,
                        firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
                      });
                      setActiveTemplate(template);
                    }}
                  >
                    <ExperienceCard
                      template={template as MysteryTemplate}
                      contractType={contractType}
                      experiences={templateExperiencesMap[template.id] as Xperience[]}
                      totalGuests={totalGuests}
                    />
                  </div>
                ))
              ) : (
                <div className={classes.emptyMessage}>
                  <div className={classes.emptyMessageIcon}>
                    <Settings04 className={classes.emptyMessageIconShape} />
                  </div>
                  <Typography className={classes.emptyMessageSubheadline}>
                    No matches
                  </Typography>
                  <Typography variant={'h6'}>
                    Try a different search to find that perfect event
                  </Typography>
                </div>
              )}
            </div>
          </div>
          {activeTemplate && (
            <ExperienceDrawer
              open={true}
              template={activeTemplate}
              experiences={templateExperiencesMap[activeTemplate.id] || []}
              contract={contract}
              onClose={() => setActiveTemplate(null)}
              onSelectExperience={() => {
                const lowestMinimumGuests = Math.min(
                  ...activeTemplate.experiences.map(
                    (experience) => experience.numMinGuests,
                  ),
                );
                const highestMaximumGuests = Math.max(
                  ...activeTemplate.experiences.map(
                    (experience) => experience.numMaxGuests,
                  ),
                );
                if (
                  totalGuests > highestMaximumGuests ||
                  totalGuests < lowestMinimumGuests
                ) {
                  setMismatchedGuestRange([lowestMinimumGuests, highestMaximumGuests]);
                  return setActiveTemplate(null);
                }
                if (activeTemplate.id === STA_TEMPLATE.id) {
                  // Truly a hack for the STA experience
                  mixpanel.track('selected experience', {
                    name: 'Surprise to all',
                    customerType: viewer?.customerType,
                    firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
                  });
                  setSelectedTemplate(null);
                  setSurpriseToAllView(true);
                } else {
                  mixpanel.track('selected experience', {
                    name: activeTemplate.title,
                    customerType: viewer?.customerType,
                    firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
                  });
                  const templateWithExperienceInfo = {
                    ...activeTemplate,
                    experiences: templateExperiencesMap[activeTemplate.id].map(
                      (experience) => ({
                        ...experience,
                        estimatedDurationMins: activeTemplate.estimatedDurationMins,
                      }),
                    ),
                  };
                  setSelectedTemplate(
                    templateWithExperienceInfo as unknown as TemplateWithExperienceInfo,
                  );
                }
                window.scrollTo(0, 0);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  catalogWrapper: {
    maxWidth: '1140px',
    margin: 'auto',
  },
  eventCell: {
    height: '338px',
    '@media (max-width: 700px)': {
      flex: '0 0 100%',
    },
  },
  topEvents: {
    '& $subtitle': {
      marginBottom: '12px',
    },
    '@media (min-width: 1300px)': {
      height: '420px',
    },
  },
  catalogList: {
    padding: '20px 0 80px',
    // Prevent jumping when we filter the list to fewer than fit on a page.
    minHeight: '100vh',
  },
  header: {
    fontWeight: 400,
    fontSize: '48px',
    lineHeight: '48px',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(6),
  },
  title: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '28px',
    color: colors.Dusk,
    marginBottom: theme.spacing(1),
    '@media (max-width: 700px)': {
      textAlign: 'center',
    },
  },
  subtitle: {
    fontSize: '16px',
    lineHeight: '24px',
    color: colors.Midnight,
    '@media (max-width: 700px)': {
      textAlign: 'center',
    },
  },
  mainFilters: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: `${theme.spacing(4)} 0`,
    flexWrap: 'wrap',
    gap: '12px',
    justifyContent: 'space-between',
    '& > *': {
      marginRight: theme.spacing(4),
    },
    '@media (max-width: 700px)': {
      alignItems: 'center',
    },
  },
  eventFilters: {
    display: 'flex',
    gap: '12px',
    flexWrap: 'wrap',
    maxWidth: '100%',
    '@media (max-width: 1200px)': {
      justifyContent: 'center',
    },
  },
  cardsRow: {
    display: 'flex',
    gap: '28px',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'row',
    padding: '20px 0',
    flexWrap: 'wrap',
    '@media (max-width: 700px)': {
      flexDirection: 'column',
    },
  },
  categoryNav: {
    maxWidth: '900px',
    flex: 1,
    overflow: 'hidden',
    display: 'flex',
    margin: 0,
    '@media (max-width: 1200px)': {
      maxWidth: '100%',
    },
    '@media (max-width: 700px)': {
      margin: '12px 0',
    },
    '& > *': {
      width: '100%',
      '& > *': {
        alignSelf: 'center',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      },
    },
    // Disable scrollbar
    '& .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar': {
      display: 'none',
    },
    '& .react-horizontal-scrolling-menu--scroll-container': {
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
    },
  },
  categoryPill: {
    fontWeight: 500,
    lineHeight: '20px',
    color: colors.Dusk,
    padding: '4px 12px',
    background: colors.White,
    border: `1px solid ${colors.Purple200}`,
    borderRadius: '16px',
    marginRight: theme.spacing(2),
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      background: colors.Purple100,
      borderColor: colors.Purple300,
    },
  },
  activeCategoryPill: {
    fontWeight: 500,
    lineHeight: '20px',
    color: colors.White,
    padding: '4px 12px',
    border: `1px solid ${colors.Purple800}`,
    background: colors.Purple800,
    borderRadius: '16px',
    marginRight: theme.spacing(2),
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
  secondaryFilters: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '12px',
    marginBottom: theme.spacing(8),
    '& > span': {
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.08em',
      color: theme.palette.grey[700],
    },
    '& > div': {
      display: 'flex',
    },
  },
  switchContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '&:last-of-type': {
      marginLeft: '12px',
    },
  },
  toggleLabel: {
    fontWeight: 500,
    marginRight: theme.spacing(2),
    fontSize: '14px',
    color: theme.palette.grey[700],
  },
  categoryNavArrow: {
    margin: `0 ${theme.spacing(1)}`,
    cursor: 'pointer',
    border: '1px solid #BDBDBD',
    borderRadius: '50%',
    transition: 'all .3s ease-in-out',
    '&:hover': {
      borderColor: colors.Purple700,
    },
  },
  arrowIcon: {
    fontSize: '14px',
  },
  emptyMessage: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyMessageIcon: {
    width: '44px',
    height: '44px',
    backgroundColor: colors.Purple100,
    borderRadius: '50%',
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  emptyMessageIconShape: {
    fill: colors.Purple500,
    marginTop: '2px',
    fontSize: theme.spacing(12),
  },
  emptyMessageSubheadline: {
    color: '#A35DA8',
    fontWeight: 800,
    fontSize: '0.8rem',
    lineHeight: '2rem',
  },
});

export default ExperienceCatalog;
