import { css } from '@emotion/react';
import { Checkbox } from '@material-ui/core';
import {
  Coal_10,
  Coal_30,
  Coal_40,
  Ruby_50,
  Ruby_60,
} from '@mysteryco/design/src/tokens/colors';
import { Dispatch, SetStateAction } from 'react';
import { MeetupsState } from '.';
import theme from 'theme';

const DisableMeetups = ({
  setMeetupsState,
}: {
  setMeetupsState: Dispatch<SetStateAction<MeetupsState>>;
}) => (
  <div css={styles.container}>
    <div css={styles.stripes} />
    <div css={styles.content}>
      <span css={styles.title}>{strings.title}</span>
      <span css={styles.subtitle}>{strings.subtitle}</span>
      <p css={styles.explainer}>{strings.explainer}</p>
      {/* This is a little hacky, I'd like to replace this with a Radix checkpoint component */}
      <div css={styles.checkboxSection}>
        <Checkbox
          onClick={(e) => {
            setMeetupsState(
              (e.target as unknown as { checked: boolean }).checked
                ? MeetupsState.DisablingConfirmed
                : MeetupsState.Disabling,
            );
          }}
        />
        <span css={styles.checkboxLabel}>{strings.confirm}</span>
      </div>
    </div>
  </div>
);

const strings = {
  title: 'Disabling Meetups',
  subtitle: 'Are you sure you want to disable Meetups?',
  explainer:
    'When you disable Meetups we stop connecting people based on their interests and connection needs. Meetups that have already been scheduled will stay on the calendar but we won’t schedule any future Meetups. You can update your Meetups cohort at any time to re-enable.',
  confirm: 'I understand',
};

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    borderRadius: theme.spacing(2),
    overflow: 'hidden',
    fontFamily: 'Manrope',
    maxWidth: '700px',
    border: `solid 1px ${Coal_40}`,
  }),
  stripes: css({
    width: '100%',
    height: theme.spacing(8),
    background: `repeating-linear-gradient(120deg, ${Ruby_50}, ${Ruby_50} 20px, ${Ruby_60} 20px, ${Ruby_60} 40px)`,
  }),
  content: css({
    margin: theme.spacing(12),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
    color: Coal_10,
  }),
  title: css({ fontSize: theme.spacing(7), fontWeight: 500 }),
  subtitle: css({ fontSize: theme.spacing(5), fontWeight: 700 }),
  explainer: css({ fontSize: theme.spacing(4), lineHeight: theme.spacing(6) }),
  checkboxSection: css({
    border: `solid 1.5px ${Coal_30}`,
    borderRadius: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'fit-content',
  }),
  checkboxLabel: css({
    paddingRight: '9px',
  }),
};

export default DisableMeetups;
