import { gql } from '@apollo/client';
import { Loading } from 'components/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import { TaskFlowSubType, TaskFlowType, useOnboardingTaskQuery } from 'types';
import { OrgCalendarIntegration } from './OrgCalendarIntegration';

/**
 * Props that every component managing a flow will receive
 */
export type TaskFlowComponentProps = {
  taskFlowId: string;
  flow: TaskFlowType;
  subFlow: TaskFlowSubType;
};

// Add your components for managing flows here
const flowMap: Record<TaskFlowType, (props: TaskFlowComponentProps) => JSX.Element> = {
  [TaskFlowType.OrgCalendarIntegration]: OrgCalendarIntegration,
  [TaskFlowType.OrgStructure]: () => <div />,
  [TaskFlowType.OrgSlackIntegration]: () => <div />,
  [TaskFlowType.PersonalPreferences]: () => <div />,
  [TaskFlowType.PersonalCalendarIntegration]: () => <div />,
  // Currently unused
  [TaskFlowType.AcceptTermsOfService]: () => <div />,
};

/**
 * This component should not need to be changed often. All you should need to do is add your flow component to the map.
 */
export const OnboardingTask = () => {
  const { id: taskId } = useParams<{ id: string }>();
  const { loading, data, error } = useOnboardingTaskQuery({
    variables: { id: taskId },
  });
  if (loading || !data) {
    return <Loading />;
  }
  const taskFlow = data?.taskFlow;
  if (!taskFlow || error) {
    // TODO: Replace with a 404, or redirect back to the to-do list
    return <div>Task not found</div>;
  }

  const flowComponent = flowMap[taskFlow.flow];
  return React.createElement(flowComponent, {
    taskFlowId: taskId,
    flow: taskFlow.flow,
    subFlow: taskFlow.subFlow,
  });
};

OnboardingTask.query = gql`
  query OnboardingTask($id: ID!) {
    taskFlow(id: $id) {
      id
      flow
      subFlow
    }
  }
`;
