import { cx } from '@emotion/css';
import { ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@mysteryco/design';
import theme from 'theme';

const FlatButton = ({
  variant = 'primary',
  corners = 'partiallyRounded',
  disabled = false,
  fullWidth = false,
  onClick = () => {},
  children,
  ...props
}: {
  variant?: 'primary' | 'secondary';
  corners?: 'rounded' | 'partiallyRounded';
  disabled?: boolean;
  fullWidth?: boolean;
  onClick?: (event: any) => void;
  children: any;
  [key: string]: any;
}) => {
  const classes = useStyles();
  const buttonClasses = cx(
    classes.button,
    classes[variant],
    classes[corners],
    fullWidth && classes.fullWidth,
    props?.className,
  );
  return (
    <ButtonBase
      disabled={disabled}
      onClick={onClick}
      {...props}
      className={buttonClasses}
    >
      {children}
    </ButtonBase>
  );
};

const useStyles = makeStyles({
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
    gap: theme.spacing(2.5),
    border: `1px solid ${colors.Main}`,
    transition: 'all 300ms ease-in-out',
    fontSize: '.875rem',
    lineHeight: 1,
    '&:hover': {
      borderColor: colors.Purple400,
    },
    '&:focus': {
      borderColor: colors.Dusk,
    },
    '&.Mui-disabled': {
      color: colors.MediumGray,
      background: colors.OffWhite,
      borderColor: '#D5DCDE',
      '& > svg': {
        color: colors.MediumGray,
      },
    },
  },
  rounded: {
    borderRadius: theme.spacing(14.5),
  },
  partiallyRounded: {
    borderRadius: theme.spacing(2),
  },
  primary: {
    color: colors.White,
    background: colors.Main,
    '& > svg': {
      color: colors.White,
    },
    '&:hover': {
      background: colors.Dusk,
    },
    '&:focus': {
      background: colors.Dusk,
    },
  },
  secondary: {
    color: colors.Main,
    background: colors.White,
    '& > svg': {
      color: colors.Main,
    },
    '&:hover': {
      background: colors.Purple100,
    },
    '&:focus': {
      color: colors.White,
      background: colors.Dusk,
      borderColor: colors.Dusk,
      '& > svg': {
        color: colors.White,
      },
    },
  },
  fullWidth: {
    width: '100%',
  },
});

export default FlatButton;
