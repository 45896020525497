import { css } from '@emotion/react';
import ArrowLeft from '@mysteryco/design/icons/ArrowLeft';
import ArrowRight from '@mysteryco/design/icons/ArrowRight';
import { colors } from '@mysteryco/design/src';
import { useField, useFormikContext } from 'formik';
import Button from 'glue/components/buttons/Button';
import { pluralize } from 'humanize-plus';
import mixpanel from 'mixpanel-browser';
import { useState } from 'react';
import theme from 'theme';

interface Props {
  back: () => void;
  questionId: string;
}

const strings = {
  shareYour: 'Share your thoughts',
  optional: 'Optional and anonymous',
  edit: 'Edit answer',
  continue: 'Continue',
};

const MAX_CHAR_LIMIT = 500;
const CHAR_WARNING_THRESHOLD = 100;

const PulseQuestionComment = ({ back, questionId }: Props) => {
  const { isSubmitting } = useFormikContext();
  const [fieldProps, field] = useField({
    name: 'comment',
  });
  const [length, setLength] = useState(fieldProps.value.length ?? 0);
  const handleChange = (event) => {
    setLength(event.target.value.length);
  };
  const charsRemaining = MAX_CHAR_LIMIT - length;
  const charsRemainingStr = `${charsRemaining} ${pluralize(
    charsRemaining,
    'character',
  )} left`;

  return (
    <div css={styles.root}>
      <div css={styles.description}>
        <div css={styles.shareText}>{strings.shareYour}</div>
        <div css={styles.optionalText}>{strings.optional}</div>
      </div>
      <textarea
        maxLength={MAX_CHAR_LIMIT}
        css={styles.input}
        id={`comment-${questionId}`}
        onKeyUp={handleChange}
        autoFocus
        {...fieldProps}
      />
      <div
        css={[
          styles.remainingChars,
          charsRemaining <= CHAR_WARNING_THRESHOLD && styles.warningRed,
        ]}
      >
        {charsRemainingStr}
      </div>
      <div css={styles.buttonContainer}>
        <Button
          shape='round'
          color='white'
          startIcon={<ArrowLeft size={22} color='currentColor' />}
          onClick={back}
        >
          {strings.edit}
        </Button>
        <Button
          shape='round'
          color='black'
          type='submit'
          endIcon={<ArrowRight size={22} color='currentColor' />}
          disabled={!field.value}
          onClick={() => {
            if (!!field.value) mixpanel.track('pls-comment-submitted');
          }}
          loading={isSubmitting}
        >
          {strings.continue}
        </Button>
      </div>
    </div>
  );
};

const styles = {
  root: css({
    color: colors.Glue_Ink00,
    margin: `${theme.spacing(8)} 0`,
    width: '320px',
    '@media (min-width: 600px)': {
      width: '440px',
    },
    '@media (min-width: 960px)': {
      width: '600px',
    },
  }),
  input: css({
    width: '100%',
    height: '490px',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '180%',
    padding: theme.spacing(3),
    border: `1px solid ${colors.Glue_BorderLight}`,
    '&:focus': {
      background: colors.Glue_LavenderLight,
      outline: 'none',
      border: `1.5px solid ${colors.Glue_Ink00}`,
    },
  }),
  description: css({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  }),
  buttonContainer: css({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(5),
  }),
  shareText: css({
    fontWeight: 800,
    fontSize: '16px',
    lineHeight: '140%',
  }),
  optionalText: css({
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '28px',
    color: `rgba(59, 59, 59, 0.6)`,
  }),
  remainingChars: css({
    fontSize: '16px',
    color: '#706A74', // Coal 20
    fontWeight: 700,
  }),
  warningRed: css({
    color: '#882121', // ruby 10
  }),
};

export default PulseQuestionComment;
