import { colors } from '@mysteryco/design';

const LogoMark = ({
  size = 78,
  color = colors.Glue_Ink00,
  background = colors.Glue_Mint00,
  style = {},
  className = '',
}) => {
  return (
    <svg
      className={className}
      style={style}
      height={size}
      width={size}
      fill='none'
      viewBox='0 0 48 48'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 24C0 10.7452 10.7452 0 24 0H44C46.2091 0 48 1.79086 48 4V24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z'
        fill={background}
      />
      <path
        d='M35 28.4324C35 33.5688 31.6902 37.7002 24.5 37.7002C18.6974 37.7002 15.5932 34.674 15.0341 31.2265C14.9654 30.8029 15.3109 30.4423 15.74 30.4423H19.7255C20.0888 30.4423 20.3892 30.7082 20.5266 31.0445C21.0077 32.2222 22.2758 33.0477 24.5 33.0477C27.5815 33.0477 29.1413 31.4844 29.1413 28.4324V26.3626C29.1413 26.2518 29.0515 26.162 28.9407 26.162C28.8756 26.162 28.815 26.1935 28.7763 26.2459C28.0521 27.2272 26.3119 28.8791 23.2065 28.8791C17.9946 28.8791 14 25.0454 14 18.718C14 12.4278 17.9185 8.51974 23.0543 8.51974C26.2264 8.51974 28.0753 10.1641 28.7398 11.1462C28.7775 11.202 28.8398 11.2368 28.9072 11.2368C29.0155 11.2368 29.1033 11.149 29.1033 11.0407V9.7536C29.1033 9.33939 29.439 9.0036 29.8533 9.0036H34.25C34.6642 9.0036 35 9.33939 35 9.7536V28.4324ZM24.2145 23.9835C26.8856 23.9835 29.0509 21.6332 29.0509 18.734C29.0509 15.8348 26.8856 13.4845 24.2145 13.4845C21.5435 13.4845 19.3782 15.8348 19.3782 18.734C19.3782 21.6332 21.5435 23.9835 24.2145 23.9835Z'
        fill={color}
        fillRule='evenodd'
      />
    </svg>
  );
};

export default LogoMark;
