import { css } from '@emotion/react';
import { colors } from '@mysteryco/design/src';
import Button from 'glue/components/buttons/Button';
import theme from 'theme';
import { Maybe } from 'types';
import MoralePulseSurveyFooter from './MoralePulseSurveyFooter';
import ArrowRight from '@mysteryco/design/icons/ArrowRight';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';

interface Props {
  next: () => void;
  name?: string;
  orgName?: Maybe<string>;
  logoUrl?: Maybe<string>;
}

const MoralePulseSurveyIntro = ({ next, name, orgName, logoUrl }: Props) => {
  useEffect(() => {
    mixpanel.track('pls-survey-loaded');
  }, []);

  return (
    <>
      <div css={styles.container}>
        <div css={styles.content}>
          {logoUrl && (
            <div>
              <img css={{ maxWidth: 350 }} src={logoUrl} alt={'Company logo'} />
            </div>
          )}
          <div css={styles.needsOpinion}>{`${orgName} needs your opinion`}</div>
          <div css={styles.helpUs}>
            {`Hi ${
              name || 'there'
            } 👋 Help us create a supportive and engaging workplace. Your input in
          this survey is very important to us.`}
          </div>
          <div css={styles.estimatedCompletion}>Estimated completion: 5 minutes</div>
          <Button
            shape='round'
            css={styles.getStartedButton}
            color='green'
            endIcon={<ArrowRight size={22} color='currentColor' />}
            onClick={next}
          >
            <div>Let's get started</div>
          </Button>
        </div>
      </div>
      <MoralePulseSurveyFooter />
    </>
  );
};

const styles = {
  brandImage: css({
    height: '25px',
  }),
  getStartedButton: css({
    width: '100%',
    marginTop: theme.spacing(10),
    justifyContent: 'center',
  }),
  estimatedCompletion: css({
    marginTop: theme.spacing(5),
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '20px',
    '@media (min-width: 960px)': {
      fontSize: 16,
      lineHeight: '28px',
    },
    alignSelf: 'flex-start',
  }),
  container: css({
    padding: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: colors.Glue_Ink00,
    flex: 1,
  }),
  content: css({
    width: '320px',
    '@media (min-width: 600px)': {
      width: '440px',
    },
    '@media (min-width: 960px)': {
      width: '600px',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(8),
  }),
  helpUs: css({
    fontWeight: 500,
    fontSize: 24,
    '@media (min-width: 960px)': {
      fontSize: 32,
    },
    lineHeight: '120%',
    textAlign: 'left',
    marginTop: theme.spacing(10),
  }),
  needsOpinion: css({
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '20px',
    '@media (min-width: 960px)': {
      fontSize: 22,
      lineHeight: '120%',
    },
    textAlign: 'center',
    color: 'rgba(59, 59, 59, 0.7)',
    marginTop: theme.spacing(5),
    alignSelf: 'flex-start',
  }),
};

export default MoralePulseSurveyIntro;
