import * as muiLab from "@material-ui/lab";
import { css } from '@emotion/react';

type SkeletonProps = Omit<muiLab.SkeletonTypeMap['props'], 'animation'> & React.HTMLAttributes<HTMLDivElement>;

const skeletonStyles = css({
  backgroundColor: 'rgba(242, 232, 243, 0.75)',
  ':after': {
    background: `linear-gradient(90deg, transparent 20%, #DDCFE7 50%, transparent 80%)`
  }
})

export const Skeleton = (props: SkeletonProps) => {
  return <muiLab.Skeleton {...props} animation='wave' css={skeletonStyles} />;
}
