import { css } from '@emotion/react';
import LifeBuoy01 from '@mysteryco/design/icons/LifeBuoy01';
import TextLink from 'glue/components/buttons/TextLink';
import InlineBanner from 'glue/components/structure/InlineBanner';
import { ReactNode } from 'react';
import theme from 'theme';

const strings = {
  questions: 'Questions?',
  weHave: 'We have answers on all things Pulse',
  help: 'Help',
};

interface PulseHelpBannerProps {
  to?: string;
  headline?: string;
  description?: string;
  /** Override the action link */
  action?: ReactNode;
}

const PulseHelpBanner = ({
  to = 'https://help.glue.co/knowledge/pulse-surveys',
  headline = strings.questions,
  description = strings.weHave,
  action,
}: PulseHelpBannerProps) => (
  <InlineBanner
    type='help'
    css={styles.helpBanner}
    headline={headline}
    description={description}
    action={
      action || (
        <TextLink Icon={LifeBuoy01} to={to} newTab>
          {strings.help}
        </TextLink>
      )
    }
  />
);

const styles = {
  helpBanner: css({
    marginTop: theme.spacing(15),
  }),
};

export default PulseHelpBanner;
