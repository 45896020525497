interface ManagerPartial {
  name?: string | null;
  isArchived?: boolean;
}

interface TeamPartial {
  name?: string | null;
  manager?: ManagerPartial | null;
}

export const defaultTeamName = (team: TeamPartial): string => {
  return team?.manager?.name ? `${team.manager.name}'s team` : 'No team';
};

export const isUsingDefaultTeamName = (team: TeamPartial): boolean => {
  return defaultTeamName(team).toLowerCase() === team?.name?.toLowerCase();
};

export const displayTeamName = (team: TeamPartial): string => {
  if (team?.manager?.name && !isUsingDefaultTeamName(team)) {
    return `${defaultTeamName(team)} - ${team?.name}`;
  } else {
    return defaultTeamName(team);
  }
};

export const isTeamDisabled = (team: TeamPartial): boolean => {
  return !!team?.manager?.isArchived;
};

export const teamToInitials = (team: { name: string }): string => {
  return team?.name
    ?.split(' ')
    .slice(0, 2)
    .map((word: string) => word.charAt(0).toUpperCase())
    .join('');
};
