// @ts-strict-ignore
import CheckoutError, {
  CheckoutErrorType,
} from 'components/Checkout/steps/CheckoutError';
import LogoWithCard from 'components/LogoWithCard';
import { isEventCompleted, isInstructor, linkIsValid } from 'lib/helpers/events';
import { DateTime } from 'luxon';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { InvitedGuestRsvpStatus, TeamEventFragmentFragment } from 'types';
import { getUserRsvpStatus, useViewer } from 'utils/state';
import EventEndedFlow from './EventEndedFlow';
import NormalFlow from './NormalFlow';
import TardyFlow from './TardyFlow';

const TARDY_THRESHOLD_MINUTES = 5;

const CheckoutFlow = ({ teamEvent }: { teamEvent: TeamEventFragmentFragment }) => {
  const viewer = useViewer();
  const [error, setError] = React.useState<CheckoutErrorType>(null);
  const minutesToEventStart = DateTime.fromISO(teamEvent.requestedFor)
    .diff(DateTime.now(), 'minutes')
    .toObject().minutes;
  const rsvpStatus = getUserRsvpStatus(viewer, teamEvent);
  const hasAcceptedRsvp = rsvpStatus === InvitedGuestRsvpStatus.Accepted;

  React.useEffect(() => {
    if (
      !hasAcceptedRsvp &&
      !linkIsValid({ signUpExpirationDate: teamEvent.signUpExpirationDate })
    ) {
      setError({
        message: `Sorry the sign up window for this event has expired!`,
        contact: {
          firstName: teamEvent.owner?.firstName,
          email: teamEvent.owner?.email,
        },
      });
    }
  }, []);

  const isTardy = minutesToEventStart < TARDY_THRESHOLD_MINUTES;
  const isEventInstructor = isInstructor({ teamEvent, viewer });
  const hasEventEnded = isEventCompleted(teamEvent);

  const renderFlow = () => {
    if (!hasAcceptedRsvp && hasEventEnded) {
      return <EventEndedFlow teamEvent={teamEvent} />;
    }

    if (!hasAcceptedRsvp && isTardy) {
      return <TardyFlow teamEvent={teamEvent} viewer={viewer} />;
    }

    if (isEventInstructor || hasEventEnded || isTardy) {
      return <Redirect to={`/event-details/${teamEvent.id}`} />;
    }

    if (error) {
      return <CheckoutError error={error} teamEvent={teamEvent} />;
    }

    return <NormalFlow teamEvent={teamEvent} viewer={viewer} />;
  };

  return <LogoWithCard>{renderFlow()}</LogoWithCard>;
};

export default CheckoutFlow;
