import { gql } from '@apollo/client';
import { css } from '@emotion/react';
import { colors, MetricCard } from '@mysteryco/design/src';
import { Loading } from 'components/core';
import { LinkButton } from 'glue/components/buttons/Button';
import TextLink from 'glue/components/buttons/TextLink';
import HelpTooltip from 'glue/components/information/HelpTooltip';
import {
  Audience,
  AudienceBuilder,
  AudienceList,
  AudienceTypeEnum,
  getUniqUsers,
} from 'glue/components/inputs/audience';
import ResultsSummaryCards from 'glue/scenes/Explore/Pulse/ResultsSummaryCards';
import ResultsSummaryTable from 'glue/scenes/Explore/Pulse/ResultsSummaryTable';
import {
  usePulseTimeframes,
  useSelectedTimeframeFilter,
} from 'glue/scenes/Explore/Pulse/timeframes';
import PulseHelpBanner from 'glue/scenes/PulseSettings/components/PulseHelpBanner';
import { GraphQLError } from 'graphql';
import mixpanel from 'mixpanel-browser';
import { ReactNode, useState } from 'react';
import { Paths } from 'Routes';
import { Statsig } from 'statsig-react';
import theme from 'theme';
import { usePulseResultsSummaryQuery } from 'types';
import { useViewer } from 'utils/state';
import { FewAnswers, MismatchedAudience, NoResults, SmallTeam } from './EmptyStates';
import TimeframeFilter from './TimeframeFilter';
import { existsFilter } from 'lib/helpers/maybe';

const emptyStates = {
  noResults: NoResults,
  smallTeam: SmallTeam,
  fewAnswers: FewAnswers,
  mismatchedAudience: MismatchedAudience,
};

const SectionHeader = ({
  title,
  subtitle,
  tooltipContent,
}: {
  title: string;
  subtitle: string;
  tooltipContent?: ReactNode;
}) => {
  return (
    <div css={styles.sectionHeaderLayout}>
      <div css={styles.sectionHeaderTitle}>
        {title}
        {tooltipContent && <HelpTooltip content={tooltipContent} />}
      </div>
      <div css={styles.sectionHeaderSubtitle}>{subtitle}</div>
    </div>
  );
};

const ParticipationHelpText = () => (
  <div css={helpStyles.layout}>
    <div css={helpStyles.title}>Participation</div>
    <div css={helpStyles.text}>
      Refers to the percentage of employees who submit completed surveys. Employee surveys
      typically have a 25-60% response rate.
    </div>
    <div css={helpStyles.text}>
      You can improve response rate by encouraging employees to share their thoughts.
    </div>
    <TextLink href='https://help.glue.co/knowledge'>Learn More</TextLink>
  </div>
);

const SurveyHelpText = () => (
  <div css={helpStyles.layout}>
    <div css={helpStyles.title}>Survey Results</div>
    <div css={helpStyles.text}>
      Survey questions are scored from 1 to 5. We calculate the average of all responses
      to determine an overall positivity score, which indicates how positively your
      employees feel.
    </div>
    <TextLink href='https://help.glue.co/knowledge'>Learn More</TextLink>
  </div>
);

export function ResultsSummary() {
  // TODO: remove after launch, delete cards version
  const showTableVersion = Statsig.checkGate('morale_pulse_beta_2');

  const [audience, setAudience] = useState<Audience>({});

  // timeframe selection (key) is stored in the URL
  const [timeframe, setTimeframe] = useSelectedTimeframeFilter();
  const timeframes = usePulseTimeframes();

  const selectedAudience = getUniqUsers(audience);
  const selectedAudienceIds = selectedAudience.map((user) => user.id);
  const selectedAudienceCount = selectedAudienceIds?.length || 0;
  const viewer = useViewer();
  const orgId = viewer?.orgs[0]?.id || '';

  const surveyIds = timeframe?.surveyIds || [];

  const { data, loading } = usePulseResultsSummaryQuery({
    variables: {
      audienceIds: selectedAudienceIds,
      timeframe: timeframe
        ? {
            startTime: timeframe?.startDate,
            endTime: timeframe?.endDate,
          }
        : undefined,
    },
    context: {
      ignoreErrorFilter: (err: GraphQLError) => {
        return err.extensions?.code === 'AUDIENCE_TOO_SMALL';
      },
    },
  });
  const participation = data?.moralePulseSurveyParticipationSummary;
  const answerSummary = data?.moralePulseSurveyAnswerSummary.filter(existsFilter);
  const { completedCount = 0, audienceCount = 0 } = participation || {};

  const noResults = !loading && selectedAudienceCount === 0 && completedCount < 5;
  const smallTeam =
    !loading &&
    ((selectedAudienceCount > 0 && selectedAudienceCount < 2) || audienceCount < 2);
  const fewAnswers = !loading && completedCount < 2;
  const mismatchedAudience = !loading && participation?.audienceCount === 0;
  const hideResults = noResults || smallTeam || fewAnswers || mismatchedAudience;
  const EmptyStateComponent = mismatchedAudience
    ? emptyStates.mismatchedAudience
    : noResults
    ? emptyStates.noResults
    : smallTeam
    ? emptyStates.smallTeam
    : emptyStates.fewAnswers;

  const responseRate = ((participation?.responseRate || 0) * 100).toFixed(0);

  return (
    <>
      <div>
        <span css={styles.overview}>Overview</span>
        <span css={styles.beta}>Beta</span>
      </div>
      <div css={styles.actionsLayout}>
        <div css={styles.filters}>
          <TimeframeFilter
            onChange={setTimeframe}
            value={timeframe}
            options={timeframes}
          />
          <AudienceBuilder
            audience={audience}
            setAudience={setAudience}
            organizationId={orgId}
            hideList
            allowedSearchTerms={[AudienceTypeEnum.TAG, AudienceTypeEnum.TEAM]}
            css={styles.audienceBuilder}
            variant='compact'
            placeholderActionText='Filter'
          />
        </div>
        <div css={styles.selectedAudience}>
          <AudienceList audience={audience} onAudienceChange={setAudience} />
        </div>
        <div css={styles.actionButtons}>
          <LinkButton
            size='compact'
            color='purple'
            to={Paths.PULSE_SETTINGS}
            onClick={() =>
              mixpanel.track('pls-lp-pls-settings', {
                eventType: 'click',
                buttonCopy: 'Manage Pulse settings',
              })
            }
          >
            Manage Pulse settings
          </LinkButton>
        </div>
      </div>
      <div className='survey-results' css={styles.mainContent}>
        {loading && !data && <Loading />}
        {!loading && hideResults && surveyIds.length > 1 && <EmptyStateComponent />}
        {!hideResults && timeframe && !loading && (
          <div>
            <SectionHeader
              title='Participation'
              subtitle={`Your participation results for ${timeframe.displayString}`}
              tooltipContent={<ParticipationHelpText />}
            />
            <div css={styles.metricCardRow}>
              <MetricCard
                headline='Surveys within timeframe'
                value={`${surveyIds.length}`}
                css={styles.metricCard}
                loading={loading}
              />
              <MetricCard
                headline='Survey responses'
                value={`${completedCount}`}
                css={styles.metricCard}
                loading={loading}
              />
              <MetricCard
                headline='Response rate'
                value={`${responseRate}%`}
                css={styles.metricCard}
                loading={loading}
              />
            </div>
            <SectionHeader
              title='Survey Results'
              subtitle={`Your ${timeframe.displayString} results for your survey questions`}
              tooltipContent={<SurveyHelpText />}
            />
            <div css={styles.questionLayout}>
              {loading && <Loading />}
              {!loading && showTableVersion && (
                <ResultsSummaryTable data={answerSummary ?? []} />
              )}
              {!loading && !showTableVersion && (
                <ResultsSummaryCards data={answerSummary ?? []} />
              )}
            </div>
          </div>
        )}
      </div>
      <PulseHelpBanner />
    </>
  );
}

const styles = {
  overview: css({
    color: colors.Glue_Ink00,
    fontSize: 30,
    fontWeight: 400,
    lineHeight: `36px`,
  }),
  mainContent: css({
    flex: 1,
  }),
  beta: css({
    fontStyle: `normal`,
    fontWeight: 500,
    fontSize: `14px`,
    lineHeight: `140%`,
    color: colors.Glue_Ink10,
    textTransform: 'uppercase',
    marginLeft: '7px',
  }),
  actionsLayout: css({
    display: 'grid',
    gridTemplateAreas: `"filters buttons" "audience audience"`,
    gridAutoRows: `auto`,
    gridTemplateColumns: `1fr auto`,
    alignItems: 'center',
    gap: 16,

    [theme.breakpoints.down('md')]: {
      gridTemplateAreas: `"buttons" "filters" "audience"`,
      gridTemplateColumns: '1fr',
    },
  }),
  filters: css({
    gridArea: 'filters',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
  }),
  selectedAudience: css({
    gridArea: 'audience',
  }),
  actionButtons: css({
    gridArea: 'buttons',
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
    justifySelf: 'flex-end',
  }),
  metricCardRow: css({
    display: 'flex',
    justifyContent: 'space-between',
    gap: 20,
    marginBottom: 60,
  }),
  metricCard: css({
    flex: 1,
  }),
  sectionHeaderLayout: css({
    display: 'flex',
    flexDirection: 'column',
    fontStyle: 'normal',
    lineHeight: '140%',
    margin: '20px 0',
    gap: 8,
  }),
  sectionHeaderTitle: css({
    fontWeight: 700,
    color: colors.Glue_Ink00,
    fontSize: 18,
    display: 'flex',
    gap: 9,
    alignItems: 'center',
  }),
  sectionHeaderSubtitle: css({
    fontWeight: 500,
    color: colors.Glue_Ink10,
    fontSize: 12,
  }),
  questionLayout: css({
    width: '100%',
  }),
  audienceBuilder: css({
    flex: 1,
    maxWidth: 500,
  }),
};

const helpStyles = {
  layout: css({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 360,
    gap: 12,
  }),
  title: css({
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '24px',
    color: colors.Glue_Ink00,
  }),
  text: css({
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    color: colors.Glue_Ink00,
  }),
};

ResultsSummary.query = gql`
  query PulseResultsSummary($audienceIds: [ID!]!, $timeframe: DateWindowInput) {
    moralePulseSurveyParticipationSummary(
      audienceIds: $audienceIds
      timeframe: $timeframe
    ) {
      surveyIds
      audienceCount
      completedCount
      responseRate
    }
    moralePulseSurveyAnswerSummary(audienceIds: $audienceIds, timeframe: $timeframe) {
      ...ResultsSummaryTableAnswer
    }
  }
  ${ResultsSummaryTable.fragments}
`;

export default ResultsSummary;
