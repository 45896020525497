import CenterSection from 'glue/scenes/Meetups/Settings/CenterSection';
import { MeetupsLoaderPassedProps } from 'glue/scenes/Meetups/Settings/MeetupsLoader';
import MeetupsPage from 'glue/scenes/Meetups/Settings/MeetupsPage';
import SetupSection from 'glue/scenes/Meetups/Settings/SetupSection';

import SummaryAndEdit from 'glue/scenes/Meetups/Settings/SummaryAndEdit';

const Layout = ({
  children,
  loaderProps,
}: {
  children: React.ReactElement | React.ReactElement[];
  loaderProps: MeetupsLoaderPassedProps;
}) => {
  const hasActiveInitiative =
    loaderProps.existingInitiative?.id && !loaderProps.existingInitiative?.disabledAt;

  return (
    <MeetupsPage noSidePad>
      <CenterSection>{children}</CenterSection>
      {hasActiveInitiative ? <SummaryAndEdit {...loaderProps} /> : <SetupSection {...loaderProps} />}
    </MeetupsPage>
  );
};

export default Layout;
