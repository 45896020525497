// @ts-strict-ignore
import config from 'config';
import _ from 'lodash';
import { DateTime } from 'luxon';
import {
  PreferenceCategory,
  PreferenceQuestionsFragmentFragment,
  Questionnaire,
  TeamEvent,
  TeamEventFragmentFragment,
  TeamEventStatus,
  VirtualEventType,
} from 'types';

const DEFAULT_HOST_JOIN_LEAD_TIME_MINS = 90;
const DEFAULT_DURATION_MINS = 60;

export const canJoinVirtualEvent = ({
  teamEvent,
  isHost,
}: {
  teamEvent: Pick<
    TeamEventFragmentFragment,
    'requestedFor' | 'virtualEvent' | 'status' | 'id'
  >;
  isHost: boolean;
}): boolean => {
  // valid event
  if (!isEventValid(teamEvent)) return false;

  // valid status
  if (isEventCompleted(teamEvent)) return false;

  // valid time
  const startTime = DateTime.fromJSDate(new Date(teamEvent.requestedFor));
  const durationMins = teamEvent.virtualEvent?.durationMins || DEFAULT_DURATION_MINS;
  const endTime = startTime.plus({ minutes: durationMins });
  const now = DateTime.now();
  if (now > endTime) return false;

  // valid url
  return !!getJoinButtonUrl({ teamEvent, isHost });
};

export interface SplitPreferenceQuestions {
  activity: PreferenceQuestionsFragmentFragment[];
  other: PreferenceQuestionsFragmentFragment[];
}

export const shapePreferenceQuestions = ({
  questions,
}: {
  questions: PreferenceQuestionsFragmentFragment[];
}): SplitPreferenceQuestions => {
  return _.reduce(
    questions,
    (acc, cur) => {
      if (cur.category === PreferenceCategory.Activity) {
        acc.activity.push(cur);
      } else {
        acc.other.push(cur);
      }
      return acc;
    },
    { activity: [], other: [] },
  );
};

export const isRequester = ({ teamEvent, viewer }): boolean => {
  if (!viewer || !teamEvent) return false;
  return teamEvent.requestedBy?.id === viewer.id;
};

export const isInstructor = ({ teamEvent, viewer }) => {
  if (!viewer || !teamEvent) return false;
  return viewer?.id === teamEvent.virtualEvent?.instructor?.id;
};

export const isHost = ({ teamEvent, viewer }) => {
  return teamEvent.virtualEvent?.requireInstructor
    ? isInstructor({ teamEvent, viewer })
    : isRequester({ teamEvent, viewer });
};

export const linkIsValid = ({
  signUpExpirationDate,
}: {
  signUpExpirationDate: string;
}) => {
  if (!signUpExpirationDate) return true;
  return DateTime.now() < DateTime.fromISO(signUpExpirationDate);
};

export const extractQuestionsFromQuestionnaires = (questionnaires: Questionnaire[]) => {
  return questionnaires.reduce((acc, cur) => {
    return [...acc, ...cur.questions];
  }, []);
};

export const hasZoomMeeting = ({
  virtualEvent,
}: {
  virtualEvent: { type?: VirtualEventType; zoomMeeting?: any };
}): boolean => {
  return !!(
    virtualEvent &&
    (virtualEvent.type === VirtualEventType.Zoom ||
      virtualEvent.type === VirtualEventType.ZoomIsv) &&
    virtualEvent.zoomMeeting
  );
};

export const getFeedbackUrl = (teamEventId: string): string => {
  return `${config.teams.url}/feedback/${teamEventId}?product=TeamEvent`;
};

export const getZoomMeetingUrl = ({
  teamEvent,
  isHost,
}: {
  teamEvent: Pick<TeamEventFragmentFragment, 'virtualEvent' | 'status'>;
  isHost: boolean;
}): string | null => {
  if (!hasZoomMeeting({ virtualEvent: teamEvent.virtualEvent })) {
    return null;
  }
  if (isHost) {
    return teamEvent.virtualEvent.zoomMeeting.startUrl;
  }
  return teamEvent.virtualEvent.zoomMeeting.joinUrl;
};

export const getJoinButtonUrl = ({
  teamEvent,
  isHost,
}: {
  teamEvent: Pick<TeamEventFragmentFragment, 'virtualEvent' | 'status' | 'id'>;
  isHost: boolean;
}): string => {
  if (isEventCompleted(teamEvent)) {
    return getFeedbackUrl(teamEvent.id);
  }
  return getZoomMeetingUrl({ teamEvent, isHost });
};

export const isEventCompleted = (teamEvent: {
  status?: TeamEventStatus | null;
}): boolean =>
  [
    TeamEventStatus.ReadyToReview,
    TeamEventStatus.InvoiceSent,
    TeamEventStatus.Complete,
  ].includes(teamEvent.status);

export const isEventValid = (teamEvent: Pick<TeamEvent, 'status'>): boolean => {
  const status = teamEvent?.status;
  return status !== TeamEventStatus.Expired && status !== TeamEventStatus.Canceled;
};

export const isEventUpcoming = (teamEvent: Pick<TeamEvent, 'status'>): boolean => {
  const status = teamEvent?.status;
  return status === TeamEventStatus.InProgress || status === TeamEventStatus.Scheduled;
};

export const isEventLive = (teamEvent: Pick<TeamEvent, 'requestedFor'>): boolean => {
  const now = DateTime.now();
  const startTime = DateTime.fromJSDate(new Date(teamEvent.requestedFor));
  return (
    now.diff(startTime, 'minutes').minutes <= DEFAULT_HOST_JOIN_LEAD_TIME_MINS &&
    now.diff(startTime, 'minutes').minutes >= -DEFAULT_HOST_JOIN_LEAD_TIME_MINS
  );
};

export const isExemptFromQuestions = (teamEvent: TeamEventFragmentFragment): boolean => {
  const exemptTeamEventIds = new Set([
    'babd30b8-bea4-4e7f-aa45-c0debbe9bb59',
    'c028872e-a7ad-4868-bc00-425d318a9d2a',
  ]);
  return exemptTeamEventIds.has(_.get(teamEvent, 'id'));
};

export const canShowJoinButton = ({
  teamEvent,
  isHost,
}: {
  teamEvent: TeamEventFragmentFragment;
  isHost: boolean;
}) => {
  return (
    canJoinVirtualEvent({ teamEvent, isHost }) &&
    isEventValid(teamEvent) &&
    !!getJoinButtonUrl({ teamEvent, isHost })
  );
};
