// @ts-strict-ignore
import React from 'react';
import { Colors, Sizes, Type } from 'constants/index';
import { fontSizePx } from 'constants/Type';
import useResponsive from 'hooks/useResponsive';
import { SPACING_XSMALL } from 'constants/Sizes';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  sub: {
    ...fontSizePx(11),
    color: Colors.Palette.GreyLight,
    fontStyle: 'italic',
    marginTop: SPACING_XSMALL,
  },
  productOptionLarge: (props: any) => ({
    ...fontSizePx(props.responsive({ small: 15, medium: 16, large: 17, xlarge: 17 })),
    color: Colors.Palette.GreyDarkest,
    fontWeight: Type.FontWeights.BOLD,
  }),
  uppercase: {
    textTransform: 'uppercase',
  },
  fieldLabel: {
    display: 'block',
    ...fontSizePx(12),
    textTransform: 'uppercase',
    color: Colors.Palette.GreyLightest,
    marginBottom: Sizes.SPACING_SMALL,
  },
  sectionTitle: {
    display: 'block',
    ...fontSizePx(18),
    color: Colors.Palette.GreyDark,
    fontWeight: Type.FontWeights.BOLD,
    marginBottom: Sizes.SPACING_NORMAL,
  },
  warningBody: {
    display: 'block',
    ...fontSizePx(16),
    color: '#000000d9',
    fontWeight: Type.FontWeights.BOLD,
  },
  noMargin: {
    margin: 0,
  },
});

type TextTypes =
  | 'h2'
  | 'h3'
  | 'h4'
  | 'body'
  | 'sub'
  | 'productOptionLarge'
  | 'checkout-line-item'
  | 'checkout-total'
  | 'field-label'
  | 'section-title'
  | 'warning-body';

const Text = ({
  type = 'body',
  children,
  noMargin,
  className,
  ...otherProps
}: {
  type?: TextTypes;
  noMargin?: boolean;
  className?: string;
  children: React.ReactNode;
}) => {
  let classNames = [];
  const responsive = useResponsive();
  const classes = useStyles({ responsive });

  if (type.match(/h\d/)) classNames.push(classes.uppercase);

  if (type === 'sub') {
    classNames.push(classes.sub);
  }

  if (type === 'productOptionLarge') classNames.push(classes.productOptionLarge);

  if (type === 'field-label') classNames.push(classes.fieldLabel);

  if (type === 'section-title') classNames.push(classes.sectionTitle);

  if (type === 'warning-body') classNames.push(classes.warningBody);

  if (noMargin) classNames.push(classes.noMargin);

  if (className) classNames.push(className);

  return (
    <div className={classNames.join(' ')} {...otherProps}>
      {children}
    </div>
  );
};

export default Text;
