// @ts-strict-ignore
import { Box, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import humanize from 'humanize-plus';
import { getTotalGuests } from 'lib/helpers/booking';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import { useEffect, useState } from 'react';
import {
  useGetUserWithOrgsQuery,
  UserTag,
  UserTagType,
  ViewerFragmentFragment,
  CustomerType,
  AudienceBuilderUserTagFragment,
  AudienceBuilderTeamFragment,
} from 'types';
import * as yup from 'yup';

import TeamChip from 'components/Chips/TeamChip';
import { Loading } from 'components/core';
import SectionSubHeader from 'components/SectionSubHeader';
import SelectOneOption from 'components/SelectOneOption';
import StepWrapper from 'components/SSFSteps/StepWrapper';
import theme from 'theme';
import {
  GroupType,
  GuestInputType,
  GuestsType,
  SelfServeState,
  ViewerOrg,
} from 'types/selfServeFlow';
import { Props } from '../types';
import GuestDrawer from './GuestDrawer';
import Users02 from 'components/icons/Users02';
import ThreePeopleTandemBike from 'components/icons/ThreePeopleTandemBike';
import UpSell from '../UpSell';
import MessageQuestionCircle from 'components/icons/MessageQuestionCircle';
import Mail04 from 'components/icons/Mail04';
import { getUpsellLink } from 'utils/stringUtil';
import { Audience, AudienceBuilder } from 'glue/components/inputs/audience';
import OrgSearchAndSelect from './OrgSearchAndSelect';
import { Statsig } from 'statsig-react';

const MAX_LIST_SIZE = 10;
export const MIN_SURPRISE_TO_ALL_SEAT_COUNT = 6;

export const title = 'Guests';
export const key = 'uploadGuests';
export const getSubtitle = ({ label }) => label;

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    maxWidth: 562,
  },
  headCountContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  noteContainer: {
    backgroundColor: theme.palette.warning[200],
    padding: 12,
    marginBottom: theme.spacing(6),
  },
  headCount: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(4),

    '& .MuiInputBase-root': {
      borderRadius: theme.spacing(2),
      height: 52,
    },
    '& .MuiInputBase-input': {
      boxSizing: 'none',
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  warning: {
    maxWidth: '380px',
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      width: '240px',
    },
  },
}));

export const InvitedGuestText = ({ children }) => {
  return (
    <Typography style={{ marginLeft: theme.spacing(1) }} variant='body2'>
      {children}
    </Typography>
  );
};

export const makeUserTagsWithMembers = (organization: ViewerOrg): ViewerOrg => {
  if (!organization) return organization;

  const tagsWithMembersById = organization.userTags?.reduce((acc, tag) => {
    acc[tag.id] = { ...tag, users: [] };
    return acc;
  }, {});

  for (const member of organization.members) {
    for (const tag of member.tags) {
      tagsWithMembersById[tag.id].users.push(member);
    }
  }

  return {
    ...organization,
    userTags: Object.values(tagsWithMembersById),
  };
};

export const guestToAudience = (guests?: GuestsType): Audience => {
  if (!guests) {
    return {
      tags: [],
      teams: [],
      users: [],
    };
  }
  const teams: AudienceBuilderTeamFragment[] = [];
  const tags: AudienceBuilderUserTagFragment[] = [];
  guests.groups.forEach((group) => {
    if (group.type === 'tag') {
      tags.push({
        id: group.id,
        name: group.name,
        users: group.users.map((user) => {
          return {
            id: user.id,
            companyRole: user.companyRole,
            email: user.email,
            name: user.name,
          };
        }),
      });
    } else if (group.type === 'team') {
      teams.push({
        id: group.id,
        name: group.name,
        members: group.users
          .filter((user) => !!user)
          .map((user) => {
            return {
              id: user.id,
              companyRole: user.companyRole,
              email: user.email,
              name: user.name,
            };
          }),
      });
    }
  });
  return {
    teams,
    tags,
    users: guests.otherGuests || [],
  } as Audience;
};

export const audienceToGuests = (audience: Audience): GuestsType => {
  const groups = audience.tags
    .map((tag) => {
      return { ...tag, type: 'tag' } as GroupType;
    })
    .concat(
      audience.teams.map((team) => {
        return {
          id: team.id,
          name: team.name,
          users: team.manager ? [...team.members, team.manager] : team.members,
          type: 'team',
        } as GroupType;
      }),
    );
  return {
    groups,
    otherGuests: audience.users.map((user) => {
      return {
        ...user,
        email: user.email,
      };
    }),
  };
};

export const Content = (props: Props<any>) => {
  const { globalState, setValue, viewer } = props;
  const groupsSelected = globalState.uploadGuests?.guests?.groups || [];
  const otherGuestsSelected = globalState.uploadGuests?.guests?.otherGuests || [];
  const combinedGuestList = [...groupsSelected, ...otherGuestsSelected];
  const [searchTags, setSearchTags] = useState([] as UserTag[]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [guestInputType, setGuestInputType] = useState<GuestInputType>(null);
  const classes = useStyles();
  const useAudienceBuilder = Statsig.checkGate('org_select_to_audience_builder');

  const { data, loading } = useGetUserWithOrgsQuery({
    variables: {
      getMembers: true,
    },
  });
  const organization: ViewerOrg = _.first(data?.viewer?.orgs);
  const totalGuests = getTotalGuests({
    guests: globalState.uploadGuests?.guests,
  });

  useEffect(() => {
    mixpanel.track('upload guests loaded', {
      customerType: viewer?.customerType,
      firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
    });
  }, []);

  useEffect(() => {
    setValue({
      ...globalState.uploadGuests,
      totalRecipientsEstimate: totalGuests,
    });
  }, [totalGuests]);

  const setAudience = (audience) => {
    setValue({
      ...globalState.uploadGuests,
      guests: audienceToGuests(audience),
    });
  };

  if (loading) return <Loading />;

  return (
    <StepWrapper
      title='Add guests'
      subTitle='Glue curates events to the size and shape of your team.'
      messageWidget={
        viewer?.customerType === CustomerType.Transactional ? (
          <UpSell
            icon={<Users02 />}
            description={
              <span>
                Know exactly{' '}
                <strong>
                  who
                  <br />
                  to invite
                </strong>{' '}
                for every event
              </span>
            }
            illustration={<ThreePeopleTandemBike />}
            illustrationStyle={{ top: 185, right: -90 }}
            linkTo={getUpsellLink('booking-guests')}
          />
        ) : null
      }
      {...props}
    >
      <Box
        display='flex'
        flexDirection='column'
        flexWrap='wrap'
        className={classes.contentContainer}
      >
        {!data ? (
          <Loading />
        ) : (
          <>
            <Box className={classes.noteContainer}>
              <Typography>
                We’ll send your guests email invites and event reminders 💌
              </Typography>
            </Box>
            <Box style={{ marginBottom: theme.spacing(10) }}>
              <SelectOneOption
                description={'Who is coming to this party?'}
                subtitle={`This will help us recommend events for you`}
                optionSize='fullWidth'
                selectedKey={guestInputType}
                options={[
                  {
                    key: GuestInputType.idkyet,
                    text: `I don't know yet`,
                    icon: <MessageQuestionCircle />,
                  },
                  {
                    key: GuestInputType.imready,
                    text: `I have emails ready`,
                    icon: <Mail04 />,
                  },
                ]}
                onSelectKey={(newValue: GuestInputType) => {
                  mixpanel.track('guest input type selected', {
                    interactivity: newValue,
                    source: 'guest selection',
                    customerType: viewer?.customerType,
                    firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
                  });
                  setGuestInputType(newValue);
                  globalState.guestInputType = newValue;
                }}
              />
            </Box>
            {guestInputType === GuestInputType.imready && (
              <>
                <SectionSubHeader
                  title={`What's their email?`}
                  subTitle={`Pro tip: paste a list of email addresses, no special formatting
                    neccesary`}
                />
                {useAudienceBuilder ? (
                  <AudienceBuilder
                    organizationId={organization.id}
                    audience={guestToAudience(globalState.uploadGuests?.guests)}
                    setAudience={setAudience}
                  />
                ) : (
                  <OrgSearchAndSelect
                    organization={makeUserTagsWithMembers(organization)}
                    searchTags={searchTags}
                    setSearchTags={setSearchTags}
                    setSearchString={setSearchString}
                    searchString={searchString}
                    setGuests={(guests) => {
                      setValue({
                        ...globalState.uploadGuests,
                        guests,
                      });
                    }}
                    currentGuests={globalState.uploadGuests?.guests}
                  />
                )}

                <Box display='flex' mt={2}>
                  <Box
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                    }}
                  >
                    {_.map(_.slice(combinedGuestList, 0, MAX_LIST_SIZE), (g: any, i) => {
                      const isLast =
                        i === _.size(combinedGuestList) - 1 || i === MAX_LIST_SIZE - 1;
                      const isGroup = g.__typename === 'UserTag';

                      if (isGroup)
                        return (
                          <TeamChip
                            key={i}
                            automated={g.type === UserTagType.Automatic}
                            label={g.name}
                          />
                        );
                      const text = g.name || g.email;
                      if (isLast)
                        return <InvitedGuestText key={i}>{text}</InvitedGuestText>;
                      return <InvitedGuestText key={i}>{`${text}, `}</InvitedGuestText>;
                    })}

                    <Box
                      onClick={(e) => setDrawerOpen(!drawerOpen)}
                      display='inline'
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        marginLeft: theme.spacing(1),
                        color: theme.palette.primary.main,
                      }}
                    >
                      {!!totalGuests &&
                        `view and edit ${totalGuests} ${humanize.pluralize(
                          totalGuests,
                          'guest',
                        )}`}
                    </Box>
                  </Box>
                </Box>
                {drawerOpen && (
                  <GuestDrawer
                    organization={organization}
                    searchTags={searchTags}
                    setSearchTags={setSearchTags}
                    setSearchString={setSearchString}
                    searchString={searchString}
                    setGuests={(guests) =>
                      setValue({
                        ...globalState.uploadGuests,
                        guests,
                      })
                    }
                    currentGuests={globalState.uploadGuests?.guests}
                    closeDrawer={() => setDrawerOpen(false)}
                    drawerOpen={drawerOpen}
                    setAudience={setAudience}
                  />
                )}
              </>
            )}
            {guestInputType === GuestInputType.idkyet && (
              <EstimatedHeadCountSection
                globalState={globalState}
                setValue={setValue}
                viewer={viewer}
              />
            )}
          </>
        )}
      </Box>
    </StepWrapper>
  );
};

const EstimatedHeadCountSection = ({
  globalState,
  viewer,
  setValue,
}: {
  globalState: SelfServeState;
  viewer: ViewerFragmentFragment;
  setValue(value: any, altKey?: string): void;
}) => {
  const totalGuests = getTotalGuests({
    guests: globalState.uploadGuests?.guests,
  });

  const headCount = _.get(globalState, 'uploadGuests.totalRecipientsEstimate');

  const classes = useStyles();

  return (
    <Box mb={3} className={classes.headCountContainer}>
      <SectionSubHeader title={`How many guests are you expecting?`} />
      <Box className={classes.headCount}>
        <TextField
          variant='outlined'
          style={{ width: '100%' }}
          type='number'
          required
          value={headCount}
          InputProps={{
            inputProps: {
              style: { borderRadius: theme.spacing(2) },
              min: totalGuests,
            },
          }}
          onChange={(e) => {
            setValue({
              ...globalState.uploadGuests,
              totalRecipientsEstimate: parseInt(e.target.value),
            });
          }}
        />
      </Box>
    </Box>
  );
};

export const schema = yup.object({
  uploadGuests: yup
    .object({
      guests: yup.object({
        groups: yup.array().of(
          yup.object({
            users: yup
              .array()
              .of(yup.object({ email: yup.string().email(), name: yup.string() })),
          }),
        ),
        otherGuests: yup
          .array()
          .of(yup.object({ email: yup.string().email(), name: yup.string() })),
      }),
      totalRecipientsEstimate: yup
        .number()
        .integer()
        .typeError('Total guests must be a number')
        .min(1, 'Total guests must be greater than 0')
        .required('Total guests is required'),
    })
    .required(),
});
