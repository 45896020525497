import { css } from '@emotion/react';
import { Glue_Ink00, Glue_Paper } from '@mysteryco/design/src/tokens/colors';

interface Props {
  text: React.ReactNode;
}

const FeatureStatusPills = ({ text }: Props) => {
  return (
    <div css={styles.root}>
      <div css={styles.text}>{text}</div>
    </div>
  );
};

const styles = {
  root: css({
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: `4px 12px`,
    background: Glue_Paper,
    border: `1px solid ${Glue_Ink00}`,
    borderRadius: 12,
    boxSizing: 'border-box',
  }),
  text: css({
    fontFamily: 'Avenir, sans-serif',
    fontStyle: 'normal',
    fontSize: 12,
    lineHeight: 1.4,
    fontWeight: 700,
    color: Glue_Ink00,
  }),
};

export default FeatureStatusPills;
