import { colors } from '@mysteryco/design/src';
import { PatternName } from 'glue/components/accessibility/PatternedBox';
import { useColorblindMode } from 'glue/components/accessibility/hooks';
import { SVGAttributes } from 'react';

export const TrendUpArrowPath = (props: SVGAttributes<SVGPathElement>) => (
  <path
    d='M 0.5 7.5 l 7 -7 l -6 0 m 6 0 l 0 6'
    strokeWidth='1'
    stroke='inherit'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
    {...props}
  />
);

export const TrendDownArrowPath = (props: SVGAttributes<SVGPathElement>) => (
  <path
    d='M 0.5 0.5 l 7 7 l -6 0 m 6 0 l 0 -6'
    strokeWidth='1'
    stroke='inherit'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
    {...props}
  />
);

export const TrendNeutralArrowPath = (props: SVGAttributes<SVGPathElement>) => (
  <path
    d='M 0.5 4 l 7 0 l -3.5 -3.5 m 3.5 3.5 l -3.5 3.5'
    strokeWidth='1'
    stroke='inherit'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
    {...props}
  />
);

/**
 * Renders an arrow SVG for a trend indicator in a tight square.
 */
export const TrendArrow = ({
  trend,
  ...rest
}: SVGAttributes<SVGSVGElement> & {
  trend: 'up' | 'down' | 'neutral';
}) => (
  <svg viewBox='0 0 8 8' {...rest}>
    {trend === 'up' && <TrendUpArrowPath stroke='inherit' />}
    {trend === 'down' && <TrendDownArrowPath stroke='inherit' />}
    {trend === 'neutral' && <TrendNeutralArrowPath stroke='inherit' />}
  </svg>
);

export const COLORS = {
  trendUpStrongBg: colors.Mint_10,
  trendUpStrongFg: colors.Mint_30,
  trendUpBg: colors.Mint_40,
  trendUpFg: colors.Mint_00,
  trendNeutralBg: colors.Plum_40,
  trendNeutralFg: colors.Plum_20,
  trendDownBg: colors.Ruby_40,
  trendDownFg: '#E65E24',
  trendDownStrongBg: '#E65E24',
  trendDownStrongFg: colors.Ruby_40,
};

/**
 * Renders the SVG patterns used for colorblindness accessibility.
 * Reference a pattern by its ID to apply it to an element after including
 * this component in your SVG.
 *
 * IDS:
 * - pattern-trend-up-strong
 * - pattern-trend-up
 * - pattern-trend-neutral
 * - pattern-trend-down
 * - pattern-trend-down-strong
 *
 * You can use the `getPatternFill` helper exported from this module to easily
 * pick a pattern.
 *
 * <path fill={getPatternFill('trend-up-strong')} />
 **/
export function SvgColorblindPatternDefs() {
  const [colorblindOn] = useColorblindMode();

  return (
    <defs>
      <TrendUpArrowPath id='path-trend-up' />
      {/* A downward arrow */}
      <TrendDownArrowPath id='path-trend-down' />
      {/* A rightward arrow */}
      <TrendNeutralArrowPath id='path-trend-neutral' />

      {/* An upward arrow with a dark green background */}
      <pattern
        id='pattern-trend-up-strong'
        width='32'
        height='32'
        patternUnits='userSpaceOnUse'
        stroke={COLORS.trendUpStrongFg}
      >
        <rect width='32' height='32' fill={COLORS.trendUpStrongBg} stroke='none' />
        {colorblindOn && (
          <>
            <use href='#path-trend-up' x='4' y='4' />
            <use href='#path-trend-up' x='20' y='20' />
          </>
        )}
      </pattern>
      {/* An upward arrow with a light green background */}
      <pattern
        id='pattern-trend-up'
        width='32'
        height='32'
        patternUnits='userSpaceOnUse'
        stroke={COLORS.trendUpFg}
      >
        <rect width='32' height='32' fill={COLORS.trendUpBg} stroke='none' />
        {colorblindOn && (
          <>
            <use href='#path-trend-up' x='4' y='4' />
            <use href='#path-trend-up' x='20' y='20' />
          </>
        )}
      </pattern>
      {/* A rightward arrow with a purple background */}
      <pattern
        id='pattern-trend-neutral'
        width='32'
        height='32'
        patternUnits='userSpaceOnUse'
        stroke={COLORS.trendNeutralFg}
      >
        <rect width='32' height='32' fill={COLORS.trendNeutralBg} stroke='none' />
        {colorblindOn && (
          <>
            <use href='#path-trend-neutral' x='4' y='4' />
            <use href='#path-trend-neutral' x='20' y='20' />
          </>
        )}
      </pattern>
      {/* A downward arrow with a light orange background */}
      <pattern
        id='pattern-trend-down'
        width='32'
        height='32'
        patternUnits='userSpaceOnUse'
        stroke={COLORS.trendDownFg}
      >
        <rect width='32' height='32' fill={COLORS.trendDownBg} stroke='none' />
        {colorblindOn && (
          <>
            <use href='#path-trend-down' x='4' y='4' />
            <use href='#path-trend-down' x='20' y='20' />
          </>
        )}
      </pattern>
      {/* A downward arrow with a dark orange background */}
      <pattern
        id='pattern-trend-down-strong'
        width='32'
        height='32'
        patternUnits='userSpaceOnUse'
        stroke={COLORS.trendDownStrongFg}
      >
        <rect width='32' height='32' fill={COLORS.trendDownStrongBg} stroke='none' />
        {colorblindOn && (
          <>
            <use href='#path-trend-down' x='4' y='4' />
            <use href='#path-trend-down' x='20' y='20' />
          </>
        )}
      </pattern>
    </defs>
  );
}

export function getPatternFill(pattern: PatternName) {
  return `url(#pattern-${pattern})`;
}

const arrowsByPattern: Record<PatternName, React.FC<SVGAttributes<SVGPathElement>>> = {
  'trend-up-strong': TrendUpArrowPath,
  'trend-up': TrendUpArrowPath,
  'trend-neutral': TrendNeutralArrowPath,
  'trend-down': TrendDownArrowPath,
  'trend-down-strong': TrendDownArrowPath,
};
const colorsByPattern = {
  'trend-up-strong': { fg: COLORS.trendUpStrongFg, bg: COLORS.trendUpStrongBg },
  'trend-up': { fg: COLORS.trendUpFg, bg: COLORS.trendUpBg },
  'trend-neutral': { fg: COLORS.trendNeutralFg, bg: COLORS.trendNeutralBg },
  'trend-down': { fg: COLORS.trendDownFg, bg: COLORS.trendDownBg },
  'trend-down-strong': { fg: COLORS.trendDownStrongFg, bg: COLORS.trendDownStrongBg },
};

export const PatternLegend = ({
  pattern,
  size = 32,
}: {
  pattern: PatternName;
  size?: number;
}) => {
  const Arrow = arrowsByPattern[pattern];
  const colors = colorsByPattern[pattern];
  const [colorblindOn] = useColorblindMode();

  return (
    <svg width={size} height={size} viewBox='0 0 24 24'>
      <rect fill={colors.bg} width={24} height={24} rx={10} ry={10} />
      {colorblindOn && (
        <g transform='translate(8, 8)'>
          <Arrow stroke={colors.fg} />
        </g>
      )}
    </svg>
  );
};
