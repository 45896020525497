import { gql } from '@apollo/client';
import { ClassNames, css } from '@emotion/react';
import { TextField, Typography, InputProps } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { colors } from '@mysteryco/design';
import AlertHexagon from '@mysteryco/design/icons/AlertHexagon';
import UserSquare from '@mysteryco/design/icons/UserSquare';
import { useDebounce } from 'ahooks';
import AudienceList from 'glue/components/inputs/audience/AudienceList';
import { isTeam, isUser, isUserTag } from 'glue/components/inputs/audience/guards';
import { getUniqUserCount } from 'glue/components/inputs/audience/info';
import {
  Audience,
  AudienceType,
  AudienceTypeEnum,
} from 'glue/components/inputs/audience/types';
import InlineBanner from 'glue/components/structure/InlineBanner';
import { pluralize } from 'humanize-plus';
import { existsFilter } from 'lib/helpers/maybe';
import _ from 'lodash';
import { CSSProperties, ChangeEvent, FocusEvent, useMemo, useRef, useState } from 'react';
import theme from 'theme';
import { useAudienceBuilderQuery } from 'types';

export interface AudienceBuilderProps {
  organizationId: string;
  audience: Audience;
  setAudience: (audience: Audience) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  style?: CSSProperties;
  allowedSearchTerms?: AudienceTypeEnum[];
  maxListSize?: number;
  minUsersRequired?: number;
  minUsersRecommended?: number;
  minUsersRecommendedText?: string;
  variant?: 'default' | 'compact';
  className?: string;
  placeholderActionText?: string;
  validateOnMount?: boolean;
  hideList?: boolean;
  InputProps?: InputProps;
}

const DEBOUNCE_WAIT_TIME = 200;

/**
 * Our API filters for us, so we don't need MUI to
 * filter the result set by the search term. Some returned
 * results may not actually include the search string, like
 * team names.
 */
const allowAllFilter = (options: any[]) => options;

const allSearchTerms = [
  AudienceTypeEnum.USER,
  AudienceTypeEnum.TEAM,
  AudienceTypeEnum.TAG,
];

const AudienceBuilder = ({
  organizationId,
  audience,
  setAudience,
  onFocus,
  onBlur,
  style,
  allowedSearchTerms = allSearchTerms,
  minUsersRequired,
  minUsersRecommended,
  minUsersRecommendedText,
  maxListSize,
  variant = 'default',
  placeholderActionText = 'Search',
  validateOnMount = false,
  hideList,
  InputProps,
  ...rest
}: AudienceBuilderProps) => {
  const styles = variant === 'compact' ? compactStyles : defaultStyles;
  const rootRef = useRef<HTMLDivElement>();
  const [open, setOpen] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');
  // debounce searching to avoid too many requests
  const debouncedSearchString = useDebounce(searchString.trim(), {
    wait: DEBOUNCE_WAIT_TIME,
  });
  const { data } = useAudienceBuilderQuery({
    variables: {
      orgId: organizationId,
      searchString: debouncedSearchString,
      includeUsers: allowedSearchTerms.includes(AudienceTypeEnum.USER),
      includeTeams: allowedSearchTerms.includes(AudienceTypeEnum.TEAM),
      includeTags: allowedSearchTerms.includes(AudienceTypeEnum.TAG),
    },
    // don't search without a term for filtering.
    skip: !debouncedSearchString || debouncedSearchString.length < 1,
  });

  const users =
    data?.orgUsersConnection?.edges.map((edge) => edge?.node).filter(existsFilter) || [];
  // eliminate groups with 0 users
  const teams =
    data?.searchTeams?.filter(existsFilter).filter((team) => !!team.members.length) || [];
  const tags =
    data?.searchUserTags?.filter(existsFilter).filter((tag) => !!tag.users?.length) || [];

  const uniqUserCount = useMemo(() => {
    return getUniqUserCount(audience);
  }, [audience]);

  const placeholder = () => {
    let string = '';
    const searchTerms = _.compact([
      allowedSearchTerms.includes(AudienceTypeEnum.USER) ? 'member name' : null,
      allowedSearchTerms.includes(AudienceTypeEnum.TEAM) ? 'team name' : null,
      allowedSearchTerms.includes(AudienceTypeEnum.TAG) ? 'tags' : null,
    ]);
    const lastTerm = searchTerms.pop();
    const secondLastTerm = searchTerms.pop();
    string = `${lastTerm}`;
    if (secondLastTerm) {
      string = `${secondLastTerm} or ${string}`;
    }
    if (searchTerms.length >= 1) {
      string = `${searchTerms.join(', ')}, ${string}`;
    }
    return `${placeholderActionText} by ${string}`;
  };

  const options = [...users, ...teams, ...tags];

  return (
    <div
      css={[
        styles.audienceBuilderContainer,
        !!maxListSize ? styles.audienceBuilderGap : {},
      ]}
      {...rest}
    >
      <ClassNames>
        {({ css }) => (
          <Autocomplete
            ref={rootRef}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            open={open}
            style={style}
            css={styles.autoComplete}
            fullWidth
            value={[] as AudienceType[]}
            inputValue={searchString}
            options={options}
            limitTags={5}
            multiple
            freeSolo
            autoHighlight
            filterOptions={allowAllFilter}
            classes={{
              option:
                variant === 'compact'
                  ? css(compactStyleCustomizations.option)
                  : undefined,
              listbox:
                variant === 'compact'
                  ? css(compactStyleCustomizations.listbox)
                  : undefined,
              paper:
                variant === 'compact' ? css(compactStyleCustomizations.paper) : undefined,
            }}
            autoSelect
            getOptionLabel={(option) => option.name || option.email || ''}
            renderOption={(option) => {
              if (isUser(option)) {
                return (
                  <div css={styles.selectOptionContainer}>
                    <Typography css={styles.selectOption}>
                      {option.name || option.email}
                    </Typography>
                    <Typography css={styles.selectOptionDescription}>
                      {option.companyRole}
                    </Typography>
                  </div>
                );
              } else if (isTeam(option)) {
                const userCount = option.members?.length + (option.manager ? 1 : 0) || 0;
                return (
                  <div css={styles.selectOptionContainer}>
                    <Typography css={styles.selectOption}>{option.name}</Typography>
                    {userCount > 0 && (
                      <Typography css={styles.selectOptionDescription}>
                        {`(Team: ${userCount} ${pluralize(userCount, 'member')})`}
                      </Typography>
                    )}
                  </div>
                );
              } else if (isUserTag(option)) {
                const userCount = option.users?.length || 0;
                return (
                  <div css={styles.selectOptionContainer}>
                    <Typography css={styles.selectOption}>{option.name}</Typography>
                    {userCount > 0 && (
                      <Typography css={styles.selectOptionDescription}>
                        {`(Group Tag: ${userCount} ${pluralize(userCount, 'member')})`}
                      </Typography>
                    )}
                  </div>
                );
              } else {
                throw new Error('Invalid option type');
              }
            }}
            onChange={(_event, selectedVals, _reason) => {
              const usersSelected = audience.users || [];
              const teamsSelected = audience.teams || [];
              const tagsSelected = audience.tags || [];
              selectedVals.forEach((val) => {
                if (typeof val === 'string') {
                  // invalid selection, do nothing
                } else if (isUser(val)) {
                  usersSelected.push(val);
                } else if (isTeam(val)) {
                  teamsSelected.push(val);
                } else if (isUserTag(val)) {
                  tagsSelected.push(val);
                }
              });

              setAudience({
                users: _.uniqBy(usersSelected, 'id'),
                teams: _.uniqBy(teamsSelected, 'id'),
                tags: _.uniqBy(tagsSelected, 'id'),
              });
              setSearchString('');
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  onChange={(e) => {
                    (params.inputProps as any)?.onChange?.(e);
                    setSearchString(e.target.value);
                  }}
                  inputProps={params.inputProps}
                  InputProps={{ ...params.InputProps, ...InputProps }}
                  data-open={open}
                  variant='outlined'
                  css={styles.searchBox}
                  value={searchString}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  placeholder={placeholder()}
                />
              );
            }}
          />
        )}
      </ClassNames>
      {!hideList && (
        <div css={styles.selectedContainer}>
          {uniqUserCount > 0 && (
            <AudienceList audience={audience} onAudienceChange={setAudience} />
          )}
          {!!maxListSize && (
            <div css={styles.numGuests}>
              <UserSquare color={colors.Glue_Ink00} size={12} css={{ marginTop: 3 }} />
              {`${uniqUserCount} ${pluralize(uniqUserCount, 'member')} added`}
            </div>
          )}
        </div>
      )}
      {minUsersRequired &&
        (uniqUserCount > 0 || validateOnMount) &&
        uniqUserCount < minUsersRequired && (
          <InlineBanner
            type='error'
            headline='Uh oh.'
            description={`You need to add at least ${minUsersRequired} participants to continue`}
            icon={
              <AlertHexagon
                size={18}
                color={colors.Glue_Ink00}
                style={{ marginTop: theme.spacing(1) }}
              />
            }
          />
        )}
      {minUsersRecommended &&
        (uniqUserCount > 0 || validateOnMount) &&
        uniqUserCount < minUsersRecommended && (
          <InlineBanner
            type='error'
            description={
              minUsersRecommendedText ||
              `We recommend you add at least ${minUsersRecommended} participants to continue`
            }
            icon={
              <AlertHexagon
                size={18}
                color={colors.Glue_Ink00}
                style={{ marginTop: theme.spacing(1) }}
              />
            }
          />
        )}
    </div>
  );
};
AudienceBuilder.fragments = gql`
  fragment AudienceBuilderUser on User {
    id
    name
    firstName
    lastName
    email
    companyRole
    optOutOfWatercoolerAt
  }

  fragment AudienceBuilderTeam on Team {
    id
    name
    manager {
      id
      ...AudienceBuilderUser
    }
    members {
      id
      ...AudienceBuilderUser
    }
  }

  fragment AudienceBuilderUserTag on UserTag {
    id
    name
    users {
      id
      ...AudienceBuilderUser
    }
  }
`;
AudienceBuilder.query = gql`
  query AudienceBuilder(
    $orgId: ID!
    $searchString: String!
    $includeUsers: Boolean!
    $includeTeams: Boolean!
    $includeTags: Boolean!
  ) {
    orgUsersConnection(
      organizationArgs: { organizationId: $orgId, showArchived: false }
      search: $searchString
      order: [{ key: FirstName, direction: Asc }]
    ) @include(if: $includeUsers) {
      edges {
        node {
          id
          ...AudienceBuilderUser
        }
      }
    }
    searchTeams(orgId: $orgId, name: $searchString) @include(if: $includeTeams) {
      id
      ...AudienceBuilderTeam
    }
    searchUserTags(orgId: $orgId, name: $searchString) @include(if: $includeTags) {
      id
      ...AudienceBuilderUserTag
    }
  }

  ${AudienceBuilder.fragments}
`;

const defaultStyles = {
  audienceBuilderContainer: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  audienceBuilderGap: css({
    gap: theme.spacing(3),
  }),
  selectedContainer: css({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(4),
  }),
  selected: css({
    flex: 1,
  }),
  numGuests: css({
    display: 'flex',
    width: 150,
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#3B3B3B',
  }),
  autoComplete: css({
    '& .MuiAutocomplete-inputRoot': {
      flexWrap: 'nowrap',
    },
  }),
  searchBox: css({
    '& .MuiInputBase-root': {
      borderRadius: theme.spacing(2),
      height: '52px',
      '& .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${colors.Glue_Ink30}`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.Glue_Ink10,
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.Glue_Ink00,
      },
    },
    '& .MuiInputBase-input': {
      '&::placeholder': {
        color: colors.Black,
      },
    },
    display: 'flex',
    alignItems: 'center',
  }),
  searchLabel: css({
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  }),
  selectOption: css({
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.Glue_Ink00,
  }),
  selectOptionContainer: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(1),
  }),
  selectOptionDescription: css({
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.Glue_Ink10,
  }),
  preselectedPill: css({
    background: colors.Glue_Mint20,
  }),
};

const compactStyles = {
  audienceBuilderContainer: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  audienceBuilderGap: css({
    gap: theme.spacing(3),
  }),
  selectedContainer: css({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(4),
  }),
  selected: css({
    flex: 1,
  }),
  numGuests: css({
    display: 'flex',
    width: 150,
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#3B3B3B',
  }),
  autoComplete: css({
    '& .MuiAutocomplete-inputRoot': {
      flexWrap: 'nowrap',
    },
  }),
  searchBox: css({
    '& .MuiInputBase-root': {
      borderRadius: 34,
      height: '40px',
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${colors.Glue_BorderLight}`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.Glue_Ink10,
        borderWidth: 1,
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.Glue_Ink00,
        borderWidth: 1,
      },
    },
    '& .MuiInputBase-input': {
      '&::placeholder': {
        color: colors.Black,
      },
    },
    '&[data-open="true"] .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.Glue_Ink00,
    },
    display: 'flex',
    alignItems: 'center',
  }),
  searchLabel: css({
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  }),
  selectOption: css({
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.Glue_Ink00,
  }),
  selectOptionContainer: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(1),
  }),
  selectOptionDescription: css({
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.Glue_Ink10,
  }),
  preselectedPill: css({
    background: colors.Glue_Mint20,
  }),
};

const compactStyleCustomizations = {
  listbox: {
    border: `1px solid ${colors.Glue_Ink30}`,
    borderRadius: 4,
    boxShadow:
      '0px 10px 15px -3px rgba(155, 160, 166, 0.1), 0px 4px 6px -2px rgba(155, 160, 166, 0.06)',
  },
  paper: {
    boxShadow: 'none',
    overflow: 'visible',
    borderRadius: 4,
    border: 'none',
  },
  option: {
    '&[data-focus="true"]': {
      backgroundColor: colors.Glue_LavenderLight,
    },
  },
};

export default AudienceBuilder;
