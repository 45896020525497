/**
 * Returns a string with 0 or 1 decimal places
 *
 * If the string version of the number would end with 0, i.e. 5.0 or 11.0,
 * this 0 is omitted, return 5 or 11 instead.
 * @param num a number
 * @returns a string representation of the number with 0 or 1 decimal places
 */
export const numberToPrettyDecimal = (num: number): string => {
  const asString = num.toFixed(1);
  return Number.isInteger(parseFloat(asString)) ? num.toFixed(0) : asString;
};

export const titleCaseEnum = (enumString: string): string => {
  let result = '';
  if (!enumString) {
    return result;
  }

  for (const char of enumString) {
    if (char.toUpperCase() === char && result.length) {
      result += ` ${char}`;
    } else {
      result += char;
    }
  }
  return result;
};
