import { css } from '@emotion/react';
import { Box, Typography } from '@material-ui/core';
import { Glue_Ink00 } from '@mysteryco/design/src/tokens/colors';
import Celeberate from 'glue/assets/onboarding-celebrate-min.png';
import theme from 'theme';

const SignUp = () => (
  <Box css={styles.root}>
    <Typography css={styles.header}>This is a big milestone, celebrate!</Typography>
    <Box>
      <Typography css={styles.content}>
        A more connected, engaged and resilient team is coming your way
      </Typography>
    </Box>
    <img
      src={Celeberate}
      alt='Hand holding a dart next to a dartboard'
      css={styles.image}
    />
  </Box>
);

const styles = {
  root: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  header: css({
    fontSize: 18,
    fontWeight: 700,
    color: Glue_Ink00,
  }),
  content: css({
    fontSize: 16,
    lineHeight: 2,
    color: Glue_Ink00,
    fontWeight: 500,
    paddingTop: 4,
  }),
  image: css({
    borderRadius: 4,
    width: '100%',
    paddingTop: theme.spacing(3),
  }),
};

export default SignUp;
