import theme from 'theme';

const Back = ({ size = 24, color = theme.palette.primary.main }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.5 7.78469L1.90957 7.19425C1.58348 7.52034 1.58348 8.04903 1.90957 8.37512L2.5 7.78469ZM10 14.4497C9.53884 14.4497 9.165 14.8235 9.165 15.2847C9.165 15.7458 9.53884 16.1197 10 16.1197V14.4497ZM6.42377 5.04179C6.74986 4.7157 6.74986 4.18701 6.42377 3.86092C6.09768 3.53483 5.56899 3.53483 5.2429 3.86092L6.42377 5.04179ZM5.2429 11.7085C5.56899 12.0345 6.09768 12.0345 6.42377 11.7085C6.74985 11.3824 6.74985 10.8537 6.42377 10.5276L5.2429 11.7085ZM13.75 6.94969L2.5 6.94969L2.5 8.61969L13.75 8.61969V6.94969ZM13.75 14.4497H10V16.1197H13.75V14.4497ZM16.665 11.5347C16.665 13.1446 15.3599 14.4497 13.75 14.4497V16.1197C16.2822 16.1197 18.335 14.0669 18.335 11.5347H16.665ZM13.75 8.61969C15.3599 8.61969 16.665 9.92478 16.665 11.5347H18.335C18.335 9.00246 16.2822 6.94969 13.75 6.94969V8.61969ZM5.2429 3.86092L1.90957 7.19425L3.09043 8.37512L6.42377 5.04179L5.2429 3.86092ZM1.90957 8.37512L5.2429 11.7085L6.42377 10.5276L3.09043 7.19425L1.90957 8.37512Z'
        fill={color}
      />
    </svg>
  );
};

export default Back;
