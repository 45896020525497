import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import LogoWithName from 'components/LogoWithName';
import React from 'react';
import { HEADER_HEIGHT } from '../App';
import config from '../config';

const MysteryHeader = ({
  children,
  style,
}: {
  style?: React.CSSProperties;
  children?: React.ReactNode;
}) => {
  const classes = useStyles();
  return (
    <header className={classes.header} style={style}>
      <Box>
        <a href={config.teams.url} className={classes.logoWrapper} id='home-link'>
          <LogoWithName logoSize={80} />
        </a>
      </Box>
      {children || null}
    </header>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    '> *': {
      marginRight: theme.spacing(2),
    },
    height: HEADER_HEIGHT,
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
}));

export default MysteryHeader;
