import { keyframes } from '@emotion/css';

import theme from 'theme';

const spin = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
});

const Loading = ({ size = 24, style = {}, className = '' }) => {
  return (
    <svg
      css={{
        animation: `${spin} 1.5s linear infinite`,
      }}
      className={className}
      width={size}
      height={size}
      style={style}
      viewBox={`0 0 19.3 19.3`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.4'
        d='M14.6738 16.088C15.0643 16.4786 15.6975 16.4786 16.088 16.088C16.4786 15.6975 16.4786 15.0643 16.088 14.6738L14.6738 16.088ZM15.4988 14.0846C15.1083 13.694 14.4751 13.694 14.0846 14.0846C13.694 14.4751 13.694 15.1083 14.0846 15.4988L15.4988 14.0846ZM16.2606 5.22027C16.6511 4.82975 16.6511 4.19658 16.2606 3.80606C15.8701 3.41553 15.2369 3.41553 14.8464 3.80606L16.2606 5.22027ZM13.6679 4.98457C13.2774 5.37509 13.2774 6.00826 13.6679 6.39878C14.0584 6.78931 14.6916 6.78931 15.0821 6.39878L13.6679 4.98457ZM3.39421 15.1916C3.00369 15.5821 3.00369 16.2153 3.39421 16.6058C3.78474 16.9963 4.4179 16.9963 4.80843 16.6058L3.39421 15.1916ZM7.16545 14.2488C7.55597 13.8583 7.55597 13.2251 7.16545 12.8346C6.77492 12.444 6.14176 12.444 5.75123 12.8346L7.16545 14.2488ZM4.98101 3.63347C4.59049 3.24295 3.95733 3.24295 3.5668 3.63347C3.17628 4.02399 3.17628 4.65716 3.5668 5.04768L4.98101 3.63347ZM5.33457 6.81545C5.72509 7.20597 6.35826 7.20597 6.74878 6.81545C7.13931 6.42493 7.13931 5.79176 6.74878 5.40124L5.33457 6.81545ZM16.088 14.6738L15.4988 14.0846L14.0846 15.4988L14.6738 16.088L16.088 14.6738ZM14.8464 3.80606L13.6679 4.98457L15.0821 6.39878L16.2606 5.22027L14.8464 3.80606ZM4.80843 16.6058L7.16545 14.2488L5.75123 12.8346L3.39421 15.1916L4.80843 16.6058ZM3.5668 5.04768L5.33457 6.81545L6.74878 5.40124L4.98101 3.63347L3.5668 5.04768Z'
        fill={theme.palette.primary.main}
      />
      <path
        d='M11 1.875C11 1.32272 10.5523 0.875 10 0.875C9.44772 0.875 9 1.32272 9 1.875H11ZM9 3.95833C9 4.51062 9.44772 4.95833 10 4.95833C10.5523 4.95833 11 4.51062 11 3.95833H9ZM11 15C11 14.4477 10.5523 14 10 14C9.44772 14 9 14.4477 9 15H11ZM9 18.3333C9 18.8856 9.44772 19.3333 10 19.3333C10.5523 19.3333 11 18.8856 11 18.3333H9ZM4.79167 11C5.34395 11 5.79167 10.5523 5.79167 10C5.79167 9.44772 5.34395 9 4.79167 9V11ZM1.875 9C1.32272 9 0.875 9.44772 0.875 10C0.875 10.5523 1.32272 11 1.875 11V9ZM17.7083 11C18.2606 11 18.7083 10.5523 18.7083 10C18.7083 9.44772 18.2606 9 17.7083 9V11ZM16.4583 9C15.906 9 15.4583 9.44772 15.4583 10C15.4583 10.5523 15.906 11 16.4583 11V9ZM9 1.875V3.95833H11V1.875H9ZM9 15V18.3333H11V15H9ZM4.79167 9H1.875V11H4.79167V9ZM17.7083 9H16.4583V11H17.7083V9Z'
        fill={theme.palette.primary[900]}
      />
    </svg>
  );
};

export default Loading;
