import { css } from '@emotion/react';
import { colors } from '@mysteryco/design';
import { forwardRef } from 'react';

type Props = {
  value: React.ReactNode;
};

export const TabLabel = forwardRef<HTMLSpanElement, Props>(({ value }, ref) => {
  return (
    <span css={styles.root} ref={ref}>
      {value}
    </span>
  );
});

const styles = {
  root: css({
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '.75rem',
    lineHeight: 1,
    display: 'flex',
    alignItems: 'center',
    color: colors.Glue_Ink10,
    textTransform: 'uppercase',
  }),
};
