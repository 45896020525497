import mixpanel from 'mixpanel-browser';
import { Step, Steps, Wizard } from 'react-albus';
import MoralePulseSurveyIntro from './MoralePulseSurveyIntro';
import PulseQuestion from './MoralePulseQuestion';
import { ReactElement, useCallback, useRef } from 'react';
import MoralePulseSurveySuccess from './MoralePulseSurveySuccess';
import { css } from '@emotion/react';
import { Maybe, MoralePulseQuestionReponse } from 'types';

export interface PulseSurveyAnswer {
  value: number;
  questionId: string;
  comment?: string | null;
}

interface PulseSurveyProps {
  questions: { id: string; copy: string; options: Maybe<string>[] }[];
  name: string;
  logoUrl?: string | null;
  orgName: string;
  onSubmit: (answers: PulseSurveyAnswer[]) => void | Promise<void>;
  showIntro?: boolean;
}

export const PulseSurvey = ({
  questions,
  name,
  logoUrl,
  orgName,
  onSubmit: submitSurvey,
  showIntro = true,
}: PulseSurveyProps) => {
  const surveyAnswersRef = useRef<Record<string, MoralePulseQuestionReponse>>({});

  const getInitialValues = useCallback((questionId: string) => {
    const existing = surveyAnswersRef.current[questionId];
    if (existing) return existing;
    return { questionId, value: -1, comment: '' };
  }, []);
  const finalQuestionId = questions[questions.length - 1]?.id;
  const steps: ReactElement[] = [];

  if (showIntro)
    steps.push(
      <Step
        id={'intro'}
        render={({ next }) => (
          <MoralePulseSurveyIntro
            next={next}
            name={name}
            orgName={orgName}
            logoUrl={logoUrl}
          />
        )}
      />,
    );
  steps.push(
    ...questions.map((question, index) => (
      <Step
        id={question.id}
        render={({ next, previous }) => (
          <PulseQuestion
            question={question}
            index={index}
            previous={previous}
            surveyLength={questions.length}
            getInitialValues={getInitialValues}
            logoUrl={logoUrl}
            onSubmit={async (answer) => {
              surveyAnswersRef.current[question.id] = answer;
              if (question.id === finalQuestionId) {
                mixpanel.track(
                  'pls-survey-finished',
                  {
                    surveyLength: questions.length,
                  },
                  {
                    send_immediately: true,
                  },
                );
                await submitSurvey(
                  Object.values(surveyAnswersRef.current).map((answer) => {
                    return {
                      questionId: answer.questionId,
                      value: answer.value!,
                      comment: answer.comment,
                    };
                  }),
                );
              }
              next();
            }}
          />
        )}
      />
    )),
  );
  steps.push(
    <Step id='complete' render={() => <MoralePulseSurveySuccess logoUrl={logoUrl} />} />,
  );
  return (
    <div css={styles.form}>
      <Wizard
        onNext={async (wizard) => {
          if (wizard.step.id === 'intro') {
            mixpanel.track('pls-survey-started', {
              surveyLength: questions.length,
            });
          } else {
            mixpanel.track('pls-survey-next', {
              step: wizard.step.id,
            });
          }
          wizard.push();
        }}
      >
        <Steps>{steps}</Steps>
      </Wizard>
    </div>
  );
};

const styles = {
  form: css({
    flex: 1,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
  }),
};
