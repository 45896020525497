import { Formik, FormikConfig, FormikHelpers, FormikValues } from 'formik';
import Form from './Form';
import { useCallback } from 'react';

export interface FormikFormProps<Values> extends FormikConfig<Values> {
  className?: string;
}

/**
 * Combines Formik + Form into one component for convenience. Use of render prop
 * is discouraged with this form system. To access form state,
 * use the `useFormikContext` hook, but for most fields you should be able
 * to utilize the field components in `glue/components/forms`.
 */
export function FormikForm<Values extends FormikValues>({
  className,
  children,
  onSubmit,
  ...props
}: FormikFormProps<Values>) {
  const wrappedOnSubmit = useCallback(
    async (values: Values, bag: FormikHelpers<Values>) => {
      try {
        bag.setSubmitting(true);
        return await onSubmit(values, bag);
      } finally {
        bag.setSubmitting(false);
      }
    },
    [onSubmit],
  );

  if (typeof children === 'function') {
    return (
      <Formik<Values> onSubmit={wrappedOnSubmit} {...props}>
        {(formik) => <Form className={className}>{children(formik)}</Form>}
      </Formik>
    );
  }

  return (
    <Formik<Values> onSubmit={wrappedOnSubmit} {...props}>
      <Form className={className}>{children}</Form>
    </Formik>
  );
}

export default FormikForm;
