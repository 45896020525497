import { gql } from '@apollo/client';
import { css } from '@emotion/react';
import { Loading } from 'components/core';
import NotSetUp from 'glue/scenes/Explore/Pulse/NotSetUp';
import ResultsSummary from 'glue/scenes/Explore/Pulse/ResultsSummary';
import WaitingForFirstSurvey from 'glue/scenes/Explore/Pulse/WaitingForFirstSurvey';
import { ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';
import { usePulseReportingQuery } from 'types';

export default function PulseReporting() {
  const { data, loading } = usePulseReportingQuery();

  const waitingForFirstMoralePulse =
    data?.viewer?.defaultOrganization?.waitingForFirstMoralePulse;

  let content: ReactElement | null = null;
  if (loading) {
    content = <Loading />;
  } else if (waitingForFirstMoralePulse) {
    const startDate = data?.viewer?.defaultOrganization?.moralePulses?.[0]?.startDate;
    content = <WaitingForFirstSurvey surveyDate={startDate} />;
  } else if (!data?.viewer?.defaultOrganization?.hasMoralePulseBeenSetUp) {
    content = <NotSetUp />;
  } else {
    content = <ResultsSummary />;
  }

  return (
    <main css={styles.layout}>
      <Helmet>
        <title>Pulse Overview</title>
      </Helmet>
      {content}
    </main>
  );
}

PulseReporting.query = gql`
  query PulseReporting {
    viewer {
      id
      defaultOrganization {
        id
        hasMoralePulseBeenSetUp
        waitingForFirstMoralePulse
        moralePulses {
          id
          startDate
          activeSurvey {
            id
          }
        }
      }
    }
  }
`;

const styles = {
  layout: css({
    marginTop: '20px',
    gap: 20,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '& *': {
      fontFamily: 'inherit',
    },
  }),
};
