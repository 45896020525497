import _ from 'lodash';
import config from 'config';
import { v4 } from 'uuid';
import { DateTime, Interval } from 'luxon';
import { Maybe, TimeSlot, TimeWindow } from 'types';
import { EventForSelection, ExperienceInfo } from 'types/selfServeFlow';
import { addBusinessDaysToDate } from 'utils/dateUtil';
import { existsFilter } from 'lib/helpers/maybe';

const TIME_WINDOW_INTERVAL_MINUTES = 30;
const CAN_RTB_AFTER_DAYS = 2;

const HOLIDAY_BLACKOUTS = [
  // 2023
  DateTime.fromISO('2023-01-02').toJSDate(), // new years
  DateTime.fromISO('2023-01-16').toJSDate(), // MLK day
  DateTime.fromISO('2023-03-21').toJSDate(), // Q1 offsite
  DateTime.fromISO('2023-05-29').toJSDate(), // Memorial Day
  DateTime.fromISO('2023-06-19').toJSDate(), // Juneteenth
  DateTime.fromISO('2023-07-04').toJSDate(), // THIS. Is our INDEPENDENCE DAY.
  DateTime.fromISO('2023-08-04').toJSDate(), // Labor Day
  DateTime.fromISO('2023-11-23').toJSDate(), // Thanks...
  DateTime.fromISO('2023-11-24').toJSDate(), // ...giving
  DateTime.fromISO('2023-12-25').toJSDate(), // Christmas
  // 2024
  DateTime.fromISO('2024-01-01').toJSDate(), // new years
  DateTime.fromISO('2024-01-15').toJSDate(), // MLK day
  DateTime.fromISO('2024-02-19').toJSDate(), // Lincoln's Birfday
  DateTime.fromISO('2024-05-27').toJSDate(), // Memorial Day
  DateTime.fromISO('2024-06-19').toJSDate(), // Juneteenth
  DateTime.fromISO('2024-07-04').toJSDate(), // THIS. Is our INDEPENDENCE DAY.
  DateTime.fromISO('2024-08-02').toJSDate(), // Labor Day
  DateTime.fromISO('2024-11-28').toJSDate(), // Thanks...
  DateTime.fromISO('2024-11-29').toJSDate(), // ...giving
  DateTime.fromISO('2024-12-25').toJSDate(), // Christmas
];

export const automatedSlotsToEvent = ({
  slots,
}: {
  slots: TimeSlot[];
}): EventForSelection[] => {
  return filterEventsForCompatability(
    slots.map((slot) => {
      return {
        id: v4(),
        start: slot.startTime,
        end: slot.endTime,
        emails: slot.emails,
      };
    }),
  );
};

const isWeekend = (date: DateTime): Boolean => {
  const isSaturday = date.weekday === 6;
  const isSunday = date.weekday === 7;
  if (isSaturday || isSunday) return true;
  return false;
};

const ensureNoWeekends = (date: DateTime): DateTime => {
  const isSaturday = date.weekday === 6;

  if (isWeekend(date)) {
    date = date.plus({ days: isSaturday ? 2 : 1 });
  }
  return date;
};

// Makes 2000 slots for event selection if no calendar availablity is used
export const makeDummyEventsForSelection = (): EventForSelection[] => {
  let firstDate = DateTime.fromJSDate(
    addBusinessDaysToDate(
      DateTime.now().setZone(config.defaultTimezone).toJSDate(),
      CAN_RTB_AFTER_DAYS,
    ),
  ).set({ minute: 0, second: 0, millisecond: 0 });

  // Force into working hours
  if (firstDate.hour < 7) {
    firstDate = firstDate.set({ hour: config.pacificTimeOperatingHours.start });
  } else if (firstDate.hour > 18) {
    firstDate = firstDate
      .set({ hour: config.pacificTimeOperatingHours.start })
      .plus({ day: 1 });
  }

  const events = [
    {
      id: v4(),
      start: firstDate.toISO(),
      end: firstDate.plus({ minutes: 30 }).toISO(),
      emails: null,
    },
  ];

  for (let i = 0; i < 1999; i++) {
    const lastEvent = events[events.length - 1];
    let nextEventStart = DateTime.fromISO(lastEvent.end).setZone(config.defaultTimezone);

    if (nextEventStart.hour > config.pacificTimeOperatingHours.end - 1) {
      nextEventStart = nextEventStart
        .plus({ day: 1 })
        .set({ hour: config.pacificTimeOperatingHours.start });
    }
    nextEventStart = ensureNoWeekends(nextEventStart);

    events.push({
      id: v4(),
      start: nextEventStart.toISO(),
      end: nextEventStart.plus({ minutes: 30 }).toISO(),
      emails: null,
    });
  }

  const filtered = filterEventsForCompatability(events.filter(existsFilter));

  return filtered;
};

const filterEventsForCompatability = (events: EventForSelection[]) => {
  return events.filter(
    (event) =>
      isTimeBlockCompatibleWithOpenHours({ startISO: event.start }) &&
      !isWeekend(DateTime.fromISO(event.start)) &&
      !HOLIDAY_BLACKOUTS.some((date) =>
        DateTime.fromJSDate(date).hasSame(DateTime.fromISO(event.start), 'day'),
      ),
  );
};

export const windowsToEvents = ({
  windows,
}: {
  windows: TimeWindow[];
}): EventForSelection[] => {
  const eventsCreated = _.map(windows, (window) => {
    const startDate = DateTime.fromJSDate(new Date(window.startTime));
    const endDate = DateTime.fromJSDate(new Date(window.endTime));
    const hours = Math.abs(startDate.hour - endDate.hour);
    // create TIME_WINDOW_INTERVAL_MINUTES segments
    const newEvents = [...Array(hours * (60 / TIME_WINDOW_INTERVAL_MINUTES))].map(
      (__, i) => {
        const startTime = startDate.plus({ minutes: i * TIME_WINDOW_INTERVAL_MINUTES });
        /**
         * Make sure that the user's timezone is compatible with our PT-based open hours
         * This only needs to happen for the MysterySelection because with the other flows
         * we handle this on the backend with RTBOperatingHours or the experience's operating hours.
         * Unable to do that in this flow because we don't have any experience to base it off of!
         * It's a mystery all around!
         */

        if (!isTimeBlockCompatibleWithOpenHours({ startISO: startTime.toISO() })) {
          return undefined;
        }

        const newEvent = {
          id: v4(),
          start: startTime.toISO(),
          end: startTime.plus({ minutes: 60 }).toISO(),
        };
        return newEvent;
      },
    );
    return newEvents;
  });
  return eventsCreated.flat().filter(existsFilter);
};

const isTimeBlockCompatibleWithOpenHours = ({ startISO }) => {
  // Filter for Mystery Open Hours
  const dateTime = DateTime.fromISO(startISO)
    .setZone(config.defaultTimezone)
    .startOf('day');
  const PTOpenInterval = Interval.fromDateTimes(
    dateTime.set({ hour: config.pacificTimeOperatingHours.start }),
    dateTime.set({ hour: config.pacificTimeOperatingHours.end }),
  );
  const timeBlockinPT = DateTime.fromISO(startISO).setZone(config.defaultTimezone);

  // Filter for customer open hours
  const userDateTime = DateTime.fromISO(startISO).startOf('day');
  const userOpenHoursInterval = Interval.fromDateTimes(
    userDateTime.set({ hour: config.customerWorkinghours.start }),
    userDateTime.set({ hour: config.customerWorkinghours.end }),
  );
  const timeBlockForUser = DateTime.fromISO(startISO);

  return (
    PTOpenInterval.contains(timeBlockinPT) &&
    userOpenHoursInterval.contains(timeBlockForUser)
  );
};

export const makeGroupedEventsForMysteryToAll = ({
  availableEventTimes,
}: {
  availableEventTimes: EventForSelection[];
}): {
  [dateString: string]: {
    start: string;
    end: string;
    id: string;
    emails?: Maybe<Maybe<string>[]>;
  }[];
} => {
  const groupedEvents = _.groupBy(availableEventTimes, (ev) => {
    const startDate = DateTime.fromJSDate(new Date(ev.start));
    return `${startDate.toLocaleString()}`;
  });
  return groupedEvents;
};

export const getHasPhysicalGoodsOrUpgradeFromTemplate = (
  template: {
    experiences: Maybe<Pick<ExperienceInfo, 'hasPhysicalGoods' | 'requiresUpgrade'>>[];
  } | null,
): { hasPhysicalGoods: boolean; requiresUpgrade: boolean } => {
  if (!template) {
    return {
      hasPhysicalGoods: false,
      requiresUpgrade: false,
    };
  }

  const hasPhysicalGoods = template.experiences.some((exp) => exp?.hasPhysicalGoods);
  const requiresUpgrade = template.experiences.some((exp) => exp?.requiresUpgrade);
  return {
    hasPhysicalGoods,
    requiresUpgrade,
  };
};
