// @ts-strict-ignore
import _ from 'lodash';
import { Step } from 'react-albus';
import AccountStep from 'scenes/AccountSetup/steps/Account';
import CalendarSync from 'scenes/AccountSetup/steps/CalendarSync';
import { NylasConnectionState, ViewerFragmentFragment } from 'types';

interface AccountStepProps {
  viewer: ViewerFragmentFragment;
  showCalendarSync?: boolean;
}

export const renderAccountStep = ({ viewer, showCalendarSync }: AccountStepProps) => {
  const componentsToRender = [];
  const accountFilledOutEnough = _.every(
    ['firstName', 'lastName'].map((prop) => !!_.get(viewer, prop)),
  );

  if (!accountFilledOutEnough) {
    const account = (
      <Step
        id={`account`}
        key='accountStep'
        render={(context) => <AccountStep {...context} viewer={viewer} />}
      />
    );
    componentsToRender.push(account);
  }

  if (
    showCalendarSync ||
    (showCalendarSync === undefined &&
      viewer.nylasConnectionState === NylasConnectionState.Inactive)
  ) {
    const calendarSyncStep = (
      <Step
        id={'calendarSync'}
        key='calendarStep'
        render={(context) => <CalendarSync {...context} viewer={viewer} />}
      />
    );
    componentsToRender.push(calendarSyncStep);
  }

  return componentsToRender;
};
