import { cx } from '@emotion/css';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import SearchMd from '@mysteryco/design/icons/SearchMd';
import { colors } from '@mysteryco/design/src';
import EmptyLoadingSpinner from 'components/EmptyLoadingSpinner';
import theme from 'theme';

const SearchBar = ({
  query,
  onChange,
  placeholder = 'Search',
  disabled = false,
  loading = false,
  ...props
}: {
  query: string;
  onChange: (event: any) => void;
  placeholder?: string;
  disabled?: boolean;
  loading?: boolean;
  [key: string]: any;
}) => {
  const classes = useStyles();
  return (
    <InputBase
      startAdornment={<SearchMd />}
      endAdornment={loading && <EmptyLoadingSpinner />}
      className={cx(classes.search, loading && classes.loading)}
      value={query}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      {...props}
    />
  );
};

const useStyles = makeStyles({
  search: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: `${theme.spacing(2.5)} ${theme.spacing(4)}`,
    gap: theme.spacing(2),
    background: colors.White,
    border: '1px solid #D5DCDE',
    borderRadius: theme.spacing(10),
    fontWeight: 500,
    lineHeight: 1.5,
    color: colors.Black,
    width: '100%',
    transition: 'all 300ms, ease-in-out',
    caretColor: colors.Main,
    '& input': {
      padding: 0,
    },
    '& svg': {
      height: 16,
      width: 16,
      color: colors.MediumGray,
    },
    '& ::placeholder': {
      opacity: 1,
      color: colors.MediumGray,
    },
    '&:hover': {
      borderColor: colors.Purple200,
    },
    '&:focus-within': {
      borderColor: colors.Main,
      '& svg': {
        color: colors.Main,
      },
    },
    '&.Mui-disabled': {
      background: colors.OffWhite,
      color: colors.MediumGray,
      '&:focus-within, &:hover': {
        borderColor: colors.LightGray,
      },
    },
  },
  loading: {
    '&$search': {
      background: colors.Purple50,
      borderColor: colors.Main,
      color: colors.Main,
      '& svg': {
        color: colors.Main,
      },
    },
  },
});

export default SearchBar;
