import { css } from '@emotion/react';
import theme from 'theme';

interface Props {
  widths?: string[];
  className?: string;
}

const CustomSkeletonBar = ({ className }: { className?: string }) => (
  <div className={className} css={styles.defaultBar}>
    {' '}
  </div>
);

const CustomSkeleton = ({ className, widths = ['100%'] }: Props) => (
  <div className={className} css={styles.root}>
    {widths.map((width) => (
      <CustomSkeletonBar css={css({ width })} />
    ))}
  </div>
);

const styles = {
  root: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
    marginTop: theme.spacing(4),
  }),
  defaultBar: css({
    alignSelf: 'stretch',
    background: 'rgba(233, 235, 241, 0.75)',
    minHeight: '1.2em',
    borderRadius: '5px',
  }),
};

export default CustomSkeleton;
