declare global {
  interface Window {
    HubSpotConversations: any;
  }
}

export const openHubspotChat = () => {
  const openChat = window?.HubSpotConversations?.widget?.open;
  openChat && openChat();
};
