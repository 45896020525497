import React from 'react';
import {
  Elements,
  injectStripe,
  ReactStripeElements,
  StripeProvider,
} from 'react-stripe-elements';

import config from '../config';

export const StripeContext = React.createContext(null);

const StripeProviderInner = injectStripe(
  ({
    children,
    stripe,
  }: { children: React.ReactNode } & ReactStripeElements.InjectedStripeProps) => {
    return <StripeContext.Provider value={stripe}>{children}</StripeContext.Provider>;
  },
);

const StripeProviderOuter = (props) => (
  <StripeProvider apiKey={config.stripe.publishableKey}>
    <Elements>
      <StripeProviderInner {...props} />
    </Elements>
  </StripeProvider>
);

export default StripeProviderOuter;
