import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@mysteryco/design';
import { ReactNode } from 'react';
import theme from 'theme';

const FormNote = ({ children, icon }: { children: ReactNode; icon?: ReactNode }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      {!!icon && <Box className={classes.icon}>{icon}</Box>}
      <Box className={classes.text}>{children}</Box>
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: theme.spacing(3),
    gap: theme.spacing(3),
    background: colors.Green100,
    borderRadius: theme.spacing(1),
  },
  text: {
    fontWeight: 700,
    fontSize: '.75rem',
    lineHeight: 1.4,
    color: colors.Dusk,
  },
  icon: {
    color: colors.Dusk,
    width: 16,
    height: 16,
  },
});

export default FormNote;
