// @ts-strict-ignore
import { Box, ButtonBase, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Trash03 from '@mysteryco/design/icons/Trash03';
import { colors } from '@mysteryco/design/src';
import TextInput from 'components/Forms/TextInput';
import { useState } from 'react';
import DeleteTagConfirmation from 'scenes/Team/Team/Modals/DeleteTagConfirmation';
import theme from 'theme';
import { UserTag } from 'types';
import LabeledInput from './LabeledInput';

export const isTagDuplicate = (newTag: UserTag, userTags: UserTag[]): boolean => {
  return userTags?.some(
    (userTag) => userTag.name === newTag?.name && userTag.id !== newTag?.id,
  );
};

export const isTagChanged = (newTag: UserTag, userTags: UserTag[]): boolean => {
  const oldTag = userTags?.find((userTag) => userTag.id === newTag.id);
  return oldTag && newTag ? oldTag.name !== newTag.name : true;
};

export const tagFilterOptions = (options: UserTag[], query: string): UserTag[] => {
  if (!query?.trim()) return options;
  if (!options?.length) return [];
  const tokens = query.toLocaleLowerCase().split(' ');
  return options.filter((option) =>
    tokens.some((token) => option?.name?.toLocaleLowerCase().includes(token)),
  );
};

const EditUserTag = ({
  userTag,
  userTagOptions,
  onChange,
  onDelete = () => null,
  classes,
}: {
  userTag: UserTag;
  userTagOptions: UserTag[];
  onChange: (event: any, newValue: UserTag) => void;
  onDelete?: (
    event: any,
    tagIds: string[],
    onChange: (event: any, newValue: UserTag) => void,
  ) => Promise<void>;
  classes: any;
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleChange = (e) => {
    onChange(e, { ...userTag, name: e.target.value });
  };

  const handleDelete = async (e) => {
    await onDelete(e, [userTag.id], onChange);
    setConfirmOpen(false);
  };

  const error = isTagDuplicate(userTag, userTagOptions)
    ? 'Tag name is already in use – try again'
    : '';

  return (
    <>
      <Box className={classes.activeContainer}>
        <Box className={classes.activeEdit}>
          <Typography className={classes.activeHeader}>Tag options</Typography>
          <LabeledInput label='Tag name' error={error} fullWidth>
            <TextInput
              value={userTag.name}
              onChange={handleChange}
              label='Tag name'
              error={!!error}
              fullWidth
              autoFocus
            />
          </LabeledInput>
        </Box>
        <ButtonBase
          className={classes.activeDeleteButton}
          onClick={() => setConfirmOpen(true)}
        >
          <Trash03 size={16} color={colors.Main} />
          Delete tag
        </ButtonBase>
      </Box>
      <DeleteTagConfirmation
        tag={userTag}
        isShowing={confirmOpen}
        onDelete={handleDelete}
        onCancel={() => setConfirmOpen(false)}
      />
    </>
  );
};

// Casting flexDirection and textAlign:
// https://github.com/cssinjs/jss/issues/1344

export default withStyles({
  activeContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    gap: theme.spacing(2),
  },
  activeEdit: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    gap: theme.spacing(2),
  },
  activeHeader: {
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: 2,
    color: colors.Dusk,
  },
  activeDeleteButton: {
    justifyContent: 'start',
    textAlign: 'left' as 'left',
    padding: `${theme.spacing(2)} 0`,
    color: colors.DarkGray,
    textDecorationLine: 'underline',
    gap: theme.spacing(2),
    transition: 'color 300ms ease-in-out',
    '&:hover': {
      color: colors.Main,
    },
  },
})(EditUserTag);
