import { css } from '@emotion/react';
import { GLUE_BANNER_HEIGHT } from 'glue/components/branding/GlueBanner';
import { Paths } from 'Routes';
import SettingsTopper from 'glue/components/structure/SettingsTopper';
import { useHistory } from 'react-router-dom';
import theme from 'theme';

interface Props {
  children: React.ReactNode;
  next?: () => void;
  previous?: () => void;
  finish?: () => void;
  disableClose?: boolean;
  nextText?: string;
  finishText?: string;
  noSettingsTopper?: boolean;
}

const MoralePulseStepWrapper = ({
  children,
  next,
  previous,
  finish,
  disableClose,
  nextText,
  finishText,
  noSettingsTopper,
}: Props) => {
  const history = useHistory();
  return (
    <div css={styles.container}>
      {!noSettingsTopper && (
        <SettingsTopper
          title='Pulse Settings'
          onBack={previous}
          onNext={next}
          onFinish={finish}
          disableClose={disableClose}
          nextText={nextText}
          finishText={finishText}
          css={styles.header}
          onClose={() => history.push(`${Paths.EXPLORE}/pulse`)}
        />
      )}
      {children}
    </div>
  );
};

const styles = {
  container: css({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  header: css({
    position: 'sticky',
    top: GLUE_BANNER_HEIGHT,
    zIndex: theme.zIndex.appBar,
  }),
};

export default MoralePulseStepWrapper;
