import GlueBanner from 'glue/components/branding/GlueBanner';
import { default as GlueDrawerWrapper } from 'glue/components/navigation/DrawerWrapper';

type Props = {
  navItems?: { label: string; href: string }[];
  children: React.ReactNode;
};

const BrandWrapper = ({ ...props }: Props) => (
  <>
    <GlueBanner />
    <GlueDrawerWrapper {...props} />
  </>
);

export default BrandWrapper;
