import { css } from '@emotion/react';
import { colors } from '@mysteryco/design';
import ArrowLeft from '@mysteryco/design/icons/ArrowLeft';
import ArrowRight from '@mysteryco/design/icons/ArrowRight';
import XClose from '@mysteryco/design/icons/XClose';
import { useHistory } from 'react-router-dom';
import theme from 'theme';
import Button from '../buttons/Button';

interface Props {
  title: string;
  onNext?: () => void;
  disableNext?: boolean;
  nextText?: string;
  onBack?: () => void;
  disableBack?: boolean;
  backText?: string;
  onFinish?: () => void;
  onClose?: () => void;
  disableFinish?: boolean;
  finishText?: string;
  loading?: boolean;
  disableClose?: boolean;
  className?: string;
}

const SettingsTopper = ({
  title,
  onNext,
  disableNext = false,
  nextText,
  onBack,
  disableBack = false,
  backText,
  onFinish,
  onClose,
  disableFinish = false,
  finishText,
  loading = false,
  disableClose = false,
  ...rest
}: Props) => {
  return (
    <SettingsTopperRoot {...rest}>
      <SettingsTopperContent>
        <SettingsTopperTitle>{title}</SettingsTopperTitle>
        <SettingsTopperActions>
          {onBack && (
            <Button
              onClick={onBack}
              size='compact'
              color='white'
              disabled={disableBack}
              startIcon={<ArrowLeft size={22} color={colors.Glue_Ink00} />}
            >
              {backText || 'Back'}
            </Button>
          )}
          {onNext && (
            <Button
              color='purple'
              size='compact'
              onClick={onNext}
              endIcon={<ArrowRight size={22} color={colors.Glue_Paper} />}
              loading={loading}
              disabled={disableNext}
            >
              {nextText || 'Next'}
            </Button>
          )}
          {onFinish && (
            <Button
              size='compact'
              color='purple'
              onClick={onFinish}
              loading={loading}
              disabled={disableFinish}
            >
              {finishText || 'Finish'}
            </Button>
          )}
          {!disableClose && <SettingsTopperCloseButton onClick={onClose} />}
        </SettingsTopperActions>
      </SettingsTopperContent>
    </SettingsTopperRoot>
  );
};

/**
 * usage:
 *
 * ```tsx
 * <SettingsTopperRoot>
 *  <SettingsTopperContent>
 *   <SettingsTopperTitle>My Title</SettingsTopperTitle>
 *   <SettingsTopperActions>
 *    <Button>My Button</Button>
 *   </SettingsTopperActions>
 *  </SettingsTopperContent>
 * </SettingsTopperRoot>
 * ```
 */
export const SettingsTopperRoot = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <div css={styles.container} className={className}>
    {children}
  </div>
);

export const SettingsTopperContent = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <div css={styles.content} className={className}>
    {children}
  </div>
);

export const SettingsTopperTitle = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <div css={styles.title} className={className}>
    {children}
  </div>
);

export const SettingsTopperActions = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <div css={styles.navigation} className={className}>
    {children}
  </div>
);

export const SettingsTopperCloseButton = ({
  onClick,
  className,
}: {
  onClick?: () => void;
  className?: string;
}) => {
  const history = useHistory();

  const handleClick = onClick ?? (() => history.goBack());
  return (
    <button css={styles.xContainer} onClick={handleClick} className={className}>
      <XClose color='currentColor' />
    </button>
  );
};

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: `${theme.spacing(5)} ${theme.spacing(20)}`,
    width: '100%',
    background: colors.Glue_Paper,
    borderBottom: `1px solid ${colors.Glue_BorderLight}`,
  }),
  content: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  }),
  navigation: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(5),
  }),
  title: css({
    fontWeight: 800,
    fontSize: 16,
    lineHeight: 1,
    color: colors.Glue_Ink00,
  }),
  xContainer: css({
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    color: colors.Glue_Ink00,
  }),
};

export default SettingsTopper;
