import _ from 'lodash';
import { Card, Grid, IconButton, Typography } from '@material-ui/core';
import { Skeleton } from 'components/Skeleton';
import { makeStyles } from '@material-ui/styles';
import theme from 'theme';
import {
  ExpandMore,
  TrendingDown,
  TrendingUp,
  Close,
  TrendingFlat,
  CallMade,
} from '@material-ui/icons';
import { getScoreRating, ScoreRating } from 'glue/components/insights/data/utility';

export interface ScoringCardProps {
  loading?: boolean;
  title: string;
  value: number;
  trend?: string;
  active?: boolean;
  onClose?: () => unknown;
  showMeasureBar?: boolean;
  [key: string]: any;
}

const ScoringCard = ({
  loading = false,
  title,
  value,
  trend = 'Neutral',
  active = false,
  onClose,
  showMeasureBar = false,
  ...otherProps
}: ScoringCardProps) => {
  const classes = useStyles();
  const scoreRating = getScoreRating(value);
  return (
    <Card
      className={classes.card}
      css={
        active
          ? {
              borderColor: theme.palette.primary[500],
              filter: 'drop-shadow(rgb(242, 232, 243, .5) 0 .5rem 0.35rem)',
            }
          : {
              borderColor: theme.palette.primary[100],
            }
      }
      {...otherProps}
    >
      {!active && <div className={classes.pseudoTooltip}>View details</div>}
      <Grid container className={classes.container}>
        <Grid item>
          <Typography
            className={classes.title}
            css={{
              color: active ? theme.palette.text.primary : theme.palette.grey[700],
            }}
          >
            {title}
          </Typography>
          {loading && scoreRating === '' ? (
            <Skeleton height={24} width={100} />
          ) : (
            <div className={classes.evaluation}>
              <Typography className={classes.valueText}>{scoreRating}</Typography>
              {trend === 'Rising' ? (
                <TrendingUp className={classes.trendIcon} />
              ) : trend === 'Falling' ? (
                <TrendingDown className={classes.trendIcon} />
              ) : (
                <TrendingFlat className={classes.trendIcon} />
              )}
            </div>
          )}
        </Grid>
        <Grid item>
          <IconButton
            className={classes.headerIcon}
            css={
              active
                ? {
                    backgroundColor: theme.palette.text.secondary,
                    color: theme.palette.common.white,
                  }
                : {}
            }
            onClick={(event) => {
              if (!active) return;
              event.preventDefault();
              event.stopPropagation();
              onClose?.();
            }}
          >
            {active ? (
              <Close />
            ) : (
              <div>
                <ExpandMore className={classes.iconHide} />
                <CallMade className={classes.iconHover} />
              </div>
            )}
          </IconButton>
        </Grid>
      </Grid>
      {(active || showMeasureBar) && (
        <Grid container spacing={0} className={classes.measureBar}>
          {_.orderBy(ScoreRating, 'threshold').map((score, index) => {
            return (
              <Grid
                item
                xs={3}
                key={index}
                className={classes.measureUnit}
                css={{
                  backgroundColor:
                    scoreRating === score.rating
                      ? score.highlightColor
                      : score.backgroundColor,
                }}
              ></Grid>
            );
          })}
        </Grid>
      )}
    </Card>
  );
};

const useStyles = makeStyles({
  iconHide: {
    display: 'block',
  },
  iconHover: {
    display: 'none',
  },
  pseudoTooltip: {
    zIndex: 1,
    fontSize: '11px',
    fontWeight: 600,
    lineHeight: '11px',
    position: 'absolute',
    top: 44,
    right: 20,
    padding: '5px 8px',
    borderRadius: 4,
    color: theme.palette.common.white,
    background: theme.palette.text.primary,
    opacity: 0,
    transition: 'all ease-in-out 3ms',
  },
  card: {
    maxWidth: '240px',
    borderWidth: '1px',
    borderStyle: 'solid',
    padding: `${theme.spacing(3)} ${theme.spacing(5)}`,
    marginBottom: theme.spacing(3),
    boxShadow: 'none',
    transition: 'all ease-in 300ms',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      cursor: 'pointer',
      filter: 'drop-shadow(rgb(242, 232, 243, .5) 0 .5rem 0.35rem)',
      '& $iconHover': {
        display: 'block',
      },
      '& $iconHide': {
        display: 'none',
      },
      '& $pseudoTooltip': {
        opacity: 1,
      },
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 500,
    fontSize: '14px',
  },
  headerIcon: {
    padding: 0,
    width: 28,
    height: 28,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    borderRadius: '8px',
    boxShadow:
      '0px 4px 6px -1px rgba(155, 160, 166, 0.1), 0px 2px 4px -1px rgba(155, 160, 166, 0.07)',
    '&:hover': {
      backgroundColor: 'initial',
    },
    '& svg': {
      width: 16,
      height: 16,
    },
  },
  evaluation: {
    display: 'flex',
    alignItems: 'center',
  },
  valueText: {
    fontWeight: 700,
    fontSize: '16px',
    color: theme.palette.text.primary,
  },
  trendIcon: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary[700],
    fontSize: '16px',
  },
  measureBar: {
    marginTop: theme.spacing(2),
    height: '8px',
  },
  measureUnit: {
    borderWidth: '0 1px',
    borderStyle: 'solid',
    borderColor: theme.palette.common.white,
  },
});

export default ScoringCard;
