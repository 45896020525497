import _ from 'lodash';
import React from 'react';
import { ValidateStepArgs } from '../components/SSFSteps/types';
import { getCurrentStep, getCurrentStepIndex } from '../lib/helpers/router';
import { SelfServeState, SelfServeStep } from '../types/selfServeFlow';

interface SelfServeValidationArgs {
  globalState: SelfServeState;
  steps: SelfServeStep[];
  activeStep: string;
  validateStep(args: ValidateStepArgs): void;
}

const useSelfServeValidation = ({
  globalState,
  validateStep,
  steps,
  activeStep,
}: SelfServeValidationArgs) => {
  const currentStep = getCurrentStep({ steps, activeStep });

  React.useEffect(() => {
    // On every change, validate all steps in the flow. If there is an invalid step, short circuit
    // and force validate all future steps to `false`. We don't want users bouncing around to valid
    // steps as many stages in the wizard are dependent on previous step inputs.
    if (validateStep && currentStep) {
      const currentStepIndex = getCurrentStepIndex({ activeStep, steps });
      steps.forEach((s, i) => {
        const { key, schema } = _.get(s, 'value');
        if (i > currentStepIndex) {
          validateStep({ key, schema, state: globalState, forceValidation: false });
        } else {
          validateStep({ key, schema, state: globalState });
        }
      });
    }
  }, [globalState, activeStep]);
};

export default useSelfServeValidation;
