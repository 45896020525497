// @ts-strict-ignore
import { Loading, NotFoundError } from 'components/core';
import { teamEventFragment, virtualEventFragment } from 'gql/queries';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import { EventDetailsQuery, InvitedGuestRsvpStatus, User } from 'types';
import { getEventDetails, getUserRsvpStatus } from 'utils/state';
import { useHistory } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Box, Breadcrumbs, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import JoinAndFeedbackSection from './JoinAndFeedbackSection';
import { addAttendeeToVirtualEvent } from 'gql/mutations';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import { getFeedbackUrl, isEventLive, isHost } from 'lib/helpers/events';
import TeamEventDetail from 'components/TeamEventDetail';
import Mail01 from 'components/icons/Mail01';
import theme from 'theme';
import CheckVerified01 from 'components/icons/CheckVerified01';
import { green, orange } from '@material-ui/core/colors';
import XCircle from 'components/icons/XCircle';
import NewPrimaryButton from 'components/core/NewPrimaryButton';
import RsvpDeadlineBanner from 'components/RsvpDeadlineBanner';
import { cx } from '@emotion/css';
import { isUpsellable } from 'utils/customerTypeUtils';
import UpgradeButton from 'components/UpgradeButton';

const MINUTES_BEFORE_FEEDBACK_REFRESH = 5;

type Props = {
  teamEvent: EventDetailsQuery['teamEvent'];
  viewer: User;
};
const Loader = ({ viewer }) => {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useQuery(EventDetails.query, {
    fetchPolicy: 'network-only',
    variables: { id },
  });

  if (!data || loading) return <Loading />;

  const teamEvent = data.teamEvent;
  return teamEvent ? (
    <EventDetails teamEvent={teamEvent} viewer={viewer} />
  ) : (
    <NotFoundError />
  );
};

const EventDetails = ({ teamEvent, viewer }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const virtualEvent = teamEvent.virtualEvent;
  const rsvpStatus = getUserRsvpStatus(viewer, teamEvent);
  const includeDelivery = !!teamEvent.includeDelivery;

  const [addAttendee] = useMutation(addAttendeeToVirtualEvent, {
    variables: {
      virtualEventId: virtualEvent?.id,
      userId: viewer.id,
    },
  });

  const rsvpDeadline = DateTime.fromISO(teamEvent?.signUpExpirationDate).toLocaleString(
    DateTime.DATE_MED_WITH_WEEKDAY,
  );
  const hasPassedDeadline =
    rsvpDeadline && DateTime.now() > DateTime.fromISO(teamEvent?.signUpExpirationDate);

  const eventIsLive = isEventLive(teamEvent);
  // No deadline for virtual only event
  const showDeadlineBanner =
    includeDelivery &&
    rsvpDeadline &&
    !hasPassedDeadline &&
    rsvpStatus !== InvitedGuestRsvpStatus.Accepted;
  const showChangeRsvpLink = !eventIsLive && !hasPassedDeadline;

  const hasCompletedFeedback = teamEvent.userCompletedFeedback;
  const showRsvpSection = !hasCompletedFeedback;
  const { title, description } = getEventDetails({
    teamEvent: {
      experience: {
        templates: teamEvent.experience?.templates,
        ...teamEvent.experience,
      },
      ...teamEvent,
    },
    viewer,
    defaultTitle: teamEvent.title,
  });
  const canUpsell = isUpsellable(viewer?.customerType);

  useEffect(() => {
    mixpanel.track('event details loaded', {
      customerType: viewer?.customerType,
      firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
    });
    const pageLoadTime = DateTime.now();

    const interval = setInterval(() => {
      // check every minute if we should refresh the page to the feedback link
      const durationMins = virtualEvent?.durationMins || 20;
      const redirectDateTime = DateTime.fromISO(teamEvent.requestedFor).plus({
        // Redirect halfway through event in case it ends early
        minutes: durationMins / 2,
      });
      const now = DateTime.now();
      if (
        // Only refresh if we don't already have feedback
        !hasCompletedFeedback &&
        // Wait at least 5 minutes before doing the redirect
        pageLoadTime.plus({ minutes: MINUTES_BEFORE_FEEDBACK_REFRESH }) < now &&
        // The event is at least halfway done
        redirectDateTime < now
      ) {
        // redirect to feedback form!
        window.location.assign(getFeedbackUrl(teamEvent.id));
      }
    }, 60 * 1000 /* one minute */);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box pl={10} pr={4} pb={20}>
      <Box className={classes.pageHeader} py={8}>
        <Box>
          <Typography className={classes.title}>Your event</Typography>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='always' color='primary' href='/'>
              Home
            </Link>
            <Typography className={classes.breadcumbTitle}>{title}</Typography>
          </Breadcrumbs>
        </Box>
        {canUpsell && <UpgradeButton page='event details' />}
      </Box>
      <Box className={classes.body}>
        <Box className={classes.card}>
          {showDeadlineBanner && <RsvpDeadlineBanner rsvpDeadline={rsvpDeadline} />}
          <Mail01 className={classes.headerIcon} color={theme.palette.primary.main} />
          <Typography className={classes.headerTitle}>{title}</Typography>
          <Typography className={classes.headerTextContent}>{description}</Typography>
          <TeamEventDetail teamEvent={teamEvent} viewer={viewer} />
          <JoinAndFeedbackSection
            teamEvent={teamEvent}
            virtualEvent={virtualEvent}
            isHost={isHost({ teamEvent, viewer })}
            addAttendee={addAttendee}
            hasCompletedFeedback={hasCompletedFeedback}
            rsvpStatus={rsvpStatus}
          />

          {showRsvpSection && (
            <Box className={classes.rsvp}>
              {(!rsvpStatus || rsvpStatus === InvitedGuestRsvpStatus.Invited) &&
                !hasPassedDeadline && (
                  <>
                    <Typography className={classes.rsvpTitle}>RSVP needed</Typography>
                    <Box className={classes.rsvpBodyInvited}>
                      <NewPrimaryButton
                        label='RSVP to this event'
                        onClick={() => history.replace(`/team-events?id=${teamEvent.id}`)}
                      />
                    </Box>
                  </>
                )}
              {rsvpStatus === InvitedGuestRsvpStatus.Accepted && (
                <>
                  <Typography className={classes.rsvpTitle}>RSVP Status</Typography>
                  <Box className={classes.rsvpBody}>
                    <CheckVerified01 />
                    <Typography className={classes.rsvpText}>
                      You accepted your invitation
                    </Typography>
                    {showChangeRsvpLink && (
                      <Box className={classes.rvspChangeLink}>
                        <Link
                          underline='always'
                          href={`/team-events?id=${teamEvent.id}&action=change-rsvp`}
                        >
                          Change RSVP
                        </Link>
                      </Box>
                    )}
                  </Box>
                </>
              )}
              {rsvpStatus === InvitedGuestRsvpStatus.Declined && (
                <>
                  <Typography className={classes.rsvpTitle}>RSVP Status</Typography>
                  <Box className={cx(classes.rsvpBody, classes.rsvpBodyDeclined)}>
                    <XCircle />
                    <Typography
                      className={cx(classes.rsvpText, classes.rsvpTextDeclined)}
                    >
                      You declined your invitation
                    </Typography>
                    {showChangeRsvpLink && (
                      <Box className={classes.rvspChangeLink}>
                        <Link
                          underline='always'
                          href={`/team-events?id=${teamEvent.id}&action=change-rsvp`}
                        >
                          Change RSVP
                        </Link>
                      </Box>
                    )}
                  </Box>
                </>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

EventDetails.query = gql`
  query EventDetails($id: ID!) {
    teamEvent(id: $id) {
      ...teamEventFragment
      userCompletedFeedback
      virtualEvent {
        ...virtualEventFragment
      }
    }
  }
  ${teamEventFragment}
  ${virtualEventFragment}
`;

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '32px',
    fontWight: 300,
    lineHeight: '32px',
    color: theme.palette.grey[600],
    paddingBottom: '8px',
  },
  breadcumbTitle: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 700,
    color: theme.palette.grey[600],
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary[50],
    borderTop: `1px solid ${theme.palette.primary[100]}`,
  },
  bannerText: {
    color: theme.palette.common.white,
    padding: '12px',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Reboto Mono, monospace',
    background: theme.palette.primary.main,
  },
  card: {
    width: '100%',
    maxWidth: '600px',
    margin: '40px auto',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px',
    border: `1px solid ${theme.palette.primary[200]}`,
    color: theme.palette.text.primary,
    fontSize: '16px',
    lineHeight: '24px',
  },
  headerIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '36px',
    height: '36px',
    padding: '8px',
    margin: '32px 0 12px 32px',
    borderRadius: '8px',
    background: theme.palette.primary[100],
  },
  headerTitle: {
    color: theme.palette.text.primary,
    fontSize: '48px',
    lineHeight: '52px',
    fontWeight: 'normal',
    margin: '0 32px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  headerTextContent: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    margin: '12px 32px',
  },
  joinSection: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '40px',
    '& >button': {
      width: '536px',
    },
  },
  rsvp: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '20px 0',
  },
  rsvpTitle: {
    color: theme.palette.primary.main,
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '20px',
  },
  rsvpBody: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    background: green[50],
    margin: '12px 32px',
    height: '48px',
    padding: '12px',
    borderRadius: '4px',
  },
  rsvpBodyDeclined: {
    background: orange[50],
  },
  rsvpBodyInvited: {
    display: 'flex',
    padding: '16px 32px',
  },
  rsvpText: {
    padding: '0 8px',
    color: green[600],
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
  },
  rsvpTextDeclined: {
    color: '#A1330F',
  },
  rvspChangeLink: {
    marginLeft: '166px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '40px',
    },
  },
  pageHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export default Loader;
