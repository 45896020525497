const CheckVerified01Filled = ({
  height = 20,
  width = 20,
  color = '#2D8A26',
  style = {},
  className = '',
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      style={style}
      viewBox={`0 0 20 20`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.6118 1.79525C11.1068 0.512688 8.8932 0.512688 7.38819 1.79525C7.0102 2.11737 6.54022 2.31204 6.04516 2.35155C4.07405 2.50884 2.50883 4.07407 2.35153 6.04518C2.31203 6.54023 2.11736 7.01021 1.79523 7.3882C0.512673 8.89321 0.512673 11.1068 1.79523 12.6118C2.11736 12.9898 2.31203 13.4598 2.35153 13.9548C2.50883 15.9259 4.07405 17.4911 6.04516 17.6484C6.54022 17.6879 7.0102 17.8826 7.38819 18.2047C8.8932 19.4873 11.1068 19.4873 12.6118 18.2047C12.9898 17.8826 13.4597 17.6879 13.9548 17.6484C15.9259 17.4911 17.4911 15.9259 17.6484 13.9548C17.6879 13.4598 17.8826 12.9898 18.2047 12.6118C19.4873 11.1068 19.4873 8.89321 18.2047 7.3882C17.8826 7.01021 17.6879 6.54023 17.6484 6.04518C17.4911 4.07407 15.9259 2.50884 13.9548 2.35155C13.4597 2.31204 12.9898 2.11737 12.6118 1.79525ZM13.5059 8.50592C13.8313 8.18048 13.8313 7.65284 13.5059 7.32741C13.1805 7.00197 12.6528 7.00197 12.3274 7.32741L9.16665 10.4881L8.08923 9.41074C7.7638 9.0853 7.23616 9.0853 6.91072 9.41074C6.58529 9.73618 6.58529 10.2638 6.91072 10.5893L8.57739 12.2559C8.90283 12.5814 9.43046 12.5814 9.7559 12.2559L13.5059 8.50592Z'
        fill={color}
      />
    </svg>
  );
};

export default CheckVerified01Filled;
