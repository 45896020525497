import theme from 'theme';

const CurrencyDollar = ({
  size = 14,
  color = theme.palette.primary.main,
  style = {},
  className = '',
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size / 14 * 22}
      style={style}
      viewBox={`0 0 14 22`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7 0C7.55228 0 8 0.447715 8 1V2H9C11.7614 2 14 4.23858 14 7C14 7.55228 13.5523 8 13 8C12.4477 8 12 7.55228 12 7C12 5.34315 10.6569 4 9 4H8V10H9C11.7614 10 14 12.2386 14 15C14 17.7614 11.7614 20 9 20H8V21C8 21.5523 7.55228 22 7 22C6.44772 22 6 21.5523 6 21V20H5C2.23858 20 0 17.7614 0 15C0 14.4477 0.447715 14 1 14C1.55228 14 2 14.4477 2 15C2 16.6569 3.34315 18 5 18H6V12H5C2.23858 12 0 9.76142 0 7C0 4.23858 2.23858 2 5 2H6V1C6 0.447715 6.44772 0 7 0ZM6 4H5C3.34315 4 2 5.34315 2 7C2 8.65685 3.34315 10 5 10H6V4ZM8 12V18H9C10.6569 18 12 16.6569 12 15C12 13.3431 10.6569 12 9 12H8Z'
        fill={color}
      />
    </svg>
  );
};

export default CurrencyDollar;
