import React, { CSSProperties } from 'react';

type Props = {
  size?: number;
  style?: CSSProperties;
  className?: string;
  color?: string;
};

export default function DoubleChevronLeft({ size = 12, style, className }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      css={{ fill: 'currentColor' }}
      className={className}
      style={style}
    >
      <path d='M11.7071 2.54028C12.0976 2.14975 12.0976 1.51659 11.7071 1.12606C11.3166 0.735539 10.6834 0.735539 10.2929 1.12606L11.7071 2.54028ZM6.83333 5.99984L6.12623 5.29273C5.7357 5.68325 5.7357 6.31642 6.12623 6.70694L6.83333 5.99984ZM10.2929 10.8736C10.6834 11.2641 11.3166 11.2641 11.7071 10.8736C12.0976 10.4831 12.0976 9.84992 11.7071 9.4594L10.2929 10.8736ZM5.87377 2.54028C6.2643 2.14975 6.2643 1.51659 5.87377 1.12606C5.48325 0.735539 4.85008 0.735539 4.45956 1.12606L5.87377 2.54028ZM1 5.99984L0.292893 5.29273C-0.0976313 5.68325 -0.0976312 6.31642 0.292893 6.70694L1 5.99984ZM4.45956 10.8736C4.85008 11.2641 5.48325 11.2641 5.87377 10.8736C6.2643 10.4831 6.2643 9.84992 5.87377 9.4594L4.45956 10.8736ZM10.2929 1.12606L6.12623 5.29273L7.54044 6.70694L11.7071 2.54028L10.2929 1.12606ZM6.12623 6.70694L10.2929 10.8736L11.7071 9.4594L7.54044 5.29273L6.12623 6.70694ZM4.45956 1.12606L0.292893 5.29273L1.70711 6.70694L5.87377 2.54028L4.45956 1.12606ZM0.292893 6.70694L4.45956 10.8736L5.87377 9.4594L1.70711 5.29273L0.292893 6.70694Z' />
    </svg>
  );
}
