const env = process.env.NODE_ENV || 'development';
export const isProd = env === 'production';
export const isDev = env === 'development';
export const isTest = process.env.REACT_APP_IS_TEST === 'TRUE';
export const isSandbox = process.env.REACT_APP_IS_SANDBOX === 'TRUE';
const externalIp = process.env.REACT_APP_IP_ADDR || 'localhost';

let teamsUrl: string;
// Sandbox is technically running as production, so check that first
if (isSandbox) {
  teamsUrl = 'https://teams-sandbox.trymystery.com';
} else if (isProd) {
  teamsUrl = 'https://teams.trymystery.com';
} else {
  teamsUrl = `http://${externalIp}:3000`;
}

function getEnvOrDie(key: string) {
  const value = process.env[key];
  if (!value || value.trim() === '') {
    throw new Error(`Please provide a value for ${key}`);
  }

  return value;
}

const config = {
  env,
  api: {
    url: isProd ? 'https://api.trymystery.com' : `http://${externalIp}:5000`,
  },
  auth: {
    url: isProd ? 'https://auth.trymystery.com' : `http://${externalIp}:3002`,
  },
  fullStory: {
    enabled: isProd && !isSandbox,
  },
  google: {
    mapsApiKey: getEnvOrDie('REACT_APP_GOOGLE_MAPS_API_KEY'),
    tagsManagerId: 'GTM-WPZ7TTM',
  },
  teams: {
    url: teamsUrl,
  },
  finch: {
    user: '6bd15824-2579-4c90-ad5d-2c7f18c697a0',
  },
  pusher: {
    key: getEnvOrDie('REACT_APP_PUSHER_KEY'),
    auth: isProd
      ? 'https://api.trymystery.com/pusher/auth'
      : `http://${externalIp}:5000/pusher/auth`,
    trigger: isProd
      ? 'https://api.trymystery.com/pusher/trigger'
      : `http://${externalIp}:5000/pusher/trigger`,
    config: (viewerId) => ({
      clientKey: config.pusher.key,
      cluster: 'us2',
      authEndpoint: config.pusher.auth,
      triggerEndpoint: config.pusher.trigger,
      auth: {
        headers: {
          token: viewerId,
        },
      },
    }),
  },
  sentry: {
    key: getEnvOrDie('REACT_APP_SENTRY_KEY'),
    project: '5765671',
  },
  stripe: {
    publishableKey: getEnvOrDie('REACT_APP_STRIPE_KEY'),
  },
  facebookPixel: isProd ? '638736343328106' : null,
  hubspotChat: {
    enabled: isProd,
    portalId: '9438752',
  },
  mixpanel: getEnvOrDie('REACT_APP_MIXPANEL_KEY'),
  statsig: {
    key: getEnvOrDie('REACT_APP_STATSIG_CLIENT_KEY'),
  },
  // [M-1879] Timezone should be set to Pacific Time for all experiences.
  defaultTimezone: 'America/Los_Angeles',
  pacificTimeOperatingHours: { start: 0, end: 20 },
  customerWorkinghours: { start: 0, end: 20 },
};

export default config;
