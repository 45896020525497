import { makeStyles } from '@material-ui/styles';
import UpsellLink from 'components/UpsellLink';
import UpsellLock from 'components/UpsellLock';
import mixpanel from 'mixpanel-browser';
import theme from 'theme';
import { useViewer } from 'utils/state';

const FAKE_GRAPH_ANIMATION_LINK = 'https://rive.app/s/6xDqbmserEW11BGmAvM1eQ/embed';

function InsightsUpsell() {
  const classes = useStyles();
  const viewer = useViewer();
  const handleClick = () => {
    mixpanel.track('schedule a demo clicked', {
      source: 'insights',
      customerType: viewer?.customerType,
      firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
    });
  };

  return (
    <div style={{ height: '88%' }}>
      <main className={classes.main}>
        <UpsellLock />
        <div className={classes.text}>
          <div className={classes.heroMessage}>Connected people make</div>
          <div className={classes.gradientMessage}>better teams</div>
          <div className={classes.subtext}>
            Unlock real-time visibility into employee connections when you upgrade to
            Glue.
          </div>
        </div>
        <UpsellLink
          className={classes.callToAction}
          onClick={handleClick}
          text='Schedule a demo'
          location='insights'
        />
        <iframe
          title='Animation displaying sample insights data'
          style={{ border: 'none', width: '700px', height: '440px' }}
          src={FAKE_GRAPH_ANIMATION_LINK}
        ></iframe>
      </main>
    </div>
  );
}

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '24px',
    fontFamily: 'inherit',
    fontStyle: 'normal',
    gap: '20px',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  heroMessage: {
    fontWeight: 400,
    fontSize: '48px',
    lineHeight: '48px',
    color: '#5c296c',
  },
  gradientMessage: {
    fontWeight: 400,
    fontSize: '48px',
    lineHeight: '48px',
    color: '#5c296c',
    background:
      'linear-gradient(90deg, #7A1783 0%, #BE207A 27.6%, #9747FF 68.75%, #EB5757 97.92%)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  subtext: {
    fontSize: '18px',
    lineHeight: '28px',
    color: '#5c296c',
    fontWeight: 400,
    marginTop: '12px',
  },
  callToAction: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px 32px',
    gap: '10px',
    background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 100%)`,
    zIndex: 1,
    borderRadius: '58px',
    color: `${theme.palette.primary.contrastText} !important`,
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '20px',
    border: 0,
    cursor: 'pointer',
    transition: 'background 0.5s ease-in-out',
    '&:visited': {
      color: theme.palette.primary.contrastText,
    },
    '&:active': {
      color: theme.palette.primary.contrastText,
    },
    '&:before': {
      position: 'absolute',
      content: "''",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: -1,
      background:
        'linear-gradient(90deg, #7A1783 0%, #BE207A 27.6%, #9747FF 68.75%, #EB5757 97.92%)',
      transition: 'opacity 0.5s ease-in-out',
      opacity: 0,
      border: 0,
      borderRadius: '58px',
    },
    '&:hover': {
      color: theme.palette.primary.contrastText,
      '&:visited': {
        color: theme.palette.primary.contrastText,
      },
      '&:active': {
        color: theme.palette.primary.contrastText,
      },
      '&:before': {
        opacity: 1,
      },
    },
  },
});
export default InsightsUpsell;
