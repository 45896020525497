import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ICSDate from 'components/ICSDate';
import GlueConfirmThumb from 'assets/logos/confirm-thumb.png';
import { DateTime } from 'luxon';
import theme from 'theme';

type SummaryExperience = {
  hasPhysicalGoods: boolean;
  photoUrl: string;
  requiresUpgrade: boolean;
  startTimeISO: string;
  title: string;
};

const SummarySection = ({
  experience,
  surpriseToAll,
  surpriseToAttendees,
  title,
}: {
  experience: SummaryExperience;
  headCount: number;
  surpriseToAll: boolean;
  surpriseToAttendees: boolean;
  title: string;
}) => {
  const dateTime = DateTime.fromISO(experience.startTimeISO);
  const dateString = dateTime.toLocaleString({
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  });
  const classes = useStyles();

  return (
    <>
      <Box className={classes.container}>
        {surpriseToAttendees || surpriseToAll ? (
          <div className={classes.imageContainer}>
            <img
              src={GlueConfirmThumb}
              alt=''
              style={{ height: '150px', width: '150px' }}
            />
          </div>
        ) : (
          <div className={classes.imageContainer}>
            <img
              alt='experience selection'
              src={experience.photoUrl}
              className={classes.image}
            />
          </div>
        )}
        <Box display='flex' flexDirection={'column'} style={{ padding: `10px 20px` }}>
          <Typography style={{ fontSize: 24, marginBottom: theme.spacing(2) }}>
            {title}
          </Typography>
          <Box display={'flex'} height={'100%'}>
            <Box mr={4}>
              <ICSDate
                monthName={dateTime.monthShort}
                dateNumber={dateTime.day}
                containerStyle={{
                  width: 65,
                  height: 68,
                  boxShadow: `-3px 15px 58px -25px rgba(0,0,0,0.75)`,
                }}
                dateNumberStyle={{ fontSize: 24 }}
                monthStyle={{ fontSize: 12 }}
              />
            </Box>
            <Box>
              <Typography className={classes.line} variant='body1'>
                <b>{dateString}</b>
              </Typography>

              <Typography variant='body1'>
                {surpriseToAll ? `Glue's choice` : experience.title}
              </Typography>
              <Typography variant='caption'>{`${
                surpriseToAttendees
                  ? ` (We'll keep this a surprise to attendees until the event starts)`
                  : surpriseToAll
                  ? `Your Glue surprise event details will be revealed to you the day of your event`
                  : ''
              }
          `}</Typography>
              {!surpriseToAll && experience.hasPhysicalGoods && (
                <Box color={theme.palette.primary.dark} fontWeight='bold'>
                  <Typography>Supplies included</Typography>
                </Box>
              )}
              {experience.requiresUpgrade && (
                <Box color={theme.palette.primary.dark} fontWeight='bold'>
                  <Typography>VIP host</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  imageContainer: {
    width: 150,
    height: 150,
    objectFit: 'contain',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginRight: '15%',
    marginLeft: theme.spacing(4),
    textAlign: 'left',
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginLeft: 0,
      textAlign: 'center',
    },
  },
  line: { marginBottom: theme.spacing(1) },
});

export default SummarySection;
