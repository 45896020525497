const TeamCheckbox = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='0.5' y='0.5' width='23' height='23' rx='1.5' fill='white' />
      <g filter='url(#filter0_i_112_25)'>
        <rect width='24' height='24' rx='2' fill='white' />
      </g>
      <rect x='0.5' y='0.5' width='23' height='23' rx='1.5' stroke='#7B1783' />
      <rect x='0.5' y='0.5' width='23' height='23' rx='1.5' stroke='#7B1783' />
      <defs>
        <filter
          id='filter0_i_112_25'
          x='0'
          y='0'
          width='24'
          height='24'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='2' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'
          />
          <feBlend mode='normal' in2='shape' result='effect1_innerShadow_112_25' />
        </filter>
      </defs>
    </svg>
  );
};

export default TeamCheckbox;
