// @ts-strict-ignore
import { gql } from '@apollo/client';
import { cx } from '@emotion/css';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ProfileCard from 'glue/components/profile/ProfileCard';
import AsideHeader from 'glue/components/structure/AsideHeader';
import theme from 'theme';
import { UserProfileViewerFragment } from 'types';

type Props = {
  viewer: UserProfileViewerFragment;
  [key: string]: any;
};

const UserProfile = ({ viewer, ...props }: Props) => {
  const classes = useStyles();
  return (
    <Box className={cx(classes.root, props?.className)}>
      <AsideHeader>Your profile</AsideHeader>
      <ProfileCard
        firstName={viewer.firstName}
        lastName={viewer.lastName}
        title={viewer.companyRole}
      />
    </Box>
  );
};

UserProfile.fragments = gql`
  fragment UserProfileViewer on User {
    id
    firstName
    lastName
    companyRole
  }
`;

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    gap: theme.spacing(5),
  },
});

export default UserProfile;
