const CsvBanner = () => {
  return (
    <svg
      width='100%'
      viewBox='0 0 536 180'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_490_95820)'>
        <rect width='536' height='180' rx='4' fill='#F2FBF0' />
        <circle opacity='0.4' cx='495' cy='15' r='100' fill='#DAFCD5' />
        <circle opacity='0.4' cx='23' cy='133' r='100' fill='#DAFCD5' />
        <g opacity='0.7'>
          <g opacity='0.3'>
            <circle cx='470.692' cy='118.692' r='1.69231' fill='#4FA848' />
            <circle cx='470.692' cy='125.461' r='1.69231' fill='#4FA848' />
            <circle cx='470.692' cy='132.231' r='1.69231' fill='#4FA848' />
            <circle cx='470.692' cy='139' r='1.69231' fill='#4FA848' />
            <circle cx='470.692' cy='145.769' r='1.69231' fill='#4FA848' />
            <circle cx='470.692' cy='152.538' r='1.69231' fill='#4FA848' />
            <circle cx='470.692' cy='159.308' r='1.69231' fill='#4FA848' />
            <circle cx='470.692' cy='166.077' r='1.69231' fill='#4FA848' />
            <circle cx='470.692' cy='172.846' r='1.69231' fill='#4FA848' />
            <circle cx='470.692' cy='179.615' r='1.69231' fill='#4FA848' />
            <circle cx='477.461' cy='118.692' r='1.69231' fill='#4FA848' />
            <circle cx='477.461' cy='125.461' r='1.69231' fill='#4FA848' />
            <circle cx='477.461' cy='132.231' r='1.69231' fill='#4FA848' />
            <circle cx='477.461' cy='139' r='1.69231' fill='#4FA848' />
            <circle cx='477.461' cy='145.769' r='1.69231' fill='#4FA848' />
            <circle cx='477.461' cy='152.538' r='1.69231' fill='#4FA848' />
            <circle cx='477.461' cy='159.308' r='1.69231' fill='#4FA848' />
            <circle cx='477.461' cy='166.077' r='1.69231' fill='#4FA848' />
            <circle cx='477.461' cy='172.846' r='1.69231' fill='#4FA848' />
            <circle cx='477.461' cy='179.615' r='1.69231' fill='#4FA848' />
            <circle cx='484.23' cy='118.692' r='1.69231' fill='#4FA848' />
            <circle cx='484.23' cy='125.461' r='1.69231' fill='#4FA848' />
            <circle cx='484.23' cy='132.231' r='1.69231' fill='#4FA848' />
            <circle cx='484.23' cy='139' r='1.69231' fill='#4FA848' />
            <circle cx='484.23' cy='145.769' r='1.69231' fill='#4FA848' />
            <circle cx='484.23' cy='152.538' r='1.69231' fill='#4FA848' />
            <circle cx='484.23' cy='159.308' r='1.69231' fill='#4FA848' />
            <circle cx='484.23' cy='166.077' r='1.69231' fill='#4FA848' />
            <circle cx='484.23' cy='172.846' r='1.69231' fill='#4FA848' />
            <circle cx='484.23' cy='179.615' r='1.69231' fill='#4FA848' />
            <circle cx='491' cy='118.692' r='1.69231' fill='#4FA848' />
            <circle cx='491' cy='125.461' r='1.69231' fill='#4FA848' />
            <circle cx='491' cy='132.231' r='1.69231' fill='#4FA848' />
            <circle cx='491' cy='139' r='1.69231' fill='#4FA848' />
            <circle cx='491' cy='145.769' r='1.69231' fill='#4FA848' />
            <circle cx='491' cy='152.538' r='1.69231' fill='#4FA848' />
            <circle cx='491' cy='159.308' r='1.69231' fill='#4FA848' />
            <circle cx='491' cy='166.077' r='1.69231' fill='#4FA848' />
            <circle cx='491' cy='172.846' r='1.69231' fill='#4FA848' />
            <circle cx='491' cy='179.615' r='1.69231' fill='#4FA848' />
            <circle cx='497.769' cy='118.692' r='1.69231' fill='#4FA848' />
            <circle cx='497.769' cy='125.461' r='1.69231' fill='#4FA848' />
            <circle cx='497.769' cy='132.231' r='1.69231' fill='#4FA848' />
            <circle cx='497.769' cy='139' r='1.69231' fill='#4FA848' />
            <circle cx='497.769' cy='145.769' r='1.69231' fill='#4FA848' />
            <circle cx='497.769' cy='152.538' r='1.69231' fill='#4FA848' />
            <circle cx='497.769' cy='159.308' r='1.69231' fill='#4FA848' />
            <circle cx='497.769' cy='166.077' r='1.69231' fill='#4FA848' />
            <circle cx='497.769' cy='172.846' r='1.69231' fill='#4FA848' />
            <circle cx='497.769' cy='179.615' r='1.69231' fill='#4FA848' />
            <circle cx='504.539' cy='118.692' r='1.69231' fill='#4FA848' />
            <circle cx='504.539' cy='125.461' r='1.69231' fill='#4FA848' />
            <circle cx='504.539' cy='132.231' r='1.69231' fill='#4FA848' />
            <circle cx='504.539' cy='139' r='1.69231' fill='#4FA848' />
            <circle cx='504.539' cy='145.769' r='1.69231' fill='#4FA848' />
            <circle cx='504.539' cy='152.538' r='1.69231' fill='#4FA848' />
            <circle cx='504.539' cy='159.308' r='1.69231' fill='#4FA848' />
            <circle cx='504.539' cy='166.077' r='1.69231' fill='#4FA848' />
            <circle cx='504.539' cy='172.846' r='1.69231' fill='#4FA848' />
            <circle cx='504.539' cy='179.615' r='1.69231' fill='#4FA848' />
            <circle cx='511.308' cy='118.692' r='1.69231' fill='#4FA848' />
            <circle cx='511.308' cy='125.461' r='1.69231' fill='#4FA848' />
            <circle cx='511.308' cy='132.231' r='1.69231' fill='#4FA848' />
            <circle cx='511.308' cy='139' r='1.69231' fill='#4FA848' />
            <circle cx='511.308' cy='145.769' r='1.69231' fill='#4FA848' />
            <circle cx='511.308' cy='152.538' r='1.69231' fill='#4FA848' />
            <circle cx='511.308' cy='159.308' r='1.69231' fill='#4FA848' />
            <circle cx='511.308' cy='166.077' r='1.69231' fill='#4FA848' />
            <circle cx='511.308' cy='172.846' r='1.69231' fill='#4FA848' />
            <circle cx='511.308' cy='179.615' r='1.69231' fill='#4FA848' />
            <circle cx='518.077' cy='118.692' r='1.69231' fill='#4FA848' />
            <circle cx='518.077' cy='125.461' r='1.69231' fill='#4FA848' />
            <circle cx='518.077' cy='132.231' r='1.69231' fill='#4FA848' />
            <circle cx='518.077' cy='139' r='1.69231' fill='#4FA848' />
            <circle cx='518.077' cy='145.769' r='1.69231' fill='#4FA848' />
            <circle cx='518.077' cy='152.538' r='1.69231' fill='#4FA848' />
            <circle cx='518.077' cy='159.308' r='1.69231' fill='#4FA848' />
            <circle cx='518.077' cy='166.077' r='1.69231' fill='#4FA848' />
            <circle cx='518.077' cy='172.846' r='1.69231' fill='#4FA848' />
            <circle cx='518.077' cy='179.615' r='1.69231' fill='#4FA848' />
            <circle cx='524.847' cy='118.692' r='1.69231' fill='#4FA848' />
            <circle cx='524.847' cy='125.461' r='1.69231' fill='#4FA848' />
            <circle cx='524.847' cy='132.231' r='1.69231' fill='#4FA848' />
            <circle cx='524.847' cy='139' r='1.69231' fill='#4FA848' />
            <circle cx='524.847' cy='145.769' r='1.69231' fill='#4FA848' />
            <circle cx='524.847' cy='152.538' r='1.69231' fill='#4FA848' />
            <circle cx='524.847' cy='159.308' r='1.69231' fill='#4FA848' />
            <circle cx='524.847' cy='166.077' r='1.69231' fill='#4FA848' />
            <circle cx='524.847' cy='172.846' r='1.69231' fill='#4FA848' />
            <circle cx='524.847' cy='179.615' r='1.69231' fill='#4FA848' />
            <circle cx='531.616' cy='118.692' r='1.69231' fill='#4FA848' />
            <circle cx='531.616' cy='125.461' r='1.69231' fill='#4FA848' />
            <circle cx='531.616' cy='132.231' r='1.69231' fill='#4FA848' />
            <circle cx='531.616' cy='139' r='1.69231' fill='#4FA848' />
            <circle cx='531.616' cy='145.769' r='1.69231' fill='#4FA848' />
            <circle cx='531.616' cy='152.538' r='1.69231' fill='#4FA848' />
            <circle cx='531.616' cy='159.308' r='1.69231' fill='#4FA848' />
            <circle cx='531.616' cy='166.077' r='1.69231' fill='#4FA848' />
            <circle cx='531.616' cy='172.846' r='1.69231' fill='#4FA848' />
            <circle cx='531.616' cy='179.615' r='1.69231' fill='#4FA848' />
          </g>
          <g opacity='0.3'>
            <circle cx='2.61565' cy='1.69231' r='1.69231' fill='#4FA848' />
            <circle cx='2.61565' cy='8.46135' r='1.69231' fill='#4FA848' />
            <circle cx='2.61565' cy='15.2309' r='1.69231' fill='#4FA848' />
            <circle cx='2.61565' cy='21.9999' r='1.69231' fill='#4FA848' />
            <circle cx='2.61565' cy='28.7695' r='1.69231' fill='#4FA848' />
            <circle cx='2.61565' cy='35.5385' r='1.69231' fill='#4FA848' />
            <circle cx='2.61565' cy='42.3075' r='1.69231' fill='#4FA848' />
            <circle cx='2.61565' cy='49.0771' r='1.69231' fill='#4FA848' />
            <circle cx='2.61565' cy='55.8461' r='1.69231' fill='#4FA848' />
            <circle cx='2.61565' cy='62.6152' r='1.69231' fill='#4FA848' />
            <circle cx='2.61565' cy='69.3847' r='1.69231' fill='#4FA848' />
            <circle cx='9.38469' cy='1.69231' r='1.69231' fill='#4FA848' />
            <circle cx='9.38469' cy='8.46135' r='1.69231' fill='#4FA848' />
            <circle cx='9.38469' cy='15.2309' r='1.69231' fill='#4FA848' />
            <circle cx='9.38469' cy='21.9999' r='1.69231' fill='#4FA848' />
            <circle cx='9.38469' cy='28.7695' r='1.69231' fill='#4FA848' />
            <circle cx='9.38469' cy='35.5385' r='1.69231' fill='#4FA848' />
            <circle cx='9.38469' cy='42.3075' r='1.69231' fill='#4FA848' />
            <circle cx='9.38469' cy='49.0771' r='1.69231' fill='#4FA848' />
            <circle cx='9.38469' cy='55.8461' r='1.69231' fill='#4FA848' />
            <circle cx='9.38469' cy='62.6152' r='1.69231' fill='#4FA848' />
            <circle cx='9.38469' cy='69.3847' r='1.69231' fill='#4FA848' />
            <circle cx='16.1537' cy='1.69231' r='1.69231' fill='#4FA848' />
            <circle cx='16.1537' cy='8.46135' r='1.69231' fill='#4FA848' />
            <circle cx='16.1537' cy='15.2309' r='1.69231' fill='#4FA848' />
            <circle cx='16.1537' cy='21.9999' r='1.69231' fill='#4FA848' />
            <circle cx='16.1537' cy='28.7695' r='1.69231' fill='#4FA848' />
            <circle cx='16.1537' cy='35.5385' r='1.69231' fill='#4FA848' />
            <circle cx='16.1537' cy='42.3075' r='1.69231' fill='#4FA848' />
            <circle cx='16.1537' cy='49.0771' r='1.69231' fill='#4FA848' />
            <circle cx='16.1537' cy='55.8461' r='1.69231' fill='#4FA848' />
            <circle cx='16.1537' cy='62.6152' r='1.69231' fill='#4FA848' />
            <circle cx='16.1537' cy='69.3847' r='1.69231' fill='#4FA848' />
            <circle cx='22.9228' cy='1.69231' r='1.69231' fill='#4FA848' />
            <circle cx='22.9228' cy='8.46135' r='1.69231' fill='#4FA848' />
            <circle cx='22.9228' cy='15.2309' r='1.69231' fill='#4FA848' />
            <circle cx='22.9228' cy='21.9999' r='1.69231' fill='#4FA848' />
            <circle cx='22.9228' cy='28.7695' r='1.69231' fill='#4FA848' />
            <circle cx='22.9228' cy='35.5385' r='1.69231' fill='#4FA848' />
            <circle cx='22.9228' cy='42.3075' r='1.69231' fill='#4FA848' />
            <circle cx='22.9228' cy='49.0771' r='1.69231' fill='#4FA848' />
            <circle cx='22.9228' cy='55.8461' r='1.69231' fill='#4FA848' />
            <circle cx='22.9228' cy='62.6152' r='1.69231' fill='#4FA848' />
            <circle cx='22.9228' cy='69.3847' r='1.69231' fill='#4FA848' />
            <circle cx='29.6918' cy='1.69231' r='1.69231' fill='#4FA848' />
            <circle cx='29.6918' cy='8.46135' r='1.69231' fill='#4FA848' />
            <circle cx='29.6918' cy='15.2309' r='1.69231' fill='#4FA848' />
            <circle cx='29.6918' cy='21.9999' r='1.69231' fill='#4FA848' />
            <circle cx='29.6918' cy='28.7695' r='1.69231' fill='#4FA848' />
            <circle cx='29.6918' cy='35.5385' r='1.69231' fill='#4FA848' />
            <circle cx='29.6918' cy='42.3075' r='1.69231' fill='#4FA848' />
            <circle cx='29.6918' cy='49.0771' r='1.69231' fill='#4FA848' />
            <circle cx='29.6918' cy='55.8461' r='1.69231' fill='#4FA848' />
            <circle cx='29.6918' cy='62.6152' r='1.69231' fill='#4FA848' />
            <circle cx='29.6918' cy='69.3847' r='1.69231' fill='#4FA848' />
            <circle cx='36.4618' cy='1.69231' r='1.69231' fill='#4FA848' />
            <circle cx='36.4618' cy='8.46135' r='1.69231' fill='#4FA848' />
            <circle cx='36.4618' cy='15.2309' r='1.69231' fill='#4FA848' />
            <circle cx='36.4618' cy='21.9999' r='1.69231' fill='#4FA848' />
            <circle cx='36.4618' cy='28.7695' r='1.69231' fill='#4FA848' />
            <circle cx='36.4618' cy='35.5385' r='1.69231' fill='#4FA848' />
            <circle cx='36.4618' cy='42.3075' r='1.69231' fill='#4FA848' />
            <circle cx='36.4618' cy='49.0771' r='1.69231' fill='#4FA848' />
            <circle cx='36.4618' cy='55.8461' r='1.69231' fill='#4FA848' />
            <circle cx='36.4618' cy='62.6152' r='1.69231' fill='#4FA848' />
            <circle cx='36.4618' cy='69.3847' r='1.69231' fill='#4FA848' />
            <circle cx='43.2309' cy='1.69231' r='1.69231' fill='#4FA848' />
            <circle cx='43.2309' cy='8.46135' r='1.69231' fill='#4FA848' />
            <circle cx='43.2309' cy='15.2309' r='1.69231' fill='#4FA848' />
            <circle cx='43.2309' cy='21.9999' r='1.69231' fill='#4FA848' />
            <circle cx='43.2309' cy='28.7695' r='1.69231' fill='#4FA848' />
            <circle cx='43.2309' cy='35.5385' r='1.69231' fill='#4FA848' />
            <circle cx='43.2309' cy='42.3075' r='1.69231' fill='#4FA848' />
            <circle cx='43.2309' cy='49.0771' r='1.69231' fill='#4FA848' />
            <circle cx='43.2309' cy='55.8461' r='1.69231' fill='#4FA848' />
            <circle cx='43.2309' cy='62.6152' r='1.69231' fill='#4FA848' />
            <circle cx='43.2309' cy='69.3847' r='1.69231' fill='#4FA848' />
            <circle cx='49.9999' cy='1.69231' r='1.69231' fill='#4FA848' />
            <circle cx='49.9999' cy='8.46135' r='1.69231' fill='#4FA848' />
            <circle cx='49.9999' cy='15.2309' r='1.69231' fill='#4FA848' />
            <circle cx='49.9999' cy='21.9999' r='1.69231' fill='#4FA848' />
            <circle cx='49.9999' cy='28.7695' r='1.69231' fill='#4FA848' />
            <circle cx='49.9999' cy='35.5385' r='1.69231' fill='#4FA848' />
            <circle cx='49.9999' cy='42.3075' r='1.69231' fill='#4FA848' />
            <circle cx='49.9999' cy='49.0771' r='1.69231' fill='#4FA848' />
            <circle cx='49.9999' cy='55.8461' r='1.69231' fill='#4FA848' />
            <circle cx='49.9999' cy='62.6152' r='1.69231' fill='#4FA848' />
            <circle cx='49.9999' cy='69.3847' r='1.69231' fill='#4FA848' />
            <circle cx='56.769' cy='1.69231' r='1.69231' fill='#4FA848' />
            <circle cx='56.769' cy='8.46135' r='1.69231' fill='#4FA848' />
            <circle cx='56.769' cy='15.2309' r='1.69231' fill='#4FA848' />
            <circle cx='56.769' cy='21.9999' r='1.69231' fill='#4FA848' />
            <circle cx='56.769' cy='28.7695' r='1.69231' fill='#4FA848' />
            <circle cx='56.769' cy='35.5385' r='1.69231' fill='#4FA848' />
            <circle cx='56.769' cy='42.3075' r='1.69231' fill='#4FA848' />
            <circle cx='56.769' cy='49.0771' r='1.69231' fill='#4FA848' />
            <circle cx='56.769' cy='55.8461' r='1.69231' fill='#4FA848' />
            <circle cx='56.769' cy='62.6152' r='1.69231' fill='#4FA848' />
            <circle cx='56.769' cy='69.3847' r='1.69231' fill='#4FA848' />
            <circle cx='63.538' cy='1.69231' r='1.69231' fill='#4FA848' />
            <circle cx='63.538' cy='8.46135' r='1.69231' fill='#4FA848' />
            <circle cx='63.538' cy='15.2309' r='1.69231' fill='#4FA848' />
            <circle cx='63.538' cy='21.9999' r='1.69231' fill='#4FA848' />
            <circle cx='63.538' cy='28.7695' r='1.69231' fill='#4FA848' />
            <circle cx='63.538' cy='35.5385' r='1.69231' fill='#4FA848' />
            <circle cx='63.538' cy='42.3075' r='1.69231' fill='#4FA848' />
            <circle cx='63.538' cy='49.0771' r='1.69231' fill='#4FA848' />
            <circle cx='63.538' cy='55.8461' r='1.69231' fill='#4FA848' />
            <circle cx='63.538' cy='62.6152' r='1.69231' fill='#4FA848' />
            <circle cx='63.538' cy='69.3847' r='1.69231' fill='#4FA848' />
            <circle cx='70.308' cy='1.69231' r='1.69231' fill='#4FA848' />
            <circle cx='70.308' cy='8.46135' r='1.69231' fill='#4FA848' />
            <circle cx='70.308' cy='15.2309' r='1.69231' fill='#4FA848' />
            <circle cx='70.308' cy='21.9999' r='1.69231' fill='#4FA848' />
            <circle cx='70.308' cy='28.7695' r='1.69231' fill='#4FA848' />
            <circle cx='70.308' cy='35.5385' r='1.69231' fill='#4FA848' />
            <circle cx='70.308' cy='42.3075' r='1.69231' fill='#4FA848' />
            <circle cx='70.308' cy='49.0771' r='1.69231' fill='#4FA848' />
            <circle cx='70.308' cy='55.8461' r='1.69231' fill='#4FA848' />
            <circle cx='70.308' cy='62.6152' r='1.69231' fill='#4FA848' />
            <circle cx='70.308' cy='69.3847' r='1.69231' fill='#4FA848' />
          </g>
        </g>
        <g filter='url(#filter0_bdddd_490_95820)'>
          <rect x='193' y='32' width='150' height='170' rx='12' fill='white' />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M273.223 65.7139C270.213 63.1488 265.786 63.1488 262.776 65.7139C262.02 66.3581 261.08 66.7475 260.09 66.8265C256.148 67.1411 253.018 70.2715 252.703 74.2137C252.624 75.2039 252.235 76.1438 251.59 76.8998C249.025 79.9098 249.025 84.3369 251.59 87.3469C252.235 88.1029 252.624 89.0429 252.703 90.033C253.018 93.9752 256.148 97.1057 260.09 97.4203C261.08 97.4993 262.02 97.8886 262.776 98.5329C265.786 101.098 270.213 101.098 273.223 98.5329C273.979 97.8886 274.919 97.4993 275.909 97.4203C279.852 97.1057 282.982 93.9752 283.297 90.033C283.376 89.0429 283.765 88.1029 284.409 87.3469C286.974 84.3369 286.974 79.9098 284.409 76.8998C283.765 76.1438 283.376 75.2039 283.297 74.2137C282.982 70.2715 279.852 67.1411 275.909 66.8265C274.919 66.7475 273.979 66.3581 273.223 65.7139ZM275.012 79.1352C275.663 78.4843 275.663 77.4291 275.012 76.7782C274.361 76.1273 273.306 76.1273 272.655 76.7782L266.333 83.0997L264.178 80.9449C263.527 80.294 262.472 80.294 261.821 80.9449C261.17 81.5957 261.17 82.651 261.821 83.3019L265.155 86.6352C265.806 87.2861 266.861 87.2861 267.512 86.6352L275.012 79.1352Z'
            fill='#7B1783'
          />
          <rect x='219' y='119' width='98' height='8' fill='#E5D1E6' />
          <rect x='219' y='135' width='98' height='8' fill='#E5D1E6' />
          <rect x='219' y='151' width='98' height='8' fill='#E5D1E6' />
          <rect
            x='194'
            y='33'
            width='148'
            height='168'
            rx='11'
            stroke='#D6C3D6'
            strokeWidth='2'
          />
        </g>
      </g>
      <defs>
        <filter
          id='filter0_bdddd_490_95820'
          x='169.548'
          y='17.3422'
          width='199.836'
          height='211.042'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='7.32889' />
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur_490_95820'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='2.93156' />
          <feGaussianBlur stdDeviation='8.79467' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.384314 0 0 0 0 0.301961 0 0 0 0 0.890196 0 0 0 0.12 0'
          />
          <feBlend
            mode='normal'
            in2='effect1_backgroundBlur_490_95820'
            result='effect2_dropShadow_490_95820'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='8.79467' dy='8.79467' />
          <feGaussianBlur stdDeviation='8.79467' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.98025 0 0 0 0 0.0125 0 0 0 0 1 0 0 0 0.06 0'
          />
          <feBlend
            mode='normal'
            in2='effect2_dropShadow_490_95820'
            result='effect3_dropShadow_490_95820'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='-5.86311' dy='8.79467' />
          <feGaussianBlur stdDeviation='8.79467' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.08125 0 0 0 0 0.0625 0 0 0 0 1 0 0 0 0.08 0'
          />
          <feBlend
            mode='normal'
            in2='effect3_dropShadow_490_95820'
            result='effect4_dropShadow_490_95820'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='2.93156' />
          <feGaussianBlur stdDeviation='1.46578' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0'
          />
          <feBlend
            mode='normal'
            in2='effect4_dropShadow_490_95820'
            result='effect5_dropShadow_490_95820'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect5_dropShadow_490_95820'
            result='shape'
          />
        </filter>
        <clipPath id='clip0_490_95820'>
          <rect width='536' height='180' rx='4' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CsvBanner;
