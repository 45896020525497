// @ts-strict-ignore
import { css } from '@emotion/react';
import { Loading } from 'components/core';
import LogoWithContainer from 'components/LogoWithContainer';
import ProgressBar from 'glue/components/information/ProgressBar';
import _ from 'lodash';
import { ReactElement, useEffect, useState } from 'react';
import { Step, Steps, Wizard } from 'react-albus';
import { Helmet } from 'react-helmet-async';
import { Redirect, useHistory } from 'react-router-dom';
import { PageMetaData, Paths } from 'Routes';
import theme from 'theme';
import {
  GenericReferenceInput,
  NylasConnectionState,
  PreferenceCategory,
  useDietaryRestrictionsListQuery,
  useGetViewerQuery,
  useUpdatePreferencesAndDietaryRestrictionsMutation,
} from 'types';
import AccountStep from './steps/Account';
import ActivityPreferenceQuestions from './steps/ActivityPreferenceQuestions';
import AdditionalPreferenceQuestion from './steps/AdditionalPreferenceQuestion';
import CalendarSync from './steps/CalendarSync';
import DietaryRestrictions from './steps/DietaryRestrictions';
import LegalStep from './steps/LegalStep';

type StepMap = {
  activityQuestions: boolean;
  additionalPreferenceQuestions: boolean;
  dietaryRestrictions: boolean;
  account: boolean;
  calendarSync: boolean;
  legal: boolean;
};

const getActivityQuestions = (viewer) =>
  (viewer?.unansweredPreferenceQuestions ?? []).filter(
    (q) => q.category === PreferenceCategory.Activity,
  );

const getAdditionalPreferenceQuestions = (viewer) =>
  (viewer?.unansweredPreferenceQuestions ?? []).filter(
    (q) => q.category !== PreferenceCategory.Activity,
  );

const AccountSetup = () => {
  const history = useHistory();
  const [updatePreferencesAndDietaryRestrictions] =
    useUpdatePreferencesAndDietaryRestrictionsMutation();

  // initialized from the viewer. if it's null, we're not ready to show steps yet.
  const [stepsToShow, setStepsToShow] = useState<StepMap | null>(null);

  // this global map stores the answers to the questions
  const [preferenceAnswers, setPreferenceAnswers] = useState<{
    [questionId: string]: string[];
  }>({});

  const { data: viewerData } = useGetViewerQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      // we've already determined the steps we want to show
      if (stepsToShow !== null) return;

      const viewer = data.viewer;

      // compute and set the steps to show from current data
      setStepsToShow({
        account: !viewer.firstName || !viewer.lastName,
        calendarSync: viewer.nylasConnectionState === NylasConnectionState.Inactive,
        dietaryRestrictions: !viewer.dietaryRestrictionsLastAsked,
        activityQuestions: !!getActivityQuestions(viewer).length,
        additionalPreferenceQuestions: !!getAdditionalPreferenceQuestions(viewer).length,
        legal: !viewer.hasAgreedToLatestTOS || !viewer.promptedForMarketingConsent,
      });
    },
  });

  const viewer = viewerData?.viewer;

  const { data: dietaryRestrictionListQueryData } = useDietaryRestrictionsListQuery();

  useEffect(() => {
    if (viewer) {
      // Hydrate our current state with the user's preferenceAnswers
      if (_.isEmpty(preferenceAnswers)) {
        setPreferenceAnswers(
          viewer.preferenceAnswers.reduce((acc, cur) => {
            acc[cur.question.id] = [cur.id];
            return acc;
          }, {}),
        );
      }
    }
  }, [viewer]);

  const updatePreferences = async () => {
    const preferenceAnswersInput = Object.values(preferenceAnswers)
      .flat(Infinity)
      .map((answerId) => {
        return { id: answerId } as GenericReferenceInput;
      });
    await updatePreferencesAndDietaryRestrictions({
      variables: {
        id: viewer.id,
        preferenceAnswers: preferenceAnswersInput,
      },
      refetchQueries: ['getViewer'],
    });
  };

  const updateDietaryRestrictions = async (dietaryRestrictions: string[]) => {
    const dietaryRestrictionsInput = dietaryRestrictions.map((id) => ({
      id,
    }));
    const preferenceAnswersInput = Object.values(preferenceAnswers)
      .flat(Infinity)
      .map((answerId) => {
        return { id: answerId } as GenericReferenceInput;
      });
    await updatePreferencesAndDietaryRestrictions({
      variables: {
        id: viewer.id,
        preferenceAnswers: preferenceAnswersInput,
        dietaryRestrictions: dietaryRestrictionsInput,
        dietaryRestrictionsLastAsked: dietaryRestrictions
          ? new Date().toISOString()
          : undefined,
      },
      refetchQueries: ['getViewer'],
    });
  };

  const steps: ReactElement[] = [];

  if (!viewer || !stepsToShow) return <Loading />;

  if (stepsToShow.account) {
    steps.push(
      <Step
        id='account'
        key='accountStep'
        render={(context) => <AccountStep {...context} viewer={viewer} />}
      />,
    );
  } else if (stepsToShow.legal) {
    // AccountStep includes LegalStep, so don't show both
    steps.push(
      <Step
        id='legal'
        key='legalStep'
        render={(context) => <LegalStep {...context} viewer={viewer} />}
      />,
    );
  }
  if (stepsToShow.activityQuestions) {
    steps.push(
      <Step
        id='activityPreferenceQuestions'
        render={(context) => (
          <ActivityPreferenceQuestions
            context={context}
            viewer={viewer}
            currentPreferenceAnswers={preferenceAnswers}
            setPreferenceAnswers={setPreferenceAnswers}
            onNext={updatePreferences}
            questions={getActivityQuestions(viewer)}
          />
        )}
      />,
    );
  }
  if (stepsToShow.additionalPreferenceQuestions) {
    steps.push(
      ...getAdditionalPreferenceQuestions(viewer).map((q) => (
        <Step
          id={q.id}
          key={q.id}
          render={(context) => (
            <AdditionalPreferenceQuestion
              context={context}
              question={q}
              viewer={viewer}
              currentPreferenceAnswers={preferenceAnswers}
              setPreferenceAnswers={setPreferenceAnswers}
              onNext={updatePreferences}
            />
          )}
        />
      )),
    );
  }
  if (stepsToShow.dietaryRestrictions) {
    steps.push(
      <Step
        id='dietaryRestrictions'
        key='dietaryRestrictions'
        render={(context) => (
          <DietaryRestrictions
            context={context}
            viewer={viewer}
            dietaryRestrictionList={dietaryRestrictionListQueryData?.dietaryRestrictions}
            onNext={updateDietaryRestrictions}
          />
        )}
      />,
    );
  }
  if (stepsToShow.calendarSync) {
    steps.push(
      <Step
        id='calendar-sync'
        key='calendarSyncStep'
        render={(context) => <CalendarSync {...context} viewer={viewer} />}
      />,
    );
  }

  return (
    <LogoWithContainer>
      <Helmet>
        <title>{PageMetaData.ACCOUNT_SETUP.title}</title>
      </Helmet>
      <Wizard
        history={history}
        basename={Paths.ACCOUNT_SETUP}
        render={({ step, steps: wizardSteps }) => {
          if (!step) {
            return <Loading />;
          }

          const totalSteps = wizardSteps.length - 1;
          const currentStepIndex = wizardSteps.findIndex((s) => s.id === step.id);

          return (
            <>
              <div css={styles.progressContainer}>
                <span css={styles.progressStep}>
                  {currentStepIndex + 1} of {totalSteps}
                </span>
                <ProgressBar value={Math.round((100 * currentStepIndex) / totalSteps)} />
              </div>
              <Steps>
                {steps}
                <Step
                  id='confirmation'
                  render={() => {
                    return <Redirect to={Paths.HOME} />;
                  }}
                />
              </Steps>
            </>
          );
        }}
      />
    </LogoWithContainer>
  );
};

export default AccountSetup;

const styles = {
  progressContainer: css({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    margin: `0 ${theme.spacing(6)}`,
  }),
  progressStep: css({
    whiteSpace: 'nowrap',
    flexShrink: 0,
  }),
};
