import { css } from '@emotion/react';
import { colors } from '@mysteryco/design';
import ClockRefresh from '@mysteryco/design/icons/ClockRefresh';
import CursorClick01 from '@mysteryco/design/icons/CursorClick01';
import LineChartUp04 from '@mysteryco/design/icons/LineChartUp04';
import ArrowRight from '@mysteryco/design/icons/ArrowRight';
import PulseBanner from 'glue/assets/artwork/pulse-demo.png';
import { LinkButton } from 'glue/components/buttons/Button';
import ValueProp from 'glue/components/cards/ValueProp';
import FeatureStatusPills from 'glue/components/structure/FeatureStatusPills';
import theme from 'theme';
import mixpanel from 'mixpanel-browser';
import { Paths } from 'Routes';

function trackBeginSetup(ctaText: string) {
  mixpanel.track('pls-begin-setup', {
    eventType: 'click',
    buttonCopy: ctaText,
  });
}

const NotSetUp = ({
  ctaLink = Paths.PULSE_SETTINGS,
  ctaText = 'Get Started',
  onClickCta = trackBeginSetup,
}: {
  ctaLink?: string;
  onClickCta?: (ctaText: string) => void;
  ctaText?: string;
}) => {
  return (
    <div css={styles.root}>
      <div css={styles.status}>
        <FeatureStatusPills text='Live BETA' />
      </div>
      <div css={styles.summary}>
        <h2 css={styles.headline}>Gather signal on employee engagement and morale</h2>
        <p css={styles.details}>
          Empower your teams to turn your company into a great place to work
        </p>
        <LinkButton
          to={ctaLink}
          color='green'
          shape='round'
          newTab
          endIcon={<ArrowRight size={24} color='currentColor' />}
          onClick={() => onClickCta?.(ctaText)}
        >
          {ctaText}
        </LinkButton>
      </div>
      <div css={styles.image}>
        <img src={PulseBanner} alt='' />
      </div>
      <div css={styles.values}>
        <div css={styles.valueRow}>
          <ValueProp
            headline={'Streamlined survey creation'}
            icon={<CursorClick01 />}
            css={styles.valueCard}
          >
            Simplify employee surveys with Glue's streamlined approach. Focus on insights,
            not question writing.
          </ValueProp>
          <ValueProp
            headline={'Ongoing employee feedback'}
            icon={<ClockRefresh />}
            css={styles.valueCard}
          >
            Regular Pulse surveys offer valuable employee insights to help you shape a
            strong people strategy.
          </ValueProp>
          <ValueProp
            headline={'See problems right away'}
            icon={<LineChartUp04 />}
            css={styles.valueCard}
          >
            {`We'll help you quickly identify trends, track changes, and pinpoint areas of
            improvement.`}
          </ValueProp>
        </div>
      </div>
    </div>
  );
};

const styles = {
  root: css({
    marginTop: theme.spacing(10),
  }),
  status: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  }),
  summary: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: theme.spacing(10),
    gap: theme.spacing(5),
  }),
  headline: css({
    fontWeight: 700,
    fontSize: '2.5rem',
    lineHeight: 1.2,
    textAlign: 'center',
    color: colors.Glue_Ink00,
  }),
  details: css({
    fontWeight: 500,
    fontSize: '1.375rem',
    lineHeight: 1.4,
    textAlign: 'center',
    color: colors.Glue_Ink00,
  }),
  image: css({
    width: '80%',
    margin: 'auto',
    '& img': {
      maxWidth: '100%',
    },
  }),
  values: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'stretch',
    padding: 0,
    marginTop: theme.spacing(10),
  }),
  valueHeadline: css({
    width: '100%',
    fontWeight: 700,
    fontSize: '.75rem',
    lineHeight: 1.2,
    textAlign: 'center',
    letterSpacing: '0.1em',
    color: colors.Glue_Ink10,
    textTransform: 'uppercase',
  }),
  valueRow: css({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'stretch',
    padding: `0 ${theme.spacing(10)}`,
    gap: theme.spacing(10),
    marginBottom: theme.spacing(10),
  }),
  valueCard: css({
    flex: 1,
    minWidth: '18em',
  }),
};

export default NotSetUp;
