import { Box, Link, Typography } from '@material-ui/core';
import {
  buildEventBasedEstimateString,
  centsToDollarString,
  ContractCost,
} from 'lib/helpers/money';
import theme from 'theme';
import { ContractUnitType } from 'types';
import { getUpsellLink } from 'utils/stringUtil';

const Estimate = ({
  contractCost,
  estimate,
}: {
  contractCost: ContractCost;
  estimate: string;
}) => {
  if (contractCost) {
    return (
      <Box>
        <ContractUnitEstimate contractCost={contractCost} />
      </Box>
    );
  }

  return (
    <>
      <LineItem title={''} content={estimate} />{' '}
      <Typography variant='caption' color='primary'>
        Psst...You could save money{' '}
      </Typography>
      <Link
        variant='caption'
        color='primary'
        href={getUpsellLink('booking-psst')}
        target='_blank'
        rel='noreferrer'
      >
        with a Glue Subscription
      </Link>
    </>
  );
};

export default Estimate;

const LineItem = ({
  title,
  content,
}: {
  title: string;
  content: string | React.ReactNode;
}) => (
  <Box mb={theme.spacing(8)}>
    <Typography variant='h6'>{title}</Typography>
    <Box mt={theme.spacing(2)}>
      <Typography variant='h5'>
        {typeof content === 'string' ? <b>{content}</b> : content}
      </Typography>
    </Box>
  </Box>
);

const ContractUnitEstimate = ({ contractCost }: { contractCost: ContractCost }) => {
  if (contractCost.type === ContractUnitType.Recurring)
    return (
      <>
        <Typography>{contractCost.unitCount} seats</Typography>
        {(contractCost.premiumCostCents > 0 || contractCost.premiumUnitCount > 0) && (
          <div style={{ fontSize: '12px', lineHeight: '160%', opacity: '.75' }}>
            + {centsToDollarString(contractCost.total)} Premium upgrade fee
          </div>
        )}
      </>
    );

  if (contractCost.type === ContractUnitType.Credits)
    return (
      <Typography>
        {`${contractCost.unitCount - contractCost.additionalUnits} credits${
          contractCost.total !== 0 ? ` + ${centsToDollarString(contractCost.total)}` : ''
        }`}
      </Typography>
    );

  if (contractCost.type === ContractUnitType.Cents)
    return (
      <Typography>
        {`${centsToDollarString(contractCost.unitCount + contractCost.additionalUnits)}${
          contractCost.unitCount
            ? `${contractCost.additionalUnits ? ' partially' : ''}
      covered by your plan`
            : ''
        }`}
      </Typography>
    );

  if (contractCost.type === ContractUnitType.Event) {
    return <Typography>{buildEventBasedEstimateString(contractCost)}</Typography>;
  }

  // Keeps app from crashing
  return null;
};
