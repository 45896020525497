// @ts-strict-ignore
import config from 'config';
import _ from 'lodash';
import { DateTime, Interval } from 'luxon';
import { OperatingHours, TimeWindow } from 'types';
import {
  EventForSelection,
  EventInfo,
  MatchedExperience,
  TemplateWithExperienceInfo,
} from 'types/selfServeFlow';
import {
  calculateMinBookingLeadTimeDays,
  canRequestToBook,
  getBusinessDaysBetweenDates,
} from 'utils/dateUtil';

const findEventOpHoursDate = ({
  eventStartTime,
  operatingHours,
  experienceTimezone,
}: {
  eventStartTime: DateTime;
  operatingHours: OperatingHours[];
  experienceTimezone: string;
}): OperatingHours[] => {
  const startDay = eventStartTime.setZone(experienceTimezone).weekday;
  return operatingHours.filter((operatingHour) => operatingHour.open.day === startDay);
};

export const getOpenExperiences = (
  experiencesWithTemplates: MatchedExperience[],
  windows: TimeWindow[],
): MatchedExperience[] => {
  // Make sure all experiences have operating hours that work with our windows from calendar integration

  const openExperiences = _.filter(experiencesWithTemplates, (experience) => {
    const timezone = experience.location?.timezone;
    const operatingHours = experience.operatingHours;
    return checkIfWindowHasIntersectionWithOperatingHours({
      windows,
      operatingHours,
      experienceTimezone: timezone,
    });
  });

  const withinBookingThreshold = _.filter(openExperiences, (experience) =>
    _.some(windows, (window) =>
      canRequestToBook(window.startTime, experience.hasPhysicalGoods, [experience]),
    ),
  );

  return withinBookingThreshold;
};

export const groupExperiencesByTemplate = (
  experiencesWithTemplates: MatchedExperience[],
) => {
  return experiencesWithTemplates.reduce(
    (acc, experienceWithTemplates) => {
      for (const template of experienceWithTemplates.templates) {
        const experienceInfo = _.omit(experienceWithTemplates, ['templates']);

        if (acc[template.id]) {
          acc[template.id].experiences.push(experienceInfo);
        } else {
          acc[template.id] = {
            ...template,
            experiences: [experienceInfo],
          };
        }
      }
      return acc;
    },
    {} as {
      [key: string]: TemplateWithExperienceInfo;
    },
  );
};

// Pass in windows and check for an intersection in _ANY_ operating hour object.
// This is used to ensure that we only show templates/experiences that are open in a user's
// specified window of availability
// [M-1879] Timezone should be set to Pacific Time for all experiences.
export const checkIfWindowHasIntersectionWithOperatingHours = ({
  windows,
  operatingHours,
  experienceTimezone,
}: {
  windows: TimeWindow[];
  operatingHours: OperatingHours[];
  experienceTimezone: string;
}) => {
  return _.some(windows, (window) => {
    const tz = config.defaultTimezone;
    const windowStartDT = DateTime.fromJSDate(window.startTime);
    const windowEndDT = DateTime.fromJSDate(window.endTime);
    const windowInterval = Interval.fromDateTimes(
      windowStartDT.setZone(tz),
      windowEndDT.setZone(tz),
    );
    const applicableOperatingHours = findEventOpHoursDate({
      eventStartTime: DateTime.fromJSDate(window.startTime),
      operatingHours,
      experienceTimezone: tz,
    });

    const windowDayOfYear = {
      day: windowStartDT.setZone(tz).day,
      month: windowStartDT.setZone(tz).month,
      year: windowStartDT.setZone(tz).year,
    };

    return _.some(applicableOperatingHours, (operatingHour) => {
      const closeDT = operatingHour.close
        ? DateTime.fromObject(
            {
              hour: operatingHour.close.hours,
              minute: operatingHour.close.minutes,
              ...windowDayOfYear,
            },
            { zone: tz },
          )
        : DateTime.fromObject(
            {
              hour: 23,
              minute: 59,
              second: 59,
              ...windowDayOfYear,
            },
            { zone: tz },
          );
      const openDT = DateTime.fromObject(
        {
          hour: operatingHour.open.hours,
          minute: operatingHour.open.minutes,
          ...windowDayOfYear,
        },
        { zone: tz },
      );

      return Interval.fromDateTimes(openDT, closeDT).intersection(windowInterval);
    });
  });
};

// [M-1879] Timezone should be set to Pacific Time for all experiences.
export const checkIfEventIsWithinOperatingHours = ({
  event,
  operatingHours,
  experienceTimezone,
}: {
  event: { start: Date };
  operatingHours: OperatingHours[];
  experienceTimezone: string;
}) => {
  const tz = config.defaultTimezone;
  const eventDT = DateTime.fromJSDate(new Date(event.start));
  const applicableOperatingHours = findEventOpHoursDate({
    eventStartTime: eventDT,
    operatingHours,
    experienceTimezone,
  });
  if (!applicableOperatingHours) return false;

  const eventDTatexperienceTimezone = eventDT.setZone(experienceTimezone);
  const eventDayOfYear = {
    day: eventDTatexperienceTimezone.day,
    month: eventDTatexperienceTimezone.month,
    year: eventDTatexperienceTimezone.year,
  };

  return _.some(applicableOperatingHours, (operatingHour) => {
    const closeDT = operatingHour.close
      ? DateTime.fromObject(
          {
            hour: operatingHour.close.hours,
            minute: operatingHour.close.minutes,
            ...eventDayOfYear,
          },
          { zone: tz },
        )
      : DateTime.fromObject(
          {
            hour: 23,
            minute: 59,
            second: 59,
            ...eventDayOfYear,
          },
          { zone: tz },
        );
    const openDT = DateTime.fromObject(
      {
        hour: operatingHour.open.hours,
        minute: operatingHour.open.minutes,
        ...eventDayOfYear,
      },
      { zone: tz },
    );

    return Interval.fromDateTimes(openDT, closeDT).contains(eventDT);
  });
};

const checkIfEnoughLeadTimeForShippedGoods = ({ date, leadTimeDays }): boolean => {
  const businessDaysBetween = getBusinessDaysBetweenDates(
    DateTime.fromJSDate(date).startOf('day').toJSDate(),
    DateTime.now().startOf('day').toJSDate(),
  );
  return businessDaysBetween >= leadTimeDays;
};

// [M-1879] Timezone should be set to Pacific Time for all experiences.
export const addOpenExperiencesToEvents = ({
  events,
  selectedTemplate,
}: {
  events: EventInfo[];
  selectedTemplate: TemplateWithExperienceInfo;
}) => {
  return events.map((event) => {
    const experiencesThatAreOpenForThisEvent = [];
    const minBookingLeadTime = calculateMinBookingLeadTimeDays(
      selectedTemplate.experiences,
    );
    for (const experience of selectedTemplate.experiences) {
      if (
        checkIfEventIsWithinOperatingHours({
          event: { start: new Date(event.start) },
          operatingHours: experience.operatingHours,
          experienceTimezone: config.defaultTimezone,
        })
      ) {
        if (
          !experience.hasPhysicalGoods ||
          checkIfEnoughLeadTimeForShippedGoods({
            date: new Date(event.start),
            leadTimeDays: minBookingLeadTime,
          })
        ) {
          experiencesThatAreOpenForThisEvent.push({ id: experience.id });
        }
      }
    }

    return {
      ...event,
      openExperiences: experiencesThatAreOpenForThisEvent,
      rtbExperiences: _.map(selectedTemplate.experiences, (exp) => _.pick(exp, 'id')),
    } as EventForSelection;
  });
};
