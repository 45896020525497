import { ScoreType } from 'types';

export const IS_GLUE = {
  title: 'Glue Event',
  description:
    'Good things come in mysterious packages! We’ve got a secret experience planned that we know you’ll love. Curious what the surprise is? You’ll find out when you join the event!',
  imageUrl: 'https://mystery-public-assets.s3.us-west-2.amazonaws.com/is_glue.png',
};

export const SCORE_TYPE_NAME_MAP = {
  [ScoreType.SenseOfBelonging]: 'Sense of belonging',
  [ScoreType.WithinTeam]: 'Within-team',
  [ScoreType.CrossTeam]: 'Cross-team',
  [ScoreType.Leadership]: 'Leadership',
};

export const SCORE_TYPE_TO_PATH_PARAM: Record<ScoreType, string> = {
  [ScoreType.CrossTeam]: 'cross-team',
  [ScoreType.Leadership]: 'leadership',
  [ScoreType.SenseOfBelonging]: 'sense-of-belonging',
  [ScoreType.WithinTeam]: 'within-team',
};

export const EN_DASH = '–';
export const EM_DASH = '—';
