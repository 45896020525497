import { getUniqUserCount } from 'glue/components/inputs/audience';
import { audienceReducer } from 'glue/scenes/Meetups/Settings';
import ActiveMeetupSummary from 'glue/scenes/Meetups/Settings/ActiveMeetupSummary';
import InitialSetup from 'glue/scenes/Meetups/Settings/InitialSetup';
import MeetupsAreEnabled from 'glue/scenes/Meetups/Settings/MeetupsAreEnabled';
import MeetupsLoader, {
  MeetupsLoaderPassedProps,
} from 'glue/scenes/Meetups/Settings/MeetupsLoader';
import { Route, Switch } from 'react-router';
import { ViewerFragmentFragment } from 'types';
import { useQueryParams } from 'lib/helpers/router';
import { getUpsellLink } from 'utils/stringUtil';

import { getActivitiesTypePath } from '../';
import Layout from './new/Layout';
import Report from 'glue/scenes/Meetups/Settings/Report';

export const getMeetupsPath = (requiresUpgrade?: boolean) => {
  return requiresUpgrade
    ? getUpsellLink('meetups upgrade')
    : getActivitiesTypePath('meetups');
};

const NewMeetups = ({
  viewer,
  showSummary = false,
}: {
  viewer: ViewerFragmentFragment | undefined;
  showSummary: boolean;
}) => {
  if (!viewer || !viewer.orgs?.[0]) return null;
  const org = viewer.orgs[0];

  const MeetupsState = (props: MeetupsLoaderPassedProps) => {
    const { existingInitiative, copy, orgId } = props;
    if (existingInitiative?.id && !existingInitiative?.disabledAt) {
      return (
        <Layout loaderProps={props}>
          {showSummary ? (
            <>
              <MeetupsAreEnabled />
              <ActiveMeetupSummary
                {...copy}
                memberCount={getUniqUserCount(
                  audienceReducer(existingInitiative.audience),
                )}
              />
            </>
          ) : (
            <Report existingInitiative={existingInitiative} orgId={orgId} />
          )}
        </Layout>
      );
    } else {
      return (
        <Layout loaderProps={props}>
          <InitialSetup />
        </Layout>
      );
    }
  };

  return (
    <MeetupsLoader
      firstName={viewer.firstName || viewer.name || ''}
      orgId={org.id}
      orgName={org.name}
      Component={MeetupsState}
    />
  );
};

const Meetups = ({ viewer }: { viewer: ViewerFragmentFragment | undefined }) => {
  const params = useQueryParams();
  const showSummary = !!params.get('summary');

  return (
    <Switch>
      <Route exact path={getMeetupsPath()}>
        <NewMeetups viewer={viewer} showSummary={showSummary} />
      </Route>
    </Switch>
  );
};

export default Meetups;
