// @ts-strict-ignore
import { makeStyles } from '@material-ui/styles';
import { colors } from '@mysteryco/design/src';
import EditTeam, {
  isValidTeam,
  RenderTeam,
  teamFilterOptions,
} from 'components/Forms/EditTeam';
import { pluralize } from 'humanize-plus';
import { TeamPageOrganization } from 'scenes/Team';
import theme from 'theme';
import { Team, useEditTeamNameMutation } from 'types';
import { defaultTeamName } from 'utils/teamUtil';
import SelectFilter from './SelectFilter';

interface SelectTeamFilterProps {
  organization: TeamPageOrganization;
  value?: string[];
  onChange?: (event: any, newValue: string[]) => void;
  disabled?: boolean;
  [key: string]: any;
}

const SelectTeamFilter = ({
  organization,
  value = [],
  onChange = () => null,
  disabled = false,
  ...props
}: SelectTeamFilterProps) => {
  const classes = useStyles();

  const orgTeams = (organization.teams || []) as Team[];

  const [renameTeam] = useEditTeamNameMutation();

  const handleSave = async (team: Team) => {
    if (!team.id) return;
    const name = team?.name || defaultTeamName(team);
    await renameTeam({
      variables: { id: team?.id, name },
      refetchQueries: ['searchOrgUsers'],
    });
  };

  const RenderTeamOption = (team: Team) => {
    return <RenderTeam team={team} classes={classes} />;
  };

  const RenderActiveTeam = (
    team: Team,
    thisOnChange: (event: any, newValue: Team) => void,
  ) => {
    return <EditTeam team={team} teamOptions={orgTeams} onChange={thisOnChange} />;
  };

  return (
    <SelectFilter
      label='Teams'
      options={orgTeams}
      value={value}
      onChange={onChange}
      onSave={async (_, team) => handleSave(team as Team)}
      filterOptions={teamFilterOptions}
      renderOption={RenderTeamOption}
      renderActive={RenderActiveTeam}
      isValid={(newTeam: Team) => isValidTeam(newTeam, orgTeams)}
      selectAllText={`Select all ${orgTeams.length} ${pluralize(
        orgTeams.length,
        'team',
      )}`}
      placeholderText='Search teams'
      disabled={disabled}
      disableCreate
      {...props}
    />
  );
};

const useStyles = makeStyles({
  teamOption: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: `${theme.spacing(2)} 0`,
    minHeight: '3.5rem',
  },
  teamOptionName: {
    color: colors.Black,
    fontSize: '.875rem',
  },
  teamOptionManager: {
    color: colors.MediumGray,
    fontSize: '.75rem',
  },
});

export default SelectTeamFilter;
