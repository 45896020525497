import { cx } from '@emotion/css';
import { Box, Popper, Tooltip as MuiTooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@mysteryco/design';
import theme from 'theme';

const Tooltip = ({
  title,
  placement = 'bottom-start',
  children,
}: {
  title: string;
  placement?:
    | 'bottom-start'
    | 'bottom-end'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  children: any;
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.tooltip}>
      <MuiTooltip
        title={title}
        PopperProps={{ modifiers: { flip: { enabled: true } } }}
        PopperComponent={(props) => (
          <Popper {...props} className={cx(classes.tooltip, props?.className)} />
        )}
        placement={placement}
        arrow
      >
        <div>{children}</div>
      </MuiTooltip>
    </Box>
  );
};

const useStyles = makeStyles({
  tooltip: {
    zindex: theme.zIndex.tooltip,
    '& .MuiTooltip-tooltip': {
      zindex: theme.zIndex.tooltip,
      padding: theme.spacing(2),
      background: colors.Midnight,
      borderRadius: theme.spacing(1),
      fontWeight: 500,
      fontSize: '.75rem',
      lineHeight: 1.33,
      color: colors.White,
      margin: `${theme.spacing(2)} 0`,
    },
    '& .MuiTooltip-arrow::before': {
      background: colors.Midnight,
      borderRadius: 1,
    },
  },
});

export default Tooltip;
