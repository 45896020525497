import { Box, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import theme from 'theme';
import Check from './icons/Check';

export enum SelectableOptionStatus {
  Selected,
  NothingSelected,
  SomethingSelectedButNotMe,
}

export const OPTION_HEIGHT = 52;
const OPTION_WIDTHS = { small: 172, large: 264, fullWidth: '100%' };

interface Props {
  label: string;
  isSelected: boolean;
  size?: 'small' | 'large' | 'fullWidth';
  onClick(): void;
  icon?: React.ReactElement;
}

const useStyles = makeStyles<Theme, Partial<Props>>({
  container: ({ isSelected, size }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: size ? OPTION_WIDTHS[size] : OPTION_WIDTHS.small,
    border: `1px solid ${
      isSelected ? theme.palette.primary.main : theme.palette.grey[400]
    }`,
    borderRadius: theme.spacing(2),
    padding: `12px 12px 12px 24px`,
    height: OPTION_HEIGHT,
    cursor: 'pointer',
    background: isSelected ? theme.palette.primary.light : theme.palette.common.white,
    '&:hover': {
      background: theme.palette.primary.light,
      borderColor: theme.palette.primary[500],
      boxShadow:
        '0px 4px 6px -1px rgba(202, 194, 203, 0.1), 0px 2px 4px -1px rgba(202, 194, 203, 0.06)',
    },
    '&:focus': {
      background: theme.palette.primary.light,
      borderColor: theme.palette.primary[700],
    },
  }),
  label: ({ isSelected }) => ({
    fontWeight: isSelected ? 900 : 500,
    color: isSelected ? theme.palette.primary.main : 'inherit',
  }),
});

const NewSelectableOption = (props: Props) => {
  const { isSelected, size } = props;
  const classes = useStyles({ isSelected, size });
  return (
    <Box onClick={props.onClick} className={classes.container}>
      <Box display='flex' alignItems={'center'}>
        {props.icon &&
          React.cloneElement(props.icon, { size: 18, color: theme.palette.primary.main })}
        <Box style={{ marginLeft: theme.spacing(2) }}>
          <Typography className={classes.label}>{props.label}</Typography>
        </Box>
      </Box>
      {isSelected && (
        <Check size={18} style={{ display: 'flex', alignItems: 'center' }} />
      )}
    </Box>
  );
};

export default NewSelectableOption;
