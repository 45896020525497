export const Brand = {
  Primary: `#7B1783`,
  Secondary: `#5C296C`,
  PrimaryGradient: `linear-gradient(45deg, #7B1783 0%, #F37046 100%)`,

  PurpleDark: `#2B0A45`,
  PurpleLight: `#D7B9DA`,
  Purple: `#7B1783`,

  White: '#FFF',
};

export const Palette = {
  Error: `#F37046`,
  Success: `#7B1783`,

  Grey100: `#F9F8F0`,
  Grey500: `#D0D0D0`,

  GreyLightest: `#ADADAD`,
  GreyLight: `#525252`,
  GreyDark: `#292929`,
  GreyDarkest: `#141414`,

  Black: `#000`,

  customGradient: (c1, c2) => `linear-gradient(45deg, ${c1} 0%, ${c2} 100%)`,
};

export const FONT = `rgba(0, 0, 0, 0.87)`;
export const INPUT_ICON = `rgba(0, 0, 0, .25)`;

export const BORDER_GREY = `#D9D9D9`;

export const BORDER = `1px solid ${BORDER_GREY}`;

export const BOX_SHADOW = `rgba(14, 30, 37, .12) 0 2px 4px`;
export const HEADER_BOX_SHADOW = `rgba(12, 52, 75, 0.05) 0px 3px 3px`;
