// @ts-strict-ignore
import { TextField } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { useState } from 'react';

const TagAutoComplete = ({ value, onChange, existingTags }) => {
  const [currentValue, setCurrentValue] = useState(value);
  const filter = createFilterOptions({
    stringify: (option) => (option as any).name,
  });
  return (
    <Autocomplete
      fullWidth
      value={currentValue}
      onChange={async (event, newValue) => {
        if (newValue && newValue.id) {
          onChange.add(newValue);
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          await onChange.create(newValue.inputValue);
        }
        setCurrentValue({});
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.name);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            title: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={existingTags}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (option.name) {
          return option.name;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.title;
        }
        // Regular option
        return option;
      }}
      renderOption={(option) => option.name || option.title}
      freeSolo
      renderInput={(params) => <TextField variant='outlined' fullWidth {...params} />}
    />
  );
};

export default TagAutoComplete;
