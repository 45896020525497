// @ts-strict-ignore
import { Box, Typography } from '@material-ui/core';
import { centsToDollarString, ContractCost } from 'lib/helpers/money';
import { ContractFragmentFragment, ContractUnitType } from 'types';
import { useSummaryStyles } from '.';
import cx from 'classnames';
import theme from 'theme';
import { CONTRACT_UNIT_PHRASE_LOOKUP } from 'lib/helpers/contract';
import _ from 'lodash';

const ContractSummary = ({
  contract,
  contractCost,
  subtotal,
  tax,
  total,
  finalBilling = false,
}: {
  contract: ContractFragmentFragment;
  contractCost: ContractCost;
  subtotal: string;
  tax: string;
  total: string;
  finalBilling?: boolean;
}) => {
  const classes = useSummaryStyles();
  const centsBased = contract.type === ContractUnitType.Cents;
  const creditsBased = contract.type === ContractUnitType.Credits;
  const labelName =
    contract.type === ContractUnitType.Recurring
      ? 'Event Seats'
      : centsBased
      ? 'Plan Usage'
      : creditsBased
      ? 'Credits'
      : 'Event Credits';
  const unitPhrase = CONTRACT_UNIT_PHRASE_LOOKUP[contract.type];

  return (
    <Box className={classes.container}>
      <Typography variant='h5' className={classes.sectionHeader}>
        {finalBilling ? 'Billing Summary' : 'Booking Summary'}
      </Typography>
      <Box mb={6}>
        <Box mb={6}>
          <Typography variant='subtitle1' className={classes.subSectionHeader}>
            {labelName}
          </Typography>
          <Box className={classes.lineItemRow}>
            <Typography>{`${_.startCase(unitPhrase)}s used for this event`}</Typography>
            <Typography>
              {centsBased
                ? centsToDollarString(contractCost.unitCount)
                : contractCost.unitCount}
            </Typography>
          </Box>
          {contractCost.additionalUnits > 0 && (
            <Box className={cx([classes.lineItemRow, classes.purpleText])}>
              <Typography>{`Additional ${unitPhrase}s${
                !centsBased ? ` (${centsToDollarString(contract.unitCostCents)}/ea)` : ''
              }`}</Typography>
              <Typography>
                {centsBased
                  ? centsToDollarString(contractCost.additionalUnits)
                  : contractCost.additionalUnits}
              </Typography>
            </Box>
          )}
        </Box>
        {(contractCost.premiumUnitCount > 0 || contractCost.premiumCostCents > 0) && (
          <Box mb={6}>
            <Typography variant='subtitle1' className={classes.subSectionHeader}>
              Supply & upgrade credits
            </Typography>
            <Box className={classes.lineItemRow}>
              <Typography>Credits used for this event</Typography>
              <Typography>{contractCost.premiumUnitCount}</Typography>
            </Box>
            {contractCost.additionalPremiumUnits > 0 && (
              <Box className={cx([classes.lineItemRow, classes.purpleText])}>
                <Typography>{`Additional credits (${centsToDollarString(
                  contract.premiumUnitCostCents,
                )}/ea)`}</Typography>
                <Typography>{contractCost.additionalPremiumUnits}</Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
      {contractCost.additionalUnits > 0 && (
        <Box className={classes.lineItemRow} style={{ marginBottom: theme.spacing(4) }}>
          <Typography>
            <b>{`Additional ${unitPhrase}s subtotal`}</b>
          </Typography>
          <Typography>{centsToDollarString(contractCost.unitCostCents)}</Typography>
        </Box>
      )}
      {contractCost.additionalPremiumUnits > 0 && (
        <Box className={classes.lineItemRow} style={{ marginBottom: theme.spacing(4) }}>
          <Typography>
            <b>Additional credits subtotal</b>
          </Typography>
          <Typography>{centsToDollarString(contractCost.premiumCostCents)}</Typography>
        </Box>
      )}
      <Box className={classes.lineItemRow}>
        <Typography>
          <b>Total before tax</b>
        </Typography>
        <Typography>{subtotal}</Typography>
      </Box>
      <Box className={classes.lineItemRow}>
        <Typography>
          <b>Tax</b>
        </Typography>
        <Typography>{tax}</Typography>
      </Box>
      <Box className={classes.lineItemRow} style={{ marginTop: theme.spacing(4) }}>
        <Typography>
          <b>{finalBilling ? 'Total' : 'Estimated Total'}</b>
        </Typography>
        <Typography>
          <b>{total}</b>
        </Typography>
      </Box>
      <Box>
        {total && !finalBilling && (
          <Typography>
            {`No payment required today${
              contract.type !== ContractUnitType.Event
                ? ' — your final total will be based on the number of guests who RSVP.'
                : ` — we'll send an invoice after your event for any additional charges.`
            }`}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ContractSummary;
