import { AudienceType } from 'glue/components/inputs/audience/types';
import {
  AudienceBuilderTeamFragment,
  AudienceBuilderUserFragment,
  AudienceBuilderUserTagFragment,
} from 'types';

export const isUserTag = (
  audience: AudienceType,
): audience is AudienceBuilderUserTagFragment => audience.__typename === 'UserTag';
export const isTeam = (audience: AudienceType): audience is AudienceBuilderTeamFragment =>
  audience.__typename === 'Team';
export const isUser = (audience: AudienceType): audience is AudienceBuilderUserFragment =>
  audience.__typename === 'User';
