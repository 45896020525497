import { css } from '@emotion/react';
import { colors } from '@mysteryco/design';
import theme from 'theme';
import { card } from './sharedStyles';

type Props = {
  headline: React.ReactNode;
  children: React.ReactNode;
  action: React.ReactNode;
  image?: React.ReactNode;
  headerElementType?: React.ElementType; // for accessibility
  [key: string]: any;
};

const ActionCard = ({
  image,
  headline,
  action,
  headerElementType = 'h3',
  children,
  ...props
}: Props) => {
  const Header = headerElementType;
  return (
    <div {...props} css={[styles.root, props?.css]}>
      {image && <div css={styles.image}>{image}</div>}
      <div css={styles.content}>
        <div>
          <Header css={styles.headline}>{headline}</Header>
          <div css={styles.description}>{children}</div>
        </div>
        <div>{action}</div>
      </div>
    </div>
  );
};

const styles = {
  root: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    padding: 0,
    ...card.root,
    '&:hover': {
      ...card.hover,
    },
    overflow: 'hidden',
  }),
  image: css({
    alignSelf: 'stretch',
    background: colors.Glue_LavenderLight,
    flex: 1,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      width: '100%',
      objectFit: 'cover',
    },
  }),
  content: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: '100%',
    justifyContent: 'space-between',
    padding: `${theme.spacing(6)} ${theme.spacing(6)} ${theme.spacing(8)}`,
    gap: theme.spacing(2.5),
    flex: 1,
  }),
  headline: css({
    fontWeight: 700,
    fontSize: '1.125rem',
    lineHeight: 1.33,
    color: colors.Glue_Ink00,
  }),
  description: css({
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: 1.4,
    color: colors.Glue_Ink00,
    opacity: 0.7,
    marginBottom: theme.spacing(5),
  }),
};

export default ActionCard;
