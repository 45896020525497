import Question from 'components/Checkout/steps/PreferenceQuestions/RadioOrCheckboxAnswer';
import RegistrationStepWrapper from 'components/Checkout/styling/RegistrationStepWrapper';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import { WizardContext } from 'react-albus';
import { PreferenceQuestionsFragmentFragment, ViewerFragmentFragment } from 'types';

interface RegistrationFlowPreferenceAnswers {
  [questionId: string]: string[];
}
export interface AdditionalPreferenceQuestionProps {
  context: WizardContext;
  viewer: ViewerFragmentFragment;
  currentPreferenceAnswers: RegistrationFlowPreferenceAnswers;
  setPreferenceAnswers(answers: RegistrationFlowPreferenceAnswers): void;
  question: PreferenceQuestionsFragmentFragment;
  onNext: () => Promise<void>;
}

export function AdditionalPreferenceQuestion({
  context,
  viewer,
  currentPreferenceAnswers,
  setPreferenceAnswers,
  question,
  onNext,
}: AdditionalPreferenceQuestionProps) {
  const questionIsAnswered = !_.isEmpty(currentPreferenceAnswers[question.id]);

  const onAnswer = ({ answerId, checked }) => {
    let currentValueForQuestion = currentPreferenceAnswers[question.id];
    if (!question.multiSelect)
      setPreferenceAnswers({ ...currentPreferenceAnswers, [question.id]: answerId });
    else {
      if (!checked) {
        setPreferenceAnswers({
          ...currentPreferenceAnswers,
          [question.id]: currentValueForQuestion.filter((a) => a !== answerId),
        });
      }
      if (checked) {
        if (currentValueForQuestion) {
          currentValueForQuestion.push(answerId);
        } else {
          currentValueForQuestion = [answerId];
        }
        setPreferenceAnswers({
          ...currentPreferenceAnswers,
          [question.id]: currentValueForQuestion,
        });
      }
    }
  };

  return (
    <RegistrationStepWrapper
      {...context}
      label={question.question}
      subTitle={question.subTitle}
      onClickNext={async () => {
        mixpanel.track('interest survey updated', {
          source: 'registration',
          questionsType: 'other',
          customerType: viewer?.customerType,
          firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
        });
        await onNext();
      }}
      nextButtonProps={{
        disabled: !questionIsAnswered,
      }}
    >
      <Question
        currentAnswers={currentPreferenceAnswers[question.id]}
        multiSelect={question.multiSelect}
        onAnswer={onAnswer}
        answers={question.preferenceAnswers ?? []}
      />
    </RegistrationStepWrapper>
  );
}

export default AdditionalPreferenceQuestion;
