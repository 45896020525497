import _ from 'lodash';
import { renderToString } from 'react-dom/server';
import { keyframes } from '@emotion/react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, Typography } from '@material-ui/core';
import { Skeleton } from 'components/Skeleton';
import { ScoreDetailsInsightsScoreFragment, ScoreType } from 'types';
import * as d3 from 'd3';
import { useRef, useEffect, useMemo } from 'react';
import { TrendingDown, TrendingUp, TrendingFlat } from '@material-ui/icons';

import LongArrowLeft from 'components/icons/LongArrowLeft';
import LongArrowRight from 'components/icons/LongArrowRight';
import theme from 'theme';
import FormatPercentage from 'components/FormatPercentage';
import { Dot, SubScore, SubjectType } from './types';
import { BeeSwarmTooltip } from './tooltip';

const DOT_RADIUS = 6;
const TRANSITION_DURATION = 250;

interface ClusterConfig {
  // How close scores must be to be considered for a cluster.
  range: number;
  // Minimum number of dots required to create a cluster.
  minSize: number;
  // Maximum number of dots allowed in a cluster.
  maxSize: number;
}

const CLUSTER_CONFIG: Record<SubjectType, ClusterConfig> = {
  [SubjectType.Individual]: {
    range: 0.01,
    minSize: 6,
    maxSize: 50,
  },
  [SubjectType.Team]: {
    range: 0.025,
    minSize: 2,
    maxSize: 6,
  },
};

interface Props {
  className?: string;
  // Note that we can be loading AND have subScores!
  // (e.g. when loading a new month, while displaying the old month's dots)
  loading?: boolean;
  subjectType: SubjectType;
  subScores?: SubScore[];
  score?: ScoreDetailsInsightsScoreFragment;
  tab: string;
}

interface TieredBucket {
  threshold: number;
  count: number;
  percentage: number;
  name: string;
  color: string;
}

const tiers = [
  { name: 'strong', threshold: 0.6, color: '#9DFC95' },
  { name: 'moderate', threshold: 0.4, color: '#59E1FF' },
  { name: 'low', threshold: 0.3, color: '#FCD45C' },
  { name: 'weak', threshold: 0, color: '#FB9054' },
];

const SCORE_TYPE_PHRASE_MAP = {
  [ScoreType.Leadership]: 'connections to leadership',
  [ScoreType.WithinTeam]: 'within-team connections',
  [ScoreType.CrossTeam]: 'cross-team connections',
  [ScoreType.SenseOfBelonging]: 'sense of belonging',
};

const clusteredDot = (subjectType: SubjectType, cluster: SubScore[]): Dot => {
  let size: number;
  if (subjectType === SubjectType.Individual) {
    size = cluster.length;
  } else {
    size = cluster.reduce((sum, { team }) => sum + (team?.size || 1), 0);
  }

  return {
    value: d3.mean(cluster, ({ value }) => value) ?? 0,
    size,
    subjects: cluster,
    name: cluster
      .map((subScore: SubScore) => subScore.teamName || subScore.team?.name || '')
      .slice(0, 25)
      .concat(cluster.length > 25 ? '...' : '')
      .filter(Boolean)
      .join(', '),
  };
};

const singleDots = (cluster: SubScore[]): Dot[] => {
  return cluster.map((subScore: SubScore) => {
    return {
      value: subScore.value ?? 0,
      size: subScore.team?.size || 1,
      name: subScore.teamName || subScore.team?.name || '',
      subjects: [subScore],
    };
  });
};

// TS note - using ! assertions here because code changes to make this more robust
// caused an infinite loop somehow.
const generateDots = (subjectType: SubjectType, subScores: SubScore[]) => {
  const { range, minSize, maxSize } = CLUSTER_CONFIG[subjectType];

  subScores = [...subScores].sort((i, j) => i.value! - j.value!);
  const dots: Dot[] = [];
  let i = 0;
  let firstInCluster: SubScore = subScores[i++];
  let cluster: SubScore[] = firstInCluster ? [firstInCluster] : [];
  while (i < subScores.length) {
    // Add score to existing cluster if possible
    if (
      subScores[i].value! - firstInCluster.value! <= range &&
      cluster.length < maxSize
    ) {
      cluster.push(subScores[i]);
      i++;
      continue;
    }

    // Current cluster is not large enough to be grouped; push each one to dots, then reset
    if (subScores[i].value! - firstInCluster.value! > range && cluster.length < minSize) {
      dots.push(...singleDots(cluster));
      firstInCluster = subScores[i];
      cluster = [];
      continue;
    }

    // Current cluster is full; push whole cluster to dots, then reset
    dots.push(clusteredDot(subjectType, cluster));
    firstInCluster = subScores[i];
    cluster = [];
  }

  // Push all leftover
  if (cluster.length) {
    if (cluster.length < minSize) {
      dots.push(...singleDots(cluster));
    } else {
      dots.push(clusteredDot(subjectType, cluster));
    }
  }
  return dots;
};

const computeRadius = (size: number) => {
  return DOT_RADIUS * (1 + Math.log10(size));
};

type DodgeNode = {
  index: number;
  next: DodgeNode | null;
};
/**
 * Determines the Y positions of dots to avoid each other along the X
 * axis.
 *
 * @param X The X positions of the dots.
 * @param R The radii of the dots.
 */
const dodge = (X: number[], R: number[]) => {
  const Y: number[] = [];
  const epsilon = 1e-3;
  const padding = 2;
  let head: DodgeNode | null = null,
    tail: DodgeNode | null = null;

  // Returns true if circle ⟨x,y⟩ intersects with any circle in the queue.
  const intersects = (x: number, y: number, r: number) => {
    let a = head;
    while (a) {
      const ai = a.index;
      if ((r + R[ai] + padding) ** 2 - epsilon > (X[ai] - x) ** 2 + (Y[ai] - y) ** 2) {
        return true;
      }
      a = a.next;
    }
    return false;
  };

  // Place each circle sequentially.
  for (const bi of d3.range(X.length).sort((i, j) => X[i] - X[j])) {
    // Remove circles from the queue that can’t intersect the new circle b.
    while (head && X[head.index] < X[bi] - (R[bi] * 2) ** 2) {
      head = head.next;
    }

    // Choose the minimum non-intersecting tangent.
    if (intersects(X[bi], (Y[bi] = 0), R[bi])) {
      let a = head;
      Y[bi] = Infinity;
      do {
        const ai = a!.index;
        let y1 = Y[ai] + Math.sqrt((R[ai] + R[bi] + padding) ** 2 - (X[ai] - X[bi]) ** 2);
        let y2 = Y[ai] - Math.sqrt((R[ai] + R[bi] + padding) ** 2 - (X[ai] - X[bi]) ** 2);
        if (Math.abs(y1) < Math.abs(Y[bi]) && !intersects(X[bi], y1, R[bi])) {
          Y[bi] = y1;
        }
        if (Math.abs(y2) < Math.abs(Y[bi]) && !intersects(X[bi], y2, R[bi])) {
          Y[bi] = y2;
        }
        a = a!.next;
      } while (a);
    }

    // Add b to the queue.
    const b = { index: bi, next: null };
    if (head === null) {
      head = tail = b;
    } else {
      // @ts-ignore
      tail = tail.next = b;
    }
  }

  return Y;
};

const renderSvg = ({
  subjectType,
  subScores,
  svgRef,
  containerRef,
  scoreType,
  tab,
  transition = false,
}: {
  subjectType: SubjectType;
  subScores: SubScore[];
  svgRef: any;
  containerRef: any;
  scoreType: ScoreType;
  tab: string;
  transition: boolean;
}) => {
  if (!subScores || !svgRef?.current || !containerRef?.current) {
    return null;
  }

  const margin = 30;
  const minHeight = 180;
  const width = svgRef.current.parentElement.offsetWidth - 30;
  const centerX = svgRef.current.parentElement.offsetWidth / 2;
  const centerY = svgRef.current.parentElement.offsetHeight / 2;

  let dots: Dot[];
  dots = generateDots(subjectType, subScores);

  // Compute default domains.
  const xDomain = d3.extent([0, 1]);
  const xRange = [margin, width - margin];

  // Construct scales and axes.
  const xScale = d3.scaleLinear([xDomain[0] ?? 0, xDomain[1] ?? 0], xRange);

  // If any dot is a cluster, non-cluster dots should have reduced size
  const clusterView = dots.some((dot) => dot.subjects.length > 1);
  const R = dots.map((dot) => {
    if (dot.subjects.length === 1 && clusterView) {
      return computeRadius(1);
    }
    return computeRadius(dot.size);
  });

  // Compute the y-positions.
  const Y = dodge(
    dots.map((dot) => xScale(dot.value)),
    R,
  );

  dots.forEach((dot: Dot, i: number) => {
    for (const tier of tiers) {
      if (dot.value >= tier.threshold) {
        return Object.assign(dot, {
          x: xScale(dot.value),
          y: Y[i],
          radius: R[i],
          color: tier.color,
          strength: tier.name,
        });
      }
    }
  });

  const graphHeight =
    d3.max(dots.map((dot: Dot) => (dot.y ?? 0) + (dot.radius ?? 0))) ??
    0 -
      (d3.min(dots.map((dot: Dot) => (dot.y ?? 0) - (dot.radius ?? 0))) ?? 0) +
      margin * 2;
  const height = Math.max(graphHeight, minHeight);

  const svg = d3.select(svgRef.current).attr('height', height);
  svg.selectAll('*').remove();

  const TooltipOffset = 8;

  const tooltip = d3
    .select(containerRef.current)
    .append('div')
    .style('z-index', -1)
    .style('opacity', 0)
    .style('position', 'absolute')
    .style('border', 'solid')
    .style('background-color', theme.palette.common.white)
    .style('border-width', '1px')
    .style('border-color', theme.palette.primary[500])
    .style('border-radius', '5px')
    .style('padding', theme.spacing(4))
    .style(
      'box-shadow',
      '0px 4px 6px -1px rgba(155, 160, 166, 0.1), 0px 2px 4px -1px rgba(155, 160, 166, 0.06)',
    );

  const pointerOver = (event: React.PointerEvent, dot: Dot) => {
    tooltip
      .html(
        renderToString(
          <BeeSwarmTooltip dot={dot} subjectType={subjectType} scoreType={scoreType} />,
        ),
      )
      .style('z-index', 1)
      .style('opacity', 1)
      .style('left', `${event.pageX + TooltipOffset}px`)
      .style('top', `${event.pageY + TooltipOffset}px`);
  };

  const pointerMove = (event: React.PointerEvent, _dot: Dot) => {
    tooltip
      .style('left', `${event.pageX + TooltipOffset}px`)
      .style('top', `${event.pageY + TooltipOffset}px`);
  };

  const pointerLeave = (_event: React.PointerEvent, _dot: Dot) => {
    tooltip.style('z-index', -1).style('opacity', 0);
  };

  if (transition) {
    svg
      .append('g')
      .selectAll('circle')
      .data(dots)
      .join('circle')
      .attr('fill', (dot: Dot) => dot.color ?? 'none')
      .attr('r', (dot: Dot) => dot.radius ?? 0)
      .attr('cx', centerX)
      .attr('cy', centerY)
      .on('pointerover', pointerOver)
      .on('pointermove', pointerMove)
      .on('pointerleave', pointerLeave)
      .transition()
      .ease(d3.easeBounceOut)
      .duration(TRANSITION_DURATION)
      .attr('cx', (dot: Dot) => dot.x ?? 0)
      .attr('cy', (dot: Dot) => height / 2 + (dot.y ?? 0));
  } else {
    svg
      .append('g')
      .selectAll('circle')
      .data(dots)
      .join('circle')
      .attr('fill', (dot: Dot) => dot.color ?? 'none')
      .attr('r', (dot: Dot) => dot.radius ?? 0)
      .attr('cx', (dot: Dot) => dot.x ?? 0)
      .attr('cy', (dot: Dot) => height / 2 + (dot.y ?? 0))
      .on('pointerover', pointerOver)
      .on('pointermove', pointerMove)
      .on('pointerleave', pointerLeave);
  }
};

const retractDots = (svgRef: any) => {
  if (!svgRef?.current) {
    return null;
  }
  const centerX = svgRef.current.parentElement.offsetWidth / 2;
  const centerY = svgRef.current.parentElement.offsetHeight / 2;
  const svg = d3.select(svgRef.current);
  svg
    .selectAll('circle')
    .transition()
    .ease(d3.easeCircleOut)
    .duration(TRANSITION_DURATION)
    .attr('cx', centerX)
    .attr('cy', centerY);
};

const BeeSwarm = ({ subjectType, subScores = [], score, tab, loading }: Props) => {
  const classes = useStyles();
  const svgRef = useRef(null);
  const containerRef = useRef(null);
  const scoreRating = useMemo(() => {
    if (score) {
      for (const tier of tiers) {
        if (score.value >= tier.threshold) {
          return tier.name;
        }
      }
    }
  }, [score]);
  const tieredBuckets: TieredBucket[] = useMemo(() => {
    const tieredBuckets = tiers.map((tier) => {
      return {
        threshold: tier.threshold,
        count: 0,
        percentage: 0,
        name: tier.name,
        color: tier.color,
      };
    });
    if (subScores) {
      for (const score of subScores) {
        for (const tier of tieredBuckets) {
          if ((score.value ?? 0) >= tier.threshold) {
            tier.count++;
            break;
          }
        }
      }
      tieredBuckets.forEach((tier) => {
        tier.percentage = (tier.count / subScores.length) * 100;
      });
    }
    return tieredBuckets;
  }, [subScores]);
  useEffect(() => {
    retractDots(svgRef);
    setTimeout(
      () =>
        score &&
        renderSvg({
          subjectType,
          subScores,
          svgRef,
          containerRef,
          scoreType: score.scoreType,
          tab,
          transition: true,
        }),
      TRANSITION_DURATION,
    );
  }, [subScores]);
  useEffect(() => {
    const onResize = () => {
      score &&
        renderSvg({
          subjectType,
          subScores,
          svgRef,
          containerRef,
          scoreType: score?.scoreType,
          tab,
          transition: false,
        });
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  });
  return (
    <>
      {score && !loading ? (
        <Box style={{ marginBottom: theme.spacing(5) }}>
          <Typography className={classes.scoreBig}>{_.startCase(scoreRating)}</Typography>
          {score.trend === 'Rising' ? (
            <TrendingUp className={classes.trendIcon} />
          ) : score.trend === 'Falling' ? (
            <TrendingDown className={classes.trendIcon} />
          ) : (
            <TrendingFlat className={classes.trendIcon} />
          )}
        </Box>
      ) : (
        <Skeleton height={50} width={200} />
      )}
      <Grid container justifyContent={'space-between'}>
        <PercentageRowItem
          tieredBuckets={tieredBuckets}
          strength='weak'
          score={score}
          loading={loading}
        />

        <PercentageRowItem
          tieredBuckets={tieredBuckets}
          strength='low'
          score={score}
          loading={loading}
        />

        <PercentageRowItem
          tieredBuckets={tieredBuckets}
          strength='moderate'
          score={score}
          loading={loading}
        />

        <PercentageRowItem
          tieredBuckets={tieredBuckets}
          strength='strong'
          score={score}
          loading={loading}
          isLast
        />
      </Grid>
      <Box className={classes.graphContainer}>
        <Grid container alignItems={'flex-end'} spacing={1}>
          <Grid item xs={1} style={{ display: 'flex', flexDirection: 'column' }}>
            <LongArrowLeft />
            <Typography className={classes.arrowText}>WEAK</Typography>
          </Grid>
          <Grid item xs={10}>
            {subScores && !loading ? (
              <div ref={containerRef}>
                <svg className={classes.beeSwarmGraph} ref={svgRef} />
              </div>
            ) : (
              <div className={classes.beeSwarmGraph}>
                <LoadingIndicator />
              </div>
            )}
          </Grid>
          <Grid item xs={1} style={{ display: 'flex', flexDirection: 'column' }}>
            <LongArrowRight style={{ alignSelf: 'flex-end' }} />
            <Typography className={classes.arrowText} style={{ alignSelf: 'flex-end' }}>
              STRONG
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {!loading && (
        <Grid container justifyContent={'center'} spacing={3}>
          {tieredBuckets &&
            tieredBuckets
              .slice()
              .reverse()
              .map((tier) => {
                return (
                  <Grid key={tier.name} item>
                    <span
                      className={classes.beeSwarmLegendDot}
                      style={{
                        backgroundColor: tier.color,
                      }}
                    />
                    <Typography
                      className={classes.beeSwarmLegendText}
                    >{`${tier.name}`}</Typography>
                  </Grid>
                );
              })}
        </Grid>
      )}
    </>
  );
};

const NUM_LOADING_DOTS = 9;

const PercentageRowItem = ({
  loading = false,
  score,
  tieredBuckets,
  strength,
  isLast,
}: {
  loading?: boolean;
  score?: ScoreDetailsInsightsScoreFragment;
  tieredBuckets: TieredBucket[];
  strength: 'weak' | 'low' | 'moderate' | 'strong';
  isLast?: boolean;
}) => {
  const classes = useStyles();
  return (
    <Grid item xs css={{ marginRight: isLast ? theme.spacing(0) : theme.spacing(4) }}>
      <Typography variant='caption' className={classes.metricsHeader}>
        {score && !loading ? (
          `${_.startCase(strength)} ${SCORE_TYPE_PHRASE_MAP[score.scoreType]}`
        ) : (
          <Skeleton height={30} width={250} />
        )}
      </Typography>
      <div>
        <Typography className={classes.metricsPercentage}>
          {loading ? (
            <Skeleton />
          ) : (
            <FormatPercentage
              percentage={
                tieredBuckets.find((tier) => tier.name === strength)?.percentage
              }
            />
          )}
        </Typography>
      </div>
    </Grid>
  );
};

const LoadingIndicator = () => {
  const classes = useStyles();

  const loadingDots = useMemo(() => {
    let dots: React.ReactNode[] = [];
    for (let i = 0; i < NUM_LOADING_DOTS; i++) {
      Math.random();
      // const angle = ((i + 0.5) / NUM_LOADING_DOTS) * (2 * Math.PI);

      dots.push(
        <div
          key={i}
          className={classes.loadingDotContainer}
          css={{
            animationName: loadingAnimation,
            animationDuration: `${Math.random() * 0.5 + 1}s`,
            animationTimingFunction: 'ease-in-out',
            animationDelay: `${Math.random() * 0.5}s`,
            animationDirection: Math.random() > 0.5 ? 'alternate' : 'alternate-reverse',
            animationIterationCount: 'infinite',
          }}
        >
          <Skeleton variant='circle' className={classes.loadingDot} />
        </div>,
      );
    }

    return dots;
  }, []);

  return (
    <div className={classes.loading} css={{ animation: `${loadingAnimationFade} 1.5s` }}>
      {loadingDots}
    </div>
  );
};

const loadingAnimationFade = keyframes`
  0%, 50% {
    opacity: 0;
  }
  100% {
    opacity: 1.0;
  }
`;

const loadingAnimation = keyframes`
  0% {
    left: 0;
  }
  100% {
    left: calc(100% - ${DOT_RADIUS * 2}px);
  }
`;

const useStyles = makeStyles({
  scoreBig: {
    fontSize: '30px',
    lineHeight: '36px',
    color: theme.palette.text.primary,
    display: 'inline-block',
  },
  trendIcon: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary[700],
    display: 'inline-block',
    verticalAlign: 'text-bottom',
  },
  graphContainer: {
    width: '100%',
    borderStyle: 'none none dashed none',
    borderColor: theme.palette.primary[300],
    marginBottom: '20px',
  },
  metricsHeader: {
    color: theme.palette.grey[700],
    fontWeight: 500,
  },
  metricsPercentage: {
    color: theme.palette.text.secondary,
    fontWeight: 700,
    fontSize: '40px',
    display: 'inline',
  },
  metricsText: {
    marginLeft: theme.spacing(3),
    color: theme.palette.primary[800],
    display: 'inline',
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 500,
  },
  beeSwarmGraph: {
    display: 'block',
    width: '100%',
    minHeight: 160,
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 160,
  },
  loadingDotContainer: {
    flex: 1,
    position: 'relative',
  },
  loadingDot: {
    position: 'absolute',
    width: DOT_RADIUS * 2,
    height: DOT_RADIUS * 2,
    animation: `${loadingAnimation.name} 1s linear`,
  },
  arrowText: {
    fontSize: '12px',
    lineHeight: '24px',
    fontWeight: 600,
    fontFamily: `'Roboto Mono', monospaced`,
    letterSpacing: '1px',
    color: theme.palette.primary[300],
  },
  beeSwarmLegendDot: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    borderRadius: '50%',
    display: 'inline-block',
  },
  beeSwarmLegendText: {
    textTransform: 'capitalize',
    marginLeft: theme.spacing(2),
    fontSize: '12px',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    fontWeight: 600,
    display: 'inline-block',
  },
});

export default BeeSwarm;
