// @ts-strict-ignore
import { Box, Button, ButtonGroup, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@mysteryco/design';
import InfoCircle from 'components/icons/InfoCircle';
import theme from 'theme';
import { OrganizationRole } from 'types';

const roleDescription = {
  Admin: 'Can make changes across your account',
  Coordinator: 'Can book and manage events',
  Member: 'Can join your events and provide feedback',
};

const RolePicker = ({
  onChange,
  value,
  roles,
  disabled = false,
  useNewStyles = false,
}: {
  onChange: (args: any) => void;
  value?: string;
  roles: OrganizationRole[];
  disabled?: boolean;
  useNewStyles?: boolean;
}) => {
  const originalStyles = useOriginalStyles();
  const newStyles = useStyles();
  const classes = useNewStyles ? newStyles : originalStyles;
  const getDescriptionById = (id: string) => {
    const role = roles.find((role) => role.id === id);
    return role ? roleDescription[role.name] : '';
  };
  return (
    <div className={classes.container}>
      <Typography variant='h5' className={classes.header}>
        User role
      </Typography>
      <Typography className={classes.subtitle}>Glue user access permissions</Typography>
      <ButtonGroup fullWidth size='large' className={classes.buttonGroup}>
        {roles.map((role) => (
          <Button
            key={role.id}
            className={classes.button}
            disableElevation
            variant={value === role.id ? 'contained' : 'outlined'}
            onClick={() => onChange(role.id)}
            disabled={disabled}
          >
            {role.name}
          </Button>
        ))}
      </ButtonGroup>
      {useNewStyles && !disabled && (
        <Box className={classes.helperText}>
          <InfoCircle size={16} color={colors.Purple800} />
          {getDescriptionById(value)}
        </Box>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    width: '100%',
    padding: 0,
  },
  header: {
    fontWeight: 500,
    fontSize: '1.125rem',
    lineHeight: 1.56,
    color: colors.Midnight,
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '.75rem',
    lineHeight: 1.33,
    color: colors.DarkGray,
  },
  buttonGroup: {
    padding: `${theme.spacing(3)} 0`,
    '& .MuiButton-root': {
      textTransform: 'none',
      padding: `${theme.spacing(2.5)} ${theme.spacing(4)}`,
      fontWeight: 500,
      fontSize: '.875rem',
      lineHeight: 1.43,
      border: `1px solid ${colors.LightGray}`,
      margin: 0,
      '&:hover': {
        background: colors.Purple50,
        color: colors.Main,
      },
      '&:focus': {
        background: colors.Purple50,
        color: colors.Main,
        borderColor: colors.Purple200,
      },
      '&.MuiButton-contained': {
        background: colors.Purple100,
        border: `1px solid ${colors.Purple300}`,
        color: colors.Main,
        '&:hover': {
          background: colors.Purple200,
        },
      },
      '&.Mui-disabled': {
        color: colors.DarkGray,
        background: colors.OffWhite,
        borderColor: colors.LightGray,
      },
    },
  },
  helperText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    fontWeight: 500,
    fontSize: '.75rem',
    lineHeight: 1.33,
    color: colors.Purple800,
  },
  button: {},
});

const useOriginalStyles = makeStyles({
  container: {
    width: '100%',
  },
  helperText: {},
  header: {
    fontWeight: 500,
    lineHeight: '32px',
  },
  subtitle: {
    lineHeight: '22px',
    fontSize: '16px',
    fontWeight: 500,
    marginBottom: '4px',
  },
  buttonGroup: {
    height: '48px',
    '& .MuiButton-contained': {
      background: '#F2E8F3',
      border: '1.5px solid #7B1783',
    },
  },
  button: {
    textTransform: 'none',
    '&.Mui-disabled': {
      color: colors.DarkGray,
    },
  },
});

export default RolePicker;
