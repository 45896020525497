import { css } from '@emotion/react';
import theme from 'theme';
import { Maybe } from 'types';

interface EdgeCaseScreenProps {
  primary: string;
  logoUrl?: Maybe<string>;
}
const SUPPORT_FOOTER =
  'If you believe this is an error, email us at support@trymystery.com';

export const EdgeCaseScreen = ({ primary, logoUrl }: EdgeCaseScreenProps) => {
  return (
    <div css={styles.outerContainer}>
      <div css={styles.container}>
        <div css={styles.content}>
          {logoUrl && (
            <div>
              <img css={{ maxWidth: 350 }} src={logoUrl} alt='Company logo' />
            </div>
          )}
          <div css={styles.primary}>{primary}</div>
          <div css={styles.supportFooter}>{SUPPORT_FOOTER}</div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  brandImage: css({
    height: '25px',
  }),
  container: css({
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  content: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(8),
    width: '320px',
    '@media (min-width: 600px)': {
      width: '440px',
    },
    '@media (min-width: 960px)': {
      width: '600px',
    },
  }),
  outerContainer: css({
    marginTop: theme.spacing(8),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }),
  primary: css({
    lineHeight: '120%',
    marginTop: theme.spacing(5),
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '32px',
    '@media (min-width: 960px)': {
      fontWeight: 400,
      fontSize: '32px',
    },
  }),
  supportFooter: css({
    marginTop: theme.spacing(5),
  }),
};
