import { Box } from '@material-ui/core';
import NumericalMetric from 'components/Metric/Numerical';
import { goalToScoreType } from 'lib/helpers/goals';
import theme from 'theme';
import { InvitedGuestRsvpStatus, ScoreDetailsTeamFragment, ScoreType } from 'types';
import { EventStrategyTeamEvent } from '.';

interface Props {
  fullOrgView: boolean;
  scoreType: ScoreType;
  teamEvents: EventStrategyTeamEvent[];
  teams: ScoreDetailsTeamFragment[];
}

const EventsStrategyStatistics = ({
  fullOrgView,
  scoreType,
  teamEvents,
  teams,
}: Props) => {
  const eventsForScoreType = teamEvents?.filter((teamEvent) => {
    if (scoreType === ScoreType.SenseOfBelonging) {
      return true;
    }
    return teamEvent.goals.some((goal) => goalToScoreType(goal.goal) === scoreType);
  });

  const teamEventAcceptedGuests = eventsForScoreType?.map(
    (te) =>
      new Set(
        te.invitedGuests
          ?.filter((guest) => guest.rsvpStatus === InvitedGuestRsvpStatus.Accepted)
          ?.map((guest) => guest.user?.id),
      ),
  );

  const invitations = eventsForScoreType?.flatMap((teamEvent) => teamEvent.invitedGuests);

  return (
    <Box display='flex' flexDirection='row' justifyContent='space-between'>
      <Box flex={1}>
        <NumericalMetric<ScoreDetailsTeamFragment>
          title={`${fullOrgView ? 'Org' : 'Team'} participation in events`}
          data={teams}
          filter={(team) =>
            team.members.some((member) =>
              teamEventAcceptedGuests.some((set) => set.has(member.id)),
            )
          }
          subtitleSubject='team'
          subtitlePredicate='are part of events'
          pillSubject='participation'
        />
      </Box>
      <Box borderLeft={`1px solid ${theme.palette.primary[100]}`} flex={1}>
        <Box marginLeft='34px'>
          <NumericalMetric<Props['teamEvents'][0]['invitedGuests'][0]>
            title='Average event attendance'
            data={invitations}
            filter={(invite) => invite.rsvpStatus === InvitedGuestRsvpStatus.Accepted}
            subtitleSubject='RSVP'
            subtitlePredicate='accepted'
            pillSubject='attendance'
          />
        </Box>
      </Box>
    </Box>
  );
};

export default EventsStrategyStatistics;
