import { css } from '@emotion/react';
import GlueyFace, { GlueyFaceName } from 'glue/components/glueyFace/GlueyFace';
import GlueyHead from 'glue/components/glueyFace/GlueyHead';
import theme from 'theme';
import { colors } from '@mysteryco/design';

export interface PrivacyShieldProps {
  className?: string;
}

const strings = {
  message: 'Privacy shield is on',
  subMessage: 'There are not enough members to disclose answers',
};

export function PrivacyShield({ className }: PrivacyShieldProps) {
  return (
    <div css={styles.root} className={className}>
      <GlueyHead>
        <GlueyFace face={GlueyFaceName.Wink} css={styles.face} />
      </GlueyHead>
      <span css={styles.message}>{strings.message}</span>
      <span css={styles.subMessage}>{strings.subMessage}</span>
    </div>
  );
}

export default PrivacyShield;

const styles = {
  root: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(10),
    gap: theme.spacing(4),
  }),
  message: css({
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(28),
    color: colors.Coal_10,
  }),
  subMessage: css({
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(28),
    color: colors.Coal_20,
  }),
  face: css({
    color: colors.Coal_10,
  }),
};
