import { TeamEventStatus } from 'types';

export const teamEventStatusMap = {
  [TeamEventStatus.Canceled]: 'Canceled',
  [TeamEventStatus.Expired]: 'Expired',
  [TeamEventStatus.Requested]: 'Upcoming',
  [TeamEventStatus.Scheduled]: 'Upcoming',
  [TeamEventStatus.InProgress]: 'In Progress',
  [TeamEventStatus.Complete]: 'Completed',
};

export const eventStatusToString = (teamEventStatus: TeamEventStatus): string => {
  return (
    teamEventStatusMap[teamEventStatus] || teamEventStatusMap[TeamEventStatus.Complete]
  );
};

export const stringToEventStatuses = (statusString: string): TeamEventStatus[] => {
  return Object.keys(teamEventStatusMap)
    .map((key) => key as TeamEventStatus)
    .filter((key) => teamEventStatusMap[key] === statusString);
};
