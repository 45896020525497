import { css } from '@emotion/react';
import { colors } from '@mysteryco/design';
import Coins01 from '@mysteryco/design/icons/Coins01';
import MessagePlusCircle from '@mysteryco/design/icons/MessagePlusCircle';
import BillingPlaceholder from 'glue/assets/artwork/billing-placeholder-2.png';
import TextLink from 'glue/components/buttons/TextLink';
import { aside } from 'glue/components/cards/sharedStyles';
import AsideHeader from 'glue/components/structure/AsideHeader';
import SectionHeader from 'glue/components/structure/SectionHeader';
import {
  formatContractUnit,
  getContractIcon,
  getContractUnit,
} from 'lib/helpers/contract';
import { centsToDollarString } from 'lib/helpers/money';
import { DateTime } from 'luxon';
import theme from 'theme';
import {
  BillingContractInfoFragment,
  ContractUnitType,
  ViewerFragmentFragment,
} from 'types';
import { openHubspotChat } from 'utils/hubspotUtil';
import BillingCard, {
  BillingAside,
  BillingCell,
  BillingDetails,
  BillingHeader,
  BillingSection,
  BillingTitle,
} from '../components/BillingCard';
import ArrowRight from '@mysteryco/design/icons/ArrowRight';
import Button from 'glue/components/buttons/Button';
import { gql } from '@apollo/client';
import { pluralize } from 'humanize-plus';
import { ContractInfo, getContractName } from 'utils/contract';

const formatDate = (value: string) =>
  DateTime.fromISO(value).toLocaleString(DateTime.DATE_MED);

const formatNumber = (value: number) => new Intl.NumberFormat('en-US').format(value);

const EmptyState = () => {
  return (
    <div css={emptyStateStyles.root}>
      <div css={emptyStateStyles.imageContainer}>
        <img src={BillingPlaceholder} alt='' css={emptyStateStyles.image} />
      </div>
      <div css={emptyStateStyles.header}>
        <h2 css={emptyStateStyles.headline}>Glue is your solution for a happier team</h2>
        <p css={emptyStateStyles.description}>
          Get access to volume discounts for team events, connection and engagement
          insights.
        </p>
        <Button
          shape='round'
          color='green'
          onClick={openHubspotChat}
          endIcon={<ArrowRight size={24} color='currentColor' />}
        >
          Contact us
        </Button>
      </div>
    </div>
  );
};

const Support = () => {
  return (
    <div css={styles.aside}>
      <AsideHeader>Support</AsideHeader>
      <div css={styles.asideCard}>
        <div css={styles.asideCell}>
          <header css={styles.asideInternalHeader}>Need a bigger events budget?</header>
          <p>It's easy to add more event credits to your account.</p>
        </div>
        <TextLink Icon={MessagePlusCircle} onClick={openHubspotChat}>
          Contact us
        </TextLink>
      </div>
    </div>
  );
};

const DateInfo = ({
  startDate,
  renewalDate,
}: {
  startDate: string;
  renewalDate: string;
}) => {
  return (
    <BillingSection>
      <BillingCell>
        <BillingHeader>Start date</BillingHeader>
        <BillingDetails>{formatDate(startDate)}</BillingDetails>
      </BillingCell>
      <BillingCell>
        <BillingHeader>Auto-renews on</BillingHeader>
        <BillingDetails>{formatDate(renewalDate)}</BillingDetails>
      </BillingCell>
    </BillingSection>
  );
};

const PrimaryUnitInfo = ({ contract }: { contract: BillingContractInfoFragment }) => {
  const type = contract.type;
  const Icon = getContractIcon(type);
  const units = getContractUnit(type);
  const amount = centsToDollarString(contract.unitCostCents || 0);
  const showAddMore = type !== ContractUnitType.Cents && !!contract.unitCostCents;
  return (
    <>
      <BillingCell>
        <BillingHeader>{units} remaining</BillingHeader>
        <BillingDetails>
          <Icon color={colors.Glue_Ink10} size={24} />
          {formatContractUnit(type, contract.unitCount)}
        </BillingDetails>
      </BillingCell>
      <BillingCell>
        <BillingHeader>{units} pending</BillingHeader>
        <BillingDetails>
          {contract.pendingUnitCount
            ? formatContractUnit(type, contract.pendingUnitCount)
            : 'None'}
        </BillingDetails>
      </BillingCell>
      {showAddMore && (
        <BillingAside>
          Add more {units} for {amount} each when you book events
        </BillingAside>
      )}
    </>
  );
};

const PremiumUnitInfo = ({ contract }: { contract: BillingContractInfoFragment }) => {
  const amount = centsToDollarString(contract.premiumUnitCostCents || 0);
  return (
    <BillingCard>
      <BillingCell>
        <BillingHeader>Supply & upgrade credits remaining</BillingHeader>
        <BillingDetails>
          <Coins01 color={colors.Glue_Ink10} size={24} />
          {formatNumber(contract.premiumUnitCount || 0)}
        </BillingDetails>
      </BillingCell>
      <BillingCell>
        <BillingHeader>Supply & upgrade credits credits pending</BillingHeader>
        <BillingDetails>
          {contract.pendingPremiumUnitCount
            ? formatNumber(contract.pendingPremiumUnitCount)
            : 'None'}
        </BillingDetails>
      </BillingCell>
      <BillingAside>Add more credits for {amount} each when you book events</BillingAside>
    </BillingCard>
  );
};

const CostInfo = ({ contract }: { contract: BillingContractInfoFragment }) => {
  const showPremium =
    (contract?.type === ContractUnitType.Recurring ||
      contract?.type === ContractUnitType.Event) &&
    !!contract?.premiumUnitCount;
  return (
    <BillingSection>
      <PrimaryUnitInfo contract={contract} />
      {showPremium && <PremiumUnitInfo contract={contract} />}
    </BillingSection>
  );
};

const UserInfo = ({ contract }: { contract: BillingContractInfoFragment }) => {
  const users = contract.authorizedUsers.filter((user) => !user?.isServiceAccount);
  return (
    <BillingSection>
      <div css={{ gridColumn: 'span 2' }}>
        <BillingCell>
          <BillingHeader>Authorized users</BillingHeader>
          {users
            .map((user) => user?.name)
            .filter((name) => !!name)
            .join(', ')}
        </BillingCell>
      </div>
    </BillingSection>
  );
};

const ContractCard = ({ contract }: { contract: BillingContractInfoFragment }) => {
  return (
    <BillingCard>
      <BillingTitle>{getContractName(contract as ContractInfo)}</BillingTitle>
      <DateInfo startDate={contract.startDate} renewalDate={contract.renewDate} />
      <CostInfo contract={contract} />
      <UserInfo contract={contract} />
    </BillingCard>
  );
};

const Billing = ({ viewer }: { viewer: ViewerFragmentFragment }) => {
  const contracts = viewer.contracts as BillingContractInfoFragment[];
  return (
    <div css={styles.root}>
      {contracts.length >= 1 ? (
        <>
          <div css={styles.left}>
            <div css={styles.section}>
              <SectionHeader title={pluralize(contracts.length, 'Your event budget')} />
              {contracts.map((contract) => (
                <ContractCard contract={contract} />
              ))}
            </div>
          </div>
          <div css={styles.right}>
            <Support />
          </div>
        </>
      ) : (
        <div css={styles.left}>
          <EmptyState />
        </div>
      )}
    </div>
  );
};

Billing.fragments = {
  contract: gql`
    fragment BillingContractInfo on Contract {
      id
      name
      unitCount
      unitCostCents
      pendingUnitCount
      premiumUnitCount
      premiumUnitCostCents
      pendingPremiumUnitCount
      renewDate
      startDate
      status
      type
      authorizedUsers {
        id
        name
        isServiceAccount
      }
    }
  `,
};

const styles = {
  root: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyItems: 'flex-start',
    padding: `${theme.spacing(10)} 0 0 0`,
    gap: theme.spacing(20),
  }),
  left: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: 0,
    gap: theme.spacing(15),
    width: '45rem',
  }),
  right: css({
    position: 'sticky',
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: 0,
    gap: theme.spacing(6),
    width: '17.5rem',
  }),
  section: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: 0,
    gap: theme.spacing(8),
  }),
  header: css({
    fontFamily: 'inherit',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1.75rem',
    lineHeight: 1.4,
    color: colors.Glue_Ink00,
  }),
  row: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyItems: 'stretch',
    flexWrap: 'wrap',
    padding: 0,
    gap: theme.spacing(8),
  }),
  aside: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    gap: theme.spacing(5),
  }),
  asideCard: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 24,
    gap: theme.spacing(4),
    ...aside.root,
  }),
  asideCell: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    gap: theme.spacing(2),
    fontFamily: 'inherit',
    fontWeight: 500,
    fontSize: '.875rem',
    lineHeight: 1.4,
    color: colors.Glue_Ink00,
  }),
  asideInternalHeader: css({
    fontWeight: 800,
    fontSize: '1rem',
    lineHeight: 1,
  }),
};

const emptyStateStyles = {
  root: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    gap: theme.spacing(5),
  }),
  imageContainer: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `${theme.spacing(7)} 0`,
    background: colors.Glue_LavenderLight,
    width: '100%',
  }),
  image: css({
    width: '100%',
  }),
  content: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    gap: theme.spacing(6),
  }),
  header: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    gap: theme.spacing(2),
  }),
  headline: css({
    fontWeight: 500,
    fontSize: '1.75rem',
    lineHeight: 1.4,
    color: colors.Glue_Ink00,
    margin: 0,
  }),
  description: css({
    fontWeight: 800,
    fontSize: '1rem',
    lineHeight: 1.4,
    color: colors.Glue_Ink10,
  }),
};

export default Billing;
