// @ts-strict-ignore
import { Box, Breadcrumbs, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Dusk, MediumGray } from '@mysteryco/design/src/tokens/colors';
import { Loading, NotFoundError } from 'components/core';
import UpgradeButton from 'components/UpgradeButton';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  CustomerType,
  TeamEventStatus,
  useGetTeamEventQuery,
  ViewerFragmentFragment,
} from 'types';
import { getEventDetails, getGuests } from 'utils/state';
import EventSummary from './EventSummary';
import EventMetrics from './EventMetrics';
import EventDetails from './EventDetails';
import { Helmet } from 'react-helmet-async';
import { PageMetaData } from 'Routes';
import { isUpsellable } from 'utils/customerTypeUtils';

const BookedEvent = ({ viewer }: { viewer: ViewerFragmentFragment | undefined }) => {
  const { id: teamEventId } = useParams<{ id: string }>();
  const classes = useStyles();
  const { data, loading, refetch } = useGetTeamEventQuery({
    variables: { id: teamEventId, includeBilling: true },
  });
  const teamEvent = data?.teamEvent;

  useEffect(() => {
    mixpanel.track('booked event loaded', {
      customerType: viewer?.customerType,
      firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
    });
    refetch();
  }, [teamEventId]);

  if (loading) return <Loading />;
  if (!teamEvent) return <NotFoundError />;

  const { title } = getEventDetails({
    teamEvent,
    viewer,
    defaultTitle: 'Event Title',
  });

  const guests = getGuests(teamEvent);

  const invalidStatuses = [TeamEventStatus.Canceled, TeamEventStatus.Expired];
  const isValidEventStatus = !invalidStatuses.includes(teamEvent?.status);

  const metricsEnabledCustomers = [
    CustomerType.EventsAndInsights,
    CustomerType.EventsOnly,
  ];
  const showUpsellMetrics = !metricsEnabledCustomers.includes(viewer?.customerType);
  const canUpsell = isUpsellable(viewer?.customerType);

  return (
    <Box className={classes.fullPage}>
      <Helmet>
        <title>{PageMetaData.EVENTS.title}</title>
      </Helmet>
      <Box className={classes.pageHeader}>
        <Box>
          <Typography variant='h3'>{title}</Typography>
          <Breadcrumbs aria-label='breadcrumb' className={classes.breadcrumbs}>
            <Link href='/events'>Events</Link>
            <Typography>Event Details</Typography>
          </Breadcrumbs>
        </Box>
        {canUpsell && <UpgradeButton page='events' />}
      </Box>
      <Box className={classes.mainWrapper}>
        <Box className={classes.eventDetailsContent}>
          <Box className={classes.summaryColumn}>
            <EventSummary teamEvent={teamEvent} viewer={viewer} />
            <Box my={8}>
              <EventDetails teamEvent={teamEvent} guests={guests} />
            </Box>
          </Box>
          {isValidEventStatus && (
            <Box className={classes.metricsColumn}>
              <EventMetrics
                teamEvent={teamEvent}
                viewer={viewer}
                showUpsell={showUpsellMetrics}
                canUpsell={canUpsell}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    margin: `0 auto ${theme.spacing(5)}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    width: 'calc(100% - 48px)',
    maxWidth: '1600px',
    '& h3': {
      fontWeight: 300,
      color: theme.palette.grey[900],
      letterSpacing: '-1px',
    },
  },
  breadcrumbs: {
    '& li': {
      fontSize: '.875rem',
      color: MediumGray,
      '& .MuiTypography-root': {
        fontSize: '.875rem',
        fontWeight: theme.typography.fontWeightBold,
        color: MediumGray,
      },
      '& a.MuiLink-root': {
        textDecoration: 'underline',
        color: Dusk,
      },
    },
  },
  mainWrapper: {
    height: 'calc(100% - 32px)',
    padding: theme.spacing(5),
    borderTop: `1px solid ${theme.palette.primary[200]}`,
    backgroundColor: theme.palette.primary[50],
  },
  eventDetailsContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(20),
    marginTop: theme.spacing(6),
    justifyContent: 'center',
  },
  metricsColumn: {
    flexShrink: 0,
    width: 360,
  },
  summaryColumn: {
    flexGrow: 1,
    maxWidth: '680px',
  },
  selectedImage: {
    width: '100%',
    height: 'auto',
  },
  eventDetails: {
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  fullPage: {
    padding: '2rem',
  },
}));

export default BookedEvent;
