import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import {
  LightGray,
  Main,
  Midnight,
  White,
} from '@mysteryco/design/src/tokens/colors';

const EventCalendarImage = ({ dateTime }: { dateTime: DateTime }) => {
  const classes = useStyles();

  const eventMonth = dateTime.toFormat('MMM');
  const eventDay = dateTime.toFormat('dd');

  return (
    <Box className={classes.eventCalendarImage}>
      <Typography className={classes.eventCalendarImageMonth}>{eventMonth}</Typography>
      <Typography className={classes.eventCalendarImageDate}>{eventDay}</Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  eventCalendarImage: {
    display: 'flex',
    flexDirection: 'column',
    height: 70,
    width: 64,
    flexShrink: 0,
    textAlign: 'center',
    padding: '12px 16px',
    borderRadius: '4px',
    border: `1px solid ${LightGray}`,
    background: White,
    position: 'relative',
    overflow: 'hidden',
    '&:before': {
      content: '""',
      display: 'block',
      width: '100%',
      height: 4,
      background: Main,
      position: 'absolute',
      top: 0,
      left: 0,
    },
  },
  eventCalendarImageMonth: {
    color: Main,
    textTransform: 'uppercase',
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: theme.typography.fontWeightMedium,
  },
  eventCalendarImageDate: {
    color: Midnight,
    fontSize: 24,
    lineHeight: '24px',
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export default EventCalendarImage;
