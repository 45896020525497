// @ts-strict-ignore
import { Box, Typography } from '@material-ui/core';
import withStyles, { ClassNameMap } from '@material-ui/core/styles/withStyles';
import AlertHexagon from '@mysteryco/design/icons/AlertHexagon';
import { colors } from '@mysteryco/design/src';
import TextInput from 'components/Forms/TextInput';
import { pluralize } from 'humanize-plus';
import theme from 'theme';
import { Team, User } from 'types';
import { defaultTeamName, isUsingDefaultTeamName } from 'utils/teamUtil';
import AutocompleteInput from './AutocompleteInput';
import FormNote from './FormNote';
import LabeledInput from './LabeledInput';

interface EditTeamProps {
  team: Team;
  teamOptions: Team[];
  onChange: (event: any, newValue: Team) => void;
  classes: any;
  members?: User[];
  membersLoading?: boolean;
  disableLabels?: boolean;
}

const isTeamDuplicate = (newTeam: Team, orgTeams: Team[]): boolean => {
  return orgTeams?.some((team) => team.id !== newTeam?.id && team.name === newTeam?.name);
};

const isTeamChanged = (newTeam: Team, orgTeams: Team[]): boolean => {
  const oldTeam = orgTeams?.find((team) => team.id === newTeam.id);
  return oldTeam && newTeam ? oldTeam.name !== newTeam.name : true;
};

export const isValidTeam = (newTeam: Team, orgTeams: Team[]): boolean => {
  return (
    (newTeam?.id || newTeam?.manager?.id) &&
    !isTeamDuplicate(newTeam, orgTeams) &&
    isTeamChanged(newTeam, orgTeams)
  );
};

export const teamFilterOptions = (options: Team[], query: string): Team[] => {
  if (!query?.trim()) return options;
  if (!options?.length) return [];
  const tokens = query.toLocaleLowerCase().split(' ');
  return options.filter((option) =>
    tokens.some(
      (token) =>
        option?.name.toLocaleLowerCase().includes(token) ||
        option?.manager?.name?.toLocaleLowerCase().includes(token),
    ),
  );
};

export const RenderTeam = ({ team, classes }: { team: Team; classes: ClassNameMap }) => {
  return (
    <Box className={classes.teamOption}>
      <Typography className={classes.teamOptionName}>{team?.name}</Typography>
      {!isUsingDefaultTeamName(team) && (
        <Typography className={classes.teamOptionManager}>
          {defaultTeamName(team)}
        </Typography>
      )}
    </Box>
  );
};

const EditTeam = ({
  team,
  teamOptions,
  onChange,
  classes,
  members = [],
  membersLoading,
  disableLabels = false,
}: EditTeamProps) => {
  const createMode = !team?.id && members.length;

  const handleNameChange = (e) => {
    onChange(e, { ...team, name: e.target.value });
  };

  const handleManagerChange = (e, newValue) => {
    onChange(e, { ...team, manager: newValue });
  };

  let nameError = '';
  if (isTeamDuplicate(team, teamOptions)) {
    nameError = 'There is already a team with this name';
  }

  return (
    <Box className={classes.activeContainer}>
      <Box className={classes.activeEdit}>
        {!disableLabels && (
          <Typography className={classes.activeHeader}>Team options</Typography>
        )}
        <Box className={classes.formGroup}>
          {createMode && (
            <LabeledInput
              label={!disableLabels ? 'Team manager' : ''}
              fullWidth
              disabled={membersLoading || !members.length}
              id='teamManager'
            >
              <AutocompleteInput
                options={members}
                getOptionLabel={(option) => ('name' in option ? option.name : '')}
                selectOnFocus
                placeholder='Select manager'
                value={team.manager}
                onChange={handleManagerChange}
                fullWidth
                disabled={membersLoading}
                autoFocus={createMode}
                id='teamManager'
                disablePortal
              />
            </LabeledInput>
          )}
          <LabeledInput
            label={!disableLabels ? 'Team name' : ''}
            error={nameError}
            fullWidth
            id='teamName'
          >
            <TextInput
              value={team.name || ''}
              onChange={handleNameChange}
              placeholder={defaultTeamName(team)}
              error={!!nameError}
              fullWidth
              autoFocus={!createMode}
              id='teamName'
            />
          </LabeledInput>
        </Box>
      </Box>
      {!createMode && (
        <FormNote icon={<AlertHexagon size={16} />}>{`Changing name for ${
          team?.manager?.name
        }'s team - ${team?.members?.length} ${pluralize(
          team?.members?.length,
          'direct report',
        )}`}</FormNote>
      )}
    </Box>
  );
};

// Casting flexDirection and textAlign:
// https://github.com/cssinjs/jss/issues/1344

export default withStyles({
  activeContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    gap: theme.spacing(2),
  },
  activeEdit: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    gap: theme.spacing(2),
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    gap: theme.spacing(4),
  },
  activeHeader: {
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: 2,
    color: colors.Dusk,
  },
  activeDeleteButton: {
    justifyContent: 'start',
    textAlign: 'left' as 'left',
    padding: `${theme.spacing(2)} 0`,
    color: colors.DarkGray,
    textDecorationLine: 'underline',
    gap: theme.spacing(2),
    transition: 'color 300ms ease-in-out',
    '&:hover': {
      color: colors.Main,
    },
  },
})(EditTeam);
