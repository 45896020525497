// @ts-strict-ignore
import _ from 'lodash';
import { DateTime } from 'luxon';
import {
  EventForSelection,
  GroupType,
  GuestsType,
  MatchedExperience,
} from 'types/selfServeFlow';

import {
  BookingOptionsPresentedInput,
  CostUnit,
  MysteryTemplate,
  ViewerFragmentFragment,
  TimeWindow,
  ContractFragmentFragment,
} from '../../types';
import { recursivelyRemoveTypenames } from './apollo';

export const SURPISE_TO_ALL_SEAT_MIN = 6;

export const shapeOptionsPresented = (
  availableOptions: MatchedExperience[],
): BookingOptionsPresentedInput[] => {
  const shaped = _.map(recursivelyRemoveTypenames(availableOptions), (exp) => {
    return {
      template: _.get(_.first(exp.templates), 'id'),
      experiences: [_.pick(exp, ['id'])],
    };
  });

  return shaped as any;
};

export const getActiveContract = (
  viewer: ViewerFragmentFragment,
): ContractFragmentFragment => {
  // TODO - here be a multi-org footgun - TW
  return _.get(_.first(_.get(viewer, 'orgs')), 'activeContract');
};

export const getMinimumEventSize = (
  template: Pick<MysteryTemplate, 'cost'>,
  isSurpriseToAll?: boolean,
): number => {
  if (isSurpriseToAll) {
    return SURPISE_TO_ALL_SEAT_MIN;
  }

  // Get the bands
  const bands = _.get(template, 'cost.ctcPricingBands');

  // Select the band who's type is .Event, ops knows to use this to spec the min user number
  const eventBand = _.find(bands, (band) => band.unit === CostUnit.Event);

  // Return that .Event band's maxUsers as the minimum for this event, or zero
  return eventBand ? eventBand.maxUsers : 0;
};

export const getTotalGuests = ({
  guests,
  groupsOnly,
}: {
  guests: GuestsType;
  groupsOnly?: boolean;
}) => {
  const groupsEmails = _.uniq(getEmailsOfGuests({ guests, groupsOnly: true }));
  if (groupsOnly) return _.size(groupsEmails);
  else return _.size(_.uniq(getEmailsOfGuests({ guests, groupsOnly: false })));
};

export const extractUsersFromGroups = ({ groups }: { groups: GroupType[] }) => {
  const users = [];
  groups.forEach((g) => users.push(...g.users));
  return _.uniqBy(users, 'email');
};

export const getEmailsOfGuests = ({
  guests,
  groupsOnly,
}: {
  guests: GuestsType;
  groupsOnly?: boolean;
}) => {
  const groups = guests?.groups || [];
  const otherGuests = guests?.otherGuests || [];
  const emailArray = [];
  groups.forEach((g) => emailArray.push(_.map(g.users, 'email')));
  if (groupsOnly) return _.flattenDeep(_.uniq(emailArray));
  otherGuests.forEach((g) => emailArray.push(g.email));
  return _.flattenDeep(_.uniq(emailArray));
};

export const availableEventTimesToWindows = ({
  availableEventTimes,
}: {
  availableEventTimes: EventForSelection[];
}): TimeWindow[] => {
  const sortedAvailableEventTimes = _.orderBy(
    availableEventTimes,
    (e) => new Date(e.start),
    ['desc'],
  );
  let windowStart;
  const windows = [];
  for (let i = 0; i < sortedAvailableEventTimes.length; i++) {
    if (!windowStart) {
      windowStart = DateTime.fromJSDate(new Date(availableEventTimes[i].start));
    }
    const currentStartTime = DateTime.fromJSDate(new Date(availableEventTimes[i].start));
    const currentEndTime = DateTime.fromJSDate(new Date(availableEventTimes[i].end));
    const nextStartTime = DateTime.fromJSDate(
      new Date(_.get(availableEventTimes[i + 1], 'start')),
    );
    if (
      !!nextStartTime &&
      nextStartTime > currentStartTime &&
      nextStartTime <= currentEndTime
    ) {
      continue;
    } else {
      windows.push({
        startTime: windowStart.toJSDate(),
        endTime: currentEndTime.toJSDate(),
      });
      windowStart = null;
    }
  }
  return windows;
};
