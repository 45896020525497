import { Box, Divider, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

interface TabNavProps {
  selectedTab: string;
  setSelectedTab: (newTab: string) => void;
  tabs: string[];
  variant?: 'scrollable' | 'fullWidth' | 'standard';
}

const TabNav = ({ selectedTab, setSelectedTab, tabs, variant }: TabNavProps) => {
  const classes = useStyles();
  return (
    <Box>
      <Tabs
        variant={variant || undefined}
        onChange={(e, val) => {
          window.location.hash = val;
          setSelectedTab(val);
        }}
        className={classes.tabs}
        value={selectedTab}
      >
        {tabs.map((tab, i) => (
          <Tab key={`tab-${i}`} className={classes.tab} label={tab} value={tab} />
        ))}
      </Tabs>
      <Divider />
    </Box>
  );
};

const useStyles = makeStyles({
  tabs: {
    '& .MuiTabs-flexContainer': {
      height: theme.spacing(10),
    },
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    '& .Mui-selected': {
      fontWeight: 600,
      color: theme.palette.text.primary,
    },
    '& .MuiTab-root': {
      paddingLeft: 0,
      paddingRight: 0,
      marginRight: theme.spacing(6),
      minWidth: 0,
      border: 'none',
    },
    '& .MuiTab-root:last-child': {
      marginRight: 0,
    },
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.secondary[500],
      height: '3px',
    },
  },
  tab: {
    '& .MuiTab-wrapper': {
      justifyContent: 'left',
    },
    fontSize: '16px',
    fontFamily: 'inherit',
    fontWeight: 500,
    textTransform: 'none',
  },
});

export default TabNav;
