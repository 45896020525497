// @ts-strict-ignore
import { Box, Drawer, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import humanize from 'humanize-plus';
import {
  extractUsersFromGroups,
  getTotalGuests,
  getEmailsOfGuests,
} from 'lib/helpers/booking';
import _ from 'lodash';

import theme from 'theme';
import { User, UserTag, UserTagType } from 'types';
import { GroupType } from 'types/selfServeFlow';

import { CloseRounded } from '@material-ui/icons';
import FaceNegative from 'components/icons/preferences/FaceNegative';
import PrimaryButton from 'components/PrimaryButton';
import OrgSearchAndSelect, { OrgSearchAndSelectInterface } from './OrgSearchAndSelect';
import { gql } from '@apollo/client';
import { Audience, AudienceBuilder } from 'glue/components/inputs/audience';
import { guestToAudience } from '.';
import { Statsig } from 'statsig-react';
import TeamChip from 'components/Chips/TeamChip';

const Row = ({ children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.rowContainer}>
      <Box className={classes.rowInnerContainer}>{children}</Box>
    </Box>
  );
};

const GuestDrawer = ({
  setGuests,
  currentGuests,
  closeDrawer,
  searchTags,
  setSearchString,
  setSearchTags,
  setAudience,
  searchString,
  organization,
  attendeeEmails,
  emailsNotOnProvider,
  drawerOpen,
  cta,
  ctaText,
  ctaLoading,
}: {
  drawerOpen: boolean;
  closeDrawer(): void;
  attendeeEmails?: string[];
  emailsNotOnProvider?: string[];
  cta?(): void;
  ctaText?: string;
  ctaLoading?: boolean;
  setAudience(audience: Audience): void;
} & Partial<OrgSearchAndSelectInterface>) => {
  const classes = useStyles();
  const groups = currentGuests.groups;
  let otherGuests = currentGuests.otherGuests;

  const totalGuests = getTotalGuests({ guests: currentGuests });
  const groupGuestEmails = getEmailsOfGuests({ guests: currentGuests, groupsOnly: true });
  const totalGroupGuests = getTotalGuests({
    guests: currentGuests,
    groupsOnly: true,
  });
  const groupGuestEmailSet = new Set(groupGuestEmails);
  const allGuests = _.uniqBy(
    [...otherGuests, ...extractUsersFromGroups({ groups })],
    (guest) => guest.email,
  );
  const useAudienceBuilder = Statsig.checkGate('org_select_to_audience_builder');

  if (attendeeEmails) {
    // Order those who can not attend to be first in the list and remove if not on provider
    otherGuests = _.filter(
      _.orderBy(otherGuests, (g) => attendeeEmails.includes(g.email)),
      (g) => !emailsNotOnProvider.includes(g.email),
    );
  }

  const removeGuest = ({
    guest,
    group,
  }: {
    guest?: Partial<User>;
    group?: GroupType;
  }) => {
    const isGroup = !!group;
    const otherGuestsFiltered = isGroup
      ? otherGuests
      : _.filter(otherGuests, (g) => g.email !== guest.email);
    const groupsFiltered = isGroup ? _.filter(groups, (g) => g.id !== group.id) : groups;

    setGuests({
      otherGuests: otherGuestsFiltered,
      groups: groupsFiltered,
    });
  };

  return (
    <Drawer anchor='right' open={drawerOpen} style={{ position: 'relative' }}>
      <div className={classes.container}>
        <Box
          style={{
            position: 'absolute',
            top: theme.spacing(3),
            right: theme.spacing(3),
            cursor: 'pointer',
          }}
          onClick={() => closeDrawer()}
        >
          <CloseRounded style={{ fontSize: '2rem' }} />
        </Box>
        <Typography variant='h3' style={{ marginBottom: theme.spacing(2) }}>
          {totalGuests} Guests
        </Typography>

        {searchTags && (
          <>
            <Typography style={{ fontWeight: theme.typography.fontWeightMedium }}>
              {!!organization
                ? 'Add guests by group, name or email address'
                : 'Add guests by email'}
            </Typography>
            {useAudienceBuilder ? (
              <AudienceBuilder
                organizationId={organization.id}
                audience={guestToAudience(currentGuests)}
                setAudience={setAudience}
              />
            ) : (
              <OrgSearchAndSelect
                organization={organization}
                setGuests={setGuests}
                currentGuests={currentGuests}
                searchTags={searchTags}
                setSearchString={setSearchString}
                setSearchTags={setSearchTags}
                searchString={searchString}
              />
            )}
          </>
        )}
        <Box className={classes.contentContainer}>
          <Box>
            {!_.isEmpty(groups) && <Typography variant='h5'>Groups</Typography>}
            {_.map(groups, (group) => {
              return (
                <Row>
                  {useAudienceBuilder ? (
                    <>
                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant='body1' style={{ fontWeight: 500 }}>
                          {group.name}
                        </Typography>
                        <Box style={{ marginLeft: theme.spacing(1) }}>
                          <Typography variant='body2'>{`${_.size(
                            group.users,
                          )} members`}</Typography>
                        </Box>
                      </Box>
                      <Box
                        onClick={() => removeGuest({ group })}
                        className={classes.xContainer}
                      >
                        <Typography>x</Typography>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box style={{ display: 'flex' }}>
                        <TeamChip
                          automated={group.type === UserTagType.Automatic}
                          label={group.name}
                        />
                        <Box style={{ marginLeft: theme.spacing(1) }}>
                          <Typography variant='body2'>{`${_.size(
                            group.users,
                          )} members`}</Typography>
                        </Box>
                      </Box>
                      <Box
                        onClick={() => removeGuest({ group })}
                        className={classes.xContainer}
                      >
                        <Typography>x</Typography>
                      </Box>{' '}
                    </>
                  )}
                </Row>
              );
            })}
          </Box>
          <Box style={{ marginTop: theme.spacing(4) }}>
            {<Typography variant='h5'>All guests</Typography>}
            {
              <Typography variant='body2'>
                {!_.isEmpty(groupGuestEmails) &&
                  `Includes ${_.size(groupGuestEmails)} ${humanize.pluralize(
                    totalGroupGuests,
                    'guest',
                  )} added through groups`}
              </Typography>
            }
            <Box style={{ marginTop: theme.spacing(4) }}>
              {_.map(
                allGuests,
                (guest: { email: string; name: string; tags: UserTag[] }) => {
                  const canAttend = attendeeEmails
                    ? attendeeEmails.includes(guest.email)
                    : true;
                  return (
                    <Row>
                      <Box
                        className={classes.guestDetails}
                        style={{
                          color: !canAttend && theme.palette.primary.main,
                        }}
                      >
                        {' '}
                        {!canAttend && (
                          <FaceNegative color={theme.palette.primary.main} />
                        )}
                        <Typography
                          style={{
                            marginLeft: !canAttend ? theme.spacing(2) : 0,
                            whiteSpace: 'nowrap',
                          }}
                        >{`${guest.name}`}</Typography>
                        <Box className={classes.tagsWrapper}>
                          {_.map(guest.tags, (tag, i) => {
                            return (
                              <span className={classes.textTag}>
                                {tag.name}
                                <em>, </em>
                              </span>
                            );
                          })}
                        </Box>
                      </Box>
                      {!groupGuestEmailSet.has(guest.email) && (
                        <Box
                          onClick={() => removeGuest({ guest })}
                          className={classes.xContainer}
                        >
                          <Typography>x</Typography>
                        </Box>
                      )}
                    </Row>
                  );
                },
              )}
            </Box>
          </Box>
        </Box>
        {!!cta && (
          <Box
            style={{
              bottom: 0,
              width: 490,
              backgroundColor: theme.palette.common.white,
              height: 100,
              position: 'fixed',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <PrimaryButton
              style={{ width: '80%' }}
              label={ctaText || 'Done'}
              onClick={cta}
              loading={ctaLoading}
              disabled={totalGuests === 0}
            />
          </Box>
        )}
      </div>
    </Drawer>
  );
};
GuestDrawer.fragments = gql`
  fragment GuestDrawerOrganization on Organization {
    id
    name
    ...OrgSearchAndSelectOrganization
  }
  ${OrgSearchAndSelect.fragments}
`;

export default GuestDrawer;

const useStyles = makeStyles({
  container: {
    background: 'white',
    padding: theme.spacing(4),
    width: '100vw',
    paddingBottom: 100,
    [theme.breakpoints.up('md')]: {
      width: 500,
    },
  },
  contentContainer: {
    marginTop: theme.spacing(6),
    paddingBottom: theme.spacing(1),
    height: 'calc(100% - 115px)',
    overflowY: 'auto',
  },
  rowContainer: {
    //height: 45,
    display: 'flex',
    alignItems: 'center',
    padding: '12px 0',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  rowInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  guestDetails: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '4px',
    flexDirection: 'column',
  },
  tagsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '4px',
  },
  textTag: {
    color: theme.palette.primary.main,
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '20px',
    '&:last-of-type em': {
      display: 'none',
    },
  },
  xContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    cursor: 'pointer',
  },
});
