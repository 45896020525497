import { gql } from '@apollo/client';
import { css } from '@emotion/react';
import { colors } from '@mysteryco/design/src';
import { Loading } from 'components/core';
import format from 'date-fns/format';
import GlueyFace, { GlueyFaceName } from 'glue/components/glueyFace/GlueyFace';
import GlueyHead from 'glue/components/glueyFace/GlueyHead';
import TimeframeFilter from 'glue/scenes/Explore/Pulse/TimeframeFilter';
import { sentimentGraphics } from 'glue/scenes/Explore/Pulse/sentimentGraphics';
import theme from 'theme';
import {
  MoralePulseResponseSentiment,
  useQuestionFeedbackQuery,
  SummaryCommentDataFragment,
} from 'types';
import FiltersContainer from './FiltersContainer';
import {
  usePulseTimeframes,
  useSelectedTimeframeFilter,
} from 'glue/scenes/Explore/Pulse/timeframes';
import { RouteComponentProps } from 'react-router-dom';

const SummaryComment = ({ comment }: { comment: SummaryCommentDataFragment }) => (
  <div css={styles.comment}>
    <span css={styles.commentText}>{comment.comment}</span>
    <div css={styles.commentSubline}>
      {comment.sentiment && <CommentSentiment sentiment={comment.sentiment} />}
      <span css={styles.commentAnswer}>{comment.valueDescription}</span>
      {comment.answeredAt && (
        <span css={styles.commentDate}>
          {format(new Date(comment.answeredAt), 'MMM dd, yyyy')}
        </span>
      )}
    </div>
  </div>
);
SummaryComment.fragments = gql`
  fragment SummaryCommentData on MoralePulseAnswer {
    id
    comment
    valueDescription
    sentiment
    answeredAt
  }
`;

export function QuestionFeedback({ match }: RouteComponentProps<{ questionId: string }>) {
  const questionId = match.params.questionId;
  const [timeframe, setTimeframe] = useSelectedTimeframeFilter();
  const timeframes = usePulseTimeframes();

  const { data: feedbackData, loading } = useQuestionFeedbackQuery({
    variables: {
      questionId,
      timeframe: timeframe
        ? {
            startTime: timeframe.startDate,
            endTime: timeframe.endDate,
          }
        : undefined,
    },
  });
  const comments = feedbackData?.moralePulseQuestionSummary?.answers || [];

  return (
    <div css={styles.root}>
      <FiltersContainer>
        <TimeframeFilter onChange={setTimeframe} value={timeframe} options={timeframes} />
      </FiltersContainer>
      <div css={styles.byline}>
        <span>There are {comments.length} comments in total</span>
      </div>
      <div css={styles.comments}>
        {comments.map((comment) => (
          <SummaryComment key={comment.id} comment={comment} />
        ))}
        {!comments.length && !loading && <EmptyState />}
        {loading && <Loading />}
      </div>
    </div>
  );
}
QuestionFeedback.query = gql`
  query QuestionFeedback($questionId: ID!, $timeframe: DateWindowInput) {
    moralePulseQuestionSummary(questionId: $questionId) {
      id
      answers(onlyWithComments: true, timeframe: $timeframe) {
        id
        ...SummaryCommentData
      }
    }
  }
  ${SummaryComment.fragments}
`;

export default QuestionFeedback;

const CommentSentiment = ({ sentiment }: { sentiment: MoralePulseResponseSentiment }) => {
  const { Arrow, background, color } = sentimentGraphics[sentiment];

  return (
    <svg css={[styles.commentSentiment, { background, color }]} viewBox='0 0 24 24'>
      <Arrow stroke='currentColor' css={styles.commentSentimentArrow} />
    </svg>
  );
};

const EmptyState = () => {
  return (
    <div css={[styles.root, styles.empty]}>
      <GlueyHead>
        <GlueyFace face={GlueyFaceName.Wink} />
      </GlueyHead>
      <h2 css={styles.emptyTitle}>Nothing to see here</h2>
      <p css={styles.emptyDescription}>
        There is no feedback from your employees on this question
      </p>
    </div>
  );
};

const styles = {
  root: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
    maxWidth: '60rem',
  }),
  comments: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(12),
    marginBottom: theme.spacing(5),
  }),
  comment: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    width: '100%',
  }),
  commentSentiment: css({
    width: 32,
    height: 32,
    borderRadius: 10,
    flexShrink: 0,
  }),
  commentSentimentArrow: css({
    transform: 'translate(8px, 8px)',
  }),
  commentDate: css({
    color: colors.Glue_Ink10,
    fontSize: 14,
    lineHeight: '143%',
    marginLeft: 'auto',
  }),
  commentAnswer: css({
    color: colors.Glue_Ink10,
    fontSize: 14,
    fontWeight: 900,
    lineHeight: '143%',
  }),
  commentText: css({
    color: colors.Glue_Ink00,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '150%',
  }),
  commentSubline: css({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    alignItems: 'center',
  }),
  loadMoreButton: css({
    alignSelf: 'center',
  }),
  byline: css({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    justifyContent: 'flex-end',
    fontSize: 16,
    color: colors.Glue_Ink10,
    fontWeight: 500,
  }),
  empty: css({
    alignItems: 'center',
    width: '100%',
  }),
  emptyTitle: css({
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '140%',
    marginBottom: 0,
    color: '#3b3b3b',
  }),
  emptyDescription: css({
    color: '#706a74',
    lineHeight: '156%',
    fontSize: 18,
  }),
};
