import { SelfServeState } from 'types/selfServeFlow';
import { stringFromFormState } from './serialization';

export const redirectAndSaveSSFState = ({
  globalState,
  fromLoginStep,
  redirectToUrl,
  path,
}: {
  globalState: SelfServeState;
  fromLoginStep?: boolean;
  redirectToUrl: string;
  path?: string;
}): void => {
  const crushed = stringFromFormState(globalState);
  const urlToRedirectTo = new URL(redirectToUrl);
  const pathToComeBackTo = fromLoginStep ? '/book/guests' : path || window.location.pathname;
  const redirectUrl = new URL(`${window.location.origin}${pathToComeBackTo}`);

  redirectUrl.searchParams.append('session', crushed);
  urlToRedirectTo.searchParams.append('redirect', redirectUrl.toString());
  window.location.assign(urlToRedirectTo.toString());
};
