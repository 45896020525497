import { css } from '@emotion/react';
import theme from 'theme';

const MeetupsPage = ({ noSidePad = false, children }) => (
  <div css={styles.container(noSidePad)}>{children}</div>
);

const styles = {
  container: (removeSidePadding: boolean) =>
    css({
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: `${theme.spacing(15)}`,
      padding: removeSidePadding ? `2rem 0rem` : `${theme.spacing(15)}`,
    }),
};

export default MeetupsPage;
