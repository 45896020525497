import { css } from '@emotion/react';
import { Form as InternalForm, FormikFormProps } from 'formik';
import theme from 'theme';

export interface FormProps extends FormikFormProps {}

/**
 * This form automatically wires up to a parent Formik
 */
export function Form(props: FormProps) {
  return <InternalForm css={styles.form} {...props} />;
}

export default Form;

const styles = {
  // resetting to some default form styles based on general observation
  // of designs - these may not align with your form!
  form: css({
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: theme.spacing(4),
  }),
};
