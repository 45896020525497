import config from 'config';
import { doFetch } from 'lib/helpers/fetch';
import mixpanel from 'mixpanel-browser';
import theme from 'theme';

import { css } from '@emotion/react';
import FormikForm from 'glue/components/forms/FormikForm';
import FormikSubmitButton from 'glue/components/forms/FormikSubmitButton';
import FormikTextField from 'glue/components/forms/FormikTextField';
import { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import AuthContainer from './AuthContainer';
import PeopleAsideOne from 'glue/assets/artwork/people-aside-one.png';
import PeopleAsideTwo from 'glue/assets/artwork/people-aside-two.png';
import ArrowRight from '@mysteryco/design/icons/ArrowRight';
import { Coal_10, Coal_30, Coal_40, Plum_10 } from '@mysteryco/design/src/tokens/colors';
import Heart from '@mysteryco/design/icons/MessageHeartSquare';
import { SUPPORT_EMAIL } from 'utils/stringUtil';

const validationSchema = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
});

export const sendOTP = async ({ email }) => {
  return doFetch(`${config.api.url}/authEmail/token`, { email });
};

const EmailInputScreen = ({
  wizard,
  setAccountInfo,
  setIsNewUser,
  title,
  subTitle,
  org,
}) => {
  const emailInputRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (emailInputRef.current) {
      // @ts-ignore
      emailInputRef.current.focus();
    }
  }, [emailInputRef.current]);

  const handleSubmit = async (values) => {
    setLoading(true);

    const [data] = await Promise.all([
      sendOTP(values),
      new Promise((resolve) => setTimeout(resolve, 750)),
    ]);
    setLoading(false);
    mixpanel.track('user submitted email', { source: 'auth' });
    setAccountInfo((p) => ({ ...p, email: values.email }));

    if (!data.userExists) {
      mixpanel.track('new user in auth', { source: 'auth' });
      setIsNewUser(true);
      wizard.push('account-info');
    } else {
      mixpanel.track('user already exists', { source: 'auth' });
      setIsNewUser(false);
      wizard.push('otp-input');
    }
  };

  return (
    <AuthContainer
      title={title}
      subTitle={subTitle}
      leftIllustrationSrc={PeopleAsideOne}
      rightIllustrationSrc={PeopleAsideTwo}
      logoImage={org?.logoUrl}
      loading={loading}
      icon={<Heart size={24} color={Plum_10} />}
    >
      <div css={styles.formContainer}>
        <FormikForm
          initialValues={{ email: '' }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          css={styles.form}
        >
          <FormikTextField
            name='email'
            type='email'
            label='Company email'
            placeholder='micah.evans@polymr.co'
            autoFocus
          />
          <FormikSubmitButton
            onlyValid
            shape='square'
            color='green'
            iconSize={16}
            endIcon={<ArrowRight size={16} color={Coal_10} />}
            css={styles.underlineText}
          >
            Login
          </FormikSubmitButton>
        </FormikForm>
      </div>
      <div css={styles.helpText}>
        <b>Need Help? </b>
        Email{' '}
        <a
          href={`mailto:${SUPPORT_EMAIL}`}
          target='_blank'
          rel='noreferrer'
          css={styles.emailLink}
        >
          {SUPPORT_EMAIL}
        </a>
      </div>
    </AuthContainer>
  );
};

const styles = {
  form: css({
    width: '100%',
    rowGap: theme.spacing(6),
    '& label': {
      fontSize: theme.spacing(4),
      fontWeight: 500,
      color: Coal_10,
      marginBottom: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      padding: `${theme.spacing(4.5)} ${theme.spacing(3.5)}`,
      fontFamily: 'Figtree',
      fontWeight: 500,
      fontSize: theme.spacing(4),
      color: Coal_10,
      borderRadius: theme.spacing(2),
      border: `1px solid ${Coal_40}`,
      '&:focus': {
        borderColor: Coal_30,
        borderRadius: theme.spacing(2),
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: Coal_40,
        borderRadius: theme.spacing(2),
        borderWidth: '1px',
      },
      '&:hover fieldset': {
        borderColor: Coal_30,
      },
      '&.Mui-focused fieldset': {
        borderColor: Coal_30,
      },
    },
  }),
  formContainer: css({
    marginTop: theme.spacing(10),
    width: '100%',
    display: 'flex',
    justifyContent: 'stretch',
  }),
  helpText: css({
    marginTop: theme.spacing(10),
  }),
  emailLink: css({
    textDecoration: 'underline',
    color: Coal_10,
  }),
  underlineText: css({
    '&: hover': {
      textDecoration: 'underline',
    },
  }),
};

export default EmailInputScreen;
