import { css } from '@emotion/react';
import { Typography } from '@material-ui/core';
import { colors } from '@mysteryco/design';

const InsightsExplainer = ({
  explainerText,
  title,
}: {
  explainerText: string;
  title: string;
}) => {
  return (
    <>
      <Typography css={styles.title}>{title}</Typography>
      <Typography css={styles.body}>{explainerText}</Typography>
    </>
  );
};

const styles = {
  title: css({
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: 1.5,
    color: colors.Glue_Ink00,
  }),
  body: css({
    paddingTop: '12px',
    fontSize: '14px',
    lineHeight: '20px',
  }),
};

export default InsightsExplainer;
