import theme from 'theme';

const InfoCircle = ({
  size = 24,
  color = theme.palette.primary.main,
  style = {},
  className = '',
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      style={style}
      viewBox={`0 0 16 16`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.00004 10.6667C7.00004 11.219 7.44776 11.6667 8.00004 11.6667C8.55233 11.6667 9.00004 11.219 9.00004 10.6667H7.00004ZM9.00004 8.00001C9.00004 7.44773 8.55233 7.00001 8.00004 7.00001C7.44776 7.00001 7.00004 7.44773 7.00004 8.00001H9.00004ZM8.00004 4.33334C7.44776 4.33334 7.00004 4.78106 7.00004 5.33334C7.00004 5.88563 7.44776 6.33334 8.00004 6.33334V4.33334ZM8.00671 6.33334C8.55899 6.33334 9.00671 5.88563 9.00671 5.33334C9.00671 4.78106 8.55899 4.33334 8.00671 4.33334V6.33334ZM13.6667 8.00001C13.6667 11.1296 11.1297 13.6667 8.00004 13.6667V15.6667C12.2342 15.6667 15.6667 12.2342 15.6667 8.00001H13.6667ZM8.00004 13.6667C4.87043 13.6667 2.33337 11.1296 2.33337 8.00001H0.333374C0.333374 12.2342 3.76586 15.6667 8.00004 15.6667V13.6667ZM2.33337 8.00001C2.33337 4.8704 4.87043 2.33334 8.00004 2.33334V0.333344C3.76586 0.333344 0.333374 3.76583 0.333374 8.00001H2.33337ZM8.00004 2.33334C11.1297 2.33334 13.6667 4.8704 13.6667 8.00001H15.6667C15.6667 3.76583 12.2342 0.333344 8.00004 0.333344V2.33334ZM9.00004 10.6667V8.00001H7.00004V10.6667H9.00004ZM8.00004 6.33334H8.00671V4.33334H8.00004V6.33334Z'
        fill={color}
      />
    </svg>
  );
};

export default InfoCircle;
