// @ts-strict-ignore
import { useQuery } from '@apollo/client';
import { Box, ButtonBase, Drawer, Snackbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Alert } from '@material-ui/lab';
import { colors } from '@mysteryco/design/src';
import { Loading } from 'components/core';
import FlatButton from 'components/core/FlatButton';
import CloseX from 'components/icons/CloseX';
import TagSelect from 'components/SelectDropdown/TagSelect';
import TeamSelect from 'components/SelectDropdown/TeamSelect';
import { updateUser } from 'gql/interactions';
import { getOrganizationRoles } from 'gql/queries';
import { useEffect, useState } from 'react';
import theme from 'theme';
import {
  MemberFragmentFragment,
  useAddRemoveTagsFromUsersMutation,
  UserTag,
  UserTagType,
} from 'types';
import { isContract } from 'utils/customerTypeUtils';
import { TeamPageOrganization } from '.';
import RolePicker from './RolePicker';

interface BulkEditTeamMembersProps {
  isShowing: boolean;
  isArchivedPage: boolean;
  setIsShowing(isShowing: boolean): void;
  organization: TeamPageOrganization;
  members: MemberFragmentFragment[];
  setSelectedMembers?: (members: any) => void;
}

const updateUsers = async (members: MemberFragmentFragment[], values: any) => {
  const lastElement = members.length - 1;
  await Promise.all(
    members.map((member, i) => {
      return updateUser(
        {
          ...values,
          id: member.id,
        },
        i === lastElement ? ['searchOrgUsers'] : [],
      );
    }),
  );
};

const getAllSharedTags = (members: MemberFragmentFragment[]): UserTag[] => {
  const { counts, tags } = members
    .flatMap((member) => member.tags)
    .reduce(
      ({ counts, tags }, tag) => {
        counts[tag.id] = counts[tag.id] ? counts[tag.id] + 1 : 1;
        tags[tag.id] = tag;
        return { counts, tags };
      },
      { counts: {}, tags: {} },
    );
  return Object.entries(counts).reduce((sharedTags, [id, count]) => {
    if (count === members.length) sharedTags.push(tags[id]);
    return sharedTags;
  }, []);
};

const getSharedTagIds = (members: MemberFragmentFragment[]): string[] => {
  return getAllSharedTags(members)
    .filter((tag) => tag.type === UserTagType.Manual)
    .map((tag) => tag.id);
};

const getSharedRole = (members: MemberFragmentFragment[]) => {
  const role = members[0]?.organizationRole?.id;
  return members.every((member) => member.organizationRole?.id === role) ? role : '';
};

const getSharedTeam = (members: MemberFragmentFragment[]) => {
  const team = members[0]?.teams?.[0]?.id;
  return members.every((member) => member.teams?.[0]?.id === team) ? [team] : [];
};

const BulkEditTeamMembersHeader = ({
  classes,
  members,
  onClose = () => null,
}: {
  classes: ClassNameMap;
  members: MemberFragmentFragment[];
  onClose: (event) => void;
}) => {
  const text = `Edit ${members.length} members`;
  return (
    <Box className={classes.headerContainer}>
      <Typography className={classes.title}>{text}</Typography>
      <ButtonBase onClick={onClose}>
        <CloseX className={classes.close} />
      </ButtonBase>
    </Box>
  );
};

const BulkEditTeamMembers = ({
  isShowing,
  isArchivedPage,
  setIsShowing,
  members,
  organization,
  setSelectedMembers,
}: BulkEditTeamMembersProps) => {
  const classes = useStyles();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [teamId, setTeamId] = useState([]);
  const [sharedTagIds, setSharedTagIds] = useState([]);
  const [selectedTagIds, setSelectedTagIds] = useState([]);

  const { data, loading } = useQuery(getOrganizationRoles);
  const [addRemoveTags] = useAddRemoveTagsFromUsersMutation();

  useEffect(() => {
    if (!data) return;
    setRoles(data.roles);
  }, [data]);

  useEffect(() => {
    if (!members) return;
    setSelectedRole(getSharedRole(members));
    setTeamId(getSharedTeam(members));
    setSharedTagIds(getSharedTagIds(members));
    setSelectedTagIds(sharedTagIds);
  }, [members]);

  const snackbarAlert = (message: string) => {
    setAlertMessage(message);
    setShowSnackbar(true);
  };

  const isHrisConnected = organization.isHrisConnected;
  const disabled = isArchivedPage || submitting;

  const updateTags = async () => {
    const memberIds = members.map((member) => member.id);
    const removeTagIds = sharedTagIds.filter((tag) => !selectedTagIds.includes(tag));
    await addRemoveTags({
      variables: { userIds: memberIds, addTagIds: selectedTagIds, removeTagIds },
    });
  };

  const onSave = async () => {
    let values = { organizationRoleId: selectedRole };
    if (teamId?.[0]) values['team'] = teamId[0];

    setSubmitting(true);
    await updateTags();
    await updateUsers(members, values);

    snackbarAlert('Members updated!');
    setSubmitting(false);
    setIsShowing(false);
    setSelectedMembers(new Set());
  };

  return (
    <>
      <Drawer
        className={classes.drawer}
        open={isShowing && !loading}
        onClose={() => setIsShowing(false)}
        anchor='right'
      >
        {!members && <Loading />}
        {members && (
          <>
            <div className={classes.container}>
              <BulkEditTeamMembersHeader
                classes={classes}
                members={members}
                onClose={() => setIsShowing(false)}
              />
              <Box className={classes.content}>
                <RolePicker
                  onChange={(newRole) => setSelectedRole(newRole)}
                  value={selectedRole}
                  roles={roles}
                  disabled={disabled}
                />
                {isContract(organization.customerType) && !isHrisConnected && (
                  <Box>
                    <Typography variant='h5' className={classes.header}>
                      Team
                    </Typography>
                    <Typography className={classes.subtitle}>
                      Change the team of the selected members
                    </Typography>
                    <Box mt={3}>
                      <TeamSelect
                        organization={organization}
                        value={teamId}
                        onChange={(_, newValue) => setTeamId(newValue)}
                        userIds={members.map((member) => member.id)}
                        disabled={disabled}
                      />
                    </Box>
                  </Box>
                )}
                {isContract(organization.customerType) && (
                  <Box>
                    <Typography variant='h5' className={classes.header}>
                      Tags
                    </Typography>
                    <Typography className={classes.subtitle}>
                      Edit members' tags
                    </Typography>
                    <Box mt={3}>
                      <TagSelect
                        organization={organization}
                        value={selectedTagIds}
                        onChange={(_, newValues) => setSelectedTagIds(newValues)}
                        multiple
                        disabled={disabled}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
              <Box className={classes.footer}>
                <FlatButton type='submit' fullWidth disabled={disabled} onClick={onSave}>
                  Save changes
                </FlatButton>
              </Box>
            </div>
          </>
        )}
      </Drawer>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity='success'>{alertMessage}</Alert>
      </Snackbar>
    </>
  );
};

const useStyles = makeStyles({
  drawer: {
    overflowY: 'scroll',
    '& .MuiDrawer-paperAnchorRight': {
      width: '30rem',
    },
  },
  container: {
    padding: '1.5rem',
  },
  row: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    width: '100%',
  },
  footer: {
    marginTop: theme.spacing(8),
  },
  close: {
    color: colors.MediumGray,
    transition: 'color 300ms ease-in-out',
    '&:hover': {
      color: colors.Main,
    },
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 500,
    fontSize: 30,
    lineHeight: 1.07,
    color: colors.Midnight,
    paddingBottom: theme.spacing(8),
  },
  header: {
    fontWeight: 500,
    fontSize: '1.125rem',
    lineHeight: 1.56,
    color: colors.Midnight,
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '.75rem',
    lineHeight: 1.33,
    color: colors.DarkGray,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
  },
});

export default BulkEditTeamMembers;
