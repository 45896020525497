// @ts-strict-ignore
import { css } from '@emotion/react';
import AlertHexagon from '@mysteryco/design/icons/AlertHexagon';
import ClockRefresh from '@mysteryco/design/icons/ClockRefresh';
import { colors } from '@mysteryco/design/src';
import ListedStepper from 'glue/components/lists/ListedStepper';
import InlineBanner from 'glue/components/structure/InlineBanner';
import StepperBlock from 'glue/components/structure/StepperBlock';
import { usePulseSettingsState } from './pulseSettingsState';
import { existsFilter } from 'lib/helpers/maybe';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import theme from 'theme';
import MoralePulseStepWrapper from './MoralePulseStepWrapper';
import PulseHelpBanner from '../components/PulseHelpBanner';
import { getPreviewStepArr, getStepperProps } from './helpers';

interface MoralePulsePreviewProps {
  previous: () => void;
  next: () => void;
  setStepperSteps: (arr: any[]) => void;
  setIsEnabling: (val: boolean) => void;
  activePulse?: any;
}

const strings = {
  title: 'Preview summary',
  hereAreDetails: 'Here are the details on your Pulse surveys',
  whatHasChanged: 'Here’s what has changed',
  yourSummary: 'Your summary details',
  changesDetails: 'Changes Details',
  fyi: 'FYI:',
  youWillBe: 'You will be able to make changes to your surveys at any time',
  questions: 'Questions?',
  checkOut: 'Check our help documentation for answers on all things Pulse',
  help: 'Help',
  theseChanges: 'These changes will be applied to your upcoming surveys',
};

const MoralePulsePreview = ({
  previous,
  next,
  setStepperSteps,
  setIsEnabling,
  activePulse,
}: MoralePulsePreviewProps) => {
  const {
    startDate: date,
    cadence,
    audience,
    pulseId,
    questionIds,
  } = usePulseSettingsState();
  const isDatePickedToday =
    DateTime.local().toJSDate().toDateString() === new Date(date).toDateString();
  const hasActivePulse = !!pulseId;
  const stepperProps = getStepperProps({ step: 2, enabled: hasActivePulse });
  const steps = getPreviewStepArr({
    hasActivePulse,
    date,
    isDatePickedToday,
    cadence,
    audience,
    questionIds,
    previousQuestionIds:
      hasActivePulse &&
      (activePulse.activeQuestions ?? []).filter(existsFilter).map((q) => q.id),
    previousAudience: hasActivePulse && { users: activePulse.targetAudience },
    previousStartDate: hasActivePulse && new Date(activePulse.startDate),
    previousCadence: hasActivePulse && activePulse.cadence,
  });

  useEffect(() => {
    setStepperSteps(steps);
    setIsEnabling(!pulseId);
  }, []);

  return (
    <MoralePulseStepWrapper
      finish={next}
      previous={previous}
      finishText={pulseId ? 'Save changes' : 'Finish'}
    >
      <div css={styles.root}>
        <div css={styles.content}>
          <StepperBlock {...stepperProps} />
          <div css={styles.title}>{strings.title}</div>
          <div css={styles.subTitle}>
            {pulseId ? strings.whatHasChanged : strings.hereAreDetails}
          </div>
          <div css={styles.summaryDetails}>
            <div css={styles.subSection}>
              {pulseId ? strings.changesDetails : strings.yourSummary}
            </div>
            <ListedStepper steps={steps} showConnector={!pulseId} />
          </div>
          {pulseId ? (
            <InlineBanner
              type='error'
              headline={strings.theseChanges}
              icon={
                <AlertHexagon
                  size={18}
                  color='#3B3B3B'
                  style={{ marginTop: theme.spacing(1) }}
                />
              }
            />
          ) : (
            <InlineBanner
              type='tip'
              headline={strings.fyi}
              description={strings.youWillBe}
              icon={
                <ClockRefresh
                  size={18}
                  color='#3B3B3B'
                  style={{ marginTop: theme.spacing(1) }}
                />
              }
            />
          )}
        </div>
        <PulseHelpBanner />
      </div>
    </MoralePulseStepWrapper>
  );
};

const styles = {
  root: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    padding: '2rem',
    width: 860,
  }),
  content: css({
    color: colors.Glue_Ink00,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8),
    flex: 1,
  }),
  summaryDetails: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  }),
  title: css({
    fontSize: 32,
    fontWeight: 700,
    lineHeight: '120%',
  }),
  subTitle: css({
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '28px',
  }),
  subSection: css({
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: 12,
    lineHeight: 1.2,
    letterSpacing: '0.1em',
    color: colors.Glue_Ink10,
  }),
  helpBanner: css({
    marginBottom: theme.spacing(15),
  }),
};

export default MoralePulsePreview;
