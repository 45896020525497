import { Loading, PageWrapper } from 'components/core';
import NotFoundPage from 'glue/components/navigation/NotFoundPage';
import { teamEventFragment } from 'gql/queries';
import gql from 'graphql-tag';
import { useQueryParams } from 'lib/helpers/router';
import { useTeamEventRegistrationQuery } from 'types';
import CheckoutFlow from './Checkout';

const TeamEventRegistration = () => {
  const query = useQueryParams();
  const teamEventId = query.get('id') ?? '';
  const { data, loading } = useTeamEventRegistrationQuery({
    variables: { teamEventId },
    skip: !teamEventId,
  });

  if (loading) return <Loading />;

  const teamEvent = data?.teamEvent;

  if (!teamEventId || !teamEvent)
    return (
      <PageWrapper>
        <NotFoundPage />
      </PageWrapper>
    );

  return <CheckoutFlow teamEvent={teamEvent} />;
};

TeamEventRegistration.query = gql`
  query TeamEventRegistration($teamEventId: ID!) {
    teamEvent(id: $teamEventId) {
      ...teamEventFragment
    }
  }
  ${teamEventFragment}
`;

export default TeamEventRegistration;
