// @ts-strict-ignore
import { Loading, NotFoundError } from 'components/core';
import {
  GetUserWithOrgsQuery,
  useGetUserWithOrgsQuery,
  ViewerFragmentFragment,
} from 'types';

import { isTransactional } from 'utils/customerTypeUtils';
import { getSettingsTypePath } from '..';
import Team from './Team';
import TeamsUpsell from './UpsellView';

export const getTeamsPath = () => {
  return getSettingsTypePath('teams');
};

type Props = {
  viewer: ViewerFragmentFragment;
};

// We explicitly do NOT get members from this query
export type TeamPageOrganization = Omit<
  GetUserWithOrgsQuery['viewer']['orgs'][0],
  'members' | 'archivedMembers'
>;

const Teams = ({ viewer }: Props) => {
  const { data, loading } = useGetUserWithOrgsQuery({
    variables: { getMembers: false },
  });
  if (loading || !data) return <Loading />;

  if (isTransactional(viewer?.customerType)) return <TeamsUpsell />;
  // The array of orgs is to support a user being able to belong to more than a single org.
  // For now, we are assuming that users will only belong to one organization.
  const organization = data?.viewer?.orgs?.[0] as TeamPageOrganization;
  if (!organization) {
    return (
      <NotFoundError
        title='No Organization Found'
        subtitle='Unable to show organization settings as you are not a member of any organization'
      />
    );
  }

  return <Team organization={organization} viewer={viewer} />;
};

export default Teams;
