import { useRef } from 'react';

/**
 * Like useMemo, except it guarantees that it will only be called once when
 * dependencies change
 *
 * @see https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
 */
export default function useGuaranteedMemo<TResult>(
  factory: () => TResult,
  dependencies: unknown[],
): TResult {
  const ref = useRef<{ result: TResult; dependencies: unknown[] }>();
  if (!ref.current || !sameDependencies(ref.current.dependencies, dependencies)) {
    ref.current = { result: factory(), dependencies };
  }

  return ref.current.result;
}

function sameDependencies(dependenciesA: unknown[], dependenciesB: unknown[]) {
  if (dependenciesA.length !== dependenciesB.length) return false;
  for (let i = 0; i < dependenciesA.length; i++) {
    if (dependenciesA[i] !== dependenciesB[i]) return false;
  }
  return true;
}
