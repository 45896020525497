import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete, AutocompleteChangeReason } from '@material-ui/lab';
import ChevronDown from '@mysteryco/design/icons/ChevronDown';
import ChevronUp from '@mysteryco/design/icons/ChevronUp';
import { colors } from '@mysteryco/design';
import { useState } from 'react';
import TextInput from './TextInput';
import theme from 'theme';
import { cx } from '@emotion/css';
import { Paper } from '@material-ui/core';

export interface AutocompleteInputProps {
  options: any[];
  value?: any;
  placeholder?: string;
  onChange?: (event: any, value: any, reason: AutocompleteChangeReason) => void;
  getOptionLabel?: (option: any) => string;
  disabled?: boolean;
  [key: string]: any;
}

const AutocompleteInput = ({
  options,
  getOptionLabel = (option) => option,
  value = {},
  onChange,
  placeholder,
  fullWidth,
  readOnly,
  disablePortal,
  disabled = false,
  ...props
}: AutocompleteInputProps) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const icon = open ? <ChevronUp /> : <ChevronDown />;

  const CustomPaper = (props) => {
    return <Paper {...props} className={cx(classes.dropdown, props?.className)} />;
  };

  return (
    <div className={classes.container} css={fullWidth && { width: '100%' }}>
      <Autocomplete
        renderInput={(params) => (
          <div ref={params.InputProps.ref} css={fullWidth && { width: '100%' }}>
            <TextInput
              inputProps={params.inputProps}
              placeholder={placeholder}
              endAdornment={<span className={classes.icon}>{icon}</span>}
              fullWidth={fullWidth}
              disabled={disabled}
              {...props}
            />
          </div>
        )}
        value={value}
        options={options}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={onChange}
        getOptionLabel={getOptionLabel}
        renderOption={(option, state) => (
          <span className={cx(classes.option, state.selected && classes.selectedOption)}>
            {getOptionLabel(option)}
          </span>
        )}
        PaperComponent={CustomPaper}
        fullWidth={fullWidth}
        disablePortal={disablePortal}
        disabled={disabled}
      />
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    position: 'relative',
    '&:focus-within': {
      '& $icon svg': {
        color: colors.Main,
      },
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: 16,
      height: 16,
      color: colors.MediumGray,
    },
  },
  option: {
    width: '100%',
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    background: colors.White,
    fontWeight: 500,
    fontSize: '.875rem',
    lineHeight: 1.71,
    color: colors.Black,
    '&:hover': {
      background: colors.Green100,
    },
  },
  selectedOption: {
    color: colors.Dusk,
  },
  dropdown: {
    border: `1px solid ${colors.LightGray}`,
    boxShadow:
      '0px 10px 15px -3px rgba(155, 160, 166, 0.1), 0px 4px 6px -2px rgba(155, 160, 166, 0.06)',
    borderRadius: theme.spacing(1),
    '& .MuiAutocomplete-listbox': {
      maxHeight: '12rem',
    },
    '& .MuiAutocomplete-option': {
      padding: 0,
      justifyContent: 'stretch',
    },
    '& .MuiAutocomplete-noOptions': {
      padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
      background: colors.White,
      fontWeight: 500,
      fontSize: '.875rem',
      lineHeight: 1.71,
      color: colors.MediumGray,
    },
  },
});

export default AutocompleteInput;
