import React from 'react';
import { Button, ButtonGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@mysteryco/design';
import theme from '../../theme';

const useStyles = makeStyles({
  buttonGroup: {
    height: '48px',
    '& .MuiButton-contained': {
      background: colors.Purple100,
      border: `1.5px solid ${colors.Main}`,
      color: theme.palette.primary.main,
    },
    alignSelf: 'center',
  },
  button: {
    textTransform: 'none',
    fontSize: '14px',
    borderRadius: '8px',
  },
});

interface Props {
  options: { orderIndex: number; label: string; value: string }[];
  onButtonClick: (value: string) => void;
  selectedValue: string;
  groupName: string;
}

const AnswerButtonGroup = ({
  options,
  onButtonClick,
  selectedValue,
  groupName,
}: Props) => {
  const classes = useStyles();
  return (
    <ButtonGroup
      fullWidth
      aria-label={`${groupName} button group`}
      className={classes.buttonGroup}
    >
      {options
        .sort((a, b) => b.orderIndex - a.orderIndex)
        .map((option, index) => (
          <Button
            className={classes.button}
            variant={selectedValue === option.value ? 'contained' : 'outlined'}
            key={`button-${index}`}
            onClick={() => onButtonClick(option.value)}
          >
            {option.label}
          </Button>
        ))}
    </ButtonGroup>
  );
};

export default AnswerButtonGroup;
