// @ts-strict-ignore
import { cx } from '@emotion/css';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import React from 'react';
import theme from 'theme';

const useStyles = makeStyles({
  container: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: theme.spacing(2),
  },
  answerContainer: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    width: '100%',
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
    cursor: 'pointer',
  },
  answer: {
    fontSize: 30,
  },
  lastItem: {
    borderBottom: 'none',
  },
  isSelected: {
    background: theme.palette.primary[300],
  },
});

interface Props {
  multiSelect?: boolean;
  answers?: { id: string; label: string }[];
  currentAnswers: string[];
  onAnswer(args: { answerId: string; checked: boolean }): void;
}

const Question = ({ multiSelect, answers, onAnswer, currentAnswers }: Props) => {
  const classes = useStyles();
  const controlComponent = multiSelect ? <Checkbox /> : <Radio />;
  return (
    <Box className={classes.container}>
      <FormControl fullWidth component='fieldset'>
        <RadioGroup>
          {answers.map((r, i) => {
            const isSelected = !!_.find(currentAnswers, (id) => id === r.id);
            return (
              <Box
                key={r.id}
                className={cx(
                  classes.answerContainer,
                  isSelected && classes.isSelected,
                  i === answers.length - 1 && classes.lastItem,
                )}
                onClick={(e) => {
                  e.preventDefault(); // Prevent event bubbling
                  onAnswer({ answerId: r.id, checked: isSelected ? false : true });
                }}
              >
                <FormControlLabel
                  checked={isSelected}
                  value={r.id}
                  control={controlComponent}
                  onChange={(e) => {
                    const t = e.target as any; // Target here doesn't inherit from Element for some reason?
                    onAnswer({ answerId: t.value, checked: t.checked });
                  }}
                  label={<Typography variant='body1'>{r.label}</Typography>}
                />
              </Box>
            );
          })}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default Question;
