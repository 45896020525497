// import Indentify from '@mysteryco/design/icons/Indentify'

import { css } from '@emotion/react';
import Identify from '@mysteryco/design/icons/Identify';
import { Coal_10, Coal_30, Coal_40 } from '@mysteryco/design/src/tokens/colors';
import theme from 'theme';

const EmptyCohort = () => {
  return (
    <div css={styles.container}>
      <Identify size={36} color={Coal_30} />
      <p css={styles.text}>Use the search field to add participants to Meetups</p>
    </div>
  );
};

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    border: `solid 1px ${Coal_40}`,
    borderRadius: theme.spacing(4),
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  text: css({
    color: Coal_10,
    fontFamily: 'Manrope',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: theme.spacing(6),
  }),
};

export default EmptyCohort;
