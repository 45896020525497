import theme from 'theme';

const FaceHappy = ({
  size = 20,
  color = theme.palette.primary.main,
  style = {},
  className = '',
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      style={style}
    >
      <path
        d='M10.0002 2.49967C5.85803 2.49967 2.50016 5.85754 2.50016 9.99967C2.50016 14.1418 5.85803 17.4997 10.0002 17.4997C14.1423 17.4997 17.5002 14.1418 17.5002 9.99967C17.5002 5.85754 14.1423 2.49967 10.0002 2.49967ZM0.833496 9.99967C0.833496 4.93706 4.93755 0.833008 10.0002 0.833008C15.0628 0.833008 19.1668 4.93706 19.1668 9.99967C19.1668 15.0623 15.0628 19.1663 10.0002 19.1663C4.93755 19.1663 0.833496 15.0623 0.833496 9.99967ZM6.25016 7.49967C6.25016 6.80932 6.80981 6.24967 7.50016 6.24967C8.19052 6.24967 8.75016 6.80932 8.75016 7.49967C8.75016 8.19003 8.19052 8.74967 7.50016 8.74967C6.80981 8.74967 6.25016 8.19003 6.25016 7.49967ZM11.2502 7.49967C11.2502 6.80932 11.8098 6.24967 12.5002 6.24967C13.1905 6.24967 13.7502 6.80932 13.7502 7.49967C13.7502 8.19003 13.1905 8.74967 12.5002 8.74967C11.8098 8.74967 11.2502 8.19003 11.2502 7.49967ZM5.41683 11.6663C5.41683 11.2061 5.78993 10.833 6.25016 10.833H13.7502C14.2104 10.833 14.5835 11.2061 14.5835 11.6663C14.5835 12.6867 13.9872 13.6318 13.1852 14.2898C12.3667 14.9614 11.2444 15.4163 10.0002 15.4163C8.7559 15.4163 7.6336 14.9614 6.81515 14.2898C6.01313 13.6318 5.41683 12.6867 5.41683 11.6663ZM7.39604 12.4997C7.5166 12.6684 7.67417 12.8388 7.87236 13.0014C8.40798 13.4409 9.16068 13.7497 10.0002 13.7497C10.8396 13.7497 11.5923 13.4409 12.128 13.0014C12.3262 12.8388 12.4837 12.6684 12.6043 12.4997H7.39604Z'
        fill={color}
      />
    </svg>
  );
};

export default FaceHappy;
