// @ts-strict-ignore
import { Skeleton } from 'components/Skeleton';
import { DateTime } from 'luxon';

import ScoreDetailsHeader from '../common/Header';
import EventsStrategyStatistics from './Statistics';
import EventStrategyTable from './Table';
import { gql } from '@apollo/client';
import {
  ScoreDetailsTeamFragment,
  ScoreType,
  TeamEventsByOrgIdQuery,
  useTeamEventsByOrgIdQuery,
} from 'types';
import { goalToScoreType } from 'lib/helpers/goals';
import EmptyState from 'glue/components/insights/EmptyState/EventStrategy';

interface Props {
  orgId: string;
  date: DateTime;
  teams: ScoreDetailsTeamFragment[];
  activeTeamName: string;
  parentLoading: boolean;
  scoreType: ScoreType;
}

export type EventStrategyTeamEvent =
  TeamEventsByOrgIdQuery['teamEventsConnection']['edges'][0]['node'];

const ScoreDetailsEventsStrategy = ({
  orgId,
  date,
  teams,
  activeTeamName,
  parentLoading,
  scoreType,
}: Props) => {
  const now = DateTime.now();
  let endDate = date.endOf('month');
  if (date.month === now.month && date.year === now.year) {
    endDate = date.endOf('month').plus({ months: 2 });
  }
  const isCurrentMonth = date.startOf('month') >= DateTime.now().startOf('month');
  const { data, loading } = useTeamEventsByOrgIdQuery({
    variables: { startDate: date.startOf('month'), endDate: endDate, orgId: orgId },
  });

  if (loading || parentLoading) return <Skeleton />;

  const allMemberIds =
    teams?.reduce((acc, team) => {
      for (const member of team.members) {
        acc.add(member.id);
      }
      return acc;
    }, new Set<string>()) || new Set<string>();

  const teamEvents = [] as EventStrategyTeamEvent[];
  if (data) {
    teamEvents.push(
      ...data.teamEventsConnection.edges
        .filter(({ node }) =>
          node.invitedGuests.some((ig) => allMemberIds.has(ig.user?.id)),
        )
        .map((edge) => ({
          ...edge.node,
          requestedFor: DateTime.fromISO(edge.node.requestedFor),
        })),
    );
  }

  const eventsOfGoalType =
    scoreType === ScoreType.SenseOfBelonging
      ? teamEvents
      : teamEvents.filter((event) =>
          event.goals.some((goal) => goalToScoreType(goal.goal) === scoreType),
        );

  return (
    <>
      <ScoreDetailsHeader title='Events Strategy'>
        Events for {date.monthLong} {date.year}
      </ScoreDetailsHeader>
      <EventsStrategyStatistics
        fullOrgView={true}
        teamEvents={teamEvents}
        teams={teams}
        scoreType={scoreType}
      />
      {eventsOfGoalType?.length ? (
        <EventStrategyTable
          isCurrentMonth={isCurrentMonth}
          scoreType={scoreType}
          teamEvents={teamEvents}
        />
      ) : (
        <EmptyState
          scoreType={scoreType}
          showCTA={isCurrentMonth}
          showNoData={teamEvents?.length === 0}
          monthName={date.monthLong}
          teamName={activeTeamName}
        />
      )}
    </>
  );
};

ScoreDetailsEventsStrategy.query = gql`
  query teamEventsByOrgId($orgId: ID!, $startDate: DateTime, $endDate: DateTime) {
    teamEventsConnection(organizationId: $orgId, dateRange: [$startDate, $endDate]) {
      edges {
        node {
          requestedFor
          goals {
            id
            goal
          }
          invitedGuests {
            id
            user {
              id
            }
            rsvpStatus
          }
          connectionRating
        }
      }
    }
  }
`;

export default ScoreDetailsEventsStrategy;
