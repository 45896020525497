// @ts-strict-ignore
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PageMetaData } from 'Routes';
import RSVP_BACKGROUND from 'assets/rsvp-background.png';
import EmptyButton from 'components/EmptyButton';
import RsvpDeadlineBanner from 'components/RsvpDeadlineBanner';
import TeamEventDetail from 'components/TeamEventDetail';
import { Loading } from 'components/core';
import NewPrimaryButton from 'components/core/NewPrimaryButton';
import ArrowRight from 'components/icons/ArrowRight';
import Back from 'components/icons/Back';
import CheckVerified02 from 'components/icons/CheckVerified02';
import Mail01 from 'components/icons/Mail01';
import SadFace from 'components/icons/SadFace';
import Ticket01 from 'components/icons/Ticket01';
import { client } from 'gql/client';
import { updateRsvpStatus } from 'gql/mutations';
import { DateTime } from 'luxon';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import theme from 'theme';
import {
  InvitedGuestRsvpStatus,
  TeamEventFragmentFragment,
  ViewerFragmentFragment,
} from 'types';
import RsvpHeader from './RsvpHeader';

type HoveringState = 'HoveringAccept' | 'HoveringDecline' | 'HoveringNone';

const Rsvp = ({
  existingRsvpStatus,
  isChangingRsvp,
  teamEvent,
  viewer,
  setComplete,
  hasOtherSteps,
}: {
  existingRsvpStatus: InvitedGuestRsvpStatus;
  isChangingRsvp: boolean;
  teamEvent: TeamEventFragmentFragment;
  viewer: ViewerFragmentFragment;
  setComplete?: (a: boolean) => void;
  hasOtherSteps?: boolean;
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [hasAccepted, setHasAccepted] = React.useState(
    existingRsvpStatus === InvitedGuestRsvpStatus.Accepted,
  );
  const [hasDeclined, setHasDeclined] = React.useState(
    existingRsvpStatus === InvitedGuestRsvpStatus.Declined,
  );
  const [isUpdatedRsvp, setUpdatedRsvp] = React.useState(false);
  const [hoveringState, setHoveringState] = React.useState<HoveringState>('HoveringNone');
  const rsvpDeadline = DateTime.fromISO(teamEvent?.signUpExpirationDate).toLocaleString(
    DateTime.DATE_MED_WITH_WEEKDAY,
  );
  const includeDelivery = !!teamEvent?.includeDelivery;
  const hasPassedDeadline =
    rsvpDeadline && DateTime.now() > DateTime.fromISO(teamEvent?.signUpExpirationDate);

  const onUpdateStatus = async (status: InvitedGuestRsvpStatus) => {
    setLoading(true);
    await client.mutate({
      mutation: updateRsvpStatus,
      variables: {
        teamEventId: teamEvent.id,
        email: viewer.email,
        rsvpStatus: status,
      },
    });
    setLoading(false);
  };

  const renderDefaultHeader = () => {
    return (
      <RsvpHeader
        icon={
          <Mail01 className={classes.headerIcon} color={theme.palette.primary.main} />
        }
        iconText="You're Invited"
        title={'Accept your invitation'}
      />
    );
  };

  const renderAcceptedHeader = () => {
    return (
      <RsvpHeader
        icon={
          <Mail01 className={classes.headerIcon} color={theme.palette.primary.main} />
        }
        iconText="You're Invited"
        title={
          <span>
            Your invitation is
            <span css={{ fontWeight: 700, color: theme.palette.primary.main }}>
              {' '}
              almost confirmed
            </span>
          </span>
        }
        subtitle='Answer a few more questions to confirm your reservation'
        children={
          <NewPrimaryButton
            css={{ width: '190px' }}
            label='Continue'
            endIcon={<ArrowRight color='white' />}
            onClick={() => setComplete(true)}
          />
        }
      />
    );
  };

  const renderDeclinedHeader = () => {
    return (
      <RsvpHeader
        icon={
          <Mail01 className={classes.headerIcon} color={theme.palette.primary.main} />
        }
        iconText='Invitation declined'
        title={
          <span>
            You've
            <span css={{ fontWeight: 700, color: theme.palette.primary.main }}>
              {' '}
              declined{' '}
            </span>
            your invitation
          </span>
        }
        subtitle='You can still change your mind.'
        children={
          <NewPrimaryButton
            css={{ width: '238px' }}
            label='Accept invitation'
            endIcon={<ArrowRight color='white' />}
            onClick={() => {
              !includeDelivery && onUpdateStatus(InvitedGuestRsvpStatus.Accepted);
              setHasAccepted(true);
              setHasDeclined(false);
            }}
          />
        }
      />
    );
  };

  const renderExpiredHeader = () => {
    return (
      <RsvpHeader
        icon={
          <Mail01 className={classes.headerIcon} color={theme.palette.primary.main} />
        }
        iconText='Invitation Expired'
        title={
          <span>
            Your invite has
            <span css={{ fontWeight: 700, color: theme.palette.primary.main }}>
              {' '}
              expired{' '}
            </span>
          </span>
        }
        subtitle='The RSVP deadline for this event has already passed.'
        children={
          <span>
            <p>
              You can find events you’ve already RSVP’d for in your upcoming events or by
              clicking the button below.
            </p>
            <p>
              Otherwise, try searching your inbox for mstry.co to find other upcoming
              event invites.
            </p>
          </span>
        }
      />
    );
  };

  const renderChangingRsvpHeader = () => {
    if (isUpdatedRsvp) {
      if (hasAccepted) {
        return (
          <RsvpHeader
            icon={
              <CheckVerified02
                className={classes.headerIcon}
                color={theme.palette.primary.main}
              />
            }
            iconText="You're all set"
            title={
              <span>
                RSVP
                <span css={{ fontWeight: 700, color: theme.palette.primary.main }}>
                  {' '}
                  accepted
                </span>
              </span>
            }
            subtitle={`Your name is on the list!`}
          />
        );
      } else {
        return (
          <RsvpHeader
            icon={
              <Mail01 className={classes.headerIcon} color={theme.palette.primary.main} />
            }
            iconText='Invitation declined'
            title={
              <span>
                You've
                <span css={{ fontWeight: 700, color: theme.palette.primary.main }}>
                  {' '}
                  declined{' '}
                </span>
                your invitation
              </span>
            }
            subtitle={`You're no longer RSVP's to the event`}
          />
        );
      }
    }
    if (existingRsvpStatus === InvitedGuestRsvpStatus.Accepted) {
      return (
        <RsvpHeader
          icon={
            <Ticket01 className={classes.headerIcon} color={theme.palette.primary.main} />
          }
          iconText="Your Event's Ticket"
          title={`${teamEvent.title}`}
          subtitle={`Your name is on the list. See you soon!`}
        />
      );
    } else {
      return renderDefaultHeader();
    }
  };

  const renderExistingRsvpCtas = () => {
    return (
      <Box className={classes.ctas}>
        <EmptyButton
          css={{
            width: '220px',
            height: theme.spacing(12),
            marginRight: '24px',
          }}
          onClick={() => {
            history.replace(`/event-details/${teamEvent.id}`);
          }}
        >
          <Back size={20} />
          Return to event details
        </EmptyButton>
        {existingRsvpStatus === InvitedGuestRsvpStatus.Accepted && !isUpdatedRsvp && (
          <NewPrimaryButton
            css={{ width: '220px' }}
            label='Decline invitation'
            onClick={() => {
              onUpdateStatus(InvitedGuestRsvpStatus.Declined);
              setHasAccepted(false);
              setHasDeclined(true);
              setUpdatedRsvp(true);
            }}
          />
        )}
        {existingRsvpStatus === InvitedGuestRsvpStatus.Declined && !isUpdatedRsvp && (
          <NewPrimaryButton
            css={{ width: '220px' }}
            label='Accept invitation'
            onClick={() => {
              !includeDelivery && onUpdateStatus(InvitedGuestRsvpStatus.Accepted);
              setHasAccepted(true);
              setHasDeclined(false);
              setUpdatedRsvp(true);
            }}
          />
        )}
      </Box>
    );
  };

  if (loading) return <Loading />;

  const showExpired =
    !isChangingRsvp && hasPassedDeadline && !hasAccepted && !hasDeclined;
  const showDefault =
    !isChangingRsvp && !hasPassedDeadline && !hasAccepted && !hasDeclined;
  const showAccepted = !isChangingRsvp && hasAccepted;
  const showDeclined = !isChangingRsvp && hasDeclined;
  // No deadline for virtual only event
  const showDeadlineBanner = includeDelivery && rsvpDeadline && !hasPassedDeadline;
  const showChangingRsvp = isChangingRsvp;

  if (showAccepted && !hasOtherSteps) {
    setComplete(true);
  }

  return (
    <Box width={'100%'}>
      <Helmet>
        <title>{PageMetaData.RSVP.title}</title>
      </Helmet>
      {showDeadlineBanner && <RsvpDeadlineBanner rsvpDeadline={rsvpDeadline} />}
      <Box className={classes.body}>
        {showDefault && renderDefaultHeader()}
        {showAccepted && renderAcceptedHeader()}
        {showDeclined && renderDeclinedHeader()}
        {showExpired && renderExpiredHeader()}
        {showChangingRsvp && renderChangingRsvpHeader()}
        {!showExpired && <TeamEventDetail teamEvent={teamEvent} viewer={viewer} />}
        {showDefault ? (
          <Box className={classes.ctas}>
            <EmptyButton
              css={{
                width: '190px !important',
                height: theme.spacing(12),
                marginRight: '24px',
                opacity: hoveringState === 'HoveringAccept' ? 0.25 : 1,
              }}
              hovering={hoveringState === 'HoveringDecline'}
              onClick={() => {
                onUpdateStatus(InvitedGuestRsvpStatus.Declined);
                setHasAccepted(false);
                setHasDeclined(true);
              }}
              onMouseEnter={() => setHoveringState('HoveringDecline')}
              onMouseLeave={() => setHoveringState('HoveringNone')}
            >
              <SadFace
                color={
                  hoveringState === 'HoveringDecline'
                    ? 'white'
                    : theme.palette.primary.main
                }
                css={{
                  marginRight: '8px',
                }}
              />
              Decline Invitation
            </EmptyButton>
            <NewPrimaryButton
              css={{
                width: '190px !important',
                opacity: hoveringState === 'HoveringDecline' ? 0.25 : 1,
              }}
              label='Accept Invitation'
              onClick={() => {
                !includeDelivery && onUpdateStatus(InvitedGuestRsvpStatus.Accepted);
                setHasAccepted(true);
                setHasDeclined(false);
              }}
              onMouseEnter={() => setHoveringState('HoveringAccept')}
              onMouseLeave={() => setHoveringState('HoveringNone')}
            />
          </Box>
        ) : (
          <Box css={{ height: '32px' }} />
        )}
        {showExpired && (
          <Box className={classes.ctas}>
            <EmptyButton
              css={{ width: '250px', height: theme.spacing(12) }}
              onClick={() => history.replace('/home')}
            >
              <Back />
              View upcoming events
            </EmptyButton>
          </Box>
        )}
        {showChangingRsvp && renderExistingRsvpCtas()}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    maxWidth: '600px',
    margin: '80px auto',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.primary[200]}`,
    color: theme.palette.text.primary,
    fontSize: '16px',
    lineHeight: '24px',
  },
  bannerText: {
    color: theme.palette.common.white,
    padding: '12px',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Reboto Mono, monospace',
    background: theme.palette.primary.main,
  },
  body: {
    backgroundColor: theme.palette.primary[50],
    backgroundImage: `url(${RSVP_BACKGROUND})`,
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
  },
  header: {
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
  },
  headerText: {
    color: theme.palette.text.primary,
    fontSize: '48px',
    lineHeight: '52px',
    fontWeight: 'normal',
    margin: 0,
  },
  headerTextContent: {
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '28px',
    margin: '12px 0',
  },
  headerIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '36px',
    height: '36px',
    padding: '8px',
    margin: '0 0 12px',
    borderRadius: '8px',
    background: theme.palette.primary[100],
  },
  headerBodyText: {
    fontSize: '12px',
    lineHeight: '20px',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: 'Reboto Mono, monospace',
  },
  ctas: {
    padding: '40px 24px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}));

export default Rsvp;
