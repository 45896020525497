// @ts-strict-ignore
import { CONNECTION_STRENGTH_MAP, ConnectionStrength } from 'constants/Type';
import theme from 'theme';
import { UserTagType, ScoreType } from 'types';

export const getDepartmentName = (manager: {
  name?: string | null;
  tags?: ({ type?: string | null; name?: string | null } | null)[] | null;
}) => {
  if (!manager) return null;

  return manager.tags.find(
    (tag) => tag.type === UserTagType.Automatic && !tag.name.endsWith("'s Team"),
  )?.name;
};

export const ScoreTypeIndex = [
  ScoreType.SenseOfBelonging,
  ScoreType.Leadership,
  ScoreType.WithinTeam,
  ScoreType.CrossTeam,
];

export const ScoreRating = [
  {
    threshold: CONNECTION_STRENGTH_MAP[ConnectionStrength.Strong].threshold,
    rating: 'Strong',
    highlightColor: theme.palette.secondary[500],
    backgroundColor: theme.palette.secondary[200],
  },
  {
    threshold: CONNECTION_STRENGTH_MAP[ConnectionStrength.Moderate].threshold,
    rating: 'Moderate',
    highlightColor: theme.palette.success[500],
    backgroundColor: theme.palette.success[200],
  },
  {
    threshold: CONNECTION_STRENGTH_MAP[ConnectionStrength.Poor].threshold,
    rating: 'Low',
    highlightColor: theme.palette.warning[500],
    backgroundColor: theme.palette.warning[200],
  },
  {
    threshold: 0,
    rating: 'Weak',
    highlightColor: theme.palette.error[500],
    backgroundColor: theme.palette.error[200],
  },
];

export const getScoreRating = (value: number): string => {
  let scoreRating = '';
  for (const score of ScoreRating) {
    if (value >= score.threshold) {
      scoreRating = score.rating;
      break;
    }
  }
  return scoreRating;
};
