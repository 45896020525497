import { makeStyles, Theme } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';
import theme from 'theme';

const VERY_LIGHT_PURPLE = '#FFFAFF';

export interface Props {
  children: React.ReactNode;
  footer: React.ReactNode;
  color?: 'primary' | 'secondary' | 'white';
  footerBackgroundWeight?: number;
  footerTextColor?: string;
  footerClassName?: string;
  className?: string;
}

/**
 * Passing `white` as a color causes pretty drastic stylistic changes
 * */
const useStyles = makeStyles<Theme, Partial<Props>>({
  upSellWrapper: ({ color = 'secondary' }) => ({
    marginTop: 40,
    marginBottom: 40,
    width: 420,
    minWidth: 300,
    height: 'auto',
    background: color === 'white' ? 'white' : theme.palette[color][100],
    position: 'relative',
    borderRadius: '1rem',
    color: color === 'white' ? theme.palette.text.primary : theme.palette.text[color],
    transition: 'all .5s linear 4ms',
    '&:hover': {
      boxShadow: '0px 4px 12px -8px #C3D2C2',
      upslFooter: {
        background: color === 'white' ? 'white' : theme.palette[color][300],
      },
    },
    [theme.breakpoints.down('md')]: {
      width: '90%',
      height: 'auto',
      margin: '40px auto 40px',
      '&:hover': {
        upslFooter: {
          background: color === 'white' ? 'white' : theme.palette.text[color],
        },
      },
    },
  }),
  upSellFooter: ({
    color = 'secondary',
    footerBackgroundWeight = 200,
    footerTextColor,
  }) => ({
    outline: 'none',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: color === 'white' ? '20px' : '16px 32px',
    background:
      color === 'white'
        ? VERY_LIGHT_PURPLE
        : theme.palette[color][footerBackgroundWeight],
    borderRadius: '0 0 1rem 1rem',
    border: color === 'white' ? `1px solid ${theme.palette.grey[500]}` : 0,
    borderWith: color === 'white' ? '0 1px 1px 1px' : 0,
    borderTop: color === 'white' ? `2px solid ${theme.palette.primary.main}` : 0,
    color:
      footerTextColor || color === 'white'
        ? theme.palette.text.primary
        : theme.palette.text[color],
    cursor: color === 'white' ? 'auto' : 'pointer',
    transition: 'all .5s linear 4ms',
    textAlign: 'left',
    '&:hover': {
      background: color === 'white' ? VERY_LIGHT_PURPLE : theme.palette[color][300],
    },
    '& a': {
      color: 'inherit',
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      background: color === 'white' ? VERY_LIGHT_PURPLE : theme.palette.primary.main,
      color: color === 'white' ? theme.palette.text.primary : theme.palette.common.white,
    },
  }),
});

const SSFMessageWidgetWrapper = ({
  children,
  footer,
  color = 'secondary',
  footerBackgroundWeight,
  footerTextColor,
  className,
  footerClassName,
}: Props) => {
  const classes = useStyles({ color, footerBackgroundWeight, footerTextColor });
  return (
    <div className={classNames(classes.upSellWrapper, className)}>
      {children}
      <div className={classNames(classes.upSellFooter, footerClassName)}>{footer}</div>
    </div>
  );
};

export default SSFMessageWidgetWrapper;
