import { ContractStatus, ContractUnitType } from 'types';

const contractTypeDisplayStrings: Record<ContractUnitType, string> = {
  Cents: 'cent based',
  Credits: 'credit based',
  Recurring: 'seat based',
  Event: '',
  Unlimited: '',
};

export type ContractInfo = {
  name?: string;
  status?: ContractStatus;
  type?: ContractUnitType;
};

export const getContractName = (contract: ContractInfo) => {
  const contractUnitType = contract?.type || ContractUnitType.Credits;
  const contractStatus = contract?.status === 'Active' ? 'Active' : 'Inactive';
  return (
    contract?.name ||
    `${contractStatus} ${contractTypeDisplayStrings[contractUnitType]} contract`
  );
};
