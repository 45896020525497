import theme from 'theme';

const Credit = ({
  size = 16,
  color = theme.palette.primary.main,
  style = {},
  className = '',
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      style={style}
      viewBox={`0 0 16 16`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_330_1279)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.80227 4.80184C5.34629 2.43311 7.46655 0.666748 10.0003 0.666748C12.9458 0.666748 15.3337 3.05456 15.3337 6.00008C15.3337 8.53385 13.5673 10.6541 11.1986 11.1981C10.6549 13.5668 8.53392 15.3334 6.00033 15.3334C3.05481 15.3334 0.666992 12.9456 0.666992 10.0001C0.666992 7.46649 2.43364 5.34552 4.80227 4.80184ZM5.45404 6.03707C5.46077 6.03628 5.46748 6.03538 5.47417 6.03438C5.64635 6.01175 5.82197 6.00008 6.00033 6.00008C8.20946 6.00008 10.0003 7.79094 10.0003 10.0001C10.0003 10.1784 9.98865 10.3541 9.96603 10.5262C9.96503 10.5329 9.96413 10.5396 9.96333 10.5464C9.69693 12.4971 8.02415 14.0001 6.00033 14.0001C3.79119 14.0001 2.00033 12.2092 2.00033 10.0001C2.00033 7.97625 3.50334 6.30347 5.45404 6.03707ZM11.329 9.7743C11.2138 7.00838 8.99203 4.78663 6.2261 4.67144C6.77378 3.11555 8.25719 2.00008 10.0003 2.00008C12.2095 2.00008 14.0003 3.79094 14.0003 6.00008C14.0003 7.74322 12.8849 9.22663 11.329 9.7743Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_330_1279'>
          <rect width={size} height={size} fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Credit;
