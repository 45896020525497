import { css } from '@emotion/react';
import ChevronDown from '@mysteryco/design/icons/ChevronDown';
import ChevronUp from '@mysteryco/design/icons/ChevronUp';
import MessageQuestionCircle from '@mysteryco/design/icons/MessageQuestionCircle';
import {
  Coal_10,
  Coal_20,
  Coal_60,
  Glue_LavenderLight,
  Mint_20,
} from '@mysteryco/design/src/tokens/colors';
import RadioDropdown from 'components/RadioDropdown';
import { Tooltip } from 'glue/components/tooltip/Tooltip';
import { MEETUP_CADENCE_OPTIONS, getNextMeetupsRunDateString } from 'lib/helpers/meetups';
import React, { useState } from 'react';
import theme from 'theme';

type MeetupSummaryCardsProps = {
  memberCount: number;
  cadenceWeeks: number;
  lastRun: string;
  runAfter: string;
  setCadenceWeeks(cadenceWeeks: number): void;
};

const FrequencyDropdown = ({
  setSelectedCadence,
  selectedCadence,
  lastRun,
  runAfter,
}: {
  setSelectedCadence(value: number): void;
  selectedCadence: number;
  lastRun: string;
  runAfter: string;
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const icon = isDropdownOpen ? <ChevronUp /> : <ChevronDown />;

  return (
    <RadioDropdown
      onChange={(cadence: string) => setSelectedCadence(Number(cadence))}
      value={`${selectedCadence}`}
      options={MEETUP_CADENCE_OPTIONS}
      handleDropdownToggle={(open) => setIsDropdownOpen(open)}
      triggerElement={
        <div css={[styles.subTitleDropdownWrapper]}>
          <span
            css={[
              styles.settingSubtitle,
              styles.settingsClickableSubtitle({ isDropdownOpen }),
            ]}
          >
            {
              MEETUP_CADENCE_OPTIONS.find(
                (option) => Number(option.value) === selectedCadence,
              )?.label
            }
          </span>
          {icon}
        </div>
      }
      footerElement={
        <div css={styles.frequencyRadioFooterWrapper}>
          <p css={styles.frequencyRadioFooterTitle}>Next Meetups Round: </p>
          <p>
            {getNextMeetupsRunDateString({
              runAfter,
              lastRun,
              cadenceWeeks: selectedCadence,
            })}
          </p>
        </div>
      }
    />
  );
};

const SettingsCard = ({
  title,
  subtitle,
  tooltipText,
}: {
  title: string;
  subtitle: string | React.ReactElement;
  tooltipText?: string;
}) => (
  <div css={styles.settingsCard}>
    <div css={styles.settingText}>
      <div css={styles.settingTitle}>
        {title}
        {tooltipText && (
          <Tooltip
            content={<div css={{ width: '320px' }}>{tooltipText}</div>}
            size={'small'}
          >
            <span css={{ marginLeft: theme.spacing(2) }}>
              <MessageQuestionCircle size={14} color={Coal_20} />
            </span>
          </Tooltip>
        )}
      </div>
      {typeof subtitle === 'string' ? (
        <span css={styles.settingSubtitle}>{subtitle}</span>
      ) : (
        subtitle
      )}
    </div>
  </div>
);

function MeetupsSummaryCards(props: MeetupSummaryCardsProps) {
  return (
    <div
      css={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        rowGap: theme.spacing(4),
      }}
    >
      <SettingsCard
        title='Connection Focus'
        subtitle='Auto'
        tooltipText='Glue automatically identifies whether participants need a boost in cross-team, within-team or leadership connections and schedules a Meetup to address that focus area.'
      />
      <SettingsCard title='Total Cohort' subtitle={`${props.memberCount}`} />
      <SettingsCard
        title='Frequency'
        subtitle={
          <FrequencyDropdown
            selectedCadence={props.cadenceWeeks}
            setSelectedCadence={props.setCadenceWeeks}
            lastRun={props.lastRun}
            runAfter={props.runAfter}
          />
        }
      />
      <SettingsCard title='Length' subtitle={`30 min`} />
    </div>
  );
}

const styles = {
  settingsCard: css({
    width: '48%',
    background: Glue_LavenderLight,
    borderRadius: theme.spacing(4),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'self-start',
  }),
  settingText: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    width: '100%',
  }),
  settingTitle: css({
    fontWeight: 600,
    fontSize: `calc(${theme.spacing(4)} - 1px)`,
    color: Coal_20,
  }),
  subTitleDropdownWrapper: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  settingSubtitle: css({
    fontSize: 28,
    color: Coal_10,
    fontWeight: 500,
  }),
  settingsClickableSubtitle: ({ isDropdownOpen }) =>
    css({
      cursor: 'pointer',
      textDecoration: 'underline',
      textDecorationColor: isDropdownOpen ? Mint_20 : 'inherit',
      textUnderlineOffset: theme.spacing(1),
    }),
  frequencyRadioFooterWrapper: {
    backgroundColor: Coal_60,
    padding: `12px 20px`,
    fontSize: 13,
  },
  frequencyRadioFooterTitle: { fontWeight: 600, marginBottom: theme.spacing(1) },
};

export default MeetupsSummaryCards;
