import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { forwardRef } from 'react';
import theme from 'theme';
import { colors } from '@mysteryco/design';

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const StyledContent = styled(PopoverPrimitive.Content)({
  borderRadius: 4,
  padding: theme.spacing(5),
  minWidth: 120,
  backgroundColor: colors.Glue_Paper,
  zIndex: theme.zIndex.tooltip,
  boxShadow: `0px 20px 25px -5px rgba(155, 160, 166, 0.15), 0px 10px 10px -5px rgba(155, 160, 166, 0.04)`,
  border: `1px solid ${colors.Glue_BorderLight}`,
  opacity: 0,
  display: 'none',
  maxWidth: '90vw',

  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '300ms',
    animationTimingFunction: 'ease-out',
    animationFillMode: 'forwards',
    willChange: 'transform, opacity',
    '&[data-state="open"][data-side="top"]': {
      animationName: slideDownAndFade,
    },
    '&[data-state="open"][data-side="right"]': {
      animationName: slideLeftAndFade,
    },
    '&[data-state="open"][data-side="bottom"]': {
      animationName: slideUpAndFade,
    },
    '&[data-state="open"][data-side="left"]': {
      animationName: slideRightAndFade,
    },
  },
  '&[data-state="open"]': {
    opacity: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  '&[data-padding="false"]': {
    padding: 0,
  },
});

const StyledArrow = styled(PopoverPrimitive.Arrow)({
  fill: colors.Glue_Paper,
});

const StyledClose = styled(PopoverPrimitive.Close)({
  all: 'unset',
  fontFamily: 'inherit',
  borderRadius: '100%',
  height: 25,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: colors.Glue_Ink00,
  position: 'absolute',
  top: 5,
  right: 5,

  '&:hover': { backgroundColor: colors.Glue_LavenderLight },
});

// Exports
export const Popover = PopoverPrimitive.Root;
export const PopoverTrigger = styled(PopoverPrimitive.Trigger)({
  'button&[data-state="open"]': {
    boxShadow: `0px 3px 3px #E3E4F3`,
    borderColor: colors.Glue_Ink00,
  },
});
export const PopoverArrow = StyledArrow;
export const PopoverClose = StyledClose;
export const PopoverAnchor = PopoverPrimitive.Anchor;

export const PopoverContent = forwardRef<
  HTMLDivElement,
  PopoverPrimitive.PopoverContentProps & {
    containerClassName?: string;
    disablePadding?: boolean;
  }
>(function PopoverContent(
  { children, forceMount, containerClassName, disablePadding, ...props },
  ref,
) {
  return (
    <PopoverPrimitive.Portal forceMount={forceMount} className={containerClassName}>
      <StyledContent
        side='bottom'
        sideOffset={5}
        {...props}
        forceMount={forceMount}
        ref={ref}
        data-padding={!disablePadding}
      >
        {children}
      </StyledContent>
    </PopoverPrimitive.Portal>
  );
});
