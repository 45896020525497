import { useLocation, Redirect } from 'react-router-dom';
import { Paths } from 'Routes';
import { useViewer } from 'utils/state';

const RequireAuth = ({ children }) => {
  const viewer = useViewer();
  const location = useLocation();
  const redirectPath = `${location.pathname}${location.search}`;

  return !!viewer ? children : <Redirect to={`${Paths.AUTH}?redirect=${redirectPath}`} />;
};

export default RequireAuth;
