import { gql, useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { colors } from '@mysteryco/design';
import { Loading, SnackbarUtils } from 'components/core';
import FormikForm from 'glue/components/forms/FormikForm';
import FormikResetButton from 'glue/components/forms/FormikResetButton';
import FormikSubmitButton from 'glue/components/forms/FormikSubmitButton';
import { getPreferenceQuestions } from 'gql/queries';
import { existsFilter } from 'lib/helpers/maybe';
import theme from 'theme';
import {
  PreferenceAnswer,
  PreferenceCategory,
  PreferenceQuestionsFragmentFragment,
  PreferenceType,
  ViewerFragmentFragment,
  useUpdateUserMutation,
  useUserPreferenceStatusQuery,
} from 'types';
import Events from './Events';
import Interests from './Interests';
import Location from './Location';
import Participation from './Participation';

export const Preferences = ({ viewer }: { viewer: ViewerFragmentFragment }) => {
  const [updateUser] = useUpdateUserMutation();
  const { data: preferenceData } = useQuery(getPreferenceQuestions);
  const { data: userStatus, loading } = useUserPreferenceStatusQuery({
    variables: {
      userId: viewer?.id || '',
      orgId: viewer?.orgs?.[0]?.id || '',
    },
  });

  if (loading) return <Loading />;

  const interests =
    preferenceData?.preferenceQuestions.find(
      (question: PreferenceQuestionsFragmentFragment) =>
        question.type === PreferenceType.GeneralInterests,
    )?.preferenceAnswers || [];

  const activityQuestions =
    preferenceData?.preferenceQuestions.filter(
      (question: PreferenceQuestionsFragmentFragment) =>
        question.category === PreferenceCategory.Activity,
    ) || [];

  return (
    <div css={styles.root}>
      <FormikForm
        initialValues={{
          optOutOfWatercoolerAt: viewer.optOutOfWatercoolerAt || null,
          timezone: viewer.timezone || '',
          selectedInterests:
            interests
              ?.filter((interest: PreferenceAnswer) =>
                viewer.preferenceAnswers?.find((answer) => answer?.id === interest.id),
              )
              .map((interest: PreferenceAnswer) => interest.id)
              .sort() || [],
          activityAnswers:
            viewer.preferenceAnswers
              ?.filter(
                (answer) => answer?.question.category === PreferenceCategory.Activity,
              )
              .map((answer) => answer?.id)
              .filter(existsFilter)
              .sort() || [],
        }}
        enableReinitialize
        onSubmit={async (values) => {
          await updateUser({
            variables: {
              id: viewer.id,
              optOutOfWatercoolerAt: values.optOutOfWatercoolerAt,
              timezone: values.timezone,
              preferenceAnswers: values.selectedInterests
                .map((interest: string) => {
                  return { id: interest };
                })
                .concat(
                  values.activityAnswers.map((answer) => {
                    return { id: answer };
                  }),
                ),
            },
            refetchQueries: ['getViewer'],
            awaitRefetchQueries: true,
          });
          SnackbarUtils.success('Your preferences have been updated');
        }}
      >
        <div css={styles.headline}>
          <div css={styles.headlineText}>Update your personal preferences</div>
          <div css={styles.headlineButtons}>
            <FormikResetButton color={'white'}>Cancel</FormikResetButton>
            <FormikSubmitButton color={'black'} onlyDirty>
              Save changes
            </FormikSubmitButton>
          </div>
        </div>
        {userStatus?.userInWatercoolerInitiativeAudience && (
          <Participation isManager={!!userStatus?.viewer?.managerOf?.length} />
        )}
        <Location />
        <Interests interests={interests} />
        <Events activityQuestions={activityQuestions} />
      </FormikForm>
    </div>
  );
};

const styles = {
  root: css({
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(5)}`,
    margin: 'auto',
    maxWidth: '1200px',
  }),
  headline: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(5),
    gap: theme.spacing(20),
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(5)} 0`,
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: theme.spacing(4),
    },
  }),
  headlineText: css({
    fontWeight: 500,
    fontSize: '28px',
    lineHeight: '160%',
    letterSpacing: '-0.01em',
    color: colors.Coal_10,
  }),
  headlineButtons: css({
    display: 'flex',
    gap: theme.spacing(3),
  }),
};

Preferences.query = gql`
  query UserPreferenceStatus($userId: ID!, $orgId: ID!) {
    userInWatercoolerInitiativeAudience(userId: $userId, orgId: $orgId)

    viewer {
      id
      managerOf {
        id
      }
    }
  }
`;
