import { Elements } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import NotFoundPage from 'glue/components/navigation/NotFoundPage';
import QuestionDeepDive from 'glue/scenes/Explore/Pulse/questions/QuestionDeepDive';
import { MagicLinkSurveyVerification } from 'glue/scenes/MoralePulseSurvey/MagicLinkSurvey';
import RequireAuth from 'lib/hocs/RequireAuth';
import RequireRole from 'lib/hocs/RequireRole';
import { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Statsig } from 'statsig-react';
import { OrganizationRoleName } from 'types';
import { useViewer } from 'utils/state';
import config from './config';
import * as scenes from './glue/scenes';
import { SelfServeProvider } from './providers/SelfServeProvider';
import * as mysteryScenes from './scenes';

export const PageMetaData = {
  RSVP: { title: `RSVP to your Glue event` },
  INSIGHTS: { title: `Glue Explore` },
  SETTINGS_MYSTERY: { title: `Glue Settings` },
  PROFILE_MYSTERY: { title: `Glue Profile` },
  PROFILE: { title: `Glue Profile` },
  EVENTS: { title: `Glue Activities` },
  TEAM: { title: `Glue Team` },
  HOME: { title: `Glue Home` },
  ACCOUNT_SETUP: { title: `Glue Account Setup` },
  ACTIVITIES: { title: `Glue Activities` },
  EXPLORE: { title: `Glue Explore` },
  SETTINGS: { title: `Glue Settings` },
  MEETUPS: { title: `Glue Meetups` },
};

export const Paths = {
  ACCOUNT_SETUP: '/account-setup',
  ACTIVITIES: '/activities',
  AUTH: '/auth',
  AWAITING: '/settings/awaiting',
  BOOKED_EVENT: '/booked-event/:id',
  BOOKING: '/book',
  BOOKING_DEFAULT_START: '/book/details',
  BOOKING_STEP: '/book/:step',
  CANCELATION: '/cancel-policy',
  EVENT_DETAILS: '/event-details/:id',
  EVENTS: '/activities/events',
  EXPLORE: '/explore',
  EXPLORE_CONNECTIONS: '/explore/connections',
  EXPLORE_CONNECTIONS_DETAILS: '/explore/connections/:orgId/:teamId/:scoreType',
  EXPLORE_PULSE: '/explore/pulse',
  EXPLORE_PULSE_QUESTION: '/explore/pulse/question/:questionId',
  EXPLORE_PULSE_QUESTION_FEEDBACK: '/explore/pulse/question/:questionId/feedback',
  FEEDBACK: '/feedback/:productId',
  HOME: '/home',
  INVOICE: '/invoice/:id',
  JOIN_ORGANIZATION: '/join-organization/:id',
  LOG_OUT: `${config.api.url}/logout`,
  OFFSITES: '/offsites',
  ONBOARDING_TASK: '/task/:id',
  ORG_SIGN_UP: `/sign-up/:orgLinkPath`,
  PRIVACY_POLICY: '/privacy-policy',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  SKIP_FEEDBACK: '/skipfeedback/:id',
  TEAM: '/team',
  TEAM_EVENTS: '/team-events',
  TERMS_OF_SERVICE: '/terms-of-service',
  USER: '/user',
  MEETUPS: '/meetups',
  PULSE_SURVEY: '/pulse/user-survey/:id',
  PULSE_MAGIC_LINK: '/pulse/magic-link/:id',
  PULSE_SETTINGS: '/pulse/settings',
};

export const LegacyPaths = {
  EVENTS: '/events',
  INSIGHTS_DETAILS: '/insights/:orgId/:teamId/:scoreType',
  INSIGHTS: '/insights',
};

export const DEFAULT_SSF_ROUTE = Paths.BOOKING_DEFAULT_START;

export const Routes = ({ stripe }: { stripe: Promise<Stripe | null> }) => {
  const viewer = useViewer();
  const location = useLocation();
  const activePath = location.pathname;

  const statsigInitialized = Statsig.initializeCalled();
  useEffect(() => {
    if (!statsigInitialized) return;
    const { path, params } = extractSymbolicPath(activePath);
    Statsig.logEvent('b2b-dash:navigate', path, params);
  }, [statsigInitialized, activePath]);

  const pulseBeta2Enabled = Statsig.checkGate('morale_pulse_beta_2');

  return (
    <Switch>
      {/* Legacy routes */}
      <Redirect from={LegacyPaths.EVENTS} to={Paths.EVENTS} />
      <Redirect
        from={LegacyPaths.INSIGHTS_DETAILS}
        to={`${Paths.EXPLORE_CONNECTIONS_DETAILS}/${location.search}`}
      />
      <Redirect
        from={LegacyPaths.INSIGHTS}
        to={`${Paths.EXPLORE_CONNECTIONS}${location.search}`}
      />

      {/* Live routes */}
      <Route
        path={Paths.ORG_SIGN_UP}
        render={({ match }) => {
          const { params } = match;
          return (
            <Redirect
              to={{
                pathname: Paths.AUTH,
                search: `?org=${params.orgLinkPath}`,
              }}
            />
          );
        }}
      />
      <Route path={Paths.AUTH}>
        <mysteryScenes.Auth />
      </Route>
      <Route path={Paths.ACTIVITIES}>
        <RequireAuth>
          <scenes.Activities viewer={viewer} />
        </RequireAuth>
      </Route>
      <Route path={Paths.OFFSITES}>
        <RequireAuth>
          <RequireRole role={OrganizationRoleName.Admin}>
            <scenes.Offsites />
          </RequireRole>
        </RequireAuth>
      </Route>
      {pulseBeta2Enabled && (
        <Route path={Paths.EXPLORE_PULSE_QUESTION} component={QuestionDeepDive} />
      )}
      <Route path={Paths.EXPLORE}>
        <RequireAuth>
          <scenes.Explore />
        </RequireAuth>
      </Route>
      <Redirect exact path={Paths.BOOKING} to={DEFAULT_SSF_ROUTE} />
      <Route path={Paths.BOOKING_STEP}>
        <Elements stripe={stripe}>
          <RequireAuth>
            <SelfServeProvider>
              <RequireRole orgOptional role={OrganizationRoleName.Coordinator}>
                <mysteryScenes.SelfServeFlow />
              </RequireRole>
            </SelfServeProvider>
          </RequireAuth>
        </Elements>
      </Route>
      <Route path={Paths.CANCELATION}>
        <mysteryScenes.CancelPolicy />
      </Route>
      <Route path={Paths.EVENT_DETAILS}>
        <RequireAuth>
          <mysteryScenes.EventDetails viewer={viewer} />
        </RequireAuth>
      </Route>
      <Route exact path='/'>
        <Redirect to={Paths.HOME} />
      </Route>
      <Route path={Paths.ACCOUNT_SETUP}>
        <RequireAuth>
          <mysteryScenes.AccountSetup />
        </RequireAuth>
      </Route>
      <Route path={Paths.HOME}>
        <RequireAuth>
          <scenes.Home viewer={viewer} />
        </RequireAuth>
      </Route>
      <Route path={Paths.INVOICE}>
        <RequireAuth>
          <Elements stripe={stripe}>
            <mysteryScenes.Invoice viewer={viewer} />
          </Elements>
        </RequireAuth>
      </Route>
      {/* In case any emails with links to ~/join-organization/id are still floating around */}
      <Route path={Paths.JOIN_ORGANIZATION}>
        <RequireAuth>
          <Redirect to={Paths.HOME} />
        </RequireAuth>
      </Route>
      <Route path={Paths.BOOKED_EVENT}>
        <RequireAuth>
          <mysteryScenes.BookedEvent viewer={viewer} />
        </RequireAuth>
      </Route>
      <Route path={Paths.SETTINGS}>
        <RequireAuth>
          <RequireRole role={OrganizationRoleName.Admin}>
            <scenes.Settings viewer={viewer} />
          </RequireRole>
        </RequireAuth>
      </Route>
      <Route exact path={Paths.AWAITING}>
        <RequireAuth>
          <RequireRole role={OrganizationRoleName.Admin}>
            <mysteryScenes.Pending viewer={viewer} />
          </RequireRole>
        </RequireAuth>
      </Route>
      <Route path={Paths.TEAM}>
        <RequireAuth>
          <mysteryScenes.Team viewer={viewer} />
        </RequireAuth>
      </Route>
      <Route
        path={Paths.PROFILE}
        render={(props: any) => (
          <RequireAuth>
            <scenes.Profile viewer={viewer} {...props} />
          </RequireAuth>
        )}
      />
      <Route
        path={Paths.FEEDBACK}
        render={(props: any) => (
          <RequireAuth>
            <scenes.Feedback viewer={viewer} {...props} />
          </RequireAuth>
        )}
      />

      <Route path={Paths.SKIP_FEEDBACK}>
        <RequireAuth>
          <scenes.SkipFeedback />
        </RequireAuth>
      </Route>

      <Route path={Paths.TERMS_OF_SERVICE}>
        <mysteryScenes.TermsOfService />
      </Route>
      <Route path={Paths.TEAM_EVENTS}>
        <RequireAuth>
          {/* Go to start of registration flow on load */}
          <Redirect
            path={Paths.TEAM_EVENTS}
            to={`${Paths.TEAM_EVENTS}${location.search}`}
          />
          <mysteryScenes.TeamEvents />
        </RequireAuth>
      </Route>
      <Route
        path={Paths.MEETUPS}
        render={(props: any) => (
          <RequireAuth>
            <scenes.Meetups viewer={viewer} {...props} />
          </RequireAuth>
        )}
      />
      <Route path={Paths.USER}>
        <RequireAuth>
          <mysteryScenes.UserInfo viewer={viewer} />
        </RequireAuth>
      </Route>
      <Route path={Paths.PRIVACY_POLICY}>
        <mysteryScenes.PrivacyPolicy />
      </Route>
      <Route path={Paths.PULSE_SETTINGS}>
        <RequireAuth>
          <RequireRole role={OrganizationRoleName.Admin}>
            <scenes.PulseSettings />
          </RequireRole>
        </RequireAuth>
      </Route>
      <Route path={Paths.PULSE_SURVEY}>
        <RequireAuth>
          <scenes.MoralePulseSurvey viewer={viewer} />
        </RequireAuth>
      </Route>
      <Route path={Paths.PULSE_MAGIC_LINK}>
        <MagicLinkSurveyVerification />
      </Route>
      <Route path={Paths.ONBOARDING_TASK}>
        <RequireAuth>
          <scenes.OnboardingTask />
        </RequireAuth>
      </Route>
      <Route path='*'>
        <NotFoundPage />
      </Route>
    </Switch>
  );
};

const IS_UUID = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;

// Annoyingly, react-router won't give us the matched path unless we are
// within the context of the <Route> that defines it.
//
// So we have to parse out IDs and params heuristically…
function extractSymbolicPath(pathname: string) {
  const parts = pathname.split('/');

  let unnamedParamCount = 0;
  const params = {};
  const path = parts
    .map((part, index) => {
      if (!IS_UUID.test(part)) return part;

      const parentPart = parts[index - 1];
      const paramName =
        parentPart && !IS_UUID.test(parentPart)
          ? // If we have something like /foo/<UUID>, we treat it as /foo/:fooId
            `${parentPart}Id`
          : // Or, if there is no (non-UUID) parent, we treat it as /:id#.
            `id${++unnamedParamCount}`;

      params[paramName] = part;
      return `:${paramName}`;
    })
    .join('/');

  return { path, params };
}

export default Routes;
