import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  PopoverProps,
  ListSubheader,
  Typography,
} from '@material-ui/core';
import { Dispatch, SetStateAction } from 'react';
import { makeStyles } from '@material-ui/styles';
import theme from 'theme';
import Check from './icons/Check';

export interface SelectFilterProps {
  label: string;
  options: SelectFilterOption[];
  selectedOptions: SelectFilterOption[];
  setSelectedOptions: Dispatch<SetStateAction<any[]>>;
  disabled?: boolean;
}

export interface SelectFilterOption {
  name: string;
  id: any;
}

const SelectFilterOriginal = ({
  label,
  options,
  selectedOptions,
  setSelectedOptions,
  disabled = false,
}: SelectFilterProps) => {
  const classes = useStyles();
  const MenuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      horizontal: 'left',
      vertical: 'bottom',
    },
  } as PopoverProps;
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOptions(options.filter((option) => value.includes(option.id)));
  };
  const renderValue = () => selectedOptions.map((val) => val.name).join(', ');
  const getValue = () => selectedOptions.map((val) => val.id);
  return (
    <>
      <FormControl fullWidth className={classes.formControl}>
        <InputLabel id='select-filter-label'>{label}</InputLabel>
        <Select
          labelId='select-filter-label'
          multiple
          value={getValue()}
          onChange={handleChange}
          renderValue={renderValue}
          input={<OutlinedInput label={label} />}
          MenuProps={MenuProps}
          disabled={disabled}
        >
          <ListSubheader className={classes.selectHelper}>
            Select one or many
          </ListSubheader>
          {options.map((option) => (
            <MenuItem
              key={option.id}
              value={option.id}
              className={classes.listItem}
            >
              <Typography>{option.name}</Typography>
              <Check size={16} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

const useStyles = makeStyles({
  formControl: {
    maxWidth: '15rem',
    '& .MuiInputLabel-formControl': {
      left: theme.spacing(3.5),
      top: theme.spacing(-1.5),
      '&.MuiInputLabel-shrink': {
        top: theme.spacing(-2),
      },
    },
    '& .MuiInputBase-root': {
      borderRadius: theme.spacing(2),
      height: '52px',
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${theme.palette.primary[400]}`,
        boxShadow: '0px 4px 8px rgba(234, 234, 234, 0.8)',
      },
      '&:focus .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${theme.palette.primary[400]}`,
        boxShadow: '0px 4px 8px rgba(234, 234, 234, 0.8)',
      },
      '&:active .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${theme.palette.primary[400]}`,
        boxShadow: '0px 4px 8px rgba(234, 234, 234, 0.8)',
      },
      '&.MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${theme.palette.primary[100]}`,
      },
    },
    '& .MuiInputBase-input': {
      boxSizing: 'none',
    },
    '& .MuiSelect-root': {
      '&:focus': {
        background: 'none',
      },
    },
  },
  selectHelper: {
    lineHeight: '2',
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightLight,
    color: theme.palette.grey[900],
  },
  listItem: {
    justifyContent: 'space-between',
    '&.MuiListItem-root': {
      '& path': {
        fill: 'transparent',
      },
      '&:hover:not(.Mui-selected), &:focus:not(.Mui-selected)': {
        backgroundColor: theme.palette.primary[100],
        '& path': {
          fill: theme.palette.primary[600],
        },
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.secondary[100],
        '&:hover, &:focus': {
          backgroundColor: theme.palette.secondary[200],
        },
        '& path': {
          fill: theme.palette.secondary[600],
        },
      },
    },
  },
});

export default SelectFilterOriginal;
