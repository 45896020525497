// @ts-strict-ignore
import { getOrganizationQuery } from 'gql/queries';
import { useEffect, useState } from 'react';
import {
  useCreateUserTagsMutation,
  useDeleteUserTagsMutation,
  useRenameUserTagMutation,
  UserTag,
  UserTagType,
} from 'types';
import OptionSelect from './OptionSelect';
import EditUserTag, {
  isTagChanged,
  isTagDuplicate,
  tagFilterOptions,
} from 'components/Forms/EditUserTag';
import TagChip from 'components/Chips/TagChip';
import { TeamPageOrganization } from 'scenes/Team';

const getValueAsUserTag = (value: string): UserTag => {
  return { name: value } as UserTag;
};

const renderUserTagInput = (userTag: UserTag) => {
  return userTag?.name;
};

interface TagSelectProps {
  organization: TeamPageOrganization;
  value?: string[];
  onChange?: (event?: any, newValue?: string[]) => void;
  multiple?: boolean;
  disabled?: boolean;
}

const TagSelect = ({
  organization,
  value = [],
  onChange = () => {},
  multiple = false,
  disabled = false,
  ...props
}: TagSelectProps) => {
  const [selected, setSelected] = useState(value);
  const [renameTag] = useRenameUserTagMutation();
  const [createTag] = useCreateUserTagsMutation();
  const [deleteTag] = useDeleteUserTagsMutation();

  const organizationId = organization?.id;
  const userTags = (organization?.userTags || []) as UserTag[];
  const userTagOptions = userTags.filter((tag) => tag.type === UserTagType.Manual);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const handleChange = (event: any, newTags: string[]) => {
    setSelected(newTags);
    onChange(event, newTags);
  };

  const handleRemove = (event: any, id: string) => {
    handleChange(
      event,
      selected.filter((value) => id !== value),
    );
  };

  const handleCreate = async (name: string) => {
    const result = await createTag({
      variables: { names: [name], organizationId },
      refetchQueries: [{ query: getOrganizationQuery }],
      awaitRefetchQueries: true,
    });
    return result?.data?.createUserTags?.[0]?.id;
  };

  const handleSave = async (userTag: UserTag) => {
    await renameTag({
      variables: { tagId: userTag.id, name: userTag.name },
      refetchQueries: [{ query: getOrganizationQuery }],
    });
  };

  const isValid = (newTag: UserTag): boolean => {
    return (
      newTag?.name &&
      !isTagDuplicate(newTag, userTagOptions) &&
      isTagChanged(newTag, userTagOptions)
    );
  };

  const RenderUserTagOption = (userTag: UserTag) => {
    if (!userTag) return <></>;
    return <TagChip text={userTag.name} />;
  };

  const RenderUserTagTag = (userTag: UserTag) => {
    if (!userTag) return <></>;
    return (
      <TagChip
        text={userTag.name}
        key={userTag.id}
        onDelete={(e) => handleRemove(e, userTag.id)}
        disabled={disabled}
      />
    );
  };

  const handleDelete = async (
    event: any,
    tagIds: string[],
    thisOnChange: (event: any, value: UserTag) => void,
  ) => {
    await deleteTag({
      variables: { tagIds },
      refetchQueries: [{ query: getOrganizationQuery }],
    });
    thisOnChange(event, null);
    handleRemove(event, tagIds?.[0]);
  };

  const RenderActiveUserTag = (
    userTag: UserTag,
    thisOnChange: (event: any, newValue: UserTag) => void,
  ) => {
    return (
      <EditUserTag
        userTag={userTag}
        userTagOptions={userTagOptions}
        onChange={thisOnChange}
        onDelete={handleDelete}
      />
    );
  };

  return (
    <>
      <OptionSelect
        options={userTagOptions}
        values={value}
        filterOptions={tagFilterOptions}
        onChange={handleChange}
        onCreate={async (_, newValue) => {
          return await handleCreate(newValue);
        }}
        onSave={async (_, userTag) => handleSave(userTag)}
        placeholderEmpty='No tags'
        placeholderFocus='Search or add'
        helperText={
          userTagOptions.length
            ? 'Select a tag or create a new one'
            : 'Begin typing to create a new tag'
        }
        getValueAsOption={getValueAsUserTag}
        isValid={isValid}
        renderOption={RenderUserTagOption}
        renderActive={RenderActiveUserTag}
        renderInput={renderUserTagInput}
        renderTag={RenderUserTagTag}
        multiple={multiple}
        disabled={disabled}
        disableSelectAll
        {...props}
      />
    </>
  );
};

export default TagSelect;
