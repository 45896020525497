import { css } from '@emotion/react';
import { Widget } from '@typeform/embed-react';
import PageWrapper from 'glue/components/structure/PageWrapper';

const Offsites = () => {
  return (
    <PageWrapper title={'Offsites'}>
      <Widget id='Taz50gXP' css={styles.typeform} />
    </PageWrapper>
  );
};

const styles = {
  typeform: css({
    width: '80%',
    height: '700px',
  }),
};

export default Offsites;
