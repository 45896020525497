import { CSSProperties } from 'react';
import theme from 'theme';

type Props = {
  size?: number;
  style?: CSSProperties;
  className?: string;
  color?: string;
};

const Offsite = ({
  size = 24,
  style,
  className,
  color = theme.palette.primary.main,
}: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      style={style}
    >
      <path
        d='M17.6207 10.1643C19.039 11.5068 19.9186 14.5841 17.6207 17.0295C15.6432 10.5535 9.2423 8.58498 4.26871 14.5841C3.18681 11.6003 5.5023 8.09616 8.62112 7.23911'
        stroke={color}
        strokeWidth='2'
        strokeLinejoin='round'
      />
      <path
        d='M12 10.6014C15.877 9.57978 18.7924 9.71024 22.9767 11.6039C22.9767 5.05961 13.3939 3.71082 12 10.6014Z'
        stroke={color}
        strokeWidth='2'
        strokeLinejoin='round'
      />
      <path
        d='M1.01883 4.73362C4.78979 5.0208 8.55252 5.33228 12.0025 10.6061C14.2627 0.125579 4.65689 0.677178 1.01883 4.73362Z'
        stroke={color}
        strokeWidth='2'
        strokeLinejoin='round'
      />
      <path
        d='M13.7274 12C13.5646 13.9241 13.5321 18.6119 14.7039 21.9703'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.1711 11.0583C9.24125 13.2407 8.59794 19.2423 9.24124 21.9703'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Offsite;
