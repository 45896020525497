// @ts-strict-ignore
import {
  CostUnit,
  InvitedGuestRsvpStatus,
  TeamEventFragmentFragment,
  TeamEventStatus,
} from 'types';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Laptop01 from 'components/icons/Laptop01';
import DetailSection from './DetailSection';
import EventCost from './EventCost';
import { MIN_SURPRISE_TO_ALL_SEAT_COUNT } from 'components/SSFSteps/UploadGuests';
import InviteGuests from 'components/InviteGuests';
import { DateTime } from 'luxon';

const EventType = ({ hasPhysicalGoods }: { hasPhysicalGoods: boolean }) => {
  const classes = useStyles();
  return (
    <DetailSection label='Event Type'>
      <Box className={classes.iconData}>
        <Laptop01 />
        <Box>{hasPhysicalGoods ? 'Virtual event with supplies' : 'Virtual event'}</Box>
      </Box>
    </DetailSection>
  );
};

const EventDetails = ({
  teamEvent,
  guests,
}: {
  teamEvent: TeamEventFragmentFragment;
  guests: {
    email: string;
    rsvpStatus: InvitedGuestRsvpStatus;
  }[];
}) => {
  const classes = useStyles();

  const { expectedHeadCount, finalHeadCount, status, experience, rsvpSummary } = teamEvent;

  const isComplete = [TeamEventStatus.Complete, TeamEventStatus.ReadyToReview].includes(
    status,
  );
  const canInvite = teamEvent.signUpExpirationDate
    ? !isComplete && DateTime.now() < DateTime.fromISO(teamEvent.signUpExpirationDate)
    : !isComplete;

  const minimumEventSize = teamEvent?.surpriseToAll
    ? MIN_SURPRISE_TO_ALL_SEAT_COUNT
    : experience?.cost?.ctcPricingBands?.find((band) => band.unit === CostUnit.Event)
        ?.maxUsers;

  const showInvitedGuests =
    teamEvent.surpriseToAll ||
    (teamEvent.status && teamEvent.status !== TeamEventStatus.Requested);

  return (
    <Box className={classes.container}>
      <EventType hasPhysicalGoods={experience?.hasPhysicalGoods}></EventType>
      <EventCost
        teamEvent={teamEvent}
        experience={experience}
        minimumEventSize={minimumEventSize}
      />
      <InviteGuests
        guests={guests}
        teamEventId={teamEvent.id}
        joinLink={teamEvent.joinLink}
        isComplete={isComplete}
        canInvite={canInvite}
        expectedHeadCount={rsvpSummary?.total || expectedHeadCount}
        minimumEventSize={minimumEventSize}
        finalHeadCount={finalHeadCount}
        showInvitedGuests={showInvitedGuests}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8),
  },
  iconData: {
    display: 'flex',
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    alignItems: 'center',
    fontSize: '.875rem',
  },
  guestData: {
    padding: theme.spacing(4),
  },
}));

export default EventDetails;
