import { gql } from '@apollo/client';
import { Link } from '@material-ui/core';
import ArrowRight from '@mysteryco/design/icons/ArrowRight';
import RegistrationStepWrapper from 'components/Checkout/styling/RegistrationStepWrapper';
import { sharedStyles } from 'components/Checkout/styling/sharedStyles';
import FormikCheckboxField from 'glue/components/forms/FormikCheckboxField';
import FormikForm from 'glue/components/forms/FormikForm';
import FormikSubmitButton from 'glue/components/forms/FormikSubmitButton';
import mixpanel from 'mixpanel-browser';
import { useUpdateUserLegalMutation } from 'types';
import { PRIVACY_POLICY_URL, TOS_URL } from 'utils/stringUtil';
import * as yup from 'yup';

const validationSchema = yup.object({
  privacyPolicyAndTosAccepted: yup
    .bool()
    .isTrue('Privacy policy and terms of use must be accepted before proceeding'),
});

const LegalStep = ({ viewer, ...context }) => {
  const [updateUser, { loading, error }] = useUpdateUserLegalMutation({
    variables: {
      id: viewer.id,
    },
  });
  const initialValues = {
    marketingConsent: viewer.marketingConsent || false,
    privacyPolicyAndTosAccepted: false,
  };

  const onSubmit = async () => {
    mixpanel.track('Continue clicked', {
      source: 'registration',
      step: context.step,
      customerType: viewer?.customerType,
      firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
    });
    mixpanel.track('legal agreements accepted', {
      source: 'registration',
      customerType: viewer?.customerType,
      firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
    });
    await updateUser();
    if (!error) context.next();
  };

  return (
    <RegistrationStepWrapper {...context} label='Welcome back!' hideButtons>
      <FormikForm
        initialValues={initialValues}
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <FormikCheckboxField
          name='privacyPolicyAndTosAccepted'
          label={
            <div css={sharedStyles.checkboxLabel}>
              I've read and agree to Glue's{' '}
              <Link
                href={PRIVACY_POLICY_URL}
                target='_blank'
                rel='noopener'
                underline='always'
              >
                Privacy Policy
              </Link>{' '}
              and{' '}
              <Link href={TOS_URL} target='_blank' rel='noopener' underline='always'>
                Terms of Use
              </Link>
            </div>
          }
        />
        <FormikCheckboxField
          name='marketingConsent'
          label={
            <div css={sharedStyles.checkboxLabel}>
              <div css={sharedStyles.checkboxHeading}>Subscribe to our newsletter</div>
              <div>Receive tips and product updates from Glue</div>
            </div>
          }
        />
        <FormikSubmitButton
          onlyValid
          css={sharedStyles.button}
          endIcon={<ArrowRight size={16} />}
          loading={loading && !error}
        >
          Continue
        </FormikSubmitButton>
      </FormikForm>
    </RegistrationStepWrapper>
  );
};

LegalStep.mutation = gql`
  mutation updateUserLegal($id: ID!) {
    updateUser(id: $id, wasPromptedForMarketingConsent: true) {
      id
      hasAgreedToLatestTOS
      promptedForMarketingConsent
    }
    acceptLatestLegalAgreements {
      id
    }
  }
`;

export default LegalStep;
