import { createTheme, ThemeOptions } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles';
import { colors } from '@mysteryco/design';

export const themeOptions: ThemeOptions = {
  typography: {
    // see src/index.css for font-family
    fontFamily: 'inherit',
    fontWeightLight: 300,
    fontWeightBold: 600,
    fontWeightMedium: 500,
  },
  spacing: (factor) => `${0.25 * factor}rem`,
  palette: {
    primary: {
      // purples
      main: '#7B1783', // purple
      light: '#F9F4F9', // ghost pink
      50: '#FFFDFF',
      100: '#F2E8F3',
      200: '#E5D1E6',
      300: '#D7B9DA',
      400: '#CAA2CD',
      500: '#BD8BC1',
      600: '#B074B5',
      700: '#A35DA8',
      800: '#95459C',
      900: '#882E8F',
    },
    secondary: {
      // greens
      main: '#67DB5E',
      50: '#FCFFFC',
      100: '#F2FFF1',
      200: '#DEFFDC',
      300: '#C3FFBE',
      400: '#9DFC95',
      500: '#67DB5E',
      600: '#2D8A26',
    },
    success: {
      // blues
      200: '#E3FCFF',
      300: '#94F3FF',
      400: '#30E3F9',
      500: '#0FC2D8',
      600: '#11B5C9',
      700: '#046B78',
    },
    error: {
      // orange
      100: '#FFF3ED',
      200: '#FFE1D0',
      300: '#FFBD98',
      400: '#FB9054',
      500: '#F37046',
      600: '#A1330F',
      700: '#EC420A',
    },
    warning: {
      //yellows
      200: '#FFFAE9',
      300: '#FEE18B',
      400: '#FDCC35',
      500: '#E3B832',
      600: '#84670F',
    },
    grey: {
      100: '#FDFCFE',
      200: '#f2f0f3',
      300: '#F2F2F2',
      500: '#D0D0D0',
      700: '#4F4F4F',
      800: '#909090',
      900: '#767676',
    },
    info: {
      main: '#8007F0', // same as Highlight Purple
    },
    text: {
      primary: '#2B0A45', // midnight
      secondary: '#5C296C', // dusk
      disabled: '',
    },
    common: {
      white: '#FFF',
      black: '#000',
    },
  },
  overrides: {
    MuiOutlinedInput: {
      input: {
        '&$disabled': {
          backgroundColor: colors.Glue_LavenderLight,
          // this color isn't accessible at the current font weight...
          // until we glueify inputs more completely we'll just use a darker gray
          // color: colors.Glue_LavenderDark,
          color: '#767676',
        },
      },
      root: {
        '&$disabled': {
          notchedOutline: {
            borderColor: colors.Glue_BorderLight,
          },
        },
      },
    },
  },
};

const theme = createTheme(themeOptions);

// Note that we have a few unique typographical styles, so we piggyback on MUI's
// themeing infrastructure to propagate it around the component hierarchy.
declare module '@material-ui/core/styles/createTypography' {
  export interface Typography {
    actionLabel: React.CSSProperties;
  }
}

theme.typography.actionLabel = {
  fontWeight: 500,
  fontFamily: `'Roboto Mono', monospaced`,
  textTransform: 'uppercase',
  letterSpacing: '-.5px',
};

export const primaryGradient = `linear-gradient(90deg, ${theme.palette.primary[700]} 0%, ${theme.palette.primary.main} 100%)`;
export const secondaryGradient =
  'linear-gradient(90deg, #7A1783 0%, #BE207A 27.6%, #9747FF 68.75%, #EB5757 97.92%)';

export const selectedOptionBackground = alpha(theme.palette.primary.main, 0.1);

export const highlightPurple = '#8007F0';

export default theme;
