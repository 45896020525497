import { DateTime } from 'luxon';
import {
  BookingOptionsPresentedInput,
  DummyEvent,
  MysteryTemplate,
  User,
  OperatingHours,
  ParticipationModel,
  GetUserWithOrgsQuery,
  EventGoal,
  Maybe,
  UserTagType,
  ContractFragmentFragment,
} from '../types';

export interface TagSelectionType {
  [key: string]: { key: string; value: string[]; label: string };
}

export interface EventSelectionArgs {
  hasPhysicalGoods: boolean;
  requiresUpgrade: boolean;
  event?: DummyEvent;
  template?: Partial<MysteryTemplate>;
  experiences?: { id: string }[];
  requestBookingHeadCount?: number;
  requestBookingDateTime?: DateTime;
}

export type ValueLabel<T> = {
  value: T;
  label: string;
};

export interface EventDetailsType {
  interactiveOrEntertaining: string;
  hasPhysicalGoods: boolean;
  goals?: EventGoal[];
  multiSelectOptions: TagSelectionType;
  requestHeadCount: number;
}

export interface GroupType {
  __typename: string;
  id: string;
  name: string;
  type: 'tag' | 'team' | UserTagType;
  users: Partial<User>[];
}
export interface GuestsType {
  groups: GroupType[];
  otherGuests: { email: string; name?: string }[];
}
export interface UploadGuests {
  checkingCalendarAvailability: boolean;
  checkedCalendarAvailability: boolean;
  guests: GuestsType;
  totalRecipientsEstimate: number;
}

interface SelfServeStepValue {
  key: string;
  title: string;
  Content: any;
  schema: any;
  getSubtitle?({ label }: { label: string }): string;
}

interface Payment {
  bookSuccessful: boolean;
}

export interface SelfServeStep {
  key: string;
  value: SelfServeStepValue;
}

export enum GuestInputType {
  idkyet = 'idkyet',
  imready = 'imready',
}
interface Summary {
  title: string;
  purpose: string;
  participantFamiliarity: number;
  participationModel: ParticipationModel;
}

export interface SelfServeState {
  accountFinished?: boolean;
  contract?: ContractFragmentFragment;
  budget?: ValueLabel<1 | 2 | 3 | 4>;
  eventDetails?: EventDetailsType;
  eventDate?: {
    availableEventTimes: EventForSelection[];
    emailsNotOnProvider?: string[];
    hasWorkingHours: boolean;
  };
  eventSelection?: EventSelectionArgs;
  uploadGuests?: UploadGuests;
  guestInputType?: GuestInputType;
  bookedEvent?: { id: string; initialPrice: { totalCents: number } };
  optionsPresented?: BookingOptionsPresentedInput[];
  payment?: Payment;
  summary?: Summary;
  isRequestToBook?: boolean;
  surpriseSelection?: { surpriseToAll: boolean; surpriseToAttendees: boolean };
}

export interface SelfServeStepValidationMap {
  budget: boolean;
  eventDetails: boolean;
  account: boolean;
  eventDate: boolean;
  eventSelection: boolean;
  guests: boolean;
  payment: boolean;
}

export interface MatchedExperience {
  id: string;
  estimatedDurationMins: number;
  name: string;
  hasPhysicalGoods: boolean;
  rating: number;
  requiresUpgrade: boolean;
  operatingHours: OperatingHours[];
  location: {
    timezone: string;
  };
  usaShipping: { estimatedLeadTimeDays?: number };
  templates: MysteryTemplate[];
}

export type ExperienceInfo = Omit<MatchedExperience, 'templates'>;
export type TemplateWithExperienceInfo = Omit<MysteryTemplate, 'experiences'> & {
  experiences: ExperienceInfo[];
};

export interface EventInfo {
  id: string;
  start: string;
  end: string;
  emails?: Maybe<Maybe<string>[]>;
}
export interface EventForSelection extends EventInfo {
  openExperiences?: { id: string }[];
  rtbExperiences?: { id: string }[];
}

export type EventArrayByDateString = {
  [dateString: string]: EventInfo[];
};

export type ViewerOrg = Exclude<
  GetUserWithOrgsQuery['viewer'],
  null | undefined
>['orgs'][0];
