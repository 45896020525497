import Button from 'glue/components/buttons/Button';
import mixpanel from 'mixpanel-browser';
import { useState } from 'react';
import { isUpsellable } from 'utils/customerTypeUtils';
import { isAdmin } from 'utils/security';
import { useViewer } from 'utils/state';

import { Drawer } from '@material-ui/core';
import ArrowRight from '@mysteryco/design/icons/ArrowRight';

import UpsellSubscription from './UpsellSubscription';

interface Props {
  page: string;
}

const UpgradeButton = ({ page }: Props) => {
  const viewer = useViewer();
  const [open, setOpen] = useState(false);

  const canUpsell = isAdmin(viewer) && isUpsellable(viewer?.customerType);

  const onClick = () => {
    mixpanel.track('header upgrade clicked', {
      source: page,
      customerType: viewer?.customerType,
      firstTimeBooker: !viewer?.requestedTeamEvents?.length,
    });
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      {canUpsell && (
        <Button
          color='green'
          shape='round'
          endIcon={<ArrowRight size={24} color='currentColor' />}
          onClick={onClick}
        >
          Upgrade plan
        </Button>
      )}
      <Drawer anchor='right' open={open} onClose={onClose}>
        <UpsellSubscription onClose={onClose} />
      </Drawer>
    </>
  );
};

export default UpgradeButton;
