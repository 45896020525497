import { useFormikContext } from 'formik';
import Button, { ButtonProps } from 'glue/components/buttons/Button';

export type FormikSubmitButtonProps = ButtonProps & {
  /** Prevent submitting a form which hasn't been changed since initialization */
  onlyDirty?: boolean;
  /** Prevent submitting a form which has validation errors */
  onlyValid?: boolean;
};

/**
 * A submit button wired up to Formik. This button will automatically disable
 * itself when the form is submitting, and can be configured with props to
 * prevent submission based on validation or dirty state.
 */
export function FormikSubmitButton({
  onlyDirty,
  onlyValid,
  disabled,
  ...props
}: FormikSubmitButtonProps) {
  const ctx = useFormikContext();
  const disableInternal =
    (onlyDirty && !ctx.dirty) || (onlyValid && !ctx.isValid) || disabled;

  return (
    <Button
      loading={ctx.isSubmitting}
      {...props}
      type='submit'
      disabled={disableInternal}
    />
  );
}

export default FormikSubmitButton;
