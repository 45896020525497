import { Box, Container } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import { loadStripe } from '@stripe/stripe-js';
import { Loading } from 'components/core';
import { default as DrawerWrapper } from 'components/BrandWrapper';
import useHubspotChat from 'hooks/useHubspotChat';
import { Settings } from 'luxon';
import mixpanel from 'mixpanel-browser';
import { useEffect, useMemo, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { identify } from 'react-fullstory';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Routes from 'Routes';
import { StatsigProvider } from 'statsig-react';
import { CustomerType, useGetViewerQuery } from 'types';
import { isAdmin, isAdminOrCoordinator } from 'utils/security';
import config, { isProd } from './config';
import { TooltipProvider } from '@radix-ui/react-tooltip';
import { HelmetProvider } from 'react-helmet-async';

// We use locale strings to group some data in SSF (and maybe elsewhere). Surprisingly (SIKE!) other locale's
// use different formats and this breaks our code. Set everything to US until we
// do localization right. M-1708 for more info (https://linear.app/mystery/issue/M-1708/bug-platform-not-permitting-contract-customer-dx-to-book-events)
Settings.defaultLocale = 'en-us';

export const HEADER_HEIGHT = 80;

const stripe = loadStripe(config.stripe.publishableKey);
const guestRoutes = [
  {
    label: 'Home',
    href: '/home',
  },
];

const eventRoutes = [
  {
    label: 'Events',
    href: '/events',
  },
];
const adminRoutes = [
  {
    label: 'Team',
    href: '/team',
  },
];
const insightsRoutes = [
  {
    label: 'Insights',
    href: '/insights',
  },
];

function App() {
  const { data, loading } = useGetViewerQuery();
  const [navItems, setNavItems] = useState(guestRoutes);
  const viewer = data?.viewer;

  config.facebookPixel && ReactPixel.init(config.facebookPixel);
  mixpanel.init(config.mixpanel);

  if (isProd && viewer) {
    Sentry.setUser({
      email: viewer.email!,
      username: viewer.name!,
      id: viewer.id,
    });
  }

  const createNavItems = (viewer) => {
    const items = [...guestRoutes];
    if (isAdmin(viewer) || viewer?.customerType === CustomerType.Transactional)
      items.push(...[...adminRoutes]);
    if (viewer.requestedTeamEvents.length > 0 || isAdminOrCoordinator(viewer))
      items.push(...eventRoutes);
    if (isAdmin(viewer) || viewer?.customerType === CustomerType.Transactional) {
      items.push(...insightsRoutes);
    }
    return items;
  };

  useEffect(() => {
    if (viewer) {
      mixpanel.identify(viewer.id);
      mixpanel.people.set({
        $name: viewer.name,
        $email: viewer.email,
        role: viewer.role,
      });
      setNavItems(createNavItems(viewer));
      if (config.fullStory.enabled) {
        // Identify the user to FullStory for production sessions only
        identify(viewer.id, {
          name: viewer.name!,
          email: viewer.email!,
        });
      }
    }
  }, [viewer]);

  useHubspotChat();

  const statsigUser = useMemo(() => {
    if (viewer) {
      return {
        userID: viewer.id,
        email: viewer.email || undefined,
        custom: {
          role: viewer.role,
        },
      };
    }
    return {
      userID: undefined,
      email: undefined,
    };
  }, [viewer]);

  if (loading) {
    return (
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        height='100vh'
        width='100vw'
      >
        <Loading />
      </Box>
    );
  }

  return (
    <HelmetProvider>
      <TooltipProvider>
        <StatsigProvider
          sdkKey={config.statsig.key}
          user={statsigUser}
          options={{
            environment: {
              tier: config.env,
            },
            localMode: config.env === 'test',
          }}
          waitForInitialization
        >
          <Router>
            <DrawerWrapper
              navItems={navItems}
              children={
                <Container
                  maxWidth={false}
                  css={{ minWidth: 0, display: 'flex', flexDirection: 'column' }}
                >
                  <Switch>
                    <Routes stripe={stripe} />
                  </Switch>
                </Container>
              }
            />
          </Router>
        </StatsigProvider>
      </TooltipProvider>
    </HelmetProvider>
  );
}

export default App;
