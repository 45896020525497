import {
  Box,
  Typography,
  ButtonGroup as MuiButtonGroup,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useResponsive } from 'ahooks';
import { memo, useCallback } from 'react';
import theme from 'theme';

type Value = string | number;

type Props = {
  multiselect?: boolean;
  options: { value: Value; label: string }[];
  title: string;
  titleAlign?: 'left' | 'center' | 'right';
  value: Value | Value[];
  setValue: (newValue: Value | Value[]) => void;
};

const useStyles = makeStyles({
  buttonGroup: {
    '& .MuiButtonBase-root': {
      flex: 1,
      textTransform: 'none',
    },
    '& .MuiButton-contained': {
      color: theme.palette.primary.main,
      background: theme.palette.primary[100],
    },
  },
  titleSection: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2rem',
    fontWeight: 'bold',
  },
});

const SelfServeButtonGroup = ({
  options,
  title,
  titleAlign,
  multiselect,
  value,
  setValue,
}: Props) => {
  const classes = useStyles();
  const { lg } = useResponsive();

  const variant = useCallback(
    (optionValue: Value) => {
      const isArray = Array.isArray(value);
      const selected = isArray
        ? (value as Array<Value>).includes(optionValue)
        : value === optionValue;
      return selected ? 'contained' : 'outlined';
    },
    [value],
  );

  const onClick = useCallback(
    (optionValue: Value) => {
      if (multiselect) {
        const asArray = (value as Array<Value>)?.length ? (value as Array<Value>) : [];
        asArray.includes(optionValue)
          ? setValue(asArray.filter((v) => v !== optionValue))
          : setValue([...asArray, optionValue]);
      } else {
        setValue(optionValue);
      }
    },
    [value, setValue],
  );

  return (
    <Box className={classes.titleSection}>
      <Typography
        css={{
          textAlign: titleAlign || lg ? 'left' : 'center',
          marginBottom: theme.spacing(2),
          fontSize: 16,
        }}
      >
        {title}
      </Typography>
      <MuiButtonGroup className={classes.buttonGroup} variant='outlined' disableElevation>
        {options.map((option) => (
          <Button
            key={`button-group-button-${option.value}`}
            variant={variant(option.value)}
            onClick={() => onClick(option.value)}
          >
            {option.label}
          </Button>
        ))}
      </MuiButtonGroup>
    </Box>
  );
};

export default memo(SelfServeButtonGroup);
