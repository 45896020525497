import {
  SelectSubflowButton,
  TaskFlowComponent,
  TaskFlowWizard,
} from 'glue/components/TaskFlowWizard';
import { TaskFlowStatus, TaskFlowSubType } from 'types';
import { TaskFlowComponentProps } from '..';
import {
  GlobalGoogleCalendarStatus,
  globalGoogleStatusMap,
} from './GlobalGoogleCalendar';

type StatusMap<T extends OrgCalendarSubFlows> = Record<
  StatusesBySubflow[T],
  TaskFlowComponent
>;
type StatusRecord = { [K in OrgCalendarSubFlows]: StatusMap<K> };

// Define the subflows this comopnent handles here
type OrgCalendarSubFlows =
  | TaskFlowSubType.GlobalGoogle
  | TaskFlowSubType.IndividualGoogle
  | TaskFlowSubType.IndividualMicrosoft
  | TaskFlowSubType.GlobalGoogleDelegate;

// Define which statuses have views for the subflow here
type StatusesBySubflow = {
  // Example of defining the statuses in the subflow component
  [TaskFlowSubType.GlobalGoogle]: GlobalGoogleCalendarStatus;
  // Example of defining the statuses in line
  [TaskFlowSubType.IndividualGoogle]: TaskFlowStatus.Completed | TaskFlowStatus.Errored;
  [TaskFlowSubType.IndividualMicrosoft]:
    | TaskFlowStatus.Completed
    | TaskFlowStatus.Errored;
  [TaskFlowSubType.GlobalGoogleDelegate]:
    | TaskFlowStatus.WaitingForDelegate
    | TaskFlowStatus.Errored;
};

// Example of a subflow with simple component you can stick directly in index.tsx
const individualGoogleStatusMap: StatusMap<TaskFlowSubType.IndividualGoogle> = {
  [TaskFlowStatus.Completed]: () => <div>Placeholder completed view</div>,
  [TaskFlowStatus.Errored]: () => <div>Placeholder error view</div>,
};

const individualMicrosoftStatusMap: StatusMap<TaskFlowSubType.IndividualMicrosoft> = {
  [TaskFlowStatus.Completed]: () => <div>Placeholder completed view</div>,
  [TaskFlowStatus.Errored]: () => <div>Placeholder error view</div>,
};

const statusMap: StatusRecord = {
  // Example of a subflow with complex component you don't want to stick directly in index.tsx
  [TaskFlowSubType.GlobalGoogle]: globalGoogleStatusMap,
  // Example of locally defined status map for a flow
  [TaskFlowSubType.IndividualGoogle]: individualGoogleStatusMap,
  [TaskFlowSubType.IndividualMicrosoft]: individualMicrosoftStatusMap,
  // Example of inline status map
  [TaskFlowSubType.GlobalGoogleDelegate]: {
    [TaskFlowStatus.WaitingForDelegate]: () => <div>Placeholder completed view</div>,
    [TaskFlowStatus.Errored]: () => <div>Placeholder error view</div>,
  },
};

// Example selector component for the subflow. It can be as simple or complex as you want it
const SubflowSelector = () => {
  return (
    <div>
      <h1>Placeholder Subflow Selector Component</h1>
      <SelectSubflowButton
        subflow={TaskFlowSubType.GlobalGoogle}
        status={TaskFlowStatus.UserCalendarConnect}
      >
        Global Google Calendar Integration
      </SelectSubflowButton>
    </div>
  );
};

export const OrgCalendarIntegration = ({
  taskFlowId,
  subFlow,
}: TaskFlowComponentProps) => {
  return (
    <TaskFlowWizard
      taskFlowId={taskFlowId}
      statusComponents={statusMap[subFlow]}
      subFlowComponent={SubflowSelector}
      finalStatus={TaskFlowStatus.CalendarSyncing}
    />
  );
};
