// @ts-strict-ignore
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import theme from 'theme';
import { PreferenceQuestion } from 'types';
import AnswerButtonGroup from 'components/core/AnswerButtonGroup';

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  answersContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  answerWrapper: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: theme.spacing(12),
    width: '33%',
  },
  questionTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
  },
  iconWrapper: {
    marginRight: theme.spacing(2),
  },
});

interface Props {
  question: Partial<PreferenceQuestion>;
  currentPreferenceAnswers: any;
  onAnswer(answer: { questionId: string; answerId: string }): void;
}

const TrinaryQuestionAnswer = ({
  question,
  currentPreferenceAnswers,
  onAnswer,
}: Props) => {
  const classes = useStyles();
  const answerChosen = _.first(currentPreferenceAnswers[question.id]) as string;

  return (
    <div className={classes.container}>
      {/* Question */}
      <Typography variant='h6' className={classes.questionTitle}>
        {question.question}
      </Typography>

      {/* Answers */}
      <AnswerButtonGroup
        options={question.preferenceAnswers.map((answer) => {
          return {
            label: answer.label,
            orderIndex: answer.orderIndex,
            value: answer.id,
          };
        })}
        groupName={question.id}
        selectedValue={answerChosen}
        onButtonClick={(value) => {
          onAnswer({ questionId: question.id, answerId: value });
        }}
      />
    </div>
  );
};

export default TrinaryQuestionAnswer;
