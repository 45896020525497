import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@mysteryco/design';
import CheckVerified01Filled from 'components/icons/CheckVerified01Filled';
import UpgradeButton from 'components/UpgradeButton';
import UpsellLock from 'components/UpsellLock';
import UpsellLink from 'components/UpsellLink';
import mixpanel from 'mixpanel-browser';
import { Helmet } from 'react-helmet-async';
import { PageMetaData } from 'Routes';
import theme from 'theme';
import teamUpsellImage from 'assets/team-dynamic-art.png';
import { useViewer } from 'utils/state';

const strings = {
  header: 'Team',
  gradientMessage: 'Save time and simplify',
  remainderMessage: ' event invites',
  messageSecondLine: 'with team manager',
  subMessage:
    'Connect your team for easy team management and tag groups to quickly invite the right people to every event',
  upgradeButton: 'Upgrade to unlock',
  inReturn: "WHAT YOU'LL GET IN RETURN",
  benefit1: 'Admin controls to view all of your companies booked events',
  benefit2: 'Organizer permissions so managers can quickly schedule team fun',
  benefit3: 'Speed guest invites with group tagging',
};

const TeamsUpsell = () => {
  const classes = useStyles();
  const viewer = useViewer();
  const handleClick = () => {
    mixpanel.track('upgrade to unlock clicked', {
      source: 'team',
      customerType: viewer,
      firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
    });
  };

  return (
    <Box className={classes.root}>
      <Helmet>
        <title>{PageMetaData.TEAM.title}</title>
      </Helmet>
      <Box className={classes.pageHeader}>
        <Typography variant={'h3'}>{strings.header}</Typography>
        <UpgradeButton page='team' />
      </Box>
      <Box className={classes.content}>
        <UpsellLock />
        <Box className={classes.text}>
          <Box>
            <span className={classes.gradientMessage}>{strings.gradientMessage}</span>
            <span className={classes.heroMessage}>{strings.remainderMessage}</span>
          </Box>
          <Typography className={classes.heroMessage}>
            {strings.messageSecondLine}
          </Typography>
          <Typography className={classes.subMessage}>{strings.subMessage}</Typography>
        </Box>
        <Box>
          <img className={classes.teamImage} alt='team' src={teamUpsellImage} />
        </Box>
        <UpsellLink
          className={classes.callToAction}
          onClick={handleClick}
          text={strings.upgradeButton}
          location='teams'
        />
        <Box className={classes.benefitContainer}>
          <Typography className={classes.benefitHeader}>{strings.inReturn}</Typography>
          <Box className={classes.benefitList}>
            <Box className={classes.benefitPoint}>
              <CheckVerified01Filled color={theme.palette.primary.main} />
              <Typography className={classes.benefitPointText}>
                {strings.benefit1}
              </Typography>
            </Box>
            <Box className={classes.benefitPoint}>
              <CheckVerified01Filled color={theme.palette.primary.main} />
              <Typography className={classes.benefitPointText}>
                {strings.benefit2}
              </Typography>
            </Box>
            <Box className={classes.benefitPoint}>
              <CheckVerified01Filled color={theme.palette.primary.main} />
              <Typography className={classes.benefitPointText}>
                {strings.benefit3}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  root: {
    padding: '2rem',
  },
  pageHeader: {
    margin: `0 auto ${theme.spacing(5)}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'calc(100% - 48px)',
    maxWidth: '1600px',
    '& h3': {
      fontWeight: 300,
      color: theme.palette.grey[700],
      letterSpacing: '-1px',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '24px',
    fontFamily: 'inherit',
    fontStyle: 'normal',
    gap: '20px',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  heroMessage: {
    fontWeight: 400,
    fontSize: '48px',
    lineHeight: '48px',
    color: colors.Dusk,
  },
  gradientMessage: {
    fontWeight: 400,
    fontSize: '48px',
    lineHeight: '48px',
    color: colors.Dusk,
    background:
      'linear-gradient(90deg, #7A1783 0%, #BE207A 27.6%, #9747FF 68.75%, #EB5757 97.92%)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  subMessage: {
    fontSize: '18px',
    lineHeight: '28px',
    color: colors.Dusk,
    fontWeight: 400,
    marginTop: '12px',
  },
  benefitContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1em',
    marginTop: theme.spacing(10),
  },
  benefitList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    color: colors.Dusk,
  },
  benefitPoint: {
    display: 'flex',
    flexDirection: 'row',
  },
  benefitPointText: {
    marginLeft: theme.spacing(3),
  },
  benefitHeader: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1em',
    color: colors.Purple700,
  },
  callToAction: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px 32px',
    gap: '10px',
    marginTop: theme.spacing(5),
    background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 100%)`,
    zIndex: 1,
    borderRadius: '58px',
    color: `${theme.palette.primary.contrastText} !important`,
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '20px',
    border: 0,
    cursor: 'pointer',
    transition: 'background 0.5s ease-in-out',
    '&:visited': {
      color: theme.palette.primary.contrastText,
    },
    '&:active': {
      color: theme.palette.primary.contrastText,
    },
    '&:before': {
      position: 'absolute',
      content: "''",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: -1,
      background:
        'linear-gradient(90deg, #7A1783 0%, #BE207A 27.6%, #9747FF 68.75%, #EB5757 97.92%)',
      transition: 'opacity 0.5s ease-in-out',
      opacity: 0,
      border: 0,
      borderRadius: '58px',
    },
    '&:hover': {
      color: theme.palette.primary.contrastText,
      '&:visited': {
        color: theme.palette.primary.contrastText,
      },
      '&:active': {
        color: theme.palette.primary.contrastText,
      },
      '&:before': {
        opacity: 1,
      },
    },
  },
  teamImage: {
    height: '300px',
  },
});

export default TeamsUpsell;
