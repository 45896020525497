import { css } from '@emotion/react';
import { Link } from '@material-ui/core';
import Check from '@mysteryco/design/icons/Check';
import { colors } from '@mysteryco/design/src';
import { Coal_10, Coal_30, Coal_40 } from '@mysteryco/design/src/tokens/colors';
import FormikCheckboxField from 'glue/components/forms/FormikCheckboxField';
import FormikForm from 'glue/components/forms/FormikForm';
import FormikSubmitButton from 'glue/components/forms/FormikSubmitButton';
import FormikTextField from 'glue/components/forms/FormikTextField';
import theme from 'theme';
import { ViewerFragmentFragment } from 'types';
import { nil } from 'utils/state';
import { PRIVACY_POLICY_URL, TOS_URL } from 'utils/stringUtil';
import * as yup from 'yup';

export interface AccountFormValues {
  id: string | nil;
  firstName: string | nil;
  lastName: string | nil;
  marketingConsent: boolean;
  email: string | nil;
  companyRole: string | nil;
  companyNameForSales: string | nil;
  privacyPolicyAndTosAccepted: boolean;
}

type Props = {
  viewer?: ViewerFragmentFragment;
  title?: string;
  subTitle?: string;
  showTooltip?: boolean;
  onSave(values: AccountFormValues): any;
  orgName?: string;
  ctaText?: string;
};

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('First name is required')
    .typeError('First name is requried'),
  lastName: yup
    .string()
    .required('Last name is required')
    .typeError('Last name is requried'),
  companyRole: yup
    .string()
    .required('Job title is required')
    .typeError('Job title is required'),
  companyNameForSales: yup
    .string()
    .required('Company name is required')
    .typeError('Company name is required'),
  privacyPolicyAndTosAccepted: yup
    .bool()
    .isTrue('Privacy policy and terms of use must be accepted before proceeding'),
});

const AccountForm = ({ viewer, onSave, orgName, ctaText = 'Sign up' }: Props) => {
  return (
    <div css={styles.container}>
      <FormikForm
        initialValues={{
          id: viewer?.id,
          firstName: viewer?.firstName,
          lastName: viewer?.lastName,
          marketingConsent: viewer?.marketingConsent || false,
          email: viewer?.email,
          companyRole: viewer?.companyRole,
          companyNameForSales: orgName || viewer?.companyNameForSales,
          privacyPolicyAndTosAccepted: false,
        }}
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={(values) => {
          onSave(values);
        }}
      >
        <FormikTextField
          name='email'
          type='email'
          label='Company email'
          css={[styles.textInput, styles.readOnlyInput]}
          InputProps={{ endAdornment: <Check size={20} />, readOnly: true }}
        />
        <div css={styles.formRow}>
          <FormikTextField
            name='firstName'
            type='text'
            label='First name'
            placeholder='Micah'
            css={styles.textInput}
            autoFocus
          />
          <FormikTextField
            name='lastName'
            type='text'
            label='Last name'
            placeholder='Evans'
            css={styles.textInput}
          />
        </div>
        <FormikTextField
          name='companyRole'
          type='text'
          label='Job title'
          placeholder='Account Manager'
          css={styles.textInput}
        />
        <FormikTextField
          name='companyNameForSales'
          type='text'
          label='Company name'
          placeholder='Polymr'
          css={[styles.textInput, !!orgName && styles.readOnlyInput]}
          InputProps={{ readOnly: !!orgName }}
        />
        <FormikCheckboxField
          name='privacyPolicyAndTosAccepted'
          label={
            <div css={styles.checkboxLabel}>
              I've read and agree to Glue's{' '}
              <Link
                href={PRIVACY_POLICY_URL}
                target='_blank'
                rel='noopener'
                underline='always'
              >
                Privacy Policy
              </Link>{' '}
              and{' '}
              <Link href={TOS_URL} target='_blank' rel='noopener' underline='always'>
                Terms of Use
              </Link>
            </div>
          }
        />
        <FormikCheckboxField
          css={styles.checkbox}
          name='marketingConsent'
          label={
            <div css={styles.checkboxLabel}>
              <div css={styles.checkboxHeading}>Subscribe to our newsletter</div>
              <div>Receive tips and product updates from Glue</div>
            </div>
          }
        />
        <FormikSubmitButton shape='square' color='green' onlyValid>
          {ctaText}
        </FormikSubmitButton>
      </FormikForm>
    </div>
  );
};

const styles = {
  checkbox: css({
    '& .MuiCheckbox-root': {
      color: Coal_10,
    },
  }),
  textInput: css({
    '& label': {
      marginBottom: theme.spacing(1),
      fontSize: '.875rem',
      fontWeight: 400,
      lineHeight: 1.43,
    },
    '& .MuiInputBase-input': {
      fontFamily: 'Figtree',
      fontWeight: 500,
      fontSize: theme.spacing(4),
      color: Coal_10,
      borderRadius: theme.spacing(2),
      border: `1px solid ${Coal_40}`,
      '&:focus': {
        borderColor: Coal_30,
        borderRadius: theme.spacing(2),
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: Coal_40,
        borderRadius: theme.spacing(2),
        borderWidth: '1px',
      },
      '&:hover fieldset': {
        borderColor: Coal_30,
      },
      '&.Mui-focused fieldset': {
        borderColor: Coal_30,
      },
    },
  }),
  readOnlyInput: css({
    '& .MuiOutlinedInput-root': {
      backgroundColor: colors.Purple100,
    },
  }),
  formRow: css({
    display: 'flex',
    gap: theme.spacing(4),
  }),
  checkboxLabel: css({
    fontSize: '.875rem',
    fontWeight: 400,
    lineHeight: 1.43,
  }),
  checkboxHeading: css({
    fontWeight: 'bolder',
  }),
  container: css({
    display: 'flex',
    flexDirection: 'column',
  }),
};

export default AccountForm;
