import React, { HTMLAttributes } from 'react';
import { css } from '@emotion/react';
import { IconProps } from '@mysteryco/design/icons';
import { colors } from '@mysteryco/design';
import Square from '@mysteryco/design/icons/Square';
import CheckSquare from '@mysteryco/design/icons/CheckSquare';
import XClose from '@mysteryco/design/icons/XClose';

export interface SelectableButtonProps extends HTMLAttributes<HTMLDivElement> {
  text?: string;
  Icon?: React.ComponentType<IconProps>;
  shape?: 'rectangle' | 'pill';
  disabled?: boolean;
  selected?: boolean;
  showChecked?: boolean;
  showClose?: boolean;
  onSelect?: () => void;
  onDeselect?: () => void;
  onClose?: () => void;
}

const SelectableButton = ({
  text,
  Icon,
  shape = 'rectangle',
  disabled,
  selected,
  showChecked,
  showClose,
  onSelect,
  onDeselect,
  onClose,
  ...props
}: SelectableButtonProps) => {
  return (
    <div
      css={[
        styles.root,
        selected && styles.selected,
        disabled && styles.disabled,
        shape === 'pill' && styles.pill,
      ]}
      onClick={() => {
        if (disabled) {
          return;
        }
        if (onSelect && !selected) {
          return onSelect();
        }
        if (onDeselect && selected) {
          return onDeselect();
        }
      }}
      {...props}
    >
      {showChecked &&
        (selected ? (
          <CheckSquare color={colors.Coal_10} />
        ) : (
          <Square color={colors.Coal_30} />
        ))}
      {Icon && (
        <Icon size={14} color={disabled ? colors.Glue_Ink10 : colors.Glue_Ink10} />
      )}
      {text && <p>{text}</p>}
      {showClose && (
        <div
          css={!disabled && styles.close}
          onClick={(event) => {
            event.stopPropagation();
            !disabled && onClose && onClose();
          }}
        >
          <XClose size={10} color={disabled ? colors.Glue_Ink10 : colors.Glue_Ink00} />
        </div>
      )}
    </div>
  );
};

const styles = {
  root: css({
    display: 'flex',
    alignItems: 'center',
    padding: '14px 16px',
    width: 'fit-content',
    gap: '10px',
    borderRadius: '8px',
    color: colors.Glue_Ink00,
    background: colors.Glue_LavenderLight,
    border: '1px solid',
    borderColor: colors.OffWhite,
    '&:hover': {
      cursor: 'pointer',
      background: colors.Glue_Paper,
      borderColor: colors.Glue_BorderDark,
      boxShadow:
        '0px 4px 6px -1px rgba(155, 160, 166, 0.1), 0px 2px 4px -1px rgba(155, 160, 166, 0.06)',
    },
    '& > *': {
      margin: 0,
    },
  }),
  selected: css({
    background: colors.Glue_Paper,
    borderColor: colors.Glue_BorderLight,
    boxShadow:
      '0px 4px 6px -1px rgba(155, 160, 166, 0.1), 0px 2px 4px -1px rgba(155, 160, 166, 0.06)',
    fontWeight: 700,
    '&:hover': {
      boxShadow: 'none',
    },
  }),
  disabled: css({
    background: colors.Glue_LavenderLight,
    borderColor: colors.Glue_LavenderLight,
    boxShadow:
      '0px 4px 6px -1px rgba(155, 160, 166, 0.1), 0px 2px 4px -1px rgba(155, 160, 166, 0.06)',
    color: colors.Glue_Ink10,
    '&:hover': {
      background: colors.Glue_LavenderLight,
      borderColor: colors.Glue_LavenderLight,
    },
  }),
  pill: css({
    lineHeight: '20px',
    padding: '6px 16px',
    borderRadius: '16px',
    background: colors.Glue_Paper,
    borderColor: colors.Glue_Ink30,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '&$disabled': {
      background: colors.Glue_LavenderLight,
      borderColor: colors.Glue_LavenderLight,
    },
    '&$selected': {
      fontWeight: 500,
      borderColor: colors.Glue_Ink00,
    },
  }),
  close: css({
    cursor: 'pointer',
  }),
};

export default SelectableButton;
