import { gql } from '@apollo/client';

export const updateUser = gql`
  mutation updateUser(
    $id: ID!
    $zipCode: String
    $name: String
    $firstName: String
    $lastName: String
    $email: String
    $dob: Date
    $preferenceAnswers: [GenericReferenceInput]
    $restrictions: [RestrictionInput]
    $dietaryRestrictions: [GenericReferenceInput]
    $dietaryRestrictionsLastAsked: Date
    $foodPreferences: [FoodPreferenceInput]
    $companyRole: String
    $companyNameForSales: String
    $organizationRoleId: ID
    $marketingConsent: Boolean
    $startDate: DateTime
    $team: ID
    $optOutOfWatercoolerAt: DateTime
    $timezone: String
    $optOutOfDigestAt: DateTime
  ) {
    updateUser(
      id: $id
      zipCode: $zipCode
      name: $name
      firstName: $firstName
      lastName: $lastName
      email: $email
      dob: $dob
      preferenceAnswers: $preferenceAnswers
      restrictions: $restrictions
      dietaryRestrictions: $dietaryRestrictions
      dietaryRestrictionsLastAsked: $dietaryRestrictionsLastAsked
      foodPreferences: $foodPreferences
      companyRole: $companyRole
      companyNameForSales: $companyNameForSales
      organizationRoleId: $organizationRoleId
      marketingConsent: $marketingConsent
      startDate: $startDate
      team: $team
      optOutOfWatercoolerAt: $optOutOfWatercoolerAt
      timezone: $timezone
      optOutOfDigestAt: $optOutOfDigestAt
    ) {
      # Be careful adding fields to this. Anything that is not directly on the User model will require potentially expensive SQL to retrieve
      # on _every_ interactions/update_user call! Consider writing a custom mutation or passing a refetch query with what you need instead.
      id
      name
      firstName
      lastName
      email
      phone
      dob
      companyRole
      companyNameForSales
      marketingConsent
      role
      createdAt
      zipCode
      optOutOfWatercoolerAt
      timezone
      optOutOfDigestAt
    }
  }
`;

export const completeQuestionnaire = gql`
  mutation CompleteQuestionnaire($userId: ID!, $answers: [AnswerInput]) {
    completeQuestionnaire(answers: $answers) {
      id
      answerForUser(id: $userId) {
        id
        answer
      }
    }
  }
`;

export const userBookTeamEventMutation = gql`
  mutation UserCreateTeamEvent(
    $requestedFor: DateTime!
    $expectedHeadCount: Int
    $experience: GenericReferenceInput
    $title: String
    $cost: Int
    $isRequestToBook: Boolean
    $surpriseToAttendees: Boolean
    $surpriseToAll: Boolean
    $purpose: String
    $participantFamiliarity: Int
    $participationModel: ParticipationModel
    $goals: [GenericReferenceInput!]
    $testEventType: TestEventType
    $contract: GenericReferenceInput
  ) {
    userCreateTeamEvent(
      requestedFor: $requestedFor
      expectedHeadCount: $expectedHeadCount
      experience: $experience
      title: $title
      cost: $cost
      isRequestToBook: $isRequestToBook
      surpriseToAttendees: $surpriseToAttendees
      surpriseToAll: $surpriseToAll
      purpose: $purpose
      participantFamiliarity: $participantFamiliarity
      participationModel: $participationModel
      goals: $goals
      testEventType: $testEventType
      contract: $contract
    ) {
      id
      initialPrice {
        totalCents
      }
    }
  }
`;

export const addUserToTeamEvent = gql`
  mutation AddUserToTeamEvent($teamEventId: ID!, $userId: ID!) {
    addUserToTeamEvent(id: $teamEventId, userId: $userId) {
      id
      virtualEvent {
        id
      }
    }
  }
`;

export const addAttendeeToVirtualEvent = gql`
  mutation EventDetailsAddAttendee($virtualEventId: ID!, $userId: ID!) {
    addAttendeeToVirtualEvent(virtualEventId: $virtualEventId, userId: $userId) {
      id
    }
  }
`;

export const addUserToTeamEventAndVirtualEvent = gql`
  mutation addUserToTeamEventAndVirtualEvent(
    $teamEventId: ID!
    $userId: ID!
    $virtualEventId: ID!
    $skipRegistrationConfirmationEmail: Boolean
  ) {
    addUserToTeamEvent(
      id: $teamEventId
      userId: $userId
      skipRegistrationConfirmationEmail: $skipRegistrationConfirmationEmail
    ) {
      id
    }
    addAttendeeToVirtualEvent(virtualEventId: $virtualEventId, userId: $userId) {
      id
    }
  }
`;

export const chargeForFinalInvoice = gql`
  mutation ChargeForFinalInvoice($userId: ID!, $teamEventId: ID!) {
    chargeCustomerForTeamEvent(
      userId: $userId
      teamEventId: $teamEventId
      shouldEmailReceipt: true
    ) {
      viewer {
        id
      }
    }
  }
`;

export const updateUserTagsUserDocument = gql`
  mutation updateUserTags($userId: ID!, $tagIds: [ID]!) {
    updateUserTags(userId: $userId, tagIds: $tagIds) {
      id
      name
    }
  }
`;

export const createUserTags = gql`
  mutation createUserTags($names: [String]!, $organizationId: ID!) {
    createUserTags(names: $names, organizationId: $organizationId) {
      id
      name
    }
  }
`;

export const renameUserTag = gql`
  mutation renameUserTag($tagId: ID!, $name: String) {
    renameUserTag(tagId: $tagId, name: $name) {
      id
      name
    }
  }
`;

export const deleteUserTags = gql`
  mutation deleteUserTags($tagIds: [ID]!) {
    deleteUserTags(tagIds: $tagIds)
  }
`;

export const createTeam = gql`
  mutation createTeam($managerId: ID!, $orgId: ID!, $teamName: String) {
    createTeam(managerId: $managerId, orgId: $orgId, teamName: $teamName) {
      id
    }
  }
`;

export const renameTeam = gql`
  mutation editTeamName($id: ID!, $name: String!) {
    editTeamName(id: $id, name: $name)
  }
`;

export const changeManagers = gql`
  mutation changeManagers($inputs: [ChangeManagerInput!]!) {
    changeManagers(inputs: $inputs)
  }
`;

export const addRemoveTagsFromUsers = gql`
  mutation addRemoveTagsFromUsers(
    $userIds: [ID]!
    $addTagIds: [ID]
    $removeTagIds: [ID]
  ) {
    addRemoveTagsFromUsers(
      userIds: $userIds
      addTagIds: $addTagIds
      removeTagIds: $removeTagIds
    ) {
      id
      tags {
        id
        name
      }
    }
  }
`;

export const updateOrganizationMembers = gql`
  mutation UpdateOrganizationMembers(
    $id: ID!
    $members: [MemberInput!]!
    $preserveServiceAccounts: Boolean
  ) {
    updateOrganizationMembers(
      id: $id
      members: $members
      preserveServiceAccounts: $preserveServiceAccounts
    ) {
      id
    }
  }
`;

export const archiveOrganizationMembers = gql`
  mutation ArchiveMembers($id: ID!, $usersToArchive: [ID!]!) {
    archiveOrganizationMembers(id: $id, usersToArchive: $usersToArchive) {
      id
    }
  }
`;

export const restoreOrganizationMembers = gql`
  mutation RestoreMembers($id: ID!, $usersToRestore: [ID!]!) {
    restoreArchivedOrganizationMembers(id: $id, usersToRestore: $usersToRestore) {
      id
    }
  }
`;

export const acceptLatestLegalAgreements = gql`
  mutation acceptLatestLegalAgreements {
    acceptLatestLegalAgreements {
      id
      hasAgreedToLatestTOS
      promptedForMarketingConsent
    }
  }
`;

export const sendAccountSetupReminder = gql`
  mutation sendAccountSetupReminderEmails($orgId: ID!, $emails: [String!]!) {
    sendAccountSetupReminders(orgId: $orgId, emails: $emails) {
      id
    }
  }
`;

export const updateRsvpStatus = gql`
  mutation updateRsvpStatus(
    $email: String!
    $teamEventId: ID!
    $rsvpStatus: InvitedGuestRsvpStatus!
  ) {
    updateRsvpStatus(email: $email, teamEventId: $teamEventId, rsvpStatus: $rsvpStatus) {
      __typename
      id
      email
      rsvpStatus
    }
  }
`;

export const updateProfileDetails = gql`
  mutation updateProfileDetails(
    $id: ID!
    $firstName: String
    $lastName: String
    $companyRole: String
    $dietaryRestrictions: [GenericReferenceInput]
    $dietaryRestrictionsLastAsked: Date
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      companyRole: $companyRole
      dietaryRestrictions: $dietaryRestrictions
      dietaryRestrictionsLastAsked: $dietaryRestrictionsLastAsked
    ) {
      id
      firstName
      lastName
      dietaryRestrictions {
        id
        name
      }
      dietaryRestrictionsLastAsked
    }
  }
`;

export const createMoralePulseQuery = gql`
  mutation CreateMoralePulse($settings: MoralePulseSettings!) {
    createMoralePulse(settings: $settings) {
      id
    }
  }
`;

export const updateMoralePulseQuery = gql`
  mutation UpdateMoralePulse($pulseId: ID!, $settings: MoralePulseSettings!) {
    updateMoralePulse(pulseId: $pulseId, settings: $settings) {
      id
    }
  }
`;

export const cancelPulseQuery = gql`
  mutation CancelPulse($pulseId: ID!) {
    disableMoralePulse(pulseId: $pulseId)
  }
`;
