/**
 * FilledButton is meant to compliment EmptyButton
 */

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import theme, { primaryGradient } from 'theme';
import cx from 'classnames';

const FilledButton = ({ hovering, ...otherProps }: { [key: string]: any }) => {
  const classes = useStyles();
  const { className: otherPropsClassName, ...otherPropsWithoutClassName } = otherProps;
  return (
    <Button
      className={cx(classes.emptyButton, hovering && classes.hover, otherPropsClassName)}
      {...otherPropsWithoutClassName}
    />
  );
};

const useStyles = makeStyles({
  emptyButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px 16px',
    background: primaryGradient,
    borderRadius: '44px',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '1rem',
    textTransform: 'none',
  },
  hover: {
    opacity: 1,
    boxShadow: 'none',
    background:
      'linear-gradient(90deg, #EB5757 0%, #BE207A 27.6%, #9747FF 68.75%, #7A1783 97.92%)',
    color: theme.palette.common.white,
  },
});

export default FilledButton;
