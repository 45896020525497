import { keyframes, SerializedStyles } from '@emotion/react';
import { IconProps } from '@mysteryco/design/icons';
import { colors } from '@mysteryco/design';

interface ProgressProps extends IconProps {
  progress: number; // value between 0 and 1
  initialProgress?: number; // value between 0 and 1
  css?: SerializedStyles;
}

export default function ProgressSemiCircle({
  progress = 0,
  initialProgress = 0,
  className = '',
  color = colors.Glue_Ink00,
  size = 91,
  style = {},
  css,
}: ProgressProps) {
  const initialRotation = initialProgress * 180 - 180;
  const rotation = progress * 180 - 180;
  return (
    <div
      css={{ width: size, height: (46 / 91) * size, overflow: 'hidden', css }}
      className={className}
      style={style}
    >
      <svg
        width={size}
        viewBox='0 0 91 91'
        xmlns='http://www.w3.org/2000/svg'
        preserveAspectRatio='none'
      >
        <mask
          height='46'
          id='mask'
          style={{ maskType: 'alpha' }}
          width='91'
          x='0'
          y='0'
          maskUnits='userSpaceOnUse'
        >
          <path
            d='M91 45.5C91 33.4327 86.2063 21.8595 77.6734 13.3266C69.1405 4.79374 57.5674 9.1106e-07 45.5 0C33.4327 -9.11059e-07 21.8596 4.79373 13.3266 13.3266C4.79374 21.8595 1.82212e-06 33.4326 0 45.5L13.65 45.5C13.65 37.0528 17.0056 28.9517 22.9787 22.9786C28.9517 17.0056 37.0529 13.65 45.5 13.65C53.9471 13.65 62.0483 17.0056 68.0214 22.9786C73.9944 28.9517 77.35 37.0529 77.35 45.5H91Z'
            css={{ fill: 'currentColor', color }}
          />
        </mask>
        <g mask='url(#mask)'>
          <mask id='container' fill='white'>
            <path d='M91 45.5C91 33.4327 86.2063 21.8595 77.6734 13.3266C69.1405 4.79374 57.5674 9.1106e-07 45.5 0C33.4327 -9.11059e-07 21.8596 4.79373 13.3266 13.3266C4.79374 21.8595 1.82212e-06 33.4326 0 45.5L13.65 45.5C13.65 37.0528 17.0056 28.9517 22.9787 22.9786C28.9517 17.0056 37.0529 13.65 45.5 13.65C53.9471 13.65 62.0483 17.0056 68.0214 22.9786C73.9944 28.9517 77.35 37.0529 77.35 45.5H91Z' />
          </mask>
          <mask id='progress' fill='white'>
            <path d='M91 45.5C91 33.4327 86.2063 21.8595 77.6734 13.3266C69.1405 4.79374 57.5674 9.1106e-07 45.5 0C33.4327 -9.11059e-07 21.8596 4.79373 13.3266 13.3266C4.79374 21.8595 1.82212e-06 33.4326 0 45.5L13.65 45.5C13.65 37.0528 17.0056 28.9517 22.9787 22.9786C28.9517 17.0056 37.0529 13.65 45.5 13.65C53.9471 13.65 62.0483 17.0056 68.0214 22.9786C73.9944 28.9517 77.35 37.0529 77.35 45.5H91Z' />
          </mask>
          <path
            d='M91 45.5C91 33.4327 86.2063 21.8595 77.6734 13.3266C69.1405 4.79374 57.5674 9.1106e-07 45.5 0C33.4327 -9.11059e-07 21.8596 4.79373 13.3266 13.3266C4.79374 21.8595 1.82212e-06 33.4326 0 45.5L13.65 45.5C13.65 37.0528 17.0056 28.9517 22.9787 22.9786C28.9517 17.0056 37.0529 13.65 45.5 13.65C53.9471 13.65 62.0483 17.0056 68.0214 22.9786C73.9944 28.9517 77.35 37.0529 77.35 45.5H91Z'
            fill='transparent'
            css={{ stroke: 'currentColor', color }}
            strokeWidth='2'
            mask='url(#container)'
          />
          <path
            d='M91 45.5C91 33.4327 86.2063 21.8595 77.6734 13.3266C69.1405 4.79374 57.5674 9.1106e-07 45.5 0C33.4327 -9.11059e-07 21.8596 4.79373 13.3266 13.3266C4.79374 21.8595 1.82212e-06 33.4326 0 45.5L13.65 45.5C13.65 37.0528 17.0056 28.9517 22.9787 22.9786C28.9517 17.0056 37.0529 13.65 45.5 13.65C53.9471 13.65 62.0483 17.0056 68.0214 22.9786C73.9944 28.9517 77.35 37.0529 77.35 45.5H91Z'
            strokeWidth='2'
            css={{
              fill: 'currentColor',
              stroke: 'currentColor',
              color,
              transformOrigin: 'center',
              animation: `${animationIn({
                rotation,
                initialRotation,
              })} 2000ms ease-in-out forwards`,
            }}
            mask='url(#progress)'
          />
        </g>
      </svg>
    </div>
  );
}

const animationIn = ({
  rotation,
  initialRotation,
}: {
  rotation: number;
  initialRotation: number;
}) => {
  return keyframes`
		0% {
			transform: rotate(${initialRotation}deg);
		}
		100% {
			transform: rotate(${rotation}deg);
		}
	`;
};
