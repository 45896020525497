// @ts-strict-ignore
import { Box } from '@material-ui/core';
import Progress from 'components/Checkout/Progress';
import { renderAccountStep } from 'components/Checkout/steps/Account';
import Confirmation from 'components/Checkout/steps/Confirmation';
import { renderPreferenceQuestionsSteps } from 'components/Checkout/steps/PreferenceQuestions';
import {
  QuestionnaireAnswerType,
  renderQuestionnairesStep,
} from 'components/Checkout/steps/Questionnaire';
import Rsvp from 'components/Checkout/steps/Rsvp';
import RegistrationStepWrapper from 'components/Checkout/styling/RegistrationStepWrapper';
import { Loading } from 'components/core';
import { useQueryParams } from 'lib/helpers/router';
import _ from 'lodash';
import { DateTime } from 'luxon';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import { Step, Steps, Wizard } from 'react-albus';
import { useHistory } from 'react-router-dom';
import { Paths } from 'Routes';
import {
  CheckExistingInputsQuery,
  DietaryRestriction,
  InvitedGuestRsvpStatus,
  TeamEventFragmentFragment,
  useCheckExistingInputsQuery,
  useDietaryRestrictionsListQuery,
  ViewerFragmentFragment,
} from 'types';
import { getUserRsvpStatus } from 'utils/state';

export type PreEventQuestionnaire =
  CheckExistingInputsQuery['teamEvent']['preEventQuestionnaires'][0];

interface HydratedNormalFlowProps {
  teamEvent: TeamEventFragmentFragment;
  viewer: ViewerFragmentFragment;
  dietaryRestrictionList: Pick<DietaryRestriction, 'id' | 'name' | 'orderIndex'>[];
  preEventInfo: CheckExistingInputsQuery;
  preEventQuestionnaires: PreEventQuestionnaire[];
}

const NormalFlow = (props: {
  teamEvent: TeamEventFragmentFragment;
  viewer: ViewerFragmentFragment;
}) => {
  const { viewer, teamEvent } = props;

  const { data: preEventInfoQueryData, loading } = useCheckExistingInputsQuery({
    variables: { eventId: teamEvent.id, userId: viewer.id },
  });
  const { data: dietaryRestrictionListQueryData } = useDietaryRestrictionsListQuery();

  const preEventQuestionnaires = [
    ..._.get(preEventInfoQueryData, 'teamEvent.preEventQuestionnaires', []),
  ];

  useEffect(() => {
    mixpanel.track('normal flow started', {
      source: 'registration',
      customerType: viewer?.customerType,
      firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
    });
  }, []);

  if (
    loading ||
    !dietaryRestrictionListQueryData ||
    !preEventInfoQueryData ||
    !preEventQuestionnaires
  )
    return <Loading />;

  return (
    <HydratedNormalFlow
      teamEvent={teamEvent}
      viewer={viewer}
      dietaryRestrictionList={dietaryRestrictionListQueryData?.dietaryRestrictions}
      preEventInfo={preEventInfoQueryData}
      preEventQuestionnaires={preEventQuestionnaires}
    />
  );
};
const HydratedNormalFlow = ({
  teamEvent,
  viewer,
  dietaryRestrictionList,
  preEventInfo,
  preEventQuestionnaires,
}: HydratedNormalFlowProps) => {
  const history = useHistory();
  const params = useQueryParams();

  const viewersPreferenceAnswers = _.reduce(
    viewer?.preferenceAnswers,
    (acc, cur) => {
      acc[cur.question.id] = [cur.id];
      return acc;
    },
    {},
  );

  const askFeedbackEvent = viewer.askFeedbackEvent;
  const preferenceQuestions = viewer.unansweredPreferenceQuestions;
  const shouldAskDietary = !viewer.dietaryRestrictionsLastAsked;
  const existingRsvpStatus = getUserRsvpStatus(viewer, teamEvent);
  const isChangingRsvp = !!existingRsvpStatus && params.get('action') === 'change-rsvp';

  const [preferenceAnswers, setPreferenceAnswers] = useState<{
    [questionId: string]: string[];
  }>(viewersPreferenceAnswers);
  const [isRsvpStepComplete, setRsvpStepComplete] = React.useState(
    !isChangingRsvp && InvitedGuestRsvpStatus.Accepted === existingRsvpStatus,
  );
  const [answers, setAnswers] = useState<QuestionnaireAnswerType[]>(null);
  const [currentDietaryRestrictions, setCurrentDietaryRestrictions] = useState([]);

  const accountStep = React.useMemo(() => renderAccountStep({ viewer }), []);
  const preferenceQuestionStep = React.useMemo(
    () =>
      renderPreferenceQuestionsSteps({
        teamEvent,
        viewer,
        preferenceQuestions,
        currentPreferenceAnswers: preferenceAnswers,
        setPreferenceAnswers,
        shouldAskDietary,
        dietaryRestrictionList,
        currentDietaryRestrictions,
        setCurrentDietaryRestrictions,
      }),
    [preferenceQuestions, preferenceAnswers, currentDietaryRestrictions],
  );

  const questionnaireStep = React.useMemo(
    () =>
      renderQuestionnairesStep({
        teamEvent,
        viewer,
        questionnaires: preEventQuestionnaires,
        answers,
        setAnswers,
      }),
    [preEventQuestionnaires, answers],
  );

  const finishFeedbackStep = React.useMemo(() => {
    if (!askFeedbackEvent || params.get('skipAskFeedback')) return [];
    return (
      <Step
        id={'finishFeedback'}
        render={(context) => (
          <RegistrationStepWrapper
            {...context}
            label={'Finish missing feedback?'}
            secondaryLabel={`You haven't yet submitted feedback from your event
            on ${DateTime.fromISO(askFeedbackEvent?.requestedFor).toFormat(
              'MMMM dd',
            )}, help
            your team by giving feedback.`}
            askFeedbackEvent={askFeedbackEvent}
            nextButtonProps={{
              label: 'Leave feedback',
            }}
          />
        )}
      />
    );
  }, [askFeedbackEvent, params]);

  const hasOtherSteps =
    _.isElement(finishFeedbackStep) ||
    accountStep.length > 0 ||
    preferenceQuestionStep.length > 0 ||
    (questionnaireStep.length > 0 && questionnaireStep[0].length > 0);

  if (!isRsvpStepComplete) {
    return (
      <Rsvp
        existingRsvpStatus={existingRsvpStatus}
        isChangingRsvp={isChangingRsvp}
        teamEvent={teamEvent}
        viewer={viewer}
        setComplete={setRsvpStepComplete}
        hasOtherSteps={hasOtherSteps}
      />
    );
  }

  return (
    <Wizard
      history={history}
      basename={Paths.TEAM_EVENTS}
      render={({ step, steps }) => {
        return (
          <Box css={{ paddingTop: '40px' }}>
            <Progress steps={steps} step={step} />
            <Steps key={step.id} step={step}>
              {finishFeedbackStep}
              {preferenceQuestionStep}
              {accountStep}
              {questionnaireStep}
              <Step
                id='confirmation'
                render={(context) => (
                  <Confirmation {...context} teamEvent={teamEvent} viewer={viewer} />
                )}
              />
            </Steps>
          </Box>
        );
      }}
    />
  );
};

export default NormalFlow;
