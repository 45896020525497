import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import cx from 'classnames';
import { Colors, Type } from 'constants/index';
import React from 'react';
import theme, { primaryGradient } from 'theme';

const useStyles = makeStyles({
  button: (props: { size: string; allCaps: boolean; disabled: boolean }) => {
    return {
      width: '100%',
      border: 'none !important',
      borderRadius: `4 !important`,
      transition: 'opacity .25s ease-in-out !important',
      background: props.disabled ? theme.palette.grey[500] : primaryGradient,
      span: { fontWeight: Type.FontWeights.BOLD, color: Colors.Brand.White },
      textTransform: props.allCaps ? 'uppercase' : undefined,
      opacity: props.disabled ? `0.75 !important` : 1,
      filter: props.disabled ? `grayscale(1) !important` : undefined,
      ':hover, :focus, :active': {
        background: Colors.Brand.PrimaryGradient,
        opacity: props.disabled ? `0.3 !important` : `0.85 !important`,
      },
      color: 'white',
    };
  },
});

/**
 * @deprecated - use glue/components/buttons components instead
 */
const PrimaryButton = ({
  onClick,
  label,
  loading,
  disabled = false,
  allCaps = false,
  size = 'large',
  className,
  appendedIcon,
  ...otherProps
}: {
  allCaps?: boolean;
  size?: ButtonProps['size'];
  className?: string;
  onClick: () => void | Promise<void>;
  label: string;
  loading?: boolean;
  disabled?: boolean;
  appendedIcon?: React.ReactNode;
  [key: string]: any;
}) => {
  const isDisabled = !loading && disabled;
  const classes = useStyles({ size, allCaps, disabled: isDisabled });

  return (
    <Button
      size={size}
      shape='round'
      type='primary'
      loading={loading}
      disabled={isDisabled}
      onClick={onClick}
      className={cx([classes.button, className])}
      style={{ background: primaryGradient, color: 'white' }}
      {...otherProps}
    >
      {label}
      {!!appendedIcon && (
        <Box style={{ marginLeft: theme.spacing(2), display: 'flex' }}>
          {appendedIcon}
        </Box>
      )}
    </Button>
  );
};

export default PrimaryButton;
