// @ts-strict-ignore
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Laptop01 from 'components/icons/Laptop01';
import Settings04 from 'components/icons/Settings04';
import {
  CostUnit,
  InvitedGuestRsvpStatus,
  Role,
  TeamEventFragmentFragment,
  TeamEventStatus,
  TestEventType,
} from 'types';

import InviteGuests from 'components/InviteGuests';
import { MIN_SURPRISE_TO_ALL_SEAT_COUNT } from 'components/SSFSteps/UploadGuests';
import { DateTime } from 'luxon';
import { useViewer } from 'utils/state';
import DetailSection from './DetailSection';
import EventCost from './EventCost';

const testEventTypeDisplayStrings: Record<TestEventType, string> = {
  [TestEventType.NoPayments]: 'No charges, no payouts',
  [TestEventType.OnlyCharges]: 'Only charges, no payouts',
  [TestEventType.ChargesAndPayout]: 'Charges and payouts',
};

const EventType = ({ hasPhysicalGoods }: { hasPhysicalGoods: boolean }) => {
  const classes = useStyles();
  return (
    <DetailSection label='Event Type'>
      <Box className={classes.iconData}>
        <Laptop01 />
        <Box>{hasPhysicalGoods ? 'Virtual event with supplies' : 'Virtual event'}</Box>
      </Box>
    </DetailSection>
  );
};

const TestType = ({ testEventType }: { testEventType: TestEventType }) => {
  const classes = useStyles();
  return (
    <DetailSection label='Wizard Details'>
      <Box className={classes.iconData}>
        <Settings04 />
        <Box>Test Event: {testEventTypeDisplayStrings[testEventType]}</Box>
      </Box>
    </DetailSection>
  );
};

const EventDetails = ({
  teamEvent,
  guests,
}: {
  teamEvent: TeamEventFragmentFragment;
  guests: {
    email: string;
    rsvpStatus: InvitedGuestRsvpStatus;
  }[];
}) => {
  const classes = useStyles();
  const viewer = useViewer();
  const isWizard = viewer?.role === Role.Wizard;

  const { expectedHeadCount, finalHeadCount, status, experience, rsvpSummary } =
    teamEvent;

  const isComplete = [TeamEventStatus.Complete, TeamEventStatus.ReadyToReview].includes(
    status,
  );
  const canInvite = teamEvent.signUpExpirationDate
    ? !isComplete && DateTime.now() < DateTime.fromISO(teamEvent.signUpExpirationDate)
    : !isComplete;

  const minimumEventSize = teamEvent?.surpriseToAll
    ? MIN_SURPRISE_TO_ALL_SEAT_COUNT
    : experience?.cost?.ctcPricingBands?.find((band) => band.unit === CostUnit.Event)
        ?.maxUsers;

  const showInvitedGuests =
    teamEvent.surpriseToAll ||
    (teamEvent.status && teamEvent.status !== TeamEventStatus.Requested);

  const testEventType = teamEvent?.testEventType;

  return (
    <Box className={classes.container}>
      <EventType hasPhysicalGoods={experience?.hasPhysicalGoods}></EventType>
      {isWizard && testEventType && <TestType testEventType={testEventType} />}
      <EventCost
        teamEvent={teamEvent}
        experience={experience}
        minimumEventSize={minimumEventSize}
      />
      <InviteGuests
        guests={guests}
        teamEventId={teamEvent.id}
        joinLink={teamEvent.joinLink}
        isComplete={isComplete}
        canInvite={canInvite}
        expectedHeadCount={rsvpSummary?.total || expectedHeadCount}
        minimumEventSize={minimumEventSize}
        finalHeadCount={finalHeadCount}
        showInvitedGuests={showInvitedGuests}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8),
  },
  iconData: {
    display: 'flex',
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    alignItems: 'center',
    fontSize: '.875rem',
  },
  guestData: {
    padding: theme.spacing(4),
  },
}));

export default EventDetails;
