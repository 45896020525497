// @ts-strict-ignore
import { cx } from '@emotion/css';
import { Box, Typography } from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core/styles';
import {
  Main,
  Purple100,
  Purple50,
  Purple500,
  White,
} from '@mysteryco/design/src/tokens/colors';
import UpsellLock from 'components/UpsellLock';
import UpsellWrapper from 'components/UpsellWrapper';
import ScorePill, { Score } from './ScorePill';

interface Props {
  title: string;
  subtitle?: string;
  score?: Score | null;
  footer?: React.ReactNode;
  type?: 'detail' | 'aggregate';
  children: React.ReactNode;
  showUpsell?: boolean;
  upsellEmpty?: boolean;
  upsellLock?: boolean;
}

const EventMetricsCard = ({
  title,
  subtitle,
  score = null,
  footer,
  children,
  type = 'detail',
  showUpsell = false,
  upsellEmpty = false,
  upsellLock = false,
}: Props) => {
  const classes = useStyles();
  const containerClass = cx([classes.container, { [classes.upsell]: showUpsell }]);

  const footerClass = cx([
    classes.footer,
    { [classes.aggregateFooter]: type === 'aggregate' },
  ]);

  return (
    <Box className={containerClass}>
      <Box className={classes.header}>
        <Box className={classes.titleContainer}>
          <Typography className={classes.title}>{title}</Typography>
          {showUpsell && upsellLock && (
            <Box className={classes.upsellWrapper}>
              <UpsellLock />
            </Box>
          )}
          {!showUpsell && score !== null && <ScorePill score={score} />}
        </Box>
        {subtitle && <Typography className={classes.subtitle}>{subtitle}</Typography>}
      </Box>
      <Box className={classes.content}>
        <UpsellWrapper showUpsell={showUpsell && upsellEmpty} height={28} width='100%'>
          <span>{children}</span>
        </UpsellWrapper>
      </Box>
      {footer && !showUpsell && (
        <Box className={footerClass}>
          <Box className={classes.footerContent}>
            <span>{footer}</span>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: `1px solid ${Purple100}`,
    borderRadius: 4,
    background: White,
    boxShadow: `0 ${theme.spacing(1)} ${theme.spacing(2)} -${theme.spacing(1)} ${alpha(
      Main,
      0.2,
    )}`,
    transition: 'all 300ms ease-in-out',
  },
  header: {
    padding: `${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(2)}`,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '1.125rem',
    fontWeight: theme.typography.fontWeightMedium,
    color: Main,
  },
  subtitle: {
    fontSize: '.85rem',
    color: theme.palette.common.black,
    opacity: '.75',
    fontWeight: 500,
  },
  content: {
    padding: `${theme.spacing(1)} ${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(
      4,
    )}`,
  },
  footer: {
    backgroundColor: Purple50,
    borderRadius: `0 0 3px 3px`,
    '&::before': {
      content: '""',
      display: 'block',
      width: '100%',
      height: 3,
      background: `linear-gradient(90deg, #BE207A 0%, #9747FF 100%)`,
    },
  },
  aggregateFooter: {
    '&::before': {
      height: 1,
      background: Purple100,
    },
    '& $footerContent': {
      textAlign: 'left',
      margin: 0,
    },
  },
  footerContent: {
    padding: `${theme.spacing(3)} 0`,
    textAlign: 'center',
    margin: 'auto',
  },
  upsellWrapper: {
    alignSelf: 'start',
    margin: `${theme.spacing(-1)} ${theme.spacing(-1)} 0 0`,
  },
  upsell: {
    cursor: 'default',
    '& $title, & $subtitle': {
      color: Purple500,
    },
    '&$container': {
      justifyContent: 'start',
      '&:hover': {
        borderColor: Purple100,
      },
    },
    '& $titleContainer': {},
  },
}));

export default EventMetricsCard;
