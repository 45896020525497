// @ts-strict-ignore
import StepWrapper from 'components/SSFSteps/StepWrapper';
import { userBookTeamEventMutation } from 'gql/mutations';
import { recursivelyRemoveTypenames } from 'lib/helpers/apollo';
import {
  DEFAULT_SURPRISE_TO_ALL_BANDS,
  getContractCostFromProps,
  getTotalEventCostCents,
} from 'lib/helpers/money';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import { ContractFragmentFragment, ContractUnitType, PricingBand, Role, TestEventType } from 'types';
import { headCountFromProps, useViewer } from 'utils/state';
import * as yup from 'yup';
import SnackbarUtils from 'components/core/SnackbarUtils';
import { useMutation } from '@apollo/client';
import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { colors } from '@mysteryco/design/src';
import SectionSubHeader from 'components/SectionSubHeader';
import { getRequestedEventsQuery } from 'gql/queries';
import { Statsig } from 'statsig-react';
import { EventSelectionArgs } from 'types/selfServeFlow';
import { Props } from '../types';
import ContractCoverage from './ContractCoverage';
import PaymentMethod from './PaymentMethod';
import { PriceSummary } from './PriceSummary';
import SummarySection from './SummarySection';

export const title = 'Confirm and Book';
export const key = 'payment';

export type SummaryProps = {
  contract?: ContractFragmentFragment;
  eventMinimum?: number;
  hasPhysicalGoods?: boolean;
  headCount?: number;
  priceString?: string;
  subtotal: string;
  tax: string;
  total: string;
  unitType?: ContractUnitType;
};

const testEventTypeDisplayStrings: Record<TestEventType, string> = {
  [TestEventType.NoPayments]: 'No charges, no payouts',
  [TestEventType.OnlyCharges]: 'Only charges, no payouts',
  [TestEventType.ChargesAndPayout]: 'Charges and payouts',
};

const AgreeToCancelPolicy = ({
  agreedToCancelPolicy,
  setAgreedToCancelPolicy,
}: {
  agreedToCancelPolicy: boolean;
  setAgreedToCancelPolicy(agree: boolean): void;
}) => (
  <FormControlLabel
    control={
      <Checkbox
        checked={agreedToCancelPolicy}
        onChange={(e) => setAgreedToCancelPolicy(e.target.checked)}
      />
    }
    label={
      <Typography>
        I agree to the Glue{' '}
        <a
          rel='noreferrer'
          target='_blank'
          href='https://glue.co/event-cancellation-policy/'
        >
          cancellation policy.
        </a>
      </Typography>
    }
  />
);

const TestEvent = ({
  testEventType,
  booked,
  onChange,
}: {
  testEventType: TestEventType;
  booked?: boolean;
  onChange: (testEventType: TestEventType) => void;
}) => {
  const isTestEvent = Boolean(testEventType);
  const styles = useSummaryStyles();
  const handleToggle = () =>
    isTestEvent ? onChange(null) : onChange(TestEventType.NoPayments);
  const handleSelect = (e) => onChange(e.target.value);
  return (
    <div className={styles.testEventLayout}>
      <Typography color='error'>{booked ? 'Wizard Info' : 'Wizard Options'}</Typography>
      <FormControlLabel
        control={
          <Checkbox checked={isTestEvent} onChange={handleToggle} disabled={booked} />
        }
        label={<Typography>Test event?</Typography>}
      />
      {isTestEvent && (
        <div className={styles.testEventType}>
          <label>
            <Typography>Test event type:</Typography>
          </label>
          <Select
            id='testEventTYpe'
            name='testEventType'
            label='Test Event Type'
            onChange={handleSelect}
            value={testEventType}
            disabled={booked}
          >
            {Object.values(TestEventType).map((testType) => (
              <MenuItem value={testType} key={testType}>
                {testEventTypeDisplayStrings[testType]}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
    </div>
  );
};

export const Content = (props: Props<any>) => {
  const viewer = useViewer();
  const { globalState } = props;
  const {
    eventDetails,
    eventSelection,
    summary,
    surpriseSelection,
    payment,
    isRequestToBook,
    contract
  } = globalState;
  const [stripeToken, setStripeToken] = useState(null);
  const [errorState, setErrorState] = useState(false);
  const [agreedToCancelPolicy, setAgreedToCancelPolicy] = React.useState(false);
  const [testEventType, setTestEventType] = useState<TestEventType>(undefined);
  const [bookTeamEvent, { loading: bookingMutationLoading }] = useMutation(
    userBookTeamEventMutation,
    { refetchQueries: [{ query: getRequestedEventsQuery }] },
  );
  const sanitizedEventSelection = recursivelyRemoveTypenames(
    eventSelection,
  ) as EventSelectionArgs;
  const surpriseToAttendees = surpriseSelection?.surpriseToAttendees;
  const surpriseToAll = surpriseSelection?.surpriseToAll;
  const headCount = headCountFromProps(props);
  const selectedContract = ((contract ||  viewer.contracts[0])) ? contract || viewer.contracts[0]: undefined;
  const contractCost = selectedContract && getContractCostFromProps(props);
  const hasPhysicalGoods = !!_.get(globalState, 'eventSelection.hasPhysicalGoods');
  const customerType = viewer ? viewer.customerType : null;
  const isWizard = viewer ? viewer.role === Role.Wizard : false;
  const isTestServiceAccount = Statsig.checkGate('test_service_accounts');
  const hasTestEventPermission = isWizard || isTestServiceAccount;
  
  useEffect(() => {
    mixpanel.track('payment loaded', {
      customerType: viewer?.customerType,
      firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
    });
  }, []);

  const template = _.get(globalState, 'eventSelection.template');
  let ctcPricingBands = _.get(template, 'cost.ctcPricingBands');

  if (surpriseToAll) {
    ctcPricingBands = DEFAULT_SURPRISE_TO_ALL_BANDS as PricingBand[];
  }
  let subtotal = selectedContract
    ? contractCost.total
    : getTotalEventCostCents({ headCount, ctcPricingBands });

  const bookEvent = async () => {
    try {
      const result = await bookTeamEvent({
        variables: {
          requestedFor: sanitizedEventSelection?.event?.start,
          expectedHeadCount: headCount,
          experience: surpriseToAll
            ? null
            : _.pick(_.sample(sanitizedEventSelection.experiences), 'id'),
          isRequestToBook,
          surpriseToAttendees,
          surpriseToAll,
          goals: eventDetails?.goals?.map((goal) => ({ id: goal })),
          testEventType: testEventType,
          contract: selectedContract ? {id: selectedContract.id}: undefined,
          ...summary,
        },
      });
      const bookedEvent = result.data;
      props.setValue(_.get(bookedEvent, 'userCreateTeamEvent'), 'bookedEvent');
      props.setValue({ bookSuccessful: true });
    } catch (error) {
      SnackbarUtils.error(`[Internal Server Error] Booking Event Error`);
      console.log('Booking Event Error ::',error);
    }
  };

  const onNext = async () => {
    mixpanel.track('event booked', {
      source: 'payment',
      customerType: viewer?.customerType,
      firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
    });
    await bookEvent();
    // props.goToNextStep({ replace: true });
  };

  const isValid =
    isRequestToBook ||
    (((viewer && !!viewer.cardOnFile) || !!stripeToken || !subtotal || selectedContract) &&
      !errorState &&
      agreedToCancelPolicy);
  const bookSuccessful = payment?.bookSuccessful;

  const strings = getStrings({ bookSuccessful });

  return (
    <StepWrapper
      title={strings.title}
      subTitle={strings.subTitle}
      {...props}
      stepIsValid={isValid}
      onNext={onNext}
      customNextText={'Confirm and book event'}
      bookSuccessful={bookSuccessful}
      nextButtonLoading={bookingMutationLoading}
      messageWidget={
        <PriceSummary
          contract={selectedContract}
          template={template}
          guestCount={headCount}
          bookSuccessful={bookSuccessful}
          isRequestToBook={isRequestToBook}
          customerType={customerType}
          isSurpriseToAll={surpriseToAll}
        />
      }
    >
      <SectionSubHeader title={strings.sectionTitle} subTitle={strings.subTitle} />
      <SummarySection
        {...summary}
        surpriseToAll={surpriseToAll}
        surpriseToAttendees={surpriseToAttendees}
        experience={{
          hasPhysicalGoods,
          photoUrl: _.get(eventSelection, 'template.photoUrl'),
          requiresUpgrade: !!_.get(eventSelection, 'requiresUpgrade'),
          startTimeISO: _.get(eventSelection, 'event.start'),
          title: _.get(eventSelection, 'template.title'),
        }}
        headCount={headCount}
      />
      {(
        <ContractCoverage
          isSurpriseToAll={surpriseToAll}
          contract={selectedContract}
          headCount={headCount}
          template={template}
        />
      )}
      {!!subtotal && !selectedContract &&(
        <PaymentMethod
          userId={viewer.id}
          cardOnFile={_.get(viewer, 'cardOnFile')}
          setErrorState={setErrorState}
          setStripeToken={setStripeToken}
          bookSuccessful={bookSuccessful}
        />
      )}
      {!bookSuccessful && (
        <AgreeToCancelPolicy
          agreedToCancelPolicy={agreedToCancelPolicy}
          setAgreedToCancelPolicy={setAgreedToCancelPolicy}
        />
      )}
      {hasTestEventPermission && (
        <TestEvent
          testEventType={testEventType}
          onChange={setTestEventType}
          booked={bookSuccessful}
        />
      )}
    </StepWrapper>
  );
};

const getStrings = ({ bookSuccessful }) => {
  /**
   * Making Text while booking a event uniform after discussion with product.
   */
  if (bookSuccessful) {
    return {
      title: `Request confirmed`,
      subTitle: `Check your email for a request confirmation. An account manager will be in touch shortly to confirm details and next steps.`,
      sectionTitle: `Your event`,
    };
  }
  return { title: `Confirm and book`, sectionTitle: `Your event` };
};

export const useSummaryStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      marginTop: '2rem',
      width: '100%',
    },
  },
  lineItemRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: '0.5rem',
  },
  sectionHeader: {
    fontWeight: 'bold',
    marginBottom: '1rem',
  },
  subSectionHeader: {
    fontWeight: 'bold',
    marginBottom: '0.5rem',
  },
  boldRow: {
    fontWeight: 'bold',
    fontSize: '1.4rem',
    display: 'flex',
    flexDirection: 'column',
  },
  purpleText: {
    color: theme.palette.primary.main,
  },
  testEventLayout: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: `3px solid ${colors.Glue_Red20}`,
    gap: '10px',
    padding: '20px',
    borderRadius: 16,
  },
  testEventType: { display: 'flex', gap: 10, alignItems: 'center' },
}));

export const schema = yup.object({
  payment: yup
    .object()
    .shape({
      bookSuccessful: yup.boolean(),
    })
    .required(),
});
