// @ts-strict-ignore
import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { gql } from '@apollo/client';
import { Box, CircularProgress } from '@material-ui/core';
import * as yup from 'yup';

import NoEventsState from 'components/NoEventsState';
import EventSelectionDetails from 'components/SSFSteps/EventSelectionDetails';
import StepWrapper from 'components/SSFSteps/StepWrapper';
import { Props } from 'components/SSFSteps/types';
import { client } from 'gql/client';
import {
  availableEventTimesToWindows,
  shapeOptionsPresented,
  getTotalGuests,
} from 'lib/helpers/booking';
import mixpanel from 'mixpanel-browser';
import {
  EventForSelection,
  MatchedExperience,
  TemplateWithExperienceInfo,
} from 'types/selfServeFlow';
import { getOpenExperiences, groupExperiencesByTemplate } from './helpers';
import QuoteBox from '../QuoteBox';
import avatarImage from 'assets/testimonials/chang-zuo.png';
import ExperienceCatalog from 'components/SSFSteps/ExperienceCatalog';
import { NylasAvailabilityProvider } from '../NylasAvailabilityProvider';
import { useScrollTopOnLoad } from 'hooks';

export const title = 'Event Selection';
export const key = 'eventSelection';

const findEventsForWindows = async ({
  availableEventTimes,
  budget,
  hasPhysicalGoods,
  setAvailableTemplates,
  setLooseFitTemplates,
  setShownExperiences,
  setLoading,
}: {
  availableEventTimes: EventForSelection[];
  budget?: {
    priceLevel: number;
  };
  hasPhysicalGoods: boolean;
  setAvailableTemplates: (templates: TemplateWithExperienceInfo[]) => void;
  setLooseFitTemplates: (templates: TemplateWithExperienceInfo[]) => void;
  setShownExperiences: (xperiences: MatchedExperience[]) => void;
  setLoading: (loading: boolean) => void;
}) => {
  setLoading(true);

  const windows = availableEventTimesToWindows({ availableEventTimes });
  const { data } = await client.query({
    query: findMatchingExperiencesQuery,
    fetchPolicy: 'network-only',
    variables: {
      rules: {
        PriceRange: budget
          ? {
              priceLevel: budget.priceLevel,
            }
          : undefined,
        HasPhysicalGoods: { showPhysicalGoods: hasPhysicalGoods },
      },
    },
  });

  const { experiences, looseFitIndex } = data.findMatchingExperiencesForTeamEvents! as {
    experiences: MatchedExperience[];
    looseFitIndex: number;
  };

  const experiencesWithTemplates = _.filter(
    experiences,
    (exp) => !_.isEmpty(exp.templates),
  );
  const bestMatches = new Set(
    experiences.slice(0, looseFitIndex).map((experience) => experience.id),
  );

  const openExperiences = getOpenExperiences(experiencesWithTemplates, windows);
  const groupedByTemplate = groupExperiencesByTemplate(openExperiences);

  const available = [];
  const looseFit = [];

  const openExperiencesIds = new Set(openExperiences?.map((experience) => experience.id));
  const templatesWithOpenExperiences = _.filter(
    Object.values(groupedByTemplate),
    (template) =>
      _.some(template.experiences, (experience) => openExperiencesIds.has(experience.id)),
  );
  for (const template of templatesWithOpenExperiences) {
    if (_.some(template.experiences, (experience) => bestMatches.has(experience.id))) {
      available.push(template);
    } else {
      looseFit.push(template);
    }
  }

  setAvailableTemplates(available);
  setLooseFitTemplates(looseFit);
  setShownExperiences(openExperiences);
  setLoading(false);
};

export const Content = (props: Props<any>) => {
  const history = useHistory();
  const [availableTemplates, setAvailableTemplates] = React.useState<
    TemplateWithExperienceInfo[]
  >([]);
  const [looseFitTemplates, setLooseFitTemplates] = React.useState<
    TemplateWithExperienceInfo[]
  >([]);
  const [shownExperiences, setShownExperiences] = React.useState<MatchedExperience[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [selectedTemplate, setSelectedTemplate] =
    React.useState<TemplateWithExperienceInfo>(null);
  const [surpriseToAllView, setSurpriseToAllView] = React.useState(true);
  const hasResults = !_.isEmpty(availableTemplates) || !_.isEmpty(looseFitTemplates);
  const { eventDate } = props.value;
  const availableEventTimes = eventDate?.availableEventTimes;
  const wantsPhysicalGoods = !!_.get(
    props,
    'globalState.eventDetails.wantsPhysicalGoods',
  );
  const uploadGuests = props?.globalState?.uploadGuests;
  const totalGuests =
    getTotalGuests({ guests: uploadGuests.guests }) ||
    uploadGuests.totalRecipientsEstimate;
  const showCatalogView =
    (loading || hasResults) && _.isEmpty(selectedTemplate) && !surpriseToAllView;

  React.useEffect(() => {
    mixpanel.track('event selection loaded', {
      customerType: props.viewer?.customerType,
      firstTimeBooker: props.viewer?.requestedTeamEvents.length === 0,
    });
  }, []);

  useScrollTopOnLoad();

  React.useEffect(() => {
    if (!surpriseToAllView) {
      findEventsForWindows({
        availableEventTimes,
        budget: _.get(props, 'value.budget'),
        hasPhysicalGoods: wantsPhysicalGoods,
        setAvailableTemplates,
        setLooseFitTemplates,
        setShownExperiences,
        setLoading,
      });
    }
  }, [surpriseToAllView]);

  React.useEffect(() => {
    // TODO: Figure out booking options shit
    const shaped = shapeOptionsPresented(shownExperiences);
    props.setValue(shaped, 'optionsPresented');
  }, [shownExperiences]);

  const getStepWrapperTitleContent = () => {
    return {
      title: hasResults && !selectedTemplate ? '' : 'Event planning',
      subTitle: '',
    };
  };

  const { title, subTitle } = getStepWrapperTitleContent();

  const renderContent = () => {
    if (loading) {
      return (
        <Box display='flex' justifyContent='center' alignItems='center' mt={20}>
          <CircularProgress color='primary' />
        </Box>
      );
    } else if (surpriseToAllView || !_.isEmpty(selectedTemplate)) {
      return (
        <EventSelectionDetails
          clearSelection={() => setSelectedTemplate(null)}
          currentEventSelection={props.value.eventSelection}
          onChoose={props.setValue}
          selectedTemplate={selectedTemplate}
          viewer={props.viewer}
          setValue={props.setValue}
          surpriseToAllView={surpriseToAllView}
          setSurpriseToAllView={setSurpriseToAllView}
          availableEventTimes={availableEventTimes}
          wantsPhysicalGoods={wantsPhysicalGoods}
        />
      );
    } else if (hasResults) {
      return (
        <ExperienceCatalog
          setSelectedTemplate={setSelectedTemplate}
          setSurpriseToAllView={setSurpriseToAllView}
          viewer={props.viewer}
          totalGuests={totalGuests}
        />
      );
    } else {
      return (
        <NoEventsState
          firstLine={`We could not find any events that matched the dates you've selected`}
          buttonProps={{
            text: 'Choose Another Date',
            onClick: () => {
              history.goBack();
            },
          }}
        />
      );
    }
  };

  return (
    <NylasAvailabilityProvider {...props}>
      <StepWrapper
        title={title}
        subTitle={subTitle}
        steps={props.steps}
        activeStep={props.activeStep}
        fullWidth={showCatalogView}
        onNext={(navigateForward) => {
          if (surpriseToAllView) {
            mixpanel.track('sta event picked', {
              customerType: props.viewer?.customerType,
              firstTimeBooker: props.viewer?.requestedTeamEvents.length === 0,
            });
          } else {
            mixpanel.track('self chosen event picked', {
              customerType: props.viewer?.customerType,
              firstTimeBooker: props.viewer?.requestedTeamEvents.length === 0,
            });
          }
          navigateForward();
        }}
        messageWidget={
          <QuoteBox
            color='secondary'
            author='Chang Zuo'
            company='Google'
            title='Administrative Business Partner'
            quote={`We rely on Glue because they take the guesswork out of matching the right event to the right team. It's a big reason I think their events actually help build connections on the teams I support—people genuinely look forward to them.`}
            avatarImage={avatarImage}
          />
        }
        {...props}
      >
        {renderContent()}
      </StepWrapper>
    </NylasAvailabilityProvider>
  );
};

export const schema = yup
  .object({
    eventSelection: yup
      .object()
      .shape({
        event: yup.object().shape({
          id: yup.string().required(),
          start: yup.date(),
          end: yup.date(),
        }),
        template: yup.object(),
      })
      .required(),
  })
  .required();

const findMatchingExperiencesQuery = gql`
  query getMatchingExperiences($rules: Rules!) {
    findMatchingExperiencesForTeamEvents(rules: $rules, strict: false) {
      experiences {
        id
        name
        hasPhysicalGoods
        requiresUpgrade
        operatingHours {
          open {
            day
            time
            hours
            minutes
          }
          close {
            day
            time
            hours
            minutes
          }
        }
        location {
          timezone
        }
        templates {
          id
          title
          description
          photoUrl
          cost {
            ctcCents
            ctcVolumeDiscounts {
              minNumUsers
              percentDiscount
            }
            ctcUnit
            ctcPricingBands {
              minUsers
              maxUsers
              cents
              credits
              unit
            }
          }
        }
      }
      looseFitIndex
    }
  }
`;
