import { gql } from '@apollo/client';
import { css } from '@emotion/react';
import {
  Coal_10,
  Coal_40,
  Glue_Ink10,
  Glue_LavenderLight,
  Mint_30,
  Mint_40,
  Ruby_30,
  Ruby_40,
} from '@mysteryco/design/src/tokens/colors';
import { Paths } from 'Routes';
import Button from 'glue/components/buttons/Button';
import { Audience } from 'glue/components/inputs/audience';
import { meetupAudienceFragment } from 'gql/queries';
import { useHistory } from 'react-router-dom';
import theme from 'theme';
import {
  AudienceMemberInput,
  WatercoolerInitiativeByOrgQuery,
  useCreateWatercoolerInitiativeMutation,
  useUpdateWatercoolerInitiativeMutation,
} from 'types';
import { MeetupsConfigurations, MeetupsState } from '.';

const audienceToMutationPayload = (audience: Audience): AudienceMemberInput[] => {
  if (!audience) {
    return [];
  }

  // Short circuit on organization-wide audience
  if (audience.organization) {
    return [{ organization: { id: audience.organization.id } }];
  }

  const emptyInput = {
    user: null,
    userTag: null,
    team: null,
    organization: null,
  } as AudienceMemberInput;

  const keyLookup = {
    users: 'user',
    tags: 'userTag',
    teams: 'team',
  } as Record<keyof Audience, string>;

  return Object.entries(audience).flatMap(([key, audienceValue]) => {
    return audienceValue?.map((value) => ({
      ...emptyInput,
      [keyLookup[key]]: { id: value.id },
    }));
  });
};

const ActionButtons = ({
  meetupsState,
  dirty,
  existingInitiative,

  orgId,
  meetupsConfiguration,
}: {
  meetupsState: MeetupsState;
  dirty: boolean;
  existingInitiative: WatercoolerInitiativeByOrgQuery['watercoolerInitiativeByOrg'];
  audience: Audience;
  orgId: string;
  meetupsConfiguration: MeetupsConfigurations;
}) => {
  const history = useHistory();

  const [createMeetupInitiative, { loading: createLoading }] =
    useCreateWatercoolerInitiativeMutation({
      refetchQueries: ['WatercoolerInitiativeByOrg'],
    });
  const [updateMeetupInitiative, { loading: updateLoading }] =
    useUpdateWatercoolerInitiativeMutation({
      refetchQueries: ['WatercoolerInitiativeByOrg'],
    });

  const loading = updateLoading || createLoading;

  const goToActivitiesPage = (summary: boolean = false) =>
    history.push(`${Paths.ACTIVITIES}${Paths.MEETUPS}${summary ? '?summary=true' : ''}`);

  const onConfirm = async () => {
    const { Creating, Editing } = MeetupsState;
    const audiencePayload = audienceToMutationPayload(meetupsConfiguration.audience);
    const cadenceWeeks = meetupsConfiguration.cadenceWeeks;

    if (meetupsState === Creating) {
      // creating new meetup initiative
      await createMeetupInitiative({
        variables: { org: { id: orgId }, audience: audiencePayload, cadenceWeeks },
      });
      goToActivitiesPage(true);
    } else if (meetupsState === Editing) {
      !!existingInitiative &&
        (await updateMeetupInitiative({
          variables: {
            id: existingInitiative.id,
            audience: audiencePayload,
            cadenceWeeks,
            disabledAt: null,
          },
        }));
      goToActivitiesPage(true);
    } else {
      // Disabling
      !!existingInitiative &&
        (await updateMeetupInitiative({
          variables: { id: existingInitiative.id, disabledAt: new Date() },
        }));
      goToActivitiesPage();
    }
  };

  const onCancel = () => {
    goToActivitiesPage();
  };

  const { Creating, Editing, Disabling, DisablingConfirmed } = MeetupsState;

  const disableConfirm = !dirty || Disabling === meetupsState;
  const displayCancel = !loading && Creating !== meetupsState;

  const confirmTextLookup = {
    [Creating]: 'Set up Meetups',
    [Editing]: 'Update',
    [Disabling]: 'Disable',
    [DisablingConfirmed]: 'Disable',
  };

  const loadingTextLookup = {
    [Creating]: 'Setting up Meetups',
    [Editing]: 'Updating Meetups',
    [DisablingConfirmed]: 'Disabling Meetups',
  };

  return (
    <div css={styles.actionButtons}>
      {displayCancel && (
        <Button
          shape='square'
          css={[styles.button, styles.cancelButton]}
          onClick={() => onCancel()}
        >
          Cancel
        </Button>
      )}
      <Button
        shape='square'
        disabled={disableConfirm}
        loading={loading}
        css={[
          styles.button,
          meetupsState === DisablingConfirmed
            ? styles.disableButton
            : styles.confirmButton,
        ]}
        onClick={() => onConfirm()}
      >
        {loading ? loadingTextLookup[meetupsState] : confirmTextLookup[meetupsState]}
      </Button>
    </div>
  );
};

const styles = {
  button: css({
    flex: 1,
    justifyContent: 'center',
    fontSize: '15px',
    color: Coal_10,
  }),
  confirmButton: css({
    background: Mint_40,
    border: `solid 1px ${Mint_30}`,
    '--bg-disabled': Glue_LavenderLight,
    '--fg-disabled': Glue_Ink10,
  }),
  cancelButton: css({
    border: `solid 1px ${Coal_40}`,
  }),
  disableButton: css({
    background: Ruby_40,
    border: `solid 1px ${Ruby_30}`,
    '--bg-disabled': Glue_LavenderLight,
    '--fg-disabled': Glue_Ink10,
  }),
  actionButtons: css({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(4),
  }),
};

gql`
  mutation CreateWatercoolerInitiative(
    $org: GenericReferenceInput!
    $audience: [AudienceMemberInput]
    $cadenceWeeks: Int
  ) {
    createWatercoolerInitiative(
      organization: $org
      audience: $audience
      cadenceWeeks: $cadenceWeeks
    ) {
      id
      disabledAt
      lastRun
      runAfter
      cadenceWeeks
      audience {
        ...MeetupAudienceFragment
      }
    }
  }
  ${meetupAudienceFragment}
`;

gql`
  mutation UpdateWatercoolerInitiative(
    $id: ID!
    $audience: [AudienceMemberInput]
    $cadenceWeeks: Int
    $disabledAt: DateTime
  ) {
    updateWatercoolerInitiative(
      id: $id
      audience: $audience
      disabledAt: $disabledAt
      cadenceWeeks: $cadenceWeeks
    ) {
      id
      disabledAt
      lastRun
      runAfter
      cadenceWeeks
      audience {
        id
        user {
          id
          name
        }
        team {
          id
          name
          members {
            id
            name
          }
        }
        userTag {
          id
          name
          users {
            id
            name
          }
        }
        organization {
          id
          members {
            id
          }
        }
      }
    }
  }
`;

export default ActionButtons;
