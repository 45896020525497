import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuItemProps,
  Select,
  SelectProps,
} from '@material-ui/core';
import { FieldValidator, useField, useFormikContext } from 'formik';
import { ReactNode, createContext, forwardRef, useContext } from 'react';
import { textFieldStyles } from 'glue/components/forms/FormikTextField';

export type FormikSelectFieldProps = SelectProps & {
  /** Field name is required. This is used to hook the field up to the parent Formik. */
  name: string;
  validate?: FieldValidator;
  required?: boolean;
  label?: ReactNode;
};

export const FormikSelectField = forwardRef<HTMLDivElement, FormikSelectFieldProps>(
  ({ name, validate, required, label, className, ...props }, ref) => {
    const [fieldProps, utils] = useField({
      name,
      validate,
      required,
    });

    return (
      <div ref={ref} css={textFieldStyles.root} className={className}>
        {label && (
          <label htmlFor={name} css={textFieldStyles.label}>
            {label}
          </label>
        )}
        <FormikSelectFieldContext.Provider value={name}>
          <Select
            ref={ref}
            variant='outlined'
            {...props}
            {...fieldProps}
            error={!!utils.error}
            name={name}
          />
        </FormikSelectFieldContext.Provider>
      </div>
    );
  },
);
FormikSelectField.displayName = 'FormikSelectField';

export default FormikSelectField;

// have to omit 'button' here because MUI props are a little weird.
export const FormikSelectFieldOption = forwardRef<
  HTMLLIElement,
  Omit<MenuItemProps, 'button'> & { secondary?: ReactNode }
>(({ children, secondary, value, ...props }, ref) => {
  const ctx = useFormikContext<any>();
  const name = useContext(FormikSelectFieldContext);
  // somehow MUI is passing 'data-value' instead of 'value' to the option component
  // despite the parent passing value. ech!
  const optionValue = value || props['data-value'];
  const fieldValue = name ? ctx.values[name] : undefined;
  const selected = Array.isArray(fieldValue)
    ? fieldValue.includes(optionValue)
    : fieldValue === optionValue;

  return (
    <MenuItem button={undefined} {...props} value={value} ref={ref}>
      <ListItemIcon>
        <Checkbox checked={selected} />
      </ListItemIcon>
      <ListItemText primary={children} secondary={secondary} />
    </MenuItem>
  );
});
FormikSelectFieldOption.displayName = 'FormikSelectFieldOption';

// this micro-context keeps the field name in scope so we can use it in the option
// to determine if it's selected...
const FormikSelectFieldContext = createContext<string | undefined>(undefined);
