import { Green300, Purple100 } from '@mysteryco/design/src/tokens/colors';
import { EncircleIcon } from 'components/icons/EncircleIcon';

const CircledIcon = ({ children, smallPadding, disabled }) => (
  <EncircleIcon
    backgroundColor={disabled ? Purple100 : Green300}
    padding={smallPadding ? '6px' : undefined}
  >
    {children}
  </EncircleIcon>
);

const Circle = ({ children, size = 24, disabled = false }) => {
  const smallPadding = size < 24;
  return (
    <CircledIcon disabled={disabled} smallPadding={smallPadding}>
      {children}
    </CircledIcon>
  );
};

export default Circle;
