// @ts-strict-ignore
import 'react-calendar/dist/Calendar.css';

import { useResponsive } from 'ahooks';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import * as yup from 'yup';

import { Box } from '@material-ui/core';

import theme from 'theme';
import StepWrapper from '../StepWrapper';
import { Props } from '../types';
import EventPrep from './EventPrep';
import QuoteBox from '../QuoteBox';
import avatarImage from 'assets/testimonials/aleena-sarwana.png';
import EventSummaryCard from './EventSummaryCard';
import {
  centsToDollarString,
  DEFAULT_SURPRISE_TO_ALL_BANDS,
  getContractCostFromProps,
  getTotalEventCostCents,
} from 'lib/helpers/money';
import { headCountFromProps } from 'utils/state';
import { PricingBand } from 'types';
import { useViewer } from 'utils/state';

export const title = 'Summary';
export const key = 'summary';

export const Content = (props: Props<any>) => {
  const viewer = useViewer();
  const { lg } = useResponsive();
  const { globalState } = props;
  const { eventSelection, surpriseSelection, uploadGuests } = globalState;

  const isSurpriseToAll = surpriseSelection.surpriseToAll;
  let ctcPricingBands = _.get(
    eventSelection,
    'template.cost.ctcPricingBands',
  ) as PricingBand[];
  if (isSurpriseToAll) {
    ctcPricingBands = DEFAULT_SURPRISE_TO_ALL_BANDS as PricingBand[];
  }
  const estimateCents = getTotalEventCostCents({
    ctcPricingBands,
    headCount: headCountFromProps(props),
  });
  const estimate = centsToDollarString(estimateCents);
  const contractCost = getContractCostFromProps(props);

  useEffect(() => {
    mixpanel.track('summary loaded', {
      customerType: viewer?.customerType,
      firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
    });
  }, []);

  return (
    <StepWrapper
      title='Event summary'
      {...props}
      customNextText='Next Step'
      messageWidget={
        <QuoteBox
          color='primary'
          author='Aleena Sarwana'
          title='Talent Guide'
          company='Twilio'
          quote={`By using Glue's platform, I was able to improve our average attendance rate from 35% to 75% even with a multi-regional, global team.`}
          avatarImage={avatarImage}
        />
      }
    >
      <Box
        display='flex'
        flexDirection={lg ? 'row' : 'column'}
        width='100%'
        marginRight={theme.spacing(4)}
        overflow={lg ? undefined : 'hidden'}
        alignItems={lg ? undefined : 'center'}
      >
        <Box width='100%' mr={lg ? 4 : 0}>
          <EventSummaryCard
            isSurpriseToAll={isSurpriseToAll}
            eventSelection={eventSelection}
            uploadGuests={uploadGuests}
            estimate={estimate}
            contractCost={contractCost}
          />
          <EventPrep {...props} />
        </Box>
      </Box>
    </StepWrapper>
  );
};

export const schema = yup.object({
  summary: yup
    .object()
    .shape({
      title: yup.string().required(),
      participantFamiliarity: yup.number().required(),
      participationModel: yup.string().required(),
    })
    .required(),
});
