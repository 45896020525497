import { css } from '@emotion/react';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import CheckCircle from '@mysteryco/design/icons/CheckCircle';
import { colors } from '@mysteryco/design/src';
import theme from 'theme';
import { useEffect, useRef, useState } from 'react';
import { MoralePulseQuestion, MoralePulseQuestionType } from 'types';
import { useField } from 'formik';
import { existsFilter } from 'lib/helpers/maybe';

const MoralePulseQuestionField = ({ question }: { question: MoralePulseQuestion }) => {
  const [, field, tools] = useField({
    name: 'value',
    type: 'radio',
  });

  const { options, type } = question;
  const isHorizontal = type === MoralePulseQuestionType.Nps;

  return (
    <RadioGroup
      css={styles.group}
      value={field.value}
      // converts the value to an integer before setting it
      onChange={(_, value) => tools.setValue(parseInt(value, 10))}
    >
      {options.filter(existsFilter).map((option, index) => (
        <MoralePulseQuestionOption
          option={option}
          index={index}
          isSelected={field.value === index}
          key={`pulse-question-nps-${index}`}
          variant={isHorizontal ? 'horizontal' : 'vertical'}
          // for friendship question and NPS, we map 0=0,...
          // for scale questions we map 1=0,...
          // because friendship/nps have 0 as an option but
          // others begin at 1...
          keyShortcut={type === MoralePulseQuestionType.Scale ? index + 1 : index}
        />
      ))}
    </RadioGroup>
  );
};

const MoralePulseQuestionOption = ({
  option,
  index,
  isSelected,
  variant = 'vertical',
  keyShortcut,
}: {
  option: string;
  index: number;
  isSelected: boolean;
  variant?: 'vertical' | 'horizontal';
  keyShortcut?: number;
}) => {
  // used to style container when focus is on radio button
  const [isFocused, setIsFocused] = useState(false);
  const onFocus = () => setIsFocused(true);
  const onBlur = () => setIsFocused(false);

  /**
   * Register a global key handler to use number keys to select
   * options quickly.
   *
   * Clicks the input directly so that the element becomes
   * focused, moving focus inside the form so that it can be
   * submitted via keyboard.
   */
  const inputRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      const key = e.key;
      const num = parseInt(key, 10);
      if (isNaN(num)) return;

      if (num === keyShortcut) {
        inputRef.current?.click();
      }
    };
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [keyShortcut]);

  return (
    <FormControlLabel
      css={[
        styles.radioOption,
        variant === 'horizontal' && styles.horizontal,
        isSelected && styles.isSelected,
      ]}
      data-focused={isFocused}
      value={index}
      checked={isSelected}
      control={
        <Radio
          css={[styles.radio, variant === 'horizontal' && styles.radioHidden]}
          disableRipple
          onFocus={onFocus}
          onBlur={onBlur}
          checkedIcon={
            <CheckCircle color='#3B3B3B' size={24} style={{ fill: 'transparent' }} />
          }
          ref={inputRef}
        />
      }
      label={option}
      labelPlacement='start'
    />
  );
};

const styles = {
  isSelected: css({
    backgroundColor: colors.Glue_Mint00,
    borderColor: colors.Glue_DarkMint10,
    '&:hover': {
      backgroundColor: colors.Glue_Mint00,
      borderColor: colors.Glue_DarkMint10,
    },
    '&[data-focused="true"]': {
      backgroundColor: colors.Glue_Mint00,
      borderColor: colors.Glue_DarkMint10,
      boxShadow: `0 0 0 1px ${colors.Glue_DarkMint10}`,
    },
  }),
  radioOption: css({
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
    border: `1px solid ${colors.Glue_Ink30}`,
    borderRadius: `${theme.spacing(2)}`,
    width: '100%',
    margin: `${theme.spacing(2)} 0`,
    transition: `all 0.2s ease-in-out`,

    '&:hover': {
      backgroundColor: colors.Glue_Mint20,
      borderColor: colors.Glue_Berry20,
    },
    '&[data-focused="true"]': {
      backgroundColor: colors.Glue_LavenderLight,
      borderColor: colors.Glue_Berry20,
      boxShadow: `0 0 0 1px ${colors.Glue_LavenderDark}`,
    },
    '&:disabled': {
      backgroundColor: colors.Glue_LavenderLight,
      borderColor: colors.Glue_LavenderLight,
      color: colors.Glue_Ink10,
    },
  }),
  horizontal: css({
    padding: `0 ${theme.spacing(1)}`,
    margin: 0,
    flex: '1 1 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '62px',
    '& span': {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '180%',
    },
  }),
  radio: css({
    color: colors.Glue_Ink00,
  }),
  radioHidden: css({
    // radio inputs can't be hidden with display: none
    // or they can't be navigated/focused with the keyboard
    '&.MuiRadio-root': {
      opacity: 0,
      position: 'absolute',
      zIndex: -1,
      margin: '-99999px',
    },
  }),
  group: css({
    width: '100%',
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'center',
    margin: `${theme.spacing(8)} 0`,
    flexDirection: 'column',
    '@media (min-width: 960px)': {
      // force high specificity
      '&&&': {
        flexDirection: 'row',
      },
    },
  }),
};

export default MoralePulseQuestionField;
