import { css } from '@emotion/react';
import { Plum_40 } from '@mysteryco/design/src/tokens/colors';
import GlueyFace, { GlueyFaceName } from 'glue/components/glueyFace/GlueyFace';
import theme from 'theme';

const MeetupsAreEnabled = () => (
  <div css={styles.container}>
    <div css={styles.delightedGluey}>
      <GlueyFace face={GlueyFaceName.Delighted} />
    </div>
    <p css={styles.text}>Meetups are now enabled!</p>
  </div>
);

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: theme.spacing(3),
  }),
  delightedGluey: css({
    background: Plum_40,
    width: theme.spacing(20),
    height: theme.spacing(20),
    borderRadius: theme.spacing(20),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  text: css({
    fontFamily: 'Manrope',
    fontWeight: 700,
    fontSize: theme.spacing(5),
  }),
};

export default MeetupsAreEnabled;
