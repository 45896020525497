import { css } from '@emotion/react';
import { useField } from 'formik';
import SelectableButton from 'glue/components/buttons/SelectableButton';
import { PreferenceAnswer } from 'types';
import PreferencesSection, { sectionStyles } from './PreferencesSection';
import theme from 'theme';

const EMOJI_MAP = {
  'Business and finance': '🗄 Business & finance',
  'Science and technology': '⚛ Science & technology',
  Food: '🍔 Food',
  Travel: '✈️ Travel',
  Sports: '⚽️ Sports',
  'Art and design': '🎨 Art & design',
  'Family life': '👨‍👦‍👦 Family life',
  Animals: '🐶 Animals',
  'Home and garden': '🏡 Home & garden',
  'Health and fitness': '💪 Health & fitness',
  Automobiles: '🏎 Automobiles ',
  'Fashion and beauty': '🛍 Fashion & beauty ',
  Gaming: '🎮 Gaming',
  Outdoors: '🥾 Outdoors',
  Music: '🥁 Music',
  'Entertainment and culture': '🍿 Entertainment & culture',
};

const Interests = ({
  interests,
}: {
  interests: PreferenceAnswer[];
}) => {
  const [, meta, helpers] = useField({ name: 'selectedInterests' });
  const { value: selectedInterests } = meta;
  const { setValue } = helpers;
  return (
    <PreferencesSection
      title={'Interests'}
      description={
        'Glue matches you with members who have similar interests in your org to help you connect better.'
      }
    >
      <div css={sectionStyles.content}>
        <div css={sectionStyles.label}>Select all of the topics that interest you</div>
        <div css={styles.options}>
          {interests?.length > 0 &&
            interests.map((interest: PreferenceAnswer, i: number) => (
              <SelectableButton
                key={`interest-${i}`}
                text={EMOJI_MAP[interest.label]}
                showChecked={true}
                selected={selectedInterests.includes(interest.id)}
                onSelect={() => setValue(selectedInterests.concat(interest.id).sort())}
                onDeselect={() =>
                  setValue(
                    selectedInterests.filter(
                      (selectedInterest: string) => selectedInterest !== interest.id,
                    ),
                  )
                }
              />
            ))}
        </div>
      </div>
    </PreferencesSection>
  );
};

const styles = {
  options: css({
    display: 'flex',
    gap: theme.spacing(3),
    flexWrap: 'wrap',
  }),
};

export default Interests;
