import config from 'config';

export const getFinchConnectOptions = (orgId: string, userId: string) => {
  const onSuccess = (e) => {
    const code = e.code;
    return fetch(`${config.api.url}/exchange?code=${code}`, {
      method: 'post',
      body: JSON.stringify({ orgId, userId }),
    });
  };
  const onError = ({ errorMessage }) => console.error(errorMessage);
  const onClose = () => console.log('User exited Finch Connect');

  return {
    clientId: config.finch.user,
    products: ['company', 'directory', 'individual', 'employment'],
    sandbox: false,
    onSuccess,
    onError,
    onClose,
  };
};
