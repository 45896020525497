// @ts-strict-ignore
import { gql } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SectionSubHeader from 'components/SectionSubHeader';
import { CONTRACT_UNIT_PHRASE_LOOKUP } from 'lib/helpers/contract';
import { formatOrderPricingBreakdown } from 'lib/helpers/money';
import theme from 'theme';
import {
  ContractCoverageContractFragment,
  ContractCoverageTemplateFragment,
  ContractUnitType,
} from 'types';

const ContractCoverage = ({
  contract,
  template,
  headCount,
  isSurpriseToAll,
}: {
  contract: ContractCoverageContractFragment;
  template: ContractCoverageTemplateFragment;
  headCount: number;
  isSurpriseToAll: boolean;
}) => {
  const classes = useStyles();

  if (!contract) return null;

  const {
    isPremium,
    hasPhysicalGoods,
    premiumUnitOvercharge,
    unitOvercharge,
    unitExpense,
  } = formatOrderPricingBreakdown({
    contract,
    guestCount: headCount,
    template,
    isSurpriseToAll,
  });

  const unitPhrase = CONTRACT_UNIT_PHRASE_LOOKUP[contract.type];
  let message = '';

  // there was overcharge on units, but some units were paid from contract
  const wasBasicUnitPartiallyCovered = unitOvercharge && unitExpense;
  // there was overcharge on units and no units were paid from contract
  const wasOutOfBasicUnits = unitOvercharge && !unitExpense;

  if (wasBasicUnitPartiallyCovered || premiumUnitOvercharge) {
    return (
      <Box className={classes.container}>
        <Typography variant='h4'>{`Your Glue plan covers some of the costs for this event.`}</Typography>
      </Box>
    );
  } else if (wasOutOfBasicUnits) {
    message += `Your Glue plan has run out of ${unitPhrase}s.${
      contract.type === ContractUnitType.Cents
        ? ` After your event, we'll send you an invoice based on the number of guests who RSVP.`
        : ` We've got you covered though; we'll automatically add more at a discounted price.`
    }`;
  } else {
    message += `Huzzah! Your Glue plan covers ${
      contract.type === ContractUnitType.Recurring
        ? `seats for all ${headCount} attendees.`
        : 'the cost of this event.'
    }`;
  }

  if (isPremium) {
    if (!contract.premiumUnitCount) {
      message += ` We will email you an invoice for ${
        hasPhysicalGoods ? 'supplies' : 'upgrades'
      } after the event.`;
    }
  }

  return (
    <Box className={classes.container}>
      <SectionSubHeader title='Payment details' subTitle={message} />
    </Box>
  );
};
ContractCoverage.fragments = {
  contract: gql`
    fragment ContractCoverageContract on Contract {
      id
      type
      unitCount
      premiumUnitCount
      premiumUnitCostCents
      unitCostCents
    }
  `,
  template: gql`
    fragment ContractCoverageTemplate on MysteryTemplate {
      id
      cost {
        ctcPricingBands {
          minUsers
          maxUsers
          cents
          credits
          unit
        }
      }
      experiences {
        hasPhysicalGoods
        requiresUpgrade
      }
    }
  `,
};

const useStyles = makeStyles({
  container: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
});

export default ContractCoverage;
