import { MoralePulseQuestionCategory } from 'types';

export const questionCategoryDisplayMap: Record<MoralePulseQuestionCategory, string> = {
  [MoralePulseQuestionCategory.Connection]: 'Connection',
  [MoralePulseQuestionCategory.Trust]: 'Trust',
  [MoralePulseQuestionCategory.Leadership]: 'Trust in Leadership',
  [MoralePulseQuestionCategory.JobSatisfaction]: 'Job Satisfaction',
  [MoralePulseQuestionCategory.Morale]: 'Morale',
  [MoralePulseQuestionCategory.Communication]: 'Communication',
  [MoralePulseQuestionCategory.WellBeing]: 'Well Being',
  [MoralePulseQuestionCategory.EmployeeGrowth]: 'Employee Growth',
  [MoralePulseQuestionCategory.Pay]: 'Pay',
  [MoralePulseQuestionCategory.Benefits]: 'Benefits',
  [MoralePulseQuestionCategory.CompanyDirection]: 'Company Direction',
  [MoralePulseQuestionCategory.DiversityAndInclusion]: 'Diversity And Inclusion',
};
