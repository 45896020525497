import { ConnectionStrength, CONNECTION_STRENGTH_MAP } from 'constants/Type';

import { GraphContext } from './GraphContext';
import { Edge, Node, Configuration } from './interface';

export const computeEdges = (
  context: GraphContext,
  thresholds: Configuration,
  nodes: Node[],
): Edge[] => {
  const seenEdges = new Set<string>();
  const edges: Edge[] = [];

  // Oooh boy, gotta love O((n*m)^2)!
  for (const nodeA of nodes) {
    for (const nodeB of nodes) {
      if (nodeB.id === nodeA.id) continue;
      // https://jsbench.me/wjlbzqinac/1
      const key =
        nodeA.id > nodeB.id ? `${nodeA.id}:${nodeB.id}` : `${nodeB.id}:${nodeA.id}`;
      if (seenEdges.has(key)) continue; // We've already counted it from the other direction.
      seenEdges.add(key);

      // Note that a node can be either a team (manager id) or an individual.
      const nodeAUserIds = [nodeA.id, ...(nodeA?.members?.map((m) => m.id) || [])];
      const nodeBUserIds = [nodeB.id, ...(nodeB?.members?.map((m) => m.id) || [])];
      const connection = context.meanConnectionScore(nodeAUserIds, nodeBUserIds);
      if (connection < thresholds.showEdge) continue;

      edges.push({
        id: key,
        from: nodeA.id,
        to: nodeB.id,
        connection,
        length: connection,
      });
    }
  }

  return edges;
};

export const getConnectionStrength = ({
  connection,
  // min,
  // max,
  thresholds,
}: {
  connection: number;
  // min: number;
  // max: number;
  thresholds: Configuration;
}) => {
  CONNECTION_STRENGTH_MAP[ConnectionStrength.Strong].threshold =
    thresholds.strongModerate;
  CONNECTION_STRENGTH_MAP[ConnectionStrength.Moderate].threshold =
    thresholds.moderateSubpar;
  CONNECTION_STRENGTH_MAP[ConnectionStrength.Poor].threshold = thresholds.subparWeak;

  const connectionStrengths = [
    CONNECTION_STRENGTH_MAP[ConnectionStrength.Strong],
    CONNECTION_STRENGTH_MAP[ConnectionStrength.Moderate],
    CONNECTION_STRENGTH_MAP[ConnectionStrength.Poor],
    CONNECTION_STRENGTH_MAP[ConnectionStrength.Weak],
  ] as const;

  for (const connectionStrength of connectionStrengths) {
    if (connection >= connectionStrength.threshold) {
      return connectionStrength;
    }
  }
  return connectionStrengths[connectionStrengths.length - 1];
};
