import theme from 'theme';

const Target = ({
  size = 16,
  color = theme.palette.primary.main,
  style = {},
  className = '',
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      style={style}
      viewBox={`0 0 16 16`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_224_22267)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.7738 0.675364C12.9853 0.709691 13.1674 0.843609 13.2632 1.03528L13.8305 2.16985L14.9651 2.73714C15.1568 2.83297 15.2907 3.01511 15.325 3.22663C15.3593 3.43816 15.2899 3.6533 15.1384 3.80483L13.1384 5.80483C13.0133 5.92985 12.8438 6.00009 12.667 6.00009H10.9431L8.47171 8.47146C8.21136 8.73181 7.78925 8.73181 7.5289 8.47146C7.26855 8.21111 7.26855 7.789 7.5289 7.52865L10.0003 5.05728V3.33342C10.0003 3.15661 10.0705 2.98704 10.1956 2.86202L12.1956 0.862018C12.3471 0.710488 12.5622 0.641038 12.7738 0.675364ZM11.3336 4.66676H12.3908L13.5415 3.51606L13.0355 3.26304C12.9065 3.19853 12.8019 3.09392 12.7373 2.9649L12.4843 2.45887L11.3336 3.60957V4.66676ZM8.00033 2.00009C4.68662 2.00009 2.00033 4.68638 2.00033 8.00009C2.00033 11.3138 4.68662 14.0001 8.00033 14.0001C11.314 14.0001 14.0003 11.3138 14.0003 8.00009C14.0003 7.6319 14.2988 7.33342 14.667 7.33342C15.0352 7.33342 15.3337 7.6319 15.3337 8.00009C15.3337 12.0502 12.0504 15.3334 8.00033 15.3334C3.95024 15.3334 0.666992 12.0502 0.666992 8.00009C0.666992 3.95 3.95024 0.666756 8.00033 0.666756C8.36852 0.666756 8.66699 0.965233 8.66699 1.33342C8.66699 1.70161 8.36852 2.00009 8.00033 2.00009ZM8.00033 5.33342C6.52757 5.33342 5.33366 6.52733 5.33366 8.00009C5.33366 9.47285 6.52757 10.6668 8.00033 10.6668C9.47308 10.6668 10.667 9.47285 10.667 8.00009C10.667 7.6319 10.9655 7.33342 11.3337 7.33342C11.7018 7.33342 12.0003 7.6319 12.0003 8.00009C12.0003 10.2092 10.2095 12.0001 8.00033 12.0001C5.79119 12.0001 4.00033 10.2092 4.00033 8.00009C4.00033 5.79095 5.79119 4.00009 8.00033 4.00009C8.36852 4.00009 8.66699 4.29857 8.66699 4.66676C8.66699 5.03495 8.36852 5.33342 8.00033 5.33342Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_224_22267'>
          <rect width={size} height={size} fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Target;
