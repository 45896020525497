import { cx } from '@emotion/css';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@mysteryco/design';

type Props = {
  component?: React.ElementType; // For accessibility
  children: React.ReactNode;
  className?: string;
};

const AsideHeader = ({ children, component = 'h2', className, ...props }: Props) => {
  const classes = useClasses();
  return (
    <Typography component={component} className={cx(classes.root, className)} {...props}>
      {children}
    </Typography>
  );
};

const useClasses = makeStyles({
  root: {
    fontWeight: 700,
    fontSize: '.75rem',
    lineHeight: 1.67,
    letterSpacing: '0.08em',
    color: colors.Glue_Ink10,
    textTransform: 'uppercase',
  },
});

export default AsideHeader;
