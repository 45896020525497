// @ts-strict-ignore
import { useState } from 'react';

import { gql, useMutation } from '@apollo/client';
import { Button, Form, Input, Modal } from 'antd';
import _ from 'lodash';

import { Cancel, Check, EditOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { Bold, CreditCard, Hr, Loading, Page } from 'components/core';
import { Sizes, Type } from 'constants/index';
import useStripe from 'hooks/useStripe';
import { formatPhone } from 'lib/helpers/phone';
import { ViewerFragmentFragment } from '../types';

const Strings = {
  ACCOUNT: `Account`,
  ADD_PAYMENT_INFO: `Add payment info`,
  PAYMENT_INFO: `Payment info`,
  GIFT_CARD_BALANCE: `Gift Card balance`,
  NAME: `Name`,
  EMAIL: `Email`,
  PHONE: `Phone`,
  ADD_CARD: `Add card`,
  SAVE: `Save`,
  FIRST_NAME: `First name`,
  LAST_NAME: `Last name`,
};

interface UserProfileProps {
  viewer: ViewerFragmentFragment | undefined;
}

interface EditableNameProps {
  user: ViewerFragmentFragment;
}

const updateViewerMutation = gql`
  mutation updateViewer($id: ID!, $firstName: String, $lastName: String, $email: String) {
    updateUser(id: $id, firstName: $firstName, lastName: $lastName, email: $email) {
      id
      firstName
      lastName
      name
      email
    }
  }
`;

const addOrUpdateSourceForUserMutation = gql`
  mutation addOrUpdateSourceForUser($userId: ID!, $source: String!) {
    addOrUpdateSourceForUser(userId: $userId, source: $source) {
      id
      stripeId
    }
  }
`;

const useStyles = makeStyles({
  flex: {
    display: 'flex',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  boldBlock: {
    display: 'block',
    marginRight: Sizes.SPACING_LARGE * 2,
    width: Sizes.GRID_UNIT * 17,
  },
  block: {
    display: 'block',
    marginRight: Sizes.GRID_UNIT * 2,
  },
  input: {
    marginRight: Sizes.GRID_UNIT,
    width: Sizes.GRID_UNIT * 15,
  },
  mr: {
    marginRight: Sizes.GRID_UNIT,
  },
  fontMedium: {
    ...Type.FontSizes.MEDIUM,
  },
  'mb-0': {
    marginBottom: 0,
  },
});

const EditableName = (props: EditableNameProps) => {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState(props.user.firstName || '');
  const [lastName, setLastName] = useState(props.user.lastName || '');

  const [updateViewer] = useMutation(updateViewerMutation);

  const classes = useStyles();
  const ICON_FONT_SIZE = 'small';

  const resetName = () => {
    setFirstName(props.user.firstName || '');
    setLastName(props.user.lastName || '');
  };

  const notEditing = (
    <div className={classes.flexCenter}>
      <span className={classes.block}>{props.user.name}</span>
      <Button onClick={() => setEditing(true)} size='small' type='text'>
        <EditOutlined fontSize={ICON_FONT_SIZE} />
      </Button>
    </div>
  );

  const amEditing = (
    <div className={classes.flex}>
      <Input
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        placeholder={Strings.FIRST_NAME}
        className={classes.input}
        disabled={loading}
      />
      <Input
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        placeholder={Strings.LAST_NAME}
        className={classes.input}
        disabled={loading}
      />
      <Button
        onClick={async () => {
          try {
            setLoading(true);
            await updateViewer({ variables: { id: props.user.id, firstName, lastName } });
            setEditing(false);
            setLoading(false);
          } catch (error) {
            setLoading(false);
          }
        }}
        type='primary'
        loading={loading}
        className={classes.mr}
      >
        <Check fontSize={ICON_FONT_SIZE} />
      </Button>
      <Button
        type='text'
        onClick={() => {
          setEditing(false);
          resetName();
        }}
        disabled={loading}
      >
        <Cancel fontSize={ICON_FONT_SIZE} />
      </Button>
    </div>
  );

  return <div>{editing ? amEditing : notEditing}</div>;
};

export const UserInfo = (props: UserProfileProps) => {
  const stripe = useStripe();
  const { viewer } = props;
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stripeValidationError, setStripeValidationError] = useState('');

  const [updateSource] = useMutation(addOrUpdateSourceForUserMutation);

  const classes = useStyles();

  const renderSection = (label: string, content: any) => (
    <div className={[classes.flexCenter, classes.fontMedium].join(' ')}>
      <Bold className={classes.boldBlock}>{label}</Bold>
      {content}
    </div>
  );

  const renderSavedPaymentInfo = () => {
    const { cardOnFile } = props.viewer;
    if (!cardOnFile)
      return (
        <>
          <Button type='primary' onClick={() => setPaymentModalOpen(true)}>
            {Strings.ADD_CARD}
          </Button>
          <Modal
            title={Strings.ADD_PAYMENT_INFO}
            visible={paymentModalOpen}
            onOk={onClickSavePaymentInfo}
            confirmLoading={loading}
            okText={Strings.ADD_CARD}
            onCancel={() => setPaymentModalOpen(false)}
            destroyOnClose
          >
            <Form.Item
              help={stripeValidationError}
              className={classes['mb-0']}
              validateStatus={stripeValidationError ? 'error' : ''}
            >
              <CreditCard />
            </Form.Item>
          </Modal>
        </>
      );
    return (
      <div>
        •••• {cardOnFile.last4} ({cardOnFile.brand})
      </div>
    );
  };

  const onClickSavePaymentInfo = async () => {
    setLoading(true);
    const response = await stripe!.createToken({ name: Strings.PAYMENT_INFO });
    if (_.has(response, 'token.id')) {
      setStripeValidationError('');
      await updateSource({
        variables: {
          userId: props.viewer.id,
          source: response.token!.id,
        },
      });
    } else {
      setStripeValidationError(response.error.message);
      console.error(`No token returned by Stripe`);
    }
    setLoading(false);
  };

  return (
    <Page title={Strings.ACCOUNT}>
      {viewer ? (
        <>
          {renderSection(Strings.NAME, <EditableName user={viewer} />)}
          <Hr />
          {renderSection(Strings.PHONE, formatPhone(viewer.phone))}
          <Hr />
          {renderSection(Strings.EMAIL, viewer.email)}
          <Hr />
          {renderSection(Strings.PAYMENT_INFO, renderSavedPaymentInfo())}
        </>
      ) : (
        <Loading />
      )}
    </Page>
  );
};

export default UserInfo;
