import { PageMetaData, Paths } from 'Routes';
import PageWrapper from 'glue/components/structure/PageWrapper';
import {
  NavigationTab,
  NavigationTabs,
  NavigationTabsList,
} from 'glue/components/tabs/NavigationTabs';
import { TabLabel } from 'glue/components/tabs/TabLabel';
import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch } from 'react-router';
import { ViewerFragmentFragment } from 'types';
import Events from './Events';
import Meetups from './Meetups';
import { viewerHasOrganization } from 'lib/helpers/organization';

export const ACTIVITIES = [
  {
    path: 'events',
    name: 'Events',
    component: (props) => <Events {...props} />,
    disabled: false,
    label: '',
    requiresOrg: false,
  },
  {
    path: 'meetups',
    name: 'Meetups',
    component: (props) => <Meetups {...props} />,
    disabled: false,
    label: 'Beta',
    requiresOrg: true,
  },
];

export const DEFAULT_ACTIVITIES_TYPE = Object.keys(ACTIVITIES)[0];

export const getActivitiesTypePath = (type: string) => {
  return `${Paths.ACTIVITIES}/${type}`;
};

export const getActivitiesType = (pathname: string) => {
  return pathname.split(`${Paths.ACTIVITIES}/`)[1]?.split('/')[0] ?? ACTIVITIES[0].path;
};

const Activities = ({ viewer }: { viewer: ViewerFragmentFragment | undefined }) => {
  const viewerHasOrganizations = viewerHasOrganization(viewer);
  return (
    <PageWrapper title={'Activities'}>
      <Helmet>
        <title>{PageMetaData.ACTIVITIES.title}</title>
      </Helmet>
      <NavigationTabs>
        <NavigationTabsList>
          {ACTIVITIES.map((tab) => {
            if (tab.requiresOrg && !viewerHasOrganizations) {
              return null;
            }
            return (
              <NavigationTab
                to={`${Paths.ACTIVITIES}/${tab.path}`}
                key={tab.path}
                disabled={tab.disabled}
              >
                {tab.name} {tab.label && <TabLabel value={tab.label} />}
              </NavigationTab>
            );
          })}
        </NavigationTabsList>
      </NavigationTabs>
      <Switch>
        <Route
          exact
          path={Paths.ACTIVITIES}
          render={() => <Redirect to={getActivitiesTypePath(ACTIVITIES[0].path)} />}
        />
        {ACTIVITIES.map(({ component: Component, path }) => (
          <Route
            path={getActivitiesTypePath(path)}
            key={`${path}-link`}
            render={() => <Component viewer={viewer} />}
          />
        ))}
      </Switch>
    </PageWrapper>
  );
};

export default Activities;
