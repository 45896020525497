import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import IntersectCircles from 'components/icons/IntersectCircles';

import theme from 'theme';

const PendingApproval = () => {
  const classes = useStyles();

  return (
    <Box
      my={4}
      display='flex'
      justifyContent={'center'}
      alignItems={'center'}
      className={classes.pageWrapper}
    >
      <Box style={{ maxWidth: 740 }}>
        <Box display='flex' justifyContent='center' mb={theme.spacing(3)}>
          <div className={classes.messageIcon}>
            <IntersectCircles height={20} width={20} color={theme.palette.primary[900]} />
          </div>
        </Box>
        <Box flex>
          <Typography align='center' className={classes.mainHeadline}>
            Building your graph
          </Typography>
          <Box>
            <Typography align='center' className={classes.mainSubheadline}>
              We're working on connecting the dots
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.notificationText}>
              You'll be notified as soon as your graph is ready.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  pageWrapper: {
    height: 'calc(100% - 48px)',
    width: 'calc(100% - 48px)',
    border: `1px solid ${theme.palette.primary[100]}`,
    background: '#FFFDFF',
    borderRadius: '10px',
    position: 'relative',
    padding: '32px',
    margin: ' 24px',
  },

  mainHeadline: {
    color: theme.palette.primary[500],
    fontSize: '14px',
    textAlign: 'center',
  },
  mainSubheadline: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '28px',
    textAlign: 'center',
  },
  notificationText: {
    fontSize: '14px',
    fontWeight: 400,
    color: theme.palette.text.secondary,
    textAlign: 'center',
  },
  messageIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '44px',
    height: '44px',
    borderRadius: '44px',
    backgroundColor: theme.palette.primary[100],
  },
  contentWrapper: { maxWidth: 250, borderRadius: 20 },
});

export default PendingApproval;
