import { gql } from '@apollo/client';
import { css } from '@emotion/react';
import { Box, Typography } from '@material-ui/core';
import { colors } from '@mysteryco/design';
import ArrowRight from '@mysteryco/design/icons/ArrowRight';
import { Paths } from 'Routes';
import billingPlaceholderImage from 'glue/assets/artwork/billing-placeholder.png';
import { LinkButton } from 'glue/components/buttons/Button';
import mixpanel from 'mixpanel-browser';
import { CustomerType, NoOrganizationPlaceholderViewerFragment } from 'types';

const NoOrganizationPlaceholder = ({
  viewer,
}: {
  viewer: NoOrganizationPlaceholderViewerFragment;
}) => {
  const onUpsellClick = () => {
    mixpanel.track('no organization stopgap cta clicked', {
      source: 'no organization stopgap',
      customerType: viewer?.customerType,
    });
  };

  const isEventsOnly = viewer?.customerType === CustomerType.EventsOnly;

  return (
    <Box flexDirection='column' width='100%' display='flex' css={{ gap: 24 }}>
      <img
        css={styles.image}
        alt='A woman with a notebook standing in front of a collection of abstract agendas, graphs, and social connections'
        src={billingPlaceholderImage}
      />
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Box display='flex' flexDirection='column' css={{ gap: 12 }}>
          <Typography variant='h3' css={styles.title}>
            {isEventsOnly ? 'Upgrade to Glue' : 'Get started with Glue'}
          </Typography>
          <Typography variant='h4' css={styles.subtitle}>
            Your all-in-one connection solution
          </Typography>
        </Box>
        <LinkButton
          shape='round'
          endIcon={<ArrowRight size={24} color='currentColor' />}
          color='green'
          to={Paths.BOOKING}
          newTab
          onClick={onUpsellClick}
        >
          Book an Event
        </LinkButton>
      </Box>
    </Box>
  );
};
NoOrganizationPlaceholder.fragments = gql`
  fragment NoOrganizationPlaceholderViewer on User {
    id
    customerType
    requestedTeamEvents {
      id
    }
  }
`;

export default NoOrganizationPlaceholder;

const styles = {
  image: css({
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
  }),
  cta: css({
    color: 'inherit',
    textDecoration: 'none',
  }),
  title: css({
    fontSize: 28,
    fontWeight: 700,
    lineHeight: '140%',
    color: colors.Glue_Ink00,
  }),
  subtitle: css({
    fontSize: 28,
    fontWeight: 500,
    lineHeight: '140%',
    color: colors.Glue_Ink00,
  }),
  caption: css({
    color: colors.Glue_Ink10,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '140%',
  }),
};
