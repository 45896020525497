import { css } from '@emotion/react';
import { colors } from '@mysteryco/design';
import theme from 'theme';
import { card } from './sharedStyles';
import styled from '@emotion/styled';
import Link from 'glue/components/buttons/Link';
import Button from 'glue/components/buttons/Button';
import ArrowRightIcon from '@mysteryco/design/icons/ArrowRight';
import TextLink from 'glue/components/buttons/TextLink';
import { DateTime } from 'luxon';

export const ActivityScheduleCardRoot = styled(Link)({
  display: 'grid',
  gridTemplateAreas: '"image details" "blank actions"',
  gridTemplateColumns: '150px 1fr',
  ...card.root,
  fontStyle: 'normal',
  flex: 1,
  transition: 'all .3s ease-in-out',
  overflow: 'hidden',
  padding: theme.spacing(5),
  gap: theme.spacing(7),

  '&:hover': card.hover,
  '&:focus-visible': card.focus,

  [theme.breakpoints.down('sm')]: {
    gridTemplateAreas: '"image" "details" "actions"',
    gridTemplateColumns: '1fr',
  },
});

export const ActivityScheduleCardImage = ({ imageUrl }: { imageUrl: string }) => {
  return <img src={imageUrl} css={styles.image} alt='activity' />;
};

export const ActivityScheduleCardDetails = ({
  name,
  description,
  date,
}: {
  name: string;
  description: string;
  /** A ISO date string */
  date?: string;
}) => {
  return (
    <div css={styles.details}>
      <div css={styles.name}>{name}</div>
      <div css={styles.description}>{description}</div>
      {date && (
        <div css={styles.date}>
          {DateTime.fromISO(date).toFormat('EEE MMMM dd, yyyy @ h:mm a ZZZZ')}
        </div>
      )}
    </div>
  );
};

export const ActivityScheduleCardActions = styled.div({
  gridArea: 'actions',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(6),
});

const styles = {
  details: css({
    gridArea: 'details',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
  }),
  name: css({
    fontWeight: 700,
    fontSize: 18,
    lineHeight: 1.4,
    color: colors.Glue_Ink00,
    marginBottom: 8,
  }),
  description: css({
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1.4,
    color: colors.Glue_Ink10,
    marginBottom: 20,
  }),
  date: css({
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 1.4,
    color: colors.Glue_Ink00,
  }),
  image: css({
    gridArea: 'image',
    width: '100%',
    objectFit: 'cover',
    height: 'auto',
    marginBottom: 'auto',
    borderRadius: 4,
  }),
};

const ActivityScheduleCard = ({
  linkUrl,
  cta,
  imageUrl,
  name,
  description,
  date,
  onClick,
}: {
  linkUrl: string;
  cta?: string;
  imageUrl: string;
  description: string;
  date: string;
  name: string;
  onClick?: () => void;
}) => (
  <ActivityScheduleCardRoot to={linkUrl} onClick={onClick}>
    <ActivityScheduleCardImage imageUrl={imageUrl} />
    <ActivityScheduleCardDetails name={name} description={description} date={date} />
    <ActivityScheduleCardActions>
      {/*
      The action here is purely for display purposes; the entire card is a
      clickable link. So we also remove it from tab order (tabIndex=-1) so
      that it doesn't get in the way of the user's tabbing through the
      page.
      */}
      {cta ? (
        <Button
          tabIndex={-1}
          color='green'
          shape='round'
          endIcon={<ArrowRightIcon size={22} color='inherit' />}
        >
          {cta}
        </Button>
      ) : (
        <TextLink visualOnly tabIndex={-1}>
          View details
        </TextLink>
      )}
    </ActivityScheduleCardActions>
  </ActivityScheduleCardRoot>
);

export default ActivityScheduleCard;
