import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NewPrimaryButton from 'components/core/NewPrimaryButton';
import { EncircleIcon } from 'components/icons/EncircleIcon';
import DataIcon from 'components/icons/DataIcon';
import FaceFrown from 'components/icons/FaceFrown';
import ShootingStar from 'components/icons/ShootingStar';
import { goalToString, scoreTypeToGoal } from 'lib/helpers/goals';
import mixpanel from 'mixpanel-browser';
import { Paths } from 'Routes';
import theme from 'theme';
import { colors } from '@mysteryco/design';
import { ScoreType, CustomerType } from 'types';

const useStyles = makeStyles({
  noEventsText: {
    color: theme.palette.primary[800],
  },
  ctaText: {
    color: theme.palette.primary[800],
    fontSize: '12px',
  },
  explainerText: {
    fontSize: '18px',
    fontWeight: 600,
  },
});

const EmptyState = ({
  scoreType,
  teamName,
  monthName,
  showNoData,
  showCTA,
}: {
  scoreType: ScoreType;
  teamName: string;
  monthName: string;
  showCTA: boolean;
  showNoData: boolean;
}) => {
  const classes = useStyles();
  return (
    <div
      style={{
        marginTop: '40px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '300px',
        padding: '64px',
        border: `1px solid ${theme.palette.primary[100]}`,
        borderRadius: theme.spacing(1),
      }}
    >
      {scoreType === ScoreType.SenseOfBelonging ? (
        <>
          <EncircleIcon backgroundColor={theme.palette.primary[100]} padding='12px'>
            <DataIcon width={24} height={24} color={colors.Purple900} />
          </EncircleIcon>
          <Typography
            className={classes.explainerText}
            css={{ margin: `${theme.spacing(2)} 0` }}
          >
            {`You had no tagged events during ${monthName}`}
          </Typography>
          <Box width={'40%'} textAlign={'center'}>
            <Typography className={classes.ctaText}>
              Event tagging is new to Glue so this is normal. If you believe there’s an
              error contact your success manager.
            </Typography>
          </Box>
        </>
      ) : (
        <>
          <EncircleIcon backgroundColor={theme.palette.primary[100]} padding='12px'>
            <FaceFrown />
          </EncircleIcon>
          <Box mt={theme.spacing(3)}>
            <Typography variant='body2' className={classes.noEventsText}>
              {showNoData ? 'No data available' : `Couldn't find any events`}
            </Typography>
          </Box>
          <Typography className={classes.explainerText}>
            {showNoData
              ? 'Try a different month to review'
              : `${teamName} had no ${goalToString(
                  scoreTypeToGoal(scoreType),
                ).toLowerCase()} events for ${monthName}`}
          </Typography>
          {showCTA && (
            <Box mt={theme.spacing(2)}>
              <Typography variant='body2' className={classes.ctaText}>
                It's never too late to schedule a get-together
              </Typography>
              <Box width='fit-content' margin={`${theme.spacing(2)} auto 0px`}>
                <NewPrimaryButton
                  onClick={() => {
                    mixpanel.track('book event opened', {
                      source: 'insights',
                      customerType: CustomerType.EventsAndInsights,
                    });
                    window.location.assign(Paths.BOOKING_DEFAULT_START);
                  }}
                  label='Book event'
                  prependedIcon={<ShootingStar />}
                />
              </Box>
            </Box>
          )}
        </>
      )}
    </div>
  );
};

export default EmptyState;
