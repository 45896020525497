// @ts-strict-ignore
import { ScoreDetailsTeamFragment } from 'types';

/**
 * Given all teams in an organization, the ID of the root team, and the ID of
 * the user that is the manager of the team currently being viewed, return a
 * set of User IDs including the manager's ID that reports directly or indirectly
 * to that manager
 */
export const getSubMembers = ({
  teams,
  rootTeamId,
  currentTeamId,
}: {
  teams: ScoreDetailsTeamFragment[];
  rootTeamId: string | undefined;
  currentTeamId: string;
}): Set<string> => {
  const tree = createTreeFromOrgTeams(teams, rootTeamId);
  const managerId = getTeamManagerIdFromTeamId(teams, currentTeamId);
  return getSubMemberIdsFromTree(tree, managerId);
};

const getTeamManagerIdFromTeamId = (
  teams: ScoreDetailsTeamFragment[],
  teamId: string | undefined,
): string => {
  if (!teams?.length) return null;

  const teamManagerId = teams.find((team) => team.id === teamId)?.manager?.id;
  return teamManagerId;
};

const createTreeFromOrgTeams = (
  teams: ScoreDetailsTeamFragment[],
  rootTeamId: string | undefined,
): Object => {
  if (!teams?.length) return null;

  const rootTeamManagerId = getTeamManagerIdFromTeamId(teams, rootTeamId);

  const stack = [] as { pointer: Object; team: { id: string }[] }[];
  const tree = {
    [rootTeamManagerId]: {},
  };

  const teamsByManagerId = teams.reduce((acc, team) => {
    acc[team.manager.id] = team.members;
    return acc;
  }, {});

  stack.push({
    pointer: tree[rootTeamManagerId],
    team: teamsByManagerId[rootTeamManagerId],
  });

  // Reconstruct team tree from depth first search
  while (stack.length) {
    const { pointer, team } = stack.pop();
    for (const { id } of team) {
      pointer[id] = {};
      const foundTeam = teamsByManagerId[id];
      if (foundTeam) {
        stack.push({ pointer: pointer[id], team: foundTeam });
      }
    }
  }

  return tree;
};

const getSubMemberIdsFromTree = (tree: Object, managerId: string): Set<string> => {
  const members = new Set<string>();
  if (!tree) return members;

  const stack = [] as { pointer: Object; keys: string[] }[];
  let foundRoot = false;
  stack.push({ pointer: tree, keys: Object.keys(tree) });
  let startingPoint = null;

  while (!foundRoot && stack.length) {
    const { pointer, keys } = stack.pop();
    for (const key of keys) {
      if (key === managerId) {
        // Found the correct starting point to find sub-members from.
        // Bookmark our place in the tree, and stop DFS.
        startingPoint = pointer[key];
        foundRoot = true;
        break;
      } else {
        // The manager is deeper in the tree. Get the list of members at this depth of the tree,
        // and add them all to the queue to be searched
        const thisTeamsMembers = Object.keys(pointer[key]);
        if (thisTeamsMembers.length) {
          stack.push({ pointer: pointer[key], keys: thisTeamsMembers });
        }
      }
    }
  }

  // Never found the manager in the tree, return an empty set
  if (!startingPoint) {
    return members;
  }

  // Traverse the tree from our starting point, adding members to our list as we go
  const queue = [startingPoint];

  while (queue.length) {
    const node = queue.shift();
    for (const key of Object.keys(node)) {
      members.add(key);
      if (Object.keys(node[key]).length) {
        queue.push(node[key]);
      }
    }
  }

  return members;
};
