import { css } from '@emotion/react';
import { useField } from 'formik';
import Check from '@mysteryco/design/icons/Check';
import ButtonGroup from 'glue/components/buttons/ButtonGroup';
import { PreferenceQuestionsFragmentFragment } from 'types';
import { existsFilter } from 'lib/helpers/maybe';
import PreferencesSection, { sectionStyles } from './PreferencesSection';
import theme from 'theme';

const ACTIVITY_ANSWER_LABELS = ['No thanks', 'Sure', 'Count me in'];

const Events = ({
  activityQuestions,
}: {
  activityQuestions: PreferenceQuestionsFragmentFragment[];
}) => {
  const [, meta, helpers] = useField({ name: 'activityAnswers' });
  const { value: activityAnswers } = meta;
  const { setValue } = helpers;
  return (
    <PreferencesSection
      title={'Events'}
      description={
        'Glue uses your preferences to choose the best team bonding event for you.'
      }
      css={{ borderBottom: 'none' }}
    >
      <div css={sectionStyles.content}>
        {activityQuestions.length > 0 &&
          activityQuestions.map(
            (activityQuestion: PreferenceQuestionsFragmentFragment, i: number) => (
              <div key={`preference-question-${i}`} css={styles.activity}>
                <div css={sectionStyles.label}>
                  Are you into {activityQuestion.question.toLowerCase()}
                </div>
                <ButtonGroup
                  buttonsProps={ACTIVITY_ANSWER_LABELS.map((label, value) => {
                    const answer = activityQuestion.preferenceAnswers?.find(
                      (preferenceAnswer) => activityAnswers.includes(preferenceAnswer.id),
                    );
                    return {
                      label,
                      selected: answer?.value === value,
                      Icon: answer?.value === value ? Check : undefined,
                      onClick: () => {
                        setValue(
                          activityAnswers
                            .filter((activityAnswer) => answer?.id !== activityAnswer)
                            .concat(
                              activityQuestion.preferenceAnswers?.find(
                                (preferenceAnswer) => preferenceAnswer.value === value,
                              )?.id || '',
                            )
                            .sort(),
                        );
                      },
                    };
                  }).filter(existsFilter)}
                />
              </div>
            ),
          )}
      </div>
    </PreferencesSection>
  );
};

const styles = {
  activity: css({
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    gap: theme.spacing(4),
  }),
};

export default Events;
