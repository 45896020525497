import { css } from '@emotion/react';
import { colors } from '@mysteryco/design';
import { MouseEvent } from 'react';
import theme from 'theme';
import { card } from './sharedStyles';

type Props = {
  headline: React.ReactNode;
  children: React.ReactNode;
  icon?: React.ReactNode;
  onClick?: (event: MouseEvent) => void;
  href?: string;
  [key: string]: any;
};

const ValueProp = ({ icon, headline, children, href, onClick, ...props }: Props) => {
  const Card = href ? 'a' : 'div';
  const role = href ? 'link' : onClick ? 'button' : 'none'; // for accessibility
  return (
    <Card css={styles.root} onClick={onClick} role={role} {...props}>
      {icon && <div css={styles.icon}>{icon}</div>}
      <header css={styles.headline}>{headline}</header>
      <p css={styles.body}>{children}</p>
    </Card>
  );
};

const styles = {
  root: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(7),
    gap: theme.spacing(3),
    ...card.root,
    borderRadius: theme.spacing(3),
    '&[role=link], &[role=button]': {
      cursor: 'pointer',
      color: colors.Glue_Ink00,
      '&:hover': {
        ...card.hover,
      },
    },
  }),
  icon: css({
    width: 24,
    height: 24,
    svg: {
      color: colors.Glue_Ink00,
    },
  }),
  headline: css({
    fontFamily: 'inherit',
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '1.125rem',
    lineHeight: 1.2,
    color: colors.Glue_Ink00,
  }),
  body: css({
    fontFamily: 'inherit',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '.75rem',
    lineHeight: 1.43,
  }),
};

export default ValueProp;
