// @ts-strict-ignore
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@mysteryco/design/src';
import classNames from 'classnames';
import { PremiumExperienceBanner } from 'components/Experiences/PremiumExperienceBanner';
import { DetailedPriceEstimatePopover } from 'components/Experiences/DetailedPriceEstimatePopover';
import InfoCircleLight from 'components/icons/InfoCircleLight';
import { PricingUnitIcon } from 'components/icons/PricingUnitIcon';
import { pluralize } from 'humanize-plus';
import { formatOrderPricingBreakdown } from 'lib/helpers/money';
import theme from 'theme';
import {
  ContractUnitType,
  PricingInfoContractFragment,
  PricingInfoTemplateFragment,
  RemainingUnitsContractFragment,
} from 'types';
import { gql } from '@apollo/client';
import { SelectionCard } from './SelectionCards';
import { css } from '@emotion/react';
import SectionSubHeader from 'components/SectionSubHeader';
import {
  formatContractUnit,
  getContractIcon,
  getContractUnit,
  contractHasPremiumUnits,
} from 'lib/helpers/contract';
import {
  BillingDetails,
  BillingHeader,
} from 'glue/scenes/Settings/components/BillingCard';
import React from 'react';
import { getContractName } from 'utils/contract';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  mainRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    gap: '16px',
    alignItems: 'flex-end',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  estimateHeading: {
    color: colors.Dusk,
    fontSize: '12px',
    textTransform: 'uppercase',
    fontWeight: 700,
    letterSpacing: '2px',
    marginBottom: '8px',
  },
  headingRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '8px',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  mainRowItem: {
    fontWeight: theme.typography.fontWeightBold,
    color: colors.Dusk,
    flex: '1 1 0',
  },
  price: {
    color: colors.Black,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    alignItems: 'flex-end',
    justifySelf: 'flex-end',
  },
  lightText: {
    fontWeight: theme.typography.fontWeightRegular,
    color: colors.DarkGray,
  },
  minimumDisclaimer: {
    color: colors.DarkGray,
    fontSize: '0.75rem',
    marginTop: '8px',
    alignSelf: 'flex-end',
  },
  unitIcon: {
    marginRight: '8px',
    // nudge down for better centering
    position: 'relative',
    top: '2px',
  },
  helpIcon: {
    stroke: colors.MediumGray,
    marginLeft: '8px',
  },
  remainingUnits: {
    color: colors.DarkGray,
    fontWeight: 500,
    '& span': {
      color: theme.palette.primary[700],
    },
  },
  pricingHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(6),
  },
});

function RemainingUnits({ contract }: { contract: RemainingUnitsContractFragment }) {
  const contractType = contract.type;
  const classes = useStyles();

  if (contractType === ContractUnitType.Cents) {
    return null;
  }

  const unit = contractType === ContractUnitType.Recurring ? 'seat' : 'credit';
  const unitCount =
    contractType === ContractUnitType.Recurring
      ? contract.unitCount
      : (contract.unitCount ?? 0) / 100;
  const premiumUnitCount =
    contractType === ContractUnitType.Recurring
      ? contract.premiumUnitCount
      : (contract.premiumUnitCount ?? 0) / 100;

  const premiumAddition =
    contractType === ContractUnitType.Recurring
      ? ` and ${premiumUnitCount} upgrade ${pluralize(premiumUnitCount, unit)} `
      : '';

  return (
    <Box className={classes.remainingUnits}>
      You have{' '}
      <span>
        {unitCount} {pluralize(unitCount, unit)}
      </span>{' '}
      {premiumAddition}
      remaining in your Glue plan
    </Box>
  );
}
RemainingUnits.fragments = {
  contract: gql`
    fragment RemainingUnitsContract on Contract {
      type
      unitCount
      premiumUnitCount
    }
  `,
};

const ContractCard = ({
  contract,
  isSelected,
  onClick,
}: {
  contract: PricingInfoContractFragment;
  isSelected: boolean;
  onClick: () => void;
}) => {
  const type = contract.type;
  const Icon = getContractIcon(type);
  const units = getContractUnit(type);
  const hasPremiumUnits = contractHasPremiumUnits(type);
  const contractInfo = (
    <div css={styles.contractInfoRow}>
      <div css={styles.contractInfoCell}>
        <BillingHeader>{units} remaining</BillingHeader>
        <BillingDetails>
          <Icon color={colors.Glue_Ink10} size={24} />
          {formatContractUnit(type, contract.unitCount)}
        </BillingDetails>
      </div>
      {hasPremiumUnits && (
        <div css={styles.contractInfoCell}>
          <BillingHeader>Upgrade {units} remaining</BillingHeader>
          <BillingDetails>
            <Icon color={colors.Glue_Ink10} size={24} />
            {formatContractUnit(type, contract.premiumUnitCount)}
          </BillingDetails>
        </div>
      )}
    </div>
  );
  return (
    <SelectionCard
      title={getContractName(contract)}
      selected={isSelected}
      content={contractInfo}
      onClick={onClick}
    />
  );
};

const ContractSelection = ({
  contracts,
  selectedContract,
  setSelectedContract,
}: {
  selectedContract: string;
  setSelectedContract: (val: string) => void;
  contracts: PricingInfoContractFragment[];
}) => {
  return (
    <>
      <SectionSubHeader
        title='Select a contract'
        subTitle='Choose which of your contracts you are using to pay for the event.'
      />
      {contracts.map((contract) => (
        <ContractCard
          contract={contract}
          isSelected={contract.id === selectedContract}
          onClick={() => setSelectedContract(contract.id)}
        />
      ))}
    </>
  );
};

const PricingInfo = ({
  template,
  guestCount,
  contracts,
  isSurpriseToAll,
  selectedContract,
  setSelectedContract,
}: {
  template: PricingInfoTemplateFragment;
  guestCount: number;
  contracts: PricingInfoContractFragment[];
  isSurpriseToAll: boolean;
  selectedContract: string;
  setSelectedContract: (val: string) => void;
}) => {
  const classes = useStyles();
  const defaultContract = contracts?.[0];
  const [pricingBreakdown, setPricingBreakdown] = React.useState(
    formatOrderPricingBreakdown({
      contract: defaultContract,
      guestCount,
      template,
      isSurpriseToAll,
    }),
  );

  const handleSelectContract = (contractId: string) => {
    setSelectedContract(contractId);
    const contract = contracts.find((contract) => contract.id === contractId);
    setPricingBreakdown(
      formatOrderPricingBreakdown({
        contract,
        guestCount,
        template,
        isSurpriseToAll,
      }),
    );
  };

  return (
    <Box display='flex' flexDirection='column' className={classes.root}>
      <Box className={classes.headingRow}>
        <Typography className={classes.estimateHeading}>Estimated price</Typography>
        <DetailedPriceEstimatePopover
          contract={defaultContract}
          prospectiveGuests={guestCount}
          template={template}
          isSurpriseToAll={isSurpriseToAll}
        >
          How is this calculated?
          <InfoCircleLight className={classes.helpIcon} />
        </DetailedPriceEstimatePopover>
      </Box>
      <Box className={classes.mainRow}>
        <Typography className={classes.mainRowItem} variant='h5'>
          {pricingBreakdown.doesNotExceedMinimum ? 'Minimum event cost' : 'Guests cost'}
        </Typography>
        <Box className={classNames(classes.mainRowItem, classes.price)}>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <PricingUnitIcon
              unit={defaultContract?.type ?? ContractUnitType.Cents}
              className={classes.unitIcon}
            />
            <Typography variant='h5' component='span'>
              {pricingBreakdown.subtotal}
              {!pricingBreakdown.doesNotExceedMinimum && (
                <Box component='span' ml={1} className={classes.lightText}>
                  ({guestCount} guests)
                </Box>
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
      {contracts.length > 1 && (
        <ContractSelection
          contracts={contracts}
          selectedContract={selectedContract}
          setSelectedContract={handleSelectContract}
        />
      )}
      {pricingBreakdown.isPremium && <PremiumExperienceBanner />}
      {contracts.length === 1 && <RemainingUnits contract={defaultContract} />}
    </Box>
  );
};
PricingInfo.fragments = {
  contract: gql`
    fragment PricingInfoContract on Contract {
      id
      name
      type
      premiumUnitCostCents
      premiumUnitCount
      unitCount
      unitCostCents
      ...RemainingUnitsContract
    }
    ${RemainingUnits.fragments.contract}
  `,
  template: gql`
    fragment PricingInfoTemplate on MysteryTemplate {
      id
      cost {
        id
        ctcPricingBands {
          unit
          maxUsers
          minUsers
          cents
          credits
        }
      }
      experiences {
        id
        hasPhysicalGoods
        requiresUpgrade
      }
    }
  `,
};

const styles = {
  contractInfoRow: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(6),
  }),
  contractInfoCell: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    padding: 0,
    gap: theme.spacing(2),
  }),
};

export default PricingInfo;
