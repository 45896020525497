import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { Box, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { CheckCircle } from '@material-ui/icons';
import { useResponsive } from 'ahooks';
import config from 'config';
import { Sizes } from 'constants/index';
import Button from 'glue/components/buttons/Button';
import { getNylasInformation } from 'gql/queries';
import { popupCenter } from 'lib/helpers/router';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import theme from 'theme';
import { NylasConnectionState, NylasProvider } from 'types';
import GOOGLE_HELPER_GIF from '../assets/google-cal-access-box.gif';
import MysteryModal from './MysteryModal';
import { Loading } from './core';
import OpenCircleCheck from './icons/OpenCircleCheck';
import Google from './icons/logos/Google';
import Microsoft from './icons/logos/Microsoft';

interface Props {
  onConnection?(): void;
}

const makeAndRedirectToCalendarUrl = ({
  provider,
  windowRef,
}: {
  provider: 'google' | 'microsoft';
  windowRef: React.MutableRefObject<Window | null>;
}) => {
  const urlToRedirectTo = new URL(`${config.api.url}/auth/${provider}/calendarLink`);
  const pathToComeBackTo = '/profile#Integrations';
  const redirectUrl = new URL(`${window.location.origin}${pathToComeBackTo}`);
  urlToRedirectTo.searchParams.append('redirect', redirectUrl.toString());
  windowRef.current = popupCenter({
    url: urlToRedirectTo.toString(),
    title: '',
    w: 400,
    h: 600,
  });
};

const INSTRUCTION_LIST = [
  <Typography variant='body2'>
    First, log into the Google email address associated with your Glue account
  </Typography>,
  <Typography variant='body2'>
    Then,{' '}
    <b>
      make sure you check the permission box that allows Glue to only see your schedule
    </b>
  </Typography>,
];

const MICROSOFT_PROVIDERS = new Set([
  NylasProvider.Eas,
  NylasProvider.Ews,
  NylasProvider.Office365,
]);

const CalendarSyncButtons = ({ onConnection }: Props) => {
  const { md } = useResponsive();
  const openWindowRef = useRef<Window | null>(null);
  const [helperModalOpen, setHelperModalOpen] = useState(false);
  const { data, loading, startPolling, stopPolling } = useQuery(getNylasInformation);
  const nylasAssociationViewer = _.get(data, 'viewer.nylasAssociation');
  const nylasConnectionState = _.get(data, 'viewer.nylasConnectionState');
  const connectedWithGoogle =
    nylasConnectionState !== NylasConnectionState.Inactive &&
    _.get(nylasAssociationViewer, 'provider') === NylasProvider.Gmail;
  const connectedWithMSFT =
    nylasConnectionState !== NylasConnectionState.Inactive &&
    MICROSOFT_PROVIDERS.has(_.get(nylasAssociationViewer, 'provider'));

  useEffect(() => {
    return () => {
      if (openWindowRef.current) openWindowRef.current.close();
      stopPolling();
    };
  }, []);

  useEffect(() => {
    if (nylasAssociationViewer) {
      if (openWindowRef.current) openWindowRef.current.close();
      stopPolling();
      setHelperModalOpen(false);
      if (onConnection) onConnection();
    }
  }, [data]);

  if (loading) return <Loading />;

  return (
    <>
      <MysteryModal
        open={helperModalOpen}
        onCancel={() => {
          setHelperModalOpen(false);
          stopPolling();
        }}
        onConfirm={() => {
          startPolling(500);
          makeAndRedirectToCalendarUrl({ provider: 'google', windowRef: openWindowRef });
        }}
        ctaLabel='Connect calendar'
      >
        <Box style={{ width: md ? 400 : '100%' }}>
          <Box style={{ textAlign: 'center' }}>
            <Typography variant='h5'>
              <b>Connecting your Google Calendar</b>
            </Typography>

            <Typography variant='body1' style={{ marginTop: theme.spacing(4) }}>
              Follow these steps and never miss out on the fun:
            </Typography>
          </Box>

          <List style={{ marginTop: theme.spacing(4) }}>
            {INSTRUCTION_LIST.map((li, i) => {
              return (
                <ListItem key={i} style={{ padding: 0, marginBottom: theme.spacing(2) }}>
                  <ListItemAvatar>
                    <Avatar
                      sizes=''
                      style={{
                        backgroundColor: theme.palette.primary.light,
                        height: 30,
                        width: 30,
                      }}
                    >
                      <Typography
                        variant='body1'
                        style={{
                          color: theme.palette.primary.main,
                          marginRight: 0,
                        }}
                      >
                        <b>{i + 1}</b>
                      </Typography>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={li} />
                </ListItem>
              );
            })}
          </List>

          <Box display={'flex'} justifyContent='center'>
            <Box
              style={{
                backgroundColor: theme.palette.primary.light,
                padding: theme.spacing(3),
                borderRadius: theme.spacing(2),
                marginTop: theme.spacing(4),
              }}
            >
              <img src={GOOGLE_HELPER_GIF} alt='Google checkbox gif' />
            </Box>
          </Box>
          <FinePrintAssurance />
        </Box>
      </MysteryModal>

      <Box css={styles.buttonWrapper}>
        <Button
          startIcon={!connectedWithGoogle && <Google />}
          endIcon={
            connectedWithGoogle && (
              <CheckCircle style={{ color: theme.palette.primary.main }} />
            )
          }
          color='white'
          disabled={connectedWithGoogle || connectedWithMSFT}
          css={styles.defaultButtonStyle}
          onClick={() => setHelperModalOpen(true)}
        >
          {`Connect${connectedWithGoogle ? 'ed' : ''} with Google`}
        </Button>

        <Button
          label={`Connect${connectedWithMSFT ? 'ed' : ''} with Microsoft`}
          startIcon={!connectedWithMSFT && <Microsoft />}
          endIcon={
            connectedWithMSFT && (
              <CheckCircle style={{ color: theme.palette.primary.main }} />
            )
          }
          color='white'
          disabled={connectedWithGoogle || connectedWithMSFT}
          css={styles.defaultButtonStyle}
          onClick={() => {
            startPolling(500);
            makeAndRedirectToCalendarUrl({
              provider: 'microsoft',
              windowRef: openWindowRef,
            });
          }}
        >
          {`Connect${connectedWithMSFT ? 'ed' : ''} with Microsoft`}
        </Button>
      </Box>
    </>
  );
};

const FinePrintAssurance = () => {
  return (
    <Box display={'flex'} mt={4}>
      <OpenCircleCheck height={16} width={16} />
      <Typography variant='body2' style={{ marginLeft: theme.spacing(2) }}>
        All your calendar details will be kept safe and private, we promise.
      </Typography>
    </Box>
  );
};

const styles = {
  buttonWrapper: css({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      height: 150,
      justifyContent: 'center',
    },
    margin: '12px 0',
  }),
  defaultButtonStyle: css({
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: Sizes.BUTTON_BORDER_RADIUS,
    color: theme.palette.common.black,
    gap: theme.spacing(2),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(4),
  }),
};

export default CalendarSyncButtons;
