// @ts-strict-ignore
import { cx } from '@emotion/css';
import {
  Box,
  ButtonBase,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@mysteryco/design';
import AlignLeft01 from '@mysteryco/design/icons/AlignLeft01';
import BarChartCircle01 from '@mysteryco/design/icons/BarChartCircle01';
import FaceHappy from '@mysteryco/design/icons/FaceHappy';
import HomeSmile from '@mysteryco/design/icons/HomeSmile';
import LogOut04 from '@mysteryco/design/icons/LogOut04';
import Route from '@mysteryco/design/icons/Route';
import Settings04 from '@mysteryco/design/icons/Settings04';
import { Paths } from 'Routes';
import { useResponsive } from 'ahooks';
import Offsite from 'components/icons/Offsite';
import config from 'config';
import { GLUE_BANNER_HEIGHT } from 'glue/components/branding/GlueBanner';
import { client } from 'gql/client';
import { getUserIdQuery } from 'gql/queries';
import { viewerHasOrganization } from 'lib/helpers/organization';
import mixpanel from 'mixpanel-browser';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import theme from 'theme';
import { CustomerType, ViewerFragmentFragment } from 'types';
import { isAdmin } from 'utils/security';
import { useViewer } from 'utils/state';
import LogoMark from '../branding/LogoMark';
import LogoType from '../branding/LogoType';
import TooltipSmall from '../information/TooltipSmall';

type DrawerWrapperProps = {
  navItems?: { label: string; href: string }[];
  children: React.ReactNode;
  [key: string]: any;
};

type NavigationState = {
  viewer?: ViewerFragmentFragment;
  history?: any;
  pathname?: any;
  isOpen?: boolean;
  classes?: ClassNameMap;
  [key: string]: any;
};

type NavigationItemProps = {
  icon: React.ReactNode;
  label: string;
  href?: string;
  onClick?: () => void;
  state: NavigationState;
  mixpanelEventName?: string;
  isActive?: boolean;
  hideItem?: boolean;
  tooltipLabel?: string;
  [key: string]: any;
};

const AvatarElement = ({ classes, src }: { classes: ClassNameMap; src?: string }) => {
  return (
    <div className={cx(classes.avatar, src && classes.avatarImage)}>
      {src ? <img src={src} alt='' /> : <FaceHappy size={20} />}
    </div>
  );
};

const NavigationItem = ({
  icon,
  label,
  href,
  onClick,
  state,
  mixpanelEventName,
  isActive = false,
  hideItem = false,
  tooltipLabel,
}: NavigationItemProps) => {
  const { viewer, history, classes, isOpen } = state;
  const handleClick = (event: any) => {
    if (mixpanelEventName) {
      mixpanel.track(mixpanelEventName, {
        source: 'navigation',
        customerType: viewer?.customerType,
        firstTimeBooker: viewer?.requestedTeamEvents.length === 0,
      });
    }
    if (onClick) {
      return onClick();
    }
    if (href) {
      history.push(href);
    }
  };

  return !hideItem ? (
    <TooltipSmall
      title={isOpen ? '' : tooltipLabel || label}
      placement='right'
      disableFlip
      offset={`0, -20`}
    >
      <ListItem
        className={cx(classes.navItem, isActive && classes.active)}
        onClick={handleClick}
        button
        disableRipple
      >
        <Box className={classes.navText}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{label}</ListItemText>
        </Box>
      </ListItem>
    </TooltipSmall>
  ) : (
    <></>
  );
};

const HomeLink = ({ pathname, ...state }: NavigationState) => {
  const href = Paths.HOME;
  const label = 'Home';
  return (
    <NavigationItem
      icon={<HomeSmile size={20} />}
      label={label}
      mixpanelEventName={`${label} navigated to`}
      isActive={new RegExp(href).test(pathname)}
      href={href}
      state={state}
    />
  );
};

const ActivitiesLink = ({ pathname, ...state }: NavigationState) => {
  const href = Paths.ACTIVITIES;
  const label = 'Activities';
  return (
    <NavigationItem
      icon={<Route size={20} />}
      label={label}
      mixpanelEventName={`${label} navigated to`}
      isActive={new RegExp(href).test(pathname)}
      href={href}
      state={state}
    />
  );
};

const OffsitesLink = ({ pathname, ...state }: NavigationState) => {
  const href = Paths.OFFSITES;
  const label = 'Offsites';
  return (
    <NavigationItem
      icon={<Offsite size={20} color={colors.Glue_Ink00} />}
      label={label}
      mixpanelEventName={`${label} navigated to`}
      isActive={new RegExp(href).test(pathname)}
      href={href}
      hideItem={!isAdmin(state.viewer)}
      state={state}
    />
  );
};

const ExploreLink = ({ pathname, ...state }: NavigationState) => {
  const href = Paths.EXPLORE;
  const label = 'Explore';
  return (
    <NavigationItem
      icon={<BarChartCircle01 size={20} />}
      label={label}
      mixpanelEventName={`${label} navigated to`}
      isActive={new RegExp(href).test(pathname)}
      href={href}
      hideItem={
        !isAdmin(state.viewer) &&
        state.viewer?.customerType !== CustomerType.Transactional
      }
      state={state}
    />
  );
};

const ProfileLink = ({ pathname, ...state }: NavigationState) => {
  const href = Paths.PROFILE;
  const label = 'My profile';
  return (
    <NavigationItem
      icon={<AvatarElement classes={state?.classes} />}
      label={label}
      mixpanelEventName={`${label} navigated to`}
      isActive={new RegExp(href).test(pathname)}
      href={href}
      hideItem={!state.viewer?.firstName}
      state={state}
    />
  );
};

const SettingsLink = ({ pathname, ...state }: NavigationState) => {
  const href = Paths.SETTINGS;
  const label = 'Settings';
  return (
    <NavigationItem
      icon={<Settings04 size={20} />}
      label={label}
      mixpanelEventName={`${label} navigated to`}
      isActive={new RegExp(href).test(pathname)}
      href={href}
      hideItem={!isAdmin(state.viewer)}
      state={state}
    />
  );
};

const LogoutButton = ({ ...state }: NavigationState) => {
  const logOut = async () => {
    // TODO (Ian): Ideally this would be a GraphQL mutation, so that we can
    // also query the server on the tail end to atomically update the
    // viewer as null…
    await fetch(`${config.api.url}/logout?noRedirect=true`, {
      credentials: 'include',
    });
    // …but until then, we can reach into the cache ourselves:
    client.cache.writeQuery({
      query: getUserIdQuery,
      data: { viewer: null },
    });
    // Reload the entire page to get rid of any state
    window.location.href = `${config.teams.url}${Paths.AUTH}/login`;
  };
  return (
    <NavigationItem
      icon={<LogOut04 size={20} />}
      label={'Logout'}
      onClick={logOut}
      mixpanelEventName='user logged out'
      state={state}
    />
  );
};

const CollapseButton = ({ onClick, ...state }: NavigationState) => {
  return (
    <NavigationItem
      icon={
        <AlignLeft01
          className={cx(
            state.classes.collapseIcon,
            !state.isOpen && state.classes.expandIcon,
          )}
          size={20}
        />
      }
      label={'Collapse'}
      tooltipLabel={'Expand'}
      onClick={onClick}
      state={state}
    />
  );
};

const LogoButton = ({ classes, history }: NavigationState) => {
  return (
    <ButtonBase
      onClick={() => history.push(Paths.HOME)}
      className={cx(classes.logoContainer)}
      disableRipple
    >
      <LogoType size={83} className={classes.type} />
      <LogoMark size={34} className={classes.mark} />
    </ButtonBase>
  );
};

const DrawerWrapper = ({ children }: DrawerWrapperProps) => {
  const classes = useStyles();
  const history = useHistory();
  const viewer = useViewer();
  const { pathname } = useLocation();
  const { md } = useResponsive();
  const [isOpen, setIsOpen] = useState(md);

  const feedBackPath = Paths.FEEDBACK.split('/:')[0];
  const skipfeedBackPath = Paths.SKIP_FEEDBACK.split('/:')[0];
  const shouldHideDrawer = () => {
    return (
      new RegExp(`^${Paths.BOOKING}(/.*)?$`).test(pathname) ||
      new RegExp(`^${Paths.TEAM_EVENTS}(/.*)?$`).test(pathname) ||
      new RegExp(`^${feedBackPath}(/.*)?$`).test(pathname) ||
      new RegExp(`^${Paths.ACCOUNT_SETUP}(/.*)?$`).test(pathname) ||
      new RegExp(`^${Paths.AUTH}(/.*)?$`).test(pathname) ||
      new RegExp(`^/pulse/user-survey(/.*)?$`).test(pathname) ||
      new RegExp(`^/pulse/magic-link(/.*)?$`).test(pathname) ||
      new RegExp(`^${Paths.PULSE_SETTINGS}(/.*)?$`).test(pathname) ||
      new RegExp(`^${skipfeedBackPath}(/.*)?$`).test(pathname)
    );
  };

  const state = {
    viewer,
    history,
    pathname,
    isOpen,
    classes,
  };

  const viewerHasOrganizations = viewerHasOrganization(viewer);

  return (
    <div className={cx(classes.root)}>
      <Drawer
        variant='permanent'
        anchor='left'
        elevation={0}
        className={cx(
          classes.drawer,
          isOpen ? classes.opened : classes.closed,
          shouldHideDrawer() && classes.hidden,
        )}
        classes={{ paper: classes.paper }}
      >
        <Box className={cx(classes.menu, isOpen ? classes.opened : classes.closed)}>
          <Box>
            <LogoButton {...state} />
            <List className={classes.navLinks}>
              <HomeLink {...state} />
              <ActivitiesLink {...state} />
              <OffsitesLink {...state} />
              {viewerHasOrganizations && <ExploreLink {...state} />}
              <ProfileLink {...state} />
            </List>
          </Box>
          <List className={classes.navButtons}>
            <SettingsLink {...state} />
            <LogoutButton {...state} />
            <CollapseButton {...state} onClick={() => setIsOpen(!isOpen)} />
          </List>
        </Box>
      </Drawer>
      {children}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    gap: theme.spacing(10),
    flex: 1,
  },
  drawer: {
    display: 'block',
  },
  paper: {
    padding: `0 ${theme.spacing(4)} ${theme.spacing(4)}`,
    height: '-webkit-fill-available', // TODO: set to 100% when removing GlueBanner
    background: 'rgba(248, 249, 255, 0.5)',
    border: 'none',
    // TODO: Remove when removing GlueBanner
    top: GLUE_BANNER_HEIGHT,
  },
  menu: {
    overflow: 'clip',
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  logoContainer: {
    display: 'grid',
    width: '100%',
    textAlign: 'left',
    transition: 'all 300ms ease-in-out',
    padding: `${theme.spacing(4)} 0`,
  },
  type: { gridColumn: 1, gridRow: 1, transition: 'all 300ms ease-in-out' },
  mark: {
    gridColumn: 1,
    gridRow: 1,
    transition: 'all 300ms ease-in-out',
    // Position the mark to match the 'g' in 'glue'
    position: 'relative',
    top: 3,
    left: 6,
    zIndex: -1,
  },
  collapseIcon: {
    transition: 'transform 300ms ease-in-out',
  },
  expandIcon: {
    transform: 'rotate(180deg)',
  },
  navLinks: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    gap: theme.spacing(2),
  },
  navButtons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    gap: theme.spacing(2),
  },
  navItem: {
    minWidth: 0,
    padding: `${theme.spacing(2.5)} ${theme.spacing(4)} ${theme.spacing(
      2.5,
    )} ${theme.spacing(5)}`,
    color: colors.Glue_Ink00,
    '&:hover': {
      background: colors.Glue_LavenderLight,
    },
    '&:focus-visible': {
      // TODO: not in design system yet
      background: '#EBEDFA',
    },
    '& .MuiListItemIcon-root': {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 'auto',
      width: 28,
      height: 28,
      '& svg': {
        color: colors.Glue_Ink00,
      },
    },
    '& .MuiTypography-root': {
      fontSize: '.875rem',
      fontFamily: 'inherit',
      transition: 'opacity 300ms ease-in-out',
    },
  },
  navText: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(5),
    alignItems: 'center',
    overflow: 'hidden',
    width: '100%',
    transition: 'width 300ms ease-in-out',
  },
  active: {
    background: colors.Glue_Mint00,
    '&:hover': {
      background: colors.Glue_Mint00,
    },
    '&:focus-visible': {
      background: colors.Glue_DarkMint00,
    },
  },
  opened: {
    transition: 'width 300ms ease-in-out',
    '&$drawer, &$menu': {
      width: '10.5rem',
    },
    '& $type': {
      opacity: 1,
    },
    '& $mark': {
      opacity: 0,
    },
  },
  closed: {
    transition: 'width 300ms ease-in-out',
    '&$drawer, &$menu': {
      width: '4.25rem',
    },
    '& $type': {
      opacity: 0,
    },
    '& $mark': {
      opacity: 1,
    },
    '& $logoContainer': {
      // Center mark while maintaining transition
      paddingLeft: 'calc(4.25rem - 34px)', // ( $menu.width - $mark.width )
    },
    '& $navText': {
      width: 28,
      '& .MuiTypography-root': {
        opacity: 0,
      },
    },
  },
  avatar: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    padding: 0,
    width: 28,
    height: 28,
    background: colors.Glue_Ink00,
    border: `1px solid ${colors.Glue_Ink00}`,
    borderRadius: 28,
    overflow: 'hidden',
    '& svg, .MuiListItemIcon-root & svg,': {
      color: colors.Glue_Paper,
    },
    '& img': {
      width: 28,
      height: 28,
      objectFit: 'cover',
    },
  },
  avatarImage: {
    background: colors.Glue_LavenderDark,
    borderColor: colors.Glue_LavenderDark,
  },
  hidden: {
    display: 'none',
  },
}));

export default DrawerWrapper;
