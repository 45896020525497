import { DateTime } from 'luxon';

const SCHEDULER_RUN_HOUR_UTC = 15;
const MEETUPS_FIRST_RUN_DELAY_DAYS = 2;
export const DEFAULT_MEETUPS_CADENCE_WEEKS = 2;

export const MEETUP_CADENCE_OPTIONS = [
  { label: 'Once a week', value: '1' },
  { label: 'Every 2 weeks', value: '2' },
  { label: 'Once a month', value: '4' },
  { label: 'Every 2 months', value: '8' },
];


type MeetupsNextRunArgs = {
  lastRun?: string;
  runAfter?: string;
  cadenceWeeks: number;
};

export const calculateNextMeetupsSchedulerRun = ({
  lastRun,
  runAfter,
  cadenceWeeks,
}: MeetupsNextRunArgs): Date => {
  /**
   * If there is no lastRun this means the meetup scheduler hasn't run for this initiative yet.
   * If the time of runAFter is before our scheduler hour cron then it'll run today. Otherwise it'll run
   * the day after the runAfterDT's date.
   */
  if (!lastRun) {
    if (!runAfter) {
      return DateTime.now().plus({ days: MEETUPS_FIRST_RUN_DELAY_DAYS }).toJSDate();
    }
    const runAfterDT = DateTime.fromISO(runAfter);
    if (runAfterDT.toUTC().hour < SCHEDULER_RUN_HOUR_UTC) return new Date();
    return runAfterDT.plus({ days: 1 }).toJSDate();
  }

  const nextRun = DateTime.fromISO(lastRun).plus({ weeks: cadenceWeeks });
  const now = DateTime.now();

  /**
   * If the calculated next run is before the current time the user is moving to a more frequenct cadence.
   * For example, previously doing once a month and their last meetups was ran 3 weeks ago but now they are moving
   * to once every 2 weeks. This means the next run will either happen today (if they are doing this change before the scheduler runs)
   * Or it will happen tomorrow
   */
  if (nextRun < now) {
    // Current time is before scheduler has ran. This will run today.
    if (now.toUTC().hour < SCHEDULER_RUN_HOUR_UTC) return new Date();
    return now.plus({ days: 1 }).toJSDate();
  }

  return nextRun.toJSDate();
};

export const getNextMeetupsRunDateString = ({
  lastRun,
  runAfter,
  cadenceWeeks,
}: MeetupsNextRunArgs) => {
  const nextRun = calculateNextMeetupsSchedulerRun({
    lastRun,
    runAfter,
    cadenceWeeks,
  });

  return DateTime.fromJSDate(nextRun).toLocaleString({
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
};
