import { css } from '@emotion/react';
import { animated, to, useTransition } from '@react-spring/web';
import { Group } from '@visx/group';
import { Pie } from '@visx/shape';
import type { PieArcDatum, ProvidedProps } from '@visx/shape/lib/shapes/Pie';
import {
  SvgColorblindPatternDefs,
  getPatternFill,
} from 'glue/components/accessibility/SvgColorblindPatterns';
import { PatternName } from 'glue/components/accessibility/PatternedBox';
import GlueyFace, { GlueyFaceName } from 'glue/components/glueyFace/GlueyFace';
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import { scaleOrdinal } from '@visx/scale';
import {
  COLORS,
  PatternLegend,
} from 'glue/components/accessibility/SvgColorblindPatterns';
import theme from 'theme';
import styled from '@emotion/styled';

const BAGEL_THICKNESS_PERCENT = 1 / 2;
const BAGEL_NATURAL_SIZE = 220;

export type BagelBucketData = {
  type: string;
  quantity: number;
  trend?: PatternName;
  color?: string;
  label: string;
  description?: string;
};

type AnimatedPieProps = ProvidedProps<BagelBucketData> & {
  animate?: boolean;
  getKey: (d: PieArcDatum<BagelBucketData>) => string;
  delay?: number;
};

type AnimatedStyles = { startAngle: number; endAngle: number; opacity: number };

const BagelGraph = ({
  buckets,
  className,
}: {
  buckets: BagelBucketData[];
  className?: string;
}) => {
  return (
    <BagelGraphRoot className={className}>
      <BagelGraphChart data={buckets} />
      <BagelGraphLegendArea>
        <BagelGraphLegend data={buckets} />
      </BagelGraphLegendArea>
    </BagelGraphRoot>
  );
};
export default BagelGraph;

export const BagelGraphRoot = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'stretch',
  flexWrap: 'wrap',
  width: '100%',
  height: 440,
  gap: theme.spacing(10),
  padding: theme.spacing(4),
});

export const BagelGraphLegendArea = styled.div({
  flex: '0 0 auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const BagelGraphChart = ({
  data,
  face = GlueyFaceName.Smile,
  size = BAGEL_NATURAL_SIZE,
}: {
  data: BagelBucketData[];
  face?: GlueyFaceName;
  size?: number;
}) => {
  const animate = window.matchMedia('(prefers-reduced-motion: no-preference)').matches;

  return (
    <div css={[styles.bagel, { width: size }]}>
      <GlueyFace face={face} css={styles.smiley} size={32} />
      <svg viewBox={`0 0 ${size} ${size}`} css={{ width: '100%', height: '100%' }}>
        <SvgColorblindPatternDefs />
        <Group top={size / 2} left={size / 2}>
          <Pie
            data={data}
            pieValue={(d) => d.quantity}
            outerRadius={size / 2}
            innerRadius={(size / 2) * BAGEL_THICKNESS_PERCENT}
            cornerRadius={0}
            padAngle={0.025}
          >
            {(pie) => (
              <AnimatedPie {...pie} animate={animate} getKey={(arc) => arc.data.type} />
            )}
          </Pie>
        </Group>
      </svg>
    </div>
  );
};

const fromLeaveTransition = ({ endAngle }: PieArcDatum<any>) => ({
  // enter from 360° if end angle is > 180°
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
});

const enterUpdateTransition = ({ startAngle, endAngle }: PieArcDatum<any>) => ({
  startAngle,
  endAngle,
  opacity: 1,
});

function AnimatedPie({ animate, arcs, path, getKey }: AnimatedPieProps) {
  const transitions = useTransition<PieArcDatum<BagelBucketData>, AnimatedStyles>(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey,
  });
  return transitions((props, arc, { key }) => {
    return (
      <g key={key}>
        <animated.path
          // compute interpolated path d attribute from intermediate angle values
          d={to([props.startAngle, props.endAngle], (startAngle, endAngle) =>
            path({
              ...arc,
              startAngle,
              endAngle,
            }),
          )}
          fill={
            arc.data.color
              ? arc.data.color
              : arc.data.trend
              ? getPatternFill(arc.data.trend)
              : undefined
          }
        />
      </g>
    );
  });
}

/**
 * Renders a legend for the bagel graph sections
 */
export function BagelGraphLegend({ data }: { data: BagelBucketData[] }) {
  const total = data.reduce((acc, d) => acc + d.quantity, 0);
  return (
    <LegendOrdinal scale={scale} labelFormat={(label) => label}>
      {(labels) => (
        <div css={styles.legendStack}>
          {labels.map((label, i) => {
            const datum = data.find((d) => d.type === label.text);
            if (!datum) return null;

            return (
              <LegendItem key={`legend-${i}`} css={styles.legendItem}>
                {!!label.value && <PatternLegend pattern={patternMapping[label.text]} />}
                <div css={styles.legendInner}>
                  <div css={styles.legendLabelStack}>
                    <LegendLabel align='left' css={styles.legendLabel}>
                      {datum.label}
                    </LegendLabel>
                    {datum.description && (
                      <span css={styles.legendSublabel}>{datum.description}</span>
                    )}
                  </div>
                  <div css={styles.legendValue}>
                    {Math.round((datum.quantity * 100) / total)}%
                  </div>
                </div>
              </LegendItem>
            );
          })}
        </div>
      )}
    </LegendOrdinal>
  );
}

const scale = scaleOrdinal({
  domain: ['more-than-one', 'at-least-one', 'none'],
  range: [
    {
      bg: COLORS.trendUpStrongBg,
      fg: COLORS.trendUpStrongFg,
    },
    {
      bg: COLORS.trendNeutralBg,
      fg: COLORS.trendNeutralFg,
    },
    {
      bg: COLORS.trendDownStrongBg,
      fg: COLORS.trendDownStrongFg,
    },
  ],
});

const patternMapping = {
  'more-than-one': 'trend-up-strong',
  'at-least-one': 'trend-neutral',
  none: 'trend-down-strong',
};

const styles = {
  bagel: css({
    flex: '0 0 auto',
    position: 'relative',
    maxWidth: '100%',
  }),
  smiley: css({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    pointerEvents: 'none',
  }),
  legendStack: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: theme.spacing(3),
  }),
  legendItem: css({
    gap: theme.spacing(4),
    width: '100%',
  }),
  legendInner: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
  }),
  legendLabelStack: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  }),
  legendLabel: css({
    fontSize: 18,
    fontWeight: 700,
  }),
  legendSublabel: css({
    color: '#706A74',
    fontSize: 14,
  }),
  legendValue: css({
    fontSize: 20,
    color: '#706A74',
  }),
};
