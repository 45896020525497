import _ from 'lodash';
import { css } from '@emotion/react';
import { useState, useEffect } from 'react';
import { colors } from '@mysteryco/design';
import { gql } from '@apollo/client';
import { Loading } from 'components/core';
import SearchMd from '@mysteryco/design/icons/SearchMd';
import AnnotationAlert from '@mysteryco/design/icons/AnnotationAlert';
import TextLink from 'glue/components/buttons/TextLink';
import { Tooltip } from 'glue/components/tooltip/Tooltip';
import {
  Audience,
  AudienceBuilder,
  AudienceList,
  getUniqUserArr,
  getUniqUserCount,
} from 'glue/components/inputs/audience';
import { GlueyFaceName } from 'glue/components/glueyFace/GlueyFace';
import { BagelGraphChart } from 'glue/components/illustrations/BagelGraph';
import {
  ViewerFragmentFragment,
  AudienceMember,
  AudienceMemberInput,
  WatercoolerStatus,
  useWatercoolerInitiativeStatusQuery,
  WatercoolerInitiativeByOrgQuery,
  WatercoolerGoal,
} from 'types';
import theme from 'theme';

const MEETUPS_GOAL_DISPLAY_MAP = {
  General: {
    text: 'General',
    color: colors.Mint_40,
  },
  Leadership: {
    text: 'Leadership connections',
    color: colors.Plum_30,
  },
  CrossTeam: {
    text: 'Cross-team connections',
    color: colors.Gold_30,
  },
  WithinTeam: {
    text: 'Within-team connections',
    color: colors.Cyan_30,
  },
  Random: {
    text: 'Random',
    color: colors.Ruby_40,
  },
};

const computeParticipationRate = (users: Partial<ViewerFragmentFragment>[]) => {
  return users?.filter((user) => !user.optOutOfWatercoolerAt).length / users?.length;
};

const parseMeetupAudience = (audience: Audience): AudienceMemberInput[] => {
  return [
    ...(audience.users?.map((user) => {
      return { user: { id: user.id } };
    }) || []),
    ...(audience.teams?.map((team) => {
      return { team: { id: team.id } };
    }) || []),
    ...(audience.tags?.map((tag) => {
      return { userTag: { id: tag.id } };
    }) || []),
  ];
};

const cleanUpWatercoolerInitiativeAudience = (
  audience: AudienceMember[],
): AudienceMemberInput[] => {
  if (!audience) {
    return [];
  }
  return audience.map((audience: any) => {
    const { user, team, userTag, organization } = audience;
    return {
      user: user ? { id: user.id } : undefined,
      team: team ? { id: team?.id } : undefined,
      userTag: userTag ? { id: userTag?.id } : undefined,
      organization: organization ? { id: organization?.id } : undefined,
    };
  });
};

const getUniqWatercoolerAudienceUsers = (
  watercoolerInitiative: WatercoolerInitiativeByOrgQuery['watercoolerInitiativeByOrg'],
) => {
  return _.uniqBy(
    _.compact(
      watercoolerInitiative?.audience.flatMap((audience) => [
        audience?.user,
        ...(audience?.team?.members || []),
        ...(audience?.userTag?.users || []),
        ...(audience?.organization?.members || []),
      ]),
    ),
    'id',
  );
};

const Report = ({ existingInitiative, orgId }) => {
  const [selectedAudience, setSelectedAudience] = useState<Audience>({});
  const audienceUsers = getUniqWatercoolerAudienceUsers(existingInitiative);

  const {
    data: watercoolerInitiativeStatusData,
    loading: watercoolerInitiativeStatusLoading,
    refetch,
  } = useWatercoolerInitiativeStatusQuery({
    variables: {
      orgId,
      audience: cleanUpWatercoolerInitiativeAudience(
        existingInitiative?.audience as AudienceMember[],
      ),
    },
    skip: !existingInitiative,
  });
  const { averageFeedbackRatingForWatercooler: feedbackRating, watercoolersForAudience } =
    watercoolerInitiativeStatusData || {};

  useEffect(() => {
    let audience: AudienceMemberInput[];
    if (getUniqUserCount(selectedAudience) > 0) {
      audience = parseMeetupAudience(selectedAudience);
    } else {
      audience = cleanUpWatercoolerInitiativeAudience(
        existingInitiative?.audience as AudienceMember[],
      );
    }
    refetch({
      orgId,
      audience,
    });
  }, [selectedAudience]);
  const selectedAudienceUsers = getUniqUserArr(selectedAudience);
  const selectedAudienceUserCount = getUniqUserCount(selectedAudience);
  const participationRate =
    selectedAudienceUserCount > 0
      ? computeParticipationRate(selectedAudienceUsers as ViewerFragmentFragment[])
      : computeParticipationRate(audienceUsers as ViewerFragmentFragment[]);

  const watercoolersByGoal = _.groupBy(watercoolersForAudience, 'goal');
  /* We are hiding watercoolers with goal "Random" and combining them with "General". */
  if (watercoolersByGoal[WatercoolerGoal.Random]) {
    if (!watercoolersByGoal[WatercoolerGoal.General]) {
      watercoolersByGoal[WatercoolerGoal.General] = [];
    }
    watercoolersByGoal[WatercoolerGoal.General].push(
      ...watercoolersByGoal[WatercoolerGoal.Random],
    );
    delete watercoolersByGoal[WatercoolerGoal.Random];
  }

  return (
    <div css={styles.root}>
      {watercoolerInitiativeStatusLoading ? (
        <Loading />
      ) : (
        <>
          <div css={styles.audienceContainer}>
            <AudienceBuilder
              organizationId={orgId}
              audience={selectedAudience}
              setAudience={setSelectedAudience}
              InputProps={{ startAdornment: <SearchMd css={{ margin: '0 14px' }} /> }}
              hideList
            />
            <AudienceList
              audience={selectedAudience}
              onAudienceChange={setSelectedAudience}
              pillStyle={{ background: colors.Plum_60 }}
            />
          </div>
          <div css={styles.reportCardContainer}>
            <div css={styles.reportCard}>
              <div css={styles.reportCardTitle}>Completed</div>
              <div css={styles.reportCardMetric}>
                <span css={styles.reportCardNumber}>
                  {_.size(
                    watercoolersForAudience?.filter(
                      (watercooler) => watercooler?.status === WatercoolerStatus.Complete,
                    ),
                  )}
                </span>
                meetups
              </div>
            </div>
            <div css={styles.reportCard}>
              <div css={styles.reportCardTitle}>Scheduled</div>
              <div css={styles.reportCardMetric}>
                <span css={styles.reportCardNumber}>
                  {_.size(
                    watercoolersForAudience?.filter(
                      (watercooler) =>
                        watercooler?.status === WatercoolerStatus.Scheduled,
                    ),
                  )}
                </span>
                meetups
              </div>
            </div>
          </div>
          <div css={styles.reportCardContainer}>
            <div css={styles.reportCard}>
              <div css={styles.reportCardTitle}>Total Cohort</div>
              <div css={styles.reportCardMetric}>
                <span css={styles.reportCardNumber}>
                  {selectedAudienceUsers.length || audienceUsers.length}
                </span>
                participants
              </div>
            </div>
            <div css={styles.reportCard}>
              <div css={styles.reportCardTitle}>Opt-in Rate</div>
              <div css={styles.reportCardNumber}>{`${(participationRate * 100).toFixed(
                0,
              )}%`}</div>
            </div>
            <div css={styles.reportCard}>
              <div css={styles.reportCardTitle}>Participant Rating</div>
              <div css={styles.reportCardMetric}>
                {feedbackRating ? (
                  <>
                    <div css={styles.reportCardNumber}>{feedbackRating?.toFixed(2)}</div>
                    out of 5
                  </>
                ) : (
                  <>{'N/A'}</>
                )}
              </div>
            </div>
          </div>
          <div css={styles.divider}>Focus Breakdown</div>
          <div css={styles.graphContainer}>
            <div css={styles.graphChart}>
              <BagelGraphChart
                face={
                  _.size(watercoolersForAudience) > 0
                    ? GlueyFaceName.Smile
                    : GlueyFaceName.Meh
                }
                data={Object.entries(watercoolersByGoal).map(([goal, watercoolers]) => {
                  return {
                    type: goal,
                    quantity: _.size(watercoolers) / _.size(watercoolersForAudience) || 0,
                    color: MEETUPS_GOAL_DISPLAY_MAP[goal].color,
                    label: MEETUPS_GOAL_DISPLAY_MAP[goal].text,
                  };
                })}
              />
            </div>
            <div css={styles.graphDetails}>
              <div css={styles.graphLegends}>
                {Object.values(WatercoolerGoal)
                  .filter((goal) => goal !== WatercoolerGoal.Random)
                  .map((goal) => (
                    <div key={`goal-${goal}`} css={styles.graphLegendItem}>
                      <div css={styles.graphLegendLabel}>
                        <div
                          css={[
                            styles.graphLegendColor,
                            { background: MEETUPS_GOAL_DISPLAY_MAP[goal].color },
                          ]}
                        />
                        {MEETUPS_GOAL_DISPLAY_MAP[goal].text}
                      </div>
                      <div>
                        {`${(
                          (_.size(watercoolersByGoal[goal]) /
                            _.size(watercoolersForAudience)) *
                            100 || 0
                        ).toFixed(0)}%`}
                      </div>
                    </div>
                  ))}
              </div>
              <div css={styles.graphExplanation}>
                <Tooltip
                  content={
                    <div css={{ width: '320px' }}>
                      Glue measures connection within teams, across teams, and with
                      leaders to find where participants need a boost. Glue groups
                      participants based on overlapping interests, availability and
                      connection needs to help people build new relationships and improve
                      their connectivity.
                    </div>
                  }
                  size={'small'}
                >
                  <div>
                    <TextLink Icon={AnnotationAlert} visualOnly>
                      How does Glue choose focus areas?
                    </TextLink>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const styles = {
  root: css({
    width: '700px',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
  }),
  audienceContainer: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
  }),
  reportCardContainer: css({
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    gap: theme.spacing(5),
  }),
  reportCard: css({
    padding: `${theme.spacing(4)} ${theme.spacing(5)} ${theme.spacing(5)}`,
    gap: theme.spacing(2),
    background: colors.Glue_LavenderLight,
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: colors.Coal_20,
    flex: 1,
  }),
  reportCardTitle: css({
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '160%',
  }),
  reportCardMetric: css({
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '120%',
    display: 'flex',
    alignItems: 'flex-end',
    gap: theme.spacing(2),
  }),
  reportCardNumber: css({
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: '100%',
    color: colors.Coal_10,
  }),
  divider: css({
    padding: `0 ${theme.spacing(2.5)} ${theme.spacing(5)} 0`,
    color: colors.Coal_20,
    borderBottom: `1px solid ${colors.Coal_40}`,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '140%',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
  }),
  graphContainer: css({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(10),
  }),
  graphChart: css({
    flex: 1,
  }),
  graphDetails: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8),
    flex: 2,
  }),
  graphLegends: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  }),
  graphLegendItem: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  graphLegendColor: css({
    width: '32px',
    height: '32px',
    borderRadius: '10px',
  }),
  graphLegendLabel: css({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(5),
  }),
  graphExplanation: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
};

gql`
  query WatercoolerInitiativeStatus($orgId: ID!, $audience: [AudienceMemberInput!]!) {
    averageFeedbackRatingForWatercooler(orgId: $orgId, audience: $audience)

    watercoolersForAudience(orgId: $orgId, audience: $audience) {
      id
      status
      goal
    }
  }
`;

export default Report;
