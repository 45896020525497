import { gql } from '@apollo/client';
import BagelGraph from 'glue/components/illustrations/BagelGraph';
import { patternsBySentiment } from 'glue/scenes/Explore/Pulse/questions/patternsBySentiment';
import {
  FriendshipDistributionGraphBucketFragment,
  MoralePulseResponseSentiment,
} from 'types';

export interface FriendshipDistributionGraphProps {}

enum BucketType {
  MoreThanOne = 'more-than-one',
  AtLeastOne = 'at-least-one',
  None = 'none',
}

// defining a wider mapping than we actually use, just for safety.
const typeBySentiment = {
  [MoralePulseResponseSentiment.VeryPositive]: BucketType.MoreThanOne,
  [MoralePulseResponseSentiment.Positive]: BucketType.MoreThanOne,
  [MoralePulseResponseSentiment.Neutral]: BucketType.AtLeastOne,
  [MoralePulseResponseSentiment.Negative]: BucketType.None,
  [MoralePulseResponseSentiment.VeryNegative]: BucketType.None,
};

export const FriendshipDistributionGraph = ({
  className,
  buckets,
}: {
  className?: string;
  buckets: FriendshipDistributionGraphBucketFragment[];
}) => {
  const asBagelBuckets = buckets.map((bucket) => ({
    type: typeBySentiment[bucket.sentiment],
    quantity: bucket.percentValue,
    trend: patternsBySentiment[bucket.sentiment],
    label: bucket.label,
    description: bucket.description || undefined,
  }));
  return <BagelGraph buckets={asBagelBuckets} className={className} />;
};

FriendshipDistributionGraph.fragment = gql`
  fragment FriendshipDistributionGraphBucket on MoralePulseAnswerSummaryBucket {
    label
    percentValue
    sentiment
    description
  }
`;

export default FriendshipDistributionGraph;
