import { Type } from 'constants/index';
import _ from 'lodash';
import { ResponsiveContext, responsiveSwitch } from 'providers/BreakpointsProvider';
import React, { useContext } from 'react';
import posed from 'react-pose';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  text: (props: any) => ({
    ...(props.notResponsive
      ? Type.fontSize(Type.Sizes.MEDIUM * props.factor)
      : responsiveSwitch(
          {
            small: Type.fontSize(Type.Sizes.SMALL * props.factor),
            medium: Type.fontSize(Type.Sizes.MEDIUM * props.factor),
            large: Type.fontSize(Type.Sizes.MEDIUM * props.factor),
            xlarge: Type.fontSize(Type.Sizes.MEDIUM * props.factor),
          },
          props.context,
        )),
    fontWeight: props.dontSetWeight
      ? undefined
      : Type.FontWeights[props.weight || 'NORMAL'],
  }),
});

type SizeVariant = 'normal' | 'big' | 'medium' | 'small';

interface BodyTextProps {
  children: any;
  className?: string;
  size?: SizeVariant;
  notResponsive?: boolean;
  weight?: keyof typeof Type['FontWeights'];
  dontSetWeight?: boolean;
  pose?: any;
}

const AnimatedText = posed.p({
  regular: { fontWeight: 400 },
  bold: { fontWeight: 600 },
});

export function BodyText({ dontSetWeight, className, ...otherProps }: BodyTextProps) {
  const context = useContext(ResponsiveContext);

  const factor = (() => {
    switch (otherProps.size) {
      case 'small':
        return 0.8;
      case 'medium':
        return 0.9;
      case 'big':
        return 1.2;
      default:
      case 'normal':
        return 1;
    }
  })();

  const classes = useStyles({
    factor,
    context,
    dontSetWeight,
    notResponsive: otherProps.notResponsive,
    weight: otherProps.weight,
  });

  return (
    <AnimatedText
      className={_.compact([classes.text, className]).join(' ')}
      {...(otherProps as any)}
    >
      {otherProps.children}
    </AnimatedText>
  );
}
