import { SVGProps } from 'react';

export const AccessibilityIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='13'
    height='16'
    viewBox='0 0 13 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M6.21564 0C7.32111 0 8.21564 0.894531 8.21564 2C8.21564 3.10547 7.32111 4 6.21564 4C5.11407 4 4.21564 3.10547 4.21564 2C4.21564 0.894531 5.11407 0 6.21564 0Z'
      fill='currentColor'
    />
    <path
      d='M0.200012 7H3.20001V16H5.20001V12C5.20001 11.4492 5.64923 11 6.20001 11C6.75079 11 7.20001 11.4492 7.20001 12V16H9.20001V7H12.2V5H0.200012V7Z'
      fill='currentColor'
    />
  </svg>
);
