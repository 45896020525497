import { gql } from '@apollo/client';
import { InputAdornment, TextField, Typography } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  Fragment,
  useRef,
} from 'react';

import theme from 'theme';
import {
  MemberFragmentFragment,
  OrderDirection,
  OrgSearchUserFragment,
  OrgSearchUserTagFragment,
  UsersOrderKey,
  UserTagType,
  useSearchOrgUsersLazyQuery,
} from 'types';
import { emailRegex } from 'utils/stringUtil';
import TeamChip from './Chips/TeamChip';
import MagnGlass from 'components/icons/MagnGlass';
import { useDebounceFn } from 'ahooks';
import { pluralize } from 'humanize-plus';

export interface OrgSearchProps {
  orgId?: string;
  orgTags?: OrgSearchUserTagFragment[];
  searchTags: OrgSearchUserTagFragment[];
  setSearchTags: Dispatch<SetStateAction<OrgSearchUserTagFragment[]>>;
  searchString: string;
  setSearchString: Dispatch<SetStateAction<string>>;
  onChange?(event, selectedVals, setValues): void;
  onFocus?(event): void;
  onBlur?(event): void;
  showUsersInOptions?: boolean;
  useAsSelectionComponent?: boolean;
  style?: CSSProperties;
  neverOpen?: boolean;
}

type Option =
  | OrgSearchUserTagFragment
  | OrgSearchUserFragment
  | { type: 'dummy'; name: string }
  | string;

function isDummy(option: any): option is { type: 'dummy'; name: string } {
  return typeof option === 'object' && option !== null && option.type === 'dummy';
}

function isUserTag(option: any): option is OrgSearchUserTagFragment {
  return typeof option === 'object' && option !== null && option.__typename === 'UserTag';
}

function isUser(option: any): option is OrgSearchUserFragment {
  return typeof option === 'object' && option !== null && option.__typename === 'User';
}

export const OrgSearch = ({
  orgId,
  orgTags = [],
  searchTags,
  setSearchTags,
  searchString,
  setSearchString,
  style,
  onChange,
  onFocus,
  onBlur,
  showUsersInOptions = false,
  useAsSelectionComponent = false,
  neverOpen = false,
}: OrgSearchProps) => {
  const rootRef = useRef<HTMLDivElement>();
  const classes = useStyles();
  const [values, setValues] = useState<Option[]>(() => [...searchTags]);
  const [open, setOpen] = useState(false);
  const filter = createFilterOptions({
    stringify: (option: Option) => {
      if (typeof option === 'string') return option;
      return option.name || '';
    },
  });

  const [getMembers, { data: userData }] = useSearchOrgUsersLazyQuery();

  const { run: debouncedGetMembers } = useDebounceFn(getMembers, { wait: 200 });

  useEffect(() => {
    if (orgId) {
      debouncedGetMembers({
        variables: {
          query: searchString.trim(),
          orgId,
          sort: [{ key: UsersOrderKey.FirstName, direction: OrderDirection.Asc }],
          userTags: searchTags.map((tag) => tag.id),
          showArchived: false,
        },
      });
    }
  }, [searchString, searchTags]);

  let members: MemberFragmentFragment[] = [];
  if (userData?.orgUsersConnection?.edges) {
    members = userData.orgUsersConnection.edges
      .map((edge) => edge?.node)
      .filter((node): node is MemberFragmentFragment => !!node);
  }

  return (
    <Autocomplete
      ref={rootRef}
      onOpen={() => {
        if (neverOpen) {
          setOpen(false);
        } else {
          setOpen(true);
        }
      }}
      onClose={() => {
        setOpen(false);
      }}
      open={open}
      style={style}
      className={classes.autoComplete}
      fullWidth
      value={values}
      inputValue={searchString}
      options={[
        ...orgTags,
        ...(showUsersInOptions && searchString ? members : []),
      ].filter((tag): tag is OrgSearchUserTagFragment | MemberFragmentFragment => !!tag)}
      limitTags={3}
      multiple
      freeSolo
      autoSelect
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.name || ''
      }
      renderOption={(option) => {
        if (isDummy(option)) {
          return <Typography>{option.name}</Typography>;
        } else if (isUserTag(option)) {
          return (
            <>
              {!useAsSelectionComponent && (
                <Typography style={{ marginRight: '5px' }}>part of</Typography>
              )}
              <TeamChip
                automated={option.type === UserTagType.Automatic}
                label={option.name || ''}
              />
              {useAsSelectionComponent && (
                <Typography style={{ marginLeft: '5px' }}>
                  {option.users?.length ?? 0}{' '}
                  {pluralize(option.users?.length ?? 0, 'member')}
                </Typography>
              )}
            </>
          );
        } else if (isUser(option)) {
          return (
            <Typography style={{ marginLeft: '5px' }}>{` ${option.name || ''} (${
              option.email || ''
            })`}</Typography>
          );
        } else if (typeof option === 'string') {
          const split = option.split(emailRegex);
          const matches = option.match(emailRegex) || [];
          if (useAsSelectionComponent && matches.length > 0) {
            return (
              <Typography style={{}}>
                <Typography
                  style={{
                    paddingTop: theme.spacing(2),
                    paddingBottom: theme.spacing(2),
                  }}
                >
                  {`Add ${matches.length} guests from email addresses`}
                </Typography>
                {split.map((nonEmail, i) => {
                  const isLast = i === split.length - 1;
                  return (
                    <>
                      <Typography
                        style={{ display: 'inline', color: theme.palette.grey[800] }}
                      >{`${nonEmail}`}</Typography>
                      {!isLast && (
                        <Typography
                          style={{
                            display: 'inline',
                            fontWeight: 700,
                            color: theme.palette.primary.main,
                          }}
                        >{`${matches[i]}`}</Typography>
                      )}
                    </>
                  );
                })}
              </Typography>
            );
          } else if (!useAsSelectionComponent) {
            return (
              <Typography style={{ display: 'flex' }}>
                <Typography
                  style={{ fontWeight: 700, marginLeft: '5px' }}
                >{`name includes ${option}`}</Typography>
              </Typography>
            );
          }
        }
      }}
      onChange={(event, selectedVals, reason) => {
        if (onChange) return onChange(event, selectedVals, setValues);
        if (reason === 'clear') {
          setSearchString('');
        }
        const tags: OrgSearchUserTagFragment[] = [];
        let query = '';
        selectedVals = selectedVals.filter((val) => !isDummy(val));
        selectedVals.forEach((val) => {
          if (isUserTag(val)) {
            tags.push(val);
            setSearchString('');
            setSearchTags([...tags]);
            setValues([...tags]);
          } else if (typeof val === 'string') {
            query = val;
          }
        });
        if (query) {
          setSearchString(query);
          return;
        }
        setSearchTags([...tags]);
        setValues([...selectedVals]);
      }}
      renderTags={(values, getTagProps) => {
        return values.map((option, index) => {
          if (isUserTag(option)) {
            return (
              <TeamChip
                key={index}
                automated={option.type === UserTagType.Automatic}
                label={option.name || ''}
                {...getTagProps({ index })}
              />
            );
          } else {
            return <Fragment key={index}>{option}</Fragment>;
          }
        });
      }}
      filterOptions={(options, params) => {
        let filtered = filter(options, params);
        if (params.inputValue === '') {
          filtered = [
            {
              id: 'dummy',
              type: 'dummy',
              name: 'Type to search by name',
            },
            ...filtered,
          ];
        }
        return filtered;
      }}
      renderInput={(params) => {
        params.InputProps.startAdornment = (
          <>
            {!useAsSelectionComponent && (
              <InputAdornment position='start'>
                <MagnGlass color={theme.palette.primary.main} height={16} width={16} />
                <Typography className={classes.searchLabel}>Search:</Typography>
              </InputAdornment>
            )}
            {params.InputProps.startAdornment}
          </>
        );
        (params.inputProps as any).value = searchString;
        return (
          <TextField
            {...params}
            onChange={(e) => {
              if (params.inputProps.hasOwnProperty('onChange')) {
                (params.inputProps as any).onChange(e);
              }
              setSearchString(e.target.value);
            }}
            inputProps={params.inputProps}
            variant='outlined'
            className={classes.searchBox}
            value={searchString}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        );
      }}
    />
  );
};

OrgSearch.fragments = gql`
  fragment OrgSearchUserTag on UserTag {
    id
    name
    type
    users {
      id
    }
  }

  fragment OrgSearchUser on User {
    id
    name
    email
  }
`;

const useStyles = makeStyles({
  autoComplete: {
    '& .MuiAutocomplete-inputRoot': {
      flexWrap: 'nowrap',
    },
  },
  searchBox: {
    '& .MuiInputBase-root': {
      borderRadius: theme.spacing(2),
      height: '52px',
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${theme.palette.primary[400]}`,
        boxShadow: '0px 4px 8px rgba(234, 234, 234, 0.8)',
      },
      '&:focus .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${theme.palette.primary[400]}`,
        boxShadow: '0px 4px 8px rgba(234, 234, 234, 0.8)',
      },
      '&:active .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${theme.palette.primary[400]}`,
        boxShadow: '0px 4px 8px rgba(234, 234, 234, 0.8)',
      },
      '&.MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${theme.palette.primary[100]}`,
      },
    },
    '& .MuiInputBase-input': {
      boxSizing: 'none',
    },
    dispay: 'flex',
    alignItems: 'center',
  },
  searchLabel: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  },
});
