import { Audience } from 'glue/components/inputs/audience';
import { existsFilter } from 'lib/helpers/maybe';
import { MoralePulseEditQuery, MoralePulseCadence, User } from 'types';
import { create } from 'zustand';

export type PulseSettingsState = {
  /** Which MoralePulse entity are we modifying? */
  pulseId: string | null;
  /** Which questions from our pre-made selections will be activated? */
  questionIds: string[];
  /** Who receives the survey? */
  audience: Audience;
  /** When will we begin sending the survey? Only the date matters. */
  startDate: Date;
  /** Inferred from the API response and survey start date. */
  surveyHasBeenSent: boolean;
  /** How often will this survey be sent out? */
  cadence: MoralePulseCadence;

  // mutations

  /**
   * Resets state from the GraphQL API response. Call this whenever
   * we pull data from the API during the page load to load in
   * the temporary page state and prepare for editing.
   */
  setFromApi: (data: MoralePulseEditQuery) => void;

  /**
   * Sets the basic setup info. Call this whenever the user changes
   * the top-level Pulse details like audience, cadence, or start date.
   */
  setSetupInfo: (
    info: Pick<PulseSettingsState, 'startDate' | 'cadence' | 'audience'>,
  ) => void;

  /**
   * Sets the questions. Call this whenever the user changes the
   * active question selections.
   */
  setQuestions: (questionIds: string[]) => void;
};

const emptyState = {
  pulseId: null,
  questionIds: [],
  audience: {
    tags: [],
    teams: [],
    users: [],
  },
  startDate: new Date(),
  cadence: MoralePulseCadence.Weekly,
  surveyHasBeenSent: false,
};

/**
 * This is a Zustand state store + hook!
 * https://github.com/pmndrs/zustand
 *
 * It's a FLUX/Redux-like state management system that uses React hooks.
 *
 * We define the 'store' here, which includes data and methods to mutate the
 * data. You can then call this hook anywhere to reactively update your
 * component as the data changes and access everything.
 *
 * You can also pass a selector function to the hook to only get a subset of
 * the data. This prevents unnecessary re-renders.
 *
 * I like to design the store as if it were a third-party SDK for the data.
 * So I've exposed some named methods which the UI uses to interact and
 * change things which correspond with how this page works. They're documented
 * above in the PulseSettingsState type.
 *
 * The reason I've opted to pull in Zustand for this page is because I want
 * to store and react to state changes in components, but also be able to
 * immediately access the current state value after setting it. React's
 * useState can't do the second part - it requires a re-render before the
 * new state is available.
 *
 * It's also convenient for such a large page to have a Context-like store
 * you can access with a hook wherever, instead of passing a ton of stuff
 * everywhere in props.
 */
export const usePulseSettingsState = create<PulseSettingsState>()((set) => ({
  ...emptyState,

  setFromApi: (data) => {
    const moralePulses = data?.viewer?.defaultOrganization?.moralePulses;
    const activePulse = moralePulses?.find((pulse) => pulse.enabled);

    const activeMoralePulseQuestions =
      data?.viewer?.defaultOrganization?.activeMoralePulseQuestions || [];

    const storedQuestionIds = (activePulse?.activeQuestions ?? [])
      .filter(existsFilter)
      .map((q) => q.id);
    if (storedQuestionIds.length === 0) {
      // default to all questions
      storedQuestionIds.push(...activeMoralePulseQuestions.map((q) => q.id));
    }

    const startDate = activePulse?.startDate ? new Date(activePulse.startDate) : null;
    const surveyHasBeenSent = !!startDate && startDate < new Date();

    set({
      pulseId: activePulse?.id ?? null,
      questionIds: storedQuestionIds,
      // because the API only returns user IDs, we can only
      // infer an audience of users, not teams or tags, from
      // API data. If we want the UI to remember team or tag
      // selections between visits, we will need to update the
      // backend to store that data natively.
      audience: {
        tags: [],
        teams: [],
        // FIXME: Audience typings are way too broad! We have to cast to an
        // entire User model...
        users: (activePulse?.targetAudience ?? []).filter(existsFilter) as User[],
      },
      cadence: activePulse?.cadence ?? emptyState.cadence,
      startDate: startDate ?? new Date(),
      surveyHasBeenSent,
    });
  },

  setSetupInfo: (info) => {
    set(info);
  },

  setQuestions: (questionIds) => {
    set({ questionIds });
  },
}));
