import { Box, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MysteryHeader from 'components/MysteryHeader';
import React from 'react';
import { SUPPORT_EMAIL } from 'utils/stringUtil';

const LogoWithCard = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();

  return (
    <Box width={'100%'} mb={20}>
      <MysteryHeader style={{ width: '100%', justifyContent: 'center' }} />
      <Box mt={2}>
        <Divider />
      </Box>
      <Box className={classes.card}>
        {children}
        <Box className={classes.helpText}>
          <Typography>
            Need help?{' '}
            <span className={classes.helpTextLink}>
              <a href={`mailto:${SUPPORT_EMAIL}`}>Contact us.</a>
            </span>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    maxWidth: '600px',
    margin: '40px auto',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.primary[200]}`,
    color: theme.palette.text.primary,
    fontSize: '16px',
    lineHeight: '24px',
    boarderRadius: '8px',
  },
  helpText: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'row',
    color: theme.palette.text.primary,
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'center',
    padding: '12px 20px',
    borderTop: `1px solid ${theme.palette.primary[200]}`,
    background: theme.palette.common.white,
  },
  helpTextLink: {
    color: theme.palette.primary.main,
    padding: '0 8px',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'none',
    },
  },
}));

export default LogoWithCard;
