import { CSSObject } from '@emotion/react';
import { colors } from '@mysteryco/design';
import theme from 'theme';

type CSSMap = { [key: string]: CSSObject };

export const card: CSSMap = {
  root: {
    background: colors.Glue_Paper,
    border: `1px solid ${colors.Glue_BorderLight}`,
    borderRadius: theme.spacing(2),
    transition: 'border 300ms ease-in-out, box-shadow 300ms ease-in-out',
  },
  hover: {
    borderColor: colors.Glue_BorderDark,
    boxShadow:
      '0px 10px 15px -3px rgba(155, 160, 166, 0.1), 0px 4px 6px -2px rgba(155, 160, 166, 0.06)',
  },
  focus: {
    borderColor: colors.Glue_Ink00,
    boxShadow: `0px 0px 0px 0.5px ${colors.Glue_Ink00}, 0px 10px 15px -3px rgba(155, 160, 166, 0.1), 0px 4px 6px -2px rgba(155, 160, 166, 0.06)`,
  },
};

export const aside: CSSMap = {
  root: {
    background: colors.Glue_LavenderLight,
    borderRadius: theme.spacing(2),
  },
};
