import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowLeft from '@mysteryco/design/icons/ArrowLeft';
import ArrowRight from '@mysteryco/design/icons/ArrowRight';
import { colors } from '@mysteryco/design';
import { pluralize } from 'humanize-plus';
import React, { useEffect, useState } from 'react';
import TextInput from './Forms/TextInput';
import IconButton from './IconButton';
import theme from 'theme';

interface TablePaginationProps {
  totalCount: number;
  pageSize: number;
  onChange?: (indices: [start: number, end: number]) => void;
  pluralizeLabel?: (count: number) => string;
}

const clamp = (number: number, min: number, max: number) =>
  Math.min(Math.max(number, min), max);
const startIndex = (page: number, pageSize: number) => (page - 1) * pageSize;
const endIndex = (start: number, pageSize: number, totalCount: number) =>
  Math.min(start + pageSize, totalCount);

const TablePagination = ({
  totalCount,
  pageSize,
  onChange = () => null,
  pluralizeLabel = (count) => pluralize(count, 'item'),
}: TablePaginationProps) => {
  const classes = useStyles();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageInput, setPageInput] = useState(`${currentPage}`);

  useEffect(() => {
    // Reset page when anything affecting pagination changes
    handleChange(1);
  }, [totalCount, pageSize]);

  const totalPages = Math.ceil(totalCount / pageSize);
  const start = startIndex(currentPage, pageSize) + 1;
  const end = endIndex(start - 1, pageSize, totalCount);

  const handleBlur = () => {
    setPageInput(`${currentPage}`);
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
    const newPage = clamp(parseInt(pageInput), 1, totalPages);
    handleChange(newPage);
  };

  const handleClick = (event: React.SyntheticEvent, change: number) => {
    const newPage = currentPage + change;
    handleChange(newPage);
  };

  const handleChange = (newPage: number) => {
    const start = startIndex(newPage, pageSize);
    const end = endIndex(start, pageSize, totalCount);
    setPageInput(`${newPage}`);
    setCurrentPage(newPage);
    onChange([start, end]);
  };

  return (
    !!totalCount && (
      <Box className={classes.container}>
        <Box className={classes.showing}>
          <Box className={classes.active}>
            <span>Showing</span>
            <span className={classes.number}>
              {start}-{end}
            </span>
          </Box>
          <span>of</span> <span className={classes.number}>{totalCount}</span>
          <span>{pluralizeLabel(totalCount)}</span>
        </Box>
        <Box className={classes.actions}>
          <form
            className={classes.pageSelect}
            onSubmitCapture={handleSubmit}
            onBlur={handleBlur}
          >
            Page
            <TextInput
              type='number'
              min='1'
              max={totalPages}
              value={pageInput}
              onChange={(e) => setPageInput(e.target.value)}
              className={classes.pageSelectInput}
            />
            of {totalPages}
          </form>
          <Box className={classes.pageArrows}>
            <IconButton onClick={(e) => handleClick(e, -1)} disabled={currentPage <= 1}>
              <ArrowLeft size={16} />
            </IconButton>
            <IconButton
              onClick={(e) => handleClick(e, 1)}
              disabled={currentPage >= totalPages}
            >
              <ArrowRight size={16} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    )
  );
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    width: '100%',
    background: colors.White,
    borderTop: `1px solid ${colors.LightGray}`,
  },
  showing: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    padding: 0,
    gap: theme.spacing(1),
    fontSize: '.875rem',
    fontWeight: 500,
    lineHeight: 1,
    color: colors.DarkGray,
  },
  active: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    gap: theme.spacing(1),
    fontWeight: 700,
    color: colors.Dusk,
  },
  number: {
    fontFamily: theme.typography.actionLabel.fontFamily,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-center',
    padding: 0,
    gap: theme.spacing(5),
  },
  pageSelect: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    gap: theme.spacing(3),
    fontWeight: 500,
    fontSize: '.875rem',
    lineHeight: 1,
    color: colors.DarkGray,
  },
  pageSelectInput: {
    '& .MuiInputBase-input': {
      padding: 0,
      width: '4em',
    },
  },
  pageArrows: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alicgnItems: 'center',
    padding: 0,
    gap: 8,
  },
});

export default TablePagination;
