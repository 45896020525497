import { Result } from 'antd';

const NotAuthorizedError = ({
  title,
  subtitle,
}: {
  title?: string;
  subtitle?: string;
}) => (
  <Result
    status='403'
    title={title || 'Forbidden'}
    subTitle={subtitle || 'Sorry, you are not authorized to access this page.'}
  />
);

export default NotAuthorizedError;
