import { ViewerFragmentFragment } from 'types';
import AccountForm from './AccountForm';
import AuthContainer from './AuthContainer';
import PeopleAsideFour from 'glue/assets/artwork/people-aside-four.png';
import PeopleAsideThree from 'glue/assets/artwork/people-aside-three.png';

const NewUserScreen = ({ accountInfo, setAccountInfo, next, org }) => {
  return (
    <AuthContainer
      showCodeMessage
      title={`Welcome to Glue`}
      subTitle={`Sign up to get started`}
      leftIllustrationSrc={PeopleAsideThree}
      rightIllustrationSrc={PeopleAsideFour}
      logoImage={org?.logoUrl}
    >
      <AccountForm
        orgName={org?.name}
        ctaText='Sign up'
        viewer={accountInfo as ViewerFragmentFragment}
        onSave={(v) => {
          setAccountInfo(v);
          next();
        }}
      />
    </AuthContainer>
  );
};

export default NewUserScreen;
