import React from 'react';
import { css } from '@emotion/react';
import { StepStatus } from 'constants/Type';
import { IconProps } from '@mysteryco/design/icons';
import { colors } from '@mysteryco/design';
import theme from 'theme';

export interface StepperLinkProps {
  text: string;
  Icon?: React.ComponentType<IconProps>;
  iconText?: string;
  status?: StepStatus;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  [key: string]: any;
}

const StepperLink = ({
  text,
  Icon,
  iconText,
  status,
  onClick = () => {},
  ...props
}: StepperLinkProps) => {
  return (
    <div css={styles.root} onClick={onClick} {...props}>
      <div
        css={[
          styles.circle,
          (status === StepStatus.Initial && styles.initial) ||
            (status === StepStatus.Cancelled && styles.cancelled),
        ]}
      >
        {Icon ? (
          <Icon
            size={14}
            color={status === StepStatus.Cancelled ? '#AB5F5F' : colors.Glue_Ink00}
          />
        ) : (
          <p
            style={
              status === StepStatus.Cancelled
                ? { color: '#AB5F5F' }
                : { color: colors.Glue_Ink00 }
            }
          >
            {iconText}
          </p>
        )}
      </div>
      <p css={status === StepStatus.Current && styles.current}>{text}</p>
    </div>
  );
};

const styles = {
  root: css({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: colors.Glue_Ink00,
  }),
  circle: css({
    display: 'flex',
    width: '28px',
    height: '28px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '28px',
    background: colors.Glue_Mint00,
    border: `1px solid ${colors.Glue_DarkMint10}`,
    marginRight: theme.spacing(2),
  }),
  initial: css({
    background: colors.Glue_Paper,
    border: `1px solid ${colors.Glue_Ink00}`,
  }),
  cancelled: css({
    background: colors.Glue_Red10,
    border: '1px solid #E7AFAF',
  }),
  current: css({
    fontWeight: 700,
  }),
};

export default StepperLink;
