import { cx } from '@emotion/css';
import { Box, ButtonBase, Chip, Link } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@mysteryco/design';
import { ReactNode } from 'react';
import theme from 'theme';

interface AlertProps {
  text: string;
  icon?: ReactNode;
  severity?: 'success' | 'info' | 'warning' | 'error';
  href?: string;
  linkText?: string;
  pillText?: string;
  onClick?: () => void;
}

const AlertBar = ({
  text,
  icon,
  severity = 'info',
  href,
  linkText,
  pillText,
  onClick,
}: AlertProps) => {
  const classes = useStyles();
  const containerClass = cx(
    classes.container,
    ((href || onClick) && linkText) && classes.elevatedContainer,
  );

  return (
    <Alert className={containerClass} variant='outlined' severity={severity} icon={false}>
      <Box className={classes.alertContent}>
        <Box className={classes.iconContainer}>{icon}</Box>
        {pillText && <Chip label={pillText} className={classes.pill} />}
        <Box className={classes.alertText}>{text}</Box>
      </Box>
      {href && (
        <Link href={href} className={classes.link}>
          {linkText}
        </Link>
      )}
      {onClick && (
        <ButtonBase onClick={onClick} className={classes.link}>
          {linkText}
        </ButtonBase>
      )}
    </Alert>
  );
};

const useStyles = makeStyles({
  container: {
    borderRadius: theme.spacing(1),
    border: '1px solid',
    padding: 0,
    '& .MuiAlert-message': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: `${theme.spacing(0.5)} ${theme.spacing(4)}`,
      gap: theme.spacing(2),
      width: '100%',
    },
    '&.MuiAlert-outlinedInfo': {
      background: colors.Purple50,
      borderColor: colors.Purple300,
      '& $pill': {
        color: colors.Dusk,
        background: colors.Purple100,
      },
    },
    '&.MuiAlert-outlinedError': {
      background: colors.Orange100,
      borderColor: colors.Orange300,
      '& $pill': {
        color: colors.Orange800,
        background: colors.Orange200,
      },
    },
    '&.MuiAlert-outlinedWarning': {
      background: colors.Yellow200,
      borderColor: colors.Yellow300,
      '& $pill': {
        color: colors.Yellow600,
        background: colors.Yellow300,
      },
    },
    '&.MuiAlert-outlinedSuccess': {
      background: colors.Green100,
      borderColor: colors.Green400,
      '& $pill': {
        color: colors.Green900,
        background: colors.Green200,
      },
    },
  },
  elevatedContainer: {
    boxShadow:
      '0px 4px 6px -1px rgba(155, 160, 166, 0.1), 0px 2px 4px -1px rgba(155, 160, 166, 0.06)',
  },
  iconContainer: {
    color: colors.Main,
    height: 20,
    width: 20,
  },
  pill: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
    height: 'inherit',
    gap: theme.spacing(2),
    borderRadius: theme.spacing(4),
    '& .MuiChip-label': {
      padding: 0,
      fontWeight: 500,
      fontSize: '.875rem',
      lineHeight: 1.43,
      textAlign: 'center',
    },
  },
  alertContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: `${theme.spacing(2)} 0`,
    gap: theme.spacing(3),
  },
  alertText: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1.29,
    color: colors.Dusk,
  },
  link: {
    '&.MuiButtonBase-root, &.MuiLink-root': {
      padding: `${theme.spacing(2)} 0`,
      fontWeight: 500,
      fontSize: '.875rem',
      lineHeight: 1.29,
      color: colors.Main,
      textDecoration: 'underline',
    },
  },
});

export default AlertBar;
