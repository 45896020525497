import { css } from '@emotion/react';
import { colors } from '@mysteryco/design';
import theme from 'theme';

export const tabStyles = css({
  color: colors.Glue_Ink00,
  display: 'inline-flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  alignItems: 'flex-start',
  padding: `0 0 ${theme.spacing(2)}`,
  minWidth: 0,
  minHeight: 0,
  fontWeight: 500,
  fontSize: '1rem',
  lineHeight: 1,
  textTransform: 'none',
  opacity: 1,
  borderBottom: '2px solid transparent',
  transition: 'border 300ms ease-in-out',

  '&:hover, &:focus-within': {
    borderBottomColor: colors.Glue_Mint00,
    color: colors.Glue_Ink00,
  },
  '&:disabled, &.Mui-disabled': {
    opacity: 1,
    color: colors.Glue_Ink20,
  },

  '&[data-state="active"]': {
    fontWeight: 700,
    borderBottomColor: colors.Glue_DarkMint10,
  },
});

export const tabsListStyles = css({
  position: 'relative',
  minHeight: 0,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  flexWrap: 'nowrap',
  gap: theme.spacing(5),

  '&::after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 2,
    zIndex: -1,
    backgroundColor: colors.Glue_LavenderDark,
    opacity: 0.25,
  },
});
