import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@mysteryco/design';
import theme from 'theme';

interface BadgeChipProps {
  text: string;
  iconElement?: React.ReactNode;
}

const BadgeChip = ({ text, iconElement }: BadgeChipProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {iconElement}
      <Typography className={classes.text}>{text}</Typography>
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    borderRadius: theme.spacing(8),
    color: colors.Dusk,
    background: colors.Purple100,
  },
  text: {
    fontSize: 12,
    fontWeight: 500,
    marginLeft: `${theme.spacing(1)}`,
  },
});

export default BadgeChip;
