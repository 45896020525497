// @ts-strict-ignore
import { gql } from '@apollo/client';
import { Box, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SSFMessageWidgetWrapper from 'components/SSFMessageWidgetWrapper';
import SectionSubHeader from 'components/SectionSubHeader';
import { pluralize } from 'humanize-plus';
import { formatOrderPricingBreakdown } from 'lib/helpers/money';
import theme from 'theme';
import {
  ContractUnitType,
  CustomerType,
  PriceSummaryContractFragment,
  PriceSummaryTemplateFragment,
} from 'types';

export interface PriceSummaryProps {
  contract: PriceSummaryContractFragment;
  template: PriceSummaryTemplateFragment;
  guestCount: number;
  bookSuccessful?: boolean;
  isRequestToBook?: boolean;
  isSurpriseToAll: boolean;
  customerType: CustomerType;
}

const useStyles = makeStyles({
  root: {
    border: `1px solid ${theme.palette.grey[500]}`,
  },
  divider: {
    marginTop: '16px',
    marginBottom: '16px',
  },
  upsell: {
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
  },
  lineItemDetail: {
    marginTop: '8px',
    fontSize: '12px',
    width: '100%',
    color: theme.palette.grey[600],
    fontWeight: 500,
    alignSelf: 'flex-start',
    whiteSpace: 'nowrap',
  },
  lineItemLabel: {
    fontWeight: 'bold',
  },
  lineItemPrice: {
    whiteSpace: 'nowrap',
  },
  lineItemLine: {
    gap: '12px',
  },
});

export function PriceSummary({
  contract,
  template,
  guestCount,
  bookSuccessful,
  isRequestToBook,
  isSurpriseToAll,
  customerType,
}: PriceSummaryProps) {
  const classes = useStyles();

  const {
    pricePerGuest,
    minimumPrice,
    hasPhysicalGoods,
    premiumUnitPrice,
    isPremium,
    subtotal,
    total,
    doesNotExceedMinimum,
    premiumUnitOverchargeSubtotal,
    premiumUnitExpense,
    unitExpense,
    unitOvercharge,
    unitOverchargeSubtotal,
    unitPrice,
    totalAdditionalDollarCharge,
    subtotalAdditionalDollarCharge,
    totalAdditionalDollarChargeTax,
    totalTax,
    unit,
    guestCount: guestCountString,
  } = formatOrderPricingBreakdown({
    contract,
    guestCount,
    template,
    isSurpriseToAll,
  });

  const isAdditionalPaymentRequired = !contract || !!totalAdditionalDollarCharge;

  const conclusion = !isAdditionalPaymentRequired
    ? `No payment is required today.` +
      (contract?.type !== ContractUnitType.Event
        ? ' You will be charged based on the number of guests who RSVP.'
        : '')
    : `Events are billed based on the number of guests who RSVP.  Some additional payment may be required depending on the final RSVP count.`;

  let title: string;
  let subTitle: string;
  if (isRequestToBook) {
    title = 'Request summary';
    subTitle = "These are the details of the event you're requesting to book";
  } else if (bookSuccessful) {
    title = 'Order summary';
    subTitle = "These are the details of the event you've booked";
  } else {
    title = 'Order summary';
    subTitle = "These are the details of the event you're about to book";
  }

  const pluralUnitString = pluralize(2, unit);

  return (
    <SSFMessageWidgetWrapper
      color='white'
      footer={conclusion}
      className={classes.root}
      footerClassName={classes.upsell}
    >
      <Box p={6} mb={6}>
        <Box mb={6}>
          <SectionSubHeader
            title={title}
            subTitle={subTitle}
            color={theme.palette.text.primary}
          />
        </Box>
        {/* How many guests the customer specified */}
        <LineItem description='Attendees' costString={guestCountString} />
        {/*
          If it doesn't reach the minimum, we remind of the minimum to clarify
          the rest of the pricing breakdown
         */}
        {doesNotExceedMinimum && (
          <LineItem description='Minimum event cost' costString={minimumPrice} />
        )}
        {/* Hide per-guest cost if minimum is not met; it's not accurate */}
        {!doesNotExceedMinimum && (
          <LineItem description='Price per guest' costString={pricePerGuest} />
        )}
        {/* If units are drawn from the customer's contract, we inform them */}
        {unitExpense && (
          <LineItem
            description={`${pluralUnitString} used from your contract`}
            costString={unitExpense}
          />
        )}
        {/* If additional units need to be purchased to cover the cost, we also inform them */}
        {unitOvercharge && (
          <LineItem
            description={`Additional ${pluralUnitString} needed`}
            costString={unitOvercharge}
            detail='Additional purchase required to cover total'
          />
        )}
        {unitOverchargeSubtotal && (
          <LineItem
            description={`Cost of additional ${pluralUnitString}`}
            costString={unitOverchargeSubtotal}
            detail={unitPrice}
          />
        )}
        {premiumUnitExpense && !premiumUnitExpense.startsWith('0') && (
          <LineItem
            description={`Upgrade ${pluralUnitString} used from your contract`}
            costString={premiumUnitExpense}
          />
        )}
        {isPremium && premiumUnitOverchargeSubtotal && (
          <LineItem
            description={`Cost of additional upgrade ${pluralUnitString}`}
            costString={premiumUnitOverchargeSubtotal}
            detail={premiumUnitPrice}
            extraDetail={capitalize(
              hasPhysicalGoods
                ? 'This event includes physical goods'
                : 'This is an upgraded event',
            )}
          />
        )}
        {/* Only show subtotal if tax is applied to it later */}
        {totalTax && <LineItem description='Total before tax' costString={subtotal} />}
        {/* Show tax if it was applied to subtotal */}
        {totalTax && <LineItem description='Tax' costString={totalTax} />}
        {/* Show additional $$ overcharge subtotal if it exists, and the tax applied to it. */}
        {subtotalAdditionalDollarCharge && (
          <>
            <Divider className={classes.divider} />
            <LineItem
              description='Additional costs before tax'
              costString={subtotalAdditionalDollarCharge}
            />
          </>
        )}
        {totalAdditionalDollarChargeTax && (
          <LineItem
            description='Tax on additional costs'
            costString={totalAdditionalDollarChargeTax}
          />
        )}
        <Divider className={classes.divider} />
        {/* Contract customers see a breakdown of all units debited from their account, and the final total $$ cost of overcharges */}
        {contract ? (
          <>
            <LineItem
              description={`Total ${pluralUnitString} from your contract`}
              bold
              costString={unitExpense || `1 ${pluralUnitString}`}
            />
            {premiumUnitExpense && !premiumUnitExpense.startsWith('0') && (
              <LineItem
                description={`Total upgrade ${pluralUnitString} from your contract`}
                bold
                costString={premiumUnitExpense || `0 ${pluralUnitString}`}
              />
            )}
            {totalAdditionalDollarCharge && (
              <LineItem
                description='Potential additional costs'
                bold
                costString={totalAdditionalDollarCharge}
                detail='The maximum amount if everyone RSVPs'
              />
            )}
          </>
        ) : (
          <LineItem description='Total' bold costString={total} />
        )}
      </Box>
    </SSFMessageWidgetWrapper>
  );
}
PriceSummary.fragments = {
  template: gql`
    fragment PriceSummaryTemplate on MysteryTemplate {
      id
      cost {
        ctcPricingBands {
          minUsers
          maxUsers
          cents
          credits
          unit
        }
      }
      experiences {
        hasPhysicalGoods
        requiresUpgrade
      }
    }
  `,
  contract: gql`
    fragment PriceSummaryContract on Contract {
      type
      premiumUnitCostCents
      premiumUnitCount
      unitCount
      unitCostCents
    }
  `,
};

const LineItem = ({
  description,
  costString,
  detail,
  extraDetail,
  bold = false,
}: {
  description: string;
  costString: string;
  detail?: string;
  extraDetail?: string;
  bold?: boolean;
}) => {
  const classes = useStyles();

  return (
    <Box display='flex' flexDirection='column' mb={2}>
      <Box
        display={'flex'}
        justifyContent='space-between'
        className={classes.lineItemLine}
      >
        <Typography className={classes.lineItemLabel}>
          {capitalize(description)}
        </Typography>
        <Typography className={classes.lineItemPrice}>
          {bold ? <strong>{costString}</strong> : costString}
        </Typography>
      </Box>
      {detail && (
        <Typography className={classes.lineItemDetail}>{capitalize(detail)}</Typography>
      )}
      {extraDetail && (
        <Typography className={classes.lineItemDetail}>
          {capitalize(extraDetail)}
        </Typography>
      )}
    </Box>
  );
};

function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
