import { Main, Purple500 } from '@mysteryco/design/src/tokens/colors';
import StarEmpty from './icons/StarEmpty';
import StarFull from './icons/StarFull';

const StarRating = ({
  value = 0,
  size = 20,
  emptyColor = Purple500,
  filledColor = Main,
  count = 5,
  gap = 2,
  className = '',
  style = {},
}) => {
  const fullStars = Math.floor(value);
  const partialStars = value - fullStars;
  const width = size * count + gap * (count - 1);
  const fillWidth = (size + gap) * Math.floor(fullStars) + size * partialStars;

  const sharedStyles = {
    display: 'flex',
    gap: gap,
  };
  const emptyStyles = {
    ...sharedStyles,
    clipPath: `inset(0 0 0 ${fillWidth}px)`,
  };
  const fullStyles = {
    ...sharedStyles,
    clipPath: `inset(0 ${width - fillWidth}px 0 0)`,
    top: 0,
  };

  return (
    <div css={{ position: 'relative' }} className={className} style={style}>
      <div css={emptyStyles}>
        {new Array(count).fill(null).map((_, i) => (
          <StarEmpty key={i} color={emptyColor} size={size} />
        ))}
      </div>
      <div css={{ position: 'absolute', ...fullStyles }}>
        {new Array(count).fill(null).map((_, i) => (
          <StarFull key={i} color={filledColor} size={size} />
        ))}
      </div>
    </div>
  );
};

export default StarRating;
