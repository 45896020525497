// @ts-strict-ignore
import { gql } from '@apollo/client';
import { Box } from '@material-ui/core';
import { colors } from '@mysteryco/design';
import ArrowRight from '@mysteryco/design/icons/ArrowRight';
import CalendarDate from '@mysteryco/design/icons/CalendarDate';
import { DEFAULT_SSF_ROUTE, Paths } from 'Routes';
import meetups from 'glue/assets/reco-meetups-min.png';
import pulse from 'glue/assets/reco-pulse-min.png';
import virtualEvents from 'glue/assets/reco-virtual-events-min.png';
import ActionCard from 'glue/components/cards/ActionCard';
import {
  CollapsibleCard,
  CollapsibleCardGroup,
} from 'glue/components/cards/CollapsibleCard';
import SectionHeader from 'glue/components/structure/SectionHeader';
import mixpanel from 'mixpanel-browser';
import theme from 'theme';
import {
  OrganizationOnboardingStatus,
  useRecommendationsQuery,
  RecommendationsQuery,
} from 'types';
import { getMeetupsPath } from '../Activities/Meetups';
import ConnectTeamCalendars, {
  isLowAccuracy,
  isOrgConnected,
} from './components/CalendarConnection';

import { LinkButton } from 'glue/components/buttons/Button';
import { isInsightsCustomer } from 'utils/security';
import { css } from '@emotion/react';

type Props = {
  onboardingStatus?: OrganizationOnboardingStatus;
  className?: string;
  isAdmin?: boolean;
};

const EnableMeetups = ({
  className,
  showUpsell,
  meetupExists,
}: {
  className?: string;
  showUpsell?: boolean;
  meetupExists?: boolean;
}) => {
  return (
    <ActionCard
      image={<img src={meetups} alt='' />}
      headline='Build strong connected teams'
      action={
        <LinkButton
          to={getMeetupsPath(showUpsell)}
          color='green'
          endIcon={<ArrowRight color='inherit' size={22} />}
        >
          {meetupExists
            ? 'Manage Meetups'
            : showUpsell
            ? 'Upgrade to activate'
            : 'Activate now'}
        </LinkButton>
      }
      className={className}
    >
      Increase job satisfaction and lower churn by building meaningful connections.
      {showUpsell && <b> Upgrade your account to activate Meetups.</b>}
    </ActionCard>
  );
};

const EnablePulse = ({
  className,
  pulseExists,
}: {
  className?: string;
  pulseExists?: boolean;
}) => {
  return (
    <ActionCard
      image={<img src={pulse} alt='' />}
      headline='Understand employee sentiment'
      action={
        <LinkButton
          to={`${Paths.EXPLORE}/pulse`}
          color='green'
          endIcon={<ArrowRight color='inherit' size={22} />}
        >
          {pulseExists ? 'Manage Pulse' : 'Activate Pulse'}
        </LinkButton>
      }
      className={className}
    >
      Use Pulse surveys to empower employees to give you feedback
    </ActionCard>
  );
};

const BookEvents = ({
  className,
  viewer,
}: {
  className?: string;
  viewer: RecommendationsQuery['viewer'];
}) => {
  const handleBookEvent = () => {
    mixpanel.track('book event opened', {
      source: 'home',
      customerType: viewer?.customerType,
      firstTimeBooker: viewer?.requestedTeamEvents.length === 0,
    });
  };
  return (
    <ActionCard
      image={<img src={virtualEvents} alt='' />}
      headline={'Book a virtual event'}
      action={
        <LinkButton
          shape='round'
          color='green'
          to={DEFAULT_SSF_ROUTE}
          newTab
          onClick={handleBookEvent}
          endIcon={<ArrowRight color='inherit' size={22} />}
        >
          Book event
        </LinkButton>
      }
      className={className}
    >
      Book an engaging, virtual team-building activity from Glue’s catalog.
    </ActionCard>
  );
};

const ConnectCalendars = ({ viewer }: { viewer: RecommendationsQuery['viewer'] }) => {
  return (
    <CollapsibleCardGroup>
      <CollapsibleCard
        leadIcon={<CalendarDate color={colors.Glue_Ink00} size={15} />}
        headline='Connect team calendars'
        teaser='Unlock richer connection data by syncing your team’s calendars'
      >
        <ConnectTeamCalendars viewer={viewer} loading={false} />
      </CollapsibleCard>
    </CollapsibleCardGroup>
  );
};

const Recommendations = ({ onboardingStatus, isAdmin = false, ...props }: Props) => {
  const { data } = useRecommendationsQuery({
    variables: { isOrgAdmin: isAdmin },
  });
  const viewer = data?.viewer;
  const organization = viewer?.defaultOrganization;
  const meetupExists = !!organization?.watercoolerInitiative;
  const pulseExists = !!organization?.moralePulses?.find((pulse) => pulse.enabled);
  const userIsInsightsCustomer = isInsightsCustomer(viewer);
  const showConnectCalendarAction =
    onboardingStatus === OrganizationOnboardingStatus.Complete &&
    !isOrgConnected(organization) &&
    isLowAccuracy(organization);

  return (
    <Box className={props?.className} css={styles.root}>
      <SectionHeader title='Recommendations' />
      <Box css={[styles.cardsRow, !userIsInsightsCustomer && styles.cardsRowWithUpsell]}>
        {isAdmin && (
          <>
            <EnableMeetups
              css={styles.card}
              showUpsell={!userIsInsightsCustomer}
              meetupExists={meetupExists}
            />
            <EnablePulse css={styles.card} pulseExists={pulseExists} />
          </>
        )}
        <BookEvents css={styles.card} viewer={viewer} />
      </Box>
      <Box css={styles.cardsColumn}>
        {showConnectCalendarAction && <ConnectCalendars viewer={viewer} />}
      </Box>
    </Box>
  );
};
Recommendations.query = gql`
  query Recommendations($isOrgAdmin: Boolean!) {
    viewer {
      id
      customerType
      organizationRole {
        id
        name
      }
      requestedTeamEvents {
        id
      }
      defaultOrganization {
        id
        moralePulses {
          id
          enabled
        }
        watercoolerInitiative @include(if: $isOrgAdmin) {
          id
        }
      }
      ...ConnectTeamCalendarsViewer
    }
  }
  ${ConnectTeamCalendars.fragments}
`;

const styles = {
  root: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    gap: theme.spacing(8),
    alignSelf: 'stretch',
  }),
  cardsRow: css({
    width: '100%',
    padding: 0,
    gap: theme.spacing(5),

    display: 'grid',
    gridTemplateColumns: `repeat(auto-fit, minmax(240px, 1fr))`,
    gridAutoFlow: 'row',
    gridAutoRows: 'min-content',

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  }),
  cardsRowWithUpsell: css({
    flexDirection: 'row-reverse',
  }),
  cardsColumn: css({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyItems: 'stretch',
    padding: 0,
    gap: theme.spacing(5),
  }),
  card: css({
    maxWidth: 300,
    [theme.breakpoints.down('sm')]: {
      minWidth: 0,
      maxWidth: '100%',
      flexDirection: 'row',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  }),
};

export default Recommendations;
