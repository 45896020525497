import { css } from '@emotion/react';
import { colors } from '@mysteryco/design/src';
import theme from 'theme';
import ProgressIndicator from '../information/ProgressIndicator';

interface Props {
  title: string;
  subTitle?: string;
  heroIcon?: JSX.Element;
  onFinish: () => void;
}

const ProgressPage = ({ title, subTitle, heroIcon, onFinish }: Props) => {
  return (
    <div css={styles.root}>
      <div css={styles.content}>
        {heroIcon && <div>{heroIcon}</div>}
        <div css={styles.title}>{title}</div>
        {subTitle && <div css={styles.subTitle}>{subTitle}</div>}
        <ProgressIndicator onFinish={onFinish} />
      </div>
    </div>
  );
};

const styles = {
  root: css({
    height: '100%',
    padding: '2rem',
  }),
  content: css({
    marginTop: theme.spacing(12),
    color: colors.Glue_Ink00,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: theme.spacing(5),
    flex: 1,
  }),
  title: css({
    fontSize: 32,
    fontWeight: 700,
    lineHeight: '120%',
  }),
  subTitle: css({
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '28px',
  }),
};

export default ProgressPage;
