import { PatternName } from 'glue/components/accessibility/PatternedBox';
import { MoralePulseResponseSentiment } from 'types';

export const patternsBySentiment: { [sentiment: string]: PatternName } = {
  [MoralePulseResponseSentiment.VeryNegative]: 'trend-down-strong',
  [MoralePulseResponseSentiment.Negative]: 'trend-down',
  [MoralePulseResponseSentiment.Neutral]: 'trend-neutral',
  [MoralePulseResponseSentiment.Positive]: 'trend-up',
  [MoralePulseResponseSentiment.VeryPositive]: 'trend-up-strong',
};
