import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Orange200, Orange800 } from '@mysteryco/design/src/tokens/colors';
import theme from 'theme';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

const UpsellLock = () => {
  const classes = useStyles();
  return (
    <Box className={classes.upsellLock}>
      <LockOutlinedIcon style={{ fontSize: 16 }} />
      <span>Locked</span>
    </Box>
  );
};

const useStyles = makeStyles({
  upsellLock: {
    display: 'inline-flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    alignItems: 'center',
    padding: `${theme.spacing(1)} 14px ${theme.spacing(1)} ${theme.spacing(3)}`,
    borderRadius: theme.spacing(4),
    backgroundColor: Orange200,
    color: Orange800,
    fontSize: '.6rem',
    textTransform: 'uppercase',
    letterSpacing: '.1rem',
    fontWeight: 700,
    lineHeight: '1.25rem',
    '& > svg': {
      width: '1rem',
      height: '1rem',
    },
  },
});

export default UpsellLock;
