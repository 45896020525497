// @ts-strict-ignore
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import cx from 'classnames';
import _ from 'lodash';
import theme, { selectedOptionBackground } from 'theme';
import { AnswerProps } from '.';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(6),
  },
  flexCol: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 350,
    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap',
      maxWidth: 600,
    },
  },
});

const useCircularNumberStyles = makeStyles({
  circularContainer: {
    height: 52,
    width: 52,
    borderRadius: '50%',
    border: `1px solid ${theme.palette.grey[500]}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),

    cursor: 'pointer',
  },
  selected: {
    borderColor: theme.palette.primary.dark,
    backgroundColor: selectedOptionBackground,
  },
});

const CircularNumber = ({
  number,
  onClick,
  answer,
}: {
  number: number;
  onClick(answer: string): void;
  answer: string;
}) => {
  const classes = useCircularNumberStyles();
  const isSelected = String(answer) === String(number);
  return (
    <Box
      onClick={() => onClick(String(number))}
      className={cx({ [classes.selected]: isSelected }, classes.circularContainer)}
    >
      <Typography>{number}</Typography>
    </Box>
  );
};

const ScaleAnswer = ({ question, answer, setAnswer }: AnswerProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.flexCol}>
        {_.times(question.scaleMax, (i) => {
          const rating = i + 1;
          return (
            <CircularNumber
              number={rating}
              onClick={(v) => setAnswer(v)}
              answer={answer}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ScaleAnswer;
