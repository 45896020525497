import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Colors, Sizes } from 'constants/index';
import React from 'react';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Paths } from 'Routes';
import { AnswerProps } from '.';

const useStyles = makeStyles({
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputWrapper: {
    height: '55px',
  },
  charLimit: {
    fontSize: '10px',
    color: Colors.Palette.Error,
    marginTop: Sizes.SPACING_XSMALL,
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const TextAnswer = ({ answer, setAnswer }: AnswerProps) => {
  const location = useLocation();
  const isFeedback = matchPath(location.pathname, {
    path: Paths.FEEDBACK,
    exact: false,
    strict: false,
  });

  const classes = useStyles();

  return (
    <div className={classes.flexCol} css={{ paddingTop: '5px' }}>
      <TextField
        id='outlined-multiline-static'
        label={isFeedback ? 'Your feedback' : 'Your answer'}
        multiline
        rows={8}
        variant='outlined'
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
      />
    </div>
  );
};

export default TextAnswer;
