// @ts-strict-ignore
import { gql } from '@apollo/client';
import { Box, Popover, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@mysteryco/design/src';
import { PricingUnitIcon } from 'components/icons/PricingUnitIcon';
import {
  DEFAULT_SURPRISE_TO_ALL_BANDS,
  formatBandPrice,
  formatOrderPricingBreakdown,
} from 'lib/helpers/money';
import { ReactNode, useState } from 'react';
import {
  ContractUnitType,
  CostUnit,
  DetailedPriceEstimatePopoverContractFragment,
  DetailedPriceEstimatePopoverTemplateFragment,
} from 'types';

/**
 * Renders tiers of pricing for a given number of guests as a
 * popover, plus an estimate for their expected usage.
 *
 * Does not show details like premium units, insufficient
 * unit inventory on contract, etc.
 */
export function DetailedPriceEstimatePopover({
  contract,
  prospectiveGuests,
  template,
  children = 'Estimate my cost',
  isSurpriseToAll,
}: {
  prospectiveGuests: number;
  contract: DetailedPriceEstimatePopoverContractFragment | null;
  template: DetailedPriceEstimatePopoverTemplateFragment;
  children?: ReactNode;
  isSurpriseToAll?: boolean;
}) {
  const cost = template?.cost;
  const contractType = contract?.type ?? ContractUnitType.Cents;
  const bands = cost?.ctcPricingBands
    ? cost?.ctcPricingBands
    : isSurpriseToAll
    ? DEFAULT_SURPRISE_TO_ALL_BANDS
    : [];
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const eventPricedBand = bands.find((band) => band.unit === CostUnit.Event);
  const minGuests = eventPricedBand?.maxUsers ?? 0;
  const tiersBeyondMinimum = bands.filter((band) => band.unit === CostUnit.User);

  const { subtotal, minimumPrice, isPremium } = formatOrderPricingBreakdown({
    contract,
    guestCount: prospectiveGuests,
    template,
    isSurpriseToAll,
  });

  return (
    <>
      <button
        className={classes.linkStyledButton}
        onClick={(ev) => setAnchorEl(ev.currentTarget)}
      >
        {children}
      </button>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className={classes.costTiersPopover}>
          <Typography className={classes.tierEstimateTitle} variant='h6'>
            Your estimate
          </Typography>
          <Box className={classes.estimateRow}>
            <Box fontWeight={900}>{prospectiveGuests} guests</Box>
            <Box className={classes.estimatePrice}>
              <PricingUnitIcon unit={contractType} />
              <span>{subtotal}</span>
              {isPremium && <span className={classes.plusUpgrade}>+ upgrade fee</span>}
            </Box>
          </Box>
          {/*
            An event-priced band indicates a minimum price, so this is
            displayed as such.
          */}
          {eventPricedBand && (
            <>
              <Typography
                gutterBottom={false}
                className={classes.tierPricingTableTitle}
                variant='h6'
              >
                Event pricing
              </Typography>
              <div className={classes.tierSectionHeader}>Minimum event cost</div>
              <Box className={classes.tierRow}>
                <div className={classes.tierGuestCount}>{minGuests} guests</div>
                <div>{minimumPrice}</div>
              </Box>
            </>
          )}
          {/* Display all user-priced bands; these are the tiered prices */}
          {!!tiersBeyondMinimum.length && (
            <div>
              <Box ml={1} className={classes.tierSectionHeader}>
                Per-guest pricing
              </Box>
              {tiersBeyondMinimum.map((band) => {
                return (
                  <Box className={classes.tierRow} key={band.minUsers}>
                    <div className={classes.tierGuestCount}>
                      {band.minUsers}
                      {band.maxUsers ? `-${band.maxUsers}` : '+'} guests
                    </div>
                    <div>{formatBandPrice({ band, contractType })}</div>
                  </Box>
                );
              })}
            </div>
          )}
          {isSurpriseToAll && <Typography></Typography>}
        </div>
      </Popover>
    </>
  );
}

DetailedPriceEstimatePopover.fragments = {
  template: gql`
    fragment DetailedPriceEstimatePopoverTemplate on MysteryTemplate {
      cost {
        id
        ctcPricingBands {
          minUsers
          maxUsers
          unit
          cents
          credits
        }
      }
      experiences {
        id
        hasPhysicalGoods
        requiresUpgrade
      }
    }
  `,
  contract: gql`
    fragment DetailedPriceEstimatePopoverContract on Contract {
      id
      type
      premiumUnitCostCents
      premiumUnitCount
      unitCount
      unitCostCents
    }
  `,
};

const useStyles = makeStyles({
  linkStyledButton: {
    color: colors.DarkGray,
    background: 'none',
    border: 'none',
    padding: 0,
    textDecoration: 'underline',
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',

    '&:hover': {
      cursor: 'pointer',
      color: colors.Main,
    },
  },
  costTiersPopover: {
    width: '300px',
    background: colors.White,
    padding: '16px',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },

  estimateRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'start',
    gap: '8px',
    padding: '8px',
    background: colors.Green100,
    borderRadius: 2,
  },
  estimatePrice: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    maxWidth: '60%',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
  },

  tierRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'start',
    gap: '8px',
    padding: '0 0 8px',
  },
  tierSectionHeader: {
    color: colors.Black,
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 700,
    margin: 0,
  },
  tierGuestCount: {
    color: colors.Black,
    fontSize: '14px',
  },
  tierPricingTableTitle: {
    color: colors.Dusk,
    fontSize: '12px',
    lineHeight: '24px',
    fontWeight: 700,
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  tierEstimateTitle: {
    color: colors.Black,
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '28px',
  },
  plusUpgrade: {
    marginLeft: '4px',
  },
});
