import PageWrapper from 'glue/components/structure/PageWrapper';
import {
  NavigationTab,
  NavigationTabs,
  NavigationTabsList,
} from 'glue/components/tabs/NavigationTabs';
import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch } from 'react-router';
import { PageMetaData, Paths } from 'Routes';
import { ViewerFragmentFragment } from 'types';
import { Details } from './Details';
import Integrations from './Integrations/Integrations';
import { Preferences } from './Preferences/Preferences';

export const PROFILE = [
  {
    path: 'details',
    name: 'Details',
    component: () => <Details />,
    disabled: false,
  },
  {
    path: 'preferences',
    name: 'Preferences',
    component: (props) => <Preferences {...props} />,
    disabled: false,
  },
  {
    path: 'integrations',
    name: 'Integrations',
    component: (props) => <Integrations {...props} />,
    disabled: false,
  },
];

export const DEFAULT_PROFILE_SECTION = Object.keys(PROFILE)[0];

export const getProfileSectionPath = (section: string) => {
  return `${Paths.PROFILE}/${section}`;
};

export const getProfileSection = (pathname: string) => {
  return pathname.split(`${Paths.PROFILE}/`)[1]?.split('/')[0] ?? PROFILE[0].path;
};

export const Profile = ({ viewer }: { viewer: ViewerFragmentFragment | undefined }) => {
  return (
    <PageWrapper title={'Profile'}>
      <Helmet>
        <title>{PageMetaData.PROFILE.title}</title>
      </Helmet>
      <NavigationTabs>
        <NavigationTabsList>
          {PROFILE.map((tab) => (
            <NavigationTab
              to={`${Paths.PROFILE}/${tab.path}`}
              key={tab.path}
              disabled={tab.disabled}
            >
              {tab.name}
            </NavigationTab>
          ))}
        </NavigationTabsList>
      </NavigationTabs>
      <Switch>
        <Route
          exact
          path={Paths.PROFILE}
          render={() => <Redirect to={getProfileSectionPath(PROFILE[0].path)} />}
        />
        {PROFILE.map(({ path, component: Component }) => (
          <Route
            path={getProfileSectionPath(path)}
            key={`${path}-link`}
            render={() => <Component viewer={viewer} />}
          />
        ))}
      </Switch>
    </PageWrapper>
  );
};
