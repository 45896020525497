import theme from 'theme';

const EmptyPie = ({
  size = 32,
  color = theme.palette.primary.main,
  style = {},
  className = '',
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      style={style}
      viewBox={`0 0 32 32`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='16' cy='16' r='16' transform='rotate(-90 16 16)' fill={color} />
      <path
        d='M11.5556 24.8891L20.4444 7.11133'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default EmptyPie;
