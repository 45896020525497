import { css, keyframes } from '@emotion/react';
import { colors } from '@mysteryco/design';
import Loading02 from '@mysteryco/design/icons/Loading02';
import { forwardRef, HTMLProps, ReactNode } from 'react';
import Link, { LinkProps } from './Link';

type RoundButtonProps = {
  shape?: 'round';
  color?: 'white' | 'purple' | 'green' | 'black' | 'ghost';
};

type SquareButtonProps = {
  shape: 'square';
  color?: 'white' | 'green';
};

type BaseButtonProps = {
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  loading?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  size?: 'default' | 'compact' | 'icon';
  iconSize?: number;
} & (RoundButtonProps | SquareButtonProps);

/**
 * Button/Link polymorphism is really hard to type correctly, so this
 * private component exists to provide an implementation with relaxed
 * type values, while the public components enforce proper prop types
 * for their respective implementations.
 */
const ButtonBase = forwardRef<
  any,
  BaseButtonProps & {
    implementation: 'button' | 'link';
  }
>(
  (
    {
      implementation,
      shape = 'round',
      color = 'purple',
      children,
      startIcon,
      endIcon,
      loading,
      disabled,
      size,
      iconSize,
      ...props
    },
    ref,
  ) => {
    const Implementation = implementation === 'button' ? 'button' : Link;
    return (
      <Implementation
        css={[
          styles.base,
          shape === 'round' && styles.round,
          shape === 'square' && styles.square,
          shape === 'round' && color === 'white' && styles.roundWhite,
          shape === 'round' && color === 'purple' && styles.roundPurple,
          shape === 'round' && color === 'green' && styles.roundGreen,
          shape === 'round' && color === 'black' && styles.roundBlack,
          shape === 'round' && color === 'ghost' && styles.roundGhost,
          shape === 'square' && color === 'white' && styles.squareWhite,
          shape === 'square' && color === 'green' && styles.squareGreen,
          shape === 'round' && size === 'compact' && styles.roundCompact,
          shape === 'round' && size === 'icon' && styles.roundIcon,
        ]}
        ref={ref}
        disabled={loading || disabled}
        {...(props as any)}
      >
        {startIcon}
        <span css={styles.label}>{children}</span>
        {loading ? (
          <Loading02 size={iconSize || 24} color='currentColor' css={styles.spinner} />
        ) : (
          endIcon
        )}
      </Implementation>
    );
  },
);

export type ButtonProps = Omit<HTMLProps<HTMLButtonElement>, 'ref' | 'size'> &
  BaseButtonProps;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => (
  <ButtonBase implementation='button' type='button' ref={ref} {...props} />
));
Button.displayName = 'Button';

export default Button;

export type LinkButtonProps = LinkProps & BaseButtonProps;

export const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonProps>((props, ref) => (
  <ButtonBase implementation='link' ref={ref} {...props} />
));

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const styles = {
  base: css({
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    fontWeight: 700,
    fontSize: 14,
    boxSizing: 'border-box',
    whiteSpace: 'nowrap',
    transition:
      'border-color 0.2s ease-in-out, background-color 0.2s ease-in-out, color 0.2s ease-in-out',

    // basic color applications. vars are defined in color variants below.
    background: 'var(--bg)',
    color: 'var(--fg)',
    borderColor: 'var(--border)',

    '&:hover': {
      borderColor: 'var(--border-hover, var(--border))',
      background: 'var(--bg-hover, var(--bg))',
      color: 'var(--fg-hover, var(--fg))',
    },
    '&:focus, &:active': {
      borderColor: 'var(--border-focus, var(--border))',
      background: 'var(--bg-focus, var(--bg))',
      color: 'var(--fg-focus, var(--fg))',
    },
    '&:focus': {
      outline: 'none',
    },

    // disabled styles come last to take precedence
    '&:disabled': {
      background: 'var(--bg-disabled, var(--bg))',
      color: 'var(--fg-disabled, var(--fg))',
      cursor: 'default',
      borderColor: 'var(--border-disabled, var(--bg-disabled, var(--bg)))',
    },
  }),
  round: css({
    borderRadius: 84,
    padding: `11px 24px`,
    gap: 12,
    lineHeight: `24px`,
  }),
  square: css({
    borderRadius: 8,
    padding: `14px 16px`,
    gap: 10,
    lineHeight: `16px`,
  }),

  roundCompact: css({
    padding: `7px 16px`,
  }),
  squareCompact: css({
    // TODO
  }),
  roundIcon: css({
    padding: `11px 11px`,
    alignItems: 'center',
    justifyContent: 'center',
  }),
  squareIcon: css({
    // TODO
  }),

  // color variants. missing variables for hover/focus is fine, they will fallback to non-stateful values.
  roundWhite: css({
    '--bg': colors.Glue_Paper,
    '--fg': colors.Glue_Ink00,
    '--border': colors.Glue_Ink30,
    '--bg-disabled': colors.Glue_LavenderLight,
    '--fg-disabled': colors.Glue_Ink10,
    '--border-hover': colors.Glue_BorderDark,
    '--border-focus': colors.Glue_Ink00,
  }),
  roundPurple: css({
    '--bg': colors.Glue_Darkberry10,
    '--fg': colors.Glue_Paper,
    '--border': colors.Glue_Darkberry10,
    '--bg-disabled': colors.Glue_LavenderLight,
    '--fg-disabled': colors.Glue_Ink10,
    '--border-hover': colors.Glue_Darkberry00,
    '--bg-hover': colors.Glue_Darkberry10,
    '--fg-hover': colors.Glue_Paper,
    '--bg-focus': colors.Glue_Darkberry00,
    '--fg-focus': colors.Glue_Paper,
    '--border-focus': colors.Glue_Darkberry00,
  }),
  roundGreen: css({
    '--bg': colors.Glue_Mint00,
    '--fg': colors.Glue_Ink00,
    '--border': colors.Glue_DarkMint10,
    '--bg-disabled': colors.Glue_LavenderLight,
    '--fg-disabled': colors.Glue_Ink10,
    '--border-hover': colors.Glue_Ink00,
    '--bg-hover': colors.Glue_Ink00,
    '--fg-hover': colors.Glue_Paper,
    '--bg-focus': colors.Glue_Ink00,
    '--fg-focus': colors.Glue_Paper,
    '--border-focus': colors.Glue_DarkMint10,
  }),
  roundBlack: css({
    '--bg': colors.Glue_Ink00,
    '--fg': colors.Glue_Paper,
    '--border': colors.Glue_Ink00,
    '--bg-disabled': colors.Glue_LavenderLight,
    '--fg-disabled': colors.Glue_Ink10,
    '--border-hover': colors.Glue_DarkMint10,
    '--bg-hover': colors.Glue_Ink00,
    '--fg-hover': colors.Glue_Paper,
    '--bg-focus': colors.Glue_Ink00,
    '--fg-focus': colors.Glue_Paper,
    '--border-focus': colors.Glue_DarkMint10,
  }),
  roundGhost: css({
    '--bg': 'transparent',
    '--fg': colors.Glue_Ink00,
    '--border': 'transparent',
    '--bg-disabled': 'transparent',
    '--fg-disabled': colors.Glue_Ink10,
    '--border-hover': 'transparent',
    '--bg-hover': colors.Glue_Ink30,
    '--fg-hover': colors.Glue_Ink00,
    '--bg-focus': 'transparent',
    '--fg-focus': colors.Glue_Ink00,
    '--border-focus': colors.Glue_Ink00,
  }),
  squareWhite: css({
    '--bg': colors.Glue_Paper,
    '--fg': colors.Glue_Ink00,
    '--border': colors.Glue_Paper,
    '--bg-disabled': colors.Glue_LavenderLight,
    '--fg-disabled': colors.Glue_Ink10,
    '--border-hover': colors.Glue_BorderDark,
    '--border-focus': colors.Glue_Ink00,
  }),
  squareGreen: css({
    '--bg': colors.Mint_40,
    '--fg': colors.Coal_10,
    '--border': colors.Mint_40,
    '--bg-disabled': colors.Glue_LavenderLight,
    '--fg-disabled': colors.Coal_10,
    '--border-hover': colors.Mint_50,
    '--bg-hover': colors.Mint_50,
    '--fg-hover': colors.Coal_10,
    '--bg-focus': colors.Mint_50,
    '--fg-focus': colors.Coal_10,
    '--border-focus': colors.Mint_50,
  }),

  spinner: css({
    '@media (prefers-reduced-motion: no-preference)': {
      animation: `${spin} 2s linear infinite`,
    },
  }),

  label: css({
    display: 'flex',
  }),
};
