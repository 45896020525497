import { Audience, AudienceType } from './types';
import { isTeam, isUser, isUserTag } from './guards';
import { useCallback } from 'react';

export function useRemoveAudience(
  audience: Audience,
  onAudienceChange: (audience: Audience) => void,
) {
  return useCallback(
    (removedAudience: AudienceType) => {
      const clonedAudience: Audience = Object.assign({}, audience);
      if (isUser(removedAudience)) {
        clonedAudience.users =
          audience.users?.filter((user) => user.id !== removedAudience.id) || [];
      }
      if (isTeam(removedAudience)) {
        clonedAudience.teams =
          audience.teams?.filter((user) => user.id !== removedAudience.id) || [];
      }
      if (isUserTag(removedAudience)) {
        clonedAudience.tags =
          audience.tags?.filter((user) => user.id !== removedAudience.id) || [];
      }
      onAudienceChange(clonedAudience);
    },
    [audience, onAudienceChange],
  );
}
