import _ from 'lodash';
import { pluralize } from 'humanize-plus';
import { colors } from '@mysteryco/design';

import { SCORE_TYPE_NAME_MAP } from 'constants/Strings';
import theme from 'theme';
import { ScoreType } from 'types';

import { Dot, SubjectType } from './types';

const MAX_VISIBLE_GROUPS = 8;
const MAX_WIDTH = 500;
// Bah, I fail at getting things to flow and clip properly; this could use some
// love to remove some of the calculation…
const COLUMN_GAP = 32;
const MAX_COLUMN_WIDTH = (MAX_WIDTH - COLUMN_GAP) / 2;

const TOOLTIP_COUNT_LABELS: Record<SubjectType, string> = {
  [SubjectType.Individual]: 'individual',
  [SubjectType.Team]: 'member',
};

export interface Props {
  dot: Dot;
  subjectType: SubjectType;
  scoreType: ScoreType;
}

interface Group {
  name: string;
  count: number;
}

export const BeeSwarmTooltip = ({ dot, subjectType, scoreType }: Props) => {
  const grouped = groupSubjects(subjectType, dot);
  const numHidden = grouped.length - MAX_VISIBLE_GROUPS;

  return (
    <>
      <BeeSwarmTooltipSummary groups={grouped} subjectType={subjectType} />
      <div
        css={{
          // Let the grid collapse if there's only one item.
          display: grouped.length === 1 ? 'block' : 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gridGap: `${theme.spacing(2)} ${COLUMN_GAP}px`,
          maxWidth: MAX_WIDTH,
          paddingBottom: theme.spacing(2),
        }}
      >
        {grouped.slice(0, MAX_VISIBLE_GROUPS).map((s, i) => (
          <BeeSwarmTooltipGroup
            key={i}
            subjectType={subjectType}
            dotColor={dot.color}
            group={s}
          />
        ))}
      </div>
      {numHidden > 0 && (
        <div
          css={{
            color: colors.Purple700,
            textAlign: 'center',
            padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
          }}
        >
          Plus {numHidden} other {pluralize(numHidden, 'team')}
        </div>
      )}
      <div
        style={{
          borderRadius: '12px',
          backgroundColor: colors.Purple100,
          color: colors.Dusk,
          textAlign: 'center',
          padding: `0 ${theme.spacing(2)}`,
          marginTop: theme.spacing(2),
        }}
      >
        {`${_.startCase(dot.strength)} ${
          SCORE_TYPE_NAME_MAP[scoreType]?.toLowerCase() ?? ''
        }`}
      </div>
    </>
  );
};

const BeeSwarmTooltipSummary = ({
  subjectType,
  groups,
}: {
  subjectType: SubjectType;
  groups: Group[];
}) => {
  if (groups.length === 1) return null;

  const totalPeople = groups.reduce((sum, { count }) => sum + count, 0);
  const teamText = `${groups.length} ${pluralize(groups.length, 'team')}`;

  let message: JSX.Element;
  if (subjectType === SubjectType.Individual) {
    message = (
      <>
        Includes {totalPeople} individuals from {teamText}
      </>
    );
  } else {
    message = (
      <>
        Includes {teamText} with {totalPeople} total members
      </>
    );
  }
  return (
    <div
      css={{
        color: colors.Main,
        fontWeight: 600,
        textAlign: 'center',
        padding: theme.spacing(2),
        paddingTop: 0,
      }}
    >
      {message}
    </div>
  );
};

const BeeSwarmTooltipGroup = ({
  dotColor,
  subjectType,
  group,
}: {
  dotColor: string | undefined;
  subjectType: SubjectType;
  group: Group;
}) => {
  return (
    <div>
      <div
        css={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          maxWidth: MAX_COLUMN_WIDTH,
        }}
      >
        <div
          css={{
            display: 'inline-block',
            height: 12,
            width: 12,
            marginRight: 8,
            borderRadius: '50%',
            backgroundColor: dotColor,
          }}
        />
        <span css={{ color: colors.Dusk, fontWeight: 600 }}>{group.name}</span>
      </div>
      <div css={{ paddingLeft: 12 + 8, lineHeight: 1 }}>
        {group.count} {pluralize(group.count, TOOLTIP_COUNT_LABELS[subjectType])}
      </div>
    </div>
  );
};

function groupSubjects(subjectType: SubjectType, { subjects }: Dot): Group[] {
  if (subjectType === SubjectType.Team) {
    return subjects.map(({ team = { name: '', size: 0 } }) => ({
      name: team.name,
      count: team.size ?? 0,
    }));
  }

  const groupsByTeam = {} as { [team: string]: Group };
  for (const subject of subjects) {
    const teamName = subject.team?.name || subject.teamName || '';
    if (!groupsByTeam[teamName]) {
      groupsByTeam[teamName] = { name: teamName, count: 0 };
    }
    groupsByTeam[teamName].count += 1;
  }
  return Object.values(groupsByTeam).sort((a, b) => b.count - a.count);
}
