import { getUpsellLink } from 'utils/stringUtil';

interface Props {
  text: string;
  location?: string;
  className?: string;
  target?: string;
  rel?: string;
  onClick?: any;
}

const UpsellLink = ({ className, location, target, rel, text, onClick }: Props) => {
  const upsellUrl = getUpsellLink(location);

  return (
    <a href={upsellUrl} className={className} target={target} rel={rel} onClick={onClick}>
      {text}
    </a>
  );
};

export default UpsellLink;
