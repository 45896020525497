import styled from '@emotion/styled';

const GlueyHead = styled('div')({
  width: 80,
  height: 80,
  backgroundColor: '#f8ecff',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export default GlueyHead;
