import { useQuery } from '@apollo/client';
import Progress from 'components/Checkout/Progress';
import { renderQuestionnairesStep } from 'components/Checkout/steps/Questionnaire';
import { Loading } from 'components/core';
import LogoWithContainer from 'components/LogoWithContainer';
import WizardConfirmation from 'components/WizardConfirmation';
import { questionFragment } from 'gql/queries';
import gql from 'graphql-tag';
import { useQueryParams } from 'lib/helpers/router';
import _ from 'lodash';
import React, { useState } from 'react';
import { Step, Steps, Wizard } from 'react-albus';
import { useHistory, useParams } from 'react-router-dom';
import { User } from 'types';

export const Feedback = ({ viewer }: { viewer: User }) => {
  const query = useQueryParams();
  const { productId } = useParams<{ productId: string }>();
  const history = useHistory();
  const product = query.get('product');
  const rsvpLink = query.get('rsvpLink');

  const { data, loading } = useQuery(mysteryFeedbackDataQuery, {
    variables: { product, productId },
  });

  const [answers, setAnswers] = useState(null as any);

  const reviewQuestionnaires = _.get(data, 'reviewQuestionnaires', []);

  const questionnaireSteps = React.useMemo(() => {
    if (reviewQuestionnaires) {
      return renderQuestionnairesStep({
        teamEvent: _.get(data, 'teamEvent'),
        viewer,
        questionnaires: reviewQuestionnaires,
        answers,
        setAnswers,
        refetchQueries: ['getViewer'],
      });
    }
  }, [reviewQuestionnaires, answers]);

  if (loading) return <Loading />;

  return (
    <LogoWithContainer>
      <Wizard
        history={history}
        basename={`/feedback/${productId}`}
        render={({ step, steps }) => {
          return (
            <>
              <Progress steps={steps} step={step} />
              <Steps key={step.id} step={step}>
                {questionnaireSteps}
                <Step
                  id='confirmation'
                  render={() => (
                    <WizardConfirmation
                      label='Thanks!'
                      subTitle={
                        'Thanks for sharing your feedback. We use your input to create the best experience for your team.'
                      }
                      nextButtonProps={{
                        label: rsvpLink ? 'Continue your RSVP' : 'View my events',
                        onClick: () =>
                          rsvpLink
                            ? history.replace(rsvpLink)
                            : history.replace(`/events`),
                      }}
                    />
                  )}
                />
              </Steps>
            </>
          );
        }}
      ></Wizard>
    </LogoWithContainer>
  );
};

const mysteryFeedbackDataQuery = gql`
  query mysteryFeedbackData($product: MysteryProduct, $productId: ID!) {
    reviewQuestionnaires(product: $product, productId: $productId) {
      id
      questions {
        ...questionFragment
      }
    }
  }
  ${questionFragment}
`;
