// @ts-strict-ignore
import { gql } from '@apollo/client';
import ActivityQuestions from 'components/Checkout/steps/PreferenceQuestions/ActivityQuestions';
import Question from 'components/Checkout/steps/PreferenceQuestions/RadioOrCheckboxAnswer';
import RegistrationStepWrapper from 'components/Checkout/styling/RegistrationStepWrapper';
import { client } from 'gql/client';

import { preferenceQuestionsFragment } from 'gql/queries';
import { isExemptFromQuestions, shapePreferenceQuestions } from 'lib/helpers/events';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import { Step } from 'react-albus';
import {
  DietaryRestriction,
  PreferenceQuestionsFragmentFragment,
  TeamEventFragmentFragment,
  ViewerFragmentFragment,
} from 'types';

interface RegistrationFlowPreferenceAnswers {
  [questionId: string]: string[];
}
interface PreferenceQuestionStepProps {
  teamEvent?: TeamEventFragmentFragment;
  viewer: ViewerFragmentFragment;
  preferenceQuestions: PreferenceQuestionsFragmentFragment[];
  currentPreferenceAnswers: RegistrationFlowPreferenceAnswers;
  setPreferenceAnswers(answers: RegistrationFlowPreferenceAnswers): void;
  shouldAskDietary: boolean;
  dietaryRestrictionList: DietaryRestriction[];
  currentDietaryRestrictions: string[];
  setCurrentDietaryRestrictions(answers: string[]): void;
}

const updateUsersPreferenceAnswers = async ({ currentPreferenceAnswers, viewer }) => {
  const preferenceAnswers = _.map(
    _.flattenDeep(Object.values(currentPreferenceAnswers)),
    (answerId) => ({ id: answerId }),
  );
  await client.mutate({
    mutation: updatePreferencesAndDietaryRestrictions,
    variables: {
      id: viewer.id,
      preferenceAnswers,
    },
  });
};

export const renderPreferenceQuestionsSteps = ({
  teamEvent,
  viewer,
  preferenceQuestions,
  currentPreferenceAnswers,
  setPreferenceAnswers,
  dietaryRestrictionList,
  shouldAskDietary,
  currentDietaryRestrictions,
  setCurrentDietaryRestrictions,
}: PreferenceQuestionStepProps) => {
  const questions = preferenceQuestions;

  if (!questions) return null;
  if (teamEvent && isExemptFromQuestions(teamEvent)) return [];

  const componentsToRender = [];
  const shapedQuestions = shapePreferenceQuestions({ questions });

  const allActivityQuestionsAnswered = !_.find(shapedQuestions.activity, (question) =>
    _.isEmpty(currentPreferenceAnswers[question.id]),
  );

  if (!_.isEmpty(shapedQuestions.activity)) {
    const activity = (
      <Step
        id={'activityPreferenceQuestions'}
        render={(context) => {
          return (
            <RegistrationStepWrapper
              {...context}
              label={'What activities sound fun to do with your team?'}
              subTitle={`This helps us plan events that everyone will enjoy.`}
              onClickNext={async () => {
                mixpanel.track('activity preferences updated', {
                  source: 'registration',
                  customerType: viewer?.customerType,
                  firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
                });
                updateUsersPreferenceAnswers({ currentPreferenceAnswers, viewer });
              }}
              nextButtonProps={{
                disabled: !allActivityQuestionsAnswered,
              }}
            >
              <ActivityQuestions
                setPreferenceAnswers={setPreferenceAnswers}
                currentPreferenceAnswers={currentPreferenceAnswers}
                questions={shapedQuestions.activity}
              />
            </RegistrationStepWrapper>
          );
        }}
      />
    );
    componentsToRender.push(activity);
  }

  if (!_.isEmpty(shapedQuestions.other)) {
    const singletons = _.map(shapedQuestions.other, (q) => {
      const questionIsAnswered = !_.isEmpty(currentPreferenceAnswers[q.id]);

      return (
        <Step
          id={q.id}
          render={(context) => {
            const onAnswer = ({ answerId, checked }) => {
              let currentValueForQuestion = currentPreferenceAnswers[q.id];
              if (!q.multiSelect)
                setPreferenceAnswers({ ...currentPreferenceAnswers, [q.id]: answerId });
              else {
                if (!checked) {
                  setPreferenceAnswers({
                    ...currentPreferenceAnswers,
                    [q.id]: currentValueForQuestion.filter((a) => a !== answerId),
                  });
                }
                if (checked) {
                  if (currentValueForQuestion) {
                    currentValueForQuestion.push(answerId);
                  } else {
                    currentValueForQuestion = [answerId];
                  }
                  setPreferenceAnswers({
                    ...currentPreferenceAnswers,
                    [q.id]: currentValueForQuestion,
                  });
                }
              }
            };
            return (
              <RegistrationStepWrapper
                {...context}
                label={q.question}
                subTitle={q.subTitle}
                onClickNext={async () => {
                  mixpanel.track('interest survey updated', {
                    source: 'registration',
                    questionsType: 'other',
                    customerType: viewer?.customerType,
                    firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
                  });
                  updateUsersPreferenceAnswers({ currentPreferenceAnswers, viewer });
                }}
                nextButtonProps={{
                  disabled: !questionIsAnswered,
                }}
              >
                <Question
                  currentAnswers={currentPreferenceAnswers[q.id]}
                  multiSelect={q.multiSelect}
                  onAnswer={onAnswer}
                  answers={q.preferenceAnswers}
                />
              </RegistrationStepWrapper>
            );
          }}
        />
      );
    });
    componentsToRender.push(singletons);
  }
  if (shouldAskDietary) {
    const dietaryRestrictions = (
      <Step
        id={'dietaryRestrictions'}
        render={(context) => {
          const onAnswer = ({ answerId, checked }) => {
            if (!checked) {
              setCurrentDietaryRestrictions(
                currentDietaryRestrictions.filter((a) => a !== answerId),
              );
            }
            if (checked) {
              setCurrentDietaryRestrictions([...currentDietaryRestrictions, answerId]);
            }
          };
          return (
            <RegistrationStepWrapper
              {...context}
              label={'Drink or a snack'}
              subTitle={`Say we made you a drink and a snack, do you have any special requests?`}
              onClickNext={async () => {
                await client.mutate({
                  mutation: updatePreferencesAndDietaryRestrictions,
                  variables: {
                    id: viewer.id,
                    dietaryRestrictions: _.map(currentDietaryRestrictions, (id) => ({
                      id,
                    })),
                    dietaryRestrictionsLastAsked: new Date().toISOString(),
                  },
                });
                mixpanel.track('dietary restrictions added', {
                  source: 'registration',
                  questionsType: 'dietary',
                  customerType: viewer?.customerType,
                  firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
                });
              }}
            >
              <Question
                currentAnswers={currentDietaryRestrictions}
                multiSelect
                onAnswer={onAnswer}
                answers={_.map(_.orderBy(dietaryRestrictionList, 'orderIndex'), (d) => ({
                  ...d,
                  label: d.name,
                }))}
              />
            </RegistrationStepWrapper>
          );
        }}
      />
    );
    componentsToRender.push(dietaryRestrictions);
  }
  return _.flatten(componentsToRender);
};

const updatePreferencesAndDietaryRestrictions = gql`
  mutation updatePreferencesAndDietaryRestrictions(
    $id: ID!
    $preferenceAnswers: [GenericReferenceInput]
    $dietaryRestrictions: [GenericReferenceInput]
    $dietaryRestrictionsLastAsked: Date
  ) {
    updateUser(
      id: $id
      preferenceAnswers: $preferenceAnswers
      dietaryRestrictions: $dietaryRestrictions
      dietaryRestrictionsLastAsked: $dietaryRestrictionsLastAsked
    ) {
      id
      unansweredPreferenceQuestions {
        ...preferenceQuestionsFragment
      }
      dietaryRestrictions {
        id
        name
      }
      dietaryRestrictionsLastAsked
    }
  }

  ${preferenceQuestionsFragment}
`;
