import { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import InfoCircle from '@mysteryco/design/icons/InfoCircle';
import { colors } from '@mysteryco/design/src';
import { Tooltip } from 'glue/components/tooltip/Tooltip';
import { ReactNode } from 'react';

export default function HelpTooltip({ content }: { content: ReactNode }) {
  const styles = useStyles();
  const [hovered, setHovered] = useState(false);

  return (
    <Tooltip content={content} side='bottom' sideOffset={-5}>
      <div
        css={styles.infoIconContainer}
        onMouseOver={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <InfoCircle
          size={15}
          dualTone={false}
          color={hovered ? colors.Glue_Darkberry00 : colors.Glue_Ink10}
        />
      </div>
    </Tooltip>
  );
}

const useStyles = makeStyles({
  infoIconContainer: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});
