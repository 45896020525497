// @ts-strict-ignore
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { EN_DASH } from 'constants/Strings';
import { SelectionCard, InfoCard } from './SelectionCards';

const useStyles = makeStyles({
  selectionSection: ({ surpriseToAllView }: any) => ({
    display: 'flex',
    flexDirection: surpriseToAllView ? 'column' : 'column-reverse',
  }),
});

const getStaText = (surpriseToAll: boolean) => ({
  staTitle: surpriseToAll ? `Surprise to all${EN_DASH}Glue pick` : 'Have Glue pick',
  staDescription: `We'll select the best event for your team based on your preferences`,
});

const SelectionSection = ({
  clearSelection,
  description,
  durationMins,
  photoUrl,
  rating,
  showUpsell,
  surpriseToAllView,
  setSurpriseToAllView,
}: {
  clearSelection: () => void;
  description?: string;
  durationMins?: number;
  photoUrl?: string;
  rating?: number;
  showUpsell: boolean;
  surpriseToAllView: boolean;
  setSurpriseToAllView: (val: boolean) => void;
}) => {
  const classes = useStyles({ surpriseToAllView });
  const { staTitle, staDescription } = getStaText(surpriseToAllView);
  const hasSelectedExperience = !surpriseToAllView && durationMins && description;

  return (
    <Box className={classes.selectionSection}>
      <SelectionCard
        title={staTitle}
        content={(<Typography>{staDescription}</Typography>)}
        selected={surpriseToAllView}
        showIcon={surpriseToAllView}
        onClick={() => {
          clearSelection();
          setSurpriseToAllView(true);
        }}
        buttonText={surpriseToAllView ? 'Most popular' : 'Choose this option'}
      />
      {hasSelectedExperience ? (
        <InfoCard
          durationMins={durationMins}
          photoUrl={photoUrl}
          title={description}
          showUpsell={showUpsell}
          rating={rating}
          showEventSelectionScreen={clearSelection}
        />
      ) : (
        <SelectionCard
          title='Or pick from our catalog'
          content={(<Typography>Pick from our catalog of high-quality events</Typography>)}
          selected={!surpriseToAllView}
          onClick={() => setSurpriseToAllView(false)}
          buttonText='Choose event'
        />
      )}
    </Box>
  );
};

export default SelectionSection;
