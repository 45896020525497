import React, { CSSProperties } from 'react';

type Props = {
  size?: number;
  style?: CSSProperties;
  className?: string;
  color?: string;
};

export default function DoubleChevronRight({ size = 12, style, className }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      css={{ fill: 'currentColor' }}
      className={className}
      style={style}
    >
      <path d='M0.292832 9.45972C-0.0976922 9.85025 -0.0976922 10.4834 0.292832 10.8739C0.683356 11.2645 1.31652 11.2645 1.70705 10.8739L0.292832 9.45972ZM5.16661 6.00016L5.87371 6.70727C6.26424 6.31675 6.26424 5.68358 5.87371 5.29306L5.16661 6.00016ZM1.70705 1.12639C1.31652 0.735865 0.683357 0.735865 0.292832 1.12639C-0.0976918 1.51691 -0.0976919 2.15008 0.292832 2.5406L1.70705 1.12639ZM6.12617 9.45972C5.73564 9.85025 5.73564 10.4834 6.12617 10.8739C6.51669 11.2645 7.14985 11.2645 7.54038 10.8739L6.12617 9.45972ZM10.9999 6.00016L11.707 6.70727C12.0976 6.31675 12.0976 5.68358 11.707 5.29306L10.9999 6.00016ZM7.54038 1.12639C7.14985 0.735865 6.51669 0.735865 6.12617 1.12639C5.73564 1.51691 5.73564 2.15008 6.12617 2.5406L7.54038 1.12639ZM1.70705 10.8739L5.87371 6.70727L4.4595 5.29306L0.292832 9.45972L1.70705 10.8739ZM5.87371 5.29306L1.70705 1.12639L0.292832 2.5406L4.4595 6.70727L5.87371 5.29306ZM7.54038 10.8739L11.707 6.70727L10.2928 5.29306L6.12617 9.45972L7.54038 10.8739ZM11.707 5.29306L7.54038 1.12639L6.12617 2.5406L10.2928 6.70727L11.707 5.29306Z' />
    </svg>
  );
}
