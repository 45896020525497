// @ts-strict-ignore
import React from 'react';
import config from '../config';
declare const window: Window &
  typeof globalThis & { hsConversationsOnReady: any; HubSpotConversations: any };

export default function useHubspotChat() {
  const portalId = config.hubspotChat.enabled ? config.hubspotChat.portalId : '';
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const [activeConversation, setActiveConversation] = React.useState(false);
  const eventRef = React.useRef(null);

  React.useEffect(() => {
    if (!portalId) return;

    // Add event listener.
    window.hsConversationsOnReady = [
      () => {
        setHasLoaded(true);
      },
    ];

    const scriptId = 'hubspot-script';
    // Create script component.
    let script = document.createElement('script');
    script.id = scriptId;
    script.src = `//js.hs-scripts.com/${portalId}.js`;
    script.async = true;

    const cleanup = () => {
      const element = document.getElementById(scriptId);
      element && document.body.removeChild(element);
      window.hsConversationsOnReady = [];
      setHasLoaded(false);
    };

    script.onerror = (event) => {
      console.error('[Hubspot chat]', event);
      cleanup();
    };

    document.body.appendChild(script);

    return cleanup;
  }, [portalId]);

  // Subscripe to conversation events.
  React.useEffect(() => {
    eventRef.current = (payload) => {
      setActiveConversation(payload.conversation.conversationId);
    };

    if (hasLoaded) {
      window.HubSpotConversations.on('conversationStarted', eventRef.current);
    }

    return () => {
      window.HubSpotConversations &&
        window.HubSpotConversations.off('conversationStarted', eventRef.current);
    };
  }, [hasLoaded]);

  const openHandler = React.useCallback(() => {
    if (hasLoaded) {
      window.HubSpotConversations.widget.open();
    }
  }, [hasLoaded]);

  const closeHandler = React.useCallback(() => {
    if (hasLoaded) {
      window.HubSpotConversations.widget.close();
    }
  }, [hasLoaded]);

  return {
    hasLoaded,
    activeConversation,
    openHandler,
    closeHandler,
  };
}
