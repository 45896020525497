const Calendar = () => {
  return (
    <svg
      width='160'
      height='126'
      viewBox='0 0 160 126'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M105.991 88.501C105.681 89.5867 105.16 90.6006 104.458 91.4848C103.756 92.369 102.887 93.1061 101.9 93.6539C99.9618 94.7664 97.7704 95.3537 95.6411 95.9779C89.5915 97.7498 83.4754 99.2443 77.2928 100.461C64.5916 102.927 51.7488 104.597 38.8394 105.462C33.027 105.856 27.1273 106.04 21.3066 105.68C18.9272 105.532 16.365 105.213 14.2894 103.951C12.5024 102.864 11.4738 100.961 10.7657 99.0485C9.25558 94.9241 8.8663 90.4205 8.15317 86.1099C7.08265 79.628 6.3712 73.0907 5.85272 66.5417C5.33423 59.9927 4.92314 53.3749 4.90636 46.7823C4.89461 42.2787 4.60768 36.911 7.68167 33.2296C8.50371 32.2682 9.483 31.4535 10.5778 30.82C11.2104 30.4425 10.6349 29.4508 9.99891 29.83C7.92162 31.0835 6.22186 32.8185 5.2302 35.0552C4.29559 37.166 3.97845 39.4715 3.85429 41.7669C3.50863 48.1532 3.90462 54.6099 4.31572 60.9844C4.75534 67.8052 5.3594 74.6194 6.29401 81.3932C6.69169 84.2575 7.20682 87.1099 7.64308 89.9792C7.97867 92.1941 8.31426 94.4124 8.84281 96.5886C9.35961 98.7045 10.0627 100.886 11.3346 102.69C12.6064 104.493 14.4639 105.542 16.5764 106.126C18.9642 106.777 21.4827 106.878 23.9443 106.918C27.0233 106.97 30.1007 107.002 33.173 106.898C40.0357 106.668 46.875 105.965 53.6908 105.175C60.3154 104.408 66.9248 103.497 73.4906 102.331C80.1016 101.156 86.6708 99.718 93.1359 97.8958C97.413 96.691 102.782 95.7262 105.588 91.9407C106.272 90.9929 106.783 89.9318 107.098 88.8064C107.306 88.0983 106.202 87.7996 105.992 88.501H105.991Z'
        fill='#5C296C'
      />
      <path
        d='M107.145 85.7648C104.021 90.0671 24.7583 99.9535 20.0466 94.6126C15.335 89.2717 9.45044 38.0794 13.3584 30.3843C17.2663 22.6893 94.7587 16.2997 99.3294 21.4308C103.9 26.562 110.682 80.9189 107.145 85.7648Z'
        fill='#E8F8E7'
      />
      <path
        d='M12.8148 30.8077C11.6503 34.6535 11.4355 38.6806 11.402 42.6741C11.365 46.8387 11.4836 51.0017 11.7577 55.163C12.306 63.4817 13.4364 71.7518 15.1404 79.9126C15.9274 83.6914 16.7647 87.5187 17.8604 91.222C18.2715 92.613 18.9225 93.9167 20.1222 94.7876C21.2213 95.5863 22.5586 96.0293 23.8775 96.2977C26.9229 96.9102 30.054 96.7021 33.1397 96.7256C34.0944 96.7256 35.0408 96.7726 35.9922 96.7457C42.7224 96.5511 49.4577 95.9068 56.1577 95.2842C65.7387 94.3916 75.3148 93.3244 84.8287 91.8713C89.0504 91.2287 93.2671 90.5122 97.4351 89.5826C100.385 88.9232 103.385 88.205 106.16 86.9835C106.62 86.7856 107.068 86.5615 107.503 86.3123C108.139 85.9398 107.563 84.9481 106.926 85.3223C104.699 86.6278 102.059 87.2788 99.5795 87.9013C95.8041 88.8493 91.9683 89.5591 88.1292 90.1967C78.8384 91.7404 69.4705 92.8311 60.1076 93.7624C52.785 94.4889 45.4424 95.1517 38.0913 95.5158C35.5123 95.6433 32.9451 95.5829 30.3627 95.5947C27.4632 95.6098 24.1359 95.7037 21.5015 94.2959C20.0366 93.5123 19.342 92.2472 18.9074 90.6917C18.4476 89.0457 18.0567 87.3711 17.6439 85.7116C15.6399 77.6383 14.2211 69.4308 13.3987 61.1532C12.9949 57.1128 12.7331 53.0611 12.6134 48.9982C12.4977 45.0316 12.4188 41.0162 12.7812 37.0613C12.957 35.0454 13.3365 33.0525 13.9138 31.113C14.1286 30.4049 13.0229 30.1063 12.8081 30.8077H12.8148Z'
        fill='#5C296C'
      />
      <path
        d='M22.3337 25.0942C21.3689 22.2501 25.1157 18.9546 27.8088 20.0738C29.4347 20.745 31.3157 22.3457 31.8963 24.0455C32.601 26.1077 31.6412 28.3762 30.8258 30.2539C30.5355 30.925 31.5221 31.5073 31.8141 30.8311C32.8208 28.5138 33.893 25.8191 32.846 23.3256C32.0238 21.3708 29.8257 19.4966 27.8122 18.8489C24.3707 17.7364 20.0399 21.8927 21.2296 25.3929C21.4645 26.0858 22.572 25.7889 22.3337 25.0875V25.0942Z'
        fill='#5C296C'
      />
      <path
        d='M51.1738 19.6205C50.9423 18.1741 51.5094 16.6002 52.9021 15.971C54.6472 15.179 56.6993 16.0784 58.0014 17.2949C58.9621 18.1743 59.6562 19.3061 60.0045 20.5612C60.3528 21.8162 60.3413 23.1438 59.9713 24.3926C59.8035 24.9933 59.1205 27.0454 58.2598 26.9313C57.5315 26.834 57.5382 27.98 58.2598 28.0757C59.4343 28.2334 60.1726 26.8592 60.5938 25.9598C61.1154 24.8577 61.3908 23.6553 61.4009 22.4361C61.3972 21.2778 61.1476 20.1335 60.6685 19.0788C60.1895 18.0242 59.492 17.0833 58.6222 16.3183C56.9443 14.8501 54.4559 13.9558 52.3232 14.9861C50.4473 15.8938 49.7576 17.9761 50.068 19.9293C50.1838 20.6558 51.2879 20.3471 51.1738 19.6256V19.6205Z'
        fill='#5C296C'
      />
      <path
        d='M77.6854 18.4579C78.0999 16.6323 78.8231 14.7161 80.8232 14.2815C82.3158 13.986 83.865 14.2532 85.1724 15.0315C86.7866 16.0249 87.6692 18.035 87.644 19.8875C87.6172 21.9732 86.4695 23.9145 84.8234 25.1478C84.2395 25.5841 84.81 26.5791 85.4006 26.1378C87.3185 24.7049 88.6575 22.5672 88.7817 20.1375C88.8991 17.8505 87.8286 15.4208 85.8956 14.1355C84.3569 13.1136 82.2142 12.7696 80.4238 13.1975C78.0747 13.7613 77.0763 15.9678 76.5797 18.1542C76.4119 18.8723 77.521 19.1777 77.6854 18.4579Z'
        fill='#5C296C'
      />
      <path
        d='M13.0876 95.059C13.3894 95.9142 13.879 96.6909 14.5204 97.3321C15.1619 97.9732 15.9388 98.4625 16.7942 98.7638C17.4889 99.0139 17.7876 97.9081 17.0979 97.6598C16.4292 97.4185 15.8218 97.0331 15.3186 96.5309C14.8155 96.0287 14.4291 95.4219 14.1867 94.7536C13.9384 94.0656 12.8309 94.3626 13.0826 95.059H13.0876Z'
        fill='#5C296C'
      />
      <path
        d='M29.7347 30.1582C29.0635 30.9887 29.2162 32.1851 30.238 32.6482C31.1122 33.0442 32.1425 32.6314 32.3774 31.6683C32.6039 30.7337 31.9864 29.695 30.9646 29.6984C30.716 29.6942 30.4738 29.7766 30.2793 29.9315C30.0849 30.0865 29.9505 30.3042 29.8991 30.5474C29.8102 31.0407 30.0954 31.6146 30.624 31.7035C30.7702 31.7393 30.9246 31.7179 31.0556 31.6437C31.1866 31.5695 31.2843 31.448 31.3287 31.3042C31.4042 31.029 31.2364 30.6498 30.9293 30.5994C30.7448 30.5676 30.9881 30.6129 31.0149 30.6901C31.0317 30.7387 30.9763 30.8746 30.9512 30.8579C31.0049 30.9082 31.119 30.873 31.1928 30.9636C31.25 31.0482 31.2823 31.1472 31.2858 31.2493C31.2894 31.3514 31.2641 31.4524 31.2129 31.5408C30.9293 31.9468 30.1911 31.4166 30.5417 30.977C30.6447 30.8672 30.702 30.7223 30.702 30.5718C30.702 30.4212 30.6447 30.2763 30.5417 30.1665C30.3404 29.9669 29.9259 29.9249 29.7313 30.1665L29.7347 30.1582Z'
        fill='#5C296C'
      />
      <path
        d='M57.6897 27.247C57.0185 28.0776 57.1712 29.274 58.1931 29.7371C59.0673 30.1331 60.0975 29.7203 60.3324 28.7572C60.5589 27.8226 59.9415 26.7839 58.9196 26.7873C58.671 26.7831 58.4288 26.8655 58.2344 27.0204C58.0399 27.1753 57.9055 27.3931 57.8541 27.6363C57.7652 28.1296 58.0504 28.7035 58.579 28.7924C58.7254 28.8281 58.88 28.8065 59.011 28.7319C59.1421 28.6574 59.2396 28.5355 59.2837 28.3914C59.3592 28.1179 59.1914 27.7387 58.8844 27.6883C58.6998 27.6564 58.9431 27.7017 58.9699 27.7772C58.9867 27.8276 58.933 27.9635 58.9062 27.945C58.9599 27.9954 59.074 27.9601 59.1478 28.0508C59.205 28.1354 59.2373 28.2344 59.2409 28.3365C59.2444 28.4385 59.2191 28.5396 59.1679 28.628C58.8844 29.034 58.1461 28.5038 58.4968 28.0642C58.5997 27.9544 58.6571 27.8095 58.6571 27.6589C58.6571 27.5084 58.5997 27.3635 58.4968 27.2537C58.2954 27.0541 57.881 27.0121 57.6863 27.2537L57.6897 27.247Z'
        fill='#5C296C'
      />
      <path
        d='M83.9723 25.5185C83.3011 26.3491 83.4538 27.5455 84.4756 28.0086C85.3499 28.4046 86.3801 27.9918 86.615 27.0286C86.8415 26.094 86.2241 25.0554 85.2022 25.0587C84.9536 25.0545 84.7114 25.137 84.5169 25.2919C84.3225 25.4468 84.1881 25.6646 84.1367 25.9078C84.0494 26.4011 84.333 26.975 84.8632 27.0639C85.0095 27.0993 85.1637 27.0777 85.2946 27.0035C85.4254 26.9293 85.5232 26.8082 85.568 26.6645C85.6418 26.3894 85.474 26.0101 85.167 25.9598C84.9824 25.9279 85.2257 25.9732 85.2525 26.0487C85.2794 26.1242 85.2156 26.235 85.1888 26.2165C85.2425 26.2669 85.3566 26.2316 85.4321 26.3222C85.4889 26.407 85.521 26.506 85.5245 26.608C85.5281 26.71 85.503 26.8109 85.4522 26.8994C85.1669 27.3055 84.4287 26.7753 84.781 26.3357C84.884 26.2259 84.9413 26.081 84.9413 25.9304C84.9413 25.7799 84.884 25.635 84.781 25.5252C84.5797 25.3255 84.1652 25.2836 83.9706 25.5252L83.9723 25.5185Z'
        fill='#5C296C'
      />
      <path
        d='M38.5608 49.3738C36.8375 51.0853 29.7281 51.7229 27.9142 50.4829C26.1004 49.2429 25.545 42.8601 26.8387 40.1451C28.1323 37.4302 35.7015 37.0577 37.4835 39.0377C39.2655 41.0177 40.2857 47.6606 38.5608 49.3738Z'
        fill='#CAA2CD'
      />
      <path
        d='M63.9394 46.7289C62.2145 48.4387 55.1067 49.078 53.2929 47.838C51.479 46.598 50.9236 40.2168 52.2156 37.4952C53.5076 34.7736 61.0802 34.4078 62.8622 36.3861C64.6441 38.3644 65.6643 45.0107 63.9394 46.7289Z'
        fill='#CAA2CD'
      />
      <path
        d='M89.3183 44.0863C87.5934 45.7961 80.4856 46.4354 78.6718 45.1938C76.8579 43.9521 76.3025 37.5742 77.5945 34.8526C78.8865 32.131 86.4591 31.7652 88.2411 33.7435C90.0231 35.7218 91.0365 42.3748 89.3183 44.0863Z'
        fill='#CAA2CD'
      />
      <path
        d='M40.487 67.8627C38.7621 69.5725 31.6543 70.2118 29.8405 68.9701C28.0266 67.7285 27.4712 61.3506 28.7632 58.629C30.0552 55.9074 37.6278 55.5416 39.4098 57.5199C41.1918 59.4982 42.2086 66.1529 40.487 67.8627Z'
        fill='#CAA2CD'
      />
      <path
        d='M65.8682 65.2182C64.1433 66.9297 57.0355 67.5673 55.2216 66.3273C53.4078 65.0873 52.8524 58.7078 54.1444 55.9861C55.4364 53.2645 63.0157 52.897 64.7893 54.877C66.5628 56.857 67.5897 63.5084 65.8682 65.2182Z'
        fill='#92E18D'
      />
      <path
        d='M91.243 62.5758C89.5197 64.2873 82.412 64.9249 80.5981 63.6849C78.7843 62.4449 78.2272 56.0654 79.5209 53.3421C80.8146 50.6188 88.3838 50.2547 90.1674 52.2346C91.9511 54.2146 92.9679 60.866 91.243 62.5758Z'
        fill='#CAA2CD'
      />
      <path
        d='M42.4116 86.352C40.6884 88.0635 33.5806 88.7011 31.7667 87.4611C29.9529 86.2211 29.3958 79.8415 30.6895 77.1199C31.9832 74.3983 39.5524 74.0308 41.3361 76.0108C43.1197 77.9908 44.1365 84.6421 42.4116 86.352Z'
        fill='#CAA2CD'
      />
      <path
        d='M67.7905 83.71C66.0673 85.4215 58.9578 86.0591 57.1456 84.8191C55.3335 83.5791 54.7747 77.1979 56.0684 74.4763C57.3621 71.7547 64.9313 71.3889 66.7066 73.3689C68.4818 75.3488 69.5154 81.9985 67.7905 83.71Z'
        fill='#CAA2CD'
      />
      <path
        d='M93.1695 81.0668C91.4462 82.7766 84.3368 83.4159 82.5229 82.1759C80.7091 80.9359 80.1537 74.5547 81.4457 71.8331C82.7377 69.1115 90.3102 68.7457 92.0922 70.724C93.8742 72.7023 94.8961 79.3553 93.1695 81.0668Z'
        fill='#CAA2CD'
      />
      <path
        d='M58.6809 63.7667L62.4898 58.0986C62.9026 57.4862 61.9109 56.9123 61.5015 57.5214L57.6925 63.1878C57.2798 63.8003 58.2714 64.3741 58.6809 63.765V63.7667Z'
        fill='white'
      />
      <path
        d='M57.0733 58.2897C58.9179 59.9464 60.8095 61.5466 62.7481 63.0903C63.3186 63.545 64.1358 62.7396 63.5585 62.2815C61.6188 60.7367 59.7272 59.1359 57.8838 57.4792C57.3367 56.9876 56.5229 57.7947 57.0733 58.2897Z'
        fill='white'
      />
      <path
        d='M160 56.6979C160 56.6979 135.633 31.2519 123.181 28.3004C112.251 25.7835 105.623 24.8069 103.831 31.2569C102.039 37.7069 114.905 40.3581 114.905 40.3581C114.905 40.3581 102.393 48.9944 103.646 67.091C105.35 76.3197 133.747 97.2806 133.747 97.2806L160 56.6979Z'
        fill='#CAA2CD'
      />
      <path
        d='M104.011 46.0381C104.011 46.0381 98.3665 47.2026 99.3514 54.0117C100.336 60.8208 109.565 60.0137 110.55 54.7281C111.535 49.4426 106.877 46.2176 104.011 46.0381Z'
        fill='#CAA2CD'
      />
      <path
        d='M115.58 40.3586C115.58 40.3586 105.623 32.8699 102.307 39.1404C98.9916 45.4108 105.891 51.7719 110.997 49.354C116.103 46.9361 116.848 42.1137 115.58 40.3586Z'
        fill='#CAA2CD'
      />
      <path
        d='M102.039 56.9679C102.039 56.9679 98.0117 57.5938 98.1795 62.8793C98.3473 68.1648 104.989 70.8529 107.766 68.3443C110.543 65.8358 110.722 59.2063 107.766 57.6844C106.001 56.7782 103.973 56.5244 102.039 56.9679Z'
        fill='#CAA2CD'
      />
      <path
        d='M102.354 47.2423C104.103 49.0473 106.492 50.0908 109.004 50.1469C109.742 50.167 109.741 49.021 109.004 49.0025C106.795 48.9485 104.697 48.0254 103.165 46.4336C102.65 45.9033 101.841 46.7138 102.354 47.2423Z'
        fill='#5C296C'
      />
      <path
        d='M100.502 57.5832C103.15 60.0531 108.573 60.4357 110.456 56.8449C110.791 56.1939 109.808 55.6133 109.466 56.2677C107.939 59.1789 103.435 58.7544 101.313 56.7711C100.774 56.2677 99.9702 57.0765 100.502 57.5815V57.5832Z'
        fill='#5C296C'
      />
      <path
        d='M98.6832 66.4781C99.5222 67.6527 100.725 68.302 102.039 68.8406C103.522 69.4497 105.12 69.8977 106.7 69.391C107.363 69.1764 107.974 68.8279 108.496 68.367C109.018 67.9062 109.44 67.3429 109.735 66.7121C110.03 66.0814 110.192 65.3967 110.211 64.7006C110.231 64.0045 110.106 63.3119 109.847 62.6658C109.575 61.9946 108.466 62.2866 108.741 62.9712C109.635 65.1978 108.55 67.7282 106.162 68.354C104.947 68.6728 103.734 68.2886 102.605 67.8406C101.511 67.4077 100.38 66.8791 99.6732 65.8992C99.2453 65.3069 98.2503 65.8774 98.6832 66.4781Z'
        fill='#5C296C'
      />
      <path
        d='M105.588 55.6474C105.649 55.2286 105.832 54.837 106.115 54.522C106.398 54.207 106.767 53.9827 107.177 53.8772C107.889 53.6876 107.587 52.5818 106.872 52.7731C106.224 52.9504 105.645 53.32 105.211 53.8332C104.778 54.3464 104.51 54.979 104.444 55.6474C104.357 56.3773 105.503 56.3723 105.588 55.6474Z'
        fill='white'
      />
      <path
        d='M104.726 66.0451C104.6 65.3269 105.048 64.6759 105.684 64.389C106.355 64.0853 105.773 63.0986 105.105 63.399C103.971 63.9108 103.413 65.1558 103.622 66.3505C103.747 67.0754 104.851 66.7683 104.726 66.0451Z'
        fill='white'
      />
      <path
        d='M107.457 45.1785C107.409 44.5945 107.526 44.0086 107.793 43.4872C108.129 42.8345 107.145 42.2539 106.803 42.9083C106.432 43.605 106.26 44.3905 106.306 45.1785C106.352 45.9118 107.498 45.9168 107.451 45.1785H107.457Z'
        fill='white'
      />
      <path
        d='M115.32 39.8267C112.348 39.2058 109.214 38.1823 106.682 36.4573C104.027 34.6502 103.457 31.0074 105.593 28.5492C106.074 27.9938 105.267 27.1817 104.782 27.7388C102.601 30.2557 102.552 34.0629 104.932 36.4943C106.152 37.7393 107.877 38.5078 109.462 39.179C111.253 39.9462 113.117 40.5324 115.025 40.9291C115.744 41.0801 116.051 39.976 115.328 39.825L115.32 39.8267Z'
        fill='#5C296C'
      />
      <path
        d='M134.771 38.1419C137.455 33.4722 128.898 22.1696 128.539 21.6377C113.625 28.1448 76.6634 44.9611 76.6634 44.9611L83.5698 60.4854C83.5698 60.4854 106.524 50.9631 121.716 44.0684L134.771 38.1419Z'
        fill='#7B1783'
      />
      <path
        d='M133.37 38.7477C138.471 36.4322 142.417 34.5529 143.86 33.6669C147.541 31.4051 142.271 16.4731 136.954 18.1426C135.73 18.5269 132.37 19.9363 127.918 21.8777C128.277 22.4096 136.053 34.0764 133.37 38.7477Z'
        fill='#B074B5'
      />
      <path
        d='M83.5904 60.4817C82.7967 58.6074 84.8925 57.656 84.0519 55.7666C83.2112 53.8773 81.0668 54.8136 80.2194 52.9141C79.3721 51.0147 81.5098 50.0616 80.6641 48.1555C79.8184 46.2493 77.6774 47.2024 76.8317 45.303C76.7791 45.1882 76.7365 45.0692 76.7041 44.9473L70.4169 50.8201C70.4169 50.8201 68.4134 52.8974 66.509 55.1257C66.9452 56.5687 67.7104 59.5705 69.5997 61.225C72.3868 61.2686 75.0212 61.1595 75.0212 61.1595L83.5904 60.4817Z'
        fill='white'
      />
      <path
        d='M66.5089 55.125C64.6497 57.3063 62.8862 59.6202 63.1815 60.2629C63.502 60.9642 66.6364 61.1807 69.5996 61.2243C67.7136 59.5699 66.9451 56.568 66.5089 55.125Z'
        fill='#5C296C'
      />
      <path
        d='M138.157 18.474C141.089 20.4676 143.171 23.484 143.996 26.9325C144.353 28.4208 144.689 30.384 144.142 31.8639C143.677 33.119 142.489 33.8674 141.338 34.4211C139.335 35.3876 137.22 36.1326 135.199 37.074C132.886 38.1512 130.572 39.2251 128.255 40.2956C123.342 42.5742 118.454 44.9267 113.489 47.103C105.507 50.6099 97.4046 53.8416 89.4042 57.3049C88.2984 57.7831 87.1927 58.263 86.0919 58.753C85.0197 59.2312 84.0062 59.8017 82.8552 60.0718C80.7762 60.5568 78.5966 60.498 76.479 60.6138C72.2221 60.847 68.0138 60.9158 63.8139 60.0517C63.0941 59.904 62.787 61.0081 63.5085 61.1575C67.445 61.9662 71.4116 62.03 75.4101 61.8186C77.2055 61.7229 79.0127 61.6944 80.803 61.5316C81.794 61.4625 82.7755 61.2939 83.7327 61.0283C84.7059 60.7329 85.5986 60.2228 86.5231 59.8101C94.938 56.0397 103.514 52.6369 111.984 48.9991C117.463 46.65 122.842 44.0609 128.25 41.5541C130.711 40.4131 133.172 39.2709 135.633 38.1277C136.472 37.7384 137.311 37.329 138.15 36.9531C139.452 36.381 140.818 35.9581 142.092 35.3188C143.399 34.6644 144.619 33.7382 145.174 32.3422C145.805 30.7515 145.54 28.753 145.206 27.1221C144.386 23.1912 142.064 19.7358 138.734 17.4924C138.12 17.0813 137.546 18.0729 138.157 18.4824V18.474Z'
        fill='#5C296C'
      />
      <path
        d='M76.2288 45.098C76.2154 47.0142 78.2105 47.1266 79.4555 47.9052C80.4925 48.5529 80.0696 49.7795 79.8196 50.7443C79.5981 51.595 79.4186 52.5229 79.781 53.3619C80.3767 54.7328 81.8818 54.872 82.9993 55.6187C83.8383 56.1842 83.6705 56.998 83.3349 57.8319C83.0228 58.6105 82.6805 59.3421 82.7073 60.1978C82.7308 60.9328 83.8819 60.9361 83.8534 60.1978C83.803 58.6306 85.3451 57.2816 84.5665 55.6842C83.9255 54.3653 82.3718 54.2982 81.3113 53.4944C80.3784 52.7863 80.7777 51.6084 81.0277 50.6554C81.2341 49.8751 81.3935 49.0261 81.1368 48.2374C80.8801 47.4488 80.2223 46.9656 79.484 46.6518C78.7457 46.338 77.3631 46.1048 77.3715 45.098C77.3715 44.3614 76.2305 44.3597 76.2255 45.098H76.2288Z'
        fill='#5C296C'
      />
      <path
        d='M126.69 26.5238C115.414 31.3663 104.231 36.4169 93.141 41.6756C89.9742 43.1768 86.8151 44.6959 83.664 46.2329C83.0012 46.555 83.5818 47.5433 84.2412 47.2212C95.2742 41.8518 106.405 36.6826 117.632 31.7136C120.838 30.2974 124.052 28.8992 127.274 27.5188C127.945 27.2285 127.368 26.2402 126.696 26.5288L126.69 26.5238Z'
        fill='white'
      />
      <path
        d='M130.096 34.1798L95.2312 49.7914L85.3314 54.2228C84.6602 54.5232 85.2408 55.5098 85.9086 55.2111L120.771 39.6063L130.671 35.1765C131.342 34.8745 130.76 33.8878 130.092 34.1865L130.096 34.1798Z'
        fill='white'
      />
      <path
        d='M127.448 22.1276C129.131 24.5424 130.542 27.1368 131.653 29.8629C132.722 32.509 133.582 35.3833 133.394 38.266C133.346 39.0027 134.492 38.9993 134.54 38.266C134.735 35.2877 133.869 32.2926 132.757 29.5592C131.618 26.7371 130.169 24.0504 128.436 21.5487C128.02 20.9463 127.027 21.5185 127.448 22.1276Z'
        fill='#5C296C'
      />
      <path
        d='M141.657 28.5825C142.457 30.0926 141.949 31.8058 140.482 32.6599C139.846 33.029 140.422 34.0207 141.061 33.6482C143.074 32.4736 143.736 30.059 142.648 28.0052C142.304 27.3525 141.306 27.9314 141.66 28.5825H141.657Z'
        fill='#5C296C'
      />
      <path
        d='M129.271 51.0518C129.271 51.0518 116.731 36.0913 110.908 41.5563C105.086 47.0214 114.85 60.2805 115.923 61.9836C114.759 63.8662 110.722 76.1387 117.536 83.5737C124.35 91.0086 133.752 97.2808 133.752 97.2808C133.752 97.2808 157.134 64.5827 160 56.6981C151.489 47.7412 142.62 40.125 129.271 51.0518Z'
        fill='#CAA2CD'
      />
      <path
        d='M114.122 71.9433C114.392 69.7459 114.854 67.5763 115.503 65.4597C115.798 64.5016 116.174 63.5737 116.498 62.6307C116.808 61.7532 116.518 61.2498 115.976 60.54C113.627 57.4526 111.446 54.0917 110.439 50.3046C109.588 47.1265 109.311 40.6597 114.193 41.0037C116.206 41.1464 117.988 42.529 119.602 43.6196C121.428 44.8501 123.212 46.1427 124.955 47.4974C125.881 48.2172 126.854 48.9286 127.698 49.7441C128.202 50.2391 128.724 50.9069 129.438 51.1116C130.843 51.5144 132.246 50.0277 133.056 49.0864C133.534 48.5293 132.72 47.7155 132.246 48.2759C131.808 48.8187 131.295 49.2956 130.722 49.6921C129.776 50.2978 129.348 49.7743 128.66 49.088C127.222 47.6534 125.485 46.4436 123.856 45.2288C122.112 43.9351 120.332 42.6766 118.498 41.5155C116.988 40.5557 115.27 39.7167 113.424 39.856C109.322 40.1648 108.444 44.8529 108.86 48.1954C109.333 52.0026 111.098 55.5213 113.223 58.6657C113.857 59.6087 114.696 60.483 115.248 61.4545C115.594 62.0636 115.34 62.4428 115.11 63.0586C114.907 63.6023 114.717 64.1493 114.542 64.6996C113.792 67.0582 113.269 69.4825 112.978 71.9399C112.887 72.6698 114.035 72.6631 114.122 71.9399V71.9433Z'
        fill='#5C296C'
      />
      <path
        d='M112.167 46.0569C112.838 47.609 114.107 48.948 115.877 49.0873C117.369 49.2047 118.81 48.2248 119.065 46.7163C119.188 45.9948 118.085 45.6844 117.961 46.411C117.76 47.6023 116.632 48.1661 115.499 47.8674C114.325 47.557 113.613 46.5368 113.15 45.478C112.858 44.8068 111.87 45.3857 112.162 46.0569H112.167Z'
        fill='white'
      />
      <path
        d='M16.6891 33.1897C17.9458 32.7102 19.2625 32.4059 20.6021 32.2853C21.3303 32.2216 21.337 31.0755 20.6021 31.1393C19.1595 31.2608 17.7405 31.581 16.3854 32.0907C15.7025 32.3491 15.9978 33.4565 16.6891 33.1948V33.1897Z'
        fill='#5C296C'
      />
      <path
        d='M17.0113 36.7119L20.9343 35.5575C21.6407 35.3494 21.3404 34.2437 20.6306 34.4517L16.7042 35.6145C15.9995 35.8226 16.2999 36.9284 17.0096 36.7186L17.0113 36.7119Z'
        fill='#5C296C'
      />
      <path
        d='M17.5263 40.8164L19.57 40.313C20.2865 40.1453 19.9828 39.0345 19.2663 39.209L17.2209 39.7123C16.5061 39.8801 16.8081 40.9926 17.5263 40.8164Z'
        fill='#5C296C'
      />
      <path
        d='M103.558 80.842C102.779 80.5852 101.979 81.0802 101.7 81.8202C101.387 82.6491 101.868 83.4982 102.581 83.9428C103.294 84.3875 104.289 84.4462 104.947 83.9042C105.556 83.4008 105.655 82.4259 105.053 81.8773C104.509 81.3739 103.697 82.1894 104.243 82.6877C104.447 82.8723 104.202 83.0703 104.009 83.1206C103.864 83.1505 103.714 83.1511 103.568 83.1222C103.422 83.0934 103.283 83.0358 103.16 82.9528C102.957 82.8236 102.714 82.5837 102.741 82.3152C102.745 82.2548 102.762 82.1961 102.791 82.1432C102.821 82.0903 102.861 82.0444 102.91 82.009C102.959 81.9736 103.016 81.9495 103.075 81.9383C103.134 81.9271 103.196 81.9292 103.254 81.9444C103.956 82.1759 104.261 81.0702 103.558 80.8403V80.842Z'
        fill='#5C296C'
      />
      <path
        d='M94.931 85.7854L98.0201 85.2551C98.745 85.1293 98.4379 84.0252 97.7164 84.1494L94.6273 84.6813C93.9024 84.8054 94.2095 85.9095 94.931 85.7854Z'
        fill='#5C296C'
      />
      <path
        d='M101.893 9.06273C102.798 6.22772 103.963 3.48261 105.374 0.862633C105.723 0.21327 104.734 -0.365621 104.385 0.285421C102.928 2.99229 101.724 5.82839 100.789 8.75735C100.565 9.46209 101.67 9.76411 101.893 9.06273Z'
        fill='#5C296C'
      />
      <path
        d='M108.036 15.5329L113.353 13.5966C114.041 13.3466 113.744 12.2391 113.048 12.4925L107.732 14.4288C107.044 14.6789 107.341 15.7863 108.036 15.5329Z'
        fill='#5C296C'
      />
      <path
        d='M87.5502 3.37922L88.312 8.70837C88.416 9.43659 89.5201 9.12617 89.416 8.40466L88.6542 3.07384C88.5502 2.34729 87.4478 2.65603 87.5502 3.37922Z'
        fill='#5C296C'
      />
      <path
        d='M1.00014 116.8L4.72853 113.658C5.29232 113.183 4.47851 112.376 3.91808 112.849L0.191373 115.988C-0.374094 116.463 0.439708 117.27 1.00014 116.799V116.8Z'
        fill='#5C296C'
      />
      <path
        d='M10.1838 124.838C10.3989 123.4 10.9134 122.024 11.6939 120.797C12.0899 120.173 11.0983 119.598 10.7056 120.22C9.87019 121.529 9.31646 122.998 9.07971 124.532C8.96729 125.254 10.0714 125.564 10.1838 124.838Z'
        fill='#5C296C'
      />
      <path
        d='M22.7969 119.359C22.8837 120.681 23.3058 121.959 24.0234 123.073C24.4228 123.689 25.4145 123.117 25.0118 122.495C24.3938 121.557 24.0253 120.478 23.9412 119.359C23.8892 118.629 22.7432 118.622 22.7969 119.359Z'
        fill='#5C296C'
      />
      <path
        d='M106.549 103.236C108.577 102.719 110.384 101.564 111.704 99.9401C112.163 99.3729 111.358 98.5574 110.893 99.1313C109.71 100.608 108.079 101.661 106.245 102.13C105.532 102.314 105.834 103.42 106.549 103.236Z'
        fill='#5C296C'
      />
    </svg>
  );
};

export default Calendar;