// Example of a subflow with complex component you don't want to stick directly in index.tsx
import {
  BackButton,
  ContinueButton,
  TaskFlowComponent,
  useTaskFlowActions,
} from 'glue/components/TaskFlowWizard';
import { TaskFlowAssigneeType, TaskFlowStatus, TaskFlowSubType } from 'types';

export type GlobalGoogleCalendarStatus =
  | TaskFlowStatus.UserCalendarConnect
  | TaskFlowStatus.CalendarSyncing
  | TaskFlowStatus.Errored;

type StatusMap = Record<GlobalGoogleCalendarStatus, TaskFlowComponent>;

const ErrorComponent = () => <div>Placeholder Error Component</div>;

const UserCalendarConnectComponent = () => {
  const { update } = useTaskFlowActions();
  const handlePhoneAFriend = async () => {
    await update({
      status: TaskFlowStatus.WaitingForDelegate,
      subFlow: TaskFlowSubType.GlobalGoogleDelegate,
      assignee: {
        type: TaskFlowAssigneeType.External,
        token: 'thebossman@gmail.com',
      },
    });
  };
  return (
    <div>
      <h1>Placeholder Global Calendar connection instructions comopnent</h1>
      <button onClick={handlePhoneAFriend}>Phone a friend?</button>
      <ContinueButton>Continue</ContinueButton>
    </div>
  );
};

const CalendarySyncingComponent = () => (
  <div>
    <h1>Placeholder calendar syncing success comopnent</h1>
    <BackButton>Back</BackButton>
    <ContinueButton>All done</ContinueButton>
  </div>
);

export const globalGoogleStatusMap: StatusMap = {
  [TaskFlowStatus.Errored]: ErrorComponent,
  [TaskFlowStatus.UserCalendarConnect]: UserCalendarConnectComponent,
  [TaskFlowStatus.CalendarSyncing]: CalendarySyncingComponent,
};
