import { Checkbox, CheckboxProps, FormControlLabel } from '@material-ui/core';
import { useField } from 'formik';
import { forwardRef, ReactNode } from 'react';

export type FormikCheckboxFieldProps = CheckboxProps & {
  /** Field name is required. This is used to hook the field up to the parent Formik. */
  name: string;
  label?: ReactNode;
};

export const FormikCheckboxField = forwardRef<
  HTMLDivElement | HTMLButtonElement,
  FormikCheckboxFieldProps
>(({ name, label, ...props }, ref) => {
  const [fieldProps] = useField({ name, type: 'checkbox' });

  if (label) {
    return (
      <FormControlLabel
        ref={ref}
        control={<Checkbox {...props} {...fieldProps} />}
        label={label}
      />
    );
  }

  return <Checkbox ref={ref as any} {...props} {...fieldProps} />;
});

export default FormikCheckboxField;
