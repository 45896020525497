import { useHistory, useParams } from 'react-router-dom';
import { useQueryParams } from 'lib/helpers/router';
import { Typography } from 'antd';
import GlueyFace, { GlueyFaceName } from 'glue/components/glueyFace/GlueyFace';
import GlueyHead from 'glue/components/glueyFace/GlueyHead';
import { getActivitiesEventsPath } from 'glue/scenes/Activities/Events';
import LogoWithContainer from 'components/LogoWithContainer';
import { useToggleUserDidNotAttendMutation } from 'types';
import TextLink from 'glue/components/buttons/TextLink';
import RoundButton from 'glue/components/buttons/Button';
import { css } from '@emotion/react';
import gql from 'graphql-tag';

export const SkipFeedback = () => {
  const query = useQueryParams();
  const product = query.get('product');
  const [skipFeedbackDataQuery] = useToggleUserDidNotAttendMutation();
  const history = useHistory();
  const events = getActivitiesEventsPath();

  const { id: teamEventId } = useParams<{ id: string }>();

  const clickToggleUserDidNotAttend = async () => {
    await skipFeedbackDataQuery({
      variables: {
        teamEventId: teamEventId,
      },
    });
    history.replace(`/feedback/${teamEventId}?product=${product}`);
  };

  return (
    <>
      <LogoWithContainer>
        <div css={styles.boarderContainer}>
          <GlueyHead css={{ width: '120px', height: '120px' }}>
            <GlueyFace face={GlueyFaceName.Wink} size={30} />
          </GlueyHead>
          <div css={styles.headContainer}>
            <Typography css={styles.label}>Okay, got it</Typography>
            <Typography css={styles.subtitle}>
              Thank you for letting us know you didn't attend this Event
            </Typography>
          </div>

          <RoundButton
            color={'green'}
            css={styles.PrimaryButtonWripper}
            onClick={() => history.replace(events)}
          >
            View my events
          </RoundButton>

          <div css={styles.skipBackContainer}>
            <text>Was this an error? you can still &nbsp;</text>
            <TextLink onClick={clickToggleUserDidNotAttend}>give feedback</TextLink>
          </div>
        </div>
      </LogoWithContainer>
    </>
  );
};

SkipFeedback.mutation = gql`
  mutation toggleUserDidNotAttend($teamEventId: ID!) {
    toggleUserDidNotAttend(teamEventId: $teamEventId)
  }
`;

const styles = {
  boarderContainer: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boardRadius: '20px',
    padding: '40px',
    gap: '40px',
  }),
  glueyFaceContainer: css({
    top: '40px',
    left: '220px',
  }),
  PrimaryButtonWripper: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '480px',
  }),
  headContainer: css({
    display: 'flex',
    top: '200px',
    left: '40px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '12px',
  }),
  label: css({
    display: 'flex',
    fontFamily: 'Zilla Slab',
    fontSize: '32px',
    textAlign: 'center',
    alignSelf: 'center',
    lineHeight: '38px',
  }),
  subtitle: css({
    top: '50px',
    fontSize: '16px',
    lineHeight: '160%',
    textAlign: 'center',
  }),
  skipBackContainer: css({
    top: '404px',
    left: '40px',
    boardRedius: '8px',
    gap: '12px',
  }),
};
