import { useField } from 'formik';
import TimezoneSelect from 'react-timezone-select';
import { colors } from '@mysteryco/design';
import PreferencesSection, { sectionStyles } from './PreferencesSection';
import theme from 'theme';

const Location = () => {
  const [, meta, helpers] = useField({ name: 'timezone' });
  const { value: timezone } = meta;
  const { setValue } = helpers;
  return (
    <PreferencesSection
      title={'Location'}
      description={
        'Glue uses your timezone to schedule activities and events within your working hours.'
      }
    >
      <div css={sectionStyles.content}>
        <div css={sectionStyles.label}>Select your timezone</div>
        <TimezoneSelect
          css={{ width: '100%' }}
          value={timezone || ''}
          onChange={(value) => setValue(value.value)}
          styles={{
            option: (styles, { isFocused }) => {
              return {
                ...styles,
                cursor: 'pointer',
                color: colors.Coal_10,
                backgroundColor: isFocused ? colors.Mint_60 : colors.Glue_Paper,
                borderRadius: '8px',
              };
            },
            menu: (styles) => {
              return {
                ...styles,
                padding: `0 ${theme.spacing(2)}`,
              };
            },
          }}
        />
      </div>
    </PreferencesSection>
  );
};

export default Location;
