const IntersectCircles = ({ height, width, color, style = {}, className = '' }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      style={style}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.3337 7.50002C12.3337 10.1694 10.1697 12.3334 7.50033 12.3334V14.3334C11.2743 14.3334 14.3337 11.274 14.3337 7.50002H12.3337ZM7.50033 12.3334C4.83095 12.3334 2.66699 10.1694 2.66699 7.50002H0.666992C0.666992 11.274 3.72638 14.3334 7.50033 14.3334V12.3334ZM2.66699 7.50002C2.66699 4.83064 4.83095 2.66669 7.50033 2.66669V0.666687C3.72638 0.666687 0.666992 3.72607 0.666992 7.50002H2.66699ZM7.50033 2.66669C10.1697 2.66669 12.3337 4.83064 12.3337 7.50002H14.3337C14.3337 3.72607 11.2743 0.666687 7.50033 0.666687V2.66669ZM17.3337 12.5C17.3337 15.1694 15.1697 17.3334 12.5003 17.3334V19.3334C16.2743 19.3334 19.3337 16.274 19.3337 12.5H17.3337ZM12.5003 17.3334C9.83095 17.3334 7.66699 15.1694 7.66699 12.5H5.66699C5.66699 16.274 8.72638 19.3334 12.5003 19.3334V17.3334ZM7.66699 12.5C7.66699 9.83064 9.83095 7.66669 12.5003 7.66669V5.66669C8.72638 5.66669 5.66699 8.72607 5.66699 12.5H7.66699ZM12.5003 7.66669C15.1697 7.66669 17.3337 9.83064 17.3337 12.5H19.3337C19.3337 8.72607 16.2743 5.66669 12.5003 5.66669V7.66669Z'
        fill={color}
      />
    </svg>
  );
};

export default IntersectCircles;
