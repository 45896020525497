import { css } from '@emotion/react';
import { Box, Button, RadioGroup } from '@material-ui/core';
import { colors } from '@mysteryco/design/src';
import RadioButton from 'glue/components/inputs/RadioButton';
import { Popover, PopoverContent, PopoverTrigger } from 'glue/components/popover/Popover';
import { useEffect, useState } from 'react';

type RadioDropdownOptions = { label: string; value: string }[];

type RadioPopoverProps = {
  onChange: (value) => void;
  options: RadioDropdownOptions;
  defaultValue: string;
};
const RadioPopover = ({ onChange, options, defaultValue }: RadioPopoverProps) => {
  const [selected, setSelected] = useState<string>(defaultValue);

  const handleSelection = (e) => {
    const value = e.target.value;
    setSelected(value);
    onChange(value);
  };

  return (
    <div css={styles.popoverLayout}>
      <Box padding={4}>
        <RadioGroup
          name='radio-dropdown-group'
          onChange={handleSelection}
          value={selected}
        >
          {options.map(({ label, value }) => (
            <RadioButton
              value={value}
              label={label}
              key={value}
              css={styles.radioButton}
            />
          ))}
        </RadioGroup>
      </Box>
    </div>
  );
};

type RadioDropdownProps = {
  onChange: (value: string) => void;
  value: string;
  options: RadioDropdownOptions;
  triggerElement?: React.ReactElement;
  handleDropdownToggle?(isOpen: boolean): void;
  footerElement?: React.ReactElement;
};

const RadioDropdown = ({
  onChange,
  value,
  options,
  triggerElement,
  handleDropdownToggle,
  footerElement,
}: RadioDropdownProps) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (handleDropdownToggle) {
      handleDropdownToggle(open);
    }
  }, [open]);

  const handleChange = (value: string) => {
    onChange(value);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>{triggerElement || <Button>Open</Button>}</PopoverTrigger>
      <PopoverContent align='start' css={styles.popoverContent}>
        <RadioPopover onChange={handleChange} options={options} defaultValue={value} />
        {footerElement ? footerElement : null}
      </PopoverContent>
    </Popover>
  );
};

const styles = {
  popoverContent: css({
    padding: 0,
    borderRadius: 8,
  }),
  popoverLayout: css({
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '40vh',
    overflowY: 'auto',
    width: 240,
  }),
  radioButton: css({
    '&:hover': {
      backgroundColor: colors.Glue_LavenderLight,
    },
    padding: '8px 16px',
    margin: 0,
  }),
};

export default RadioDropdown;
