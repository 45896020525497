import _ from 'lodash';

/**
 * Note that all sizes referenced in this file are using the "rem" unit (_not_
 * "px" or "pt" or "%" or anything else).
 */

const rem = (val: number) => `${val}rem`;

export const PRIMARY_FONT_FAMILY = [
  'Avenir',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

export const BASE_SIZE = 1;
export const SCALE_RATIO = 1.2;

export const Sizes = {
  XXSMALL: BASE_SIZE / SCALE_RATIO ** 2,
  XSMALL: BASE_SIZE / SCALE_RATIO,
  SMALL: BASE_SIZE,
  NORMAL: BASE_SIZE * 1.1,
  MEDIUM: BASE_SIZE * SCALE_RATIO,
  LARGE: BASE_SIZE * SCALE_RATIO ** 2,
  XLARGE: BASE_SIZE * SCALE_RATIO ** 3,
  XXLARGE: BASE_SIZE * SCALE_RATIO ** 4,
  XXXLARGE: BASE_SIZE * SCALE_RATIO ** 5,
};

export const FontSizes: {
  [K in keyof typeof Sizes]: { fontFamily: string; fontSize: string };
} = _.mapValues(Sizes, fontSize) as any;

export function fontSize(size: number) {
  return {
    fontFamily: PRIMARY_FONT_FAMILY,
    fontSize: `${rem(size)} !important`,
  };
}

export function fontSizePx(size: number) {
  return {
    fontFamily: PRIMARY_FONT_FAMILY,
    fontSize: `${size}px !important`,
  };
}

export const FontWeights = {
  LIGHT: 300,
  NORMAL: 500,
  BOLD: 700,
};

export const BODY_TEXT = {
  ...FontSizes.MEDIUM,
  color: '#808080',
  margin: 'auto',
};

export enum ConnectionFocusArea {
  Belonging = 'senseOfBelonging',
  Leadership = 'leadershipConnection',
  IntraTeam = 'withinTeamConnection',
  CrossTeam = 'betweenTeamConnection',
}

export const CONNECTION_FOCUS_AREA_MAP = [
  { title: 'Belonging', key: ConnectionFocusArea.Belonging },
  { title: 'Leadership', key: ConnectionFocusArea.Leadership },
  { title: 'Within-team', key: ConnectionFocusArea.IntraTeam },
  { title: 'Cross-team', key: ConnectionFocusArea.CrossTeam },
];

export enum ConnectionStrength {
  Strong = 'Strong',
  Moderate = 'Moderate',
  Poor = 'Low',
  Weak = 'Weak',
}

export const CONNECTION_STRENGTH_MAP = {
  [ConnectionStrength.Strong]: {
    rating: ConnectionStrength.Strong,
    threshold: 0.6,
    color: '#6FCF97',
    textColor: '#144228',
    backgroundColor: '#DBF3E5',
    edgeWidth: 8,
  },
  [ConnectionStrength.Moderate]: {
    rating: ConnectionStrength.Moderate,
    threshold: 0.4,
    color: '#2D9CDB',
    textColor: '#173C51',
    backgroundColor: '#DAF2FA',
    edgeWidth: 4.5,
  },
  [ConnectionStrength.Poor]: {
    rating: ConnectionStrength.Poor,
    threshold: 0.3,
    color: '#EBC349',
    textColor: '#493B0E',
    backgroundColor: '#F9EDC9',
    edgeWidth: 1.5,
  },
  [ConnectionStrength.Weak]: {
    rating: ConnectionStrength.Weak,
    threshold: 0,
    color: '#EB5757',
    textColor: '#592312',
    backgroundColor: '#FDE2DA',
    edgeWidth: 1.5,
  },
};

export const CONNECTION_STRENGTH_ARRAY = [
  CONNECTION_STRENGTH_MAP[ConnectionStrength.Strong],
  CONNECTION_STRENGTH_MAP[ConnectionStrength.Moderate],
  CONNECTION_STRENGTH_MAP[ConnectionStrength.Poor],
  CONNECTION_STRENGTH_MAP[ConnectionStrength.Weak],
];

export enum StepStatus {
  Initial = 'Initial',
  Current = 'Current',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
}
