// @ts-strict-ignore
/**
 * This is a HOC that is used to enforce role permissions. By default this requires the user to be in an org.
 * If orgOptional is passed as true this allows users without an org (and in turn, without a role), to access
 * the resource. This is handy for use in our Self Serve Booking flow where anyone should be able to access it
 * unless they have the `Member` role in an org. This prevents them from booking events and using contract units
 */

import NotAuthorizedError from 'components/core/NotAuthorizedError';
import _ from 'lodash';
import { OrganizationRoleName } from 'types';
import { hasOrg } from 'utils/security';
import { useViewer } from 'utils/state';

// Order matters here. An Admin should be able to access everything Coordinators and Members can.
// Coordinators should be able to access everything members can, etc...
const ROLES = [
  OrganizationRoleName.Admin,
  OrganizationRoleName.Coordinator,
  OrganizationRoleName.Member,
];

const isRolePermissable = ({
  userRole,
  roleRequired,
}: {
  userRole: OrganizationRoleName;
  roleRequired: OrganizationRoleName;
}) => {
  const userRoleIndex = ROLES.indexOf(userRole);
  if (userRoleIndex <= ROLES.indexOf(roleRequired) && userRoleIndex !== -1) return true;
  return false;
};

const RequireRole = ({
  children,
  role,
  orgOptional,
}: {
  children: JSX.Element;
  role: OrganizationRoleName;
  orgOptional?: boolean;
}) => {
  const viewer = useViewer();
  let canView = isRolePermissable({
    userRole: _.get(viewer, 'organizationRole.name'),
    roleRequired: role,
  });
  
  if (orgOptional && !hasOrg(viewer)) canView = true;

  return canView ? children : <NotAuthorizedError />;
};

export default RequireRole;
