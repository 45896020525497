import { gql, useQuery } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import calLineIllustration from 'assets/illustrations/cal-line-illus.png';
import CalendarSyncButtons from 'components/CalendarSyncButtons';
import CalendarDate from 'components/icons/CalendarDate';
import theme from 'theme';

const useStyles = makeStyles({
  mCard: {
    width: '100%',
    maxWidth: theme.spacing(140),
    margin: `${theme.spacing(6)} auto`,
    padding: theme.spacing(6),
    borderRadius: theme.spacing(2),
    border: `1px solid ${theme.palette.primary[100]}`,
    background: theme.palette.common.white,
    boxShadow: `0px 6px 8px -3px ${theme.palette.primary[100]}`,
    '&:hover': {
      border: `1px solid ${theme.palette.primary[200]}`,
      boxShadow: `0px 8px 20px -2px ${theme.palette.primary[100]}`,
    },
  },
  mCardIcon: {
    display: 'inline-flex',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    background: theme.palette.secondary[200],
  },
  mCardLabel: {
    color: theme.palette.primary.main,
    fontSize: '0.8rem',
    fontFamily: `'Roboto Monospace', monospace`,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  mCardTitle: {
    fontSize: theme.spacing(6),
    color: theme.palette.text.primary,
  },
  mCardHelperText: {
    fontSize: '1rem',
    color: theme.palette.grey[900],
  },
});

const CalendarIntegration = () => {
  const classes = useStyles();
  const { data, loading } = useQuery(getServiceAccountIntegrationQuery);
  const org = data?.viewer?.orgs[0];
  const serviceAccounts = org?.nylasServiceAccounts;
  const hasServiceAccounts = serviceAccounts?.length > 0;

  return (
    !loading && (
      <Box className={classes.mCard}>
        <Box mb={2} className={classes.mCardIcon}>
          <CalendarDate color={theme.palette.secondary[600]} height={24} width={24} />
        </Box>
        <Box mb={2}>
          <Typography className={classes.mCardLabel}>Calendar Integration</Typography>
        </Box>
        <Box mb={2}>
          <Typography variant='h4' className={classes.mCardTitle}>
            {hasServiceAccounts
              ? 'Your organization’s calendar has been connected'
              : 'Connect your calendar'}
          </Typography>
        </Box>
        <Box mb={4}>
          <Typography className={classes.mCardHelperText}>
            {hasServiceAccounts
              ? 'Nothing for you to do here other than wait for your next event invite and have fun!'
              : 'Help us better schedule events based on your availability'}
          </Typography>
        </Box>
        {hasServiceAccounts ? (
          <Box display={'flex'} justifyContent='center'>
            <img src={calLineIllustration} alt='Calendar' />
          </Box>
        ) : (
          <>
            <Box mb={4}>
              <Typography className={classes.mCardLabel}>Select an option:</Typography>
            </Box>
            <Box display='flex' flexDirection={'column'}>
              <CalendarSyncButtons />
            </Box>
          </>
        )}
      </Box>
    )
  );
};

const getServiceAccountIntegrationQuery = gql`
  query getServiceAccountIntegrationsInfo {
    viewer {
      id
      orgs {
        id
        nylasServiceAccounts {
          id
          provider
          createdAt
        }
      }
    }
  }
`;

export default CalendarIntegration;
