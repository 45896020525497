import { cx } from '@emotion/css';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@mysteryco/design';
import React from 'react';
import theme from 'theme';

type Props = {
  title: React.ReactNode;
  subtitle?: string;
  anchorId?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  anchorEl?: HTMLButtonElement | null;
  component?: React.ElementType; // For accessibility
  [key: string]: any;
};

const SectionHeader = ({
  title,
  subtitle,
  component = 'h2',
  children,
  ...props
}: Props) => {
  const classes = useStyles();

  const SubtitleElement = () => {
    return <Typography className={cx(classes.subtitle)}>{subtitle}</Typography>;
  };

  return (
    <Box {...props} className={cx(classes.root, props?.className)}>
      <Typography component={component} className={classes.title}>
        {title}
      </Typography>
      {subtitle && <SubtitleElement />}
      {/* TODO: Tooltip link */}
      {children}
    </Box>
  );
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    padding: 0,
    gap: theme.spacing(4),
    alignSelf: 'stretch',
  },
  title: {
    fontWeight: 500,
    fontSize: '1.75rem',
    lineHeight: 1.4,
    color: colors.Glue_Ink00,
  },
  subtitle: {
    fontWeight: 700,
    fontSize: '.875rem',
    lineHeight: 1,
    textAlign: 'right',
    color: colors.Glue_Ink10,
  },
  actionableSubtitle: {
    fontWeight: 700,
    fontSize: '.875rem',
    lineHeight: 1,
    textAlign: 'right',
    color: colors.Glue_Ink10,
  },
});

export default SectionHeader;
