import { forwardRef } from 'react';
import Button, { ButtonProps } from 'glue/components/buttons/Button';
import { useFormikContext } from 'formik';

export type FormikResetButtonProps = ButtonProps & {
  className?: string;
};

export const FormikResetButton = forwardRef<HTMLButtonElement, FormikResetButtonProps>(
  function FormikResetButton({ onClick, disabled: disabledExternal, ...props }, ref) {
    const { resetForm, dirty } = useFormikContext();

    // doesn't make sense to reset if the form is pristine
    const disabled = !dirty || disabledExternal;

    return (
      <Button
        ref={ref}
        {...props}
        disabled={disabled}
        onClick={(ev) => {
          resetForm();
          onClick?.(ev);
        }}
      />
    );
  },
);

export default FormikResetButton;
