// @ts-strict-ignore
import { gql } from '@apollo/client';
import { css } from '@emotion/react';
import { colors, MetricCard } from '@mysteryco/design';
import { SCORE_TYPE_NAME_MAP } from 'constants/Strings';
import { TeamFragment } from 'glue/components/insights/data/interface';
import { getScoreRating, ScoreTypeIndex } from 'glue/components/insights/data/utility';
import SectionHeader from 'glue/components/structure/SectionHeader';
import { Link } from 'react-router-dom';
import theme from 'theme';
import { getScoreDetailsPath } from '../Explore/Connections/ScoreDetails';

type Props = {
  orgId?: string;
  team?: TeamFragment;
  loading?: boolean;
  className?: string;
  [key: string]: any;
};

const TeamOverview = ({ orgId, team, loading = false, ...props }: Props) => {
  const disabled = !orgId || !team || loading;
  return (
    <div css={styles.root} {...props}>
      <SectionHeader title='Connection health' />
      <div css={styles.grid}>
        {ScoreTypeIndex.map((scoreType) => {
          const score = team?.scores?.find((s) => s.scoreType === scoreType);
          const rating = getScoreRating((score as any)?.value as number);
          const metricCard = (
            <MetricCard
              loading={loading}
              headline={SCORE_TYPE_NAME_MAP[scoreType]}
              value={rating}
              css={styles.card}
            />
          );
          return !disabled ? (
            <Link
              to={getScoreDetailsPath(orgId, team.id, scoreType)}
              key={scoreType}
              css={styles.linkCard}
            >
              {metricCard}
            </Link>
          ) : (
            <div key={scoreType}>{metricCard}</div>
          );
        })}
      </div>
    </div>
  );
};

TeamOverview.fragments = gql`
  fragment TeamOverview on Team {
    id
    name
    scores(version: $version) {
      scoreType
      ... on EngagementScore {
        value
        trend
      }
    }
  }
`;

const styles = {
  root: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    gap: theme.spacing(8),
    alignSelf: 'stretch',
  }),
  grid: css({
    display: 'grid',
    alignSelf: 'stretch',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(5),
    padding: 2,
  }),
  card: css({
    alignSelf: 'stretch',
    justifySelf: 'stretch',
    flex: `1 1 280px`,

    '& *': {
      fontFamily: 'inherit',
    },
  }),
  linkCard: css({
    transition: 'box-shadow 0.2s ease-in-out',
    '&:focus-visible, &:hover': {
      boxShadow: `0 0 0 1.5px ${colors.Glue_Ink00}`,
    },
  }),
};

export default TeamOverview;
