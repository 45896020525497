import { PageMetaData, Paths } from 'Routes';
import PageWrapper from 'glue/components/structure/PageWrapper';
import {
  NavigationTab,
  NavigationTabs,
  NavigationTabsList,
} from 'glue/components/tabs/NavigationTabs';
import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch } from 'react-router';
import { ViewerFragmentFragment } from 'types';
import Billing from './Billing';
import Integrations from './Integrations';
import Teams from './Teams';
import Notifications from './Notifications';

export const SETTINGS = [
  {
    path: 'teams',
    name: 'Teams',
    component: (props) => <Teams {...props} />,
    disabled: false,
  },
  {
    path: 'integrations',
    name: 'Integrations',
    component: (props) => <Integrations {...props} />,
    disabled: false,
  },
  {
    path: 'notifications',
    name: 'Notifications',
    component: (props) => <Notifications {...props} />,
    disabled: false,
  },
  {
    path: 'billing',
    name: 'Billing',
    component: (props) => <Billing {...props} />,
    disabled: false,
  },
];

export const getSettingsTypePath = (type: string) => {
  return `${Paths.SETTINGS}/${type}`;
};

export const getSettingsType = (pathname: string) => {
  return pathname.split(`${Paths.SETTINGS}/`)[1]?.split('/')[0] ?? SETTINGS[0].path;
};

const Settings = ({ viewer }: { viewer: ViewerFragmentFragment | undefined }) => {
  return (
    <PageWrapper title={'Settings'}>
      <Helmet>
        <title>{PageMetaData.SETTINGS.title}</title>
      </Helmet>
      <NavigationTabs>
        <NavigationTabsList>
          {SETTINGS.map((tab) => (
            <NavigationTab
              to={`${Paths.SETTINGS}/${tab.path}`}
              key={tab.path}
              disabled={tab.disabled}
            >
              {tab.name}
            </NavigationTab>
          ))}
        </NavigationTabsList>
      </NavigationTabs>
      <Switch>
        <Route
          exact
          path={Paths.SETTINGS}
          render={() => <Redirect to={getSettingsTypePath(SETTINGS[0].path)} />}
        />
        {SETTINGS.map(({ path, component: Component }) => (
          <Route
            path={getSettingsTypePath(path)}
            key={`${path}-link`}
            render={() => <Component viewer={viewer} />}
          />
        ))}
      </Switch>
    </PageWrapper>
  );
};

export default Settings;
