import theme from 'theme';

const InfoCircleFilled = ({
  size = 20,
  color = theme.palette.primary.main,
  style = {},
  className = '',
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      style={style}
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.99992 1.11816C4.93731 1.11816 0.833252 5.22222 0.833252 10.2848C0.833252 15.3474 4.93731 19.4515 9.99992 19.4515C15.0625 19.4515 19.1666 15.3474 19.1666 10.2848C19.1666 5.22222 15.0625 1.11816 9.99992 1.11816ZM9.99992 6.11816C9.53968 6.11816 9.16658 6.49126 9.16658 6.9515C9.16658 7.41173 9.53968 7.78483 9.99992 7.78483H10.0083C10.4685 7.78483 10.8416 7.41173 10.8416 6.9515C10.8416 6.49126 10.4685 6.11816 10.0083 6.11816H9.99992ZM10.8333 10.2848C10.8333 9.82459 10.4602 9.4515 9.99992 9.4515C9.53968 9.4515 9.16658 9.82459 9.16658 10.2848V13.6182C9.16658 14.0784 9.53968 14.4515 9.99992 14.4515C10.4602 14.4515 10.8333 14.0784 10.8333 13.6182V10.2848Z'
        fill={color}
      />
    </svg>
  );
};

export default InfoCircleFilled;
