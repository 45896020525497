import { css } from '@emotion/react';
import { RadioGroup } from '@material-ui/core';
import { Coal_10, Plum_30, Plum_60 } from '@mysteryco/design/src/tokens/colors';
import RadioButtonCard from 'glue/components/inputs/RadioButtonCard';
import { Audience, AudienceType } from 'glue/components/inputs/audience';
import { useQueryParams } from 'lib/helpers/router';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import theme from 'theme';
import { WatercoolerInitiativeByOrgQuery } from 'types';
import { MeetupsState, audienceIsFullOrganization } from '.';
import { Paths } from 'Routes';
import { useHistory } from 'react-router-dom';

type SelectCohortProps = {
  audience: Audience;
  setAudience(audience: Audience): void;
  meetupsState: MeetupsState;
  setMeetupsState: Dispatch<SetStateAction<MeetupsState>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  memberCount: number;
  orgId: string;
  existingInitiative: WatercoolerInitiativeByOrgQuery['watercoolerInitiativeByOrg'];
  setDirty: Dispatch<SetStateAction<boolean>>;
};

type CohortOption = 'everyone' | 'specific' | 'noone';

const stringifyAudience = (audience: Audience) => {
  if (audience.organization) {
    return audience.organization.id;
  }
  return Object.values(audience)
    .flatMap((audienceType) =>
      (audienceType as AudienceType[]).map((audienceUnit) => audienceUnit.id),
    )
    .sort()
    .toString();
};

const SelectCohort = ({
  audience,
  setAudience,
  meetupsState,
  setMeetupsState,
  memberCount,
  orgId,
  existingInitiative,
  setDirty,
}: SelectCohortProps) => {
  const params = useQueryParams();
  const preSelectedCohort = params.get('cohort') as CohortOption;
  const history = useHistory();

  useEffect(() => {
    if (preSelectedCohort) {
      onChangeCohortSelection(preSelectedCohort);
    }
  }, []);

  const fullOrg = audienceIsFullOrganization(audience);
  const hasSomeAudience =
    !!audience.organization || Object.values(audience).some((x) => x?.length);

  const [startingState] = useState<CohortOption>(
    fullOrg || meetupsState === MeetupsState.Creating
      ? 'everyone'
      : hasSomeAudience
      ? 'specific'
      : 'noone',
  );
  const [initialAudienceSelection] = useState(stringifyAudience(audience));

  const [picked, setPicked] = useState<CohortOption>(startingState);

  useEffect(() => {
    if (!initialAudienceSelection) {
      return;
    }

    const { Creating, Editing, Disabling } = MeetupsState;

    switch (meetupsState) {
      case Creating: {
        setDirty(hasSomeAudience);
        break;
      }
      case Editing: {
        setDirty(
          !!existingInitiative?.disabledAt
            ? hasSomeAudience
            : hasSomeAudience && stringifyAudience(audience) !== initialAudienceSelection,
        );
        break;
      }
      case Disabling: {
        setDirty(true);
        break;
      }
    }
  }, [meetupsState, audience]);

  const onChangeCohortSelection = (newValue: CohortOption) => {
    setPicked(newValue);

    const { Creating, Editing, Disabling } = MeetupsState;
    if (newValue === 'noone') {
      if (MeetupsState.Creating === meetupsState || !!existingInitiative?.disabledAt) {
        return history.push(`${Paths.ACTIVITIES}${Paths.MEETUPS}`);
      }
      setMeetupsState(Disabling);
    } else {
      setMeetupsState(existingInitiative ? Editing : Creating);
      // toggling full-org audience
      if (newValue === 'everyone') {
        const newAudience = { ...audience };
        newAudience.organization = { id: orgId } as Audience['organization'];
        setAudience(newAudience);
      } else if (newValue === 'specific') {
        const newAudience = { ...audience };
        delete newAudience.organization;
        setAudience(newAudience);
      }
    }
  };

  return (
    <>
      <div css={styles.header}>Selected cohort</div>
      <div css={styles.description}>Select who should be scheduled for Meetups</div>
      <RadioGroup
        css={styles.flexCol}
        value={picked}
        onChange={(event) => onChangeCohortSelection(event.target.value as CohortOption)}
      >
        <RadioButtonCard
          css={styles.card}
          label='Everyone in my org'
          subText={`${memberCount} members`}
          value='everyone'
        />
        <RadioButtonCard css={styles.card} label='A specific cohort' value='specific' />
        <RadioButtonCard
          css={styles.card}
          label='Nobody'
          subText='Meetups is disabled'
          value='noone'
        />
      </RadioGroup>
    </>
  );
};

const styles = {
  flexCol: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  }),
  header: css({
    color: Coal_10,
    fontWeight: 800,
    fontSize: '15px',
    lineHeight: '15px',
  }),
  description: css({
    color: Coal_10,
    opacity: '0.7',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '160%',
  }),
  card: {
    '&:has(> .Mui-checked)': {
      background: Plum_60,
      border: `solid 1px ${Plum_30}`,
    },
  },
};

export default SelectCohort;
