import { Box } from '@material-ui/core';
import WrongEvent from 'components/Checkout/steps/WrongEvent';
import React from 'react';
import { Step, Steps, Wizard } from 'react-albus';
import { useHistory } from 'react-router-dom';
import { Paths } from 'Routes';
import { TeamEventFragmentFragment } from 'types';

const EventEndedFlow = (props: { teamEvent: TeamEventFragmentFragment }) => {
  const { teamEvent } = props;
  const history = useHistory();
  return (
    <Wizard
      history={history}
      basename={Paths.TEAM_EVENTS}
      render={({ step, steps }) => {
        return (
          <Box>
            <Steps key={step.id} step={step}>
              <Step
                id='ended'
                render={(context) => {
                  return <WrongEvent context={context} teamEvent={teamEvent} />;
                }}
              />
            </Steps>
          </Box>
        );
      }}
    ></Wizard>
  );
};

export default EventEndedFlow;
