// @ts-strict-ignore
import { Loading, NotFoundError } from 'components/core';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import theme, { primaryGradient } from 'theme';
import { ViewerFragmentFragment, Organization as OrganizationType } from 'types';
import { Helmet } from 'react-helmet-async';
import mixpanel from 'mixpanel-browser';

import { gql, useQuery } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/styles';

import TabNav from 'components/TabNav';
import Integrations from './Integrations';
import Pricing from './Pricing';
import { PageMetaData } from 'Routes';
import UpgradeButton from 'components/UpgradeButton';
import { isUpsellable } from 'utils/customerTypeUtils';

type Props = {
  viewer: ViewerFragmentFragment;
  location: { hash: string };
};

export type SettingsTabProps = {
  viewer: ViewerFragmentFragment;
  classes: ClassNameMap<any>;
  history: any;
  orgId?: string;
};

enum TabName {
  Pricing = 'Pricing',
  Integrations = 'Integrations',
}

const TabLookup = {
  [TabName.Pricing]: (props: SettingsTabProps) => <Pricing {...props} />,
  [TabName.Integrations]: (props: SettingsTabProps) => <Integrations {...props} />,
};

const Loader = ({ viewer, location }: Props) => {
  const { data, loading } = useQuery(getOrganizationQuery);

  if (loading || !data) return <Loading />;

  // The array of orgs is to support a user being able to belong to more than a single org.
  // For now, we are assuming that users will only belong to one organization.
  const organization = _.first(_.get(data, 'viewer.orgs')) as OrganizationType;
  if (!organization) {
    return (
      <NotFoundError
        title='No Organization Found'
        subtitle='Unable to show organization settings as you are not a member of any organization'
      />
    );
  }

  return <Settings organization={organization} viewer={viewer} location={location} />;
};

const Settings = ({
  viewer,
  organization,
  location,
}: {
  viewer: ViewerFragmentFragment;
  organization: OrganizationType;
  location: { hash: string };
}) => {
  const history = useHistory();
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(
    location.hash ? location.hash.substring(1) : TabName.Pricing.toString(),
  );

  const tabProps: SettingsTabProps = {
    viewer,
    classes,
    history,
    orgId: _.get(organization, 'id'),
  };

  const canUpsell = isUpsellable(viewer?.customerType);

  useEffect(() => {
    mixpanel.track('setting loaded', {
      source: 'insights',
      customerType: viewer?.customerType,
      firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
    });
  });
  return (
    <Box className={classes.root}>
      <Helmet>
        <title>{PageMetaData.SETTINGS_MYSTERY.title}</title>
      </Helmet>
      <Box mb={4} className={classes.pageHeader}>
        <Typography variant={'h3'}>Settings</Typography>
        {canUpsell && <UpgradeButton page='settings' />}
      </Box>
      <Box className={classes.pageTabs}>
        <TabNav
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabs={Object.values(TabName)}
        />
      </Box>
      <Box className={classes.mainWrapper}>{TabLookup[selectedTab](tabProps)}</Box>
    </Box>
  );
};

const useStyles = makeStyles({
  root: {
    padding: '2rem',
  },
  pageHeader: {
    margin: `0 auto ${theme.spacing(5)}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'calc(100% - 48px)',
    maxWidth: '1600px',
    '& h3': {
      fontWeight: 300,
      color: theme.palette.grey[900],
      letterSpacing: '-1px',
    },
  },
  pageTabs: {
    margin: `0 auto ${theme.spacing(4)}`,
    alignItems: 'center',
    width: 'calc(100% - 48px)',
    height: '48px',
    maxWidth: '1600px',
    overflow: 'hidden',
  },
  mainWrapper: {
    height: '100%',
    padding: theme.spacing(6),
    borderTop: `1px solid ${theme.palette.primary[200]}`,
    backgroundColor: theme.palette.primary[50],
  },
  content: {
    marginTop: theme.spacing(10),
  },
  contractRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  bold: {
    fontWeight: 'bold',
  },
  button: {
    marginRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    textDecoration: 'none',
    backgroundImage: primaryGradient,
    color: 'white',
    width: theme.spacing(50),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    borderRadius: theme.spacing(40),
    marginTop: '1rem',
  },
  column: {
    width: '30%',
    minWidth: '175px',
  },
  gray: {
    backgroundColor: '#F8F8F8',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tableContainer: {
    border: '1px solid #DDD0E6',
    borderRadius: '10px',
    padding: '1.5rem',
    marginTop: '2rem',
    minWidth: '550px',
  },
  row: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '4rem',
  },
  pl: {
    paddingLeft: '1rem',
  },
  alignCenter: {
    alignItems: 'center',
  },
  editable: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
  },
  flexCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  navMenu: {
    fontSize: '2rem',
  },
});

const getOrganizationQuery = gql`
  query getOrganization {
    viewer {
      id
      orgs {
        userTags {
          id
          name
          type
          users {
            id
            name
          }
        }
        id
        name
      }
    }
  }
`;

export default Loader;
