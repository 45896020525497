import { Box, Chip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { UserTagType } from 'types';
import theme from 'theme';
import Cube01 from 'components/icons/cube-01';

interface GroupDisplayProps {
  style?;
  tags;
  onDelete;
}

const GroupDisplay = ({ style, tags, onDelete }: GroupDisplayProps) => {
  const classes = useStyles();
  return (
    <Box style={style}>
      {tags
        .filter((userTag) => userTag.type === UserTagType.Automatic)
        .map((tag) => {
          return (
            <Chip
              key={`chip-${tag.name}`}
              className={classes.automatedChip}
              label={tag.name}
              icon={
                <Cube01
                  color={theme.palette.primary.main}
                  width={16}
                  height={16}
                  style={{ marginRight: theme.spacing(1) }}
                />
              }
            />
          );
        })}
      {tags
        .filter((userTag) => userTag.type === UserTagType.Manual)
        .map((tag) => {
          return (
            <Chip
              key={`chip-${tag.name}`}
              className={classes.chip}
              label={tag.name}
              deleteIcon={
                <Box>
                  <Typography className={classes.removeLabel}>× Remove</Typography>
                </Box>
              }
              onDelete={() => onDelete(tag)}
            />
          );
        })}
    </Box>
  );
};

const useStyles = makeStyles({
  removeLabel: {
    textDecoration: 'underline',
    fontSize: '14px',
    fontWieght: '400',
    lineHeight: '20px',
  },
  chip: {
    width: '100%',
    display: 'flex',
    justfiyContent: 'flex-end',
    background: theme.palette.primary[100],
    borderRadius: '2px',
    color: theme.palette.primary.main,
    fontWeight: 500,
    '& .MuiChip-label': {
      flex: 1,
    },
    '& .MuiChip-deleteIcon': {
      color: theme.palette.primary.main,
      width: 'auto',
      paddingRight: '7px',
    },
    marginBottom: '4px',
  },
  automatedChip: {
    '& .MuiChip-label': {
      paddingLeft: '6px',
      flex: 1,
    },
    paddingLeft: '10px',
    width: '100%',
    display: 'flex',
    justfiyContent: 'flex-end',
    color: theme.palette.primary.main,
    background: theme.palette.primary.light,
    borderRadius: '2px',
    fontWeight: 500,
    marginBottom: '4px',
  },
});

export default GroupDisplay;
