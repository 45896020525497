import {
  FormControl,
  FormHelperText,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@mysteryco/design';
import cx from 'classnames';
import { ReactNode } from 'react';

export interface LabeledInputProps {
  label?: ReactNode;
  helperText?: ReactNode;
  error?: ReactNode;
  id?: string;
  onChange?: (event: any) => void;
  fullWidth?: boolean;
  labelProps?: FormLabelProps;
  errorProps?: FormHelperTextProps;
  helperTextProps?: FormHelperTextProps;
  hiddenLabel?: boolean;
  children: ReactNode;
  [key: string]: any;
}

const LabeledInput = ({
  label,
  helperText,
  error,
  id,
  onChange = () => {},
  fullWidth = false,
  labelProps,
  errorProps,
  helperTextProps,
  hiddenLabel = false,
  children,
  ...props
}: LabeledInputProps) => {
  const classes = useLabeledInputStyles();
  const showLabel = !!label || hiddenLabel;
  return (
    <FormControl
      {...props}
      className={cx(classes.root, props?.className)}
      fullWidth={fullWidth}
    >
      {showLabel && (
        <FormLabel
          htmlFor={id}
          {...labelProps}
          className={cx(classes.label, labelProps?.className)}
        >
          {label || <span className={classes.hiddenLabel}></span>}
        </FormLabel>
      )}
      {children}
      {!!error && (
        <FormHelperText
          id={`${id}-error`}
          {...errorProps}
          className={cx(classes.errorText, errorProps?.className)}
        >
          {error}
        </FormHelperText>
      )}
      {!!helperText && (
        <FormHelperText
          id={`${id}-helper-text`}
          {...helperTextProps}
          className={cx(classes.helperText, helperTextProps?.className)}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

const useLabeledInputStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
  },
  label: {
    fontWeight: 500,
    fontSize: '.75rem',
    lineHeight: 1.33,
    color: '#4F4F4F',
    '&.Mui-focused': {
      color: '#4F4F4F',
    },
  },
  errorText: {
    fontSize: '.75rem',
    lineHeight: 1.67,
    color: colors.Orange800,
    margin: 0,
  },
  helperText: {
    fontSize: '.75rem',
    lineHeight: 1.67,
    color: colors.Purple600,
    margin: 0,
  },
  hiddenLabel: {
    display: 'inline-block',
    height: '1em',
  },
}));

export default LabeledInput;
