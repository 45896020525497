// @ts-strict-ignore
import { useQuery } from '@apollo/client';
import { Box, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import AlertHexagon from '@mysteryco/design/icons/AlertHexagon';
import { useDebounce } from 'ahooks';
import AlertBar from 'components/AlertBar';
import FlatButton from 'components/core/FlatButton';
import SearchBar from 'components/Search/SearchBar';
import SelectFilter from 'components/Search/SelectFilter';
import SelectTagFilter from 'components/Search/SelectTagFilter';
import SelectTeamFilter from 'components/Search/SelectTeamFilter';
import config from 'config';
import { getOrganizationRoles, getOrgMembers, getPendingOrgMembers } from 'gql/queries';
import humanize from 'humanize-plus';
import { useQueryParams } from 'lib/helpers/router';
import { useState } from 'react';
import { useFinchConnect } from 'react-finch-connect';
import { useHistory } from 'react-router';
import theme from 'theme';
import {
  AccountStatus,
  MemberFragmentFragment,
  OrderDirection,
  Team as TeamType,
  useGetOrgUserInfoQuery,
  UsersOrderKey,
  UserTag,
  useSendAccountSetupReminderEmailsMutation,
  ViewerFragmentFragment,
} from 'types';
import { isContract } from 'utils/customerTypeUtils';
import { getTeamsPath, TeamPageOrganization } from '.';
import SelectHeader from '../components/SelectHeader';
import { getPendingPath } from '../Integrations';
import BulkEditTeamMembers from './BulkEditTeamMembers';
import BulkEditToolbar from './BulkEditToolbar';
import EditTeamMember from './EditTeamMember';
import AddTeamMembersModal from './Modals/AddTeamMembersModal';
import EditTeamName from './Modals/EditTeamName';
import { hasUnselectedMembers, TeamTable } from './TeamTable';

export type TeamTabProps = {
  organization: TeamPageOrganization;
  viewer: ViewerFragmentFragment;
};

export enum TeamMemberStatus {
  Active = 'Active',
  Pending = 'Pending',
  Archived = 'Archived',
}

const getStatus = (params: URLSearchParams): TeamMemberStatus => {
  const status = params.get('status');
  return status in TeamMemberStatus ? TeamMemberStatus[status] : TeamMemberStatus.Active;
};

const filterAccountStatus = (currentStatus: TeamMemberStatus): AccountStatus[] => {
  switch (currentStatus) {
    case TeamMemberStatus.Active:
      return [AccountStatus.Active];
    case TeamMemberStatus.Pending:
      return [AccountStatus.NeedsAction, AccountStatus.Invited, AccountStatus.Pending];
    default:
      return [];
  }
};

const canInvite = (member: MemberFragmentFragment): boolean => {
  return [AccountStatus.Invited, AccountStatus.Pending].includes(member?.accountStatus);
};

const Header = ({
  selectedStatus,
  onChange,
  count = 0,
}: {
  selectedStatus: TeamMemberStatus;
  onChange: () => void;
  count?: number;
}) => {
  const history = useHistory();

  const statusFilterOptions = Object.values(TeamMemberStatus).map((name) => {
    return { id: name };
  });

  const handleChange = (newValue: any) => {
    if (!newValue) return;
    onChange();
    history?.push({
      pathname: getTeamsPath(),
      search: `?${new URLSearchParams({
        status: TeamMemberStatus[newValue],
      }).toString()}`,
    });
  };

  return (
    <SelectHeader
      options={statusFilterOptions}
      value={selectedStatus}
      label={`${selectedStatus.toString()} members`}
      pill={count}
      onChange={handleChange}
      renderOption={(option) => <div>{option.id} members</div>}
    />
  );
};

const HRAlert = ({ issueCount }) => {
  const history = useHistory();
  const alertText = `There ${humanize.pluralize(
    issueCount,
    `is ${issueCount} member with an issue that needs`,
    `are ${issueCount} members with issues that need`,
  )} your attention`;
  return (
    <Box width='100%'>
      <AlertBar
        text={alertText}
        icon={<AlertHexagon size={20} />}
        onClick={() => {
          history.push(getPendingPath());
        }}
        linkText='Correct issues'
        severity='error'
        pillText='Issues'
      />
    </Box>
  );
};

const Team = ({ viewer, organization }: TeamTabProps) => {
  const classes = useStyle();
  const params = useQueryParams();

  const selectedStatus = getStatus(params);

  const [addTeamMemberShowing, setAddTeamMemberShowing] = useState(false);
  const [editTeamMemberShowing, setEditTeamMemberShowing] = useState(false);
  const [bulkEditTeamMembersShowing, setBulkEditTeamMembersShowing] = useState(false);
  const [editTeamNameShowing, setEditTeamNameShowing] = useState(false);

  const [selectedMember, setSelectedMember] = useState<MemberFragmentFragment>(null);
  const [selectedMembers, setSelectedMembers] = useState(
    new Set<MemberFragmentFragment>(),
  );
  const [selectedTeam, setSelectedTeam] = useState<TeamType>(null);

  const [searchString, setSearchString] = useState('');
  const [inviteSent, setInviteSent] = useState(false);
  const [filterRoleIds, setFilterRoleIds] = useState<string[]>([]);
  const [filterTagIds, setFilterTagIds] = useState<string[]>();
  const [filterTeamIds, setFilterTeamIds] = useState<string[]>();

  const debouncedSearchString = useDebounce(searchString, { wait: 500 });

  const [sendReminders, { loading: remindersLoading }] =
    useSendAccountSetupReminderEmailsMutation();

  const isArchivedPage = selectedStatus === TeamMemberStatus.Archived;

  const { data: filteredData, loading: filteredLoading } = useQuery(getOrgMembers, {
    variables: {
      query: debouncedSearchString.trim(),
      orgId: organization?.id,
      sort: [{ key: UsersOrderKey.FirstName, direction: OrderDirection.Asc }],
      userTags: filterTagIds,
      accountStatuses: filterAccountStatus(selectedStatus),
      orgRoles: filterRoleIds,
      teams: filterTeamIds,
      showArchived: isArchivedPage,
      includeManager: true,
    },
  });

  let filteredMembers = [];
  if (filteredData && filteredData.orgUsersConnection.edges) {
    filteredMembers = filteredData.orgUsersConnection.edges.map((edge) => edge.node);
  }

  const { data: pendingData } = useQuery(getPendingOrgMembers, {
    variables: {
      id: organization?.id,
    },
  });

  let pendingMembers = [];
  if (pendingData?.getPendingOrgMembers) {
    pendingMembers = pendingData?.getPendingOrgMembers;
  }

  const { data: memberData } = useGetOrgUserInfoQuery({
    variables: { orgId: organization.id },
  });
  let allMembers = [];
  if (memberData?.orgUsersConnection?.edges) {
    allMembers = memberData.orgUsersConnection.edges.map((edge) => edge.node);
  }

  const displayedMembers = filteredMembers.filter((member) => member.email);

  const { data: roleData } = useQuery(getOrganizationRoles);
  let orgRoles = [];
  if (roleData?.roles) {
    orgRoles = roleData.roles;
  }

  const teams = organization?.teams as TeamType[];
  const userTags = organization?.userTags as UserTag[];

  const onSuccess = (e) => {
    const code = e.code;
    return fetch(`${config.api.url}/exchange?code=${code}`, {
      method: 'post',
      body: JSON.stringify({ orgId: organization?.id, userId: viewer.id }),
    });
  };
  const onError = ({ errorMessage }) => console.error(errorMessage);
  const onClose = () => console.log('User exited Finch Connect');

  const { open: openFinchConnect } = useFinchConnect({
    clientId: config.finch.user,
    products: ['company', 'directory', 'individual', 'employment'],
    sandbox: false,
    onSuccess,
    onError,
    onClose,
  });

  const resetSelectedMembers = () => {
    setSelectedMembers(new Set<MemberFragmentFragment>());
    setSelectedMember(null);
  };

  const toggleSelectedMember = (member: MemberFragmentFragment) => {
    if (selectedMembers.has(member)) {
      selectedMembers.delete(member);
      setSelectedMembers(new Set(selectedMembers));
    } else {
      setSelectedMembers(new Set(selectedMembers.add(member)));
    }
  };

  const toggleSelectedMembers = () => {
    const undisplayedSelectedMembers = [...selectedMembers].filter(
      (member) => !displayedMembers.some((displayed) => displayed.id === member.id),
    );
    if (hasUnselectedMembers(displayedMembers, selectedMembers)) {
      setSelectedMembers(new Set([...undisplayedSelectedMembers, ...displayedMembers]));
    } else {
      setSelectedMembers(new Set(undisplayedSelectedMembers));
    }
  };

  const handleInviteMembers = async () => {
    const emails = [...selectedMembers]
      .filter((member) => canInvite(member))
      .map((member) => member.email);
    await sendReminders({
      variables: {
        orgId: organization?.id,
        emails,
      },
      refetchQueries: ['searchOrgUsers'],
      awaitRefetchQueries: true,
    });
    setInviteSent(true);
    resetSelectedMembers();
  };

  const handleEditMember = () => {
    if (selectedMembers.size === 1) {
      setSelectedMember(selectedMembers.values().next().value);
      setEditTeamMemberShowing(true);
    } else if (selectedMembers.size > 1) {
      setBulkEditTeamMembersShowing(true);
    }
  };

  const handleArchive = () => {
    resetSelectedMembers();
  };

  const isContractCustomer = isContract(organization.customerType);
  const showAlertBox = organization.isHrisConnected && pendingMembers.length > 0;

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.teamManagerActions}>
          <Header
            selectedStatus={selectedStatus}
            count={filteredMembers.length}
            onChange={resetSelectedMembers}
          />
          <Box className={classes.teamManagerTop}>
            <Box className={classes.filters}>
              {isContractCustomer && !!teams?.length && (
                <SelectTeamFilter
                  organization={organization}
                  value={filterTeamIds}
                  onChange={(_, newValue) => setFilterTeamIds(newValue)}
                  disabled={filteredLoading}
                />
              )}
              <SelectFilter
                label='Role type'
                options={orgRoles}
                value={filterRoleIds}
                onChange={(_, newValue) => setFilterRoleIds(newValue)}
                disabled={filteredLoading}
                disableSearch
                disableSelectAll
                disableCreate
                disableUpdate
              />
              {isContractCustomer && !!userTags?.length && (
                <SelectTagFilter
                  userTags={userTags}
                  value={filterTagIds}
                  onChange={(_, newValue) => setFilterTagIds(newValue)}
                  disabled={filteredLoading}
                />
              )}
            </Box>
            <Box className={classes.callToAction}>
              {!organization.isHrisConnected && (
                <FlatButton
                  onClick={() => setAddTeamMemberShowing(true)}
                  corners='rounded'
                >
                  Add members
                </FlatButton>
              )}
            </Box>
          </Box>
          <SearchBar
            query={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            disabled={filteredLoading && !searchString}
            loading={filteredLoading && !!searchString}
          />
          {showAlertBox && <HRAlert issueCount={pendingMembers?.length} />}
        </Box>
        <Box>
          <AddTeamMembersModal
            isShowing={addTeamMemberShowing}
            setIsShowing={setAddTeamMemberShowing}
            organization={organization}
            openHrisConnect={openFinchConnect}
            existingMembers={allMembers}
          />
          <EditTeamMember
            isShowing={editTeamMemberShowing}
            isArchivedPage={isArchivedPage}
            setIsShowing={setEditTeamMemberShowing}
            member={selectedMember}
            organization={organization}
            setSelectedMember={setSelectedMember}
            setSelectedMembers={setSelectedMembers}
          />
          <BulkEditTeamMembers
            isShowing={bulkEditTeamMembersShowing}
            isArchivedPage={isArchivedPage}
            setIsShowing={setBulkEditTeamMembersShowing}
            members={[...selectedMembers]}
            organization={organization}
            setSelectedMembers={setSelectedMembers}
          />
          <EditTeamName
            team={selectedTeam}
            setTeam={setSelectedTeam}
            organization={organization}
            isShowing={editTeamNameShowing}
            setIsShowing={setEditTeamNameShowing}
          />

          <TeamTable
            usersLoading={filteredLoading}
            team={displayedMembers}
            selectedMembers={selectedMembers}
            onSelectMember={toggleSelectedMember}
            onSelectAllMembers={toggleSelectedMembers}
            onClickEdit={(member) => {
              setSelectedMember(member);
              setEditTeamMemberShowing(true);
            }}
            onClickEditTeam={(team) => {
              setSelectedTeam(team);
              setEditTeamNameShowing(true);
            }}
            toolbar={
              <BulkEditToolbar
                selectedMembers={selectedMembers}
                organization={organization}
                showEdit={selectedStatus === TeamMemberStatus.Active}
                showRestore={selectedStatus === TeamMemberStatus.Archived}
                showInvite={selectedStatus === TeamMemberStatus.Pending}
                showArchive={[TeamMemberStatus.Active, TeamMemberStatus.Pending].includes(
                  selectedStatus,
                )}
                onClose={resetSelectedMembers}
                onArchive={handleArchive}
                onInvite={handleInviteMembers}
                onEdit={handleEditMember}
                remindersLoading={remindersLoading}
              />
            }
          />
        </Box>
      </Box>
      <Snackbar
        open={inviteSent}
        autoHideDuration={5000}
        onClose={() => setInviteSent(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity='success'>Invitations sent!</Alert>
      </Snackbar>
    </>
  );
};

const useStyle = makeStyles({
  root: {
    paddingTop: theme.spacing(5),
  },
  teamManagerActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: `0 0 ${theme.spacing(7)}`,
    gap: theme.spacing(7),
  },
  teamManagerTop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 0,
    gap: theme.spacing(10),
    width: '100%',
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: 0,
    gap: theme.spacing(3),
  },
  callToAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    gap: theme.spacing(5),
  },
});

export default Team;
