import { Box } from '@material-ui/core';
import FastTrack from 'components/Checkout/steps/FastTrack';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import { Step, Steps, Wizard } from 'react-albus';
import { useHistory } from 'react-router-dom';
import { Paths } from 'Routes';
import { TeamEventFragmentFragment, ViewerFragmentFragment } from 'types';

interface Props {
  teamEvent: TeamEventFragmentFragment;
  viewer: ViewerFragmentFragment;
}

const TardyFlow = ({ teamEvent, viewer }: Props) => {
  const history = useHistory();

  useEffect(() => {
    mixpanel.track('tardy flow started', {
      source: 'registration',
      customerType: viewer?.customerType,
      firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
    });
  }, []);

  return (
    <Wizard
      history={history}
      basename={Paths.TEAM_EVENTS}
      render={({ step }) => {
        return (
          <Box>
            <Steps key={step.id} step={step}>
              <Step
                id='fastTrack'
                render={(context) => {
                  return (
                    <FastTrack context={context} teamEvent={teamEvent} viewer={viewer} />
                  );
                }}
              />
            </Steps>
          </Box>
        );
      }}
    ></Wizard>
  );
};

export default TardyFlow;
