// @ts-strict-ignore
import {
  Configuration,
  EngagementEdgeFragment,
  MemberFragment,
  NodeEngagementScores,
  TeamFragment,
} from './interface';

/**
 * Precompute lookup tables from an organization and engagement edges in order
 * to support insights graph computation.
 *
 * This stores data that is _independent_ from the team being currently
 * visualized.
 */
export class GraphContext {
  private _teamsById: Map<string, TeamFragment>;
  private _teamsByManagerId: Map<string, TeamFragment>;
  private _connectionScoresByKey: Map<string, number>;
  private _transitiveTeamMembers: Map<string, MemberFragment[]> = new Map();

  constructor(
    public teams: TeamFragment[],
    public engagementEdges: EngagementEdgeFragment[],
    public config?: Configuration,
  ) {
    this._teamsById = new Map(this.teams.map((t) => [t.id, t]));
    this._teamsByManagerId = new Map(
      this.teams.filter((t) => t.manager?.id).map((t) => [t.manager.id, t]),
    );

    this._connectionScoresByKey = new Map();
    for (const engagementEdge of engagementEdges) {
      const { fromUserId, toUserId, connection } = engagementEdge;
      if (fromUserId === toUserId) continue;

      const key = this._connectionScoreKey(fromUserId, toUserId);
      this._connectionScoresByKey.set(key, connection);
    }
  }

  /**
   * Retrieves the given team.
   */
  team(id: string): TeamFragment {
    return this._teamsById.get(id);
  }

  /**
   * Retrieves the transitive set of members within the team.
   *
   * Note: memoizes
   */
  transitiveTeamMembers(teamId: string): MemberFragment[] {
    if (!this._transitiveTeamMembers.has(teamId)) {
      const team = this.team(teamId);
      const members = [...(team?.members || [])];
      for (const childTeam of team?.childTeams || []) {
        members.push(...this.transitiveTeamMembers(childTeam.id));
      }
      this._transitiveTeamMembers.set(teamId, members);
    }
    return this._transitiveTeamMembers.get(teamId);
  }

  /**
   * Whether a user is a manager of a team.
   */
  isManager(memberId: string) {
    return this._teamsByManagerId.has(memberId);
  }

  /**
   * Returns the _mean_ connection score between two sets of users.
   */
  meanConnectionScore(userIdsA: string[], userIdsB: string[]) {
    // https://jsbench.me/03lbzg7d1y/1
    let mean = 0;
    let count = 0;
    for (const userIdA of userIdsA) {
      for (const userIdB of userIdsB) {
        if (userIdA === userIdB) continue;

        const key = this._connectionScoreKey(userIdA, userIdB);
        const score = this._connectionScoresByKey.get(key) || 0;

        count += 1;
        mean = (mean * (count - 1) + score) / count;
      }
    }

    return mean;
  }

  /**
   * Returns the node's `engagement_score`-based scores
   */
  nodeConnectionScores(nodeId: string): NodeEngagementScores {
    const team = this.team(nodeId);
    if (!team) return null;

    return team?.scores?.reduce((acc, score) => {
      acc[score.scoreType] = score['value'];
      return acc;
    }, {} as NodeEngagementScores);
  }

  private _connectionScoreKey(userAId: string, userBId: string) {
    // https://jsbench.me/wjlbzqinac/1
    return userAId > userBId ? `${userAId}:${userBId}` : `${userBId}:${userAId}`;
  }
}
