import { Loading } from 'components/core';
import gql from 'graphql-tag';
import { Statsig } from 'statsig-react';
import { useIsPulseBetaEnabledQuery } from 'types';
import PulseOverview from './Overview';
import PulseUpsell from './PulseUpsell';
import CenteredContent from 'glue/components/structure/CenteredContent';

const Pulse = () => {
  const { data, loading } = useIsPulseBetaEnabledQuery();
  if (loading) {
    return <Loading />;
  }
  const pulseEnabled =
    data?.viewer?.orgs?.[0]?.isPulseBetaEnabled || Statsig.checkGate('morale_pulse');

  return (
    <CenteredContent>
      {pulseEnabled ? <PulseOverview /> : <PulseUpsell />}
    </CenteredContent>
  );
};

export default Pulse;

Pulse.query = gql`
  query IsPulseBetaEnabled {
    viewer {
      orgs {
        isPulseBetaEnabled
      }
    }
  }
`;
