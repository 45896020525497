import { Colors, Sizes } from 'constants/index';
import React from 'react';
import { CardElement } from 'react-stripe-elements';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  cardElement: {
    padding: Sizes.SPACING_SMALL,
    borderRadius: Sizes.SUB_GRID_UNIT,
    border: Colors.BORDER,
  },
});

interface Props {
  [key: string]: any;
}

export const CreditCard = (props: Props) => {
  const classes = useStyles();
  return <CardElement className={classes.cardElement} {...props} />;
};
