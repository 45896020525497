// @ts-strict-ignore
import 'react-calendar/dist/Calendar.css';

import { useResponsive } from 'ahooks';
import cx from 'classnames';
import _ from 'lodash';
import { ParticipationModel } from 'types';

import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import theme from '../../../theme';
import SelfServeButtonGroup from 'components/Checkout/SelfServeButtonGroup';
import SectionSubHeader from 'components/SectionSubHeader';

enum MysterySelection {
  Mystery = 'mystery',
  SkipSurprise = 'skipSurprise',
}

const useStyles = makeStyles({
  buttonGroup: {
    '& .MuiButtonBase-root': {
      flex: 1,
      textTransform: 'none',
    },
    '& .MuiButton-contained': {
      color: theme.palette.primary.main,
      background: theme.palette.primary[100],
    },
  },
  titleSection: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2rem',
    fontWeight: 'bold',
  },
  titleInput: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    width: '100%',
  },
  borderBox: {
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
  },
  prepTitle: {
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: 800,
    letterSpacing: '0.1em',
    marginBottom: `${theme.spacing(4)}`,
    marginTop: `${theme.spacing(10)}`,
  },
});

const EventPrep = (props) => {
  const { lg } = useResponsive();
  const { globalState, setValue } = props;
  const { summary } = globalState;
  const surpriseToAll = props.globalState.surpriseSelection?.surpriseToAll;
  const surpriseOrEvent = surpriseToAll ? 'surprise' : 'event';
  const classes = useStyles();

  return (
    <Box my={4}>
      <Typography className={classes.prepTitle}>Event Prep</Typography>
      <SectionSubHeader
        title='Add details to your event'
        subTitle={`Answer the questions below to help us prepare for your ${surpriseOrEvent}`}
      />
      <Box mt={7} className={classes.titleSection}>
        <Typography
          gutterBottom
        >{`What should we name your ${surpriseOrEvent}?`}</Typography>
        <TextField
          className={cx([classes.titleInput, classes.borderBox])}
          value={_.get(summary, 'title')}
          placeholder='Example: Team Holiday Party'
          focused
          onChange={(e) =>
            setValue({
              ...summary,
              title: e.target.value as string,
            })
          }
          InputProps={{
            disableUnderline: true,
            style: { fontSize: '16px', fontWeight: 400 },
          }}
        />
      </Box>

      <Familiarity
        familiarity={_.get(summary, 'participantFamiliarity')}
        setFamiliarity={(newFamiliarity: number) =>
          props.setValue({
            ...summary,
            participantFamiliarity: newFamiliarity,
          })
        }
      />
      <ParticipantLocation
        location={_.get(summary, 'participationModel')}
        setLocation={(newLocation: ParticipationModel) =>
          props.setValue({
            ...summary,
            participationModel: newLocation,
          })
        }
      />
      <Box className={classes.titleSection}>
        <Typography
          style={{
            fontSize: 16,
            textAlign: lg ? 'left' : 'center',
            marginBottom: theme.spacing(1),
          }}
        >
          Any notes or details we should know to make this extra special?
        </Typography>
        <TextField
          multiline
          className={cx([classes.titleInput, classes.borderBox])}
          value={_.get(globalState, 'summary.purpose')}
          onChange={(e) =>
            props.setValue({
              ...globalState.summary,
              purpose: e.target.value as string,
            })
          }
          placeholder='For example: Let us know if your guests are located internationally or are ESL or if you have new hires we should spotlight!'
          InputProps={{
            disableUnderline: true,
            style: {
              fontSize: 16,
              fontWeight: 400,
              lineHeight: theme.spacing(6),
            },
          }}
        />
      </Box>
      {!surpriseToAll && (
        <Box style={{ marginBottom: '2rem' }}>
          <RadioGroup
            value={
              !!props.globalState.surpriseSelection?.surpriseToAttendees
                ? MysterySelection.Mystery
                : MysterySelection.SkipSurprise
            }
            onChange={(e) => {
              setValue(
                {
                  surpriseToAttendees: e.target.value === MysterySelection.Mystery,
                  surpriseToAll: false,
                },
                'surpriseSelection',
              );
            }}
          >
            <FormControlLabel
              style={{ fontSize: '16px' }}
              value={MysterySelection.Mystery}
              control={<Radio />}
              label={
                <>
                  <b>Maximize the mystery! </b>Keep the experience details a surprise to
                  attendees until the event starts.
                </>
              }
            />
            <FormControlLabel
              style={{ marginTop: theme.spacing(4), fontSize: '16px' }}
              value={MysterySelection.SkipSurprise}
              control={<Radio />}
              label={
                <>
                  <b>Skip the surprise. </b>Tell attendees about the experience ahead of
                  time.
                </>
              }
            />
          </RadioGroup>
        </Box>
      )}
    </Box>
  );
};

const Familiarity = ({
  familiarity,
  setFamiliarity,
}: {
  familiarity: number;
  setFamiliarity(number): void;
}) => {
  return (
    <SelfServeButtonGroup
      title='How well do the guests know each other?'
      value={familiarity}
      setValue={setFamiliarity}
      options={[
        {
          value: 1,
          label: 'Strangers',
        },
        {
          value: 2,
          label: 'A little',
        },
        {
          value: 3,
          label: 'Average',
        },
        {
          value: 4,
          label: 'Well',
        },
        {
          value: 5,
          label: 'BFFs',
        },
      ]}
    />
  );
};

const ParticipantLocation = ({
  location,
  setLocation,
}: {
  location: ParticipationModel;
  setLocation(newLocation: ParticipationModel): void;
}) => {
  return (
    <SelfServeButtonGroup
      title='Where will your guests be located?'
      options={[
        {
          value: ParticipationModel.Virtual,
          label: 'Remotely',
        },
        {
          value: ParticipationModel.InPerson,
          label: 'In person',
        },
        {
          value: ParticipationModel.Hybrid,
          label: 'A little bit of both',
        },
      ]}
      value={location}
      setValue={setLocation}
    />
  );
};

export default EventPrep;
