import { css } from '@emotion/react';
import Check from '@mysteryco/design/icons/Check';
import EyeOff from '@mysteryco/design/icons/EyeOff';
import { colors } from '@mysteryco/design/src';
import { StepStatus } from 'constants/Type';
import PulseBanner from 'glue/assets/artwork/pulse-demo.png';
import ListedStepper from 'glue/components/lists/ListedStepper';
import theme from 'theme';

const strings = {
  howDoesThisWork: 'HOW DOES THIS WORK',
  setPulse: 'Set your Pulse schedule and participants',
  youDetermine: 'You determine which teams will participate and how often.',
  getHelp: 'Get help building your survey',
  youllGet:
    'You’ll get a set of core questions based on key topics for you to choose from.',
  wellAlert: 'We’ll alert participants and gather their answers',
  participantsWill:
    'Participants will receive a notification at your set frequency to answer your survey.',
  seeChanges: 'See Pulse changes overtime',
  youllAccess:
    'You will have access to intuitive reporting on participation and employee sentiment.',
  headsUp: 'JUST A HEADS UP',
  responsesComments:
    'Responses and comments that employees provide in surveys are confidential',
  weReport:
    'We report overall findings but will not share any details that could identify individuals',
};

interface Props {
  hasActivePulse: boolean;
}

const DoneCheck = () => <Check size={18} color={colors.Glue_Ink00} />;

const PulseInfoSection = ({ hasActivePulse }: Props) => {
  return (
    <>
      <div css={styles.image}>
        <img src={PulseBanner} alt='' />
      </div>
      <div css={styles.subSection}>{strings.howDoesThisWork}</div>
      <ListedStepper
        steps={[
          {
            text: strings.setPulse,
            subText: strings.youDetermine,
            status: hasActivePulse ? StepStatus.Completed : StepStatus.Initial,
            Icon: hasActivePulse ? DoneCheck : undefined,
          },
          {
            text: strings.getHelp,
            subText: strings.youllGet,
            status: hasActivePulse ? StepStatus.Completed : StepStatus.Initial,
            Icon: hasActivePulse ? DoneCheck : undefined,
          },
          {
            text: strings.wellAlert,
            subText: strings.participantsWill,
            status: hasActivePulse ? StepStatus.Completed : StepStatus.Initial,
            Icon: hasActivePulse ? DoneCheck : undefined,
            iconText: hasActivePulse ? undefined : '3',
          },
          {
            text: strings.seeChanges,
            subText: strings.youllAccess,
            status: hasActivePulse ? StepStatus.Completed : StepStatus.Initial,
            Icon: hasActivePulse ? DoneCheck : undefined,
            iconText: hasActivePulse ? undefined : '4',
          },
        ]}
        showConnector
      />
      <div css={styles.subSection}>{strings.headsUp}</div>
      <div css={styles.confidential}>
        <EyeOff color={colors.Glue_Ink00} size={20} />
        <div css={styles.confidentialText}>
          <div css={styles.confidentialTextBold}>{strings.responsesComments}</div>
          <div css={styles.confidentialTextNormal}>{strings.weReport}</div>
        </div>
      </div>
    </>
  );
};

const styles = {
  image: css({
    width: '100%',
    margin: 'auto',
    padding: `${theme.spacing(3)} ${theme.spacing(20)}`,
    '& img': {
      maxWidth: '100%',
    },
    background: colors.Glue_LavenderLight,
  }),
  subSection: css({
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: 12,
    lineHeight: 1.2,
    letterSpacing: '0.1em',
    color: colors.Glue_Ink10,
  }),
  confidential: css({
    background: colors.Glue_LavenderLight,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: `${theme.spacing(4)} ${theme.spacing(4)}`,
    gap: theme.spacing(4),
  }),
  confidentialTextBold: css({
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '140%',
  }),
  confidentialTextNormal: css({
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '140%',
  }),
  confidentialText: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  }),
};

export default PulseInfoSection;
