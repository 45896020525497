import { IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import subscriptionIllustration from 'assets/illustrations/upsell-subscription@2x.png';
import peopleImage from 'assets/people.png';
import mixpanel from 'mixpanel-browser';
import theme from 'theme';
import { ViewerFragmentFragment } from 'types';
import { useViewer } from 'utils/state';
import { getUpsellLink } from 'utils/stringUtil';
import NewPrimaryButton from './core/NewPrimaryButton';
import CheckCircle02 from './icons/CheckCircle02';
import CloseX from './icons/CloseX';

const ILLUSTRATION_WIDTH = 123;
const ILLUSTRATION_HEIGHT = 259;

const UPSELL_LINK = getUpsellLink('header');

const onButtonClick = (viewer?: ViewerFragmentFragment) => {
  mixpanel.track('header slider cta clicked', {
    source: 'upsell slider',
    customerType: viewer?.customerType,
    firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
  });
  window.open(UPSELL_LINK, '_blank', 'noreferrer,noopener');
};

// const onLinkClick = () => {
//   mixpanel.track('header slider pricing link clicked', { source: 'upsell slider' });
// };

const strings = {
  titleOne: 'Unlock',
  titleTwo: ' your team’s',
  titleThree: ' happiness',
  subTitle: 'When you upgrade to a Glue Subscription',
  demo: 'Schedule a demo',
  alt: 'illustration',
  firstBullet: 'Save money with credits-based event pricing',
  secondBullet: 'Measure the impact of each of your events',
  thirdBullet: 'Save time with manager tools to book events',
  benefit: 'Talk to us and learn how you can benefit from a Glue Subscription',
  pricing: 'See our pricing',
};

interface Props {
  onClose: () => void;
}

const UpsellSubscription = ({ onClose }: Props) => {
  const classes = useStyles();
  const viewer = useViewer();

  return (
    <>
      <IconButton className={classes.closeIcon} onClick={onClose}>
        <CloseX />
      </IconButton>
      <div className={classes.container}>
        <Typography className={classes.gradientMessage} variant='h4' align='center'>
          <span>{strings.titleOne}</span>
          <span className={classes.regularMessage}>{strings.titleTwo}</span>
          <span>{strings.titleThree}</span>
        </Typography>
        <Typography variant='body1' css={{ margin: `${theme.spacing(3)} 0` }}>
          {strings.subTitle}
        </Typography>
        <img
          className={classes.illustration}
          src={subscriptionIllustration}
          alt={strings.alt}
        />
        <div css={{ margin: `${theme.spacing(3)} 0` }}>
          <Typography className={classes.bulletCopy} variant='body2'>
            <CheckCircle02 className={classes.circleIcon} />
            {strings.firstBullet}
          </Typography>
          <Typography className={classes.bulletCopy} variant='body2'>
            <CheckCircle02 className={classes.circleIcon} />
            {strings.secondBullet}
          </Typography>
          <Typography className={classes.bulletCopy} variant='body2'>
            <CheckCircle02 className={classes.circleIcon} />
            {strings.thirdBullet}
          </Typography>
        </div>
        <NewPrimaryButton
          label={strings.demo}
          onClick={() => onButtonClick(viewer)}
          className={classes.demoButton}
        />
        <Typography
          variant='body2'
          align='center'
          css={{ margin: `0 ${theme.spacing(12)}` }}
        >
          {strings.benefit}
        </Typography>
        <img className={classes.peopleImage} alt='' src={peopleImage} />
      </div>
      {/* <Link
        href={PRICING_LINK}
        target='_blank'
        rel='noopener'
        underline='always'
        className={classes.pricingLink}
        onClick={onLinkClick}
      >
        {strings.pricing}
      </Link> */}
    </>
  );
};

const useStyles = makeStyles({
  container: {
    width: '400px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(3)} ${theme.spacing(6)} ${theme.spacing(2)}`,
  },
  closeIcon: {
    alignSelf: 'flex-end',
    margin: theme.spacing(2),
  },
  demoButton: {
    fontWeight: 800,
    margin: `${theme.spacing(8)} 0`,
  },
  illustration: {
    height: `${ILLUSTRATION_HEIGHT}px`,
    width: `${ILLUSTRATION_WIDTH}px`,
    margin: `${theme.spacing(4)} 0`,
  },
  bulletCopy: {
    display: 'flex',
    margin: `${theme.spacing(3)} 0`,
    fontWeight: 500,
  },
  circleIcon: {
    marginRight: theme.spacing(2),
    marginBottom: `-${theme.spacing(1)}`,
  },
  pricingLink: {
    alignSelf: 'center',
    justifySelf: 'flex-end',
    marginBottom: theme.spacing(8),
    marginTop: theme.spacing(8),
  },
  gradientMessage: {
    color: '#5c296c',
    background:
      'linear-gradient(90deg, #7A1783 0%, #BE207A 27.6%, #9747FF 68.75%, #EB5757 97.92%)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  regularMessage: {
    color: '#5c296c',
    background: 'transparent',
    '-webkit-text-fill-color': '#5c296c',
  },
  peopleImage: {
    height: '52px',
    marginTop: theme.spacing(2),
  },
});

export default UpsellSubscription;
