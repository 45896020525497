// @ts-strict-ignore
import { cx } from '@emotion/css';
import { Box, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ArrowRight from '@mysteryco/design/icons/ArrowRight';
import { Sizes, Type } from 'constants/index';
import { fontSizePx } from 'constants/Type';
import { useDetectWrappedElements, wrapChildrenClassName } from 'flex-wrap-layout';
import Button from 'glue/components/buttons/Button';
import { useResponsive, useScrollTopOnLoad } from 'hooks';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { WithWizard, WizardContext } from 'react-albus';
import { useHistory, useLocation } from 'react-router-dom';
import theme from 'theme';
import { TeamEventFragmentFragment } from 'types';
import { useViewer } from 'utils/state';
import { validate as uuidValidate } from 'uuid';
import { sharedStyles } from './sharedStyles';

const useStyles = makeStyles({
  container: ({ responsive, renderRight }: any) => ({
    maxWidth: renderRight ? Sizes.GRID_UNIT * 135 : Sizes.GRID_UNIT * 75,
    ...responsive({
      small: { width: '100%', borderRadius: 0 },
      medium: { width: '95%' },
      large: { width: '100%' },
      xlarge: { width: '100%' },
    }),
    '.wrap-children': {
      display: 'flex',
      flexWrap: 'wrap !important',
    },
    '.wrap-children:not(.has-child-wrapped) > div:last-child:not(:first-of-type)': {
      marginLeft: Sizes.SPACING_LARGE,
    },
    '.wrap-children.has-child-wrapped > div:last-child': {
      marginTop: Sizes.SPACING_XSMALL,
      marginLeft: 0,
    },
    '.next-is-wrapped, .next-is-wrapped ~ *': {
      flex: 'auto',
    },
  }),
  wrapChildren: ({ responsive }: any) => ({
    maxWidth: '100%',
    padding: responsive({
      small: 0,
      medium: `0px 10px`,
      large: `0px 15px`,
      xlarge: 0,
    }),
    display: 'flex',
    justifyContent: 'space-between',
  }),
  leftInner: ({ responsive }: any) => ({
    flex: 1,
    flexBasis: Sizes.GRID_UNIT * 60,
    minWidth: responsive({
      small: '100%',
      medium: Sizes.GRID_UNIT * 50,
      large: Sizes.GRID_UNIT * 60,
      xlarge: Sizes.GRID_UNIT * 60,
    }),
    maxWidth: responsive({
      small: '100%',
      medium: '100%',
      large: Sizes.GRID_UNIT * 90,
      xlarge: Sizes.GRID_UNIT * 90,
    }),
  }),
  leftInnerBox: ({ responsive }: any) => ({
    borderRadius: responsive({ small: 0, medium: 10, large: 10, xlarge: 10 }),
    padding: `${theme.spacing(6)} 12px`,
  }),
  labelWrapper: {
    marginBottom: theme.spacing(4),
  },
  titleLabel: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '2.125rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  subTitleWrapper: {
    marginBottom: theme.spacing(8),
  },
  childrenWrapper: ({ isAddressStep }: any) => ({
    maxHeight: '700px',
    overflowY: !isAddressStep ? 'auto' : undefined,
  }),
  label: ({ responsive }: any) => ({
    fontWeight: Type.FontWeights.BOLD,
    ...fontSizePx(responsive({ small: 20, medium: 20, large: 22, xlarge: 24 })),
  }),
  buttonWrapper: ({ responsive }: any) => ({
    bottom: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: responsive({
      small: `0px ${Sizes.SPACING_MEDIUM}px`,
      medium: `0px 10px`,
      large: `0px 15px`,
      xlarge: ``,
    }),
    paddingBottom: theme.spacing(4),
    backgroundColor: theme.palette.common.white,
  }),
  helpText: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'row',
    color: theme.palette.text.primary,
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'center',
    padding: '12px 20px',
    borderTop: `1px solid ${theme.palette.primary[200]}`,
    background: theme.palette.common.white,
  },
  badgeContainer: {
    display: 'flex',
  },
});

export interface RegistrationStepWrapperProps extends Partial<WizardContext> {
  children?: React.ReactNode;
  nextButtonProps?: any;
  hideButtons?: boolean;
  renderRight?: React.ReactNode | (() => React.ReactNode);
  onClickNext?(): void;
  label?: string;
  subTitle?: React.ReactNode | string;
  teamEvent?: TeamEventFragmentFragment;
  // only the presence and ID is used in this component
  askFeedbackEvent?: Pick<TeamEventFragmentFragment, 'id'>;
  secondaryLabel?: string;
  hideContinueButton?: boolean;
  showSkipLink?: boolean;
  badgeElement?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
}

const RegistrationStepWrapper = ({
  hideButtons,
  renderRight,
  step,
  steps,
  next,
  previous,
  nextButtonProps = {},
  children,
  go,
  push,
  replace,
  onClickNext,
  label,
  subTitle,
  teamEvent,
  askFeedbackEvent,
  secondaryLabel,
  hideContinueButton,
  showSkipLink,
  badgeElement,
  className,
  ...otherProps
}: RegistrationStepWrapperProps) => {
  useScrollTopOnLoad();
  const responsive = useResponsive();
  const history = useHistory();
  const viewer = useViewer();
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const ref = useRef(null);
  useDetectWrappedElements(ref);

  const onClickNextCaller = async () => {
    setLoading(true);
    try {
      if (onClickNext) {
        await onClickNext();
      }
      await next();
    } catch (e) {
      setLoading(false);
    }
  };

  const isAddressStep = step && step['id'] === 'address';

  const classes = useStyles({
    responsive,
    renderRight,
    hideButtons,
    isAddressStep,
  });
  useEffect(() => {
    if (step && step['id']) {
      let stepName = '';
      if (uuidValidate(step['id'])) {
        stepName = 'questionnaire';
      } else {
        stepName = step['id'];
      }
      mixpanel.track(`step ${stepName} loaded`, {
        source: 'registration',
        customerType: viewer?.customerType,
        firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
      });
    }
  }, []);

  const generateFeedbackUrl = () => {
    const rsvpUrl = `${location.pathname}${location.search}&skipAskFeedback=true`;
    return `/feedback/${askFeedbackEvent.id}?product=TeamEvent&rsvpLink=${rsvpUrl}`;
  };

  return (
    <>
      {/* Outer container */}
      <div className={cx(classes.container, className)} {...otherProps}>
        <div ref={ref}>
          <div className={cx([classes.wrapChildren, wrapChildrenClassName])}>
            {/* Inner container left */}
            <div className={classes.leftInner}>
              <div className={classes.leftInnerBox}>
                {badgeElement && (
                  <Box className={classes.badgeContainer}>{badgeElement}</Box>
                )}
                <div className={classes.labelWrapper}>
                  <Typography className={classes.titleLabel}>{label}</Typography>
                </div>
                {subTitle && (
                  <Typography variant='body1' style={{ marginBottom: '20px' }}>
                    {subTitle}
                  </Typography>
                )}
                {secondaryLabel && <Typography>{secondaryLabel}</Typography>}
                <div className={classes.childrenWrapper}>{children}</div>
                {showSkipLink && (
                  <Link
                    onClick={() => onClickNextCaller()}
                  >{`Skip – I'll do this later`}</Link>
                )}
              </div>
            </div>
            {/* Inner container right */}
            {renderRight && (_.isFunction(renderRight) ? renderRight() : renderRight)}
          </div>
          {!hideButtons && (
            <div className={classes.buttonWrapper}>
              <WithWizard
                render={({ step }) => (
                  <>
                    {!hideContinueButton && (
                      <Button
                        data-testid='next-btn'
                        loading={loading}
                        endIcon={<ArrowRight size={16} />}
                        onClick={() => {
                          mixpanel.track(
                            `${nextButtonProps.label || 'Continue'} clicked`,
                            {
                              source: 'registration',
                              step,
                              customerType: viewer?.customerType,
                              firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
                            },
                          );
                          if (askFeedbackEvent) {
                            const feedbackUrl = generateFeedbackUrl();
                            history.replace(feedbackUrl);
                          } else {
                            onClickNextCaller();
                          }
                        }}
                        {...nextButtonProps}
                        css={sharedStyles.button}
                        disabled={loading || nextButtonProps.disabled}
                      >
                        {nextButtonProps.label || 'Continue'}
                      </Button>
                    )}
                    {askFeedbackEvent && (
                      <Box className={classes.helpText}>
                        <Typography>
                          Don't have time?{' '}
                          <Link
                            onClick={() => onClickNextCaller()}
                          >{` Skip feedback`}</Link>
                        </Typography>
                      </Box>
                    )}
                  </>
                )}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RegistrationStepWrapper;
