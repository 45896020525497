import TextLink from 'glue/components/buttons/TextLink';
import {
  CollapsibleCard,
  CollapsibleCardGroup,
} from 'glue/components/cards/CollapsibleCard';
import InlineBanner from 'glue/components/structure/InlineBanner';
import SectionHeader from 'glue/components/structure/SectionHeader';
import { DateTime } from 'luxon';
import theme from 'theme';
import { OnboardingViewerFragment, OrganizationOnboardingStatus } from 'types';

import { gql } from '@apollo/client';
import { cx } from '@emotion/css';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@mysteryco/design';
import CalendarDate from '@mysteryco/design/icons/CalendarDate';
import Check from '@mysteryco/design/icons/Check';
import LifeBuoy01 from '@mysteryco/design/icons/LifeBuoy01';
import Users02 from '@mysteryco/design/icons/Users02';
import { Glue_Ink00 } from '@mysteryco/design/src/tokens/colors';

import { HomeOrganization } from './';
import ConnectTeamCalendars from './components/CalendarConnection';
import SignUp from './components/SignUp';

import { css } from '@emotion/react';
import XClose from '@mysteryco/design/icons/XClose';
import thumbsUpSrc from 'glue/assets/illustrations/ThumbsUp.svg';
import { useLocalStorage } from 'hooks';
import { openHubspotChat } from 'utils/hubspotUtil';
import TeamSetup from './components/TeamSetup';

// Continue to show onboarding components x hours after completion, even when other steps
// are complete.
const POST_ONBOARDING_AGE_HOURS = 36;

type Props = {
  organization: HomeOrganization;
  viewer: OnboardingViewerFragment;
  loading?: boolean;
  onboardingCompleted: boolean;
  onboardingExpired: boolean;
  [key: string]: any;
};

export const isOnboardingCompleted = (organization: HomeOrganization): boolean => {
  return (
    organization?.orgOnboardingCompleted?.status === OrganizationOnboardingStatus.Complete
  );
};

export const isOnboardingExpired = (organization: HomeOrganization): boolean => {
  if (!isOnboardingCompleted(organization)) {
    return false;
  }

  const completedAt = DateTime.fromISO(organization.orgOnboardingCompleted.completedAt);
  const expiresAt = completedAt.plus({ hours: POST_ONBOARDING_AGE_HOURS });

  return DateTime.now() >= expiresAt;
};

const Onboarding = ({
  organization,
  viewer,
  loading,
  onboardingCompleted,
  onboardingExpired,
  ...props
}: Props) => {
  const completedSteps = {
    teamsAdded: !!organization?.rootTeam,
    teamCalendars: onboardingCompleted,
  };
  const CompletedIcon = Check;
  const CalendarIcon = completedSteps.teamCalendars ? CompletedIcon : CalendarDate;
  const TeamIcon = completedSteps.teamsAdded ? CompletedIcon : Users02;

  const classes = useStyles();

  const getIconBackgroundColor = (completed: boolean): string =>
    completed ? colors.Glue_Mint00 : colors.Glue_LavenderLight;

  if (onboardingCompleted) {
    return <CompletedBanner />;
  }

  return (
    <Box className={cx(classes.root, props?.className)}>
      <Box className={classes.headerRow}>
        <SectionHeader title='Get started' />
        <Typography className={classes.text}>
          Unlock team insights and enhance team bonding by completing the following steps:
        </Typography>
      </Box>
      <CollapsibleCardGroup>
        <CollapsibleCard
          leadIcon={<CompletedIcon color={Glue_Ink00} size={15} />}
          iconBackground={getIconBackgroundColor(true)}
          headline='Sign up for Glue'
          teaser='The first step to building a happier more connected team'
        >
          <SignUp />
        </CollapsibleCard>
        <CollapsibleCard
          leadIcon={<TeamIcon color={Glue_Ink00} size={15} />}
          iconBackground={getIconBackgroundColor(completedSteps.teamsAdded)}
          headline='Add your team members'
          teaser='Add teammates for a happier, more connected team'
        >
          <TeamSetup teamsAdded={completedSteps.teamsAdded} />
        </CollapsibleCard>
        <CollapsibleCard
          leadIcon={<CalendarIcon color={Glue_Ink00} size={15} />}
          iconBackground={getIconBackgroundColor(completedSteps.teamCalendars)}
          headline='Connect team calendars'
          teaser='Unlock richer connection data by syncing your team’s calendars'
        >
          <ConnectTeamCalendars viewer={viewer} />
        </CollapsibleCard>
      </CollapsibleCardGroup>
      <InlineBanner
        headline='Need help?'
        description='Reach out to us with questions'
        type='lavender'
        action={
          <TextLink Icon={LifeBuoy01} onClick={openHubspotChat}>
            Contact us
          </TextLink>
        }
      />
    </Box>
  );
};
Onboarding.fragments = gql`
  fragment OnboardingViewer on User {
    id
    ...ConnectTeamCalendarsViewer
  }
  ${ConnectTeamCalendars.fragments}
`;

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    gap: theme.spacing(8),
    alignSelf: 'stretch',
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    gap: theme.spacing(2),
  },
  text: {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: 1.5,
    color: colors.Glue_Ink00,
  },
});

export default Onboarding;

const CompletedBanner = () => {
  const [hide, setHideCompleted] = useLocalStorage<boolean>(
    'hideOnboardingCompletedBanner',
  );

  if (hide) {
    return null;
  }

  return (
    <div css={bannerStyles.root}>
      <ThumbsUp />
      <div>You've completed your onboarding. Go ahead and celebrate!</div>
      <button css={bannerStyles.iconButton} onClick={() => setHideCompleted(true)}>
        <XClose color={colors.Glue_Ink00} css={{ width: 16, height: 16 }} />
      </button>
    </div>
  );
};

const bannerStyles = {
  root: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    backgroundColor: colors.Glue_Mint20,
    color: colors.Glue_Ink00,
    padding: 16,
    width: '100%',
    fontSize: 16,
    fontWeight: 700,
  }),
  iconButton: css({
    marginLeft: 'auto',
    padding: 4,
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    color: colors.Glue_Ink00,

    '&:hover': {
      backgroundColor: colors.Glue_DarkMint00,
    },
    '&:active': {
      backgroundColor: colors.Glue_DarkMint00,
    },
    '&:focus': {
      backgroundColor: colors.Glue_DarkMint00,
    },
  }),
};

const ThumbsUp = () => (
  <img src={thumbsUpSrc} alt='A thumbs-up illustration' css={{ width: 24, height: 32 }} />
);
