// @ts-strict-ignore
import { Box, Drawer, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Dusk, Main, Purple200 } from '@mysteryco/design/src/tokens/colors';
import SentimentRow from 'components/SentimentRow';
import StarRating from 'components/StarRating';
import UpsellSubscription from 'glue/components/UpsellSubscription';
import { useEffect, useRef, useState } from 'react';
import EventMetricData from 'scenes/BookedEvent/EventMetricData';
import EventMetricsCard from 'scenes/BookedEvent/EventMetricsCard';
import theme from 'theme';
import { EventPageTeamEventFragmentFragment, TeamEventStatus } from 'types';

const FooterData = ({ value, label }) => {
  const classes = useStyles();
  return (
    <Typography className={classes.footer}>
      {label}: <span className={classes.footerData}>{value}</span>
    </Typography>
  );
};

const AggregateExperienceRatingCard = ({ teamEvents, showUpsell = false }) => {
  const completedTeamEventRatings = getCompletedEvents(teamEvents)
    ?.map((event) => event.teamEventMetricSummary?.rating?.overallRating)
    .filter((rating) => rating > 0);
  const rating = completedTeamEventRatings.length
    ? completedTeamEventRatings.reduce((prev, curr) => prev + curr, 0) /
      completedTeamEventRatings.length
    : 0;
  const highLow = completedTeamEventRatings.reduce(
    (prev, curr) => {
      if (curr >= 4.5) {
        prev.high++;
      } else if (curr <= 2) {
        prev.low++;
      }
      return prev;
    },
    { high: 0, low: 0 },
  );
  return (
    <EventMetricsCard
      title='Event rating'
      subtitle='Overall event rating by guests'
      type='aggregate'
      showUpsell={showUpsell}
      upsellEmpty
      upsellLock
      footer={
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            color: Main,
            fontSize: '14px',
          }}
        >
          <FooterData label='Five star events' value={highLow.high} />
          <div style={{ borderLeft: `1px solid ${Purple200}` }}></div>
          <FooterData label='Low rated events' value={highLow.low} />
        </Box>
      }
    >
      <EventMetricData value={rating.toFixed(1)} text='of 5 stars' />
      <StarRating value={rating} />
    </EventMetricsCard>
  );
};

const AggregateConnectednessCard = ({ teamEvents, showUpsell = false }) => {
  const completedTeamEvents = getCompletedEvents(teamEvents);
  const connections = completedTeamEvents.reduce((prev, curr) => {
    return prev + curr.connectionsStrengthened;
  }, 0);
  const completedSentiment = completedTeamEvents.reduce(
    (prev, curr) => {
      prev.responses += curr.teamEventMetricSummary.responses;
      prev.low += curr.teamEventMetricSummary.connectedness.low;
      prev.neutral += curr.teamEventMetricSummary.connectedness.neutral;
      prev.strong += curr.teamEventMetricSummary.connectedness.strong;
      return prev;
    },
    {
      low: 0,
      neutral: 0,
      strong: 0,
      responses: 0,
    },
  );
  const getSentimentPercentage = (connectedness: number, responses: number): number => {
    if (!connectedness || responses === 0) return 0;
    return Math.round((connectedness / responses) * 100);
  };

  return (
    <EventMetricsCard
      title='Connection impact'
      subtitle='Overall impact on guest sense of connection'
      type='aggregate'
      showUpsell={showUpsell}
      footer={<FooterData label='Total connections strengthened' value={connections} />}
    >
      <SentimentRow
        low={getSentimentPercentage(completedSentiment.low, completedSentiment.responses)}
        neutral={getSentimentPercentage(
          completedSentiment.neutral,
          completedSentiment.responses,
        )}
        strong={getSentimentPercentage(
          completedSentiment.strong,
          completedSentiment.responses,
        )}
        showUpsell={showUpsell}
      />
    </EventMetricsCard>
  );
};

const AggregateEventsCard = ({ teamEvents }) => {
  const classes = useStyles();
  const completedTeamEvents = getCompletedEvents(teamEvents);
  const totalAcceptance = completedTeamEvents.reduce((sum, curr) => {
    return sum + curr.rsvpSummary.accepted;
  }, 0);
  const totalInvited = completedTeamEvents.reduce((sum, curr) => {
    return sum + curr.rsvpSummary.total;
  }, 0);
  const averageGuests = completedTeamEvents.length
    ? Math.round(totalAcceptance / completedTeamEvents.length)
    : 0;
  const acceptanceRate = Math.floor(
    (completedTeamEvents.length ? totalAcceptance / totalInvited : 0) * 100,
  );
  return (
    <EventMetricsCard
      title='Total events'
      subtitle=''
      type='aggregate'
      footer={<FooterData label='RSVP acceptance rate' value={`${acceptanceRate}%`} />}
    >
      <Box className={classes.eventsContent}>
        <Typography className={classes.focusValue}>{teamEvents.length}</Typography>
        <Box className={classes.averageGuests}>{averageGuests} guests on average</Box>
      </Box>
    </EventMetricsCard>
  );
};

const AggregateStats = ({
  teamEvents,
  showUpsell = false,
  canUpsell,
}: {
  teamEvents: EventPageTeamEventFragmentFragment[];
  showUpsell: boolean;
  canUpsell: boolean;
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const timerRef = useRef(null);

  const onMouseEnter = () => {
    if (canUpsell) {
      timerRef.current = setTimeout(() => {
        setOpen(true);
      }, 800);
    }
  };

  const onMouseLeave = () => {
    clearTimeout(timerRef.current);
  };

  const onClose = () => {
    setOpen(false);
  };

  // Clear the interval when the component unmounts
  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  return (
    <>
      <Typography className={classes.heading}>Summary</Typography>
      <Box className={classes.container}>
        <AggregateEventsCard teamEvents={teamEvents} />
        <Box
          className={classes.upsellContainer}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <AggregateConnectednessCard teamEvents={teamEvents} showUpsell={showUpsell} />
          <AggregateExperienceRatingCard
            teamEvents={teamEvents}
            showUpsell={showUpsell}
          />
        </Box>
      </Box>
      <Drawer anchor='right' open={open} onClose={onClose}>
        <UpsellSubscription onClose={onClose} />
      </Drawer>
    </>
  );
};

const getCompletedEvents = (teamEvents) => {
  return teamEvents
    ? teamEvents.filter(
        (te) => te.status === TeamEventStatus.Complete && !isNaN(te.eventRating),
      )
    : [];
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    gap: theme.spacing(4),
    width: '100%',
    '& > *': {
      flexGrow: 1,
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  upsellContainer: {
    display: 'flex',
    gap: theme.spacing(4),
    width: '50%',
    '& > *': {
      flexGrow: 1,
    },

    [theme.breakpoints.down('md')]: {
      width: '100%',
      flexDirection: 'column',
    },
  },
  heading: {
    fontSize: '.875rem',
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
    color: Dusk,
    letterSpacing: 1,
    marginBottom: theme.spacing(2),
  },
  footer: {
    fontSize: '.875rem',
    fontWeight: theme.typography.fontWeightMedium,
    padding: `0 ${theme.spacing(4)}`,
    color: Main,
    flexGrow: 1,
  },
  footerData: {
    fontSize: '.875rem',
    fontWeight: theme.typography.fontWeightBold,
    display: 'inline',
  },
  focusValue: {
    fontSize: '3.25rem',
    fontWeight: theme.typography.fontWeightMedium,
    color: Dusk,
    lineHeight: 1,
    marginTop: theme.spacing(2),
  },
  averageGuests: {
    marginTop: theme.spacing(2),
    fontSize: '.85rem',
    color: theme.palette.primary[800],
    fontWeight: theme.typography.fontWeightMedium,
  },
  eventsContent: {
    marginTop: theme.spacing(-2),
  },
});

export default AggregateStats;
