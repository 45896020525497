import { css } from '@emotion/react';
import { useFormikContext } from 'formik';
import { HTMLAttributes } from 'react';

export interface FormikFieldErrorProps extends HTMLAttributes<HTMLSpanElement> {
  fieldName: string;
  /** Show the error even if the form has not yet been modified */
  showWhenPristine?: boolean;
}

export function FormikFieldError({
  fieldName,
  showWhenPristine,
  ...rest
}: FormikFieldErrorProps) {
  const { errors, touched } = useFormikContext();
  const error = errors[fieldName];
  const hasBeenTouched = touched[fieldName];
  const shouldShowError = showWhenPristine || hasBeenTouched;

  if (!error || !shouldShowError) {
    return null;
  }

  return (
    <span css={styles.root} {...rest}>
      {error}
    </span>
  );
}

export default FormikFieldError;

const styles = {
  root: css({
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '140%',
    color: '#E73B15',
  }),
};
