import { Box, Tooltip, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FeedbackIcon from '@material-ui/icons/Feedback';
import _ from 'lodash';
import TooltipWrapper from '../../core/TooltipWrapper';

const useStyles = makeStyles((theme) => ({
  tooltipText: {
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(2)
  },
  contentWrapper: { borderRadius: 20 },
  text: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const EmailsNotOnProviderTooltip = ({
  emailsNotOnProvider,
  guestListSize,
}: {
  emailsNotOnProvider: string[];
  guestListSize: number;
}) => {
  const classes = useStyles();
  return (
    <LightTooltip
      placement='right-end'
      title={
        <TooltipWrapper>
          <Box width={300} my={4} px={2} className={classes.contentWrapper}>
            <Typography className={classes.tooltipText}>
              We were only able to retrieve availability data for{' '}
              {guestListSize - _.size(emailsNotOnProvider)} of {guestListSize} guests. We
              were unable to get availability for the following guests because you do not
              have access to their calendar.
            </Typography>
            {_.map(emailsNotOnProvider, (email) => {
              return <Typography className={classes.text}>{email}</Typography>;
            })}
          </Box>
        </TooltipWrapper>
      }
    >
      <FeedbackIcon color='primary' />
    </LightTooltip>
  );
};

export default EmailsNotOnProviderTooltip;
