import { css } from '@emotion/react';
import { colors } from '@mysteryco/design';
import { forwardRef } from 'react';

interface Props {
  initials: string;
  background?: string;
  fontColor?: string;
  overlapping?: boolean;
  /**
   * If true, the avatar will be selectable and will have a hover state and
   * focus state that move any overlapping avatars out of the way.
   */
  selectable?: boolean;
  size: 'large' | 'small';
  [key: string]: any;
}

const makeClasses = ({
  background,
  fontColor,
  overlapping,
  size,
  selectable,
}: Props) => ({
  teardrop: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background,
    boxSizing: 'border-box',
    width: size === 'large' ? '85px' : '42.5px',
    height: size === 'large' ? '85px' : '42.5px',
    borderRadius: size === 'large' ? '40px 40px 0px 40px' : '20px 20px 0px 20px',
    border: `1px solid ${colors.Glue_Paper}`,
    cursor: 'default',
    marginRight: overlapping ? (size === 'large' ? '-12px' : '-8px') : undefined,
    transition: 'all 0.25s ease-out',
    '&:hover': {
      ...(selectable
        ? {
            background: colors.Glue_Ink00,
            cursor: 'pointer',
            marginRight: '4px',
            '&:not(:first-of-type)': {
              marginLeft: '12px',
            },
            '& span': {
              color: colors.Glue_Paper,
            },
          }
        : {}),
    },
    '&:focus': {
      ...(selectable
        ? {
            background: colors.Glue_Ink00,
            cursor: 'pointer',
            marginRight: '4px',
            '&:not(:first-of-type)': {
              marginLeft: '12px',
            },
            '& span': {
              color: colors.Glue_Paper,
            },
          }
        : {}),
      ...(overlapping
        ? {
            marginRight: '4px',
            ':not(:first-of-type)': {
              marginLeft: '12px',
            },
          }
        : {}),
    },
  }),
  initials: css({
    color: fontColor || colors.Glue_Paper,
    fontSize: size === 'large' ? '40px' : '16px',
    lineHeight: size === 'large' ? '40px' : '16px',
    fontWeight: 800,
    '&:focus': {
      ...(selectable
        ? {
            color: colors.Glue_Paper,
          }
        : {}),
    },
  }),
});

const ProfileAvatar = forwardRef<HTMLDivElement, Props>(
  (
    { background = colors.Glue_Red10, selectable, overlapping, fontColor, ...props },
    ref,
  ) => {
    const classes = makeClasses({
      background,
      selectable,
      overlapping,
      fontColor,
      ...props,
    });
    return (
      <div css={classes.teardrop} {...props} tabIndex={selectable ? 0 : -1} ref={ref}>
        <span css={classes.initials}>{props.initials}</span>
      </div>
    );
  },
);
ProfileAvatar.displayName = 'ProfileAvatar';

export default ProfileAvatar;
