import { makeStyles } from '@material-ui/styles';
import { Select } from 'antd';
import _ from 'lodash';
import React from 'react';
import { AnswerProps } from '.';

const useStyles = makeStyles({
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  selectWrapper: { display: 'flex' },
  select: {
    width: '100%',
  },
});

const DropDownAnswer = ({ question, answer, setAnswer }: AnswerProps) => {
  const classes = useStyles();

  return (
    <div className={classes.flexCol}>
      <div className={classes.selectWrapper}>
        <Select
          className={classes.select}
          value={answer}
          onChange={(option) => setAnswer(option)}
          placeholder='Your answer...'
        >
          {_.map(question.options, (option) => (
            <Select.Option value={option} key={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default DropDownAnswer;
