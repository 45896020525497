import { css } from '@emotion/react';
import { colors } from '@mysteryco/design';
import theme from 'theme';
import { Interpolation, Theme } from '@emotion/react';
import { useField } from 'formik';
import ModChat from '@mysteryco/design/icons/ModChat';
import ActivityBreak from '@mysteryco/design/icons/ActivityBreak';
import Toggle from 'glue/components/Toggle';

const NOTIFICATION_METADATA = {
  marketing: {
    formField: 'marketingConsent',
    title: 'Marketing',
    text: 'Stay informed about Glue’s product updates and be among the first to know about upcoming webinars, fireside sessions, and more.',
    icon: <ModChat size={28} color={colors.Plum_10} />,
    enabled: (marketingConsent) => marketingConsent,
    onChange: (event, setValue) => setValue(event.target.checked),
  },
  digest: {
    formField: 'optOutOfDigestAt',
    title: 'Digest',
    text: 'Get updates on your company’s connection health, activity engagement, and more with bi-weekly updates delivered directly to you.',
    icon: <ActivityBreak size={28} color={colors.Plum_10} />,
    enabled: (optOutOfDigestAt) => !optOutOfDigestAt,
    onChange: (event, setValue) => setValue(!event.target.checked ? new Date() : null),
  },
};

const NotificationToggle = ({
  field,
  style,
}: {
  field: 'marketing' | 'digest';
  style?: Interpolation<Theme>;
}) => {
  const [, meta, helpers] = useField({ name: NOTIFICATION_METADATA[field].formField });
  const { value } = meta;
  const { setValue } = helpers;
  return (
    <div css={[styles.root, style]}>
      <div css={styles.iconContainer}>{NOTIFICATION_METADATA[field].icon}</div>
      <div>
        <div css={styles.title}>{NOTIFICATION_METADATA[field].title}</div>
        <div css={styles.text}>{NOTIFICATION_METADATA[field].text}</div>
      </div>
      <div css={styles.toggle}>
        <div>{NOTIFICATION_METADATA[field].enabled(value) ? 'Enabled' : 'Disabled'}</div>
        <Toggle
          checked={NOTIFICATION_METADATA[field].enabled(value)}
          onChange={(event) => NOTIFICATION_METADATA[field].onChange(event, setValue)}
        />
      </div>
    </div>
  );
};

const styles = {
  root: css({
    display: 'flex',
    padding: `${theme.spacing(10)} 0`,
    gap: theme.spacing(5),
  }),
  iconContainer: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: colors.Plum_60,
    borderRadius: '18px',
    flex: '0 0 auto',
    height: '52px',
    width: '52px',
  }),
  title: css({
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '140%',
    color: colors.Coal_10,
    marginBottom: theme.spacing(2),
  }),
  text: css({
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '140%',
    color: colors.Coal_20,
  }),
  toggle: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    gap: theme.spacing(3),
    color: colors.Coal_20,
  }),
};

export default NotificationToggle;
