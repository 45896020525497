import React from 'react';
import { Colors, Sizes } from 'constants/index';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  asterisk: {
    color: Colors.Palette.Error,
    marginLeft: Sizes.SPACING_XSMALL,
  },
});

const RequiredAsterisk = () => {
  const classes = useStyles();
  return <span className={classes.asterisk}>*</span>;
};

export default RequiredAsterisk;
