import { gql } from '@apollo/client';
import { Paths } from 'Routes';
import { Loading } from 'components/core';
import MoralePulseIntro from 'glue/scenes/PulseSettings/MoralePulseIntro';
import { Redirect, Route, Switch } from 'react-router-dom';
import { usePulseSettingsQuery } from 'types';
import MoralePulseEdit from 'glue/scenes/PulseSettings/edit';

export function PulseSettings() {
  const { data, loading } = usePulseSettingsQuery({});
  const isPulseBetaEnabled = data?.viewer?.defaultOrganization?.isPulseBetaEnabled;

  if (loading) return <Loading />;

  if (!isPulseBetaEnabled) {
    return <Redirect to={Paths.HOME} />;
  }

  return (
    <Switch>
      <Route path={Paths.PULSE_SETTINGS} exact component={MoralePulseIntro} />
      <Route path={`${Paths.PULSE_SETTINGS}/edit`} component={MoralePulseEdit} />
    </Switch>
  );
}
PulseSettings.query = gql`
  query PulseSettings {
    viewer {
      id
      defaultOrganization {
        id
        isPulseBetaEnabled
      }
    }
  }
`;

export default PulseSettings;
