// @ts-strict-ignore
import { useCallback, useEffect, useState } from 'react';

function createStorageHook(storageSource: Storage) {
  // using a message event to trigger updates to the state
  // so that multiple calls to the hook will all update
  // when the storage is updated from one of them
  const updatedEvents = new EventTarget();

  return function useStorage<T>(
    key: string,
    defaultValue?: T,
  ): [T, (value: T, ttlMs?: number) => void] {
    const retrieveValue = useCallback(() => {
      const asString = storageSource.getItem(key);
      if (!asString) return defaultValue ?? null;

      const { value, expires } = JSON.parse(asString);

      const now = Date.now();
      if (expires && expires < now) return defaultValue ?? null;
      return value;
    }, [key, defaultValue]);

    const [val, setValue] = useState<T | null>(retrieveValue);

    useEffect(() => {
      const listener = (event: MessageEvent) => {
        if (event.data === key) {
          setValue(retrieveValue);
        }
      };

      updatedEvents.addEventListener(key, listener);
      return () => updatedEvents.removeEventListener(key, listener);
    }, [key, retrieveValue]);

    const updateValue = (newValue: T, ttlMs: number) => {
      setValue(newValue);
      storageSource.setItem(
        key,
        JSON.stringify({
          value: newValue,
          expires: ttlMs ? Date.now() + ttlMs : null,
        }),
      );
      updatedEvents.dispatchEvent(new MessageEvent(key, { data: key }));
    };

    return [val, updateValue];
  };
}

const useLocalStorage = createStorageHook(localStorage);
const useSessionStorage = createStorageHook(sessionStorage);

export { useLocalStorage, useSessionStorage };
