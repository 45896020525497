// @ts-strict-ignore
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CalendarIntegration from 'glue/scenes/Profile/Integrations/CalendarIntegration';

const Integrations = () => {
  const classes = useStyles();

  return (
    <Box className={classes.body}>
      <CalendarIntegration />
    </Box>
  );
};

const useStyles = makeStyles({
  body: {
    marginTop: '32px',
  },
});

export default Integrations;
