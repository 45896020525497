// @ts-strict-ignore
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import ChevronRight from '@mysteryco/design/icons/ChevronRight';
import {
  Glue_BorderLight,
  Glue_Ink00,
  Glue_LavenderLight,
  Glue_Mint00,
  Glue_Paper,
} from '@mysteryco/design/src/tokens/colors';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import React from 'react';
import theme from 'theme';
import { card } from './sharedStyles';

type IconProps = {
  backgroundColor: string;
};
interface Props {
  leadIcon?: React.ReactNode;
  iconBackground?: string;
  headline: React.ReactNode;
  teaser?: React.ReactNode;
  children: React.ReactNode;
  [key: string]: any;
}

export const CollapsibleCard = ({
  leadIcon,
  iconBackground = Glue_LavenderLight,
  headline,
  teaser,
  children,
}: Props) => (
  <AccordionItem value={headline.toString()}>
    <AccordionHeader>
      <AccordionTrigger asChild>
        <div>
          <StyledLeadIcon backgroundColor={iconBackground}>{leadIcon}</StyledLeadIcon>
          <HeadLine>
            <HeadLineTitle>{headline}</HeadLineTitle>
            {!!teaser && <HeadLineTeaser>{teaser}</HeadLineTeaser>}
          </HeadLine>
          <StyledChevronRight />
        </div>
      </AccordionTrigger>
    </AccordionHeader>
    <AccordionContent>
      <AccordionContentPadding>{children}</AccordionContentPadding>
    </AccordionContent>
  </AccordionItem>
);

export const CollapsibleCardGroup = styled(({ children, ...props }) => (
  <AccordionPrimitive.Root type='single' collapsible {...props}>
    {children}
  </AccordionPrimitive.Root>
))({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: '100%',
  gap: theme.spacing(8),
  overflow: 'hidden',
});

const AccordionItem = styled(AccordionPrimitive.Item)({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  ...card.root,
  '&:hover': {
    ...card.hover,
  },
  '&[data-state="open"]': card.hover,
});

const AccordionHeader = styled(AccordionPrimitive.Header)({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  alignItems: 'center',
  gap: theme.spacing(3),
});

const AccordionTrigger = styled(AccordionPrimitive.Trigger)({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  alignItems: 'center',
  gap: theme.spacing(2),
  backgroundColor: Glue_Paper,
  border: 0,
  cursor: 'pointer',
  padding: 24,
  paddingBottom: 18,
  margin: 0,
});

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-accordion-content-height)' },
});

const slideUp = keyframes({
  from: { height: 'var(--radix-accordion-content-height)' },
  to: { height: 0 },
});

const AccordionContent = styled(AccordionPrimitive.Content)({
  display: 'flex',
  flexDirection: 'column',

  overflow: 'hidden',
  boxSizing: 'border-box',

  fontSize: 16,

  '@media (prefers-reduced-motion: no-preference)': {
    '&[data-state="open"]': {
      animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
    },
    '&[data-state="closed"]': {
      animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
    },
  },
});

// separating the padding into a child element makes the sizing
// calculation and animation of the content element easier
const AccordionContentPadding = styled('div')({
  margin: `0px 64px 18px 96px`,
  paddingTop: 24,
  borderTop: `1px solid ${Glue_BorderLight}`,
});

export const StyledLeadIcon = styled.div<IconProps>(({ backgroundColor }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  width: 40,
  height: 40,
  borderRadius: '40px 40px 0px 40px',
  backgroundColor,
  '[data-state="open"] &': {
    backgroundColor: Glue_Mint00,
  },
}));

const HeadLine = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'flex-start',
  flex: 1,
  padding: `0 ${theme.spacing(5)}`,
});

const HeadLineTitle = styled.div({
  fontSize: 18,
  lineHeight: 1,
  fontWeight: 800,
  color: Glue_Ink00,
});

const HeadLineTeaser = styled.div({
  fontSize: 16,
  lineHeight: 1.6,
  fontWeight: 500,
  opacity: 0.7,
  color: Glue_Ink00,
  paddingTop: theme.spacing(0.5),
});

const StyledChevronRight = styled(ChevronRight)({
  color: Glue_Ink00,
  width: 20,
  height: 20,
  alignSelf: 'flex-start',
  transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
  '[data-state="open"] &': {
    transform: 'rotate(-90deg)',
  },
});
