import { gql, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ShareIcon from '@material-ui/icons/Share';
import { Purple100, Purple50 } from '@mysteryco/design/src/tokens/colors';
import { getTeamEventQuery } from 'gql/queries';
import { pluralize } from 'humanize-plus';
import { getEmailsOfGuests, getTotalGuests } from 'lib/helpers/booking';
import { titleCaseEnum } from 'lib/helpers/strings';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import { useState, useMemo } from 'react';
import DetailSection from 'scenes/BookedEvent/DetailSection';
import { TableRow } from 'semantic-ui-react';
import theme from 'theme';
import {
  InvitedGuestRsvpStatus,
  OrgSearchUserTagFragment,
  useInviteGuestsQuery,
} from '../types';
import PrimaryButton from './PrimaryButton';
import GuestDrawer from './SSFSteps/UploadGuests/GuestDrawer';
import { Loading, SnackbarUtils } from './core';
import BoldText from './core/BoldText';
import DropdownFilterSimple, {
  toOptionArray,
} from 'glue/components/inputs/DropdownFilterSimple';
import { GuestsType } from 'types/selfServeFlow';
import { audienceToGuests } from './SSFSteps/UploadGuests';

const DEFAULT_GUEST_STATE = { groups: [], otherGuests: [] };

const rsvpStatusToString = (rsvpStatus: InvitedGuestRsvpStatus): string => {
  if (rsvpStatus === InvitedGuestRsvpStatus.NeedsMoreInfo) {
    return 'RSVP In Progress';
  }
  return titleCaseEnum(rsvpStatus);
};

const ShareJoinLink = ({ joinLink, viewer }) => {
  return (
    <Box marginLeft='1rem'>
      <Button
        style={{ color: theme.palette.primary.main }}
        onClick={() => {
          mixpanel.track('join link copied', {
            source: 'details',
            customerType: viewer?.customerType,
            firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
          });
          SnackbarUtils.success('Share link copied to clipboard!');
          navigator.clipboard.writeText(joinLink);
        }}
      >
        <ShareIcon style={{ marginRight: '1rem' }} />
        Share
      </Button>
    </Box>
  );
};

const invitedGuestRsvpStatusMap = {
  [InvitedGuestRsvpStatus.Invited]: 'Invited',
  [InvitedGuestRsvpStatus.Accepted]: 'Accepted',
  [InvitedGuestRsvpStatus.Declined]: 'Declined',
};

const getRsvpOptions = () => {
  return toOptionArray([...new Set(Object.values(invitedGuestRsvpStatusMap))]);
};

const Guests = ({
  teamEventId,
  joinLink,
  isComplete,
  canInvite,
  expectedHeadCount,
  finalHeadCount,
  minimumEventSize,
  guests,
  showInvitedGuests = true,
}) => {
  const [filteredRsvpStatuses, setFilteredRsvpStatuses] = useState<string[]>([]);
  const [addUsersToTeamEventGuestList] = useMutation(
    AddUsersToTeamEventGuestListMutation,
  );
  const [removeGuestFromGuestList] = useMutation(
    RemoveUserFromTeamEventGuestListMutation,
  );
  const { data, loading } = useInviteGuestsQuery();
  const viewer = data?.viewer;
  const organization = _.first(viewer?.orgs);
  const classes = useStyles();
  const [newGuests, setNewGuests] = useState<GuestsType>(DEFAULT_GUEST_STATE);

  const [searchTags, setSearchTags] = useState([] as OrgSearchUserTagFragment[]);
  const [searchString, setSearchString] = useState('');
  const [inviteDrawerOpen, setInviteDrawerOpen] = useState(false);
  const totalGuests = getTotalGuests({ guests: newGuests });
  const [addGuestsLoading, setAddGuestsLoading] = useState(false);
  const rsvpOptions = useMemo(getRsvpOptions, []);

  if (loading) return <Loading />;

  const headcount = isComplete && finalHeadCount ? finalHeadCount : expectedHeadCount;
  const setAudience = (audience) => {
    setNewGuests(audienceToGuests(audience));
  };
  return (
    <Box>
      <div className={classes.filterRow}>
        <DropdownFilterSimple
          label='RSVP Status'
          options={rsvpOptions}
          value={filteredRsvpStatuses}
          onChange={(_, newValue) => {
            setFilteredRsvpStatuses(newValue);
          }}
          selectAllText={`Select all ${rsvpOptions?.length} status`}
          multiple
          disabled={loading}
        />
      </div>
      <DetailSection label='Guests'>
        {showInvitedGuests ? (
          <Box>
            <TableContainer>
              <Table className={classes.guestTable}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Box className={classes.infoCell}>
                        <Typography>
                          {isComplete && finalHeadCount
                            ? 'Final guests list'
                            : 'Expected guests'}
                        </Typography>
                        <BoldText>
                          {`${headcount} ${pluralize(headcount, 'guest')}`}
                        </BoldText>
                      </Box>
                    </TableCell>
                    {minimumEventSize != null && (
                      <TableCell>
                        <Box className={classes.infoCell}>
                          <Typography>Minimum event size</Typography>
                          <BoldText>{`${minimumEventSize} ${pluralize(
                            minimumEventSize,
                            'guest',
                          )}`}</BoldText>
                        </Box>
                      </TableCell>
                    )}
                    {!isComplete && <TableCell></TableCell>}
                  </TableRow>
                  <TableRow className={classes.guestHeader}>
                    <TableCell>Email</TableCell>
                    <TableCell>RSVP Status</TableCell>
                    {!isComplete && <TableCell></TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {guests
                    .filter((guest) => {
                      if (filteredRsvpStatuses.length === 0) {
                        return true;
                      }
                      return !!filteredRsvpStatuses.find(
                        (status) => status === guest.rsvpStatus,
                      );
                    })
                    .map((user) => (
                      <TableRow key={user.email} className={classes.rsvpRow}>
                        <TableCell>
                          <BoldText>{user.email}</BoldText>
                        </TableCell>
                        <TableCell>{rsvpStatusToString(user.rsvpStatus)}</TableCell>
                        {!isComplete && (
                          <TableCell align='right'>
                            <Typography
                              onClick={async () => {
                                mixpanel.track('guest removed', {
                                  source: 'details',
                                  customerType: viewer?.customerType,
                                  firstTimeBooker:
                                    viewer?.requestedTeamEvents?.length === 0,
                                });
                                user.email &&
                                  (await removeGuestFromGuestList({
                                    variables: {
                                      id: teamEventId,
                                      email: user.email,
                                    },
                                    refetchQueries: [
                                      {
                                        query: getTeamEventQuery,
                                        variables: {
                                          id: teamEventId,
                                          includeBilling: true,
                                        },
                                      },
                                    ],
                                  }));
                              }}
                              className={classes.removeButton}
                            >
                              Remove
                            </Typography>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <Box className={classes.placeholder}>
            <Box className={classes.placeholderText}>
              <Typography>
                Once we confirm host availability, you’ll be able to invite guests and
                view RSVPs.
              </Typography>
            </Box>
            <Box className={classes.placeholderInfo}>
              <Box className={classes.infoCell}>
                <Typography>Expected</Typography>
                <BoldText>{`${headcount} ${pluralize(headcount, 'guest')}`}</BoldText>
              </Box>
              {minimumEventSize != null && (
                <Box className={classes.infoCell}>
                  <Typography>Minimum event size</Typography>
                  <BoldText>{`${minimumEventSize} ${pluralize(
                    minimumEventSize,
                    'guest',
                  )}`}</BoldText>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </DetailSection>
      {canInvite && showInvitedGuests && (
        <Box display='flex' justifyContent='space-between' mt={4}>
          <ShareJoinLink joinLink={joinLink} viewer={viewer} />
          <PrimaryButton
            style={{ maxWidth: 150 }}
            label={`Add guests`}
            onClick={() => setInviteDrawerOpen(true)}
          />
        </Box>
      )}
      <GuestDrawer
        organization={organization}
        searchTags={searchTags}
        setSearchTags={setSearchTags}
        setSearchString={setSearchString}
        searchString={searchString}
        setGuests={setNewGuests}
        setAudience={setAudience}
        currentGuests={newGuests}
        closeDrawer={() => setInviteDrawerOpen(false)}
        drawerOpen={inviteDrawerOpen}
        cta={async () => {
          setAddGuestsLoading(true);
          mixpanel.track('guests added', {
            source: 'details',
            customerType: viewer?.customerType,
            firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
          });
          await addUsersToTeamEventGuestList({
            variables: {
              id: teamEventId,
              emails: getEmailsOfGuests({ guests: newGuests }),
            },
            refetchQueries: [
              {
                query: getTeamEventQuery,
                variables: { id: teamEventId, includeBilling: true },
              },
            ],
          });
          setAddGuestsLoading(false);
          setInviteDrawerOpen(false);
          setNewGuests(DEFAULT_GUEST_STATE);
        }}
        ctaText={`Invite ${totalGuests} guests`}
        ctaLoading={addGuestsLoading}
      />
    </Box>
  );
};
Guests.query = gql`
  query InviteGuests {
    viewer {
      id
      customerType
      requestedTeamEvents {
        id
      }
      orgs {
        id
        ...GuestDrawerOrganization
      }
    }
  }
  ${GuestDrawer.fragments}
`;

const useStyles = makeStyles((theme) => ({
  addGuestButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
  },
  emptyState: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1rem',
  },
  filterRow: {
    display: 'flex',
    marginBottom: theme.spacing(8),
    gap: theme.spacing(4),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  removeButton: {
    marginRight: '2rem',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  inputSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '2rem',
  },
  guestHeader: {
    background: Purple50,
    border: `solid ${Purple100}`,
    borderWidth: '2px 0',
    '& .MuiTableCell-head': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  infoCell: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  rsvpRow: {
    borderBottom: `2px solid ${Purple100}`,
    '&:last-child': {
      borderColor: 'transparent',
    },
  },
  guestTable: {
    '& *': {
      fontSize: '.875rem',
    },
  },
  placeholder: {
    '& *': {
      fontSize: '.875rem',
    },
  },
  placeholderText: {
    padding: theme.spacing(4),
    borderBottom: `2px solid ${Purple100}`,
  },
  placeholderInfo: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(4),
    gap: theme.spacing(8),
    '& *': {
      flexGrow: 1,
    },
  },
}));

const RemoveUserFromTeamEventGuestListMutation = gql`
  mutation RemoveUserFromTeamEventGuestList($id: ID!, $email: String!) {
    removeUserFromTeamEventGuestList(id: $id, email: $email) {
      id
    }
  }
`;

const AddUsersToTeamEventGuestListMutation = gql`
  mutation BulkAddUsersToTeamEventGuestList($id: ID!, $emails: [String]!) {
    bulkAddUsersToTeamEventGuestList(id: $id, emails: $emails) {
      id
      invitedGuests {
        email
        user {
          id
        }
      }
    }
  }
`;

export default Guests;
