export const emailRegex = /[a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+/g;
export const getParsedEmails = (emailString: string): string[] => {
  const matches = [...emailString.matchAll(emailRegex)];
  const emails = matches.map((match) => match[0]);

  return emails;
};

export const getUpsellLink = (location) =>
  `https://go.glue.co/upgrade-plan?location=${location}`;

export const SUPPORT_EMAIL = 'support@glue.co';
export const CLIENT_SUCCESS_EMAIL = 'customersuccess@trymystery.com';

export const PRIVACY_POLICY_URL = 'https://www.glue.co/privacy';
export const TOS_URL = 'https://www.glue.co/terms-service';
