/**
 * Utility component that allows you to place any icon inside a circle
 */

import { Box } from '@material-ui/core';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  circle: ({ backgroundColor, padding }: any) => ({
    borderRadius: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    padding,
    backgroundColor,
  }),
});

export const EncircleIcon = ({
  backgroundColor,
  children,
  padding = '8px',
  className,
}: {
  backgroundColor: string;
  children: JSX.Element;
  padding?: string;
  className?: string;
}) => {
  const classes = useStyles({ backgroundColor, padding });
  return <Box className={cx(classes.circle, className)}>{children}</Box>;
};
