// @ts-strict-ignore
import { useQuery } from '@apollo/client';
import { Box } from '@material-ui/core';
import LoggedIn from 'components/Checkout/LoggedIn';
import LoginButton from 'components/Checkout/LoginButton';
import { NotFoundError } from 'components/core';
import MysteryHeader from 'components/MysteryHeader';
import { getEmailsOfGuests } from 'lib/helpers/booking';
import { formStateFromString } from 'lib/helpers/serialization';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import { SelfServeContext } from 'providers/SelfServeProvider';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useViewer } from 'utils/state';
import MysteryStepper from '../components/MysteryStepper';

import * as CalendarSync from '../components/SSFSteps/CalendarSync';
import * as EventSelection from '../components/SSFSteps/EventSelection';
import * as Login from '../components/SSFSteps/Login';
import * as Payment from '../components/SSFSteps/Payment';
import * as Summary from '../components/SSFSteps/Summary';
import * as UploadGuests from '../components/SSFSteps/UploadGuests';

import { getOptions } from '../gql/queries';
import useBookingSession from '../hooks/useBookingSession';
import useSelfServeValidation from '../hooks/useSelfServeValidation';
import {
  getCurrentStep,
  navigateToFirstStep,
  navigateToNextStep,
  useQueryParams,
} from '../lib/helpers/router';
import {
  EventDetailsType,
  SelfServeStep,
  SelfServeStepValidationMap,
} from '../types/selfServeFlow';
import { NylasConnectionState } from 'types';

const makeEventDetails = (eventDetails: EventDetailsType): string[] => {
  return eventDetails
    ? [
        eventDetails.interactiveOrEntertaining,
        eventDetails.hasPhysicalGoods ? 'hasPhysicalGoods' : 'noPhysicalGoods',
      ]
    : null;
};

const DEFAULT_VALIDATION_STATE = {
  budget: false,
  eventDetails: false,
  account: false,
  eventDate: false,
  eventSelection: false,
  summary: false,
  guests: true,
  payment: false,
};
const validateKey = ({ state, schema }) => {
  if (!schema) return true;
  try {
    schema.validateSync(state);
  } catch (e) {
    return false;
  }
  return true;
};

export default function SelfServeFlow() {
  const viewer = useViewer();
  const { state, setState } = useContext(SelfServeContext);
  const { step } = useParams<{ step: string }>();
  const params = useQueryParams();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(step);
  const [steps, setSteps] = useState<SelfServeStep[]>(null);
  const [stepValidation, setStepValidation] = useState<SelfServeStepValidationMap>(
    DEFAULT_VALIDATION_STATE,
  );

  const { data: optionsData } = useQuery(getOptions);

  const validateStep = React.useCallback(({ key, state, schema, forceValidation }) => {
    const valid = _.isUndefined(forceValidation)
      ? validateKey({ state: _.pick(state, key), schema })
      : forceValidation;
    setStepValidation((stepValidation) => ({
      ...stepValidation,
      [key]: valid,
    }));
    return valid;
  }, []);

  useSelfServeValidation({
    activeStep,
    steps: steps || [],
    globalState: state,
    validateStep,
  });
  const options = _.get(optionsData, 'bookingSessionOptions');

  const setStepsBasedOnViewer = React.useCallback((): void => {
    const stepsToShow = _.compact([
      !viewer && { key: 'login', value: Login },
      { key: 'guests', value: UploadGuests },
      viewer?.nylasConnectionState === NylasConnectionState.Inactive && {
        key: 'calendar_sync',
        value: CalendarSync,
      },
      { key: 'event_selection', value: EventSelection },
      { key: 'summary', value: Summary },
      { key: 'payment', value: Payment },
    ]);
    setSteps(stepsToShow);
  }, [viewer, state]);

  useEffect(() => {
    // This useEffect unpacks form state from a url query param. This is used
    // when a new/unauthed user enters the flow, completes several steps, then auths.
    const crushed = params.get('session');
    if (crushed) {
      setState(formStateFromString(crushed));
      // This strips the query param from the browser's url without refreshing the
      // page, ensuring there's only one source of truth for state.
      window.history.replaceState(
        window.history.state,
        document.title,
        window.location.origin + window.location.pathname,
      );
    }
    mixpanel.track('self service flow started', {
      customerType: viewer?.customerType,
      firstTimeBooker: viewer?.requestedTeamEvents.length === 0,
    });
  }, []);

  useEffect(() => {
    setStepsBasedOnViewer();
    setActiveStep(step);

    // Track these links here since useEffect is called too many times on the lower level components.
    mixpanel.track_links('#see-all-events', 'see all events form opened', {
      source: 'size selection',
      customerType: viewer?.customerType,
      firstTimeBooker: viewer?.requestedTeamEvents.length === 0,
    });
    mixpanel.track_links('#home-link', 'trymystery.com navigated to', {
      source: 'self service flow',
      customerType: viewer?.customerType,
      firstTimeBooker: viewer?.requestedTeamEvents.length === 0,
    });
  }, [viewer, setStepsBasedOnViewer, step, state.isRequestToBook]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  useBookingSession({
    dateWindows: !_.isEmpty(_.get(state, `eventDate.availableEventTimes`))
      ? _.map(
          _.filter(
            _.get(state, `eventDate.windows`),
            (window) => !!window.startTime && !!window.endTime,
          ),
          (time) => ({ startTime: time.start, endTime: time.end }),
        )
      : undefined,
    budgetCents: _.isNumber(_.get(state, `budget.maxCents`))
      ? _.get(state, `budget.maxCents`)
      : undefined,
    guestEmails: getEmailsOfGuests({
      guests: _.get(state, `uploadGuests.guests`),
    }),
    requester: viewer ? _.pick(viewer, 'id') : undefined,
    optionsPresented: _.get(state, 'optionsPresented'),
    selectedEventDetails: makeEventDetails(_.get(state, 'eventDetails')),
    teamEvent: _.get(state, 'bookedEvent')
      ? { id: _.get(state, 'bookedEvent.id') }
      : undefined,
  });

  const resetGlobalState = () => {
    setState({});
    setStepValidation(DEFAULT_VALIDATION_STATE);
  };

  if (!steps) return null;
  const currentStep = _.get(getCurrentStep({ steps, activeStep }), 'value');

  if (_.isEmpty(state) && activeStep !== steps[0].key) {
    navigateToFirstStep({ history, steps });
    return <></>;
  }

  if (currentStep) {
    const { title, Content, key } = currentStep;
    return (
      <Box>
        <MysteryHeader>
          {viewer ? <LoggedIn viewer={viewer} /> : <LoginButton globalState={state} />}
        </MysteryHeader>

        <MysteryStepper
          steps={steps}
          activeStep={activeStep}
          stepValidation={stepValidation}
          globalState={state}
        />
        <Content
          options={_.get(options, key, [])}
          value={title !== 'Event Selection' ? state[key] || {} : state}
          setValue={(value, alternateKey) =>
            setState((prevState) => ({ ...prevState, [alternateKey || key]: value }))
          }
          goToNextStep={(options) =>
            navigateToNextStep({ history, activeStep, steps }, options)
          }
          globalState={state}
          steps={steps}
          activeStep={activeStep}
          stepIsValid={stepValidation[key]}
          resetGlobalState={resetGlobalState}
          key={key}
          viewer={viewer}
        />
      </Box>
    );
  } else {
    return <NotFoundError />;
  }
}
