import React from 'react';
import Text from './Text';

const LabeledField = (props: {
  label: string;
  children: React.ReactNode;
  className?: string;
}) => {
  const { label, children, className, ...otherProps } = props;
  return (
    <div className={className} {...otherProps}>
      <Text type='field-label' noMargin>
        {label}
      </Text>
      {children}
    </div>
  );
};

export default LabeledField;
