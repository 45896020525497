import Loading02 from '@mysteryco/design/icons/Loading02';
import { colors } from '@mysteryco/design/src';
import { StyledLeadIcon } from 'glue/components/cards/CollapsibleCard';
import ProgressPage from 'glue/components/structure/ProgressPage';
import MoralePulseStepWrapper from './MoralePulseStepWrapper';

const strings = {
  title: 'Enabling Pulse surveys',
  subTitle: `You're moments away from being able to getting better signal from your teams`,
  enabledTitle: 'Saving changes',
  enabledSubtitle: 'We’ll apply your changes to upcoming surveys',
};

interface MoralePulseLoaderProps {
  next: () => void;
  isEnabling: boolean;
}

const MoralePulseLoader = ({ next, isEnabling }: MoralePulseLoaderProps) => {
  return (
    <MoralePulseStepWrapper disableClose>
      <ProgressPage
        title={isEnabling ? strings.title : strings.enabledTitle}
        subTitle={isEnabling ? strings.subTitle : strings.enabledSubtitle}
        onFinish={next}
        heroIcon={
          <StyledLeadIcon backgroundColor={colors.Glue_Mint00}>
            <Loading02 size={22} color={colors.Glue_Ink00} />
          </StyledLeadIcon>
        }
      />
    </MoralePulseStepWrapper>
  );
};

export default MoralePulseLoader;
