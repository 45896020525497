export const NONE = 0;

export const TWELVE = 12;
export const TWENTY = 20;

export const GRID_UNIT = 8;
export const SUB_GRID_UNIT = 4;

export const BUTTON_BORDER_RADIUS = 30;

export const spacing = (factor) => `${0.25 * factor}rem`;

export const SPACING_XXSMALL = SUB_GRID_UNIT;
export const SPACING_XSMALL = GRID_UNIT;
export const SPACING_SMALL = TWELVE;
export const SPACING_NORMAL = GRID_UNIT * 2;
export const SPACING_MEDIUM = GRID_UNIT * 3;
export const SPACING_LARGE = GRID_UNIT * 4;
export const SPACING_XLARGE = GRID_UNIT * 6;
