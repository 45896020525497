import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import {
  generatePath,
  matchPath,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { Paths } from 'Routes';
import { SelfServeStep } from '../../types/selfServeFlow';

export type NavigateToNextStepOptions = {
  replace?: boolean;
};

export const useQueryParams = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

/**
 * Allows use of a search param as state
 *
 * Optionally, you may also provide a transform if you are dealing with values
 * other than strings.
 */
export const useQueryParam = <TValue = string>(
  param: string,
  defaultValue?: TValue | (() => TValue),
  transform?: {
    toString: (value: TValue | undefined) => string;
    fromString: (raw: string) => TValue | undefined;
  },
): [TValue | undefined, (newValue?: TValue) => unknown, (value?: TValue) => string] => {
  const history = useHistory();
  // Ok, so this is a bit counter-intuitive, but we HOOK location so that we
  // are subscribed to updates to the navigator's location.
  useLocation();
  // However, we READ directly from the navigator's `window.location` (below)
  // to make sure that we're not bashing over values that are modified in the
  // same React render loop.
  const params = new URLSearchParams(window.location.search);
  const stringValue: string | undefined = params.get(param) ?? undefined;
  let value: TValue | undefined;
  if (stringValue !== undefined) {
    value = transform
      ? transform.fromString(stringValue)
      : (stringValue as any as TValue);
  }

  if (value === undefined) {
    value =
      typeof defaultValue === 'function'
        ? (defaultValue as () => TValue)()
        : defaultValue;
  }

  const urlForQueryParam = useMemo(() => {
    return function urlForQueryParam(value?: TValue) {
      // Note that we intentionally use window.location here to ensure that we
      // are always referencing the latest value (synchronously).
      const currentParams = new URLSearchParams(window.location.search);
      if (value) {
        currentParams.set(
          param,
          transform ? transform.toString(value) : (value as any as string),
        );
      } else {
        currentParams.delete(param);
      }

      let url = window.location.pathname;

      const search = currentParams.toString();
      if (search !== '') {
        url += `?${search}`;
      }

      if (window.location.hash) {
        url += window.location.hash;
      }

      return url;
    };
  }, [param, transform]);

  const setQueryParam = useMemo(() => {
    return function setQueryParam(newValue?: TValue) {
      history.replace(urlForQueryParam(newValue));
    };
  }, [history, urlForQueryParam]);

  // Ensure that the actual param lines up with the value we are set to.

  const targetValue = transform ? transform.toString(value) : value;
  useEffect(() => {
    if (stringValue !== targetValue) {
      setQueryParam(value);
    }
  }, [stringValue, targetValue, setQueryParam, value]);

  return [value, setQueryParam, urlForQueryParam];
};

/**
 * Matches the current path to the routes we have defined in our routes.
 */
export const useCurrentRoute = () => {
  const { pathname } = useLocation();
  const allRoutes = useMemo(
    () => Object.values(Paths).sort((a, b) => b.localeCompare(a)),
    [Paths],
  );

  const currentRoute = useMemo(
    () => allRoutes.find((r) => matchPath(pathname, { path: r })),
    [allRoutes, pathname],
  );

  return currentRoute;
};

/**
 * Allows use of a path param as state
 *
 * Optionally, you may also provide a transform if you are dealing with values
 * other than strings.
 */
export const usePathParam = <TValue = string>(
  param: string,
  transform?: {
    toString: (value: TValue) => string;
    fromString: (raw: string) => TValue | undefined;
  },
): [TValue | undefined, (newValue?: TValue) => unknown, (value?: TValue) => string] => {
  const history = useHistory();
  const currentRoute = useCurrentRoute();
  const params = useParams();

  useEffect(() => {
    if (currentRoute?.includes(`:${param}`)) return;
    console.warn(
      `The current route (${currentRoute}) does not include :${param}. usePathParam will have no effect`,
    );
  }, [param, currentRoute]);

  const stringValue = params[param];
  let value: TValue | undefined;
  if (stringValue !== undefined && stringValue !== null) {
    value = transform
      ? transform.fromString(stringValue)
      : (stringValue as any as TValue);
  }

  const urlForPathParam = useMemo(() => {
    return function urlForPathParam(value?: TValue) {
      let stringValue: string | undefined;
      if (value !== undefined) {
        stringValue = transform ? transform.toString(value) : (value as any as string);
      }

      // Note that we intentionally use window.location here to ensure that we
      // are always referencing the latest value (synchronously).
      const { params } = matchPath(window.location.pathname, currentRoute || '/') || {};
      let url = generatePath(currentRoute || '/', { ...params, [param]: stringValue });
      url += window.location.search;
      if (window.location.hash) {
        url += window.location.hash;
      }

      return url;
    };
  }, [param, currentRoute]);

  const setPathParam = useMemo(() => {
    return function setPathParam(newValue?: TValue) {
      history.push(urlForPathParam(newValue));
    };
  }, [history, urlForPathParam]);

  return [value, setPathParam, urlForPathParam];
};

export const getCurrentStepIndex = ({ activeStep, steps }) =>
  steps.findIndex((v) => v.key === activeStep);

export const navigateToNextStep = (
  { history, steps, activeStep },
  options?: NavigateToNextStepOptions,
) => {
  const currentStepIndex = getCurrentStepIndex({ activeStep, steps });
  const nextStepKey = _.get(steps[currentStepIndex + 1], 'key');
  const destination = `/book/${nextStepKey}`;
  if (options) {
    if (options.replace) return history.replace(destination);
  }
  return history.push(destination);
};

export const navigateToFirstStep = ({ history, steps }) => {
  const firstStepKey = _.get(steps[0], 'key');
  const destination = `/book/${firstStepKey}`;
  return history.replace(destination);
};

export const getCurrentStep = ({ steps, activeStep }): SelfServeStep => {
  return steps.find((v) => v.key === activeStep);
};

// From https://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen
export const popupCenter = ({ url, title, w, h }) => {
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
    scrollbars=no,
    width=${w / systemZoom},
    height=${h / systemZoom},
    top=${top},
    left=${left}
    `,
  );

  // I refactored this since it was originally meant as a conditional,
  // but window.focus is never undefined - not sure what the original intention of this SO code was
  const unknownCondition: boolean = !!window.focus;
  if (unknownCondition) newWindow?.focus();

  return newWindow;
};
