import React from 'react';
import { useQuery, gql } from '@apollo/client';

import { Loading, Page } from 'components/core';

const strings = {
  TERMS_OF_SERVICE: `Terms of Service`,
};

const latestTermsOfServiceWithContentQuery = gql`
  query getLatestTermsOfServiceWithContent {
    legalDocument(type: TermsOfService) {
      id
      content
    }
  }
`;

export function TermsOfService() {
  const { data, loading } = useQuery(latestTermsOfServiceWithContentQuery);
  if (loading) return <Loading />;

  return (
    <Page title={strings.TERMS_OF_SERVICE}>
      <div dangerouslySetInnerHTML={{ __html: data!.legalDocument!.content }} />
    </Page>
  );
}
