// @ts-strict-ignore
import { gql } from '@apollo/client';
import { Loading } from 'components/core';
import { client } from 'gql/client';
import { useQueryParams } from 'lib/helpers/router';
import mixpanel from 'mixpanel-browser';
import { useEffect, useState } from 'react';
import { Step, Steps, Wizard } from 'react-albus';
import { Redirect, useHistory } from 'react-router-dom';
import { Paths } from 'Routes';
import {
  useAddUserToOrgDuringSignUpMutation,
  useGetJustViewerIdLazyQuery,
  useGetOrganizationLazyQuery,
  useGetOrgByLinkPathQuery,
  useUpdateUserAuthMutation,
} from 'types';
import { useViewer } from 'utils/state';
import EmailInputScreen from './EmailInputScreen';
import NewUserScreen from './NewUserScreen';
import OTPScreen from './OTPScreen';

const getTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

const Auth = () => {
  const viewer = useViewer();
  const history = useHistory();
  const [isNewUser, setIsNewUser] = useState(false);
  const [registering, setRegistering] = useState(false);
  const [accountInfo, setAccountInfo] = useState({});
  const params = useQueryParams();
  const redirect = params.get('redirect');
  const orgPath = params.get('org');
  const source = params.get('source');
  const [updateUser] = useUpdateUserAuthMutation();
  const [addUserToOrg] = useAddUserToOrgDuringSignUpMutation();
  const [getOrgsForViewer] = useGetOrganizationLazyQuery();
  const [getJustViewerId] = useGetJustViewerIdLazyQuery({ fetchPolicy: 'network-only' });

  const { data: orgData, loading: orgLoading } = useGetOrgByLinkPathQuery({
    variables: { path: `/${orgPath}` },
    skip: !orgPath,
  });
  const org = orgData?.getOrgByLinkPath;

  useEffect(() => {
    mixpanel.track('sign up page loaded', {
      source:
        source === 'virtual-events'
          ? 'https://www.trymystery.com/virtual-events/'
          : undefined,
      customerType: viewer?.customerType,
    });
  }, []);

  async function completeRegistration() {
    const { data, loading } = await getJustViewerId();
    if (loading) return <Loading />;

    const viewer = data?.viewer;
    if (!viewer) throw new Error('Registration unsuccessful');

    setRegistering(true);
    await updateUser({
      variables: {
        ...accountInfo,
        timezone: getTimezone(),
        id: viewer.id,
      },
    });

    if (org) {
      await addUserToOrg({ variables: { userId: viewer.id, orgId: org.id } });
      await getOrgsForViewer();
    }
    setRegistering(false);
  }

  if (registering || orgLoading) return <Loading />;

  return (
    <>
      {/*
       * Note that we want to wait before the updateUser mutation (called by
       * OTPScreen) completes, so that downstream wizards (e.g. AccountSetup)
       * can make decent assumptions about the state of the viewer.
       *
       * So, for now, we're using `hasAgreedToLatestTOS` to indicate a full
       * enough viewer.
       */}
      {!orgLoading && viewer?.email && <Redirect to={redirect || Paths.HOME} />}
      <Wizard history={history} basename={Paths.AUTH}>
        <Steps>
          <Step
            id='signup'
            render={(wizard) => (
              <EmailInputScreen
                title={`Hello there`}
                subTitle={'Log in to your account'}
                wizard={wizard}
                setAccountInfo={setAccountInfo}
                setIsNewUser={setIsNewUser}
                org={org}
              />
            )}
          />
          <Step
            id='account-info'
            render={({ next }) => (
              <NewUserScreen
                org={org}
                next={next}
                accountInfo={accountInfo}
                setAccountInfo={setAccountInfo}
              />
            )}
          />
          <Step
            id='otp-input'
            render={() => (
              <OTPScreen
                org={org}
                accountInfo={accountInfo}
                onComplete={async () => {
                  if (isNewUser) {
                    await completeRegistration();
                  }
                  await client.refetchQueries({ include: ['getViewer'] });
                  history.push(redirect || Paths.HOME);
                }}
              />
            )}
          />
        </Steps>
      </Wizard>
    </>
  );
};

Auth.mutations = {
  addUserToOrgDuringSignUp: gql`
    mutation addUserToOrgDuringSignUp($userId: ID!, $orgId: ID!) {
      addMemberToOrganization(orgId: $orgId, userId: $userId) {
        id
      }
    }
  `,
  updateNewUser: gql`
    mutation updateUserAuth(
      $id: ID!
      $firstName: String
      $lastName: String
      $email: String
      $companyRole: String
      $marketingConsent: Boolean
      $timezone: String
    ) {
      updateUser(
        id: $id
        firstName: $firstName
        lastName: $lastName
        email: $email
        companyRole: $companyRole
        marketingConsent: $marketingConsent
        timezone: $timezone
      ) {
        id
      }
      acceptLatestLegalAgreements {
        id
      }
    }
  `,
};

Auth.queries = {
  getJustViewerId: gql`
    query getJustViewerId {
      viewer {
        id
      }
    }
  `,
  getOrgByLinkPath: gql`
    query getOrgByLinkPath($path: String!) {
      getOrgByLinkPath(path: $path) {
        id
        name
        logoUrl
      }
    }
  `,
};

export default Auth;
