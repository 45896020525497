import theme from 'theme';

const StarFull = ({
  size = 20,
  color = theme.palette.primary.main,
  style = {},
  className = '',
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      style={style}
    >
      <path
        d='M10.538 1.47972C10.1977 1.31747 9.80233 1.31747 9.46203 1.47972C9.16785 1.61998 9.00565 1.86351 8.92386 1.99712C8.8393 2.13523 8.7525 2.31117 8.66789 2.48266L6.87145 6.12207L2.85307 6.70941C2.66391 6.73702 2.46982 6.76535 2.31239 6.8032C2.16009 6.83983 1.8785 6.91903 1.65441 7.15557C1.39517 7.42921 1.27325 7.80522 1.3226 8.17892C1.36526 8.50194 1.54679 8.73132 1.64863 8.85034C1.75389 8.97337 1.89443 9.11021 2.0314 9.24356L4.9379 12.0745L4.25209 16.073C4.21972 16.2616 4.18651 16.455 4.17372 16.6165C4.16135 16.7726 4.1494 17.0651 4.30498 17.3515C4.48495 17.6828 4.80484 17.9152 5.17557 17.9839C5.49607 18.0433 5.77048 17.9416 5.91517 17.8815C6.0648 17.8195 6.23848 17.7281 6.40778 17.639L10 15.7499L13.5922 17.639C13.7615 17.7281 13.9352 17.8195 14.0848 17.8815C14.2295 17.9416 14.5039 18.0433 14.8244 17.9839C15.1952 17.9152 15.5151 17.6828 15.695 17.3515C15.8506 17.0651 15.8386 16.7726 15.8263 16.6165C15.8135 16.455 15.7803 16.2616 15.7479 16.0731L15.0621 12.0745L17.9686 9.24353C18.1056 9.11018 18.2461 8.97336 18.3514 8.85034C18.4532 8.73132 18.6347 8.50194 18.6774 8.17892C18.7268 7.80522 18.6048 7.42921 18.3456 7.15557C18.1215 6.91903 17.8399 6.83983 17.6876 6.8032C17.5302 6.76535 17.3361 6.73702 17.1469 6.70941L13.1286 6.12206L11.3321 2.48272C11.2475 2.31122 11.1607 2.13524 11.0761 1.99712C10.9943 1.86351 10.8321 1.61998 10.538 1.47972Z'
        fill={color}
      />
    </svg>
  );
};

export default StarFull;
