// @ts-strict-ignore
import { Box, ClickAwayListener, Popper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@mysteryco/design/src';
import { useState } from 'react';
import theme from 'theme';
import FilterPill from './FilterPill';
import OptionMenu, { Option, OptionMenuProps } from '../SelectDropdown/OptionMenu';

export interface SelectFilterOption extends Option {
  name?: string;
}

interface SelectFilterProps extends OptionMenuProps {
  label: string;
  options: SelectFilterOption[];
  value: string[];
  onChange?: (event: any, newValue: string[]) => void;
  disabled?: boolean;
  renderOption?: (SelectFilterOption) => JSX.Element;
  [key: string]: any;
}

const pillValue = (selected: any[], options: any[]): string => {
  if (!options.length) return 'None';
  const selectedLabel = !!selected?.length ? selected.length : options.length;
  return `${selectedLabel} of ${options.length}`;
};

const SelectFilter = ({
  label,
  options = [],
  value = [],
  onChange = () => null,
  disabled = false,
  helperText = '',
  flipEnabled = false,
  ...props
}: SelectFilterProps) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: any) => {
    if (!anchorEl && !disabled) {
      setAnchorEl(event?.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const defaultRenderOption = (option: SelectFilterOption) => {
    return <Box className={classes.option}>{option.name}</Box>;
  };

  const renderOption = props.renderOption || defaultRenderOption;
  const dropdownOpen = Boolean(anchorEl);

  return (
    <Box>
      <FilterPill
        onClick={handleClick}
        label={label}
        value={pillValue(value, options)}
        expanded={dropdownOpen}
        disabled={disabled || !options.length}
      />
      <Popper
        className={classes.dropdown}
        open={dropdownOpen}
        anchorEl={anchorEl}
        disablePortal
        placement={'bottom-start'}
        modifiers={{ flip: { enabled: flipEnabled } }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <OptionMenu
            options={options}
            values={value}
            onChange={onChange}
            helperText={helperText}
            renderOption={renderOption}
            optionsContainerProps={{ className: classes.container }}
            editContainerProps={{ className: classes.container }}
            multiple
            {...props}
          />
        </ClickAwayListener>
      </Popper>
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    width: '20rem',
  },
  option: {
    fontWeight: 500,
    fontSize: '.875rem',
    lineHeight: 1.71,
    color: colors.Black,
  },
  dropdown: {
    marginTop: theme.spacing(2),
    zIndex: theme.zIndex.modal,
    boxShadow:
      '0px 3px 3px rgba(123, 23, 131, 0.09), 0px 1px 1px rgba(123, 23, 131, 0.1), 0px 10px 20px rgba(91, 15, 97, 0.1)',
    borderRadius: theme.spacing(1),
    border: `1px solid ${colors.LightGray}`,
    background: colors.White,
  },
});

export default SelectFilter;
