import { Button } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { archiveOrgMembers, restoreOrgMembers } from 'gql/interactions';
import { pluralize } from 'humanize-plus';

interface ArchiveButtonOriginalProps {
  afterUpdate: (alertMessage: string) => void;
  classes: ClassNameMap;
  memberIds: string[];
  organizationId: string;
  restoreMode: boolean;
}

const ArchiveButtonOriginal = ({
  afterUpdate,
  classes,
  memberIds,
  organizationId,
  restoreMode,
}: ArchiveButtonOriginalProps) => {
  const buttonSuffix = memberIds.length > 1 ? ' members' : '';
  const alertPrefix = pluralize(memberIds.length, 'Member');
  const className = restoreMode? classes.buttonPrimary : classes.buttonSecondary;
  const onClick = restoreMode
    ? async () => await restoreOrgMembers(organizationId, memberIds, ['searchOrgUsers'])
    : async () => await archiveOrgMembers(organizationId, memberIds, ['searchOrgUsers']);
  return (
    <Button
      className={className}
      onClick={async () => {
        await onClick();
        afterUpdate(
          restoreMode ? alertPrefix + ' unarchived!' : alertPrefix + ' archived!',
        );
      }}
    >
      {restoreMode ? 'Unarchive' + buttonSuffix : 'Archive' + buttonSuffix}
    </Button>
  );
};

export default ArchiveButtonOriginal;