// @ts-strict-ignore
import { Box, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RSVP_BACKGROUND from 'assets/rsvp-background.png';
import CalendarSyncButtons from 'components/CalendarSyncButtons';
import TeamEventDetail from 'components/TeamEventDetail';
import { Loading } from 'components/core';
import NewPrimaryButton from 'components/core/NewPrimaryButton';
import ArrowRight from 'components/icons/ArrowRight';
import CheckVerified02 from 'components/icons/CheckVerified02';
import { client } from 'gql/client';
import { addUserToTeamEvent } from 'gql/mutations';
import { useQueryParams } from 'lib/helpers/router';
import mixpanel from 'mixpanel-browser';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import theme from 'theme';
import { NylasConnectionState, ViewerFragmentFragment } from 'types';

const Confirmation = ({ teamEvent, viewer }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const params = useQueryParams();
  const rsvpLink = params.get('rsvpLink');
  const askFeedbackEvent = viewer?.askFeedbackEvent;
  const nylasConnectionState = viewer?.nylasConnectionState;
  const showActionItems =
    askFeedbackEvent || nylasConnectionState === NylasConnectionState.Inactive;

  useEffect(() => {
    if (rsvpLink) {
      history.replace(rsvpLink);
    }
  }, [rsvpLink]);

  const confirmBooking = async () => {
    setLoading(true);
    await Promise.all([
      client.mutate({
        mutation: addUserToTeamEvent,
        variables: {
          teamEventId: teamEvent.id,
          userId: viewer.id,
        },
      }),
    ]);
    setLoading(false);
  };

  useEffect(() => {
    mixpanel.track('booking confirmed', {
      source: 'registration',
      customerType: viewer?.customerType,
      firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
    });
    confirmBooking();
  }, []);

  if (loading) return <Loading />;

  const FeedbackActionItem = ({ viewer }: { viewer: ViewerFragmentFragment }) => {
    const askFeedbackEvent = viewer.askFeedbackEvent;
    const history = useHistory();
    return (
      <Box>
        <Box my={4}>
          <Typography>
            <b>Finish missing feedback</b>
          </Typography>
        </Box>
        <Box
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          mb={4}
        >
          <Box>
            <Typography>Tell us, how was {askFeedbackEvent.title}?</Typography>
          </Box>
          <Box
            css={{
              border: `1px solid ${theme.palette.primary.main}`,
              borderRadius: theme.spacing(2),
              height: 40,
              width: 40,
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
            onClick={() =>
              history.push(`/feedback/${askFeedbackEvent.id}?product=TeamEvent`)
            }
          >
            <ArrowRight size={18} color={theme.palette.primary.main} />
          </Box>
        </Box>
        <Divider css={{ marginTop: theme.spacing(4) }} />
      </Box>
    );
  };

  const CalendarActionItem = () => {
    return (
      <Box>
        <Box my={4}>
          <Typography>
            <b>Connect your calendar to receive a reminder</b>
          </Typography>
        </Box>
        <Box mb={4}>
          <Typography>Get a reminder for this event and any upcoming events</Typography>
        </Box>
        <CalendarSyncButtons />
        <Divider css={{ marginTop: theme.spacing(4) }} />
      </Box>
    );
  };

  const Header = () => {
    return (
      <Box className={classes.header}>
        <CheckVerified02
          className={classes.headerIcon}
          color={theme.palette.primary.main}
        />
        <Typography className={classes.headerBodyText}>You're all set</Typography>
        <Typography className={classes.headerText}>
          RSVP
          <span css={{ fontWeight: 700, color: theme.palette.primary.main }}>
            {' '}
            accepted
          </span>
        </Typography>
        <Typography className={classes.headerTextContent}>
          Your name is on the list. See you soon!
        </Typography>
      </Box>
    );
  };

  return (
    <Box className={classes.container}>
      <Header />
      <TeamEventDetail teamEvent={teamEvent} viewer={viewer} />
      <Box className={classes.body}>
        {showActionItems && (
          <>
            <Typography className={classes.actionItemsText}>
              While you're here...
            </Typography>
            <Box mt={4} className={classes.actionItems}>
              {nylasConnectionState === NylasConnectionState.Inactive && (
                <CalendarActionItem />
              )}
              {askFeedbackEvent && <FeedbackActionItem viewer={viewer} />}
            </Box>
          </>
        )}
      </Box>
      <Box className={classes.ctas}>
        <NewPrimaryButton
          css={{ width: '250px' }}
          label='View upcoming events'
          onClick={() => history.replace('/home')}
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '-40px',
    backgroundColor: theme.palette.primary[50],
    backgroundImage: `url(${RSVP_BACKGROUND})`,
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
  },
  body: {
    padding: '32px 36px 0',
  },
  actionItemsText: {
    color: theme.palette.primary.main,
    fontSize: '16px',
    fontWeight: 'bold',
  },
  actionItems: {
    backgroundColor: theme.palette.primary[50],
    padding: '16px',
  },
  header: {
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
  },
  headerText: {
    color: theme.palette.text.primary,
    fontSize: '48px',
    lineHeight: '52px',
    fontWeight: 'normal',
    margin: 0,
  },
  headerTextContent: {
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '28px',
    margin: '12px 0',
  },
  headerIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '36px',
    height: '36px',
    padding: '8px',
    margin: '0 0 12px',
    borderRadius: '8px',
    background: theme.palette.primary[100],
  },
  headerBodyText: {
    fontSize: '12px',
    lineHeight: '20px',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: 'Reboto Mono, monospace',
  },
  ctas: {
    padding: '40px 24px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}));

export default Confirmation;
