import { gql } from '@apollo/client';
import { Breadcrumbs as MuiBreadCrumbs, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@mysteryco/design';
import EyeOff from '@mysteryco/design/icons/EyeOff';
import XClose from '@mysteryco/design/icons/XClose';
import AlertBar from 'components/AlertBar';
import InfoCircle from 'components/icons/InfoCircle';
import Loading from 'components/icons/Loading';
import Map from 'components/icons/Map';
import SwitchHorizontal from 'components/icons/SwitchHorizontal';
import { OrgSearch } from 'components/OrgSearch';
import { CONNECTION_STRENGTH_ARRAY, ConnectionStrength } from 'constants/Type';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import theme from 'theme';
import {
  InsightsGalaxyViewOrganizationFragment,
  OrgSearchUserTagFragment,
  UserTagType,
} from 'types';
import { v4 } from 'uuid';
import 'vis-network/styles/vis-network.min.css';
import Graph, { NetworkOptions } from 'vis-react';

import { TeamSearch } from 'components/TeamSearch';
import { Edge, GraphData, Node } from 'glue/components/insights/data/interface';
import { Statsig } from 'statsig-react';
import { defaultTeamName } from 'utils/teamUtil';
import { IdType, Network } from 'vis-network';

const NODE_LABEL_SIZE = 16;

enum NodeType {
  Member = 'Member',
  Team = 'Team',
}

interface Props {
  loading: boolean;
  teamId: string;
  setTeamId: (teamId: string | undefined) => void;
  devMode: boolean;

  // FIXME: switch to strict mode and refactor the component so these can be non-nullable or at least
  // we can say with confidence they should be nullable
  data?: GraphData | null;
  organization?: InsightsGalaxyViewOrganizationFragment | null;
  scoreVersion?: string | null;
}

type NotNull<T> = T extends null ? never : T extends undefined ? never : T;
type Team = NotNull<InsightsGalaxyViewOrganizationFragment['teams']>[number];

const graphOptions: NetworkOptions = {
  autoResize: true,
  nodes: {
    shape: 'dot',
    borderWidth: 3,
    chosen: false,
    scaling: {
      label: {
        min: NODE_LABEL_SIZE,
        max: NODE_LABEL_SIZE,
      },
    },
    font: {
      size: NODE_LABEL_SIZE,
      strokeWidth: NODE_LABEL_SIZE,
      face: theme.typography.fontFamily,
    },
  },
  edges: {
    color: '#CAA2CD',
    arrows: {
      to: {
        enabled: false,
      },
    },
    smooth: {
      enabled: true,
      type: 'continuous',
      roundness: 0,
    },
  },
  interaction: {
    hover: true,
    hoverEdges: true,
    hoverConnectedEdges: true,
    dragNodes: false,
    dragView: false,
    zoomView: false,
  },
  physics: {
    solver: 'hierarchicalRepulsion',
    hierarchicalRepulsion: {
      damping: 0.8,
      avoidOverlap: 1,
      springConstant: 0.0001,
    },
    stabilization: {
      iterations: 250,
    },
  },
  layout: {
    randomSeed: 98104,
  },
};

const setSearchTagId = (
  tagId: string,
  organization: InsightsGalaxyViewOrganizationFragment,
  setTeamId: (teamId: string | undefined) => void,
) => {
  const tag = organization.userTags?.find((tag) => tag?.id === tagId);
  if (!tag) return null;
  if (tag.name?.endsWith("'s Team")) {
    const managerName = tag.name.replace("'s Team", '');
    const team = organization.teams?.find((team) => team?.manager?.name === managerName);
    if (team) {
      return setTeamId(team.id);
    }
  } else {
    for (const user of tag?.users ?? []) {
      const team = organization.teams?.find(
        (team) => !!team?.members.find((member) => member.id === user?.id),
      );
      if (team) {
        return setTeamId(team.id);
      }
    }
  }
};

const hideEdgesIfNotSelected = ({
  graph,
  connectedEdgeIds,
  hoverNode,
  edgeStrengthFilters,
}: {
  graph: GraphData['graph'] | undefined;
  connectedEdgeIds: Set<IdType>;
  hoverNode: Node | null;
  edgeStrengthFilters: string[];
}) => {
  const { nodes, edges } = graph || { nodes: [], edges: [] };
  const updateArray = new Array<Edge>();
  for (const edge of edges) {
    const fromNode = nodes.find((node: Node) => node.id === edge.from);
    const toNode = nodes.find((node: Node) => node.id === edge.to);
    edge.hidden =
      fromNode?.hidden ||
      toNode?.hidden ||
      (edge?.connectionStrengthRating &&
        edgeStrengthFilters.includes(edge.connectionStrengthRating)) ||
      (!connectedEdgeIds.has(edge.id) && !!hoverNode);
    updateArray.push(edge);
  }
  return updateArray;
};

const Breadcrumbs = ({
  organization,
  teamId,
  setTeamId,
  showUpdateTeams = false,
}: {
  organization: InsightsGalaxyViewOrganizationFragment;
  teamId: string;
  setTeamId: (teamId: string) => void;
  showUpdateTeams?: boolean;
}) => {
  const teamsByManagerId = _.keyBy(organization.teams, 'manager.id');
  const chainOfCommand = new Array<Team>();
  let team = organization.teams?.find((team) => team?.id === teamId);
  while (team) {
    chainOfCommand.unshift(team);
    const managerId = team.manager?.manager?.id;
    if (!managerId) break;
    team = teamsByManagerId[managerId];
  }
  const trail = new Array<React.ReactNode>();
  chainOfCommand.forEach((team: Team, index: number) => {
    const isLast = index === chainOfCommand.length - 1;
    if (team) {
      trail.push(
        <span
          key={team.id}
          style={{
            cursor: isLast ? '' : 'pointer',
            color: isLast ? theme.palette.grey[900] : colors.Dusk,
          }}
          onClick={() => {
            if (!isLast) {
              setTeamId(team.id);
            }
          }}
        >
          {showUpdateTeams ? team?.name : defaultTeamName(team)}
        </span>,
      );
    }
  });
  return <MuiBreadCrumbs>{trail}</MuiBreadCrumbs>;
};

const NodeTooltip = ({ node, devMode }: { node: Node; devMode: boolean }) => {
  const classes = useStyles();
  if (!node) return null;
  const title = node.name;
  let header = '';
  if (NodeType.Team === node.type) {
    header = `${node.members?.length} members`;
  }
  return (
    <div className={classes.tooltip}>
      {header && <Typography className={classes.tooltipHeader}>{header}</Typography>}
      <Typography className={classes.tooltipTitle}>{title}</Typography>
      {node?.role && (
        <Typography className={classes.tooltipSubtitle}>{node.role}</Typography>
      )}
      {!!(devMode && Number.isFinite(node.connection)) && (
        <div className={classes.devModeInfo}>
          Score: {node.connection?.toFixed(2) ?? '0.00'}
        </div>
      )}
    </div>
  );
};

const EdgeTooltip = ({
  edge,
  fromNode,
  toNode,
  devMode,
}: {
  edge: Edge;
  fromNode: Node;
  toNode: Node;
  devMode: boolean;
}) => {
  const classes = useStyles();
  if (!edge || !fromNode || !toNode) return null;
  const header = `${edge.connectionStrengthRating} connection strength`;
  const fromNodeTitle = fromNode.name;
  const fromNodeSubtitle = fromNode.role;
  const toNodeTitle = toNode.name;
  const toNodeSubtitle = toNode.role;
  return (
    <div className={classes.tooltip}>
      <Typography className={classes.tooltipHeader}>{header}</Typography>
      <div style={{ display: 'flex' }}>
        <div>
          <Typography className={classes.tooltipTitle}>{fromNodeTitle}</Typography>
          <Typography className={classes.tooltipSubtitle}>{fromNodeSubtitle}</Typography>
        </div>
        <SwitchHorizontal
          size={12}
          style={{
            margin: `0 ${theme.spacing(2.5)}`,
          }}
        />
        <div>
          <Typography className={classes.tooltipTitle}>{toNodeTitle}</Typography>
          <Typography className={classes.tooltipSubtitle}>{toNodeSubtitle}</Typography>
        </div>
      </div>
      {!!devMode && (
        <div className={classes.devModeInfo}>Score: {edge?.connection?.toFixed(2)}</div>
      )}
    </div>
  );
};

export const EdgeLegend = ({ rating }) => {
  const classes = useStyles();
  switch (rating) {
    case ConnectionStrength.Strong:
      return <span className={classes.galaxyLegendDash} style={{ height: '8px' }} />;
    case ConnectionStrength.Moderate:
      return <span className={classes.galaxyLegendDash} style={{ height: '6px' }} />;
    case ConnectionStrength.Poor:
      return <span className={classes.galaxyLegendDash} style={{ height: '4px' }} />;
    case ConnectionStrength.Weak:
      return (
        <div className={classes.weakLineLegend}>
          <span
            className={classes.galaxyLegendDash}
            style={{ height: '2px', width: '4px', marginRight: '2px' }}
          />
          <span
            className={classes.galaxyLegendDash}
            style={{ height: '2px', width: '4px' }}
          />
        </div>
      );
    default:
      return null;
  }
};

const GalaxyView = ({
  loading,
  data,
  organization,
  teamId,
  setTeamId,
  devMode,
  scoreVersion,
}: Props) => {
  const { graph, nodeMap, edgeMap } = data || {};

  const classes = useStyles();
  const [graphState, setGraphState] = useState({ id: v4(), graph });
  const [network, setNetwork] = useState<Network | null>(null);
  const [searchFocused, setSearchFocused] = useState(false);
  const [hoverNode, setHoverNode] = useState<Node | null>(null);
  const [selectNode, setSelectNode] = useState<Node | null>(null);
  const [hoverEdge, setHoverEdge] = useState<Edge | null>(null);
  const [selectEdge, setSelectEdge] = useState<Edge | null>(null);
  const [scale, setScale] = useState(1);
  const [edgeStrengthFilters, setEdgeStrengthFilters] = useState<string[]>([]);
  const [nodeStrengthFilters, setNodeStrengthFilters] = useState<string[]>([]);
  const visRef = useRef(null);
  const activeNode = hoverEdge ? null : hoverNode || selectNode;
  const activeEdge = hoverNode ? null : hoverEdge || selectEdge;
  const noTeamGraph = !graph?.nodes.some((node) => node.type === NodeType.Team);

  // TODO: Remove with `showUpdateTeams`
  const [searchTags, setSearchTags] = useState<OrgSearchUserTagFragment[]>([]);
  const [searchString, setSearchString] = useState('');

  const showUpdateTeams = Statsig.checkGate('show_update_teams');

  useEffect(() => {
    setGraphState({ id: v4(), graph: _.cloneDeep(graph) });
    setSelectNode(null);
    setSelectEdge(null);
  }, [graph]);

  useEffect(() => {
    if (network) {
      const nodes = _.cloneDeep(graph?.nodes) ?? [];
      const newScale = NODE_LABEL_SIZE / scale;
      for (const node of nodes) {
        node.scaling = {
          label: {
            min: newScale,
            max: newScale,
          },
        };
        node.font.size = newScale;
        node.font.strokeWidth = newScale;
      }
      setGraphState({
        id: graphState.id,
        graph: { nodes, edges: graphState.graph?.edges ?? [] },
      });
    }
  }, [scale]);

  useEffect(() => {
    if (visRef.current && network) {
      const graph = _.cloneDeep(graphState.graph);
      const connectedEdgeIds = hoverNode
        ? new Set(network.getConnectedEdges(_.get(hoverNode, 'id')))
        : new Set<IdType>();
      const updatedEdges = hideEdgesIfNotSelected({
        graph,
        connectedEdgeIds,
        hoverNode,
        edgeStrengthFilters,
      });
      setGraphState({
        id: graphState.id,
        graph: { nodes: graph?.nodes ?? [], edges: updatedEdges },
      });
    }
  }, [hoverNode]);

  useEffect(() => {
    if (visRef.current && network) {
      const graph = _.cloneDeep(graphState.graph);
      const { nodes, edges } = graph ?? { nodes: [], edges: [] };
      nodes.forEach((node) => {
        if (node.type === NodeType.Member) return;
        node.hidden =
          !!node.connectionStrengthRating &&
          nodeStrengthFilters.includes(node.connectionStrengthRating);
      });
      edges.forEach((edge) => {
        const fromNode = nodes.find((node) => node.id === edge.from);
        const toNode = nodes.find((node) => node.id === edge.to);
        edge.hidden =
          fromNode?.hidden ||
          toNode?.hidden ||
          (!!edge.connectionStrengthRating &&
            edgeStrengthFilters.includes(edge.connectionStrengthRating));
      });
      setGraphState({
        id: graphState.id,
        graph: { nodes, edges },
      });
    }
  }, [nodeStrengthFilters, edgeStrengthFilters]);

  // Unfortunately, if we generate these functions on each render, we end up
  // with extra callbacks to each event:
  //
  // vis-react registers them as callbacks to the underlying visjs Network
  // both during component mount (init) and also on the first component update.
  //
  // By memoizing, even though we're (currently) double-rendering the Graph on
  // mount, we avoid re-registering the same event.
  const graphEvents = useMemo(
    () => ({
      zoom: (event: any) => {
        setScale(event.scale);
      },
      click: (event: any) => {
        const nodeId = _.first(_.get(event, 'nodes')) as string;
        const node = nodeMap?.[nodeId];
        if (node && NodeType.Team === node.type) {
          setSelectNode(null);
          setHoverNode(null);
          setSelectEdge(null);
          setHoverEdge(null);
          setTeamId(nodeId);
        }
      },

      selectNode: (event: any) => {
        const nodeId = _.first(_.get(event, 'nodes')) as string;
        const node = nodeMap?.[nodeId];
        if (node && NodeType.Member === node.type) {
          setSelectEdge(null);
          setSelectNode(node);
        }
      },
      deselectNode: () => setSelectNode(null),
      hoverNode: (event: any) => setHoverNode(nodeMap?.[event.node] ?? null),
      blurNode: () => setHoverNode(null),

      selectEdge: (event: any) => {
        // This check is required because selecting a node also selects all its edges and it could cause unexpected behaviors.
        if (_.size(event.nodes) > 0) return;
        const edgeId = _.first(_.get(event, 'edges')) as string;
        setSelectNode(null);
        setSelectEdge(edgeMap?.[edgeId] ?? null);
      },
      deselectEdge: () => setSelectEdge(null),
      hoverEdge: (event: any) => setHoverEdge(edgeMap?.[event.edge] || null),
      blurEdge: () => setHoverEdge(null),
    }),
    [nodeMap, edgeMap],
  );

  // https://stackoverflow.com/a/67968142
  const fitNetwork = useCallback(() => {
    if (network) {
      requestAnimationFrame(() => {
        network.fit();
        network.off('afterDrawing', fitNetwork);
      });
    }
  }, [network]);

  useEffect(() => {
    network?.on('afterDrawing', fitNetwork);
  }, [network, fitNetwork]);

  useEffect(() => {
    window.addEventListener('resize', fitNetwork);
    return () => {
      window.removeEventListener('resize', fitNetwork);
    };
  }, [fitNetwork]);

  return (
    <div className={classes.galaxyWrapper}>
      {loading ? (
        <div className={classes.galaxyEmptyMessage}>
          <Loading className={classes.messageIcon} />
          <Typography className={classes.messageSubheadline}>
            Give us one second
          </Typography>
          <Typography variant={'h6'}>We're connecting the dots here</Typography>
        </div>
      ) : !organization ? (
        <div className={classes.galaxyEmptyMessage}>
          <Loading className={classes.messageIcon} />
          <Typography className={classes.messageSubheadline}>
            How did you get here?
          </Typography>
          <Typography variant={'h6'}>
            Insights are not available as you're not a member of any organization
          </Typography>
        </div>
      ) : (
        <div className={classes.galaxyBreadcrumbsRow}>
          {scoreVersion && (
            <div className={classes.versionAlertBar}>
              <AlertBar
                text={`You are currently viewing version ${scoreVersion} scores`}
                icon={<InfoCircle size={16} />}
                severity='error'
              />
            </div>
          )}
          <div className={classes.orgSearchWrapper}>
            {showUpdateTeams ? (
              <TeamSearch
                value={teamId}
                onChange={(id) => setTeamId(id)}
                onOpen={() => setSearchFocused(true)}
                onClose={() => setSearchFocused(false)}
                getOptionLabel={() => ''}
              />
            ) : (
              <OrgSearch
                orgTags={
                  organization.userTags?.filter(
                    (tag): tag is OrgSearchUserTagFragment =>
                      tag?.type === UserTagType.Automatic,
                  ) ?? []
                }
                searchTags={searchTags}
                setSearchTags={setSearchTags}
                searchString={searchString}
                setSearchString={setSearchString}
                useAsSelectionComponent={false}
                showUsersInOptions={true}
                onChange={(_event, selectedVals) => {
                  setSearchFocused(false);
                  setSearchString('');
                  setSearchTagId(
                    _.get(_.first(selectedVals), 'id'),
                    organization,
                    setTeamId,
                  );
                }}
                onFocus={() => {
                  setSearchFocused(true);
                }}
                onBlur={() => {
                  setSearchFocused(false);
                }}
                style={{ width: '100%' }}
              />
            )}
          </div>
          <div className={classes.galaxyBreadcrumbsWrapper}>
            <Map height={14} width={14} className={classes.mapIcon} />
            <Breadcrumbs
              organization={organization}
              teamId={teamId}
              setTeamId={setTeamId}
              showUpdateTeams={showUpdateTeams}
            />
          </div>
          <div
            className={classes.chartWrapper}
            style={{
              lineHeight: 0,
              filter: `blur(${searchFocused ? '4px' : 0}) opacity(${
                searchFocused ? '40%' : '1'
              })`,
            }}
          >
            <Graph
              graph={graphState.graph}
              options={graphOptions}
              events={graphEvents}
              key={graphState.id}
              vis={(vis: any) => (visRef.current = vis)}
              getNetwork={setNetwork}
            />
          </div>
          <div className={classes.tooltipWrapper}>
            {activeNode && <NodeTooltip node={activeNode} devMode={devMode} />}
            {activeEdge && nodeMap?.[activeEdge.from] && nodeMap?.[activeEdge.to] && (
              <EdgeTooltip
                edge={activeEdge}
                fromNode={nodeMap[activeEdge.from]}
                toNode={nodeMap[activeEdge.to]}
                devMode={devMode}
              />
            )}
          </div>
          <div className={classes.galaxyFooter}>
            {/* Intra-team */}
            {!noTeamGraph && (
              <div className={classes.galaxyFooterIntraLegend}>
                <div>
                  <Typography className={classes.galaxyFooterLabel}>
                    Within-team
                  </Typography>
                </div>
                <div>
                  {CONNECTION_STRENGTH_ARRAY.map((strength) => {
                    return (
                      <div
                        key={strength.rating}
                        className={
                          nodeStrengthFilters.includes(strength.rating)
                            ? classes.mutedPill
                            : classes.activePill
                        }
                        onClick={() => {
                          if (nodeStrengthFilters.includes(strength.rating)) {
                            setNodeStrengthFilters(
                              nodeStrengthFilters.filter(
                                (rating) => rating !== strength.rating,
                              ),
                            );
                          } else {
                            setNodeStrengthFilters([
                              ...nodeStrengthFilters,
                              strength.rating,
                            ]);
                          }
                        }}
                      >
                        <span
                          className={classes.galaxyLegendDot}
                          style={{
                            backgroundColor: strength.color,
                          }}
                        />
                        <Typography className={classes.galaxyFooterLegend}>
                          {strength.rating}
                        </Typography>
                        {nodeStrengthFilters.includes(strength.rating) ? (
                          <EyeOff
                            size={12}
                            color={theme.palette.grey[800]}
                            className={classes.pillIcon}
                          />
                        ) : (
                          <XClose
                            size={12}
                            color={theme.palette.grey[500]}
                            className={classes.pillIcon}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {/* Cross-team */}
            <div
              className={classes.galaxyFooterCrossLegend}
              css={noTeamGraph ? { borderRight: 'none' } : {}}
            >
              <div>
                <Typography className={classes.galaxyFooterLabel}>
                  {noTeamGraph ? 'Connection' : 'Cross-team'}
                </Typography>
              </div>
              <div className={classes.lineLegendsRow}>
                {CONNECTION_STRENGTH_ARRAY.map((strength) => {
                  return (
                    <div
                      key={strength.rating}
                      className={
                        edgeStrengthFilters.includes(strength.rating)
                          ? classes.mutedPill
                          : classes.activePill
                      }
                      onClick={() => {
                        if (edgeStrengthFilters.includes(strength.rating)) {
                          setEdgeStrengthFilters(
                            edgeStrengthFilters.filter(
                              (rating) => rating !== strength.rating,
                            ),
                          );
                        } else {
                          setEdgeStrengthFilters([
                            ...edgeStrengthFilters,
                            strength.rating,
                          ]);
                        }
                      }}
                    >
                      <EdgeLegend rating={strength.rating} />
                      <Typography className={classes.galaxyFooterLegend}>
                        &nbsp;&nbsp;{strength.rating}
                      </Typography>
                      {edgeStrengthFilters.includes(strength.rating) ? (
                        <EyeOff
                          size={12}
                          color={theme.palette.grey[800]}
                          className={classes.pillIcon}
                        />
                      ) : (
                        <XClose
                          size={12}
                          color={theme.palette.grey[500]}
                          className={classes.pillIcon}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            {/* Circle sizes */}
            {!noTeamGraph && (
              <div className={classes.galaxyFooterCirclesLegend}>
                <InfoCircle
                  color={theme.palette.grey[800]}
                  size={16}
                  style={{ width: '16px', height: '16px' }}
                />
                <Typography
                  className={classes.galaxyFooterLegend}
                  style={{ marginLeft: '4px' }}
                >
                  Circle size reflects team's total members.
                </Typography>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

GalaxyView.fragments = gql`
  fragment InsightsGalaxyViewOrganization on Organization {
    id
    teams {
      id
      name
      manager {
        id
        name
        manager {
          id
        }
      }
      members {
        id
      }
    }
    userTags {
      id
      ...OrgSearchUserTag
    }
  }

  ${OrgSearch.fragments}
`;

const useStyles = makeStyles({
  galaxyWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
  },
  galaxyEmptyMessage: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  galaxyBreadcrumbsRow: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
  galaxyBreadcrumbsWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    marginBottom: theme.spacing(4),
    '@media (max-width: 600px)': {
      flexDirection: 'column',
    },
    '& .MuiBreadcrumbs-li': {
      fontWeight: 700,
      fontSize: '.75rem',
      lineHeight: 1,
      letterSpacing: '0.12em',
      textTransform: 'uppercase',
    },
  },
  mapIcon: {
    marginRight: theme.spacing(2),
    '& path': {
      fill: colors.Main,
    },
    '@media (max-width: 600px)': {
      display: 'none',
    },
  },
  orgSearchWrapper: {
    marginBottom: theme.spacing(6),
    minWidth: '90%',
    '@media (min-width: 1500px)': {
      minWidth: '800px',
    },
  },
  chartWrapper: {
    display: 'flex',
    width: '100%',
    height: 'calc(100% - 190px)',
    marginBottom: theme.spacing(4),
    '@media (max-width: 1200px)': {
      minHeight: '45vh',
      maxHeight: '45vh',
    },
  },
  tooltip: {
    padding: '12px 16px',
    border: `1px solid ${theme.palette.primary[100]}`,
    borderRadius: '4px',
    backgroundColor: colors.White,
    boxShadow: '0px 4px 8px rgba(202, 162, 205, 0.2)',
  },
  tooltipWrapper: {
    zIndex: 5,
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'absolute',
    '@media (min-width: 800px)': {
      top: 105,
      left: '5%',
    },
    '@media (max-width: 1520px)': {
      top: 105,
      left: '5%',
    },
    '@media (max-width: 1000px)': {
      width: '90%',
      left: '5%',
      top: 105,
    },
  },
  tooltipHeader: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '20px',
    color: theme.palette.grey[700],
  },
  tooltipTitle: {
    fontWeight: 800,
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.text.primary,
  },
  tooltipSubtitle: {
    display: 'inline',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    fontFamily: 'monospace',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '20px',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.primary[100],
    textTransform: 'uppercase',
    '&:empty': {
      display: 'none',
    },
  },
  galaxyFooter: {
    textAlign: 'center',
    overflowX: 'scroll',
    scrollbarWidth: 'none',
    flexShrink: 0,
    '&::-webkit-scrollbar ': {
      display: 'none',
    },
  },
  galaxyFooterLabel: {
    color: theme.palette.grey[900],
    fontWeight: 600,
    fontSize: '0.7rem',
    lineHeight: '1.5rem',
    textTransform: 'uppercase',
    letterSpacing: '.5px',
    width: '7.5em',
    marginRight: theme.spacing(2),
  },
  pillIcon: {
    marginLeft: theme.spacing(1.5),
    display: 'block',
  },
  activePill: {
    color: '#47566E',
    cursor: 'pointer',
    userSelect: 'none',
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    background: colors.White,
    border: '2px solid #E3E4F3',
    borderRadius: theme.spacing(4),
    marginRight: theme.spacing(2),
    transition: 'all ease-in-out .3s',
    '&:hover': {
      borderColor: '#BDA4F2',
      boxShadow:
        '0px 4px 6px -1px rgba(155, 160, 166, 0.1), 0px 2px 4px -1px rgba(155, 160, 166, 0.06)',
    },
  },
  mutedPill: {
    color: '#8189B6',
    cursor: 'pointer',
    userSelect: 'none',
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    background: '#F4F5FA',
    border: '2px solid #F4F5FA',
    borderRadius: theme.spacing(4),
    marginRight: theme.spacing(2),
    transition: 'all ease-in-out .3s',
    '& $galaxyLegendDash': {
      //background: '#D7D9EF !important',
      filter: 'grayscale(100%)',
      opacity: 0.5,
    },
    '& $galaxyLegendDot': {
      //background: '#D7D9EF !important',
      filter: 'grayscale(100%)',
      opacity: 0.5,
    },
    '&:hover': {
      color: '#47566E',
      background: colors.White,
      borderColor: '#BDA4F2',
      boxShadow:
        '0px 4px 6px -1px rgba(155, 160, 166, 0.1), 0px 2px 4px -1px rgba(155, 160, 166, 0.06)',
      '& $galaxyLegendDash': {
        opacity: 1,
        filter: 'none',
      },
      '& $galaxyLegendDot': {
        opacity: 1,
        filter: 'none',
      },
    },
  },
  legendPill: {
    cursor: 'pointer',
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    background: colors.White,
    border: '2px solid #E3E4F3',
    borderRadius: theme.spacing(4),
    marginRight: theme.spacing(2),
  },
  galaxyFooterLegend: {
    color: theme.palette.grey[900],
    fontWeight: 500,
    fontSize: '0.8rem',
    lineHeight: '0.8rem',
  },
  galaxyLegendDot: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    marginRight: theme.spacing(2),
    display: 'inline-block',
  },
  galaxyLegendDash: {
    width: '12px',
    height: '6px',
    backgroundColor: theme.palette.primary[400],
    marginRight: theme.spacing(2),
    display: 'inline-block',
  },
  galaxyFooterIntraLegend: {
    padding: '0 12px 4px 12px',
    minWidth: 'fit-content',
    display: 'inline-flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  galaxyFooterCrossLegend: {
    padding: '0 12px 4px 12px',
    minWidth: 'fit-content',
    display: 'inline-flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  galaxyFooterCirclesLegend: {
    paddingLeft: '12px',
    minWidth: 'fit-content',
    //display: 'flex',
    alignItems: 'center',
    display: 'none',
  },
  messageIcon: {
    width: '44px',
    height: '44px',
    backgroundColor: theme.palette.primary[100],
    borderRadius: '50%',
    fill: theme.palette.primary[500],
    fontSize: theme.spacing(12),
    padding: theme.spacing(2),
  },
  messageSubheadline: {
    color: '#A35DA8',
    fontWeight: 800,
    fontSize: '0.8rem',
    lineHeight: '2rem',
  },
  weakLineLegend: {
    display: 'inline-flex',
  },
  lineLegendsRow: {
    display: 'flex',
    alignItems: 'center',
  },
  devModeInfo: {
    marginTop: theme.spacing(4),
    color: colors.DarkGray,
    fontSize: 12,
  },
  versionAlertBar: {
    marginBottom: theme.spacing(4),
  },
});

export default GalaxyView;
