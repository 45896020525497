import { makeStyles } from '@material-ui/core/styles';
import Loading01 from '@mysteryco/design/icons/Loading01';
import { colors } from '@mysteryco/design';

const EmptyLoadingSpinner = ({
  size = 20,
  color = colors.Main,
}: {
  size?: number;
  color?: string;
}) => {
  const classes = useStyles();
  return (
    <div className={classes.loader} css={{ width: size, height: size }}>
      <Loading01 dualTone css={{ color: color }} />
    </div>
  );
};

const useStyles = makeStyles({
  '@keyframes spinner': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  loader: {
    animation: '$spinner 1000ms infinite steps(8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default EmptyLoadingSpinner;
