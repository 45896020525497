import { Button, CircularProgress } from '@material-ui/core';

const ButtonWithLoader = ({ loading, children, className, ...rest }) => {
  return (
    <Button className={className} {...rest}>
      {loading ? <CircularProgress size={'1rem'} color='inherit' /> : children}
    </Button>
  );
};

export default ButtonWithLoader;
