import { Interpolation, Theme } from '@emotion/react';
import CloseablePill from 'glue/components/buttons/CloseablePill';
import { AudienceBuilderUserFragment } from 'types';
import { Audience, AudienceType } from './types';
import { useRemoveAudience } from './hooks';
import theme from 'theme';
import { useMemo } from 'react';

type AudienceListProps = {
  audience: Audience;
  onAudienceChange: (audience: Audience) => void;
  pillStyle?: Interpolation<Theme>;
};
export const AudienceList = ({
  audience,
  onAudienceChange,
  pillStyle,
}: AudienceListProps) => {
  const removeAudience = useRemoveAudience(audience, onAudienceChange);

  const handleRemoveAudience = (audienceType: AudienceType) => {
    return () => {
      removeAudience(audienceType);
    };
  };

  const allTypes = useMemo(
    () => [
      ...(audience.users || []),
      ...(audience.teams || []),
      ...(audience.tags || []),
    ],
    [audience],
  );

  return (
    <div
      css={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: theme.spacing(3),
      }}
    >
      {allTypes.slice(0, 200).map((audience: AudienceType, index: number) => (
        <CloseablePill
          key={`audience-${index}`}
          onClick={handleRemoveAudience(audience)}
          css={pillStyle}
        >
          {audience.name || (audience as AudienceBuilderUserFragment).email}
        </CloseablePill>
      ))}
    </div>
  );
};

export default AudienceList;
