import _ from 'lodash';
import React, { PureComponent } from 'react';

import { Sizes } from 'constants/index';
import {
  Breakpoints,
  ResponsiveContext,
  responsiveSwitch,
} from 'providers/BreakpointsProvider';
import { SubTitle } from './SubTitle';
import { Title } from './Title';
import { makeStyles } from '@material-ui/styles';

interface PageProps {
  classes?: any;
  className?: string;
  title?: string | React.ReactNode;
  subTitle?: string | React.ReactNode;
  children?: React.ReactNode | React.ReactNode[];
  width?: string;
}

const useStyles = makeStyles({
  root: (props: any) => ({
    width: props.width,
    margin: 'auto',
  }),
  titleWrapper: {
    marginBottom: Sizes.SPACING_LARGE,
  },
});

class PageContainer extends PureComponent<PageProps> {
  static contextType = ResponsiveContext;
  render() {
    const context: Breakpoints = this.context;
    const width = responsiveSwitch(['98%', '80%', '60%', '50%'], context);
    return <Page width={width} {...this.props} />;
  }
}

const Page = (props: PageProps) => {
  const classes = useStyles({ width: props.width });

  const renderTitle = () =>
    props.title && _.isString(props.title) ? <Title>{props.title}</Title> : props.title;

  const renderSubTitle = () =>
    props.subTitle && _.isString(props.subTitle) ? (
      <SubTitle>{props.subTitle}</SubTitle>
    ) : (
      props.subTitle
    );

  return (
    <div className={_.compact([classes.root, props.className]).join(' ')}>
      {props.title && (
        <div className={classes.titleWrapper}>
          {renderTitle()}
          {renderSubTitle()}
        </div>
      )}
      <div>{props.children}</div>
    </div>
  );
};

export default PageContainer;
