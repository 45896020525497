import { css } from '@emotion/react';
import theme from 'theme';
import { Maybe } from 'types';
interface MoralePulseSurveySuccessProps {
  logoUrl?: Maybe<string>;
}
const MoralePulseSurveySuccess = ({ logoUrl }: MoralePulseSurveySuccessProps) => {
  return (
    <div css={styles.container}>
      <div css={styles.content}>
        {logoUrl && (
          <div>
            <img css={{ maxWidth: 350 }} src={logoUrl} alt={'Company logo'} />
          </div>
        )}
        <div css={styles.complete}>You've completed this survey</div>
        <div css={styles.thankYou}>❤️ Thank you for your answers!</div>
        <div css={styles.closeWindow}>You may close this window at any time</div>
      </div>
    </div>
  );
};

const styles = {
  brandImage: css({
    height: '25px',
  }),
  celebrateButton: css({
    width: '100%',
    marginTop: theme.spacing(10),
  }),
  closeWindow: css({
    marginTop: theme.spacing(5),
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '20px',
    '@media (min-width: 960px)': {
      fontSize: 16,
      lineHeight: '28px',
    },
    color: '#3B3B3B',
  }),
  container: css({
    padding: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  content: css({
    width: '320px',
    '@media (min-width: 600px)': {
      width: '440px',
    },
    '@media (min-width: 960px)': {
      width: '600px',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(8),
  }),
  thankYou: css({
    fontWeight: 500,
    fontSize: 24,
    '@media (min-width: 960px)': {
      fontSize: 32,
    },
    lineHeight: '120%',
    textAlign: 'center',
    marginTop: theme.spacing(5),
  }),
  complete: css({
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '20px',
    '@media (min-width: 960px)': {
      fontSize: 22,
      lineHeight: '120%',
    },
    textAlign: 'center',
    color: 'rgba(59, 59, 59, 0.7)',
    marginTop: theme.spacing(5),
  }),
};

export default MoralePulseSurveySuccess;
