// @ts-strict-ignore
import { gql } from '@apollo/client';
import { Loading } from 'components/core';
import { getUniqUsers } from 'glue/components/inputs/audience';
import { usePulseSettingsState } from './pulseSettingsState';
import { memberFragment } from 'gql/queries';
import mixpanel from 'mixpanel-browser';
import { useCallback, useState } from 'react';
import { Step, Steps, Wizard } from 'react-albus';
import {
  useCreateMoralePulseMutation,
  useMoralePulseEditQuery,
  useUpdateMoralePulseMutation,
} from 'types';
import MoralePulseLoader from './MoralePulseLoader';
import MoralePulsePreview from './MoralePulsePreview';
import MoralePulseSuccess from './MoralePulseSuccess';
import SurveyQuestionsSettings from './SurveyQuestionsSettings';
import SurveySetUp from './SurveySetUp';

const MoralePulseEdit = () => {
  const [stepperSteps, setStepperSteps] = useState([]);
  const [isEnabling, setIsEnabling] = useState(true);

  const setFromApi = usePulseSettingsState((state) => state.setFromApi);

  const { loading, data } = useMoralePulseEditQuery({
    onCompleted: setFromApi,
  });
  const firstName = data?.viewer?.firstName;

  const moralePulses = data?.viewer?.defaultOrganization?.moralePulses;
  const activePulse = moralePulses?.find((pulse) => pulse.enabled);

  const saveSettings = useSaveSettings();

  if (loading) return <Loading />;

  return (
    <Wizard
      onNext={async (wizard) => {
        mixpanel.track('pls-settings-flow', {
          step: wizard.step.id,
        });
        if (wizard.step.id === 'preview') {
          // here we submit the settings values - but we still want to
          // proceed to the loading stage. if the submit fails,
          // we back up to the previous step
          saveSettings().catch((err) => {
            wizard.previous();
          });
        }
        wizard.push();
      }}
    >
      <Steps>
        <Step id='setUp' render={({ next }) => <SurveySetUp next={next} />} />
        <Step
          id='questions'
          render={({ previous, next }) => (
            <SurveyQuestionsSettings
              previous={previous}
              next={next}
              activeMoralePulseQuestions={
                data?.viewer?.defaultOrganization?.activeMoralePulseQuestions ?? []
              }
            />
          )}
        />
        <Step
          id='preview'
          render={({ previous, next }) => (
            <MoralePulsePreview
              previous={previous}
              next={next}
              setStepperSteps={setStepperSteps}
              setIsEnabling={setIsEnabling}
              activePulse={activePulse}
            />
          )}
        />
        <Step
          id='loading'
          render={({ next }) => <MoralePulseLoader isEnabling={isEnabling} next={next} />}
        />
        <Step
          id='success'
          render={() => (
            <MoralePulseSuccess
              stepperSteps={stepperSteps}
              isEnabling={isEnabling}
              firstName={firstName}
            />
          )}
        />
      </Steps>
    </Wizard>
  );
};

MoralePulseEdit.query = gql`
  query MoralePulseEdit {
    viewer {
      id
      firstName
      defaultOrganization {
        id
        moralePulses {
          id
          enabled
          cadence
          startDate
          targetAudience {
            id
            ...memberFragment
          }
          activeQuestions {
            id
          }
        }
        activeMoralePulseQuestions {
          id
          ...SurveyQuestionInfo
        }
      }
    }
  }

  ${memberFragment}
  ${SurveyQuestionsSettings.fragments}
`;

export default MoralePulseEdit;

function useSaveSettings() {
  const [createMoralePulse] = useCreateMoralePulseMutation();
  const [updateMoralePulse] = useUpdateMoralePulseMutation();

  const saveSettings = useCallback(async () => {
    const state = usePulseSettingsState.getState();
    const userIds = getUniqUsers(state.audience).map((user) => user.id);
    // if no users are included, don't even bother submitting.
    // ideally we validate this during the form and not at submission.
    if (userIds.length === 0) {
      throw new Error('You must either select a cohort or choose "Everyone in my org"');
    }

    if (!state.pulseId) {
      await createMoralePulse({
        variables: {
          settings: {
            activeQuestions: state.questionIds,
            cadence: state.cadence,
            enabled: true,
            startDate: new Date(state.startDate).toISOString(),
            targetAudience: userIds,
          },
        },
        refetchQueries: ['PulseSettings'],
      });
    } else {
      await updateMoralePulse({
        variables: {
          settings: {
            activeQuestions: state.questionIds,
            cadence: state.cadence,
            enabled: true,
            startDate: new Date(state.startDate).toISOString(),
            targetAudience: userIds,
          },
          pulseId: state.pulseId,
        },
        refetchQueries: ['PulseSettings'],
      });
    }
  }, [createMoralePulse, updateMoralePulse]);

  return saveSettings;
}
