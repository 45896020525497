// @ts-strict-ignore
import { gql, useQuery } from '@apollo/client';
import { cx } from '@emotion/css';
import {
  Box,
  Breadcrumbs,
  Divider,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Loading } from 'components/core';
import Button from 'glue/components/buttons/Button';
import { client } from 'gql/client';
import { getOrgMembers } from 'gql/queries';
import _ from 'lodash';
import { useState } from 'react';
import theme, { primaryGradient } from 'theme';
import {
  FinchReassocationInput,
  MemberFragmentFragment,
  User,
  ViewerFragmentFragment,
} from 'types';

interface PendingProps {
  viewer: ViewerFragmentFragment | undefined;
}

export const Pending = ({ viewer }: PendingProps) => {
  const { data, loading } = useQuery(getPendingOrgMembers, {
    variables: {
      id: _.first(viewer.orgs).id,
    },
  });

  if (loading || !data) return <Loading />;
  return <View members={data.getPendingOrgMembers} />;
};

export const View = ({ members }: { members: User[] }) => {
  const classes = useStyles();
  const pendingMemberMap: { [key: string]: MemberFragmentFragment } = {};
  members.forEach((member) => {
    pendingMemberMap[member.id] = member;
  });
  const [pendingMembers, setPendingMembers] = useState(pendingMemberMap);
  const [pendingUpdates, setPendingUpdates] = useState(
    {} as { [key: string]: FinchReassocationInput },
  );
  if (_.isEmpty(members)) {
    window.location.replace('/settings#Integrations');
  }
  return (
    <Box className={classes.body}>
      {/* Page header */}
      <Box mb={4} className={classes.pageHeader}>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link color='inherit' href='/settings'>
            Settings
          </Link>
          <Link color='inherit' href='/settings#Integrations'>
            Integrations
          </Link>
          <Typography color='primary'>Awaiting</Typography>
        </Breadcrumbs>
        <Typography variant={'h3'}>Awaiting action</Typography>
      </Box>
      {/* Page content */}
      <Box className={classes.mainWrapper}>
        <Box className={classes.contents}>
          <Typography variant={'h5'}>
            There were issues importing some team members
          </Typography>
          <br />
          <Typography>
            Add the work email for the members below so we can add them to your
            organization
          </Typography>
          <br />
          {/* Table */}
          <Box className={classes.tableContainer}>
            <Box className={classes.tableHeader}>
              <Box className={cx([classes.row])}>
                <Box className={classes.staticWidthCol}>
                  <Typography className={classes.tableLabel}>Name</Typography>
                </Box>
                <Box className={classes.staticWidthCol}>
                  <Typography className={classes.tableLabel}>
                    Work Email Address
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider />
            {Object.values(pendingMembers)
              .sort()
              .map((member, i) => (
                <div key={`team-member-div-${i}`}>
                  <Box key={`team-member-${i}`} className={cx([classes.row])}>
                    <Box className={classes.staticWidthCol}>
                      <Box
                        className={classes.bold}
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {`${member.firstName} ${member.lastName}`}
                      </Box>
                    </Box>
                    <Box>
                      <TextField
                        variant='outlined'
                        className={classes.workEmailInput}
                        value={
                          pendingUpdates[member.id]
                            ? pendingUpdates[member.id].targetEmail
                            : ''
                        }
                        onChange={(e) => {
                          if (e.target.value) {
                            const newState = _.clone(pendingUpdates);
                            newState[member.id] = {
                              toReassociate: member.id,
                              targetEmail: e.target.value,
                              finchAssociationId: member.finchAssociation,
                            } as FinchReassocationInput;
                            setPendingUpdates(newState);
                          }
                        }}
                      />
                    </Box>
                    <Box flex={1} className={cx([classes.column, classes.editable])}>
                      <Typography
                        className={classes.ignore}
                        onClick={() => {
                          client.mutate({
                            mutation: ignoreFinchAssociation,
                            variables: {
                              finchAssociations: [member.finchAssociation],
                            },
                            refetchQueries: ['getPendingOrgMembers'],
                            awaitRefetchQueries: true,
                          });
                          const newPending = _.clone(pendingMembers);
                          delete newPending[member.id];
                          setPendingMembers(newPending);
                          const newUpdates = _.clone(pendingUpdates);
                          delete newUpdates[member.id];
                          setPendingUpdates(newUpdates);
                        }}
                      >
                        Delete
                      </Typography>
                    </Box>
                  </Box>
                </div>
              ))}
          </Box>
          {/* save button */}
          <Box className={classes.buttonContainer}>
            <Button
              onClick={() => {
                client.mutate({
                  mutation: reassociateUsers,
                  variables: {
                    reassociations: Object.values(pendingUpdates),
                  },
                  refetchQueries: [
                    'getPendingOrgMembers',
                    'searchOrgUsers',
                    getOrgMembers,
                  ],
                  awaitRefetchQueries: true,
                });
                const newPending = _.clone(pendingMembers);
                Object.values(pendingUpdates).forEach((update) => {
                  delete newPending[update.toReassociate];
                });
                setPendingMembers(newPending);
                setPendingUpdates({});
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const reassociateUsers = gql`
  mutation reassociateUsers($reassociations: [FinchReassocationInput]!) {
    reassociateFinchEmployees(reassociations: $reassociations)
  }
`;

const getPendingOrgMembers = gql`
  query getPendingOrgMembers($id: ID!) {
    getPendingOrgMembers(id: $id) {
      id
      firstName
      lastName
      finchAssociation
    }
  }
`;

const ignoreFinchAssociation = gql`
  mutation ignoreFinchAssociations($finchAssociations: [ID]!) {
    ignoreFinchAssociation(ids: $finchAssociations)
  }
`;

const useStyles = makeStyles({
  body: {
    padding: '2rem',
  },
  pageHeader: {
    margin: `0 auto ${theme.spacing(5)}`,
    display: 'flex',
    alignItems: 'start',
    width: 'calc(100% - 48px)',
    maxWidth: '1600px',
    flexDirection: 'column',
    '& h3': {
      fontWeight: 300,
      color: theme.palette.grey[900],
      letterSpacing: '-1px',
    },
  },
  mainWrapper: {
    height: '100%',
    padding: `${theme.spacing(6)} 0`,
    borderTop: `1px solid ${theme.palette.primary[200]}`,
    backgroundColor: theme.palette.primary[50],
  },
  contents: {
    width: 'calc(100% - 48px)',
    maxWidth: '1600px',
    margin: '0 auto',
  },
  workEmailInput: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      height: '35px',
      width: '100%',
    },
  },
  row: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: `${theme.spacing(3)} ${theme.spacing(6)}`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  checkBoxColumn: {
    marginRight: '10px',
  },
  staticWidthCol: {
    paddingRight: '10px',
    width: '200px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  tableLabel: {
    fontSize: '.8rem',
    fontFamily: `'Roboto Mono', monospaced`,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    background: theme.palette.primary[100],
  },
  tableHeader: {
    padding: `0 ${theme.spacing(2)}`,
    background: theme.palette.primary[100],
  },
  column: {
    paddingRight: '10px',
    minWidth: '175px',
  },
  tableTag: {
    borderRadius: '2px',
    marginRight: '4px',
    color: theme.palette.primary.main,
    background: theme.palette.primary[100],
    height: '22px',
    fontWeight: 500,
  },
  automatedTableTag: {
    paddingLeft: '10px',
    borderRadius: '2px',
    marginRight: '4px',
    color: theme.palette.primary.main,
    background: theme.palette.primary[100],
    height: '22px',
    fontWeight: 500,
    '& .MuiChip-label': {
      paddingLeft: '6px',
    },
  },
  checkBoxes: {
    '&.MuiCheckbox-root': {
      padding: '0px',
    },
  },
  ignore: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  root: {
    paddingTop: '12px',
    paddingLeft: '24px',
  },
  content: {
    marginTop: theme.spacing(10),
  },
  contractRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  bold: {
    fontWeight: 'bold',
  },
  button: {
    marginRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    textDecoration: 'none',
    backgroundImage: primaryGradient,
    color: 'white',
    width: theme.spacing(50),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    borderRadius: theme.spacing(40),
  },
  hrisCallToAction: {
    height: '32px',
    borderRadius: '4px',
    background: '#F9E9F2',
    border: '1px solid #7B1783',
    width: '100%',
  },
  gray: {
    backgroundColor: '#F8F8F8',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tableContainer: {
    marginTop: '4px',
    minWidth: '550px',
  },
  pl: {
    paddingLeft: '1rem',
  },
  alignCenter: {
    alignItems: 'center',
  },
  editable: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'flex-end',
  },
  editIcon: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&.MuiSvgIcon-root': {
      fontSize: '16px',
      fontWeight: 200,
    },
  },
  flexCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  navMenu: {
    fontSize: '2rem',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
  },
});
