import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import theme from 'theme';

interface Props {
  title: string;
  children: React.ReactNode;
}

const useStyles = makeStyles({
  headerText: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '1px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  subtitleText: {
    color: theme.palette.text.secondary,
    fontSize: '14px',
  },
});

const ScoreDetailsHeader = ({ title, children }: Props) => {
  const classes = useStyles();

  return (
    <Box>
      <Box mb={1}>
        <Typography className={classes.headerText}>{title}</Typography>
      </Box>
      <Typography className={classes.subtitleText}>{children}</Typography>
    </Box>
  );
};

export default ScoreDetailsHeader;
