import { Sizes } from 'constants/index';
import { DateTime } from 'luxon';
import React from 'react';

import { makeStyles } from '@material-ui/styles';

import { Box, Link } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    padding: Sizes.SPACING_MEDIUM,
    paddingBottom: Sizes.GRID_UNIT * 4,
  },
  link: {
    display: 'inline',
    textDecoration: 'none',
  },
});

export const Footer = (props: { className?: any }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.root}${props.className ? ` ${props.className}` : ''}`}>
      <span>
        Copyright © {DateTime.local().year} Glue. All rights reserved.&nbsp;
        <Box display='flex' flexDirection='row' justifyContent='center'>
          <Link href='/terms-of-service' className={classes.link}>
            Terms of Service
          </Link>
          &nbsp;|&nbsp;
          <Link href='/privacy-policy' className={classes.link}>
            Privacy Policy
          </Link>
        </Box>
      </span>
    </div>
  );
};
