// @ts-strict-ignore
import { gql } from '@apollo/client';
import { Grid, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ScoreType } from 'types';
import { Skeleton } from 'components/Skeleton';
import ScoreDetailsHeader from './common/Header';
import FocusAreaCard from 'components/Cards/FocusAreaCard';
import MysteryCard from 'components/Cards/MysteryCard';
import senseOfBelongingFocusAreasIllustration from 'assets/illustrations/sense-of-belonging-focus-areas@2x.png';
import Circle from 'components/Circle';
import ESGoalLeadership from 'components/icons/ESGoalLeadership';
import ESGoalCrossTeam from 'components/icons/ESGoalCrossTeam';
import ESGoalWithinTeam from 'components/icons/ESGoalWithinTeam';
import { SCORE_TYPE_NAME_MAP } from 'constants/Strings';
import theme from 'theme';
import { colors } from '@mysteryco/design';

import { ScoreDetailsFocusAreasFragment, ScoreDetailsInsightsScoreFragment } from 'types';

export interface FocusAreasProps {
  loading: boolean;
  scoreType?: ScoreType;
  teamScores?: ScoreDetailsInsightsScoreFragment[];
  teamSubScores?: ({
    newHireCount: number;
    teamSize: number;
  } & ScoreDetailsFocusAreasFragment)[];
  previousMonthTeamSubScores?: ScoreDetailsFocusAreasFragment[];
}

const WEAK_THRESHOLD = 0.3;
const LOW_THRESHOLD = 0.4;

const areaIcon = (area: ScoreType, size: number, color: string) => {
  switch (area) {
    case ScoreType.Leadership:
      return <ESGoalLeadership color={color} size={size} />;
    case ScoreType.CrossTeam:
      return <ESGoalCrossTeam color={color} size={size} />;
    case ScoreType.WithinTeam:
      return <ESGoalWithinTeam color={color} size={size} />;
  }
};

const SenseOfBelongingCard = ({ weakAreas }: { weakAreas: ScoreType[] }) => {
  const classes = useStyles();
  return (
    <Card className={classes.senseOfBelongingCard}>
      <div className={classes.senseOfBelongingHeader}>
        Strengthen your teams’ overall sense of belonging
      </div>
      <Grid
        container
        justifyContent={'space-between'}
        css={{
          marginTop: theme.spacing(3),
        }}
      >
        <Grid item xs>
          <div>
            Help your teams feel more connected and happy by focusing on these
            connections:
          </div>
          <div className={classes.senseOfBelongingWeakAreaList}>
            {weakAreas.map((area) => {
              return (
                <div key={area} css={{ margin: theme.spacing(1) }}>
                  <Circle>{areaIcon(area, 16, colors.Main)}</Circle>
                  <span className={classes.senseOfBelongingWeakAreaLabel}>
                    {SCORE_TYPE_NAME_MAP[area]}
                  </span>
                </div>
              );
            })}
          </div>
        </Grid>
        <Grid item>
          <img
            css={{
              width: '154px',
              height: '154px',
            }}
            src={senseOfBelongingFocusAreasIllustration}
            alt={'Sense of Belonging'}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

const LeadershipCard = ({
  loading,
  teamSubScores,
}: {
  loading: boolean;
  teamSubScores: ScoreDetailsFocusAreasFragment[];
}) => {
  const classes = useStyles();
  return (
    <MysteryCard loading={loading} title={'Top leadership connection opportunities'}>
      {loading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <>
          <div className={classes.helperText}>
            Include these leaders in your within and cross-team events to help them boost
            their connectivity:
          </div>
          {teamSubScores
            .sort((a, b) => a.value - b.value)
            .slice(0, 3)
            .map((teamSubScore: any) => {
              return (
                <div key={teamSubScore?.team?.id} className={classes.team}>
                  <span className={classes.teamLabel}>
                    {teamSubScore?.team?.name
                      .split(' ')
                      .slice(0, 2)
                      .map((word: string) => word.charAt(0).toUpperCase())}
                  </span>
                  <span className={classes.teamText}>
                    {teamSubScore?.team?.name.replace("'s Team", '')}
                  </span>
                </div>
              );
            })}
        </>
      )}
    </MysteryCard>
  );
};

const WithinTeamCard = ({
  loading,
  teamSubScores,
}: {
  loading: boolean;
  teamSubScores: ({ newHireCount: number } & ScoreDetailsFocusAreasFragment)[];
}) => {
  const classes = useStyles();
  return (
    <MysteryCard loading={loading} title={'Teams with most new-hires to integrate'}>
      {loading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <>
          <div className={classes.helperText}>
            These teams could benefit the most from new-hire events to help build
            within-team relationships:
          </div>
          {teamSubScores
            .sort((a, b) => b.newHireCount - a.newHireCount)
            .slice(0, 3)
            .map((teamSubScore: any) => {
              return (
                <div key={teamSubScore?.team?.id} className={classes.team}>
                  <span className={classes.teamLabel}>
                    {teamSubScore?.team?.name
                      .split(' ')
                      .slice(0, 2)
                      .map((word: string) => word.charAt(0).toUpperCase())}
                  </span>
                  <span className={classes.teamText}>
                    {teamSubScore?.team?.name.replace("'s Team", '')}
                  </span>
                  <span className={classes.newHireCount}>
                    {teamSubScore.newHireCount} new-hires
                  </span>
                </div>
              );
            })}
        </>
      )}
    </MysteryCard>
  );
};

const ScoreDetailsFocusAreas = ({
  loading = false,
  scoreType,
  teamScores,
  teamSubScores = [],
  previousMonthTeamSubScores = [],
}: FocusAreasProps) => {
  if (!teamSubScores?.length) return null;
  const currentWeakTeams = teamSubScores.filter((team) => team.value < WEAK_THRESHOLD);
  const previousMonthWeakTeams = previousMonthTeamSubScores.filter(
    (team) => team.value < WEAK_THRESHOLD,
  );
  return (
    <>
      <ScoreDetailsHeader title='Focus Areas'>Teams to focus</ScoreDetailsHeader>
      <Grid
        container
        spacing={6}
        alignItems={'stretch'}
        css={{ marginBottom: theme.spacing(16) }}
      >
        <Grid item xs>
          <FocusAreaCard
            loading={loading}
            scoreType={scoreType}
            currentTeams={currentWeakTeams}
            previousMonthTeams={previousMonthWeakTeams}
          />
        </Grid>
        {scoreType === ScoreType.SenseOfBelonging &&
          teamScores?.filter((score) => score.value < LOW_THRESHOLD).length > 0 && (
            <Grid item xs>
              {SenseOfBelongingCard({
                weakAreas: teamScores
                  .filter(
                    (score) =>
                      score.value < LOW_THRESHOLD &&
                      score.scoreType !== ScoreType.SenseOfBelonging,
                  )
                  .map((score) => score.scoreType),
              })}
            </Grid>
          )}
        {scoreType === ScoreType.Leadership && (
          <Grid item xs>
            {LeadershipCard({ loading, teamSubScores })}
          </Grid>
        )}
        {scoreType === ScoreType.WithinTeam && (
          <Grid item xs>
            {WithinTeamCard({ loading, teamSubScores })}
          </Grid>
        )}
      </Grid>
    </>
  );
};
const useStyles = makeStyles({
  senseOfBelongingCard: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: colors.Purple100,
    backgroundColor: colors.Purple50,
    color: colors.Dusk,
    padding: theme.spacing(6),
    height: '100%',
    boxShadow: 'none',
  },
  senseOfBelongingHeader: {
    fontWeight: 800,
    fontSize: '18px',
    color: colors.Main,
  },
  senseOfBelongingWeakAreaList: {
    marginTop: theme.spacing(3),
    borderLeftStyle: 'solid',
    borderColor: colors.Purple300,
    borderWidth: '5px',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  senseOfBelongingWeakAreaLabel: {
    marginLeft: theme.spacing(3),
    fontWeight: 700,
  },
  helperText: {
    color: colors.Midnight,
    marginBottom: theme.spacing(4),
  },
  team: {
    color: colors.Dusk,
    marginTop: theme.spacing(2),
    fontWeight: 500,
    display: 'flex',
  },
  teamLabel: {
    textAlign: 'center',
    lineHeight: '44px',
    display: 'inline-block',
    width: '44px',
    height: '44px',
    fontWeight: 700,
    fontSize: '14px',
    borderRadius: '50%',
    borderWidth: '1px',
    borderColor: colors.White,
    borderStyle: 'solid',
    backgroundColor: theme.palette.secondary[200],
  },
  teamText: {
    marginLeft: theme.spacing(2),
    textAlign: 'center',
    lineHeight: '44px',
    display: 'inline-block',
  },
  newHireCount: {
    marginLeft: 'auto',
    backgroundColor: colors.Purple100,
    borderRadius: '16px',
    padding: '0 12px',
    alignSelf: 'center',
    lineHeight: '24px',
    display: 'inline-block',
    fontWeight: 500,
  },
});

ScoreDetailsFocusAreas.fragments = gql`
  fragment ScoreDetailsFocusAreas on EngagementScore {
    value
    ... on DirectTeamEngagementScore {
      team {
        id
        name
      }
    }
    ... on TransitiveTeamEngagementScore {
      team {
        id
        name
      }
    }
  }
`;

export default ScoreDetailsFocusAreas;
