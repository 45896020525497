// @ts-strict-ignore
import { Loading } from 'components/core';
import { updateUser } from 'gql/interactions';
import { getOrganizationRoles } from 'gql/queries';
import { useEffect, useState } from 'react';
import theme, { primaryGradient } from 'theme';
import {
  MemberFragmentFragment,
  useAddRemoveTagsFromUsersMutation,
  UserTag,
  UserTagType,
} from 'types';
import { useQuery } from '@apollo/client';
import { Box, Button, ButtonBase, Drawer, Snackbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RolePicker from './RolePicker';
import GroupManagement from './GroupManagement';
import ArchiveButtonOriginal from './ArchiveButtonOld';
import { Alert } from '@material-ui/lab';
import { findOrCreateTags, TeamPageOrganization } from '..';
import { isContract } from 'utils/customerTypeUtils';
import { Statsig } from 'statsig-react';
import TagSelect from 'components/SelectDropdown/TagSelect';
import TeamSelect from 'components/SelectDropdown/TeamSelect';
import { colors } from '@mysteryco/design/src';
import CloseX from 'components/icons/CloseX';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import FlatButton from 'components/core/FlatButton';

interface BulkEditTeamMembersProps {
  isShowing: boolean;
  isArchivedPage: boolean;
  setIsShowing(isShowing: boolean): void;
  organization: TeamPageOrganization;
  members: MemberFragmentFragment[];
  setSelectedMembers?: (members: any) => void;
}

const updateUsers = async (members: MemberFragmentFragment[], values: any) => {
  const lastElement = members.length - 1;
  await Promise.all(
    members.map((member, i) => {
      return updateUser(
        {
          ...values,
          id: member.id,
        },
        i === lastElement ? ['searchOrgUsers'] : [],
      );
    }),
  );
};

const getAllSharedTags = (members: MemberFragmentFragment[]): UserTag[] => {
  const { counts, tags } = members
    .flatMap((member) => member.tags)
    .reduce(
      ({ counts, tags }, tag) => {
        counts[tag.id] = counts[tag.id] ? counts[tag.id] + 1 : 1;
        tags[tag.id] = tag;
        return { counts, tags };
      },
      { counts: {}, tags: {} },
    );
  return Object.entries(counts).reduce((sharedTags, [id, count]) => {
    if (count === members.length) sharedTags.push(tags[id]);
    return sharedTags;
  }, []);
};

const getSharedTagIds = (members: MemberFragmentFragment[]): string[] => {
  return getAllSharedTags(members)
    .filter((tag) => tag.type === UserTagType.Manual)
    .map((tag) => tag.id);
};

const getSharedRole = (members: MemberFragmentFragment[]) => {
  const role = members[0]?.organizationRole?.id;
  return members.every((member) => member.organizationRole?.id === role) ? role : '';
};

const getSharedTeam = (members: MemberFragmentFragment[]) => {
  const team = members[0]?.teams?.[0]?.id;
  return members.every((member) => member.teams?.[0]?.id === team) ? [team] : [];
};

const BulkEditTeamMembersHeader = ({
  classes,
  showNewTeamStyles,
  members,
  onClose = () => null,
}: {
  classes: ClassNameMap;
  members: MemberFragmentFragment[];
  showNewTeamStyles?: boolean;
  onClose: (event) => void;
}) => {
  const text = `Edit ${members.length} members`;
  return showNewTeamStyles ? (
    <Box className={classes.headerContainer}>
      <Typography className={classes.title}>{text}</Typography>
      <ButtonBase onClick={onClose}>
        <CloseX className={classes.close} />
      </ButtonBase>
    </Box>
  ) : (
    <Typography variant='h3'>{text}</Typography>
  );
};

const BulkEditTeamMembers = ({
  isShowing,
  isArchivedPage,
  setIsShowing,
  members,
  organization,
  setSelectedMembers,
}: BulkEditTeamMembersProps) => {
  const originalStyles = useOriginalStyles();
  const newStyles = useStyles();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [teamId, setTeamId] = useState([]);
  const [sharedTagIds, setSharedTagIds] = useState([]);
  const [selectedTagIds, setSelectedTagIds] = useState([]);

  // TODO: Remove with `show_update_tags` flag
  const [allSharedTags, setAllSharedTags] = useState([]);
  const [tagsToRemove, setTagsToRemove] = useState(new Set<UserTag>());
  const [tagsToAdd, setTagsToAdd] = useState(new Set<UserTag>());

  const { data, loading } = useQuery(getOrganizationRoles);
  const [addRemoveTags] = useAddRemoveTagsFromUsersMutation();

  useEffect(() => {
    if (!data) return;
    setRoles(data.roles);
  }, [data]);

  useEffect(() => {
    if (!members) return;
    setSelectedRole(getSharedRole(members));
    setTeamId(getSharedTeam(members));
    setSharedTagIds(getSharedTagIds(members));
    setSelectedTagIds(sharedTagIds);

    // TODO: Remove with `show_update_tags` flag
    setAllSharedTags(getAllSharedTags(members));
  }, [members]);

  const snackbarAlert = (message: string) => {
    setAlertMessage(message);
    setShowSnackbar(true);
  };

  const showUpdateTeams = Statsig.checkGate('show_update_teams');
  const showUpdateTags = Statsig.checkGate('show_update_tags');
  const showNewTeamStyles = Statsig.checkGate('show_new_team_styles');

  const classes = showNewTeamStyles ? newStyles : originalStyles;

  const isHrisConnected = organization.isHrisConnected;
  const disabled = isArchivedPage || submitting;

  const updateTags = async () => {
    const memberIds = members.map((member) => member.id);
    const removeTagIds = sharedTagIds.filter((tag) => !selectedTagIds.includes(tag));
    await addRemoveTags({
      variables: { userIds: memberIds, addTagIds: selectedTagIds, removeTagIds },
    });
  };

  const onClickSave = async () => {
    let values = { organizationRoleId: selectedRole };
    if (teamId?.[0] && showUpdateTeams) values['team'] = teamId[0];

    setSubmitting(true);
    await updateTags();
    await updateUsers(members, values);

    snackbarAlert('Members updated!');
    setSubmitting(false);
    setIsShowing(false);
    setSelectedMembers(new Set());
  };

  // TODO: Remove with `show_update_tags` flag
  const onClickAllSave = async () => {
    setSubmitting(true);
    const memberIds = members.map((member) => member.id);
    const addTagIds = await (
      await findOrCreateTags([...tagsToAdd], organization.id)
    ).map((tag) => tag.id);
    const removeTagIds = [...tagsToRemove].map((tag) => tag.id);
    await addRemoveTags({ variables: { userIds: memberIds, addTagIds, removeTagIds } });
    await updateUsers(members, { organizationRoleId: selectedRole });

    snackbarAlert('Members updated!');

    setTagsToAdd(new Set());
    setTagsToRemove(new Set());
    setSubmitting(false);
    setIsShowing(false);
    setSelectedMembers(new Set());
  };

  const onSave = async () => {
    if (showUpdateTags) {
      await onClickSave();
    } else {
      await onClickAllSave();
    }
  };

  return (
    <>
      <Drawer
        className={classes.drawer}
        open={isShowing && !loading}
        onClose={() => setIsShowing(false)}
        anchor='right'
      >
        {!members && <Loading />}
        {members && (
          <>
            <div className={classes.container}>
              <BulkEditTeamMembersHeader
                classes={classes}
                members={members}
                onClose={() => setIsShowing(false)}
                showNewTeamStyles={showNewTeamStyles}
              />
              <Box className={classes.content}>
                <RolePicker
                  onChange={(newRole) => setSelectedRole(newRole)}
                  value={selectedRole}
                  roles={roles}
                  useNewStyles={showNewTeamStyles}
                  disabled={disabled}
                />
                {showUpdateTeams &&
                  isContract(organization.customerType) &&
                  !isHrisConnected && (
                    <Box>
                      <Typography variant='h5' className={classes.header}>
                        Team
                      </Typography>
                      <Typography className={classes.subtitle}>
                        Change the team of the selected members
                      </Typography>
                      <Box mt={3}>
                        <TeamSelect
                          organization={organization}
                          value={teamId}
                          onChange={(_, newValue) => setTeamId(newValue)}
                          userIds={members.map((member) => member.id)}
                          disabled={disabled}
                        />
                      </Box>
                    </Box>
                  )}
                {!showUpdateTags && isContract(organization.customerType) && (
                  <GroupManagement
                    memberLabel={`Add ${members.length} selected members to a group`}
                    organization={organization}
                    userTags={allSharedTags}
                    onChange={{
                      create: async (name) => {
                        const newTag = { id: '', name, type: UserTagType.Manual };
                        setAllSharedTags([...allSharedTags, newTag]);
                        setTagsToAdd(new Set([...tagsToAdd, newTag]));
                      },
                      add: (tag) => {
                        setAllSharedTags([...allSharedTags, tag]);
                        setTagsToAdd(new Set([...tagsToAdd, tag]));
                      },
                    }}
                    onDelete={(tag) => {
                      const i = allSharedTags.findIndex((t) => t.id === tag.id);
                      const newArray = [...allSharedTags];
                      newArray.splice(i, 1);
                      if (tagsToAdd.has(tag)) {
                        tagsToAdd.delete(tag);
                      } else {
                        setTagsToRemove(new Set([...tagsToRemove, tag]));
                      }
                      setAllSharedTags(newArray);
                    }}
                  />
                )}
                {showUpdateTags && isContract(organization.customerType) && (
                  <Box>
                    <Typography variant='h5' className={classes.header}>
                      Tags
                    </Typography>
                    <Typography className={classes.subtitle}>
                      Edit members' tags
                    </Typography>
                    <Box mt={3}>
                      <TagSelect
                        organization={organization}
                        value={selectedTagIds}
                        onChange={(_, newValues) => setSelectedTagIds(newValues)}
                        multiple
                        disabled={disabled}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
              <Box className={classes.footer}>
                {showNewTeamStyles ? (
                  <FlatButton type='submit' fullWidth disabled={disabled}>
                    Save changes
                  </FlatButton>
                ) : (
                  <Box
                    sx={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      flexWrap: 'wrap',
                    }}
                  >
                    <Button
                      className={classes.buttonSecondary}
                      onClick={() => {
                        setIsShowing(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <ArchiveButtonOriginal
                      afterUpdate={(alertMessage: string) => {
                        snackbarAlert(alertMessage);
                        setSubmitting(false);
                        setIsShowing(false);
                        setSelectedMembers(new Set());
                      }}
                      classes={classes}
                      memberIds={members?.map((m) => m.id)}
                      organizationId={organization?.id}
                      restoreMode={isArchivedPage}
                    />
                    <Button className={classes.buttonPrimary} onClick={onSave}>
                      Save
                    </Button>
                  </Box>
                )}
              </Box>
            </div>
          </>
        )}
      </Drawer>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity='success'>{alertMessage}</Alert>
      </Snackbar>
    </>
  );
};

const useStyles = makeStyles({
  drawer: {
    overflowY: 'scroll',
    '& .MuiDrawer-paperAnchorRight': {
      width: '30rem',
    },
  },
  container: {
    padding: '1.5rem',
  },
  row: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    width: '100%',
  },
  footer: {
    marginTop: theme.spacing(8),
  },
  close: {
    color: colors.MediumGray,
    transition: 'color 300ms ease-in-out',
    '&:hover': {
      color: colors.Main,
    },
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 500,
    fontSize: 30,
    lineHeight: 1.07,
    color: colors.Midnight,
    paddingBottom: theme.spacing(8),
  },
  header: {
    fontWeight: 500,
    fontSize: '1.125rem',
    lineHeight: 1.56,
    color: colors.Midnight,
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '.75rem',
    lineHeight: 1.33,
    color: colors.DarkGray,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
  },
  buttonPrimary: {},
  buttonSecondary: {},
});

const useOriginalStyles = makeStyles({
  drawer: {
    '& .MuiDrawer-paperAnchorRight': {
      width: '45%',
      minWidth: '400px',
    },
  },
  buttonPrimary: {
    marginRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    textDecoration: 'none',
    backgroundImage: primaryGradient,
    color: 'white',
    width: theme.spacing(50),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    borderRadius: theme.spacing(40),
    marginTop: '1rem',
  },
  buttonSecondary: {
    marginRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    textDecoration: 'none',
    color: theme.palette.primary.main,
    width: theme.spacing(50),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    border: '1px solid',
    borderRadius: theme.spacing(40),
    borderColor: theme.palette.primary.main,
    marginTop: '1rem',
  },
  container: {
    padding: '1.5rem',
  },
  row: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    width: '100%',
  },
  footer: {
    alignItems: 'center',
    display: 'flex',
    bottom: '2rem',
  },
  header: {
    fontWeight: 500,
    lineHeight: '32px',
  },
  subtitle: {
    lineHeight: '22px',
    fontSize: '16px',
    fontWeight: 500,
    marginBottom: '4px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
  },
  title: {},
  headerContainer: {},
  close: {},
});

export default BulkEditTeamMembers;
