// @ts-strict-ignore
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/styles';
import { Loading, NotFoundError } from 'components/core';
import _ from 'lodash';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import theme, { primaryGradient } from 'theme';
import {
  GetUserWithOrgsQuery,
  useGetUserWithOrgsQuery,
  UserTag,
  ViewerFragmentFragment,
} from 'types';

import TabNav from 'components/TabNav';
import UpgradeButton from 'components/UpgradeButton';
import { useLocation } from 'react-use';
import { PageMetaData } from 'Routes';
import Team from './Team/Team';
import { isUpsellable, isTransactional } from 'utils/customerTypeUtils';
import TeamsUpsell from './Team/UpsellView';
import { createNewTags } from 'gql/interactions';

type Props = {
  viewer: ViewerFragmentFragment | undefined;
};

// We explicitly do NOT get members from this query
export type TeamPageOrganization = Omit<
  GetUserWithOrgsQuery['viewer']['orgs'][0],
  'members' | 'archivedMembers'
>;

export type TeamTabProps = {
  classes: ClassNameMap<any>;
  organization: TeamPageOrganization;
  viewer: ViewerFragmentFragment;
  currentTab: TabName;
};

export enum TabName {
  Active = 'Active',
  Archived = 'Archived',
  Pending = 'Pending',
}

export const findOrCreateTags = async (
  tags: UserTag[],
  organizationId: string,
): Promise<UserTag[]> => {
  let existingTags = tags.filter((tag) => tag.id);
  if (tags.length > existingTags.length) {
    const newTagNames = tags.filter((val) => !val.id).map((val) => val.name);
    const createdTags = await createNewTags({ names: newTagNames, organizationId });
    existingTags = existingTags.concat(createdTags);
  }
  return existingTags;
};

const TabLookup = {
  [TabName.Active]: (props: TeamTabProps) => (
    <Team {...props} currentTab={TabName.Active} />
  ),
  [TabName.Pending]: (props: TeamTabProps) => (
    <Team {...props} currentTab={TabName.Pending} />
  ),
  [TabName.Archived]: (props: TeamTabProps) => (
    <Team {...props} currentTab={TabName.Archived} />
  ),
};

const Loader = ({ viewer }: Props) => {
  const { data, loading } = useGetUserWithOrgsQuery({
    variables: { getMembers: false },
  });
  if (loading || !data) return <Loading />;

  if (isTransactional(viewer?.customerType)) return <TeamsUpsell />;
  // The array of orgs is to support a user being able to belong to more than a single org.
  // For now, we are assuming that users will only belong to one organization.
  const organization = _.first(_.get(data, 'viewer.orgs')) as TeamPageOrganization;
  if (!organization) {
    return (
      <NotFoundError
        title='No Organization Found'
        subtitle='Unable to show organization settings as you are not a member of any organization'
      />
    );
  }

  return <Settings organization={organization} viewer={viewer} />;
};

const Settings = ({
  viewer,
  organization,
}: {
  viewer: ViewerFragmentFragment;
  organization: TeamPageOrganization;
}) => {
  const classes = useStyles();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(
    location.hash ? decodeURI(location.hash.substring(1)) : TabName.Active.toString(),
  );

  const tabProps: Omit<TeamTabProps, 'isArchivedPage' | 'currentTab'> = {
    viewer,
    classes,
    organization,
  };

  const canUpsell = isUpsellable(viewer?.customerType);

  return (
    <Box className={classes.root}>
      <Helmet>
        <title>{PageMetaData.TEAM.title}</title>
      </Helmet>
      <Box className={classes.pageHeader}>
        <Typography variant={'h3'}>Team</Typography>
        {canUpsell && <UpgradeButton page='team' />}
      </Box>
      <Box className={classes.pageTabs}>
        <TabNav
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabs={Object.keys(TabLookup)}
        />
      </Box>
      <Box className={classes.mainWrapper}>
        <Box className={classes.content}>{TabLookup[selectedTab](tabProps)}</Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  pageHeader: {
    margin: `0 auto ${theme.spacing(5)}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'calc(100% - 48px)',
    maxWidth: '1600px',
    '& h3': {
      fontWeight: 300,
      color: theme.palette.grey[900],
      letterSpacing: '-1px',
    },
  },
  pageTabs: {
    margin: `0 auto ${theme.spacing(4)}`,
    alignItems: 'center',
    width: 'calc(100% - 48px)',
    height: '48px',
    maxWidth: '1600px',
    overflow: 'hidden',
  },
  mainWrapper: {
    height: '100%',
    padding: theme.spacing(6),
    borderTop: `1px solid ${theme.palette.primary[200]}`,
    backgroundColor: theme.palette.primary[50],
  },
  content: {
    marginTop: theme.spacing(4),
    width: 'calc(100% - 48px)',
    maxWidth: '1600px',
    margin: '0 auto',
  },
  pendingButton: {
    background: 'rgba(242, 232, 243, 0.2)',
    borderRadius: '4px',
    border: '1px solid #7B1783',
    textTransform: 'none',
  },
  root: {
    padding: '2rem',
  },
  contractRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  bold: {
    fontWeight: 'bold',
  },
  button: {
    marginRight: theme.spacing(5),
    fontWeight: theme.typography.fontWeightMedium,
    textDecoration: 'none',
    backgroundImage: primaryGradient,
    color: 'white',
    width: theme.spacing(42),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    borderRadius: theme.spacing(40),
  },
  hrisCallToAction: {
    color: theme.palette.common.white,
    textTransform: 'none',
    borderRadius: theme.spacing(40),
    background: theme.palette.primary.main,
    width: '200px',
    '&:hover': {
      background: theme.palette.text.secondary,
    },
  },
  checkBoxColumn: {
    marginRight: '10px',
  },
  gray: {
    backgroundColor: '#F8F8F8',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tableContainer: {
    width: '100%',
    minWidth: '550px',
    maxHeight: '60vh',
    overflowY: 'scroll',
    border: `1px solid ${theme.palette.grey[200]}`,
    '&::-webkit-scrollbar': {
      width: '12px',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': `inset 0 0 12px ${theme.palette.primary[100]}`,
      'box-shadow': `inset 0 0 12px ${theme.palette.primary[100]}`,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary[600],
      '&:hover': {
        backgroundColor: theme.palette.primary[700],
      },
    },
  },
  row: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingTop: '9px',
    paddingBottom: '9px',
  },
  pl: {
    paddingLeft: '1rem',
  },
  alignCenter: {
    alignItems: 'center',
  },
  editable: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'flex-end',
  },
  editIcon: {
    color: '#7B1783',
    cursor: 'pointer',
    '&.MuiSvgIcon-root': {
      fontSize: '16px',
      fontWeight: 200,
    },
  },
  flexCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  navMenu: {
    fontSize: '2rem',
  },
});

export default Loader;
