import { css } from '@emotion/react';
import { LinearProgress } from '@material-ui/core';
import ArrowLeft from '@mysteryco/design/icons/ArrowLeft';
import ArrowRight from '@mysteryco/design/icons/ArrowRight';
import MessagePlusCircle from '@mysteryco/design/icons/MessagePlusCircle';
import { colors } from '@mysteryco/design/src';
import { Form, Formik } from 'formik';
import Button from 'glue/components/buttons/Button';
import TextLink from 'glue/components/buttons/TextLink';
import FormikFieldError from 'glue/components/forms/FormikFieldError';
import FormikSubmitButton from 'glue/components/forms/FormikSubmitButton';
import { useScrollTopOnLoad } from 'hooks';
import mixpanel from 'mixpanel-browser';
import { useState } from 'react';
import theme from 'theme';
import {
  Maybe,
  MoralePulseQuestion,
  MoralePulseQuestionReponse,
  MoralePulseQuestionType,
} from 'types';
import * as yup from 'yup';
import MoralePulseQuestionField from './MoralePulseQuestionField';
import MoralePulseSurveyFooter from './MoralePulseSurveyFooter';
import PulseQuestionComment from './components/PulseQuestionComment';

interface PulseQuestionProps {
  question: MoralePulseQuestion;
  index: number;
  surveyLength: number;
  onSubmit: (answer: MoralePulseQuestionReponse) => Promise<void>;
  previous: () => void;
  getInitialValues: (questionId: string) => MoralePulseQuestionReponse;
  type?: MoralePulseQuestionType;
  logoUrl?: Maybe<string>;
}

const PulseQuestionSchema = yup.object().shape({
  value: yup.number().moreThan(-1, 'Add your answer to continue'),
});

const PulseQuestion = ({
  question,
  index,
  surveyLength,
  onSubmit,
  previous,
  getInitialValues,
  logoUrl,
}: PulseQuestionProps) => {
  useScrollTopOnLoad();
  const [showCommentPage, setShowCommentPage] = useState(false);

  const { id, copy } = question;
  const initialValues = getInitialValues(id);
  const progress = (index / surveyLength) * 100;
  const currPosition = index + 1;
  const atLastQuestion = currPosition === surveyLength;
  const shouldDisableComment = (value: number | undefined) =>
    value === undefined || isNaN(value) || value < 0;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      validationSchema={PulseQuestionSchema}
    >
      {({ values }) => (
        <Form>
          <div css={styles.outerContainer}>
            <div>
              <LinearProgress
                css={styles.progressBar}
                variant='determinate'
                color='secondary'
                value={progress}
              />
              <div css={styles.container}>
                <div css={styles.content}>
                  {logoUrl && (
                    <div>
                      <img css={{ maxWidth: 350 }} src={logoUrl} alt={'Company logo'} />
                    </div>
                  )}
                  <div
                    css={styles.questionProgress}
                  >{`Question ${currPosition} of ${surveyLength}`}</div>
                  <div css={styles.prompt}>{copy}</div>

                  <FormikFieldError fieldName='value' css={styles.errorMsg} />

                  {showCommentPage ? (
                    <PulseQuestionComment
                      back={() => setShowCommentPage(false)}
                      questionId={id}
                    />
                  ) : (
                    <>
                      <MoralePulseQuestionField question={question} />
                      <div css={styles.commentContainer}>
                        <TextLink
                          onClick={() => {
                            mixpanel.track('pls-add-comment-opened');
                            setShowCommentPage(true);
                          }}
                          Icon={() => (
                            <MessagePlusCircle size={24} color={colors.Glue_Ink00} />
                          )}
                          disabled={shouldDisableComment(values.value ?? undefined)}
                        >
                          Add comment
                        </TextLink>
                      </div>
                      <div css={styles.buttonContainer}>
                        {index > 0 ? (
                          <Button
                            startIcon={<ArrowLeft size={22} color='currentColor' />}
                            onClick={previous}
                            color='white'
                            type='button'
                          >
                            <div css={styles.backText}>Back</div>
                          </Button>
                        ) : (
                          <div />
                        )}
                        <FormikSubmitButton
                          endIcon={<ArrowRight size={22} color='currentColor' />}
                          color='black'
                        >
                          {atLastQuestion ? 'Finish' : 'Next'}
                        </FormikSubmitButton>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <MoralePulseSurveyFooter />
          </div>
        </Form>
      )}
    </Formik>
  );
};

const styles = {
  backText: css({
    display: 'none',
    '@media (min-width: 960px)': {
      display: 'block',
    },
  }),
  buttonContainer: css({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(10),
  }),
  commentContainer: css({
    marginTop: theme.spacing(5),
    width: '100%',
  }),
  container: css({
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  content: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(8),
    width: '320px',
    '@media (min-width: 600px)': {
      width: '440px',
    },
    '@media (min-width: 960px)': {
      width: '600px',
    },
  }),
  errorMsg: css({
    marginTop: theme.spacing(5),
    height: '28px',
    display: 'block',
  }),
  formControl: css({
    width: '100%',
    marginTop: theme.spacing(8),
  }),
  outerContainer: css({
    marginTop: theme.spacing(8),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }),
  progressBar: css({
    width: '100%',
    height: theme.spacing(2),
    borderRadius: theme.spacing(2),
    backgroundColor: colors.Glue_LavenderLight,

    '& .MuiLinearProgress-barColorSecondary': {
      backgroundColor: colors.Glue_DarkMint10,
    },
  }),
  prompt: css({
    lineHeight: '120%',
    marginTop: theme.spacing(8),
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '24px',
    '@media (min-width: 960px)': {
      fontWeight: 400,
      fontSize: '32px',
    },
  }),
  questionProgress: css({
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '120%',
    textAlign: 'center',
    color: 'rgba(59, 59, 59, 0.7)',
    marginTop: theme.spacing(5),
  }),
};

export default PulseQuestion;
