// @ts-strict-ignore
import { Box, Typography } from '@material-ui/core';
import { AccountCircleOutlined, ChevronRight } from '@material-ui/icons';
import config from 'config';

import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import { ViewerFragmentFragment } from 'types';
import theme from 'theme';

const LoggedIn = ({ viewer }: { viewer: ViewerFragmentFragment }) => (
  <Box
    style={{
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: '16px',
      paddingRight: '16px',
      cursor: 'pointer',
    }}
    border='solid 1px'
    borderColor={theme.palette.primary[200]}
    borderRadius='4px'
    display='flex'
    flexDirection='row'
    alignItems='center'
    onClick={() => {
      mixpanel.track('user clicked logged-in', {
        customerType: viewer?.customerType,
        firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
      });
      window.location.assign(config.teams.url);
    }}
  >
    <AccountCircleOutlined style={{ marginRight: '14px' }} />
    <Box display='flex' flexDirection='column' style={{ marginRight: '14px' }}>
      <Typography>{viewer.name}</Typography>
      {/* TODO - Another multiple organization footgun -TW */}
      {!_.isEmpty(viewer.orgs) && (
        <Typography style={{ fontSize: '14px' }}>{_.first(viewer.orgs)?.name}</Typography>
      )}
    </Box>
    <ChevronRight htmlColor={theme.palette.primary.main} />
  </Box>
);

export default LoggedIn;
