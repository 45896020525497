import { css } from '@emotion/react';
import { Coal_10, Coal_40 } from '@mysteryco/design/src/tokens/colors';

import TextLink from 'glue/components/buttons/TextLink';
import theme from 'theme';

const SeeInAction = ({ testMeetupsRoute }: { testMeetupsRoute: string }) => (
  <div css={styles.container}>
    <span css={styles.title}>{strings.title}</span>
    <span css={styles.subTitle}>{strings.subTitle}</span>
    <TextLink to={testMeetupsRoute}>{strings.link}</TextLink>
  </div>
);

const strings = {
  link: 'Set up a test Meetups',
  title: 'See Meetups in action',
  subTitle:
    'Do you want to test Meetups first? Just pick another person, and see how it works first-hand!',
};

const styles = {
  container: css({
    padding: theme.spacing(6),
    border: `solid 1px ${Coal_40}`,
    borderRadius: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  }),
  title: css({
    color: Coal_10,
    fontWeight: 800,
    fontSize: '15px',
  }),
  subTitle: css({
    color: Coal_10,
    opacity: 0.7,
    fontSize: '15px',
    lineHeight: '160%',
  }),
};

export default SeeInAction;
