import 'semantic-ui-css/semantic.min.css';
import './index.css';

import { SnackbarProvider } from 'notistack';
import BreakpointsProvider from 'providers/BreakpointsProvider';
import React from 'react';
import ReactDOM from 'react-dom';
import FullStory from 'react-fullstory';

import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@material-ui/styles';
import App from './App';
import { SnackbarUtilsConfigurator } from './components/core/SnackbarUtils';
import config, { isProd } from './config';
import { client } from './gql/client';
import reportWebVitals from './reportWebVitals';
import theme from './theme';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import TagManager from 'react-gtm-module';
import { ConfusedSessionObserver } from 'services/ConfusedSessionObserver';

const tagManagerArgs = {
  gtmId: config.google.tagsManagerId,
};

const FULLSTORY_ORG_ID = `RGV79`;

if (isProd) {
  Sentry.init({
    dsn: `https://${config.sentry.key}@o251240.ingest.sentry.io/${config.sentry.project}`,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.3,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  });
  // Initialize Google Tag Manager
  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <SnackbarProvider maxSnack={3} hideIconVariant autoHideDuration={5000}>
          {config.fullStory.enabled && <FullStory org={FULLSTORY_ORG_ID} />}
          <SnackbarUtilsConfigurator />
          <BreakpointsProvider>
            <App />
          </BreakpointsProvider>
        </SnackbarProvider>
      </ApolloProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Start looking for confused sessions.
ConfusedSessionObserver.init();
