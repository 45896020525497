import { gql } from '@apollo/client';
import { css } from '@emotion/react';
import CheckCircle from '@mysteryco/design/icons/CheckCircle';
import Download04 from '@mysteryco/design/icons/Download04';
import { colors } from '@mysteryco/design/src';
import Google from 'glue/assets/logos/Google';
import theme from 'theme';
import { ConnectTeamCalendarsViewerFragment, Maybe, NylasConnectionState } from 'types';
import { getIntegrationsPath } from '../../Settings/Integrations';
import CalendarConnectionProgress, {
  ACCURACY_THRESHOLD,
  INSIGHTS_THRESHOLD,
} from './CalendarConnectionProgress';
import Button, { LinkButton } from 'glue/components/buttons/Button';

const CONNECT_ORG_INSTRUCTION_PDF =
  'https://mystery-public-assets.s3.us-west-2.amazonaws.com/GlueInsightsForGoogleWorkspaces.pdf';

type UserForCalendarConnection = {
  nylasConnectionState?: Maybe<NylasConnectionState>;
};
const isUserConnected = (user: UserForCalendarConnection) =>
  user.nylasConnectionState === NylasConnectionState.Active;
export const isOrgConnected = (organization: {
  nylasServiceAccounts?: Maybe<Maybe<{ id: string }>[]>;
}) => (organization?.nylasServiceAccounts?.length ?? 0) > 0;
const getMemberCount = (
  organization:
    | {
        members?: Maybe<Maybe<UserForCalendarConnection>[]>;
      }
    | undefined
    | null,
) => organization?.members?.length ?? 0;
const getConnectedCount = (
  organization:
    | {
        members?: Maybe<Maybe<UserForCalendarConnection>[]>;
      }
    | undefined
    | null,
) =>
  organization?.members?.filter((member) => !!member && isUserConnected(member))
    ?.length ?? 0;

export const isLowAccuracy = (organization: {
  members?: Maybe<Maybe<UserForCalendarConnection>[]>;
}) => getConnectedCount(organization) / getMemberCount(organization) < ACCURACY_THRESHOLD;

const ConnectIndividual = ({
  calendarConnected,
  current,
  total,
}: {
  calendarConnected: boolean;
  current: number;
  total: number;
}) => {
  return (
    <>
      <div css={styles.detail}>
        <h4 css={styles.header}>Connect individual work calendars</h4>
        <p css={styles.content}>
          Encourage your team to connect their calendars to Glue to unlock your teams’
          connection insights.
        </p>
      </div>
      {calendarConnected && (
        <div css={styles.action}>
          <CheckCircle /> You've connected your calendar
        </div>
      )}
      <CalendarConnectionProgress current={current} total={total} />
    </>
  );
};

const ConnectOrg = () => {
  return (
    <>
      <div css={styles.detail}>
        <h4 css={styles.header}>Connect your org’s calendar</h4>
        <p css={styles.content}>
          Unlock insights by granting Glue access to your entire org’s calendars
        </p>
      </div>
      <div css={styles.action}>
        <LinkButton
          shape='round'
          color='black'
          endIcon={<Download04 />}
          css={{ width: 'fit-content' }}
          to={CONNECT_ORG_INSTRUCTION_PDF}
          newTab
        >
          Download instructions
        </LinkButton>
      </div>
      <p css={styles.aside}>
        Org calendar connection is only available for Google Workspaces
      </p>
    </>
  );
};

const GoogleConnected = () => {
  return (
    <Button
      shape='round'
      disabled
      startIcon={<Google size={24} />}
      endIcon={<CheckCircle />}
      css={{ padding: `${theme.spacing(2.5)} ${theme.spacing(4)}` }}
    >
      Connected with Google
    </Button>
  );
};

const OrgConnected = () => {
  return (
    <>
      <div css={styles.detail}>
        <h4 css={styles.header}>You’ve connected your org’s calendar</h4>
        <p css={styles.content}>
          Your org’s calendar is connected, and you can manage this in your{' '}
          <a href={getIntegrationsPath()}>Settings</a>
        </p>
      </div>
      <div css={styles.action}>
        <GoogleConnected />
      </div>
    </>
  );
};

const ConnectTeamCalendars = ({
  viewer,
}: {
  loading?: boolean;
  viewer: ConnectTeamCalendarsViewerFragment;
}) => {
  const organization = viewer?.defaultOrganization;
  const userConnected = isUserConnected(viewer);
  const orgConnected = !!organization && isOrgConnected(organization);

  const memberCount = getMemberCount(organization);
  const connectedCount = getConnectedCount(organization);
  const progress = (connectedCount / memberCount) * 100;

  const showConnectIndividual = !orgConnected;
  const showConnectOrg = progress < INSIGHTS_THRESHOLD && !orgConnected;

  return (
    <div css={styles.root}>
      {showConnectIndividual && (
        <ConnectIndividual
          current={connectedCount}
          total={memberCount}
          calendarConnected={userConnected}
        />
      )}
      {showConnectOrg && <ConnectOrg />}
      {orgConnected && <OrgConnected />}
    </div>
  );
};

ConnectTeamCalendars.fragments = gql`
  fragment ConnectTeamCalendarsViewer on User {
    id
    nylasConnectionState
    defaultOrganization {
      id
      nylasServiceAccounts {
        id
        createdAt
      }
      members {
        id
        nylasConnectionState
      }
    }
  }
`;

const styles = {
  root: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: `${theme.spacing(5)} 0`,
    gap: theme.spacing(3),
  }),
  detail: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    '&:not(:first-of-type)': {
      marginTop: theme.spacing(4),
    },
  }),
  header: css({
    fontWeight: 700,
    fontSize: '1.125rem',
    lineHeight: 2,
    color: colors.Glue_Ink00,
  }),
  content: css({
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.6,
    color: colors.Glue_Ink00,
  }),
  aside: css({
    marginTop: theme.spacing(3),
    fontWeight: 400,
    fontSize: '.875rem',
    lineHeight: 2,
    color: colors.Glue_Ink00,
  }),
  action: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    gap: theme.spacing(2),
    color: colors.Glue_Ink00,
    fontWeight: 800,
    fontSize: '1rem',
    lineHeight: 1.5,
    '& > svg': {
      color: colors.Glue_Ink00,
    },
  }),
};

export default ConnectTeamCalendars;
