// @ts-strict-ignore
import { Props } from 'components/SSFSteps/types';
import _ from 'lodash';
import { pluralize } from 'humanize-plus';
import { headCountFromProps } from 'utils/state';
import {
  Contract,
  ContractFragmentFragment,
  ContractUnitType,
  Cost,
  CostUnit,
  LineItemCategory,
  PricingBand,
  TeamEventInvoiceFragmentFragment,
} from '../../types';
import { getMinimumEventSize } from './booking';
import { CONTRACT_UNIT_PHRASE_LOOKUP } from './contract';
import { getHasPhysicalGoodsOrUpgradeFromTemplate } from 'components/SSFSteps/EventSelectionDetails/helpers';
import { ExperienceInfo } from 'types/selfServeFlow';
import { TAX_RATE } from 'constants/Costs';

const DEFAULT_SURPRISE_TO_ALL_MIN_HEAD_COUNT = 15;
/*
 * DEFAULT_SURPRISE_TO_MIN_COST_CENTS is 
 * the minimum cents count for less than 16 users when a user select's suprise all
 */
export const DEFAULT_SURPRISE_TO_MIN_COST_CENTS = 75000;
export const DEFAULT_SURPRISE_TO_ALL_COST_DOLLARS = 50;
export const DEFAULT_SURPRISE_TO_ALL_COST_CENTS =
  DEFAULT_SURPRISE_TO_ALL_COST_DOLLARS * 100;

// default value is 1 credit per guest, represented in centi form
export const DEFAULT_SURPRISE_TO_ALL_COST_CREDITS = 100;

/*
 * DEFAULT_SURPRISE_TO_ALL_BAND is a placeholder PricingBand fitted for the
 * surpise-to-all logic needs, since this band doesn't exist in the DB.
 *
 * Technically, we could leave 'maxUsers' empty and findPricingBand assumptions
 * would work on this, but we use 'maxUsers' to find the minimum event size
 * so we can't have it both ways (yet) :/
 */
export const DEFAULT_SURPRISE_TO_ALL_BANDS: PricingBand[] = [
  {
    minUsers: 1,
    maxUsers: DEFAULT_SURPRISE_TO_ALL_MIN_HEAD_COUNT,
    cents: DEFAULT_SURPRISE_TO_MIN_COST_CENTS,
    credits: DEFAULT_SURPRISE_TO_ALL_COST_CREDITS,
    unit: CostUnit.Event,
  },
  {
    minUsers: DEFAULT_SURPRISE_TO_ALL_MIN_HEAD_COUNT + 1,
    cents: DEFAULT_SURPRISE_TO_ALL_COST_CENTS,
    credits: DEFAULT_SURPRISE_TO_ALL_COST_CREDITS,
    unit: CostUnit.User,
  },
];

export type ContractCost = {
  additionalUnits: number;
  additionalPremiumUnits: number;
  unitCostCents: number;
  unitCount: number;
  premiumCostCents: number;
  premiumUnitCount: number;
  total: number;
  suppliesIncluded: boolean;
  type: ContractUnitType;
  upgradeWithoutSupplies: boolean;
};

export type ContractInfo = {
  id: string;
  premiumUnitCostCents?: number;
  type: ContractUnitType;
};

type CostInfo = Omit<Cost, 'id' | 'ctmUnit' | 'ctmCents'>;

export const centsToDollars = (cents: number) => {
  return Math.trunc(cents / 100);
};

export const centsToDollarString = (cents: number, showCents: boolean = true): string => {
  const hasCents = cents % 100 !== 0;

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: showCents || hasCents ? undefined : 0,
    maximumFractionDigits: showCents || hasCents ? undefined : 0,
  }).format(centsToDollars(cents));
};

export const findPricingBand = ({
  ctcPricingBands,
  headCount,
}: {
  ctcPricingBands: PricingBand[];
  headCount: number;
}): PricingBand => {
  const band = _.find(
    ctcPricingBands,
    (b) => b.minUsers <= headCount && b.maxUsers >= headCount,
  );
  if (!band) {
    return _.find(ctcPricingBands, (b) => _.isNil(b.maxUsers));
  }
  return band;
};

export const getTotalEventCostCents = ({
  ctcPricingBands,
  headCount,
}: {
  ctcPricingBands: PricingBand[];
  headCount: number;
}): number => {
  const applicableBand = findPricingBand({ ctcPricingBands, headCount });
  if (!applicableBand) return 0;
  if (applicableBand.unit === CostUnit.Event) return applicableBand.cents;
  else return headCount * applicableBand.cents;
};

export const getContractFromTeamEventInvoiceFragment = ({
  finalizedPrice,
}: Pick<
  TeamEventInvoiceFragmentFragment,
  'finalizedPrice'
>): ContractFragmentFragment => {
  const contractLineItem = _.find(
    finalizedPrice.lineItems,
    (li) => li.category === LineItemCategory.Contract,
  );
  const contract = contractLineItem?.contract;
  return contract;
};

//TODO
export const getContractCostForInvoice = ({
  experience,
  finalHeadCount,
  finalizedPrice,
}: TeamEventInvoiceFragmentFragment): ContractCost => {
  const contractLineItem = _.find(
    finalizedPrice.lineItems,
    (li) => li.category === LineItemCategory.Contract,
  );
  const contract = getContractFromTeamEventInvoiceFragment({ finalizedPrice });
  if (!contract) return null;
  const useUpgrade = experience.hasPhysicalGoods || experience.requiresUpgrade;

  if (contract.type === ContractUnitType.Recurring) {
    return {
      additionalUnits: Math.max(finalHeadCount - contractLineItem.contractUnits, 0),
      additionalPremiumUnits: useUpgrade
        ? Math.max(finalHeadCount - contractLineItem.contractPremiumUnits, 0)
        : 0,
      unitCostCents:
        (finalHeadCount - contractLineItem.contractUnits) * contract.unitCostCents,
      unitCount: contractLineItem.contractUnits,
      premiumCostCents: useUpgrade
        ? (finalHeadCount - contractLineItem.contractPremiumUnits) *
          contract.premiumUnitCostCents
        : 0,
      premiumUnitCount: contractLineItem.contractPremiumUnits,
      suppliesIncluded: experience.hasPhysicalGoods,
      total: finalizedPrice.totalCents,
      type: contract.type,
      upgradeWithoutSupplies: experience.requiresUpgrade && !experience.hasPhysicalGoods,
    };
  }

  if (contract.type === ContractUnitType.Cents) {
    return {
      additionalUnits: finalizedPrice.totalCents,
      additionalPremiumUnits: 0,
      unitCostCents: 1,
      unitCount: contractLineItem.contractUnits,
      premiumCostCents: 0,
      premiumUnitCount: 0,
      suppliesIncluded: experience.hasPhysicalGoods,
      total: finalizedPrice.totalCents + contractLineItem.contractUnits,
      type: contract.type,
      upgradeWithoutSupplies: experience.requiresUpgrade && !experience.hasPhysicalGoods,
    };
  }

  if (contract.type === ContractUnitType.Event) {
    return {
      additionalUnits: !contractLineItem.contractUnits ? 1 : 0,
      additionalPremiumUnits:
        useUpgrade && !contractLineItem.contractPremiumUnits ? 1 : 0,
      unitCostCents: !contractLineItem.contractUnits ? contract.unitCostCents : 0,
      unitCount: contractLineItem.contractUnits,
      premiumCostCents:
        useUpgrade && !contractLineItem.contractPremiumUnits
          ? contract.premiumUnitCostCents
          : 0,
      premiumUnitCount: contractLineItem.contractPremiumUnits,
      suppliesIncluded: experience.hasPhysicalGoods,
      total: finalizedPrice.totalCents,
      type: contract.type,
      upgradeWithoutSupplies: experience.requiresUpgrade && !experience.hasPhysicalGoods,
    };
  }
};

export const getContractCostFromProps = (props: Props<any>): ContractCost => {
  let contracts = props.viewer?.contracts;
  contracts = contracts.filter((contract) => contract.status !== "Expired");
  if (!contracts?.length) return null;

  const headCount = headCountFromProps(props);
  const minimumEventSize = getMinimumEventSize(
    _.get(props, 'globalState.eventSelection.template'),
  );

  const contract = props.globalState?.contract || contracts[0];
  const hasPhysicalGoods = !!_.get(props, 'globalState.eventSelection.hasPhysicalGoods');
  const requiresUpgrade = !!_.get(props, 'globalState.eventSelection.requiresUpgrade');
  const usePremiumUnits = hasPhysicalGoods || requiresUpgrade;
  const eventSize = Math.max(headCount, minimumEventSize);
  const isSurpriseToAll = !!_.get(props, 'globalState.surpriseSelection.surpriseToAll');

  switch (contract.type) {
    case ContractUnitType.Recurring: {
      const unitCostCents =
        eventSize <= contract.unitCount
          ? 0
          : (eventSize - contract.unitCount) * contract.unitCostCents;
      const premiumCostCents =
        eventSize <= contract.premiumUnitCount || !usePremiumUnits
          ? 0
          : (eventSize - contract.premiumUnitCount) * contract.premiumUnitCostCents;

      return {
        additionalUnits:
          eventSize > contract.unitCount ? eventSize - contract.unitCount : 0,
        additionalPremiumUnits:
          usePremiumUnits && eventSize > contract.premiumUnitCount
            ? eventSize - contract.premiumUnitCount
            : 0,
        unitCostCents,
        unitCount: Math.min(contract.unitCount, eventSize),
        premiumCostCents,
        premiumUnitCount: usePremiumUnits
          ? Math.min(contract.premiumUnitCount, eventSize)
          : 0,
        suppliesIncluded: hasPhysicalGoods,
        total: unitCostCents + (usePremiumUnits ? premiumCostCents : 0),
        type: contract.type,
        upgradeWithoutSupplies: usePremiumUnits && !hasPhysicalGoods,
      };
    }

    case ContractUnitType.Credits: {
      const cost = _.get(props, 'globalState.eventSelection.template.cost');

      const band = isSurpriseToAll
        ? { credits: DEFAULT_SURPRISE_TO_ALL_COST_CREDITS }
        : findPricingBand({
            ctcPricingBands: cost.ctcPricingBands,
            headCount: eventSize,
          });

      const eventCost = band.credits * eventSize;
      const creditsNeededCenti = Math.abs(Math.min(contract.unitCount - eventCost, 0));
      const creditsNeededWhole = Math.ceil(creditsNeededCenti / 100);

      // calculate overages aka money for extra credits needed to complete this booking
      // Credits are purchased as a whole unit per contract. Could be zero
      const unitCostCents = creditsNeededWhole * contract.unitCostCents;

      return {
        additionalUnits: creditsNeededWhole,
        unitCostCents,
        unitCount: eventCost / 100,
        additionalPremiumUnits: 0,
        premiumCostCents: 0,
        premiumUnitCount: 0,
        suppliesIncluded: hasPhysicalGoods,
        total: unitCostCents,
        type: contract.type,
        upgradeWithoutSupplies: usePremiumUnits && !hasPhysicalGoods,
      };
    }

    case ContractUnitType.Cents: {
      const cost = _.get(props, 'globalState.eventSelection.template.cost');
      const ctcPricingBands = isSurpriseToAll
        ? DEFAULT_SURPRISE_TO_ALL_BANDS
        : cost.ctcPricingBands;

      const band = findPricingBand({
        ctcPricingBands: ctcPricingBands,
        headCount: eventSize,
      });
      const eventCost =
        band.unit === CostUnit.Event ? band.cents : band.cents * eventSize;
      const unitCostCents = Math.max(eventCost - contract.unitCount, 0);

      return {
        additionalUnits: unitCostCents,
        additionalPremiumUnits: 0,
        unitCostCents,
        unitCount: Math.min(eventCost, contract.unitCount),
        premiumCostCents: 0,
        premiumUnitCount: 0,
        suppliesIncluded: hasPhysicalGoods,
        total: unitCostCents,
        type: contract.type,
        upgradeWithoutSupplies: usePremiumUnits && !hasPhysicalGoods,
      };
    }

    case ContractUnitType.Event: {
      const unitCostCents = !contract.unitCount ? contract.unitCostCents : 0;
      const premiumCostCents =
        usePremiumUnits && !contract.premiumUnitCount ? contract.premiumUnitCostCents : 0;

      return {
        additionalUnits: !contract.unitCount ? 1 : 0,
        additionalPremiumUnits: usePremiumUnits && !contract.premiumUnitCount ? 1 : 0,
        unitCostCents,
        unitCount: contract.unitCount ? 1 : 0,
        premiumCostCents,
        premiumUnitCount: usePremiumUnits && contract.premiumUnitCount ? 1 : 0,
        suppliesIncluded: hasPhysicalGoods,
        total: unitCostCents + premiumCostCents,
        type: contract.type,
        upgradeWithoutSupplies: usePremiumUnits && !hasPhysicalGoods,
      };
    }

    default:
      return null;
  }
};

const getContractCost = ({
  contract,
  cost,
  headCount,
  useUpgrade,
}: {
  cost: CostInfo;
  contract?: ContractInfo;
  headCount?: number;
  useUpgrade?: boolean;
}) => {
  if (!contract) {
    return null;
  }

  switch (contract.type) {
    case ContractUnitType.Cents:
      const dollarCost = getEventCost({
        contract: null,
        cost,
        headCount,
        returnNumber: false,
        useUpgrade,
      });
      return `${dollarCost} from your contract`;
    case ContractUnitType.Event:
      return `1 event credit${useUpgrade ? ' and 1 upgrade credit' : ''}`;
    case ContractUnitType.Recurring:
      return `1 seat${
        useUpgrade
          ? ` + ${centsToDollarString(contract.premiumUnitCostCents, false)}`
          : ''
      } per person`;
    case ContractUnitType.Credits:
      const band = findPricingBand({ ctcPricingBands: cost.ctcPricingBands, headCount });
      const creditCostWhole = band.credits / 100;
      const phrase = pluralize(
        creditCostWhole,
        CONTRACT_UNIT_PHRASE_LOOKUP[contract.type],
      );
      return `${creditCostWhole} ${phrase} per person`;
  }
};

export const getEventCost = ({
  contract,
  cost,
  headCount,
  returnNumber,
  useUpgrade,
}: {
  cost: CostInfo;
  contract?: ContractInfo;
  headCount?: number;
  returnNumber?: boolean;
  useUpgrade?: boolean;
}): string | number => {
  const contractCost = getContractCost({
    contract,
    cost,
    headCount,
    useUpgrade,
  });
  if (contractCost) {
    return contractCost;
  }

  if (_.isNil(cost)) {
    return returnNumber ? NaN : "Can't get cost";
  }
  const { ctcPricingBands, ctcUnit, ctcCents } = cost;
  const band =
    headCount && ctcPricingBands
      ? findPricingBand({ ctcPricingBands, headCount })
      : _.first(ctcPricingBands);
  const cents = Math.trunc(_.get(band, 'cents', ctcCents));
  const unit = _.get(band, 'unit', ctcUnit);

  if (returnNumber) {
    const centsPerGuest = unit === CostUnit.User ? cents : cents / (headCount || 1);
    // return cost in dollars
    return centsPerGuest;
  } else {
    const prefix = centsToDollarString(cents);
    const suffix = unit === CostUnit.User ? ' per guest' : ' for the event';
    return `${prefix} ${suffix}`;
  }
};

export const getExperiencePrice = ({ experience, contractType }) => {
  const pricingBand = experience.templates[0]?.cost?.ctcPricingBands?.find(
    (band: PricingBand) => band.unit === CostUnit.User,
  );
  if (contractType === ContractUnitType.Credits) {
    return centsToDollars(pricingBand?.credits);
  } else if (contractType === ContractUnitType.Cents) {
    return centsToDollars(pricingBand?.cents);
  }
  return 0;
};

export const getTemplatePrice = ({
  template,
  contractType,
}: {
  template: {
    cost?: Pick<CostInfo, 'ctcPricingBands'> | null;
  } | null;
  contractType: ContractUnitType;
}) => {
  const pricingBand = template?.cost?.ctcPricingBands?.find(
    (band: PricingBand) => band.unit === CostUnit.User,
  );
  if (contractType === ContractUnitType.Credits) {
    return centsToDollars(pricingBand?.credits ?? 0);
  } else if (contractType === ContractUnitType.Cents) {
    return centsToDollars(pricingBand?.cents ?? 0);
  } else if (contractType === ContractUnitType.Recurring) {
    return centsToDollars(pricingBand?.credits ?? 0);
  }
  return 0;
};

export const buildEventBasedEstimateString = (contractCost: ContractCost): string => {
  const baseText = contractCost.unitCount ? '1 event credit' : '';
  const upgradeText = contractCost.premiumUnitCount ? '1 upgrade credit' : '';
  const suppliesText =
    contractCost.premiumUnitCount && contractCost.suppliesIncluded
      ? 'for supply kits'
      : '';
  const totalText = contractCost.total ? centsToDollarString(contractCost.total) : '';

  return _.compact([
    _.compact([baseText, _.compact([upgradeText, suppliesText]).join(' ')]).join(' and '),
    totalText,
  ]).join(' + ');
};

/**
 * Formats the pricing of a cost band based on the contract type
 * of the organization. For event-based bands (i.e. minimum price bands),
 * the full cost of the event is returned. For user-based bands, the
 * cost per user is returned. These are formatted with units and proper
 * pluralization where applicable.
 */
export function formatBandPrice({
  band,
  contractType,
  headCount = 1,
}: {
  band: PricingBand;
  contractType: ContractUnitType;
  /**
   * Optionally specify a head count to multiply the price by for a total.
   * This will not apply to Event-based bands.
   */
  headCount?: number;
}) {
  const { price, unit } = getBandPriceDetails({ band, contractType, headCount });
  return formatPriceByUnit(price, unit);
}

export function getBandPriceDetails({
  band,
  contractType,
  headCount = 1,
}: {
  band: PricingBand;
  contractType: ContractUnitType;
  /**
   * Optionally specify a head count to multiply the price by for a total.
   * This will not apply to Event-based bands.
   */
  headCount?: number;
}) {
  // attempt to get the price in the contract type if possible,
  // fall back to cents.
  let price: number;
  if (band.credits && contractType === ContractUnitType.Credits) {
    price = centsToDollars(band.credits);
    if (band.unit === CostUnit.Event) {
      price = price * band.maxUsers ?? band.minUsers;
    } else {
      price = price * headCount;
    }
  } else if (contractType === ContractUnitType.Recurring) {
    // SEATS - are always 1 per person
    if (band.unit === CostUnit.Event) {
      price = band.maxUsers ?? band.minUsers;
    } else {
      price = 1 * headCount;
    }
  } else if (contractType === ContractUnitType.Cents) {
    if (band.unit === CostUnit.Event) {
      price = band.cents;
    } else {
      price = band.cents * headCount;
    }
  } else if (contractType === ContractUnitType.Event) {
    price = band.cents;
  } else if (contractType === ContractUnitType.Unlimited) {
    price = 0;
  }

  return {
    price,
    // TODO: remove this as it's now redundant...
    unit: contractType,
  };
}

export function formatPriceByUnit(price: number, unit: ContractUnitType) {
  console.log('🚀 ~ unit:', unit);
  console.log('🚀 ~ price:', price);
  if (unit === ContractUnitType.Credits || unit === ContractUnitType.Recurring) {
    return `${price} ${pluralize(price, CONTRACT_UNIT_PHRASE_LOOKUP[unit])}`;
  }
  return centsToDollarString(price);
}

export type TemplateForPricingBreakdown = {
  cost?: Pick<CostInfo, 'ctcPricingBands'>;
  experiences: Pick<ExperienceInfo, 'hasPhysicalGoods' | 'requiresUpgrade'>[];
};

/**
 * Computes a cost breakdown of an experience for a given number of guests and
 * a given contract (if available).
 *
 * This is a very thorough cost breakdown, which includes taxes, applying existing contract
 * units onto costs and includes the $$ cost of purchasing additional units if required.
 *
 * https://www.notion.so/mystery/Pricing-Implementation-Guide-6cccbe2a42274f97a2a0464fe2ef904e
 */
export function formatOrderPricingBreakdown({
  contract,
  template,
  guestCount,
  isSurpriseToAll,
}: {
  contract: Pick<
    Contract,
    'type' | 'premiumUnitCostCents' | 'premiumUnitCount' | 'unitCount' | 'unitCostCents'
  >;
  template: TemplateForPricingBreakdown;
  guestCount: number;
  isSurpriseToAll?: boolean;
}) {
  const contractType = contract?.type || ContractUnitType.Cents;

  const bands = isSurpriseToAll
    ? DEFAULT_SURPRISE_TO_ALL_BANDS
    : template?.cost?.ctcPricingBands ?? DEFAULT_SURPRISE_TO_ALL_BANDS;
  const matchingBand =
    findPricingBand({
      ctcPricingBands: bands,
      headCount: guestCount,
    }) || bands[0]; // TODO: better default than bands[0] ?
  const eventBand = bands.find((band) => band.unit === CostUnit.Event);
  const firstUserPricedBand = bands.find((band) => band.unit === CostUnit.User);
  const doesNotExceedMinimum = matchingBand?.unit === CostUnit.Event;
  const howManyGuestsToReachMinimum = Math.max(
    0,
    doesNotExceedMinimum ? (eventBand.maxUsers ?? 0) - guestCount : 0,
  );
  const { hasPhysicalGoods, requiresUpgrade } =
    getHasPhysicalGoodsOrUpgradeFromTemplate(template);
  const isPremium =
    contract?.type === ContractUnitType.Recurring &&
    (hasPhysicalGoods || requiresUpgrade);
  const premiumUnitPrice = contract?.premiumUnitCostCents;

  const { price: subtotalRaw, unit } = getBandPriceDetails({
    band: matchingBand,
    contractType,
    headCount: guestCount,
  });

  // now for some fun. for seats based plans ONLY, we add the individual
  // price to the premium price for each guest. If the contract
  // has sufficient units and premium units to cover the cost,
  // we don't add additional $$ cost. If it falls short of either, we have
  // to add additional $$ to cover the remainder based on their
  // contracted prices.

  // first we need to compute the number of seats to charge -
  // if the matching band is Event, this is maxUsers, if not,
  // it's guest count
  const actualUnitsCharged =
    matchingBand.unit === CostUnit.Event ? matchingBand.maxUsers : guestCount;

  let contractUnitsExpended = 0;
  let unitOvercharge = 0;
  /** always in cents */
  let unitOverchargeSubtotal = 0;
  let contractPremiumUnitsExpended = 0;
  let premiumUnitOvercharge = 0;
  /** always in cents */
  let premiumUnitOverchargeSubtotal = 0;
  if (contractType === ContractUnitType.Recurring) {
    const unitCost = contract.unitCostCents ?? 0;
    unitOvercharge = Math.max(0, actualUnitsCharged - contract.unitCount);
    if (unitOvercharge > 0) {
      unitOverchargeSubtotal = unitCost * unitOvercharge;
    }
    contractUnitsExpended = Math.min(contract.unitCount, actualUnitsCharged);

    const premiumUnitCost = premiumUnitPrice ?? 0;
    premiumUnitOvercharge = Math.max(0, unitOvercharge - contract.premiumUnitCount);
    if (isPremium && premiumUnitOvercharge > 0) {
      premiumUnitOverchargeSubtotal = premiumUnitCost * premiumUnitOvercharge;
    }
    contractPremiumUnitsExpended = Math.min(
      contract.premiumUnitCount,
      actualUnitsCharged,
    );
  } else if (contractType === ContractUnitType.Credits) {
    const unitCost = contract.unitCostCents ?? 0;
    const creditCost = matchingBand?.credits / 100;
    const actualCreditsCharged = creditCost
      ? actualUnitsCharged * creditCost
      : actualUnitsCharged;
    const unitCountAsWhole = contract.unitCount / 100;
    unitOvercharge = Math.max(0, actualCreditsCharged - unitCountAsWhole);
    if (unitOvercharge > 0) {
      unitOverchargeSubtotal = unitCost * unitOvercharge;
    }
    contractUnitsExpended = Math.min(unitCountAsWhole, actualCreditsCharged);
  } else if (contractType === ContractUnitType.Cents) {
    const unitCost = contract?.unitCostCents ?? 0;
    unitOvercharge = Math.max(0, actualUnitsCharged - contract?.unitCount ?? 0);
    if (unitOvercharge > 0) {
      unitOverchargeSubtotal = unitCost * unitOvercharge;
    }
    contractUnitsExpended = contract
      ? Math.min(contract?.unitCount, actualUnitsCharged)
      : actualUnitsCharged * unitCost;
  }

  // assumption: tax doesn't apply to non-$ prices
  let totalRaw = subtotalRaw;

  let totalAdditionalDollarChargeRaw = 0;
  // now, if the base unit of this transaction is already $$,
  // add any overcharge subtotals to the total. This... should actually
  // never happen, but just in case.
  if (unit === ContractUnitType.Cents) {
    totalRaw += unitOverchargeSubtotal + premiumUnitOverchargeSubtotal;
  } else {
    // otherwise we have to store a second total for $$ charges
    // besides the base price.
    totalAdditionalDollarChargeRaw =
      unitOverchargeSubtotal + premiumUnitOverchargeSubtotal;
  }
  // store this before applying tax...
  const subtotalAdditionalDollarChargeRaw = totalAdditionalDollarChargeRaw;

  // finally, both totals have to be
  // multiplied by tax, if applicable
  let totalTax = 0;
  if (!contract && unit === ContractUnitType.Cents) {
    // the assumption here is we don't charge tax on contract prices
    totalTax = TAX_RATE * totalRaw;
    totalRaw += totalTax;
  }
  // the assumption here is we always charge tax on overage fees,
  // even for contract customers
  const totalAdditionalDollarChargeTax = TAX_RATE * totalAdditionalDollarChargeRaw;
  totalAdditionalDollarChargeRaw += totalAdditionalDollarChargeTax;

  return {
    pricePerGuest: formatBandPrice({
      band: matchingBand,
      contractType,
    }),
    minimumPrice: eventBand
      ? formatBandPrice({
          band: eventBand,
          contractType,
        })
      : '0',
    doesNotExceedMinimum,
    howManyGuestsToReachMinimum,
    pricePerGuestAfterMinimum: firstUserPricedBand
      ? formatBandPrice({
          band: firstUserPricedBand,
          contractType,
        })
      : 0,
    isPremium,
    premiumUnitPrice: premiumUnitPrice
      ? `${centsToDollarString(premiumUnitPrice)} / upgrade ${
          CONTRACT_UNIT_PHRASE_LOOKUP[unit]
        }`
      : undefined,
    premiumUnitOvercharge: premiumUnitOvercharge
      ? formatPriceByUnit(premiumUnitOvercharge, unit)
      : undefined,
    premiumUnitOverchargeSubtotal: premiumUnitOverchargeSubtotal
      ? centsToDollarString(premiumUnitOverchargeSubtotal)
      : undefined,
    premiumUnitExpense: premiumUnitOvercharge
      ? formatPriceByUnit(contractPremiumUnitsExpended, unit)
      : undefined,
    unitExpense: contractUnitsExpended
      ? formatPriceByUnit(contractUnitsExpended ?? 0, unit)
      : undefined,
    unitOverchargeSubtotal: unitOvercharge
      ? centsToDollarString(unitOverchargeSubtotal)
      : undefined,
    unitPrice: contract
      ? `${centsToDollarString(contract.unitCostCents)} / ${
          CONTRACT_UNIT_PHRASE_LOOKUP[unit]
        }`
      : undefined,
    unitOvercharge: unitOvercharge ? formatPriceByUnit(unitOvercharge, unit) : undefined,
    hasPhysicalGoods,
    requiresUpgrade,
    subtotal: formatPriceByUnit(subtotalRaw, unit),
    totalTax: totalTax ? formatPriceByUnit(totalTax, unit) : undefined,
    total: formatPriceByUnit(totalRaw, unit),
    subtotalAdditionalDollarCharge: subtotalAdditionalDollarChargeRaw
      ? formatPriceByUnit(subtotalAdditionalDollarChargeRaw, ContractUnitType.Cents)
      : undefined,
    totalAdditionalDollarCharge: totalAdditionalDollarChargeRaw
      ? formatPriceByUnit(totalAdditionalDollarChargeRaw, ContractUnitType.Cents)
      : undefined,
    totalAdditionalDollarChargeTax: totalAdditionalDollarChargeTax
      ? formatPriceByUnit(totalAdditionalDollarChargeTax, ContractUnitType.Cents)
      : undefined,
    unit: CONTRACT_UNIT_PHRASE_LOOKUP[unit],
    guestCount: `${guestCount} ${pluralize(guestCount, 'person', 'people')}`,
  };
}
