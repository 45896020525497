// @ts-strict-ignore
import DateFnsUtils from '@date-io/date-fns';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EventIcon from '@material-ui/icons/Event';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import _ from 'lodash';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import theme from 'theme';
import { inRequestToBookThreshold } from 'utils/dateUtil';
import { addOpenExperiencesToEvents } from '../EventSelection/helpers';
import EventTimeTile, { EventTimeTileProps } from './EventTimeTile';
import {
  EventArrayByDateString,
  EventForSelection,
  TemplateWithExperienceInfo,
} from 'types/selfServeFlow';
import { getHasPhysicalGoodsOrUpgradeFromTemplate } from './helpers';

const MIN_SELECTABLE_DATE = DateTime.local().plus({ days: 2 }).toJSDate();

const useStyles = makeStyles({
  datePicker: {
    width: '100%',
    borderRadius: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
  },
  sectionHeader: {
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
  },
});

interface DatePickerProps {
  validDates: Date[];
  date: ParsableDate;
  onDateSelection(d: Date): void;
}
const PureDatePicker = React.memo(
  ({ validDates, date, onDateSelection }: DatePickerProps) => {
    const classes = useStyles();
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          disableToolbar
          inputVariant='outlined'
          variant='inline'
          format='EEEE, LLLL do, yyyy'
          id='date-picker-inline'
          disablePast={true}
          minDate={MIN_SELECTABLE_DATE}
          shouldDisableDate={(date) => {
            const isValid = validDates.find(
              (d) => d.toISOString() === date.toISOString(),
            );
            return !isValid;
          }}
          autoOk={true}
          value={date}
          InputProps={{
            className: classes.datePicker,
            endAdornment: <EventIcon color='primary' />,
          }}
          onChange={onDateSelection}
          style={{ marginBottom: theme.spacing(4), width: '100%' }}
        />
      </MuiPickersUtilsProvider>
    );
  },
);

export const PureEventTile = React.memo(
  ({
    event,
    isSelected,
    onClickEventTime,
    isRequestToBook,
    fullDate,
  }: EventTimeTileProps) => {
    return (
      <EventTimeTile
        event={event}
        isSelected={isSelected}
        onClickEventTime={onClickEventTime}
        isRequestToBook={isRequestToBook}
        fullDate={fullDate}
      />
    );
  },
);

const SelectAndShowDates = ({
  groupedEvents,
  currentEventSelection,
  onClickEventTime,
  surpriseToAllView,
  selectedTemplate,
}: {
  groupedEvents: EventArrayByDateString;
  currentEventSelection: any;
  onClickEventTime(event: EventForSelection, isRequestToBook: boolean): void;
  surpriseToAllView: boolean;
  selectedTemplate: TemplateWithExperienceInfo;
}) => {
  const classes = useStyles();
  const sortedDates = Object.keys(groupedEvents).sort((dateStringA, dateStringB) =>
    new Date(dateStringA) < new Date(dateStringB) ? -1 : 1,
  );

  const [dateToShow, setDateToShow] = useState(_.first(sortedDates));
  const [eventsWithOpenExperiences, setEventsWithOpenExperiences] = useState<
    EventForSelection[]
  >([]);
  const { hasPhysicalGoods } = getHasPhysicalGoodsOrUpgradeFromTemplate(selectedTemplate);

  useEffect(() => {
    if (dateToShow) return;

    setDateToShow(_.first(sortedDates));
  }, [sortedDates, dateToShow]);

  useEffect(() => {
    if (dateToShow) {
      const events = groupedEvents[dateToShow];
      if (!surpriseToAllView) {
        const withExperiences = addOpenExperiencesToEvents({ events, selectedTemplate });
        setEventsWithOpenExperiences(withExperiences);
      } else {
        setEventsWithOpenExperiences(events);
      }
    }
  }, [dateToShow, surpriseToAllView]);

  const onDateSelection = React.useCallback(
    (date: Date) => {
      setDateToShow(
        sortedDates.find((d) => new Date(d).toISOString() === date.toISOString()),
      );
    },
    [sortedDates],
  );

  const validDates = sortedDates.map((date) => new Date(date));

  const dateTimeIsRequestToBook = surpriseToAllView
    ? false
    : inRequestToBookThreshold(
        new Date(dateToShow),
        hasPhysicalGoods,
        selectedTemplate?.experiences,
      );

  if (!dateToShow) return null;

  return (
    <>
      <Box mb={2}>
        <Typography variant='body2' color='primary' className={classes.sectionHeader}>
          Date
        </Typography>
      </Box>
      <PureDatePicker
        date={dateToShow}
        onDateSelection={onDateSelection}
        validDates={validDates}
      />
      <Box mb={2}>
        <Typography variant='body2' color='primary' className={classes.sectionHeader}>
          Time
        </Typography>
      </Box>
      <Box key={`event-date-${dateToShow[0]}`}>
        <Box display='flex' flexDirection='column' mb={5}>
          {_.map(eventsWithOpenExperiences, (event, i) => {
            const eventId = event.id;
            const isSelected = currentEventSelection?.event?.id === eventId;
            const isRequestToBook =
              dateTimeIsRequestToBook ||
              (!surpriseToAllView && _.isEmpty(event.openExperiences));

            return (
              <PureEventTile
                key={`option-${i}`}
                event={event}
                isSelected={isSelected}
                onClickEventTime={() => onClickEventTime(event, isRequestToBook)}
                isRequestToBook={isRequestToBook}
              />
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default SelectAndShowDates;
