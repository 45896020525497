import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import React, { useEffect } from 'react';
import TrinaryQuestionAnswer from 'scenes/ProfileQuestions/TrinaryQuestionAnswer';
import theme from 'theme';

const DEFAULT_ANSWER_VALUE = 1;

const useStyles = makeStyles({
  container: {
    marginTop: theme.spacing(6),
  },
});

const ActivityQuestions = ({
  questions,
  currentPreferenceAnswers,
  setPreferenceAnswers,
}) => {
  const classes = useStyles();

  useEffect(() => {
    const defaultAllAnswers = questions.reduce((acc, cur) => {
      const sureAnswer = _.find(
        cur.preferenceAnswers,
        (a) => a.value === DEFAULT_ANSWER_VALUE,
      );
      acc[cur.id] = sureAnswer && [sureAnswer.id];
      return acc;
    }, {});
    setPreferenceAnswers({ ...currentPreferenceAnswers, ...defaultAllAnswers });
  }, []);

  const onAnswer = ({ questionId, answerId }) => {
    setPreferenceAnswers({ ...currentPreferenceAnswers, [questionId]: [answerId] });
  };

  return (
    <>
      {questions.map((q) => {
        return (
          <Box key={q.id} className={classes.container}>
            <TrinaryQuestionAnswer
              currentPreferenceAnswers={currentPreferenceAnswers}
              onAnswer={onAnswer}
              question={q}
            />
          </Box>
        );
      })}
    </>
  );
};

export default ActivityQuestions;