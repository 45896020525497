// @ts-strict-ignore
import {
  Box,
  FormControl,
  FormControlLabel,
  Typography,
  FormGroup,
  Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import React, { useState } from 'react';
import theme, { selectedOptionBackground } from 'theme';
import { PersonPickerAnswer } from 'types';
import { Colors } from 'constants/index';
import { OrgSearch } from 'components/OrgSearch';
import { makeBorderStyle } from 'utils/borders';
import { AnswerProps } from '.';

const useStyles = makeStyles({
  answerContainer: {
    width: '100%',
  },
  button: {
    textTransform: 'none',
    width: '100%',
  },
  activeButton: {
    textTransform: 'none',
    width: '100%',
    color: Colors.Brand.Purple,
    background: Colors.Brand.PurpleLight,
  },
  select: {
    width: '90%',
    borderRadius: '100px',
    alignSelf: 'center',
  },
});

const PeopleChoice = ({ question, answer, setAnswer }: AnswerProps) => {
  const classes = useStyles();
  const [searchString, setSearchString] = useState('');
  const [selectedOptions, setSelectedOptions] = useState(
    new Set(JSON.parse(answer ?? '[]')),
  );
  const options = question.options.filter((option) => {
    const userName = JSON.parse(option)?.name;
    return userName?.toLowerCase()?.includes(searchString?.toLowerCase() || '');
  });

  const setSelected = (option: string) => {
    const copySelectedOptions = selectedOptions;
    if (copySelectedOptions.has(option)) {
      copySelectedOptions.delete(option);
    } else {
      copySelectedOptions.add(option);
    }
    setSelectedOptions(copySelectedOptions);
    if (copySelectedOptions.size === 0) {
      setAnswer(null);
    } else {
      setAnswer(JSON.stringify(Array.from(copySelectedOptions)));
    }
  };

  return (
    <Box>
      <FormControl fullWidth component='fieldset'>
        <h3>Search by name</h3>
        <Box pb={7}>
          <OrgSearch
            searchTags={[]}
            setSearchTags={(_) => _}
            setSearchString={setSearchString}
            searchString={searchString}
            neverOpen={true}
          />
        </Box>
        {options.length === 0 && (
          <Box
            py={5}
            display={'flex'}
            alignItems={'center'}
            flexDirection='column'
            justifyContent='center'
          >
            <text css={{ color: theme.palette.primary.contrastText }}>
              Hmmm are you sure?
            </text>
            <h3>We couldn't find anyone with that name</h3>
          </Box>
        )}
        <FormControl
          css={{
            maxHeight: 305,
            overflowY: 'visible',
            overflowX: 'hidden',
            paddingLeft: '14px',
          }}
          fullWidth
          component='fieldset'
        >
          <FormGroup>
            {_.map(options, (o, i) => {
              const option = JSON.parse(o) as PersonPickerAnswer;
              const isSelected = selectedOptions.has(option?.id);
              return (
                <FormControlLabel
                  className={classes.answerContainer}
                  style={{
                    ...makeBorderStyle({ index: i, options }),
                  }}
                  control={
                    <Checkbox
                      checked={isSelected}
                      key={i}
                      value={option.id}
                      css={{
                        backgroundColor: isSelected ? selectedOptionBackground : 'white',
                      }}
                      onChange={(e) => {
                        const t = e.target as any; // Target here doesn't inherit from Element for some reason?
                        setSelected(t.value);
                      }}
                    />
                  }
                  label={<Typography variant='body1'>{option.name}</Typography>}
                />
              );
            })}
          </FormGroup>
        </FormControl>
      </FormControl>
      <Typography
        css={{
          marginTop: '20px',
          color: theme.palette.primary['main'],
          textAlign: 'center',
        }}
      >
        Or continue if you didn't connect with anyone
      </Typography>
    </Box>
  );
};

export default PeopleChoice;
