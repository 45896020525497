const CheckCircle = ({
  height = 24,
  width = 24,
  style = {},
  color = '#A1330F',
  className = '',
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      style={style}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.7071 8.70711C15.0976 8.31658 15.0976 7.68342 14.7071 7.29289C14.3166 6.90237 13.6834 6.90237 13.2929 7.29289L14.7071 8.70711ZM7.29289 13.2929C6.90237 13.6834 6.90237 14.3166 7.29289 14.7071C7.68342 15.0976 8.31658 15.0976 8.70711 14.7071L7.29289 13.2929ZM8.70711 7.29289C8.31658 6.90237 7.68342 6.90237 7.29289 7.29289C6.90237 7.68342 6.90237 8.31658 7.29289 8.70711L8.70711 7.29289ZM13.2929 14.7071C13.6834 15.0976 14.3166 15.0976 14.7071 14.7071C15.0976 14.3166 15.0976 13.6834 14.7071 13.2929L13.2929 14.7071ZM20 11C20 15.9706 15.9706 20 11 20V22C17.0751 22 22 17.0751 22 11H20ZM11 20C6.02944 20 2 15.9706 2 11H0C0 17.0751 4.92487 22 11 22V20ZM2 11C2 6.02944 6.02944 2 11 2V0C4.92487 0 0 4.92487 0 11H2ZM11 2C15.9706 2 20 6.02944 20 11H22C22 4.92487 17.0751 0 11 0V2ZM13.2929 7.29289L7.29289 13.2929L8.70711 14.7071L14.7071 8.70711L13.2929 7.29289ZM7.29289 8.70711L13.2929 14.7071L14.7071 13.2929L8.70711 7.29289L7.29289 8.70711Z'
        fill={color}
      />
    </svg>
  );
};

export default CheckCircle;
