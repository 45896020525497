import { cx } from '@emotion/css';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Main, Purple500 } from '@mysteryco/design/src/tokens/colors';
import FaceFrown from 'components/icons/FaceFrown';
import FaceHappy from 'components/icons/FaceHappy';
import FaceNeutral from 'components/icons/FaceNeutral';
import UpsellWrapper from './UpsellWrapper';

const SentimentRow = ({
  low,
  neutral,
  strong,
  showUpsell = false,
}: {
  low: number;
  neutral: number;
  strong: number;
  showUpsell?: boolean;
}) => {
  const classes = useStyles();
  const containerClass = cx([classes.container, { [classes.upsell]: showUpsell }]);

  if (showUpsell) {
    low = 0;
    neutral = 0;
    strong = 0;
  }

  const max = Math.max(low, neutral, strong);
  const highlightedClass = (value) =>
    value === max && value !== 0 && !showUpsell ? classes.highlighted : '';

  const SentimentCell = ({ title, value, icon }) => (
    <Box className={highlightedClass(value)}>
      <Typography className={classes.sentimentTitle}>{title}</Typography>
      <Box className={classes.sentimentData}>
        {icon}
        <UpsellWrapper showUpsell={showUpsell} height={28} width={50}>
          <Typography className={classes.sentimentValue}>{value}%</Typography>
        </UpsellWrapper>
      </Box>
    </Box>
  );

  return (
    <Box className={containerClass}>
      <SentimentCell title='Low' value={low} icon={<FaceFrown />} />
      <SentimentCell title='Neutral' value={neutral} icon={<FaceNeutral />} />
      <SentimentCell title='Strong' value={strong} icon={<FaceHappy />} />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(8),
    color: theme.palette.grey[700],
  },
  sentimentData: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    '& path': {
      fill: theme.palette.grey[700],
    },
  },
  sentimentValue: {
    fontSize: '1.25rem',
  },
  sentimentTitle: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  highlighted: {
    color: Main,
    '& $sentimentTitle': {
      fontWeight: theme.typography.fontWeightBold,
    },
    '& $sentimentValue': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& $sentimentData path': {
      fill: Main,
    },
  },
  upsell: {
    '& *': {
      color: Purple500,
    },
    '& $sentimentData path': {
      fill: Purple500,
    },
  },
}));

export default SentimentRow;
