import React, { CSSProperties } from 'react';
import theme from 'theme';

type Props = {
  size?: number;
  style?: CSSProperties;
  className?: string;
  color?: string;
};

export default function ChevronRight({
  size = 24,
  style,
  className,
  color = theme.palette.primary.main,
}: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      style={style}
    >
      <path
        d='M5.29289 11.2929C4.90237 11.6834 4.90237 12.3166 5.29289 12.7071C5.68342 13.0976 6.31658 13.0976 6.70711 12.7071L5.29289 11.2929ZM10 8L10.7071 8.70711C11.0976 8.31658 11.0976 7.68342 10.7071 7.29289L10 8ZM6.70711 3.29289C6.31658 2.90237 5.68342 2.90237 5.29289 3.29289C4.90237 3.68342 4.90237 4.31658 5.29289 4.70711L6.70711 3.29289ZM6.70711 12.7071L10.7071 8.70711L9.29289 7.29289L5.29289 11.2929L6.70711 12.7071ZM10.7071 7.29289L6.70711 3.29289L5.29289 4.70711L9.29289 8.70711L10.7071 7.29289Z'
        fill={color}
      />
    </svg>
  );
}
