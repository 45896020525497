import _ from 'lodash';

import { CustomerType, OrganizationRoleName, Role, ViewerFragmentFragment } from 'types';

export const hasOrg = (
  viewer: { orgs?: any[]; defaultOrganization?: any } | null | undefined,
): boolean => viewer?.defaultOrganization || !_.isEmpty(_.get(viewer, 'orgs'));

export const isAdminOrCoordinator = (
  viewer:
    | (Pick<ViewerFragmentFragment, 'organizationRole'> & {
        orgs: any[];
      })
    | null
    | undefined,
): boolean =>
  hasOrg(viewer) &&
  [OrganizationRoleName.Admin, OrganizationRoleName.Coordinator].includes(
    _.get(viewer, 'organizationRole.name'),
  );

export const isAdmin = (
  viewer:
    | (Pick<ViewerFragmentFragment, 'organizationRole'> & {
        orgs?: any[];
        defaultOrganization?: any;
      })
    | null
    | undefined,
): boolean =>
  hasOrg(viewer) && OrganizationRoleName.Admin === viewer?.organizationRole?.name;

export const isWizard = (
  viewer: Pick<ViewerFragmentFragment, 'role'> | null | undefined,
): boolean => Role.Wizard === viewer?.role;

export const isInsightsCustomer = (
  viewer: { customerType: CustomerType } | null | undefined,
): boolean => viewer?.customerType === CustomerType.EventsAndInsights;
