// @ts-strict-ignore
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@mysteryco/design';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import DoubleChevronLeft from 'components/icons/DoubleChevronLeft';
import DoubleChevronRight from 'components/icons/DoubleChevronRight';
import theme from 'theme';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    borderTop: `1px solid ${colors.Purple100}`,
    fontFamily: '"Roboto Mono", monospace',
    fontWeight: 700,
    fontSize: '0.75rem',
    color: colors.Purple700,
  },
  pickerContainer: {
    flex: '1',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    minWidth: 600,
  },
  pickerCanvas: {
    position: 'relative',
    transition: `left ease-in-out 300ms`,
  },
  picker: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  link: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    height: theme.spacing(10),
    padding: `0 ${theme.spacing(3)}`,
    transition: `none`,
    borderLeft: `1px solid ${colors.Purple100}`,
    '&:hover:not([disabled]):not([data-disabled=true]):not([data-active=true])': {
      backgroundColor: colors.Purple100,
    },
    '&[data-active=true]': {
      backgroundColor: colors.Main,
      color: colors.White,
      '&[data-loading=true]': {},
    },
    '&[disabled], &[data-disabled=true]': {
      color: 'rgba(0, 0, 0, 0.25)',
      cursor: 'not-allowed',
      pointerEvents: 'none',
    },
  },
  month: {
    flex: 1,
  },
  year: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.Purple50,
    borderLeft: `1px solid ${colors.Purple100}`,
    '& span': {
      display: 'block',
      transform: 'rotate(-90deg)',
      transformOrigin: '50% 50%',
    },
  },
  selectedDot: {
    display: 'inline-block',
    width: theme.spacing(2),
    height: theme.spacing(2),
    borderRadius: '50%',
    marginLeft: 4,
    marginRight: 8,
    backgroundColor: colors.Green500,
  },
});

const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
] as const;

interface Props {
  loading: boolean;
  value: DateTime;
  earliestKnownScoreTime: DateTime;
  urlForValue: (date: DateTime) => string;
}

const ScoreDetailsInsightsTimeline = (props: Props) => {
  const { value } = props;
  const classes = useStyles();
  const [year, setYear] = useState(value.year);
  const [targetYear, setTargetYear] = useState(year);
  const [transitioning, setTransitioning] = useState(false);
  const pickerCanvasRef = useRef<HTMLDivElement>();
  const today = DateTime.now();

  return (
    <div className={classes.root}>
      <div
        className={classes.link}
        css={{ borderLeftWidth: 0 }}
        onClick={() => {
          if (transitioning) return;
          setTransitioning(true);
          setTargetYear(year - 1);
        }}
      >
        <DoubleChevronLeft />
      </div>
      <div className={classes.pickerContainer}>
        <div
          ref={pickerCanvasRef}
          className={classes.pickerCanvas}
          style={{ left: `${(today.year - targetYear) * 100}%` }}
          onTransitionEnd={(event) => {
            if (event.target !== pickerCanvasRef.current) return;
            if (!transitioning) return;
            setTransitioning(false);
            setYear(targetYear);
          }}
        >
          <MonthsForYear {...props} year={year - 1} />
          <MonthsForYear {...props} year={year} />
          <MonthsForYear {...props} year={year + 1} />
        </div>
      </div>
      <div
        className={classes.link}
        data-disabled={DateTime.fromObject({ year, month: 12, day: 1 }) > today}
        onClick={() => {
          if (transitioning) return;
          setTransitioning(true);
          setTargetYear(year + 1);
        }}
      >
        <DoubleChevronRight />
      </div>
    </div>
  );
};

const MonthsForYear = ({
  year,
  loading,
  value,
  earliestKnownScoreTime,
  urlForValue,
}: Props & { year: number }) => {
  const classes = useStyles();
  const today = DateTime.now();
  const yearOffset = year - today.year;

  return (
    <div className={classes.picker} style={{ left: `${yearOffset * 100}%` }}>
      <div className={classes.year}>
        <span>{year}</span>
      </div>
      {MONTHS.map((month, index) => {
        let date = DateTime.fromObject({ year, month: index + 1, day: 1 }).endOf('month');
        if (today.year === date.year && today.month === date.month) {
          date = date.set({ day: today.day });
        }

        const isSelectedMonth = date.year === value.year && date.month === value.month;
        const disabled =
          date.startOf('month') > today ||
          (earliestKnownScoreTime && date < earliestKnownScoreTime.startOf('month'));
        return (
          <Link
            key={index}
            to={disabled ? '#' : urlForValue(date)}
            className={`${classes.link} ${classes.month}`}
            data-active={isSelectedMonth}
            data-loading={loading}
            data-disabled={disabled}
          >
            {isSelectedMonth &&
              (loading ? (
                <CircularProgress size={16} css={{ color: 'white', marginRight: 4 }} />
              ) : (
                <div className={classes.selectedDot} />
              ))}
            {month}
          </Link>
        );
      })}
    </div>
  );
};

export default ScoreDetailsInsightsTimeline;
