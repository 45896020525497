import { gql } from '@apollo/client';
import { Loading } from 'components/core';
import { shuffleArray } from 'lib/helpers/arrays';
import { existsFilter } from 'lib/helpers/maybe';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  MoralePulseSurveyStatus,
  ViewerFragmentFragment,
  useMoralePulseSurveyQuery,
  useRespondToMoralePulseSurveyMutation,
} from 'types';
import { EdgeCaseScreen } from './EdgeScreens';
import { PulseSurvey, PulseSurveyAnswer } from './PulseSurvey';

export interface MoralePulseSurveyProps {
  viewer?: ViewerFragmentFragment;
}

const MoralePulseSurvey = ({ viewer }: MoralePulseSurveyProps) => {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useMoralePulseSurveyQuery({
    // ! assertion justified by skip below
    variables: { id, userId: viewer!.id },
    skip: !viewer,
  });

  const [respondToMoralePulseSurvey] = useRespondToMoralePulseSurveyMutation();

  const moralePulseSurvey = data?.moralePulseSurvey;
  const unsortedQuestions = moralePulseSurvey?.questions ?? [];
  const questions = useMemo(
    () => shuffleArray(unsortedQuestions.filter(existsFilter)),
    [unsortedQuestions],
  );

  if (loading) return <Loading />;

  const logoUrl = viewer?.orgs?.[0]?.logoUrl;
  const orgName = viewer?.orgs?.[0]?.name;
  const name = viewer?.name;
  if (!moralePulseSurvey?.inAudience) {
    return (
      <EdgeCaseScreen
        primary={`Hi ${viewer?.firstName} looks like you have no surveys available at this time.`}
        logoUrl={logoUrl}
      />
    );
  }
  if (moralePulseSurvey?.hasCompleted) {
    return (
      <EdgeCaseScreen
        primary={`Hi ${viewer?.firstName} 👋 you have already answered this survey. Great job!`}
        logoUrl={logoUrl}
      />
    );
  }
  if (moralePulseSurvey?.status !== MoralePulseSurveyStatus.Active) {
    return (
      <EdgeCaseScreen
        primary={`Hi ${viewer?.firstName} 👋 looks like you have an old survey link. This survey has closed.`}
        logoUrl={logoUrl}
      />
    );
  }

  const submitSurvey = async (answers: PulseSurveyAnswer[]) => {
    await respondToMoralePulseSurvey({
      variables: {
        surveyId: id,
        responses: answers,
      },
    });
  };

  return (
    <PulseSurvey
      logoUrl={logoUrl}
      name={name || ''}
      orgName={orgName || ''}
      questions={questions}
      onSubmit={submitSurvey}
    />
  );
};

MoralePulseSurvey.query = gql`
  query MoralePulseSurvey($id: ID!, $userId: ID!) {
    moralePulseSurvey(id: $id) {
      id
      questions {
        id
        copy
        options
        category
        type
      }
      hasCompleted(userId: $userId)
      inAudience(userId: $userId)
      status
    }
  }
`;

MoralePulseSurvey.mutation = gql`
  mutation RespondToMoralePulseSurvey(
    $surveyId: ID!
    $responses: [MoralePulseQuestionReponse]!
  ) {
    saveMoralePulseSurveyResponses(surveyId: $surveyId, responses: $responses)
  }
`;

export default MoralePulseSurvey;
