import { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Card, Box, Typography, IconButton } from '@material-ui/core';
import { ExpandMore, CallMade } from '@material-ui/icons';
import { Skeleton } from 'components/Skeleton';
import theme from 'theme';
import { colors } from '@mysteryco/design';

export interface MysteryCardProps {
  loading?: boolean;
  title?: string;
  children?: React.ReactNode;
  footer?: string;
  onClickFooter?(): void;
  allowsHover?: boolean;
  headerButton?: boolean;
}

const MysteryCard = ({
  loading = false,
  title,
  children,
  footer,
  onClickFooter,
  allowsHover = false,
  headerButton = false,
}: MysteryCardProps) => {
  const classes = useStyles();
  const [hover, setHover] = useState(false);
  return (
    <Card
      className={classes.card}
      css={hover ? { borderColor: colors.Purple200 } : { borderColor: colors.Purple100 }}
      onPointerEnter={() => allowsHover && setHover(true)}
      onPointerLeave={() => allowsHover && setHover(false)}
    >
      <Box
        className={classes.header}
        css={hover ? { background: colors.Purple100 } : { background: colors.Purple50 }}
      >
        {loading ? (
          <Skeleton css={{ flex: 1 }} />
        ) : (
          <>
            <Typography className={classes.headerTitle}>{title}</Typography>
            {headerButton && (
              <IconButton className={classes.headerIcon}>
                {hover ? <CallMade /> : <ExpandMore />}
              </IconButton>
            )}
          </>
        )}
      </Box>
      <Box className={classes.content}>
        {children}
        {!loading && hover && footer && (
          <Typography className={classes.footer} onClick={onClickFooter}>
            {footer}
          </Typography>
        )}
      </Box>
    </Card>
  );
};

const useStyles = makeStyles({
  card: {
    borderWidth: '1px',
    borderStyle: 'solid',
    boxShadow: 'none',
    height: '100%',
  },
  header: {
    padding: '16px 16px 12px 16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    padding: '16px 16px 20px 16px',
    borderWidth: '1px',
    borderStyle: 'solid hidden hidden hidden',
    borderColor: colors.Purple100,
  },
  headerTitle: {
    fontWeight: 800,
    color: colors.Dusk,
  },
  headerIcon: {
    padding: 0,
    color: theme.palette.primary.main,
    background: theme.palette.common.white,
    borderRadius: '8px',
    boxShadow:
      '0px 4px 6px -1px rgba(155, 160, 166, 0.1), 0px 2px 4px -1px rgba(155, 160, 166, 0.06)',
    '&:hover': {
      cursor: 'default',
    },
  },
  footer: {
    marginTop: '16px',
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

export default MysteryCard;
