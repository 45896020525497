import { css } from '@emotion/react';
import Loading02 from '@mysteryco/design/icons/Loading02';
import { colors } from '@mysteryco/design';
import PrivacyShield from 'glue/scenes/Explore/Pulse/PrivacyShield';

const noResultsStrings = {
  notYet: 'No results yet',
  atLeast5: 'We need at least 5 employee submissions to share results.',
  letYouKnow: "We'll let you know when this happens.",
};

const notEnoughAnswersStrings = {
  notEnough: 'Not enough answers yet',
  atLeast2: 'At least two employees must have responded to share results.',
};

const mismatchedAudienceStrings = {
  badSelection: 'Selected users were not surveyed',
  notSurvyed:
    'No one in your selected teams or groups was given a survey during this time',
};

export const NoResults = () => {
  return (
    <div css={styles.emptyState}>
      <div
        css={{
          width: '48px',
          height: '48px',
          background: colors.Glue_Mint00,
          borderRadius: '48px',
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <Loading02 size={22} color={colors.Glue_Ink00} />
      </div>
      <p css={styles.emptyStateTitle}>{noResultsStrings.notYet}</p>
      <p css={styles.emptyStateText}>{noResultsStrings.atLeast5}</p>
      <p css={styles.emptyStateText}>{noResultsStrings.letYouKnow}</p>
    </div>
  );
};

export const SmallTeam = PrivacyShield;

export const FewAnswers = () => {
  return (
    <div css={styles.emptyState}>
      <div
        css={{
          width: '48px',
          height: '48px',
          background: colors.Glue_Mint00,
          borderRadius: '48px',
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <Loading02 size={22} color={colors.Glue_Ink00} />
      </div>
      <p css={styles.emptyStateTitle}>{notEnoughAnswersStrings.notEnough}</p>
      <p css={styles.emptyStateText}>{notEnoughAnswersStrings.atLeast2}</p>
    </div>
  );
};

export const MismatchedAudience = () => {
  return (
    <div css={styles.emptyState}>
      <div
        css={{
          width: '48px',
          height: '48px',
          background: colors.Glue_Mint00,
          borderRadius: '48px',
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <Loading02 size={22} color={colors.Glue_Ink00} />
      </div>
      <p css={styles.emptyStateTitle}>{mismatchedAudienceStrings.badSelection}</p>
      <p css={styles.emptyStateText}>{mismatchedAudienceStrings.notSurvyed}</p>
    </div>
  );
};

const styles = {
  emptyState: css({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '90px',
    minHeight: '40vh',
  }),
  emptyStateTitle: css({
    fontFamily: 'inherit',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '28px',
    marginTop: '12px',
  }),
  emptyStateText: css({
    fontFamily: 'inherit',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '28px',
    margin: 0,
  }),
};
