// @ts-strict-ignore
import { cx } from '@emotion/css';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Dusk, Main, Purple500, Purple800 } from '@mysteryco/design/src/tokens/colors';
import UpsellWrapper from 'components/UpsellWrapper';
import theme from 'theme';

const EventMetricData = ({
  value,
  text,
  showUpsell = false,
  type = 'lg',
}: {
  value: string | number;
  text: string;
  showUpsell?: boolean;
  type?: 'sm' | 'lg';
}) => {
  const classes = useStyles();
  const containerClass = cx([
    classes.metric,
    { [classes.upsell]: showUpsell },
    { [classes.large]: type === 'lg', [classes.small]: type === 'sm' },
  ]);

  if (showUpsell) {
    value = '100';
  }

  const upsellSize = (lgValue?, smValue?) => (type === 'lg' ? lgValue : smValue);

  return (
    <Typography className={containerClass}>
      <UpsellWrapper
        showUpsell={showUpsell}
        width={upsellSize(50)}
        height={upsellSize(28)}
        margin={`0 ${theme.spacing(2)} 0 0`}
      >
        <Typography className={classes.value}>{value} </Typography>
      </UpsellWrapper>
      <Typography className={classes.text}>{text}</Typography>
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  small: {
    '& $value': {
      fontSize: '0.875rem',
      color: Main,
      fontWeight: theme.typography.fontWeightBold,
    },
    '&$metric': {
      gap: theme.spacing(1),
    },
  },
  large: {
    '& $value': {
      fontSize: '1.5rem',
      color: Dusk,
    },
    '&$metric': {
      gap: theme.spacing(2),
    },
  },
  value: {
    display: 'inline',
  },
  text: {
    color: Purple800,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '.875rem',
  },
  metric: {
    display: 'flex',
    alignItems: 'baseline',
  },
  upsell: {
    '& *': {
      color: Purple500,
    },
    '&$metric': {
      alignItems: 'end',
    },
  },
}));

export default EventMetricData;
