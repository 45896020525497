import { makeStyles } from '@material-ui/core/styles';
import theme from 'theme';
import Check from './icons/Check';
import { EncircleIcon } from './icons/EncircleIcon';

interface Props {
  percentFilled: number;
  size?: number;
}

const useStyles = makeStyles({
  pieCrust: ({ percentFilled, size }: Props) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 10,
    height: `${size}px`,
    width: `${size}px`,
    borderRadius: '100%',
    background: `conic-gradient(${theme.palette.primary[800]} calc(${percentFilled}*1%), #0000 0)`,
  }),
  pieFilling: ({ size }: Props) => ({
    zIndex: 1,
    height: `${size}px`,
    width: `${size}px`,
    borderRadius: '100%',
    background: theme.palette.primary[100],
    border: `1px solid ${theme.palette.primary[300]}`,
  }),
  relative: {
    position: 'relative',
  },
});

const PieProgress = ({ percentFilled, size = 40 }: Props) => {
  const asNumber = isNaN(percentFilled) ? 0 : percentFilled;
  const classes = useStyles({ percentFilled: asNumber, size });
  return asNumber < 100 ? (
    <div className={classes.relative}>
      <div className={classes.pieCrust} />
      <div className={classes.pieFilling} />
    </div>
  ) : (
    <EncircleIcon backgroundColor={theme.palette.primary[800]}>
      <Check color={theme.palette.common.white} size={24} />
    </EncircleIcon>
  );
};

export default PieProgress;
