import theme from 'theme';
import { Box, ButtonBase, Dialog, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseX from 'components/icons/CloseX';
import { colors } from '@mysteryco/design';

interface ModalProps {
  title?: string;
  open: boolean;
  onClose: (event: any) => void;
  children: any;
}
const Modal = ({ title, open, onClose, children }: ModalProps) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={onClose}>
      <Box className={classes.contentContainer}>
        <Box className={classes.header}>
          <Typography className={classes.title}>{title}</Typography>
          <ButtonBase onClick={onClose}>
            <CloseX className={classes.close} />
          </ButtonBase>
        </Box>
        <Box className={classes.content}>{children}</Box>
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles({
  close: {
    color: colors.MediumGray,
    transition: 'color 300ms ease-in-out',
    '&:hover': {
      color: colors.Main,
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(8),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
  title: {
    fontWeight: 700,
    fontSize: '1.5rem',
    lineHeight: 1.33,
    color: colors.Dusk,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    gap: theme.spacing(4),
  },
  infoBox: {
    margin: `${theme.spacing(4)} 0`,
  },
});

export default Modal;
