import theme from 'theme';

const LongArrowLeft = ({
  color = theme.palette.primary[400],
  className = '',
  style = {},
}) => {
  return (
    <svg
      className={className}
      width='29'
      height='9'
      style={style}
      viewBox='0 0 29 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M28 5.5C28.5523 5.5 29 5.05228 29 4.5C29 3.94772 28.5523 3.5 28 3.5V5.5ZM1 4.5L0.292893 3.79289C-0.0976311 4.18342 -0.0976311 4.81658 0.292893 5.20711L1 4.5ZM3.79289 8.70711C4.18342 9.09763 4.81658 9.09763 5.20711 8.70711C5.59763 8.31658 5.59763 7.68342 5.20711 7.29289L3.79289 8.70711ZM5.20711 1.70711C5.59763 1.31658 5.59763 0.683417 5.20711 0.292893C4.81658 -0.0976311 4.18342 -0.0976311 3.79289 0.292893L5.20711 1.70711ZM28 3.5H1V5.5H28V3.5ZM5.20711 7.29289L1.70711 3.79289L0.292893 5.20711L3.79289 8.70711L5.20711 7.29289ZM1.70711 5.20711L5.20711 1.70711L3.79289 0.292893L0.292893 3.79289L1.70711 5.20711Z'
        fill={color}
      />
    </svg>
  );
};

export default LongArrowLeft;
