// @ts-strict-ignore
import { gql } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import WifiTethering from '@material-ui/icons/WifiTethering';
import { Skeleton } from 'components/Skeleton';
import { colors } from '@mysteryco/design';
import { DateTime } from 'luxon';

import BeeSwarm from 'components/BeeSwarm';
import { useQueryParam } from 'lib/helpers/router';
import { ScoreDetailsInsightsScoreFragment, ScoreType } from 'types';

import Header from '../common/Header';

import FauxTabs from './FauxTabs';
import ScoreDescription from './ScoreDescription';
import Timeline from './Timeline';
import { useMemo } from 'react';
import theme from 'theme';
import { SubjectType } from 'components/BeeSwarm/types';

export interface ScoreDetailsInsightsProps {
  loading: boolean;
  scoreType: ScoreType;
  previousDateBeingViewed?: DateTime;
  dateBeingViewed: DateTime;
  earliestKnownScoreTime: DateTime;
  urlForDateBeingViewed: (date: DateTime) => string;
  score?: ScoreDetailsInsightsScoreFragment;
  subScores?: {
    teamSubScores?: SubScore[];
    memberSubScores?: SubScore[];
    newHireSubScores?: SubScore[];
  };
}

export const TABS = ['Teams', 'Individuals', 'New Hires'] as const;
export type Tab = typeof TABS[number];
export interface SubScore {
  __typename?: string;
  value?: number;
  teamName?: string;
  team?: {
    id: string;
    name: string;
    size?: number;
  };
}

const ScoreDetailsInsights = ({
  loading,
  scoreType,
  previousDateBeingViewed,
  dateBeingViewed,
  earliestKnownScoreTime,
  urlForDateBeingViewed,
  score,
  subScores,
}: ScoreDetailsInsightsProps) => {
  const today = DateTime.now();
  const tabs = useMemo(() => {
    if (!subScores) return TABS;
    return subScores?.teamSubScores.length ? TABS : TABS.filter((t) => t !== 'Teams');
  }, [subScores]);
  const defaultTab = tabs.includes('Teams') ? 'Teams' : 'Individuals';
  const [tab, , urlForTab] = useQueryParam<Tab>('focus', defaultTab, {
    toString: (tab) => tab,
    fromString: (raw) => (tabs.includes(raw as any) ? (raw as Tab) : undefined),
  });

  let scoresToShow: SubScore[] | 'too-few-scores';
  let subjectType: SubjectType;
  if (tab === 'Teams') {
    scoresToShow = subScores?.teamSubScores;
    subjectType = SubjectType.Team;
  } else if (tab === 'Individuals') {
    scoresToShow = subScores?.memberSubScores;
    subjectType = SubjectType.Individual;
  } else if (tab === 'New Hires') {
    scoresToShow = subScores?.newHireSubScores;
    subjectType = SubjectType.Individual;
  }

  if (
    Array.isArray(scoresToShow) &&
    scoresToShow.some((s) => s.__typename === 'ScrubbedEngagementScore')
  ) {
    scoresToShow = 'too-few-scores';
  }

  // If we are loading, we are showing previous data - make sure the date
  // indicates this.
  const dateToDisplay = previousDateBeingViewed || dateBeingViewed;
  const displayingCurrentMonth =
    dateToDisplay.year === today.year && dateToDisplay.month === today.month;

  return (
    <div
      css={{
        marginBottom: theme.spacing(16),
      }}
    >
      <Header title='Insights'>
        {!dateToDisplay ? (
          <Skeleton width={80} />
        ) : displayingCurrentMonth ? (
          `Updated on ${dateToDisplay.toFormat('LLL dd')}`
        ) : (
          `For ${dateToDisplay.toFormat('LLL yyyy')}`
        )}
      </Header>
      <ScoreDescription scoreType={scoreType} />

      <FauxTabs<Tab>
        tabs={tabs}
        value={tab}
        urlForValue={urlForTab}
        footer={
          <Timeline
            value={dateBeingViewed}
            loading={loading}
            earliestKnownScoreTime={earliestKnownScoreTime}
            urlForValue={urlForDateBeingViewed}
          />
        }
      >
        {scoresToShow === 'too-few-scores' ? (
          <TooFewScoresPlaceholder tab={tab} />
        ) : (
          <BeeSwarm
            loading={loading}
            subjectType={subjectType}
            subScores={scoresToShow}
            tab={tab}
            score={score}
            css={{ width: '100%', height: 240, margin: '0 30px' }}
          />
        )}
      </FauxTabs>
    </div>
  );
};

const TooFewScoresPlaceholder = ({ tab }: { tab: Tab }) => {
  const classes = useTooFewScoresStyles();

  return (
    <div className={classes.root}>
      <div className={classes.icon}>
        <WifiTethering />
      </div>
      <div className={classes.hint}>Not enough signal</div>
      <div className={classes.main}>
        {tab === 'Individuals'
          ? `This team is too small for us to get signal`
          : `There are too few new-hires to display for this team`}
      </div>
      <div className={classes.description}>
        Don't worry, we'll still gather insights to share with you as this team grows
      </div>
    </div>
  );
};

const useTooFewScoresStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(10),
    // We need a better way of calculating this…
    height: 289.5,
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.Purple900,
    backgroundColor: colors.Purple100,
    height: 44,
    width: 44,
    borderRadius: 100,
    marginBottom: theme.spacing(2),
  },
  hint: {
    color: colors.Purple700,
    fontSize: 14,
  },
  main: {
    fontSize: 18,
  },
  description: {
    color: colors.Purple700,
    fontSize: 12,
  },
});

ScoreDetailsInsights.fragments = gql`
  fragment ScoreDetailsInsightsScore on EngagementScore {
    value
    trend
    scoreType
  }

  fragment ScoreDetailsInsightsTeamSubScore on EngagementScore {
    value
    ... on DirectTeamEngagementScore {
      team {
        id
        name
      }
    }
    ... on TransitiveTeamEngagementScore {
      team {
        id
        name
      }
    }
  }

  fragment ScoreDetailsInsightsIndividualSubScore on EngagementResult {
    __typename
    ... on IndividualEngagementScore {
      value
      teamName
    }
  }
`;

export default ScoreDetailsInsights;
