// @ts-strict-ignore
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@mysteryco/design';
import FlatButton from 'components/core/FlatButton';
import IconButton from 'components/IconButton';
import Tooltip from 'components/core/Tooltip';
import CloseX from 'components/icons/CloseX';
import { pluralize } from 'humanize-plus';
import ReactLoading from 'react-loading';
import theme from 'theme';
import { MemberFragmentFragment } from 'types';
import ArchiveButton from './ArchiveButton';
import { TeamPageOrganization } from '..';

interface BulkEditToolbarProps {
  selectedMembers: Set<MemberFragmentFragment>;
  organization: TeamPageOrganization;
  showEdit?: boolean;
  showArchive?: boolean;
  showInvite?: boolean;
  showRestore?: boolean;
  remindersLoading?: boolean;
  onClose?: () => void;
  onArchive?: (message: string) => void;
  onInvite?: () => Promise<void>;
  onEdit?: () => void;
}

const BulkEditToolbar = ({
  selectedMembers,
  organization,
  showEdit,
  showArchive,
  showRestore,
  showInvite,
  onClose,
  onArchive,
  onInvite,
  onEdit,
  remindersLoading,
}: BulkEditToolbarProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.bulkEditBar}>
      {/* Left */}
      <Box className={classes.bulkEditInfo}>
        <Tooltip title='Close' placement='top'>
          <IconButton onClick={onClose}>
            <CloseX />
          </IconButton>
        </Tooltip>
        {selectedMembers?.size > 0 && (
          <Box>
            <Typography className={classes.selectedMembersLabel}>
              {selectedMembers.size}
            </Typography>
            {` ${pluralize(selectedMembers.size, 'member')} selected`}
          </Box>
        )}
      </Box>

      {/* Right */}
      {selectedMembers?.size > 0 && (
        <Box className={classes.bulkEditActions}>
          {(showArchive || showRestore) && (
            <ArchiveButton
              members={selectedMembers}
              organization={organization}
              restoreMode={showRestore}
              afterUpdate={onArchive}
            />
          )}
          {showInvite && (
            <FlatButton
              disabled={remindersLoading}
              variant='primary'
              corners='rounded'
              onClick={onInvite}
            >
              Resend {pluralize(selectedMembers.size, 'invite')}
              {remindersLoading && (
                <ReactLoading type='spin' height='1rem' width='1rem' />
              )}
            </FlatButton>
          )}
          {showEdit && (
            <FlatButton variant='primary' corners='rounded' onClick={onEdit}>
              Edit {pluralize(selectedMembers.size, 'member')}
            </FlatButton>
          )}
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  bulkEditBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.spacing(3)} ${theme.spacing(5)}`,
    gap: theme.spacing(2.5),
    background: colors.Purple50,
    borderTop: `2px solid ${colors.Main}`,
    marginTop: theme.spacing(1),
  },
  bulkEditInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    gap: theme.spacing(4),
    fontWeight: 700,
    fontSize: '.875rem',
    lineHeight: 1.71,
    color: colors.Purple800,
  },
  selectedMembersLabel: {
    display: 'inline',
    fontWeight: 700,
    fontSize: '.875rem',
    lineHeight: 1.71,
    color: colors.Main,
  },
  bulkEditActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 0,
    gap: theme.spacing(3),
  },
});

export default BulkEditToolbar;
