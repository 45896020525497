import { css } from '@emotion/react';
import { colors } from '@mysteryco/design';
import { card } from 'glue/components/cards/sharedStyles';
import theme from 'theme';

export const BillingTitle = ({ children }: { children: React.ReactNode }) => {
  return <header css={styles.title}>{children}</header>;
};

export const BillingHeader = ({ children }: { children: React.ReactNode }) => {
  return <header css={styles.header}>{children}</header>;
};

export const BillingDetails = ({ children }: { children: React.ReactNode }) => {
  return <div css={styles.details}>{children}</div>;
};

export const BillingAside = ({ children }: { children: React.ReactNode }) => {
  return <p css={styles.aside}>{children}</p>;
};

export const BillingSection = ({children}: {children: React.ReactNode}) => {
  return <div css={styles.section}>{children}</div>;
}

export const BillingCell = ({ children }: { children: React.ReactNode }) => {
  return <div css={styles.cell}>{children}</div>;
};

const BillingCard = ({ children }: { children: React.ReactNode }) => {
  return <div css={styles.root}>{children}</div>;
};

const styles = {
  root: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(5),
    gap: theme.spacing(3),
    flex: 1,
    ...card.root,
    minWidth: '20em',
  }),
  section: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: '100%',
    gap: theme.spacing(6),
    '&:not(:first-of-type)': {
      paddingTop: theme.spacing(3),
    },
    '&:not(:last-of-type)': {
      paddingBottom: theme.spacing(6),
      borderBottom: `1px solid ${colors.Glue_Ink30}`,
    },
  }),
  cell: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    padding: 0,
    gap: theme.spacing(2),
  }),
  title: css ({
    fontWeight: 500,
    fontSize: '1.5rem',
  }),
  header: css({
    fontFamily: 'inherit',
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: 1.2,
    color: colors.Glue_Ink00,
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  }),
  details: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    gap: 8,
    fontFamily: 'inherit',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1.5rem',
    lineHeight: 1,
    color: colors.Glue_Ink00,
  }),
  aside: css({
    fontFamily: 'inherit',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '.875rem',
    lineHeight: 1.4,
    colors: colors.Glue_Ink00,
    gridColumn: 'span 2'
  }),
};

export default BillingCard;
