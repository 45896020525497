import { css } from '@emotion/react';
import { Coal_10, Mint_40, White } from '@mysteryco/design/src/tokens/colors';
import MeetupsZoomers from 'glue/assets/artwork/meetups-zoomers.png';
import LogoType from 'glue/components/branding/LogoType';
import { EM_DASH } from 'constants/Strings';
import Button from 'glue/components/buttons/Button';
import theme from 'theme';

type PreviewEmailProps = {
  firstName: string;
  orgName: string;
};

const PreviewEmail = (props: PreviewEmailProps) => {
  const compiledStrings = strings(props);
  return (
    <div css={styles.container}>
      <LogoType size={100} css={styles.shiftIcon} />
      <img src={MeetupsZoomers} alt='A video conference' />
      <p css={styles.title}>{compiledStrings.title}</p>
      <p>{compiledStrings.intro}</p>
      <p>{compiledStrings.newFeature}</p>
      <p css={styles.bold}>{compiledStrings.whatDoesThisMean}</p>
      <ul css={styles.ul}>
        <li>{compiledStrings.matching}</li>
        <li>{compiledStrings.interests}</li>
      </ul>
      <p>{compiledStrings.firstMeetup}</p>
      <Button
        shape='round'
        color='green'
        onClick={(e) => e.preventDefault()}
        disabled
        css={styles.previewButton}
      >
        {compiledStrings.updatePreferences}
      </Button>
    </div>
  );
};

const strings = ({ firstName, orgName }: PreviewEmailProps) => ({
  intro: `Hi ${firstName},`,
  title: `You have been added to ${orgName}'s Meetups`,
  newFeature:
    'Your company has enabled a new feature to help you expand your network and build community at work. We call this Meetups',
  whatDoesThisMean: 'What does this mean for you?',
  matching:
    'Twice a month, our AI will match you with people you don’t know to boost your connections',
  interests: "We'll find connections based on your interests, so update those if needed",
  firstMeetup: `Your first Meetup will be scheduled in 48 hours${EM_DASH}set your preferences now via the button below to personalize your settings.`,
  updatePreferences: 'Update my preferences',
});

const styles = {
  container: css({
    fontFamily: 'Manrope',
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(6)} ${theme.spacing(8)}`,
    fontSize: theme.spacing(4.5),
    color: Coal_10,
    maxWidth: '600px',
    borderRadius: theme.spacing(4),
    background: White,
  }),
  shiftIcon: css({
    marginLeft: theme.spacing(-5),
    marginBottom: theme.spacing(4),
  }),
  bold: css({
    fontWeight: 700,
  }),
  ul: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  }),
  title: css({
    fontFamily: 'Zilla Slab',
    fontWeight: 600,
    fontSize: theme.spacing(10),
    lineHeight: theme.spacing(12),
    marginBottom: theme.spacing(6),
  }),
  previewButton: css({
    '--bg-disabled': Mint_40,
    '--fg-disabled': Coal_10,
  }),
};

export default PreviewEmail;
