import { SnackbarUtils } from 'components/core';

export const doFetch = async (
  url: string,
  body: any,
  method: string = 'post',
): Promise<{ [v: string]: any }> => {
  const data = await fetch(url, {
    method: method,
    credentials: 'include',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
  });

  let json: any = {};
  try {
    json = await data.json();
  } catch (e) {}

  if (data.status >= 400) {
    const errorMessage = `Error: ${json.message || ''} (${data.statusText} / ${
      data.status
    })`;
    SnackbarUtils.error(errorMessage);
  }
  return json;
};
