import { css } from '@emotion/react';
import ClockIcon from '@mysteryco/design/icons/Clock';
import ArrowRightIcon from '@mysteryco/design/icons/ArrowRight';
import ClipboardCheckIcon from '@mysteryco/design/icons/ClipboardCheck';
import ListedStepper from 'glue/components/lists/ListedStepper';
import theme from 'theme';
import { Paths } from 'Routes';
import { LinkButton } from 'glue/components/buttons/Button';
import mixpanel from 'mixpanel-browser';
import { PULSE_SURVEY_LINK } from 'glue/scenes/PulseSettings/constants';
import GlueyFace, { GlueyFaceName } from 'glue/components/glueyFace/GlueyFace';
import format from 'date-fns/format';
import { useState } from 'react';
import { Maybe } from 'types';
import GlueyHead from 'glue/components/glueyFace/GlueyHead';

export function WaitingForFirstSurvey({ surveyDate }: { surveyDate?: Maybe<string> }) {
  return (
    <div css={styles.root}>
      <div css={styles.mainContent}>
        <div css={styles.icon}>
          <ClockIcon color='currentColor' size={24} />
        </div>
        <h2 css={styles.headline}>We're waiting on your first Pulse</h2>
        <p css={styles.subtitle}>What happens now</p>
        <ListedStepper
          activeStep={-1}
          showConnector
          steps={[
            {
              text: surveyDate
                ? `We'll send your first survey on ${format(
                    new Date(surveyDate),
                    'MMMM d, yyyy',
                  )}`
                : `We'll send your first survey soon`,
              subText: 'Participants will receive a notification to answer your survey',
            },
            {
              text: "We'll collect survey feedback from your employees",
              subText:
                'You will receive a notification as soon as data is ready for your review',
            },
          ]}
        />
        <p css={styles.subtitle}>Meanwhile you can</p>
        <LinkButton
          to={`${Paths.PULSE_SETTINGS}/edit`}
          color='black'
          shape='round'
          endIcon={<ArrowRightIcon color='currentColor' size={24} />}
        >
          Manage Pulse Settings
        </LinkButton>
      </div>
      <SurveyBanner />
    </div>
  );
}

const SurveyBanner = () => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      css={styles.hint}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    >
      <GlueyHead css={styles.face}>
        <GlueyFace
          face={GlueyFaceName.Delighted}
          animation={hovered ? 'wink' : undefined}
          css={styles.glueyFace}
        />
      </GlueyHead>
      <div css={styles.hintContent}>
        <p css={{ margin: 0 }}>
          <strong>Psst...</strong> share your experience with Pulse so far by answering a
          few questions while we wait for your first survey results.
        </p>
        <LinkButton
          to={PULSE_SURVEY_LINK}
          color='white'
          shape='round'
          startIcon={<ClipboardCheckIcon color='currentColor' size={20} />}
          onClick={() => {
            mixpanel.track('pls-begin-survey', {
              eventType: 'click',
              buttonCopy: 'Answer survey',
            });
          }}
        >
          Answer survey
        </LinkButton>
      </div>
    </div>
  );
};

export default WaitingForFirstSurvey;

const styles = {
  root: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: 1,
    alignItems: 'center',
    padding: theme.spacing(8),
    gap: theme.spacing(3),
  }),
  icon: css({
    borderRadius: '100%',
    background: '#B9FFD1',
    width: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  headline: css({
    fontWeight: 700,
    size: theme.typography.pxToRem(20),
    lineHeight: 1.4,
  }),
  subtitle: css({
    fontWeight: 700,
    textTransform: 'uppercase',
    color: '#706A74',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 2.33,
  }),
  hint: css({
    marginTop: 'auto',
    width: '100%',
    maxWidth: 840,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(3),
    backgroundColor: '#f8ecff',
    padding: theme.spacing(4),
  }),
  face: css({
    backgroundColor: '#e7c2ff',
  }),
  hintContent: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
    gap: theme.spacing(3),
  }),
  glueyFace: css({
    stroke: '#3b3b3b',
  }),
  mainContent: css({
    flex: 1,
    marginBottom: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
};
