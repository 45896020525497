import { css } from '@emotion/react';
import theme from 'theme';

const SidePanel = ({ children }) => <div css={styles.sidePanel}>{children}</div>;

const styles = {
  sidePanel: css({
    width: '280px',
    minWidth: '280px',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  }),
};

export default SidePanel;
