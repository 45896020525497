import theme from 'theme';
const TeamCheckboxSelected = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='0.5' y='0.5' width='23' height='23' rx='1.5' fill={theme.palette.primary.main} />
      <path
        d='M5.20711 11.2929L4.5 10.5858L3.08579 12L3.79289 12.7071L5.20711 11.2929ZM9.5 17L8.79289 17.7071L9.5 18.4142L10.2071 17.7071L9.5 17ZM20.2071 7.70711L20.9142 7L19.5 5.58579L18.7929 6.29289L20.2071 7.70711ZM3.79289 12.7071L8.79289 17.7071L10.2071 16.2929L5.20711 11.2929L3.79289 12.7071ZM10.2071 17.7071L20.2071 7.70711L18.7929 6.29289L8.79289 16.2929L10.2071 17.7071Z'
        fill={theme.palette.common.white}
      />
      <rect x='0.5' y='0.5' width='23' height='23' rx='1.5' stroke={theme.palette.primary.main} />
    </svg>
  );
};

export default TeamCheckboxSelected;
