import { css } from '@emotion/react';
import { colors } from '@mysteryco/design';
import theme from 'theme';
import FormikForm from 'glue/components/forms/FormikForm';
import FormikResetButton from 'glue/components/forms/FormikResetButton';
import FormikSubmitButton from 'glue/components/forms/FormikSubmitButton';
import { ViewerFragmentFragment, useUpdateUserMutation } from 'types';
import NotificationToggle from './NotificationToggle';

const Notifications = ({ viewer }: { viewer: ViewerFragmentFragment }) => {
  const [updateUser] = useUpdateUserMutation();
  return (
    <FormikForm
      initialValues={{
        marketingConsent: viewer.marketingConsent,
        optOutOfDigestAt: viewer.optOutOfDigestAt,
      }}
      enableReinitialize
      onSubmit={async (values) => {
        await updateUser({
          variables: {
            id: viewer.id,
            marketingConsent: values.marketingConsent,
            optOutOfDigestAt: values.optOutOfDigestAt,
          },
          refetchQueries: ['getViewer'],
          awaitRefetchQueries: true,
        });
      }}
    >
      <div css={styles.root}>
        <div>
          <NotificationToggle
            field={'marketing'}
            style={{ paddingTop: 0, borderBottom: `1px solid ${colors.Coal_40}` }}
          />
          <NotificationToggle field={'digest'} />
        </div>
        <div css={styles.promptBox}>
          <div css={styles.promptTitle}>Notifications</div>
          <div css={styles.promptText}>
            This is the place where you can control the updates your Glue account admins
            will receive.
          </div>
          <div css={styles.promptButtons}>
            <FormikResetButton color={'white'} shape={'square'}>
              Cancel
            </FormikResetButton>
            <FormikSubmitButton shape={'square'} onlyDirty>
              Save
            </FormikSubmitButton>
          </div>
        </div>
      </div>
    </FormikForm>
  );
};

const styles = {
  root: css({
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(20),
    maxWidth: '1080px',
    margin: `${theme.spacing(10)} auto 0`,
  }),
  promptBox: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
    border: `1px solid ${colors.Coal_40}`,
    borderRadius: '20px',
    color: colors.Coal_10,
    lineHeight: '160%',
    padding: theme.spacing(6),
    width: '380px',
  }),
  promptTitle: css({
    fontWeight: 600,
    fontSize: '18px',
  }),
  promptText: css({
    fontWeight: 500,
    fontSize: '15px',
  }),
  promptButtons: css({
    display: 'flex',
    gap: theme.spacing(2),
    '& > *': {
      flexGrow: 1,
    },
  }),
};

export default Notifications;
