type FormatPercentageProps = {
    percentage: number | typeof NaN | undefined;
  }
  const FormatPercentage = ({percentage}: FormatPercentageProps) => {
    if(percentage === undefined || percentage === null) {
      return <span>0%</span>;
    }
    if(isNaN(percentage)) {
      return <span>0%</span>
    }
    return <span>{percentage.toFixed()}%</span> 
  }

  export default FormatPercentage;