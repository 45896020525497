import ActivityQuestions from 'components/Checkout/steps/PreferenceQuestions/ActivityQuestions';
import RegistrationStepWrapper from 'components/Checkout/styling/RegistrationStepWrapper';
import mixpanel from 'mixpanel-browser';
import { WizardContext } from 'react-albus';
import { PreferenceQuestionsFragmentFragment, ViewerFragmentFragment } from 'types';

export interface ActivityPreferenceQuestionsProps {
  context: WizardContext;
  viewer: ViewerFragmentFragment;
  currentPreferenceAnswers: any;
  setPreferenceAnswers: (answers: any) => void;
  questions: PreferenceQuestionsFragmentFragment[];
  onNext: () => void;
}

export function ActivityPreferenceQuestions({
  context,
  viewer,
  currentPreferenceAnswers,
  setPreferenceAnswers,
  questions,
  onNext,
}: ActivityPreferenceQuestionsProps) {
  const allActivityQuestionsAnswered = questions.every(
    (q) => currentPreferenceAnswers[q.id],
  );

  return (
    <RegistrationStepWrapper
      {...context}
      label={'What activities sound fun to do with your team?'}
      subTitle={`This helps us plan events that everyone will enjoy.`}
      onClickNext={async () => {
        mixpanel.track('activity preferences updated', {
          source: 'registration',
          customerType: viewer?.customerType,
          firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
        });
        await onNext();
      }}
      nextButtonProps={{
        disabled: !allActivityQuestionsAnswered,
      }}
    >
      <ActivityQuestions
        setPreferenceAnswers={setPreferenceAnswers}
        currentPreferenceAnswers={currentPreferenceAnswers}
        questions={questions}
      />
    </RegistrationStepWrapper>
  );
}

export default ActivityPreferenceQuestions;
