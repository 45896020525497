import { makeStyles } from '@material-ui/styles';
import { Input } from 'antd';
import { Colors, Sizes } from 'constants/index';
import _ from 'lodash';
import React from 'react';
import { AnswerProps } from '.';

const useStyles = makeStyles({
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputWrapper: {
    height: '55px',
  },
  limitReached: {
    fontSize: '10px',
    color: Colors.Palette.Error,
    marginTop: Sizes.SPACING_XSMALL,
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const CHARACTER_LIMIT = 60;

const TextAnswer = ({ answer, setAnswer }: AnswerProps) => {
  const classes = useStyles();
  return (
    <div className={classes.flexCol}>
      <div className={classes.inputWrapper}>
        <Input
          maxLength={CHARACTER_LIMIT}
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          placeholder='Your answer...'
        />
        {_.size(answer) === CHARACTER_LIMIT && (
          <div className={classes.limitReached}>Character limit reached</div>
        )}
      </div>
    </div>
  );
};

export default TextAnswer;
