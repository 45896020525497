import { gql } from '@apollo/client';
import { makeStyles } from '@material-ui/styles';
import { DetailedPriceEstimatePopover } from 'components/Experiences/DetailedPriceEstimatePopover';
import { PremiumExperienceBanner } from 'components/Experiences/PremiumExperienceBanner';
import { getHasPhysicalGoodsOrUpgradeFromTemplate } from 'components/SSFSteps/EventSelectionDetails/helpers';
import {
  ContractUnitType,
  CostUnit,
  DrawerPricingSummaryContractFragment,
  DrawerPricingSummaryTemplateFragment,
} from 'types';
import { DrawerPricingDetail } from './DrawerPricingDetail';

export function DrawerPricingSummary({
  contract,
  prospectiveGuests,
  template,
}: {
  contract: DrawerPricingSummaryContractFragment | null;
  prospectiveGuests: number;
  template: DrawerPricingSummaryTemplateFragment;
}) {
  const classes = useStyles();
  const bands = template.cost?.ctcPricingBands ?? [];
  const eventBand = bands.find((band) => band.unit === CostUnit.Event);
  const minGuests = eventBand?.maxUsers ?? 0;
  const firstUserBand = bands.find((band) => band.unit === CostUnit.User);

  const { hasPhysicalGoods, requiresUpgrade } =
    getHasPhysicalGoodsOrUpgradeFromTemplate(template);
  const isPremium =
    (hasPhysicalGoods || requiresUpgrade) &&
    contract?.type === ContractUnitType.Recurring;

  return (
    <div>
      <div className={classes.priceCostsRow}>
        {firstUserBand && (
          <DrawerPricingDetail
            label='Cost per guest'
            contractType={contract?.type ?? ContractUnitType.Cents}
            priceBand={firstUserBand}
            subLine={
              bands.length > 1 && (
                <DetailedPriceEstimatePopover
                  contract={contract}
                  template={template}
                  prospectiveGuests={prospectiveGuests}
                />
              )
            }
          />
        )}
        {eventBand && (
          <DrawerPricingDetail
            label='Minimum event cost'
            contractType={contract?.type ?? ContractUnitType.Cents}
            priceBand={eventBand}
            subLine={<span>Covers first {minGuests} people</span>}
            headCount={minGuests}
          />
        )}
      </div>
      {isPremium && <PremiumExperienceBanner />}
    </div>
  );
}
DrawerPricingSummary.fragments = {
  contract: gql`
    fragment DrawerPricingSummaryContract on Contract {
      id
      type
      ...DetailedPriceEstimatePopoverContract
    }
    ${DetailedPriceEstimatePopover.fragments.contract}
  `,
  template: gql`
    fragment DrawerPricingSummaryTemplate on MysteryTemplate {
      id
      cost {
        id
        ctcPricingBands {
          ...DrawerPricingDetailPriceBand
        }
      }
      experiences {
        id
        hasPhysicalGoods
        requiresUpgrade
      }
      ...DetailedPriceEstimatePopoverTemplate
    }
    ${DrawerPricingDetail.fragments.priceBand}
    ${DetailedPriceEstimatePopover.fragments.template}
  `,
};

const useStyles = makeStyles({
  priceCostsRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    padding: '12px',
  },
});
