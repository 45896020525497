import CashStash from 'components/icons/CashStash';
import InfoHex from 'components/icons/InfoHex';
import StarGroup from 'components/icons/StarGroup';
import TeamBikes from 'components/illustrations/TeamBikes';
import { pluralize } from 'humanize-plus';
import {
  CONTRACT_UNIT_FORMAT_LOOKUP,
  CONTRACT_UNIT_PHRASE_LOOKUP,
} from 'lib/helpers/contract';
import { centsToDollarString } from 'lib/helpers/money';
import { DateTime } from 'luxon';
import theme from 'theme';
import { ContractUnitType } from 'types';

import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { openHubspotChat } from 'utils/hubspotUtil';
import { SettingsTabProps } from './';

declare global {
  interface Window {
    HubSpotConversations: any;
  }
}

const EmptyState = ({ classes }) => (
  <Box className={classes.mCard}>
    <Box mb={2} className={classes.mCardIcon}>
      <StarGroup color={theme.palette.secondary[600]} height={24} width={24} />
    </Box>
    <Box mb={2}>
      <Typography className={classes.mCardLabel}>SUBSCRIBE TO GLUE</Typography>
    </Box>
    <Box mb={2}>
      <Typography variant='h4' className={classes.mCardTitle}>
        Enjoy recurring happiness{' '}
        <span role='img' aria-label='happy'>
          😁
        </span>
      </Typography>
    </Box>
    <Box mb={2}>
      <Typography className={classes.mCardHelperText}>
        We offer Morale as a Service, tailored to your team
      </Typography>
    </Box>
    <Typography className={classes.subtitle}>
      Your team will get access to volume discounts, team insights, and extra secret
      mysteries.
    </Typography>
    <Box className={classes.illustration}>
      <TeamBikes />
    </Box>
    <ContactButton classes={classes} />
  </Box>
);

const Cell = ({ classes, children, label }) => (
  <div className={classes.cellContainer}>
    <div className={classes.cellLabelContainer}>
      <Typography className={classes.cellLabel}>{label}</Typography>
    </div>
    {children}
  </div>
);

const PricingPlan = ({ classes, activeContract }) => {
  const unitPhrase = CONTRACT_UNIT_PHRASE_LOOKUP[activeContract.type];
  const pricingLabel = pluralize(2, unitPhrase);
  const centsBased = activeContract.type === ContractUnitType.Cents;
  const showPremium =
    activeContract.type === ContractUnitType.Recurring ||
    activeContract.type === ContractUnitType.Event;

  return (
    <div className={classes.mCard}>
      <Box mb={2} className={classes.mCardIcon}>
        <CashStash color={theme.palette.secondary[600]} height={24} width={24} />
      </Box>
      <Box mb={2}>
        <Typography className={classes.mCardLabel}>Your plan</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant='h4' className={classes.mCardTitle}>
          Glue recurring{' '}
          <span role='img' aria-label='happy'>
            😁
          </span>
        </Typography>
      </Box>
      {/* Seats */}
      <div className={classes.pricingMain}>
        <Typography className={classes.pricingLabel}>{pricingLabel}</Typography>
        <div className={classes.pricingCells}>
          <Cell classes={classes} label={'Remaining'}>
            <div className={classes.statContainer}>
              <Typography className={classes.stat}>
                {CONTRACT_UNIT_FORMAT_LOOKUP[activeContract.type]?.(
                  activeContract.unitCount,
                ) || 'N/A'}
              </Typography>
            </div>
          </Cell>
          <Cell classes={classes} label='Pending'>
            <Typography className={classes.stat}>
              {activeContract.pendingUnitCount > 0
                ? `${
                    CONTRACT_UNIT_FORMAT_LOOKUP[activeContract.type]?.(
                      activeContract.pendingUnitCount,
                    ) || 'N/A'
                  } ${pluralize(activeContract.pendingUnitCount, unitPhrase)}`
                : 'None'}
            </Typography>
            {!centsBased &&
              (activeContract.premiumUnitCount > 0 ||
                activeContract.pendingPremiumUnitCount > 0) && (
                <Typography className={classes.stat}>{`${
                  activeContract.pendingPremiumUnitCount
                } ${pluralize(
                  activeContract.pendingPremiumUnitCount,
                  'supply credit',
                )}`}</Typography>
              )}
          </Cell>
        </div>
        <div>
          {!centsBased && activeContract.unitCostCents > 0 && (
            <div className={classes.AddMoreBox}>
              <InfoHex
                color={theme.palette.secondary[600]}
                height={12}
                width={12}
                className={classes.AddMoreBoxIcon}
              />
              <Typography>{`Add more for ${centsToDollarString(
                activeContract.unitCostCents,
              )} each, when you book events`}</Typography>
            </div>
          )}
        </div>
      </div>
      {/* Premium Credits, only applies for Recurring & Event contracts */}
      {showPremium && !!activeContract.premiumUnitCount && (
        <div className={classes.pricingMain}>
          <Typography className={classes.pricingLabel}>Premium</Typography>
          <div className={classes.pricingCells}>
            <Cell classes={classes} label={`Supply & upgrade credits`}>
              <div className={classes.statContainer}>
                <Typography className={classes.stat}>
                  {`${activeContract.premiumUnitCount} remaining`}
                </Typography>
              </div>
              <Typography>
                Add more for {centsToDollarString(activeContract.premiumUnitCostCents)},
                when you book events
              </Typography>
            </Cell>
          </div>
        </div>
      )}
      {/* Other details*/}
      <div className={classes.pricingMain}>
        <Typography className={classes.pricingLabel}>Details</Typography>
        <div className={classes.pricingCells}>
          <Cell classes={classes} label='Plan start date'>
            <Typography className={classes.stat}>
              {DateTime.fromJSDate(new Date(activeContract.startDate)).toLocaleString(
                DateTime.DATE_MED,
              )}
            </Typography>
          </Cell>
          <Cell classes={classes} label='Plan auto-renews on'>
            <Typography className={classes.stat}>
              {DateTime.fromJSDate(new Date(activeContract.renewDate)).toLocaleString(
                DateTime.DATE_MED,
              )}
            </Typography>
          </Cell>
        </div>
      </div>
    </div>
  );
};

const PricingPlanContainer = ({ classes, activeContract }) => (
  <div className={classes.pricingContainer}>
    <PricingPlan classes={classes} activeContract={activeContract} />
    <BiggerPlan classes={classes} />
  </div>
);

const BiggerPlan = ({ classes }) => (
  <div className={classes.upsellBox}>
    <Typography className={classes.upsellBoxTitle}>Need a bigger plan?</Typography>
    <Typography className={classes.upsellBoxCopy}>
      Get volume discounts, personal assistance, and extra secret mysteries.
    </Typography>
    <ContactButton classes={classes} />
  </div>
);

const ContactButton = ({ classes }) => (
  <Button onClick={openHubspotChat} className={classes.button}>
    Contact Us
  </Button>
);

const Pricing = ({ viewer, classes }: SettingsTabProps) => {
  const pricingClasses = { ...classes, ...useStyles() } as any;
  const activeContract = viewer?.orgs?.[0]?.activeContract;

  return (
    <div className={pricingClasses.container}>
      <div className={pricingClasses.body}>
        {!!activeContract ? (
          <PricingPlanContainer
            classes={pricingClasses}
            activeContract={activeContract}
          />
        ) : (
          <EmptyState classes={pricingClasses} />
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  body: {},
  cellContainer: {
    flex: 1,
    '&:first-of-type': {
      borderRight: `1px solid ${theme.palette.primary[100]}`,
    },
    '&:last-of-type': {
      paddingLeft: theme.spacing(4),
    },
    '@media (max-width: 800px)': {
      '&:first-of-type': {
        borderRight: 0,
        paddingBottom: theme.spacing(4),
        borderBottom: `1px solid ${theme.palette.primary[100]}`,
      },
      '&:last-of-type': {
        paddingTop: theme.spacing(4),
        paddingLeft: 0,
      },
    },
  },
  cellLabel: {
    minWidth: '240px',
    color: theme.palette.primary.main,
    fontSize: '0.8rem',
    fontFamily: `'Roboto Monospace', monospace`,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  cellLabelContainer: {
    marginTop: theme.spacing(2),
  },
  subtitle: { marginBottom: '1rem' },
  main: { marginBottom: '1rem' },
  pricingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pricingSection: { width: '60%' },
  pricingMain: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
  },
  pricingCells: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '@media (max-width: 800px)': {
      flexDirection: 'column',
    },
  },
  pricingLabel: {
    color: theme.palette.text.primary,
    fontSize: '1.25rem',
    fontWeight: 500,
    textTransform: 'capitalize',
  },
  upsellBox: {
    padding: theme.spacing(6),
    borderRadius: theme.spacing(2),
    background: theme.palette.primary[100],
    width: '100%',
    maxWidth: theme.spacing(140),
  },
  upsellBoxTitle: {
    fontSize: '18px',
    lineHeight: '32px',
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  upsellBoxCopy: {
    fontSize: '14px',
    color: theme.palette.text.primary,
  },
  stat: {
    fontSize: '1.5rem',
    color: theme.palette.text.primary,
  },
  statContainer: {
    marginBottom: theme.spacing(1),
  },
  AddMoreBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    background: theme.palette.secondary[100],
    '& p': {
      fontSize: '.8rem',
      lineHeight: '1rem',
      fontWeight: 500,
      color: theme.palette.text.primary,
    },
  },
  AddMoreBoxIcon: {
    height: '16px',
    width: '16px',
    marginRight: theme.spacing(2),
    '& path': {
      fill: theme.palette.text.primary,
    },
  },
  mCard: {
    width: '100%',
    maxWidth: theme.spacing(140),
    margin: `${theme.spacing(6)} auto`,
    padding: theme.spacing(6),
    borderRadius: theme.spacing(2),
    border: `1px solid ${theme.palette.primary[100]}`,
    background: theme.palette.common.white,
    boxShadow: `0px 6px 8px -3px ${theme.palette.primary[100]}`,
    '&:hover': {
      border: `1px solid ${theme.palette.primary[200]}`,
      boxShadow: `0px 8px 20px -2px ${theme.palette.primary[100]}`,
    },
  },
  mCardIcon: {
    display: 'inline-flex',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    background: theme.palette.secondary[200],
  },
  mCardLabel: {
    color: theme.palette.primary.main,
    fontSize: '0.8rem',
    fontFamily: `'Roboto Monospace', monospace`,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  mCardTitle: {
    fontSize: theme.spacing(8),
    color: theme.palette.text.primary,
  },
  mCardHelperText: {
    fontSize: '1rem',
    color: theme.palette.grey[900],
  },
  button: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(4),
    borderRadius: theme.spacing(4),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary[900],
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  illustration: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export default Pricing;
