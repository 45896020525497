// @ts-strict-ignore
import { DateTime } from 'luxon';
import { ExperienceInfo } from 'types/selfServeFlow';

// weeks
export const DEFAULT_PHYSICAL_GOODS_BOOKING_LEAD_TIME = 4;
const DEFAULT_TEN_DAYS_LESS_SHIP_LEAD_TIME_DAYS = 17;
const DEFAULT_11_DAYS_MORE_SHIP_LEAD_TIME_DAYS = 21;
export const DEFAULT_WATERCOOLER_CADENCE_WEEKS = 2; // from api/src/tasks/WatercoolerSchedulerTask.ts

export const makeArrayOfDates = (intervalMinutes) => {
  const minutesInDay = 1440;
  const numberOfEntries = minutesInDay / intervalMinutes;
  const now = DateTime.local().startOf('day');
  const arr = [];
  for (let i = 0; i < numberOfEntries; i++) {
    const value = now.plus({ minutes: intervalMinutes * i });
    arr.push({
      label: value.toLocaleString(DateTime.TIME_SIMPLE),
      value: { hour: value.hour, minute: value.minute },
    });
  }
  return arr;
};

export const getNumberSuffix = (n: number) => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const calculateMinBookingLeadTimeDays = (experiences) => {
  if (!experiences) return DEFAULT_PHYSICAL_GOODS_BOOKING_LEAD_TIME;
  const days = experiences.map((exp) => {
    if (exp.usaShipping?.estimatedLeadTimeDays) {
      if (exp.usaShipping.estimatedLeadTimeDays <= 10)
        return DEFAULT_TEN_DAYS_LESS_SHIP_LEAD_TIME_DAYS;
    }
    return DEFAULT_11_DAYS_MORE_SHIP_LEAD_TIME_DAYS;
  });
  return Math.min(days);
};

export const inRequestToBookThreshold = (
  date: Date,
  hasPhysicalGoods: boolean,
  experiences?: ExperienceInfo[],
) => {
  const businessDaysBetween = getBusinessDaysBetweenDates(
    DateTime.now().startOf('day').toJSDate(),
    date,
  );
  return hasPhysicalGoods
    ? businessDaysBetween < calculateMinBookingLeadTimeDays(experiences)
    : businessDaysBetween < 10;
};
export const canRequestToBook = (
  date: Date,
  hasPhysicalGoods: boolean,
  experiences?: ExperienceInfo[],
) => {
  const businessDaysBetween = getBusinessDaysBetweenDates(
    DateTime.now().startOf('day').toJSDate(),
    date,
  );
  return hasPhysicalGoods
    ? businessDaysBetween >= calculateMinBookingLeadTimeDays(experiences)
    : businessDaysBetween >= 2;
};

export const isNewHire = (
  employeeStartDate: DateTime,
  comparePointInTime = DateTime.now(),
) => {
  const newHireDiff = comparePointInTime
    .diff(employeeStartDate, ['months', 'days'])
    .toObject();
  if (newHireDiff.months < 6) {
    return true;
  }
  // Special cases for the exact start date and exactly 6 months from start
  if (newHireDiff.months === 6 && newHireDiff.days === 0) {
    return true;
  }
  return false;
};

// Hot copy-pasta from API
export const getBusinessDaysBetweenDates = (dateA: Date, dateB: Date): number => {
  let earlierDate: Date;
  let laterDate: Date;
  if (dateA < dateB) {
    earlierDate = dateA;
    laterDate = dateB;
  } else {
    earlierDate = dateB;
    laterDate = dateA;
  }

  let businessDays = 0;
  while (
    !DateTime.fromJSDate(earlierDate).hasSame(DateTime.fromJSDate(laterDate), 'day')
  ) {
    const dayOfWeek = earlierDate.getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      businessDays++;
    }
    earlierDate.setDate(earlierDate.getDate() + 1);
  }

  return businessDays;
};

export const addBusinessDaysToDate = (date: Date, businessDays: number): Date => {
  const newDate = date;

  if (businessDays < 0) {
    for (let i = 0; i < Math.abs(businessDays); i++) {
      newDate.setDate(newDate.getDate() - 1);
      // If it's Sunday...
      if (newDate.getDay() === 0) {
        // ...set the date all the way back to Friday (Sunday - 2)
        newDate.setDate(newDate.getDate() - 2);
      }
      // If it's Saturday...
      else if (newDate.getDay() === 6) {
        // ...set the date to Friday (Sat - 1)
        newDate.setDate(newDate.getDate() - 1);
      }
    }
  } else if (businessDays > 0) {
    for (let i = 0; i < businessDays; i++) {
      newDate.setDate(newDate.getDate() + 1);
      // If it's Saturday...
      if (newDate.getDay() === 6) {
        // ...set the date all the way to Monday (Saturday + 2)
        newDate.setDate(newDate.getDate() + 2);
      }
      // If it's Sunday...
      else if (newDate.getDay() === 0) {
        // ...set the date to Monday (Sunday + 1)
        newDate.setDate(newDate.getDate() + 1);
      }
    }
  }

  return newDate;
};
