import ESGoalLeadership from 'components/icons/ESGoalLeadership';
import ESGoalCrossTeam from 'components/icons/ESGoalCrossTeam';
import ESGoalWithinTeam from 'components/icons/ESGoalWithinTeam';
import { CubeOutline } from 'components/icons/CubeOutline';
import { Star04 } from 'components/icons/Star04';
import Circle from 'components/Circle';

import { EventGoal } from 'types';
import { Dusk, Purple600 } from '@mysteryco/design/src/tokens/colors';

const GoalIcon = ({
  disabled = false,
  goal,
  size,
}: {
  disabled?: boolean;
  goal: EventGoal;
  size: number;
}) => {
  const iconColor = disabled ? Purple600 : Dusk;
  switch (goal) {
    case EventGoal.BetweenTeam:
      return (
        <Circle size={size} disabled={disabled}>
          <ESGoalCrossTeam size={size} color={iconColor} />
        </Circle>
      );
    case EventGoal.FullOrg:
      return (
        <Circle size={size} disabled={disabled}>
          <CubeOutline size={size} color={iconColor} />
        </Circle>
      );
    case EventGoal.Leadership:
      return (
        <Circle size={size} disabled={disabled}>
          <ESGoalLeadership color={iconColor} size={size} />
        </Circle>
      );
    case EventGoal.NewHire:
      return (
        <Circle size={size} disabled={disabled}>
          <Star04 size={size} color={iconColor} />
        </Circle>
      );
    case EventGoal.WithinTeam:
      return (
        <Circle size={size} disabled={disabled}>
          <ESGoalWithinTeam size={size} color={iconColor} />
        </Circle>
      );
    default:
      return (
        <Circle size={size} disabled={disabled}>
          <CubeOutline size={size} color={iconColor} />
        </Circle>
      );
  }
};

export default GoalIcon;
