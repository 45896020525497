import { css } from '@emotion/react';
import { colors } from '@mysteryco/design/src';
import * as Progress from '@radix-ui/react-progress';

export interface ProgressBarProps extends Progress.ProgressProps {}

export function ProgressBar(props: ProgressBarProps) {
  const progress = props.value ?? 0;
  return (
    <Progress.Root css={styles.progressRoot} {...props}>
      <Progress.Indicator
        css={styles.progressIndicator}
        style={{ transform: `translateX(-${100 - progress}%)` }}
      />
    </Progress.Root>
  );
}

export default ProgressBar;

const styles = {
  progressRoot: css({
    overflow: 'hidden',
    height: '15px',
    background: colors.Glue_Ink30,
    width: '100%',
  }),
  progressIndicator: css({
    backgroundColor: colors.Glue_Darkberry10,
    width: '100%',
    height: '100%',
    transition: 'transform 660ms cubic-bezier(0.65, 0, 0.35, 1)',
  }),
};
