import { Box, Typography } from '@material-ui/core';
import React from 'react';
import theme from 'theme';

type Props = {
  title: string;
  subTitle?: string;
  className?: string;
  color?: string;
};

export default function SectionSubHeader({ title, subTitle, className, color }: Props) {
  return (
    <Box style={{ marginBottom: theme.spacing(4) }} className={className}>
      <Typography variant='h5' css={{ color: color || 'inherit' }}>
        <b>{title}</b>
      </Typography>
      {!!subTitle && (
        <Typography variant='body1' css={{ color: color || 'inherit' }}>
          {subTitle}
        </Typography>
      )}
    </Box>
  );
}
