import React from 'react';
import { Type } from 'constants/index';
import { makeStyles } from '@material-ui/styles';

interface BoldProps {
  className?: string;
  children?: any;
}

const useStyles = makeStyles({
  bold: {
    fontWeight: Type.FontWeights.BOLD,
  },
});

export function Bold({ className, children, ...otherProps }: BoldProps) {
  const classes = useStyles();
  return (
    <span className={[classes.bold, className].join(' ')} {...otherProps}>
      {children}
    </span>
  );
}
