import upDarkGreenPattern from 'glue/assets/patterns/up-dark-green.png';
import upLightGreenPattern from 'glue/assets/patterns/up-light-green.png';
import neutralPattern from 'glue/assets/patterns/nochange.png';
import downLightOrangePattern from 'glue/assets/patterns/down-light-orange.png';
import downDarkOrangePattern from 'glue/assets/patterns/down-dark-orange.png';
import { HTMLAttributes, forwardRef } from 'react';
import { css } from '@emotion/react';
import { useColorblindMode } from 'glue/components/accessibility/hooks';

const PATTERNS = {
  'trend-up-strong': {
    color: '#3C8452',
    img: upLightGreenPattern,
  },
  'trend-up': {
    color: '#BFFED1',
    img: upDarkGreenPattern,
  },
  'trend-down': {
    color: '#FFDCC0',
    img: downDarkOrangePattern,
  },
  'trend-down-strong': {
    color: '#E65E24',
    img: downLightOrangePattern,
  },
  'trend-neutral': {
    color: '#F0D9FF',
    img: neutralPattern,
  },
};

export type PatternName = keyof typeof PATTERNS;

export interface PatternedBoxProps extends HTMLAttributes<HTMLDivElement> {
  pattern: PatternName;
  /**
   * Whether the pattern only shows up when accessibility mode is on
   * or always.
   */
  mode?: 'accessible' | 'always';
}

export const PatternedBox = forwardRef<HTMLDivElement, PatternedBoxProps>(
  function PatternedBox({ pattern, mode = 'accessible', ...rest }, ref) {
    const [isColorblindAccessibilityMode] = useColorblindMode();
    const enabled =
      mode === 'always' || (mode === 'accessible' && isColorblindAccessibilityMode);
    return (
      <div
        css={[styles.base[pattern], enabled && styles.pattern[pattern]]}
        ref={ref}
        {...rest}
      />
    );
  },
);

export default PatternedBox;

const styles = {
  base: Object.keys(PATTERNS).reduce((acc, key) => {
    acc[key] = css({
      backgroundColor: PATTERNS[key].color,
      backgroundRepeat: 'repeat',
      backgroundSize: '30px',
      backgroundPositionX: 'right',
    });
    return acc;
  }, {}),
  pattern: Object.keys(PATTERNS).reduce((acc, key) => {
    acc[key] = css({
      backgroundImage: `url(${PATTERNS[key].img})`,
    });
    return acc;
  }, {}),
};
