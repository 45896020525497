import { ScoreType } from 'types';
import { SCORE_TYPE_NAME_MAP } from 'constants/Strings';
import { makeStyles } from '@material-ui/styles';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Tooltip } from '@material-ui/core';
import { Skeleton } from 'components/Skeleton';
import TooltipWrapper from 'components/core/TooltipWrapper';
import { ArrowUpward, ArrowDownward, ArrowRightAlt } from '@material-ui/icons';
import MysteryCard from './MysteryCard';
import { pluralize } from 'humanize-plus';
import theme from 'theme';
import { colors } from '@mysteryco/design';

export interface FocusAreaCardProps {
  loading?: boolean;
  scoreType?: ScoreType;
  currentTeams?: any[];
  previousMonthTeams?: any[];
}

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: colors.White,
    border: `1px solid ${colors.Purple200}`,
    fontSize: 12,
    boxShadow:
      '0px 10px 15px -3px rgba(155, 160, 166, 0.1), 0px 4px 6px -2px rgba(155, 160, 166, 0.06)',
  },
}))(Tooltip);

const formatScoreType = (scoreType: ScoreType) => {
  switch (scoreType) {
    case ScoreType.SenseOfBelonging:
      return 'sense of belonging';
    case ScoreType.Leadership:
      return 'connections to leadership';
    default:
      return `${SCORE_TYPE_NAME_MAP[scoreType].toLowerCase()} connections`;
  }
};

const FocusAreaCard = ({
  loading = false,
  scoreType,
  currentTeams,
  previousMonthTeams,
}: FocusAreaCardProps) => {
  const classes = useStyles();
  const currentTeamsCount = currentTeams?.length ?? 0;
  const previousTeamsCount = previousMonthTeams?.length ?? 0;
  const displayedTeams =
    (currentTeamsCount > 6 ? currentTeams?.slice(0, 5) : currentTeams) ?? [];
  return (
    <MysteryCard
      loading={loading}
      title={scoreType ? `Teams with weak ${formatScoreType(scoreType)}` : ''}
      allowsHover={true}
    >
      {loading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <>
          <div>
            <Typography className={classes.currentTeamSize}>
              {currentTeamsCount}
            </Typography>
            {currentTeamsCount > previousTeamsCount ? (
              <ArrowUpward className={classes.trendIcon} />
            ) : currentTeamsCount < previousTeamsCount ? (
              <ArrowDownward className={classes.trendIcon} />
            ) : (
              <ArrowRightAlt className={classes.trendIcon} />
            )}
            <Typography className={classes.previousMonthTeamSize}>
              Previous: {previousTeamsCount}
            </Typography>
          </div>
          {currentTeamsCount > 0 ? (
            <>
              <div css={{ marginTop: theme.spacing(2) }}>
                {displayedTeams.map((team, index) => {
                  return (
                    <LightTooltip
                      key={index}
                      placement='top'
                      title={
                        <TooltipWrapper>
                          <div css={{ padding: theme.spacing(2) }}>
                            <div
                              css={{
                                color: colors.Dusk,
                                fontWeight: 700,
                                marginBottom: theme.spacing(2),
                              }}
                            >
                              <span
                                css={{
                                  height: '10px',
                                  width: '10px',
                                  borderRadius: '50%',
                                  backgroundColor: colors.Orange300,
                                  display: 'inline-block',
                                  marginRight: theme.spacing(2),
                                }}
                              ></span>
                              {team.team.name}
                            </div>
                            <div
                              css={{
                                fontWeight: 500,
                                color: theme.palette.grey[700],
                                marginLeft: theme.spacing(4.5),
                              }}
                            >{`${team.teamSize} ${pluralize(
                              team.teamSize,
                              'member',
                            )}`}</div>
                          </div>
                        </TooltipWrapper>
                      }
                    >
                      <span
                        className={classes.teamLabel}
                        css={{
                          backgroundColor: colors.Orange200,
                          '&:hover': { background: colors.Orange300 },
                        }}
                      >
                        {team.team.name
                          .split(' ')
                          .slice(0, 2)
                          .map((word: string) => word.charAt(0).toUpperCase())}
                      </span>
                    </LightTooltip>
                  );
                })}
                {currentTeamsCount > 6 ? (
                  <span
                    className={classes.teamLabel}
                    css={{ backgroundColor: colors.OffWhite }}
                  >{`+${currentTeamsCount - 5}`}</span>
                ) : null}
              </div>
              <div css={{ marginTop: theme.spacing(4) }}>
                <Typography className={classes.notes}>
                  {(currentTeams ?? []).map((team) => team.team.name).join(', ')}
                </Typography>
              </div>
            </>
          ) : (
            <div css={{ marginTop: theme.spacing(4) }}>
              <Typography className={classes.emptyStateNotes}>
                👏 Way to go team!
              </Typography>
            </div>
          )}
        </>
      )}
    </MysteryCard>
  );
};

const useStyles = makeStyles({
  currentTeamSize: {
    fontWeight: 700,
    fontSize: '24px',
    color: colors.Dusk,
    display: 'inline-block',
  },
  trendIcon: {
    marginLeft: theme.spacing(1),
    marginBottom: '-3px',
    fontSize: '16px',
    color: colors.Main,
    display: 'inline-block',
  },
  previousMonthTeamSize: {
    marginLeft: theme.spacing(3),
    color: theme.palette.grey[700],
    fontWeight: 500,
    display: 'inline-block',
  },
  teamLabel: {
    marginLeft: '-8px',
    textAlign: 'center',
    lineHeight: '44px',
    width: '44px',
    height: '44px',
    fontWeight: 700,
    color: colors.Main,
    fontSize: '14px',
    borderRadius: '50%',
    borderWidth: '1px',
    borderColor: colors.White,
    borderStyle: 'solid',
    display: 'inline-block',
  },
  notes: {
    color: colors.Dusk,
    fontSize: '14px',
    fontWeight: 500,
  },
  emptyStateNotes: {
    color: colors.Dusk,
    fontSize: '16px',
    fontWeight: 700,
  },
});

export default FocusAreaCard;
