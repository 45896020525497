// @ts-strict-ignore
import { Box, ClickAwayListener, Popper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@mysteryco/design/src';
import { useState } from 'react';
import theme from 'theme';
import OptionMenu, { Option, OptionMenuProps } from './dropdown/OptionMenu';
import DropdownFilterButton from './DropdownFilterButton';

export interface DropdownFilterOption extends Option {
  name?: string;
}

interface DropdownFilterProps extends OptionMenuProps {
  label: string;
  options: DropdownFilterOption[];
  value: string[];
  onChange?: (event: any, newValue: string[]) => void;
  disabled?: boolean;
  renderOption?: (DropdownFilterOption) => JSX.Element;
  [key: string]: any;
}

export const toOptionArray = (stringOptions: string[]): Option[] => {
  return stringOptions.map((option) => {
    return { id: option, name: option };
  });
};

const getName = (selected: any[], options: any[]) => {
  const selectedId = selected[0];
  return options.find((option) => option.id === selectedId)?.name;
};

const pillValue = (selected: any[], options: any[], multiple = false): string => {
  if (!options.length) return 'None';
  if (!multiple) return selected?.length ? getName(selected, options) : 'None';
  const selectedLabel = !!selected?.length ? selected.length : options.length;
  return `${selectedLabel} of ${options.length}`;
};

const DropdownFilterSimple = ({
  label,
  options = [],
  value = [],
  onChange = () => null,
  disabled = false,
  helperText,
  multiple,
  flipEnabled = false,
  ...props
}: DropdownFilterProps) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: any) => {
    if (!anchorEl && !disabled) {
      setAnchorEl(event?.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: any, newValue: string[]) => {
    onChange(event, newValue);
    if (!multiple) handleClose();
  };

  const defaultRenderOption = (option: DropdownFilterOption) => {
    return <Box className={classes.option}>{option.name}</Box>;
  };

  const renderOption = props.renderOption || defaultRenderOption;
  const dropdownOpen = Boolean(anchorEl);

  const defaultHelperText = multiple ? 'Select one or many' : 'Select one';

  return (
    <Box>
      <DropdownFilterButton
        onClick={handleClick}
        label={label}
        value={pillValue(value, options, multiple)}
        expanded={dropdownOpen}
        disabled={disabled || !options.length}
      />
      <Popper
        className={classes.dropdown}
        open={dropdownOpen}
        anchorEl={anchorEl}
        disablePortal
        placement={'bottom-start'}
        modifiers={{ flip: { enabled: flipEnabled } }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <OptionMenu
            options={options}
            values={value}
            onChange={handleChange}
            helperText={helperText ?? defaultHelperText}
            renderOption={renderOption}
            optionsContainerProps={{ className: classes.container }}
            editContainerProps={{ className: classes.container }}
            disableSearch
            disableCreate
            disableUpdate
            multiple={multiple}
            {...props}
          />
        </ClickAwayListener>
      </Popper>
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    width: '20rem',
  },
  option: {
    fontWeight: 500,
    fontSize: '.875rem',
    lineHeight: 1.71,
    color: colors.Black,
  },
  dropdown: {
    marginTop: theme.spacing(2),
    zIndex: theme.zIndex.modal,
    boxShadow:
      '0px 20px 25px -5px rgba(155, 160, 166, 0.15), 0px 10px 10px -5px rgba(155, 160, 166, 0.04)',
    borderRadius: theme.spacing(1),
    border: `1px solid ${colors.Glue_BorderLight}`,
    background: colors.Glue_Paper,
  },
});

export default DropdownFilterSimple;
