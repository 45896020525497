// @ts-strict-ignore
import { gql } from '@apollo/client';
import { Button, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@mysteryco/design';
import AlertBar from 'components/AlertBar';
import { NotFoundError } from 'components/core';
import InfoCircle from 'components/icons/InfoCircle';
import { SCORE_TYPE_TO_PATH_PARAM } from 'constants/Strings';
import { usePathParam, useQueryParam, useQueryParams } from 'lib/helpers/router';
import { getSubMembers } from 'lib/helpers/teams';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import theme from 'theme';
import { ScoreType, useScoreDetailsQuery } from 'types';
import { getConnectionsPath } from '..';
import ScoreDetailsEventsStrategy from './EventsStrategy';
import ScoreDetailsFocusAreas from './FocusAreas';
import ScoreDetailsInsights, { ScoreDetailsInsightsProps, SubScore } from './Insights';
import ScoreDetailsNavigation from './Navigation';
import ScoreDetailsTeam from './Team';

const PathParamToScoreType = Object.fromEntries(
  Object.entries(SCORE_TYPE_TO_PATH_PARAM).map(([param, scoreType]) => [
    scoreType,
    param,
  ]),
) as Record<string, ScoreType>;

export const getScoreDetailsPath = (
  organizationId: string,
  teamId: string,
  scoreType: ScoreType,
) => {
  return `${getConnectionsPath()}/${organizationId}/${teamId}/${
    SCORE_TYPE_TO_PATH_PARAM[scoreType]
  }`;
};

const DATE_FORMAT = 'yyyy-MM-dd';

const ScoreDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const [teamId] = usePathParam('teamId');
  const [orgId] = usePathParam('orgId');
  const params = useQueryParams();
  const version = params.get('scoreVersion');
  const [scoreType] = usePathParam<ScoreType>('scoreType', {
    toString: (scoreType) => SCORE_TYPE_TO_PATH_PARAM[scoreType],
    fromString: (pathParam) => PathParamToScoreType[pathParam],
  });
  const [dateBeingViewed, , urlForDateBeingViewed] = useQueryParam(
    'date',
    () => DateTime.now(),
    {
      toString: (date: DateTime) => date.toFormat(DATE_FORMAT),
      fromString: (raw) => raw && DateTime.fromFormat(raw, DATE_FORMAT),
    },
  );
  const datePreviousMonth = dateBeingViewed.plus({ months: -1 });
  const { activeState, previousState, loading, error, reload } = useMainQuery({
    orgId,
    teamId,
    scoreType,
    dateBeingViewed,
    datePreviousMonth,
    version,
  });

  if (!scoreType) return <NotFoundError />;

  const subMemberIds = getSubMembers({
    teams: activeState?.allTeamsInMyOrg,
    rootTeamId: activeState?.organization?.rootTeam?.id,
    currentTeamId: teamId,
  });

  return (
    <div>
      <div
        css={{
          display: 'flex',
          maxWidth: '1400px',
          margin: 'auto',
        }}
      >
        <ScoreDetailsNavigation
          loading={loading}
          teamId={teamId}
          activeScoreType={scoreType}
          setActiveScoreType={(type) =>
            history.push(getScoreDetailsPath(orgId, teamId, type))
          }
          scores={activeState?.teamScores as any}
        />
        <div
          css={{
            flex: 1,
            marginLeft: 80,
            paddingTop: 20,
            '& > :not(:first-of-type)': { marginTop: theme.spacing(2) },
          }}
        >
          {version && (
            <div className={classes.versionAlertBar}>
              <AlertBar
                text={`You are currently viewing version ${version} scores`}
                icon={<InfoCircle size={16} />}
                severity='error'
              />
            </div>
          )}
          <ScoreDetailsTeam
            loading={loading}
            teamId={teamId}
            setTeamId={(id) => history.push(getScoreDetailsPath(orgId, id, scoreType))}
            team={activeState?.team}
            scoreType={scoreType}
            allTeamsInMyOrg={activeState?.allTeamsInMyOrg}
          />
          <ScoreDetailsInsights
            loading={loading}
            scoreType={scoreType}
            previousDateBeingViewed={previousState?.variables.dateBeingViewed}
            dateBeingViewed={dateBeingViewed}
            earliestKnownScoreTime={DateTime.fromJSDate(
              new Date(activeState?.team?.earliestKnownScoreTime),
            )}
            urlForDateBeingViewed={urlForDateBeingViewed}
            score={
              activeState?.teamScores?.find(
                (s) => 'scoreType' in s && s.scoreType === scoreType,
              ) as any
            }
            subScores={activeState?.insights}
          />
          <ScoreDetailsFocusAreas
            loading={loading}
            teamScores={activeState?.teamScores as any}
            scoreType={scoreType}
            teamSubScores={activeState?.focusAreas?.teamSubScoresWithNewHireCount as any}
            previousMonthTeamSubScores={
              activeState?.focusAreas?.previousMonthTeamSubScores as any
            }
          />
          <ScoreDetailsEventsStrategy
            activeTeamName={activeState?.team?.name}
            parentLoading={loading}
            teams={activeState?.allTeamsInMyOrg?.filter((team) =>
              team.members.some((m) => subMemberIds.has(m.id)),
            )}
            orgId={orgId}
            date={dateBeingViewed}
            scoreType={scoreType}
          />
        </div>
      </div>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={!!error}>
        <div className={classes.errorSnackbar}>
          There was trouble loading your data.
          <Button variant='text' className={classes.reloadButton} onClick={reload}>
            Retry?
          </Button>
        </div>
      </Snackbar>
    </div>
  );
};

const useStyles = makeStyles({
  errorSnackbar: {
    backgroundColor: colors.Red600,
    color: colors.White,
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    borderRadius: 8,
  },
  reloadButton: {
    color: colors.White,
    fontWeight: 600,
  },
  versionAlertBar: {
    marginBottom: theme.spacing(4),
  },
});

const useMainQuery = (variables: {
  orgId: string;
  teamId: string;
  scoreType: ScoreType;
  dateBeingViewed: DateTime;
  datePreviousMonth: DateTime;
  version: string;
}) => {
  // Hooks are GREAT, they said…
  const variablesForMemo = [
    variables.teamId,
    variables.scoreType,
    variables.dateBeingViewed.valueOf(),
    variables.version,
  ];
  const {
    loading: apolloLoading,
    data: apolloData,
    error,
    refetch,
  } = useScoreDetailsQuery({ variables, errorPolicy: 'all' });

  // Wat: Apollo is not re-rendering when we call `refetch`.
  //
  // Unclear why, and I've beaten my head against this for too long. So, as
  // a workaround, this forces the component to re-render (and Apollo does have
  // the correct state handy at that point).
  const [reloading, setReloading] = useState(false);
  const reload = useMemo(() => {
    return async function reload() {
      setReloading(true);
      try {
        await refetch();
      } finally {
        setReloading(false);
      }
    };
  }, [refetch]);
  const loading = apolloLoading || reloading;

  // Convert the Apollo response into a structure that more closely mirrors our
  // component hierarchy
  const currentState = useMemo(() => {
    if (!apolloData) return;

    const {
      teamScores,
      teamSubScores,
      memberSubScores,
      newHireSubScores,
      previousMonthTeamSubScores,
      ...team
    } = apolloData?.teamInMyOrg;

    const teamSizeByTeamId = apolloData.allTeamsInMyOrg?.reduce((acc, team) => {
      acc[team.id] = team.members?.length;
      return acc;
    }, {});

    let result = {
      variables,
      team,
      teamScores,
      allTeamsInMyOrg: apolloData.allTeamsInMyOrg,
      organization: apolloData.organization,
      insights: {
        teamSubScores: teamSubScores.map((subScore: SubScore) => ({
          ...subScore,
          team: {
            ...subScore.team,
            size: teamSizeByTeamId[subScore.team.id],
          },
        })),
        memberSubScores: memberSubScores.map(
          (score: { teamName?: string } & SubScore) =>
            ({
              ...score,
              team: score.teamName
                ? {
                    name: score.teamName,
                  }
                : undefined,
            } as SubScore),
        ),
        newHireSubScores,
      } as ScoreDetailsInsightsProps['subScores'],
      focusAreas: {
        previousMonthTeamSubScores,
        teamSubScoresWithNewHireCount: teamSubScores.map((subScore: SubScore) => ({
          ...subScore,
          newHireCount: newHireSubScores.filter(
            (score: { teamName?: string } & SubScore) =>
              score.teamName === subScore.team.name,
          ).length,
          teamSize: teamSizeByTeamId[subScore.team.id],
        })),
      },
    };

    return result;
  }, [
    // IMPORTANT: We intentionally do not include `variables`.
    //
    //   * useQuery does not clear data immediately on variable change, which
    //     leads to confusion around which data values are bound to which
    //     variables.
    //
    apolloData,
  ]);

  // We hang onto previous state so that we can render it while loading
  // (enabling transitions between values).
  let [previousState, setPreviousState] = useState(currentState);
  if (currentState && currentState !== previousState && !error) {
    setPreviousState(currentState);
    previousState = currentState;
  }

  // Depending on what variables have changed, we can preserve some of the
  // previous state when loading
  const activeState = useMemo(() => {
    if (!loading) return currentState;
    if (!previousState) return;

    const result = {} as typeof currentState;
    result.variables = variables;
    if (previousState.variables.teamId === variables.teamId) {
      result.team = previousState.team;
    }
    if (previousState.variables.dateBeingViewed.equals(variables.dateBeingViewed)) {
      result.teamScores = previousState.teamScores;
    }
    if (previousState.variables.scoreType === variables.scoreType) {
      result.insights = previousState.insights;
    }

    return result;
  }, [loading, currentState, previousState, ...variablesForMemo]);

  return {
    activeState,
    currentState,
    previousState,
    loading,
    error,
    reload,
  };
};

ScoreDetails.AllTeamsFragment = gql`
  fragment AllTeamSearch on Team {
    id
    name
    manager {
      id
    }
    members {
      id
      nylasAssociation {
        id
      }
    }
  }
`;

ScoreDetails.query = gql`
  query ScoreDetails(
    $orgId: ID!
    $teamId: ID!
    $scoreType: ScoreType!
    $dateBeingViewed: DateTime!
    $datePreviousMonth: DateTime!
    $version: String
  ) {
    organization(id: $orgId) {
      id
      rootTeam {
        id
      }
    }
    allTeamsInMyOrg {
      ...AllTeamSearch
    }
    teamInMyOrg(id: $teamId) {
      ...ScoreDetailsTeam
      teamScores: scores(
        entityType: TransitiveTeam
        dateCutoff: $dateBeingViewed
        version: $version
      ) {
        ...ScoreDetailsInsightsScore
      }
      teamSubScores: subScores(
        entityType: TransitiveTeam
        dateCutoff: $dateBeingViewed
        scoreType: $scoreType
        version: $version
      ) {
        ...ScoreDetailsInsightsTeamSubScore
      }
      previousMonthTeamSubScores: subScores(
        entityType: TransitiveTeam
        dateCutoff: $datePreviousMonth
        scoreType: $scoreType
        version: $version
      ) {
        ...ScoreDetailsFocusAreas
      }
      memberSubScores: subScores(
        entityType: Individual
        dateCutoff: $dateBeingViewed
        scoreType: $scoreType
        version: $version
      ) {
        ...ScoreDetailsInsightsIndividualSubScore
      }
      newHireSubScores: subScores(
        entityType: NewHire
        dateCutoff: $dateBeingViewed
        scoreType: $scoreType
        version: $version
      ) {
        ...ScoreDetailsInsightsIndividualSubScore
      }
    }
  }
  ${ScoreDetailsNavigation.fragment}
  ${ScoreDetailsTeam.fragment}
  ${ScoreDetailsInsights.fragments}
  ${ScoreDetailsFocusAreas.fragments}
  ${ScoreDetails.AllTeamsFragment}
`;

export default ScoreDetails;
