import { css } from '@emotion/react';
import { animated, useSpring } from '@react-spring/web';
import { useState, useEffect } from 'react';

export enum GlueyFaceName {
  Smile,
  Wink,
  Meh,
  Delighted,
}

type GlueyFaceAnimation = {
  frames: {
    face: GlueyFaceName;
    timing: number;
  }[];
};

export interface GlueyFaceProps {
  face?: GlueyFaceName;
  animation?: keyof typeof glueyAnimations;
  size?: number;
  className?: string;
}

/**
 * Hi! Be careful about editing these paths. They are not the same as what's in Figma.
 *
 * To animate between SVG paths, the paths need to be constructed in the same way. So
 * I've hand-rewritten each variant to be compatible with the others, plus made
 * some tweaks so they interpolate nicely. For example, the vertical line of
 * the "open" eye path is actually a 2-segment line which goes up and then back
 * down again. Each eye path has to be 2-segment, because smiling eyes are 2-segment.
 * Going up and then down again transitions to smiling or closed nicely because the
 * two points at the bottom 'fan out' to the sides seamlessly.
 */

// keep the vertical alignment of matched segments
// prettier-ignore
const eyePaths = {
  // eye shapes are within an 8x5 box
  open:    'M 0 5 L 0 0 L 0 5',
  closed:  'M 0 2 L 4 2 L 8 2',
  smiling: 'M 0 5 L 4 0 L 8 5',
};

// keep the vertical alignment of matched segments
// prettier-ignore
const mouthPaths = {
  // mouth shapes are within a 20x8 box
  smile: 'M 0 0 C 2 8 18 8 20 0',
  flat:  'M 0 2 C 9 2 9  2 20 2',
};

const faces: Record<GlueyFaceName, { leftEye: string; rightEye: string; mouth: string }> =
  {
    [GlueyFaceName.Smile]: {
      leftEye: eyePaths.open,
      rightEye: eyePaths.open,
      mouth: mouthPaths.smile,
    },
    [GlueyFaceName.Wink]: {
      leftEye: eyePaths.closed,
      rightEye: eyePaths.open,
      mouth: mouthPaths.smile,
    },
    [GlueyFaceName.Meh]: {
      leftEye: eyePaths.closed,
      rightEye: eyePaths.closed,
      mouth: mouthPaths.flat,
    },
    [GlueyFaceName.Delighted]: {
      leftEye: eyePaths.smiling,
      rightEye: eyePaths.smiling,
      mouth: mouthPaths.smile,
    },
  };

export const glueyAnimations = {
  wink: {
    frames: [
      {
        face: GlueyFaceName.Smile,
        timing: 800,
      },
      {
        face: GlueyFaceName.Wink,
        timing: 500,
      },
      {
        face: GlueyFaceName.Smile,
        timing: 2000,
      },
      {
        face: GlueyFaceName.Delighted,
        timing: 8000,
      },
    ],
  },
};

export function GlueyFace({ face, animation, size = 20, ...rest }: GlueyFaceProps) {
  const animationDef = animation ? glueyAnimations[animation] : undefined;
  const currentFace = useFaceAnimation(animationDef, face);

  const { leftEye, rightEye, mouth } = useSpring(faces[currentFace]);

  return (
    <svg
      width={size}
      height={(25 / 32) * size}
      viewBox='0 0 32 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      css={styles.root}
      {...rest}
    >
      <g css={styles.leftEye}>
        <animated.path
          className='left-eye'
          d={leftEye}
          stroke='inherit'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit='10'
        />
      </g>
      <g css={styles.rightEye}>
        <animated.path
          className='right-eye'
          d={rightEye}
          stroke='inherit'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit='10'
        />
      </g>
      <g css={styles.mouth}>
        <animated.path
          className='mouth'
          d={mouth}
          stroke='inherit'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit='10'
        />
      </g>
    </svg>
  );
}

export default GlueyFace;

function useFaceAnimation(animation?: GlueyFaceAnimation, fallbackFace?: GlueyFaceName) {
  const [currentFrame, setCurrentFrame] = useState(0);

  useEffect(() => {
    if (animation) {
      const timeout = setTimeout(() => {
        setCurrentFrame((currentFrame) => (currentFrame + 1) % animation.frames.length);
      }, animation.frames[currentFrame].timing);
      return () => clearTimeout(timeout);
    }
  }, [animation, currentFrame]);

  return animation?.frames[currentFrame]?.face ?? fallbackFace ?? GlueyFaceName.Smile;
}

const styles = {
  root: css({
    stroke: '#706A74',
  }),
  leftEye: css({
    transformOrigin: 'center',
    transformBox: 'fill-box',
    transform: `translate(5px, 4px) translate(-50%, -50%)`,
  }),
  rightEye: css({
    transformOrigin: 'center',
    transformBox: 'fill-box',
    transform: `translate(27px, 4px) translate(-50%, -50%)`,
  }),
  mouth: css({
    transformOrigin: 'center',
    transformBox: 'fill-box',
    transform: `translate(16px, 20px) translate(-50%, -50%)`,
  }),
};
