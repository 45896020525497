import { Drawer, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@mysteryco/design';
import mixpanel from 'mixpanel-browser';
import { useState } from 'react';
import theme from 'theme';
import StarDouble from './icons/StarDouble';
import UpsellSubscription from './UpsellSubscription';
import { useViewer } from 'utils/state';

const useStyles = makeStyles({
  upgradePlan: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 800,
    maxHeight: 46,
    borderRadius: theme.spacing(3),
    padding: `${theme.spacing(2.5)} ${theme.spacing(6)}`,
    border: '2px solid transparent',
    background: `linear-gradient(${colors.White}, ${colors.White}),
                 linear-gradient(rgba(194, 74, 204, .2), rgba(237, 146, 117, .2))`,
    backgroundClip: 'padding-box, border-box',
    backgroundOrigin: 'padding-box, border-box',
    '&:hover': {
      color: theme.palette.common.white,
      background: theme.palette.primary[600],
      '& svg path': {
        fill: theme.palette.common.white,
      },
    },
  },
});

interface Props {
  page: string;
}

const UpgradeButton = ({ page }: Props) => {
  const classes = useStyles();
  const viewer = useViewer();
  const [open, setOpen] = useState(false);
  const onClick = () => {
    mixpanel.track('header upgrade clicked', {
      source: page,
      customerType: viewer?.customerType,
      firstTimeBooker: viewer?.requestedTeamEvents?.length === 0,
    });
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Link className={classes.upgradePlan} underline='none' onClick={onClick}>
        <StarDouble css={{ marginRight: theme.spacing(2) }} />
        Upgrade plan
      </Link>
      <Drawer anchor='right' open={open} onClose={onClose}>
        <UpsellSubscription onClose={onClose} />
      </Drawer>
    </>
  );
};

export default UpgradeButton;
