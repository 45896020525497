// @ts-strict-ignore
import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  FormControl,
  MenuItem,
  OutlinedInput,
  RadioGroup,
  Select,
  Typography,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { colors } from '@mysteryco/design';
import ArrowRight from '@mysteryco/design/icons/ArrowRight';
import CalendarDate from '@mysteryco/design/icons/CalendarDate';
import ChevronDown from '@mysteryco/design/icons/ChevronDown';
import ClockRefresh from '@mysteryco/design/icons/ClockRefresh';
import { Loading } from 'components/core';
import { Form, Formik } from 'formik';
import Button from 'glue/components/buttons/Button';
import TextLink from 'glue/components/buttons/TextLink';
import { AudienceBuilder, getUniqUserCount } from 'glue/components/inputs/audience';
import RadioButtonCard from 'glue/components/inputs/RadioButtonCard';
import InlineBanner from 'glue/components/structure/InlineBanner';
import {
  SettingsTopperActions,
  SettingsTopperCloseButton,
  SettingsTopperContent,
  SettingsTopperRoot,
  SettingsTopperTitle,
} from 'glue/components/structure/SettingsTopper';
import StepperBlock from 'glue/components/structure/StepperBlock';
import { usePulseSettingsState } from './pulseSettingsState';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import { ChangeEvent, useMemo } from 'react';
import theme from 'theme';
import { MoralePulseCadence, User, useGetUserWithOrgsQuery } from 'types';
import MoralePulseStepWrapper from './MoralePulseStepWrapper';
import PulseHelpBanner from '../components/PulseHelpBanner';
import { getStepperProps } from './helpers';
import { css } from '@emotion/react';

interface Props {
  next: () => void;
}

const strings = {
  pulseSettings: 'Pulse Settings',
  title: 'Survey set up',
  schedule: 'schedule',
  whenStart: 'When should surveys start?',
  setSchedule: 'Set your survey schedule:',
  tip: 'Surveys are sent weekly at 11 AM EST.',
  tipLearnMore: 'Learn more',
  fyi: 'FYI:',
  audience: 'audience',
  whoShould: 'Who should receive your Pulse checks?',
  selectOne: 'Select one of the options below',
  everyone: 'Everyone in my org',
  segment: 'A specific segment',
  define: 'Define your segment',
  build: 'Build your audience',
  youNeed: 'You need to add at least 5 participants to continue',
};

const MIN_USER_LIMIT = 5;
const MAX_LIST_SIZE = 15;
const scheduleOptions = [
  { name: 'Weekly', value: MoralePulseCadence.Weekly },
  { name: 'Every 2 weeks', value: MoralePulseCadence.Semimonthly, recommended: true },
  { name: 'Every 4 weeks', value: MoralePulseCadence.Monthly },
  { name: 'Quarterly', value: MoralePulseCadence.Quarterly },
];

const SurveySetUp = ({ next }: Props) => {
  const state = usePulseSettingsState();

  const { data, loading } = useGetUserWithOrgsQuery({
    variables: {
      getMembers: true,
    },
  });
  const organization = _.first(data?.viewer?.orgs);
  const totalMembersCount = organization?.members?.length || 0;
  const areAllMembersSelected = getUniqUserCount(state.audience) === totalMembersCount;
  // this also means "everyone"...
  const areNoMembersSelected = getUniqUserCount(state.audience) === 0;

  const initialValues = useMemo(
    () => ({
      // we infer if audience type is all from the selected audience count.
      // if count === total we can just show "all" but if not we
      // only have the data to render the selected segment as a raw user
      // list from the API.
      audienceType: areAllMembersSelected || areNoMembersSelected ? 'all' : 'segment',
      // however, as long as the user is on this page, their audience
      // will still be stored in state so we can use that to render
      // tags and teams selections faithfully.
      audience: state.audience,
      startDate: state.startDate,
      cadence: state.cadence,
    }),
    [areAllMembersSelected, state.audience, state.startDate, state.cadence],
  );

  if (loading) return <Loading />;

  const stepperProps = getStepperProps({ step: 0, enabled: !!state.pulseId });

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(values) => {
        state.setSetupInfo({
          audience:
            values.audienceType === 'all'
              ? {
                  users: organization.members as User[],
                  teams: [],
                  tags: [],
                }
              : values.audience,
          startDate: values.startDate,
          cadence: values.cadence,
        });
        next();
      }}
      validate={(values) => {
        if (
          values.audienceType === 'segment' &&
          getUniqUserCount(values.audience) < MIN_USER_LIMIT
        ) {
          return {
            audience: strings.youNeed,
          };
        }
      }}
      validateOnMount
    >
      {({ values, setFieldValue, isSubmitting, isValid }) => (
        <Form>
          <MoralePulseStepWrapper noSettingsTopper>
            <SettingsTopperRoot>
              <SettingsTopperContent>
                <SettingsTopperTitle>{strings.pulseSettings}</SettingsTopperTitle>
                <SettingsTopperActions>
                  <Button
                    loading={isSubmitting}
                    type='submit'
                    endIcon={<ArrowRight size={24} color='currentColor' />}
                    size='compact'
                    disabled={!isValid}
                  >
                    Next
                  </Button>
                  <SettingsTopperCloseButton />
                </SettingsTopperActions>
              </SettingsTopperContent>
            </SettingsTopperRoot>
            <Box css={styles.outerContainer}>
              <Box css={styles.content}>
                <StepperBlock {...stepperProps} />
                <Typography css={styles.title}>{strings.title}</Typography>
                <Box css={styles.subContainer}>
                  <Typography css={styles.subSection}>{strings.schedule}</Typography>
                  <Box css={styles.optionCardsContainer}>
                    <Box css={styles.optionContainer}>
                      <Typography css={styles.optionTitle}>
                        {strings.whenStart}
                      </Typography>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          css={styles.datePicker}
                          disableToolbar
                          inputVariant='outlined'
                          variant='inline'
                          format='EEEE, LLLL do, yyyy'
                          // if survey was already sent, this can't be changed
                          // and also shouldn't be validated
                          disabled={state.surveyHasBeenSent}
                          disablePast={!state.surveyHasBeenSent}
                          autoOk={true}
                          value={values.startDate}
                          onChange={(d) => setFieldValue('startDate', d)}
                          InputProps={{
                            startAdornment: (
                              <CalendarDate
                                size={18}
                                color='#3B3B3B'
                                css={styles.calendarIcon}
                              />
                            ),
                            endAdornment: <ChevronDown color='#3B3B3B' />,
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Box>
                    <Box css={styles.optionContainer}>
                      <Typography css={styles.optionTitle}>
                        {strings.setSchedule}
                      </Typography>
                      <FormControl>
                        <Select
                          value={values.cadence}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            mixpanel.track('pls-frequency', { value: e.target.value });
                            setFieldValue(
                              'cadence',
                              e.target.value as MoralePulseCadence,
                            );
                          }}
                          input={<OutlinedInput />}
                          IconComponent={() => (
                            <ChevronDown css={styles.selectChevron} color='#3B3B3B' />
                          )}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                          css={styles.muiSelect}
                        >
                          <MenuItem css={styles.placeHolder} disabled value=''>
                            {strings.selectOne}
                          </MenuItem>
                          {scheduleOptions.map((option) => (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                              css={styles.menuItem}
                            >
                              {option.name}
                              {option.recommended && (
                                <Box css={styles.recommendedPill}>Recommended</Box>
                              )}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                  <InlineBanner
                    type='tip'
                    headline={strings.fyi}
                    description={<span>{strings.tip}</span>}
                    icon={
                      <ClockRefresh
                        size={18}
                        color='#3B3B3B'
                        style={{ marginTop: theme.spacing(1) }}
                      />
                    }
                    action={
                      <TextLink
                        to='https://help.glue.co/knowledge/setting-up-pulse-surveys'
                        newTab
                      >
                        {strings.tipLearnMore}
                      </TextLink>
                    }
                  />
                </Box>
                <Box css={styles.subContainer}>
                  <Typography css={styles.subSection}>{strings.audience}</Typography>
                  <Typography css={styles.optionTitle}>{strings.whoShould}</Typography>
                  <RadioGroup
                    name='audience-radio-group'
                    css={styles.optionCardsContainer}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      mixpanel.track('pls-audience', {
                        value: e.target.value as 'all' | 'segment',
                      });
                      setFieldValue('audienceType', e.target.value as 'all' | 'segment');
                    }}
                    value={values.audienceType}
                  >
                    <RadioButtonCard
                      css={styles.radioButtonCard}
                      value='all'
                      label={strings.everyone}
                      subText={`${totalMembersCount} members`}
                    />
                    <RadioButtonCard
                      css={styles.radioButtonCard}
                      value='segment'
                      label={strings.segment}
                      subText={strings.define}
                    />
                  </RadioGroup>
                </Box>
                {values.audienceType === 'segment' && (
                  <Box css={styles.subContainer}>
                    <Typography css={styles.subSection}>{strings.build}</Typography>
                    <AudienceBuilder
                      organizationId={organization.id}
                      audience={values.audience}
                      setAudience={(audience) => setFieldValue('audience', audience)}
                      minUsersRequired={MIN_USER_LIMIT}
                      maxListSize={MAX_LIST_SIZE}
                      validateOnMount
                    />
                  </Box>
                )}
              </Box>
              <PulseHelpBanner />
            </Box>
          </MoralePulseStepWrapper>
        </Form>
      )}
    </Formik>
  );
};

const styles = {
  outerContainer: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    padding: '2rem',
    maxWidth: '60rem',
  }),
  content: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8),
    flex: 1,
  }),
  optionContainer: css({
    marginTop: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flex: '1 1 0',
  }),
  menuItem: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: '#3B3B3B',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '140%',
  }),
  muiSelect: css({
    marginTop: theme.spacing(2),
    '& .MuiOutlinedInput-input': {
      display: 'flex',
      fontWeight: 500,
      fontSize: '16px',
      justifyContent: 'space-between',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.Glue_Ink00,
      borderWidth: 1.5,
    },
    color: '#3B3B3B',
  }),
  recommendedPill: css({
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '15px',
    padding: '2px 8px',
    color: '#2E6129',
    background: '#E8F8E7',
    borderRadius: '16px',
  }),
  subContainer: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  }),
  calendarIcon: css({
    marginRight: theme.spacing(2),
    marginBottom: '1px',
  }),
  placeHolder: css({
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.Glue_Ink10,
    '&.Mui-disabled': {
      opacity: 1,
    },
  }),
  selectChevron: css({
    marginRight: theme.spacing(3),
  }),
  datePicker: css({
    marginTop: theme.spacing(2),
    '& .MuiInputBase-root': {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '140%',
      color: '#3B3B3B',
    },
    '& > .Mui-disabled.MuiOutlinedInput-root': {
      backgroundColor: colors.Glue_LavenderLight,
      color: colors.Glue_Ink10,
    },
  }),
  subSection: css({
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '120%',
    letterSpacing: '0.1em',
    color: '#828282',
  }),
  optionCardsContainer: css({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(4),
  }),
  optionTitle: css({
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '28px',
  }),
  title: css({
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: '120%',
  }),
  radioButtonCard: css({
    flex: '1 1 0',
  }),
  selectedContainer: css({
    display: 'flex',
    flexDirection: 'row',
    margin: `${theme.spacing(1)} 0`,
    gap: theme.spacing(4),
  }),
  selected: css({
    flex: 1,
  }),
  numGuests: css({
    display: 'flex',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#3B3B3B',
  }),
};

export default SurveySetUp;
