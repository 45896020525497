import { Box, Modal, ModalProps } from '@material-ui/core';
import { useResponsive } from 'ahooks';
import Button from 'glue/components/buttons/Button';
import theme from 'theme';

interface Props extends ModalProps {
  onCancel(): void;
  onConfirm(): void;
  ctaLabel: string;
}

const MysteryModal = ({ children, open, onCancel, onConfirm, ctaLabel }: Props) => {
  const { md } = useResponsive();
  return (
    <Modal open={open}>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Box
          style={{
            backgroundColor: 'white',
            borderRadius: theme.spacing(2),
            padding: theme.spacing(6),
            width: md ? undefined : '100%',
          }}
        >
          {children}
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: theme.spacing(12),
            }}
          >
            <Box>
              <Button onClick={onCancel} color='white'>
                Cancel
              </Button>
            </Box>
            <Box>
              <Button onClick={onConfirm} color='purple'>
                {ctaLabel}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default MysteryModal;
