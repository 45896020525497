import cx from 'classnames';
import { ButtonWithLoader } from 'components/core';
import theme, { primaryGradient } from 'theme';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  button: {
    marginRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    textDecoration: 'none',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    borderRadius: theme.spacing(40),
  },
  buttonLoading: {
    width: theme.spacing(40),
    backgroundImage: primaryGradient,
    color: 'white',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    borderRadius: theme.spacing(40),
  },
  nextButton: {
    backgroundImage: primaryGradient,
    color: 'white',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    borderRadius: theme.spacing(40),
  },
});

const NextButton = ({
  disabled,
  loading,
  onClick,
  label,
  width,
}: {
  disabled: boolean;
  loading: boolean;
  onClick: (...args: any) => void | Promise<void>;
  label?: string;
  width?: string;
}) => {
  const classes = useStyles();

  return (
    <ButtonWithLoader
      loading={loading}
      onClick={onClick}
      disabled={disabled}
      variant='contained'
      style={{ width }}
      className={
        loading
          ? classes.buttonLoading
          : !disabled
          ? cx([classes.button, classes.nextButton])
          : classes.button
      }
    >
      {label || 'Next Step'}
    </ButtonWithLoader>
  );
};

export default NextButton;
