import { gql } from '@apollo/client';
import { css } from '@emotion/react';
import TrendDown02 from '@mysteryco/design/icons/TrendDown02';
import TrendUp02 from '@mysteryco/design/icons/TrendUp02';
import { colors } from '@mysteryco/design/src';
import * as Separator from '@radix-ui/react-separator';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { curveLinear } from '@visx/curve';
import { localPoint } from '@visx/event';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { MarkerCircle } from '@visx/marker';
import { ParentSize, ScaleSVG } from '@visx/responsive';
import { scaleLinear, scaleTime } from '@visx/scale';
import { LinePath } from '@visx/shape';
import { TooltipWithBounds, useTooltip } from '@visx/tooltip';
import { Loading } from 'components/core';
import { NumberValue } from 'd3';
import { bisector, extent } from 'd3-array';
import { timeFormat } from 'd3-time-format';
import { Audience, getUniqUsers } from 'glue/components/inputs/audience';
import theme from 'theme';
import { TimeframeFilterTimeframeFragment, useDeepDiveLineChartQuery } from 'types';

interface DateValue {
  date: Date;
  value: number;
}
const NUM_TICKS = 1;
// annoying that visx doesn't accept this directly...
const formatDateRaw = timeFormat('%b %d');
const formatDate = (date: Date | NumberValue) =>
  date instanceof Date ? formatDateRaw(date) : formatDateRaw(new Date(date.valueOf()));
const getX = (d: DateValue) => d.date;
const getY = (d: DateValue) => d.value;

const bisectDate = bisector<
  {
    date: Date;
    value: number;
  },
  Date
>((d) => new Date(d.date)).left;

const width = 800;
const height = 350;
const chartMargins = { top: 40, right: 30, bottom: 65, left: 70 };

interface DeepDiveLineChartProps {
  audience: Audience;
  timeframe: TimeframeFilterTimeframeFragment;
  questionId: string;
  highestPossibleValue: number;
}

const DeepDiveLineChart = ({
  audience,
  timeframe,
  questionId,
  highestPossibleValue,
}: DeepDiveLineChartProps) => {
  const { data, loading } = useDeepDiveLineChartQuery({
    variables: {
      questionId,
      timeframe: {
        startTime: timeframe.startDate,
        endTime: timeframe.endDate,
      },
      audienceIds: getUniqUsers(audience).map((user) => user.id),
    },
  });

  const {
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
    showTooltip,
    hideTooltip,
  } = useTooltip();

  const maxYValue = highestPossibleValue;

  const timeseriesValues =
    data?.moralePulseQuestionSummary?.timeseriesValuesConnection?.timeseriesValues?.map(
      (seriesValue) => {
        const endDate = new Date(seriesValue.endDate);
        const startDate = new Date(seriesValue.startDate);
        return {
          ...seriesValue,
          endDate,
          startDate,
          date: new Date((endDate.getTime() + startDate.getTime()) / 2),
          value: seriesValue.scoreAverage,
        };
      },
    ) ?? [];

  if (loading) return <Loading />;

  const lineData = timeseriesValues.sort((a, b) => a.date.getTime() - b.date.getTime());
  const xScale = scaleTime<number>({
    domain: extent([new Date(timeframe.startDate), new Date(timeframe.endDate)]) as [
      Date,
      Date,
    ],
  });
  const yScale = scaleLinear<number>({
    domain: [0, maxYValue],
  });

  const dateScale = scaleTime({
    range: [chartMargins.left, width + chartMargins.left],
    domain: extent(timeseriesValues, getX) as [Date, Date],
  });

  const scoreValueScale = scaleLinear({
    range: [height + chartMargins.top, chartMargins.top],
    domain: [0, maxYValue],
    nice: true,
  });

  const handleMouseOver = (event) => {
    const { x } = localPoint(event) || { x: 0 };
    const x0 = dateScale.invert(x);
    const index = bisectDate(timeseriesValues, x0, 1);
    const d0 = timeseriesValues[index - 1];
    const d1 = timeseriesValues[index];
    let prev;
    let d = d0;
    if (
      d1 &&
      getX(d1) &&
      x0.valueOf() - getX(d0).valueOf() > getX(d1).valueOf() - x0.valueOf()
    ) {
      d = d1;
      prev = d0;
    }
    showTooltip({
      tooltipLeft: x,
      tooltipTop: scoreValueScale(getY(d)),
      tooltipData: { ...d, prevDataPoint: prev },
    });
  };

  return (
    <div css={styles.chartContainer}>
      <ParentSize css={styles.innerChartDiv}>
        {(parent) => {
          const xMax = parent.width - chartMargins.left - chartMargins.right;
          const yMax = parent.height - chartMargins.top - chartMargins.bottom;

          xScale.range([0, xMax]);
          yScale.range([yMax, 0]);

          return (
            <ScaleSVG width={parent.width} height={parent.height}>
              <MarkerCircle id='marker-circle' fill='#706A74' size={2} refX={2.5} />
              <rect
                width={parent.width}
                x={0}
                y={0}
                height={parent.height}
                fill={colors.Glue_Paper}
                stroke='#E4E2EE'
                rx={14}
                ry={14}
              />
              <Group
                key={`lines-chart`}
                left={chartMargins.left}
                top={chartMargins.top}
                css={styles.lineChart}
              >
                <AxisBottom
                  top={yMax}
                  scale={xScale}
                  numTicks={NUM_TICKS}
                  tickFormat={formatDate}
                  hideTicks
                  axisClassName='xAxis'
                />
                <AxisLeft
                  scale={yScale}
                  numTicks={5}
                  hideAxisLine
                  hideTicks
                  label='Question score'
                  labelClassName='yLabel'
                  axisClassName='yAxis'
                />
                <GridRows
                  scale={yScale}
                  numTicks={5}
                  width={xMax}
                  height={yMax}
                  stroke='#E9EBF1'
                />
                <LinePath<DateValue>
                  curve={curveLinear}
                  data={lineData}
                  x={(d) => xScale(getX(d)) ?? 0}
                  y={(d) => yScale(getY(d)) ?? 0}
                  stroke='#706A74'
                  strokeWidth={4}
                  shapeRendering='geometricPrecision'
                  markerMid='url(#marker-circle)'
                  markerStart='url(#marker-circle)'
                  markerEnd='url(#marker-circle)'
                  onMouseOver={handleMouseOver}
                  onMouseLeave={() => hideTooltip()}
                />
              </Group>
            </ScaleSVG>
          );
        }}
      </ParentSize>
      {!!tooltipData && (
        <TooltipWithBounds
          left={tooltipLeft}
          top={tooltipTop - 50}
          css={styles.tooltipRoot}
        >
          <ChartTooltip {...tooltipData} />
        </TooltipWithBounds>
      )}
    </div>
  );
};

DeepDiveLineChart.query = gql`
  query DeepDiveLineChart(
    $questionId: ID!
    $timeframe: DateWindowInput
    $audienceIds: [ID!]!
  ) {
    moralePulseQuestionSummary(questionId: $questionId) {
      id
      timeseriesValuesConnection(timeframe: $timeframe, audienceIds: $audienceIds) {
        timeseriesValues {
          startDate
          endDate
          scoreAverage
          trend
          prevSurveyEndDate
          prevSurveyScoreAverage
          respondentsCount
        }
      }
    }
  }
`;

const ChartTooltip = (props) => {
  const {
    value,
    trend,
    prevDataPoint,
    prevSurveyScoreAverage,
    respondentsCount,
    startDate,
    endDate,
  } = props;

  const startDateStr = formatDate(startDate);
  const endDateStr = endDate ? ` - ${formatDate(endDate)}` : '';

  return (
    <div css={styles.tooltipContainer}>
      <div css={styles.tooltipTitle}>{startDateStr + endDateStr}</div>
      <div css={styles.tooltipScoreContainer}>
        <Separator.Root orientation='vertical' css={styles.verticalSeperator} />
        <div css={styles.tooltipScoreSection}>
          <div>Question score</div>
          <div css={styles.tooltipScoreLine}>
            <div>{value.toFixed(2)}</div>
            {prevDataPoint && (
              <div
                css={[
                  styles.changePill,
                  trend > 0 && styles.changePillUp,
                  trend < 0 && styles.changePillDown,
                ]}
              >
                {trend > 0 && <TrendUp02 size={18} />}
                {trend < 0 && <TrendDown02 size={18} />}
                {trend > 0 && <span>+</span>}
                {Math.round(trend * 100)}%
              </div>
            )}
          </div>
          <div css={styles.tooltipRespondents}>{`${respondentsCount} respondents`}</div>
        </div>
      </div>
      {prevDataPoint && (
        <div css={styles.tooltipPrevContainer}>
          <div>{`Prior: ${formatDate(prevDataPoint.startDate)} - ${formatDate(
            prevDataPoint.endDate,
          )}`}</div>
          <div css={styles.tooltipScoreContainer}>
            <Separator.Root orientation='vertical' css={styles.verticalSeperator} />
            <div css={styles.tooltipScoreSection}>
              <div>Survey for {formatDate(prevDataPoint.date)}</div>
              <div css={styles.tooltipScoreLine}>
                <div>{prevSurveyScoreAverage.toFixed(2)}</div>
              </div>
              <div
                css={styles.tooltipRespondents}
              >{`${prevDataPoint.respondentsCount} respondents`}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  chartContainer: css({
    // needed for correct tooltip placement
    position: 'relative',
    lineHeight: 0,
    minHeight: '300px',
    maxWidth: '60rem',
  }),
  innerChartDiv: css({
    '& div': {
      height: '100%',
      minHeight: '300px',
    },
  }),
  lineChart: css({
    '.yLabel': {
      fontWeight: 700,
      fontSize: 12,
      lineHeight: 1,
      color: colors.Glue_Ink10, // doesn't seem to apply
    },
    '.xAxis text': {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 1,
      color: colors.Glue_Ink00,
    },
    '.yAxis text': {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 1,
      color: colors.Glue_Ink00,
    },
  }),
  tooltipRoot: css({
    border: '1.5px solid #E4E2EE',
    borderRadius: '20px',
  }),
  tooltipTitle: css({
    fontWeight: 800,
    fontSize: 12,
    lineHeight: 1,
  }),
  tooltipContainer: css({
    display: 'flex',
    flexDirection: 'column',
    color: '#3B3B3B',
    gap: theme.spacing(3),
    margin: theme.spacing(2),
    fontWeight: 700,
    fontSize: '11px',
    lineHeight: '120%',
  }),
  tooltipScoreContainer: css({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  }),
  tooltipScoreSection: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  }),
  tooltipScoreLine: css({
    display: 'flex',
    gap: theme.spacing(8),
    alignItems: 'center',
    fontSize: '14px',
  }),
  tooltipRespondents: css({
    fontWeight: 500,
    lineHeight: '140%',
  }),
  tooltipPrevContainer: css({
    display: 'flex',
    flexDirection: 'column',
    color: '#706A74',
    gap: theme.spacing(2),
  }),
  changePill: css({
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderRadius: '16px',
    display: 'flex',
    alignItems: 'center',
  }),
  changePillUp: css({
    color: '#3C8452',
    background: colors.Mint_50,
  }),
  changePillDown: css({
    color: '#882121',
    background: '#FFE3E3',
  }),
  verticalSeperator: css({
    height: '5rem',
    width: '2px',
    backgroundColor: 'currentColor',
  }),
};

export default DeepDiveLineChart;
