// @ts-strict-ignore
import { Box, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { colors } from '@mysteryco/design';
import ArrowRight from '@mysteryco/design/icons/ArrowRight';
import FlatButton from 'components/core/FlatButton';
import Modal from 'components/core/Modal';
import EmptyLoadingSpinner from 'components/EmptyLoadingSpinner';
import EditTeam, { isValidTeam } from 'components/Forms/EditTeam';
import { useState } from 'react';
import { TeamPageOrganization } from 'scenes/Team';
import theme from 'theme';
import { Team, useEditTeamNameMutation } from 'types';
import { isUsingDefaultTeamName } from 'utils/teamUtil';

interface EditTeamNameProps {
  team: Team;
  setTeam: (team: any) => void;
  organization: TeamPageOrganization;
  isShowing: boolean;
  setIsShowing(isShowing: boolean): void;
}

const EditTeamName = ({
  team,
  setTeam,
  organization,
  isShowing,
  setIsShowing,
}: EditTeamNameProps) => {
  const classes = useStyles();
  const [submitting, setSubmitting] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [renameTeam] = useEditTeamNameMutation();

  const orgTeams = (organization?.teams || []) as Team[];

  const error = !(team && isValidTeam(team, orgTeams));

  const isDefault = isUsingDefaultTeamName(team);

  const snackbarAlert = (message: string) => {
    setAlertMessage(message);
    setShowSnackbar(true);
  };

  const handleClose = () => {
    setIsShowing(false);
    setTeam(null);
  };

  const handleSave = async () => {
    setSubmitting(true);
    await renameTeam({
      variables: { id: team.id, name: team.name },
      refetchQueries: ['searchOrgUsers'],
    });
    snackbarAlert('Changes have been made');
    handleClose();
    setSubmitting(false);
  };

  const handleChange = (newValue: Team) => {
    setTeam(newValue);
  };

  return (
    <>
      {team && (
        <Modal open={isShowing} onClose={handleClose} title='Team name'>
          <form onSubmit={handleSave} className={classes.content}>
            <Box className={classes.form}>
              <label htmlFor='teamName' className={classes.label}>{`${
                isDefault ? 'Add a' : 'Edit the'
              } team name`}</label>
              <EditTeam
                team={team}
                teamOptions={orgTeams}
                onChange={(_, newValue) => handleChange(newValue)}
                disableLabels
              />
            </Box>
            <Box className={classes.actions}>
              {!submitting ? (
                <FlatButton type='submit' disabled={error} fullWidth>
                  Apply changes
                  <ArrowRight size={16} />
                </FlatButton>
              ) : (
                <FlatButton disabled fullWidth>
                  <EmptyLoadingSpinner size={16} color='#828282' /> Saving
                </FlatButton>
              )}
            </Box>
          </form>
        </Modal>
      )}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity='success'>{alertMessage}</Alert>
      </Snackbar>
    </>
  );
};

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(4),
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    gap: theme.spacing(2),
  },
  label: {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.5,
    color: colors.Black,
    '&.Mui-focused': {
      color: colors.Black,
    },
  },
});

export default EditTeamName;
