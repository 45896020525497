import { Sizes } from 'constants/index';
import { DEFAULT_SSF_ROUTE } from 'Routes';

import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

import PeoplePuzzle from 'components/illustrations/PeoplePuzzle';
import { hasOrg, isAdminOrCoordinator } from 'utils/security';
import { useViewer } from 'utils/state';
import { colors } from '@mysteryco/design';

const useStyles = makeStyles({
  emptyState: {
    background: colors.Glue_LavenderLight,
    backgroundSize: 'cover',
    backgroundBlendMode: 'multiply',
    flexDirection: 'column',
    borderRadius: theme.spacing(2),
    display: 'flex',
    padding: `${theme.spacing(16)} ${theme.spacing(6)}`,
    width: '100%',
    maxWidth: '1200px',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  topCopy: {
    color: theme.palette.text.primary,
  },
  bottomCopy: {
    marginTop: theme.spacing(4),
    fontSize: '1rem',
    color: theme.palette.text.secondary,
  },
  whiteText: {
    color: theme.palette.common.white,
  },
  button: {
    borderRadius: theme.spacing(20),
    background: theme.palette.primary.main,
    '& p': {
      color: theme.palette.common.white,
    },
    '&:hover': {
      background: theme.palette.text.secondary,
    },
  },
});

const NoEventsState = ({
  firstLine,
  secondLine,
  buttonProps = {
    text: 'Book Now',
    onClick: () => window.open(DEFAULT_SSF_ROUTE, '_blank'),
  },
}: {
  firstLine: string;
  secondLine?: string;
  buttonProps?: { text: string; onClick: () => void };
}) => {
  const classes = useStyles();
  const viewer = useViewer();
  const showBookButton = viewer && hasOrg(viewer) ? isAdminOrCoordinator(viewer) : true;

  return (
    <Box className={classes.emptyState}>
      <Box alignItems='center' display='flex' flexDirection='column'>
        <Typography className={classes.topCopy} align='center' variant='h4'>
          {firstLine}
        </Typography>
        <Typography className={classes.bottomCopy} align='center' variant='h4'>
          {secondLine}
        </Typography>
      </Box>
      <Box my={4} />
      {showBookButton && (
        <Button
          onClick={buttonProps.onClick}
          disableElevation={true}
          variant='contained'
          color='primary'
          size='large'
          css={{
            borderRadius: Sizes.BUTTON_BORDER_RADIUS,
          }}
        >
          <Typography className={classes.whiteText}>
            <Box mx={6} my={2} fontWeight='fontWeightBold'>
              {buttonProps.text}
            </Box>
          </Typography>
        </Button>
      )}
      <br />
      <br />
      <PeoplePuzzle />
    </Box>
  );
};

export default NoEventsState;
