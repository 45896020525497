import { gql } from '@apollo/client';
import { Loading } from 'components/core';
import { recursivelyRemoveTypenames } from 'lib/helpers/apollo';
import React, { useContext } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import {
  TaskFlow,
  TaskFlowAssignee,
  TaskFlowAssigneeInput,
  TaskFlowStatus,
  TaskFlowSubType,
  useTaskFlowWizardAdvanceMutation,
  useTaskFlowWizardQuery,
  useTaskFlowWizardRewindMutation,
  useTaskFlowWizardUpdateMutation,
} from 'types';

const TaskFlowActionContext = React.createContext({
  next: (assignee?: TaskFlowAssigneeInput) => {},
  previous: (assignee?: TaskFlowAssigneeInput) => {},
  update: ({
    subFlow,
    assignee,
    status,
  }: {
    subFlow: TaskFlowSubType;
    assignee?: TaskFlowAssigneeInput;
    status?: TaskFlowStatus;
  }) => {},
  refetch: () => {},
});

// Custom hook for changing state or refetching inside a TaskFlowWizard. Can be used to create custom buttons.
export const useTaskFlowActions = () => useContext(TaskFlowActionContext);

type ContinueProps = {
  assignee?: TaskFlowAssigneeInput;
  onClick?: never;
  [key: string]: any;
};
export const ContinueButton = (props: ContinueProps) => {
  const { assignee } = props;
  const { next } = useContext(TaskFlowActionContext);
  const handleNext = () => next(assignee);
  // Common styling can be done here
  return <button {...props} onClick={handleNext} />;
};

type BackProps = {
  assignee?: TaskFlowAssigneeInput;
  onClick?: never;
  [key: string]: any;
};
export const BackButton = (props: BackProps) => {
  const { assignee } = props;
  const { previous } = useContext(TaskFlowActionContext);
  const handlePrevious = () => previous(assignee);
  // Common styling can be done here
  return <button {...props} onClick={handlePrevious} />;
};

type SelectSubflowButtonProps = {
  subflow: TaskFlowSubType;
  assignee?: TaskFlowAssigneeInput;
  status?: TaskFlowStatus;
  onClick?: never;
  [key: string]: any;
};
export const SelectSubflowButton = (props: SelectSubflowButtonProps) => {
  const { subflow, assignee, status } = props;
  const { update } = useContext(TaskFlowActionContext);
  const handleSubflowSelection = () => update({ subFlow: subflow, assignee, status });
  // Common styling can be done here
  return <button {...props} onClick={handleSubflowSelection} />;
};

const DefaultErrorComponent = () => (
  <div>
    <div>We're sorry, something seem to have gone wrong</div>
    <Link to='/home'>Back to home </Link>
  </div>
);

export type StatusComponentProps = {
  taskFlow: TaskFlow;
};

export type TaskFlowComponent = React.ComponentType<Required<StatusComponentProps>>;

type TaskFlowProps = {
  taskFlowId: string;
  /**
   * A map of statuses that you have views for, to the component that should be displayed for that status.
   */
  statusComponents: {
    [key in TaskFlowStatus]?: React.ComponentType<StatusComponentProps>;
  };
  /**
   * Component that will be displayed to select a subflow. If not provided it will look directly at the status map for a view.
   * If you aren't providing a subFlow component, make sure to implement a view fo the NotStarted status.
   */
  subFlowComponent?: React.ComponentType<StatusComponentProps>;
  /**
   * The final status of the task flow that will have a user view. Defaults to TaskFlowStatus.Completed
   */
  finalStatus?: TaskFlowStatus;
  /**
   * This method is called on hitting the continue button (or next function) when a task is in its final status.
   * If not provided, the component will redirect to the home page
   * @param taskFlow Fully hydrated task flow
   * @returns
   */
  onFlowComplete?: (taskFlow: TaskFlow) => void;
};

/**
 * This component will take care of showing the correct view and doing most of the work for you.
 * All you need to do is provide components for each of the statuses, as well as a subflow selector (if applicable)
 * If you use the continue button provided, it will advance the task and re-fetch through GraphQL
 */
export const TaskFlowWizard = (props: TaskFlowProps) => {
  const {
    taskFlowId,
    subFlowComponent,
    statusComponents: stepComponents = { [TaskFlowStatus.NotStarted]: <Loading /> },
    finalStatus = TaskFlowStatus.Completed,
    onFlowComplete,
  } = props;
  const { loading, data, error, refetch } = useTaskFlowWizardQuery({
    variables: { id: taskFlowId },
  });
  const [advanceTaskFlow] = useTaskFlowWizardAdvanceMutation();
  const [rewindTaskFlow] = useTaskFlowWizardRewindMutation();
  const [updateTaskFlow] = useTaskFlowWizardUpdateMutation();
  const history = useHistory();

  if (loading || !data || !data.taskFlow) return <Loading />;
  const taskFlowRaw = data?.taskFlow;
  const taskFlow: TaskFlow = recursivelyRemoveTypenames(taskFlowRaw);

  const advance = async (taskId: string, assignee: TaskFlowAssigneeInput) => {
    return await advanceTaskFlow({
      variables: { id: taskId, assignee },
      refetchQueries: [TaskFlowWizard.query],
    });
  };

  const rewind = async (taskId: string, assignee: TaskFlowAssigneeInput) => {
    return await rewindTaskFlow({
      variables: { id: taskId, assignee },
      refetchQueries: [TaskFlowWizard.query],
    });
  };

  // Next it called from hitting the continue button
  const handleNext = async (newAssignee?: TaskFlowAssignee) => {
    if (taskFlow.status === finalStatus && onFlowComplete) {
      return await onFlowComplete(taskFlow);
    } else if (taskFlow.status === finalStatus && !onFlowComplete) {
      // If we are at the end, and no behavior is defined, just redirect to home
      return history.push('/home');
    } else {
      return await advance(taskFlow.id, newAssignee || taskFlow.assignee);
    }
  };

  // Previous is called from hitting the back button
  const handlePrevious = async (newAssignee?: TaskFlowAssignee) => {
    await rewind(taskFlow.id, newAssignee || taskFlow.assignee);
  };

  const handleUpdate = async ({
    subFlow: newSubFlow,
    assignee: newAssignee,
    status: newStatus,
  }: {
    subFlow: TaskFlowSubType;
    assignee?: TaskFlowAssigneeInput;
    status?: TaskFlowStatus;
  }) => {
    return await updateTaskFlow({
      variables: {
        id: taskFlow.id,
        updates: { subFlow: newSubFlow, assignee: newAssignee, status: newStatus },
      },
      refetchQueries: [TaskFlowWizard.query],
    });
  };

  const shouldRenderSubflowSelector =
    taskFlow.subFlow === TaskFlowSubType.None && !!subFlowComponent;

  const statusComponent: React.ComponentType<StatusComponentProps> = stepComponents
    ? stepComponents[taskFlow.status]
    : undefined;

  const componentToRender: TaskFlowComponent = shouldRenderSubflowSelector
    ? subFlowComponent
    : statusComponent;

  const errorComponent = stepComponents[TaskFlowStatus.Errored] || DefaultErrorComponent;

  if (!componentToRender || error) {
    return React.createElement(errorComponent, { taskFlow });
  }

  return (
    <TaskFlowActionContext.Provider
      value={{
        next: handleNext,
        update: handleUpdate,
        previous: handlePrevious,
        refetch,
      }}
    >
      <ErrorBoundary FallbackComponent={errorComponent}>
        {React.createElement(componentToRender, { taskFlow })}
      </ErrorBoundary>
    </TaskFlowActionContext.Provider>
  );
};

TaskFlowWizard.query = gql`
  query TaskFlowWizard($id: ID!) {
    taskFlow(id: $id) {
      id
      flow
      subFlow
      priority
      status
      owner {
        type
        token
      }
      scope {
        type
        token
      }
      assignee {
        type
        token
      }
      createdAt
      updatedAt
    }
  }
`;

TaskFlowWizard.advanceMutation = gql`
  mutation TaskFlowWizardAdvance($id: ID!, $assignee: TaskFlowAssigneeInput) {
    advanceTaskFlow(id: $id, assignee: $assignee) {
      id
    }
  }
`;

TaskFlowWizard.rewindMutation = gql`
  mutation TaskFlowWizardRewind($id: ID!, $assignee: TaskFlowAssigneeInput) {
    rewindTaskFlow(id: $id, assignee: $assignee) {
      id
    }
  }
`;

TaskFlowWizard.updateMutation = gql`
  mutation TaskFlowWizardUpdate($id: ID!, $updates: TaskFlowUpdate) {
    updateTaskFlow(id: $id, updates: $updates) {
      id
    }
  }
`;
