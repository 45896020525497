// @ts-strict-ignore
import theme from 'theme';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Modal from 'components/core/Modal';
import FlatButton from 'components/core/FlatButton';
import { colors } from '@mysteryco/design';
import { pluralize } from 'humanize-plus';
import { UserTag } from 'types';
import AlertBar from 'components/AlertBar';
import AlertHexagon from '@mysteryco/design/icons/AlertHexagon';

const DeleteTagConfirmation = ({
  tag,
  isShowing = false,
  loading = false,
  onDelete = () => null,
  onCancel = () => null,
}: {
  tag: UserTag;
  isShowing: boolean;
  loading?: boolean;
  onDelete: (event: any) => Promise<void>;
  onCancel: (event: any) => void;
}) => {
  const classes = useStyles();

  return (
    <>
      {tag && (
        <Modal open={isShowing} onClose={onCancel} title='Delete tag'>
          <Box className={classes.content}>
            Tags help you easy manage members for faster event invites. Are you sure you
            want to delete this tag?
            <AlertBar
              icon={<AlertHexagon size={20} />}
              severity='error'
              text={`${tag.name} tag will be removed from ${pluralize(
                tag.users?.length,
                `${tag.users?.length} user`,
              )}`}
            />
            <Box className={classes.actions}>
              <FlatButton
                variant='secondary'
                fullWidth
                onClick={onCancel}
                disabled={loading}
              >
                Cancel
              </FlatButton>
              <FlatButton type='submit' fullWidth onClick={onDelete}>
                Delete tag
              </FlatButton>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: theme.spacing(4),
    color: colors.DarkGray,
    lineHeight: 1.5
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: '100%',
  },
});

export default DeleteTagConfirmation;
