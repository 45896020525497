import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Dusk, Purple100, White } from '@mysteryco/design/src/tokens/colors';

const DetailSection = ({ label, children, ...otherProps }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container} {...otherProps}>
      <Typography className={classes.label}>{label}</Typography>
      <Box className={classes.content}>{children}</Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'block',
    width: '100%',
  },
  label: {
    fontSize: '.875rem',
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
    color: Dusk,
    letterSpacing: 1,
    marginBottom: theme.spacing(2),
  },
  content: {
    border: `1px solid ${Purple100}`,
    borderRadius: 4,
    background: White,
  },
}));

export default DetailSection;
