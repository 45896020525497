import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { UserTagType } from 'types';
import GroupDisplay from './GroupDisplay';
import TagAutoComplete from './TagAutoComplete';

const GroupManagement = ({ memberLabel, organization, userTags, onChange, onDelete }) => {
  const classes = useStyle();
  return (
    <>
      <Typography variant='h5' style={{ fontWeight: 500, lineHeight: '32px' }}>
        Groups
      </Typography>
      <Typography className={classes.memberLabel}>{memberLabel}</Typography>
      <TagAutoComplete
        value=''
        onChange={onChange}
        existingTags={organization.userTags.filter(
          (tag) => tag.type === UserTagType.Manual,
        )}
      />
      <GroupDisplay style={{ marginTop: '10px' }} onDelete={onDelete} tags={userTags} />
    </>
  );
};

const useStyle = makeStyles({
  memberLabel: {
    lineHeight: '22px',
    fontSize: '16px',
    fontWeight: 500,
    marginBottom: '4px',
  },
});

export default GroupManagement;
