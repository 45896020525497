import { gql } from '@apollo/client';
import { css } from '@emotion/react';
import { Box } from '@material-ui/core';
import { Skeleton } from 'components/Skeleton';
import { GLUE_BANNER_HEIGHT } from 'glue/components/branding/GlueBanner';
import { TeamFragment } from 'glue/components/insights/data/interface';
import {
  CenteredContentRoot,
  CenteredContentSection,
} from 'glue/components/structure/CenteredContent';
import PageTitle from 'glue/components/structure/PageTitle';
import NoOrganizationPlaceholder from 'glue/scenes/Home/components/NoOrganizationPlaceholder';
import { existsFilter } from 'lib/helpers/maybe';
import { useQueryParams } from 'lib/helpers/router';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router';
import { PageMetaData, Paths } from 'Routes';
import theme from 'theme';
import { HomeQuery, useHomeQuery, ViewerFragmentFragment } from 'types';
import { isAdmin, isAdminOrCoordinator, isInsightsCustomer } from 'utils/security';
import { AccountSetupFragment, userNeedsSetup, useViewer } from 'utils/state';
import ActivitiesCalendar from './ActivitiesCalendar';
import Header from './components/Header';
import UserProfile from './components/UserProfile';
import YourTasks from './components/YourTasks';
import HotSpots from './HotSpots';
import Onboarding, { isOnboardingCompleted, isOnboardingExpired } from './Onboarding';
import Recommendations from './Recommendations';
import TeamOverview from './TeamOverview';
import { isTransactional } from 'utils/customerTypeUtils';

interface Props {
  viewer: ViewerFragmentFragment | undefined;
}

type HomeViewer = Exclude<HomeQuery['viewer'], null | undefined>;
export type HomeOrganization = Exclude<
  HomeViewer['defaultOrganization'],
  null | undefined
>;

const TRENDING_INDIVIDUALS_TO_LOAD = 4;

const onboardingFlag = (params: URLSearchParams) => {
  return params?.get('onboarding')?.toLowerCase() === 'true';
};

const Home = ({ viewer: appLevelViewer }: Props) => {
  const params = useQueryParams();
  const userIsOrgAdmin = !!appLevelViewer && isAdmin(appLevelViewer);
  const userIsInsightsCustomer = !!appLevelViewer && isInsightsCustomer(appLevelViewer);
  const userIsTransactional = !!appLevelViewer && isTransactional(appLevelViewer.customerType)

  const { data, loading } = useHomeQuery({
    variables: {
      individualCount: TRENDING_INDIVIDUALS_TO_LOAD,
      loadInsightsProperties: userIsOrgAdmin && userIsInsightsCustomer,
    },
    fetchPolicy: 'network-only',
  });

  const viewer = data?.viewer;
  const organization = viewer?.defaultOrganization;

  const onboardingComplete = !!organization && isOnboardingCompleted(organization);
  const hideOnboardingFinishedMessage =
    !!organization && isOnboardingExpired(organization);
  const showOnboarding =
    (userIsInsightsCustomer && (!onboardingComplete || !hideOnboardingFinishedMessage)) ||
    onboardingFlag(params);

  const userHasOrganization = !!appLevelViewer?.orgs?.length;

  if (userNeedsSetup(viewer)) {
    return <Redirect to={Paths.ACCOUNT_SETUP} />;
  }

  return (
    <CenteredContentRoot css={styles.root}>
      <CenteredContentSection width='large'>
        <PageTitle pageName='home'>Home</PageTitle>
      </CenteredContentSection>
      <Helmet>
        <title>{PageMetaData?.HOME.title}</title>
      </Helmet>
      <CenteredContentSection width='large'>
        <Header viewer={viewer} isOnboarding={showOnboarding} />
      </CenteredContentSection>
      <CenteredContentSection width='large' css={styles.content}>
        <Box css={styles.leftCol}>
          {!viewer || loading ? (
            <HomeContentSkeleton />
          ) : userIsTransactional ? (
            <NoOrganizationPlaceholder 
              viewer={viewer} />
          ) : userHasOrganization ? (
            <HomeContentWithOrganization
              viewer={viewer}
              showOnboarding={showOnboarding}
              onboardingExpired={hideOnboardingFinishedMessage}
            />
          ) : (
            <HomeContentWithoutOrganization viewer={viewer} />
          )}
        </Box>
        <Box css={styles.rightCol}>
          {viewer && <UserProfile viewer={viewer} />}
          {viewer && <YourTasks viewer={viewer} />}
        </Box>
      </CenteredContentSection>
    </CenteredContentRoot>
  );
};

Home.query = gql`
  query Home(
    $version: String
    $individualCount: Int!
    $loadInsightsProperties: Boolean!
  ) {
    viewer {
      id
      defaultOrganization {
        id
        isHrisConnected
        manualIntegrationLastUpdated
        engagementGraphApproved
        isInsightsEnabled
        rootTeam {
          id
        }
        teams {
          id
          ...InsightsSidePanelTeam
        }
        trendingScores(individualCount: $individualCount)
          @include(if: $loadInsightsProperties) {
          entityId
          deltas {
            oneMonthDelta
            scoreType
          }
        }
        orgOnboardingCompleted {
          completedAt
          status
        }
      }
      ...YourTasksViewer
      ...ActivitiesCalendarViewer
      ...UserProfileViewer
      ...HeaderViewer
      ...OnboardingViewer
      ...NoOrganizationPlaceholderViewer
      ...AccountSetupViewer
    }
  }

  ${TeamOverview.fragments}
  ${ActivitiesCalendar.fragments}
  ${YourTasks.fragments}
  ${UserProfile.fragments}
  ${Header.fragments}
  ${Onboarding.fragments}
  ${NoOrganizationPlaceholder.fragments}
  ${AccountSetupFragment}
`;

const HomeContentWithOrganization = ({
  viewer,
  onboardingExpired,
  showOnboarding,
}: {
  viewer: Exclude<HomeViewer, null | undefined>;
  onboardingExpired: boolean;
  showOnboarding: boolean;
}) => {
  const isOrgAdmin = isAdmin(viewer);
  const isOrgCoordinator = isAdminOrCoordinator(viewer);
  const isInsightsOrg = isInsightsCustomer(viewer);
  const organization = viewer.defaultOrganization;
  const teams = organization?.teams?.filter(existsFilter) ?? [];
  const team = teams.find((t) => t.id === organization?.rootTeam?.id) as TeamFragment;
  const onboardingCompleted = !!organization && isOnboardingCompleted(organization);

  const onboardingSection =
    organization && showOnboarding ? (
      <Onboarding
        organization={organization}
        viewer={viewer}
        onboardingCompleted={onboardingCompleted}
        onboardingExpired={onboardingExpired}
      />
    ) : null;
  const insightsSection = isInsightsOrg ? (
    onboardingCompleted && (
      <>
        <TeamOverview team={team} orgId={organization?.id} />
        <HotSpots
          teams={teams}
          scores={organization?.trendingScores?.filter(existsFilter)}
        />
      </>
    )
  ) : (
    <NoOrganizationPlaceholder viewer={viewer} />
  );

  const activiesSection =
    organization && isOrgCoordinator ? (
      <>
        <Recommendations isAdmin={isOrgAdmin} />
        <ActivitiesCalendar viewer={viewer} />
      </>
    ) : (
      <ActivitiesCalendar viewer={viewer} />
    );

  if (isOrgAdmin) {
    return (
      <>
        {onboardingSection}
        {insightsSection}
        {activiesSection}
      </>
    );
  } else {
    return activiesSection;
  }
};

const HomeContentWithoutOrganization = ({ viewer }: { viewer: HomeViewer }) => {
  return (
    <>
      <NoOrganizationPlaceholder viewer={viewer} />
      <ActivitiesCalendar viewer={viewer} />
    </>
  );
};

const HomeContentSkeleton = () => {
  const appViewer = useViewer();
  const showAdminSections = appViewer && isAdmin(appViewer);
  const showInsightsSections = appViewer && isInsightsCustomer(appViewer);
  return showAdminSections ? (
    <>
      {showInsightsSections && <TeamOverview loading />}
      {showInsightsSections && <HotSpots loading />}
      <Recommendations />
    </>
  ) : (
    <Skeleton width={'100%'} />
  );
};

const TWO_COLUMN_MIN_WIDTH = 1440;
const TWO_COLUMN_BREAKPOINT = `@media (min-width: ${TWO_COLUMN_MIN_WIDTH}px)`;

const styles = {
  root: css({
    padding: theme.spacing(10),
  }),
  content: css({
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(1),
    gap: theme.spacing(20),
    flexDirection: 'column-reverse',
    maxWidth: 800,
    alignSelf: 'flex-start',
    alignItems: 'flex-start',

    [TWO_COLUMN_BREAKPOINT]: {
      flexDirection: 'row',
      maxWidth: 1200,
      alignSelf: 'initial',
    },
  }),
  leftCol: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    gap: 60,
    flexShrink: 3,
    flexGrow: 1,
    flexBasis: '400px',
    minWidth: '0',
  }),
  rightCol: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(6),
    flexShrink: 1,
    flexGrow: 0,
    position: 'relative',
    top: 0,

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },

    [TWO_COLUMN_BREAKPOINT]: {
      flexDirection: 'column',
      position: 'sticky',
      top: GLUE_BANNER_HEIGHT + 8,
      flexBasis: '280px',
    },
  }),
};

export default Home;
