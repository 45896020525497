import MeetupsSummary from 'glue/assets/artwork/meetups-summary.png';
import {
  Audience,
  AudienceBuilder,
  getUniqUserCount,
} from 'glue/components/inputs/audience';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { Paths } from 'Routes';
import { Statsig } from 'statsig-react';
import { ConnectionFocusArea, WatercoolerInitiativeByOrgQuery } from 'types';
import { ViewerProps } from 'types/common';
import { isAdmin } from 'utils/security';

import { gql } from '@apollo/client';
import { css } from '@emotion/react';

import ActiveMeetupSummary from './ActiveMeetupSummary';
import CenterSection from './CenterSection';
import DisableMeetups from './DisableMeetups';
import EmptyCohort from './EmptyCohort';
import MeetupsPage from './MeetupsPage';
import SeeInAction from './SeeInAction';
import SelectCohort from './SelectCohort';
import SidePanel from './SidePanel';
import MeetupsLoader, { MeetupsLoaderPassedProps } from './MeetupsLoader';
import theme from 'theme';
import MeetupsSummaryCards from './MeetupsSummaryCards';
import ActionButtons from './ActionButtons';
import EditSection from './EditSection';
import _ from 'lodash';
import { DEFAULT_MEETUPS_CADENCE_WEEKS } from 'lib/helpers/meetups';

export const audienceIsFullOrganization = (audience: Audience): boolean =>
  !!audience?.organization;

export type MeetupsConfigurations = {
  audience: Audience;
  cadenceWeeks: number;
};

export enum MeetupsState {
  Creating,
  Editing,
  Disabling,
  DisablingConfirmed,
}

const SETUP_TEST_MEETUP_ROUTE = undefined; // Fill this in once we have a test meetup form

export const FOCUS_AREAS = [
  { key: ConnectionFocusArea.CrossTeam, text: 'Cross-team connection' },
  { key: ConnectionFocusArea.IntraTeam, text: 'Within-teams connection' },
  { key: ConnectionFocusArea.Leadership, text: 'Leadership connection' },
];

const SettingsAdminGuard = ({ viewer }: ViewerProps) => {
  const org = viewer?.orgs?.[0];
  const betaAccess = org?.isMeetupsBetaEnabled || Statsig.checkGate('meetups');
  if (!viewer || !betaAccess || !isAdmin(viewer) || !org) {
    return <Redirect to={Paths.HOME} />;
  }

  return (
    <MeetupsLoader
      firstName={viewer.firstName ?? 'You'}
      orgId={org.id}
      orgName={org.name}
      Component={Settings}
    />
  );
};

const CenterContentSelector = ({
  audience,
  setAudience,
  meetupsState,
  setMeetupsState,
  copy,
  orgId,
  existingInitiative,
  orgMembers,
  cadenceWeeks,
  setCadenceWeeks,
}: {
  audience: Audience;
  setAudience(audience: Audience): void;
  meetupsState: MeetupsState;
  setMeetupsState: Dispatch<SetStateAction<MeetupsState>>;
  existingInitiative: WatercoolerInitiativeByOrgQuery['watercoolerInitiativeByOrg'];
  cadenceWeeks: number;
  setCadenceWeeks(cadenceWeeks: number): void;
} & MeetupsLoaderPassedProps) => {
  const fullOrg = audienceIsFullOrganization(audience);
  const emptyAudience = Object.values(audience).every((x) => x?.length === 0);

  const { Disabling, DisablingConfirmed } = MeetupsState;

  if ([Disabling, DisablingConfirmed].includes(meetupsState)) {
    return <DisableMeetups setMeetupsState={setMeetupsState} />;
  }

  if (fullOrg) {
    return (
      <>
        <img
          src={MeetupsSummary}
          alt='Avatars connected on a network graph'
          css={styles.image}
        />
        <MeetupsSummaryCards
          cadenceWeeks={cadenceWeeks}
          lastRun={existingInitiative?.lastRun}
          runAfter={existingInitiative?.runAfter}
          memberCount={orgMembers.length}
          setCadenceWeeks={setCadenceWeeks}
        />
        <ActiveMeetupSummary {...copy} />
      </>
    );
  } else {
    return (
      <>
        <AudienceBuilder
          audience={audience}
          setAudience={setAudience}
          organizationId={orgId}
          style={{ maxWidth: '700px', minWidth: '450px', marginBottom: theme.spacing(2) }}
        />
        {emptyAudience ? (
          <EmptyCohort />
        ) : (
          <>
            <MeetupsSummaryCards
              cadenceWeeks={cadenceWeeks}
              setCadenceWeeks={setCadenceWeeks}
              lastRun={existingInitiative?.lastRun}
              runAfter={existingInitiative?.runAfter}
              memberCount={getUniqUserCount(audience)}
            />
            <ActiveMeetupSummary {...copy} memberCount={getUniqUserCount(audience)} />
          </>
        )}
      </>
    );
  }
};

const Settings = ({
  existingInitiative,
  orgId,
  copy,
  orgMembers,
}: MeetupsLoaderPassedProps) => {
  const defaultMeetupsConfig: MeetupsConfigurations = {
    audience: existingInitiative ? audienceReducer(existingInitiative?.audience) : {},
    cadenceWeeks: existingInitiative?.cadenceWeeks || DEFAULT_MEETUPS_CADENCE_WEEKS,
  };

  const [meetupsConfiguration, setMeetupsConfiguration] =
    useState<MeetupsConfigurations>(defaultMeetupsConfig);
  const [meetupsState, setMeetupsState] = useState<MeetupsState>(
    existingInitiative ? MeetupsState.Editing : MeetupsState.Creating,
  );
  const [dirty, setDirty] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const setAudience = (audience: Audience) =>
    setMeetupsConfiguration({ ...meetupsConfiguration, audience });
  const setCadenceWeeks = (cadenceWeeks: number) =>
    setMeetupsConfiguration({ ...meetupsConfiguration, cadenceWeeks });

  useEffect(() => {
    setDirty(!_.isEqual(meetupsConfiguration, defaultMeetupsConfig));
  }, [meetupsConfiguration]);

  return (
    <MeetupsPage>
      <CenterSection loading={loading}>
        <CenterContentSelector
          audience={meetupsConfiguration.audience}
          setAudience={setAudience}
          meetupsState={meetupsState}
          setMeetupsState={setMeetupsState}
          copy={copy}
          orgId={orgId}
          orgMembers={orgMembers}
          existingInitiative={existingInitiative}
          cadenceWeeks={meetupsConfiguration.cadenceWeeks}
          setCadenceWeeks={setCadenceWeeks}
        />
      </CenterSection>
      <SidePanel>
        <EditSection>
          <SelectCohort
            audience={meetupsConfiguration.audience}
            setAudience={setAudience}
            meetupsState={meetupsState}
            setMeetupsState={setMeetupsState}
            setLoading={setLoading}
            memberCount={copy.memberCount}
            orgId={orgId}
            existingInitiative={existingInitiative}
            setDirty={setDirty}
          />
          <ActionButtons
            existingInitiative={existingInitiative}
            meetupsState={meetupsState}
            dirty={dirty}
            meetupsConfiguration={meetupsConfiguration}
            audience={meetupsConfiguration.audience}
            orgId={orgId}
          />
        </EditSection>
        {SETUP_TEST_MEETUP_ROUTE && (
          <SeeInAction testMeetupsRoute={SETUP_TEST_MEETUP_ROUTE} />
        )}
      </SidePanel>
    </MeetupsPage>
  );
};

const styles = {
  image: css({
    maxWidth: '700px',
    width: '100%',
    borderRadius: theme.spacing(4),
    border: `1px solid rgba(0, 0, 0, 0.1)`,
  }),
};

gql`
  query GetOrgMembers($orgId: ID!) {
    organization(id: $orgId) {
      id
      members {
        id
        email
        name
        firstName
        lastName
        companyRole
      }
    }
  }
`;

export const audienceReducer = (
  audience?: NonNullable<
    WatercoolerInitiativeByOrgQuery['watercoolerInitiativeByOrg']
  >['audience'],
): Audience => {
  if (!audience) {
    return { users: [], teams: [], tags: [] } as Audience;
  }

  return audience.reduce(
    (acc, audienceSegment) => {
      if (!acc.tags) {
        acc.tags = [];
      }
      if (!acc.teams) {
        acc.teams = [];
      }
      if (!acc.users) {
        acc.users = [];
      }

      const { organization, team, user, userTag } = audienceSegment!;

      team && acc.teams.push(team);
      user && acc.users.push(user);
      userTag && acc.tags.push(userTag);

      organization && (acc.organization = organization);
      return acc;
    },
    { users: [], teams: [], tags: [] } as Audience,
  );
};

export default SettingsAdminGuard;
