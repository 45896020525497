import { css } from '@emotion/react';
import ArrowRight from '@mysteryco/design/icons/ArrowRight';
import { colors } from '@mysteryco/design/src';
import { Paths } from 'Routes';
import Magic from 'glue/assets/illustrations/Magic.svg';
import { LinkButton } from 'glue/components/buttons/Button';
import theme from 'theme';
import { SUPPORT_EMAIL } from 'utils/stringUtil';

const strings = {
  whereDid: 'Where did it go?',
  lookedEverywhere:
    'We’ve looked everywhere but it looks like this page has now become a mystery...',
  getYouBack: 'Let’s get you back to Glue...',
  buttonText: 'Take me home',
  error: 'Think this is an error?',
  email: 'Email',
};

interface Props {
  title?: string;
  subtitle?: string;
  buttonText?: string;
}

const NotFoundPage = ({
  title = strings.whereDid,
  subtitle = strings.lookedEverywhere,
  buttonText = strings.buttonText,
}: Props) => {
  return (
    <div css={styles.root}>
      <div css={styles.title}>{title}</div>
      <div css={styles.subtitle}>{subtitle}</div>
      <div css={styles.image}>
        <img src={Magic} alt='' width={'100%'} />
      </div>
      <div css={styles.getYouBack}>{strings.getYouBack}</div>
      <LinkButton
        shape='round'
        color='black'
        css={styles.button}
        endIcon={<ArrowRight size={22} color='inherit' />}
        to={Paths.HOME}
      >
        {buttonText}
      </LinkButton>
      <div css={styles.supportInfo}>
        <div css={styles.error}>{strings.error}</div>
        <div>{strings.email}</div>
        <a css={styles.linkText} href={`mailto:${SUPPORT_EMAIL}`}>
          {SUPPORT_EMAIL}
        </a>
      </div>
    </div>
  );
};

const styles = {
  root: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(25),
    gap: theme.spacing(5),
    color: colors.Glue_Ink00,
    width: '50%',
    alignSelf: 'center',
  }),
  title: css({
    fontFamily: 'Zilla Slab',
    fontWeight: 500,
    fontSize: '48px',
    lineHeight: 1,
    textAlign: 'center',
  }),
  subtitle: css({
    fontSize: 16,
    lineHeight: '140%',
    textAlign: 'center',
  }),
  image: css({
    width: '60%',
    margin: `${theme.spacing(5)} 0`,
  }),
  getYouBack: css({
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '120%',
    textAlign: 'center',
  }),
  button: css({
    width: '100%',
    justifyContent: 'center',
  }),
  supportInfo: css({
    marginTop: theme.spacing(10),
    display: 'flex',
    gap: theme.spacing(1),
    fontSize: 16,
    lineHeight: '140%',
  }),
  error: css({
    fontWeight: 700,
  }),
  linkText: css({
    color: colors.Glue_Ink00,
    textDecoration: 'underline',
    '&:hover': {
      color: colors.Glue_Ink00,
      textDecoration: 'underline',
    },
  }),
};

export default NotFoundPage;
