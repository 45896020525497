// @ts-strict-ignore
import Trophy01 from 'components/icons/Trophy01';
import { SCORE_TYPE_NAME_MAP } from 'constants/Strings';
import { ConnectionStrength, CONNECTION_STRENGTH_MAP } from 'constants/Type';
import InsightsExplainer from 'glue/components/insights/InsightsExplainerPopover';
import ProfileAvatar from 'glue/components/profile/ProfileAvatar';
import SectionHeader from 'glue/components/structure/SectionHeader';
import { useGuaranteedMemo } from 'hooks';
import { pluralize } from 'humanize-plus';
import { forwardRef } from 'react';
import theme from 'theme';
import {
  HotSpotsMembersQuery,
  IndividualScoreTrend,
  InsightsSidePanelTeamFragment,
  ScoreType,
  useHotSpotsMembersQuery,
} from 'types';
import { teamToInitials } from 'utils/teamUtil';

import { gql } from '@apollo/client';
import { css } from '@emotion/react';
import { Box, Typography } from '@material-ui/core';
import { TrendingDown } from '@material-ui/icons';
import { colors } from '@mysteryco/design';
import InfoCircle from '@mysteryco/design/icons/InfoCircle';
import { Tooltip } from 'glue/components/tooltip/Tooltip';
import TextLink from 'glue/components/buttons/TextLink';
import CustomSkeleton from 'glue/scenes/Home/components/CustomSkeleton';

type ScoreDelta = IndividualScoreTrend & {
  name: string;
  companyRole: string;
  teamName: string;
};

type Props = {
  teams?: InsightsSidePanelTeamFragment[];
  scores?: IndividualScoreTrend[];
  loading?: boolean;
};

const Avatar = forwardRef<
  HTMLDivElement,
  {
    disabled?: boolean;
    entity: { id: string; name: string };
    positive?: boolean;
  }
>(({ disabled, entity, positive, ...rest }, ref) => {
  return (
    <ProfileAvatar
      ref={ref}
      initials={teamToInitials(entity)}
      size='small'
      background={positive ? colors.Glue_Mint00 : colors.Glue_Red10}
      overlapping
      selectable={!disabled}
      fontColor={colors.Glue_Ink00}
      {...rest}
    />
  );
});
Avatar.displayName = 'Avatar';

const MetricWrapper = ({ title, tooltipText, children }) => {
  return (
    <>
      <Box css={classes.metricWrapper}>
        <Box css={classes.metricTitle}>
          <Typography
            variant='h5'
            css={{
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '19px',
              color: colors.Glue_Ink10,
              paddingRight: '8px',
            }}
          >
            {title}
          </Typography>
          <Tooltip
            content={<InsightsExplainer explainerText={tooltipText} title={title} />}
          >
            {/* TODO: make icons forwardRef in @mysteryco/design */}
            <span css={classes.icon}>
              <InfoCircle
                size={15}
                dualTone={false}
                css={{ stroke: 'currentColor', color: 'inherit' }}
              />
            </span>
          </Tooltip>
        </Box>
        {children}
      </Box>
    </>
  );
};

const ExploreLink = ({ linkUrl }: { linkUrl: string }) => {
  return (
    <TextLink css={classes.link} to={linkUrl}>
      Explore
    </TextLink>
  );
};

const TeamsSection = ({ teams = [], loading }: Pick<Props, 'teams' | 'loading'>) => {
  const SHOW_MAX = 6;

  const weakScoreThreshold = CONNECTION_STRENGTH_MAP[ConnectionStrength.Weak].threshold;
  const filteredTeams = teams?.filter((team) =>
    team.scores.some((score: any) => score.value < weakScoreThreshold),
  );

  const getTeamMinScore = (team: InsightsSidePanelTeamFragment): number =>
    Math.min(...team.scores?.map((score: any): number => score.value));
  const sortedTeams = filteredTeams?.sort((a, b) =>
    getTeamMinScore(a) < getTeamMinScore(b) ? -1 : 1,
  );
  const overMax = (sortedTeams?.length ?? 0) - SHOW_MAX;

  return (
    <>
      <MetricWrapper
        title='Teams with weak connection'
        tooltipText='These are teams whose overall connection scores are weak across several areas.'
      >
        {loading ? (
          <CustomSkeleton widths={['100%', '100%']} />
        ) : (
          <Box css={classes.flexRow}>
            {sortedTeams?.length ? (
              <>
                {sortedTeams.slice(0, SHOW_MAX).map((team) => (
                  <TeamAvatar
                    key={`team-${team.id}`}
                    team={team}
                    scoreThreshold={weakScoreThreshold}
                  />
                ))}
                {overMax > 0 && (
                  <Avatar disabled entity={{ id: 'max', name: `+ ${overMax}` }} />
                )}
              </>
            ) : (
              <Box css={classes.scoreTrend}>
                <Typography style={{ fontSize: '16px' }}>All clear!</Typography>
              </Box>
            )}
          </Box>
        )}
      </MetricWrapper>
    </>
  );
};

const TeamAvatar = ({
  team,
  scoreThreshold: poorScoreThreshold,
}: {
  team: InsightsSidePanelTeamFragment;
  scoreThreshold: number;
}) => {
  return (
    <Tooltip
      content={
        <>
          <Typography
            css={{
              fontWeight: 700,
              fontSize: '1rem',
              lineHeight: 1.5,
              color: colors.Glue_Ink00,
            }}
          >
            {team?.name}
          </Typography>
          <Typography
            css={{
              color: colors.Glue_Ink10,
              fontSize: '14px',
              fontWeight: 700,
              paddingBottom: '4px',
            }}
          >{`${team?.members?.length} ${pluralize(
            team?.members?.length,
            'member',
          )}`}</Typography>
          {team?.scores
            ?.filter((score: any) => score.value < poorScoreThreshold)
            ?.map(({ scoreType }) => (
              <Typography
                key={`${team.id}-${scoreType}-score-text`}
                style={{
                  fontSize: '16px',
                  lineHeight: '20px',
                  fontWeight: 700,
                  paddingTop: '12px',
                }}
              >{`Low ${SCORE_TYPE_NAME_MAP[scoreType]} Connection`}</Typography>
            ))}
          <Box css={css({ paddingTop: '20px' })}>
            <ExploreLink linkUrl={`/insights?teamId=${team?.id}`} />
          </Box>
        </>
      }
    >
      <Avatar entity={team} />
    </Tooltip>
  );
};

const TrendingConnectionsSection = ({
  scores,
  loading,
}: Pick<Props, 'scores' | 'loading'>) => {
  const { data, loading: membersLoading } = useHotSpotsMembersQuery({
    variables: {
      entityIds: scores?.map((score) => score.entityId),
    },
  });
  const membersById = data?.getOrganizationMembers?.reduce((acc, member) => {
    acc[member.id] = member;
    return acc;
  }, {} as Record<string, HotSpotsMembersQuery['getOrganizationMembers'][number]>);

  const scoresByMemberId = useGuaranteedMemo(
    () =>
      (scores || []).reduce((acc, score) => {
        const member = membersById?.[score.entityId];
        const scoreDelta: ScoreDelta = {
          ...score,
          name: member?.name,
          companyRole: member?.companyRole,
          teamName: member?.teams?.[0]?.name,
        };
        acc[score.entityId] = scoreDelta;
        return acc;
      }, {} as Record<string, ScoreDelta>),
    [scores, membersById],
  ) as Record<string, ScoreDelta>;

  return (
    <>
      <MetricWrapper
        title='Trending connections'
        tooltipText='These are the 4 members in your org whose connection score has changed the most in the last 30 days.'
      >
        {loading || membersLoading ? (
          <CustomSkeleton widths={['100%', '100%']} />
        ) : (
          <Box css={classes.flexRow}>
            {Object.values(scoresByMemberId)?.map((score) => (
              <TrendingConnectionsAvatar key={`score-${score.entityId}`} score={score} />
            ))}
          </Box>
        )}
      </MetricWrapper>
    </>
  );
};

const isIncreasing = (score: ScoreDelta): boolean =>
  score.deltas.every(({ oneMonthDelta }) => oneMonthDelta > 0);

const scoreTypeToText = (scoreType: ScoreType): string => {
  switch (scoreType) {
    case ScoreType.CrossTeam:
      return 'cross-team connection';
    case ScoreType.Leadership:
      return 'connection to leadership';
    case ScoreType.SenseOfBelonging:
      return 'sense of belonging';
    case ScoreType.WithinTeam:
      return 'within-team connection';
    default:
      console.warn(`Unhandled score type: ${scoreType}`);
      return 'connection';
  }
};

const TrendingConnectionsAvatar = ({ score }: { score: ScoreDelta }) => {
  return (
    <Tooltip
      content={
        <>
          <Typography
            css={{
              fontWeight: 700,
              fontSize: '1rem',
              lineHeight: 1.5,
              color: colors.Glue_Ink00,
            }}
          >
            {score?.name}
          </Typography>
          <Typography
            style={{ fontSize: '14px', fontWeight: 700, color: colors.Glue_Ink10 }}
          >
            {score?.companyRole}
          </Typography>
          {score?.deltas?.map(({ scoreType, oneMonthDelta }) => (
            <Box
              key={`${score.entityId}-${scoreType}-score-text`}
              css={classes.scoreTrend}
            >
              {oneMonthDelta > 0 ? (
                <Trophy01 color={colors.Glue_Ink00} />
              ) : (
                <TrendingDown style={{ color: colors.Glue_Ink00 }} />
              )}
              <Typography
                style={{
                  fontSize: '16px',
                  lineHeight: '20px',
                  fontWeight: 700,
                  paddingLeft: '6px',
                }}
              >{`${isIncreasing(score) ? 'Increased' : 'Decreased'} ${scoreTypeToText(
                scoreType,
              )}`}</Typography>
            </Box>
          ))}
        </>
      }
      key={`score-${score.entityId}`}
    >
      <Avatar
        entity={{ id: score.entityId, name: score.name }}
        positive={isIncreasing(score)}
      />
    </Tooltip>
  );
};

const HotSpots = ({ teams = [], scores = [], loading }: Props) => {
  return (
    <Box css={classes.root}>
      <SectionHeader title='Connection hot spots'>
        <ExploreLink linkUrl='/explore' />
      </SectionHeader>
      <Box css={classes.container}>
        <TeamsSection teams={teams} loading={loading} />
        <TrendingConnectionsSection scores={scores} loading={loading} />
      </Box>
    </Box>
  );
};

const classes = {
  root: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    gap: theme.spacing(8),
    alignSelf: 'stretch',
  }),
  container: css({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '20px',
  }),
  link: {
    // making room for the hover effect
    marginRight: 8,
  },
  metricWrapper: css({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    background: colors.Glue_LavenderLight,
    padding: '20px',
    borderRadius: '4px',
  }),
  metricTitle: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: '16px',
  }),
  flexRow: css({
    display: 'flex',
    flexDirection: 'row',
  }),
  scoreTrend: css({
    paddingTop: '12px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }),
  icon: css({
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: colors.Glue_Ink10,
    '&:focus': {
      color: colors.Glue_Darkberry10,
    },
    '&:hover': {
      color: colors.Glue_Ink00,
    },
  }),
};

HotSpots.query = gql`
  query HotSpotsMembers($entityIds: [ID!]) {
    getOrganizationMembers(userIds: $entityIds) {
      id
      name
      companyRole
      teams {
        id
        name
      }
    }
  }
`;

export default HotSpots;
