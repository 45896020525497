import { gql } from '@apollo/client';
import { css } from '@emotion/react';
import { Box, RadioGroup } from '@material-ui/core';
import { colors } from '@mysteryco/design/src';
import * as ToggleGroup from '@radix-ui/react-toggle-group';
import DropdownFilterButton from 'glue/components/inputs/DropdownFilterButton';
import RadioButton from 'glue/components/inputs/RadioButton';
import { Popover, PopoverContent, PopoverTrigger } from 'glue/components/popover/Popover';
import { PulseTimeframes } from 'glue/scenes/Explore/Pulse/timeframes';
import { useEffect, useState } from 'react';
import theme from 'theme';
import {
  MoralePulseSurveyReportingIntervalType as IntervalType,
  MoralePulseSurveyReportingTimeframe as Timeframe,
  TimeframeFilterTimeframeFragment,
} from 'types';

type TimeframePopoverProps = {
  onChange: (timeframe: Timeframe, timeframeType: IntervalType) => void;
  quarters: Timeframe[];
  months: Timeframe[];
  defaultValue: Timeframe;
};
const TimeframeFilterPopover = ({
  onChange,
  quarters,
  months,
  defaultValue,
}: TimeframePopoverProps) => {
  const [timeframeType, setTimeframeType] = useState<IntervalType>(
    defaultValue?.type ?? IntervalType.Quarter,
  );
  const allTimeframes = Object.values(quarters).concat(months);
  const [selectedTimeframe, setSelectedTimeframe] = useState<Timeframe>(defaultValue);
  const handleToggle = (value: string | undefined) => {
    if (!value || value.length === 0) {
      return;
    }
    const typeValue =
      value === IntervalType.Quarter ? IntervalType.Quarter : IntervalType.Month;
    setTimeframeType(typeValue);
  };
  const handleSelection = (type: IntervalType) => {
    return (e) => {
      const value = e.target.value;
      const newTimeframe = allTimeframes.find((timeframe) => timeframe.key === value);
      if (!newTimeframe) {
        return;
      }
      setSelectedTimeframe({ ...newTimeframe });
      onChange(newTimeframe, type);
    };
  };
  return (
    <div css={timeframeStyles.popoverLayout}>
      {/* TODO(Justin): Extract this into a reusable component */}
      <Box padding={4}>
        <ToggleGroup.Root
          type='single'
          defaultValue={IntervalType.Quarter}
          onValueChange={handleToggle}
          value={timeframeType}
          css={timeframeStyles.toggleGroup}
        >
          <ToggleGroup.Item
            value={IntervalType.Quarter}
            css={[timeframeStyles.toggleButton, timeframeStyles.toggleButtonLeft]}
          >
            Quarter
          </ToggleGroup.Item>
          <ToggleGroup.Item
            value={IntervalType.Month}
            css={[timeframeStyles.toggleButton, timeframeStyles.toggleButtonRight]}
          >
            Month
          </ToggleGroup.Item>
        </ToggleGroup.Root>
      </Box>
      {timeframeType === IntervalType.Quarter && (
        <RadioGroup
          name='quarter-radio-group'
          onChange={handleSelection(IntervalType.Quarter)}
          value={selectedTimeframe.key}
        >
          <div css={timeframeStyles.sectionHeader}>Available Quarters</div>
          {quarters.map((quarter) => (
            <RadioButton
              value={quarter.key}
              label={quarter.displayString}
              key={quarter.key}
              css={timeframeStyles.radioButton}
            />
          ))}
        </RadioGroup>
      )}
      {timeframeType === IntervalType.Month && (
        <RadioGroup
          name='month-radio-group'
          onChange={handleSelection(IntervalType.Month)}
          value={selectedTimeframe.key}
        >
          <div css={timeframeStyles.sectionHeader}>Available Months</div>
          {months.map((month) => (
            <RadioButton
              value={month.key}
              label={month.displayString}
              key={month.key}
              css={timeframeStyles.radioButton}
            />
          ))}
        </RadioGroup>
      )}
    </div>
  );
};

type TimeframeFilterProps = {
  onChange: (timeframe: Timeframe) => void;
  value?: TimeframeFilterTimeframeFragment;
  options: PulseTimeframes;
};
const TimeframeFilter = ({
  onChange,
  value,
  options: { quarters, months, allTimeframes, loading },
}: TimeframeFilterProps) => {
  const noTimeframes = allTimeframes.length === 0;
  const mostRecentQuarter = (quarters && quarters[0]) || undefined;
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (!value && mostRecentQuarter) {
      onChange(mostRecentQuarter);
    }
  }, [value, mostRecentQuarter]);

  const handleChange = (timeframe: Timeframe) => {
    if (!timeframe) {
      return;
    }
    onChange(timeframe);
  };

  //TODO(Justin): Mildly hacky, could probably use a date formatter here for better INTL support
  const splitKey = value?.displayString.split(' ') || ' ';
  const [timeframe, year] = splitKey;

  let empty = !loading && noTimeframes;
  const formattedText = `${timeframe.substring(0, 3)} ${year}`;
  const displayText = empty ? 'None' : loading ? 'Loading' : formattedText;

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <DropdownFilterButton
          label='Timeframe'
          value={displayText}
          expanded={open}
          disabled={empty}
        />
      </PopoverTrigger>
      <PopoverContent css={timeframeStyles.popoverContent}>
        {months && quarters && mostRecentQuarter && (
          <TimeframeFilterPopover
            onChange={handleChange}
            months={months}
            quarters={quarters}
            defaultValue={value || mostRecentQuarter}
          />
        )}
      </PopoverContent>
    </Popover>
  );
};

const timeframeStyles = {
  popoverContent: css({
    padding: 0,
    borderRadius: 8,
  }),
  popoverLayout: css({
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '40vh',
    overflowY: 'auto',
    marginBottom: theme.spacing(4),
    width: 240,
  }),
  toggleButton: css({
    flex: `1 0 0`,
    height: 40,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    color: colors.Glue_Ink00,
    backgroundColor: colors.Glue_LavenderLight,
    border: '1px solid #EAEAEA',
    cursor: 'pointer',
    '&[data-state=on]': {
      backgroundColor: colors.Glue_Mint20,
      borderColor: colors.Glue_BorderDark,
    },
  }),
  toggleGroup: css({
    position: 'sticky',
    top: 0,
    zIndex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  }),
  toggleButtonLeft: css({
    borderRadius: '8px 0px 0px 8px',
  }),
  toggleButtonRight: css({
    borderRadius: '0px 8px 8px 0px',
  }),
  radioButton: css({
    '&:hover': {
      backgroundColor: colors.Glue_LavenderLight,
    },
    padding: '8px 16px',
    margin: 0,
  }),
  sectionHeader: css({
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '24px',
    color: colors.Glue_Ink10,
    margin: `0 ${theme.spacing(4)} ${theme.spacing(1)} ${theme.spacing(4)}`,
  }),
  radioLayout: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  }),
};

TimeframeFilter.fragments = gql`
  fragment TimeframeFilterTimeframe on MoralePulseSurveyReportingTimeframe {
    key
    surveyIds
    displayString
    startDate
    endDate
    type
  }
`;

export default TimeframeFilter;
