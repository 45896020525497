import {
  AudienceBuilderTeamFragment,
  AudienceBuilderUserFragment,
  AudienceBuilderUserTagFragment,
  MeetupAudienceOrganizationFragment,
} from 'types';

export type AudienceType =
  | AudienceBuilderUserFragment
  | AudienceBuilderTeamFragment
  | AudienceBuilderUserTagFragment;

export enum AudienceTypeEnum {
  USER = 'user',
  TEAM = 'team',
  TAG = 'tag',
}

export interface Audience {
  organization?: MeetupAudienceOrganizationFragment;
  users?: AudienceBuilderUserFragment[];
  teams?: AudienceBuilderTeamFragment[];
  tags?: AudienceBuilderUserTagFragment[];
}
