// @ts-strict-ignore
import { Loading } from 'components/core';
import config from 'config';

import { doFetch } from 'lib/helpers/fetch';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import ReactPinField from 'react-pin-field';
import theme from 'theme';
import { GetOrgByLinkPathQuery, User } from 'types';

import { gql } from '@apollo/client';
import { css } from '@emotion/react';
import UserCircle from '@mysteryco/design/icons/UserCircle';
import { Coal_10, Coal_20, Coal_40, Plum_10 } from '@mysteryco/design/src/tokens/colors';

import AuthContainer from './AuthContainer';
import { sendOTP } from './EmailInputScreen';

const GMAIL_SEARCH_LINK =
  'https://mail.google.com/mail/u/0/#search/Your+one+time+password+for+Glue';
const OUTLOOK_LINK = 'https://outlook.office.com/mail';
const OTP_DELAY_MS = 1500;
const RESET_OTP_DELAY_MS = 5000;

const LinkToNewTab = ({
  href,
  children,
  ...props
}: {
  href: string;
  children: React.ReactNode;
  [key: string]: any;
}) => (
  <a href={href} target='_blank' rel='noreferrer' {...props}>
    {children}
  </a>
);

const otpDelay = () => new Promise((resolve) => setTimeout(resolve, OTP_DELAY_MS));

const HelpText = ({ accountInfo }: { accountInfo: Partial<User> }) => {
  const [sendingOTP, setSendingOTP] = useState(false);
  const [otpSent, setOtpSent] = useState(false);

  useEffect(() => {
    if (otpSent) {
      setTimeout(() => setOtpSent(false), RESET_OTP_DELAY_MS);
    }
  }, [otpSent]);

  const getFooterText = (): { title: string; linkText?: string; message?: string } => {
    if (sendingOTP) {
      return {
        title: 'Give us one second... ',
        message: `We're preparing the pidgeons`,
      };
    }
    if (otpSent) {
      return {
        title: 'Code has been resent!',
      };
    }

    return {
      title: 'No code?',
      linkText: 'Request a new code',
    };
  };

  const { title, linkText, message } = getFooterText();

  const SearchLinks = () =>
    message ? null : (
      <>
        {linkText ? 'or c' : 'C'}heck{' '}
        <LinkToNewTab css={styles.anchor} href={GMAIL_SEARCH_LINK}>
          Gmail
        </LinkToNewTab>{' '}
        /{' '}
        <LinkToNewTab css={styles.anchor} href={OUTLOOK_LINK}>
          Outlook
        </LinkToNewTab>
      </>
    );

  const onSubmitEmail = async () => {
    setSendingOTP(true);
    // await for at least OTP_DELAY_MS so the user can see the transition state
    await Promise.all([await sendOTP({ email: accountInfo.email }), await otpDelay()]);
    setSendingOTP(false);
    setOtpSent(true);
  };

  return (
    <div css={styles.helpSection}>
      <b>{title}</b>
      {message ? (
        message
      ) : (
        <button css={styles.anchorlikeButton} onClick={onSubmitEmail}>
          {linkText}
        </button>
      )}
      <SearchLinks />
    </div>
  );
};

const OTPScreen = ({
  accountInfo,
  org,
  onComplete,
}: {
  accountInfo: Partial<User>;
  org?: GetOrgByLinkPathQuery['getOrgByLinkPath'];
  onComplete: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const pinRef = React.useRef(null);

  useEffect(() => {
    if (pinRef.current) {
      pinRef.current[0].focus();
    }
  }, [pinRef.current]);

  const resetPinField = () => {
    if (pinRef.current) {
      pinRef.current.forEach((input) => (input.value = ''));
      pinRef.current[0].focus();
    }
  };

  const handleOtpComplete = async (otp: string) => {
    mixpanel.track('verifying otp', { source: 'auth' });
    setLoading(true);
    const OTPCorrect = await verifyOTP(accountInfo.email!, otp);
    if (!OTPCorrect) {
      setLoading(false);
      mixpanel.track('otp incorrect', { source: 'auth' });
      resetPinField();
    } else {
      mixpanel.track('otp accepted', { source: 'auth' });
      await onComplete();
      setLoading(false);
    }
  };

  if (loading) return <Loading />;

  return (
    <AuthContainer
      showCodeMessage={false}
      title='Got your code?'
      subTitle='Enter the 6-digit-code we sent to your email'
      logoImage={org?.logoUrl}
      icon={<UserCircle size={24} color={Plum_10} />}
    >
      <div css={styles.codeInputContainer}>
        <ReactPinField
          id='phone-pin-field'
          ref={pinRef}
          css={styles.codeInput}
          autoComplete='none'
          inputMode='numeric'
          validate={new RegExp('\\d')}
          length={6}
          placeholder='0'
          onComplete={handleOtpComplete}
        />
      </div>
      <HelpText accountInfo={accountInfo} />
    </AuthContainer>
  );
};

const verifyOTP = async (email: string, otp: string): Promise<boolean> => {
  const url = `${config.api.url}/authEmail`;

  try {
    const data = await doFetch(url, { email, otp });
    if (_.isEmpty(data)) return false;
  } catch (error) {
    return false;
  }

  return true;
};

OTPScreen.mutation = gql`
  mutation addUserToOrgDuringSignUp($userId: ID!, $orgId: ID!) {
    addMemberToOrganization(orgId: $orgId, userId: $userId) {
      id
    }
  }
`;

const styles = {
  codeInputContainer: css({
    marginTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'row',
  }),
  codeInput: css({
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    width: 57,
    height: 62,
    border: `1.5px solid ${Coal_40}`,
    borderRadius: theme.spacing(1),
    margin: theme.spacing(1),
    fontSize: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      width: 46,
      height: 46,
    },
  }),
  helpSection: css({
    marginTop: theme.spacing(10),
    color: Coal_10,
    fontSize: `calc(${theme.spacing(4)} - 1px)`,
  }),
  anchor: css({
    color: Coal_10,
    textDecoration: 'underline',
    ':hover': {
      color: Coal_20,
      textDecoration: 'underline',
    },
    ':focus': {
      color: Coal_20,
      textDecoration: 'underline',
    },
  }),
  anchorlikeButton: css({
    border: 'none',
    background: 'none',
    color: Coal_10,
    textDecoration: 'underline',
    cursor: 'pointer',
    padding: 0,
    margin: theme.spacing(1),
    ':hover': {
      color: Coal_20,
    },
    ':focus': {
      color: Coal_20,
    },
  }),
};

export default OTPScreen;
