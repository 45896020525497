// @ts-strict-ignore
import { useQuery } from '@apollo/client';
import { Box, Button, Drawer, Grid, Snackbar, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { OrgSearch } from 'components/OrgSearch';
import config from 'config';
import { getOrganizationRoles, getOrgMembers, getPendingOrgMembers } from 'gql/queries';
import humanize from 'humanize-plus';
import { useEffect, useState } from 'react';
import { useFinchConnect } from 'react-finch-connect';
import { useHistory } from 'react-router-dom';
import { Paths } from 'Routes';
import {
  AccountStatus,
  MemberFragmentFragment,
  OrderDirection,
  OrganizationRole,
  UsersOrderKey,
  UserTag,
  useSendAccountSetupReminderEmailsMutation,
  Team as TeamType,
  useGetOrgUserInfoQuery,
} from 'types';
import { TabName, TeamTabProps } from '..';
import AddTeamMembers from './AddTeamMembers';
import BulkEditTeamMembers from './BulkEditTeamMembers';
import EditTeamMember from './EditTeamMember';
import { hasUnselectedMembers, TeamTable } from './TeamTable';
import theme, { primaryGradient } from 'theme';
import TeamBikes from 'components/illustrations/TeamBikes';
import ReactLoading from 'react-loading';
import { DateTime } from 'luxon';
import SelectFilterOriginal from 'components/SelectFilterOriginal';
import EditTeamName from './Modals/EditTeamName';
import AddTeamMembersModal from './Modals/AddTeamMembersModal';
import { Statsig } from 'statsig-react';
import AlertBar from 'components/AlertBar';
import BulkEditToolbar from './BulkEditToolbar';
import { Loading } from 'components/core';
import SelectFilter from 'components/Search/SelectFilter';
import SelectTagFilter from 'components/Search/SelectTagFilter';
import SelectTeamFilter from 'components/Search/SelectTeamFilter';
import SearchBar from 'components/Search/SearchBar';
import FlatButton from 'components/core/FlatButton';
import { isContract } from 'utils/customerTypeUtils';
import AlertHexagon from '@mysteryco/design/icons/AlertHexagon';
import { useDebounce } from 'ahooks';

const HRIS_WHITELIST = new Set([
  '8517f1b4-73dd-43d8-8c00-33e8c74ea3a8',
  'ede220a6-8d66-4716-b0f0-efca64c53caf',
  'a6e2fc72-5786-4c0c-8415-6c70455d0803',
]);

const filterAccountStatus = (currentTab: TabName): AccountStatus[] => {
  switch (currentTab) {
    case TabName.Active:
      return [AccountStatus.Active];
    case TabName.Pending:
      return [AccountStatus.NeedsAction, AccountStatus.Invited, AccountStatus.Pending];
    default:
      return [];
  }
};

const canInvite = (member: MemberFragmentFragment): boolean => {
  return [AccountStatus.Invited, AccountStatus.Pending].includes(member?.accountStatus);
};

const HRAlert = ({ issueCount }) => {
  const history = useHistory();
  const alertText = `There ${humanize.pluralize(
    issueCount,
    `is ${issueCount} member with an issue that needs`,
    `are ${issueCount} members with issues that need`,
  )} your attention`;
  return (
    <Box my={4}>
      <AlertBar
        text={alertText}
        icon={<AlertHexagon size={20} />}
        onClick={() => {
          history.push(Paths.AWAITING);
        }}
        linkText='Correct issues'
        severity='error'
        pillText='Issues'
      />
    </Box>
  );
};

const Team = ({ viewer, organization, currentTab }: TeamTabProps) => {
  const classes = useStyle();

  const [addTeamMemberShowing, setAddTeamMemberShowing] = useState(false);
  const [editTeamMemberShowing, setEditTeamMemberShowing] = useState(false);
  const [bulkEditTeamMembersShowing, setBulkEditTeamMembersShowing] = useState(false);
  const [editTeamNameShowing, setEditTeamNameShowing] = useState(false);

  const [selectedMember, setSelectedMember] = useState<MemberFragmentFragment>(null);
  const [selectedMembers, setSelectedMembers] = useState(
    new Set<MemberFragmentFragment>(),
  );
  const [selectedTeam, setSelectedTeam] = useState<TeamType>(null);

  const [searchString, setSearchString] = useState('');
  const [inviteSent, setInviteSent] = useState(false);
  const [filterRoleIds, setFilterRoleIds] = useState<string[]>([]);
  const [filterTagIds, setFilterTagIds] = useState<string[]>();
  const [filterTeamIds, setFilterTeamIds] = useState<string[]>();

  // TODO: Remove with `showNewTeamStyles` flag
  const [filterRole, setFilterRole] = useState<OrganizationRole[]>([]);
  const [searchTags, setSearchTags] = useState<UserTag[]>([]);

  const debouncedSearchString = useDebounce(searchString, { wait: 500 });

  const [sendReminders, { loading: remindersLoading }] =
    useSendAccountSetupReminderEmailsMutation();

  useEffect(() => {
    if (!currentTab) return;
    resetSelectedMembers();
  }, [currentTab]);

  const isActivePage = currentTab === TabName.Active;
  const isArchivedPage = currentTab === TabName.Archived;

  const showAddMemberModal = Statsig.checkGate('show__add_member_modal');
  const showNewTeamStyles = Statsig.checkGate('show_new_team_styles');
  const showUpdateTags = Statsig.checkGate('show_update_tags');
  const showUpdateTeams = Statsig.checkGate('show_update_teams');

  const { data: filteredData, loading: filteredLoading } = useQuery(getOrgMembers, {
    variables: {
      query: showNewTeamStyles ? debouncedSearchString.trim() : searchString.trim(),
      orgId: organization?.id,
      sort: [{ key: UsersOrderKey.FirstName, direction: OrderDirection.Asc }],
      userTags: searchTags.length ? searchTags.map((tag) => tag.id) : filterTagIds,
      accountStatuses: filterAccountStatus(currentTab),
      orgRoles: filterRole?.length ? filterRole.map((role) => role.id) : filterRoleIds,
      teams: filterTeamIds,
      showArchived: isArchivedPage,
      includeManager: showNewTeamStyles,
    },
  });

  let filteredMembers = [];
  if (filteredData && filteredData.orgUsersConnection.edges) {
    filteredMembers = filteredData.orgUsersConnection.edges.map((edge) => edge.node);
  }

  const { data: pendingData } = useQuery(getPendingOrgMembers, {
    variables: {
      id: organization?.id,
    },
  });

  let pendingMembers = [];
  if (pendingData?.getPendingOrgMembers) {
    pendingMembers = pendingData?.getPendingOrgMembers;
  }

  const { data: memberData } = useGetOrgUserInfoQuery({
    variables: { orgId: organization.id },
  });
  let allMembers = [];
  if (memberData?.orgUsersConnection?.edges) {
    allMembers = memberData.orgUsersConnection.edges.map((edge) => edge.node);
  }

  const displayedMembers = filteredMembers.filter((member) => member.email);

  const { data: roleData } = useQuery(getOrganizationRoles);
  let orgRoles = [];
  if (roleData?.roles) {
    orgRoles = roleData.roles;
  }

  const teams = organization?.teams as TeamType[];
  const userTags = organization?.userTags as UserTag[];

  const onSuccess = (e) => {
    const code = e.code;
    return fetch(`${config.api.url}/exchange?code=${code}`, {
      method: 'post',
      body: JSON.stringify({ orgId: organization?.id, userId: viewer.id }),
    });
  };
  const onError = ({ errorMessage }) => console.error(errorMessage);
  const onClose = () => console.log('User exited Finch Connect');

  const { open: openFinchConnect } = useFinchConnect({
    clientId: config.finch.user,
    products: ['company', 'directory', 'individual', 'employment'],
    sandbox: false,
    onSuccess,
    onError,
    onClose,
  });

  const resetSelectedMembers = () => {
    setSelectedMembers(new Set<MemberFragmentFragment>());
    setSelectedMember(null);
  };

  const toggleSelectedMember = (member: MemberFragmentFragment) => {
    if (selectedMembers.has(member)) {
      selectedMembers.delete(member);
      setSelectedMembers(new Set(selectedMembers));
    } else {
      setSelectedMembers(new Set(selectedMembers.add(member)));
    }
  };

  const toggleSelectedMembers = () => {
    const undisplayedSelectedMembers = [...selectedMembers].filter(
      (member) => !displayedMembers.some((displayed) => displayed.id === member.id),
    );
    if (hasUnselectedMembers(displayedMembers, selectedMembers)) {
      setSelectedMembers(new Set([...undisplayedSelectedMembers, ...displayedMembers]));
    } else {
      setSelectedMembers(new Set(undisplayedSelectedMembers));
    }
  };

  const handleInviteMembers = async () => {
    const emails = [...selectedMembers]
      .filter((member) => canInvite(member))
      .map((member) => member.email);
    await sendReminders({
      variables: {
        orgId: organization?.id,
        emails,
      },
      refetchQueries: ['searchOrgUsers'],
      awaitRefetchQueries: true,
    });
    setInviteSent(true);
    resetSelectedMembers();
  };

  const handleEditMember = () => {
    if (selectedMembers.size === 1) {
      setSelectedMember(selectedMembers.values().next().value);
      setEditTeamMemberShowing(true);
    } else if (selectedMembers.size > 1) {
      setBulkEditTeamMembersShowing(true);
    }
  };

  const handleArchive = () => {
    resetSelectedMembers();
  };

  const isContractCustomer = isContract(organization.customerType);
  const showAlertBox =
    organization.isHrisConnected && isActivePage && pendingMembers.length > 0;

  const HRSyncBox = () => {
    const classes = useStyle();
    return (
      <Box className={classes.hrBox}>
        <Box>
          <Typography className={classes.hrBoxHeadline}>
            Include all your organization's members in the fun
          </Typography>
          <Typography className={classes.hrBoxCopy}>
            Automatically sync your org chart and team info
          </Typography>
          <Button
            onClick={openFinchConnect}
            className={classes.hrBoxButton}
            style={{ marginTop: '12px' }}
          >
            Sync team
          </Button>
        </Box>
        <Box className={classes.hrBoxIllustrationBikes}>
          <TeamBikes />
        </Box>
      </Box>
    );
  };

  return (
    <>
      {showNewTeamStyles && (
        <Box className={classes.teamManagerActions}>
          <Box className={classes.teamManagerTop}>
            <Box className={classes.filters}>
              {showUpdateTeams && isContractCustomer && !!teams?.length && (
                <SelectTeamFilter
                  organization={organization}
                  value={filterTeamIds}
                  onChange={(_, newValue) => setFilterTeamIds(newValue)}
                  disabled={filteredLoading}
                />
              )}
              <SelectFilter
                label='Role type'
                options={orgRoles}
                value={filterRoleIds}
                onChange={(_, newValue) => setFilterRoleIds(newValue)}
                disabled={filteredLoading}
                disableSearch
                disableSelectAll
                disableCreate
                disableUpdate
              />
              {showUpdateTags && isContractCustomer && !!userTags?.length && (
                <SelectTagFilter
                  userTags={userTags}
                  value={filterTagIds}
                  onChange={(_, newValue) => setFilterTagIds(newValue)}
                  disabled={filteredLoading}
                />
              )}
            </Box>
            <Box className={classes.callToAction}>
              {!organization.isHrisConnected && (
                <FlatButton
                  onClick={() => setAddTeamMemberShowing(true)}
                  corners='rounded'
                >
                  Add members
                </FlatButton>
              )}
            </Box>
          </Box>
          <SearchBar
            query={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            disabled={filteredLoading && !searchString}
            loading={filteredLoading && !!searchString}
          />
        </Box>
      )}
      {!showNewTeamStyles && (
        <>
          {!organization.isHrisConnected && (
            <Box className={classes.addMemberRow}>
              <Button
                onClick={() => setAddTeamMemberShowing(true)}
                className={classes.secondaryButton}
                style={{
                  width: '250px',
                }}
              >
                Add member
              </Button>
            </Box>
          )}
          <Box className={classes.teamHeaderRow}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6} className={classes.filterStack}>
                {orgRoles.length > 0 && (
                  <SelectFilterOriginal
                    label='Role type'
                    options={orgRoles}
                    selectedOptions={filterRole}
                    setSelectedOptions={setFilterRole}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={true}>
                <Box>
                  <OrgSearch
                    // Warning - type casting because the query doesn't request everything
                    // from UserTags, but I don't want to touch the OrgSearch component...
                    orgTags={organization.userTags as UserTag[]}
                    searchTags={searchTags}
                    setSearchTags={setSearchTags}
                    searchString={searchString}
                    setSearchString={setSearchString}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      {showAlertBox && <HRAlert issueCount={pendingMembers?.length} />}
      <Box>
        {!showNewTeamStyles &&
          HRIS_WHITELIST.has(organization.id) &&
          !organization.isHrisConnected && <HRSyncBox />}
        {!showNewTeamStyles && (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: `${theme.spacing(2)} 0`,
            }}
          >
            <Typography
              css={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => setSelectedMembers(new Set(displayedMembers))}
            >
              {displayedMembers.length > 1 && (
                <>Select all {displayedMembers.length} team members</>
              )}
              {displayedMembers.length === 1 && <>Select 1 team member</>}
            </Typography>

            {organization.manualIntegrationLastUpdated &&
              !organization.isHrisConnected && (
                <Typography css={{ textDecoration: 'none' }}>
                  Last updated:{' '}
                  {DateTime.fromISO(organization.manualIntegrationLastUpdated).toFormat(
                    'yyyy-mm-dd hh:mm:ss',
                  )}
                </Typography>
              )}
          </Box>
        )}
        {showAddMemberModal && (
          <AddTeamMembersModal
            isShowing={addTeamMemberShowing}
            setIsShowing={setAddTeamMemberShowing}
            organization={organization}
            openHrisConnect={openFinchConnect}
            existingMembers={allMembers}
          />
        )}
        {!showAddMemberModal && (
          <AddTeamMembers
            isShowing={addTeamMemberShowing}
            setIsShowing={setAddTeamMemberShowing}
            orgId={organization?.id}
            existingMembers={allMembers}
          />
        )}
        <EditTeamMember
          isShowing={editTeamMemberShowing}
          isArchivedPage={isArchivedPage}
          setIsShowing={setEditTeamMemberShowing}
          member={selectedMember}
          organization={organization}
          setSelectedMember={setSelectedMember}
          setSelectedMembers={setSelectedMembers}
        />
        <BulkEditTeamMembers
          isShowing={bulkEditTeamMembersShowing}
          isArchivedPage={isArchivedPage}
          setIsShowing={setBulkEditTeamMembersShowing}
          members={[...selectedMembers]}
          organization={organization}
          setSelectedMembers={setSelectedMembers}
        />
        <EditTeamName
          team={selectedTeam}
          setTeam={setSelectedTeam}
          organization={organization}
          isShowing={editTeamNameShowing}
          setIsShowing={setEditTeamNameShowing}
        />

        {!showNewTeamStyles ? (
          <>
            {filteredLoading ? (
              <Loading />
            ) : (
              <TeamTable
                useOriginal={true}
                team={displayedMembers}
                selectedMembers={selectedMembers}
                onSelectMember={toggleSelectedMember}
                onSelectAllMembers={toggleSelectedMembers}
                onClickEdit={(member) => {
                  setSelectedMember(member);
                  setEditTeamMemberShowing(true);
                }}
                onClickEditTeam={(team) => {
                  setSelectedTeam(team);
                  setEditTeamNameShowing(true);
                }}
              />
            )}
          </>
        ) : (
          <TeamTable
            useOriginal={false}
            usersLoading={filteredLoading}
            team={displayedMembers}
            selectedMembers={selectedMembers}
            onSelectMember={toggleSelectedMember}
            onSelectAllMembers={toggleSelectedMembers}
            onClickEdit={(member) => {
              setSelectedMember(member);
              setEditTeamMemberShowing(true);
            }}
            onClickEditTeam={(team) => {
              setSelectedTeam(team);
              setEditTeamNameShowing(true);
            }}
            toolbar={
              <BulkEditToolbar
                selectedMembers={selectedMembers}
                organization={organization}
                showEdit={currentTab === TabName.Active}
                showRestore={currentTab === TabName.Archived}
                showInvite={currentTab === TabName.Pending}
                showArchive={[TabName.Active, TabName.Pending].includes(currentTab)}
                onClose={resetSelectedMembers}
                onArchive={handleArchive}
                onInvite={handleInviteMembers}
                onEdit={handleEditMember}
                remindersLoading={remindersLoading}
              />
            }
          />
        )}
      </Box>
      {!showNewTeamStyles && (
        <Drawer anchor='bottom' open={selectedMembers.size > 0} variant='persistent'>
          <Box
            style={{
              padding: '12px',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button className={classes.button} onClick={resetSelectedMembers}>
              Cancel
            </Button>
            {[...selectedMembers].filter((selectedMember) =>
              [AccountStatus.Invited, AccountStatus.Pending].includes(
                selectedMember?.accountStatus,
              ),
            ).length === selectedMembers.size && (
              <Button className={classes.button} onClick={handleInviteMembers}>
                {`Invite ${selectedMembers.size} ${humanize.pluralize(
                  selectedMembers.size,
                  'member',
                )}`}
                {remindersLoading && (
                  <ReactLoading
                    type='spin'
                    height='1rem'
                    width='1rem'
                    css={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}
                  />
                )}
              </Button>
            )}
            {!isArchivedPage && (
              <Button className={classes.button} onClick={handleEditMember}>
                {`Edit ${selectedMembers.size} ${humanize.pluralize(
                  selectedMembers.size,
                  'member',
                )}`}
              </Button>
            )}
          </Box>
        </Drawer>
      )}
      <Snackbar
        open={inviteSent}
        autoHideDuration={5000}
        onClose={() => setInviteSent(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity='success'>Invitations sent!</Alert>
      </Snackbar>
    </>
  );
};

const useStyle = makeStyles({
  hrBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    borderRadius: theme.spacing(2),
    padding: `${theme.spacing(4)} ${theme.spacing(4)}`,
    backgroundColor: theme.palette.error[100],
    '@media (min-width: 800px)': {
      maxHeight: '132px',
    },
    '@media (max-width: 600px)': {
      flexWrap: 'wrap-reverse',
      textAlign: 'center',
    },
  },
  hrBoxHeadline: {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  hrBoxCopy: {
    color: theme.palette.text.secondary,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
  },
  hrBoxButton: {
    width: '200px',
    display: 'inline-flex',
    textTransform: 'none',
    color: theme.palette.common.white,
    borderRadius: theme.spacing(8),
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.text.secondary,
    },
  },
  hrBoxIllustration: {
    width: '200px',
    height: '160px',
    marginLeft: '12px',
    overflow: 'hidden',
    '@media (min-width: 800px)': {
      marginTop: '-32px',
      marginBottom: '-20px',
    },
  },
  hrBoxIllustrationBikes: {
    marginTop: '-20px',
  },
  teamHeaderRow: {
    margin: `${theme.spacing(4)} 0`,
  },
  addMemberRow: {
    margin: `${theme.spacing(4)} 0`,
    width: '100%',
    textAlign: 'right',
  },
  filterStack: {
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'flex-end',
  },
  secondaryButton: {
    border: `1px solid ${theme.palette.primary.main}`,
    boxSizing: 'border-box',
    borderRadius: '44px',
    height: '45px',
    padding: '12px 16px',
    whiteSpace: 'nowrap',
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontWeight: 600,
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      boxShadow: `0px 2px 3px ${theme.palette.primary[200]}`,
    },
  },
  button: {
    marginRight: theme.spacing(5),
    fontWeight: theme.typography.fontWeightMedium,
    textDecoration: 'none',
    backgroundImage: primaryGradient,
    color: 'white',
    width: theme.spacing(42),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    borderRadius: theme.spacing(40),
  },
  teamManagerActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: `0 0 ${theme.spacing(3)}`,
    gap: theme.spacing(5),
  },
  teamManagerTop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 0,
    gap: theme.spacing(10),
    width: '100%',
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: 0,
    gap: theme.spacing(3),
  },
  callToAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    gap: theme.spacing(5),
  },
});

export default Team;
