// @ts-strict-ignore
import { gql } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';

import ScoringCard from 'components/Cards/ScoringCard';
import { SCORE_TYPE_NAME_MAP } from 'constants/Strings';
import theme from 'theme';
import { ScoreDetailsNavigationFragment, ScoreType } from 'types';
import { getConnectionsPath } from '..';

interface Props {
  loading: boolean;
  teamId: string;
  activeScoreType: ScoreType;
  setActiveScoreType: (newScoreType: ScoreType) => unknown;
  scores?: ScoreDetailsNavigationFragment[];
}

const ScoreTypeIndex = [
  ScoreType.SenseOfBelonging,
  ScoreType.Leadership,
  ScoreType.WithinTeam,
  ScoreType.CrossTeam,
];

const ScoreDetailsNavigation = ({
  loading,
  teamId,
  activeScoreType,
  setActiveScoreType: setScoreType,
  scores,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div style={{ width: 240 }} className={classes.stickyNav}>
      <Link to={getConnectionsPath(teamId)} className={classes.backLink}>
        <ArrowBack className={classes.backIcon} />
        Overview
      </Link>
      {ScoreTypeIndex.map((scoreType) => {
        const score = scores?.find((t) => t.scoreType === scoreType);

        return (
          <ScoringCard
            key={scoreType}
            loading={loading}
            title={SCORE_TYPE_NAME_MAP[scoreType]}
            value={score?.value}
            trend={score?.trend}
            active={scoreType === activeScoreType}
            onClick={() => setScoreType(scoreType)}
            onClose={() => history.push(getConnectionsPath(teamId))}
          />
        );
      })}
    </div>
  );
};

const useStyles = makeStyles({
  stickyNav: {
    position: 'sticky',
    top: 0,
    height: 400,
    paddingTop: '20px',
  },
  backLink: {
    display: 'inline-flex',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.text.secondary,
      textDecoration: 'underline',
    },
  },
  backIcon: {
    height: 16,
  },
});

ScoreDetailsNavigation.fragment = gql`
  fragment ScoreDetailsNavigation on EngagementScore {
    scoreType
    value
    trend
  }
`;

export default ScoreDetailsNavigation;
