import React from 'react';
import { useQuery, gql } from '@apollo/client';

import { Loading, Page } from 'components/core';

export const PrivacyPolicy = () => {
  const { data, loading } = useQuery(latestPrivacyPolicyWithContentQuery);

  if (loading) return <Loading />;

  return (
    <Page title={strings.PRIVACY_POLICY}>
      <div dangerouslySetInnerHTML={{ __html: data!.legalDocument!.content }} />
    </Page>
  );
};

const strings = {
  PRIVACY_POLICY: `Privacy Policy`,
};

const latestPrivacyPolicyWithContentQuery = gql`
  query getLatestPrivacyPolicyWithContent {
    legalDocument(type: PrivacyPolicy) {
      id
      content
    }
  }
`;
