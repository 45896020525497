import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useResponsive } from 'ahooks';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { getCurrentStep } from '../lib/helpers/router';
import theme, { primaryGradient } from '../theme';
import {
  SelfServeState,
  SelfServeStep,
  SelfServeStepValidationMap,
} from '../types/selfServeFlow';
interface Props {
  steps: SelfServeStep[];
  activeStep: string;
  stepValidation: SelfServeStepValidationMap;
  globalState?: SelfServeState;
}

const useMysteryStepperStyles = makeStyles((theme) => ({
  wrapper: {
    borderColor: theme.palette.primary.light,
  },
  checkIcon: {
    marginTop: 2,
    paddingBottom: 2,
    marginRight: theme.spacing(1),
    fontSize: 18,
  },
  boxBackground: {
    height: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  titleSection: {
    display: 'flex',
    flexDirection: 'column',
  },
  subtitleContainer: {
    fontSize: '0.8rem',
    minHeight: '0.8rem',
    color: theme.palette.text.secondary,
  },
}));

const MysteryStepper = ({
  steps,
  activeStep,
  stepValidation,
  globalState = {},
}: Props) => {
  const disableButtons = globalState.payment?.bookSuccessful;
  const classes = useMysteryStepperStyles();
  const { md } = useResponsive();
  const history = useHistory();

  return (
    <Box
      paddingX={md ? 25 : 0}
      position='relative'
      display='flex'
      flexDirection='row'
      justifyContent='space-between'
      width='100%'
      minHeight={60}
      borderBottom={1}
      className={classes.wrapper}
    >
      {md ? (
        <>
          <ProgressBar steps={steps} activeStep={activeStep} />
          {steps.map(({ value, key }, i) => {
            const getColor = () => {
              if (activeStep === i.toString()) return theme.palette.primary.main;
              else if (stepValidation[value.key]) return theme.palette.primary.main;
              return theme.palette.grey['500'];
            };

            const getSubtitleLabel = () => {
              if (globalState[value.key]) return globalState[value.key].label;
              return '';
            };

            return (
              <Box
                className={stepValidation[value.key] ? classes.boxBackground : ''}
                onClick={
                  stepValidation[value.key] && !disableButtons
                    ? () => {
                        history.push(`/book/${key}`);
                      }
                    : undefined
                }
                style={{ cursor: disableButtons ? 'auto' : undefined }}
                key={i}
                display='flex'
                flexDirection='column'
                alignItems='center'
                color={getColor()}
                fontWeight='fontWeightMedium'
              >
                <div className={classes.titleContainer}>
                  <div>
                    {stepValidation[value.key] && value.title && (
                      <CheckCircleOutlineIcon className={classes.checkIcon} />
                    )}
                  </div>
                  <div className={classes.titleSection}>
                    <div>{value.title}</div>
                    <div className={classes.subtitleContainer}>
                      {value.getSubtitle && stepValidation[value.key]
                        ? value.getSubtitle({ label: getSubtitleLabel() })
                        : ''}
                    </div>
                  </div>
                </div>
              </Box>
            );
          })}
        </>
      ) : (
        <Box
          width='100%'
          color={theme.palette.primary.main}
          fontWeight='fontWeightMedium'
          textAlign='center'
        >
          {getCurrentStep({ steps, activeStep }).value.title}
        </Box>
      )}
    </Box>
  );
};

const useProgressBarStyles = makeStyles({
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    background: primaryGradient,
    transition: 'width .2s',
    transitionDuration: '300',
  },
});

const ProgressBar = ({ steps, activeStep }: Pick<Props, 'steps' | 'activeStep'>) => {
  const classes = useProgressBarStyles();
  const width = (parseInt(activeStep, 10) + 1) / _.size(steps);
  return <Box className={classes.wrapper} width={width} height={6} />;
};

export default MysteryStepper;
